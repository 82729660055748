import React from 'react';
import { useTranslation } from 'react-i18next';

const EdgePopUpNote = () => {
  const { t } = useTranslation();

  return (
    <>
      <ul>
        <li>{t('statements.popUp.edge.list.1')}</li>
        <li>{t('statements.popUp.edge.list.2')}</li>
        <li>{t('statements.popUp.edge.list.3')}</li>
        <li>{t('statements.popUp.edge.list.4')}</li>
      </ul>

      <p>
        <li>{t('statements.popUp.edge.note')}</li>
      </p>
    </>
  );
};

export default EdgePopUpNote;
