import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';

import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet-async';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import history from './store/utils/history';
import useInitHook from './util/hooks/useInitHook';
import './workers';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import AppRoutes from './AppRoutes';
import useRefreshHook from './util/hooks/useRefreshHook';
import RefreshModal from './components/Modals/RefreshModal/RefreshModal';
import config from './config';
import { getCurrentAccount } from './store/selectors/accountSelectors';
import NoPermission from './components/NoPermission/NoPermission';
import ScrollToTopRouter from './components/ScrollToTopRouter/ScrollToTopRouter';
import MaintenancePage from './pages/Maintenance/MaintenancePage';
import { useGetIsImpersonating } from './util/hooks/useGetIsImpersonating';
import { useBrokerName } from './util/hooks/userBrokerName';
import GlobalStyles from './assets/styles/base';
import { PageLayout } from './components/PageLayout/PageLayout';
import loadBranchScript from './branchScript';
import ErrorBoundary from './components/ErrorBoundary';
import { IdleTimeContext } from './providers/IdleTimeContextProvider';

const App = () => {
  const { isImpersonating } = useGetIsImpersonating();
  const currentAccount = useSelector(getCurrentAccount);
  const [sessionAlert, setSessionAlert] = useState(false);
  const [accountCode, setAccountCode] = useState('');
  const [noPermissionsModal, setNoPermissionsModal] = useState(false);
  const maintenance = false;

  const brokerName = useBrokerName();
  useInitHook(setNoPermissionsModal, maintenance);
  useRefreshHook(setSessionAlert, sessionAlert);
  const getRemainingTime = useRefreshHook(
    setSessionAlert,
    sessionAlert,
    history,
  );
  const { brokerTheme } = config;
  const tagManagerArgs = {
    gtmId: config.gtm.gtmId,
  };

  useEffect(() => {
    if (maintenance) {
      return;
    }

    TagManager.initialize(tagManagerArgs);
    if (brokerTheme !== 'LegacyPM') {
      loadBranchScript();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (maintenance) {
      return;
    }

    setAccountCode(currentAccount.AccountCode);
  }, [currentAccount, maintenance]);

  return (
    <>
      <Router history={history}>
        <GlobalStyles />
        <Helmet>
          <title>
            {!isImpersonating
              ? i18next.t('app.title', { brokerName })
              : i18next.t('app.impersonatingTitle', {
                  accountCode,
                  brokerName,
                })}
          </title>
        </Helmet>
        <Header
          setNoPermissionsModal={setNoPermissionsModal}
          maintenance={maintenance}
        />
        {maintenance ? (
          <PageLayout>
            <MaintenancePage />
          </PageLayout>
        ) : (
          <>
            <IdleTimeContext.Provider value={getRemainingTime}>
              <ScrollToTopRouter />
              <PageLayout>
                <NoPermission isOpen={noPermissionsModal}>
                  <ErrorBoundary>
                    <AppRoutes />
                  </ErrorBoundary>
                </NoPermission>
              </PageLayout>
            </IdleTimeContext.Provider>
          </>
        )}
        <Footer />
      </Router>
      <RefreshModal isOpen={sessionAlert} close={setSessionAlert} />
    </>
  );
};

export default App;
