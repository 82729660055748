import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Paragraph from '../../../Paragraph/Paragraph';

export const VerifyBankAccountAttemptsError = ({ bankAccount }) => {
  const { t } = useTranslation();

  return bankAccount.VerificationAttemptsLeft > 1 ? (
    <Paragraph bold isError marginTop={8}>
      {t('bankAccounts.verify.attemptsLeft', {
        num: bankAccount.VerificationAttemptsLeft,
      })}
    </Paragraph>
  ) : (
    <Paragraph bold isError marginTop={8}>
      {t('bankAccounts.verify.attemptLeft', {
        num: bankAccount.VerificationAttemptsLeft,
      })}
    </Paragraph>
  );
};

VerifyBankAccountAttemptsError.propTypes = {
  bankAccount: PropTypes.shape({
    VerificationAttemptsLeft: PropTypes.number,
  }),
};
