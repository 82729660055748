import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import styled from 'styled-components';
import SectionLoader from '../../Loader/SectionLoader';
import { ReactComponent as Plus } from '../../../assets/images/svg/plus.svg';
import Note from '../../Notes/Note';
import { uFlexCenter } from '../../../assets/styles/utility';
import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import themeColors from '../../../assets/styles/themeColors';
import { variables } from '../../../assets/styles/variables';
import { getCurrentAccountUid } from '../../../store/selectors/accountSelectors';
import { selectIsLoadingByActionTypes } from '../../../store/selectors/loadingSelectors';
import { SUBMIT_CREDIT_CARD_LOADING } from '../../../store/actions/creditCard/creditCardActionConstants';
import {
  GET_COUNTRIES_LOADING,
  GET_STATES_LOADING,
} from '../../../store/actions/common/commonConstants';
import Paragraph from '../../Paragraph/Paragraph';
import Button from '../../Button/Button';
import TextField from '../../InputFields/TextField';
import { submitMailInRequest } from '../../../store/actions/mailIn/mailInActions';

export const AddAddressPlusIconWrapper = styled.div`
  ${uFlexCenter};
  width: ${pxToRem(16)};
  height: ${pxToRem(16)};
  border-radius: 4px;
  background-color: ${themeColors.colorPrimary};
  box-shadow: ${variables.shadow.boxShadow};
  margin-right: ${pxToRem(16)};
  flex-shrink: 0;
  padding: ${pxToRem(3)};
  color: ${themeColors.colorTextContrast};

  svg {
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
    color: ${themeColors.colorTextContrast};
  }
`;

export const ProductDescriptionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 40px;
  margin-top: ${({ marginTop }) => (marginTop ? `${pxToRem(marginTop)}` : 0)};
`;

export const DescriptionField = styled.div`
  flex: 85%;
`;

export const QuantityField = styled.div`
  //   flex: 20%;
`;

export const MailInAddElement = styled(Button)`
  border-radius: 0;
  border: 1px solid ${themeColors.colorBorderPrimary};
  background-color: ${themeColors.colorBackgroundPrimary};
  padding: ${pxToRem(12.5)} ${pxToRem(16.5)};
  margin-top: ${pxToRem(20)};
  margin-bottom: ${pxToRem(20)};
  text-transform: capitalize;
  justify-content: flex-start;
  font-weight: 600;
  color: ${themeColors.colorTextPrimary};
  font-size: ${pxToRem(16)};

  ${({ disabled }) =>
    disabled &&
    `opacity: 0.5;
    pointer-events: none;
    `}

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(16)};
  }
`;

export const MailInAddIcon = styled.div`
  ${uFlexCenter};
  width: ${pxToRem(32)};
  height: ${pxToRem(32)};
  border-radius: 4px;
  background-color: ${themeColors.colorPrimary};
  box-shadow: ${variables.shadow.boxShadow};
  margin-right: ${pxToRem(16)};
  flex-shrink: 0;
  padding: ${pxToRem(3)};
  color: ${themeColors.colorWhite};

  svg {
    width: ${pxToRem(32)};
    height: ${pxToRem(32)};
    color: ${themeColors.colorWhite};
  }
`;

const AddMailInStorage = ({ handleNext }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accountUid = useSelector(getCurrentAccountUid);

  const isLoading = useSelector(
    selectIsLoadingByActionTypes([
      SUBMIT_CREDIT_CARD_LOADING,
      GET_STATES_LOADING,
      GET_COUNTRIES_LOADING,
    ]),
  );

  const onSuccess = () => {
    handleNext();
  };

  const handleSubmitMailIn = (values) => {
    dispatch(
      submitMailInRequest({
        accountUid,
        Items: [...values.Items],
        onSuccess,
      }),
    );
  };

  return (
    <SectionLoader isLoading={isLoading}>
      <Formik
        initialValues={{
          Items: [
            {
              Description: '',
              Quantity: '',
            },
          ],
        }}
        onSubmit={handleSubmitMailIn}
        enableReinitialize
      >
        {({ values, setFieldValue }) => (
          <Form>
            <>
              <Paragraph bold>{t('mailIn.description')}</Paragraph>
              {values.Items.map((productItem, index) => (
                <ProductDescriptionRow
                  marginTop={20}
                  key={`roductItem${index + 1}`}
                >
                  <DescriptionField>
                    <Field
                      component={TextField}
                      name={`Items[${values.Items.indexOf(
                        productItem,
                      )}].Description`}
                      label={
                        values.Items.indexOf(productItem) === 0
                          ? t('mailIn.productDescription')
                          : null
                      }
                    />
                  </DescriptionField>
                  <QuantityField>
                    <Field
                      component={TextField}
                      name={`Items[${values.Items.indexOf(
                        productItem,
                      )}].Quantity`}
                      label={
                        values.Items.indexOf(productItem) === 0
                          ? t('mailIn.quantity')
                          : null
                      }
                    />
                  </QuantityField>
                </ProductDescriptionRow>
              ))}

              <MailInAddElement
                onClick={() => {
                  setFieldValue('Items', [
                    ...values.Items,
                    { Description: '', Quantity: '' },
                  ]);
                }}
              >
                <MailInAddIcon>
                  <Plus />
                </MailInAddIcon>
                {t('mailIn.addAnotherProduct')}
              </MailInAddElement>

              <Note text={t('mailIn.note')} marginBottom={24} noUnderline />
            </>

            <Button variant="primary" textTransform="capitalize" type="submit">
              {t('common.sendRequest')}
            </Button>
          </Form>
        )}
      </Formik>
    </SectionLoader>
  );
};

AddMailInStorage.propTypes = {
  handleNext: PropTypes.func,
  wizardData: PropTypes.shape({
    Bin: PropTypes.number,
    Last4Digits: PropTypes.number,
    ExpirationDate: PropTypes.string,
    NameOnCard: PropTypes.string,
    Nonce: PropTypes.string,
    CreditCardType: PropTypes.string,
    creditCard: PropTypes.shape({
      Last4Digits: PropTypes.string,
      ExpirationDate: PropTypes.string,
    }),
  }),
};

export default AddMailInStorage;
