import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../Button/Button';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { uFlexCenter } from '../../assets/styles/utility';
import { variables } from '../../assets/styles/variables';

export const LoadMoreContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: ${pxToRem(24)};
  margin-top: ${pxToRem(32)};

  > button {
    ${uFlexCenter};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: 0 ${pxToRem(16)};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: 0 ${pxToRemMd(16)};
    grid-template-columns: 1fr auto;
  }
`;

export const LoadMoreButton = styled(Button)`
  text-transform: none;
`;

const LoadMore = ({ onLoadClick, loadMoreDisabled, scrollToElement }) => (
  <LoadMoreContainer>
    <LoadMoreButton
      variant="default"
      onClick={onLoadClick}
      size="lg"
      disabled={loadMoreDisabled}
      data-cy="button-load-more"
    >
      Load More
    </LoadMoreButton>
    <ScrollToTop scrollToElement={scrollToElement} />
  </LoadMoreContainer>
);

LoadMore.propTypes = {
  onLoadClick: PropTypes.func,
  loadMoreDisabled: PropTypes.bool,
  scrollToElement: PropTypes.number,
};

export default LoadMore;
