import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from '../../Modal/Modal';
import ModalBody from '../../Modal/ModalBody';
import ModalButtons from '../../Modal/ModalButtons';
import Paragraph from '../../Paragraph/Paragraph';
import useGtmHook from '../../../util/hooks/useGtmHook';
import { FUND_DEPOSIT_START } from '../../../constants/gtmEvents';

const MilleniumDepositWizard = ({ isModalOpen, handleClose }) => {
  const { t } = useTranslation();
  const { fundGtmEvent } = useGtmHook();

  useEffect(() => {
    if (isModalOpen) {
      fundGtmEvent(FUND_DEPOSIT_START, {
        fund: {
          method: 'Fund your Millenium Trust IRA',
        },
      });
    }
  }, [isModalOpen]); // eslint-disable-line

  const url =
    'https://portal2.mtrustcompany.com/millennium/servlet/SmartForm.html?formCode=verification-form&refCode=HAA&disableInvestment=true&data=true';

  const handleOpen = useCallback(() => {
    if (url) {
      window.open(url, '_blank');
    }
    handleClose();
  }, [handleClose]);

  return (
    <Modal
      isOpen={isModalOpen}
      close={handleClose}
      size="sm"
      title={t('depositWizard.fundYourIra')}
    >
      <ModalBody>
        <Paragraph marginBottom={24}>{t('millenium.inOrder')}</Paragraph>
        <Paragraph>{t('millenium.tapTheButton')}</Paragraph>
        <ModalButtons
          marginTop
          primaryButtonProps={{
            type: 'button',
            disabled: !url,
            size: 'lg',
            onClick: handleOpen,
            label: t('millenium.fundAccount'),
          }}
          secondaryButtonProps={{
            type: 'button',
            onClick: handleClose,
            size: 'lg',
            label: t('common.cancel'),
          }}
          isVertical
        />
      </ModalBody>
    </Modal>
  );
};

MilleniumDepositWizard.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
};

export default MilleniumDepositWizard;
