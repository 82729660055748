/* eslint-disable no-unused-expressions */

import { useEffect, useState } from 'react';
import { LANDING_PAGE_URL, OFFER_CODE } from '../../constants/sessionStorage';
import { retrieveFromSessionStorage } from '../helpers/sessionStorageHelper';

const useTrackingDetails = () => {
  const [Fp, setFp] = useState('');
  const ReferrerUrl = document.referrer;
  const Offer = retrieveFromSessionStorage(OFFER_CODE);
  const LandingPageUrl = retrieveFromSessionStorage(LANDING_PAGE_URL);

  useEffect(() => {
    setTimeout(() => {
      window.Fingerprint2?.get((components) => {
        const values = components.map((component) => component.value);
        const fingerPrint = window.Fingerprint2.x64hash128(values.join(''), 31);
        setFp(fingerPrint);
      });
    }, 1000);
  }, []);

  const TrackingDetails = {
    Fp,
    LandingPageUrl,
    Offer,
    ReferrerUrl,
  };

  return { TrackingDetails };
};

export default useTrackingDetails;
