import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BUY_FOR_DELIVERY_WIZARD } from '../../constants/sessionStorage';
import StepByStep from '../StepByStep';
import ChooseDeliveryAddress from './ChooseDeliveryAddress';
import OrderForDelivery from './OrderForDelivery';
import ReviewOrderForDelivery from './ReviewOrderForDelivery';
import {
  selectBuyError,
  selectIsBuyStorageSuccessful,
} from '../../store/selectors/buyStorageSelectors';
import Status from '../Modals/Status';
import Paragraph from '../Paragraph/Paragraph';
import Restriction from '../Modals/RestrictionModal/Restriction';
import useRestrictionHook from '../../util/hooks/useRestrictionHook';
import { isActionRestrictedHelper } from '../../util/helpers/restrictionHelper';
import { restrictionTypes } from '../../util/enum/api/restrictionTypes';
import UnverifiedBankAccountList from '../UnverifiedBankAccountList/UnverifiedBankAccountList';
import OrderForDeliveryPayment from './OrderForDeliveryPayment';
import { selectSettings } from '../../store/selectors/settingsSelectors';

const nonPostPaidSteps = [
  { children: ChooseDeliveryAddress },
  { children: OrderForDelivery },
  { children: ReviewOrderForDelivery },
  { children: Status },
];

const postPaidSteps = [
  { children: ChooseDeliveryAddress },
  { children: OrderForDelivery },
  { children: OrderForDeliveryPayment },
  { children: ReviewOrderForDelivery },
  { children: Status },
];

const BuyForDelivery = ({
  closeModal,
  setWizardData,
  wizardData,
  handleBack,
  handleNext,
  setWizardTitle,
  openSupport,
  isSupportOpen,
  skipChooseLocation,
  unverifiedBankAccounts,
  tiers,
  counter,
  unity,
  isSegregated,
  isPostPaidVisible,
}) => {
  const { t } = useTranslation();
  const settings = useSelector(selectSettings);
  const isBuyStorageSuccessful = useSelector(selectIsBuyStorageSuccessful);
  const errorMessage = useSelector(selectBuyError);
  const restrictions = useRestrictionHook();

  const isPostPaidActive = settings?.IsPostPaidActive;

  const steps = isPostPaidVisible ? postPaidSteps : nonPostPaidSteps;

  useEffect(() => {
    if (isBuyStorageSuccessful || errorMessage) {
      setWizardData({
        ...wizardData,
        isBuyStorageSuccessful: !isBuyStorageSuccessful,
        errorMessage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBuyStorageSuccessful, errorMessage]);

  if (!isPostPaidActive && unverifiedBankAccounts?.length) {
    return <UnverifiedBankAccountList bankAccounts={unverifiedBankAccounts} />;
  }
  return (
    <>
      {isActionRestrictedHelper(restrictions, [
        restrictionTypes.LogonIsPrevented,
        restrictionTypes.RestrictBuyForDelivery,
        restrictionTypes.RestrictBuy,
      ]) ? (
        <Restriction restrictions={restrictions} />
      ) : (
        <StepByStep sessionScopeStep={BUY_FOR_DELIVERY_WIZARD}>
          {({ goStepForward, goStepBack, skipPreviousStep }) =>
            steps.map((buyStorageStep) =>
              React.createElement(buyStorageStep.children, {
                handleBack: goStepBack,
                handleNext: goStepForward,
                skipPreviousStep,
                closeModal,
                setWizardData,
                wizardData,
                handleBuyWizardBack: handleBack,
                handleBuyWizardNext: handleNext,
                hasError: wizardData?.isBuyStorageSuccessful,
                text: wizardData?.errorMessage || (
                  <>
                    <Paragraph centerText>
                      {t('buyWizard.confirmationMessage.orderPlaced')}
                    </Paragraph>
                    <Paragraph centerText>
                      {t(
                        'buyWizard.confirmationMessage.onTradeConfirmedMessage',
                      )}
                    </Paragraph>
                  </>
                ),
                onButtonClick: closeModal,
                setWizardTitle,
                openSupport,
                isSupportOpen,
                skipChooseLocation,
                tiers,
                counter,
                unity,
                isSegregated,
                isPostPaidVisible,
              }),
            )
          }
        </StepByStep>
      )}
    </>
  );
};

BuyForDelivery.propTypes = {
  closeModal: PropTypes.func,
  setWizardData: PropTypes.func,
  wizardData: PropTypes.shape({
    isBuyStorageSuccessful: PropTypes.bool,
    errorMessage: PropTypes.string,
  }),
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  setWizardTitle: PropTypes.func,
  openSupport: PropTypes.func,
  isSupportOpen: PropTypes.bool,
  skipChooseLocation: PropTypes.bool,
  unverifiedBankAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      BankAccountUid: PropTypes.string,
      BankName: PropTypes.string,
      AccountMask: PropTypes.string,
      IsVerified: PropTypes.bool,
    }),
  ),
  tiers: PropTypes.arrayOf(
    PropTypes.shape({
      HighBound: PropTypes.number,
      LowBound: PropTypes.number,
      PricePerOz: PropTypes.number,
      PricePerUnit: PropTypes.number,
    }),
  ),
  counter: PropTypes.number,
  unity: PropTypes.bool,
  isSegregated: PropTypes.bool,
  isPostPaidVisible: PropTypes.bool,
};

export default BuyForDelivery;
