import { createSelector } from 'reselect';

const documentsSelector = (state) => state.documents;

export const getDocumentsData = createSelector(
  documentsSelector,
  (state) => state.documents,
);

export const getDocumentsDataError = createSelector(
  getDocumentsData,
  (state) => state.error,
);
