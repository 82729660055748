import { hardAssetsAllianceColors } from './Themes/HardAssetsAllianceTheme';
import { goldSilverColors } from './Themes/GoldSilverTheme';
import { contosoColors } from './Themes/ContosoTheme';
import { legacyPMColors } from './Themes/LegacyPreciousMetalsTheme';
import config from '../../config';

const getThemeColors = () => {
  const { brokerTheme } = config;

  switch (brokerTheme) {
    case 'HardAssetsAlliance':
      return { ...hardAssetsAllianceColors };

    case 'GoldSilver':
      return { ...goldSilverColors };
    case 'Contoso':
      return { ...contosoColors };
    case 'LegacyPM':
      return { ...legacyPMColors };

    default:
      return { ...hardAssetsAllianceColors };
  }
};

const themeColors = getThemeColors();

export default themeColors;
