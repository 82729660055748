import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Restriction from '../../../RestrictionModal/Restriction';
import StepByStep from '../../../../StepByStep';
import { ACH_DEPOSIT_WIZARD } from '../../../../../constants/sessionStorage';
import { isActionRestrictedHelper } from '../../../../../util/helpers/restrictionHelper';
import { restrictionTypes } from '../../../../../util/enum/api/restrictionTypes';
import useRestrictionHook from '../../../../../util/hooks/useRestrictionHook';
import AchDeposit from './AchDeposit';
import AchDepositReview from './AchDepositReview';
import Status from '../../../Status';
import useGtmHook from '../../../../../util/hooks/useGtmHook';
import { getCurrentAccount } from '../../../../../store/selectors/accountSelectors';
import { transactionOrderTypes } from '../../../../../util/enum/api/transactionTypes';
import { FUND_DEPOSIT_COMPLETE } from '../../../../../constants/gtmEvents';
import { updateAchDeposit } from '../../../../../store/actions/orders/orderActions';
import useCheckPermissions from '../../../../../util/hooks/useCheckPermissions';
import { PORTFOLIO_PAGE } from '../../../../../constants/pages';
import {
  accountTransactionsFetch,
  fetchPortfolioBalances,
  portfolioFinancialPendingTransactions,
} from '../../../../../store/actions/portfolio/portfolioActions';
import { RECENT_TRANSACTIONS } from '../../../../../constants/accountTransactionConstants';
import { fetchNagDashboard } from '../../../../../store/actions/account/accountActions';
import { DEPOSIT_WIZARD_TYPES } from '../../../../../constants/standardDepositWizardConstants';

const steps = [AchDeposit, AchDepositReview, Status];

const AchDepositWizard = ({
  goToInitialModal,
  setWizardData,
  wizardData,
  setModalSize,
  handleClose,
  setWizardBack,
  setWizardTitle,
  handleRefreshTransactions,
}) => {
  const { t } = useTranslation();
  const { gtmDialogScreenView, fundGtmEvent } = useGtmHook();
  const restrictions = useRestrictionHook();
  const dispatch = useDispatch();
  const { AccountUid } = useSelector(getCurrentAccount);
  const {
    displayStandardOptionsPermission,
    displayBasicAccountOptionsPermission,
  } = useCheckPermissions();
  const history = useHistory();
  const account = useSelector(getCurrentAccount);
  const isPendingAccount =
    account?.AccountStatus === 4 ||
    account?.AccountStatus === 1 ||
    account?.AccountStatus === 7;

  const disableManualAch =
    isPendingAccount || displayBasicAccountOptionsPermission;

  const handleSubmitDeposit = (goToNextStep) => {
    const data = {
      Amount: wizardData?.amount,
      BankAccountUid: wizardData?.bankAccount.BankAccountUid,
      AccountUid,
      Type: transactionOrderTypes.AchDeposit,
    };

    const handleTransactionsRefresh = () => {
      if (history.location.pathname === PORTFOLIO_PAGE) {
        dispatch(
          accountTransactionsFetch({
            accountUid: AccountUid,
            content: RECENT_TRANSACTIONS,
          }),
        );
      } else {
        if (typeof handleRefreshTransactions === 'function') {
          handleRefreshTransactions();
        }

        if (displayStandardOptionsPermission) {
          dispatch(
            portfolioFinancialPendingTransactions({
              accountUid: AccountUid,
            }),
          );
        }
      }

      dispatch(fetchPortfolioBalances({ accountUid: AccountUid }));
    };

    const afterSubmit = (hasError, statusMessage) => {
      setWizardData({ hasError, statusMessage });

      gtmDialogScreenView({
        title: `ACH Deposit Submitted`,
      });
      // gtmEvent(DEPOSIT, {
      //   AccountType: parseEnumType(accountTypeKeys, AccountType),
      //   Date: formatDate(date),
      //   Amount: wizardData?.amount,
      // });

      fundGtmEvent(FUND_DEPOSIT_COMPLETE, {
        fund: {
          method: DEPOSIT_WIZARD_TYPES.ACH,
          amount: wizardData?.amount,
        },
      });

      handleTransactionsRefresh();

      dispatch(fetchPortfolioBalances({ accountUid: AccountUid }));
      dispatch(fetchNagDashboard({ accountUid: AccountUid }));

      goToNextStep();
    };

    dispatch(
      updateAchDeposit({
        data,
        accountUid: AccountUid,
        afterSubmit,
      }),
    );
  };

  if (
    isActionRestrictedHelper(restrictions, [
      restrictionTypes.LogonIsPrevented,
      restrictionTypes.RestrictAchDeposit,
    ])
  ) {
    return <Restriction restrictions={restrictions} />;
  }

  return (
    <StepByStep sessionScopeStep={ACH_DEPOSIT_WIZARD}>
      {({ goStepForward, goStepBack }) =>
        steps.map((StandardDepositWizardStep) =>
          React.createElement(StandardDepositWizardStep, {
            handleBack: goStepBack,
            handleNext: goStepForward,
            handleClose,
            wizardData,
            setWizardData,
            setModalSize,
            goToInitialModal,
            closeEverything: handleClose,
            onButtonClick: handleClose,
            handleSubmitDeposit,
            hasError: wizardData?.hasError,
            text:
              wizardData?.statusMessage ||
              t('standardDepositWizard.achDeposit.depositSubmitted'),
            setWizardBack,
            setWizardTitle,
            disableManualAch,
          }),
        )
      }
    </StepByStep>
  );
};

AchDepositWizard.propTypes = {
  wizardData: PropTypes.shape({
    amount: PropTypes.number,
    bankAccount: PropTypes.shape({
      BankAccountUid: PropTypes.string,
    }),
    hasError: PropTypes.bool,
    statusMessage: PropTypes.string,
  }),
  setWizardData: PropTypes.func,
  goToInitialModal: PropTypes.func,
  setModalSize: PropTypes.func,
  handleClose: PropTypes.func,
  setWizardBack: PropTypes.func,
  setWizardTitle: PropTypes.func,
  handleRefreshTransactions: PropTypes.func,
};
export default AchDepositWizard;
