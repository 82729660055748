import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PortfolioSection from '../Section/PortfolioSection';
import RecentTransactionCard from './RecentTransactionsCard';
import TransactionDetails from '../../Modals/Transaction/TransactionDetails';
import {
  emptyPortfolioTransactionSingle,
  portfolioTransactionsSingleFetch,
} from '../../../store/actions/portfolio/portfolioActions';
import {
  selectPortfolioSingleTransaction,
  selectPortfolioSingleTransactionError,
} from '../../../store/selectors/portfolioTransactionsSelector';
import { formatDate } from '../../../util/helpers/dateHelpers';
import { transactionFieldFormatter } from '../../../util/helpers/transcation/transactionHelper';
import BlockSectionLoader from '../../Loader/BlockSectionLoader';
import { selectIsLoadingByActionType } from '../../../store/selectors/loadingSelectors';
import { PORTFOLIO_TRANSACTIONS_SINGLE_LOADING } from '../../../store/actions/portfolio/portfolioActionConstants';
import SectionLoader from '../../Loader/SectionLoader';
import { EmptyTable } from '../../EmptyTable/EmptyTable';
import { uFlexColumn } from '../../../assets/styles/utility';
import { variables } from '../../../assets/styles/variables';

const RecentTransaction = styled.div`
  ${uFlexColumn};
  box-shadow: ${variables.shadow.boxShadow};
  border-radius: ${variables.borderRadius.borderRadius};
`;

const RecentTransactions = ({ data, error, accountUid, isLoading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isTransactionDetailsOpen, setIsTransactionDetailsOpen] = useState(
    false,
  );
  const singleTransaction = useSelector(selectPortfolioSingleTransaction);
  const singleTransactionError = useSelector(
    selectPortfolioSingleTransactionError,
  );
  const singleTransactionLoading = useSelector(
    selectIsLoadingByActionType(PORTFOLIO_TRANSACTIONS_SINGLE_LOADING),
  );

  const onTransactionCardClick = useCallback(
    (transactionUid) => {
      dispatch(
        portfolioTransactionsSingleFetch({
          accountUid,
          transactionUid,
        }),
      );
      setIsTransactionDetailsOpen(true);
    },
    [accountUid, dispatch],
  );

  const renderTransactions = useCallback(() => {
    const renderBody = () =>
      !isEmpty(data) && !!data.length ? (
        data.map((transaction) => (
          <RecentTransactionCard
            key={`${transaction.Uid}_${transaction.DateTime}`}
            month={formatDate(transaction.DateTime, 'MMMM')}
            day={formatDate(transaction.DateTime, 'd')}
            action={
              transaction.Fields[0]
                ? transactionFieldFormatter(transaction.Fields[0])
                : null
            }
            actionSubject={
              transaction.Fields[1]
                ? transactionFieldFormatter(transaction.Fields[1])
                : null
            }
            isSegregated={transaction.IsSegregated}
            status={transaction.StatusType}
            onClick={() => onTransactionCardClick(transaction.Uid)}
          />
        ))
      ) : (
        <EmptyTable>
          <p>{t('portfolio.transactions.tableEmpty')}</p>
        </EmptyTable>
      );

    return (
      <>
        {isLoading ? (
          <BlockSectionLoader isLoading={isLoading} />
        ) : (
          renderBody()
        )}
      </>
    );
  }, [data, isLoading, onTransactionCardClick, t]);

  return (
    <PortfolioSection title="Recent Transactions">
      <RecentTransaction>
        {!error ? (
          <SectionLoader isLoading={singleTransactionLoading}>
            {renderTransactions()}
          </SectionLoader>
        ) : (
          <span>{error}</span>
        )}
      </RecentTransaction>
      <TransactionDetails
        isOpen={!isEmpty(singleTransaction) && isTransactionDetailsOpen}
        onClose={() => {
          dispatch(emptyPortfolioTransactionSingle());
          setIsTransactionDetailsOpen(false);
        }}
        transaction={singleTransaction}
        error={singleTransactionError}
      />
    </PortfolioSection>
  );
};

RecentTransactions.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  error: PropTypes.string,
  accountUid: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default RecentTransactions;
