/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Section from '../../components/Section/Section';
import RouterStepByStep from '../../components/RouterStepByStep/RouterStepByStep';
import Credentials from './Credentials';
import Confirm from './Confirm';
import Welcome from './Welcome';
import {
  getRegisterContent,
  setRegisterContent,
} from '../../util/helpers/registerHelpers';
import {
  FORM_VALUES,
  IGNORE_PERMISSIONS,
  PHONE_NUMBERS,
  SESSION_STORAGE_SCOPE,
  TWO_FA_LOGIN_SETUP,
  USERNAME,
  USER_INVITE_ID,
} from '../../constants/sessionStorage';
import { useQuery } from '../../util/hooks/useQuery';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import {
  retrieveFromSessionStorage,
  storeInSessionStorage,
} from '../../util/helpers/sessionStorageHelper';
import TwoFAVerifyIdentityPage from '../TwoFA/Authenticate/TwoFAVerifyIdentityPage';
import TwoFAVerifyIdentityPhonePage from '../TwoFA/Authenticate/TwoFAVerifyIdentityPhonePage';
import TwoFAHavingTroublePage from '../TwoFA/Authenticate/TwoFAHavingTroublePage';
import { selectTwoFactorAuthenticationResponse } from '../../store/selectors/loginSelectors';
import { parseEnumType } from '../../util/helpers/enumMappers';
import { twoFaTypesEnum } from '../../util/enum/api/twoFATypes';
import { TWO_FA_TYPES } from '../../constants/twoFaConstants';
import {
  fetchTwoFaCodeViaEmail,
  fetchTwoFaCodeViaPhone,
} from '../../store/actions/twoFA/twoFAActions';
import IdentityPage from '../IdentityPage';
import SecurityQuestionPage from '../LoginPage/SecurityQuestionPage';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import TwoFASetupChooseMethodPage from '../TwoFA/Setup/TwoFASetupChooseMethodPage';
import TwoFAPhonePage from '../TwoFA/Setup/TwoFAPhonePage';
import TwoFAVerificationCodePage from '../TwoFA/Setup/TwoFAVerificationCodePage';
import TwoFASuccessfulSetup from '../TwoFA/Setup/TwoFASuccessfulSetup';
import { selectAuthUser } from '../../store/selectors/userSelectors';
import {
  closeTwoFaLoginContent,
  getTwoFaLoginContent,
  setTwoFaLoginContent,
} from '../../util/helpers/twoFALoginSetupHelper';
import TwoFAQRCodePage from '../TwoFA/Setup/TwoFAQRCodePage';

const UserInviteWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserInviteHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${pxToRem(40)} 0 ${pxToRem(75)};
  box-shadow: ${variables.shadow.boxShadow};
  margin-bottom: ${pxToRem(64)};

  > :first-child {
    max-width: ${pxToRem(600)};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: ${pxToRemMd(40)} 0;

    > :first-child {
      max-width: 100%;
      padding: 0 ${pxToRemMd(24)};
    }
  }
`;

const UserInviteBody = styled(Section)`
  display: flex;
  justify-content: center;
  padding-top: ${pxToRem(40)};
  padding-bottom: ${pxToRem(75)};
  box-shadow: ${variables.shadow.boxShadow};
  margin-bottom: ${pxToRem(64)};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    > :first-child {
      padding: 0 ${pxToRemMd(24)} 0;
    }
  }
`;

const InviteUserPage = () => {
  const history = useHistory();
  const query = useQuery();
  const dispatch = useDispatch();
  const user = useSelector(selectAuthUser);
  const steps = [
    { label: i18next.t('inviteUser.steps.credentials'), percentage: 0 },
    { label: i18next.t('inviteUser.steps.welcome'), percentage: 100 },
  ];
  const currentPath = history.location.pathname;
  const [currentStep, setCurrentStep] = useState(0);
  const [userInviteId, setUserInviteId] = useState(
    () => getRegisterContent(USER_INVITE_ID) || null,
  );
  const [formValues, setFormValues] = useState(
    () => getRegisterContent(FORM_VALUES) || {},
  );
  const [phoneNumbers, setPhoneNumbers] = useState(
    () => getRegisterContent(PHONE_NUMBERS) || [],
  );
  const [twoFaValue, setValues] = useState(() => getTwoFaLoginContent() || {});
  const { id } = query;
  const encodedId = encodeURIComponent(id);

  const handleNext = (page) => {
    history.push(page);
  };

  useEffect(() => {
    storeInSessionStorage(SESSION_STORAGE_SCOPE, true);
    storeInSessionStorage(IGNORE_PERMISSIONS, true);
  }, []);

  useEffect(() => {
    if (id) {
      setUserInviteId(encodedId);
      setRegisterContent(USER_INVITE_ID, encodedId);
    }
  }, [id]);

  // Two Fa Identity Pages Logic
  const username = retrieveFromSessionStorage(USERNAME);
  const twoFactorAuthenticationResponse = useSelector(
    selectTwoFactorAuthenticationResponse,
  );

  const getTwoFaCode = (value) => {
    if (twoFactorAuthenticationResponse) {
      const { AuthenticationMethodType } = twoFactorAuthenticationResponse;

      const parsedAuthenticationMethodType = parseEnumType(
        twoFaTypesEnum,
        AuthenticationMethodType,
      );

      if (parsedAuthenticationMethodType === TWO_FA_TYPES.Phone) {
        dispatch(
          fetchTwoFaCodeViaPhone({
            ...value,
          }),
        );
      }

      if (parsedAuthenticationMethodType === TWO_FA_TYPES.Email) {
        dispatch(
          fetchTwoFaCodeViaEmail({
            ...value,
          }),
        );
      }
    }
  };

  const setTwoFaValues = (data) => {
    if (data === null) {
      setValues({});
      closeTwoFaLoginContent();
      return;
    }

    setValues({ ...twoFaValue, ...data });
    setTwoFaLoginContent(TWO_FA_LOGIN_SETUP, { ...twoFaValue, ...data });
  };

  // HERE

  const registerSteps = useCallback(
    (goStepBack, goStepForward) => {
      const compProps = {
        goStepBack,
        goStepForward,
        setCurrentStep,
        handleNext,
        currentStep,
        formValues,
        setFormValues,
        setPhoneNumbers,
        phoneNumbers,
        userInviteId,
        getTwoFaCode,
        username,
        twoFaValue,
        setValues,
        user,
        twoFactorAuthenticationResponse,
        isInviteUserFlow: true,
        setTwoFaValues,
      };

      return {
        '/invite-user': <Confirm {...compProps} />,
        '/invite-user/credentials': <Credentials {...compProps} />,
        '/invite-user/verify-identity': (
          <TwoFAVerifyIdentityPage {...compProps} />
        ),
        '/invite-user/verify-identity-phone': (
          <TwoFAVerifyIdentityPhonePage {...compProps} />
        ),
        '/invite-user/having-trouble': (
          <TwoFAHavingTroublePage {...compProps} />
        ),
        '/invite-user/identity': <IdentityPage {...compProps} />,
        '/invite-user/security-question': (
          <SecurityQuestionPage {...compProps} />
        ),
        '/invite-user/verify-choose-method': (
          <TwoFASetupChooseMethodPage {...compProps} />
        ),
        '/invite-user/authentication-code': <TwoFAQRCodePage {...compProps} />,
        '/invite-user/verify-phone-method': <TwoFAPhonePage {...compProps} />,
        '/invite-user/verify-code': (
          <TwoFAVerificationCodePage {...compProps} />
        ),
        '/invite-user/successful-setup': (
          <TwoFASuccessfulSetup {...compProps} />
        ),
        '/invite-user/welcome': <Welcome {...compProps} />,
      };
    },
    [
      currentStep,
      setCurrentStep,
      formValues,
      setFormValues,
      setPhoneNumbers,
      phoneNumbers,
      userInviteId,
      twoFactorAuthenticationResponse,
      username,
      twoFaValue,
      user,
      setTwoFaValues,
    ],
  );

  return (
    <UserInviteWrap>
      <UserInviteHeader>
        <ProgressBar currentStep={currentStep} steps={steps} />
      </UserInviteHeader>
      <UserInviteBody>
        <RouterStepByStep currentPath={currentPath}>
          {({ goStepBack, goStepForward }) =>
            registerSteps(goStepBack, goStepForward)
          }
        </RouterStepByStep>
      </UserInviteBody>
    </UserInviteWrap>
  );
};

export default InviteUserPage;
