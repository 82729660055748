import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import {
  getCountryStatesRequest,
  getPortalSecurityQuestionsRequest,
} from '../../request/commonRequests';
import {
  getPortalSecurityQuestionsSuccess,
  getPortalSecurityQuestionsError,
  getStatesError,
  getStatesSuccess,
} from '../actions/common/commonActions';
import {
  GET_PORTAL_SECURITY_QUESTIONS,
  GET_STATES,
} from '../actions/common/commonConstants';
import { rejectErrorCodeHelper } from '../../util/helpers/rejectErrorCodeHelper';

function* fetchPortalSecurityQuestions() {
  try {
    const { data } = yield call(getPortalSecurityQuestionsRequest);
    yield put(getPortalSecurityQuestionsSuccess(data));
    yield put(getPortalSecurityQuestionsError(''));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(getPortalSecurityQuestionsError(errorMessage));
  }
}

function* fetchStates({ payload }) {
  try {
    const { data } = yield call(getCountryStatesRequest, payload);
    yield put(getStatesSuccess(data));
    yield put(getStatesError(''));
  } catch (error) {
    // const errMsg = formatErrorMessage(error);
    yield put(getStatesError(error));
  }
}

export default function* commonSaga() {
  return yield all([
    takeLatest(GET_PORTAL_SECURITY_QUESTIONS, fetchPortalSecurityQuestions),
    takeLatest(GET_STATES, fetchStates),
  ]);
}
