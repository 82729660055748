import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { decode } from 'jsonwebtoken';
import PropTypes from 'prop-types';
import FullPageLoader from '../Loader/FullPageLoader';

import { selectIsLoadingByActionType } from '../../store/selectors/loadingSelectors';
import { APP_LOADING } from '../../store/actions/app/appActionConstants';
import {
  authenticateUser,
  logoutUser,
} from '../../store/actions/login/loginActions';
import {
  selectAuthUser,
  selectIsUserAuthenticated,
} from '../../store/selectors/userSelectors';
import {
  ADDRESSES_PAGE,
  BANK_ACCOUNTS_PAGE,
  BUY_PAGE,
  EMAIL_LOGIN_PAGE,
  METAL_STREAM_PAGE,
  PORTFOLIO_PAGE,
  PORTFOLIO_PRODUCT_PAGE,
  PROFILE_SETTINGS_PAGE,
  SELL_PAGE,
  USER_MANAGEMENT_PAGE,
  BASE_PAGE,
  STATEMENTS_PAGE,
} from '../../constants/pages';
import { selectSettings } from '../../store/selectors/settingsSelectors';
import { authScopeStringGetHelper } from '../../util/helpers/authScopeHelpers';
import { JWT_TOKEN } from '../../constants/localStorage';
import { useGetIsImpersonating } from '../../util/hooks/useGetIsImpersonating';
import { getCurrentAccount } from '../../store/selectors/accountSelectors';
import { userPermissionsFetch } from '../../store/actions/account/accountActions';
import { useIsClosedAccount } from '../../util/hooks/useIsClosedAccount';
import { storeInSessionStorage } from '../../util/helpers/sessionStorageHelper';
import { LANDING_PAGE } from '../../constants/sessionStorage';
import useCheckPermissions from '../../util/hooks/useCheckPermissions';
import { useQuery } from '../../util/hooks/useQuery';

const PrivateRoute = ({ ...props }) => {
  const dispatch = useDispatch();
  const isUserAuthenticated = useSelector(selectIsUserAuthenticated);
  const user = useSelector(selectAuthUser);
  const currentAccount = useSelector(getCurrentAccount);
  const isLoading = useSelector(selectIsLoadingByActionType(APP_LOADING));
  const { IsBroker2FAActive } = useSelector(selectSettings);
  const jwtToken = authScopeStringGetHelper(JWT_TOKEN);
  const { isImpersonating } = useGetIsImpersonating();
  const isClosedAccount = useIsClosedAccount();
  const { displayBasicAccountOptionsPermission } = useCheckPermissions();
  const query = useQuery();
  const { flow } = query;

  const isTheUserAuthenticated = () => {
    const authenticationMethodType = decode(jwtToken)?.AuthenticationMethodType;
    const isRequired2FA = decode(jwtToken)?.isRequired2FA;
    const isShow2Fa = isRequired2FA === 'True' && !authenticationMethodType;

    if (!isImpersonating && IsBroker2FAActive) {
      if (isUserAuthenticated) {
        if (IsBroker2FAActive && jwtToken && !isShow2Fa) {
          return true;
        }
        if (IsBroker2FAActive && jwtToken && isRequired2FA === 'True') {
          if (authenticationMethodType) return true;
          return false;
        }
        if (!jwtToken) {
          dispatch(logoutUser({}));
          return false;
        }
      }
    }

    if (isUserAuthenticated) {
      return true;
    }

    storeInSessionStorage(LANDING_PAGE, props.path);
    return false;
  };

  useEffect(() => {
    if (flow) {
      dispatch(logoutUser());
    }
  });

  useEffect(() => {
    if (!isTheUserAuthenticated()) {
      dispatch(authenticateUser());
    }
  }, [IsBroker2FAActive, isUserAuthenticated]); // eslint-disable-line

  useEffect(() => {
    if (user && user.UserUid) {
      if (currentAccount.AccountUid) {
        dispatch(
          userPermissionsFetch({
            accountUid: currentAccount.AccountUid,
            userUid: user.UserUid,
          }),
        );
      }
    }
  }, [user, currentAccount.AccountUid]); // eslint-disable-line

  if (isTheUserAuthenticated() && isLoading) {
    return <FullPageLoader />;
  }

  if (isClosedAccount) {
    const isNotAllowedPage = () => {
      const notAllowedRoutes = [
        USER_MANAGEMENT_PAGE,
        BANK_ACCOUNTS_PAGE,
        PROFILE_SETTINGS_PAGE,
        ADDRESSES_PAGE,
        BASE_PAGE,
        BUY_PAGE,
        SELL_PAGE,
        METAL_STREAM_PAGE,
        PORTFOLIO_PAGE,
        PORTFOLIO_PRODUCT_PAGE,
      ];

      if (notAllowedRoutes.includes(props.path)) {
        return true;
      }

      return false;
    };

    if (isNotAllowedPage()) {
      return <Redirect to={STATEMENTS_PAGE} />;
    }
  }

  if (displayBasicAccountOptionsPermission) {
    const isNotAllowedPage = () => {
      const notAllowedRoutes = [BASE_PAGE, METAL_STREAM_PAGE];

      if (notAllowedRoutes.includes(props.path)) {
        return true;
      }

      return false;
    };

    if (isNotAllowedPage()) {
      return <Redirect to={PORTFOLIO_PAGE} />;
    }
  }

  return isTheUserAuthenticated() ? (
    <Route {...props} />
  ) : (
    <Redirect to={EMAIL_LOGIN_PAGE} />
  );
};

PrivateRoute.propTypes = {
  path: PropTypes.string,
};

export default PrivateRoute;
