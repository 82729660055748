import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TypeCard from '../../TypeCard/TypeCard';
import { EQUITY_WITHDRAW_WIZARD_TYPES } from '../../../constants/equityWithdrawWizardConstants';
import { setWizardContent } from '../../../util/helpers/wizardHelpers';
import { IRA_DISTRIBUTION, IRA_TRANSFER_OUT } from '../../../util/constants';
import useGtmHook from '../../../util/hooks/useGtmHook';
import TypeList from '../../TypeList/TypeList';
import { FUND_WITHDRAW_START } from '../../../constants/gtmEvents';

const IRAWithdrawType = ({ handleNext, setSelectedType }) => {
  const { t } = useTranslation();
  const { gtmDialogScreenView, fundGtmEvent } = useGtmHook();

  return (
    <TypeList>
      <TypeCard
        title={t('IRAWithdraw.iraDistribution')}
        description={t('IRAWithdraw.iraDistributionDescription')}
        onClick={() => {
          setWizardContent(
            'SelectType',
            EQUITY_WITHDRAW_WIZARD_TYPES.DISTRIBUTION,
          );
          setSelectedType(
            EQUITY_WITHDRAW_WIZARD_TYPES.DISTRIBUTION,
            handleNext,
          );
          gtmDialogScreenView({
            title: `Prefunded Withdraw Funds - ${IRA_DISTRIBUTION}`,
          });
          fundGtmEvent(FUND_WITHDRAW_START, {
            fund: {
              method: IRA_DISTRIBUTION,
            },
          });
        }}
      />
      <TypeCard
        title={t('IRAWithdraw.iraTransferOut')}
        description={t('IRAWithdraw.iraTransferOutDescription')}
        onClick={() => {
          setWizardContent('SelectType', EQUITY_WITHDRAW_WIZARD_TYPES.TRANSFER);
          setSelectedType(EQUITY_WITHDRAW_WIZARD_TYPES.TRANSFER, handleNext);
          gtmDialogScreenView({
            title: `Prefunded Withdraw Funds - ${IRA_TRANSFER_OUT}`,
          });
          fundGtmEvent(FUND_WITHDRAW_START, {
            fund: {
              method: IRA_TRANSFER_OUT,
            },
          });
        }}
      />
    </TypeList>
  );
};

IRAWithdrawType.propTypes = {
  handleNext: PropTypes.func,
  setSelectedType: PropTypes.func,
};

export default IRAWithdrawType;
