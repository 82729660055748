export function storeInSessionStorage(key, value) {
  const parseValue = JSON.stringify(value);
  sessionStorage.setItem(key, parseValue);
}

export function retrieveFromSessionStorage(key) {
  const result = JSON.parse(sessionStorage.getItem(key));
  return result;
}

export function removeFromSessionStorage(key) {
  sessionStorage.removeItem(key);
}
