import React, { useEffect, useState } from 'react';
import {
  compareAsc,
  isAfter,
  isBefore,
  isEqual,
  sub as dateSub,
} from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import isEmpty from 'lodash.isempty';
import styled from 'styled-components';
import Balances from '../../components/Portfolio/Balance/Balances';
import Holdings from '../../components/Portfolio/Holdings/Holdings';
import RecentTransactions from '../../components/Portfolio/RecentTransactions/RecentTransactions';
import {
  REGISTRATION_USER_UID,
  CHOSEN_VAULT,
  USER_LOGGED_IN,
} from '../../constants/sessionStorage';

import {
  fetchValuation,
  fetchMarketPrices,
  accountTransactionsFetch,
} from '../../store/actions/portfolio/portfolioActions';
import {
  getDataSeries,
  getMaximumPeriodType,
  getPortfolioValuation,
  getPortfolioValuationTimestamp,
  getMarketPricesTimestamp,
  getPortfolioChartError,
} from '../../store/selectors/portfolioValuationSelectors';
import {
  getCurrentAccount,
  getCurrentAccountUid,
  getCurrentAccountCreatedDate,
  selectNagDashboard,
  selectAccountAddresses,
} from '../../store/selectors/accountSelectors';

import {
  period,
  PERIOD,
  periodsData,
  periodToDateDurationMapper,
} from '../../constants/period';
import useSelectScenarioMock from '../../util/hooks/useSelectScenarioMock';

import Section from '../../components/Section/Section';
import {
  selectPortfolioTransactions,
  selectPortfolioTransactionsError,
} from '../../store/selectors/portfolioTransactionsSelector';
import {
  ACCOUNT_TRANSACTIONS_FETCH_LOADING,
  PORTFOLIO_VALUATION_LOADING,
  PORTFOLIO_MARKET_PRICES_LOADING,
} from '../../store/actions/portfolio/portfolioActionConstants';
import {
  selectIsLoadingByActionType,
  selectIsLoadingByActionTypes,
} from '../../store/selectors/loadingSelectors';
import useGtmHook from '../../util/hooks/useGtmHook';
import {
  closeWizardContent,
  getWizardContent,
} from '../../util/helpers/wizardHelpers';
import { ACCOUNT_DATA_LOADED, USER_LOGIN } from '../../constants/gtmEvents';
import RestrictionBanner from '../../components/RestrictionBanner/RestrictionBanner';
import { PORTFOLIO_PAGE } from '../../constants/pages';
import ImpersonateBanner from '../../components/ImpersonateBanner/ImpersonateBanner';
import PortfolioChart from '../../components/Portfolio/PortfolioChart/PortfolioChart';
import { RECENT_TRANSACTIONS } from '../../constants/accountTransactionConstants';
import {
  retrieveFromSessionStorage,
  storeInSessionStorage,
} from '../../util/helpers/sessionStorageHelper';
import {
  mediaBelow,
  mediaUp,
  pxToRem,
  pxToRemMd,
} from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import NagDashboardBanner from '../../components/NagDashboard/NagDashboardBanner';
import useWindowSize from '../../util/hooks/useIsMobileHook';
import {
  fetchAccountAddresses,
  fetchNagDashboard,
} from '../../store/actions/account/accountActions';
import { selectAuthUser } from '../../store/selectors/userSelectors';
import { fetchUserPhoneNumbers } from '../../store/actions/user/userActions';

const types = [
  'Portfolio Valuation',
  'Gold',
  'Silver',
  'Platinum',
  'Palladium',
];

const PortfolioBody = styled(Section)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${pxToRem(40)};

  &:not(:last-child) {
    margin-bottom: ${pxToRem(60)};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    margin-bottom: ${pxToRemMd(48)};
  }
`;

const DashboardAndChartWrapper = styled.div`
  ${mediaUp(variables.breakpoints.bpXl)} {
    ${({ showDashboardOnPortfolio }) =>
      showDashboardOnPortfolio &&
      `display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: ${pxToRem(40)};
      padding: 0 ${pxToRem(52)};
    `};
  }
`;

const PortfolioPage = () => {
  const history = useHistory();
  const { selectedScenario } = useSelectScenarioMock(0);
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedPeriod, setSelectedPeriod] = useState(PERIOD.ThreeMonths);
  const [periods, setPeriods] = useState(period);
  const [selectedType, setSelectedType] = useState(types[0]);
  const [showDashboardOnPortfolio, setShowDashboardOnPortfolio] = useState(
    false,
  );
  const dataSeries = useSelector(getDataSeries);
  const [series, setSeries] = useState(dataSeries);
  const [domain, setDomain] = useState({ min: 0, max: 0 });
  const maximumPeriodType = useSelector(getMaximumPeriodType);
  const portfolioValuation = useSelector(getPortfolioValuation);
  const portfolioValuationTimestamp = useSelector(
    getPortfolioValuationTimestamp,
  );
  const marketPricesTimestamp = useSelector(getMarketPricesTimestamp);
  const accountUid = useSelector(getCurrentAccountUid);
  const accountCreatedDate = useSelector(getCurrentAccountCreatedDate);
  const account = useSelector(getCurrentAccount);
  const userInfo = useSelector(selectAuthUser);
  const transactions = useSelector(selectPortfolioTransactions);
  const transactionsError = useSelector(selectPortfolioTransactionsError);
  const transactionsLoading = useSelector(
    selectIsLoadingByActionType(ACCOUNT_TRANSACTIONS_FETCH_LOADING),
  );
  const valuationLoading = useSelector(
    selectIsLoadingByActionTypes([
      PORTFOLIO_VALUATION_LOADING,
      PORTFOLIO_MARKET_PRICES_LOADING,
    ]),
  );
  const nagDashboard = useSelector(selectNagDashboard);
  const addresses = useSelector(selectAccountAddresses);
  const registrationAccountUid = retrieveFromSessionStorage(
    REGISTRATION_USER_UID,
  );
  const [change, setChange] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [currentPrice, setCurrentPrice] = useState(0);
  const { gtmScreenView, appGtmEvent, appLoginGtmEvent } = useGtmHook();
  const chartError = useSelector(getPortfolioChartError);
  const windowSize = useWindowSize();
  const areBiggerScreens = windowSize.width > 1199;
  useEffect(() => {
    if (account?.AccountUid) {
      dispatch(fetchAccountAddresses({ accountUid: account?.AccountUid }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);
  useEffect(() => {
    gtmScreenView({
      path: window.location.pathname,
      title: 'Portfolio',
    });

    if (!retrieveFromSessionStorage(USER_LOGGED_IN)) {
      appLoginGtmEvent(USER_LOGIN);

      storeInSessionStorage(USER_LOGGED_IN, true);
    }

    if (getWizardContent(CHOSEN_VAULT)) {
      closeWizardContent();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!isEmpty(userInfo) && userInfo && account?.AccountUid) {
      dispatch(fetchUserPhoneNumbers({ userUid: userInfo.UserUid }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, account?.AccountUid]);

  useEffect(() => {
    if (account?.AccountUid) {
      dispatch(fetchAccountAddresses({ accountUid: account?.AccountUid }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account?.AccountUid]);

  useEffect(() => {
    dispatch(fetchNagDashboard({ accountUid: account.AccountUid }));
  }, [account]); // eslint-disable-line

  useEffect(() => {
    if (
      retrieveFromSessionStorage(USER_LOGGED_IN) &&
      account &&
      dispatch &&
      nagDashboard.DepositStatusType &&
      !isEmpty(addresses)
    ) {
      appGtmEvent(ACCOUNT_DATA_LOADED);
    }
  }, [account, nagDashboard.DepositStatusType, addresses, dispatch]); // eslint-disable-line

  useEffect(() => {
    if (!isEmpty(location?.state)) {
      history.replace(PORTFOLIO_PAGE);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!isEmpty(registrationAccountUid)) {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = () => {
        window.history.go(1);
      };
    }
  }, [registrationAccountUid]);

  // fetch portfolio valuation and market prices on change selectedType
  useEffect(() => {
    if (account) {
      setSelectedType('Portfolio Valuation');
      dispatch(
        fetchValuation({
          accountUid: account.AccountUid,
          periodType: PERIOD.All,
          accountCreatedDate: new Date(accountCreatedDate),
        }),
      );
      setSelectedPeriod(PERIOD.ThreeMonths);
    }
  }, [account]); // eslint-disable-line

  useEffect(() => {
    const index = period.indexOf(selectedPeriod);
    const p = period.slice(index, index.length);

    if (!p.includes(PERIOD.All)) {
      p.unshift(PERIOD.All);
    }
    if (selectedType === 'Portfolio Valuation') {
      setPeriods(period.slice(0, 6));
    } else {
      setPeriods(period);
    }
  }, [period, selectedType]); // eslint-disable-line

  useEffect(() => {
    if (account) {
      dispatch(
        accountTransactionsFetch({
          accountUid: account.AccountUid,
          content: RECENT_TRANSACTIONS,
        }),
      );
    }
  }, [account.AccountUid, accountUid, dispatch]); // eslint-disable-line

  // filter valuations and market prices from ALL on change period
  useEffect(() => {
    if (!dataSeries || isEmpty(dataSeries)) {
      return;
    }
    if (
      selectedType === 'Portfolio Valuation' &&
      selectedPeriod !== PERIOD.OneHour &&
      selectedPeriod !== PERIOD.OneDay
    ) {
      const series = dataSeries
        .filter((seriesValue) => {
          const seriesDate = new Date(seriesValue.Date);
          const selectedDate = new Date();
          return (
            isBefore(seriesDate, selectedDate) ||
            isEqual(seriesDate, selectedDate)
          );
        })
        .sort((a, b) => compareAsc(new Date(a.Date), new Date(b.Date)));
      if (selectedPeriod === PERIOD.All) {
        return setSeries(series);
      }
      const [dur, per] = selectedPeriod.split('');
      const [, duration] = Object.entries(
        periodToDateDurationMapper,
      ).find(([period]) => period.includes(per));
      const pastDate = dateSub(new Date(), {
        [duration]: dur,
      });
      const newSeries = series.filter(
        (s) =>
          isAfter(new Date(s.Date), pastDate) ||
          isEqual(new Date(s.Date), pastDate),
      );
      return setSeries(newSeries);
    }

    const newSeries = dataSeries.sort((a, b) =>
      compareAsc(new Date(a.Date), new Date(b.Date)),
    );
    return setSeries(newSeries);
  }, [dataSeries, selectedScenario, selectedType, selectedPeriod]); // eslint-disable-line

  // set current price, change and procentage on change selectedType
  useEffect(() => {
    if (!series || isEmpty(series)) {
      return;
    }
    const serieValues = series.map((s) => s.Value);
    const min = Math.min(...serieValues);
    const max = Math.max(...serieValues);
    setDomain({ min, max });
    if (selectedType !== 'Portfolio Valuation') {
      const { length, 0: first, [length - 1]: last } = series;
      setCurrentPrice(last?.Value);
      if (series.length > 0) {
        if (first?.Value === 0) {
          setChange(last?.Value);
          setPercentage(100);
        } else if (last?.Value === 0) {
          setChange(first?.Value);
          setPercentage(-100);
        } else {
          setChange(last?.Value - first?.Value);
          setPercentage(((last?.Value - first?.Value) / first?.Value) * 100);
        }
      }
    }
  }, [series]); // eslint-disable-line

  if (!series) {
    return null;
  }

  const getPeriod = (p) => () => {
    setSelectedPeriod(p);
  };
  const selectType = (type) => {
    if (account) {
      if (type !== 'Portfolio Valuation') {
        setSelectedPeriod(PERIOD.OneDay);
        setSelectedType(type);
        dispatch(
          fetchMarketPrices({
            metalType: type,
            periodType: periodsData[PERIOD.OneDay],
          }),
        );
      } else {
        setSelectedType(type);
        setSelectedPeriod(PERIOD.ThreeMonths);
        dispatch(
          fetchValuation({
            accountUid: account.AccountUid,
            periodType: PERIOD.All,
            accountCreatedDate: new Date(accountCreatedDate),
          }),
        );
      }
    }
  };
  const selectPeriod = (period) => {
    if (account) {
      if (selectedType !== 'Portfolio Valuation') {
        dispatch(
          fetchMarketPrices({
            metalType: selectedType,
            periodType: periodsData[period],
          }),
        );
      }
      setSelectedPeriod(period);
    }
  };

  return (
    <div data-cy="container-portfolio-page">
      <ImpersonateBanner />
      <RestrictionBanner />
      <DashboardAndChartWrapper
        showDashboardOnPortfolio={showDashboardOnPortfolio}
      >
        <NagDashboardBanner
          setShowDashboardOnPortfolio={setShowDashboardOnPortfolio}
        />
        {((areBiggerScreens && showDashboardOnPortfolio) ||
          !showDashboardOnPortfolio) && (
          <PortfolioChart
            timestamp={
              selectedType === 'Portfolio Valuation'
                ? portfolioValuationTimestamp
                : marketPricesTimestamp
            }
            portfolioValuation={portfolioValuation}
            account={account}
            currentPrice={currentPrice}
            types={types}
            selectedType={selectedType}
            setSelectedType={selectType}
            change={change}
            percentage={percentage}
            data={series}
            period={periods}
            getPeriod={getPeriod}
            domain={domain}
            selectedPeriod={selectedPeriod}
            setSelectedPeriod={selectPeriod}
            maximumPeriodType={maximumPeriodType}
            isLoading={valuationLoading}
            chartError={chartError}
            showDashboardOnPortfolio={showDashboardOnPortfolio}
          />
        )}
      </DashboardAndChartWrapper>
      <PortfolioBody>
        <div>
          <Balances />
          <Holdings />
        </div>
        <RecentTransactions
          data={transactions}
          error={transactionsError}
          accountUid={account.AccountUid}
          isLoading={transactionsLoading}
        />
      </PortfolioBody>
    </div>
  );
};

export default PortfolioPage;
