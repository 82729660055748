import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { buttonizeDiv } from '../../util/buttonizeDiv';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';
import { pxToRem } from '../../assets/styles/helper';

const StatusTooltip = styled.div`
  position: relative;
`;

const StatusTooltipButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
`;

const StatusTooltipFullWidthButton = styled(StatusTooltipButton)`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: none;
  width: 100%;
  height: 100%;
  border-radius: none;
`;

const StatusTooltipText = styled.span`
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  padding: 4px;
  border-radius: 4px;
  background-color: ${themeColors.colorTooltipBackground};
  color: ${themeColors.colorTooltipText};
  font-weight: 600;
  margin-right: ${pxToRem(4)};
  width: ${pxToRem(100)};
  text-align: center;
  z-index: ${variables.zIndex.indexXxs};
`;

const StatusTooltipFullWidthText = styled(StatusTooltipText)`
  left: 50%;
  width: max-content;
`;

const Tooltip = ({ children, fullWidth, text }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClickOutside = (event) => {
    if (
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target) &&
      event.target !== tooltipRef.current &&
      !tooltipRef.current.contains(event.target)
    ) {
      setShowTooltip(false);
    }
  };

  return (
    <StatusTooltip ref={tooltipRef}>
      {fullWidth ? (
        <StatusTooltipFullWidthButton
          {...buttonizeDiv((event) => {
            event.stopPropagation();
            setShowTooltip(!showTooltip);
          })}
        />
      ) : (
        <StatusTooltipButton
          {...buttonizeDiv((event) => {
            event.stopPropagation();
            setShowTooltip(!showTooltip);
          })}
        />
      )}
      {showTooltip &&
        (fullWidth ? (
          <StatusTooltipFullWidthText>{text}</StatusTooltipFullWidthText>
        ) : (
          <StatusTooltipText>{text}</StatusTooltipText>
        ))}
      {children}
    </StatusTooltip>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default Tooltip;
