import {
  createClearType,
  createErrorType,
  createFetchType,
  createLoadingType,
  createSetType,
  createSuccessType,
  createUpdateType,
} from '../actionHelpers';

// valuation
export const PORTFOLIO_VALUATION_FETCH = createFetchType('PORTFOLIO_VALUATION');
export const PORTFOLIO_VALUATION_SUCCESS = createSuccessType(
  'PORTFOLIO_VALUATION',
);
export const PORTFOLIO_VALUATION_ERROR = createErrorType('PORTFOLIO_VALUATION');
export const PORTFOLIO_VALUATION_LOADING = createLoadingType(
  'PORTFOLIO_VALUATION',
);
// market prices
export const PORTFOLIO_MARKET_PRICES_FETCH = createFetchType('MARKET_PRICES');
export const PORTFOLIO_MARKET_PRICES_SUCCESS = createSuccessType(
  'MARKET_PRICES',
);
export const PORTFOLIO_MARKET_PRICES_ERROR = createErrorType('MARKET_PRICES');
export const PORTFOLIO_MARKET_PRICES_LOADING = createLoadingType(
  'MARKET_PRICES',
);

export const MAXIMUM_PERIOD_TYPE_SET = createSetType('MAXIMUM_PERIOD_TYPE');

// holdings
const PORTFOLIO_HOLDINGS_SCOPE = 'PORTFOLIO_HOLDINGS';

export const PORTFOLIO_HOLDINGS_FETCH = createFetchType(
  PORTFOLIO_HOLDINGS_SCOPE,
);
export const PORTFOLIO_HOLDINGS_FETCH_SUCCESS = createSuccessType(
  PORTFOLIO_HOLDINGS_SCOPE,
);
export const PORTFOLIO_HOLDINGS_FETCH_ERROR = createErrorType(
  PORTFOLIO_HOLDINGS_SCOPE,
);
export const PORTFOLIO_HOLDINGS_LOADING = createLoadingType(
  PORTFOLIO_HOLDINGS_SCOPE,
);

// my holdings codes
const PORTFOLIO_MY_HOLDINGS_CODES_SCOPE = 'PORTFOLIO_MY_HOLDINGS_CODES';

export const MY_HOLDINGS_CODES_FETCH = createFetchType(
  PORTFOLIO_MY_HOLDINGS_CODES_SCOPE,
);
export const MY_HOLDINGS_CODES_SUCCESS = createSuccessType(
  PORTFOLIO_MY_HOLDINGS_CODES_SCOPE,
);
export const MY_HOLDINGS_CODES_ERROR = createErrorType(
  PORTFOLIO_MY_HOLDINGS_CODES_SCOPE,
);
export const RESET_MY_HOLDINGS_ACTION = createSetType(
  PORTFOLIO_MY_HOLDINGS_CODES_SCOPE,
);

// balances
const PORTFOLIO_BALANCES_SCOPE = 'PORTFOLIO_BALANCES_SCOPE';

export const PORTFOLIO_BALANCES_FETCH = createFetchType(
  PORTFOLIO_BALANCES_SCOPE,
);
export const PORTFOLIO_BALANCES_SUCCESS = createSuccessType(
  PORTFOLIO_BALANCES_SCOPE,
);
export const PORTFOLIO_BALANCES_ERROR = createErrorType(
  PORTFOLIO_BALANCES_SCOPE,
);
export const PORTFOLIO_BALANCES_LOADING = createLoadingType(
  PORTFOLIO_BALANCES_SCOPE,
);

// product by symbol
const PORTFOLIO_PRODUCT_BY_SYMBOL_SCOPE = 'PORTFOLIO_PRODUCT_BY_SYMBOL_SCOPE';

export const PORTFOLIO_PRODUCT_BY_SYMBOL_FETCH = createFetchType(
  PORTFOLIO_PRODUCT_BY_SYMBOL_SCOPE,
);
export const PORTFOLIO_PRODUCT_BY_SYMBOL_FETCH_SUCCESS = createSuccessType(
  PORTFOLIO_PRODUCT_BY_SYMBOL_SCOPE,
);
export const PORTFOLIO_PRODUCT_BY_SYMBOL_FETCH_ERROR = createErrorType(
  PORTFOLIO_PRODUCT_BY_SYMBOL_SCOPE,
);
export const PORTFOLIO_PRODUCT_BY_SYMBOL_FETCH_LOADING = createLoadingType(
  PORTFOLIO_PRODUCT_BY_SYMBOL_SCOPE,
);
export const PORTFOLIO_PRODUCT_BY_SYMBOL_CLEAR = createClearType(
  PORTFOLIO_PRODUCT_BY_SYMBOL_SCOPE,
);

// transactions
const PORTFOLIO_TRANSACTIONS_SCOPE = 'PORTFOLIO_TRANSACTIONS';
const PORTFOLIO_TRANSACTIONS_SINGLE_SCOPE = `${PORTFOLIO_TRANSACTIONS_SCOPE}_SINGLE`;
export const PORTFOLIO_TRANSACTIONS_SINGLE_FETCH = createFetchType(
  PORTFOLIO_TRANSACTIONS_SINGLE_SCOPE,
);
export const PORTFOLIO_TRANSACTIONS_SINGLE_SUCCESS = createSuccessType(
  PORTFOLIO_TRANSACTIONS_SINGLE_SCOPE,
);
export const PORTFOLIO_TRANSACTIONS_SINGLE_ERROR = createErrorType(
  PORTFOLIO_TRANSACTIONS_SINGLE_SCOPE,
);
export const PORTFOLIO_TRANSACTIONS_SINGLE_LOADING = createLoadingType(
  PORTFOLIO_TRANSACTIONS_SINGLE_SCOPE,
);
export const EMPTY_PORTFOLIO_TRANSACTION_SINGLE = createUpdateType(
  PORTFOLIO_TRANSACTIONS_SINGLE_SCOPE,
);

// account transactions
const ACCOUNT_TRANSACTIONS_SCOPE = 'ACCOUNT_TRANSACTIONS';

export const ACCOUNT_TRANSACTIONS_FETCH = createFetchType(
  ACCOUNT_TRANSACTIONS_SCOPE,
);
export const ACCOUNT_TRANSACTIONS_FETCH_LOADING = createLoadingType(
  ACCOUNT_TRANSACTIONS_SCOPE,
);
export const ACCOUNT_TRANSACTIONS_SUCCESS = createSuccessType(
  ACCOUNT_TRANSACTIONS_SCOPE,
);
export const ACCOUNT_TRANSACTIONS_ERROR = createErrorType(
  ACCOUNT_TRANSACTIONS_SCOPE,
);

const PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_SCOPE =
  'PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS';

export const PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS = createFetchType(
  PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_SCOPE,
);
export const PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_LOADING = createLoadingType(
  PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_SCOPE,
);
export const PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_SUCCESS = createSuccessType(
  PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_SCOPE,
);
export const PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_ERROR = createErrorType(
  PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_SCOPE,
);

const PATCH_PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_SCOPE =
  'PATCH_PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS';

export const PATCH_PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS = createUpdateType(
  PATCH_PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_SCOPE,
);
export const PATCH_PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_LOADING = createLoadingType(
  PATCH_PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_SCOPE,
);
export const PATCH_PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_ERROR = createErrorType(
  PATCH_PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_SCOPE,
);

export const CLEAR_CHART_ERROR = createClearType('CHART_ERROR');
