import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import StepByStep from '../../../StepByStep';
import CheckInformationForm from './Check/CheckInformationForm';
import { fetchAccountAddresses } from '../../../../store/actions/account/accountActions';
import { selectAccountAddresses } from '../../../../store/selectors/accountSelectors';
import { selectBalancesAvailable } from '../../../../store/selectors/portfolioBalancesSelectors';
import CheckInformationReview from './Check/CheckInformationReview';
import { iraFeeWithdrawalSubmit } from '../../../../store/actions/orders/orderActions';
import {
  IRA_FEE_WITHDRAWAL,
  TYPE_IRA_FEE_CHECK_WITHDRAWAL,
} from '../../../../util/constants';
import Status from '../../Status';
import {
  closeWizardContent,
  setWizardContent,
} from '../../../../util/helpers/wizardHelpers';
import {
  PREFUNDED_CHECK_WITHDRAWAL_WIZARD,
  STATUS_MESSAGE,
  STATUS_MODAL,
  WITHDRAWAL_VALUE,
} from '../../../../constants/sessionStorage';
import {
  WITHDRAWAL_PREFUNDED_WIZARD_TYPES,
  WITHDRAWAL_WIZARD_TYPES,
} from '../../../../constants/standardWithdrawalWizardConstants';
import { useSessionStorageState } from '../../../../util/hooks/useSessionStorageState';
import { formatAddressString } from '../../../../util/helpers/addressHelper';
import { FUND_WITHDRAW_COMPLETE } from '../../../../constants/gtmEvents';
import useGtmHook from '../../../../util/hooks/useGtmHook';

const PrefundedCheckWithdrawalWizard = ({
  accountUid,
  closeModal,
  handleRefreshTransactions,
  goBack,
  type,
  setWithdrawalType,
  setTitleType,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const addresses = useSelector(selectAccountAddresses);
  const balances = useSelector(selectBalancesAvailable);
  const { fundGtmEvent } = useGtmHook();

  const [addressOptions, setAddressOptions] = useState([]);
  const [availableMoney, setAvailableMoney] = useState(0);
  const [value, setValue] = useSessionStorageState(WITHDRAWAL_VALUE, {
    address: { value: '', label: t('common.select') },
    amount: 0,
  });
  useEffect(() => {
    if (addresses) {
      const defaultAddress = addresses?.find(
        (address) => address.IsDefaultMailingAddress,
      );
      setValue({
        address: {
          value: defaultAddress?.AddressUid,
          label: formatAddressString(defaultAddress),
        },
        amount: 0,
      });
    }
  }, [addresses, setValue]);
  const [status, setStatus] = useSessionStorageState(STATUS_MODAL, null);
  const [statusMessage, setStatusMessage] = useSessionStorageState(
    STATUS_MESSAGE,
    null,
  );
  const [isLoading, setIsLoading] = useState(false);

  const formatAddressLabel = (address) => formatAddressString(address);

  const setWithdrawValue = (value) => {
    setWizardContent(WITHDRAWAL_VALUE, value);
    setValue(value);
  };

  const setWithdrawStatus = (value) => {
    setWizardContent(STATUS_MODAL, value);
    setStatus(value);
  };

  const setWithdrawStatusMessage = (value) => {
    setWizardContent(STATUS_MESSAGE, value);
    setStatusMessage(value);
  };

  const closeStatusModal = () => {
    closeModal();
    closeWizardContent();
  };

  useEffect(() => {
    if (accountUid) {
      dispatch(fetchAccountAddresses({ accountUid }));
    }
  }, [accountUid, dispatch]);

  useEffect(() => {
    const addressOptions = addresses.map((address) => ({
      value: address?.AddressUid,
      label: formatAddressLabel(address),
    }));

    setAddressOptions(addressOptions);
  }, [addresses]);

  useEffect(() => {
    const availableMoney = balances?.AvailablePrefundedStorageFees;
    setAvailableMoney(availableMoney);
  }, [balances]);

  useEffect(() => {
    setTitleType(WITHDRAWAL_PREFUNDED_WIZARD_TYPES.CHECK);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmWithdraw = (goStepForward) => {
    setIsLoading(true);
    const onRequestSuccess = () => {
      handleRefreshTransactions();
      fundGtmEvent(FUND_WITHDRAW_COMPLETE, {
        fund: {
          method: WITHDRAWAL_WIZARD_TYPES.CHECK,
          amount: value.amount,
        },
      });
    };
    const handleResponse = (hasError, message) => {
      setWithdrawStatus(hasError);
      setWithdrawStatusMessage(message);
      goStepForward();
      setIsLoading(false);
    };

    if (!isEmpty(value)) {
      const data = {
        Amount: value.amount,
        AddressUid: value.address.value,
        IRAFeeWithdrawalType: TYPE_IRA_FEE_CHECK_WITHDRAWAL,
        AccountUid: accountUid,
        Type: IRA_FEE_WITHDRAWAL,
      };
      dispatch(
        iraFeeWithdrawalSubmit({
          accountUid,
          data,
          handleResponse,
          onRequestSuccess,
        }),
      );
    }
  };

  const steps = [CheckInformationForm, CheckInformationReview, Status];

  return (
    <StepByStep sessionScopeStep={PREFUNDED_CHECK_WITHDRAWAL_WIZARD}>
      {({ goStepForward, goStepBack }) =>
        steps.map((buyStorageStep) =>
          React.createElement(buyStorageStep, {
            handleBack: goStepBack,
            handleNext: goStepForward,
            handleClose: closeModal,
            goBack,
            addressOptions,
            availableMoney,
            setValue: setWithdrawValue,
            value,
            confirmWithdraw,
            type,
            setWithdrawalType,
            isLoading,
            // These ones are for the Status Modal
            hasError: status,
            onButtonClick: closeStatusModal,
            text: statusMessage,
            backButtonText: 'Close',
          }),
        )
      }
    </StepByStep>
  );
};

PrefundedCheckWithdrawalWizard.propTypes = {
  closeModal: PropTypes.func,
  handleRefreshTransactions: PropTypes.func,
  accountUid: PropTypes.string,
  goBack: PropTypes.func,
  type: PropTypes.string,
  setWithdrawalType: PropTypes.func,
  setTitleType: PropTypes.func,
};

export default PrefundedCheckWithdrawalWizard;
