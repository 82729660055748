import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Modal from '../../Modal/Modal';
import { selectSettings } from '../../../store/selectors/settingsSelectors';
import ModalBody from '../../Modal/ModalBody';
import Paragraph from '../../Paragraph/Paragraph';
import Anchor from '../../Anchor/Anchor';
import ModalButtons from '../../Modal/ModalButtons';

const Information = ({
  isOpen,
  handleClose,
  handleBack,
  openSupport,
  type,
}) => {
  const { t } = useTranslation();
  const brokerSettings = useSelector(selectSettings);

  return (
    <Modal
      title={
        type === 'salesTax'
          ? t(`product.requestDelivery.information.salesTax.title`)
          : t(`product.requestDelivery.information.storageFees.title`)
      }
      isOpen={isOpen}
      size="sm"
      close={handleClose}
      overModal
    >
      <ModalBody>
        <Paragraph marginBottom={24}>
          {type === 'salesTax'
            ? t(`product.requestDelivery.information.salesTax.charge`)
            : t(`product.requestDelivery.information.storageFees.storageFees`)}
        </Paragraph>

        <Paragraph>
          {t(`product.requestDelivery.information.contactCustomerService`)}{' '}
          <Anchor
            text={brokerSettings.SupportPhoneTollFree}
            type="telephone"
            value={brokerSettings.SupportPhoneTollFree}
          />
          .
        </Paragraph>
        <ModalButtons
          isVertical
          marginTop
          secondaryButtonProps={{ onClick: handleBack }}
          primaryButtonProps={{
            label: t(
              `product.requestDelivery.information.contactCustomerSupport`,
            ),
            onClick: openSupport,
          }}
        />
      </ModalBody>
    </Modal>
  );
};

Information.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleBack: PropTypes.func,
  openSupport: PropTypes.func,
  type: PropTypes.string,
};

export default Information;
