import {
  ACC_TYPE_INACTIVE,
  ACC_TYPE_INVITED,
  userPermissions,
  userStateType,
} from '../enum/api/accountTypes';
import { parseEnumType } from './enumMappers';
import { joinArrayWithComma, separateByUnderscore } from './stringHelpers';
import i18next from '../../i18n';
import { USERNAMES } from '../../constants/sessionStorage';
import { retrieveFromSessionStorage } from './sessionStorageHelper';

export const formatUsersTableRowData = (
  users,
  setUserToRemove,
  editPermission,
  resendInvite,
  activateAccount,
  currentUser,
  isRemoveButtonAllowedForCurrentUser,
  isImpersonating,
  allowAccountChangesPermission,
  displayBasicAccountOptionsPermission,
) =>
  users.map((user) => {
    const activateAccountAction = () => {
      activateAccount(user);
    };
    const editPermissions = () => {
      editPermission(user);
    };
    const removeAccount = () => {
      setUserToRemove(user.UserUid);
    };

    const resendInviteAction = () => {
      resendInvite(user);
    };

    let actions = [
      {
        id: `${user.UserUid}-edit-action`,
        label: i18next.t('userManagement.actionBtns.editPermissions'),
        action: editPermissions,
        variant: 'primaryOutlined',
        size: 'sm',
        textTransform: 'capitalize',
        disabled:
          !allowAccountChangesPermission ||
          displayBasicAccountOptionsPermission,
      },
      {
        id: `${user.UserUid}-remove-action`,
        label: i18next.t('common.remove'),
        action: removeAccount,
        variant: 'primaryClear',
        size: 'sm',
        textTransform: 'capitalize',
        minWidth: 'auto',
        disabled:
          !allowAccountChangesPermission ||
          displayBasicAccountOptionsPermission,
        hidden:
          currentUser?.UserUid === user?.UserUid &&
          isRemoveButtonAllowedForCurrentUser,
      },
    ];

    if (
      parseEnumType(userStateType, user.StateType) === ACC_TYPE_INACTIVE &&
      isImpersonating
    ) {
      actions = [
        {
          id: `${user.UserUid}-activate-action`,
          label: i18next.t('userManagement.activate.title'),
          action: activateAccountAction,
          variant: 'primary',
          size: 'sm',
          textTransform: 'capitalize',
          disabled:
            !allowAccountChangesPermission ||
            displayBasicAccountOptionsPermission,
        },

        ...actions,
      ];
    }
    if (
      parseEnumType(userStateType, user.StateType) === ACC_TYPE_INVITED &&
      user.RoleType !== 1
    ) {
      actions = [
        {
          id: `${user.UserUid}-invite-action`,
          label: i18next.t('userManagement.resendInvite.title'),
          action: resendInviteAction,
          variant: 'primary',
          size: 'sm',
          textTransform: 'capitalize',
          disabled:
            !allowAccountChangesPermission ||
            displayBasicAccountOptionsPermission,
        },
        ...actions,
      ];
    }
    return {
      id: user.UserUid,
      user,
      content: user.content,
      actions,
    };
  });

export const formatUsersTableHeaderData = () => [
  { id: 1, content: i18next.t('userManagement.tableHeader.users') },
  { id: 2, content: i18next.t('userManagement.tableHeader.email') },
  { id: 3, content: i18next.t('userManagement.tableHeader.permissions') },
  { id: 4, content: i18next.t('userManagement.tableHeader.status') },
  { id: 5, content: '' },
];

export function formatUserPermissions(data) {
  const permissionsEnumerated = data.Permissions.map(
    (perm) => userPermissions[perm],
  );
  return separateByUnderscore(joinArrayWithComma(permissionsEnumerated));
}

export function formatUsernamesSelect(usernames) {
  return usernames.map((username) => ({ label: username, value: username }));
}

export function getUsernames() {
  const usernames = retrieveFromSessionStorage(USERNAMES);
  const usernameOptions = usernames
    ? usernames.map((username) => ({
        label: username,
        value: username,
        dataCy: username,
      }))
    : [];

  return usernameOptions;
}
