import i18next from 'i18next';
import * as Yup from 'yup';

export const userLoginValidationSchema = Yup.object().shape({
  username: Yup.object().shape({
    value: Yup.string().required(
      i18next.t('validationSchema.general.usernameRequired'),
    ),
  }),
  password: Yup.string().required(
    i18next.t('validationSchema.general.passwordRequired'),
  ),
});
