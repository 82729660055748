import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import themeColors from '../../assets/styles/themeColors';

const RegisterStepTitle = styled.h1`
  font-size: ${pxToRem(36)};
  line-height: 1.2;
  font-weight: bold;
  margin-bottom: ${pxToRem(16)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(24)};
    line-height: 1.33;
    margin-bottom: ${pxToRemMd(16)};
  }
`;

const RegisterStepSubtitle = styled.h3`
  font-size: ${pxToRem(20)};
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: ${pxToRem(44)};
  color: ${themeColors.colorTextSecondary};
  max-width: ${pxToRem(720)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(16)};
    line-height: 1.5;
    margin-bottom: ${pxToRemMd(40)};
  }
`;

const RegisterStep = ({ title, subtitle, children }) => (
  <>
    <RegisterStepTitle>{title}</RegisterStepTitle>
    <RegisterStepSubtitle>{subtitle}</RegisterStepSubtitle>
    {children}
  </>
);

RegisterStep.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
};

export default RegisterStep;
