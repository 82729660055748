import React from 'react';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { uuid } from 'uuidv4';
import ReviewBar from '../../ReviewBar/ReviewBar';
import SectionLoader from '../../Loader/SectionLoader';
import ModalBody from '../../Modal/ModalBody';
import InformationList from '../../InformationList/InformationList';
import InformationListItem from '../../InformationList/InformationListItem';
import ModalButtons from '../../Modal/ModalButtons';

const AchAccountReview = ({ newAchData, goStepBack, onConfirm, isLoading }) => {
  const { t } = useTranslation();

  if (isEmpty(newAchData)) {
    return null;
  }

  const reviewData = [
    {
      label: t('achDeposit.accountType'),
      value: newAchData.accountType.label,
    },
    {
      label: t('achDeposit.routingNumber'),
      value: newAchData.routingNumber,
    },
    {
      label: t('achDeposit.accountNumber'),
      value: newAchData.accountNumber,
    },
    {
      label: t('achDeposit.nameOnAccount'),
      value: newAchData.nameOnAccount,
    },
  ];

  return (
    <SectionLoader isLoading={isLoading}>
      <ReviewBar title={t('bankAccounts.pleaseReviewBankDetails')} />
      <ModalBody>
        <InformationList withBackground noMargin>
          {reviewData.map(({ label, value }) => (
            <InformationListItem
              key={uuid()}
              label={label}
              value={value}
              fullWidth
            />
          ))}
        </InformationList>

        <ModalButtons
          isHorizontal
          marginTop
          secondaryButtonProps={{ onClick: goStepBack }}
          primaryButtonProps={{
            label: t('common.confirm'),
            onClick: onConfirm,
          }}
        />
      </ModalBody>
    </SectionLoader>
  );
};

AchAccountReview.propTypes = {
  newAchData: PropTypes.shape({
    accountType: PropTypes.shape({ label: PropTypes.string }),
    routingNumber: PropTypes.number,
    accountNumber: PropTypes.string,
    nameOnAccount: PropTypes.string,
  }),
  goStepBack: PropTypes.func,
  onConfirm: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default AchAccountReview;
