import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash-es/isEmpty';
import styled from 'styled-components';
import { ReactComponent as ExecutedIcon } from '../../../assets/images/svg/executed.svg';
import {
  selectAuthUser,
  selectUserTwoFAPhoneNumber,
} from '../../../store/selectors/userSelectors';
import { fetchUserPhoneNumber } from '../../../store/actions/user/userActions';
import { formatPhoneNumber } from '../../../util/helpers/phoneNumberHelpers';
import useGetCountries from '../../../util/hooks/useGetCountries';
import { selectPortalGatewayISOCodesListToIgnoreAreaCode } from '../../../store/selectors/settingsSelectors';
import themeColors from '../../../assets/styles/themeColors';
import { pxToRem } from '../../../assets/styles/helper';

const TwoFaEnabledContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TwoFaEnabledValue = styled.p`
  color: ${themeColors.colorSuccess};
  font-weight: 600;
`;

const TwoFaEnabledIcon = styled(ExecutedIcon)`
  width: ${pxToRem(24)};
  height: ${pxToRem(24)};
  margin-right: ${pxToRem(16)};
  color: ${themeColors.colorSuccess};
`;

const TwoFaEnabled = ({ twoFactorAuthenticationResponse, email }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectAuthUser);
  const userTwoFAPhone = useSelector(selectUserTwoFAPhoneNumber);
  const selectedISOCodesListToIgnoreAreaCode = useSelector(
    selectPortalGatewayISOCodesListToIgnoreAreaCode,
  );
  const {
    AuthenticationMethodType,
    PhoneUid,
  } = twoFactorAuthenticationResponse;
  const countries = useGetCountries();
  const formatedPhoneNumber = formatPhoneNumber(
    userTwoFAPhone,
    countries,
    selectedISOCodesListToIgnoreAreaCode,
  );

  useEffect(() => {
    if (!isEmpty(user) && PhoneUid)
      dispatch(
        fetchUserPhoneNumber({
          userUid: user.UserUid,
          phoneUid: PhoneUid,
        }),
      );
  }, [PhoneUid, user]); // eslint-disable-line

  const renderText = (type) => {
    switch (type) {
      case 1:
        return 'Authentication App Enabled';

      case 2:
        return `Voice or text message ${formatedPhoneNumber}`;
      case 3:
        return `Email ${email}`;

      default:
        break;
    }
  };

  return (
    <TwoFaEnabledContainer>
      <TwoFaEnabledIcon />
      <TwoFaEnabledValue>
        {renderText(AuthenticationMethodType)}
      </TwoFaEnabledValue>
    </TwoFaEnabledContainer>
  );
};

TwoFaEnabled.propTypes = {
  twoFactorAuthenticationResponse: PropTypes.shape({
    AuthenticationMethodType: PropTypes.number,
    PhoneUid: PropTypes.string,
    EmailUid: PropTypes.string,
  }),
  email: PropTypes.string,
};

export default TwoFaEnabled;
