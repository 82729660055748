import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import i18n from 'i18next';
import { useHistory } from 'react-router';

import { uuid } from 'uuidv4';
import { selectBalancesAvailable } from '../../store/selectors/portfolioBalancesSelectors';
import Modal from '../Modal/Modal';
import { FINANCIAL_BALANCES_PAGE } from '../../constants/pages';
import SectionLoader from '../Loader/SectionLoader';
import { selectIsLoadingByActionType } from '../../store/selectors/loadingSelectors';
import { PORTFOLIO_BALANCES_LOADING } from '../../store/actions/portfolio/portfolioActionConstants';
import { formatMoneyNumeral } from '../../util/helpers/numeralHelpers';
import Paragraph from '../Paragraph/Paragraph';
import InformationList from '../InformationList/InformationList';
import InformationListItem from '../InformationList/InformationListItem';
import ModalButtons from '../Modal/ModalButtons';
import ModalBody from '../Modal/ModalBody';

const AllFundsModal = ({ closeModal, isOpen, setIsOpen, overModal }) => {
  const history = useHistory();
  const balance = useSelector(selectBalancesAvailable);

  const isLoading = useSelector(
    selectIsLoadingByActionType(PORTFOLIO_BALANCES_LOADING),
  );

  const handleClose = () => {
    closeModal();
    setIsOpen(false);
    history.push(FINANCIAL_BALANCES_PAGE);
  };

  const informationData = [
    {
      value: formatMoneyNumeral(balance.Effective),
      label: i18n.t('allFunds.effectiveBalance'),
    },
    {
      value: formatMoneyNumeral(balance.AvailableForTrading),
      label: i18n.t('allFunds.forTrading'),
    },
    {
      value: formatMoneyNumeral(balance.AvailableForWithdrawal),
      label: i18n.t('allFunds.forWithdrawal'),
    },
  ];
  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      close={() => setIsOpen(false)}
      title={i18n.t('allFunds.dontSee')}
      overModal={overModal}
    >
      <SectionLoader isLoading={isLoading}>
        <ModalBody>
          <Paragraph marginBottom={24}>
            {i18n.t('allFunds.description')}
          </Paragraph>
          <InformationList withBackground>
            {informationData.map(({ value, label }) => (
              <InformationListItem key={uuid()} value={value} label={label} />
            ))}
          </InformationList>
          <ModalButtons
            marginTop
            isVertical
            primaryButtonProps={{
              onClick: handleClose,
              label: i18n.t('allFunds.history'),
            }}
            secondaryButtonProps={{ onClick: () => setIsOpen(false) }}
          />
        </ModalBody>
      </SectionLoader>
    </Modal>
  );
};

AllFundsModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  closeModal: PropTypes.func,
  overModal: PropTypes.bool,
};

export default AllFundsModal;
