import React from 'react';
import { Redirect, Route } from 'react-router';
import PropTypes from 'prop-types';
import { decode } from 'jsonwebtoken';

import { authScopeStringGetHelper } from '../../util/helpers/authScopeHelpers';
import { JWT_TOKEN } from '../../constants/localStorage';
import { PORTFOLIO_PAGE } from '../../constants/pages';

const AuthRoute = ({ ...props }) => {
  const jwtToken = authScopeStringGetHelper(JWT_TOKEN);
  const is2FaConfigured = !!decode(jwtToken)?.AuthenticationMethodType;

  return jwtToken && is2FaConfigured ? (
    <Redirect to={PORTFOLIO_PAGE} />
  ) : (
    <Route {...props} />
  );
};

AuthRoute.propTypes = {
  path: PropTypes.string,
};

export default AuthRoute;
