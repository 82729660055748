import {
  createErrorType,
  createFetchType,
  createSubmitType,
  createSuccessType,
  createLoadingType,
  createDeleteType,
} from '../actionHelpers';

// get client token
const CLIENT_TOKEN_SCOPE = 'CLIENT_TOKEN';
export const FETCH_CLIENT_TOKEN = createFetchType(CLIENT_TOKEN_SCOPE);
export const FETCH_CLIENT_TOKEN_SUCCESS = createSuccessType(CLIENT_TOKEN_SCOPE);
export const FETCH_CLIENT_TOKEN_ERROR = createErrorType(CLIENT_TOKEN_SCOPE);
export const FETCH_CLIENT_TOKEN_LOADING = createLoadingType(CLIENT_TOKEN_SCOPE);

// submit new credit card
const SUBMIT_CREDIT_CARD_SCOPE = 'SUBMIT_CREDIT_CARD';
export const SUBMIT_CREDIT_CARD = createSubmitType(SUBMIT_CREDIT_CARD_SCOPE);
export const SUBMIT_CREDIT_CARD_SUCCESS = createSuccessType(
  SUBMIT_CREDIT_CARD_SCOPE,
);
export const SUBMIT_CREDIT_CARD_ERROR = createErrorType(
  SUBMIT_CREDIT_CARD_SCOPE,
);
export const SUBMIT_CREDIT_CARD_LOADING = createLoadingType(
  SUBMIT_CREDIT_CARD_SCOPE,
);

// get credit cards
const CREDIT_CARD = 'CREDIT_CARD';
export const CREDIT_CARD_FETCH = createFetchType(CREDIT_CARD);
export const CREDIT_CARD_SUCCESS = createSuccessType(CREDIT_CARD);
export const CREDIT_CARD_ERROR = createErrorType(CREDIT_CARD);
export const CREDIT_CARD_LOADING = createLoadingType(CREDIT_CARD);

// delete credit cards
const CREDIT_CARD_DELETE_SCOPE = 'CREDIT_CARD_DELETE';
export const CREDIT_CARD_DELETE = createDeleteType(CREDIT_CARD_DELETE_SCOPE);
export const CREDIT_CARD_DELETE_SUCCESS = createSuccessType(
  CREDIT_CARD_DELETE_SCOPE,
);
export const CREDIT_CARD_DELETE_ERROR = createErrorType(
  CREDIT_CARD_DELETE_SCOPE,
);
export const CREDIT_CARD_DELETE_LOADING = createLoadingType(
  CREDIT_CARD_DELETE_SCOPE,
);
