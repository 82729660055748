import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const PriceTierModalAmount = styled.div`
  font-weight: 600;
`;

const PriceTierModalPrice = styled.div`
  font-weight: 600;
`;

const PriceTierRow = ({ amount, price }) => (
  <>
    <PriceTierModalAmount>
      <p>{amount}</p>
    </PriceTierModalAmount>
    <PriceTierModalPrice>
      <p>{price}</p>
    </PriceTierModalPrice>
  </>
);

PriceTierRow.propTypes = {
  amount: PropTypes.string,
  price: PropTypes.string,
};

export default PriceTierRow;
