export const accountDocumentTypes = {
  Statement: 'Statement',
  Invoice: 'Invoice',
  Tax: 'Tax',
  DomesticWireDepositInstructionsUSD: 'DomesticWireDepositInstructionsUSD',
  InternationalWireDepositInstructionsUSD:
    'InternationalWireDepositInstructionsUSD',
  OtherWireDepositInstructions: 'OtherWireDepositInstructions',
  CheckDepositInstructions: 'CheckDepositInstructions',
  IRADistributionForm: 'IRADistributionForm',
  IRATransferForm: 'IRATransferForm',
  IRAFoundYourAccount: 'IRAFoundYourAccount',
  IRARolloverInstructions: 'IRARolloverInstructions',
  IRAAnnualContributionWireDepositInstructions:
    'IRAAnnualContributionWireDepositInstructions',
  IRAAnnualContributionCheckDepositInstructions:
    'IRAAnnualContributionCheckDepositInstructions',
  IRAPrefundedWireDepositInstructions: 'IRAPrefundedWireDepositInstructions',
  IRAPrefundedCheckDepositInstructions: 'IRAPrefundedCheckDepositInstructions',
};
