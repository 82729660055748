import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import SectionLoader from '../../Loader/SectionLoader';
import ModalBody from '../../Modal/ModalBody';
import ModalButtons from '../../Modal/ModalButtons';
import { fetchCreditCards } from '../../../store/actions/creditCard/creditCardActions';
import { getCurrentAccount } from '../../../store/selectors/accountSelectors';
import { selectCreditCards } from '../../../store/selectors/creditCardSelectors';
import { AddOrChooseCreditCard } from '../../CreditCard.js/AddOrChooseCreditCard';
import { creditCardPaymentValidation } from '../../../validation/creditCardPaymentValidation';
import { selectSettings } from '../../../store/selectors/settingsSelectors';
import { pxToRem } from '../../../assets/styles/helper';
import AutoPayCheckbox from '../PayStorageFeeByCard/AutoPayCheckbox';
import { changeAutoPaySettings } from '../../../store/actions/storageFee/storageFeeActions';

export const ChargedAmountSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
`;

export const CheckboxWrap = styled.div`
  margin-top: ${pxToRem(24)};
`;

export const CheckboxText = styled.span`
  font-size: ${pxToRem(16)};
`;

const ChangeAutoPaySettings = ({
  handleNext,
  setError,
  handleClose,
  storageFeeAmount,
  setSelectedCreditCard,
  isAutoPayOn,
  setIsAutoPayOn,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const account = useSelector(getCurrentAccount);
  const creditCards = useSelector(selectCreditCards)?.sort(
    (x, y) => Number(x.IsExpired) - Number(y.IsExpired),
  );
  const selectedCardForAutoPay = creditCards.find(
    (creditCard) => creditCard.IsDefaultForStorage,
  );
  const defaultCreditCard = selectedCardForAutoPay || creditCards[0];
  const settings = useSelector(selectSettings);
  const [isLoading, setIsLoading] = useState(false);

  const onFormSubmit = ({ creditCard }) => {
    const onSuccess = () => {
      dispatch(fetchCreditCards({ accountUid: account.AccountUid }));
    };
    dispatch(
      changeAutoPaySettings({
        accountUid: account.AccountUid,
        setting: {
          Autopay: isAutoPayOn,
          CreditCardUid: creditCard ?? null,
        },
        setIsLoading,
        handleNext,
        onSuccess,
        setError,
      }),
    );
  };

  return (
    <SectionLoader isLoading={isLoading}>
      <ModalBody>
        <Formik
          initialValues={{
            autoPay: isAutoPayOn,
            creditCard: defaultCreditCard
              ? defaultCreditCard.CreditCardUid
              : null,
          }}
          onSubmit={onFormSubmit}
          validationSchema={isAutoPayOn && creditCardPaymentValidation}
        >
          {({ values }) => (
            <Form>
              <AutoPayCheckbox
                isAutoPayOn={isAutoPayOn}
                setIsAutoPayOn={setIsAutoPayOn}
              />
              {isAutoPayOn && (
                <>
                  <AddOrChooseCreditCard
                    creditCards={creditCards}
                    values={values}
                    isReachedTransactionLimit={
                      storageFeeAmount > settings?.CreditCardTransactionLimit
                    }
                    setSelectedCreditCard={setSelectedCreditCard}
                  />
                </>
              )}

              <ModalButtons
                marginTop
                isHorizontal
                secondaryButtonProps={{
                  label: t('common.cancel'),
                  onClick: handleClose,
                }}
                primaryButtonProps={{
                  disabled: isAutoPayOn && !values.creditCard,
                  label: t('common.submit'),
                  type: 'submit',
                }}
              />
            </Form>
          )}
        </Formik>
      </ModalBody>
    </SectionLoader>
  );
};

ChangeAutoPaySettings.propTypes = {
  setError: PropTypes.func,
  handleClose: PropTypes.func,
  handleNext: PropTypes.func,
  storageFeeAmount: PropTypes.number,
  setSelectedCreditCard: PropTypes.func,
  isAutoPayOn: PropTypes.bool,
  setIsAutoPayOn: PropTypes.func,
};

export default ChangeAutoPaySettings;
