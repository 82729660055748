import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import HavingTroubleLink from './HavingTroubleLink';
import Button from '../../../components/Button/Button';
import { mediaBelow, pxToRem } from '../../../assets/styles/helper';
import { variables } from '../../../assets/styles/variables';

export const HavingTroubleButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${pxToRem(60)};
  align-items: center;

  a {
    font-weight: 600;
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    grid-template-columns: 1fr;
    grid-gap: ${pxToRem(32)};

    > :nth-child(2) {
      order: -1;
    }

    > :nth-child(1) {
      text-align: center;
    }
  }
`;

const HavingTroubleButtonGroup = ({
  buttonLabel,
  isInviteUserFlow,
  isForgotPasswordFlow,
}) => {
  const { t } = useTranslation();

  return (
    <HavingTroubleButtonsContainer>
      <HavingTroubleLink
        isInviteUserFlow={isInviteUserFlow}
        isForgotPasswordFlow={isForgotPasswordFlow}
      />
      <Button size="lg" variant="primary" type="submit">
        {buttonLabel || t('common.submit')}
      </Button>
    </HavingTroubleButtonsContainer>
  );
};

HavingTroubleButtonGroup.propTypes = {
  buttonLabel: PropTypes.string,
  isInviteUserFlow: PropTypes.bool,
  isForgotPasswordFlow: PropTypes.bool,
};

export default HavingTroubleButtonGroup;
