import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import BlockSectionLoader from '../Loader/BlockSectionLoader';
import BalanceTransactionsTable from '../Portfolio/BalanceTransactions/BalanceTransactionsTable';
import LoadMore from '../LoadMore/LoadMore';

const FinancialBalanceTransactions = ({
  transactionsLoading,
  account,
  handleLoadMore,
  transactionsTotalCount,
  transactionList,
  pageNumber,
  pageSize,
}) => {
  const element = document.getElementById('scrollTo')?.offsetTop;

  return (
    <div id="scrollTo">
      <BalanceTransactionsTable
        data={transactionList}
        accountUid={account.AccountUid}
        shouldShowTransactions={
          !isEmpty(transactionList) && !!transactionList.length
        }
      />
      <BlockSectionLoader noShadow isLoading={transactionsLoading} />
      {transactionsTotalCount > 0 && (
        <LoadMore
          loadMoreDisabled={pageNumber * pageSize >= transactionsTotalCount}
          onLoadClick={handleLoadMore}
          scrollToElement={element}
        />
      )}
    </div>
  );
};

FinancialBalanceTransactions.propTypes = {
  transactionList: PropTypes.arrayOf(PropTypes.shape({})),
  transactionsLoading: PropTypes.bool,
  account: PropTypes.shape({
    AccountUid: PropTypes.string,
  }),
  handleLoadMore: PropTypes.func,
  transactionsTotalCount: PropTypes.number,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
};

export default FinancialBalanceTransactions;
