import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { selectWireDepositInstructions } from '../../../../../store/selectors/accountSelectors';
import { useBrokerCode } from '../../../../../util/hooks/useBrokerCode';
import ModalBody from '../../../../Modal/ModalBody';
import { ModalTitle } from '../../../../Modal/Modal';
import Paragraph from '../../../../Paragraph/Paragraph';
import AnchorOnClick from '../../../../Anchor/AnchorOnClick';
import TabSelect from '../../../../TabSelect/TabSelect';
import ModalButtons from '../../../../Modal/ModalButtons';
import Note from '../../../../Notes/Note';
import WireDepositList from './WireDepositList';
import Support from '../../../../Support/Support';

const depositTypes = {
  Domestic: 'Domestic USD',
  International: 'International USD',
};

const WireStandardDeposit = ({
  handleClose,
  goToInitialModal,
  setModalSize,
  setWizardTitle,
  setWizardBack,
}) => {
  const { t } = useTranslation();
  const wireDepositInstructions = useSelector(selectWireDepositInstructions);
  const brokerCode = useBrokerCode();
  const [type, setType] = useState(depositTypes.Domestic);
  const [isSupportOpen, setIsSupportOpen] = useState(false);

  useEffect(() => {
    if (isSupportOpen) {
      setWizardTitle(t('support.contactSupport'));
    } else {
      setWizardTitle(t('standardDepositWizard.wireDeposit.wireDepositTitle'));
    }

    setModalSize('lg');
  }, [setModalSize, setWizardTitle, isSupportOpen, t]);

  useEffect(() => {
    if (isSupportOpen) {
      setWizardBack(() => () => setIsSupportOpen(false));
      return;
    }

    setWizardBack(null);
  }, [isSupportOpen, setWizardBack]);

  const filterObject = (object, keys) => {
    const filteredObject = Object.keys(object)
      .filter((key) => keys.includes(key))
      .reduce((obj, key) => {
        obj[key] = object[key]; // eslint-disable-line
        return obj;
      }, {});
    return filteredObject;
  };

  const listFields = (entries) => {
    const fields = entries.map((entry) => ({
      label: t(`standardDepositWizard.wireDeposit.wireDepositList.${entry[0]}`),
      value: entry[1],
    }));

    return fields;
  };

  const bankNameEdit = (instructions) => {
    const { BankAddress } = instructions;
    const BankAddressLabel = `${BankAddress.AddressLine} ${BankAddress.AddressLine2} ${BankAddress.City}, ${BankAddress.CountryIso3Code} ${BankAddress.PostalCode}`;

    const wireDepositInstructionObject = {
      ...instructions,
      BankAddress: BankAddressLabel,
    };

    return wireDepositInstructionObject;
  };

  const companyAddressEdit = (CompanyAddress) => {
    const CompanyAddressLabel = `${CompanyAddress.AddressLine} ${CompanyAddress.AddressLine2} ${CompanyAddress.City}, ${CompanyAddress.CountryIso3Code} ${CompanyAddress.PostalCode}`;

    const companyAddressInstructionObject = {
      CompanyAddress: CompanyAddressLabel,
    };

    return companyAddressInstructionObject;
  };

  const domesticWireDepositEntries = () => {
    const wireDepositInstruction = bankNameEdit(
      wireDepositInstructions.Domestic,
    );
    const companyAddress = companyAddressEdit(
      wireDepositInstructions.CompanyAddress,
    );
    const listKeys = [
      'BankName',
      'BankAddress',
      'RoutingAbaNumber',
      'AccountNumber',
      'PayableTo',
      'Memo',
      'CompanyAddress',
    ];
    const templateLPMObject = {
      BankName: null,
      BankAddress: null,
      RoutingAbaNumber: null,
      AccountNumber: null,
      PayableTo: null,
      Memo: null,
    };
    const entries = Object.entries(
      Object.assign(
        templateLPMObject,
        filterObject(
          { ...wireDepositInstruction, ...companyAddress },
          listKeys,
        ),
      ),
    );

    return entries;
  };

  const internationalWireDepositEntries = () => {
    const wireDepositInstruction = bankNameEdit(
      wireDepositInstructions.International,
    );
    const companyAddress = companyAddressEdit(
      wireDepositInstructions.CompanyAddress,
    );

    const listKeys = [
      'BankName',
      'BankAddress',
      'SwiftCode',
      'AccountNumber',
      'AccountName',
      'Memo',
      'CompanyAddress',
    ];

    const entries = Object.entries(
      filterObject({ ...wireDepositInstruction, ...companyAddress }, listKeys),
    );

    return entries;
  };

  const renderType = (type) => {
    if (type === 'Domestic USD') {
      if (wireDepositInstructions.Domestic) {
        const domesticEntries = domesticWireDepositEntries();
        return <WireDepositList fields={listFields(domesticEntries)} />;
      }
    }

    if (type === 'International USD') {
      if (wireDepositInstructions.International) {
        const internationalEntries = internationalWireDepositEntries();

        return <WireDepositList fields={listFields(internationalEntries)} />;
      }
    }
  };

  if (isSupportOpen) {
    return (
      <ModalBody>
        <Support />
      </ModalBody>
    );
  }

  return (
    <ModalBody>
      <ModalTitle>
        {t('standardDepositWizard.wireDeposit.bankWireDepositTitle')}
      </ModalTitle>
      <Paragraph marginBottom={24}>
        {t('standardDepositWizard.wireDeposit.checkOnlineWire')}
      </Paragraph>
      <Paragraph marginBottom={24}>
        <strong>{t('standardDepositWizard.wireDeposit.useGuideBelow')}</strong>
      </Paragraph>
      <Paragraph marginBottom={24}>
        <Trans i18nKey="standardDepositWizard.checkDeposit.haveQuestions">
          If you have any questions about funding your account,
          <AnchorOnClick
            onClick={(event) => {
              event.preventDefault();
              setIsSupportOpen(true);
            }}
          >
            please contact customer service.
          </AnchorOnClick>
        </Trans>
      </Paragraph>

      <Paragraph marginBottom={24}>
        {t('standardDepositWizard.wireDeposit.pleaseProvideInformation', {
          brokerCode,
        })}
      </Paragraph>
      <TabSelect
        initialValue={type}
        types={Object.values(depositTypes)}
        onChange={(type) => setType(type)}
      >
        {type && renderType(type)}
      </TabSelect>

      <Note
        text={t('standardDepositWizard.wireDeposit.brokerDoesNotChargeFee', {
          brokerCode,
        })}
      />

      <ModalButtons
        marginTop
        secondaryButtonProps={{
          onClick: goToInitialModal,
          label: t('common.back'),
        }}
        primaryButtonProps={{
          onClick: handleClose,
          label: t('common.ok'),
        }}
        smallButtons
      />
    </ModalBody>
  );
};

WireStandardDeposit.propTypes = {
  handleClose: PropTypes.func,
  goToInitialModal: PropTypes.func,
  setModalSize: PropTypes.func,
  setWizardTitle: PropTypes.func,
  setWizardBack: PropTypes.func,
};

export default WireStandardDeposit;
