import styled from 'styled-components';
import { mediaBelow } from '../../assets/styles/helper';
import { uFlexColumn } from '../../assets/styles/utility';
import { variables } from '../../assets/styles/variables';

export const PageLayout = styled.div`
  ${uFlexColumn};
  flex: 1 1 auto;
  padding-bottom: 7rem;
  position: relative;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding-bottom: 4rem;
  }
`;
