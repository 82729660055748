import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  TYPE_ANNUAL_IRA_CONTRIBUTION,
  TYPE_IRA_TO_IRA,
  TYPE_ROLLOVER_DEPOSIT,
} from '../../../../util/constants';
import IraToIra from './IraToIra';
import RolloverDeposit from './RolloverDeposit';
import IraContribution from './IraContribution';

const IraDepositType = ({
  iraSettings,
  handleBack,
  setWizardData,
  wizardData,
  type,
  setIsDocumentLoading,
  handleClose,
  handleNext,
}) => {
  const { iraDepositType } = wizardData;
  const { t } = useTranslation();

  useEffect(() => {
    const getWizardTitle = () => {
      switch (iraDepositType) {
        case TYPE_IRA_TO_IRA:
          return t('depositWizard.iraTransfer.title');

        case TYPE_ROLLOVER_DEPOSIT:
          return t('depositWizard.rollover.title');

        case TYPE_ANNUAL_IRA_CONTRIBUTION:
          return t('depositWizard.iraContribution.title');

        default:
          return null;
      }
    };

    setWizardData({
      wizardHandleBack: handleBack,
      wizardSize: 'lg',
      wizardTitle: getWizardTitle(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  switch (iraDepositType) {
    case TYPE_IRA_TO_IRA:
      return (
        <IraToIra
          setIsDocumentLoading={setIsDocumentLoading}
          type={type}
          iraSettings={iraSettings}
          onOkClick={handleClose}
          handleNext={handleNext}
        />
      );

    case TYPE_ROLLOVER_DEPOSIT:
      return (
        <RolloverDeposit
          onOkClick={handleClose}
          type={type}
          iraSettings={iraSettings}
        />
      );

    case TYPE_ANNUAL_IRA_CONTRIBUTION:
      return (
        <IraContribution
          onOkClick={handleClose}
          type={type}
          iraSettings={iraSettings}
        />
      );

    default:
      return null;
  }
};

IraDepositType.propTypes = {
  wizardData: PropTypes.shape({
    iraDepositType: PropTypes.string,
  }),
  handleBack: PropTypes.func,
  setWizardData: PropTypes.func,
  iraSettings: PropTypes.shape({}),
  type: PropTypes.string,
  setIsDocumentLoading: PropTypes.func,
  handleClose: PropTypes.func,
  handleNext: PropTypes.func,
};

export default IraDepositType;
