import {
  MAXIMUM_PERIOD_TYPE_SET,
  MY_HOLDINGS_CODES_ERROR,
  MY_HOLDINGS_CODES_FETCH,
  MY_HOLDINGS_CODES_SUCCESS,
  PORTFOLIO_BALANCES_ERROR,
  PORTFOLIO_BALANCES_FETCH,
  PORTFOLIO_BALANCES_SUCCESS,
  PORTFOLIO_HOLDINGS_FETCH,
  PORTFOLIO_HOLDINGS_FETCH_ERROR,
  PORTFOLIO_HOLDINGS_FETCH_SUCCESS,
  PORTFOLIO_PRODUCT_BY_SYMBOL_FETCH,
  PORTFOLIO_PRODUCT_BY_SYMBOL_FETCH_ERROR,
  PORTFOLIO_PRODUCT_BY_SYMBOL_FETCH_SUCCESS,
  ACCOUNT_TRANSACTIONS_FETCH,
  ACCOUNT_TRANSACTIONS_SUCCESS,
  ACCOUNT_TRANSACTIONS_ERROR,
  PORTFOLIO_VALUATION_ERROR,
  PORTFOLIO_VALUATION_FETCH,
  PORTFOLIO_VALUATION_SUCCESS,
  PORTFOLIO_TRANSACTIONS_SINGLE_ERROR,
  PORTFOLIO_TRANSACTIONS_SINGLE_FETCH,
  PORTFOLIO_TRANSACTIONS_SINGLE_SUCCESS,
  EMPTY_PORTFOLIO_TRANSACTION_SINGLE,
  PORTFOLIO_PRODUCT_BY_SYMBOL_CLEAR,
  PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS,
  PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_SUCCESS,
  PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_ERROR,
  PATCH_PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS,
  PATCH_PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_ERROR,
  PORTFOLIO_MARKET_PRICES_FETCH,
  PORTFOLIO_MARKET_PRICES_SUCCESS,
  PORTFOLIO_MARKET_PRICES_ERROR,
  RESET_MY_HOLDINGS_ACTION,
  CLEAR_CHART_ERROR,
} from './portfolioActionConstants';

export const fetchValuation = ({
  accountUid,
  periodType = null,
  accountCreatedDate,
}) => ({
  type: PORTFOLIO_VALUATION_FETCH,
  accountUid,
  periodType,
  accountCreatedDate,
});

export const fetchValuationSuccess = (payload) => ({
  type: PORTFOLIO_VALUATION_SUCCESS,
  payload,
});

export const fetchValuationError = (payload) => ({
  type: PORTFOLIO_VALUATION_ERROR,
  payload,
});

export const fetchMarketPrices = ({ metalType, periodType = null }) => ({
  type: PORTFOLIO_MARKET_PRICES_FETCH,
  metalType,
  periodType,
});

export const fetchMarketPricesSuccess = (payload) => ({
  type: PORTFOLIO_MARKET_PRICES_SUCCESS,
  payload,
});

export const fetchMarketPricesError = (payload) => ({
  type: PORTFOLIO_MARKET_PRICES_ERROR,
  payload,
});

export const setMaximumPeriodType = (payload) => ({
  type: MAXIMUM_PERIOD_TYPE_SET,
  payload,
});

export const fetchPortfolioHoldings = (payload) => ({
  type: PORTFOLIO_HOLDINGS_FETCH,
  payload,
});

export const fetchPortfolioHoldingsSuccess = (payload) => ({
  type: PORTFOLIO_HOLDINGS_FETCH_SUCCESS,
  payload,
});

export const fetchPortfolioHoldingsError = (payload) => ({
  type: PORTFOLIO_HOLDINGS_FETCH_ERROR,
  payload,
});

export const fetchMyHoldingsCodes = (payload) => ({
  type: MY_HOLDINGS_CODES_FETCH,
  payload,
});

export const fetchMyHoldingsCodesSuccess = (payload) => ({
  type: MY_HOLDINGS_CODES_SUCCESS,
  payload,
});

export const fetchMyHoldingsCodesError = (payload) => ({
  type: MY_HOLDINGS_CODES_ERROR,
  payload,
});

export const fetchPortfolioBalances = (payload) => ({
  type: PORTFOLIO_BALANCES_FETCH,
  payload,
});

export const fetchPortfolioBalancesSuccess = (payload) => ({
  type: PORTFOLIO_BALANCES_SUCCESS,
  payload,
});

export const fetchPortfolioBalancesError = (payload) => ({
  type: PORTFOLIO_BALANCES_ERROR,
  payload,
});

// portfolio product
export const clearPortfolioProductFromStore = () => ({
  type: PORTFOLIO_PRODUCT_BY_SYMBOL_CLEAR,
});

export const fetchPortfolioProductBySymbol = (payload) => ({
  type: PORTFOLIO_PRODUCT_BY_SYMBOL_FETCH,
  payload,
});

export const fetchPortfolioProductBySymbolSuccess = (payload) => ({
  type: PORTFOLIO_PRODUCT_BY_SYMBOL_FETCH_SUCCESS,
  payload,
});

export const fetchPortfolioProductBySymbolError = (payload) => ({
  type: PORTFOLIO_PRODUCT_BY_SYMBOL_FETCH_ERROR,
  payload,
});

//  account transactions
export const accountTransactionsFetch = (payload) => ({
  type: ACCOUNT_TRANSACTIONS_FETCH,
  payload,
});

export const accountTransactionsSuccess = (payload) => ({
  type: ACCOUNT_TRANSACTIONS_SUCCESS,
  payload,
});

export const accountTransactionsError = (payload) => ({
  type: ACCOUNT_TRANSACTIONS_ERROR,
  payload,
});

//  single transaction

export const portfolioTransactionsSingleFetch = (payload) => ({
  type: PORTFOLIO_TRANSACTIONS_SINGLE_FETCH,
  payload,
});

export const portfolioTransactionsSingleSuccess = (payload) => ({
  type: PORTFOLIO_TRANSACTIONS_SINGLE_SUCCESS,
  payload,
});

export const portfolioTransactionsSingleError = (payload) => ({
  type: PORTFOLIO_TRANSACTIONS_SINGLE_ERROR,
  payload,
});

export const emptyPortfolioTransactionSingle = () => ({
  type: EMPTY_PORTFOLIO_TRANSACTION_SINGLE,
});

// pending financial transactions
export const portfolioFinancialPendingTransactions = (payload) => ({
  type: PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS,
  payload,
});

export const portfolioFinancialPendingTransactionsSuccess = (payload) => ({
  type: PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_SUCCESS,
  payload,
});

export const portfolioFinancialPendingTransactionsError = (payload) => ({
  type: PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_ERROR,
  payload,
});

// patch pending financial transactions

export const patchPortfolioFinancialPendingTransactions = (payload) => ({
  type: PATCH_PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS,
  payload,
});

export const patchPortfolioFinancialPendingTransactionsError = (payload) => ({
  type: PATCH_PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_ERROR,
  payload,
});

export const resetMyHoldingState = () => ({
  type: RESET_MY_HOLDINGS_ACTION,
});

export const clearChartError = () => ({
  type: CLEAR_CHART_ERROR,
});
