import {
  createClearType,
  createErrorType,
  createUpdateType,
} from '../actionHelpers';

export const CLEAR_SELL_WIZARD_STATE = createClearType(
  'CLEAR_SELL_WIZARD_STATE',
);

const SELL_PRODUCT_FROM_STORAGE_SCOPE = 'SELL_PRODUCT_FROM_STORAGE';
export const SELL_PRODUCT_FROM_STORAGE = createClearType(
  SELL_PRODUCT_FROM_STORAGE_SCOPE,
);
export const SELL_PRODUCT_FROM_STORAGE_SUCCESS = createUpdateType(
  SELL_PRODUCT_FROM_STORAGE_SCOPE,
);
export const SELL_PRODUCT_FROM_STORAGE_ERROR = createErrorType(
  SELL_PRODUCT_FROM_STORAGE_SCOPE,
);
