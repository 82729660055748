import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ModalBody from '../../Modal/ModalBody';
import ModalButtons from '../../Modal/ModalButtons';
import { postAccountAddress } from '../../../store/actions/account/accountActions';
import { getCurrentAccountUid } from '../../../store/selectors/accountSelectors';
import SectionLoader from '../../Loader/SectionLoader';
import AddressForm from '../../AddressForm/AddressForm';
import { getStatesOptions } from '../../../util/helpers/addressHelper';
import { addNewAddressValidationSchema } from '../../../validation/addAddressValidationSchema';
import { USA_COUNTRY_ISO3CODE } from '../../../util/constants';
import { selectStates } from '../../../store/selectors/commonSelectors';

const AddAddress = ({ onButtonClick, handleNext, setError }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const accountUid = useSelector(getCurrentAccountUid);

  const states = useSelector(selectStates);
  const STATES_OPTIONS = getStatesOptions(states);

  const handleSubmitAddress = (values) => {
    const addressPayload = {
      Street: `${values.AddressLine} ${
        values.AddressLine2 && values.AddressLine2
      }`,
      City: values.City,
      PostalCode: values.PostalCode,
      State: values.StateRegion,
      CountryIso3Code: values.CountryIso3Code,
    };
    dispatch(
      postAccountAddress({
        accountUid,
        data: {
          ...addressPayload,
        },
        handleNext,
        onButtonClick,
        setIsLoading,
        setError,
      }),
    );
  };

  return (
    <SectionLoader isLoading={isLoading}>
      <ModalBody>
        <Formik
          initialValues={{
            CountryIso3Code: USA_COUNTRY_ISO3CODE,
            AddressLine: '',
            AddressLine2: '',
            City: '',
            StateRegion: STATES_OPTIONS[0].value,
            PostalCode: '',
          }}
          onSubmit={handleSubmitAddress}
          validationSchema={addNewAddressValidationSchema}
          enableReinitialize
        >
          {({ isValid, values, setFieldValue }) => (
            <Form>
              <AddressForm
                stateOptions={STATES_OPTIONS}
                setFieldValue={setFieldValue}
                {...values}
              />
              <ModalButtons
                isHorizontal
                marginTop
                primaryButtonProps={{
                  type: 'submit',
                  label: t('common.submit'),
                  disabled: !isValid,
                }}
                secondaryButtonProps={{
                  onClick: onButtonClick,
                  label: t('common.cancel'),
                }}
              />
            </Form>
          )}
        </Formik>
      </ModalBody>
    </SectionLoader>
  );
};

AddAddress.propTypes = {
  onButtonClick: PropTypes.func,
  handleNext: PropTypes.func,
  setError: PropTypes.func,
};

export default AddAddress;
