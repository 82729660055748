import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal from '../Modal/Modal';
import ModalBody from '../Modal/ModalBody';
import ModalTitle from '../Modal/ModalTitle';
import Paragraph from '../Paragraph/Paragraph';
import ModalButtons from '../Modal/ModalButtons';
import Checkbox from '../InputFields/Checkbox';
import { pxToRem } from '../../assets/styles/helper';
import { selectSettings } from '../../store/selectors/settingsSelectors';

const CheckboxWrap = styled.div`
  margin-top: ${pxToRem(20)};
`;

const LearnMoreModal = ({
  isOpen,
  close,
  isSegregatedStorageEnabled,
  isSegregatedStorageSelect,
  selectIsSegregatedStorage,
}) => {
  const { t } = useTranslation();
  const settings = useSelector(selectSettings);
  const segregatedCaption = settings?.SegregatedCaption;

  return (
    <>
      <Modal
        title={t('buyWizard.chooseVault.segregatedStorageModal.title', {
          segregatedCaption,
        })}
        isOpen={isOpen}
        size="sm"
        close={close}
        goBack={close}
      >
        <ModalBody>
          <ModalTitle marginBottom={24}>
            {t('buyWizard.chooseVault.segregatedStorageModal.textTitle')}
          </ModalTitle>
          <Paragraph marginBottom={16}>
            {' '}
            {t(
              'buyWizard.chooseVault.segregatedStorageModal.textIntroduction',
              { segregatedCaption },
            )}
          </Paragraph>
          <ul marginTop={24}>
            <li>
              <Paragraph marginBottom={16}>
                <b>
                  {t(
                    'buyWizard.chooseVault.segregatedStorageModal.paragraplLabel1',
                  )}
                </b>
                {t('buyWizard.chooseVault.segregatedStorageModal.paragraph1')}
              </Paragraph>
            </li>
            <li>
              <Paragraph marginBottom={16}>
                <b>
                  {t(
                    'buyWizard.chooseVault.segregatedStorageModal.paragraplLabel2',
                  )}
                </b>
                {t('buyWizard.chooseVault.segregatedStorageModal.paragraph2')}
              </Paragraph>
            </li>
            <li>
              <Paragraph marginBottom={20}>
                <b>
                  {t(
                    'buyWizard.chooseVault.segregatedStorageModal.paragraplLabel3',
                  )}
                </b>
                {t('buyWizard.chooseVault.segregatedStorageModal.paragraph3')}
              </Paragraph>
            </li>
          </ul>
          <CheckboxWrap>
            <Checkbox
              onChange={() =>
                selectIsSegregatedStorage(!isSegregatedStorageSelect)
              }
              checked={isSegregatedStorageSelect}
              disabled={!isSegregatedStorageEnabled}
            >
              {t('buyWizard.chooseVault.segregatedStorageModal.checkboxInfo', {
                segregatedCaption,
              })}
            </Checkbox>
          </CheckboxWrap>
          <ModalButtons
            isVertical
            marginTop
            showOnlySecondary
            secondaryButtonProps={{
              label: t('common.ok'),
              onClick: close,
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

LearnMoreModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  isSegregatedStorageSelect: PropTypes.bool,
  isSegregatedStorageEnabled: PropTypes.bool,
  selectIsSegregatedStorage: PropTypes.func,
};

export default LearnMoreModal;
