import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SectionLoader from '../Loader/SectionLoader';

import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';

export const AuthCardElement = styled.div`
  max-width: ${pxToRem(624)};
  width: 100%;
  box-shadow: ${variables.shadow.boxShadow};
  border-radius: ${variables.borderRadius.borderRadius};
  border: 1px solid ${themeColors.colorPrimaryLight};
  padding: ${pxToRem(24)} ${pxToRem(40)} ${pxToRem(32)};

  ${({ hasSubtitle }) =>
    !hasSubtitle &&
    `
    ${AuthCardElementTitle} {
      margin-bottom: ${pxToRem(36)}
    }`}

  ${mediaBelow(variables.breakpoints.bpMd)} {
    border: none;
    box-shadow: none;
    padding: 0;
    max-width: 100%;
    ${({ hasSubtitle }) => !hasSubtitle && `margin-bottom: ${pxToRem(36)}`};
`;

export const AuthCardElementTitle = styled.h1`
  text-align: left;
  font-size: ${pxToRem(36)};
  line-height: 1.22;
  font-weight: 400;
  margin-bottom: ${pxToRem(8)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(36)};
    margin-bottom: ${pxToRemMd(6)};
  }
`;

export const AuthCardElementSubtitle = styled.p`
  font-size: ${pxToRem(16)};
  line-height: 1.5;
  letter-spacing: 0;
  font-weight: 600;
  margin-bottom: ${pxToRem(36)};
  color: ${themeColors.colorAuthCardSubtitle};
  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(16)};
    margin-bottom: ${pxToRemMd(36)};
  }
`;

const AuthCard = ({ children, title, subtitle, isLoading }) => (
  <AuthCardElement hasSubtitle={subtitle}>
    <SectionLoader isLoading={isLoading}>
      {title && <AuthCardElementTitle>{title}</AuthCardElementTitle>}
      {subtitle && (
        <AuthCardElementSubtitle>{subtitle}</AuthCardElementSubtitle>
      )}
      {children}
    </SectionLoader>
  </AuthCardElement>
);

AuthCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default AuthCard;
