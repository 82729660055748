import { css } from 'styled-components';
import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import themeColors from '../../../assets/styles/themeColors';
import { variables } from '../../../assets/styles/variables';
import { TableDataElement } from '../TableData';
import {
  TableActionButtons,
  TableDropdown,
  TableDropdownContainer,
  TableDropdownContent,
} from '../TableRow';

export const locationsTable = css`
  display: block;
  ${(props) =>
    props.isLocationTableMobile &&
    `
    display: none;
  `};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    display: none;

    ${(props) =>
      props.isLocationTableMobile &&
      `
      display: block;
      margin: ${pxToRem(8)} 0 ${pxToRemMd(16)};
      border: none;
    `};
  }
`;

export const locationsTableHead = css`
  padding: ${pxToRem(12)} ${pxToRem(24)};
  font-size: ${pxToRem(14)};
  display: grid;
  grid-template-columns:
    minmax(0, ${pxToRem(413)}) minmax(0, ${pxToRem(258)}) minmax(
      0,
      ${pxToRem(257)}
    )
    minmax(${pxToRem(344)}, 1fr);

  > :nth-child(4) {
    padding-right: 0;
    display: flex;
  }

  > ${TableDataElement} {
    width: 100%;
    font-size: ${pxToRem(12)};
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.35;
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(4)} ${pxToRemMd(16)};
    grid-template-columns: 1fr 1fr 1fr ${pxToRemMd(16)};

    > :not(:last-child) {
      padding-right: ${pxToRemMd(8)};
    }

    > :last-child {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    ${TableDataElement} {
      font-size: ${pxToRemMd(12)};
    }
  }
`;

export const locationsTableRow = css`
  padding: ${pxToRem(16)} ${pxToRem(24)};

  > ${TableDataElement} {
    width: 100%;
    display: grid;
    grid-template-columns:
      minmax(0, ${pxToRem(413)}) minmax(0, ${pxToRem(258)}) minmax(
        0,
        ${pxToRem(257)}
      )
      minmax(${pxToRem(344)}, 1fr);

    > div {
      padding-right: ${pxToRem(16)};
    }

    > :nth-child(4) {
      padding-right: 0;
      display: flex;
    }
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: 0 ${pxToRemMd(16)};
    cursor: pointer;

    > ${TableDataElement} {
      grid-template-columns: 1fr;

      > ${TableDropdown} {
        padding-right: 0;
      }

      ${TableDropdownContent} {
        display: grid;
        padding: ${pxToRemMd(4)} ${pxToRemMd(16)};
        grid-template-columns: 1fr 1fr 1fr ${pxToRemMd(16)};
        font-size: ${pxToRemMd(14)};
        padding: ${pxToRemMd(12)} 0;

        > :not(:last-child) {
          padding-right: ${pxToRemMd(8)};
        }

        > :last-child {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }

      ${TableDropdownContainer} {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
`;

export const locationsTableActions = css`
  justify-content: flex-end;

  > ${TableActionButtons} {
    min-width: ${pxToRem(160)};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    > button {
      min-width: 0;
      margin-right: 0;
    }
  }
`;

export const locationsTableBody = css`
  border: 1px solid ${themeColors.colorBorderPrimary};
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;
