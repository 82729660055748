import React from 'react';
import { useTranslation } from 'react-i18next';

const FirefoxPopUpNote = () => {
  const { t } = useTranslation();

  return (
    <>
      <ul>
        <li>{t('statements.popUp.firefox.list.1')}</li>
        <li>{t('statements.popUp.firefox.list.2')}</li>
        <li>{t('statements.popUp.firefox.list.3')}</li>
        <li>{t('statements.popUp.firefox.list.4')}</li>
        <li>{t('statements.popUp.firefox.list.5')}</li>
        <li>{t('statements.popUp.firefox.list.6')}</li>
        <li>{t('statements.popUp.firefox.list.7')}</li>
        <li>{t('statements.popUp.firefox.list.8')}</li>
        <li>{t('statements.popUp.firefox.list.9')}</li>
      </ul>

      <p>{t('statements.popUp.firefox.note')}</p>
    </>
  );
};

export default FirefoxPopUpNote;
