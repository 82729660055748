export const PAYMENT_METHOD_TYPES = {
  ACCOUNT_BALANCE: 1,
  ACH: 2,
  CREDIT_CARD: 3,
};

export default {
  [PAYMENT_METHOD_TYPES.ACCOUNT_BALANCE]: 'AccountBalance',
  [PAYMENT_METHOD_TYPES.ACH]: 'ACH',
  [PAYMENT_METHOD_TYPES.CREDIT_CARD]: 'CreditCard',
};
