import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as RadioOn } from '../../assets/images/svg/radio-on.svg';
import { ReactComponent as RadioOff } from '../../assets/images/svg/radio-off.svg';
import { formatAddress } from '../../util/helpers/addressHelper';
import { variables } from '../../assets/styles/variables';
import themeColors from '../../assets/styles/themeColors';
import {
  uButtonClear,
  uFlexColumn,
  uFullWidth,
  uOutlineNone,
} from '../../assets/styles/utility';
import { pxToRem } from '../../assets/styles/helper';

export const DeliveryAddressContainer = styled.li`
  display: flex;
  border: 1px solid transparent;
  box-shadow: ${variables.shadow.boxShadow};
  border-radius: ${variables.borderRadius.borderRadius};

  ${(props) => props.isSelected && `border-color: ${themeColors.colorPrimary}`};
`;

export const DeliveryAddressButton = styled.button`
  ${uButtonClear};
  ${uOutlineNone};
  display: flex;
  align-items: center;
  cursor: pointer;
  ${uFullWidth};
  padding: ${pxToRem(8)} ${pxToRem(16)};
`;

export const DeliveryAddressIndicator = styled.span`
  width: ${pxToRem(16)};
  margin-right: ${pxToRem(18)};
`;

export const DeliveryAddressValue = styled.p`
  ${uFlexColumn};
  align-items: flex-start;
  font-size: ${pxToRem(14)};
  line-height: 1.75;
  text-align: left;
`;

const DeliveryAddress = ({ address, isSelected, onClick }) => (
  <DeliveryAddressContainer isSelected={isSelected} key={address?.AddressUid}>
    <DeliveryAddressButton type="button" onClick={() => onClick(address)}>
      <DeliveryAddressIndicator>
        {isSelected ? <RadioOn /> : <RadioOff />}
      </DeliveryAddressIndicator>
      <DeliveryAddressValue>{formatAddress(address)}</DeliveryAddressValue>
    </DeliveryAddressButton>
  </DeliveryAddressContainer>
);

DeliveryAddress.propTypes = {
  address: PropTypes.shape({
    AddressLine: PropTypes.string,
    City: PropTypes.string,
    StateRegion: PropTypes.string,
    PostalCode: PropTypes.string,
    AddressUid: PropTypes.string,
    AddressLine2: PropTypes.string,
    CountryIso3Code: PropTypes.string,
  }),
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default DeliveryAddress;
