import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Paragraph from '../Paragraph/Paragraph';
import Anchor from '../Anchor/Anchor';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';

export const AuthCtaParagraph = styled(Paragraph)`
  margin-top: ${pxToRem(24)};

  > a {
    color: ${themeColors.colorLinkPrimary};
  }
  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-top: ${pxToRemMd(40)};
  }
`;

const AuthCta = ({ text, link, linkText, dataCy }) => {
  if (!text && !link && !linkText) {
    return null;
  }

  return (
    <AuthCtaParagraph centerText labelColor>
      {text}
      <Anchor type="website" value={link} text={linkText} data-cy={dataCy} />
    </AuthCtaParagraph>
  );
};

AuthCta.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  dataCy: PropTypes.string,
};

export default AuthCta;
