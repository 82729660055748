/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';

import styled from 'styled-components';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import ProfileSettings from '../../components/ProfileSettings/ProfileSettings';
import Section from '../../components/Section/Section';
import { selectAuthUser } from '../../store/selectors/userSelectors';
import useGtmHook from '../../util/hooks/useGtmHook';
import TwoFACard from '../../components/TwoFactorAuthentication/TwoFACard';
import TwoFASetup from '../../components/TwoFactorAuthentication/TwoFASetup';
import {
  getWizardContent,
  setWizardContent,
} from '../../util/helpers/wizardHelpers';
import {
  IS_MODAL_OPEN,
  IS_DELETE_MODAL_OPEN,
} from '../../constants/sessionStorage';
import { selectIsTwoFAEnabled } from '../../store/selectors/settingsSelectors';
import { closeTwoFaContent } from '../../util/helpers/twoFaSetupHelper';
import { fetchTwoFa } from '../../store/actions/twoFA/twoFAActions';
import TwoFADelete from '../../components/TwoFactorAuthentication/TwoFADelete';
import { selectIsLoadingByActionType } from '../../store/selectors/loadingSelectors';
import { GET_TWO_FA_LOADING } from '../../store/actions/twoFA/twoFAActionConstants';
import Paragraph from '../../components/Paragraph/Paragraph';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import { ACCOUNT_DATA_LOADED } from '../../constants/gtmEvents';

const ProfileSettingsContainer = styled.div`
  padding: ${pxToRem(24)} 0;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: ${pxToRemMd(24)} ${pxToRemMd(16)};
  }
`;

const ProfileSettingsContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${pxToRem(48)};

  ${mediaBelow(1550)} {
    grid-template-columns: 1fr;
  }
`;

const ProfileSettingsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { gtmScreenView, appGtmEvent } = useGtmHook();
  const userInfo = useSelector(selectAuthUser);
  const isTwoFaLoading = useSelector(
    selectIsLoadingByActionType(GET_TWO_FA_LOADING),
  );
  const [isTwoFAModalOpen, setIsTwoFAModalOpen] = useState(
    getWizardContent(IS_MODAL_OPEN) || false,
  );
  const [isTwoFADeleteModalOpen, setIsTwoFADeleteModalOpen] = useState(
    getWizardContent(IS_DELETE_MODAL_OPEN) || false,
  );

  const isTwoFAEnabled = useSelector(selectIsTwoFAEnabled);

  const openModal = () => {
    setIsTwoFAModalOpen(true);
    setWizardContent(IS_MODAL_OPEN, true);
  };

  const openDeleteModal = () => {
    setIsTwoFADeleteModalOpen(true);
    setWizardContent(IS_DELETE_MODAL_OPEN, true);
  };

  useEffect(() => {
    gtmScreenView({
      path: window.location.pathname,
      title: 'Profile',
    });
    appGtmEvent(ACCOUNT_DATA_LOADED);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (isTwoFAEnabled) {
      dispatch(fetchTwoFa({ username: userInfo.Username }));
    }
  }, [userInfo]); // eslint-disable-line

  return isEmpty(userInfo) ? null : (
    <div data-cy="container-profile-settings-page">
      <NavigationBar
        mainPage={t('header.navDropdownAccount')}
        subPage={t('header.profileSettings')}
      />
      <Section>
        <ProfileSettingsContainer>
          <Paragraph marginBottom={16}>
            {t('profileSettings.profileSettingsDescription')}
          </Paragraph>
          <Paragraph marginBottom={24}>
            {t('profileSettings.profileSettingsNote')}
          </Paragraph>
          <ProfileSettingsContent>
            <ProfileSettings {...userInfo} />
            {isTwoFAEnabled && (
              <>
                <TwoFACard
                  isLoading={isTwoFaLoading}
                  openModal={openModal}
                  email={userInfo.Email}
                  openDeleteModal={openDeleteModal}
                />
                {isTwoFAModalOpen && (
                  <TwoFASetup
                    closeModal={() => {
                      setIsTwoFAModalOpen(false);
                      closeTwoFaContent();
                    }}
                    isModalOpen={isTwoFAModalOpen}
                  />
                )}
                {isTwoFADeleteModalOpen && (
                  <TwoFADelete
                    closeModal={() => {
                      setIsTwoFADeleteModalOpen(false);
                      closeTwoFaContent();
                    }}
                    isModalOpen={isTwoFADeleteModalOpen}
                  />
                )}
              </>
            )}
          </ProfileSettingsContent>
        </ProfileSettingsContainer>
      </Section>
    </div>
  );
};

ProfileSettingsPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
};
export default ProfileSettingsPage;
