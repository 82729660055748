import i18next from 'i18next';

export const rejectErrorCodeHelper = (error) => {
  if (error?.response?.data?.Errors) {
    const errorCode = error?.response?.data?.Errors[0]?.Code;
    if (
      errorCode === 'AccountBalanceForDeliveryBalanceTooLow' ||
      errorCode === 'WireWithdrawalFeeCreditNegative' ||
      errorCode === 'WireWithdrawalFeeCreditGreatherThanWireWithdrawalFee' ||
      errorCode === 'AvailableForWithdrawalBalanceTooLow' ||
      errorCode === 'CantDelete2FaPhone' ||
      errorCode === 'AvailableBalanceTooLow'
    ) {
      return error?.response?.data?.Errors[0]?.Message;
    }
    const errorMessage = errorCode
      ? i18next.t(`apiErrors.${errorCode}`)
      : i18next.t('apiErrors.SomethingWentWrong');

    return errorMessage;
  }

  return i18next.t('apiErrors.SomethingWentWrong');
};
