import { createSelector } from 'reselect';
import isEqual from 'lodash.isequal';
import groupBy from 'lodash.groupby';
import { parseEnumType } from '../../util/helpers/enumMappers';
import { accountTypeKeys } from '../../util/enum/api/accountTypes';
import { formatAddressString } from '../../util/helpers/addressHelper';

const accountSelector = (state) => state.account.current;
const userPermissionsSelector = (state) => state.account.userPermissions;
const userSettingsSelector = (state) => state.account.settings;
const nagDashboardSelector = (state) => state.account.current.nagDashboard;

export const getCurrentAccount = createSelector(
  accountSelector,
  (state) => state.current,
);

export const getCurrentAccountUid = createSelector(
  getCurrentAccount,
  (state) => state.AccountUid,
);

export const getCurrentAccountCode = createSelector(
  getCurrentAccount,
  (state) => state.AccountCode,
);

export const getCurrentAccountType = createSelector(
  getCurrentAccount,
  (state) => state.type,
);

export const getCurrentAccountCreatedDate = createSelector(
  getCurrentAccount,
  (state) => state.AddedOn,
);

export const getCurrentAccountNavigationInfo = createSelector(
  getCurrentAccountUid,
  getCurrentAccountType,
  (accountUid, accountType) => ({ accountUid, accountType }),
);

export const selectAccountUsers = createSelector(
  accountSelector,
  (state) => state.accountUsers,
);

export const selectAccountUsersData = createSelector(
  selectAccountUsers,
  (state) => state.data,
);

export const selectAccountUsersError = createSelector(
  selectAccountUsers,
  (state) => state.errorMessage,
);

export const selectAccountAddresses = createSelector(
  accountSelector,
  (state) => state.accountAddresses.data,
);

export const selectMappedAccountAddressesForSelectField = createSelector(
  selectAccountAddresses,
  (state) =>
    state.map((address) => {
      const { AddressUid } = address;

      const addressLabel = formatAddressString(address);

      return {
        label: addressLabel,
        value: AddressUid,
      };
    }),
);
export const selectMappedAccountVerifiedAddressesForSelectField = createSelector(
  selectAccountAddresses,
  (state) =>
    state
      .filter((address) => address.IsVerified)
      .map((address) => {
        const { AddressUid } = address;

        const addressLabel = formatAddressString(address);

        return {
          label: addressLabel,
          value: AddressUid,
        };
      }),
);
export const selectDefaultMailingAddress = createSelector(
  selectAccountAddresses,
  (state) => state.find((address) => address.IsDefaultMailingAddress),
);

export const selectDefaultMailingAddressForSelectField = createSelector(
  selectDefaultMailingAddress,
  (state) => {
    if (!state) {
      return null;
    }
    const {
      AddressUid,
      RecipientCaption,
      AddressLine,
      StateRegion,
      PostalCode,
    } = state;
    const addressLabel = [
      RecipientCaption,
      AddressLine,
      StateRegion,
      PostalCode,
    ]
      .filter(Boolean)
      .join(', ');

    return {
      label: addressLabel,
      value: AddressUid,
    };
  },
);

export const selectAccountAddressesError = createSelector(
  accountSelector,
  (state) => state.accountAddresses.errorMessage,
);

export const selectAddAccountAddresses = createSelector(
  accountSelector,
  (state) => state.newAddress.data,
);

export const selectAddAccountAddressesError = createSelector(
  accountSelector,
  (state) => state.newAddress.error,
);

export const getCurrentUserPermissions = createSelector(
  userPermissionsSelector,
  (state) => state.permissions,
);

export const getCurrentUserPermissionsError = createSelector(
  userPermissionsSelector,
  (state) => state.permissionsError,
);

export const selectWireDepositInstructions = createSelector(
  userSettingsSelector,
  (state) => state.WireDepositInstructions,
);

export const selectCheckDepositInstructions = createSelector(
  userSettingsSelector,
  (state) => state.CheckDepositInstructions,
);

export const selectAccountRestrictionFlags = createSelector(
  userSettingsSelector,
  (state) => state.AccountRestrictionFlags,
);

export const selectAccountsWithoutCurrent = createSelector(
  accountSelector,
  (state) =>
    state.allAccounts.data.filter(
      (account) => !isEqual(account, state.current),
    ),
);

export const selectAccounts = createSelector(
  accountSelector,
  (state) => state.allAccounts.data,
);

export const selectGroupedAccounts = createSelector(
  selectAccountsWithoutCurrent,
  (state) =>
    groupBy(state, (account) =>
      parseEnumType(accountTypeKeys, account.AccountType),
    ),
);

export const selectNewUser = createSelector(
  accountSelector,
  (state) => state.accountUsers.data,
);

export const selectNewUserError = createSelector(
  accountSelector,
  (state) => state.accountUsers.error,
);

export const getUpdateAccountError = createSelector(
  accountSelector,
  (state) => state.errorMessage,
);

export const selectNagDashboard = createSelector(
  nagDashboardSelector,
  (state) => state?.data,
);
