import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';

import PasswordField from '../../../InputFields/PasswordField';

import { selectAuthUser } from '../../../../store/selectors/userSelectors';
import { updateUserPass } from '../../../../store/actions/user/userActions';
import useGtmHook from '../../../../util/hooks/useGtmHook';
import { PASSWORD_CHANGED } from '../../../../constants/gtmEvents';
import { changePasswordValidationSchema } from '../../../../validation/changePasswordValidationSchema';
import ModalButtons from '../../../Modal/ModalButtons';
import FormContainer from '../../../FormContainer/FormContainer';
import SectionLoader from '../../../Loader/SectionLoader';
import { refreshUserToken } from '../../../../store/actions/login/loginActions';
import ModalBody from '../../../Modal/ModalBody';

const EditPassword = ({ setError, handleClose, handleNext }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectAuthUser);
  const [isLoading, setIsLoading] = useState(false);

  const { gtmEvent } = useGtmHook();

  const handleGtm = () => {
    gtmEvent(PASSWORD_CHANGED);
  };

  const refreshUser = () => {
    dispatch(refreshUserToken());
  };

  const handleSubmit = ({ oldPass, Password }, { setFieldError }) =>
    dispatch(
      updateUserPass({
        userUid: user.UserUid,
        data: {
          OldPassword: oldPass,
          NewPassword: Password,
        },
        setIsLoading,
        setFieldError,
        handleGtm,
        setError,
        handleNext,
        refreshUser,
      }),
    );

  if (isEmpty(user)) {
    return null;
  }

  return (
    <SectionLoader isLoading={isLoading}>
      <ModalBody>
        <Formik
          initialValues={{
            oldPass: '',
            Password: '',
          }}
          onSubmit={handleSubmit}
          validationSchema={changePasswordValidationSchema}
        >
          {({ dirty, errors, isValid }) => (
            <Form>
              <FormContainer>
                <Field
                  label={t('profileSettings.oldPass')}
                  name="oldPass"
                  component={PasswordField}
                  autoComplete="off"
                />
                <Field
                  label={t('profileSettings.newPass')}
                  name="Password"
                  component={PasswordField}
                  autoComplete="off"
                  shouldTestPasswordStrength={isEmpty(errors?.Password)}
                />
              </FormContainer>
              <ModalButtons
                isHorizontal
                marginTop
                secondaryButtonProps={{
                  onClick: handleClose,
                  label: t('common.cancel'),
                }}
                primaryButtonProps={{
                  type: 'submit',
                  label: t('common.save'),
                  disabled: !dirty || !isValid,
                }}
              />
            </Form>
          )}
        </Formik>
      </ModalBody>
    </SectionLoader>
  );
};

EditPassword.propTypes = {
  handleClose: PropTypes.func,
  setError: PropTypes.func,
  handleNext: PropTypes.func,
};

export default EditPassword;
