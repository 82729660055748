import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from '../../Modal/Modal';

import StepByStep from '../../StepByStep';
import Status from '../Status';
import EditUser from './EditUser';

const steps = [EditUser, Status];

const EditUserModal = ({ isOpen, handleClose, selectedUser }) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      close={handleClose}
      title={t('user.editPermissions.label')}
      size="sm"
    >
      <StepByStep saveStepDisabled>
        {({ goStepForward, goStepBack }) =>
          steps.map((step) =>
            React.createElement(step, {
              handleBack: goStepBack,
              handleNext: goStepForward,
              handleClose,
              setError,
              hasError: !!error,
              text: error || t('user.editPermissions.successful'),
              backButtonText: t('common.ok'),
              onButtonClick: handleClose,
              selectedUser,
            }),
          )
        }
      </StepByStep>
    </Modal>
  );
};

EditUserModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedUser: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
};

export default EditUserModal;
