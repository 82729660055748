import { TWO_FA_SETUP_WIZARD } from '../../constants/sessionStorage';
import { isJson } from './jsonHelper';

export function closeTwoFaContent() {
  sessionStorage.removeItem(TWO_FA_SETUP_WIZARD);
}

export function setTwoFaContent(key, value) {
  const values = sessionStorage.getItem(TWO_FA_SETUP_WIZARD);
  const data = values ? JSON.parse(values) : {};
  const newData = { ...data, ...value };
  sessionStorage.setItem(TWO_FA_SETUP_WIZARD, JSON.stringify(newData));
}

export function getTwoFaContent() {
  const value = sessionStorage.getItem(TWO_FA_SETUP_WIZARD);
  const isJsonValue = isJson(value);

  if (isJsonValue) {
    return JSON.parse(value);
  }
  return value;
}
