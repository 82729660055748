import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Caret } from '../../../assets/images/svg/arrow-down-thick.svg';
import { buttonizeDiv } from '../../../util/buttonizeDiv';
import { formatMoneyNumeral } from '../../../util/helpers/numeralHelpers';
import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import { variables } from '../../../assets/styles/variables';
import themeColors from '../../../assets/styles/themeColors';
import {
  uFlexColumn,
  uLineClamp,
  uTextEllipsis,
  uTransition,
} from '../../../assets/styles/utility';

const HoldingTableHeaderContainer = styled.div`
  padding: ${pxToRem(16)} ${pxToRem(16)} ${pxToRem(16)} ${pxToRem(24)};
  font-size: ${pxToRem(16)};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5;
  display: flex;
  align-items: center;
  border-radius: ${variables.borderRadius.borderRadius};
  background-color: ${themeColors.colorTableHeaderBackground};
  box-shadow: ${variables.shadow.boxShadow};
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr auto ${pxToRem(10)};
  grid-gap: ${pxToRem(8)};
  ${(props) =>
    props.isOpen &&
    `
    ${HoldingTableHeaderIcon} {
      svg {
        transform: rotate(-180deg);
      }
    }
  `};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: ${pxToRemMd(16)};
  }
`;

const HoldingTableHeaderInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  grid-column-gap: ${pxToRem(16)};
  align-items: center;
  flex-grow: 1;

  ${mediaBelow(variables.breakpoints.bpSm)} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
`;

const HoldingTableHeaderTitle = styled.p`
  ${uLineClamp(2)}
  letter-spacing: 0;
  line-height: 1.5;
  text-align: left;
  font-weight: 600;

  ${mediaBelow(variables.breakpoints.bpSm)} {
    margin-bottom: ${pxToRemMd(12)};
    ${uLineClamp(1)};
  }
`;

const HoldingTableHeaderDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${pxToRem(16)};
`;

const HoldingTableHeaderValue = styled.p`
  ${uTextEllipsis};
  font-weight: 600;
  font-size: ${pxToRem(16)};
  line-height: 1.5;
  letter-spacing: 0;
  width: 100%;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(12)};
    line-height: 1.3;
  }
`;

const HoldingTableHeaderQuantity = styled.div`
  ${uFlexColumn};
  align-items: flex-start;
  flex-shrink: 0;

  ${HoldingTableHeaderValue} {
    text-align: left;
  }

  ${mediaBelow(variables.breakpoints.bpSm)} {
    display: flex;
  }
`;

const HoldingTableHeaderLabel = styled.p`
  font-size: ${pxToRem(14)};
  line-height: 1.57;
  letter-spacing: 0;
  flex-shrink: 0;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(12)};
    line-height: 1.3;
  }

  ${mediaBelow(variables.breakpoints.bpSm)} {
    margin-right: ${pxToRemMd(4)};
  }
`;

const HoldingTableHeaderValuation = styled.div`
  ${uFlexColumn};
  align-items: flex-start;
  min-width: 0;

  ${HoldingTableHeaderValue} {
    text-align: left;
  }

  ${mediaBelow(variables.breakpoints.bpSm)} {
    display: flex;
  }
`;

const HoldingTableHeaderIcon = styled.div`
  ${uTransition};
  justify-self: flex-end;
  width: ${pxToRem(18)};
  height: auto;

  svg {
    ${uTransition};
    width: ${pxToRem(18)};
    height: auto;
  }
`;

const HoldingTableHeader = ({
  title,
  value,
  ounces,
  quantity,
  onClick,
  isOpen,
  isSortByVault,
  isSortByType,
  isSortByProduct,
  isDiamondProduct,
}) => {
  const { t } = useTranslation();

  return (
    <HoldingTableHeaderContainer
      isOpen={isOpen}
      {...buttonizeDiv(onClick)}
      data-cy="button-holdings-vault"
    >
      <HoldingTableHeaderInfo>
        <HoldingTableHeaderTitle>{title}</HoldingTableHeaderTitle>
        <HoldingTableHeaderDetails>
          <HoldingTableHeaderQuantity>
            {isSortByVault || (isSortByType && isDiamondProduct) ? null : (
              <>
                <HoldingTableHeaderLabel>
                  {isSortByProduct
                    ? 'Total units'
                    : t('portfolio.holdings.totlaOuncesLabel')}
                </HoldingTableHeaderLabel>

                <HoldingTableHeaderValue>
                  {isSortByProduct ? `${quantity}` : `${ounces} oz`}
                </HoldingTableHeaderValue>
              </>
            )}
          </HoldingTableHeaderQuantity>

          <HoldingTableHeaderValuation>
            <HoldingTableHeaderLabel>
              {t('portfolio.holdings.valuationLabel')}
            </HoldingTableHeaderLabel>

            <HoldingTableHeaderValue>
              {formatMoneyNumeral(value)}
            </HoldingTableHeaderValue>
          </HoldingTableHeaderValuation>
        </HoldingTableHeaderDetails>
      </HoldingTableHeaderInfo>
      <HoldingTableHeaderIcon>
        <Caret />
      </HoldingTableHeaderIcon>
    </HoldingTableHeaderContainer>
  );
};

HoldingTableHeader.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  ounces: PropTypes.string,
  quantity: PropTypes.number,
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  isSortByVault: PropTypes.bool,
  isSortByType: PropTypes.bool,
  isSortByProduct: PropTypes.bool,
  isDiamondProduct: PropTypes.bool,
};

export default HoldingTableHeader;
