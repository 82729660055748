// Register & Wizard
export const WIZARD = 'Wizard';
export const REGISTER = 'Register';

// Wizard Session Storage Constants
export const IS_DEPOSIT_MODAL_OPEN = 'IsDepositModalOpen';
export const IS_FUND_MODAL_OPEN = 'IsFundModalOpen';
export const SELECTED_TYPE = 'SelectedType';
export const WITHDRAWAL_TYPE = 'WithdrawalType';
export const BANK_ACCOUNT = 'BankAccount';
export const TAB_TYPE_CHECK = 'Check';
export const ACCOUNT_DETAILS_INFO = 'AccountDetailsInfo';
export const COUNTRY_SELECT_MODAL_INFO = 'CountrySelectModalInfo';
export const NON_US_COUNTRY_DATA = 'NonUsCountryData';
export const SHOW_NON_US_MODAL = 'ShowNonUsModal';
export const NON_US_COUNTRY_SELECT_MODAL_INFO = 'NonUsCountrySelectModalInfo';
export const CHOSEN_VAULT = 'ChosenVault';
export const PRODUCT = 'Product';
export const IS_MODAL_OPEN = 'IsModalOpen';
export const IS_BUY_WIZARD_OPEN = 'IsBuyWizardOpen';
export const IS_RESTRICTION_MODAL_OPEN = 'IsRestrictionModalOpen';
export const IS_DELETE_MODAL_OPEN = 'IsDeleteModalOpen';
export const IS_PRODUCT_FRACTIONAL = 'IsProductFractional';
export const METAL_STREAM_SETTINGS = 'MetalStreamSettings';
export const METAL_STREAM_EDITING = 'MetalStreamEditing';
export const DELIVERY_ADDRESS = 'DeliveryAddress';
export const DELIVERY_ADDRESS_OBJECT = 'DeliveryAddressObject';
export const REVIEW_PRODUCT = 'ReviewProduct';
export const IS_NO_ADDRESS_MODAL_OPEN = 'IsNoAddressModalOpen';
export const NEW_ACH_ERROR = 'NewAchError';
export const IS_ABA_DISABLED = 'ABAIsDisabled';
export const NEW_IRA_TO_IRA_ERROR = 'NewIraToIraError';
export const ERROR_MESSAGES = 'ErrorMessages';
export const NEW_ROLLOVER_ERROR = 'NewRolloverError';
export const NEW_CASH_CONTRIBUTION_ERROR = 'NewCashContributionError';
export const NEW_INITIAL_DEPOIST_ERROR = 'NewInitialDepositError';
export const NEW_MS_FUNDING_ERROR = 'NewMSFundingError';
export const STATUS_MODAL_OPEN = 'StatusModalOpen';
export const STATUS_MODAL = 'StatusModal';
export const IS_ADD_ACCOUNT_MODAL_OPEN = 'IsAddAccountModalOpen';
export const WIRE_INSTRUCTIONS = 'WireInstructions';
export const CHECK_INSTRUCTIONS = 'CheckInstructions';
export const SELECT_TYPE = 'SelectType';
export const WITHDRAW_MODAL_OPEN = 'WithdrawModalOpen';
export const PREFUNDED_WITHDRAW_MODAL_OPEN = 'PrefundedWithdrawModalOpen';
export const PLAID_STATUS_MODAL_OPEN = 'PlaidStatusModalOpen';
export const CHOSEN_ACH_METHOD = 'ChosenAchMethod';
export const NEW_ACH_DATA = 'NewAchData';
export const BANK_DETAILS = 'BankDetails';
export const PRODUCT_BY_SYMBOL = 'ProductBySymbol';
export const BUY_FOR_STORAGE_SUCCESS = 'BuyForStorageSuccess';
export const BUY_ERROR_MESSAGE = 'BuyErrorMessage';
export const CASH_VALUE = 'CashValue';
export const QUANTITY_VALUE = 'QuantityValue';
export const TYPE = 'Type';
export const QUANTITY_ERROR = 'QuantityError';
export const CASH_ERROR = 'CashError';
export const DEPOSIT_AMOUNT = 'DepositAmount';
export const IS_CHECK_ACCOUNT_MODAL_OPEN = 'IsCheckAccountModalOpen';
export const TERMS_MODAL_OPEN = 'TermsModalOpen';
export const SUPPORT_MODAL_OPEN = 'SupportModalOpen';
export const WIRE_DEPOSIT_TYPE = 'WireDepositType';
export const CURRENT_SELECTION = 'CurrentSelection';
export const REMOVE_LOCATION = 'RemoveLocation';
export const PRICE_TIER_MODAL_DATA = 'PriceTierModalData';
export const PRICE_TIER_MODAL_STATE = 'PriceTierModalState';
export const SET_ACCOUNT_BALANCE = 'SetAccountBalance';
export const ERROR_OCCURED = 'ErrorOccurred';
export const STATUS_MESSAGE = 'StatusMessage';
export const INFORMATION_CONTENT_TYPE = 'InformationContentType';
export const INFORMATION_MODAL_OPEN = 'InformationModalOpen';
export const HAS_ERROR = 'HasError';
export const ERROR_TEXT = 'ErrorText';
export const WITHDRAWAL_ADDRESS = 'WithdrawalAddress';
export const IS_SUCCESS_OR_FAIL_MODAL_OPEN = 'IsSuccessOrFailModalOpen';
export const WITHDRAWAL_AMOUNT = 'WithdrawalAmount';
export const WIRE_WITHDRAWAL_FEE_CREDIT = 'WireWithdrawalFeeCredit';
export const WIRE_WITHDRAWAL_OBI_MESSAGES = 'WireWithdrawalObiMessages';
export const WITHDRAWAL_VALUE = 'WithdrawalValue';
export const IS_METAL_STREAM_SETUP_DONE = 'IsMetalStreamSetupOpen';
export const IS_NEW_WIRE_OPEN = 'IsNewWireOpen';
export const MODAL_TITLE_SUFFIX = 'ModalTitleSuffix';
export const IS_SELL_WIZARD_OPEN = 'IsSellWizardOpen';
export const IS_FRACTIONAL_CONVERSION_OPEN = 'IsFractionalConversionOpen';
export const IS_SUPPORT_MODAL_OPEN = 'IsSupportModalOpen';
export const IS_REQUEST_DELIVERY_OPEN = 'IsRequestDeliveryOpen';
export const BANK_DETAILS_BY_ROUTING_NUMBER = 'BankDetailsByRoutingNumber';
export const NEW_WIRE_ACC_DETAILS = 'NewWireAccDetails';
export const NEW_WIRE_RESPONSE_ERROR = 'NewWireResponseError';
export const NEW_WIRE_RESPONSE_MODAL_OPEN = 'NewWireResponseModalOpen';
export const ERROR = 'Error';
export const METAL_STREAM_ERROR_MESSAGE = 'MetalStreamError';
export const INVITE_EMAIL = 'InvitedEmail';
export const IS_CALCULATE_DELIVERY_VISIBLE = 'isCalculateDeliveryVisible';
export const MODAL_EXTENDED = 'ModalExtended';
export const DELIVERY_VERIFIED_DATA = 'DeliveryVerifiedData';
export const DELIVERY_QUANTITY = 'DeliveryQuantity';
export const DELIVERY_VERIFY_DATA = 'DeliveryVerifyData';
export const IS_CASH_CONTRIBUTION_MODAL_OPEN = 'IsCashContributionModalOpen';
export const IS_ROLLOVER_MODAL_OPEN = 'IsRolloverModalOpen';
export const IS_IRA_TO_IRA_MODAL_OPEN = 'IsIraToIraModalOpen';
export const IS_METAL_STREAM_REGISTRATION_FLOW =
  'IsMetalStreamRegistrationFlow';
export const IS_USA = 'IsUSA';
export const IS_BUY_FOR_STORAGE_OPEN = 'IsBuyForStorageOpen';
export const REVIEW_IRA_TO_IRA = 'ReviewIraToIra';
export const DISPATCH_DATA = 'DispatchData';
export const USER_INVITE_STATUS_MODAL = 'UserInviteStatusModal';
export const IS_CARD_USED_FOR_AUTO_PAY = 'IsCardUsedForAutoPay';

// Wizard Session Storage Names
export const ADD_WIRE_WITHDRAWAL_WIZARD = 'AddWireWithdrawalWizard';
export const BUY_WIZARD = 'BuyWizard';
export const WIZARD_DATA = 'WizardData';
export const BUY_FOR_STORAGE_WIZARD = 'BuyForStorageWizard';
export const BUY_FOR_DELIVERY_WIZARD = 'BuyForDeliveryWizard';
export const METAL_STREAM_WIZARD = 'MetalStreamWizard';
export const FRACTIONAL_CONVERSION_WIZARD = 'FractionalConversionWizard';
export const REQUEST_DELIVERY_WIZARD = 'RequestDeliveryWizard';
export const SELL_WIZARD = 'SellWizard';
export const EQUITY_WITHDRAWAL_WIZARD = 'EquityWithdrawalWizard';
export const CHECK_WITHDRAWAL_WIZARD = 'CheckWithdrawalWizard';
export const WIRE_WITHDRAWAL_WIZARD = 'WireWithdrawalWizard';
export const ACH_DEPOSIT_WIZARD = 'AchDepositWizard';
export const WIRE_DEPOSIT_WIZARD = 'WireDepositWizard';
export const STANDARD_DEPOSIT_WIZARD = 'StandardDepositWizard';
export const IRA_DEPOSIT_WIZARD = 'IraDepositWizard';
export const ADD_NEW_ACH_WIZARD = 'AddNewAchWizard';
export const MANUAL_ADD_NEW_ACH_WIZARD = 'ManualAddNewAchWizard';
export const STANDARD_WITHDRAWAL_WIZARD = 'StandardWithdrawalWizard';
export const ADD_CREDIT_CARD_WIZARD = 'AddCreditCardWizard';

export const IRA_TRANSFER_OUT_WITHDRAWAL_WIZARD =
  'IraTransferOutWithdrawalWizard';
export const IRA_CASH_DISTRIBUTION_WITHDRAWAL_WIZARD =
  'IraCashDistributionWithdrawalWizard';
export const ENTRUST_PREFUNDED_WITHDRAWAL_WIZARD =
  'EntrustPrefundedWithdrawalWizard';
export const PREFUNDED_CHECK_WITHDRAWAL_WIZARD =
  'PrefundedCheckWithdrawalWizard';
export const PREFUNDED_WIRE_WITHDRAWAL_WIZARD = 'PrefundedWireWithdrawalWizard';
export const REMOVE_USER_WIZARD = 'RemoveUserWizard';
export const REMOVE_BANK_ACCOUNT_WIZARD = 'RemoveBankAccountWizard';
export const REMOVE_ADDRESS_WIZARD = 'RemoveAddressWizard';
export const ADD_ADDRESS_WIZARD = 'AddAddressWizard';
export const IS_ADD_MAIL_IN_WIZARD_OPEN = 'AddMailInWizard';

// Global Session Storage Constants
export const ACCOUNT_UID = 'AccountUid';
export const PREVENT_SUPPORT_MODAL = 'PreventSupportModal';
export const USER_LOGGED_IN = 'UserLoggedIn';
export const EMAIL = 'Email';
export const LOGIN_EMAIL = 'LoginEmail';
export const USERNAME = 'Username';
export const IMPERSONATE_USER_UID = 'ImpersonateUserUid';
export const REGISTRATION_USER_UID = 'RegistrationUserUid';

// Invite User Session Storage
export const INVITE_USER_DATA = 'InviteUserData';
export const FORM_VALUES = 'FormValues';
export const PHONE_NUMBERS = 'PhoneNumbers';
export const USER_UID = 'UserUid';
export const USER_INVITE_ID = 'UserInviteId';

// Session Storage
export const SESSION_STORAGE_SCOPE = 'SessionStorageScope';
export const IGNORE_PERMISSIONS = 'IgnorePermissions';
export const LANDING_PAGE = 'LandingPage';

// Register Storage
export const APPLICATION_USER = 'ApplicationUser';
export const BANK_ACCOUNT_REGISTRATION = 'BankAccountRegistration';
export const APPLICATION_DATA = 'ApplicationData';
export const APPLICATION_TYPE = 'ApplicationType';
export const CURRENT_ACCOUNT_TYPE = 'CurrentAccountType';
export const IS_COMPLETED_APPLICATION = 'CompletedApplication';

// Register Wizard
export const EXISTING_IRA_FUNDING_WIZARD_STEP = 'ExistingIraFundingWizardStep';

// STATUS MODALS Register
export const STATUS_MODAL_IRA_TO_IRA = 'StatusModalIraToIra';
export const STATUS_MODAL_CASH_CONTRIBUTION = 'StatusModalCashContribution';
export const STATUS_MODAL_ROLLOVER = 'StatusModalRollover';
export const STATUS_MODAL_INITIAL_DEPOSIT = 'StatusModalInitialDeposit';
export const STATUS_MODAL_METAL_STREAM = 'StatusModalMetalStream';

// TWO FA SETUP WIZARD
export const TWO_FA_SETUP_WIZARD = 'TwoFaSetupWizard';
export const TWO_FA_LOGIN_SETUP = 'TwoFaLoginSetup';
export const TWO_FA_LOGIN_AUTHENTICATE = 'TWO_FA_LOGIN_AUTHENTICATE';

// SESSION STORAGE
export const HOLDINGS_VIEW_TYPE = 'HoldingsViewType';
export const USERNAMES = 'Usernames';

// BUY FOR DELIVERY ADDRESS STATUS
export const STATUS_NEW_ADDRESS_ADDED = 'NewAddressAdded';

// SIGNIFYD SESSION ID
export const SIGNIFYD_SESSION_ID = 'SignifydSessionId';

// IS NAG DASHBOARD COLLAPSED
export const IS_NAG_DASHBOARD_OPEN = 'IsNagDashboardOpen';

// TRACKING DETAILS
export const LANDING_PAGE_URL = 'LandingPageUrl';
export const OFFER_CODE = 'OfferCode';

// MAIL IN STORAGE
export const ADD_MAIL_IN_STORAGE_WIZARD = 'AddMailInStorage';

// INSTAVAULT PRODUCT
export const IS_INSTA_VAULT_PRODUCT = 'IsInstaVaultProduct';
