import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Paragraph from '../Paragraph/Paragraph';
import { pxToRem } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';

const InformationListItemContainer = styled.li`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${pxToRem(16)};

  ${({ noValue }) =>
    noValue &&
    `grid-template-columns: 1fr;
      grid-column-gap: 0;`}

  ${({ withBackground }) =>
    withBackground &&
    `
      background-color: ${themeColors.colorBackgroundSecondary};
      padding: ${pxToRem(4)} ${pxToRem(8)};
  `}
    

  ${({ isBold }) => isBold && `> p {font-weight: 600;}`}

  ${({ fullWidth }) =>
    fullWidth &&
    `
      grid-template-columns: 1fr 1fr;

      > :last-child {
        text-align: right;
      }
  `}
`;

const InformationListItem = ({
  label,
  value,
  noValue,
  withBackground,
  fullWidth,
  isBold,
  whiteSpace,
}) => {
  if (!value && !noValue) {
    return null;
  }

  return (
    <InformationListItemContainer
      key={label}
      noValue={noValue}
      withBackground={withBackground}
      isBold={isBold}
      fullWidth={fullWidth}
    >
      <Paragraph bold={isBold}>{label}</Paragraph>
      <Paragraph whiteSpace={whiteSpace}>{value}</Paragraph>
    </InformationListItemContainer>
  );
};

InformationListItem.propTypes = {
  label: PropTypes.node,
  value: PropTypes.string,
  noValue: PropTypes.bool,
  withBackground: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isBold: PropTypes.bool,
  whiteSpace: PropTypes.bool,
};

export default InformationListItem;
