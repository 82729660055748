import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { pxToRem } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';

const NoResultsTypography = styled.p`
  font-size: ${pxToRem(24)};
  letter-spacing: 0;
  line-height: 1.1;
  border-bottom: 1px solid ${themeColors.colorBorderPrimary};
`;

const NoResults = () => {
  const { t } = useTranslation();

  return <NoResultsTypography>{t('common.noResults')}</NoResultsTypography>;
};

export default NoResults;
