import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';

import NumberField from '../../../InputFields/NumberField';
import {
  closeWizardContent,
  getWizardContent,
  setWizardContent,
} from '../../../../util/helpers/wizardHelpers';
import { QUANTITY_VALUE } from '../../../../constants/sessionStorage';
import { quantityValidationSchema } from '../../../../validation/quantityValidationSchema';
import ModalBody from '../../../Modal/ModalBody';
import ModalButtons from '../../../Modal/ModalButtons';
import ModalTitle from '../../../Modal/ModalTitle';
import Label from '../../../Notes/Label';
import Paragraph from '../../../Paragraph/Paragraph';

const QuantityForm = ({
  product,
  quantity,
  setQuantity,
  goStepForward,
  onClose,
}) => {
  const { t } = useTranslation();

  const initialValues = {
    quantity: getWizardContent(QUANTITY_VALUE) || '',
  };

  return (
    <ModalBody>
      <ModalTitle>{product.ProductCaption}</ModalTitle>
      <Label
        marginBottom={4}
        isBold
        text={t('product.fractionalConversion.unitsAvailableForConversion')}
      />
      <Paragraph marginBottom={32}>
        {product.UnitsAvailableForConversion}
      </Paragraph>
      <Formik
        initialValues={initialValues}
        validationSchema={quantityValidationSchema(
          product.UnitsAvailableForConversion,
        )}
        onSubmit={goStepForward}
        initialTouched={{
          quantity: true,
        }}
      >
        {({ isValid }) => (
          <Form>
            <Field
              label={t('product.fractionalConversion.quantityToConvert')}
              placeholder={0}
              name="quantity"
              centerText
              demiBold
              component={NumberField}
              onKeyUp={({ target: { value } }) => {
                setWizardContent(QUANTITY_VALUE, value);
                setQuantity(value);
              }}
            />
            <ModalButtons
              marginTop
              isHorizontal
              secondaryButtonProps={{
                label: t('common.cancel'),
                onClick: () => {
                  closeWizardContent();
                  onClose();
                },
              }}
              primaryButtonProps={{
                disabled:
                  !isValid ||
                  !Number(quantity) ||
                  !(product.UnitsAvailableForConversion > 0),
                type: 'submit',
              }}
            />
          </Form>
        )}
      </Formik>
    </ModalBody>
  );
};

QuantityForm.propTypes = {
  product: PropTypes.shape({
    ProductCaption: PropTypes.string,
    UnitsAvailableForConversion: PropTypes.number,
  }),
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setQuantity: PropTypes.func,
  goStepForward: PropTypes.func,
  onClose: PropTypes.func,
};

export default QuantityForm;
