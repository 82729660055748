import createReducer from '../../utils/createReducer';
import {
  FETCH_DOCUMENT_BY_YEAR_SUCCESS,
  FETCH_DOCUMENT_BY_YEAR_ERROR,
} from '../../actions/documents/documentActionConstants';

const initialState = {
  documents: [],
  errorMessage: '',
};

export default createReducer(
  {
    [FETCH_DOCUMENT_BY_YEAR_SUCCESS]: setDocumentsData,
    [FETCH_DOCUMENT_BY_YEAR_ERROR]: setDocumentsError,
  },
  initialState,
);

function setDocumentsData(state, action) {
  return {
    ...state,
    documents: action.payload,
    error: '',
  };
}

function setDocumentsError(state, action) {
  return {
    ...state,
    error: action.payload,
  };
}
