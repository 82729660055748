/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import i18next from '../../i18n';
import BlockSectionLoader from '../Loader/BlockSectionLoader';
import { FETCH_DOCUMENT_BY_YEAR_LOADING } from '../../store/actions/documents/documentActionConstants';
import { selectIsLoadingByActionType } from '../../store/selectors/loadingSelectors';
import { fetchDocumentByDocumentUrl } from '../../store/actions/documents/documentActions';
import Button from '../Button/Button';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import { uFlexCenter } from '../../assets/styles/utility';
import themeColors from '../../assets/styles/themeColors';

export const StatementsTableContainer = styled.div``;

export const StatementsTableTitle = styled.h3`
  font-size: ${pxToRem(18)};
  font-weight: 600;
  line-height: 1.35;
  margin-bottom: ${pxToRem(16)};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(20)};
    margin-bottom: ${pxToRemMd(18)};
  }
`;

export const StatementsTableEmpty = styled.div`
  ${uFlexCenter};
  min-height: ${pxToRem(200)};
  width: 100%;
  border-radius: ${variables.borderRadius.borderRadius};
  box-shadow: ${variables.shadow.boxShadow};
  background-color: ${themeColors.colorCardBackground};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    min-height: ${pxToRemMd(200)};
  }

  p {
    font-weight: 600;
    text-align: center;
  }
`;

export const StatementsList = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${pxToRem(8)};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    grid-row-gap: ${pxToRemMd(16)};
  }
`;

export const StatementsListItem = styled.li`
  padding: ${pxToRem(8)} ${pxToRem(8)} ${pxToRem(8)} ${pxToRem(24)};
  display: flex;
  align-items: center;
  border-radius: ${variables.borderRadius.borderRadius};
  box-shadow: ${variables.shadow.boxShadow};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: ${pxToRemMd(8)} ${pxToRemMd(8)} ${pxToRemMd(8)} ${pxToRemMd(24)};
  }
`;

export const StatementListItemName = styled.p`
  font-size: ${pxToRem(16)};
  line-height: 1.5;
  letter-spacing: 0;
  margin-right: auto;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(16)};
  }
`;

const StatementsTable = ({ title, data, type, year, setIsDownloadLoading }) => {
  const dispatch = useDispatch();
  const tableTitle = `${title} ${year}`;

  const isLoading = useSelector(
    selectIsLoadingByActionType(FETCH_DOCUMENT_BY_YEAR_LOADING),
  );

  const renderStatements = () => {
    if (data) {
      return (
        <StatementsList>
          {data.map((item) => {
            const month = new Date(item.Date);
            const monthName = month.toLocaleString('default', {
              month: 'long',
            });

            const documentName =
              type === 'tax' ? `1099 - ${year}` : `${monthName}`;
            const documentUrl = item.DocumentUrl;

            const handleDocDownload = () => {
              dispatch(
                fetchDocumentByDocumentUrl({
                  documentUrl,
                  setIsDownloadLoading,
                }),
              );
            };

            return (
              <StatementsListItem key={monthName}>
                <StatementListItemName>{documentName}</StatementListItemName>
                <Button size="sm" variant="primary" onClick={handleDocDownload}>
                  {i18next.t('common.download')}
                </Button>
              </StatementsListItem>
            );
          })}
        </StatementsList>
      );
    }

    if (type === 'tax') {
      return <p>{i18next.t('statements.noTaxForms', { year })}</p>;
    }

    return (
      <StatementsTableEmpty>
        <p>{i18next.t('statements.noTypeSelected', { type })}</p>
      </StatementsTableEmpty>
    );
  };

  return (
    <StatementsTableContainer>
      <StatementsTableTitle>{tableTitle}</StatementsTableTitle>
      {isLoading ? (
        <BlockSectionLoader isLoading={isLoading} />
      ) : (
        renderStatements()
      )}
    </StatementsTableContainer>
  );
};

StatementsTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  type: PropTypes.string,
  year: PropTypes.number,
  setIsDownloadLoading: PropTypes.func,
};

export default StatementsTable;
