import * as Yup from 'yup';
import i18next from '../i18n';

export const changeSecurityQuestionValidationSchema = Yup.object().shape({
  answer: Yup.string().required(
    i18next.t('validationSchema.general.securityAnswerRequired'),
  ),
  securityQuestion: Yup.object().shape({
    value: Yup.string().required(
      i18next.t('validationSchema.general.securityQuestionRequired'),
    ),
  }),
});
