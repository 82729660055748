import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import HavingTroubleIsClosed from '../Components/HavingTroubleIsClosed';
import HavingTroubleIsOpen from '../Components/HavingTroubleIsOpen';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import { Phone, twoFaTypesEnum } from '../../../util/enum/api/twoFATypes';
import {
  INVITE_USER_PAGE,
  INVITE_USER_TWO_FA_VERIFY_IDENTITY,
  INVITE_USER_TWO_FA_VERIFY_IDENTITY_PHONE,
  TWO_FA_VERIFY_IDENTITY,
  TWO_FA_VERIFY_IDENTITY_PHONE,
} from '../../../constants/pages';
import { fetchTwoFaCustomerSupportIsOpen } from '../../../store/actions/twoFA/twoFAActions';
import { selectTwoFaIsCustomerSupportOpen } from '../../../store/selectors/twoFASelector';
import { selectIsLoadingByActionType } from '../../../store/selectors/loadingSelectors';
import { GET_TWO_FA_CUSTOMER_SUPPORT_IS_OPEN_LOADING } from '../../../store/actions/twoFA/twoFAActionConstants';
import { selectTokens } from '../../../store/selectors/loginSelectors';
import { resetLoginState } from '../../../store/actions/login/loginActions';

const TwoFAHavingTroublePage = ({
  username,
  twoFactorAuthenticationResponse,
  twoFaValue,
  isInviteUserFlow,
  formValues,
  userInviteId,
  handleNext,
}) => {
  const { AuthenticationMethodType } = twoFactorAuthenticationResponse;
  const history = useHistory();
  const dispatch = useDispatch();
  const isOpen = useSelector(selectTwoFaIsCustomerSupportOpen);
  const isLoading = useSelector(
    selectIsLoadingByActionType(GET_TWO_FA_CUSTOMER_SUPPORT_IS_OPEN_LOADING),
  );
  const tokens = useSelector(selectTokens);

  useEffect(() => {
    dispatch(fetchTwoFaCustomerSupportIsOpen());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInviteUserFlow) {
      const { RefreshToken } = tokens;

      if (!RefreshToken) {
        dispatch(resetLoginState());
        return history.push(INVITE_USER_PAGE);
      }
    }
  }, [dispatch, history, isInviteUserFlow, tokens]);

  const handleBackClick = () => {
    const isPhoneType =
      parseEnumType(twoFaTypesEnum, AuthenticationMethodType) === Phone;
    const isMessageTypeSelected = twoFaValue?.phoneMessageType;

    if (isInviteUserFlow) {
      if (isPhoneType && !isMessageTypeSelected) {
        return history.push(INVITE_USER_TWO_FA_VERIFY_IDENTITY_PHONE);
      }

      history.push(INVITE_USER_TWO_FA_VERIFY_IDENTITY);
    } else {
      if (isPhoneType && !isMessageTypeSelected) {
        return history.push(TWO_FA_VERIFY_IDENTITY_PHONE);
      }

      history.push(TWO_FA_VERIFY_IDENTITY);
    }
  };

  if (isOpen) {
    return (
      <HavingTroubleIsOpen
        username={username}
        handleBackClick={handleBackClick}
        isOpenLoading={isLoading}
        formValues={formValues}
        userInviteId={userInviteId}
        handleNext={handleNext}
      />
    );
  }

  return (
    <HavingTroubleIsClosed
      isOpenLoading={isLoading}
      handleBackClick={handleBackClick}
    />
  );
};

TwoFAHavingTroublePage.propTypes = {
  username: PropTypes.string,
  twoFactorAuthenticationResponse: PropTypes.oneOfType([
    PropTypes.shape({
      AuthenticationMethodType: PropTypes.number,
    }),
    PropTypes.string,
  ]),
  twoFaValue: PropTypes.shape({
    phoneMessageType: PropTypes.string,
  }),
  isInviteUserFlow: PropTypes.bool,
  handleNext: PropTypes.func,
  userInviteId: PropTypes.string,
  formValues: PropTypes.shape({
    values: PropTypes.shape({
      ExistingUsername: PropTypes.string,
      LegalFirstName: PropTypes.string,
      LegalMiddleName: PropTypes.string,
      LegalLastName: PropTypes.string,
      Suffix: PropTypes.string,
      Username: PropTypes.string,
      Password: PropTypes.string,
      SecurityQuestion: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
        PropTypes.string,
      ]),
      Answer: PropTypes.string,
    }),
    phoneNumbers: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default TwoFAHavingTroublePage;
