import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ModalBody from '../../Modal/ModalBody';
import Paragraph from '../../Paragraph/Paragraph';
import ModalButtons from '../../Modal/ModalButtons';
import useGtmHook from '../../../util/hooks/useGtmHook';
import { ADDRESS_DELETED } from '../../../constants/gtmEvents';
import { deleteAccountAddresses } from '../../../store/actions/account/accountActions';
import { getCurrentAccountUid } from '../../../store/selectors/accountSelectors';
import SectionLoader from '../../Loader/SectionLoader';

const RemoveAddress = ({ handleNext, handleClose, addressUid, setError }) => {
  const { t } = useTranslation();
  const { gtmEvent } = useGtmHook();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const accountUid = useSelector(getCurrentAccountUid);

  const deleteAddress = () => {
    const handleGtm = () => {
      gtmEvent(ADDRESS_DELETED);
    };

    dispatch(
      deleteAccountAddresses({
        accountUid,
        addressUid,
        handleGtm,
        handleNext,
        setIsLoading,
        setError,
      }),
    );
  };

  return (
    <SectionLoader isLoading={isLoading}>
      <ModalBody>
        <Paragraph marginBottom={24}>{t('address.areYouSure')}</Paragraph>
        <Paragraph>{t('address.youWillNoLonger')}</Paragraph>
        <ModalButtons
          marginTop
          isHorizontal
          primaryButtonProps={{
            onClick: deleteAddress,
            label: t('common.delete'),
          }}
          secondaryButtonProps={{
            onClick: handleClose,
            label: t('common.cancel'),
          }}
        />
      </ModalBody>
    </SectionLoader>
  );
};

RemoveAddress.propTypes = {
  addressUid: PropTypes.string,
  handleClose: PropTypes.func,
  handleNext: PropTypes.func,
  setError: PropTypes.func,
};

export default RemoveAddress;
