import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentAccount } from '../../store/selectors/accountSelectors';

export const useIsClosedAccount = () => {
  const [isClosedAccount, setIsClosedAccount] = useState(false);

  const currentAccount = useSelector(getCurrentAccount);

  useEffect(() => {
    if (!isEmpty(currentAccount)) {
      const { AccountStatus } = currentAccount;

      if (!AccountStatus) {
        setIsClosedAccount(false);
        return;
      }

      if (AccountStatus === 6) {
        setIsClosedAccount(true);
        return;
      }

      setIsClosedAccount(false);
    }
  }, [currentAccount]);

  return isClosedAccount;
};
