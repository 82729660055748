import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from '../../Modal/Modal';
import ModalBody from '../../Modal/ModalBody';
import Paragraph from '../../Paragraph/Paragraph';
import ModalButtons from '../../Modal/ModalButtons';

const LockedPriceVariableWeightDisclaimerModal = ({ isOpen, close }) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        title={t('buyWizard.verifyOrder.buyForStorage')}
        isOpen={isOpen}
        size="sm"
        close={close}
        goBack={close}
      >
        <ModalBody>
          <Paragraph fontSize={16} marginTop={12} marginBottom={12}>
            {t('lockedPrice.disclaimerInfoDetails')}
          </Paragraph>

          <ModalButtons
            isVertical
            marginTop
            showOnlySecondary
            secondaryButtonProps={{
              label: t('common.ok'),
              onClick: close,
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

LockedPriceVariableWeightDisclaimerModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
};

export default LockedPriceVariableWeightDisclaimerModal;
