import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { buttonizeDiv } from '../../util/buttonizeDiv';

import { ReactComponent as DropdownIcon } from '../../assets/images/svg/dropdown.svg';
import {
  BANK_ACCOUNTS_PAGE,
  CREDIT_CARDS_PAGE,
  METAL_STREAM_PAGE,
} from '../../constants/pages';
import useCheckPermissions from '../../util/hooks/useCheckPermissions';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';
import { mediaBelow, mediaUp, pxToRem } from '../../assets/styles/helper';
import {
  uFlexCenter,
  uResetPosition,
  uTransition,
} from '../../assets/styles/utility';
import { selectSettings } from '../../store/selectors/settingsSelectors';
import { parseEnumType } from '../../util/helpers/enumMappers';
import {
  accountTypeKeys,
  accountTypes,
} from '../../util/enum/api/accountTypes';
import { getCurrentAccount } from '../../store/selectors/accountSelectors';
import config from '../../config';

const NavigationDropdownList = styled.ul`
  position: absolute;
  top: 100%;
  left: 50%;
  right: 0;
  width: 11rem;
  background-color: ${themeColors.colorNavigationDropdownBackground};
  z-index: ${variables.zIndex.indexSm};
  display: none;
  transform: translateX(-50%);
  box-shadow: ${variables.shadow.boxShadow};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    ${uResetPosition};
    width: 100%;
    transform: translateX(0);
    background-color: ${themeColors.colorSecondaryHeaderBackground};
  }
`;

const NavigationDropdownIconWrapper = styled.div`
  margin-left: ${pxToRem(12)};

  svg {
    ${uTransition};
    width: 1rem;
    height: 1rem;
    color: ${themeColors.colorTextContrast};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    margin-left: auto;

    svg {
      color: ${themeColors.colorTextPrimary};
      width: ${pxToRem(18)};
      height: ${pxToRem(18)};
    }
  }
`;

const NavigationDropdownContainer = styled.div`
  ${uFlexCenter};
  position: relative;
  height: 100%;
  width: 100%;

  ${({ isPageOpen, isMobile }) =>
    isPageOpen &&
    !isMobile &&
    `
    ${NavigationDropdownLabel} {
      font-weight: 600;
    }
  `}

  ${mediaBelow(variables.breakpoints.bpXl)} {
    flex-direction: column;

    ${({ isMobile }) =>
      isMobile &&
      `display: flex;

      ${NavigationDropdownList} {
        display: none;
      }
    `}

    ${({ isMobileOpen }) =>
      isMobileOpen &&
      `
      ${NavigationDropdownIconWrapper} {
        svg {
          transform: rotate(-180deg);
        }
      }

      ${NavigationDropdownList} {
        display: block;
      }
    `}
  }

  ${mediaUp(variables.breakpoints.bpXl)} {
    &:hover {
      ${NavigationDropdownIconWrapper} {
        svg {
          transform: rotate(-180deg);
        }
      }

      ${NavigationDropdownList} {
        display: block;
      }
    }
  }
`;

const NavigationDropdownLabel = styled.span`
  ${uFlexCenter};
  text-decoration: none;
  line-height: 1.5;
  font-size: ${pxToRem(18)};
  color: ${themeColors.colorTextContrast};
  text-transform: uppercase;
  height: 100%;
  width: 100%;
  cursor: pointer;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    color: ${themeColors.colorTextPrimary};
    justify-content: flex-start;
    align-items: center;
    padding: ${pxToRem(14)} ${pxToRem(18)};
    font-size: ${pxToRem(20)};
    line-height: 1.35;
    text-transform: capitalize;

    &:hover {
      background-color: ${themeColors.colorNavigationItemHover};
    }
  }
`;

const NavigationDropdownItem = styled.li`
  display: flex;
  flex-shrink: 0;

  &:hover {
    background-color: ${themeColors.colorNavigationDropdownItemHoverBackground};

    > a {
      font-weight: 600;
    }
  }

  > a {
    font-size: ${pxToRem(16)};
    line-height: 1.75;
    color: ${themeColors.colorTextHeader};
    padding: 4px ${pxToRem(16)};
    width: 100%;
    text-decoration: none;
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    display: flex;

    &:hover {
      background-color: ${themeColors.colorNavigationItemHover};
    }

    > a {
      color: ${themeColors.colorTextPrimary};
      padding: 0;
      font-size: ${pxToRem(21)};
      line-height: 1.35;
      padding: ${pxToRem(10)} ${pxToRem(36)};
    }
  }
`;

const NavigationDropdown = ({
  title,
  items,
  isNavOpen,
  closeNav,
  isMobile,
  dataCyLabel,
}) => {
  const settings = useSelector(selectSettings);
  const [isOpen, setIsOpen] = useState(isMobile);
  const [isPageOpen, setIsPageOpen] = useState(false);
  const location = useLocation();
  const account = useSelector(getCurrentAccount);
  const {
    displayStandardOptionsPermission,
    displayMetalStreamMenuItems,
    displayBasicAccountOptionsPermission,
  } = useCheckPermissions();
  const { brokerTheme } = config;
  const isGS = brokerTheme === 'GoldSilver';
  const isErrAccount = account?.AccountStatus === 7;
  const isPendingEquityAccount =
    parseEnumType(accountTypeKeys, account.AccountType) === accountTypes.IRA &&
    displayBasicAccountOptionsPermission;

  useEffect(() => {
    if (isNavOpen) {
      setIsOpen(false);
    }
  }, [isNavOpen, isMobile]);

  useEffect(() => {
    const pages = items.filter((item) => item.href === location.pathname);

    if (pages.length > 0) {
      setIsPageOpen(true);
    } else {
      setIsPageOpen(false);
    }
  }, [items, location.pathname]);

  return (
    <NavigationDropdownContainer
      isPageOpen={isPageOpen}
      isMobile={isMobile}
      isMobileOpen={!isOpen && isMobile && isNavOpen}
      {...buttonizeDiv(() => {
        setIsOpen(!isOpen);
      })}
      data-cy={dataCyLabel}
    >
      <NavigationDropdownLabel>
        {title}
        <NavigationDropdownIconWrapper>
          <DropdownIcon />
        </NavigationDropdownIconWrapper>
      </NavigationDropdownLabel>
      <NavigationDropdownList>
        {!isEmpty(items) &&
          items.map((item) => {
            if (
              (!displayStandardOptionsPermission &&
                !displayBasicAccountOptionsPermission &&
                item.href === BANK_ACCOUNTS_PAGE) ||
              (isPendingEquityAccount && item.href === BANK_ACCOUNTS_PAGE) ||
              (isErrAccount && item.href === BANK_ACCOUNTS_PAGE) ||
              (!displayMetalStreamMenuItems &&
                item.href === METAL_STREAM_PAGE) ||
              (isGS && item.href === METAL_STREAM_PAGE) ||
              (!settings?.IsCreditCardActive && item.href === CREDIT_CARDS_PAGE)
            ) {
              return null;
            }

            return (
              <NavigationDropdownItem key={item.id}>
                <NavLink
                  onClick={closeNav}
                  to={item.href}
                  data-cy={item.dataCyDropdownItem}
                >
                  {item.name}
                </NavLink>
              </NavigationDropdownItem>
            );
          })}
      </NavigationDropdownList>
    </NavigationDropdownContainer>
  );
};

NavigationDropdown.propTypes = {
  title: PropTypes.string,
  isNavOpen: PropTypes.bool,
  closeNav: PropTypes.func,
  isMobile: PropTypes.bool,
  dataCyLabel: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      href: PropTypes.string,
      name: PropTypes.string,
      dataCyDropdownItem: PropTypes.string,
    }),
  ),
};

export default NavigationDropdown;
