import i18next from 'i18next';
import * as Yup from 'yup';

export const quantityValidationSchema = (maxValue) =>
  Yup.object().shape({
    quantity: Yup.number()
      .integer(i18next.t('validationSchema.quantity.wholeNumber'))
      .required(i18next.t('validationSchema.quantity.quantityRequired'))
      .min(1, i18next.t('validationSchema.quantity.minRoundNumber'))
      .max(maxValue, i18next.t('validationSchema.general.insufficientUnits')),
  });
