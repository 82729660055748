import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useBrokerName } from '../../../util/hooks/userBrokerName';
import { useBrokerCode } from '../../../util/hooks/useBrokerCode';
import BulletItem from '../../BulletItem/BulletItem';
import BulletList from '../../BulletList/BulletList';
import Paragraph from '../../Paragraph/Paragraph';
import ModalBody from '../../Modal/ModalBody';
import ModalButtons from '../../Modal/ModalButtons';
import ContainerWithCustomScrollbar from '../../ContainerWithCustomScrollbar/ContainerWithCustomScrollbar';
import config from '../../../config';

export const MetalStreamTerms = ({ handleBack, handleNext }) => {
  const { t } = useTranslation();
  const brokerName = useBrokerName();
  const brokerCode = useBrokerCode();
  const { metalStreamCaption } = config;

  return (
    <ContainerWithCustomScrollbar>
      <ModalBody>
        <Paragraph marginBottom={24}>
          {t('termsAndConditions.textOne', {
            brokerName,
            metalStream: metalStreamCaption,
          })}
        </Paragraph>
        <BulletList>
          <BulletItem text={t('termsAndConditions.textTwo')} />
          <BulletItem text={t('termsAndConditions.textThree')} />
          <BulletItem text={t('termsAndConditions.textFour', { brokerName })} />
        </BulletList>
        <Paragraph marginBottom={24}>
          {t('termsAndConditions.textFive', {
            metalStream: metalStreamCaption,
          })}
        </Paragraph>
        <Paragraph marginBottom={24}>
          {t('termsAndConditions.textSix')}
        </Paragraph>
        <BulletList>
          <BulletItem text={t('termsAndConditions.textSeven')} />
          <BulletItem
            text={t('termsAndConditions.textEight', {
              metalStream: metalStreamCaption,
            })}
          />
          <BulletItem text={t('termsAndConditions.textNine')} />
        </BulletList>
        <Paragraph marginBottom={24}>
          {t('termsAndConditions.textTen')}
        </Paragraph>
        <BulletList>
          <BulletItem text={t('termsAndConditions.textEleven')} />
          <BulletItem
            text={t('termsAndConditions.textTwelve', {
              brokerCode,
              metalStream: metalStreamCaption,
            })}
          />
        </BulletList>
        <Paragraph marginBottom={24}>
          {t('termsAndConditions.textThirteen')}
        </Paragraph>
        <Paragraph marginBottom={24}>
          {t('termsAndConditions.textFourteen', {
            brokerCode,
            metalStream: metalStreamCaption,
          })}
        </Paragraph>
        <Paragraph>
          {' '}
          {t('termsAndConditions.textFifteen', {
            brokerCode,
            metalStream: metalStreamCaption,
          })}
        </Paragraph>

        <ModalButtons
          isHorizontal
          marginTop
          smallButtons
          secondaryButtonProps={{ onClick: handleBack }}
          primaryButtonProps={{
            label: t('common.agree'),
            onClick: handleNext,
          }}
        />
      </ModalBody>
    </ContainerWithCustomScrollbar>
  );
};

MetalStreamTerms.propTypes = {
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
};
