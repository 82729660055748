import isEmpty from 'lodash.isempty';
import React, { useEffect } from 'react';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as Close } from '../../assets/images/svg/close.svg';
import { separateByUppercase } from '../../util/helpers/stringHelpers';
import { pxToRem } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import themeColors from '../../assets/styles/themeColors';

const CurrentRefinementList = styled.ul`
  display: flex;
  flex-wrap: wrap;

  > :not(:last-child) {
    margin-right: ${pxToRem(16)};
  }
`;

const CurrentRefinementListItem = styled.li`
  border-radius: ${variables.borderRadius.borderRadius};
  background-color: ${themeColors.colorFilterItemBackground};
  padding: ${pxToRem(4)} ${pxToRem(8)};
  flex-shrink: 0;
  margin-bottom: ${pxToRem(16)};
`;

const CurrentRefinementLink = styled.a`
  font-size: ${pxToRem(16)};
  line-height: 1.5;
  font-weight: 600;
  color: ${themeColors.colorFilterItemText};
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const CurrentRefinementClose = styled(Close)`
  color: ${themeColors.colorFilterItemText};
  width: ${pxToRem(24)};
  margin-left: ${pxToRem(8)};
`;

const CustomCurrentRefinement = ({ items, refine, createURL, setItems }) => {
  useEffect(() => {
    setItems(items);
  }, [items, setItems]);

  if (!isEmpty(items)) {
    const allRefinements = items.map((item) => item.items).flat();

    return (
      <CurrentRefinementList>
        {allRefinements.map((item) => (
          <CurrentRefinementListItem key={item.label}>
            <CurrentRefinementLink
              href={createURL(item.value)}
              onClick={(event) => {
                event.preventDefault();
                refine(item.value);
              }}
            >
              {separateByUppercase(item.label)}
              <CurrentRefinementClose />
            </CurrentRefinementLink>
          </CurrentRefinementListItem>
        ))}
      </CurrentRefinementList>
    );
  }

  return null;
};

CustomCurrentRefinement.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  refine: PropTypes.func,
  createURL: PropTypes.func,
  setItems: PropTypes.func,
};

export default connectCurrentRefinements(CustomCurrentRefinement);
