import isEmpty from 'lodash.isempty';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InformationList from '../../../InformationList/InformationList';
import InformationListItem from '../../../InformationList/InformationListItem';
import CheckDetails from './CheckDetails';
import Paragraph from '../../../Paragraph/Paragraph';

const CheckInformation = ({ checkDetails, isPrefunded }) => {
  const { t } = useTranslation();

  if (isEmpty(checkDetails)) {
    return null;
  }

  return (
    <>
      {!isPrefunded && (
        <Paragraph marginBottom={16} fontSize={14}>
          {t('depositWizard.equityCheck.textOne')}
        </Paragraph>
      )}
      <InformationList withBackground noMargin>
        <InformationListItem
          label={t('depositWizard.equityCheck.payableTo')}
          value={checkDetails.PayableTo}
        />
        <InformationListItem
          label={t('depositWizard.equityCheck.memo')}
          value={
            <>
              {checkDetails.Memo}
              {isPrefunded && (
                <>
                  <br />
                  {t('depositWizard.equityCheck.storageFeeBalance')}
                </>
              )}
            </>
          }
        />
      </InformationList>
      <InformationList withContainer>
        <InformationListItem
          noValue
          label={t('depositWizard.equityCheck.sendCheckTo')}
        />
      </InformationList>
      <CheckDetails isPrefunded={isPrefunded} checkDetails={checkDetails} />
    </>
  );
};

CheckInformation.propTypes = {
  checkDetails: PropTypes.shape({
    PayableTo: PropTypes.string,
    Memo: PropTypes.string,
    Addresses: PropTypes.shape({
      FundingCheckMailingAddress: PropTypes.shape({
        Receipient: PropTypes.string,
        Attn: PropTypes.string,
        AddressLine: PropTypes.string,
        City: PropTypes.string,
        PostalCode: PropTypes.string,
        StateRegion: PropTypes.string,
      }),
      MailingAddress: PropTypes.shape({
        Receipient: PropTypes.string,
        Attn: PropTypes.string,
        AddressLine: PropTypes.string,
        City: PropTypes.string,
        PostalCode: PropTypes.string,
        StateRegion: PropTypes.string,
      }),
      Overnight: PropTypes.shape({
        Receipient: PropTypes.string,
        Attn: PropTypes.string,
        AddressLine: PropTypes.string,
        City: PropTypes.string,
        StateRegion: PropTypes.string,
        PostalCode: PropTypes.string,
      }),
      FundingCheckMailingAddressThirdPartyShipper: PropTypes.shape({
        Receipient: PropTypes.string,
        Attn: PropTypes.string,
        AddressLine: PropTypes.string,
        City: PropTypes.string,
        PostalCode: PropTypes.string,
        StateRegion: PropTypes.string,
      }),
    }),
  }),
  isPrefunded: PropTypes.bool,
};

export default CheckInformation;
