import { createSelector } from 'reselect';
import { getWizardContent } from '../../util/helpers/wizardHelpers';
import { CHOSEN_VAULT } from '../../constants/sessionStorage';

export const buyStorageSelector = (state) => state.order.buyStorage;

export const selectStorageProduct = createSelector(
  buyStorageSelector,
  (state) => state.product,
);

export const selectStorageSymbolCode = createSelector(
  buyStorageSelector,
  (state) => state.vault || getWizardContent(CHOSEN_VAULT),
);

export const selectVaultPricePerUnit = createSelector(
  selectStorageSymbolCode,
  (state) => state.PricePerUnit,
);

export const selectIsBuyStorageSuccessful = createSelector(
  buyStorageSelector,
  (state) => state.isSuccessful,
);

export const selectBuyError = createSelector(
  buyStorageSelector,
  (state) => state.errorMessage,
);

export const selectDeliveryFees = createSelector(
  buyStorageSelector,
  (state) => state.deliveryFees,
);
