import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Modal from '../Modal/Modal';
import StepByStep from '../StepByStep';
import Status from '../Modals/Status';
import { closeWizardContent } from '../../util/helpers/wizardHelpers';
import { TWO_FA_SETUP_WIZARD } from '../../constants/sessionStorage';
import {
  closeTwoFaContent,
  getTwoFaContent,
  setTwoFaContent,
} from '../../util/helpers/twoFaSetupHelper';
import TwoFADeleteConfirmation from './Delete/TwoFADeleteConfirmation';
import { selectTwoFaError } from '../../store/selectors/twoFASelector';

const twoFaDeleteSteps = [TwoFADeleteConfirmation, Status];

const TwoFADelete = ({ isModalOpen, closeModal }) => {
  const { t } = useTranslation();
  const [twoFaValue, setValues] = useState(() => getTwoFaContent() || {});
  // eslint-disable-next-line no-unused-vars
  const [isSuccessful, setIsSuccessful] = useState(null);

  const twoFAError = useSelector(selectTwoFaError);

  const handleModalClose = () => {
    closeWizardContent();
    closeModal();
    setValues({});
  };

  const setTwoFaValues = (data) => {
    if (data === null) {
      setValues({});
      closeTwoFaContent();
      return;
    }

    setValues({ ...twoFaValue, ...data });
    setTwoFaContent(TWO_FA_SETUP_WIZARD, { ...twoFaValue, ...data });
  };

  const statusText = () => {
    if (!isSuccessful) {
      return twoFAError || t('twoFa.profileSettings.delete.error');
    }
    return t('twoFa.profileSettings.delete.success');
  };

  return !isModalOpen ? null : (
    <Modal
      isOpen={isModalOpen}
      size="sm"
      title={t('twoFa.profileSettings.delete.title')}
      close={closeModal}
    >
      <StepByStep>
        {({ goStepBack, goStepForward }) =>
          twoFaDeleteSteps.map((modal) =>
            React.createElement(modal, {
              handleBack: goStepBack,
              handleClose: handleModalClose,
              handleNext: goStepForward,
              setTwoFaValues,
              isModalOpen,
              twoFaValue,
              onButtonClick: handleModalClose,
              backButtonText: t('common.ok'),
              text: statusText(),
              hasError: !isSuccessful,
              setIsSuccessful,
            }),
          )
        }
      </StepByStep>
    </Modal>
  );
};

TwoFADelete.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
};

export default TwoFADelete;
