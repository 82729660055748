import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';

import styled from 'styled-components';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import Section from '../../components/Section/Section';
import Table from '../../components/Table/Table';
import TableBody from '../../components/Table/TableBody';
import TableHead from '../../components/Table/TableHead';
import TableRow from '../../components/Table/TableRow';
import UserManagementRow from '../../components/UserManagementTable/UserManagementRow';
import Button from '../../components/Button/Button';
import ManagementCard from '../../components/ManagementCard/ManagementCard';
import RemoveUserModal from '../../components/Modals/Users/RemoveUserModal';
import StatusModal from '../../components/Modals/StatusModal';
import {
  fetchAccountUsers,
  updateAccountUser,
} from '../../store/actions/account/accountActions';
import {
  getCurrentAccountUid,
  getUpdateAccountError,
  selectAccountUsersData,
} from '../../store/selectors/accountSelectors';

import {
  formatUsersTableHeaderData,
  formatUsersTableRowData,
} from '../../util/helpers/userHelpers';
import {
  ACCOUNT_RESEND_INVITE,
  userPermissionLiterals,
  userStateType,
  UUAT_ACTIVATE_PROFILE,
} from '../../util/enum/api/accountTypes';
import { parseEnumType } from '../../util/helpers/enumMappers';
import { updateUserState } from '../../store/actions/user/userActions';
import {
  getUpdateUserStateError,
  selectAuthUser,
} from '../../store/selectors/userSelectors';
import { selectIsLoadingByActionType } from '../../store/selectors/loadingSelectors';
import { UPDATE_USER_STATE_LOADING } from '../../store/actions/user/userActionConstants';
import {
  FETCH_ACCOUNT_USERS_LOADING,
  UPDATE_ACCOUNT_USER_LOADING,
} from '../../store/actions/account/accountActionConstants';
import useGtmHook from '../../util/hooks/useGtmHook';
import BlockSectionLoader from '../../components/Loader/BlockSectionLoader';
import useCheckPermissions from '../../util/hooks/useCheckPermissions';
import { useGetIsImpersonating } from '../../util/hooks/useGetIsImpersonating';
import Paragraph from '../../components/Paragraph/Paragraph';
import AddNewUserModal from '../../components/Modals/AccountAccess/AddNewUserModal';
import EditUserModal from '../../components/Modals/AccountAccess/EditUserModal';
import {
  managementTable,
  managementTableActions,
  managementTableBody,
  managementTableHead,
  managementTableRow,
} from '../../components/Table/Components/ManagementTable';
import { variables } from '../../assets/styles/variables';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { uFlexCenter } from '../../assets/styles/utility';
import themeColors from '../../assets/styles/themeColors';
import NagDashboardBanner from '../../components/NagDashboard/NagDashboardBanner';
import { ACCOUNT_DATA_LOADED } from '../../constants/gtmEvents';

const ManagementAddNewContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    margin-bottom: ${pxToRemMd(24)};
  }
`;

const ManagementContainer = styled.div`
  margin: ${pxToRem(32)} 0;

  ${ManagementAddNewContainer} {
    display: none;

    form {
      display: none;
    }
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    margin: ${pxToRem(24)} ${pxToRem(16)};

    ${ManagementAddNewContainer} {
      display: flex;

      form {
        display: flex;
      }
    }
  }
`;

const ManagementGrid = styled.div`
  display: none;
  grid-gap: ${pxToRemMd(24)};
  grid-template-columns: 1fr 1fr;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    display: grid;
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    display: grid;
    grid-template-columns: 1fr;
  }
`;

const ManagementTableEmpty = styled.div`
  ${uFlexCenter};
  min-height: ${pxToRem(150)};
`;

const ManagementTableEmptyText = styled.p`
  text-align: center;
  font-weight: 600;
  color: ${themeColors.colorTextSecondary});
`;

const UserManagementPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUser = useSelector(selectAuthUser);
  const [usersState, setUsersState] = useState([]);
  const [openAddNewUser, setOpenAddNewUser] = useState(false);
  const [editedUser, setUserEdited] = useState('');
  const [isEditUserEmailModalOpen, setIsEditUserEmailModalOpen] = useState(
    false,
  );
  const [isActivateModalOpen, setIsActivateModalOpen] = useState(false);
  const activateError = useSelector(getUpdateUserStateError);
  const resendError = useSelector(getUpdateAccountError);
  const [
    isResendInvitationModalOpen,
    setIsResendInvitationModalOpen,
  ] = useState(false);
  const [userToRemove, setUserToRemove] = useState(null);
  const [removeUserModalOpen, setRemoveUserModalOpen] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const users = useSelector(selectAccountUsersData);
  const usersLoading = useSelector(
    selectIsLoadingByActionType(FETCH_ACCOUNT_USERS_LOADING),
  );
  const accountUid = useSelector(getCurrentAccountUid);
  const isLoadingActivateAccount = useSelector(
    selectIsLoadingByActionType(UPDATE_USER_STATE_LOADING),
  );

  const isLoadingResendInvitation = useSelector(
    selectIsLoadingByActionType(UPDATE_ACCOUNT_USER_LOADING),
  );

  const {
    allowAccountChangesPermission,
    displayBasicAccountOptionsPermission,
  } = useCheckPermissions();
  const { isImpersonating } = useGetIsImpersonating();
  const isRemoveButtonAllowedForCurrentUser = !isImpersonating;

  const mappedUsers = usersState.map((user) => ({
    ...user,
    content: (
      <UserManagementRow
        name={`${user.FirstName} ${user.MiddleName ? user.MiddleName : ''} ${
          user.LastName
        }`}
        email={user.Email}
        key={user.UserUid}
        permission={userPermissionLiterals[user.RoleType]}
        status={parseEnumType(userStateType, user.StateType)}
      />
    ),
  }));

  const editPermission = (user) => {
    setUserEdited(user);
    setIsEditUserEmailModalOpen(true);
  };

  const activateAccount = (user) => {
    setUserEmail(user.Email);
    setIsActivateModalOpen(true);
    dispatch(
      updateUserState({
        userUid: user.UserUid,
        actionType: UUAT_ACTIVATE_PROFILE,
        data: [],
      }),
    );
  };

  const resendInvite = (user) => {
    setUserEmail(user.Email);
    setIsResendInvitationModalOpen(true);
    dispatch(
      updateAccountUser({
        accountUid,
        userUid: user.UserUid,
        actionType: ACCOUNT_RESEND_INVITE,
        data: [],
      }),
    );
  };

  const closeActivateAccountModal = () => {
    setIsActivateModalOpen(false);
    setUserEmail(null);
  };

  const closeResendInvitationModal = () => {
    setIsResendInvitationModalOpen(false);
    setUserEmail(null);
  };

  const rowData = formatUsersTableRowData(
    mappedUsers,
    setUserToRemove,
    editPermission,
    resendInvite,
    activateAccount,
    currentUser,
    isRemoveButtonAllowedForCurrentUser,
    isImpersonating,
    allowAccountChangesPermission,
    displayBasicAccountOptionsPermission,
  );
  const headerData = formatUsersTableHeaderData();

  const handleRemoveUserModalClose = () => {
    setUserToRemove(null);
  };

  const { gtmScreenView, appGtmEvent } = useGtmHook();

  useEffect(() => {
    gtmScreenView({
      path: window.location.pathname,
      title: 'Users',
    });
    appGtmEvent(ACCOUNT_DATA_LOADED);
  }, []); // eslint-disable-line

  useEffect(() => {
    dispatch(
      fetchAccountUsers({
        accountUid,
      }),
    );
  }, [accountUid, dispatch]);

  useEffect(() => {
    setUsersState(users);
  }, [users]); // eslint-disable-line

  useEffect(() => {
    if (userToRemove) {
      setRemoveUserModalOpen(true);
    } else {
      setRemoveUserModalOpen(false);
    }
  }, [userToRemove]);

  const AddNewUser = useCallback(
    () => (
      <ManagementAddNewContainer>
        <Button
          size="sm"
          variant="primary"
          type="button"
          onClick={() => setOpenAddNewUser(true)}
          disabled={
            !allowAccountChangesPermission ||
            displayBasicAccountOptionsPermission
          }
        >
          {t('userManagement.addNewBtn')}
        </Button>
      </ManagementAddNewContainer>
    ),
    [t, allowAccountChangesPermission, displayBasicAccountOptionsPermission],
  );

  return (
    <>
      <div data-cy="container-users-page">
        <NagDashboardBanner />
        <NavigationBar
          mainPage={t('header.navDropdownAccount')}
          subPage={t('header.accessManagement')}
          hideCtaOnMobile
          cta={<AddNewUser />}
        />
        <Section>
          <ManagementContainer>
            <AddNewUser />
            <Paragraph marginBottom={32}>
              {t('userManagement.managementDescription')}
            </Paragraph>
            {usersLoading ? (
              <BlockSectionLoader isLoading={usersLoading} />
            ) : (
              <>
                <Table css={managementTable}>
                  <TableHead css={managementTableHead} data={headerData} />
                  <TableBody css={managementTableBody}>
                    {!isEmpty(rowData) ? (
                      <TableRow
                        css={managementTableRow}
                        actionButtonsClassName={managementTableActions}
                        rowData={rowData}
                      />
                    ) : (
                      <ManagementTableEmpty>
                        <ManagementTableEmptyText>
                          {t('userManagement.noUsers')}
                        </ManagementTableEmptyText>
                      </ManagementTableEmpty>
                    )}
                  </TableBody>
                </Table>
                <ManagementGrid>
                  {!isEmpty(mappedUsers) ? (
                    mappedUsers.map((user) => (
                      <ManagementCard
                        editPermission={() => editPermission(user)}
                        handleRemoveUser={() => setUserToRemove(user.UserUid)}
                        handleActivateAccount={() => activateAccount(user)}
                        handleResendInvitation={() => resendInvite(user)}
                        key={user.UserUid}
                        name={`${user.FirstName || ''} ${
                          user.MiddleName || ''
                        } ${user.LastName || ''}`}
                        permission={userPermissionLiterals[user.RoleType]}
                        permissionType={user.RoleType}
                        status={parseEnumType(userStateType, user.StateType)}
                        userHasChangePermission={allowAccountChangesPermission}
                        isBasicAccount={displayBasicAccountOptionsPermission}
                        currentUser={currentUser}
                        user={user}
                        isRemoveButtonAllowedForCurrentUser={
                          isRemoveButtonAllowedForCurrentUser
                        }
                        isImpersonating={isImpersonating}
                      />
                    ))
                  ) : (
                    <ManagementTableEmpty>
                      <ManagementTableEmptyText>
                        {t('userManagement.noUsers')}
                      </ManagementTableEmptyText>
                    </ManagementTableEmpty>
                  )}
                </ManagementGrid>
              </>
            )}
          </ManagementContainer>
        </Section>
      </div>
      <EditUserModal
        isOpen={isEditUserEmailModalOpen}
        handleClose={() => setIsEditUserEmailModalOpen(false)}
        selectedUser={editedUser}
      />
      {removeUserModalOpen && (
        <RemoveUserModal
          isOpen={removeUserModalOpen}
          handleClose={handleRemoveUserModalClose}
          userUid={userToRemove}
        />
      )}

      <AddNewUserModal
        isOpen={openAddNewUser}
        handleClose={() => setOpenAddNewUser(false)}
      />
      {isActivateModalOpen && (
        <StatusModal
          isOpen={isActivateModalOpen}
          close={closeActivateAccountModal}
          hasError={activateError}
          isLoading={isLoadingActivateAccount}
          text={
            activateError ? (
              <span>{activateError}</span>
            ) : (
              <>
                <span>{t('userManagement.activate.activated')}</span>
                <span>{t('userManagement.activate.emailSent')}</span>
                <span>
                  {t('userManagement.activate.email', { email: userEmail })}
                </span>
              </>
            )
          }
          modalTitle={t('userManagement.activate.title')}
          backButtonText={t('common.ok')}
          onButtonClick={closeActivateAccountModal}
        />
      )}
      {isResendInvitationModalOpen && (
        <StatusModal
          isOpen={isResendInvitationModalOpen}
          close={closeResendInvitationModal}
          isLoading={isLoadingResendInvitation}
          hasError={resendError}
          text={
            resendError ? (
              <span>{resendError}</span>
            ) : (
              <>
                {t('userManagement.resendInvite.invitationSent')}{' '}
                <strong>
                  {t('userManagement.resendInvite.email', {
                    email: userEmail,
                  })}
                </strong>
              </>
            )
          }
          modalTitle={t('userManagement.resendInvite.title')}
          backButtonText={t('common.ok')}
          onButtonClick={closeResendInvitationModal}
        />
      )}
    </>
  );
};

export default UserManagementPage;
