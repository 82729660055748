import Select from 'react-select';
import styled from 'styled-components';
import themeColors from '../../../assets/styles/themeColors';

export const SelectInput = styled(Select)`
  .c-select__control {
    &.c-select__control {
      outline: none;
      border: 1px solid ${themeColors.colorInputBorder};
      border-radius: 4px;
      font-size: 16px;
      line-height: 1.75;
      height: 3.125rem;
      padding: 0 0.75rem;
      color: ${themeColors.colorLabel};
      background-color: ${themeColors.colorWhite};
      box-shadow: none;

      &:active,
      &:focus {
        outline: none;
      }

      &.c-select__control--is-focused {
        border-color: ${themeColors.colorPrimary};
        box-shadow: none;

        .c-select__indicator {
          svg {
            transform: rotate(-180deg);
          }
        }
      }

      .css-1uccc91-singleValue {
        color: ${themeColors.colorLabel};
        margin: 0;
      }

      .css-b8ldur-Input {
        margin: 0;
      }

      .c-select__value-container {
        height: 100%;
        padding: 0;
        padding-right: 2rem;
      }

      .c-select__input,
      .c-select__placeholder {
        font-size: 1rem;
        line-height: 1.75;
        letter-spacing: 0;
        color: ${themeColors.colorLabel};
      }

      .c-select__indicator-separator {
        display: none;
      }

      .c-select__indicator {
        padding: 0;

        > svg {
          transition: all ease-in-out 0.3s;
          width: 1rem;
          height: 1rem;
          color: ${themeColors.colorLabel};
          transform: rotate(0);
        }
      }

      &.c-select__control--is-disabled {
        background-color: ${themeColors.colorInputBackgroundDisabled};
      }
    }
  }

  .c-select__multiple {
    .c-select__menu {
      min-height: 10rem;
    }
  }

  .c-select__menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    border: 1px solid ${themeColors.colorInputBorder};
    border-radius: 4px;
    box-shadow: 0 3px 8px 0 rgba(112, 120, 135, 0.24);
    max-height: 10rem;
    min-height: max-content;
    overflow: auto;

    .c-select__menu-list {
      display: flex;
      flex-direction: column;
      padding: 0;
      flex-grow: 1;
    }

    .c-select__option,
    .c-select__menu-notice {
      padding: 0.75rem 0.9375rem;
      font-size: 1rem;
      line-height: 1.75;
      letter-spacing: 0;
      color: ${themeColors.colorLabel};
      text-align: left;
      cursor: pointer;

      &:hover {
        background-color: ${themeColors.colorInputSelectItemFocused};
      }

      &.c-select__option--is-selected {
        background-color: ${themeColors.colorInputSelectItemFocused};
      }

      &.c-select__option--is-focused {
        background-color: ${themeColors.colorInputSelectItemFocused};
      }
    }
  }

  @media only screen and (max-width: 1200px - 0.02px) {
    .c-select__control {
      &.c-select__control {
        font-size: 1rem;
        min-height: 0;

        .c-select__input,
        .c-select__placeholder {
          font-size: 1rem;
        }

        .c-select__indicator {
          > svg {
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }

    .c-select__menu {
      .c-select__option,
      .c-select__menu-notice {
        font-size: 1rem;
      }
    }
  }
`;
