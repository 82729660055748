import {
  REMOVE_USER,
  SET_USER,
  SET_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_PASS,
  USER_ACCOUNTS_FETCH,
  PASSWORD_FORGOTTEN_REQUEST,
  PASSWORD_FORGOTTEN_REQUEST_SUCCESS,
  PASSWORD_FORGOTTEN_REQUEST_ERROR,
  USER_SECURITY_QUESTION_FETCH,
  USER_SECURITY_QUESTION_FETCH_ERROR,
  USER_SECURITY_QUESTION_FETCH_SUCCESS,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_REQUEST_SUCCESS,
  RESET_PASSWORD_REQUEST_ERROR,
  REGISTRATION_RESET_PASSWORD_REQUEST,
  REGISTRATION_RESET_PASSWORD_REQUEST_SUCCESS,
  REGISTRATION_RESET_PASSWORD_REQUEST_ERROR,
  UPDATE_USER_STATE,
  UPDATE_USER_STATE_ERROR,
  UPDATE_USER_STATE_SUCCESS,
  FETCH_USERSNAMES,
  FETCH_USERSNAMES_SUCCESS,
  FETCH_USERSNAMES_ERROR,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  UPDATE_USER_REGISTRATION,
  UPDATE_USER_REGISTRATION_SUCCESS,
  UPDATE_USER_REGISTRATION_ERROR,
  USER_REGISTRATION_ACCOUNTS_FETCH,
  USER_REGISTRATION_ACCOUNTS_FETCH_SUCCESS,
  USER_REGISTRATION_ACCOUNTS_FETCH_ERROR,
  INVITE_USER,
  INVITE_USER_SUCCESS,
  INVITE_USER_ERROR,
  SECURITY_QUESTION_BY_USERNAME,
  SECURITY_QUESTION_BY_USERNAME_SUCCESS,
  SECURITY_QUESTION_BY_USERNAME_ERROR,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_REQUEST_SUCCESS,
  FORGOT_PASSWORD_REQUEST_ERROR,
  USER_PHONE_NUMBERS,
  USER_PHONE_NUMBERS_SUCCESS,
  USER_PHONE_NUMBERS_ERROR,
  DELETE_USER_PHONE_NUMBER,
  DELETE_USER_PHONE_NUMBER_ERROR,
  DELETE_USER_PHONE_NUMBER_SUCCESS,
  USER_PHONE_NUMBER,
  USER_PHONE_NUMBER_SUCCESS,
  USER_PHONE_NUMBER_ERROR,
  INVITE_USER_EMAIL,
  INVITE_USER_EMAIL_SUCCESS,
  INVITE_USER_EMAIL_ERROR,
  SUBMIT_PHONE,
  SUBMIT_PHONE_SUCCESS,
  SUBMIT_PHONE_ERROR,
} from './userActionConstants';

export const setUser = (payload) => ({
  type: SET_USER,
  payload,
});

export const setUserError = (payload) => ({
  type: SET_USER_ERROR,
  payload,
});

export const fetchUserAccounts = (payload) => ({
  type: USER_ACCOUNTS_FETCH,
  payload,
});

// security questions
export const fetchUserSecurityQuestion = (payload) => ({
  type: USER_SECURITY_QUESTION_FETCH,
  payload,
});

export const fetchUserSecurityQuestionSuccess = (payload) => ({
  type: USER_SECURITY_QUESTION_FETCH_SUCCESS,
  payload,
});

export const fetchUserSecurityQuestionError = (payload) => ({
  type: USER_SECURITY_QUESTION_FETCH_ERROR,
  payload,
});

// forgotten password
export const postPasswordForgottenRequest = (payload) => ({
  type: PASSWORD_FORGOTTEN_REQUEST,
  payload,
});

export const postPasswordForgottenRequestSuccess = (payload) => ({
  type: PASSWORD_FORGOTTEN_REQUEST_SUCCESS,
  payload,
});

export const postPasswordForgottenRequestError = (payload) => ({
  type: PASSWORD_FORGOTTEN_REQUEST_ERROR,
  payload,
});

// Registration forgotten password
export const postForgotPasswordRequest = (payload) => ({
  type: FORGOT_PASSWORD_REQUEST,
  payload,
});

export const postForgotPasswordRequestSuccess = (payload) => ({
  type: FORGOT_PASSWORD_REQUEST_SUCCESS,
  payload,
});

export const postForgotPasswordRequestError = (payload) => ({
  type: FORGOT_PASSWORD_REQUEST_ERROR,
  payload,
});

// reset password
export const postResetPasswordRequest = (payload) => ({
  type: RESET_PASSWORD_REQUEST,
  payload,
});

export const postResetPasswordRequestSuccess = (payload) => ({
  type: RESET_PASSWORD_REQUEST_SUCCESS,
  payload,
});

export const postResetPasswordRequestError = (payload) => ({
  type: RESET_PASSWORD_REQUEST_ERROR,
  payload,
});

// register reset password
export const postRegisterResetPasswordRequest = (payload) => ({
  type: REGISTRATION_RESET_PASSWORD_REQUEST,
  payload,
});

export const postRegisterResetPasswordRequestSuccess = (payload) => ({
  type: REGISTRATION_RESET_PASSWORD_REQUEST_SUCCESS,
  payload,
});

export const postRegisterResetPasswordRequestError = (payload) => ({
  type: REGISTRATION_RESET_PASSWORD_REQUEST_ERROR,
  payload,
});

// remove user
export const removeUser = (payload) => ({
  type: REMOVE_USER,
  payload,
});

// update user
export const updateUser = (payload) => ({
  type: UPDATE_USER,
  payload,
});

// update user account state
export const updateUserState = (payload) => ({
  type: UPDATE_USER_STATE,
  payload,
});

export const updateUserStateError = (payload) => ({
  type: UPDATE_USER_STATE_ERROR,
  payload,
});

export const updateUserStateSuccess = (payload) => ({
  type: UPDATE_USER_STATE_SUCCESS,
  payload,
});

// update user pass
export const updateUserPass = (payload) => ({
  type: UPDATE_USER_PASS,
  payload,
});

// register - get usernames by email
export const fetchUsernames = (payload) => ({
  type: FETCH_USERSNAMES,
  payload,
});

export const fetchUsernamesSuccess = (payload) => ({
  type: FETCH_USERSNAMES_SUCCESS,
  payload,
});

export const fetchUsernamesError = (payload) => ({
  type: FETCH_USERSNAMES_ERROR,
  payload,
});

export const createUser = (payload) => ({
  type: CREATE_USER,
  payload,
});

export const createUserSuccess = (payload) => ({
  type: CREATE_USER_SUCCESS,
  payload,
});

export const createUserError = (payload) => ({
  type: CREATE_USER_ERROR,
  payload,
});

export const updateUserRegistration = (payload) => ({
  type: UPDATE_USER_REGISTRATION,
  payload,
});
export const updateUserRegistrationSuccess = (payload) => ({
  type: UPDATE_USER_REGISTRATION_SUCCESS,
  payload,
});

export const updateUserRegistrationError = (payload) => ({
  type: UPDATE_USER_REGISTRATION_ERROR,
  payload,
});

export const fetchRegistrationUserAccounts = (payload) => ({
  type: USER_REGISTRATION_ACCOUNTS_FETCH,
  payload,
});

export const fetchRegistrationUserAccountsSuccess = (payload) => ({
  type: USER_REGISTRATION_ACCOUNTS_FETCH_SUCCESS,
  payload,
});

export const fetchRegistrationUserAccountsError = (payload) => ({
  type: USER_REGISTRATION_ACCOUNTS_FETCH_ERROR,
  payload,
});

export const inviteUser = (payload) => ({
  type: INVITE_USER,
  payload,
});

export const inviteUserSuccess = (payload) => ({
  type: INVITE_USER_SUCCESS,
  payload,
});

export const inviteUserError = (payload) => ({
  type: INVITE_USER_ERROR,
  payload,
});

export const inviteUserEmail = (payload) => ({
  type: INVITE_USER_EMAIL,
  payload,
});

export const inviteUserEmailSuccess = (payload) => ({
  type: INVITE_USER_EMAIL_SUCCESS,
  payload,
});

export const inviteUserEmailError = (payload) => ({
  type: INVITE_USER_EMAIL_ERROR,
  payload,
});

export const getSecurityQuestionByUsername = (payload) => ({
  type: SECURITY_QUESTION_BY_USERNAME,
  payload,
});

export const getSecurityQuestionByUsernameSuccess = (payload) => ({
  type: SECURITY_QUESTION_BY_USERNAME_SUCCESS,
  payload,
});

export const getSecurityQuestionByUsernameError = (payload) => ({
  type: SECURITY_QUESTION_BY_USERNAME_ERROR,
  payload,
});

export const fetchUserPhoneNumbers = (payload) => ({
  type: USER_PHONE_NUMBERS,
  payload,
});

export const fetchUserPhoneNumbersSuccess = (payload) => ({
  type: USER_PHONE_NUMBERS_SUCCESS,
  payload,
});

export const fetchUserPhoneNumbersError = (payload) => ({
  type: USER_PHONE_NUMBERS_ERROR,
  payload,
});

export const fetchUserPhoneNumber = (payload) => ({
  type: USER_PHONE_NUMBER,
  payload,
});

export const fetchUserPhoneNumberSuccess = (payload) => ({
  type: USER_PHONE_NUMBER_SUCCESS,
  payload,
});

export const fetchUserPhoneNumberError = (payload) => ({
  type: USER_PHONE_NUMBER_ERROR,
  payload,
});

export const deleteUserPhoneNumber = (payload) => ({
  type: DELETE_USER_PHONE_NUMBER,
  payload,
});

export const deleteUserPhoneNumberSuccess = (payload) => ({
  type: DELETE_USER_PHONE_NUMBER_SUCCESS,
  payload,
});

export const deleteUserPhoneNumberError = (payload) => ({
  type: DELETE_USER_PHONE_NUMBER_ERROR,
  payload,
});

// Add New Phone number
export const postPhoneNumber = (payload) => ({
  type: SUBMIT_PHONE,
  payload,
});

export const postPhoneNumberSuccess = (payload) => ({
  type: SUBMIT_PHONE_SUCCESS,
  payload,
});

export const postPhoneNumberError = (payload) => ({
  type: SUBMIT_PHONE_ERROR,
  payload,
});
