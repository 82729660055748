import React from 'react';
import PropType from 'prop-types';
import styled from 'styled-components';
import { uFlexColumn } from '../../../assets/styles/utility';
import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import { variables } from '../../../assets/styles/variables';

const PortfolioSectionContainer = styled.article`
  ${uFlexColumn};
  ${(props) => props.css}

  &:not(:last-child) {
    margin-bottom: ${pxToRem(60)};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    margin-bottom: ${pxToRemMd(48)};
  }
`;

const PortfolioSectionHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2.75rem;
  flex-wrap: wrap;
  gap: ${pxToRem(16)};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: 0 1.15rem;
    margin-bottom: 1.15rem;
    flex-wrap: wrap;
  }
`;

const PortfolioSectionHeaderCta = styled.div`
  display: flex;
  align-items: center;

  > :not(:last-child) {
    margin-right: 1.5rem;
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    > :not(:last-child) {
      margin-right: 0.57rem;
    }
  }
`;

const PortfolioSectionHeaderTitle = styled.h2`
  margin-right: auto;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.23;
  flex-shrink: 0;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: 1.7rem;
    line-height: 1.35;
    padding-right: ${pxToRem(32)};
  }
`;

const PortfolioSectionBody = styled.div`
  ${uFlexColumn};

  > :not(:last-child) {
    margin-bottom: ${pxToRem(32)};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    > :not(:last-child) {
      margin-bottom: ${pxToRemMd(16)};
    }
  }
`;

const PortfolioSection = ({ children, title, cta, ...props }) => (
  <PortfolioSectionContainer {...props}>
    <PortfolioSectionHeader>
      <PortfolioSectionHeaderTitle>{title}</PortfolioSectionHeaderTitle>
      {cta && <PortfolioSectionHeaderCta>{cta}</PortfolioSectionHeaderCta>}
    </PortfolioSectionHeader>
    <PortfolioSectionBody>{children}</PortfolioSectionBody>
  </PortfolioSectionContainer>
);

PortfolioSection.propTypes = {
  title: PropType.string,
  cta: PropType.node,
  children: PropType.node,
  className: PropType.string,
};

export default PortfolioSection;
