import * as Yup from 'yup';
import i18next from '../i18n';

export const quantityAndAddressValidationSchema = (unitsAvailableForDelivery) =>
  Yup.object({
    quantity: Yup.number()
      .required(i18next.t('validationSchema.quantity.quantityRequired'))
      .integer(i18next.t('validationSchema.quantity.wholeNumber'))
      .max(
        unitsAvailableForDelivery,
        i18next.t('validationSchema.general.insufficientUnits'),
      )
      .min(1, i18next.t('validationSchema.quantity.minRoundNumber')),
    address: Yup.object().shape({
      value: Yup.string().required(
        i18next.t('validationSchema.general.deliveryAddress'),
      ),
    }),
  });
