import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';
import Table from '../../Table/Table';
import TableHead from '../../Table/TableHead';
import TableBody from '../../Table/TableBody';
import TableRow from '../../Table/TableRow';
import TransactionRow from '../../TransactionTable/TransactionRow';
import { formatDate } from '../../../util/helpers/dateHelpers';
import {
  emptyPortfolioTransactionSingle,
  portfolioTransactionsSingleFetch,
} from '../../../store/actions/portfolio/portfolioActions';
import TransactionDetails from '../../Modals/Transaction/TransactionDetails';
import {
  selectPortfolioSingleTransaction,
  selectPortfolioSingleTransactionError,
} from '../../../store/selectors/portfolioTransactionsSelector';
import { headerData, equityHeaderData } from './headerData';
import { formatMoneyNumeral } from '../../../util/helpers/numeralHelpers';
import { transactionStatusTypesArray } from '../../../util/enum/api/transactionTypes';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import { transactionFieldFormatter } from '../../../util/helpers/transcation/transactionHelper';
import { selectIsLoadingByActionType } from '../../../store/selectors/loadingSelectors';
import { PORTFOLIO_TRANSACTIONS_SINGLE_LOADING } from '../../../store/actions/portfolio/portfolioActionConstants';
import SectionLoader from '../../Loader/SectionLoader';
import useCheckPermissions from '../../../util/hooks/useCheckPermissions';
import {
  balanceTransactionTableHead,
  balanceTransactionTableRow,
  balanceTransactionTableStyle,
} from '../../Table/Components/BalanceTransactionTable';
import {
  HoldingTableEmptyStyle,
  HoldingTableEmptyTextStyle,
} from '../../Table/Components/HoldingTable';

const BalanceTransactionsTable = ({
  data,
  accountUid,
  shouldShowTransactions,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { displayIRAEquityOptionsPermission } = useCheckPermissions();

  const [isTransactionDetailsOpen, setIsTransactionDetailsOpen] = useState(
    false,
  );

  const singleTransaction = useSelector(selectPortfolioSingleTransaction);

  const singleTransactionLoading = useSelector(
    selectIsLoadingByActionType(PORTFOLIO_TRANSACTIONS_SINGLE_LOADING),
  );

  const singleTransactionError = useSelector(
    selectPortfolioSingleTransactionError,
  );

  const [rowData, setRowData] = useState(null);

  useEffect(() => {
    if (data && data.length) {
      setRowData(
        data.map((transaction) => ({
          id: transaction.Uid,
          content: (
            <TransactionRow
              key={`${transaction.Uid}-${uuid()}`}
              month={formatDate(transaction.DateTime, 'MMM')}
              day={formatDate(transaction.DateTime, 'dd')}
              amount={transaction.Amount}
              balance={formatMoneyNumeral(transaction.EffectiveBalance)}
              status={parseEnumType(
                transactionStatusTypesArray,
                transaction.StatusType,
              )}
              action={
                transaction.Fields[0]
                  ? transactionFieldFormatter(transaction.Fields[0])
                  : null
              }
              product={
                transaction.Fields[1]
                  ? transactionFieldFormatter(transaction.Fields[1])
                  : null
              }
              isSegregated={transaction.IsSegregated}
            />
          ),
        })),
      );
    }
  }, [data]); //eslint-disable-line

  const onTransactionCardClick = (transactionUid) => {
    dispatch(
      portfolioTransactionsSingleFetch({
        accountUid,
        transactionUid,
      }),
    );
    setIsTransactionDetailsOpen(true);
  };

  if (isEmpty(rowData)) {
    return null;
  }

  return (
    <>
      <Table css={balanceTransactionTableStyle}>
        <TableHead
          data={
            displayIRAEquityOptionsPermission ? equityHeaderData : headerData
          }
          css={balanceTransactionTableHead}
          displayIRAEquityOptionsPermission={displayIRAEquityOptionsPermission}
        />
        <SectionLoader isLoading={singleTransactionLoading}>
          {shouldShowTransactions ? (
            <TableBody>
              <TableRow
                key={uuid()}
                css={balanceTransactionTableRow}
                displayIRAEquityOptionsPermission={
                  displayIRAEquityOptionsPermission
                }
                hasArrow
                onClick={(transaction) =>
                  onTransactionCardClick(transaction.id)
                }
                rowData={rowData}
                data-cy="list-item-transaction"
              />
            </TableBody>
          ) : (
            <HoldingTableEmptyStyle>
              <HoldingTableEmptyTextStyle>
                {t('balances.noRecentTransactions')}
              </HoldingTableEmptyTextStyle>
            </HoldingTableEmptyStyle>
          )}
        </SectionLoader>
      </Table>
      <TransactionDetails
        isOpen={!isEmpty(singleTransaction) && isTransactionDetailsOpen}
        onClose={() => {
          dispatch(emptyPortfolioTransactionSingle());
          setIsTransactionDetailsOpen(false);
        }}
        transaction={singleTransaction}
        error={singleTransactionError}
      />
    </>
  );
};

BalanceTransactionsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      DateTime: PropTypes.string,
      Amount: PropTypes.number,
      EffectiveBalance: PropTypes.number,
      StatusType: PropTypes.number,
      Caption: PropTypes.string,
    }),
  ),
  accountUid: PropTypes.string,
  shouldShowTransactions: PropTypes.bool,
};

export default BalanceTransactionsTable;
