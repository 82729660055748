import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import IconButton from '../IconButton/IconButton';
import { ReactComponent as Search } from '../../assets/images/svg/search.svg';
import { ReactComponent as EyeOn } from '../../assets/images/svg/eye-on.svg';
import { ReactComponent as EyeOff } from '../../assets/images/svg/eye-off.svg';
import { ReactComponent as CapsLock } from '../../assets/images/svg/caps-lock.svg';
import {
  InputError,
  InputLabel,
  InputHeader,
  InputLink,
  InputWrap,
  SearchIconWrap,
  CapsLocksIconWrap,
  PasswordIconWrap,
  InputFieldWrap,
  InputField,
} from './Components/InputComponents';

const BaseInputField = ({
  type,
  label,
  field,
  form,
  placeholder,
  clearPlaceholderOnFocus = true,
  isSearch,
  className,
  disabled,
  centerText,
  link,
  touched,
  errorMessage,
  demiBold,
  autoFocus,
  isCapsLockOn,
  maxlength,
  ...props
}) => {
  const [inputPlaceholder, setPlaceholder] = useState(placeholder);

  const inputField = useRef(null);

  useEffect(() => {
    if (autoFocus) {
      inputField.current.focus();
    }
  }, [autoFocus, inputField]);

  useEffect(() => {
    if (errorMessage) {
      form.setFieldError(field.name, errorMessage);
    }
  }, [errorMessage]); // eslint-disable-line

  useEffect(() => {
    setPlaceholder(placeholder);
  }, [placeholder]);

  useEffect(() => {
    if (type === 'number') {
      inputField.current.addEventListener(
        'wheel',
        (e) => {
          e.preventDefault();
        },
        { passive: false },
      );
    }
  }, [type]);

  const [inputType, setInputType] = useState('password');
  const passwordInput = type === 'password';

  const showPassword = () => {
    if (inputType === 'password') {
      setInputType('text');
    } else {
      setInputType('password');
    }
  };

  // Nester Formik Field Names get bugged because of Undefined values, so i had to fix it like this
  // If you ask why 0 and 1? I dont see a need for forms to be nested more then 2 levels?
  const fieldName = field.name.split('.');

  const formError =
    fieldName[0] && fieldName[1]
      ? form.errors[fieldName[0]] && form.errors[fieldName[0]][fieldName[1]]
      : form.errors[fieldName[0]];

  const formTouched =
    fieldName[0] && fieldName[1]
      ? form.touched[fieldName[0]] && form.touched[fieldName[0]][fieldName[1]]
      : form.touched[fieldName[0]];

  const additionalActions = () => {
    if (!clearPlaceholderOnFocus) {
      return null;
    }

    return {
      onFocus: () => {
        setPlaceholder('');
      },
      onBlur: (e) => {
        setPlaceholder(placeholder);
        field.onBlur(e);
      },
    };
  };

  return (
    <InputWrap isSearch={isSearch}>
      {(label || link) && (
        <InputHeader>
          {label && <InputLabel htmlFor={field.name}>{label}</InputLabel>}
          {link && <InputLink>{link}</InputLink>}
        </InputHeader>
      )}
      <InputFieldWrap>
        <InputField
          // eslint-disable-next-line
          tabIndex="1"
          id={field.name}
          ref={inputField}
          type={type === 'password' ? inputType : type}
          placeholder={inputPlaceholder}
          disabled={disabled}
          {...field}
          {...props}
          {...additionalActions()}
          maxLength={maxlength || '50'}
          centerText={centerText}
          isError={formError && formTouched}
        />
        {!!isSearch && (
          <SearchIconWrap>
            <Search />
          </SearchIconWrap>
        )}
        {!!passwordInput && (
          <>
            {isCapsLockOn && (
              <CapsLocksIconWrap>
                <CapsLock />
              </CapsLocksIconWrap>
            )}
            <PasswordIconWrap>
              <IconButton
                tabIndex="-1"
                type="button"
                onClick={() => {
                  showPassword();
                }}
              >
                {inputType === 'password' ? <EyeOff /> : <EyeOn />}
              </IconButton>
            </PasswordIconWrap>
          </>
        )}
      </InputFieldWrap>

      <ErrorMessage name={field.name}>
        {(errorMessage) => <InputError>{errorMessage}</InputError>}
      </ErrorMessage>
    </InputWrap>
  );
};

BaseInputField.propTypes = {
  type: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  form: PropTypes.shape({
    errors: PropTypes.shape({}),
    setFieldError: PropTypes.func,
    touched: PropTypes.shape({}),
  }),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  isSearch: PropTypes.bool,
  className: PropTypes.string,
  link: PropTypes.node,
  errorMessage: PropTypes.string,
  centerText: PropTypes.bool,
  clearPlaceholderOnFocus: PropTypes.bool,
  demiBold: PropTypes.bool,
  touched: PropTypes.bool,
  autoFocus: PropTypes.bool,
  isCapsLockOn: PropTypes.bool,
  maxlength: PropTypes.string,
  error: PropTypes.bool,
};

export default BaseInputField;
