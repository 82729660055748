import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  getCurrentAccountUid,
  selectAccountAddresses,
} from '../../store/selectors/accountSelectors';
import { fetchAccountAddresses } from '../../store/actions/account/accountActions';
import DeliveryAddress from './DeliveryAddress';
import { selectIsLoadingByActionTypes } from '../../store/selectors/loadingSelectors';
import {
  FETCH_ACCOUNT_ADDRESSES_LOADING,
  ACCOUNT_SETTINGS_LOADING,
} from '../../store/actions/account/accountActionConstants';

import SectionLoader from '../Loader/SectionLoader';
import Label from '../Notes/Label';
import ModalBody from '../Modal/ModalBody';
import NoAddress from '../NoAddress/NoAddress';
import ModalTitle from '../Modal/ModalTitle';
import ModalButtons from '../Modal/ModalButtons';
import TwoColumnList from '../TwoColumnList/TwoColumnList';
import Note from '../Notes/Note';
import TypeList from '../TypeList/TypeList';
import useRestrictionHook from '../../util/hooks/useRestrictionHook';
import { isActionRestrictedHelper } from '../../util/helpers/restrictionHelper';
import { restrictionTypes } from '../../util/enum/api/restrictionTypes';
import Restriction from '../Modals/RestrictionModal/Restriction';
import Paragraph from '../Paragraph/Paragraph';
import Button from '../Button/Button';
import AddAddressModal from '../Modals/Address/AddAddressModal';
import {
  removeFromSessionStorage,
  retrieveFromSessionStorage,
} from '../../util/helpers/sessionStorageHelper';
import {
  ADD_ADDRESS_WIZARD,
  STATUS_NEW_ADDRESS_ADDED,
} from '../../constants/sessionStorage';
import { AddressesPageEmpty } from '../../pages/Account/AddressesPage';
import { selectIsAddAddressesEnabled } from '../../store/selectors/settingsSelectors';

const ChooseDeliveryAddress = ({
  setWizardData,
  wizardData,
  handleNext,
  setWizardTitle,
  handleBuyWizardBack,
  openSupport,
  isSupportOpen,
  skipChooseLocation,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const restrictions = useRestrictionHook();
  const accountUid = useSelector(getCurrentAccountUid);
  const addresses = useSelector(selectAccountAddresses);
  const isNewAddressAdded = retrieveFromSessionStorage(
    STATUS_NEW_ADDRESS_ADDED,
  );
  const [selectedAddress, setSelectedAddress] = useState(
    () => wizardData?.deliveryAddress || {},
  );
  const [isNoAddressOpen, setIsNoAddressOpen] = useState(false);
  const [isAddAddressOpen, setIsAddAddressOpen] = useState(false);
  const isAddAddressesEnabled = useSelector(selectIsAddAddressesEnabled);
  const isLoading = useSelector(
    selectIsLoadingByActionTypes([
      FETCH_ACCOUNT_ADDRESSES_LOADING,
      ACCOUNT_SETTINGS_LOADING,
    ]),
  );

  useEffect(() => {
    if (accountUid) {
      dispatch(fetchAccountAddresses({ accountUid }));
    }
  }, [dispatch, accountUid]);

  useEffect(() => {
    if (addresses) {
      const defaultAddress = isNewAddressAdded
        ? addresses[addresses.length - 1]
        : addresses?.find((address) => address.IsDefaultMailingAddress);
      setSelectedAddress(defaultAddress);
    }
  }, [addresses, isNewAddressAdded]);

  const isDeliveryAddressSelected = (address) => {
    if (address.AddressUid === selectedAddress?.AddressUid) {
      return true;
    }

    return false;
  };

  const handleNextStep = () => {
    setWizardData({ ...wizardData, deliveryAddress: selectedAddress });
    handleNext();
  };

  useEffect(() => {
    if (isSupportOpen) {
      setWizardTitle(t('buyWizard.common.contactUs'));
      return;
    }

    if (isNoAddressOpen) {
      setWizardTitle(t('buyWizard.common.dontSeeAddress'));
      return;
    }

    setWizardTitle(
      t('buyWizard.buyForDelivery.chooseDeliveryAddress.modalTitle'),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSupportOpen, isNoAddressOpen]);

  if (
    isActionRestrictedHelper(restrictions, [
      restrictionTypes.LogonIsPrevented,
      restrictionTypes.RestrictBuyForDelivery,
    ])
  ) {
    return <Restriction restrictions={restrictions} />;
  }

  if (isNoAddressOpen) {
    return (
      <NoAddress
        openSupport={openSupport}
        isSupportOpen={isSupportOpen}
        onBack={() => setIsNoAddressOpen(false)}
      />
    );
  }

  if (isAddAddressOpen) {
    return (
      <AddAddressModal
        isOpen={isAddAddressOpen}
        onClose={() => {
          removeFromSessionStorage(ADD_ADDRESS_WIZARD);
          setIsAddAddressOpen(false);
        }}
      />
    );
  }

  const { storageProduct } = wizardData;

  return (
    <SectionLoader isLoading={isLoading}>
      <ModalBody>
        <ModalTitle marginBottom={4}>
          {storageProduct?.ProductCaption}
        </ModalTitle>
        <Label
          text={t('buyWizard.buyForDelivery.chooseDeliveryAddress.subtitle')}
        />
        <TwoColumnList marginBottom={8} marginTop={24}>
          <Label text={t('buyWizard.common.deliverTo')} />
          <Note
            onClick={() => setIsNoAddressOpen(true)}
            text={t('buyWizard.common.dontSeeAddress')}
          />
        </TwoColumnList>
        <TypeList>
          {addresses.map((address) => (
            <DeliveryAddress
              isSelected={isDeliveryAddressSelected(address)}
              onClick={setSelectedAddress}
              key={address.AddressUid}
              address={address}
            />
          ))}
        </TypeList>

        {!addresses?.length && !isLoading && (
          <AddressesPageEmpty>
            <Paragraph>{t('buyWizard.common.noAddressAdded')}</Paragraph>
          </AddressesPageEmpty>
        )}
        {isAddAddressesEnabled && (
          <Button
            textTransform="capitalize"
            variant="primary"
            size="sm"
            onClick={() => setIsAddAddressOpen(true)}
            marginTop={16}
          >
            {t('buyWizard.common.addAddress')}
          </Button>
        )}
        <ModalButtons
          isHorizontal
          marginTop
          primaryButtonProps={{
            onClick: handleNextStep,
            disabled: isEmpty(selectedAddress),
          }}
          secondaryButtonProps={{
            onClick: handleBuyWizardBack,
            label: skipChooseLocation ? t('common.cancel') : t('common.back'),
          }}
        />
      </ModalBody>
    </SectionLoader>
  );
};

ChooseDeliveryAddress.propTypes = {
  handleNext: PropTypes.func,
  handleBuyWizardBack: PropTypes.func,
  setWizardData: PropTypes.func,
  wizardData: PropTypes.shape({
    storageProduct: PropTypes.shape({
      ProductCaption: PropTypes.string,
    }),
    deliveryAddress: PropTypes.shape({}),
  }),
  setWizardTitle: PropTypes.func,
  openSupport: PropTypes.func,
  isSupportOpen: PropTypes.bool,
  skipChooseLocation: PropTypes.bool,
};

export default ChooseDeliveryAddress;
