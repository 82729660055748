import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';

const TwoColumnList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${pxToRem(8)};

  > :first-child {
    text-align: left;
  }

  > :last-child {
    text-align: right;
  }
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${pxToRem(marginBottom)}` : 0};
  margin-top ${({ marginTop }) => (marginTop ? `${pxToRem(marginTop)}` : 0)};

  ${({ largeGap }) => largeGap && `grid-gap: ${pxToRem(32)}`};
  ${({ rightFullWidth }) =>
    rightFullWidth &&
    `grid-template-columns: auto 1fr;

    > :last-child {
      text-align: right;
    }
  `};

  ${({ supportContainer }) =>
    supportContainer &&
    `grid-template-columns: 1.5fr 1fr;
     grid-gap: ${pxToRem(32)};
      > :last-child {
      text-align: left;
    }`};

  ${({ noTextAlign }) => noTextAlign && ` > :last-child { text-align: unset;}`};


  ${mediaBelow(variables.breakpoints.bpMd)} {
    grid-gap: ${pxToRemMd(8)};
    ${({ largeGap }) => largeGap && `grid-gap: ${pxToRemMd(32)}`};
    ${({ supportContainer }) =>
      supportContainer && `grid-template-columns: 1fr;`};}
`;

TwoColumnList.propTypes = {
  children: PropTypes.node,
  marginBottom: PropTypes.number,
  marginTop: PropTypes.number,
  rightFullWidth: PropTypes.bool,
  supportContainer: PropTypes.bool,
  largeGap: PropTypes.bool,
  noTextAlign: PropTypes.bool,
};

export default TwoColumnList;
