import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';

const SupportSearchContainerStyles = styled.div`
  form {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: ${pxToRem(24)};

    input,
    button {
      height: ${pxToRem(40)};
    }
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    form {
      input,
      button {
        height: ${pxToRemMd(40)};
      }
    }
  } ;
`;

const SupportSearchContainer = ({ children }) => (
  <SupportSearchContainerStyles>{children}</SupportSearchContainerStyles>
);

SupportSearchContainer.propTypes = {
  children: PropTypes.node,
};

export default SupportSearchContainer;
