import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ErrorMessage, useField } from 'formik';
import CurrencyInput from 'react-currency-input-field';
import { formatMoneyNumeral } from '../../util/helpers/numeralHelpers';
import {
  PLUS_SYMBOL,
  MINUS_SYMBOL,
  NUMPAD_MINUS_SYMBOL,
  NUMPAD_PLUS_SYMBOL,
  K_KEYCODE,
} from '../../constants/keyCodeConstants';
import { uFlexColumn, uOutlineNone } from '../../assets/styles/utility';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';
import { pxToRem } from '../../assets/styles/helper';
import {
  InputError,
  InputHeader,
  InputLabel,
} from './Components/InputComponents';

const CurrencyFieldContainer = styled.div`
  ${uFlexColumn};
  position: relative;

  > input {
    ${uOutlineNone};
    border: 1px solid ${themeColors.colorInputBorder};
    border-radius: ${variables.borderRadius.borderRadius};
    font-size: ${pxToRem(16)};
    line-height: 1.75;
    height: ${pxToRem(50)};
    padding: 0 ${pxToRem(12)};
    color: ${themeColors.colorLabel};
    background-color: ${themeColors.colorWhite};
    width: 100%;
    text-align: center;
    font-weight: 600;

    &:disabled {
      background-color: ${themeColors.colorInputBackgroundDisabled};
      border-color: ${themeColors.colorInputBorder};
    }

    &:focus {
      border-color: ${({ isError }) =>
        isError ? themeColors.colorError : themeColors.colorInputBorderFocused};
    }
  }

  ${({ isError }) =>
    isError &&
    `
    > input {
      border-color: ${themeColors.colorError};
    }
  `};

  ${(props) =>
    props.notCentered &&
    `
    > input {
      text-align: left;
    }
  `};

  ${(props) =>
    props.notBold &&
    `
    > input {
      font-weight: 400;
    }
  `};
`;

const CurrencyField = ({
  autoFocus,
  notCentered,
  notBold,
  label,
  onChange,
  value,
  ...props
}) => {
  const [field, meta] = useField(props);
  const inputField = useRef(null);

  useEffect(() => {
    if (autoFocus) {
      inputField.current.focus();
    }
  }, [autoFocus, inputField]);

  const onKeydownHandler = (event) => {
    if (
      event.keyCode === MINUS_SYMBOL ||
      event.keyCode === PLUS_SYMBOL ||
      event.keyCode === NUMPAD_MINUS_SYMBOL ||
      event.keyCode === NUMPAD_PLUS_SYMBOL ||
      event.keyCode === K_KEYCODE
    ) {
      event.preventDefault();
    }
  };

  const prefix = formatMoneyNumeral(0);
  const prefixSymbol = () => {
    if (prefix.includes('CAD')) {
      return 'CAD ';
    }

    return '$';
  };

  return (
    <CurrencyFieldContainer
      notCentered={notCentered}
      notBold={notBold}
      isError={meta.error && meta.touched}
    >
      <InputHeader>
        {label && <InputLabel htmlFor={field.name}>{label}</InputLabel>}
      </InputHeader>
      {value ? (
        <CurrencyInput
          {...props}
          prefix={prefixSymbol()}
          onValueChange={(value) => {
            onChange(value ? Number(value) : '');
          }}
          onKeyDown={(event) => onKeydownHandler(event)}
          ref={inputField}
          defaultValue={0}
          value={value}
          data-cy="input-amount"
        />
      ) : (
        <CurrencyInput
          {...props}
          prefix={prefixSymbol()}
          onValueChange={(value) => {
            onChange(value ? Number(value) : '');
          }}
          onKeyDown={(event) => onKeydownHandler(event)}
          ref={inputField}
          data-cy="input-amount"
        />
      )}
      <ErrorMessage name={field.name}>
        {(errorMessage) => <InputError>{errorMessage}</InputError>}
      </ErrorMessage>
    </CurrencyFieldContainer>
  );
};

CurrencyField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
  }),
  form: PropTypes.shape({}),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  notCentered: PropTypes.bool,
  notBold: PropTypes.bool,
  value: PropTypes.number,
};

export default CurrencyField;
