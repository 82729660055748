import createReducer from '../../utils/createReducer';
import {
  UPDATE_ACH_DEPOSIT_ERROR,
  UPDATE_ACH_DEPOSIT_SUCCESS,
} from '../../actions/orders/orderActionConstants';

const initialState = {
  errorMessage: '',
};
export default createReducer(
  {
    [UPDATE_ACH_DEPOSIT_ERROR]: setAchDepositError,
    [UPDATE_ACH_DEPOSIT_SUCCESS]: setAchDepoistSuccess,
  },
  initialState,
);

function setAchDepositError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}

function setAchDepoistSuccess(state) {
  return {
    ...state,
    errorMessage: '',
  };
}
