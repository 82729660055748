import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal/Modal';
import StepByStep from '../StepByStep';
import TwoFAChooseMethod from './TwoFAChooseMethod';
import TwoFAEmailWizard from './Email/TwoFAEmailWizard';
import TwoFAGoogleWizard from './Google/TwoFAGoogleWizard';
import TwoFAPhoneWizard from './PhoneNumber/TwoFAPhoneWizard';
import Status from '../Modals/Status';
import { closeWizardContent } from '../../util/helpers/wizardHelpers';
import { TWO_FA_SETUP_WIZARD } from '../../constants/sessionStorage';
import { parseEnumType } from '../../util/helpers/enumMappers';
import { twoFaTypesEnum } from '../../util/enum/api/twoFATypes';
import {
  closeTwoFaContent,
  getTwoFaContent,
  setTwoFaContent,
} from '../../util/helpers/twoFaSetupHelper';
import { postTwoFaCode } from '../../store/actions/twoFA/twoFAActions';
import { useBrokerName } from '../../util/hooks/userBrokerName';

const twoFaSetupSteps = [
  TwoFAChooseMethod,
  {
    bySelectedType: {
      AuthenticatorApplication: TwoFAGoogleWizard,
      Phone: TwoFAPhoneWizard,
      Email: TwoFAEmailWizard,
    },
  },
  Status,
];

const TwoFASetup = ({ isModalOpen, closeModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const brokerName = useBrokerName();
  const [twoFaValue, setValues] = useState(() => getTwoFaContent() || {});
  // eslint-disable-next-line no-unused-vars
  const [isSuccessful, setIsSuccessful] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleModalClose = () => {
    closeWizardContent();
    closeModal();
    setValues({});
  };

  const setTwoFaValues = (data) => {
    if (data === null) {
      setValues({});
      closeTwoFaContent();
      return;
    }

    setValues({ ...twoFaValue, ...data });
    setTwoFaContent(TWO_FA_SETUP_WIZARD, { ...twoFaValue, ...data });
  };

  const saveFormValuesOnSuccess = (values) => {
    setTwoFaValues(values);
  };

  const getTwoFaCode = (value) => {
    const { requestData, userUid, onSuccess, onError, onReset } = value;
    dispatch(
      postTwoFaCode({
        requestData,
        userUid,
        saveFormValuesOnSuccess,
        onSuccess,
        onError,
        onReset,
      }),
    );
  };

  const statusText = () => {
    if (!isSuccessful) {
      return errorMessage;
    }

    switch (twoFaValue?.AuthenticationMethodType) {
      case parseEnumType(twoFaTypesEnum, 1):
        return t('twoFa.profileSettings.success.authentication', {
          brokerName,
        });
      case parseEnumType(twoFaTypesEnum, 2):
        return t('twoFa.profileSettings.success.phone');
      case parseEnumType(twoFaTypesEnum, 3):
        return t('twoFa.profileSettings.success.email');

      default:
        break;
    }
  };

  return !isModalOpen ? null : (
    <Modal
      isOpen={isModalOpen}
      size="sm"
      title={t('twoFa.profileSettings.title')}
      close={closeModal}
    >
      <StepByStep sessionScopeStep="setup-2fa">
        {({ goStepBack, goStepForward }) =>
          twoFaSetupSteps.map((modal) => {
            if (modal.bySelectedType) {
              if (!twoFaValue?.AuthenticationMethodType) {
                return null;
              }

              return React.createElement(
                modal.bySelectedType[twoFaValue?.AuthenticationMethodType],
                {
                  handleBack: goStepBack,
                  handleClose: handleModalClose,
                  handleNext: goStepForward,
                  setTwoFaValues,
                  isModalOpen,
                  twoFaValue,
                  getTwoFaCode,
                  setIsSuccessful,
                  setErrorMessage,
                },
              );
            }

            return React.createElement(modal, {
              handleBack: goStepBack,
              handleClose: handleModalClose,
              handleNext: goStepForward,
              setTwoFaValues,
              isModalOpen,
              twoFaValue,
              onButtonClick: handleModalClose,
              backButtonText: t('common.ok'),
              text: statusText(),
              hasError: !isSuccessful,
              getTwoFaCode,
              setIsSuccessful,
              setErrorMessage,
            });
          })
        }
      </StepByStep>
    </Modal>
  );
};

TwoFASetup.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  balances: PropTypes.shape({}),
};

export default TwoFASetup;
