import createReducer from '../../utils/createReducer';
import { CLEAR_SELL_WIZARD_STATE } from '../../actions/sell/sellWizardActionConstants';
import {
  PORTFOLIO_PRODUCT_BY_SYMBOL_FETCH_ERROR,
  PORTFOLIO_PRODUCT_BY_SYMBOL_FETCH_SUCCESS,
} from '../../actions/portfolio/portfolioActionConstants';

const initialState = {
  product: {},
};

export default createReducer(
  {
    [CLEAR_SELL_WIZARD_STATE]: clearSellWizardState,
    [PORTFOLIO_PRODUCT_BY_SYMBOL_FETCH_SUCCESS]: setPortfolioProduct,
    [PORTFOLIO_PRODUCT_BY_SYMBOL_FETCH_ERROR]: setPortfolioProductError,
  },
  initialState,
);

function clearSellWizardState() {
  return initialState;
}

function setPortfolioProduct(state, action) {
  return {
    ...state,
    product: action.payload,
    errorMessage: '',
  };
}

function setPortfolioProductError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}
