import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import StandardDepositSelectedFlow from './StandardDepositSelectedFlow';
import SelectStandardDepositType from './SelectStandardDepositType';
import {
  removeFromSessionStorage,
  retrieveFromSessionStorage,
  storeInSessionStorage,
} from '../../../../util/helpers/sessionStorageHelper';
import {
  STANDARD_DEPOSIT_WIZARD,
  WIZARD,
  WIZARD_DATA,
} from '../../../../constants/sessionStorage';
import Modal from '../../../Modal/Modal';
import StepByStep from '../../../StepByStep';

const steps = [SelectStandardDepositType, StandardDepositSelectedFlow];

const StandardDepositWizard = ({
  isModalOpen,
  handleClose,
  handleRefreshTransactions,
}) => {
  const { t } = useTranslation();
  const [wizardTitle, setWizardTitle] = useState(
    t('depositWizard.depositFunds'),
  );
  const [modalSize, setModalSize] = useState('md');
  const [wizardData, setWizardData] = useState(
    () => retrieveFromSessionStorage(WIZARD_DATA) || {},
  );
  const [wizardBack, setWizardBack] = useState(null);

  const setDataForWizard = (value) => {
    setWizardData({ ...wizardData, ...value });
    storeInSessionStorage(WIZARD_DATA, { ...wizardData, ...value });
  };

  const closeModal = () => {
    handleClose();
    removeFromSessionStorage(WIZARD);
    removeFromSessionStorage(WIZARD_DATA);
  };

  return (
    <Modal
      title={wizardTitle}
      size={modalSize}
      isOpen={isModalOpen}
      close={closeModal}
      goBack={wizardBack}
      dataCy="container-deposit-funds-modal"
    >
      <StepByStep sessionScopeStep={STANDARD_DEPOSIT_WIZARD}>
        {({ goStepForward, goStepBack }) =>
          steps.map((StandardDepositWizardStep) =>
            React.createElement(StandardDepositWizardStep, {
              handleBack: goStepBack,
              handleNext: goStepForward,
              handleClose: closeModal,
              wizardData,
              setWizardData: setDataForWizard,
              setWizardTitle,
              setModalSize,
              setWizardBack,
              handleRefreshTransactions,
            }),
          )
        }
      </StepByStep>
    </Modal>
  );
};

StandardDepositWizard.propTypes = {
  handleClose: PropTypes.func,
  isModalOpen: PropTypes.bool,
  handleRefreshTransactions: PropTypes.func,
};

export default StandardDepositWizard;
