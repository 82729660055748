import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  getStylesByProps,
  pxToRem,
  mediaBelow,
} from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';

const FormContainerElement = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  ${({ hasError }) =>
    !hasError &&
    `grid-gap: ${pxToRem(32)};
`}
  ${(props) => getStylesByProps(props)};

  ${({ isHorizontal }) =>
    isHorizontal &&
    `grid-template-columns: 1fr 1fr;

    ${mediaBelow(variables.breakpoints.bpMd)} {
      grid-template-columns: 1fr;
    }
  `}

  ${({ fourColumn }) =>
    fourColumn &&
    `grid-template-columns: 1fr 1fr 1fr 120px;

    ${mediaBelow(variables.breakpoints.bpMd)} {
      grid-template-columns: 1fr;
    }
    `}
`;

const FormContainer = ({
  children,
  marginBottom,
  marginTop,
  maxWidth,
  isHorizontal,
  fourColumn,
  hasError,
}) => (
  <FormContainerElement
    marginBottom={marginBottom}
    marginTop={marginTop}
    maxWidth={maxWidth}
    isHorizontal={isHorizontal}
    fourColumn={fourColumn}
    hasError={hasError}
  >
    {children}
  </FormContainerElement>
);

FormContainer.propTypes = {
  children: PropTypes.node,
  marginBottom: PropTypes.number,
  marginTop: PropTypes.number,
  maxWidth: PropTypes.number,
  isHorizontal: PropTypes.bool,
  fourColumn: PropTypes.bool,
  hasError: PropTypes.bool,
};

export default FormContainer;
