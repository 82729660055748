import styled from 'styled-components';
import { pxToRem } from '../../assets/styles/helper';
import { uFlexColumn } from '../../assets/styles/utility';

const SingleColumnList = styled.div`
  ${uFlexColumn};
  ${({ textAlignLeft }) => textAlignLeft && `text-align: left`};
  ${({ textAlignCenter }) => textAlignCenter && `text-align: center`};
  ${({ alignCenter }) => alignCenter && `align-items: center`};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${pxToRem(marginBottom)}` : 0};
  margin-top: ${({ marginTop }) => (marginTop ? `${pxToRem(marginTop)}` : 0)};
`;

export default SingleColumnList;
