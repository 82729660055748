import { useState } from 'react';
import { getWizardContent } from '../helpers/wizardHelpers';

export const useSessionStorageState = (key, initialValue) => {
  const [state, setState] = useState(
    () => getWizardContent(key) || initialValue,
  );

  return [state, setState];
};
