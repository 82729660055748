import {
  FETCH_DOCUMENT_BY_DOCUMENT_URL,
  FETCH_DOCUMENT_BY_TYPE,
  FETCH_DOCUMENT_BY_YEAR,
  FETCH_DOCUMENT_BY_YEAR_ERROR,
  FETCH_DOCUMENT_BY_YEAR_SUCCESS,
} from './documentActionConstants';

// users for account
export const fetchDocumentsByYear = (payload) => ({
  type: FETCH_DOCUMENT_BY_YEAR,
  payload,
});

export const fetchDocumentsByYearSuccess = (payload) => ({
  type: FETCH_DOCUMENT_BY_YEAR_SUCCESS,
  payload,
});

export const fetchDocumentsByYearError = (payload) => ({
  type: FETCH_DOCUMENT_BY_YEAR_ERROR,
  payload,
});

// fetch document by type
export const fetchDocumentByType = (payload) => ({
  type: FETCH_DOCUMENT_BY_TYPE,
  payload,
});

// fetch document by document URL
export const fetchDocumentByDocumentUrl = (payload) => ({
  type: FETCH_DOCUMENT_BY_DOCUMENT_URL,
  payload,
});
