import {
  PRODUCT_FOR_REVIEW_SET,
  PRODUCT_SYMBOL_PRICES_FETCH,
  PRODUCT_SYMBOL_PRICES_FETCH_ERROR,
  PRODUCT_SYMBOL_PRICES_FETCH_SUCCESS,
  PRODUCTS_PRICES_FETCH,
  PRODUCTS_PRICES_FETCH_ERROR,
  PRODUCTS_PRICES_FETCH_SUCCESS,
  PRODUCT_TIERS_FETCH,
  PRODUCT_TIERS_FETCH_SUCCESS,
  PRODUCT_TIERS_FETCH_ERROR,
  PRODUCT_TIERS_EMPTY,
  VERIFY_DELIVER_FROM_STORAGE_DATA,
  VERIFY_DELIVER_FROM_STORAGE_DATA_SUCCESS,
  VERIFY_DELIVER_FROM_STORAGE_DATA_ERROR,
  DELIVER_FROM_STORAGE_SUBMIT,
  CONVERT_PRODUCT_FETCH,
  CONVERT_PRODUCT_FETCH_SUCCESS,
  CONVERT_PRODUCT_FETCH_ERROR,
} from './productActionConstants';

// product prices

export const fetchProductsWithPrices = (payload) => ({
  type: PRODUCTS_PRICES_FETCH,
  payload,
});

export const fetchProductsWithPricesSuccess = (payload) => ({
  type: PRODUCTS_PRICES_FETCH_SUCCESS,
  payload,
});

export const fetchProductsWithPricesError = (payload) => ({
  type: PRODUCTS_PRICES_FETCH_ERROR,
  payload,
});

// product tiers
export const emptyProductTiers = () => ({
  type: PRODUCT_TIERS_EMPTY,
});

export const fetchProductTiers = (payload) => ({
  type: PRODUCT_TIERS_FETCH,
  payload,
});

export const fetchProductTiersSuccess = (payload) => ({
  type: PRODUCT_TIERS_FETCH_SUCCESS,
  payload,
});

export const fetchProductTiersError = (payload) => ({
  type: PRODUCT_TIERS_FETCH_ERROR,
  payload,
});

export const fetchProductSymbolPrices = (payload) => ({
  type: PRODUCT_SYMBOL_PRICES_FETCH,
  payload,
});
export const fetchProductSymbolPricesSuccess = (payload) => ({
  type: PRODUCT_SYMBOL_PRICES_FETCH_SUCCESS,
  payload,
});
export const fetchProductSymbolPricesError = (payload) => ({
  type: PRODUCT_SYMBOL_PRICES_FETCH_ERROR,
  payload,
});

export const setProductForReview = (payload) => ({
  type: PRODUCT_FOR_REVIEW_SET,
  payload,
});

// deliver from storage
export const verifyDeliverFromStorageData = (payload) => ({
  type: VERIFY_DELIVER_FROM_STORAGE_DATA,
  payload,
});

export const verifyDeliverFromStorageDataSuccess = (payload) => ({
  type: VERIFY_DELIVER_FROM_STORAGE_DATA_SUCCESS,
  payload,
});

export const verifyDeliverFromStorageDataError = (payload) => ({
  type: VERIFY_DELIVER_FROM_STORAGE_DATA_ERROR,
  payload,
});

export const deliverFromStorageSubmit = (payload) => ({
  type: DELIVER_FROM_STORAGE_SUBMIT,
  payload,
});

export const fetchConvertProduct = (payload) => ({
  type: CONVERT_PRODUCT_FETCH,
  payload,
});

export const fetchConvertProductSuccess = (payload) => ({
  type: CONVERT_PRODUCT_FETCH_SUCCESS,
  payload,
});

export const fetchConvertProductError = (payload) => ({
  type: CONVERT_PRODUCT_FETCH_ERROR,
  payload,
});
