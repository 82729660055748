import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash.startcase';
import styled from 'styled-components';
import { parseEnumType } from '../../../../util/helpers/enumMappers';
import { productLocationTypes } from '../../../../util/enum/api/productTypes';
import { uFlexCenter } from '../../../../assets/styles/utility';
import {
  mediaBelow,
  pxToRem,
  pxToRemMd,
} from '../../../../assets/styles/helper';
import { variables } from '../../../../assets/styles/variables';
import themeColors from '../../../../assets/styles/themeColors';

export const TransactionDetailContainer = styled.div`
  ${uFlexCenter};
  flex-direction: column;
  margin-top: ${pxToRem(-8)};
  margin-bottom: ${pxToRem(24)};
  text-align: center;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-top: ${pxToRemMd(-6)};
    margin-bottom: ${pxToRemMd(24)};
    padding: ${pxToRemMd(4)} ${pxToRemMd(16)};
  }
`;

export const TransactionDetailCaption = styled.h2`
  font-size: ${pxToRem(20)};
  line-height: 1.4;
  font-weight: 600;
  margin-bottom: ${pxToRem(2)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(20)};
    margin-bottom: ${pxToRemMd(2)};
  }
`;

export const TransactionDetailLocation = styled.p`
  color: ${themeColors.colorTextSecondary};
  font-weight: 600;
  text-align: center;
`;

const ProductField = ({ field, isSegregated, segregatedCaption }) => (
  <TransactionDetailContainer>
    <TransactionDetailCaption>{field.ProductCaption}</TransactionDetailCaption>
    <TransactionDetailLocation>
      {startCase(parseEnumType(productLocationTypes, field.LocationType))}{' '}
      {isSegregated && `(${segregatedCaption})`}
    </TransactionDetailLocation>
  </TransactionDetailContainer>
);

ProductField.propTypes = {
  field: PropTypes.shape({
    LocationType: PropTypes.number,
    ProductCaption: PropTypes.string,
  }),
  isSegregated: PropTypes.bool,
  segregatedCaption: PropTypes.string,
};

export default ProductField;
