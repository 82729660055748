import { useEffect } from 'react';
import {
  COUNTDOWN_TIMER_INTERVAL_MS,
  COUNTDOWN_TIMER_INTERVAL_SEC,
} from '../../constants/timerConstants';

export default function useStartTimer(
  countdownInMilliseconds,
  periodicCallback,
  stop,
) {
  useEffect(() => {
    let timeIntervalId = null;
    if (!stop && countdownInMilliseconds > 0) {
      let counter = Math.floor(
        countdownInMilliseconds / COUNTDOWN_TIMER_INTERVAL_MS,
      );
      let unity = false;
      timeIntervalId = setInterval(() => {
        if (periodicCallback) {
          periodicCallback({ data: [counter, unity] });
        }
        unity = counter === 2;
        if (counter >= 0) {
          counter -= COUNTDOWN_TIMER_INTERVAL_SEC;
        }
        if (counter < 0) {
          counter = Math.floor(
            countdownInMilliseconds / COUNTDOWN_TIMER_INTERVAL_MS,
          );
        }
      }, COUNTDOWN_TIMER_INTERVAL_MS);
    }

    return () => clearInterval(timeIntervalId);
  }, [countdownInMilliseconds, periodicCallback, stop]);
}
