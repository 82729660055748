import * as Yup from 'yup';
import i18next from '../i18n';
import { formatMoneyNumeral } from '../util/helpers/numeralHelpers';

export const wireWithdrawalValidationSchema = (isImpersonating, maxAmount) => {
  const amount = Yup.number()
    .required(i18next.t('validationSchema.general.amountRequired'))
    .min(
      0.01,
      i18next.t('validationSchema.withdrawal.withdrawalMinAmount', {
        amount: formatMoneyNumeral(0.01),
      }),
    )
    .max(maxAmount, i18next.t('validationSchema.general.insufficientFunds'));

  if (isImpersonating) {
    return Yup.object().shape({
      amount,
      wireWithdrawalFeeCredit: Yup.number()
        .required(
          i18next.t(
            'validationSchema.withdrawal.wireWithdrawalFeeCreditRequired',
          ),
        )
        .min(
          0,
          i18next.t('validationSchema.withdrawal.withdrawalMinAmount', {
            amount: formatMoneyNumeral(0),
          }),
        )
        .max(25, i18next.t('validationSchema.general.insufficientFunds')),
    });
  }

  return Yup.object().shape({
    amount,
  });
};
