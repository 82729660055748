import { call, takeLatest, all, put } from '@redux-saga/core/effects';
import {
  FETCH_DOCUMENT_BY_TYPE,
  FETCH_DOCUMENT_BY_YEAR,
  FETCH_DOCUMENT_BY_DOCUMENT_URL,
} from '../actions/documents/documentActionConstants';
import {
  fetchDocumentsByYearError,
  fetchDocumentsByYearSuccess,
} from '../actions/documents/documentActions';
import {
  getDocuments,
  getDocument,
  getDocumentByType,
} from '../../request/documentRequests';
import { rejectErrorCodeHelper } from '../../util/helpers/rejectErrorCodeHelper';

function* fetchDocumentsByYear({ payload }) {
  try {
    const { data } = yield call(getDocuments, payload.accountUid, payload.year);
    yield put(fetchDocumentsByYearSuccess(data));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchDocumentsByYearError(errorMessage));
  }
}

function* fetchDocumentByDocumentURL({ payload }) {
  if (payload.setIsDownloadLoading) {
    payload.setIsDownloadLoading(true);
  }

  try {
    const { data } = yield call(getDocument, payload.documentUrl);
    const downloadUrl = window.URL.createObjectURL(data);
    window.open(downloadUrl, '_blank');
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    console.log(errorMessage);
  } finally {
    if (payload.setIsDownloadLoading) {
      payload.setIsDownloadLoading(false);
    }
  }
}

function* fetchDocumentByType({ payload }) {
  if (payload.setIsDownloadLoading) {
    payload.setIsDownloadLoading(true);
  }

  try {
    const { data } = yield call(
      getDocumentByType,
      payload.accountUid,
      payload.documentType,
    );

    const downloadUrl = window.URL.createObjectURL(data);
    window.open(downloadUrl, '_blank');
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    console.log(errorMessage);
  } finally {
    if (payload.setIsDownloadLoading) {
      payload.setIsDownloadLoading(false);
    }
  }
}

export default function* documentsSaga() {
  yield all([
    takeLatest(FETCH_DOCUMENT_BY_YEAR, fetchDocumentsByYear),
    takeLatest(FETCH_DOCUMENT_BY_DOCUMENT_URL, fetchDocumentByDocumentURL),
    takeLatest(FETCH_DOCUMENT_BY_TYPE, fetchDocumentByType),
  ]);
}
