import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as TrendingDown } from '../../../assets/images/svg/trending-down.svg';
import { ReactComponent as TrendingUp } from '../../../assets/images/svg/trending-up.svg';
import { formatMoneyNumeral } from '../../../util/helpers/numeralHelpers';
import themeColors from '../../../assets/styles/themeColors';
import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import { variables } from '../../../assets/styles/variables';

export const PortfolioTrendContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${pxToRem(6)};

  > svg {
    height: ${pxToRem(16)};
    color: currentColor;
  }

  > :not(:first-child) {
    margin-left: ${pxToRem(4)};
  }

  > :last-child {
    margin-left: ${pxToRem(8)};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-bottom: ${pxToRemMd(6)};
    font-size: ${pxToRemMd(14)};

    > svg {
      height: ${pxToRemMd(16)};
    }
  }
`;

const PortfolioTrendPositive = styled(PortfolioTrendContainer)`
  color: ${themeColors.colorSuccess};
`;

const PortfolioTrendNegative = styled(PortfolioTrendContainer)`
  color: ${themeColors.colorError};
`;

const PortfolioTrend = ({ change, percentage, noData }) => {
  if (noData) {
    return (
      <PortfolioTrendPositive>
        <TrendingUp />
        <span>{formatMoneyNumeral(0.0)}</span>
        <span>0.00 %</span>
      </PortfolioTrendPositive>
    );
  }

  if (change >= 0) {
    return (
      <PortfolioTrendPositive>
        <TrendingUp />
        <span>{formatMoneyNumeral(change.toFixed(2))}</span>
        <span>{percentage?.toFixed(2)} %</span>
      </PortfolioTrendPositive>
    );
  }

  if (change < 0) {
    return (
      <PortfolioTrendNegative>
        <TrendingDown />
        <span>{formatMoneyNumeral(change.toFixed(2))}</span>
        <span>{percentage?.toFixed(2)} %</span>
      </PortfolioTrendNegative>
    );
  }
};

PortfolioTrend.propTypes = {
  change: PropTypes.number,
  percentage: PropTypes.number,
  noData: PropTypes.bool,
};

export default PortfolioTrend;
