import styled from 'styled-components';
import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import themeColors from '../../../assets/styles/themeColors';
import { uFlexColumn, uTransition } from '../../../assets/styles/utility';
import { variables } from '../../../assets/styles/variables';

export const TableStyle = styled.div`
  ${uFlexColumn};
  ${(props) => props.css};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    ${(props) =>
      props.isLocationTableMobile &&
      `
      margin-bottom: 0;
    `};
  }
`;

export const TableDataStyle = styled.div`
  display: flex;
  align-items: center;
  font-size: ${pxToRem(18)};
  line-height: 1.35;
  width: 100%;
  ${(props) => props.css};
`;

export const TableHeadStyle = styled.div`
  background-color: ${themeColors.colorTableHeaderBackground};
  box-shadow: ${variables.shadow.boxShadow};
  z-index: ${variables.zIndex.indexXxs};
  padding: ${pxToRem(16)} ${pxToRem(24)};
  width: 100%;
  ${(props) => props.css};

  ${TableDataStyle} {
    line-height: 1.75;
    letter-spacing: 0;
    font-weight: 600;
  }
`;

export const TableRowStyle = styled.div`
  display: flex;
  padding: ${pxToRem(8)} ${pxToRem(24)};
  border-radius: ${variables.borderRadius.borderRadius};
  ${(props) => props.css};
`;

export const TableBodyStyle = styled.div`
  ${(props) => props.css};

  ${TableRowStyle} {
    &:nth-child(even) {
      background-color: ${themeColors.colorTableEvenRowBackground};
    }
    &:hover {
      background-color: ${themeColors.colorTableEvenRowHoverBackground};
    }
  }
`;

export const TableActionButtonsStyle = styled.div`
  display: flex;
  ${(props) => props.css};

  > :not(:last-child) {
    margin-right: ${pxToRem(24)};
  }
`;

export const TableArrowIconStyle = styled.div`
  width: ${pxToRem(16)};
  height: ${pxToRem(16)};

  svg {
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
  }
`;

export const TableDropdownWrapStyle = styled.div`
  ${(props) => props.css};
`;

export const TableDropdownContentStyle = styled.div`
  display: flex;
  width: 100%;
`;

export const TableDropdownIconStyle = styled.div`
  ${uTransition};
  width: ${pxToRemMd(16)};
  height: ${pxToRemMd(16)};

  ${(props) =>
    props.isOpen &&
    `
  svg {
    transform: rotate(180deg);
  }
  `};
`;

export const TableDropdownStyle = styled.div`
  width: 100%;
  padding: ${pxToRemMd(8)} 0;

  ${TableActionButtonsStyle} {
    display: flex;

    > :nth-child(2) {
      margin-left: ${pxToRemMd(14)};
    }

    > button {
      flex: 1 1 0;
    }
  }
`;
