import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';

import styled from 'styled-components';
import Investment from './Investment';
import { fetchProductsWithPrices } from '../../store/actions/products/productActions';
import { selectProductPrices } from '../../store/selectors/productsSelector';
import { uFlexColumn } from '../../assets/styles/utility';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';

const SelectedInvestmentContainer = styled.div`
  ${uFlexColumn};
`;

const SelectedInvestmentTitle = styled.h3`
  font-size: ${pxToRem(18)};
  line-height: 1.33;
  font-weight: bold;
  margin-bottom: ${pxToRem(16)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(18)};
    margin-bottom: ${pxToRemMd(16)};
  }
`;

const SelectedInvestmentInvestments = styled.div`
  display: grid;
  grid-template-columns: ${pxToRem(400)} ${pxToRem(400)};
  grid-column-gap: ${pxToRem(39)};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    grid-template-columns: ${pxToRem(400)};
    grid-row-gap: ${pxToRem(16)};

    > div {
      width: 100%;
    }
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    grid-template-columns: 1fr;
    grid-row-gap: ${pxToRemMd(16)};
  }
`;

const SelectedInvestment = ({ gold, silver, accountUid, amount }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const productsWithPrices = useSelector(selectProductPrices);

  useEffect(() => {
    if (accountUid) {
      if (
        !isEmpty(gold) &&
        !isEmpty(silver) &&
        gold.SymbolCode &&
        silver.SymbolCode
      ) {
        dispatch(
          fetchProductsWithPrices({
            accountUid,
            side: 'Buy',
            symbols: `${gold.SymbolCode},${silver.SymbolCode}`,
            productType: 'fractional',
          }),
        );
      } else if (!isEmpty(silver) && silver.SymbolCode) {
        dispatch(
          fetchProductsWithPrices({
            accountUid,
            side: 'Buy',
            symbols: `${silver.SymbolCode}`,
            productType: 'fractional',
          }),
        );
      } else if (!isEmpty(gold) && gold.SymbolCode) {
        dispatch(
          fetchProductsWithPrices({
            accountUid,
            side: 'Buy',
            symbols: `${gold.SymbolCode}`,
            productType: 'fractional',
          }),
        );
      }
    }
  }, [dispatch, gold, silver, accountUid]);

  return (
    <SelectedInvestmentContainer>
      <SelectedInvestmentTitle>
        {t('investment.selectedInvestment')}
      </SelectedInvestmentTitle>
      <SelectedInvestmentInvestments>
        {!isEmpty(gold) && !!gold.Percentage && (
          <Investment
            type="Gold"
            symbol={gold.SymbolCode}
            percentage={gold.Percentage}
            amount={amount}
            product={productsWithPrices.find((prod) =>
              prod.Locations.find(
                (location) => location.SymbolCode === gold.SymbolCode,
              ),
            )}
          />
        )}
        {!isEmpty(silver) && !!silver.Percentage && (
          <Investment
            type="Silver"
            symbol={silver.SymbolCode}
            percentage={silver.Percentage}
            amount={amount}
            product={productsWithPrices.find((prod) =>
              prod.Locations.find(
                (location) => location.SymbolCode === silver.SymbolCode,
              ),
            )}
          />
        )}
      </SelectedInvestmentInvestments>
    </SelectedInvestmentContainer>
  );
};

SelectedInvestment.propTypes = {
  gold: PropTypes.shape({
    Percentage: PropTypes.number,
    SymbolCode: PropTypes.string,
  }),
  silver: PropTypes.shape({
    Percentage: PropTypes.number,
    SymbolCode: PropTypes.string,
  }),
  accountUid: PropTypes.string,
  amount: PropTypes.number,
};

export default SelectedInvestment;
