import { isEmpty, memoize, orderBy } from 'lodash';
import { productLocationTypes } from '../enum/api/productTypes';
import { parseEnumType } from './enumMappers';

export const filterItemByLabel = memoize(
  (items, products, attribute, limit, formatLabel) =>
    items
      .map((item) => {
        const filteredProducts = products.filter(
          (product) => product[attribute] === item.label,
        );

        return !filteredProducts.length
          ? null
          : {
              ...item,
              count: `(${filteredProducts.length})`,
              label: formatLabel ? formatLabel(item.label) : item.label,
            };
      })
      .filter(Boolean),
);

export const filterItems = memoize(
  (
    items,
    attribute,
    products,
    formatLabel,
    limit,
    isMyHoldingsChecked,
    myHoldingsCodes,
  ) => {
    if (attribute !== 'Vaults.Location') {
      return filterItemByLabel(items, products, attribute, limit, formatLabel);
    }

    const countsByLocation = items
      .map((item) => {
        const matches = (location) => {
          const hasLocations =
            parseEnumType(productLocationTypes, location.LocationType) ===
            item.label;

          if (!isMyHoldingsChecked) {
            return hasLocations;
          }

          if (
            isMyHoldingsChecked &&
            !isEmpty(myHoldingsCodes) &&
            hasLocations
          ) {
            return myHoldingsCodes.some(
              (myHoldingCode) =>
                myHoldingCode.SymbolCode === location.SymbolCode,
            );
          }

          return hasLocations;
        };

        const filtered = products.filter(({ Locations }) =>
          Locations.some(matches),
        );

        return !filtered.length
          ? null
          : {
              ...item,
              label: formatLabel ? formatLabel(item.label) : item.label,
              count: `(${filtered.length})`,
            };
      })
      .filter(Boolean);
    return orderBy(countsByLocation, ['label'], 'asc');
  },
);
