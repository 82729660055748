import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SummaryView from './SummaryView';
import { mediaBelow, pxToRem } from '../../../assets/styles/helper';
import themeColors from '../../../assets/styles/themeColors';
import { variables } from '../../../assets/styles/variables';

export const SummaryWrap = styled.div`
  background-color: ${themeColors.colorBackgroundPrimary};
  box-shadow: ${variables.shadow.boxShadow};
  border-radius: ${variables.borderRadius.borderRadius};
  padding: ${pxToRem(48)};

  .recharts-surface {
    width: 100% !important;
    height: 100% !important;
  }

  .recharts-wrapper {
    height: ${pxToRem(240)} !important;
    width: 100% !important;
  }

  ${mediaBelow(variables.breakpoints.bpSm)} {
    .recharts-wrapper {
      height: ${pxToRem(300)} !important;
    }
  }

  ${mediaBelow(variables.breakpoints.bpXs)} {
    .recharts-surface {
      width: 80% !important;
    }
  }
`;

const PieChartHeaderTitle = styled.h2`
  margin-right: auto;
  margin-top: ${pxToRem(48)};
  margin-bottom: ${pxToRem(42)} !important;
  padding-bottom: 0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.23;
  flex-shrink: 0;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: 1.7rem;
    line-height: 1.35;
    padding-right: ${pxToRem(32)};
    padding-left: ${pxToRem(16)};
  }
`;

const SummaryContainer = ({
  holdings,
  totalValuation,
  isOuncesView,
  totalOunces,
  isSortByProduct,
  isSortByVault,
  isSortByType,
}) => {
  const { t } = useTranslation();

  return (
    <div data-ct="container-total-valuation">
      {isSortByProduct && (
        <PieChartHeaderTitle>
          {t('portfolio.totalValuationByProduct')}
        </PieChartHeaderTitle>
      )}
      {isSortByVault && (
        <PieChartHeaderTitle>
          {t('portfolio.totalValuationByVault')}
        </PieChartHeaderTitle>
      )}
      {isSortByType && (
        <PieChartHeaderTitle>
          {t('portfolio.totalValuationByType')}
        </PieChartHeaderTitle>
      )}
      <SummaryWrap data-cy="container-total-valuation-chart">
        <SummaryView
          totalValuation={totalValuation}
          totalOunces={totalOunces}
          isOuncesView={isOuncesView}
          holdings={holdings}
        />
      </SummaryWrap>
    </div>
  );
};

SummaryContainer.propTypes = {
  holdings: PropTypes.shape({}),
  totalValuation: PropTypes.number,
  isOuncesView: PropTypes.bool,
  totalOunces: PropTypes.number,
  isSortByProduct: PropTypes.bool,
  isSortByVault: PropTypes.bool,
  isSortByType: PropTypes.bool,
};

export default SummaryContainer;
