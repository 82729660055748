import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import i18next from 'i18next';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import Account, { AccountDropdown, AccountIconWrapper } from './Account';
import NavigationDropdown from './NavigationDropdown';

import {
  EMAIL_LOGIN_PAGE,
  IDENTITY_PAGE,
  RESET_PASSWORD,
  RESET_SECURITY_QUESTION_PAGE,
  RESET_SENT_PAGE,
  RESET_SUCCESSFUL_PAGE,
  SECURITY_QUESTION_PAGE,
  USER_LOGIN_PAGE,
  IMPERSONATE_PAGE,
  INVITE_USER_PAGE,
  NOT_FOUND_PAGE,
  ERROR_PAGE,
  MAINTENANCE_PAGE,
  TWO_FA_VERIFY_IDENTITY,
  TWO_FA_VERIFY_IDENTITY_PHONE,
  TWO_FA_QR_CODE_PAGE,
  VERIFY_CHOOSE_METHOD_PAGE,
  TWO_FA_CHOOSE_PHONE_METHOD,
  TWO_FA_VERIFY_CODE,
  TWO_FA_SUCCESSFUL_SETUP,
  TWO_FA_HAVING_TROUBLE_PAGE,
  TWO_FA_FORGOT_PASSWORD_IDENTITY_PAGE,
  TWO_FA_FORGOT_PASSWORD_PAGE,
  TWO_FA_FORGOT_PASSWORD_IDENTITY_PHONE_PAGE,
  TWO_FA_FORGOT_PASSWORD_IDENTITY_HAVING_TROUBLE_PAGE,
  QUICK_REGISTRATION_PAGE,
} from '../../constants/pages';
import {
  tradingNavItems,
  accountNavItems,
  closedAccountNavItems,
} from '../../constants/header';

import { ReactComponent as Hamburger } from '../../assets/images/svg/hamburger.svg';
import { ReactComponent as User } from '../../assets/images/svg/user-icon.svg';
import { ReactComponent as Close } from '../../assets/images/svg/close.svg';
import { ReactComponent as LogoColored } from '../../assets/images/svg/logo-colored.svg';

import { selectSettings } from '../../store/selectors/settingsSelectors';
import useWindowSize from '../../util/hooks/useIsMobileHook';
import { buttonizeDiv } from '../../util/buttonizeDiv';
import Anchor from '../Anchor/Anchor';
import SupportModal from '../Modals/SupportModal/SupportModal';
import IraDepositWizard from '../Modals/DepositWizard/IraDepositWizard';
import MilleniumDepositWizard from '../Modals/DepositWizard/MilleniumDepositWizard';
import { useIsClosedAccount } from '../../util/hooks/useIsClosedAccount';
import useCheckPermissions from '../../util/hooks/useCheckPermissions';
import { IRA_ENTRUST_TYPE, IRA_EQUITY_TYPE } from '../../util/constants';
import { storeInSessionStorage } from '../../util/helpers/sessionStorageHelper';
import {
  IS_FUND_MODAL_OPEN,
  PREVENT_SUPPORT_MODAL,
} from '../../constants/sessionStorage';
import { useSessionStorageState } from '../../util/hooks/useSessionStorageState';
import { setWizardContent } from '../../util/helpers/wizardHelpers';
import useGtmHook from '../../util/hooks/useGtmHook';
import hardAssetsAllianceBackground from '../../assets/images/hard-assets-alliance-navigation-background-image.png';

import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import themeColors from '../../assets/styles/themeColors';
import {
  uButtonClear,
  uFlexCenter,
  uOutlineNone,
  uFlexColumn,
} from '../../assets/styles/utility';
import config from '../../config';
import Logo from '../Logo/Logo';
import StandardDepositWizard from '../Modals/Deposit/StandardDepositWizard/StandardDepositWizard';
import { getCurrentAccount } from '../../store/selectors/accountSelectors';

const hideNavigationRoutes = [
  EMAIL_LOGIN_PAGE,
  USER_LOGIN_PAGE,
  RESET_PASSWORD,
  SECURITY_QUESTION_PAGE,
  IDENTITY_PAGE,
  RESET_SUCCESSFUL_PAGE,
  RESET_SENT_PAGE,
  RESET_SECURITY_QUESTION_PAGE,
  IMPERSONATE_PAGE,
  INVITE_USER_PAGE,
  NOT_FOUND_PAGE,
  ERROR_PAGE,
  MAINTENANCE_PAGE,
  VERIFY_CHOOSE_METHOD_PAGE,
  TWO_FA_QR_CODE_PAGE,
  TWO_FA_CHOOSE_PHONE_METHOD,
  TWO_FA_VERIFY_CODE,
  TWO_FA_SUCCESSFUL_SETUP,
  TWO_FA_VERIFY_IDENTITY,
  TWO_FA_VERIFY_IDENTITY_PHONE,
  TWO_FA_HAVING_TROUBLE_PAGE,
  TWO_FA_FORGOT_PASSWORD_IDENTITY_PAGE,
  TWO_FA_FORGOT_PASSWORD_PAGE,
  TWO_FA_FORGOT_PASSWORD_IDENTITY_PHONE_PAGE,
  TWO_FA_FORGOT_PASSWORD_IDENTITY_HAVING_TROUBLE_PAGE,
  QUICK_REGISTRATION_PAGE,
];

const headerHeightDesktop = pxToRem(80);
const headerHeightMobile = pxToRemMd(74);
const getHeaderBackground = () => {
  const { brokerTheme } = config;

  switch (brokerTheme) {
    case 'HardAssetsAlliance':
      return hardAssetsAllianceBackground;

    case 'GoldSilver':
      return `${themeColors.colorMainHeaderBackground}`;
    case 'Contoso':
      return;
    case 'LegacyPM':
      return;

    default:
      return hardAssetsAllianceBackground;
  }
};

const isContoso = () => {
  const { brokerTheme } = config;
  if (brokerTheme === 'Contoso') {
    return true;
  }
  return false;
};

const isHAA = () => {
  const { brokerTheme } = config;
  if (brokerTheme === 'HardAssetsAlliance') {
    return true;
  }
  return false;
};

const HeaderContainer = styled.header`
  display: flex;
  padding-left: 3.25rem;
  align-items: center;
  ${({ showBackground, isGS }) =>
    showBackground && isGS
      ? `background-color: ${getHeaderBackground()};`
      : `background-image: url(${getHeaderBackground()});
    position: sticky;
  `}

  height: ${headerHeightDesktop};
  top: 0;
  right: 0;
  left: 0;
  z-index: ${variables.zIndex.indexXl};
  border: none;
  box-shadow: ${variables.shadow.boxShadow};
  ${({ showBackground, isGS }) =>
    showBackground && !isGS && `background-color: ${getHeaderBackground()};`}

  ${mediaBelow(variables.breakpoints.bpXl)} {
    background-image: none;
    background-color: ${themeColors.colorMainHeaderBackground};
    padding-left: 0;
    padding: 0 ${pxToRemMd(24)};
    box-shadow: ${variables.shadow.boxShadow};
    height: ${headerHeightMobile};
  }
`;

const HeaderHamburgerButton = styled.button`
  ${uButtonClear};
  ${uOutlineNone};
  cursor: pointer;
  display: none;

  svg {
    width: ${pxToRem(36)};
    height: ${pxToRem(36)};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    display: flex;
  }
`;

const HeaderAccountButton = styled.button`
  ${uButtonClear};
  ${uOutlineNone};
  cursor: pointer;
  display: none;

  svg {
    width: ${pxToRem(36)};
    height: ${pxToRem(36)};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    display: flex;
  }
`;

const HeaderLogo = styled.div`
  margin-right: ${pxToRem(48)};

  ${({ logoWhite }) =>
    logoWhite &&
    `
      svg {
        color: ${themeColors.colorHeaderLogoLight};
      }
    `}

  ${({ logoDark }) =>
    logoDark &&
    `
      svg {
        color: ${themeColors.colorHeaderLogoDark};
      }
    `}  
  ${({ isDesktopShow }) =>
    isDesktopShow &&
    `display: flex;

  `};
  ${({ isMobileShow }) =>
    isMobileShow &&
    `display: none;
  `};

  img,
  svg {
    width: ${isContoso() ? pxToRem(300) : pxToRem(200)};
    object-fit: contain;
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    margin: 0 auto;
    ${(props) =>
      props.isDesktopShow &&
      `display: none;
    `};
    ${(props) =>
      props.isMobileShow &&
      `display: flex;
  
    `};

    img,
    svg {
      width: ${pxToRemMd(143)};
      color: ${themeColors.colorHeaderLogoDark};
    }
  }
`;

const HeaderNavigation = styled.nav`
  display: flex;
  align-self: stretch;
  justify-content: flex-end;
  max-width: ${pxToRem(734)};
  width: 100%;
  margin-left: auto;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    position: fixed;
    top: ${headerHeightMobile};
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: flex-start;
    flex-direction: column;
    margin-right: 0;
    background-color: ${themeColors.colorSecondaryHeaderBackground};
    z-index: ${variables.zIndex.indexSm};
    display: none;
    max-width: 100%;
    overflow: auto;
    ${(props) => props.isNavOpen && `display: flex`};
  }
`;

const HeaderNavigationList = styled.ul`
  display: flex;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    flex-direction: column;
  }
`;

const HeaderNavigationItem = styled.li`
  ${uFlexCenter};
  min-width: ${pxToRem(128)};
  font-size: ${pxToRem(16)};
  text-decoration: none;
  flex-shrink: 0;

  &:not(:first-child) {
    margin-left: ${pxToRem(26)};
  }

  > a {
    text-decoration: none;
    line-height: 1.5;
    color: ${themeColors.colorTextContrast};
    text-transform: uppercase;
    font-size: ${pxToRem(18)};
  }

  > button {
    ${uOutlineNone};
    background-color: transparent;
    align-self: stretch;
    width: 100%;
    border: 0;
    line-height: 1.5;
    font-size: ${pxToRem(18)};
    color: ${themeColors.colorTextContrast};
    text-transform: uppercase;
    cursor: pointer;
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    margin: 0;
    width: 100%;
    justify-content: flex-start;

    &:hover {
      background-color: ${themeColors.colorNavigationItemHover};
    }

    &:not(:first-child) {
      margin-left: 0;
    }

    > a {
      color: ${themeColors.colorTextPrimary};
      padding: ${pxToRemMd(12)} ${pxToRemMd(16)};
      font-size: ${pxToRemMd(18)};
      line-height: 1.35;
      width: 100%;
      text-transform: capitalize;
    }

    > button {
      align-self: flex-start;
      display: flex;
      color: ${themeColors.colorTextPrimary};
      padding: ${pxToRemMd(12)} ${pxToRemMd(16)};
      font-size: ${pxToRemMd(18)};
      line-height: 1.35;
      text-transform: capitalize;

      &:hover {
        background-color: ${themeColors.colorNavigationItemHover};
      }
  }
`;

const HeaderAccountWrap = styled.div`
  max-width: ${pxToRem(400)};
  width: 100%;
  align-self: stretch;
  ${(props) =>
    props.isDropdownOpen &&
    `
    ${AccountDropdown} {
      ${uFlexColumn};
    }

    ${AccountIconWrapper} svg {
      transform: rotate(180deg);
    }
  `}

  ${mediaBelow(variables.breakpoints.bpXl)} {
    position: fixed;
    z-index: ${variables.zIndex.indexSm};
    top: ${headerHeightMobile};
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    display: none;
    overflow: auto;
    background-color: ${themeColors.colorSecondaryHeaderBackground};
    ${(props) => props.isAccountOpen && `display: flex`};
  }
`;

const Header = ({ maintenance, setNoPermissionsModal }) => {
  const { pathname, hash } = useLocation();
  const history = useHistory();
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 1200;
  const [isNavOpen, setIsNavOpen] = useState(isMobile || false);
  const [isAccountOpen, setIsAccountOpen] = useState(false);
  const [isSupportModalOpen, setSupportModalOpen] = useState(false);
  const {
    allowAccountChangesPermission,
    displayIRAEntrustOptionsPermission,
    displayIRAMilleniumOptionsPermission,
    displayIRAEquityOptionsPermission,
    displayStandardOptionsPermission,
  } = useCheckPermissions();
  const [isModalOpen, setIsModalOpen] = useSessionStorageState(
    IS_FUND_MODAL_OPEN,
    false,
  );
  const { gtmDialogScreenView } = useGtmHook();
  const locationHistory = history.location.pathname;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { displayBasicAccountOptionsPermission } = useCheckPermissions();
  const locationHistoryWithoutParam =
    locationHistory.lastIndexOf('/') === 0
      ? locationHistory
      : locationHistory.substr(0, locationHistory.lastIndexOf('/'));

  const [showNavigation, setShowNavigation] = useState(
    () => !hideNavigationRoutes.includes(locationHistoryWithoutParam),
  );
  const settings = useSelector(selectSettings);
  const isClosedAccount = useIsClosedAccount();
  const currentAccount = useSelector(getCurrentAccount);
  const isErrAccount = currentAccount?.AccountStatus === 7;
  const isPendingAccount =
    currentAccount?.AccountStatus === 4 || currentAccount?.AccountStatus === 1;

  useEffect(() => {
    setShowNavigation(
      !hideNavigationRoutes.includes(locationHistoryWithoutParam),
    );
  }, [history, history.location, locationHistoryWithoutParam]);

  const openNavigation = () => {
    setIsNavOpen(!isNavOpen);

    if (isAccountOpen) {
      setIsAccountOpen(false);
    }
  };

  const openAccount = () => {
    setIsAccountOpen(!isAccountOpen);

    if (isNavOpen) {
      setIsNavOpen(false);
    }
  };

  const openSupport = () => {
    setSupportModalOpen(true);

    if (isNavOpen) {
      setIsNavOpen(false);
    }
  };

  const closeNavigation = () => {
    setIsNavOpen(false);
  };

  useEffect(() => {
    setIsNavOpen(false);
    setIsAccountOpen(false);
  }, [pathname, hash]);

  useEffect(() => {
    if (isDropdownOpen) {
      setIsDropdownOpen(false);
    }

    if (isNavOpen) {
      setIsNavOpen(false);
    }

    if (isAccountOpen) {
      setIsAccountOpen(false);
    }
  }, [isMobile]); // eslint-disable-line

  useEffect(() => {
    if (isMobile) {
      if (isNavOpen || isAccountOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }
  }, [isNavOpen, isAccountOpen, isMobile]);

  const logoAnchor = () => {
    if (settings.WebsiteUrl) {
      if (String(settings.WebsiteUrl).includes('https://')) {
        return settings.WebsiteUrl;
      }

      return `https://${settings.WebsiteUrl}`;
    }
  };

  const mobileLogo = () => {
    if (showNavigation && isHAA()) {
      return (
        <Anchor type="website" value={logoAnchor()} text={<LogoColored />} />
      );
    }

    return <Anchor type="website" value={logoAnchor()} text={<Logo />} />;
  };
  const getAccountNavItems = () => {
    if (isClosedAccount) {
      return closedAccountNavItems;
    }
    return accountNavItems;
  };

  if (maintenance || !showNavigation) {
    if (locationHistory === '/registration') {
      return null;
    }

    return (
      <HeaderContainer
        showBackground={!isContoso()}
        isGS={config.brokerTheme === 'GoldSilver'}
      >
        <HeaderLogo logoWhite isDesktopShow>
          <Anchor type="website" value={logoAnchor()} text={<Logo />} />
        </HeaderLogo>
        <HeaderLogo logoDark isMobileShow>
          {mobileLogo()}
        </HeaderLogo>
      </HeaderContainer>
    );
  }

  return (
    <>
      <HeaderContainer
        showBackground={!isContoso()}
        isGS={config.brokerTheme === 'GoldSilver'}
      >
        <HeaderHamburgerButton
          onClick={() => openNavigation()}
          data-cy="button-nav-list-toggle-mobile"
        >
          {!isNavOpen ? <Hamburger /> : <Close />}
        </HeaderHamburgerButton>

        <HeaderLogo logoWhite isDesktopShow>
          <Anchor type="website" value={logoAnchor()} text={<Logo />} />
        </HeaderLogo>
        <HeaderLogo logoDark isMobileShow>
          {mobileLogo()}
        </HeaderLogo>

        <HeaderNavigation isNavOpen={isNavOpen}>
          <HeaderNavigationList>
            {!isClosedAccount && (
              <HeaderNavigationItem>
                <NavLink
                  onClick={closeNavigation}
                  to="/portfolio"
                  data-cy="nav-portfolio"
                >
                  {i18next.t('header.portfolioLink')}
                </NavLink>
              </HeaderNavigationItem>
            )}
            {allowAccountChangesPermission &&
              !displayBasicAccountOptionsPermission && (
                <HeaderNavigationItem>
                  <button
                    onClick={() => {
                      storeInSessionStorage(PREVENT_SUPPORT_MODAL, true);
                      setWizardContent(IS_FUND_MODAL_OPEN, true);
                      setIsModalOpen(true);
                      gtmDialogScreenView({
                        title: 'Deposit Funds',
                      });
                    }}
                    data-cy="nav-fund"
                  >
                    {i18next.t('header.fundBtn')}
                  </button>
                </HeaderNavigationItem>
              )}
            {!isClosedAccount && (
              <HeaderNavigationItem>
                <NavigationDropdown
                  isNavOpen={isNavOpen}
                  title={i18next.t('header.navDropdownTrading')}
                  items={tradingNavItems}
                  closeNav={closeNavigation}
                  isMobile={isMobile}
                  dataCyLabel="nav-trading"
                />
              </HeaderNavigationItem>
            )}
            <HeaderNavigationItem>
              <NavigationDropdown
                isNavOpen={isNavOpen}
                title={i18next.t('header.navDropdownAccount')}
                items={getAccountNavItems()}
                closeNav={closeNavigation}
                isMobile={isMobile}
                dataCyLabel="nav-account"
              />
            </HeaderNavigationItem>
            <HeaderNavigationItem>
              <button onClick={() => openSupport()} data-cy="nav-support">
                {i18next.t('header.supportBtn')}
              </button>
            </HeaderNavigationItem>
          </HeaderNavigationList>
        </HeaderNavigation>
        <HeaderAccountButton
          onClick={() => openAccount()}
          data-cy="button-account-list-toggle-mobile"
        >
          {!isAccountOpen ? <User /> : <Close />}
        </HeaderAccountButton>
        <HeaderAccountWrap
          isAccountOpen={isAccountOpen}
          isDropdownOpen={isDropdownOpen}
          {...buttonizeDiv(() => {
            setIsDropdownOpen(!isDropdownOpen);
          })}
          data-cy="button-account-list-toggle"
        >
          <Account
            setIsAccountOpen={setIsAccountOpen}
            setIsDropdownOpen={setIsDropdownOpen}
            setNoPermissionsModal={setNoPermissionsModal}
            isBasicAccount={
              displayBasicAccountOptionsPermission &&
              !isErrAccount &&
              !isPendingAccount
            }
          />
        </HeaderAccountWrap>

        <SupportModal
          handleOutsideClick
          isOpen={isSupportModalOpen}
          close={setSupportModalOpen}
        />
      </HeaderContainer>
      {displayStandardOptionsPermission && isModalOpen && (
        <StandardDepositWizard
          isModalOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
        />
      )}
      {displayIRAEquityOptionsPermission && isModalOpen && (
        <IraDepositWizard
          isModalOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          type={IRA_EQUITY_TYPE}
        />
      )}
      {displayIRAEntrustOptionsPermission && isModalOpen && (
        <IraDepositWizard
          isModalOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          type={IRA_ENTRUST_TYPE}
        />
      )}
      {displayIRAMilleniumOptionsPermission && isModalOpen && (
        <MilleniumDepositWizard
          isModalOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};

Header.propTypes = {
  maintenance: PropTypes.bool,
  setNoPermissionsModal: PropTypes.func,
};

export default Header;
