import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  mediaBelow,
  pxToRem,
  pxToRemMd,
} from '../../../../assets/styles/helper';
import themeColors from '../../../../assets/styles/themeColors';
import { variables } from '../../../../assets/styles/variables';

export const ProductItemContainer = styled.label`
  ${(props) => props.display && `pointer-events: none;`};
`;

export const ProductItemWrap = styled.div`
  display: grid;
  grid-template-columns: ${pxToRem(78)} 1fr;
  grid-column-gap: ${pxToRem(8)};
  background-color: ${themeColors.colorCardBackground};
  box-shadow: ${variables.shadow.boxShadow};
  border-radius: ${variables.borderRadius.borderRadius};
  padding: ${pxToRem(8)};
  border: 1px solid ${themeColors.colorWhite};
  cursor: pointer;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    grid-template-columns: ${pxToRemMd(72)} 1fr;
    grid-column-gap: ${pxToRemMd(5)};
    padding: ${pxToRemMd(16)} ${pxToRemMd(10)};
  }
`;

export const ProductItemCheckbox = styled.input`
  display: none;

  &:checked {
    & + ${ProductItemWrap} {
      background-color: ${themeColors.colorCardSelected};
      border: 1px solid ${themeColors.colorPrimary};
    }
  }
`;

export const ProductItemImage = styled.img`
  width: ${pxToRem(78)};
  height: ${pxToRem(78)};
  object-fit: contain;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    width: ${pxToRemMd(72)};
    height: ${pxToRemMd(72)};
  }
`;

export const ProductItemInfo = styled.div`
  display: grid;
  grid-row-gap: ${pxToRem(8)};
  grid-template-rows: 1fr auto;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    grid-row-gap: ${pxToRemMd(8)};
  }
`;

export const ProductItemName = styled.p`
  font-size: ${pxToRem(14)};
  line-height: 1.45;
  font-weight: 600;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(14)};
  }
`;

export const ProductItemPrice = styled.p`
  font-size: ${pxToRem(14)};
  line-height: 1.72;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(14)};
  }
`;

const ProductItemFractional = ({
  display,
  imgSrc,
  selected,
  alt,
  productName,
  name,
  product,
  handleSelectProduct,
}) => {
  const handleChange = () => handleSelectProduct(product);

  return (
    <ProductItemContainer display={display}>
      <ProductItemCheckbox
        type="radio"
        name={name}
        checked={selected}
        onChange={handleChange}
      />
      <ProductItemWrap>
        <ProductItemImage src={imgSrc} alt={alt} />
        <ProductItemInfo>
          <ProductItemName>{productName}</ProductItemName>
        </ProductItemInfo>
      </ProductItemWrap>
    </ProductItemContainer>
  );
};

ProductItemFractional.propTypes = {
  display: PropTypes.bool,
  name: PropTypes.string,
  product: PropTypes.shape({
    ProductCode: PropTypes.string,
    ProductCaption: PropTypes.string,
    SymbolCode: PropTypes.string,
    Locations: PropTypes.arrayOf(
      PropTypes.shape({
        LocationType: PropTypes.number,
        PricePerOz: PropTypes.number,
        PricePerUnit: PropTypes.number,
      }),
    ),
  }),
  handleSelectProduct: PropTypes.func,
  imgSrc: PropTypes.string,
  selected: PropTypes.bool,
  alt: PropTypes.string,
  productName: PropTypes.string,
};

export default ProductItemFractional;
