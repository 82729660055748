import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { useTranslation } from 'react-i18next';

import Modal from '../../Modal/Modal';
import Table from '../../Table/Table';
import TableBody from '../../Table/TableBody';
import TableHead from '../../Table/TableHead';
import TableRow from '../../Table/TableRow';
import PriceTierRow from './PriceTierRow';
import {
  emptyProductTiers,
  fetchProductTiers,
} from '../../../store/actions/products/productActions';
import {
  selectProductTiers,
  selectProductTiersError,
} from '../../../store/selectors/productsSelector';
import { selectIsLoadingByActionType } from '../../../store/selectors/loadingSelectors';
import { PRODUCT_TIERS_LOADING } from '../../../store/actions/products/productActionConstants';
import { formatMoneyNumeral } from '../../../util/helpers/numeralHelpers';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import {
  LOCATION_TYPE_DELIVERY,
  PRODUCT_TYPE_FRACTIONAL,
  productLocationTypes,
  productLocationTypesByName,
} from '../../../util/enum/api/productTypes';
import useGetPeriodicPriceTiers from '../../../util/hooks/useGetPeriodicPriceTiers';
import { selectSettings } from '../../../store/selectors/settingsSelectors';
import Note from '../../Notes/Note';
import ModalBody from '../../Modal/ModalBody';
import ModalTitle from '../../Modal/ModalTitle';
import useCheckPermissions from '../../../util/hooks/useCheckPermissions';
import Button from '../../Button/Button';
import { separateByUppercase } from '../../../util/helpers/stringHelpers';
import {
  closeWizardContent,
  setWizardContent,
} from '../../../util/helpers/wizardHelpers';
import { setStorageProduct } from '../../../store/actions/buy/buyStorageActions';
import {
  CHOSEN_VAULT,
  IS_BUY_WIZARD_OPEN,
  IS_MODAL_OPEN,
  IS_PRODUCT_FRACTIONAL,
  IS_RESTRICTION_MODAL_OPEN,
  PRODUCT,
  WIZARD_DATA,
  IS_INSTA_VAULT_PRODUCT,
} from '../../../constants/sessionStorage';
import { storeInSessionStorage } from '../../../util/helpers/sessionStorageHelper';
import { useSessionStorageState } from '../../../util/hooks/useSessionStorageState';
// import {
// TRADE_INITIATED,
// SELECT_ITEM,
// } from '../../../constants/gtmEvents';
import BuyWizard from '../BuyWizard/BuyWizard';
import {
  priceTierModal,
  priceTierModalTableHead,
  priceTierModalTableRow,
} from '../../Table/Components/PriceTierModalTable';
import { ModalButtonsContainer } from '../../Modal/ModalButtons';
import { clearBuyStorageSuccessState } from '../../../store/actions/orders/orderActions';
import { getCurrentAccountUid } from '../../../store/selectors/accountSelectors';
import { selectBankAccountAchData } from '../../../store/selectors/bankAccountSelectors';
import {
  actionRequiredStatus,
  expiredVerificationStatus,
  failedVerificationStatus,
  getBankAccountStatus,
  pendingReviewStatus,
} from '../../../util/helpers/bankAccountHelper';
import { fetchBankAccounts } from '../../../store/actions/bankAccount/bankAccountActions';
import BasicAccountRestristionsModal from '../BasicAccountRestrictionsModal/BasicAccountRestrictionsModal';
import {
  accountTypeKeys,
  accountTypes,
} from '../../../util/enum/api/accountTypes';
import config from '../../../config';

const PriceTierModal = ({
  isOpen,
  data,
  tradingSide,
  account,
  handleClose,
  handleBack,
  overModal,
  avoidClearingStorage,
  priceTiersProduct,
  isFractionalProduct,
  isInstaVaultProduct,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const productTiers = useSelector(selectProductTiers);
  const {
    allowAccountChangesPermission,
    displayBasicAccountOptionsPermission,
  } = useCheckPermissions();
  const productTiersError = useSelector(selectProductTiersError);
  const isLoading = useSelector(
    selectIsLoadingByActionType(PRODUCT_TIERS_LOADING),
  );
  const [isSegregated, setIsSegregated] = useState(false);
  const settings = useSelector(selectSettings);
  const [isModalOpen, setIsModalOpen] = useSessionStorageState(
    IS_BUY_WIZARD_OPEN,
    false,
  );
  const [
    isBuyRestrictionModalOpen,
    setIsBuyRestrictionModalOpen,
  ] = useSessionStorageState(IS_RESTRICTION_MODAL_OPEN, false);
  const accountUid = useSelector(getCurrentAccountUid);

  const achBankAccounts = useSelector(selectBankAccountAchData);
  const { brokerTheme } = config;
  const isGS = brokerTheme === 'GoldSilver';

  const unverifiedBankAccounts = achBankAccounts?.filter((bankAccount) => {
    const verificationStatus = getBankAccountStatus(bankAccount).status;
    return [
      actionRequiredStatus,
      failedVerificationStatus,
      expiredVerificationStatus,
      pendingReviewStatus,
    ].includes(verificationStatus);
  });

  const isBasicAccount =
    parseEnumType(accountTypeKeys, account.AccountType) === accountTypes.Basic;

  useEffect(() => {
    if (accountUid) {
      dispatch(fetchBankAccounts({ accountUid }));
    }
  }, [accountUid, dispatch]);

  const canFetchTiers = useCallback(
    () =>
      isOpen &&
      !isEmpty(data) &&
      !isEmpty(account) &&
      account.AccountUid &&
      tradingSide,
    [isOpen, tradingSide], // eslint-disable-line
  );
  useEffect(() => {
    if (canFetchTiers()) {
      dispatch(
        fetchProductTiers({
          accountUid: account.AccountUid,
          symbol: data.SymbolCode,
          side: tradingSide,
          location:
            data.LocationType === LOCATION_TYPE_DELIVERY
              ? Object.keys(productLocationTypesByName).find(
                  (key) => productLocationTypesByName[key] === 12,
                )
              : parseEnumType(productLocationTypes, data.LocationType),
        }),
      );
    }

    return () => dispatch(emptyProductTiers());
  }, [
    tradingSide,
    account,
    canFetchTiers,
    dispatch,
    data.SymbolCode,
    data.LocationType,
  ]);

  useGetPeriodicPriceTiers(
    'priceTiersPTM',
    account,
    data.LocationType === LOCATION_TYPE_DELIVERY
      ? Object.keys(productLocationTypesByName).find(
          (key) => productLocationTypesByName[key] === 12,
        )
      : parseEnumType(productLocationTypes, data.LocationType),
    data.SymbolCode,
    tradingSide,
    dispatch,
    canFetchTiers(),
    settings?.PricingRefreshFrequencyMilliseconds,
  );

  function formatTierAmounts(tier) {
    if (tier.HighBound) {
      return `${formatMoneyNumeral(
        tier.LowBound,
        '$0,0',
      )} - ${formatMoneyNumeral(tier.HighBound, '$0,0')}`;
    }
    return `${formatMoneyNumeral(tier.LowBound, '$0,0')} +`;
  }
  const tiers =
    !isEmpty(productTiers) && productTiers.Tiers
      ? productTiers.Tiers.map((tier, index) => {
          const amount = formatTierAmounts(tier);
          return {
            id: index,
            content: (
              <PriceTierRow
                amount={amount}
                price={`${formatMoneyNumeral(tier.PricePerUnit)}`}
              />
            ),
          };
        })
      : [];

  const rowData = tiers.map((tier) => ({
    id: tier.id,
    ...tier,
    content: tier.content,
  }));

  const headerData = [
    { id: 1, content: t('product.table.dollarAmountLabel') },
    {
      id: 2,
      content:
        (settings && !isEmpty(settings) && !settings?.IsLockedPricingActive) ||
        (isFractionalProduct && !isInstaVaultProduct)
          ? t('product.table.pricePerUnitStarLabel')
          : t('product.table.pricePerUnitLabel'),
    },
  ];

  const selectProduct = (product, vault) => {
    if (vault) {
      setWizardContent('ShortFlow', true);
      setIsSegregated(!!vault.IsSegregated);
    }

    dispatch(setStorageProduct({ product, vault }));

    setWizardContent(CHOSEN_VAULT, vault);
    setWizardContent(PRODUCT, product);
    storeInSessionStorage(WIZARD_DATA, {
      storageProduct: product,
      selectedVault: vault,
    });
    setWizardContent(IS_MODAL_OPEN, true);
    setIsModalOpen(true);

    // gtmEvent(TRADE_INITIATED, {
    //   OrderSideType: tradingSide,
    // });

    handleClose();
    setWizardContent(
      IS_PRODUCT_FRACTIONAL,
      product.ProductType === PRODUCT_TYPE_FRACTIONAL,
    );
    const isInstavault =
      isGS &&
      (product.ProductCode === settings.InstavaultProductCodes[0] ||
        product.ProductCode === settings.InstavaultProductCodes[1]);
    setWizardContent(IS_INSTA_VAULT_PRODUCT, isInstavault);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    dispatch(clearBuyStorageSuccessState());
    closeWizardContent();
  };

  if (!isOpen && !isBuyRestrictionModalOpen) {
    if (tradingSide === 'Buy' && isModalOpen)
      return (
        <BuyWizard
          isModalOpen={isModalOpen}
          handleCloseModal={() => handleCloseModal()}
          unverifiedBankAccounts={unverifiedBankAccounts}
          isSegregated={isSegregated}
          isFractionalProduct={isFractionalProduct}
        />
      );
    return null;
  }
  return (
    <>
      {!isBuyRestrictionModalOpen && (
        <Modal
          size="sm"
          title={t('product.priceTiersBtn')}
          isOpen
          close={handleClose}
          isLoading={isLoading}
          overModal={overModal}
          avoidClearingStorage={avoidClearingStorage}
          dataCy="container-price-tiers"
        >
          <ModalBody css={priceTierModal}>
            <ModalTitle>
              {data?.LocationType &&
                `${productTiers?.ProductCaption}, ${separateByUppercase(
                  parseEnumType(productLocationTypes, data?.LocationType),
                )}`}
            </ModalTitle>
            <Table>
              <TableHead css={priceTierModalTableHead} data={headerData} />
              <TableBody>
                {!productTiersError ? (
                  <TableRow css={priceTierModalTableRow} rowData={rowData} />
                ) : (
                  <span>{productTiersError}</span>
                )}
              </TableBody>
            </Table>
            {(settings &&
              !isEmpty(settings) &&
              !settings?.IsLockedPricingActive) ||
              (isFractionalProduct && !isInstaVaultProduct && (
                <Note marginTop={24} text={t('product.priceEstimationNote')} />
              ))}
            <ModalButtonsContainer isVertical marginTop>
              {tradingSide === 'Buy' && (
                <Button
                  disabled={
                    !allowAccountChangesPermission ||
                    (account.IsTestAccount && !data?.IsTestProduct) ||
                    !priceTiersProduct.IsTradingEnabled ||
                    (displayBasicAccountOptionsPermission &&
                      !isBasicAccount &&
                      data?.LocationType !== 12)
                  }
                  lockStyle={isBasicAccount && data?.LocationType !== 12}
                  variant="primary"
                  onClick={() => {
                    if (isBasicAccount && data?.LocationType !== 12) {
                      setIsBuyRestrictionModalOpen(true);
                      handleClose();
                      return;
                    }
                    selectProduct(priceTiersProduct, data);
                  }}
                  data-cy="button-buy"
                >
                  {t('product.buyBtn')}
                </Button>
              )}
              <Button
                size="lg"
                variant="primaryOutlined"
                onClick={handleBack}
                data-cy="button-back"
              >
                {t('common.back')}
              </Button>
            </ModalButtonsContainer>
          </ModalBody>
        </Modal>
      )}
      {isBasicAccount && (
        <BasicAccountRestristionsModal
          isOpen={isBuyRestrictionModalOpen}
          close={() => setIsBuyRestrictionModalOpen(false)}
          title={t('basicAccountRestrictions.buy.title')}
          description={t('basicAccountRestrictions.buy.description')}
        />
      )}
    </>
  );
};

PriceTierModal.propTypes = {
  isOpen: PropTypes.bool,
  data: PropTypes.shape({
    SymbolCode: PropTypes.string,
    LocationType: PropTypes.number,
    IsTestProduct: PropTypes.bool,
  }),
  tradingSide: PropTypes.string,
  account: PropTypes.shape({
    AccountUid: PropTypes.string,
    IsTestAccount: PropTypes.bool,
    AccountType: PropTypes.string,
  }),
  handleClose: PropTypes.func,
  handleBack: PropTypes.func,
  overModal: PropTypes.bool,
  avoidClearingStorage: PropTypes.bool,
  priceTiersProduct: PropTypes.shape({
    IsTradingEnabled: PropTypes.bool,
  }),
  isFractionalProduct: PropTypes.bool,
  isInstaVaultProduct: PropTypes.bool,
};

export default PriceTierModal;
