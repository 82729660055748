import i18next from 'i18next';
import * as Yup from 'yup';
import { passwordValidationRule } from './passwordValidationRule';

export const userInviteNewFormValidationSchema = Yup.object().shape({
  LegalFirstName: Yup.string().required(
    i18next.t('validationSchema.general.firstNameRequired'),
  ),
  LegalLastName: Yup.string().required(
    i18next.t('validationSchema.general.lastNameRequired'),
  ),
  PhoneNumber: Yup.string().required(
    i18next.t('validationSchema.general.phoneNumberRequired'),
  ),
  Username: Yup.string().required(
    i18next.t('validationSchema.general.userNameRequired'),
  ),
  Password: passwordValidationRule,
  ConfirmPassword: Yup.string()
    .oneOf(
      [Yup.ref('Password'), null],
      i18next.t('validationSchema.general.passwordNoMatch'),
    )
    .required(i18next.t('validationSchema.general.passwordRequired')),
  SecurityQuestion: Yup.object().shape({
    value: Yup.string().required(
      i18next.t('validationSchema.general.securityQuestionRequired'),
    ),
  }),
  Answer: Yup.string().required(
    i18next.t('validationSchema.general.securityAnswerRequired'),
  ),
});

export const userInviteExistingFormValidationSchema = Yup.object().shape({
  Username: Yup.object().shape({
    value: Yup.string().required(
      i18next.t('validationSchema.general.userNameRequired'),
    ),
  }),
  Password: Yup.string().required(
    i18next.t('validationSchema.general.passwordRequired'),
  ),
});
