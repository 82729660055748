import * as Yup from 'yup';
import i18next from '../i18n';

export const editFullNameValidationSchema = Yup.object().shape({
  firstName: Yup.string().required(
    i18next.t('validationSchema.general.firstNameRequired'),
  ),
  lastName: Yup.string().required(
    i18next.t('validationSchema.general.lastNameRequired'),
  ),
});
