import React from 'react';
import PropTypes from 'prop-types';
import InformationList from '../../../../InformationList/InformationList';
import InformationListItem from '../../../../InformationList/InformationListItem';

const WireDepositList = ({ fields }) => (
  <InformationList>
    {fields.map((field) => (
      <InformationListItem
        key={field.label}
        label={field.label}
        value={field.value}
      />
    ))}
  </InformationList>
);

WireDepositList.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    }),
  ),
};

export default WireDepositList;
