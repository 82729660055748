import React from 'react';
import PropTypes from 'prop-types';
import SelectProduct from './SelectProduct';

const SelectGoldProduct = ({
  handleBack,
  handleNext,
  defaultValues,
  wizardData,
  setWizardData,
}) => (
  <SelectProduct
    metalType="gold"
    handleBack={handleBack}
    handleNext={handleNext}
    defaultValues={defaultValues}
    wizardData={wizardData}
    setWizardData={setWizardData}
  />
);

SelectGoldProduct.propTypes = {
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  defaultValues: PropTypes.shape({}),
  wizardData: PropTypes.shape({}),
  setWizardData: PropTypes.func,
};

export default SelectGoldProduct;
