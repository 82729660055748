import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';

import i18next from 'i18next';
import { useSelector } from 'react-redux';
import TableData, { TableDataElement } from './TableData';
import { selectSettings } from '../../store/selectors/settingsSelectors';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';
import { pxToRem } from '../../assets/styles/helper';

export const TableHeadElement = styled.div`
  background-color: ${themeColors.colorTableHeaderBackground};
  box-shadow: ${variables.shadow.boxShadow};
  z-index: ${variables.zIndex.indexXxs};
  padding: ${pxToRem(16)} ${pxToRem(24)};
  width: 100%;
  ${(props) => props.css};

  ${TableDataElement} {
    line-height: 1.75;
    letter-spacing: 0;
    font-weight: 600;
  }
`;

const TableHead = ({ data, ...props }) => {
  const brokerSettings = useSelector(selectSettings);

  const { TransactionStatusDescriptionsUrl } = brokerSettings;

  return isEmpty(data) ? null : (
    <TableHeadElement {...props}>
      {data.map((headData) => (
        <TableData key={headData.id}>
          {headData.content ===
            i18next.t('portfolio.transactions.tableHeadings.status') &&
          TransactionStatusDescriptionsUrl ? (
            <a
              href={TransactionStatusDescriptionsUrl}
              rel="noopener noreferrer"
              target="_blank"
            >
              {headData.content}
            </a>
          ) : (
            <p>{headData.content}</p>
          )}
        </TableData>
      ))}
    </TableHeadElement>
  );
};

TableHead.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

export default TableHead;
