import React from 'react';
import PropTypes from 'prop-types';
import { connectStateResults } from 'react-instantsearch-core';
import LoaderContainer from './LoaderContainer';
import LoaderWrap from './LoaderWrap';
import LoaderIcon from './LoaderIcon';

const AlgoliaLoader = ({ children, isSearchStalled }) => (
  <LoaderContainer>
    {children}
    {isSearchStalled && (
      <LoaderWrap data-cy="loading-indicator">
        <LoaderIcon />
      </LoaderWrap>
    )}
  </LoaderContainer>
);

AlgoliaLoader.propTypes = {
  children: PropTypes.node,
  isSearchStalled: PropTypes.bool,
};

export default connectStateResults(AlgoliaLoader);
