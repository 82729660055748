import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import StepByStep from '../../StepByStep';
import WireWithdrawalModal from './Wire/WireWithdrawalModal';
import CheckWithdrawalModal from './Check/CheckWithdrawalModal';
import StandardWithdrawalTypeSelect from './StandardWithdrawalTypeSelect';
import { getCurrentAccountUid } from '../../../store/selectors/accountSelectors';
import { WITHDRAWAL_WIZARD_TYPES } from '../../../constants/standardWithdrawalWizardConstants';
import { standardWithdrawalWizardStateReset } from '../../../store/actions/orders/orderActions';
import { setWizardContent } from '../../../util/helpers/wizardHelpers';
import {
  ERROR,
  SELECT_TYPE,
  STANDARD_WITHDRAWAL_WIZARD,
} from '../../../constants/sessionStorage';
import useGtmHook from '../../../util/hooks/useGtmHook';
import { useSessionStorageState } from '../../../util/hooks/useSessionStorageState';
import { isActionRestrictedHelper } from '../../../util/helpers/restrictionHelper';
import { restrictionTypes } from '../../../util/enum/api/restrictionTypes';
import Restriction from '../RestrictionModal/Restriction';
import Modal from '../../Modal/Modal';
import useRestrictionHook from '../../../util/hooks/useRestrictionHook';
import UnverifiedBankAccountList from '../../UnverifiedBankAccountList/UnverifiedBankAccountList';

const standardDepositModals = [
  StandardWithdrawalTypeSelect,
  {
    bySelectedType: {
      [WITHDRAWAL_WIZARD_TYPES.WIRE]: WireWithdrawalModal,
      [WITHDRAWAL_WIZARD_TYPES.CHECK]: CheckWithdrawalModal,
    },
  },
];

const StandardWithdrawalWizard = ({
  isModalOpen,
  handleClose,
  balances,
  handleRefreshTransactions,
  unverifiedBankAccounts,
}) => {
  const { gtmDialogScreenView } = useGtmHook();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedType, setSelectedType] = useSessionStorageState(
    SELECT_TYPE,
    null,
  );
  const restrictions = useRestrictionHook();

  const [error, setError] = useSessionStorageState(ERROR, '');

  const accountUid = useSelector(getCurrentAccountUid);

  const handleCloseModal = () => {
    handleClose();
    setSelectedType(null);
    dispatch(standardWithdrawalWizardStateReset());
  };

  const handleSelectType = (type, handleNext) => {
    setWizardContent(SELECT_TYPE, type);
    setSelectedType(type);
    handleNext();
  };

  useEffect(() => {
    gtmDialogScreenView({
      title: `Withdraw Funds`,
    });
  }, []); //eslint-disable-line

  if (!isModalOpen) {
    return null;
  }

  if (unverifiedBankAccounts?.length) {
    return (
      <Modal
        isOpen={isModalOpen}
        size="sm"
        close={handleCloseModal}
        title={t('withdrawalWizard.withdrawFunds')}
      >
        <UnverifiedBankAccountList bankAccounts={unverifiedBankAccounts} />
      </Modal>
    );
  }

  if (
    isActionRestrictedHelper(restrictions, [
      restrictionTypes.LogonIsPrevented,
      restrictionTypes.WithdrawalsLocked,
    ])
  ) {
    return (
      <Modal
        isOpen={isModalOpen}
        size="sm"
        close={handleCloseModal}
        title={t('withdrawalWizard.withdrawFunds')}
      >
        <Restriction restrictions={restrictions} />
      </Modal>
    );
  }
  return (
    <StepByStep sessionScopeStep={STANDARD_WITHDRAWAL_WIZARD}>
      {({ goStepBack, goStepForward, isLastStep }) =>
        standardDepositModals.map((modal) => {
          if (modal.bySelectedType) {
            if (!selectedType) {
              return null;
            }

            return React.createElement(modal.bySelectedType[selectedType], {
              handleBack: goStepBack,
              handleClose: handleCloseModal,
              handleNext: goStepForward,
              isModalOpen,
              isLastStep,
              isCheck: selectedType === WITHDRAWAL_WIZARD_TYPES.CHECK,
              accountUid,
              error,
              setError,
              balances,
              handleRefreshTransactions,
            });
          }

          return React.createElement(modal, {
            handleBack: goStepBack,
            handleClose: handleCloseModal,
            handleNext: goStepForward,
            setSelectedType: handleSelectType,
            isModalOpen,
            handleRefreshTransactions,
          });
        })
      }
    </StepByStep>
  );
};

StandardWithdrawalWizard.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  balances: PropTypes.shape({}),
  handleRefreshTransactions: PropTypes.func,
  unverifiedBankAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      BankAccountUid: PropTypes.string,
      BankName: PropTypes.string,
      AccountMask: PropTypes.string,
      IsVerified: PropTypes.bool,
    }),
  ),
};

export default StandardWithdrawalWizard;
