import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InformationList from '../../../InformationList/InformationList';
import InformationListItem from '../../../InformationList/InformationListItem';
import { formatMailingAddress } from '../../../../util/helpers/addressHelper';

const CheckDetails = ({ isPrefunded, checkDetails }) => {
  const { t } = useTranslation();

  if (!checkDetails) {
    return null;
  }

  const { Addresses } = checkDetails;

  if (!isPrefunded) {
    return (
      <>
        <InformationList withBackground>
          {Addresses.MailingAddress && (
            <InformationListItem
              label={t('depositWizard.equityCheck.mailing')}
              value={
                <span>{formatMailingAddress(Addresses.MailingAddress)}</span>
              }
            />
          )}
          {Addresses.Overnight && (
            <InformationListItem
              label={t('depositWizard.equityCheck.overnight')}
              value={<span>{formatMailingAddress(Addresses.Overnight)}</span>}
            />
          )}
        </InformationList>
      </>
    );
  }

  return (
    <>
      <InformationList withBackground>
        {Addresses.FundingCheckMailingAddress && (
          <InformationListItem
            label={t('depositWizard.equityCheck.fundingCheckMailingAddress')}
            value={
              <span>
                {formatMailingAddress(Addresses.FundingCheckMailingAddress)}
              </span>
            }
          />
        )}
        {Addresses.FundingCheckMailingAddressThirdPartyShipper && (
          <InformationListItem
            label={t('depositWizard.equityCheck.mailingAddress')}
            value={
              <span>
                {formatMailingAddress(
                  Addresses.FundingCheckMailingAddressThirdPartyShipper,
                )}
              </span>
            }
          />
        )}
      </InformationList>
    </>
  );
};

CheckDetails.propTypes = {
  isPrefunded: PropTypes.bool,
  checkDetails: PropTypes.shape({
    Addresses: PropTypes.shape({
      MailingAddress: PropTypes.shape({
        Receipient: PropTypes.string,
        Attn: PropTypes.string,
        AddressLine: PropTypes.string,
        City: PropTypes.string,
        StateRegion: PropTypes.string,
        PostalCode: PropTypes.string,
      }),
      Overnight: PropTypes.shape({
        Receipient: PropTypes.string,
        Attn: PropTypes.string,
        AddressLine: PropTypes.string,
        City: PropTypes.string,
        StateRegion: PropTypes.string,
        PostalCode: PropTypes.string,
      }),
      FundingCheckMailingAddress: PropTypes.shape({
        Receipient: PropTypes.string,
        Attn: PropTypes.string,
        AddressLine: PropTypes.string,
        City: PropTypes.string,
        StateRegion: PropTypes.string,
        PostalCode: PropTypes.string,
      }),
      FundingCheckMailingAddressThirdPartyShipper: PropTypes.shape({
        Receipient: PropTypes.string,
        Attn: PropTypes.string,
        AddressLine: PropTypes.string,
        City: PropTypes.string,
        StateRegion: PropTypes.string,
        PostalCode: PropTypes.string,
      }),
    }),
  }),
};

export default CheckDetails;
