import {
  PLAID_LINK_TOKEN_FETCH,
  PLAID_LINK_TOKEN_SUCCESS,
  PLAID_LINK_TOKEN_ERROR,
  PLAID_LINK_TOKEN_CLEAR,
} from './PlaidActionConstants';

// buy for storage
export const fetchLinkToken = (payload) => ({
  type: PLAID_LINK_TOKEN_FETCH,
  payload,
});

export const fetchLinkTokenSuccess = (payload) => ({
  type: PLAID_LINK_TOKEN_SUCCESS,
  payload,
});

export const fetchLinkTokenError = (payload) => ({
  type: PLAID_LINK_TOKEN_ERROR,
  payload,
});

export const clearLinkTokenState = () => ({
  type: PLAID_LINK_TOKEN_CLEAR,
});
