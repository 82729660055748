import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as Check } from '../../../assets/images/svg/check-round.svg';
import { refreshUserToken } from '../../../store/actions/login/loginActions';
import { useBrokerName } from '../../../util/hooks/userBrokerName';
import SingleColumnList from '../../../components/TwoColumnList/SingleColumnList';
import Note from '../../../components/Notes/Note';
import AuthCard from '../../../components/Auth/AuthCard';
import Auth from '../../../components/Auth/Auth';
import SectionLoader from '../../../components/Loader/SectionLoader';
import AuthButtons from '../../../components/Auth/AuthButtons';
import { landingPageHelper } from '../../../util/helpers/landingPageHelper';
import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import { variables } from '../../../assets/styles/variables';

export const TwoFaSuccessfulIcon = styled(Check)`
  margin: ${pxToRem(80)} 0 ${pxToRem(56)};
  width: ${pxToRem(160)};
  height: ${pxToRem(160)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin: ${pxToRemMd(80)} 0 ${pxToRemMd(56)};
    width: ${pxToRemMd(160)};
    height: ${pxToRemMd(160)};
  }
`;

const TwoFASuccessfulSetup = ({ goStepForward }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const brokerName = useBrokerName();
  const [isLoading, setIsLoading] = useState(false);

  const onFinally = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(refreshUserToken({ onFinally }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SectionLoader isLoading={isLoading}>
      <Auth>
        <AuthCard title={t('twoFa.loginSetup.success.title')}>
          <SingleColumnList>
            <SingleColumnList alignCenter>
              <TwoFaSuccessfulIcon />
            </SingleColumnList>
            <Note
              textAlignCenter
              text={t('twoFa.loginSetup.success.note', { brokerName })}
              marginBottom={72}
            />
            <AuthButtons
              showOnlyPrimary
              primaryButtonProps={{
                onClick: () => goStepForward(landingPageHelper()),
                label: t('common.continue'),
              }}
            />
          </SingleColumnList>
        </AuthCard>
      </Auth>
    </SectionLoader>
  );
};

TwoFASuccessfulSetup.propTypes = {
  goStepForward: PropTypes.func,
};
export default TwoFASuccessfulSetup;
