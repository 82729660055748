import React, { useMemo, useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';

import TextField from '../../../InputFields/TextField';
import SelectField from '../../../InputFields/SelectField';

import {
  accountSecurityQuestionTypes,
  UUAT_SECURITY_QUESTION_CHANGE,
} from '../../../../util/enum/api/accountTypes';
import { selectAuthUser } from '../../../../store/selectors/userSelectors';
import { updateUser } from '../../../../store/actions/user/userActions';
import useGtmHook from '../../../../util/hooks/useGtmHook';
import { selectSecurityQuestions } from '../../../../store/selectors/commonSelectors';

import { getPortalSecurityQuestions } from '../../../../store/actions/common/commonActions';
import { parseEnumType } from '../../../../util/helpers/enumMappers';
import { refreshUserToken } from '../../../../store/actions/login/loginActions';
import { SECURITY_QUESTION_CHANGED } from '../../../../constants/gtmEvents';
import { changeSecurityQuestionValidationSchema } from '../../../../validation/changeSecurityQuestionValidationSchema';
import FormContainer from '../../../FormContainer/FormContainer';
import ModalButtons from '../../../Modal/ModalButtons';
import SectionLoader from '../../../Loader/SectionLoader';
import ModalBody from '../../../Modal/ModalBody';

const EditSecurityQuestion = ({ handleNext, setError, isOpen, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectAuthUser);
  const [isLoading, setIsLoading] = useState(false);
  const { gtmEvent } = useGtmHook();

  const refreshUser = () => {
    dispatch(refreshUserToken());
  };

  const securityQuestions = useSelector(selectSecurityQuestions);

  const handleGtm = () => {
    gtmEvent(SECURITY_QUESTION_CHANGED);
  };

  const QUESTIONS_OPTIONS = useMemo(
    () =>
      securityQuestions.map((question) => ({
        label: t(
          `login.securityQuestionTypes.${parseEnumType(
            accountSecurityQuestionTypes,
            question,
          )}`,
        ),
        value: parseEnumType(accountSecurityQuestionTypes, question),
      })),
    [securityQuestions, t],
  );

  const handleSubmit = ({ securityQuestion, answer }) =>
    dispatch(
      updateUser({
        userUid: user.UserUid,
        data: [
          {
            op: 'replace',
            path: '/SecurityQuestionType',
            value:
              accountSecurityQuestionTypes.indexOf(securityQuestion.value) + 1,
          },
          {
            op: 'replace',
            path: '/SecurityAnswer',
            value: answer,
          },
        ],
        actionType: UUAT_SECURITY_QUESTION_CHANGE,
        setError,
        setIsLoading,
        handleGtm,
        refreshUser,
        handleNext,
      }),
    );

  const initialValues = {
    securityQuestion: {
      label: user.SecurityQuestionType
        ? t(`login.securityQuestionTypes.${user.SecurityQuestionType}`)
        : '',
      value: user.SecurityQuestionType || '',
    },
    answer: '',
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(getPortalSecurityQuestions());
    }
  }, [isOpen]); // eslint-disable-line

  if (isEmpty(user)) {
    return null;
  }

  return (
    <SectionLoader isLoading={isLoading}>
      <ModalBody>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={changeSecurityQuestionValidationSchema}
        >
          {({ dirty, isValid }) => (
            <Form>
              <FormContainer>
                <SelectField
                  label={t('profileSettings.securityQuestion')}
                  name="securityQuestion"
                  options={QUESTIONS_OPTIONS}
                />
                <Field
                  label={t('profileSettings.answer')}
                  name="answer"
                  component={TextField}
                />
              </FormContainer>
              <ModalButtons
                secondaryButtonProps={{
                  label: t('common.cancel'),
                  onClick: onClose,
                }}
                primaryButtonProps={{
                  type: 'submit',
                  disabled: !dirty || !isValid,
                  label: t('common.save'),
                }}
              />
            </Form>
          )}
        </Formik>
      </ModalBody>
    </SectionLoader>
  );
};

EditSecurityQuestion.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  setError: PropTypes.func,
  handleNext: PropTypes.func,
};

export default EditSecurityQuestion;
