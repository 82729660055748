import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconButton from '../IconButton/IconButton';
import themeColors from '../../assets/styles/themeColors';
import { pxToRem } from '../../assets/styles/helper';

const IconButtonWithLabel = styled(IconButton)`
  display: flex;
  flex-shrink: 0;

  &,
  > * {
    color: ${({ color }) => color || themeColors.colorIconPrimary};
  }

  svg {
    color: ${({ color }) => color || themeColors.colorIconPrimary};
    width: ${({ width }) => (width ? pxToRem(width) : pxToRem(16))};
    height: ${({ height }) => (height ? pxToRem(height) : pxToRem(16))};
    margin-right: ${pxToRem(8)};
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    user-interaction: none;
  }
`;

export default IconButtonWithLabel;

IconButtonWithLabel.propTypes = {
  onClick: PropTypes.func,
};
