import i18next from 'i18next';
import * as Yup from 'yup';
import { USA_COUNTRY_LABEL } from '../util/constants';

export const internationalBankAccountValidationSchema = Yup.object().shape({
  bankAbaOrRoutingNum: Yup.number().when('countrySelect', {
    is: (countrySelect) => countrySelect.value === USA_COUNTRY_LABEL,
    then: Yup.number().required(
      i18next.t(
        'validationSchema.bankAccount.bankAccountRoutingNumberRequired',
      ),
    ),
    otherwise: Yup.number(),
  }),
  countrySelect: Yup.object().shape({
    value: Yup.string().required(
      i18next.t('validationSchema.general.countryIsRequired'),
    ),
  }),
  bankName: Yup.string().when('countrySelect', {
    is: (countrySelect) => countrySelect.value !== USA_COUNTRY_LABEL,
    then: Yup.string().required(
      i18next.t('validationSchema.bankAccount.bankAccountNameRequired'),
    ),
    otherwise: Yup.string(),
  }),
  swiftCode: Yup.string().when('countrySelect', {
    is: (countrySelect) => countrySelect.value !== USA_COUNTRY_LABEL,
    then: Yup.string().required(
      i18next.t('validationSchema.bankAccount.swiftCodeRequired'),
    ),
    otherwise: Yup.string(),
  }),
});
