import {
  TWO_FA_TYPES,
  TWO_FA_TYPES_SUCCESS,
  TWO_FA_TYPES_ERROR,
  TWO_FA_APPLICATION_KEYS,
  TWO_FA_APPLICATION_KEYS_SUCCESS,
  TWO_FA_APPLICATION_KEYS_ERROR,
  TWO_FA_CODE,
  TWO_FA_CODE_SUCCESS,
  TWO_FA_CODE_ERROR,
  TWO_FA_CODE_VIA_PHONE,
  TWO_FA_CODE_VIA_PHONE_SUCCESS,
  TWO_FA_CODE_VIA_PHONE_ERROR,
  TWO_FA_CODE_VIA_EMAIL,
  TWO_FA_CODE_VIA_EMAIL_SUCCESS,
  TWO_FA_CODE_VIA_EMAIL_ERROR,
  SAVE_TWO_FA,
  SAVE_TWO_FA_SUCCESS,
  SAVE_TWO_FA_ERROR,
  DELETE_TWO_FA,
  DELETE_TWO_FA_SUCCESS,
  DELETE_TWO_FA_ERROR,
  GET_TWO_FA_CUSTOMER_SUPPORT_IS_OPEN,
  GET_TWO_FA_CUSTOMER_SUPPORT_IS_OPEN_SUCCESS,
  GET_TWO_FA_CUSTOMER_SUPPORT_IS_OPEN_ERROR,
  GET_TWO_FA,
  GET_TWO_FA_SUCCESS,
  GET_TWO_FA_ERROR,
} from './twoFAActionConstants';

export const fetchTwoFaTypes = (payload) => ({
  type: TWO_FA_TYPES,
  payload,
});

export const fetchTwoFaTypesSuccess = (payload) => ({
  type: TWO_FA_TYPES_SUCCESS,
  payload,
});

export const fetchTwoFaTypesError = (payload) => ({
  type: TWO_FA_TYPES_ERROR,
  payload,
});

export const fetchTwoFaKeys = (payload) => ({
  type: TWO_FA_APPLICATION_KEYS,
  payload,
});

export const fetchTwoFaKeysSuccess = (payload) => ({
  type: TWO_FA_APPLICATION_KEYS_SUCCESS,
  payload,
});

export const fetchTwoFaKeysError = (payload) => ({
  type: TWO_FA_APPLICATION_KEYS_ERROR,
  payload,
});

export const fetchTwoFa = (payload) => ({
  type: GET_TWO_FA,
  payload,
});

export const fetchTwoFaSuccess = (payload) => ({
  type: GET_TWO_FA_SUCCESS,
  payload,
});

export const fetchTwoFaError = (payload) => ({
  type: GET_TWO_FA_ERROR,
  payload,
});

export const postTwoFaCode = (payload) => ({
  type: TWO_FA_CODE,
  payload,
});

export const postTwoFaCodeSuccess = (payload) => ({
  type: TWO_FA_CODE_SUCCESS,
  payload,
});

export const postTwoFaCodeError = (payload) => ({
  type: TWO_FA_CODE_ERROR,
  payload,
});

export const fetchTwoFaCodeViaPhone = (payload) => ({
  type: TWO_FA_CODE_VIA_PHONE,
  payload,
});

export const fetchTwoFaCodeViaPhoneSuccess = (payload) => ({
  type: TWO_FA_CODE_VIA_PHONE_SUCCESS,
  payload,
});

export const fetchTwoFaCodeViaPhoneError = (payload) => ({
  type: TWO_FA_CODE_VIA_PHONE_ERROR,
  payload,
});

export const fetchTwoFaCodeViaEmail = (payload) => ({
  type: TWO_FA_CODE_VIA_EMAIL,
  payload,
});

export const fetchTwoFaCodeViaEmailSuccess = (payload) => ({
  type: TWO_FA_CODE_VIA_EMAIL_SUCCESS,
  payload,
});

export const fetchTwoFaCodeViaEmailError = (payload) => ({
  type: TWO_FA_CODE_VIA_EMAIL_ERROR,
  payload,
});

export const postTwoFa = (payload) => ({
  type: SAVE_TWO_FA,
  payload,
});

export const postTwoFaSuccess = (payload) => ({
  type: SAVE_TWO_FA_SUCCESS,
  payload,
});

export const postTwoFaError = (payload) => ({
  type: SAVE_TWO_FA_ERROR,
  payload,
});

export const deleteTwoFa = (payload) => ({
  type: DELETE_TWO_FA,
  payload,
});

export const deleteTwoFaSuccess = (payload) => ({
  type: DELETE_TWO_FA_SUCCESS,
  payload,
});

export const deleteTwoFaError = (payload) => ({
  type: DELETE_TWO_FA_ERROR,
  payload,
});
export const fetchTwoFaCustomerSupportIsOpen = (payload) => ({
  type: GET_TWO_FA_CUSTOMER_SUPPORT_IS_OPEN,
  payload,
});

export const fetchTwoFaCustomerSupportIsOpenSuccess = (payload) => ({
  type: GET_TWO_FA_CUSTOMER_SUPPORT_IS_OPEN_SUCCESS,
  payload,
});

export const fetchTwoFaCustomerSupportIsOpenError = (payload) => ({
  type: GET_TWO_FA_CUSTOMER_SUPPORT_IS_OPEN_ERROR,
  payload,
});
