import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { upperFirst } from 'lodash';
import Modal from '../../../Modal/Modal';
import {
  IRA_ENTRUST_TYPE,
  IRA_EQUITY_TYPE,
  IRA_MILLENIUM_TYPE,
} from '../../../../util/constants';
import EntrustPrefundedWithdrawalWizard from './EntrustPrefundedWithdrawalWizard';
import PrefundedCheckWithdrawalWizard from './PrefundedCheckWithdrawalWizard';
import { isActionRestrictedHelper } from '../../../../util/helpers/restrictionHelper';
import { restrictionTypes } from '../../../../util/enum/api/restrictionTypes';
import useRestrictionHook from '../../../../util/hooks/useRestrictionHook';
import Restriction from '../../RestrictionModal/Restriction';

const PrefundedFeeWithdrawalWizard = ({
  isModalOpen,
  type,
  handleClose,
  handleRefreshTransactions,
  accountUid,
}) => {
  const { t } = useTranslation();
  const [titleType, setTitleType] = useState('');
  const restrictions = useRestrictionHook();

  const renderModalContent = () => {
    if (type === IRA_ENTRUST_TYPE) {
      return (
        <EntrustPrefundedWithdrawalWizard
          accountUid={accountUid}
          closeModal={handleClose}
          type={type}
          setTitleType={setTitleType}
          handleRefreshTransactions={handleRefreshTransactions}
        />
      );
    }

    if (type === IRA_EQUITY_TYPE) {
      return (
        <PrefundedCheckWithdrawalWizard
          accountUid={accountUid}
          type={type}
          closeModal={handleClose}
          setTitleType={setTitleType}
          handleRefreshTransactions={handleRefreshTransactions}
        />
      );
    }

    if (type === IRA_MILLENIUM_TYPE) {
      return (
        <PrefundedCheckWithdrawalWizard
          accountUid={accountUid}
          type={type}
          closeModal={handleClose}
          setTitleType={setTitleType}
          handleRefreshTransactions={handleRefreshTransactions}
        />
      );
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      close={handleClose}
      title={
        titleType
          ? t('IRAWithdraw.prefundedWithdraw.titleByType', {
              type: upperFirst(titleType),
            })
          : t('IRAWithdraw.prefundedWithdraw.title')
      }
      size="sm"
    >
      {isActionRestrictedHelper(restrictions, [
        restrictionTypes.LogonIsPrevented,
        restrictionTypes.WithdrawalsLocked,
      ]) ? (
        <Restriction restrictions={restrictions} />
      ) : (
        renderModalContent()
      )}
    </Modal>
  );
};

PrefundedFeeWithdrawalWizard.propTypes = {
  type: PropTypes.string,
  handleClose: PropTypes.func,
  handleRefreshTransactions: PropTypes.func,
  accountUid: PropTypes.string,
  isModalOpen: PropTypes.bool,
};

export default PrefundedFeeWithdrawalWizard;
