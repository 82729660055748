import styled from 'styled-components';
import { pxToRem } from '../../../assets/styles/helper';
import { uFlexCenter, uFlexColumn } from '../../../assets/styles/utility';
import themeColors from '../../../assets/styles/themeColors';

export const HoldingTableEmptyStyle = styled.div`
  ${uFlexColumn};
  ${uFlexCenter};
  min-height: ${pxToRem(232)};
  ${(props) => props.isSmall && `min-height: ${pxToRem(100)}`};
  ${(props) => props.css};
`;

export const HoldingTableEmptyTextStyle = styled.p`
  color: ${themeColors.colorTextSecondary};
  font-weight: 600;
`;

export const HoldingTableEmptyButton = styled.div`
  margin-top: ${pxToRem(32)};

  > button {
    width: ${pxToRem(200)};
  }
`;
