import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  INVITE_USER_TWO_FA_HAVING_TROUBLE,
  TWO_FA_FORGOT_PASSWORD_IDENTITY_HAVING_TROUBLE_PAGE,
  TWO_FA_HAVING_TROUBLE_PAGE,
} from '../../../constants/pages';

const HavingTroubleLink = ({ isInviteUserFlow, isForgotPasswordFlow }) => {
  const { t } = useTranslation();

  const navigate = () => {
    if (isForgotPasswordFlow) {
      return TWO_FA_FORGOT_PASSWORD_IDENTITY_HAVING_TROUBLE_PAGE;
    }

    if (isInviteUserFlow) {
      return INVITE_USER_TWO_FA_HAVING_TROUBLE;
    }

    return TWO_FA_HAVING_TROUBLE_PAGE;
  };

  return (
    <NavLink to={navigate()}>
      {t('twoFa.loginVerify.trouble.havingTrouble')}
    </NavLink>
  );
};

HavingTroubleLink.propTypes = {
  isInviteUserFlow: PropTypes.bool,
  isForgotPasswordFlow: PropTypes.bool,
};

export default HavingTroubleLink;
