import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ChromePopUpNote = () => {
  const { t } = useTranslation();
  const [isBraveBrowser, setIsBraveBrowser] = useState(false);

  useEffect(() => {
    const isBrave = async () => {
      const result = await !!(
        navigator.brave && (await navigator.brave.isBrave())
      );

      setIsBraveBrowser(result);
    };

    isBrave();
  }, []);

  if (isBraveBrowser) {
    return (
      <>
        <ul>
          <li>{t('statements.popUp.brave.list.1')}</li>
          <li>{t('statements.popUp.brave.list.2')}</li>
          <li>{t('statements.popUp.brave.list.3')}</li>
          <li>{t('statements.popUp.brave.list.4')}</li>
          <li>{t('statements.popUp.brave.list.5')}</li>
        </ul>
      </>
    );
  }

  return (
    <>
      <ul>
        <li>{t('statements.popUp.chrome.list.1')}</li>
        <li>{t('statements.popUp.chrome.list.2')}</li>
        <li>{t('statements.popUp.chrome.list.3')}</li>
        <li>{t('statements.popUp.chrome.list.4')}</li>
        <li>{t('statements.popUp.chrome.list.5')}</li>
        <li>{t('statements.popUp.chrome.list.6')}</li>
        <li>{t('statements.popUp.chrome.list.7')}</li>
        <li>{t('statements.popUp.chrome.list.8')}</li>
        <li>{t('statements.popUp.chrome.list.9')}</li>
      </ul>

      <p>{t('statements.popUp.chrome.note')}</p>
    </>
  );
};

export default ChromePopUpNote;
