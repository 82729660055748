import React from 'react';
import PropTypes from 'prop-types';
import {
  IRA_ENTRUST_TYPE,
  IRA_EQUITY_TYPE,
  IRA_MILLENIUM_TYPE,
} from '../../../../../util/constants';
import CheckInformationTextReview from './CheckInformationTextReview';
import CheckInformationStepsReview from './CheckInformationStepsReview';
import SectionLoader from '../../../../Loader/SectionLoader';

const CheckInformationReview = ({
  handleNext,
  handleBack,
  value,
  confirmWithdraw,
  type,
  isLoading,
}) => {
  const { address, amount } = value;

  return (
    <SectionLoader isLoading={isLoading}>
      {(type === IRA_ENTRUST_TYPE || type === IRA_MILLENIUM_TYPE) && (
        <CheckInformationTextReview
          amount={amount}
          address={address}
          type={type}
          confirmWithdraw={confirmWithdraw}
          handleNext={handleNext}
          handleBack={handleBack}
        />
      )}
      {type === IRA_EQUITY_TYPE && (
        <CheckInformationStepsReview
          amount={amount}
          address={address}
          type={type}
          confirmWithdraw={confirmWithdraw}
          handleNext={handleNext}
          handleBack={handleBack}
        />
      )}
    </SectionLoader>
  );
};

CheckInformationReview.propTypes = {
  value: PropTypes.shape({
    address: PropTypes.shape({
      label: PropTypes.string,
    }),
    amount: PropTypes.number,
  }),
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  confirmWithdraw: PropTypes.func,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default CheckInformationReview;
