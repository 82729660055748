import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { useTranslation } from 'react-i18next';

import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import Modal from '../../Modal/Modal';
import ProductField from './Fields/ProductField';
import ImageField from './Fields/ImageField';
import DividerField from './Fields/DividerField';
import DetailRow from './Fields/DetailRow';
import config from '../../../config';
import { selectSettings } from '../../../store/selectors/settingsSelectors';

const TransactionDetails = ({ isOpen, onClose, transaction, errorMessage }) => {
  const { t } = useTranslation();
  const brokerSettings = useSelector(selectSettings);
  const [fields, setFields] = useState([]);
  const isSegregated = transaction?.IsSegregated;
  const segregatedCaption = brokerSettings?.SegregatedCaption;

  useEffect(() => {
    if (!isEmpty(transaction)) {
      setFields(transaction.Fields);
    }
  }, [transaction]);

  const renderItems = () =>
    fields.map((field) => {
      if (field.FormatType === 'ProductImage') {
        const imageUrl = config.algoliaSearch.imageCode
          .replace('{product_code}', field.ProductCode)
          .replace('{base_url}', brokerSettings?.RootUrl);

        return <ImageField key={uuid()} url={imageUrl} />;
      }

      if (field.FormatType === 'TransactionIcon') {
        return (
          <ImageField transactionType={field.TransactionType} key={uuid()} />
        );
      }

      if (field.FormatType === 'Product') {
        return (
          <ProductField
            key={uuid()}
            field={field}
            isSegregated={isSegregated}
            segregatedCaption={segregatedCaption}
          />
        );
      }

      if (field.FormatType === 'Divider') {
        return <DividerField key={uuid()} />;
      }

      return (
        <DetailRow brokerSettings={brokerSettings} key={uuid()} field={field} />
      );
    });

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      close={onClose}
      title={t('portfolio.transactions.title')}
      dataCy="container-transaction-details"
    >
      {!isEmpty(transaction) && !errorMessage ? (
        <div>{renderItems()}</div>
      ) : (
        <span>{errorMessage}</span>
      )}
    </Modal>
  );
};

TransactionDetails.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  transaction: PropTypes.shape({
    DateTime: PropTypes.string,
    StatusType: PropTypes.string,
    Type: PropTypes.string,
    IsSegregated: PropTypes.bool,
    Fields: PropTypes.arrayOf(
      PropTypes.shape({
        FormatType: PropTypes.string,
        UnitType: PropTypes.string,
        AmountOrQuantity: PropTypes.number,
      }),
    ),
  }),
  errorMessage: PropTypes.string,
};

export default TransactionDetails;
