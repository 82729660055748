import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ModalBody from '../Modal/ModalBody';
import ModalButtons from '../Modal/ModalButtons';
import Paragraph from '../Paragraph/Paragraph';
import Modal from '../Modal/Modal';
import { ReactComponent as AlertTriangle } from '../../assets/images/svg/alert-triangle.svg';
import { pxToRem } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';

const Alert = styled(AlertTriangle)`
  height: ${pxToRem(48)};
  color: ${themeColors.colorWarning};
`;

const AlertContainer = styled.div`
  display: flex;
  justify-content: center;
  border: 1px solid ${themeColors.colorWarning};
  border-radius: ${pxToRem(8)};
  padding: ${pxToRem(16)} 0 ${pxToRem(16)} 0;
  margin-bottom: ${pxToRem(16)};
`;

const ErrorModal = ({
  title,
  subtitle,
  message,
  btnTitle,
  isOpen,
  close,
  goBack,
}) => (
  <>
    <Modal
      title={title}
      isOpen={isOpen}
      size="sm"
      close={close}
      goBack={goBack}
    >
      <ModalBody>
        <AlertContainer>
          <Alert />
        </AlertContainer>
        <Paragraph bold>{subtitle}</Paragraph>
        <Paragraph fontSize={16} marginTop={12} marginBottom={12}>
          {message}
        </Paragraph>
        <ModalButtons
          isVertical
          marginTop
          showOnlySecondary
          secondaryButtonProps={{
            label: btnTitle,
            onClick: close,
          }}
        />
      </ModalBody>
    </Modal>
  </>
);

ErrorModal.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  message: PropTypes.string,
  btnTitle: PropTypes.string,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  goBack: PropTypes.func,
};

export default ErrorModal;
