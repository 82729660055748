import styled from 'styled-components';
import { pxToRem } from '../../assets/styles/helper';
import { uFlexCenter } from '../../assets/styles/utility';
import { variables } from '../../assets/styles/variables';

const LoaderWrap = styled.div`
  ${uFlexCenter};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: ${pxToRem(15)} 0;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: ${variables.zIndex.indexLg};
`;

export default LoaderWrap;
