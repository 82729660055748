import { getRequest, patchRequest, postRequest, replaceInUrl } from './index';
import apiEndpoints from './apiEndpoints';

export const getMetalStreamSettings = (accountUid) =>
  getRequest(
    replaceInUrl(apiEndpoints.metalStream.getMetalStreamSettings, {
      accountUid,
    }),
  );

export const patchMetalStreamSettings = ({ accountUid, data }) =>
  patchRequest(
    replaceInUrl(apiEndpoints.metalStream.getMetalStreamSettings, {
      accountUid,
    }),
    data,
  );

export const getMetalStreamProductRequest = ({
  accountUid,
  side,
  metalType,
  productType,
}) =>
  getRequest(replaceInUrl(apiEndpoints.products.getPrices, { accountUid }), {
    side,
    metaltype: metalType,
    producttype: productType,
  });

export const getMetalStreamBankAccountsRequest = ({
  accountUid,
  type,
  active,
}) =>
  getRequest(replaceInUrl(apiEndpoints.bankAccounts.get, { accountUid }), {
    type,
    active,
  });

export const postMetalStreamSetupRequest = (accountUid, data) =>
  postRequest(
    replaceInUrl(apiEndpoints.metalStream.getMetalStreamSettings, {
      accountUid,
    }),
    data,
  );
