import React from 'react';
import i18next from 'i18next';
import ChromePopUpNote from '../../components/PopUpNote/ChromePopUpNote';
import EdgePopUpNote from '../../components/PopUpNote/EdgePopUpNote';
import FirefoxPopUpNote from '../../components/PopUpNote/FirefoxPopUpNote';
import SafariPopUpNote from '../../components/PopUpNote/SafariPopUpNote';
import { getBrowserInfo } from '../helpers/browserHelper';

export const usePopUpInstructions = () => {
  const { BrowserName } = getBrowserInfo();

  switch (BrowserName) {
    case 'Firefox':
      return <FirefoxPopUpNote />;

    case 'Chrome':
      return <ChromePopUpNote />;

    case 'Edge':
      return <EdgePopUpNote />;

    case 'Safari':
      return <SafariPopUpNote />;

    default:
      return <p>{i18next.t('statements.popUp.generalNote')}</p>;
  }
};
