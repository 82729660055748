import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RadioOn } from '../../../../../assets/images/svg/radio-on.svg';
import { ReactComponent as RadioOff } from '../../../../../assets/images/svg/radio-off.svg';
import {
  mediaBelow,
  pxToRem,
  pxToRemMd,
} from '../../../../../assets/styles/helper';
import { variables } from '../../../../../assets/styles/variables';
import themeColors from '../../../../../assets/styles/themeColors';
import { uColumn, uFlexCenter } from '../../../../../assets/styles/utility';
import Paragraph from '../../../../Paragraph/Paragraph';
import Badge from '../../../../BankAccountsCard/Badge';
import {
  isBankAccountVerified,
  getBankAccountStatus,
} from '../../../../../util/helpers/bankAccountHelper';
import IconButtonWithLabel from '../../../../BankAccountsCard/IconButtonWithLabel';
import { ReactComponent as WrenchIcon } from '../../../../../assets/images/svg/wrench.svg';
import { parseEnumType } from '../../../../../util/helpers/enumMappers';
import { bankAccountPurposeTypes } from '../../../../../util/enum/api/bankAccountTypes';

export const BankAccountItemContainer = styled.li`
  display: flex;
  width: 100%;
  padding: ${pxToRem(6.5)} ${pxToRem(16)};
  border-radius: ${variables.borderRadius.borderRadius};
  border: 1px solid ${themeColors.colorBorderPrimary};
  width: 100%;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  ${({ selected }) =>
    selected &&
    `
      border: 1px solid ${themeColors.colorTextPrimary};
      color: ${themeColors.colorTextPrimary};
    `}

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(6)} ${pxToRemMd(18)};
  } ;
`;

export const BankAccountIndicator = styled.div`
  ${uFlexCenter};
  margin-top: 2px;
  margin-right: ${pxToRem(12)};
  display: flex;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  &,
  svg {
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-right: ${pxToRemMd(10)};

    &,
    svg {
      width: ${pxToRemMd(16)};
      height: ${pxToRemMd(16)};
    }
  }
`;

const BankAccountItemContent = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

const BankAccountItemDetails = styled.div`
  ${uColumn}
  margin-right: auto;
  padding-right: ${pxToRem(8)};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export const BankAccountInput = styled.input`
  display: none;
`;

const BankAccountItem = ({
  label,
  selected,
  bankAccount,
  handleSelectBankAccount,
  handleFixBankAccount,
}) => {
  const { t } = useTranslation();

  const { PurposeType } = bankAccount;

  const isWireBankAccount =
    parseEnumType(bankAccountPurposeTypes, PurposeType) ===
    parseEnumType(bankAccountPurposeTypes, 2);

  const renderFixButton = () => {
    if (isWireBankAccount) {
      return false;
    }

    const actionRequiredStatus = t(
      'bankAccounts.verificationStatus.actionRequired',
    );

    const { status } = getBankAccountStatus(bankAccount);

    return (
      status === actionRequiredStatus && !isBankAccountVerified(bankAccount)
    );
  };

  const isBankAccountItemDisabled = () => !isBankAccountVerified(bankAccount);

  return (
    <BankAccountItemContainer
      onClick={() => {
        if (!isBankAccountVerified(bankAccount)) {
          return;
        }

        handleSelectBankAccount(bankAccount);
      }}
      disabled={isBankAccountItemDisabled()}
      selected={selected}
    >
      <BankAccountInput type="radio" name="bank-account" checked={selected} />
      <BankAccountIndicator
        disabled={isBankAccountItemDisabled()}
        selected={selected}
      >
        {selected ? <RadioOn /> : <RadioOff />}
      </BankAccountIndicator>
      <BankAccountItemContent>
        <BankAccountItemDetails
          disabled={isBankAccountItemDisabled()}
          selected={selected}
        >
          <Paragraph>{label}</Paragraph>
          {isBankAccountItemDisabled() && (
            <Badge {...getBankAccountStatus(bankAccount)} />
          )}
        </BankAccountItemDetails>
        {renderFixButton() && (
          <IconButtonWithLabel
            type="button"
            onClick={(event) => handleFixBankAccount(event, bankAccount)}
            color={themeColors.colorPrimary}
          >
            <WrenchIcon width={12} height={12} />
            <Paragraph fontSize={16} marginLeft={6} textDecoration="underline">
              {t('common.fix')}
            </Paragraph>
          </IconButtonWithLabel>
        )}
      </BankAccountItemContent>
    </BankAccountItemContainer>
  );
};

BankAccountItem.propTypes = {
  label: PropTypes.string,
  selected: PropTypes.bool,
  bankAccount: PropTypes.shape({
    BankAccountUid: PropTypes.string,
    PurposeType: PropTypes.number,
  }),
  handleSelectBankAccount: PropTypes.func,
  handleFixBankAccount: PropTypes.func,
};

export default BankAccountItem;
