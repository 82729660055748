import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Auth from '../components/Auth/Auth';
import AuthButtons from '../components/Auth/AuthButtons';
import AuthCard from '../components/Auth/AuthCard';
import Paragraph from '../components/Paragraph/Paragraph';
import { EMAIL_LOGIN_PAGE } from '../constants/pages';

const ResetSuccessfulPage = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleLogin = () => {
    history.push(EMAIL_LOGIN_PAGE);
  };

  return (
    <Auth>
      <AuthCard title={t('resetPassword.title')}>
        <Paragraph isPrimaryColor bold>
          {t('resetPassword.resetSuccessful.description')}
        </Paragraph>
        <AuthButtons
          showOnlyPrimary
          primaryButtonProps={{
            label: t('login.logInTitle'),
            onClick: handleLogin,
          }}
        />
      </AuthCard>
    </Auth>
  );
};

export default ResetSuccessfulPage;
