import {
  BUY_PRODUCT_FOR_DELIVERY,
  BUY_PRODUCT_FOR_DELIVERY_ERROR,
  BUY_PRODUCT_FOR_DELIVERY_SUCCESS,
  BUY_PRODUCT_FOR_STORAGE,
  BUY_PRODUCT_FOR_STORAGE_ERROR,
  BUY_PRODUCT_FOR_STORAGE_STATE_CLEAR,
  BUY_PRODUCT_FOR_STORAGE_SUCCESS,
  FRACTIONAL_CONVERSION_SUBMIT,
  IRA_ENTRUST_WITHDRAW_SUBMIT,
  IRA_FEE_WITHDRAWAL_SUBMIT,
  UPDATE_ACH_DEPOSIT,
  UPDATE_ACH_DEPOSIT_ERROR,
  UPDATE_ACH_DEPOSIT_SUCCESS,
  UPDATE_CHECK_WITHDRAWAL,
  UPDATE_CHECK_WITHDRAWAL_ERROR,
  UPDATE_CHECK_WITHDRAWAL_SUCCESS,
  UPDATE_WIRE_WITHDRAWAL,
  UPDATE_WIRE_WITHDRAWAL_ERROR,
  UPDATE_WIRE_WITHDRAWAL_SUCCESS,
  VERIFY_BUY_FOR_DELIVERY,
  VERIFY_BUY_FOR_DELIVERY_SUCCESS,
  VERIFY_BUY_FOR_DELIVERY_ERROR,
  WITHDRAWAL_WIZARD_STATE_RESET,
  GET_QUOTES_FOR_BUY_STORAGE,
  GET_QUOTES_FOR_ORDER_DELIVERY_BUY,
  GET_QUOTES_FOR_ORDER_STORAGE_SUCCESS,
  GET_QUOTES_FOR_ORDER_STORAGE_ERROR,
  GET_QUOTES_STORAGE_EMPTY,
  GET_QUOTES_DELIVERY_EMPTY,
  EXECUTE_QUOTES_FOR_BUY_STORAGE,
  GET_QUOTES_FOR_SELL,
  GET_QUOTES_FOR_SELL_SUCCESS,
  GET_QUOTES_FOR_SELL_ERROR,
  GET_QUOTES_SELL_EMPTY,
  EXECUTE_QUOTES_FOR_SELL,
  EXECUTE_QUOTES_SELL_EMPTY,
  EXECUTE_QUOTES_FOR_SELL_SUCCESS,
  EXECUTE_QUOTES_FOR_SELL_ERROR,
} from './orderActionConstants';

// buy for storage
export const buyProductForStorage = (payload) => ({
  type: BUY_PRODUCT_FOR_STORAGE,
  payload,
});

export const buyProductForStorageSuccess = (payload) => ({
  type: BUY_PRODUCT_FOR_STORAGE_SUCCESS,
  payload,
});

export const buyProductForStorageError = (payload) => ({
  type: BUY_PRODUCT_FOR_STORAGE_ERROR,
  payload,
});

export const clearBuyStorageSuccessState = () => ({
  type: BUY_PRODUCT_FOR_STORAGE_STATE_CLEAR,
});

// buy for delivery
export const buyProductForDelivery = (payload) => ({
  type: BUY_PRODUCT_FOR_DELIVERY,
  payload,
});

export const buyProductForDeliverySuccess = () => ({
  type: BUY_PRODUCT_FOR_DELIVERY_SUCCESS,
});

export const buyProductForDeliveryError = () => ({
  type: BUY_PRODUCT_FOR_DELIVERY_ERROR,
});

// fractional conversion
export const fractionalConversionSubmit = (payload) => ({
  type: FRACTIONAL_CONVERSION_SUBMIT,
  payload,
});

// ira entrust withdraw
export const iraEntrustWithdrawSubmit = (payload) => ({
  type: IRA_ENTRUST_WITHDRAW_SUBMIT,
  payload,
});

// ira fee withdrawal submit
export const iraFeeWithdrawalSubmit = (payload) => ({
  type: IRA_FEE_WITHDRAWAL_SUBMIT,
  payload,
});

export const updateAchDeposit = (payload) => ({
  type: UPDATE_ACH_DEPOSIT,
  payload,
});

export const updateAchDepositSuccess = (payload) => ({
  type: UPDATE_ACH_DEPOSIT_SUCCESS,
  payload,
});

export const updateAchDepositError = (payload) => ({
  type: UPDATE_ACH_DEPOSIT_ERROR,
  payload,
});

export const updateWireWithdrawal = (payload) => ({
  type: UPDATE_WIRE_WITHDRAWAL,
  payload,
});

export const updateWireWithdrawalSuccess = (payload) => ({
  type: UPDATE_WIRE_WITHDRAWAL_SUCCESS,
  payload,
});

export const updateWireWithdrawalError = (payload) => ({
  type: UPDATE_WIRE_WITHDRAWAL_ERROR,
  payload,
});

export const updateCheckWithdrawal = (payload) => ({
  type: UPDATE_CHECK_WITHDRAWAL,
  payload,
});

export const updateCheckWithdrawalSuccess = (payload) => ({
  type: UPDATE_CHECK_WITHDRAWAL_SUCCESS,
  payload,
});

export const updateCheckWithdrawalError = (payload) => ({
  type: UPDATE_CHECK_WITHDRAWAL_ERROR,
  payload,
});

export const standardWithdrawalWizardStateReset = () => ({
  type: WITHDRAWAL_WIZARD_STATE_RESET,
});

export const verifyBuyForDelivery = (payload) => ({
  type: VERIFY_BUY_FOR_DELIVERY,
  payload,
});

export const verifyBuyForDeliverySuccess = (payload) => ({
  type: VERIFY_BUY_FOR_DELIVERY_SUCCESS,
  payload,
});

export const verifyBuyForDeliveryError = (payload) => ({
  type: VERIFY_BUY_FOR_DELIVERY_ERROR,
  payload,
});

export const getQuotesForOrderStorageBuy = (payload) => ({
  type: GET_QUOTES_FOR_BUY_STORAGE,
  payload,
});

export const getQuotesForOrderStorageBuySuccess = (payload) => ({
  type: GET_QUOTES_FOR_ORDER_STORAGE_SUCCESS,
  payload,
});

export const getQuotesFoOrderStorageBuyError = (payload) => ({
  type: GET_QUOTES_FOR_ORDER_STORAGE_ERROR,
  payload,
});

export const emptyQuotesOrderStorageBuy = () => ({
  type: GET_QUOTES_STORAGE_EMPTY,
});

export const executeQuotesForOrderStorageBuy = (payload) => ({
  type: EXECUTE_QUOTES_FOR_BUY_STORAGE,
  payload,
});

export const executeQuotesForBuyStorageSuccess = (payload) => ({
  type: GET_QUOTES_FOR_ORDER_STORAGE_SUCCESS,
  payload,
});

export const executeQuotesFoBuyStorageError = (payload) => ({
  type: GET_QUOTES_FOR_ORDER_STORAGE_ERROR,
  payload,
});

export const emptyExecuteQuotesOrderStorageBuy = () => ({
  type: GET_QUOTES_STORAGE_EMPTY,
});

export const getQuotesForBuyDelivery = (payload) => ({
  type: GET_QUOTES_FOR_ORDER_DELIVERY_BUY,
  payload,
});

export const getQuotesForOrderDeliveryBuySuccess = (payload) => ({
  type: GET_QUOTES_FOR_ORDER_STORAGE_SUCCESS,
  payload,
});

export const getQuotesFoOrderDeliveryBuyError = (payload) => ({
  type: GET_QUOTES_FOR_ORDER_STORAGE_ERROR,
  payload,
});

export const emptyQuotesBuyOrderDelivery = () => ({
  type: GET_QUOTES_DELIVERY_EMPTY,
});

export const getQuotesForOrderSell = (payload) => ({
  type: GET_QUOTES_FOR_SELL,
  payload,
});

export const getQuotesForSellSuccess = (payload) => ({
  type: GET_QUOTES_FOR_SELL_SUCCESS,
  payload,
});

export const getQuotesForSellError = (payload) => ({
  type: GET_QUOTES_FOR_SELL_ERROR,
  payload,
});

export const emptyQuotesSell = () => ({
  type: GET_QUOTES_SELL_EMPTY,
});

export const executeQuotesForOrderSell = (payload) => ({
  type: EXECUTE_QUOTES_FOR_SELL,
  payload,
});

export const executeQuotesForSellSuccess = (payload) => ({
  type: EXECUTE_QUOTES_FOR_SELL_SUCCESS,
  payload,
});

export const executeQuotesForSellError = (payload) => ({
  type: EXECUTE_QUOTES_FOR_SELL_ERROR,
  payload,
});

export const emptyExecuteQuotesSell = () => ({
  type: EXECUTE_QUOTES_SELL_EMPTY,
});
