import styled from 'styled-components';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';

const ModalTitle = styled.h2`
  font-size: ${pxToRem(20)};
  line-height: 1.4;
  margin-bottom: ${pxToRem(16)};
  font-weight: 600;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${pxToRem(marginBottom)}` : '16px'};
  margin-top: ${({ marginTop }) => (marginTop ? `${pxToRem(marginTop)}` : 0)};
  color: ${({ color }) => (color ? `${color}` : themeColors.colorTextPrimary)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(20)};
  }
`;

export default ModalTitle;
