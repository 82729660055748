import {
  PORTFOLIO_HOLDINGS_FETCH_ERROR,
  PORTFOLIO_HOLDINGS_FETCH_SUCCESS,
  MY_HOLDINGS_CODES_SUCCESS,
  MY_HOLDINGS_CODES_ERROR,
  RESET_MY_HOLDINGS_ACTION,
} from '../../actions/portfolio/portfolioActionConstants';
import createReducer from '../../utils/createReducer';

const initialState = {
  holdings: {
    data: [],
    errorMsg: '',
  },
  myHoldingsCodes: {
    data: {},
    errorMsg: '',
  },
};

export default createReducer(
  {
    [PORTFOLIO_HOLDINGS_FETCH_SUCCESS]: setHoldings,
    [PORTFOLIO_HOLDINGS_FETCH_ERROR]: setHoldingsError,
    [MY_HOLDINGS_CODES_SUCCESS]: setMyHoldingsCodes,
    [MY_HOLDINGS_CODES_ERROR]: setMyHoldingsCodesError,
    [RESET_MY_HOLDINGS_ACTION]: resetMyHoldingState,
  },
  initialState,
);

function setHoldings(state, action) {
  return {
    ...state,
    holdings: {
      ...state.holdings,
      data: action.payload,
    },
  };
}

function setHoldingsError(state, action) {
  return {
    ...state,
    holdings: {
      ...state.holdings,
      errorMsg: action.payload,
    },
  };
}

function setMyHoldingsCodes(state, action) {
  return {
    ...state,
    myHoldingsCodes: {
      ...state.myHoldingsCodes,
      data: action.payload,
    },
  };
}

function setMyHoldingsCodesError(state, action) {
  return {
    ...state,
    myHoldingsCodes: {
      ...state.myHoldingsCodes,
      errorMsg: action.payload,
    },
  };
}

function resetMyHoldingState() {
  return initialState;
}
