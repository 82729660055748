import { createSelector } from 'reselect';

const iraSettingsSelector = (state) => state.iraSettings;

export const getIraSettings = createSelector(
  iraSettingsSelector,
  (state) => state.settings,
);

export const getIraSettingsError = createSelector(
  getIraSettings,
  (state) => state.error,
);
