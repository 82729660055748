import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import styled, { css } from 'styled-components';
import Label from '../Notes/Label';
import Button from '../Button/Button';
import { formatMoneyNumeral } from '../../util/helpers/numeralHelpers';
import NumberField from './NumberField';
import { CashOrderOnly } from '../CashOrderOnly/CashOrderOnly';
import CurrencyField from './CurrencyField';
import { uFlexColumn } from '../../assets/styles/utility';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';

const getActiveTypeStyles = (activeType) => {
  if (activeType === 'Cash') {
    return `
      > :first-child {
          background-color: ${themeColors.colorPrimary};
          color: ${themeColors.colorWhite} !important;
      }
    `;
  }

  if (activeType === 'Quantity') {
    return `
      > :last-child {
          background-color: ${themeColors.colorPrimary};
          color: ${themeColors.colorWhite} !important;
      }
    `;
  }
};

export const BuyFieldsContainer = styled.div`
  ${uFlexColumn};
`;

export const BuyFieldsButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 0 ${pxToRem(24)};

  > button {
    border: 1px solid ${themeColors.colorPrimary};
    color: ${themeColors.colorPrimary};
    font-size: ${pxToRem(16)};
    line-height: 1.5;
  }

  > :first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }

  > :last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  ${({ activeType }) => getActiveTypeStyles(activeType)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin: 0 0 ${pxToRemMd(24)};
  }
`;

export const buyFieldsSelectedButton = css`
  background-color: ${themeColors.colorPrimary};
  color: ${themeColors.colorWhite} !important;
`;

const BuyFields = ({
  activeType,
  setActiveType,
  values,
  setFieldValue,
  selectedVault,
  unitPrice,
  setFieldTouched,
  setDeliveryFees,
  deliveryFees,
  setLimitation,
  displayError,
  setGetQuotesError,
}) => {
  const { t } = useTranslation();

  const { IsCashOrderOnly } = selectedVault;

  return (
    <BuyFieldsContainer>
      <Label fontSize={16} marginBottom={8} text="Select buy type:" />
      <BuyFieldsButtonsContainer activeType={activeType}>
        <Button
          onClick={() => {
            setActiveType('Cash');
          }}
          data-cy="button-cash"
        >
          {t('buyWizard.common.cash')}
        </Button>
        <Button
          onClick={() => setActiveType('Quantity')}
          data-cy="button-quantity"
        >
          {t('buyWizard.common.quantity')}
        </Button>
      </BuyFieldsButtonsContainer>
      <div>
        {activeType === 'Cash' && (
          <CurrencyField
            name="CashAmount"
            label={t('buyWizard.common.cashOrderLabel')}
            placeholder={formatMoneyNumeral(0)}
            onChange={(value) => {
              setFieldTouched('CashAmount');
              if (!isEmpty(deliveryFees)) {
                setDeliveryFees({});
              }
              setFieldValue('CashAmount', value);
              if (setLimitation) {
                setLimitation(value);
              }
              if (setGetQuotesError) {
                setGetQuotesError('');
              }
            }}
            onBlur={() => setFieldTouched('CashAmount', true)}
            autoFocus
            defaultValue={values.CashAmount}
          />
        )}
        {activeType === 'Quantity' && (
          <Field
            label={t('buyWizard.common.quantityOrderLabel')}
            placeholder={0}
            name="QuantityAmount"
            component={NumberField}
            centerText
            autoFocus
            disabled={IsCashOrderOnly}
            onChange={(event) => {
              setFieldTouched('QuantityAmount');
              if (!isEmpty(deliveryFees)) {
                setDeliveryFees({});
              }
              setFieldValue('QuantityAmount', event.target.value);
              if (setLimitation) {
                setLimitation(unitPrice * event.target.value);
              }
              if (setGetQuotesError) {
                setGetQuotesError('');
              }
            }}
            data-cy="input-quantity"
          />
        )}
        {displayError && displayError()}
        {activeType === 'Quantity' && IsCashOrderOnly && <CashOrderOnly />}
      </div>
    </BuyFieldsContainer>
  );
};

BuyFields.propTypes = {
  values: PropTypes.shape({
    CashAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    QuantityAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  activeType: PropTypes.string,
  setActiveType: PropTypes.func,
  setFieldValue: PropTypes.func,
  selectedVault: PropTypes.shape({
    IsCashOrderOnly: PropTypes.bool,
  }),
  setFieldTouched: PropTypes.func,
  setDeliveryFees: PropTypes.func,
  deliveryFees: PropTypes.shape({}),
  setLimitation: PropTypes.func,
  unitPrice: PropTypes.number,
  displayError: PropTypes.func,
  setGetQuotesError: PropTypes.func,
};

export default BuyFields;
