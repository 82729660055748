import { useSelector } from 'react-redux';
import { selectAccountRestrictionFlags } from '../../store/selectors/accountSelectors';
import { restrictionEnumTypes } from '../enum/api/restrictionTypes';
import { parseEnumType } from '../helpers/enumMappers';

const useRestrictionHook = () => {
  const restrictionFlags = useSelector(selectAccountRestrictionFlags);

  const restrictions = restrictionFlags.map((item) =>
    parseEnumType(restrictionEnumTypes, item),
  );

  return restrictions;
};

export default useRestrictionHook;
