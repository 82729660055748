import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from '../../Modal/Modal';
import ModalBody from '../../Modal/ModalBody';
import ModalButtons from '../../Modal/ModalButtons';
import ContainerWithCustomScrollbar from '../../ContainerWithCustomScrollbar/ContainerWithCustomScrollbar';

const TermsAndConditions = ({
  isOpen,
  handleClose,
  handleBack,
  handleNext,
  avoidClearingStorage,
  children,
  title,
  overModal,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      size="lg"
      title={title}
      isOpen={isOpen}
      close={handleClose}
      avoidClearingStorage={avoidClearingStorage}
      overModal={overModal}
    >
      <ModalBody>
        <ContainerWithCustomScrollbar>{children}</ContainerWithCustomScrollbar>
        <ModalButtons
          isHorizontal
          marginTop
          smallButtons
          secondaryButtonProps={{ onClick: handleBack }}
          primaryButtonProps={{
            label: t('common.agree'),
            onClick: handleNext,
          }}
        />
      </ModalBody>
    </Modal>
  );
};

TermsAndConditions.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleBack: PropTypes.func,
  selectedBankAccount: PropTypes.shape({
    BankAccountUid: PropTypes.string,
  }),
  productAllocation: PropTypes.shape({
    gold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    silver: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  selectedProduct: PropTypes.shape({
    gold: PropTypes.shape({
      SymbolCode: PropTypes.string,
    }),
    silver: PropTypes.shape({
      SymbolCode: PropTypes.string,
    }),
  }),
  handleNext: PropTypes.func,
  avoidClearingStorage: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
  overModal: PropTypes.bool,
};

export default TermsAndConditions;
