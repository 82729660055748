import * as Yup from 'yup';
import i18next from '../i18n';
import { passwordValidationRule } from './passwordValidationRule';

export const changePasswordValidationSchema = Yup.object().shape({
  oldPass: Yup.string().required(
    i18next.t('validationSchema.general.passwordRequired'),
  ),
  Password: passwordValidationRule,
});
