import { createSelector } from 'reselect';

const creditCardSelector = (state) => state.creditCard;

export const selectGetClientToken = createSelector(
  creditCardSelector,
  (state) => state.clientToken.data,
);

export const selectAddCreditCard = createSelector(
  creditCardSelector,
  (state) => state.newCreditCard.data,
);

export const selectAddCreditCardError = createSelector(
  creditCardSelector,
  (state) => state.newCreditCard.error,
);
export const selectCreditCards = createSelector(
  creditCardSelector,
  (state) => state.creditCards.data,
);

export const selectIsSuccessfulDelete = createSelector(
  creditCardSelector,
  (state) => state.isSuccessfulDelete,
);
