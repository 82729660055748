import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Modal from '../../Modal/Modal';
import SupportModal from '../SupportModal/SupportModal';
import {
  deleteWizardKey,
  setWizardContent,
} from '../../../util/helpers/wizardHelpers';
import { IS_SUPPORT_MODAL_OPEN } from '../../../constants/sessionStorage';
import { useSessionStorageState } from '../../../util/hooks/useSessionStorageState';
import ModalBody from '../../Modal/ModalBody';
import Paragraph from '../../Paragraph/Paragraph';
import ModalButtons from '../../Modal/ModalButtons';
import { pxToRem } from '../../../assets/styles/helper';
import themeColors from '../../../assets/styles/themeColors';
import { selectIsAddAddressesEnabled } from '../../../store/selectors/settingsSelectors';

const SupportModalWrap = styled(SupportModal)`
  background-color: transparent;
`;

const ValidateAddressesList = styled.ul`
  margin-top;${pxToRem(16)};
  list-style-type: circle;
  padding-left: ${pxToRem(50)};
`;
const ValidateAddressesItem = styled.li`
  color: ${themeColors.colorTextPrimary};
  margin-bottom: ${pxToRem(16)};
`;

const NoAddressModal = ({
  isOpen,
  handleClose,
  handleBack,
  className,
  overModal,
}) => {
  const { t } = useTranslation();
  const isAddAddressesEnabled = useSelector(selectIsAddAddressesEnabled);
  const [isSupportModalOpen, setSupportModalOpen] = useSessionStorageState(
    IS_SUPPORT_MODAL_OPEN,
    false,
  );

  const handleSupportModalClick = () => {
    setWizardContent(IS_SUPPORT_MODAL_OPEN, true);
    setSupportModalOpen(true);
  };

  const handleSupportModalBack = () => {
    deleteWizardKey(IS_SUPPORT_MODAL_OPEN);
    setSupportModalOpen(false);
  };

  return (
    <>
      {isSupportModalOpen ? null : (
        <Modal
          isOpen={isOpen}
          close={handleClose}
          title={t(
            'standardWithdrawalWizard.checkWithdrawal.dontSeeAllAddresses',
          )}
          size="sm"
          className={className}
          overModal={overModal}
        >
          <ModalBody>
            <Paragraph>
              {isAddAddressesEnabled
                ? t(
                    'standardWithdrawalWizard.checkWithdrawal.validateAddresses',
                  )
                : t(
                    'standardWithdrawalWizard.checkWithdrawal.validateAddressesDisabled',
                  )}
            </Paragraph>
            <Paragraph marginTop={16}>
              {t('standardWithdrawalWizard.checkWithdrawal.validateAddresses1')}
            </Paragraph>
            <ValidateAddressesList>
              <ValidateAddressesItem>
                {t(
                  'standardWithdrawalWizard.checkWithdrawal.validateAddresses2',
                )}
              </ValidateAddressesItem>
              <ValidateAddressesItem>
                {t(
                  'standardWithdrawalWizard.checkWithdrawal.validateAddresses3',
                )}
              </ValidateAddressesItem>
            </ValidateAddressesList>

            <ModalButtons
              isVertical
              marginTop
              secondaryButtonProps={{ onClick: handleBack }}
              primaryButtonProps={{
                onClick: handleSupportModalClick,
                label: t(
                  'standardWithdrawalWizard.checkWithdrawal.contactCustomerSupport',
                ),
              }}
            />
          </ModalBody>
        </Modal>
      )}

      <SupportModalWrap
        isOpen={isSupportModalOpen}
        close={handleClose}
        goBack={handleSupportModalBack}
        overModal={overModal}
      />
    </>
  );
};

NoAddressModal.propTypes = {
  isOpen: PropTypes.bool,
  handleBack: PropTypes.func,
  handleClose: PropTypes.func,
  className: PropTypes.string,
  overModal: PropTypes.bool,
};

export default NoAddressModal;
