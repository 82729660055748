import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';
import { RESET_SENT_PAGE } from '../../../constants/pages';
import CodeField from '../../../components/InputFields/CodeField';
import ResendCode from '../Components/ResendCode';
import { TWO_FA_TYPES } from '../../../constants/twoFaConstants';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import {
  AuthenticatorApplication,
  twoFaTypesEnum,
} from '../../../util/enum/api/twoFATypes';
import SectionLoader from '../../../components/Loader/SectionLoader';
import { selectIsLoadingByActionTypes } from '../../../store/selectors/loadingSelectors';
import {
  GET_TWO_FA_LOADING,
  TWO_FA_CODE_VIA_PHONE_LOADING,
} from '../../../store/actions/twoFA/twoFAActionConstants';
import { postPasswordForgottenRequest } from '../../../store/actions/user/userActions';
import { PASSWORD_FORGOTTEN_REQUEST_LOADING } from '../../../store/actions/user/userActionConstants';
import config from '../../../config';
import { selectIsReCaptchaEnabled } from '../../../store/selectors/settingsSelectors';
import { securityCodeValidationSchema } from '../../../validation/securityCodeValidationSchema';
import Paragraph from '../../../components/Paragraph/Paragraph';
import SingleColumnList from '../../../components/TwoColumnList/SingleColumnList';
import HavingTroubleButtonGroup from '../Components/HavingTroubleButtonGroup';
import AuthCard from '../../../components/Auth/AuthCard';
import Auth from '../../../components/Auth/Auth';

const FullWidthForm = styled(Form)`
  width: 100%;
`;

const TwoFAVerifyIdentityPage = ({
  username,
  twoFaApplication,
  getTwoFaCode,
  twoFaValue,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const reCaptchaRef = React.createRef();
  const isReCaptchaEnabled = useSelector(selectIsReCaptchaEnabled);

  const [error, setError] = useState(null);

  const isLoading = useSelector(
    selectIsLoadingByActionTypes([
      TWO_FA_CODE_VIA_PHONE_LOADING,
      PASSWORD_FORGOTTEN_REQUEST_LOADING,
      GET_TWO_FA_LOADING,
    ]),
  );
  const {
    AuthenticationMethodType,
    MaskedEmail,
    MaskedPhone,
    EmailUid,
    PhoneUid,
  } = twoFaApplication;

  const parsedAuthenticationMethodType = parseEnumType(
    twoFaTypesEnum,
    AuthenticationMethodType,
  );

  const onError = (error) => {
    setError(error);
  };

  const displayResendCode =
    parsedAuthenticationMethodType !== TWO_FA_TYPES.Authenticator;

  useEffect(() => {
    if (parsedAuthenticationMethodType === TWO_FA_TYPES.Email) {
      getTwoFaCode({
        username,
        phoneUid: PhoneUid,
        emailUid: EmailUid,
        phoneMessageType: twoFaValue?.phoneMessageType,
        onError,
      });
    }
  }, []); // eslint-disable-line

  const handleFormSubmit = async (values, { setFieldError }) => {
    if (isReCaptchaEnabled) {
      const token = await reCaptchaRef.current.executeAsync();
      reCaptchaRef.current.reset();

      dispatch(
        postPasswordForgottenRequest({
          data: {
            Username: username,
            SecurityCode: values.SecurityCode,
            RecaptchaToken: token,
          },
          handleSuccess: () => {
            history.push(RESET_SENT_PAGE);
          },
          setFieldError,
        }),
      );
    } else {
      dispatch(
        postPasswordForgottenRequest({
          data: {
            Username: username,
            SecurityCode: values.SecurityCode,
          },
          handleSuccess: () => {
            history.push(RESET_SENT_PAGE);
          },
          setFieldError,
        }),
      );
    }
  };

  const resendCode = () => {
    getTwoFaCode({
      username,
      phoneUid: PhoneUid,
      emailUid: EmailUid,
      phoneMessageType: twoFaValue?.phoneMessageType,
      onError,
    });
  };

  const renderContent = () => {
    switch (parsedAuthenticationMethodType) {
      case TWO_FA_TYPES.Authenticator:
        return {
          note: t('twoFa.loginVerify.note.authenticator'),
        };

      case TWO_FA_TYPES.Email:
        return {
          note: t('twoFa.loginVerify.note.email', {
            email: MaskedEmail,
          }),
        };

      case TWO_FA_TYPES.Phone:
        return {
          note: t('twoFa.loginVerify.note.phone', {
            phoneNumber: MaskedPhone,
          }),
        };

      default:
        return {};
    }
  };

  return (
    <SectionLoader isLoading={isLoading}>
      <Auth>
        <AuthCard title={t('twoFa.loginVerify.title')}>
          <Paragraph marginBottom={56}>
            {!isEmpty(renderContent()) && renderContent().note}
          </Paragraph>

          <Formik
            onSubmit={handleFormSubmit}
            validationSchema={securityCodeValidationSchema}
            initialValues={{ SecurityCode: '' }}
          >
            {({ errors, setFieldValue }) => (
              <FullWidthForm>
                <SingleColumnList marginBottom={24}>
                  <CodeField
                    fixedSize
                    name="SecurityCode"
                    errors={errors}
                    onCodeChange={(value) =>
                      setFieldValue('SecurityCode', value)
                    }
                    hideExpireCodeNote={
                      parseEnumType(
                        twoFaTypesEnum,
                        AuthenticationMethodType,
                      ) === AuthenticatorApplication
                    }
                    isEmail={MaskedEmail}
                  />
                </SingleColumnList>
                <SingleColumnList>
                  {error && (
                    <Paragraph isError marginTop={8}>
                      <strong>{error}</strong>
                    </Paragraph>
                  )}
                </SingleColumnList>

                <SingleColumnList alignCenter marginBottom={56}>
                  {displayResendCode && <ResendCode onClick={resendCode} />}
                </SingleColumnList>
                <HavingTroubleButtonGroup isForgotPasswordFlow />
              </FullWidthForm>
            )}
          </Formik>
        </AuthCard>
      </Auth>
      {isReCaptchaEnabled && (
        <ReCAPTCHA
          ref={reCaptchaRef}
          sitekey={config.recaptcha.siteKey}
          size="invisible"
        />
      )}
    </SectionLoader>
  );
};

TwoFAVerifyIdentityPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
  twoFaApplication: PropTypes.shape({
    AuthenticationMethodType: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    EmailUid: PropTypes.string,
    PhoneUid: PropTypes.string,
    MaskedPhone: PropTypes.string,
    MaskedEmail: PropTypes.string,
  }),
  username: PropTypes.string,
  getTwoFaCode: PropTypes.func,
  twoFaValue: PropTypes.shape({
    phoneMessageType: PropTypes.string,
  }),
};
export default TwoFAVerifyIdentityPage;
