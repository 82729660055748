import React from 'react';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { uuid } from 'uuidv4';
import { useDispatch, useSelector } from 'react-redux';
import ReviewBar from '../../../ReviewBar/ReviewBar';
import SectionLoader from '../../../Loader/SectionLoader';
import ModalBody from '../../../Modal/ModalBody';
import ModalTitle from '../../../Modal/ModalTitle';
import InformationList from '../../../InformationList/InformationList';
import InformationListItem from '../../../InformationList/InformationListItem';
import ModalButtons from '../../../Modal/ModalButtons';
import {
  clearBaByRoutingNum,
  fetchBankAccountsByType,
  submitNewAchAccount,
} from '../../../../store/actions/bankAccount/bankAccountActions';
import { ACC_PURPOSE_ACH } from '../../../../util/enum/api/bankAccountTypes';
import { getCurrentAccount } from '../../../../store/selectors/accountSelectors';
import { selectIsLoadingByActionTypes } from '../../../../store/selectors/loadingSelectors';
import {
  SUBMIT_NEW_ACH_LOADING,
  BANK_ACCOUNTS_BY_TYPE_FETCH,
} from '../../../../store/actions/bankAccount/bankAccountActionConstants';
import useGtmHook from '../../../../util/hooks/useGtmHook';
import { BANK_ACCOUNT_ADDED } from '../../../../constants/gtmEvents';

const AchAccountReview = ({
  wizardData,
  setWizardData,
  handleBack,
  handleNext,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { AccountUid } = useSelector(getCurrentAccount);
  const isLoading = useSelector(
    selectIsLoadingByActionTypes([
      SUBMIT_NEW_ACH_LOADING,
      BANK_ACCOUNTS_BY_TYPE_FETCH,
    ]),
  );

  const { bankAccountGtmEvent } = useGtmHook();

  if (isEmpty(wizardData?.achManualFormValues)) {
    return null;
  }

  const { achManualFormValues } = wizardData;

  const reviewData = [
    {
      label: t('achDeposit.accountType'),
      value: achManualFormValues.accountType.label,
    },
    {
      label: t('achDeposit.routingNumber'),
      value: achManualFormValues.routingNumber,
    },
    {
      label: t('achDeposit.accountNumber'),
      value: achManualFormValues.accountNumber,
    },
    {
      label: t('achDeposit.nameOnAccount'),
      value: achManualFormValues.nameOnAccount,
    },
  ];

  const handleGtmEvent = () => {
    bankAccountGtmEvent(
      BANK_ACCOUNT_ADDED,
      ACC_PURPOSE_ACH,
      wizardData?.achManualFormValues.accountType.value,
      wizardData?.bankAccountDetailsByRoutingNumber.Address.CountryIso3Code,
    );
  };

  const handleApiResponse = (errorMessage, isABADisabled) => {
    console.log('isABADisabled', isABADisabled);
    setWizardData({ errorMessage });
    dispatch(clearBaByRoutingNum());
    dispatch(
      fetchBankAccountsByType({
        accountUid: AccountUid,
        type: 'achdeposit',
      }),
    );
    handleNext();
  };

  const handleNewAchOnConfirm = () => {
    if (
      wizardData?.bankAccountDetailsByRoutingNumber &&
      wizardData?.achManualFormValues
    ) {
      const {
        bankAccountDetailsByRoutingNumber,
        achManualFormValues,
      } = wizardData;

      const requestBody = {
        BankAccountUid: uuid(),
        DomesticBank: {
          Name: bankAccountDetailsByRoutingNumber.Name,
          Address: {
            AddressUid: bankAccountDetailsByRoutingNumber.Address.AddressUid,
            AddressLine: bankAccountDetailsByRoutingNumber.Address.AddressLine,
            AddressLine2:
              bankAccountDetailsByRoutingNumber.Address.AddressLine2,
            City: bankAccountDetailsByRoutingNumber.Address.City,
            PostalCode: bankAccountDetailsByRoutingNumber.Address.PostalCode,
            StateRegion: bankAccountDetailsByRoutingNumber.Address.StateRegion,
            CountryIso3Code:
              bankAccountDetailsByRoutingNumber.Address.CountryIso3Code,
          },
          Phone: bankAccountDetailsByRoutingNumber.Phone,
          ABARoutingNumber: bankAccountDetailsByRoutingNumber.ABARoutingNumber,
          ACHRoutingNumber: bankAccountDetailsByRoutingNumber.ACHRoutingNumber,
          WireRoutingNumber:
            bankAccountDetailsByRoutingNumber.WireRoutingNumber,
        },
        NameOnAccount: achManualFormValues.nameOnAccount,
        NameOnAccount2: '',
        AccountNumber: achManualFormValues.accountNumber,
        BankAccountType: achManualFormValues.accountType.value,
        PurposeType: ACC_PURPOSE_ACH,
      };

      dispatch(
        submitNewAchAccount({
          data: requestBody,
          accountUid: AccountUid,
          handleApiResponse,
          onSuccess: handleGtmEvent,
        }),
      );
    }
  };

  return (
    <SectionLoader isLoading={isLoading}>
      <ReviewBar title={t('bankAccounts.pleaseReviewBankDetails')} />
      <ModalBody>
        <ModalTitle>{t('achDeposit.newAccountForAchDeposit')}</ModalTitle>
        <InformationList withBackground noMargin>
          {reviewData.map(({ label, value }) => (
            <InformationListItem
              key={uuid()}
              label={label}
              value={value}
              fullWidth
            />
          ))}
        </InformationList>

        <ModalButtons
          isHorizontal
          marginTop
          secondaryButtonProps={{ onClick: handleBack }}
          primaryButtonProps={{
            label: t('common.confirm'),
            onClick: handleNewAchOnConfirm,
          }}
        />
      </ModalBody>
    </SectionLoader>
  );
};

AchAccountReview.propTypes = {
  newAchData: PropTypes.shape({
    accountType: PropTypes.shape({ label: PropTypes.string }),
    routingNumber: PropTypes.number,
    accountNumber: PropTypes.string,
    nameOnAccount: PropTypes.string,
  }),
  handleBack: PropTypes.func,
  wizardData: PropTypes.func,
  setWizardData: PropTypes.func,
  handleNext: PropTypes.func,
};

export default AchAccountReview;
