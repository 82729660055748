import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';
import { selectCreditCards } from '../../store/selectors/creditCardSelectors';
import { fetchCreditCards } from '../../store/actions/creditCard/creditCardActions';
import { CREDIT_CARD_LOADING } from '../../store/actions/creditCard/creditCardActionConstants';
import { selectIsLoadingByActionType } from '../../store/selectors/loadingSelectors';
import { getCurrentAccountUid } from '../../store/selectors/accountSelectors';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import Section from '../../components/Section/Section';
import BlockSectionLoader from '../../components/Loader/BlockSectionLoader';
import RemoveCreditCardModal from '../../components/Modals/CreditCard/RemoveCreditCardModal';
import AddCreditCardWizard from '../../components/Modals/CreditCard/AddCreditCardWizard';
import Paragraph from '../../components/Paragraph/Paragraph';
import CreditCard from '../../components/CreditCard.js/CreditCard';
import { BankAccountCardEmpty } from '../../components/BankAccountsCard/BankAccountCardTable';
import { BankAccountsPageGrid } from './BankAccountsPage';
import { uFlexColumn } from '../../assets/styles/utility';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';

import {
  BankAccountAddElement,
  BankAccountAddIcon,
} from '../../components/BankAccountsCard/BankAccountAdd';
import { ReactComponent as Plus } from '../../assets/images/svg/plus.svg';
import { useBrokerName } from '../../util/hooks/userBrokerName';
import { removeFromSessionStorage } from '../../util/helpers/sessionStorageHelper';
import {
  ADD_CREDIT_CARD_WIZARD,
  IS_CARD_USED_FOR_AUTO_PAY,
} from '../../constants/sessionStorage';
import NagDashboardBanner from '../../components/NagDashboard/NagDashboardBanner';
import ChangeAutoPaySettingsModal from '../../components/Modals/ChangeAutoPaySettings/ChangeAutoPaySettingsModal';
import useGtmHook from '../../util/hooks/useGtmHook';
import { ACCOUNT_DATA_LOADED } from '../../constants/gtmEvents';

const CreditCardsPageContainer = styled.div`
  margin: ${pxToRem(24)} 0;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: 0 ${pxToRemMd(16)};
    margin: ${pxToRemMd(24)} 0;
  }
`;

const CreditCardsWrapper = styled.ul`
  ${uFlexColumn};
  box-shadow: ${variables.shadow.boxShadow};
  min-height: ${pxToRem(160)};
`;

const CreditCardsContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const CreditCardsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const creditCards = useSelector(selectCreditCards);
  const accountUid = useSelector(getCurrentAccountUid);
  const brokerName = useBrokerName();

  const [removeCreditCardModalOpen, setRemoveCreditCardModalOpen] = useState(
    false,
  );
  const [removeCreditCardId, setRemoveCreditCardId] = useState(null);
  const [isAddCreditCardModalOpen, setIsAddCreditCardModalOpen] = useState(
    false,
  );
  const [isAutoPaySettingsModalOpen, setIsAutoPaySettingsModalOpen] = useState(
    false,
  );
  const isLoadingCreditCards = useSelector(
    selectIsLoadingByActionType(CREDIT_CARD_LOADING),
  );

  const { gtmScreenView, appGtmEvent } = useGtmHook();

  useEffect(() => {
    gtmScreenView({
      path: window.location.pathname,
      title: 'Credit Cards',
    });
    appGtmEvent(ACCOUNT_DATA_LOADED);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (accountUid) {
      dispatch(fetchCreditCards({ accountUid }));
    }
  }, [accountUid, dispatch]);

  const handleRemoveCreditCard = (creditCardUid) => {
    setRemoveCreditCardModalOpen(true);
    setRemoveCreditCardId(creditCardUid);
  };

  const handleRemoveModalClose = () => {
    setRemoveCreditCardModalOpen(false);
    setRemoveCreditCardId(null);
    removeFromSessionStorage(IS_CARD_USED_FOR_AUTO_PAY);
  };

  const handleCloseAutoPaySettings = () => {
    setIsAutoPaySettingsModalOpen(false);
    removeFromSessionStorage(IS_CARD_USED_FOR_AUTO_PAY);
  };

  return (
    <>
      <NagDashboardBanner />
      <NavigationBar
        mainPage={t('header.navDropdownAccount')}
        subPage={t('header.linkCreditCards')}
      />
      <Section>
        <CreditCardsPageContainer>
          <Paragraph marginBottom={32}>
            {t('creditCards.creditCardIntro', { brokerName })}
          </Paragraph>
          <BankAccountsPageGrid>
            <CreditCardsContent>
              {isLoadingCreditCards ? (
                <BlockSectionLoader isLoading={isLoadingCreditCards} />
              ) : (
                <CreditCardsWrapper>
                  {!isEmpty(creditCards) ? (
                    <>
                      {creditCards?.map((creditCardItem) => (
                        <CreditCard
                          creditCard={creditCardItem}
                          key={creditCardItem.CreditCardUid}
                          handleRemoveCreditCard={handleRemoveCreditCard}
                        />
                      ))}
                    </>
                  ) : (
                    <BankAccountCardEmpty>
                      <p>{t('creditCards.emptyList')}</p>
                    </BankAccountCardEmpty>
                  )}
                </CreditCardsWrapper>
              )}

              <BankAccountAddElement
                onClick={() => setIsAddCreditCardModalOpen(true)}
              >
                <BankAccountAddIcon>
                  <Plus />
                </BankAccountAddIcon>
                {t('creditCards.addNewCreditCard')}
              </BankAccountAddElement>
            </CreditCardsContent>
          </BankAccountsPageGrid>
        </CreditCardsPageContainer>
      </Section>
      <AddCreditCardWizard
        isOpen={isAddCreditCardModalOpen}
        onClose={() => {
          removeFromSessionStorage(ADD_CREDIT_CARD_WIZARD);
          setIsAddCreditCardModalOpen(false);
        }}
      />
      <RemoveCreditCardModal
        isOpen={removeCreditCardModalOpen}
        onClose={handleRemoveModalClose}
        isDefaultForStorage={
          creditCards?.find(
            (card) => card?.CreditCardUid === removeCreditCardId,
          )?.IsDefaultForStorage
        }
        setIsAutoPaySettingsModalOpen={setIsAutoPaySettingsModalOpen}
        removeCreditCardModalOpen={removeCreditCardModalOpen}
        setRemoveCreditCardModalOpen={setRemoveCreditCardModalOpen}
        creditCard={creditCards.find(
          (card) => card.CreditCardUid === removeCreditCardId,
        )}
      />
      <ChangeAutoPaySettingsModal
        isOpen={isAutoPaySettingsModalOpen}
        handleClose={handleCloseAutoPaySettings}
        removeCreditCardModalOpen={removeCreditCardModalOpen}
        setRemoveCreditCardModalOpen={setRemoveCreditCardModalOpen}
      />
    </>
  );
};

export default CreditCardsPage;
