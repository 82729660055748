import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ReCAPTCHA from 'react-google-recaptcha';
import * as jwt from 'jsonwebtoken';
import { registrationValidationSchema } from '../../validation/registrationValidationSchema';
import SectionLoader from '../Loader/SectionLoader';
import FormContainer from '../FormContainer/FormContainer';
import TextField from '../InputFields/TextField';
import SelectField from '../InputFields/SelectField';
import { SUFFIX_TYPES_ARRAY } from '../../constants/registrationConstants';
import PasswordField from '../InputFields/PasswordField';
import { registerUser } from '../../store/actions/login/loginActions';
import { useBrokerCode } from '../../util/hooks/useBrokerCode';
import {
  IDENTITY_PAGE,
  TWO_FA_VERIFY_IDENTITY_PHONE,
  TWO_FA_VERIFY_IDENTITY,
  VERIFY_CHOOSE_METHOD_PAGE,
  EMAIL_LOGIN_PAGE,
} from '../../constants/pages';
import { selectIsLoadingByActionType } from '../../store/selectors/loadingSelectors';
import { REGISTER_USER_LOADING } from '../../store/actions/login/loginActionConstants';
import themeColors from '../../assets/styles/themeColors';
import {
  selectIsReCaptchaEnabled,
  selectSettings,
} from '../../store/selectors/settingsSelectors';
import config from '../../config';
import {
  selectTokens,
  selectTwoFactorAuthenticationResponse,
} from '../../store/selectors/loginSelectors';
import { landingPageHelper } from '../../util/helpers/landingPageHelper';
import useTrackingDetails from '../../util/hooks/useTrackingDetails';
import { USER_LOGIN } from '../../constants/gtmEvents';
import useGtmHook from '../../util/hooks/useGtmHook';

const RegistrationWrapper = styled.div`
  input {
    &:focus {
      border-color: ${themeColors.colorRegistration};
    }
  }

  label {
    color: ${themeColors.colorTextPrimary};
  }

  form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;
    max-width: 450px;
    width: 100%;
  }
`;

const RegisterButton = styled.button`
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 12px 24px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${themeColors.colorRegistration};
  width: auto;
  justify-content: center;
  font-size: 1rem;
  line-height: 1.6;
  color: ${themeColors.colorWhite};
  font-weight: 400;
  max-width: max-content;
`;
const Error = styled.div`
  dispay: flex;
  color: ${themeColors.colorRed};
`;

export const RegistrationLoginLink = styled.a`
  color: ${themeColors.colorLinkRegistration};
  text-decoration: none;
  font-weight: 600;
`;
const LoginLink = styled.div`
  dispay: flex;
  margin-left: ${(props) => (props.fullWidth ? '0' : '0.5rem')};

  @media only screen and (max-width: 992px) {
    margin-left: 0;
    text-align: center;
  }
`;

const BaseLayoutButtonContent = styled.div`
  display: ${(props) => (props.fullWidth ? 'grid' : 'flex')};
  grid-gap: ${(props) => (props.fullWidth ? '1rem' : 0)};
  align-items: center;

  @media only screen and (max-width: 992px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
`;
const Registration = () => {
  const { t } = useTranslation();
  const tokens = useSelector(selectTokens);
  const dispatch = useDispatch();
  const brokerCode = useBrokerCode();
  const history = useHistory();
  const reCaptchaRef = React.createRef();
  const brokerSettings = useSelector(selectSettings);
  const { IsBroker2FAActive } = brokerSettings;
  const isReCaptchaEnabled = useSelector(selectIsReCaptchaEnabled);
  const [errorCode, setErrorCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const twoFaAuthenticationResponse = useSelector(
    selectTwoFactorAuthenticationResponse,
  );
  const { TrackingDetails } = useTrackingDetails();
  const { appGtmEvent } = useGtmHook();

  const initialValues = {
    FirstName: '',
    MiddleName: '',
    LastName: '',
    Email: '',
    Password: '',
  };
  const isLoading = useSelector(
    selectIsLoadingByActionType(REGISTER_USER_LOADING),
  );

  const handleSubmit = async (value, { setFieldError }) => {
    let token = null;
    if (isReCaptchaEnabled) {
      token = await reCaptchaRef.current.executeAsync();
      reCaptchaRef.current.reset();
    }
    const handleGtm = () => {
      // gtmEvent(APPLICATION_STARTED);
      appGtmEvent(`app.${USER_LOGIN}`);
    };
    const newData = {
      ...value,
      SuffixType: value.SuffixType?.label,
      BrokerCode: brokerCode,
      RecaptchaToken: token,
    };
    dispatch(
      registerUser({
        BasicAccountRequest: { ...newData },
        TrackingDetails,
        setFieldError,
        setErrorCode,
        setErrorMessage,
        handleGtm,
      }),
    );
  };

  useEffect(() => {
    function redirectClient() {
      if (!tokens.RefreshToken && !tokens.JwtToken) {
        return;
      }

      if (IsBroker2FAActive) {
        const authenticationMethodType = jwt.decode(tokens.JwtToken)
          ?.AuthenticationMethodType;

        if (tokens.JwtToken) {
          if (authenticationMethodType) {
            return history.push({
              pathname: landingPageHelper(),
              state: {
                from: history.location.pathname,
              },
            });
          }
          const { isRequired2FA } = jwt.decode(tokens.JwtToken);
          if (isRequired2FA === 'True') {
            return history.push({
              pathname: VERIFY_CHOOSE_METHOD_PAGE,
              state: {
                from: history.location.pathname,
              },
            });
          }
        }
      }

      if (tokens.RefreshToken) {
        if (IsBroker2FAActive && twoFaAuthenticationResponse) {
          if (twoFaAuthenticationResponse.AuthenticationMethodType === 2) {
            return history.replace(TWO_FA_VERIFY_IDENTITY_PHONE);
          }
          return history.replace(TWO_FA_VERIFY_IDENTITY);
        }
        return history.replace(IDENTITY_PAGE);
      }

      return history.push({
        pathname: landingPageHelper(),
        state: {
          from: history.location.pathname,
        },
      });
    }
    if (tokens) {
      redirectClient();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens]);

  const displayError = () => {
    if (errorCode === '') {
      return;
    }
    if (errorCode === 'UsernameAlreadyExists') {
      return (
        <Error>
          {errorMessage}
          <RegistrationLoginLink
            onClick={() => {
              history.push(EMAIL_LOGIN_PAGE);
            }}
          >
            {t('registration.signIn')}
          </RegistrationLoginLink>
          {t('registration.instead')}
        </Error>
      );
    }
    return <Error>{errorMessage}</Error>;
  };

  return (
    <RegistrationWrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={registrationValidationSchema}
        onSubmit={handleSubmit}
      >
        {/* {({ values, errors, setFieldValue, setFieldError }) => ( */}
        <SectionLoader isLoading={isLoading}>
          <Form>
            <FormContainer hasError={errorCode !== ''}>
              <Field
                component={TextField}
                name="Email"
                label={t('quickRegistration.labels.email')}
                autoComplete="email"
                data-cy="input-email"
              />
              {displayError()}
            </FormContainer>
            <FormContainer>
              <Field
                name="Password"
                label={t('quickRegistration.labels.password')}
                component={PasswordField}
                autoComplete="new-password"
                data-cy="input-password"
              />
            </FormContainer>
            <FormContainer>
              <Field
                component={TextField}
                name="FirstName"
                label={t('quickRegistration.labels.firstName')}
                autoComplete="given-name"
                data-cy="input-first-name"
              />
              <Field
                component={TextField}
                name="MiddleName"
                label={t('quickRegistration.labels.middleName')}
                autoComplete="additional-name"
              />
              <Field
                component={TextField}
                name="LastName"
                label={t('quickRegistration.labels.lastName')}
                autoComplete="family-name"
                data-cy="input-last-name"
              />
              <SelectField
                label={t('quickRegistration.labels.suffix')}
                name="SuffixType"
                options={SUFFIX_TYPES_ARRAY}
              />
            </FormContainer>
            <BaseLayoutButtonContent>
              <RegisterButton type="submit" data-cy="button-continue">
                {t('common.continue')}
              </RegisterButton>
              <LoginLink>
                {t('registration.loginDescription')}
                <RegistrationLoginLink
                  onClick={() => {
                    history.push(EMAIL_LOGIN_PAGE);
                  }}
                >
                  {t('registration.signIn')}
                </RegistrationLoginLink>
                {t('registration.instead')}
              </LoginLink>
            </BaseLayoutButtonContent>
          </Form>
          {isReCaptchaEnabled && (
            <ReCAPTCHA
              ref={reCaptchaRef}
              sitekey={config.recaptcha.siteKey}
              size="invisible"
            />
          )}
        </SectionLoader>
      </Formik>
    </RegistrationWrapper>
  );
};

Registration.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
};

export default Registration;
