import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import { TWO_FA_FORGOT_PASSWORD_IDENTITY_PAGE } from '../../../constants/pages';
import TwoFaPhoneSelectRecieveType from '../Components/TwoFaPhoneSelectRecieveType';
import { fetchTwoFaCodeViaPhone } from '../../../store/actions/twoFA/twoFAActions';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import { twoFaMessageType } from '../../../util/enum/api/twoFATypes';
import { selectIsLoadingByActionTypes } from '../../../store/selectors/loadingSelectors';
import {
  GET_TWO_FA_LOADING,
  TWO_FA_CODE_VIA_PHONE_LOADING,
} from '../../../store/actions/twoFA/twoFAActionConstants';
import { storeInSessionStorage } from '../../../util/helpers/sessionStorageHelper';
import { TWO_FA } from '../../../constants/localStorage';
import TypeList from '../../../components/TypeList/TypeList';
import SingleColumnList from '../../../components/TwoColumnList/SingleColumnList';
import HavingTroubleButtonGroup from '../Components/HavingTroubleButtonGroup';
import Paragraph from '../../../components/Paragraph/Paragraph';
import AuthCard from '../../../components/Auth/AuthCard';
import Auth from '../../../components/Auth/Auth';

const TwoFAVerifyIdentityPhonePage = ({
  setValues,
  username,
  twoFaApplication,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { PhoneUid, MaskedPhone } = twoFaApplication;
  const isLoading = useSelector(
    selectIsLoadingByActionTypes([
      TWO_FA_CODE_VIA_PHONE_LOADING,
      GET_TWO_FA_LOADING,
    ]),
  );

  const [error, setError] = useState(null);

  const onError = (error) => {
    setError(error);
  };

  const onFormSubmit = (value) => {
    const { Type } = value;
    const encodedUsername = encodeURIComponent(username);

    const onSuccess = () => {
      setValues({
        phoneMessageType: Type,
      });
      storeInSessionStorage(TWO_FA, { phoneMessageType: Type });
      history.push(
        `${TWO_FA_FORGOT_PASSWORD_IDENTITY_PAGE}?username=${encodedUsername}`,
      );
    };

    dispatch(
      fetchTwoFaCodeViaPhone({
        username,
        phoneUid: PhoneUid,
        phoneMessageType: value.Type,
        onSuccess,
        onError,
      }),
    );
  };

  return (
    <Auth>
      <AuthCard title={t('twoFa.loginVerify.title')} isLoading={isLoading}>
        {MaskedPhone && (
          <Paragraph marginBottom={56}>
            {t('twoFa.loginVerify.phone.note', {
              phoneNumber: MaskedPhone,
            })}
          </Paragraph>
        )}

        <Formik
          onSubmit={onFormSubmit}
          initialValues={{ Type: parseEnumType(twoFaMessageType, 1) }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <SingleColumnList marginBottom={16} alignCenter>
                <TypeList maxWidth={342}>
                  <TwoFaPhoneSelectRecieveType
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                </TypeList>
              </SingleColumnList>
              <SingleColumnList marginBottom={56}>
                {error && (
                  <Paragraph isError marginTop={8}>
                    <strong>{error}</strong>
                  </Paragraph>
                )}
              </SingleColumnList>

              <HavingTroubleButtonGroup
                isForgotPasswordFlow
                buttonLabel={t('twoFa.loginVerify.sendCode')}
              />
            </Form>
          )}
        </Formik>
      </AuthCard>
    </Auth>
  );
};

TwoFAVerifyIdentityPhonePage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
  setValues: PropTypes.func,
  twoFaApplication: PropTypes.shape({
    PhoneUid: PropTypes.string,
    MaskedPhone: PropTypes.string,
  }),
  username: PropTypes.string,
};
export default TwoFAVerifyIdentityPhonePage;
