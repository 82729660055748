import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ModalTitle from '../../Modal/ModalTitle';
import Label from '../../Notes/Label';

const NewWireWithdrawalHeading = ({
  bankDetailsParagraph,
  bankMailingParagraph,
  accountDetailsParagraph,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ModalTitle marginBottom={4}>
        {t('bankAccounts.newWireHeadingLabel')}
      </ModalTitle>
      {bankDetailsParagraph && (
        <Label
          text={t('bankAccounts.newWireBankDetailsLabel')}
          marginBottom={32}
        />
      )}
      {bankMailingParagraph && (
        <Label
          text={t('bankAccounts.newWireBankMailingDetailsLabel')}
          marginBottom={32}
        />
      )}
      {accountDetailsParagraph && (
        <Label
          text={t('bankAccounts.newWireAccountDetailsLabel')}
          marginBottom={32}
        />
      )}
    </>
  );
};

NewWireWithdrawalHeading.propTypes = {
  bankDetailsParagraph: PropTypes.bool,
  bankMailingParagraph: PropTypes.bool,
  accountDetailsParagraph: PropTypes.bool,
};

export default NewWireWithdrawalHeading;
