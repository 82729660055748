import * as Yup from 'yup';
import i18next from '../i18n';

export const creditCardPaymentValidation = Yup.object().shape({
  creditCard: Yup.string().required(
    i18next.t(
      'validationSchema.creditCardPaymentValidation.creditCardRequired',
    ),
  ),
});
