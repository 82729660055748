import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import * as jwt from 'jsonwebtoken';
import get from 'lodash.get';
import {
  VERIFY_CHOOSE_METHOD_PAGE,
  TWO_FA_SUCCESSFUL_SETUP,
  TWO_FA_CHOOSE_PHONE_METHOD,
  INVITE_USER_TWO_FA_CHOOSE_PHONE_METHOD,
  INVITE_USER_TWO_FA_VERIFY_CHOOSE_METHOD,
  INVITE_USER_WELCOME_PAGE,
} from '../../../constants/pages';
import CodeField from '../../../components/InputFields/CodeField';
import ResendCode from '../Components/ResendCode';
import { TWO_FA_TYPES } from '../../../constants/twoFaConstants';
import { postTwoFa } from '../../../store/actions/twoFA/twoFAActions';
import { selectIsLoadingByActionTypes } from '../../../store/selectors/loadingSelectors';
import {
  SAVE_TWO_FA_LOADING,
  TWO_FA_CODE_LOADING,
} from '../../../store/actions/twoFA/twoFAActionConstants';
import SectionLoader from '../../../components/Loader/SectionLoader';
import {
  selectTwoFaMaskedEmail,
  selectTwoFaMaskedPhoneNumber,
} from '../../../store/selectors/twoFASelector';
import { getTwoFaLoginContent } from '../../../util/helpers/twoFALoginSetupHelper';
import {
  AuthenticatorApplication,
  twoFaTypesEnum,
} from '../../../util/enum/api/twoFATypes';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import { securityCodeValidationSchema } from '../../../validation/securityCodeValidationSchema';
import Paragraph from '../../../components/Paragraph/Paragraph';
import SingleColumnList from '../../../components/TwoColumnList/SingleColumnList';
import AuthCard from '../../../components/Auth/AuthCard';
import Auth from '../../../components/Auth/Auth';
import AuthButtons from '../../../components/Auth/AuthButtons';
import { getUsernames } from '../../../util/helpers/userHelpers';
import { accountSecurityQuestionTypesObject } from '../../../util/enum/api/accountTypes';
import { inviteUser } from '../../../store/actions/user/userActions';
import { setRegisterContent } from '../../../util/helpers/registerHelpers';
import {
  ERROR,
  INVITE_USER_DATA,
  USER_INVITE_STATUS_MODAL,
} from '../../../constants/sessionStorage';
import { retrieveFromSessionStorage } from '../../../util/helpers/sessionStorageHelper';
import { JWT_TOKEN } from '../../../constants/localStorage';
import { useSessionStorageState } from '../../../util/hooks/useSessionStorageState';
import { closeWizardContent } from '../../../util/helpers/wizardHelpers';
import StatusModal from '../../../components/Modals/StatusModal';

const initialValues = {
  SecurityCode: '',
};
const TwoFAVerificationCodePage = ({
  goStepBack,
  goStepForward,
  setTwoFaValues,
  twoFaValue,
  user,
  getTwoFaCode,
  isInviteUserFlow,
  formValues,
  userInviteId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const maskedEmail =
    useSelector(selectTwoFaMaskedEmail) || getTwoFaLoginContent()?.MaskedEmail;
  const maskedPhoneNumber =
    useSelector(selectTwoFaMaskedPhoneNumber) ||
    getTwoFaLoginContent()?.MaskedPhoneNumber;
  const isLoading = useSelector(
    selectIsLoadingByActionTypes([TWO_FA_CODE_LOADING, SAVE_TWO_FA_LOADING]),
  );
  const { UserUid, Username } = user;
  const { AuthenticationMethodType, MessageType, Phone } = twoFaValue;
  const [errorInviteUser, setError] = useSessionStorageState(ERROR, '');
  const [statusModal, setStatusModal] = useSessionStorageState(
    USER_INVITE_STATUS_MODAL,
    false,
  );

  const [errorMessage, setErrorMessage] = useState(null);

  const onError = (errorMessage) => {
    setErrorMessage(errorMessage);
  };

  const renderContent = () => {
    switch (AuthenticationMethodType) {
      case TWO_FA_TYPES.Email:
        return {
          title: t('twoFa.loginSetup.codeVerification.email.title'),
          subtitle: t('twoFa.loginSetup.codeVerification.email.subtitle'),
          sentText: maskedEmail
            ? t('twoFa.loginSetup.codeVerification.email.sentText', {
                email: maskedEmail,
              })
            : '',
        };

      case TWO_FA_TYPES.Phone:
        return {
          title: t('twoFa.loginSetup.codeVerification.phone.title'),
          subtitle: t('twoFa.loginSetup.codeVerification.phone.subtitle'),
          sentText: maskedPhoneNumber
            ? t('twoFa.loginSetup.codeVerification.phone.sentText', {
                phoneNumber: maskedPhoneNumber,
              })
            : '',
        };

      default:
        break;
    }
  };

  const handleNext = () => {
    goStepForward(
      isInviteUserFlow ? INVITE_USER_WELCOME_PAGE : TWO_FA_SUCCESSFUL_SETUP,
    );
  };

  const handleBack = () => {
    if (AuthenticationMethodType === TWO_FA_TYPES.Phone) {
      goStepBack(
        isInviteUserFlow
          ? INVITE_USER_TWO_FA_CHOOSE_PHONE_METHOD
          : TWO_FA_CHOOSE_PHONE_METHOD,
      );
      return;
    }
    setTwoFaValues(null);
    goStepBack(
      isInviteUserFlow
        ? INVITE_USER_TWO_FA_VERIFY_CHOOSE_METHOD
        : VERIFY_CHOOSE_METHOD_PAGE,
    );
  };

  const emailVerifyObject = () => ({
    AuthenticationMethodType,
    Username,
  });

  const phoneVerifyObject = () => ({
    Phone,
    MessageType,
    AuthenticationMethodType,
    Username,
  });

  const existingLoginHandleNextClick = () => {
    const { values } = formValues;
    const JwtToken = retrieveFromSessionStorage(JWT_TOKEN);
    const { Username, Password } = values;
    const { SecurityQuestionType } = jwt.decode(JwtToken);
    const usernames = getUsernames();
    const LinkWithExistingLogin = usernames.length > 0;
    const inviteUserData = {
      Username: Username.value,
      Password,
      LinkWithExistingLogin,
      InvitedUserInfo: {
        EncryptedPersonEntity: userInviteId,
        SecurityQuestionType: get(
          accountSecurityQuestionTypesObject,
          SecurityQuestionType,
          null,
        ),
      },
    };

    dispatch(
      inviteUser({
        data: inviteUserData,
        meta: {
          handleNext: () => handleNext(INVITE_USER_WELCOME_PAGE),
          createSessionUser: () =>
            setRegisterContent(INVITE_USER_DATA, inviteUserData),
          // setIsLoading,
          setError,
          setStatusModal,
        },
      }),
    );
  };

  const formSubmit = (values, { setFieldError }) => {
    const { SecurityCode } = values;
    const isEmailAuthenticationMethodType =
      AuthenticationMethodType === TWO_FA_TYPES.Email;

    const emailVerifyData = () => ({
      SecurityCode,
      ...emailVerifyObject(),
    });

    const phoneVerifyData = () => ({
      SecurityCode,
      ...phoneVerifyObject(),
    });

    const requestData = isEmailAuthenticationMethodType
      ? emailVerifyData()
      : phoneVerifyData();

    dispatch(
      postTwoFa({
        requestData,
        handleNext,
        setFieldError,
        userUid: UserUid,
        isInviteUserFlow,
        existingLoginHandleNextClick,
      }),
    );
  };

  const resendCode = () => {
    const isEmailAuthenticationMethodType =
      AuthenticationMethodType === TWO_FA_TYPES.Email;

    const emailVerifyData = () => ({
      ...emailVerifyObject(),
    });

    const phoneVerifyData = () => ({
      ...phoneVerifyObject(),
    });

    const requestData = isEmailAuthenticationMethodType
      ? emailVerifyData()
      : phoneVerifyData();

    if (UserUid && !isEmpty(requestData)) {
      getTwoFaCode({
        userUid: UserUid,
        requestData,
        onError,
      });
    }
  };

  const content = renderContent();

  return (
    <SectionLoader isLoading={isLoading}>
      <Auth>
        <AuthCard title={content?.title}>
          <Paragraph marginBottom={40} isPrimaryColor bold>
            {content?.subtitle}
          </Paragraph>
          <Paragraph marginBottom={48}>{content?.sentText}</Paragraph>
          <Formik
            onSubmit={formSubmit}
            initialValues={initialValues}
            validationSchema={securityCodeValidationSchema}
          >
            {({ errors, values, setFieldValue, setFieldError }) => (
              <Form>
                <SingleColumnList marginBottom={72} alignCenter>
                  <CodeField
                    fixedSize
                    name="SecurityCode"
                    errors={errors}
                    onCodeChange={(value) =>
                      setFieldValue('SecurityCode', value)
                    }
                    hideExpireCodeNote={
                      parseEnumType(
                        twoFaTypesEnum,
                        AuthenticationMethodType,
                      ) === AuthenticatorApplication
                    }
                    isEmail={maskedEmail}
                  />
                  <SingleColumnList marginTop={16}>
                    {errorMessage && (
                      <Paragraph isError marginTop={8}>
                        <strong>{errorMessage}</strong>
                      </Paragraph>
                    )}
                  </SingleColumnList>
                  <SingleColumnList marginTop={24}>
                    <ResendCode
                      onClick={() => resendCode(values, setFieldError)}
                    />
                  </SingleColumnList>
                </SingleColumnList>
                <AuthButtons
                  isHorizontal
                  secondaryButtonProps={{ onClick: handleBack }}
                  primaryButtonProps={{
                    type: 'submit',
                  }}
                />
              </Form>
            )}
          </Formik>
        </AuthCard>
      </Auth>
      {errorInviteUser && statusModal && (
        <StatusModal
          isOpen={statusModal}
          hasError={errorInviteUser}
          close={() => {
            setStatusModal(false);
            setError('');
            closeWizardContent(ERROR);
            closeWizardContent(USER_INVITE_STATUS_MODAL);
          }}
          text={errorInviteUser}
          modalTitle={t('inviteUser.review.statusModal')}
          backButtonText={t('common.ok')}
          onButtonClick={() => {
            setStatusModal(false);
            setError('');
            closeWizardContent(ERROR);
            closeWizardContent(USER_INVITE_STATUS_MODAL);
          }}
        />
      )}
    </SectionLoader>
  );
};

TwoFAVerificationCodePage.propTypes = {
  goStepBack: PropTypes.func,
  goStepForward: PropTypes.func,
  setTwoFaValues: PropTypes.func,
  twoFaValue: PropTypes.shape({
    AuthenticationMethodType: PropTypes.string,
    MessageType: PropTypes.number,
    Phone: PropTypes.shape({}),
    IsFromSetup: PropTypes.bool,
  }),
  user: PropTypes.shape({
    UserUid: PropTypes.string,
    Username: PropTypes.string,
  }),
  formValues: PropTypes.shape({
    values: PropTypes.shape({
      ExistingUsername: PropTypes.string,
      LegalFirstName: PropTypes.string,
      LegalMiddleName: PropTypes.string,
      LegalLastName: PropTypes.string,
      Suffix: PropTypes.string,
      Username: PropTypes.string,
      Password: PropTypes.string,
      SecurityQuestion: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
        PropTypes.string,
      ]),
      Answer: PropTypes.string,
      SecurityCode: PropTypes.string,
      Remember: PropTypes.bool,
    }),
  }),
  getTwoFaCode: PropTypes.func,
  isInviteUserFlow: PropTypes.bool,
  userInviteId: PropTypes.string,
};
export default TwoFAVerificationCodePage;
