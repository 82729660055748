import { createSelector } from 'reselect';

const sellWizardSelector = (state) => state.order.sellWizard;

export const selectSellWizardProduct = createSelector(
  sellWizardSelector,
  (state) => state.product,
);

export const selectSellWizardError = createSelector(
  sellWizardSelector,
  (state) => state.errorMessage,
);
