import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AlertTriangle } from '../../assets/images/svg/alert-triangle.svg';
import {
  ACH_ACCOUNT_NAME,
  WIRE_ACCOUNT_NAME,
} from '../../constants/bankAccounts';
import { selectIsLoadingByActionType } from '../../store/selectors/loadingSelectors';
import { BANK_ACCOUNT_LOADING } from '../../store/actions/bankAccount/bankAccountActionConstants';
import BlockSectionLoader from '../Loader/BlockSectionLoader';
import BankAccountAdd from './BankAccountAdd';
import Paragraph from '../Paragraph/Paragraph';
import AnchorOnClick from '../Anchor/AnchorOnClick';
import { uFlexColumn } from '../../assets/styles/utility';
import { variables } from '../../assets/styles/variables';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import BankAccountCardTable from './BankAccountCardTable';

export const BankAccountCard = styled.div`
  ${uFlexColumn};
  min-width: 0;

  ${mediaBelow(variables.breakpoints.bpMd)} {
  }
`;

export const BankAccountCardTitle = styled.p`
  font-size: ${pxToRem(18)};
  line-height: 1.35;
  font-weight: 600;
  margin-bottom: ${pxToRem(16)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(20)};
    line-height: 1.4;
    margin-bottom: ${pxToRemMd(18)};
  }
`;

export const BankAccountCardWrap = styled.div`
  ${uFlexColumn};
  box-shadow: ${variables.shadow.boxShadow};
  min-height: ${pxToRem(160)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    min-height: ${pxToRemMd(182)};
  }
`;

export const BankAccountCardWarning = styled.p`
  color: ${themeColors.colorPrimary};
  font-size: ${pxToRem(14)};
  line-height: 1.72;
  letter-spacing: 0;
  display: flex;
  align-items: flex-start;
  margin-top: ${pxToRem(16)};

  svg {
    color: ${themeColors.colorPrimary};
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};
    margin-left: ${pxToRem(22)};
    margin-right: ${pxToRem(16)};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-top: ${pxToRemMd(24)};
    font-size: ${pxToRemMd(14)};
    line-height: 1.72;

    svg {
      margin-left: 0;
      margin-right: ${pxToRemMd(16)};
      width: ${pxToRemMd(20)};
      height: ${pxToRemMd(20)};
    }
  }
`;

const BankAccountsCard = ({
  accountType,
  bankAccounts,
  onAddNewClick,
  handleVerifyAccount,
  handleErrorAccount,
  handleRemoveAccount,
  allowAccountChangesPermission,
  numberOfAccountsAllowed,
  bankAccountType,
  openWireDepositWizard,
  contactSupport,
  displayBasicAccountOptionsPermission,
  isErrAccount,
}) => {
  const { t } = useTranslation();

  const isLoadingBankAccounts = useSelector(
    selectIsLoadingByActionType(BANK_ACCOUNT_LOADING),
  );
  const isACHAccount = accountType === ACH_ACCOUNT_NAME;
  const isWIREAccount = accountType === WIRE_ACCOUNT_NAME;
  const canAddMoreAccounts = bankAccounts?.length < numberOfAccountsAllowed;

  const canAddACHAccount = () =>
    canAddMoreAccounts && allowAccountChangesPermission && !isErrAccount;

  return isLoadingBankAccounts ? (
    <BlockSectionLoader isLoading={isLoadingBankAccounts} />
  ) : (
    <BankAccountCard>
      <BankAccountCardTitle>
        {accountType && i18next.t('bankAccounts.cardTitle', { accountType })}
      </BankAccountCardTitle>
      <BankAccountCardWrap>
        <BankAccountCardTable
          accountType={accountType}
          bankAccounts={bankAccounts}
          allowAccountChangesPermission={allowAccountChangesPermission}
          handleVerifyAccount={handleVerifyAccount}
          handleErrorAccount={handleErrorAccount}
          handleRemoveAccount={handleRemoveAccount}
          bankAccountType={bankAccountType}
          contactSupport={contactSupport}
        />
        {accountType === ACH_ACCOUNT_NAME ? (
          <BankAccountAdd
            onClick={onAddNewClick}
            disabled={!canAddACHAccount()}
            accountType={accountType}
          />
        ) : (
          <BankAccountAdd
            onClick={onAddNewClick}
            disabled={
              !allowAccountChangesPermission ||
              displayBasicAccountOptionsPermission ||
              isErrAccount
            }
            accountType={accountType}
          />
        )}
      </BankAccountCardWrap>
      {isACHAccount && !canAddMoreAccounts ? (
        <BankAccountCardWarning>
          <AlertTriangle />
          <span>
            {t('bankAccounts.reachedLimit', {
              number: numberOfAccountsAllowed,
            })}
          </span>
        </BankAccountCardWarning>
      ) : null}
      {isWIREAccount && (
        <Paragraph marginTop={32}>
          {i18next.t('bankAccounts.wireInstructionsCopy')}{' '}
          <AnchorOnClick onClick={(event) => openWireDepositWizard(event)}>
            <strong>{`${i18next.t(
              'bankAccounts.wireInstructionsLink',
            )}`}</strong>
          </AnchorOnClick>
        </Paragraph>
      )}
    </BankAccountCard>
  );
};

BankAccountsCard.propTypes = {
  bankAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      BankAccountUid: PropTypes.string,
      BankName: PropTypes.string,
      AccountMask: PropTypes.string,
      IsVerified: PropTypes.bool,
    }),
  ),
  onAddNewClick: PropTypes.func,
  accountType: PropTypes.string,
  handleVerifyAccount: PropTypes.func,
  handleRemoveAccount: PropTypes.func,
  allowAccountChangesPermission: PropTypes.bool,
  numberOfAccountsAllowed: PropTypes.number,
  bankAccountType: PropTypes.string,
  handleErrorAccount: PropTypes.func,
  openWireDepositWizard: PropTypes.func,
  contactSupport: PropTypes.func,
  displayBasicAccountOptionsPermission: PropTypes.bool,
  isErrAccount: PropTypes.bool,
};

export default BankAccountsCard;
