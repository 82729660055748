import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ACH_ACCOUNT_NAME } from '../../constants/bankAccounts';
import { ACC_PURPOSE_ACH } from '../../util/enum/api/bankAccountTypes';
import useCheckPermissions from '../../util/hooks/useCheckPermissions';
import { useBrokerName } from '../../util/hooks/userBrokerName';
import BankAccountCardTable from '../BankAccountsCard/BankAccountCardTable';
import Paragraph from '../Paragraph/Paragraph';
import { pxToRem } from '../../assets/styles/helper';
import { sortBankAccountsUploadedVoidedCheckIncompleteFirst } from '../../util/helpers/bankAccountHelper';
import SupportModal from '../Modals/SupportModal/SupportModal';
import VerifyBankAccountModal from '../Modals/BankAccount/VerifyBankAccount/VerifyBankAccountModal';

const NoteWrapper = styled.div`
  padding: ${pxToRem(12)};
  margin-top: ${pxToRem(6)};
`;
const UnverifiedBankAccountListContainer = styled.div`
  margin: 0 ${pxToRem(12)};
`;
const BankAccountListWrapper = styled.div`
  padding: 0 ${pxToRem(12)};
`;
const UnverifiedBankAccountList = ({ bankAccounts }) => {
  const brokerName = useBrokerName();
  const { allowAccountChangesPermission } = useCheckPermissions();
  const { t } = useTranslation();
  const [isContactSupportModalOpen, setIsContactSupportModalOpen] = useState(
    false,
  );
  const [isVerifyAccountModalOpen, setIsVerifyAccountModalOpen] = useState(
    false,
  );
  const [selectedBankAccountId, setSelectedBankAccountId] = useState(null);

  const handleMicroDepositVerification = (bankAccountUid) => {
    setSelectedBankAccountId(bankAccountUid);
    setIsVerifyAccountModalOpen(true);
  };

  if (isContactSupportModalOpen) {
    return (
      <SupportModal
        isOpen={isContactSupportModalOpen}
        close={() => setIsContactSupportModalOpen(false)}
      />
    );
  }

  if (isVerifyAccountModalOpen) {
    return (
      <VerifyBankAccountModal
        isOpen={isVerifyAccountModalOpen}
        onClose={() => setIsVerifyAccountModalOpen(false)}
        bankAccountUid={selectedBankAccountId}
      />
    );
  }
  return (
    <UnverifiedBankAccountListContainer>
      <NoteWrapper>
        <Paragraph>
          {t('bankAccounts.toKeepAppSafeNote', { brokerName })}
        </Paragraph>
      </NoteWrapper>
      <BankAccountListWrapper>
        <BankAccountCardTable
          accountType={ACH_ACCOUNT_NAME}
          bankAccounts={sortBankAccountsUploadedVoidedCheckIncompleteFirst(
            bankAccounts,
          )}
          allowAccountChangesPermission={allowAccountChangesPermission}
          handleVerifyAccount={handleMicroDepositVerification}
          handleErrorAccount={() => {}}
          bankAccountType={ACC_PURPOSE_ACH}
          contactSupport={() => setIsContactSupportModalOpen(true)}
          isDepositButtonVisible={false}
        />
      </BankAccountListWrapper>
    </UnverifiedBankAccountListContainer>
  );
};

export default UnverifiedBankAccountList;

UnverifiedBankAccountList.propTypes = {
  bankAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      BankAccountUid: PropTypes.string,
      BankName: PropTypes.string,
      AccountMask: PropTypes.string,
      IsVerified: PropTypes.bool,
    }),
  ),
};
