import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import VaultTable from './VaultTable';
import VaultSelect from './VaultSelect';
import ModalBody from '../Modal/ModalBody';
import useGtmHook from '../../util/hooks/useGtmHook';
import PriceTierData from '../PriceTiers/PriceTierData';
import Label from '../Notes/Label';
import Note from '../Notes/Note';
import ModalButtons from '../Modal/ModalButtons';
import ModalTitle from '../Modal/ModalTitle';
import SingleColumnList from '../TwoColumnList/SingleColumnList';
import config from '../../config';
import { getCurrentAccount } from '../../store/selectors/accountSelectors';
import UseSegregatedStorage from './UseSegregatedStorage';
import {
  selectSegregatedStorageEnabled,
  selectSettings,
} from '../../store/selectors/settingsSelectors';
import { parseEnumType } from '../../util/helpers/enumMappers';
import {
  accountTypeKeys,
  accountTypes,
} from '../../util/enum/api/accountTypes';

const ChooseVault = ({
  wizardData,
  handleNext,
  handleClose,
  setWizardData,
  setWizardTitle,
}) => {
  const { t } = useTranslation();
  const { storageProduct } = wizardData;
  const [locations, setLocations] = useState({
    deliveryLocation: [],
    otherLocations: [],
  });

  const { gtmDialogScreenView } = useGtmHook();
  const [priceTierLocation, setPriceTierLocation] = useState({});
  const { brokerTheme } = config;
  const isLPM = brokerTheme === 'LegacyPM';
  const account = useSelector(getCurrentAccount);
  const isTestAccount = account.IsTestAccount;
  const settings = useSelector(selectSettings);
  const segregatedCaption = settings?.SegregatedCaption;

  const [isSegregatedStorageSelect, setIsSegregatedStorageSelect] = useState(
    wizardData?.isSegregated,
  );
  const [isSegregatedStorageEnabled, setIsSegregatedStorageEnabled] = useState(
    true,
  );

  const segregatedStorageEnabledSetting = useSelector(
    selectSegregatedStorageEnabled,
  );

  const [isSegregatedStorageVisible, setIsSegregatedStorageVisible] = useState(
    false,
  );

  const isIRAAccount =
    parseEnumType(accountTypeKeys, account.AccountType) === accountTypes.IRA;

  const { selectedVault } = wizardData;

  useEffect(() => {
    if (selectedVault?.IsAvailableForSegregated) {
      setIsSegregatedStorageVisible(true);
    }
    if (
      selectedVault?.LocationType === 12 ||
      !selectedVault?.IsAvailableForSegregated
    ) {
      setIsSegregatedStorageSelect(false);
      setIsSegregatedStorageEnabled(false);
      setIsSegregatedStorageVisible(false);
      setWizardData({
        ...wizardData,
        isSegregated: false,
      });
      return;
    }

    setIsSegregatedStorageEnabled(true);
  }, [selectedVault]); // eslint-disable-line

  const selectVault = (selectedVault) => {
    setWizardData({ ...wizardData, selectedVault });
  };

  const selectIsSegregatedStorage = (value) => {
    setIsSegregatedStorageSelect(value);
    setWizardData({
      ...wizardData,
      isSegregated: value,
    });
  };

  useEffect(() => {
    if (isEmpty(storageProduct)) {
      return;
    }

    const { Locations } = storageProduct;

    if (Locations) {
      const deliveryLocation = Locations.filter(
        (location) => location.LocationType === 12,
      );

      const otherLocations = Locations.filter(
        (location) => location.LocationType !== 12,
      );

      setLocations({
        deliveryLocation,
        otherLocations,
      });
    }
  }, [storageProduct, isLPM]);

  useEffect(() => {
    if (storageProduct?.Caption) {
      gtmDialogScreenView({
        title: `Buy Wizard - Choose a Location ${storageProduct?.Caption}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageProduct]);

  useEffect(() => {
    setWizardTitle(t('buyWizard.chooseVault.modalTitle'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isVaultSelected = (vault) => {
    if (isEmpty(wizardData)) {
      return false;
    }

    const { selectedVault } = wizardData;

    if (!selectedVault) {
      return false;
    }

    if (selectedVault?.LocationType === vault.LocationType) {
      return true;
    }
  };

  const isTestProductSelected = (vault) =>
    isVaultSelected(vault) && vault.IsTestProduct;

  if (!isEmpty(priceTierLocation)) {
    return (
      <PriceTierData
        priceTierLocation={priceTierLocation}
        setPriceTierLocation={setPriceTierLocation}
        tradingSide="buy"
        handleNext={handleNext}
      />
    );
  }

  const isNextButtonDisabled = () => {
    const { selectedVault } = wizardData;

    if (selectedVault && !(isTestAccount && !isTestProductSelected)) {
      return false;
    }

    return true;
  };

  return (
    <ModalBody>
      <ModalTitle>
        {' '}
        {isSegregatedStorageSelect
          ? `${storageProduct?.ProductCaption} (${segregatedCaption})`
          : `${storageProduct?.ProductCaption}`}
      </ModalTitle>
      <Label text={t('buyWizard.chooseVault.subtitle')} />
      {!isEmpty(locations?.otherLocations) && (
        <SingleColumnList marginBottom={24}>
          <VaultTable
            headerLabels={[
              t('buyWizard.chooseVault.vaultTableHeaders.vaultLocation'),
              t('buyWizard.chooseVault.vaultTableHeaders.pricePerUnit'),
            ]}
          >
            {locations?.otherLocations.map((location) => (
              <VaultSelect
                key={location?.LocationType}
                isTestProduct={location.IsTestProduct}
                isVaultSelected={isVaultSelected(location)}
                selectVault={selectVault}
                vault={location}
                setPriceTierLocation={setPriceTierLocation}
              />
            ))}
          </VaultTable>
        </SingleColumnList>
      )}

      {!isEmpty(locations?.deliveryLocation) && (
        <SingleColumnList marginBottom={24}>
          <VaultTable
            headerLabels={[
              t('buyWizard.chooseVault.vaultTableHeaders.delivery'),
              t('buyWizard.chooseVault.vaultTableHeaders.pricePerUnit'),
            ]}
          >
            {locations?.deliveryLocation.map((location) => (
              <VaultSelect
                key={location?.LocationType}
                isTestProduct={location.IsTestProduct}
                isVaultSelected={isVaultSelected(location)}
                selectVault={selectVault}
                vault={location}
                setPriceTierLocation={setPriceTierLocation}
              />
            ))}
          </VaultTable>
        </SingleColumnList>
      )}

      {settings && !isEmpty(settings) && !settings?.IsLockedPricingActive && (
        <Note text={t('buyWizard.common.note')} />
      )}
      {segregatedStorageEnabledSetting &&
        isSegregatedStorageVisible &&
        !isIRAAccount && (
          <UseSegregatedStorage
            selectIsSegregatedStorage={selectIsSegregatedStorage}
            isSegregatedStorageSelect={isSegregatedStorageSelect}
            isSegregatedStorageEnabled={isSegregatedStorageEnabled}
          />
        )}
      <ModalButtons
        isHorizontal
        marginTop
        secondaryButtonProps={{
          onClick: handleClose,
          label: t('common.cancel'),
        }}
        primaryButtonProps={{
          disabled: isNextButtonDisabled(),
          onClick: handleNext,
        }}
      />
    </ModalBody>
  );
};

ChooseVault.propTypes = {
  wizardData: PropTypes.shape({
    selectedVault: PropTypes.shape({
      SymbolCode: PropTypes.string,
      LocationType: PropTypes.number,
      IsAvailableForSegregated: PropTypes.bool,
    }),
    isSegregated: PropTypes.bool,
    storageProduct: PropTypes.shape({
      Locations: PropTypes.arrayOf(PropTypes.shape({})),
      Caption: PropTypes.string,
      ProductCaption: PropTypes.string,
    }),
  }),
  handleNext: PropTypes.func,
  handleClose: PropTypes.func,
  setWizardData: PropTypes.func,
  setWizardTitle: PropTypes.func,
};

export default ChooseVault;
