import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import {
  DELIVER_FROM_STORAGE,
  FRACTIONAL_CONVERSION,
} from '../../../../util/enum/api/transactionTypes';
import ReviewBar from '../../../ReviewBar/ReviewBar';
import { formatMoneyNumeral } from '../../../../util/helpers/numeralHelpers';
import SectionLoader from '../../../Loader/SectionLoader';
import Information from '../../DeliveryWizard/Information';
import {
  deleteWizardKey,
  setWizardContent,
} from '../../../../util/helpers/wizardHelpers';
import SupportModal from '../../SupportModal/SupportModal';
import {
  DELIVERY_VERIFIED_DATA,
  INFORMATION_CONTENT_TYPE,
  INFORMATION_MODAL_OPEN,
  SUPPORT_MODAL_OPEN,
  TERMS_MODAL_OPEN,
  WIZARD_DATA,
} from '../../../../constants/sessionStorage';
import TermsAndConditions from '../../MetalStream/TermsAndConditions';
import IraDeliveryAgreement from './IraDeliveryAgreement';
import useCheckPermissions from '../../../../util/hooks/useCheckPermissions';
import { useSessionStorageState } from '../../../../util/hooks/useSessionStorageState';
import {
  getCurrentAccount,
  selectAccountAddresses,
} from '../../../../store/selectors/accountSelectors';
import { formatAddress } from '../../../../util/helpers/addressHelper';
import ModalTitle from '../../../Modal/ModalTitle';
import AgreementCheckbox from '../../../InputFields/AgreementCheckbox';
import ModalButtons from '../../../Modal/ModalButtons';
import Paragraph from '../../../Paragraph/Paragraph';
import ReviewList from '../../../ReviewList/ReviewList';
import ModalBody from '../../../Modal/ModalBody';
import { useBrokerCode } from '../../../../util/hooks/useBrokerCode';
import {
  productTypesArray,
  PRODUCT_TYPE_FRACTIONAL,
} from '../../../../util/enum/api/productTypes';
import { parseEnumType } from '../../../../util/helpers/enumMappers';
import { fetchConvertProduct } from '../../../../store/actions/products/productActions';
import { retrieveFromSessionStorage } from '../../../../util/helpers/sessionStorageHelper';
import { selectConvertProducts } from '../../../../store/selectors/productsSelector';

const ConfirmRequestDelivery = ({
  goStepBack,
  onConfirm,
  product,
  unitsToDeliver,
  deliveryAddress,
  accountUid,
  isLoading,
  onClose,
  funds,
  isInstaVaultProduct,
}) => {
  const { t } = useTranslation();
  const {
    displayIRAOptionsPermission,
    displayIRAEntrustOptionsPermission,
    displayIRAMilleniumOptionsPermission,
    displayIRAEquityOptionsPermission,
  } = useCheckPermissions();
  const addresses = useSelector(selectAccountAddresses);
  const [verifyData] = useSessionStorageState(DELIVERY_VERIFIED_DATA, {});
  const brokerCode = useBrokerCode();
  const dispatch = useDispatch();
  const { AccountUid } = useSelector(getCurrentAccount);
  const wizardData = retrieveFromSessionStorage(WIZARD_DATA);
  const selectedSymbolCode = wizardData?.ProductConfiguration?.SymbolCode;
  const [selectedProductCaption, setSelectedProductCaption] = useState('');
  const convertProducts = useSelector(selectConvertProducts);
  const [
    informationModalOpen,
    setInformationModalOpen,
  ] = useSessionStorageState(INFORMATION_MODAL_OPEN, false);

  const [supportModalOpen, setSupportModalOpen] = useSessionStorageState(
    SUPPORT_MODAL_OPEN,
    false,
  );
  const [
    supportModalFractionalConversionOpen,
    setSupportModalFractionalConversionOpen,
  ] = useSessionStorageState(SUPPORT_MODAL_OPEN, false);

  const [
    informationContentType,
    setInformationContentType,
  ] = useSessionStorageState(INFORMATION_CONTENT_TYPE, null);

  const [termsModalOpen, setTermsModalOpen] = useSessionStorageState(
    TERMS_MODAL_OPEN,
    false,
  );
  const [error, setError] = useState(false);

  const [termsChecked, setTermsChecked] = useState(false);

  const isIraAccount =
    displayIRAOptionsPermission ||
    displayIRAEntrustOptionsPermission ||
    displayIRAMilleniumOptionsPermission ||
    displayIRAEquityOptionsPermission;

  useEffect(() => {
    dispatch(
      fetchConvertProduct({
        accountUid: AccountUid,
        side: 'buy',
        metalType: product?.MetalType === 1 ? 'gold' : 'silver',
      }),
    );
  }, [AccountUid, product]); // eslint-disable-line

  useEffect(() => {
    const selectedProduct = convertProducts.find((product) =>
      product.Locations.some(
        (location) =>
          location.LocationType === 2 &&
          location.SymbolCode === selectedSymbolCode,
      ),
    );
    setSelectedProductCaption(selectedProduct?.ProductCaption);
  }, [convertProducts]); // eslint-disable-line

  const openTermsModal = (event) => {
    event.preventDefault();
    setTermsModalOpen(true);
    setWizardContent(TERMS_MODAL_OPEN, true);
  };

  const closeTermsModal = () => {
    setTermsModalOpen(false);
    deleteWizardKey(TERMS_MODAL_OPEN);
  };

  const handleCheckboxClick = () => {
    setTermsChecked(!termsChecked);
  };

  const handleNextTermsModal = () => {
    closeTermsModal();
    setTermsChecked(true);
  };

  const confirmDelivery = () => {
    if (!(funds >= getDeliveryTotal()) && !isInstaVaultProduct) {
      setError(true);
      return;
    }
    if (isInstaVaultProduct) {
      onConfirm({
        Quantity: unitsToDeliver,
        SymbolCode: product.SymbolCode,
        AddressUid: deliveryAddress.value,
        AccountUid: accountUid,
        ConversionSymbolCode: selectedSymbolCode,
        Type: FRACTIONAL_CONVERSION,
      });
      return;
    }

    onConfirm({
      Quantity: unitsToDeliver,
      SymbolCode: product.SymbolCode,
      AddressUid: deliveryAddress.value,
      AccountUid: accountUid,

      Type: DELIVER_FROM_STORAGE,
    });
  };

  const handleBack = () => {
    setInformationModalOpen(false);
    deleteWizardKey(INFORMATION_MODAL_OPEN);
  };

  const getDeliveryTotal = () =>
    verifyData.DeliveryCosts +
    verifyData.SalesTax +
    verifyData.UnassessedStorageFees;

  const selectedAddress = addresses.find(
    (item) => item.AddressUid === deliveryAddress.value,
  );

  const reviewDataInstavault = [
    {
      label: t('product.requestDelivery.deliveryAddress'),
      value: formatAddress(selectedAddress),
    },
    {
      label: t('product.requestDelivery.convertTo'),
      value: selectedProductCaption,
    },
    {
      label: t('product.requestDelivery.unitsToDeliver'),
      value: unitsToDeliver,
    },
  ];

  const reviewDataFractional = [
    {
      label: t('product.requestDelivery.deliveryAddress'),
      value: formatAddress(selectedAddress),
    },
    {
      label: t('product.requestDelivery.convertFractionalTo'),
      value: selectedProductCaption,
    },
    {
      label: t('product.requestDelivery.unitsToDeliver'),
      value: unitsToDeliver,
    },
  ];

  const reviewData = [
    {
      label: t('product.requestDelivery.deliveryAddress'),
      value: formatAddress(selectedAddress),
    },
    {
      label: t('product.requestDelivery.unitsToDeliver'),
      value: unitsToDeliver,
    },
    {
      label: t('product.requestDelivery.deliveryCost'),
      value: formatMoneyNumeral(verifyData.DeliveryCosts),
    },
    {
      label: t('product.requestDelivery.salesTax'),
      value: formatMoneyNumeral(verifyData.SalesTax),
      action: () => {
        setInformationContentType('salesTax');
        setWizardContent(INFORMATION_CONTENT_TYPE, 'salesTax');
        setWizardContent(INFORMATION_MODAL_OPEN, true);
        setInformationModalOpen(true);
      },
    },
    {
      label: t('product.requestDelivery.accStorageFees'),
      value:
        verifyData.UnassessedStorageFees === null
          ? 'N/A'
          : formatMoneyNumeral(verifyData.UnassessedStorageFees),
      action: () => {
        setInformationContentType('storageFees');
        setWizardContent(INFORMATION_CONTENT_TYPE, 'storageFees');
        setWizardContent(INFORMATION_MODAL_OPEN, true);
        setInformationModalOpen(true);
      },
    },
    {
      label: t('product.requestDelivery.orderTotal'),
      value: formatMoneyNumeral(getDeliveryTotal()),
    },
  ];

  const reviewDataType = () => {
    if (isInstaVaultProduct) {
      return reviewDataInstavault;
    }
    if (productType === PRODUCT_TYPE_FRACTIONAL) {
      return reviewDataFractional;
    }

    return reviewData;
  };

  const productType = useMemo(
    () =>
      isEmpty(product)
        ? null
        : parseEnumType(productTypesArray, product.ProductType),
    [product],
  );

  return (
    <>
      <ReviewBar title={t('product.requestDelivery.reviewInfo')} />
      {informationModalOpen || supportModalOpen || termsModalOpen ? null : (
        <SectionLoader isLoading={isLoading}>
          <ModalBody>
            <ModalTitle>{product.ProductCaption}</ModalTitle>
            <ReviewList
              data={reviewDataType()}
              displayFractionalNote={
                productType === PRODUCT_TYPE_FRACTIONAL || isInstaVaultProduct
              }
              openSupport={() => {
                setWizardContent(SUPPORT_MODAL_OPEN, true);
                setSupportModalFractionalConversionOpen(true);
              }}
            />

            {error && (
              <Paragraph isError marginTop={8}>
                <strong>
                  {t('product.requestDelivery.fundsInsufficient')}
                </strong>
              </Paragraph>
            )}

            {isIraAccount && (
              <AgreementCheckbox
                onChange={handleCheckboxClick}
                name="terms"
                checked={termsChecked}
                text={t('product.requestDelivery.agreement.agree', {
                  brokerCode,
                })}
                // link={t('product.requestDelivery.agreement.type')}
                onLinkClick={(event) => {
                  openTermsModal(event);
                }}
              />
            )}
            <ModalButtons
              isVertical
              marginTop
              secondaryButtonProps={{ onClick: goStepBack }}
              primaryButtonProps={{
                disabled: isIraAccount ? !termsChecked : false,
                onClick: confirmDelivery,
                label: t('common.confirm'),
              }}
            />
          </ModalBody>
        </SectionLoader>
      )}

      <Information
        handleClose={onClose}
        isOpen={informationModalOpen}
        handleBack={handleBack}
        type={informationContentType}
        openSupport={() => {
          setWizardContent(SUPPORT_MODAL_OPEN, true);
          setSupportModalOpen(true);
          setInformationModalOpen(false);
          deleteWizardKey(INFORMATION_MODAL_OPEN);
        }}
      />

      <SupportModal
        goBack={() => {
          setInformationModalOpen(true);
          setWizardContent(INFORMATION_MODAL_OPEN, true);
          deleteWizardKey(SUPPORT_MODAL_OPEN);
          setSupportModalOpen(false);
        }}
        close={onClose}
        overModal
        isOpen={supportModalOpen}
      />
      <SupportModal
        goBack={() => {
          setSupportModalFractionalConversionOpen(false);
          deleteWizardKey(SUPPORT_MODAL_OPEN);
        }}
        close={() => {
          setSupportModalFractionalConversionOpen(false);
          deleteWizardKey(SUPPORT_MODAL_OPEN);
        }}
        overModal
        isOpen={supportModalFractionalConversionOpen}
      />

      <TermsAndConditions
        title={t('product.requestDelivery.agreement.title')}
        isOpen={termsModalOpen}
        handleClose={closeTermsModal}
        handleBack={closeTermsModal}
        handleNext={handleNextTermsModal}
        avoidClearingStorage
        overModal
      >
        <IraDeliveryAgreement />
      </TermsAndConditions>
    </>
  );
};

ConfirmRequestDelivery.propTypes = {
  goStepBack: PropTypes.func,
  onConfirm: PropTypes.func,
  product: PropTypes.shape({
    ProductCaption: PropTypes.string,
    SymbolCode: PropTypes.string,
    ProductType: PropTypes.string,
    MetalType: PropTypes.number,
    Locations: PropTypes.shape([]),
    ProductCode: PropTypes.string,
  }),
  unitsToDeliver: PropTypes.number,
  deliveryAddress: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  accountUid: PropTypes.string,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  funds: PropTypes.number,
  convertProduct: PropTypes.shape({}),
  isInstaVaultProduct: PropTypes.bool,
};

export default ConfirmRequestDelivery;
