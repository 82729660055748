import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import { useDispatch, useSelector } from 'react-redux';
import memoize from 'lodash.memoize';
import isEmpty from 'lodash.isempty';
import styled from 'styled-components';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import NoResults from '../NoResults/NoResults';
import {
  selectMyHoldingsCodes,
  selectMyHoldingsCodesError,
} from '../../store/selectors/portfolioHoldingsSelectors';
import ProductCard from '../ProductCard/ProductCard';
import { setStorageProduct } from '../../store/actions/buy/buyStorageActions';
import SellWizardModal from '../Modals/SellWizard/SellWizardModal';
import { selectProductPrices } from '../../store/selectors/productsSelector';
import {
  closeWizardContent,
  setWizardContent,
} from '../../util/helpers/wizardHelpers';
import { parseEnumType } from '../../util/helpers/enumMappers';
import {
  PRODUCT_TYPE_FRACTIONAL,
  productLocationTypes,
} from '../../util/enum/api/productTypes';
import BlockSectionLoader from '../Loader/BlockSectionLoader';
import CustomCurrentRefinement from './CustomCurrentRefinement';
import {
  CHOSEN_VAULT,
  IS_MODAL_OPEN,
  IS_PRODUCT_FRACTIONAL,
  PRODUCT,
  WIZARD_DATA,
  IS_INSTA_VAULT_PRODUCT,
} from '../../constants/sessionStorage';
import useCheckPermissions from '../../util/hooks/useCheckPermissions';
import AlgoliaLoader from '../Loader/AlgoliaLoader';
import { useSessionStorageState } from '../../util/hooks/useSessionStorageState';
import {
  retrieveFromSessionStorage,
  storeInSessionStorage,
} from '../../util/helpers/sessionStorageHelper';
import { pxToRem } from '../../assets/styles/helper';
import { clearBuyStorageSuccessState } from '../../store/actions/orders/orderActions';
import {
  getCurrentAccount,
  getCurrentAccountUid,
} from '../../store/selectors/accountSelectors';
import { selectBankAccountAchData } from '../../store/selectors/bankAccountSelectors';
import {
  actionRequiredStatus,
  expiredVerificationStatus,
  failedVerificationStatus,
  getBankAccountStatus,
  pendingReviewStatus,
} from '../../util/helpers/bankAccountHelper';
import { selectIsLoadingByActionType } from '../../store/selectors/loadingSelectors';
import { BANK_ACCOUNT_LOADING } from '../../store/actions/bankAccount/bankAccountActionConstants';
import { fetchBankAccounts } from '../../store/actions/bankAccount/bankAccountActions';
import BuyWizard from '../Modals/BuyWizard/BuyWizard';
import useGetPeriodicPriceTiers from '../../util/hooks/useGetPeriodicPriceTiers';
import { selectSettings } from '../../store/selectors/settingsSelectors';
import { COUNTDOWN_TIMER_INTERVAL_MS } from '../../constants/timerConstants';
import config from '../../config';

const shouldRefineByLocation = (items) => {
  const shouldFilterRefinement = !isEmpty(items);

  if (!shouldFilterRefinement) {
    return {};
  }

  const locationRefinement = items.find(
    (item) => item.attribute === 'Vaults.Location',
  );

  if (!locationRefinement) {
    return {};
  }

  return locationRefinement;
};

const filterCurrentRefinement = memoize((products, locationRefinement) => {
  const currentRefinements =
    locationRefinement && locationRefinement.currentRefinement;
  if (isEmpty(currentRefinements)) {
    return products;
  }

  return products.filter((product) =>
    product.Locations.some((location) => {
      const currentLocation = parseEnumType(
        productLocationTypes,
        location.LocationType,
      );

      return currentRefinements.includes(currentLocation);
    }),
  );
});

const calculateProductPrice = (product, myHoldingsCodes) => {
  const products = product.Locations.filter((location) =>
    myHoldingsCodes.some(
      (holdingCode) => holdingCode.SymbolCode === location.SymbolCode,
    ),
  );

  if (isEmpty(products)) {
    return product.ProductPrice;
  }

  return products.reduce((a, b) => (a.PricePerUnit > b.PricePerUnit ? a : b))
    .PricePerUnit;
};

const filterMyHoldings = (
  isMyHoldingsChecked,
  products,
  myHoldingsCodes,
  locationRefinement,
) => {
  if (isMyHoldingsChecked && !isEmpty(myHoldingsCodes)) {
    const myHoldingProducts = products
      .filter((product) => {
        const currentRefinements =
          locationRefinement && locationRefinement.currentRefinement;

        if (isEmpty(currentRefinements)) {
          const hasProductCode = myHoldingsCodes.some(
            (holdingCode) => holdingCode.ProductCode === product.ProductCode,
          );
          return hasProductCode;
        }

        const productLocations = product.Locations.filter((location) => {
          const currentLocation = parseEnumType(
            productLocationTypes,
            location.LocationType,
          );

          return currentRefinements.includes(currentLocation);
        });
        return productLocations.some((location) =>
          myHoldingsCodes.some(
            (holdingCode) => holdingCode.SymbolCode === location.SymbolCode,
          ),
        );
      })
      .map((product) => ({
        ...product,
        ProductPrice: calculateProductPrice(product, myHoldingsCodes),
      }));
    return myHoldingProducts;
  }

  return products;
};

const InfiniteHitsItem = styled.li`
  &:not(:last-child) {
    margin-bottom: ${pxToRem(16)};
  }
`;

const InfiniteHitsWrap = styled.div`
  min-height: ${pxToRem(200)};
`;

export const ShowMoreWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${pxToRem(32)};
`;

const InfiniteHitsWithScrollToTop = ({
  hits,
  isMyHoldingsChecked,
  priceTiersClickHandler,
  page,
  side,
  products,
  setProducts,
  isLoading,
  isLockedPrice,
}) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useSessionStorageState(
    IS_MODAL_OPEN,
    false,
  );
  const [isFractionalProduct, setIsFractionalProduct] = useSessionStorageState(
    IS_PRODUCT_FRACTIONAL,
    false,
  );
  const [items, setItems] = useState([]);
  const settings = useSelector(selectSettings);
  const [isSegregated, setIsSegregated] = useState(false);
  useEffect(() => {
    if (isSegregated) {
      setIsSegregated(isSegregated);
    }
  }, [isSegregated]);
  const { brokerTheme } = config;
  const isGS = brokerTheme === 'GoldSilver';
  const [isInstaVaultProduct, setIsInstaVaultProduct] = useSessionStorageState(
    IS_INSTA_VAULT_PRODUCT,
    false,
  );

  const {
    allowAccountChangesPermission,
    displayIRAOptionsPermission,
    displayBasicAccountOptionsPermission,
  } = useCheckPermissions();

  const productPrices = useSelector(selectProductPrices);
  const myHoldingsCodes = useSelector(selectMyHoldingsCodes);
  const myHoldingsCodesError = useSelector(selectMyHoldingsCodesError);
  const accountUid = useSelector(getCurrentAccountUid);
  const achBankAccounts = useSelector(selectBankAccountAchData);

  const unverifiedBankAccounts = achBankAccounts?.filter((bankAccount) => {
    const verificationStatus = getBankAccountStatus(bankAccount).status;
    return [
      actionRequiredStatus,
      failedVerificationStatus,
      expiredVerificationStatus,
      pendingReviewStatus,
    ].includes(verificationStatus);
  });

  const isLoadingBankAccounts = useSelector(
    selectIsLoadingByActionType([BANK_ACCOUNT_LOADING]),
  );

  const currentAccount = useSelector(getCurrentAccount);
  const [counterValue, setCounterValue] = useState(
    Math.floor(
      settings.PricingRefreshFrequencyMilliseconds /
        COUNTDOWN_TIMER_INTERVAL_MS,
    ),
  );
  const [unityValue, setUnityValue] = useState(false);

  useEffect(() => {
    if (accountUid) {
      dispatch(fetchBankAccounts({ accountUid }));
    }
  }, [accountUid, dispatch]);

  useEffect(() => {
    if (productPrices.length > 0 && hits.length > 0) {
      if (side === 'sell') {
        if (isMyHoldingsChecked && myHoldingsCodes.length === 0) {
          setProducts([]);
          return;
        }
        const mapProducts = hits
          .map((hit) => {
            // find productCode that matches & check if product has locations
            const product = productPrices.find(
              (product) =>
                product.ProductCode === hit.Code && product.Locations,
            );

            return !product ? null : { ...hit, ...product };
          })
          .filter(Boolean);

        const locationRefinement = shouldRefineByLocation(items);
        const myHoldingProducts = !isEmpty(myHoldingsCodes)
          ? filterMyHoldings(
              isMyHoldingsChecked,
              mapProducts,
              myHoldingsCodes,
              locationRefinement,
            )
          : mapProducts;
        const currentRefinementFiltered = filterCurrentRefinement(
          myHoldingProducts,
          locationRefinement,
        );

        setProducts(currentRefinementFiltered);
      }
      if (side === 'buy') {
        const mapProducts = hits
          .map((hit) => {
            // find productCode that matches & check if product has locations
            const product = productPrices.find(
              (product) =>
                product.ProductCode === hit.Code && product.Locations,
            );

            return !product ? null : { ...hit, ...product };
          })
          .filter(Boolean);

        const locationRefinement = shouldRefineByLocation(items);
        const myHoldingProducts = !isEmpty(myHoldingsCodes)
          ? filterMyHoldings(
              isMyHoldingsChecked,
              mapProducts,
              myHoldingsCodes,
              locationRefinement,
            )
          : mapProducts;
        const currentRefinementFiltered = filterCurrentRefinement(
          myHoldingProducts,
          locationRefinement,
        );

        setProducts(currentRefinementFiltered);
      }
    }
  }, [productPrices, isMyHoldingsChecked, hits.length, myHoldingsCodes]); // eslint-disable-line

  const handleCloseModal = () => {
    setIsModalOpen(false);
    dispatch(clearBuyStorageSuccessState());
    closeWizardContent();
  };

  const handleSelectProduct = (product, vault) => {
    if (vault) {
      setWizardContent('ShortFlow', true);
      setIsSegregated(!!vault.IsSegregated);
    }

    dispatch(setStorageProduct({ product, vault }));

    setWizardContent(CHOSEN_VAULT, vault);
    setWizardContent(PRODUCT, product);
    storeInSessionStorage(WIZARD_DATA, {
      storageProduct: product,
      selectedVault: vault,
    });
    setWizardContent(IS_MODAL_OPEN, true);
    setIsModalOpen(true);

    setWizardContent(
      IS_PRODUCT_FRACTIONAL,
      product.ProductType === PRODUCT_TYPE_FRACTIONAL,
    );
    setIsFractionalProduct(product.ProductType === PRODUCT_TYPE_FRACTIONAL);

    const isInstavault =
      isGS &&
      (product.ProductCode === settings.InstavaultProductCodes[0] ||
        product.ProductCode === settings.InstavaultProductCodes[1]);
    setIsInstaVaultProduct(isInstavault);
    setWizardContent(IS_INSTA_VAULT_PRODUCT, isInstavault);
  };

  const periodicCallback = useCallback((epoch) => {
    if (epoch?.data) {
      const [counter, unity] = epoch.data;
      setCounterValue((prevCounter) => prevCounter - prevCounter + counter);
      setUnityValue(unity);
    }
  }, []);

  const wizardData = retrieveFromSessionStorage(WIZARD_DATA);

  useGetPeriodicPriceTiers(
    'priceTiersIHWSTT',
    currentAccount,
    parseEnumType(
      productLocationTypes,
      wizardData?.selectedVault?.LocationType,
    ),
    wizardData?.selectedVault?.SymbolCode,
    'Sell',
    dispatch,
    page === 'sell' &&
      isModalOpen &&
      isFractionalProduct &&
      !isInstaVaultProduct,
    settings?.PricingRefreshFrequencyMilliseconds,
    true,
    page === 'sell' &&
      isModalOpen &&
      isFractionalProduct &&
      !isInstaVaultProduct
      ? periodicCallback
      : null,
  );

  const renderHits = () => (
    <>
      <CustomCurrentRefinement setItems={setItems} />
      {isEmpty(products) || isEmpty(hits) ? (
        <NoResults />
      ) : (
        <>
          <ul>
            {products.map((product) => (
              <InfiniteHitsItem
                key={product.objectID}
                data-cy="container-product-item"
              >
                <ProductCard
                  displayIRAOptionsPermission={displayIRAOptionsPermission}
                  product={product}
                  isTestProduct={
                    product.Locations.filter((product) => product.IsTestProduct)
                      .length > 0
                  }
                  isTradingEnabled={product.IsTradingEnabled}
                  collapsable={side === 'buy'}
                  priceTiersClickHandler={priceTiersClickHandler}
                  selectProduct={handleSelectProduct}
                  myHoldingsCodes={myHoldingsCodes}
                  side={side}
                  isMyHoldingsChecked={isMyHoldingsChecked}
                  allowAccountChangesPermission={allowAccountChangesPermission}
                  displayBasicAccountOptionsPermission={
                    displayBasicAccountOptionsPermission
                  }
                />
              </InfiniteHitsItem>
            ))}
          </ul>
          <ShowMoreWrap>
            <ScrollToTop />
          </ShowMoreWrap>
        </>
      )}

      {page === 'buy'
        ? isModalOpen && (
            <BuyWizard
              isModalOpen={isModalOpen}
              handleCloseModal={handleCloseModal}
              unverifiedBankAccounts={unverifiedBankAccounts}
              isLockedPrice={isLockedPrice}
              isSegregated={isSegregated}
            />
          )
        : isModalOpen && (
            <SellWizardModal
              isModalOpen={isModalOpen}
              handleCloseModal={handleCloseModal}
              isFractionalProduct={isFractionalProduct}
              isInstaVaultProduct={isInstaVaultProduct}
              isSegregated={isSegregated}
              isLockedPrice={isLockedPrice}
              counter={counterValue}
              unity={unityValue}
            />
          )}
    </>
  );

  const listLoadingRender = () => {
    if (products === null || isLoadingBankAccounts) {
      return <BlockSectionLoader isLoading />;
    }

    return <div>{renderHits()}</div>;
  };

  return isLoading ? (
    <BlockSectionLoader isLoading />
  ) : (
    <InfiniteHitsWrap>
      <AlgoliaLoader>
        {myHoldingsCodesError && <span>{myHoldingsCodesError}</span>}
        {listLoadingRender()}
      </AlgoliaLoader>
    </InfiniteHitsWrap>
  );
};

InfiniteHitsWithScrollToTop.propTypes = {
  hits: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMyHoldingsChecked: PropTypes.bool,
  priceTiersClickHandler: PropTypes.func,
  page: PropTypes.string,
  side: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.shape({})),
  setProducts: PropTypes.func,
  isLoading: PropTypes.bool,
  isLockedPrice: PropTypes.bool,
};

export default connectInfiniteHits(InfiniteHitsWithScrollToTop);
