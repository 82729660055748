import styled from 'styled-components';
import { mediaBelow, pxToRemMd } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';
import IconButton from '../IconButton/IconButton';

export const TradingFilterButton = styled(IconButton)`
  flex-shrink: 0;
  display: none;
  width: ${pxToRemMd(48)};
  height: ${pxToRemMd(48)};
  align-items: center;
  justify-content: center;
  background-color: ${themeColors.colorPrimary};
  color: ${themeColors.colorTextContrast};
  border-radius: ${variables.borderRadius.borderRadius};
  margin-left: ${pxToRemMd(16)};

  svg {
    width: ${pxToRemMd(32)};
    height: ${pxToRemMd(32)};
    color: ${themeColors.colorFilterIcon};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    display: flex;
  }
`;
