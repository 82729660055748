import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';

import Modal from '../../../Modal/Modal';
import CurrencyField from '../../../InputFields/CurrencyField';

import SelectField from '../../../InputFields/SelectField';
import { formatMoneyNumeral } from '../../../../util/helpers/numeralHelpers';
import {
  deleteWizardKey,
  setWizardContent,
} from '../../../../util/helpers/wizardHelpers';
import {
  WITHDRAWAL_ADDRESS,
  WITHDRAWAL_AMOUNT,
} from '../../../../constants/sessionStorage';
import { checkWithdrawalValidationSchema } from '../../../../validation/checkWithdrawalValidationSchema';
import ModalBody from '../../../Modal/ModalBody';
import Paragraph from '../../../Paragraph/Paragraph';
import Note from '../../../Notes/Note';
import FormContainer from '../../../FormContainer/FormContainer';
import ModalButtons from '../../../Modal/ModalButtons';
import Label from '../../../Notes/Label';

const CheckWithdrawalInformation = ({
  isModalOpen,
  handleBack,
  handleNext,
  handleClose,
  addressAndAmount,
  addresses,
  defaultMailingAddress,
  availableBalance,
  balances,
  isLoading,
  setWithdrawalAddress,
  setWithdrawalAmount,
  setFundsModalInfo,
  setIsNoAddressModalOpen,
}) => {
  const { t } = useTranslation();

  const handleSubmit = (values) => {
    setWizardContent(WITHDRAWAL_ADDRESS, values.address);
    setWizardContent(WITHDRAWAL_AMOUNT, values.amount);
    setWithdrawalAddress(values.address);
    setWithdrawalAmount(values.amount);
    handleNext();
  };
  const initialValues = {
    amount: addressAndAmount?.amount,
    address: {
      value: addressAndAmount.address?.value,
      label: addressAndAmount.address?.label,
    },
  };

  const handleBackButtonClick = () => {
    handleBack();
    deleteWizardKey(WITHDRAWAL_AMOUNT);
    deleteWizardKey(WITHDRAWAL_ADDRESS);
  };

  return (
    <Modal
      title={t('standardWithdrawalWizard.checkWithdrawal.title')}
      size="sm"
      isOpen={isModalOpen}
      isLoading={isLoading}
      close={handleClose}
    >
      <ModalBody>
        <Label
          marginBottom={4}
          isBold
          text={t('standardWithdrawalWizard.availableForWithdrawal')}
        />
        <Paragraph fontSize={18} marginBottom={8}>
          {formatMoneyNumeral(availableBalance)}
        </Paragraph>

        <Note
          text={t('standardWithdrawalWizard.dontSeeFunds')}
          marginBottom={24}
          onClick={() => {
            setWizardContent(
              'FundsModalInfo',
              JSON.stringify({ isOpen: true, balances }),
            );
            setFundsModalInfo({ isOpen: true, balances });
          }}
        />

        <Formik
          initialValues={initialValues}
          validationSchema={checkWithdrawalValidationSchema(availableBalance)}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, errors, setFieldTouched, setFieldValue }) => (
            <Form>
              <FormContainer>
                <SelectField
                  name="address"
                  label={t(
                    'standardWithdrawalWizard.checkWithdrawal.deliverAddress',
                  )}
                  defaultSelected={defaultMailingAddress}
                  link={
                    <Note
                      onClick={setIsNoAddressModalOpen}
                      text={t(
                        'standardWithdrawalWizard.checkWithdrawal.dontSeeAddress',
                      )}
                    />
                  }
                  options={addresses}
                />
                <CurrencyField
                  label={t('standardDepositWizard.achDeposit.achAmount')}
                  onKeyUp={() => {
                    setFieldTouched('amount', true);
                  }}
                  name="amount"
                  defaultValue={values.amount}
                  placeholder={formatMoneyNumeral(0)}
                  onChange={(value) => setFieldValue('amount', value)}
                />
              </FormContainer>
              <ModalButtons
                isHorizontal
                marginTop
                secondaryButtonProps={{ onClick: handleBackButtonClick }}
                primaryButtonProps={{
                  type: 'submit',
                  disabled:
                    !values.amount || !isEmpty(errors) || !values.address.value,
                }}
              />
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

CheckWithdrawalInformation.propTypes = {
  isModalOpen: PropTypes.bool,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  handleClose: PropTypes.func,
  addressAndAmount: PropTypes.shape({
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    address: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  addresses: PropTypes.arrayOf(PropTypes.shape({})),
  defaultMailingAddress: PropTypes.shape({}),
  availableBalance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setWithdrawalAddress: PropTypes.func,
  setWithdrawalAmount: PropTypes.func,
  balances: PropTypes.shape({}),
  setFundsModalInfo: PropTypes.func,
  setIsNoAddressModalOpen: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default CheckWithdrawalInformation;
