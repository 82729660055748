import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Paragraph from '../Paragraph/Paragraph';
import SingleColumnList from '../TwoColumnList/SingleColumnList';
import paymentTypes, {
  PAYMENT_METHOD_TYPES,
} from '../../util/enum/api/paymentTypes';
import creditCardTypes, {
  CREDIT_CARDS_TYPES_ICONS,
} from '../../util/enum/api/creditCardTypes';
import { formatMoneyNumeral } from '../../util/helpers/numeralHelpers';
import themeColors from '../../assets/styles/themeColors';
import { ReactComponent as AlertTriangle } from '../../assets/images/svg/alert-triangle.svg';
import { pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { formatDate } from '../../util/helpers/dateHelpers';

const Group = styled.div`
  display: flex;
  flex-direction: column;
`;

const WarningNote = styled(Paragraph)`
  display: flex;
  align-items: center;
  color: ${themeColors.colorRed};
  font-size: ${pxToRemMd(12)};
  margin-top: ${pxToRem(4)};

  svg {
    color: ${themeColors.colorRed};
    margin-left: 0;
    margin-top: 0;
    margin-right: ${pxToRem(4)};
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};
  }
`;

const CreditCardTypeContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    margin-top: 0;
  }
`;

const SinglePaymentMethod = ({
  method,
  isAccountBalanceDisabled,
  totalAmountWithoutCreditCard,
  totalAmountWithCreditCard,
}) => {
  const { t } = useTranslation();
  // disabled: BankAccountSetupType = 2; enabled: BankAccountSetupType = 3;
  const disabledACH =
    method?.PaymentMethodType === PAYMENT_METHOD_TYPES.ACH &&
    method?.BankAccountSetupType === 2;

  const disabledCreditCard =
    method?.PaymentMethodType === PAYMENT_METHOD_TYPES.CREDIT_CARD &&
    method?.CreditCardIsExpired;

  const isLimitExceededForCC = totalAmountWithCreditCard > method?.MaximumLimit;
  const isLimitExceededForACH =
    totalAmountWithoutCreditCard > method?.MaximumLimit;

  return (
    <SingleColumnList>
      <Group>
        <Paragraph fontSize={16} bold marginBottom={4}>
          {t(
            `buyWizard.postPaid.paymentMethods.${
              paymentTypes[method?.PaymentMethodType]
            }`,
          )}
        </Paragraph>
        {(method?.AccountBalance || method?.AccountBalance === 0) && (
          <>
            <Paragraph fontSize={16} marginBottom={4}>
              {t('buyWizard.postPaid.availableForTrading', {
                accountBalanceAmount: formatMoneyNumeral(
                  method?.AccountBalance,
                ),
              })}
            </Paragraph>

            {isAccountBalanceDisabled && (
              <WarningNote
                fontSize={16}
                color={themeColors.colorTextSecondary}
                bold
              >
                <AlertTriangle />
                {t('buyWizard.postPaid.insufficientBalance')}
              </WarningNote>
            )}
          </>
        )}
        {method?.BankName && (
          <Paragraph fontSize={16} marginBottom={4}>
            {`${method?.BankName}, ${method?.BankAccountMask}`}
          </Paragraph>
        )}
        {method?.CreditCardType && (
          <CreditCardTypeContainer>
            {CREDIT_CARDS_TYPES_ICONS[method?.CreditCardType]}
            <Paragraph fontSize={16}>
              {t('creditCards.cardTypeAndNumberLabel', {
                cardType: t(
                  `creditCards.creditCardTypes.${
                    creditCardTypes[method?.CreditCardType]
                  }`,
                ),
                last4Digits: method?.Last4Digits,
              })}
            </Paragraph>
          </CreditCardTypeContainer>
        )}
        {!disabledACH &&
          method?.MaximumLimit &&
          method?.PaymentMethodType === PAYMENT_METHOD_TYPES.ACH && (
            <Paragraph
              fontSize={16}
              color={
                isLimitExceededForACH
                  ? themeColors.colorRed
                  : themeColors.colorTextSecondary
              }
              bold
            >
              {t('buyWizard.postPaid.limitPerDay', {
                limitAmount: formatMoneyNumeral(method?.MaximumLimit),
              })}
            </Paragraph>
          )}
        {method?.MaximumLimit &&
          method?.PaymentMethodType === PAYMENT_METHOD_TYPES.CREDIT_CARD && (
            <Paragraph
              fontSize={16}
              color={
                isLimitExceededForCC
                  ? themeColors.colorRed
                  : themeColors.colorTextSecondary
              }
              bold
            >
              {t('buyWizard.postPaid.limitPerOrder', {
                limitAmount: formatMoneyNumeral(method?.MaximumLimit),
              })}
            </Paragraph>
          )}

        {disabledCreditCard && (
          <WarningNote
            fontSize={16}
            color={themeColors.colorTextSecondary}
            bold
          >
            <AlertTriangle />
            {t('buyWizard.postPaid.creditCardExpired', {
              expirationMonth: formatDate(
                method.CreditCardExpirationDate,
                'MM',
              ),
              expirationYear: formatDate(method.CreditCardExpirationDate, 'yy'),
            })}
          </WarningNote>
        )}
        {disabledACH && (
          <WarningNote
            fontSize={16}
            color={themeColors.colorTextSecondary}
            bold
          >
            <AlertTriangle />
            {t('buyWizard.postPaid.onlyPlaidAccepted')}
          </WarningNote>
        )}
      </Group>
    </SingleColumnList>
  );
};

SinglePaymentMethod.propTypes = {
  method: PropTypes.shape({
    PaymentMethodType: PropTypes.number,
    AccountBalance: PropTypes.number,
    BankName: PropTypes.string,
    BankAccountMask: PropTypes.number,
    CreditCardType: PropTypes.number,
    Last4Digits: PropTypes.number,
    MaximumLimit: PropTypes.number,
    BankAccountSetupType: PropTypes.number,
    CreditCardIsExpired: PropTypes.bool,
    CreditCardExpirationDate: PropTypes.string,
  }),
  totalAmountWithoutCreditCard: PropTypes.number,
  totalAmountWithCreditCard: PropTypes.number,
  isAccountBalanceDisabled: PropTypes.bool,
};

export default SinglePaymentMethod;
