import { createSelector } from 'reselect';
import { JWT_TOKEN } from '../../constants/localStorage';
import { authScopeStringGetHelper } from '../../util/helpers/authScopeHelpers';

const loginSelector = (state) => state.login;

export const selectLoginEmail = createSelector(
  loginSelector,
  (state) => state.email,
);

export const selectUsernames = createSelector(
  loginSelector,
  (state) => state.usernames,
);

export const selectUsernamesForSelectField = createSelector(
  selectUsernames,
  (state) => state.map((username) => ({ value: username, label: username })),
);

export const selectTokens = createSelector(
  loginSelector,
  (state) => state.token,
);
export const selectJWT = createSelector(
  loginSelector,
  (state) => state.token.JwtToken || authScopeStringGetHelper(JWT_TOKEN),
);
export const selectRegisterTokens = createSelector(
  loginSelector,
  (state) => state.registerToken,
);

export const selectTwoFactorAuthenticationResponse = createSelector(
  loginSelector,
  (state) => state.TwoFactorAuthenticationResponse,
);

export const selectLoginError = createSelector(
  loginSelector,
  (state) => state.errorMessage,
);
