import createReducer from '../../utils/createReducer';
import { PORTFOLIO_BALANCES_SUCCESS } from '../../actions/portfolio/portfolioActionConstants';

const initialState = {};
export default createReducer(
  {
    [PORTFOLIO_BALANCES_SUCCESS]: setPortfolioBalances,
  },
  initialState,
);

function setPortfolioBalances(state, action) {
  return {
    ...action.payload,
  };
}
