import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Paragraph from '../../Paragraph/Paragraph';
import SingleColumnList from '../../TwoColumnList/SingleColumnList';
import ModalButtons from '../../Modal/ModalButtons';

const QrCodeFailed = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <SingleColumnList marginTop={24} marginBottom={24} alignCenter>
      <Paragraph isError marginBottom={24}>
        {t('twoFa.loginSetup.authenticatior.somethingWentWrong')}
      </Paragraph>
      <ModalButtons
        showOnlySecondary
        secondaryButtonProps={{
          label: t('twoFa.loginSetup.authenticatior.tryAgain'),
          onClick,
        }}
      />
    </SingleColumnList>
  );
};

QrCodeFailed.propTypes = {
  onClick: PropTypes.func,
};

export default QrCodeFailed;
