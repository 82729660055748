import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Button from '../../../components/Button/Button';
import { mediaBelow, pxToRem } from '../../../assets/styles/helper';
import { variables } from '../../../assets/styles/variables';

export const HavingTroubleBackButtonElement = styled(Button)`
  max-width: ${pxToRem(260)};
  width: 100%;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    max-width: 100%;
  }
`;

const HavingTroubleBackButton = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Button variant="primaryOutlined" size="lg" onClick={onClick}>
      {t('common.back')}
    </Button>
  );
};

HavingTroubleBackButton.propTypes = {
  onClick: PropTypes.func,
};

export default HavingTroubleBackButton;
