import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';
import { pxToRem } from '../../assets/styles/helper';
import { uFlexCenter } from '../../assets/styles/utility';

const NoPermissionContainer = styled.div`
  ${uFlexCenter}
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${themeColors.colorModalBackground};
`;

const NoPermissionContent = styled.div`
  box-shadow: ${variables.shadow.boxShadow};
  border-radius: ${variables.borderRadius.borderRadius};
  background-color: ${themeColors.colorWhite};
  position: relative;
  max-width: ${pxToRem(376)};
  width: 100%;
  padding: ${pxToRem(16)};
`;

const NoPermission = ({ isOpen, children }) => {
  const { t } = useTranslation();

  if (isOpen) {
    return (
      <NoPermissionContainer>
        <NoPermissionContent>
          <p>{t('accountNoPermissions.text')}</p>
        </NoPermissionContent>
      </NoPermissionContainer>
    );
  }

  return children;
};

NoPermission.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
};

export default NoPermission;
