import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CustomPieChart from '../../CustomPieChart/CustomPieChart';
import SummaryLegend from './Views/SummaryLegend';
import { mediaBelow, pxToRem } from '../../../assets/styles/helper';
import { variables } from '../../../assets/styles/variables';

export const SummaryViewWrap = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-gap: ${pxToRem(40)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    grid-template-columns: 1fr;
  }
`;

export const SummaryPieWrap = styled.div`
  min-height: ${pxToRem(180)};
`;

const SummaryView = ({
  holdings,
  totalValuation,
  totalOunces,
  isOuncesView,
}) => {
  const dataKeys = Object.keys(holdings);

  const formatPieChartDataByValuation = () => {
    const data = dataKeys.map((item) => ({
      name: item,
      value: Number(
        holdings[item]
          .reduce((acc, curr) => acc + curr.Valuation, 0)
          .toFixed(2),
      ),
    }));

    const sortedData = data.sort(
      (a, b) => parseFloat(b.value) - parseFloat(a.value),
    );

    if (sortedData.length <= 3) {
      return sortedData;
    }

    const mainChartData = sortedData.slice(0, 3);
    const otherChartData = sortedData.slice(3);

    const otherData = {
      name: 'Other',
      value: Number(
        otherChartData.reduce((acc, curr) => acc + curr.value, 0).toFixed(2),
      ),
    };

    return [...mainChartData, otherData];
  };

  const formatPieChartDataByOunces = () => {
    const data = dataKeys.map((item) => ({
      name: item,
      value: Number(
        holdings[item]
          .reduce((acc, curr) => acc + curr.EffectiveFineWeight, 0)
          .toFixed(2),
      ),
    }));

    const sortedData = data.sort(
      (a, b) => parseFloat(b.value) - parseFloat(a.value),
    );

    if (sortedData.length <= 3) {
      return sortedData;
    }

    const mainChartData = sortedData.slice(0, 3);
    const otherChartData = sortedData.slice(3);

    const otherData = {
      name: 'Other',
      value: Number(
        otherChartData.reduce((acc, curr) => acc + curr.value, 0).toFixed(2),
      ),
    };
    return [...mainChartData, otherData];
  };

  const pieChartData = isOuncesView
    ? formatPieChartDataByOunces()
    : formatPieChartDataByValuation();

  return (
    <SummaryViewWrap>
      <SummaryPieWrap>
        <CustomPieChart
          totalValuation={totalValuation}
          data={pieChartData}
          isOuncesView={isOuncesView}
          totalOunces={totalOunces}
        />
      </SummaryPieWrap>
      <SummaryPieWrap>
        <SummaryLegend
          data={pieChartData}
          totalValuation={totalValuation}
          isOuncesView={isOuncesView}
          totalOunces={totalOunces}
        />
      </SummaryPieWrap>
    </SummaryViewWrap>
  );
};

SummaryView.propTypes = {
  holdings: PropTypes.shape({}),
  totalValuation: PropTypes.number,
  viewType: PropTypes.shape({ value: PropTypes.string }),
  isOuncesView: PropTypes.bool,
  totalOunces: PropTypes.number,
};

export default SummaryView;
