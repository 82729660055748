export const restrictionTypes = {
  WithdrawalsLocked: 'WithdrawalsLocked',
  DeliveriesLocked: 'DeliveriesLocked',
  LogonIsPrevented: 'LogonIsPrevented',
  RestrictBuy: 'RestrictBuy',
  RestrictSell: 'RestrictSell',
  RestrictBuyForDelivery: 'RestrictBuyForDelivery',
  RestrictAchDeposit: 'RestrictAchDeposit',
  RestrictAffilaiteTrades: 'RestrictAffilaiteTrades',
};

export const restrictionEnumTypes = Object.keys(restrictionTypes);
