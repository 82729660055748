import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RefinementList } from 'react-instantsearch-dom';
import styled from 'styled-components';
import useWindowSize from '../../util/hooks/useIsMobileHook';
import { ReactComponent as ChevronDown } from '../../assets/images/svg/chevron-down.svg';
import { buttonizeDiv } from '../../util/buttonizeDiv';
import { filterItems } from '../../util/helpers/refinementListItemsHelper';
import {
  FilterSection,
  FilterTitle,
  FilterTitleWrap,
} from '../Filter/Components/FilterComponent';

const RefinementListComponent = styled(RefinementList)`
  ${({ isClosedList }) =>
    isClosedList &&
    `
    display: none;
  `};
`;

const Refinement = ({
  title,
  attribute,
  showMore,
  limit = 7,
  formatLabel = null,
  products,
  isMyHoldingsChecked,
  myHoldingsCodes,
  dataCy,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const element = document.querySelector('.ais-RefinementList-showMore');
    if (element) {
      element.setAttribute('data-cy', 'button-toggle-expanded-filters');
    }

    // console.log('element', element.outerHTML);

    // element.setAttribute('data-cy', 'button-toggle-expanded-filters');
  }, []);

  const styles = (isExpanded) => {
    let styles = '';

    if (isExpanded) {
      styles += ' expanded';
    }

    return styles;
  };

  const transformItems = (items) =>
    filterItems(
      items,
      attribute,
      products,
      formatLabel,
      limit,
      isMyHoldingsChecked,
      myHoldingsCodes,
    );

  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 1200;
  const openDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <FilterSection isOpenSection={isMobile && isOpen} data-cy={dataCy}>
      <FilterTitleWrap {...buttonizeDiv(openDropdown)}>
        <FilterTitle>{title}</FilterTitle>
        <ChevronDown />
      </FilterTitleWrap>
      <RefinementListComponent
        attribute={attribute}
        showMore={showMore}
        transformItems={transformItems}
        isClosedList={isMobile && !isOpen}
        className={styles(isExpanded)}
        limit={limit}
        translations={{
          showMore: (expanded) => {
            setIsExpanded(expanded);
            return expanded ? 'Show less' : 'Show more';
          },
        }}
      />
    </FilterSection>
  );
};

Refinement.propTypes = {
  title: PropTypes.string,
  attribute: PropTypes.string,
  showMore: PropTypes.bool,
  limit: PropTypes.number,
  formatLabel: PropTypes.func,
  setItems: PropTypes.func,
  defaultRefinement: PropTypes.arrayOf(PropTypes.string),
  products: PropTypes.arrayOf(PropTypes.shape({})),
  isMyHoldingsChecked: PropTypes.bool,
  myHoldingsCodes: PropTypes.shape({}),
  dataCy: PropTypes.string,
};

export default Refinement;
