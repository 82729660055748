import {
  ACCOUNT_UID,
  IS_NAG_DASHBOARD_OPEN,
  LOGIN_EMAIL,
  SESSION_STORAGE_SCOPE,
  USERNAME,
  USERNAMES,
  USER_LOGGED_IN,
  SIGNIFYD_SESSION_ID,
} from '../../constants/sessionStorage';
import { isJson } from './jsonHelper';
import {
  removeFromSessionStorage,
  retrieveFromSessionStorage,
  storeInSessionStorage,
} from './sessionStorageHelper';

export function authScopeGetHelper(key) {
  if (retrieveFromSessionStorage(SESSION_STORAGE_SCOPE)) {
    return retrieveFromSessionStorage(key);
  }

  const value = localStorage.getItem(key);
  const isJsonValue = isJson(value);

  if (isJsonValue) {
    return JSON.parse(value);
  }
  return value;
}

export function authScopeStringGetHelper(key) {
  if (retrieveFromSessionStorage(SESSION_STORAGE_SCOPE)) {
    return retrieveFromSessionStorage(key);
  }

  const value = localStorage.getItem(key);
  const isJsonValue = isJson(value);

  if (isJsonValue) {
    return JSON.parse(value);
  }
  return value;
}

export function authScopeSetHelper(key, value) {
  if (retrieveFromSessionStorage(SESSION_STORAGE_SCOPE)) {
    storeInSessionStorage(key, value);
  } else {
    localStorage.setItem(key, value);
  }
}

export function authScopeRemoveHelper(key) {
  if (retrieveFromSessionStorage(SESSION_STORAGE_SCOPE)) {
    removeFromSessionStorage(key);
  } else {
    localStorage.removeItem(key);
  }
}

export function authScopeClearHelper() {
  // Had to remove sessionStorage.clear() - Because of landing page logic.
  // Private Routes landing page logic implemented in Session Storage.
  removeFromSessionStorage(USERNAMES);
  removeFromSessionStorage(ACCOUNT_UID);
  removeFromSessionStorage(LOGIN_EMAIL);
  removeFromSessionStorage(USER_LOGGED_IN);
  removeFromSessionStorage(USERNAME);
  removeFromSessionStorage(IS_NAG_DASHBOARD_OPEN);
  removeFromSessionStorage(SIGNIFYD_SESSION_ID);

  if (!retrieveFromSessionStorage(SESSION_STORAGE_SCOPE)) {
    localStorage.clear();
  }
}
