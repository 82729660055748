import { getRequest, patchRequest, replaceInUrl } from './index';
import apiEndpoints from './apiEndpoints';

export const getPortfolioValuationRequest = (
  accountUid,
  period,
  numberOfTicks = 500,
) => {
  const url = replaceInUrl(apiEndpoints.portfolio.getPortfolioValuations, {
    accountUid,
  });
  return getRequest(url, { period, numberOfTicks });
};

export const getPortfolioMarketPricesRequest = (metalType, periodType) => {
  const url = replaceInUrl(apiEndpoints.portfolio.getPortfolioMetalPrices, {
    metalType,
    periodType,
  });
  return getRequest(url, { metalType, periodType });
};

export const getPortfolioHoldingsRequest = ({ accountUid }) =>
  getRequest(
    replaceInUrl(apiEndpoints.portfolio.getPortfolioHoldings, { accountUid }),
  );

export const getMyHoldingsCodes = (accountUid) =>
  getRequest(
    replaceInUrl(apiEndpoints.portfolio.getPortfolioProductCodes, {
      accountUid,
    }),
  );

export const getPortfolioBalances = (accountUid) =>
  getRequest(
    replaceInUrl(apiEndpoints.portfolio.getPortfolioBalances, {
      accountUid,
    }),
  );

export const getPortfolioProductBySymbol = (accountUid, symbol, isSegregated) =>
  getRequest(
    replaceInUrl(apiEndpoints.portfolio.getPortfolioProductBySymbol, {
      accountUid,
      symbol,
    }),
    { bars: true, isSegregated },
  );

export const getPortfolioTransactionSingle = (accountUid, transactionUid) =>
  getRequest(
    replaceInUrl(apiEndpoints.portfolio.getPortfolioSingleTransaction, {
      accountUid,
      transactionUid,
    }),
  );

export const getAccountTransactions = (
  accountUid,
  productSymbol,
  content,
  page,
  size,
  isSegregated,
) =>
  getRequest(
    replaceInUrl(apiEndpoints.portfolio.getProductPortoflioTransactions, {
      accountUid,
    }),
    {
      symbol: productSymbol,
      content,
      page,
      size,
      isSegregated,
    },
  );

export const getFinancialPortfolioPendingTransactions = (accountUid) =>
  getRequest(
    replaceInUrl(
      apiEndpoints.portfolio.getFinancialPortfolioPendingTransactions,
      {
        accountUid,
      },
    ),
  );

export const patchFinancialPortfolioPendingTransactions = (
  accountUid,
  depositKey,
  data,
) =>
  patchRequest(
    replaceInUrl(
      apiEndpoints.portfolio.patchFinancialPortfolioPendingTransactions,
      {
        accountUid,
        depositKey,
      },
    ),
    data,
  );
