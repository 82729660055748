import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { usePlaidLink } from 'react-plaid-link';
import { buttonizeDiv } from '../../util/buttonizeDiv';
import { useBrokerName } from '../../util/hooks/userBrokerName';
import TypeCard from '../TypeCard/TypeCard';

const PlaidCard = ({ linkToken, onSuccess, onExit }) => {
  const { t } = useTranslation();
  const brokerName = useBrokerName();
  const config = {
    token: linkToken,
    onSuccess,
    onExit,
  };

  const { open } = usePlaidLink(config);

  return (
    <div {...buttonizeDiv(() => open())}>
      <TypeCard
        title={t('achDeposit.chooseAchAddMethod.plaidCardTitle')}
        description={t('achDeposit.chooseAchAddMethod.plaidCardDescription', {
          brokerName,
        })}
      />
    </div>
  );
};

PlaidCard.propTypes = {
  linkToken: PropTypes.string,
  onSuccess: PropTypes.func,
  onExit: PropTypes.func,
};

export default PlaidCard;
