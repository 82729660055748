export const transactionUnitTypes = {
  Quantity: 'Quantity',
  Cash: 'Cash',
};

export const transactionUnitTypesArray = ['Quantity', 'Cash'];

export const transactionTypes = {
  BuyforStorage: 'BuyforStorage',
  BuyforDeliveryOrder: 'BuyforDeliveryOrder',
  BuyforDeliveryDelivery: 'BuyforDeliveryDelivery',
  SellfromStorage: 'SellfromStorage',
  DeliveryfromStorage: 'DeliveryfromStorage',
  PositionIn: 'PositionIn',
  PositionOut: 'PositionOut',
  StorageFee: 'StorageFee',
  CheckDeposit: 'CheckDeposit',
  WireDeposit: 'WireDeposit',
  AchDeposit: 'AchDeposit',
  AchDepositRejection: 'AchDepositRejection',
  CheckDepositRejection: 'CheckDepositRejection',
  WireWithdrawalRejection: 'WireWithdrawalRejection',
  WireWithdrawalFeeRejection: 'WireWithdrawalFeeRejection',
  GenericDeposit: 'GenericDeposit',
  IraDeposit: 'IraDeposit',
  IraFeeDeposit: 'IraFeeDeposit',
  CheckWithdrawal: 'CheckWithdrawal',
  WireWithdrawal: 'WireWithdrawal',
  WireWithdrawalFee: 'WireWithdrawalFee',
  GenericWithdrawal: 'GenericWithdrawal',
  IraTransfer: 'IraTransfer',
  IraDistribution: 'IraDistribution',
  IraFeeWithdrawal: 'IraFeeWithdrawal',
  AipBuy: 'AipBuy',
  AipSell: 'AipSell',
  AipAllocation: 'AipAllocation',
  InKindTransferOut: 'InKindTransferOut',
  InKindTransferIn: 'InKindTransferIn',
  IraFeeDepositRejection: ' IraFeeDepositRejection',
  StorageFeePayment: 'StorageFeePayment',
  CreditCardChagreback: 'CreditCardCargeBack',
  CreditCardRefund: 'CreditCardRefund',
};

export const transactionTypesArray = Object.keys(transactionTypes);

export const transactionOrderTypes = {
  BuyForStorage: 'BuyForStorage',
  BuyForDeliveryOrder: 'BuyForDeliveryOrder',
  BuyForDeliveryDelivery: 'BuyForDeliveryDelivery',
  SellFromStorage: 'SellFromStorage',
  DeliveryFromStorage: 'DeliveryFromStorage',
  PositionIn: 'PositionIn',
  PositionOut: 'PositionOut',
  StorageFee: 'StorageFee',
  CheckDeposit: 'CheckDeposit',
  WireDeposit: 'WireDeposit',
  AchDeposit: 'AchDeposit',
  AchDepositRejection: 'AchDepositRejection',
  CheckDepositRejection: 'CheckDepositRejection',
  WireWithdrawalRejection: 'WireWithdrawalRejection',
  WireWithdrawalFeeRejection: 'WireWithdrawalFeeRejection',
  GenericDeposit: 'GenericDeposit',
  IraDeposit: 'IraDeposit',
  IraFeeDeposit: 'IraFeeDeposit',
  CheckWithdrawal: 'CheckWithdrawal',
  WireWithdrawal: 'WireWithdrawal',
  WireWithdrawalFee: 'WireWithdrawalFee',
  GenericWithdrawal: 'GenericWithdrawal',
  IraTransfer: 'IraTransfer',
  IraDistribution: 'IraDistribution',
  IraFeeWithdrawal: 'IraFeeWithdrawal',
  AipBuy: 'AipBuy',
  AipSell: 'AipSell',
  AipAllocation: 'AipAllocation',
  InKindTransferOut: 'InKindTransferOut',
  InKindTransferIn: 'InKindTransferIn',
  IraFeeDepositRejection: 'IraFeeDepositRejection',
  StorageFeePayment: 'StorageFeePayment',
  CreditCardChagreback: 'CreditCardCargeBack',
  CreditCardRefund: 'CreditCardRefund',
};

export const TT_BUY_FOR_DELIVERY = 'BuyForDelivery';
export const DELIVER_FROM_STORAGE = 'DeliverFromStorage';
export const FRACTIONAL_CONVERSION = 'FractionalConversion';

export const STORAGE_FEE_PAYMENT = 32;

export const iraFeeWithdrawalTypes = {
  IRAFeeCheckWithdrawal: 'IRAFeeCheckWithdrawal',
  IRAFeeWireWithdrawal: 'IRAFeeWireWithdrawal',
};

export const transactionStatusTypes = {
  PendingExecution: 'PendingExecution',
  NotExecuted: 'NotExecuted',
  PartiallyExecuted: 'PartiallyExecuted',
  Executed: 'Executed',
  PendingFineWeight: 'PendingFineWeight',
  Settled: 'Settled',
  Canceled: 'Canceled',
  PendingShipment: 'PendingShipment',
  PendingPickUp: 'PendingPickUp',
  Shipped: 'Shipped',
  Returned: 'Returned',
  PendingSettlementOfBuy: 'PendingSettlementOfBuy',
  PendingExecutionOfBuy: 'PendingExecutionOfBuy',
  Completed: 'Completed',
  Pending: 'Pending',
  CompletedOnHold: 'CompletedOnHold',
  Rejected: 'Rejected',
  PaidInFull: 'PaidInFull',
  PartiallyPaid: 'PartiallyPaid',
  PendingPayment: 'PendingPayment',
  InGracePeriod: 'InGracePeriod',
  InternalError: 'InternalError',
  InTransit: 'InTransit',
};

export const transactionStatusTypesArray = Object.keys(transactionStatusTypes);

export const tranactionRequiestStatusTypes = {
  Pending: 'Pending',
  Completed: 'Completed',
  Canceled: 'Canceled',
  Failed: 'Failed',
  OnHold: 'OnHold',
  CompletedOnHold: 'CompletedOnHold',
  Returned: 'Returned',
  None: 'None',
  Acknowledged: 'Acknowledged',
  Rejected: 'Rejected',
  PendingBankVerification: 'PendingBankVerification',
};

export const transactionRequiestStatusTypesArray = Object.keys(
  tranactionRequiestStatusTypes,
);

export const transactionFormatTypes = [
  'Address',
  'Currency',
  'Date',
  'DateRange',
  'DateTime',
  'Decimal',
  'Divider',
  'Memo',
  'Product',
  'ProductImage',
  'String',
  'TrackingNumbers',
  'TransactionIcon',
  'TransactionStatus',
  'TransactionSummary',
];

export const transactionCaptionTypes = [
  'BankAccountNickname',
  'ACHTraceNumber',
  'Address',
  'Amount',
  'BalanceDue',
  'BankName',
  'CheckNumber',
  'ClearingDate',
  'DeliveryFee',
  'DueDate',
  'FederalReferenceNumber',
  'Icon',
  'InvoicePeriod',
  'Message',
  'Notes',
  'OrderTotal',
  'PricePerOz',
  'PricePerUnit',
  'Product',
  'Quantity',
  'QuantityExecuted',
  'ReceivedOn',
  'ReferenceNumber',
  'RelatedReferenceNumber',
  'RoutingNumber',
  'SalesTax',
  'Status',
  'Submitter',
  'Summary',
  'TrackingNumbers',
  'TransactedOn',
  'Valuation',
  'SettlementDate',
  'AvailableOn',
  '',
  'CreditCardLast4',
  'TransactionID',
];

export const trackingNumberCourierType = ['Fedex', 'DHL', 'UPS', 'USPS'];

export const transactionTypeByKey = {
  TransactionType: transactionTypesArray,
  UnitType: transactionUnitTypesArray,
  FormatType: transactionFormatTypes,
  CaptionType: transactionCaptionTypes,
};
