import React from 'react';
import PropTypes from 'prop-types';
import BlockSectionLoader from './BlockSectionLoader';

const BlockSectionLoaderWrap = ({ isLoading, children }) => {
  if (isLoading) {
    return <BlockSectionLoader noShadow isLoading={isLoading} />;
  }

  return children;
};

BlockSectionLoaderWrap.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node,
};

export default BlockSectionLoaderWrap;
