import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Highlight } from 'react-instantsearch-dom';
import styled, { css } from 'styled-components';
import config from '../../config';
import Table from '../Table/Table';
import TableBody from '../Table/TableBody';
import TableHead from '../Table/TableHead';
import TableRow from '../Table/TableRow';
import Button from '../Button/Button';
import { ReactComponent as ChevronDown } from '../../assets/images/svg/chevron-down.svg';
import {
  selectProductLocations,
  selectProductLocationsError,
} from '../../store/selectors/productsSelector';
import {
  formatLocationsHeaderData,
  formatLocationsTableRowData,
} from '../../util/locationsTableHelper';
import { formatMoneyNumeral } from '../../util/helpers/numeralHelpers';
import { selectSettings } from '../../store/selectors/settingsSelectors';
import { getCurrentAccount } from '../../store/selectors/accountSelectors';
import { parseEnumType } from '../../util/helpers/enumMappers';
import {
  accountTypeKeys,
  accountTypes,
} from '../../util/enum/api/accountTypes';
import {
  locationsTable,
  locationsTableActions,
  locationsTableBody,
  locationsTableHead,
  locationsTableRow,
} from '../Table/Components/LocationTable';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import themeColors from '../../assets/styles/themeColors';
import {
  uFlexColumn,
  uLineClamp,
  uTransition,
} from '../../assets/styles/utility';
import {
  LOCATION_TYPE_DELIVERY,
  PRODUCT_TYPE_FRACTIONAL,
} from '../../util/enum/api/productTypes';
import { setWizardContent } from '../../util/helpers/wizardHelpers';
import { useSessionStorageState } from '../../util/hooks/useSessionStorageState';
import { IS_BUY_FOR_STORAGE_OPEN } from '../../constants/sessionStorage';
import BasicAccountRestristionsModal from '../Modals/BasicAccountRestrictionsModal/BasicAccountRestrictionsModal';

const ProductCardContainer = styled.div``;

export const ProductCardContent = styled.div`
  display: grid;
  grid-template-columns: ${pxToRem(136)} 1fr;
  grid-template-rows: auto auto auto;
  grid-column-gap: ${pxToRem(24)};
  padding: ${pxToRem(16)} ${pxToRem(24)} ${pxToRem(16)} ${pxToRem(16)};
  border-radius: ${variables.borderRadius.borderRadius};
  background-color: ${themeColors.colorBackgroundPrimary};
  box-shadow: ${variables.shadow.boxShadow};
  position: relative;
  z-index: 2;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    grid-template-columns: ${pxToRemMd(80)} 1fr;
    grid-template-rows: repeat(6, auto);
    padding: ${pxToRemMd(16)} 0;
  }
`;

const ProductCardImageWrap = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 3;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    padding-left: ${pxToRemMd(16)};
  }
`;

const ProductCardImage = styled.img`
  width: ${pxToRem(136)};
  object-fit: contain;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    width: 100%;
  }
`;

const ProductCardHeader = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${pxToRem(24)};

  .ais-Highlight-highlighted {
    background: #fff1d6;
    font-style: normal;
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    grid-column: 2/3;
    grid-row: 1/2;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
    padding-right: ${pxToRemMd(16)};
  }
`;

const ProductCardPriceWrap = styled.div`
  ${uFlexColumn};
  text-align: right;
  letter-spacing: 0;
  flex-shrink: 0;
  margin-left: ${pxToRem(16)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    text-align: left;
    margin-left: 0;
    flex-direction: row;
    align-items: flex-end;
    margin-top: ${pxToRemMd(7)};
  }
`;

const ProductCardPriceLabel = styled.p`
  font-size: ${pxToRem(14)};
  font-weight: 600;
  line-height: 1.75;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(16)};
    line-height: 1.5;
    color: ${themeColors.colorTextSecondary};
    font-weight: 600;
  }
`;

const ProductCardPriceValue = styled.p`
  font-size: ${pxToRem(24)};
  line-height: 1.33;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(16)};
    line-height: 1.5;
    color: ${themeColors.colorTextSecondary};
    font-weight: 600;
    margin-left: ${pxToRemMd(4)};
  }
`;

const ProductCardDescription = styled.div`
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  margin-bottom: ${pxToRem(24)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    ${uLineClamp};
    margin-top: ${pxToRemMd(15)};
    padding-right: ${pxToRemMd(16)};
  }
`;

const ProductCardActions = styled.div`
  grid-column: 1 / 3;
  display: flex;
  justify-content: flex-end;

  > button:first-child {
    margin-right: ${pxToRem(24)};
  }

  > button {
    min-width: ${pxToRem(214)};
    display: flex;
    align-items: center;

    ${({ isOpenDropdown }) =>
      isOpenDropdown && `svg {transform: rotate(180deg);}`};

    svg {
      ${uTransition};
      color: inherit;
      width: ${pxToRem(16)};
      height: ${pxToRem(16)};
      margin-right: ${pxToRem(6)};
    }
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    flex-direction: column-reverse;

    > button {
      &:first-child {
        margin-top: ${pxToRemMd(8)};
        margin-right: ${pxToRemMd(16)};
      }

      &:last-child {
        margin-top: ${pxToRemMd(8)};
      }
    }

    > button {
      margin-right: ${pxToRemMd(16)};
      margin-left: ${pxToRemMd(16)};
    }
  }
`;

const isOpenDropdown = css`
  svg {
    transform: rotate(180deg);
  }
`;

export const ProductCardDropdown = styled.div`
  overflow: hidden;
  max-height: 0;

  ${({ isOpenDropdown }) => isOpenDropdown && `max-height: 100%;`};
`;

export default function ProductCard({
  product,
  isTestProduct,
  isTradingEnabled,
  collapsable,
  priceTiersClickHandler,
  selectProduct,
  side,
  myHoldingsCodes,
  isMyHoldingsChecked,
  allowAccountChangesPermission,
  displayIRAOptionsPermission,
  displayBasicAccountOptionsPermission,
}) {
  const productLocations = useSelector(
    selectProductLocations(product.ProductCode),
  );

  const settings = useSelector(selectSettings);
  const account = useSelector(getCurrentAccount);
  const isTestAccount = account.IsTestAccount;
  const { RootUrl } = useSelector(selectSettings);
  const productLocationsError = useSelector(selectProductLocationsError);
  const imageSource = config.algoliaSearch.imageCode
    .replace('{product_code}', product.ProductCode)
    .replace('{base_url}', RootUrl);
  const isUtmaAccount =
    parseEnumType(accountTypeKeys, account.AccountType) === accountTypes.UTMA;
  const filterOutDelivery = displayIRAOptionsPermission || isUtmaAccount;
  const [isModalOpen, setIsModalOpen] = useSessionStorageState(
    IS_BUY_FOR_STORAGE_OPEN,
    false,
  );
  const currentAccount = useSelector(getCurrentAccount);
  const isErrAccount = currentAccount?.AccountStatus === 7;
  const isIRAAccount =
    parseEnumType(accountTypeKeys, account.AccountType) === accountTypes.IRA;
  const isBasicAccount =
    parseEnumType(accountTypeKeys, account.AccountType) === accountTypes.Basic;
  const isPendingEquityAccount =
    parseEnumType(accountTypeKeys, account.AccountType) === accountTypes.IRA &&
    displayBasicAccountOptionsPermission;

  const hasDisabledAction =
    (!allowAccountChangesPermission && !displayBasicAccountOptionsPermission) ||
    (!productLocations.find((location) => location.LocationType === 12) &&
      displayBasicAccountOptionsPermission) ||
    (side === 'sell' && displayBasicAccountOptionsPermission);

  const segregatedCaption = settings?.SegregatedCaption;

  const rowData = formatLocationsTableRowData(
    productLocations,
    priceTiersClickHandler,
    product,
    selectProduct,
    side,
    myHoldingsCodes,
    isMyHoldingsChecked,
    allowAccountChangesPermission,
    isTestAccount,
    isTestProduct,
    isTradingEnabled,
    filterOutDelivery,
    displayBasicAccountOptionsPermission,
    setIsModalOpen,
    isBasicAccount,
    isErrAccount,
    isIRAAccount,
    segregatedCaption,
  );

  const showHeaderPerOz = productLocations?.some((item) => item.ShowPerOz);
  const showHeaderPerUnit =
    product.ProductType !== PRODUCT_TYPE_FRACTIONAL ||
    (product.ProductType === PRODUCT_TYPE_FRACTIONAL &&
      productLocations.some((item) => item.LocationType === 13));
  const headerData = formatLocationsHeaderData(
    showHeaderPerOz,
    showHeaderPerUnit,
  );

  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const selectBuyForDeliveryProduct = () => {
    setWizardContent('ShortFlow', true);
    const location = productLocations.filter(
      (item) => item.LocationType === LOCATION_TYPE_DELIVERY,
    );
    selectProduct(product, location[0]);
  };

  return (
    <ProductCardContainer>
      <ProductCardContent>
        <ProductCardImageWrap>
          <ProductCardImage src={imageSource} alt="Product" />
        </ProductCardImageWrap>
        <ProductCardHeader>
          <h2>
            <Highlight attribute="Caption" hit={product} />
          </h2>
          <ProductCardPriceWrap>
            {side === 'buy' && (
              <ProductCardPriceLabel>
                {t('product.price')}
              </ProductCardPriceLabel>
            )}
            <ProductCardPriceValue>
              {formatMoneyNumeral(product.ProductPrice)}
            </ProductCardPriceValue>
          </ProductCardPriceWrap>
        </ProductCardHeader>
        <ProductCardDescription>
          <Highlight attribute="Description" hit={product} />
        </ProductCardDescription>
        <ProductCardActions>
          {!collapsable ? null : (
            <>
              <Button
                onClick={() => setIsOpen(!isOpen)}
                variant="primaryOutlined"
                css={isOpen && isOpenDropdown}
                data-cy="button-pricing-details"
              >
                <ChevronDown />
                {t('product.detailedPricingBtn')}
              </Button>
              <ProductCardDropdown
                isOpenDropdown={isOpen}
                data-cy="container-product-vault-mobile"
              >
                <Table css={locationsTable} isLocationTableMobile>
                  <TableHead css={locationsTableHead} data={headerData} />
                  <TableBody css={locationsTableBody}>
                    {!productLocationsError ? (
                      <TableRow
                        css={locationsTableRow}
                        actionButtonsClassName={locationsTableActions}
                        rowData={rowData}
                        mobileDropdown
                        data-cy="list-item-product-vault"
                      />
                    ) : (
                      <span>
                        {t('product.locationsError', {
                          error: productLocationsError,
                        })}
                      </span>
                    )}
                  </TableBody>
                </Table>
              </ProductCardDropdown>
              <Button
                disabled={
                  !allowAccountChangesPermission ||
                  (isTestAccount && !isTestProduct) ||
                  !isTradingEnabled ||
                  isPendingEquityAccount ||
                  hasDisabledAction ||
                  isErrAccount
                }
                variant="primary"
                onClick={() =>
                  displayBasicAccountOptionsPermission
                    ? selectBuyForDeliveryProduct()
                    : selectProduct(product)
                }
                data-cy="button-buy-product"
              >
                {t('product.buyBtn')}
              </Button>
            </>
          )}
          {!collapsable && (
            <ProductCardDropdown
              isOpenDropdown={isOpen}
              data-cy="container-product-vault-mobile"
            >
              <Table css={locationsTable} isLocationTableMobile>
                <TableHead css={locationsTableHead} data={headerData} />
                <TableBody css={locationsTableBody}>
                  {!productLocationsError ? (
                    <TableRow
                      css={locationsTableRow}
                      actionButtonsClassName={locationsTableActions}
                      rowData={rowData}
                      mobileDropdown
                      data-cy="list-item-product-vault"
                    />
                  ) : (
                    <span>
                      {t('product.locationsError', {
                        error: productLocationsError,
                      })}
                    </span>
                  )}
                </TableBody>
              </Table>
            </ProductCardDropdown>
          )}
        </ProductCardActions>
      </ProductCardContent>
      <ProductCardDropdown
        isOpenDropdown={isOpen}
        data-cy="container-product-vault"
      >
        <Table css={locationsTable}>
          <TableHead css={locationsTableHead} data={headerData} />
          <TableBody css={locationsTableBody}>
            {!productLocationsError ? (
              <TableRow
                css={locationsTableRow}
                actionButtonsClassName={locationsTableActions}
                rowData={rowData}
                data-cy="list-item-product-vault"
              />
            ) : (
              <span>
                {t('product.locationsError', { error: productLocationsError })}
              </span>
            )}
          </TableBody>
        </Table>
      </ProductCardDropdown>
      {isBasicAccount && (
        <BasicAccountRestristionsModal
          isOpen={isModalOpen}
          close={() => setIsModalOpen(false)}
          title={
            side === 'buy'
              ? t('basicAccountRestrictions.buy.title')
              : t('basicAccountRestrictions.sell.title')
          }
          description={
            side === 'buy'
              ? t('basicAccountRestrictions.buy.description')
              : t('basicAccountRestrictions.sell.description')
          }
        />
      )}
    </ProductCardContainer>
  );
}

ProductCard.propTypes = {
  product: PropTypes.shape({
    ProductCode: PropTypes.string,
    ProductPrice: PropTypes.number,
    Description: PropTypes.string,
    Caption: PropTypes.string,
    ProductType: PropTypes.string,
  }).isRequired,
  isTestProduct: PropTypes.bool,
  isTradingEnabled: PropTypes.bool,
  collapsable: PropTypes.bool,
  priceTiersClickHandler: PropTypes.func,
  selectProduct: PropTypes.func,
  side: PropTypes.string,
  myHoldingsCodes: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  isMyHoldingsChecked: PropTypes.bool,
  allowAccountChangesPermission: PropTypes.bool,
  displayIRAOptionsPermission: PropTypes.bool,
  displayBasicAccountOptionsPermission: PropTypes.bool,
};
