import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { variables } from '../../assets/styles/variables';
import { mediaBelow, pxToRem } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { uFlexCenter } from '../../assets/styles/utility';

const ProgressStepWrap = styled.div`
  position: relative;
`;

const ProgressStepIndex = styled.div`
  ${uFlexCenter}
  width: ${pxToRem(40)};
  height: ${pxToRem(40)};
  box-shadow: ${variables.shadow.boxShadow};
  border-radius: 100%;
  font-size: ${pxToRem(24)};
  line-height: 1.33;
  font-weight: 600;
  letter-spacing: 0;
  background: ${({ isFilled }) =>
    isFilled
      ? `linear-gradient(to right, ${themeColors.colorLinearGradientStart}, ${themeColors.colorLinearGradientEnd})`
      : themeColors.colorWhite};
  color: ${themeColors.colorWhite};
`;

const ProgressStepLabel = styled.p`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  margin-top: ${pxToRem(15)};
  color: ${({ isFilled }) =>
    isFilled ? themeColors.colorPrimary : themeColors.colorTextSecondary};
  font-size: ${pxToRem(20)};
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.4;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    display: none;
  }
`;

const ProgressBarStep = ({ currentStep, step, stepNumber }) => {
  const { label, percentage } = step;

  const fillStep = () => {
    if (percentage <= currentStep) {
      return true;
    }
    return false;
  };

  return (
    <ProgressStepWrap>
      <ProgressStepIndex isFilled={fillStep(fillStep)}>
        {stepNumber}
      </ProgressStepIndex>
      <ProgressStepLabel isFilled={fillStep(fillStep)}>
        {label}
      </ProgressStepLabel>
    </ProgressStepWrap>
  );
};

ProgressBarStep.propTypes = {
  stepNumber: PropTypes.number,
  step: PropTypes.shape({
    label: PropTypes.string,
    percentage: PropTypes.number,
  }),
  currentStep: PropTypes.number,
};

export default ProgressBarStep;
