import React from 'react';
import PropTypes from 'prop-types';
import Note from '../Notes/Note';
import Label from '../Notes/Label';
import Radio from './Radio';

const RadioBox = (props) => {
  const { label, cta, onCtaClick, disabled } = props;

  return (
    <Radio {...props} variant="RadioBox">
      <Label text={label} disabled={disabled} />
      {(onCtaClick || cta) && (
        <Note
          primaryColor={!!onCtaClick}
          noUnderline
          bold
          onClick={onCtaClick}
          text={cta}
          disabled={disabled}
        />
      )}
    </Radio>
  );
};

RadioBox.propTypes = {
  label: PropTypes.string,
  cta: PropTypes.string,
  onCtaClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default RadioBox;
