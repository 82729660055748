import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../../Modal/Modal';
import StepByStep from '../../../StepByStep';
import StatusModal from '../../StatusModal';
import { fetchAccountAddresses } from '../../../../store/actions/account/accountActions';
import { selectDeliverFromStorageVerifyData } from '../../../../store/selectors/productsSelector';
import AddressAndQuantityForm from './AddressAndQuantityForm';
import ConfirmRequestDelivery from './ConfirmRequestDelivery';
import { deliverFromStorageSubmit } from '../../../../store/actions/products/productActions';
import {
  closeWizardContent,
  setWizardContent,
} from '../../../../util/helpers/wizardHelpers';
import useGtmHook from '../../../../util/hooks/useGtmHook';
import {
  DELIVERY_ADDRESS,
  DELIVERY_QUANTITY,
  MODAL_EXTENDED,
  REQUEST_DELIVERY_WIZARD,
  STATUS_MESSAGE,
  STATUS_MODAL_OPEN,
} from '../../../../constants/sessionStorage';
// import { accountTypeKeys } from '../../../../util/enum/api/accountTypes';
// import { parseEnumType } from '../../../../util/helpers/enumMappers';
import { useSessionStorageState } from '../../../../util/hooks/useSessionStorageState';
import { isActionRestrictedHelper } from '../../../../util/helpers/restrictionHelper';
import { restrictionTypes } from '../../../../util/enum/api/restrictionTypes';
import Restriction from '../../RestrictionModal/Restriction';
import useRestrictionHook from '../../../../util/hooks/useRestrictionHook';
import UnverifiedBankAccountList from '../../../UnverifiedBankAccountList/UnverifiedBankAccountList';
import { onConfirmDeliveryGtmHelper } from '../../../../util/helpers/gtmHelper';
import { getCurrentAccount } from '../../../../store/selectors/accountSelectors';

const RequestDelivery = ({
  isOpen,
  onClose,
  product,
  funds,
  accountUid,
  // accountType,
  resetAfterDelivery,
  unverifiedBankAccounts,
  isInstaVaultProduct,
  isSegregated,
  isFromFractionalLocation,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    // gtmEvent,
    ecommerceGtmEvent,
  } = useGtmHook();
  const verifyData = useSelector(selectDeliverFromStorageVerifyData);
  const account = useSelector(getCurrentAccount);
  const restrictions = useRestrictionHook();
  const [statusModalOpen, setStatusModalOpen] = useSessionStorageState(
    STATUS_MODAL_OPEN,
    false,
  );
  const [errorOccurred, setErrorOccurred] = useSessionStorageState(
    'ErrorOccured',
    false,
  );
  const [statusMessage, setStatusMessage] = useSessionStorageState(
    STATUS_MESSAGE,
    '',
  );
  const [modalExtended, setModalExtended] = useSessionStorageState(
    MODAL_EXTENDED,
    false,
  );
  const [deliveryAddress, setDeliveryAddress] = useSessionStorageState(
    DELIVERY_ADDRESS,
    '',
  );
  const [unitsToDeliver, setUnitsToDeliver] = useSessionStorageState(
    DELIVERY_QUANTITY,
    0,
  );
  const [confirmLoading, setConfirmLoading] = useState(false);

  const getDeliveryTotal = useMemo(
    () =>
      verifyData.DeliveryCosts +
      verifyData.SalesTax +
      verifyData.UnassessedStorageFees,
    [verifyData],
  );

  const handleApiResponse = (errorOccurred, message) => {
    setErrorOccurred(errorOccurred);
    setStatusMessage(message);
    onClose();
    closeWizardContent();
    setStatusModalOpen(true);
    setWizardContent('ErrorOccured', errorOccurred);
    setWizardContent(STATUS_MESSAGE, message);
    setWizardContent(STATUS_MODAL_OPEN, true);
    resetAfterDelivery();

    if (!errorOccurred) {
      // gtmEvent(DELIVERY_EXECUTED, {
      //   AccountType: parseEnumType(accountTypeKeys, accountType),
      //   TerminalPointType: 'Address',
      //   DirectionType: 'In',
      //   Symbol: product.SymbolCode,
      //   OrderSideType: 'Buy',
      //   QuantityExecuted: unitsToDeliver,
      //   UnassessedStorageFees: verifyData.UnassessedStorageFees,
      //   ShippingFee: verifyData.DeliveryCosts,
      //   SalesTax: verifyData.SalesTax,
      //   OrderTotal: getDeliveryTotal,
      // });
      onConfirmDeliveryGtmHelper({
        ecommerceGtmEvent,
        product,
        getDeliveryTotal,
        account,
        quantity: unitsToDeliver,
      });
    }
  };

  const handleRequestDeliveryConfirm = (data) =>
    dispatch(
      deliverFromStorageSubmit({
        accountUid,
        dfsData: {
          ...data,
          IsSegregated: isSegregated,
        },
        handleApiResponse,
        setConfirmLoading,
        isInstaVaultProduct,
      }),
    );

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchAccountAddresses({ accountUid }));
    }
  }, [dispatch, accountUid, isOpen]);

  if (unverifiedBankAccounts?.length) {
    return (
      <Modal
        size="sm"
        title={t('product.requestDelivery.title')}
        isOpen={isOpen}
        close={onClose}
      >
        <UnverifiedBankAccountList bankAccounts={unverifiedBankAccounts} />
      </Modal>
    );
  }

  if (
    isActionRestrictedHelper(restrictions, [
      restrictionTypes.LogonIsPrevented,
      restrictionTypes.DeliveriesLocked,
    ])
  ) {
    return (
      <Modal
        size="sm"
        title={t('product.requestDelivery.title')}
        isOpen={isOpen}
        close={onClose}
      >
        <Restriction restrictions={restrictions} />
      </Modal>
    );
  }

  return (
    <>
      <Modal
        size="sm"
        title={t('product.requestDelivery.title')}
        isOpen={isOpen}
        close={onClose}
      >
        <StepByStep
          sessionScopeStep={REQUEST_DELIVERY_WIZARD}
          onConfirm={handleRequestDeliveryConfirm}
        >
          {({ goStepBack, goStepForward, onConfirm }) => [
            <AddressAndQuantityForm
              key="address-and-quantity-form"
              product={product}
              modalExtended={modalExtended}
              getDeliveryTotal={getDeliveryTotal}
              setModalExtended={setModalExtended}
              goStepForward={goStepForward}
              accountUid={accountUid}
              funds={funds}
              verifyData={verifyData}
              onClose={onClose}
              setDeliveryAddress={setDeliveryAddress}
              setUnitsToDeliver={setUnitsToDeliver}
              isInstaVaultProduct={isInstaVaultProduct}
              isSegregated={isSegregated}
              isFromFractionalLocation={isFromFractionalLocation}
              account={account}
            />,
            <ConfirmRequestDelivery
              key="confirm-request-delivery"
              goStepBack={goStepBack}
              onConfirm={onConfirm}
              product={product}
              verifyData={verifyData}
              unitsToDeliver={unitsToDeliver}
              deliveryAddress={deliveryAddress}
              accountUid={accountUid}
              isLoading={confirmLoading}
              onClose={onClose}
              funds={funds}
              isInstaVaultProduct={isInstaVaultProduct}
            />,
          ]}
        </StepByStep>
      </Modal>
      <StatusModal
        isOpen={statusModalOpen}
        onButtonClick={() => {
          closeWizardContent();
          setStatusModalOpen(false);
        }}
        close={() => {
          closeWizardContent();
          setStatusModalOpen(false);
        }}
        modalTitle={t('product.requestDelivery.title')}
        hasError={errorOccurred}
        text={statusMessage}
      />
    </>
  );
};

RequestDelivery.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  product: PropTypes.shape({
    ProductCaption: PropTypes.string,
    UnitsAvailableForDelivery: PropTypes.number,
    SymbolCode: PropTypes.string,
  }),
  funds: PropTypes.number,
  accountUid: PropTypes.string,
  // accountType: PropTypes.number,
  resetAfterDelivery: PropTypes.func,
  isInstaVaultProduct: PropTypes.bool,
  isSegregated: PropTypes.bool,
  isFromFractionalLocation: PropTypes.bool,
  unverifiedBankAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      BankAccountUid: PropTypes.string,
      BankName: PropTypes.string,
      AccountMask: PropTypes.string,
      IsVerified: PropTypes.bool,
    }),
  ),
};

export default RequestDelivery;
