import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import SectionLoader from '../../Loader/SectionLoader';
import ModalBody from '../../Modal/ModalBody';
import ModalButtons from '../../Modal/ModalButtons';
import { fetchCreditCards } from '../../../store/actions/creditCard/creditCardActions';
import { getCurrentAccount } from '../../../store/selectors/accountSelectors';
import Paragraph from '../../Paragraph/Paragraph';
import { selectIsLoadingByActionType } from '../../../store/selectors/loadingSelectors';
import { CREDIT_CARD_LOADING } from '../../../store/actions/creditCard/creditCardActionConstants';
import {
  changeAutoPaySettings,
  fetchStorageFeeAmount,
  submitCardPayStorageFee,
} from '../../../store/actions/storageFee/storageFeeActions';
import { selectCreditCards } from '../../../store/selectors/creditCardSelectors';
import { AddOrChooseCreditCard } from '../../CreditCard.js/AddOrChooseCreditCard';
import { creditCardPaymentValidation } from '../../../validation/creditCardPaymentValidation';
import { selectSettings } from '../../../store/selectors/settingsSelectors';
import { pxToRem } from '../../../assets/styles/helper';
import AutoPayCheckbox from './AutoPayCheckbox';

export const ChargedAmountSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
`;

export const CheckboxWrap = styled.div`
  margin-top: ${pxToRem(24)};
`;

export const CheckboxText = styled.span`
  font-size: ${pxToRem(16)};
`;

const PayStorageFeeByCard = ({
  handleNext,
  setError,
  handleClose,
  storageFeeAmount,
  setStorageFeeAmount,
  setSelectedCreditCard,
  isAutoPayOn,
  setIsAutoPayOn,
  handleRefreshTransactions,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const account = useSelector(getCurrentAccount);
  const creditCards = useSelector(selectCreditCards)?.sort(
    (x, y) => Number(x.IsExpired) - Number(y.IsExpired),
  );
  const selectedCardForAutoPay = creditCards.find(
    (creditCard) => creditCard.IsDefaultForStorage,
  );
  const defaultCreditCard = selectedCardForAutoPay || creditCards[0];
  const settings = useSelector(selectSettings);
  const [isLoading, setIsLoading] = useState();
  const isLoadingCreditCards = useSelector(
    selectIsLoadingByActionType(CREDIT_CARD_LOADING),
  );

  useEffect(() => {
    setIsAutoPayOn(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormSubmit = ({ creditCard }) => {
    const onSuccess = () => {
      const onSuccessAutoPay = () => {
        dispatch(fetchCreditCards({ accountUid: account.AccountUid }));
        dispatch(
          fetchStorageFeeAmount({
            accountUid: account.AccountUid,
            setStorageFeeAmount,
          }),
        );
        handleRefreshTransactions();
      };
      dispatch(
        changeAutoPaySettings({
          accountUid: account.AccountUid,
          setting: {
            Autopay: isAutoPayOn,
            CreditCardUid: creditCard ?? null,
          },
          onSuccess: onSuccessAutoPay,
        }),
      );
    };
    const onError = () => {
      dispatch(fetchCreditCards({ accountUid: account.AccountUid }));
      dispatch(fetchStorageFeeAmount({ accountUid: account.AccountUid }));
    };
    dispatch(
      submitCardPayStorageFee({
        accountUid: account.AccountUid,
        storageFeeCreditCardPayment: {
          CreditCardUid: creditCard,
          Amount: storageFeeAmount,
        },
        setIsLoading,
        onSuccess,
        onError,
        handleNext,
        setError,
      }),
    );
  };

  return (
    <SectionLoader isLoading={isLoading || isLoadingCreditCards}>
      <ModalBody>
        <Formik
          initialValues={{
            creditCard: defaultCreditCard
              ? defaultCreditCard.CreditCardUid
              : null,
            autoPay: true,
          }}
          onSubmit={onFormSubmit}
          validationSchema={creditCardPaymentValidation}
        >
          {({ values }) => (
            <Form>
              <AddOrChooseCreditCard
                creditCards={creditCards}
                values={values}
                isReachedTransactionLimit={
                  storageFeeAmount > settings?.CreditCardTransactionLimit
                }
                setSelectedCreditCard={setSelectedCreditCard}
              />
              <AutoPayCheckbox
                isAutoPayOn={isAutoPayOn}
                setIsAutoPayOn={setIsAutoPayOn}
              />
              <ChargedAmountSectionWrapper>
                <Paragraph bold>
                  {t(
                    'storageFee.payStorageFeeByCardModal.amountToBeChargedToday',
                  )}
                </Paragraph>
                <Paragraph>${storageFeeAmount}</Paragraph>
              </ChargedAmountSectionWrapper>

              <ModalButtons
                marginTop
                isHorizontal
                secondaryButtonProps={{
                  label: t('common.cancel'),
                  onClick: handleClose,
                }}
                primaryButtonProps={{
                  disabled: !values.creditCard,
                  label: t('common.submit'),
                  type: 'submit',
                }}
              />
            </Form>
          )}
        </Formik>
      </ModalBody>
    </SectionLoader>
  );
};

PayStorageFeeByCard.propTypes = {
  setError: PropTypes.func,
  handleClose: PropTypes.func,
  handleNext: PropTypes.func,
  storageFeeAmount: PropTypes.number,
  setStorageFeeAmount: PropTypes.func,
  setSelectedCreditCard: PropTypes.func,
  isAutoPayOn: PropTypes.bool,
  setIsAutoPayOn: PropTypes.func,
  handleRefreshTransactions: PropTypes.func,
};

export default PayStorageFeeByCard;
