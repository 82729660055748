import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { pxToRem } from '../../assets/styles/helper';

const AnchorElement = styled.a`
  font-weight: ${({ bold }) => (bold ? 600 : 'normal')};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${pxToRem(marginBottom)}` : 0};
  ${(props) => props.css};
`;

const Anchor = ({ value, type, text, marginBottom, bold, ...props }) => {
  const checkWebsiteUrl = () => {
    if (value) {
      if (value.includes('https://')) {
        return value;
      }

      if (value.includes('http://')) {
        return value;
      }

      return `https://${value}`;
    }
  };

  const prefix = () => {
    switch (type) {
      case 'telephone':
        return `tel:${value}`;

      case 'email':
        return `mailto:${value}`;

      case 'website':
        return checkWebsiteUrl(value);

      default:
        break;
    }
  };

  return (
    <AnchorElement
      target="_blank"
      rel="noopener noreferrer"
      href={prefix()}
      marginBottom={marginBottom}
      bold={bold}
      {...props}
    >
      {text || value}
    </AnchorElement>
  );
};

Anchor.propTypes = {
  value: PropTypes.string,
  type: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.shape({}),
  ]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  marginBottom: PropTypes.number,
  bold: PropTypes.bool,
};

export default Anchor;
