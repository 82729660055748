import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';
import NagDashboardItemStatus from './NagDashboardItemStatus';

export const NagDashboardUploadDocumentsContent = styled.p`
  margin-right: auto;
  text-align: left;
  font-weight: 600;
  width: 100%;
  //   padding: ${pxToRem(24)};
`;

export const NagDashboardUploadDocumentsContainer = styled.p`
  border-radius: ${variables.borderRadius.borderRadius};
  background-color: ${themeColors.colorWhite};
  cursor: pointer;
  margin-bottom: ${pxToRem(32)};
  width: 100%;

  &:hover {
    background-color: ${themeColors.colorPendingBannerBackground};
    box-shadow: ${variables.shadow.boxShadow};
    z-index: ${variables.zIndex.indexXxs};
  }
  align-items: center;
  line-height: 1.35;
  letter-spacing: 0;
  cursor: pointer;
  text-decoration: none;
  display: grid;
  grid-template-columns: auto;
  grid-gap: ${pxToRem(8)};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    display: grid;
    flex-direction: row;
    padding: ${pxToRemMd(10)} ${pxToRemMd(16)};
    font-size: ${pxToRemMd(14)};
    line-height: 1.7;
  }

  ${mediaBelow(variables.breakpoints.bpXs)} {
    grid-template-columns: auto;
  }
`;

export const NagDashboardUploadDocumentsTitle = styled.div`
  font-weight: 700;
  color: ${themeColors.colorTextPrimary};
  min-width: 0;
  ${mediaBelow(variables.breakpoints.bpXl)} {
    margin-bottom: ${pxToRem(8)};
  }
`;
export const NagDashboardUploadDocumentsDescription = styled.div`
  font-size: ${pxToRem(14)};
  font-weight: 400;
  color: ${themeColors.colorTextSecondary};
  margin-bottom: ${pxToRem(8)};
  ${mediaBelow(variables.breakpoints.bpXl)} {
    margin-bottom: ${pxToRem(8)};
  }
`;
export const NagDashboardTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  grid-template-columns: auto;
  justify-content: space-between;
  margin-bottom: ${pxToRem(8)};
  width: 100%;
`;

const NagDashboardUploadDocumentsList = ({
  title,
  description,
  buttonText,
  status,
  action,
}) => {
  const isDisplayInline = status === 'PendingReview' || status === 'Faild';

  return (
    <>
      {isDisplayInline ? (
        <NagDashboardUploadDocumentsContainer>
          <NagDashboardUploadDocumentsContent>
            <NagDashboardTitleWrapper>
              <NagDashboardUploadDocumentsTitle>
                {title}
              </NagDashboardUploadDocumentsTitle>
              <NagDashboardItemStatus
                status={status}
                buttonText={buttonText}
                action={action}
              />
            </NagDashboardTitleWrapper>
            <NagDashboardUploadDocumentsDescription>
              {description}
            </NagDashboardUploadDocumentsDescription>
          </NagDashboardUploadDocumentsContent>
        </NagDashboardUploadDocumentsContainer>
      ) : (
        <NagDashboardUploadDocumentsContainer>
          <NagDashboardUploadDocumentsContent>
            <NagDashboardUploadDocumentsTitle>
              {title}
            </NagDashboardUploadDocumentsTitle>
            <NagDashboardUploadDocumentsDescription>
              {description}
            </NagDashboardUploadDocumentsDescription>
          </NagDashboardUploadDocumentsContent>
          <NagDashboardItemStatus
            status={status}
            buttonText={buttonText}
            action={action}
          />
        </NagDashboardUploadDocumentsContainer>
      )}
    </>
  );
};

NagDashboardUploadDocumentsList.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string,
  status: PropTypes.string,
  description: PropTypes.string,
  action: PropTypes.func,
};

export default NagDashboardUploadDocumentsList;
