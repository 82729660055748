import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import {
  INVITE_USER_TWO_FA_VERIFY_CHOOSE_METHOD,
  INVITE_USER_TWO_FA_VERIFY_CODE,
  TWO_FA_VERIFY_CODE,
  VERIFY_CHOOSE_METHOD_PAGE,
} from '../../../constants/pages';
import TwoFaPhoneSelectRecieveType from '../Components/TwoFaPhoneSelectRecieveType';
import { selectUserPhoneNumbers } from '../../../store/selectors/userSelectors';
import { selectedPhoneNumberValidatorAndFormatter } from '../../../util/helpers/phoneNumberHelpers';
import { fetchUserPhoneNumbers } from '../../../store/actions/user/userActions';
import { selectIsLoadingByActionTypes } from '../../../store/selectors/loadingSelectors';
import SectionLoader from '../../../components/Loader/SectionLoader';
import {
  DELETE_USER_PHONE_NUMBER_LOADING,
  USER_PHONE_NUMBERS_LOADING,
} from '../../../store/actions/user/userActionConstants';
import { TWO_FA_CODE_LOADING } from '../../../store/actions/twoFA/twoFAActionConstants';
import useGetCountries from '../../../util/hooks/useGetCountries';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import { twoFaMessageType } from '../../../util/enum/api/twoFATypes';
import { selectPortalGatewayISOCodesListToIgnoreAreaCode } from '../../../store/selectors/settingsSelectors';
import { twoFaPhoneValidationSchema } from '../../../validation/twoFaPhoneValidationSchema';
import Paragraph from '../../../components/Paragraph/Paragraph';
import SingleColumnList from '../../../components/TwoColumnList/SingleColumnList';
import TypeList from '../../../components/TypeList/TypeList';
import AuthCard from '../../../components/Auth/AuthCard';
import Auth from '../../../components/Auth/Auth';
import AuthButtons from '../../../components/Auth/AuthButtons';
import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import themeColors from '../../../assets/styles/themeColors';
import { variables } from '../../../assets/styles/variables';
import { uFlexCenter } from '../../../assets/styles/utility';
import { ReactComponent as Caret } from '../../../assets/images/svg/down.svg';
import { ReactComponent as Plus } from '../../../assets/images/svg/plus.svg';
import Button from '../../../components/Button/Button';
import TwoFaPhoneAddPhoneModal from '../../../components/TwoFactorAuthentication/PhoneNumber/TwoFaPhoneAddPhoneModal';
import { ReactComponent as Warning } from '../../../assets/images/svg/alert-triangle.svg';
import TwoFaPhoneListFull from '../Components/TwoFaPhoneListFull';

const AddPhoneNumberSection = styled.div`
  border: 1px solid ${themeColors.colorBorderPrimary};
  background-color: ${themeColors.colorBackgroundPrimary};
  box-shadow: ${variables.shadow.boxShadow};
  margin-bottom: ${pxToRem(16.5)};
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    `opacity: 0.5;
      pointer-events: none;
      `}
`;

const AddPhoneNumberElement = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0;
  box-shadow: none;
  padding: ${pxToRem(12.5)} ${pxToRem(16.5)};
  text-transform: capitalize;
  justify-content: flex-start;
  font-weight: 600;
  color: ${themeColors.colorTextPrimary};
  font-size: ${pxToRem(16)};
  width: 100%;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(16)};
  }
`;

const AddPhoneNumberIconAndTextWrapper = styled.div`
  display: flex;
  flex: 1;
`;

const AddPhoneNumberIcon = styled.div`
  ${uFlexCenter};
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};
  border-radius: 4px;
  background-color: ${themeColors.colorPrimary};
  box-shadow: ${variables.shadow.boxShadow};
  margin-right: ${pxToRem(10)};
  flex-shrink: 0;
  padding: ${pxToRem(3)};
  color: ${themeColors.colorWhite};

  svg {
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};
    color: ${themeColors.colorWhite};
  }
`;

const AddPhoneNumberButtonText = styled.span``;

const BalanceCardIcon = styled.div`
  display: flex;
  justify-self: flex-end;
  flex-shrink: 0;
  transform: rotate(-90deg);
  color: ${themeColors.colorLabel};
`;

const AddPhoneNumberNote = styled.p`
  padding: 0px ${pxToRem(12.5)} ${pxToRem(12.5)} ${pxToRem(16.5)};
`;

const AddPhoneNumberWarningNote = styled.p`
  color: ${themeColors.colorError};
  display: flex;
  align-items: center;
  padding: 0px ${pxToRem(12.5)} ${pxToRem(12.5)} ${pxToRem(16.5)};

  svg {
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};
    color: ${themeColors.colorError};
    margin-right: ${pxToRem(10)};
  }
`;

const TwoFAPhonePage = ({
  goStepForward,
  goStepBack,
  setTwoFaValues,
  twoFaValue,
  getTwoFaCode,
  user,
  isInviteUserFlow,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({
    PhoneNumber: '',
    Type: '',
  });
  const phoneNumbers = useSelector(selectUserPhoneNumbers);

  const countries = useGetCountries();
  const selectedISOCodesListToIgnoreAreaCode = useSelector(
    selectPortalGatewayISOCodesListToIgnoreAreaCode,
  );
  const [isDeletingPhoneNumber, setIsDeletingPhoneNumber] = useState(null);

  const [isAddPhoneNumberModalOpen, setIsAddPhoneNumberModalOpen] = useState(
    false,
  );

  const [error, setError] = useState(null);

  const isLoading =
    useSelector(
      selectIsLoadingByActionTypes([
        USER_PHONE_NUMBERS_LOADING,
        TWO_FA_CODE_LOADING,
        DELETE_USER_PHONE_NUMBER_LOADING,
      ]),
    ) || isDeletingPhoneNumber;
  const { UserUid, Username } = user;

  useEffect(() => {
    dispatch(fetchUserPhoneNumbers({ userUid: UserUid }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (!isEmpty(phoneNumbers) && phoneNumbers.length === 1) {
      const phoneNumber = JSON.stringify(phoneNumbers[0]);

      if (phoneNumber) {
        setInitialValues({
          PhoneNumber: phoneNumber,
          Type: parseEnumType(twoFaMessageType, 1),
        });
      }
    } else {
      setInitialValues({
        PhoneNumber: JSON.stringify(phoneNumbers[0]),
        Type: parseEnumType(twoFaMessageType, 1),
      });
    }
  }, [phoneNumbers]);

  const handleBack = () => {
    setTwoFaValues(null);
    goStepBack(
      isInviteUserFlow
        ? INVITE_USER_TWO_FA_VERIFY_CHOOSE_METHOD
        : VERIFY_CHOOSE_METHOD_PAGE,
    );
  };

  const onFormSubmitSuccess = (values) => {
    setTwoFaValues(values);
  };

  const twoFaCodeSubmit = (values, { setFieldError }) => {
    const { PhoneNumber, Type } = values;
    const { AuthenticationMethodType } = twoFaValue;
    const phoneNumberJsonParsed = JSON.parse(PhoneNumber.replace('+', ''));
    const phoneNumber = selectedPhoneNumberValidatorAndFormatter(
      phoneNumberJsonParsed,
      setFieldError,
      countries,
      selectedISOCodesListToIgnoreAreaCode,
    );

    const onError = (error) => {
      setError(error);
    };

    const onSuccess = () => {
      goStepForward(
        isInviteUserFlow ? INVITE_USER_TWO_FA_VERIFY_CODE : TWO_FA_VERIFY_CODE,
      );
      onFormSubmitSuccess({ Phone: { ...phoneNumber }, MessageType: Type });
    };

    getTwoFaCode({
      userUid: UserUid,
      requestData: {
        Phone: { PhoneNumberUid: phoneNumber.PhoneNumberUid },
        MessageType: Type,
        AuthenticationMethodType,
        Username,
      },
      onSuccess,
      onError,
    });
  };

  return (
    <SectionLoader isLoading={isLoading || isEmpty(countries)}>
      <Auth>
        <AuthCard title={t('twoFa.loginSetup.phone.title')}>
          <Paragraph marginBottom={40} isPrimaryColor bold>
            {t('twoFa.loginSetup.phone.subtitle')}
          </Paragraph>

          <Paragraph marginBottom={48}>
            {t('twoFa.loginSetup.phone.note')}
          </Paragraph>

          <Formik
            onSubmit={twoFaCodeSubmit}
            validationSchema={twoFaPhoneValidationSchema}
            initialValues={initialValues}
            enableReinitialize
          >
            {({ errors, values, setFieldValue }) => (
              <Form>
                <SingleColumnList marginBottom={72} alignCenter>
                  <TypeList maxWidth={342}>
                    <SingleColumnList marginBottom={56}>
                      <TwoFaPhoneListFull
                        phoneNumbers={phoneNumbers}
                        values={values}
                        userUid={UserUid}
                        setFieldValue={setFieldValue}
                        countries={countries}
                        setIsDeletingPhoneNumber={setIsDeletingPhoneNumber}
                        errors={errors}
                      />
                    </SingleColumnList>
                    <AddPhoneNumberSection
                      onClick={() => setIsAddPhoneNumberModalOpen(true)}
                      disabled={phoneNumbers.length > 1}
                    >
                      <AddPhoneNumberElement>
                        <AddPhoneNumberIconAndTextWrapper>
                          <AddPhoneNumberIcon>
                            <Plus />
                          </AddPhoneNumberIcon>
                          <AddPhoneNumberButtonText>
                            {t('twoFa.loginSetup.phone.phoneList.addNewPhone')}
                          </AddPhoneNumberButtonText>
                        </AddPhoneNumberIconAndTextWrapper>
                        <BalanceCardIcon>
                          <Caret />
                        </BalanceCardIcon>
                      </AddPhoneNumberElement>
                      <AddPhoneNumberNote>
                        {t('twoFa.loginSetup.phone.phoneList.addNewPhoneInfo')}
                      </AddPhoneNumberNote>
                      {phoneNumbers.length > 1 && (
                        <AddPhoneNumberWarningNote>
                          <Warning />
                          {t(
                            'twoFa.loginSetup.phone.phoneList.addNewPhoneWarning',
                          )}
                        </AddPhoneNumberWarningNote>
                      )}
                    </AddPhoneNumberSection>

                    <TwoFaPhoneAddPhoneModal
                      isOpen={isAddPhoneNumberModalOpen}
                      onClose={() => {
                        setIsAddPhoneNumberModalOpen(false);
                      }}
                    />

                    <SingleColumnList>
                      <TwoFaPhoneSelectRecieveType
                        setFieldValue={setFieldValue}
                        values={values}
                        errors={errors}
                      />
                    </SingleColumnList>
                    <SingleColumnList>
                      {error && (
                        <Paragraph isError marginTop={8}>
                          <strong>{error}</strong>
                        </Paragraph>
                      )}
                    </SingleColumnList>
                  </TypeList>
                  <SingleColumnList alignCenter marginTop={24}>
                    <div id="example-container" />
                  </SingleColumnList>
                </SingleColumnList>
                <AuthButtons
                  isHorizontal
                  secondaryButtonProps={{ onClick: handleBack }}
                  primaryButtonProps={{
                    type: 'submit',
                  }}
                />
              </Form>
            )}
          </Formik>
        </AuthCard>
      </Auth>
    </SectionLoader>
  );
};

TwoFAPhonePage.propTypes = {
  setTwoFaValues: PropTypes.func,
  goStepBack: PropTypes.func,
  isInviteUserFlow: PropTypes.bool,
  goStepForward: PropTypes.func,
  twoFaValue: PropTypes.shape({ AuthenticationMethodType: PropTypes.string }),
  getTwoFaCode: PropTypes.func,
  user: PropTypes.shape({
    UserUid: PropTypes.string,
    Username: PropTypes.string,
  }),
};
export default TwoFAPhonePage;
