import { createSelector } from 'reselect';
import {
  ACC_PURPOSE_ACH,
  ACC_PURPOSE_WIRE,
  bankAccountApprovalStatus,
  bankAccountPurposeTypes,
  BANK_ACCOUNT_EXPIRED_VERIFICATION,
  BANK_ACCOUNT_FAILED_VERIFICATION,
} from '../../util/enum/api/bankAccountTypes';
import { parseEnumType } from '../../util/helpers/enumMappers';
import { getWizardContent } from '../../util/helpers/wizardHelpers';
import { getRegisterContent } from '../../util/helpers/registerHelpers';
import {
  BANK_DETAILS,
  BANK_ACCOUNT_REGISTRATION,
} from '../../constants/sessionStorage';

const bankAccountSelector = (state) => state.bankAccount;

export const selectBankAccounts = createSelector(
  bankAccountSelector,
  (state) => state.bankAccounts.data,
);

export const selectBankAccountAchData = createSelector(
  bankAccountSelector,
  (state) =>
    state.bankAccounts.data.filter(
      (account) =>
        parseEnumType(bankAccountPurposeTypes, account.PurposeType) ===
        ACC_PURPOSE_ACH,
    ),
);

export const selectBankAccountWireData = createSelector(
  bankAccountSelector,
  (state) =>
    state.bankAccounts.data.filter(
      (account) =>
        parseEnumType(bankAccountPurposeTypes, account.PurposeType) ===
        ACC_PURPOSE_WIRE,
    ),
);

export const selectBankAccount = (bankAccountUid) =>
  createSelector(bankAccountSelector, (state) =>
    state.bankAccounts.data.find(
      (account) => account.BankAccountUid === bankAccountUid,
    ),
  );

export const selectPendingAchBankAccounts = createSelector(
  selectBankAccountAchData,
  (state) =>
    state.filter(
      (account) =>
        !account.IsVerified &&
        !(
          bankAccountApprovalStatus[account.StateType] ===
            BANK_ACCOUNT_FAILED_VERIFICATION ||
          bankAccountApprovalStatus[account.StateType] ===
            BANK_ACCOUNT_EXPIRED_VERIFICATION
        ),
    ),
);

export const selectPendingWireBankAccounts = createSelector(
  selectBankAccountWireData,
  (state) =>
    state.filter(
      (account) =>
        !account.IsVerified &&
        !(
          bankAccountApprovalStatus[account.StateType] ===
            BANK_ACCOUNT_FAILED_VERIFICATION ||
          bankAccountApprovalStatus[account.StateType] ===
            BANK_ACCOUNT_EXPIRED_VERIFICATION
        ),
    ),
);
export const selectBankAccountError = createSelector(
  bankAccountSelector,
  (state) => state.bankAccounts.errorMessage,
);

export const selectBankAccountDetailsByRoutingNum = createSelector(
  bankAccountSelector,
  (state) => getWizardContent(BANK_DETAILS) || state.baDetailsByRoutingNum.data,
);

export const selectBankAccountDetailsByRoutingNumRegistration = createSelector(
  bankAccountSelector,
  (state) =>
    state.registration.baDetailsByRoutingNum.data ||
    getWizardContent(BANK_DETAILS),
);
export const selectBankAccountDetailsByRoutingNumError = createSelector(
  bankAccountSelector,
  (state) => state.baDetailsByRoutingNum.errorMessage,
);
export const selectBankAccountDetailsByRoutingNumRegistrationError = createSelector(
  bankAccountSelector,
  (state) => state.registration.baDetailsByRoutingNum.errorMessage,
);
export const selectNewWireAccountData = createSelector(
  bankAccountSelector,
  (state) => state.newWireAccountData,
);

export const selectNewWireAccount = createSelector(
  bankAccountSelector,
  (state) => state.newWireAccount.data,
);

export const selectNewWireAccountError = createSelector(
  bankAccountSelector,
  (state) => state.newWireAccount.error,
);

export const selectIsSuccessfulDelete = createSelector(
  bankAccountSelector,
  (state) => state.isSuccessfulDelete,
);

export const selectSingleBankAccount = createSelector(
  bankAccountSelector,
  (state) => state.singleBankAccount,
);

export const selectBankAccountsError = createSelector(
  bankAccountSelector,
  (state) => state.error,
);

export const selectBankAccountsByType = createSelector(
  bankAccountSelector,
  (state) => state.bankAccountsByType.data,
);

export const selectBankAccountRegistration = createSelector(
  bankAccountSelector,
  (state) =>
    state.registration.bankAccounts.data ||
    getRegisterContent(BANK_ACCOUNT_REGISTRATION),
);

export const selectObiMessages = createSelector(
  bankAccountSelector,
  (state) => state.obiMessage,
);
