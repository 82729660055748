import React, { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import Modal from '../../Modal/Modal';
import StepByStep from '../../StepByStep';
import StatusModal from '../StatusModal';
import NewAchForm from './NewAchForm';
import AchAccountReview from './AchAccountReview';
import AchDoneAccount from './AchDoneAccount';

import { METHOD_VIA_CHECK } from '../../../util/constants';
import {
  selectBankAccountDetailsByRoutingNum,
  selectBankAccountDetailsByRoutingNumError,
} from '../../../store/selectors/bankAccountSelectors';
import {
  closeWizardContent,
  deleteWizardKey,
  setWizardContent,
} from '../../../util/helpers/wizardHelpers';
import { clearBaByRoutingNum } from '../../../store/actions/bankAccount/bankAccountActions';
import {
  ADD_NEW_ACH_WIZARD,
  BANK_DETAILS,
  CHOSEN_ACH_METHOD,
  IS_ABA_DISABLED,
  IS_ADD_ACCOUNT_MODAL_OPEN,
  NEW_ACH_DATA,
  NEW_ACH_ERROR,
  PREVENT_SUPPORT_MODAL,
  STATUS_MODAL_OPEN,
} from '../../../constants/sessionStorage';
import { useSessionStorageState } from '../../../util/hooks/useSessionStorageState';
import { retrieveFromSessionStorage } from '../../../util/helpers/sessionStorageHelper';
import { ACC_PURPOSE_ACH } from '../../../util/enum/api/bankAccountTypes';
import useGtmHook from '../../../util/hooks/useGtmHook';
import { BANK_ACCOUNT_ADDED } from '../../../constants/gtmEvents';

const AddNewAchModal = ({
  chosenAddMethod,
  onClose,
  goInitialModal,
  handleNewAchSubmit,
  setChooseAchMethodOpen,
  setChosenAchMethod,
  overModal,
  disableManualAch,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [statusModalOpen, setStatusModalOpen] = useSessionStorageState(
    STATUS_MODAL_OPEN,
    false,
  );
  const [newAchError, setNewAchError] = useSessionStorageState(
    NEW_ACH_ERROR,
    false,
  );
  const [isABADisabled, setIsABADisabled] = useSessionStorageState(
    IS_ABA_DISABLED,
    false,
  );
  const [newAchData, setNewAchData] = useSessionStorageState(NEW_ACH_DATA, {});
  const [isLoading, setIsLoading] = useState(false);
  const bankDetails = useSelector(selectBankAccountDetailsByRoutingNum);
  const bankDetailsError = useSelector(
    selectBankAccountDetailsByRoutingNumError,
  );
  const { bankAccountGtmEvent } = useGtmHook();

  const handleApiResponse = (hasError, isABADisabled) => {
    const isSupportModalPrevented = retrieveFromSessionStorage(
      PREVENT_SUPPORT_MODAL,
    );
    setIsABADisabled(isABADisabled);
    if (isSupportModalPrevented) {
      setIsLoading(false);
      setChosenAchMethod(null);
      deleteWizardKey(NEW_ACH_DATA);
      deleteWizardKey(ADD_NEW_ACH_WIZARD);
      deleteWizardKey(BANK_DETAILS);
      deleteWizardKey(CHOSEN_ACH_METHOD);
      dispatch(clearBaByRoutingNum());
    } else {
      closeWizardContent();
      setNewAchError(hasError);
      setStatusModalOpen(true);
      setWizardContent(NEW_ACH_ERROR, hasError);
      setWizardContent(STATUS_MODAL_OPEN, true);
      setWizardContent(ADD_NEW_ACH_WIZARD, 0);
      setWizardContent(IS_ABA_DISABLED, isABADisabled);
    }
  };
  /* eslint-disable */
  const isModalOpen = useMemo(() => !!(chosenAddMethod === METHOD_VIA_CHECK), [
    chosenAddMethod,
    bankDetailsError,
  ]);
  /* eslint-enable */

  useEffect(() => {
    if (statusModalOpen) {
      setIsLoading(false);
      setChosenAchMethod(null);
    }
  }, [statusModalOpen, setChosenAchMethod]);

  const handleGtmEvent = () => {
    bankAccountGtmEvent(
      BANK_ACCOUNT_ADDED,
      ACC_PURPOSE_ACH,
      newAchData.accountType.value,
      bankDetails.Address.CountryIso3Code,
    );
  };

  const handleNewAchOnConfirm = () => {
    const requestBody = {
      BankAccountUid: uuid(),
      DomesticBank: {
        Name: bankDetails.Name,
        Address: {
          AddressUid: bankDetails.Address.AddressUid,
          AddressLine: bankDetails.Address.AddressLine,
          AddressLine2: bankDetails.Address.AddressLine2,
          City: bankDetails.Address.City,
          PostalCode: bankDetails.Address.PostalCode,
          StateRegion: bankDetails.Address.StateRegion,
          CountryIso3Code: bankDetails.Address.CountryIso3Code,
        },
        Phone: bankDetails.Phone,
        ABARoutingNumber: bankDetails.ABARoutingNumber,
        ACHRoutingNumber: bankDetails.ACHRoutingNumber,
        WireRoutingNumber: bankDetails.WireRoutingNumber,
      },
      NameOnAccount: newAchData.nameOnAccount,
      NameOnAccount2: '',
      AccountNumber: newAchData.accountNumber,
      BankAccountType: newAchData.accountType.value,
      PurposeType: ACC_PURPOSE_ACH,
    };
    setIsLoading(true);
    handleNewAchSubmit(requestBody, { handleApiResponse });
  };

  const sbsContent = useCallback(
    (
      goStepBack,
      goStepForward,
      onConfirm,
      setChooseAchMethodOpen,
      disableManualAch,
    ) => {
      if (chosenAddMethod === METHOD_VIA_CHECK) {
        return [
          <NewAchForm
            key="new-ach-form"
            goStepForward={goStepForward}
            goInitialModal={() => {
              goInitialModal();
              setWizardContent(IS_ADD_ACCOUNT_MODAL_OPEN, true);
              setChooseAchMethodOpen(true);
            }}
            setNewAchData={setNewAchData}
            disableManualAch={disableManualAch}
          />,
          <AchAccountReview
            isLoading={isLoading}
            key="ach-account-review"
            newAchData={newAchData}
            goStepBack={goStepBack}
            onConfirm={onConfirm}
          />,
        ];
      }
      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chosenAddMethod, newAchData, isLoading, goInitialModal],
  );

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        size="sm"
        title={t('achDeposit.chooseAchAddMethod.modalTitle')}
        close={onClose}
        overModal={overModal}
      >
        <StepByStep
          sessionScopeStep={ADD_NEW_ACH_WIZARD}
          onConfirm={handleNewAchOnConfirm}
        >
          {({ goStepBack, goStepForward, onConfirm }) =>
            sbsContent(
              goStepBack,
              goStepForward,
              onConfirm,
              setChooseAchMethodOpen,
              disableManualAch,
            )
          }
        </StepByStep>
      </Modal>
      {statusModalOpen && newAchError ? (
        <StatusModal
          isOpen={statusModalOpen}
          hasError={newAchError}
          close={() => {
            closeWizardContent();
            setStatusModalOpen(false);
            setWizardContent(IS_ABA_DISABLED, false);
          }}
          text={newAchError}
          modalTitle={t('achDeposit.chooseAchAddMethod.modalTitle')}
          backButtonText={t('common.ok')}
          onButtonClick={() => {
            closeWizardContent();
            setStatusModalOpen(false);
            if (isABADisabled) {
              setWizardContent(IS_ABA_DISABLED, false);
            }
          }}
          isABADisabled={isABADisabled}
        />
      ) : (
        <AchDoneAccount
          isOpen={statusModalOpen}
          close={() => setStatusModalOpen(false)}
          onButtonClick={() => {
            closeWizardContent();
            setStatusModalOpen(false);
            handleGtmEvent();
          }}
        />
      )}
    </>
  );
};

AddNewAchModal.propTypes = {
  chosenAddMethod: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setChooseAchMethodOpen: PropTypes.func,
  onClose: PropTypes.func,
  handleNewAchSubmit: PropTypes.func,
  setChosenAchMethod: PropTypes.func,
  overModal: PropTypes.bool,
  goInitialModal: PropTypes.func,
  disableManualAch: PropTypes.bool,
};

export default AddNewAchModal;
