import styled from 'styled-components';
import { uFlexColumn } from '../../assets/styles/utility';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';

const ModalBody = styled.div`
  ${uFlexColumn};
  flex-grow: 1;
  padding: ${pxToRem(18)} ${pxToRem(16)};
  ${(props) => props.css}

  form {
    ${uFlexColumn};
    flex-grow: 1;
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(24)};
  }
`;

export default ModalBody;
