import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Anchor from '../Anchor/Anchor';
import Paragraph from '../Paragraph/Paragraph';
import SingleColumnList from '../TwoColumnList/SingleColumnList';
import config from '../../config';

const SupportLinks = ({ brokerSettings, isLPM }) => {
  const { t } = useTranslation();
  const { brokerTheme } = config;
  const isHAA = brokerTheme === 'HardAssetsAlliance';

  if (!brokerSettings) {
    return null;
  }

  const {
    FrequentlyAskedQuestionsUrl,
    SupportLiveChatUrl,
    SupportEmail,
    SupportPhoneTollFree,
    SupportPhoneInternational,
  } = brokerSettings;

  return (
    <SingleColumnList textAlignLeft>
      {!isLPM && FrequentlyAskedQuestionsUrl && (
        <>
          <Paragraph bold marginBottom={4}>
            {t(`support.supportCenter`)}
          </Paragraph>
          <Anchor
            bold
            marginBottom={32}
            type="website"
            value={FrequentlyAskedQuestionsUrl}
            text={t(`support.browseQuestions`)}
          />
        </>
      )}
      {SupportLiveChatUrl && (
        <>
          <Paragraph bold marginBottom={4}>
            {t(`support.chatWithAgents`)}
          </Paragraph>
          <Anchor
            bold
            type="website"
            marginBottom={32}
            value={SupportLiveChatUrl}
            text={t(`support.browseQuestions`)}
          />
        </>
      )}
      {SupportEmail && (
        <>
          <Paragraph bold marginBottom={4}>
            {t(`support.emailUs`)}
          </Paragraph>
          <Anchor
            bold
            type="email"
            marginBottom={32}
            value={SupportEmail}
            text={SupportEmail}
          />
        </>
      )}

      <Paragraph bold marginBottom={4}>
        {t(`support.callUs`)}
      </Paragraph>
      {SupportPhoneTollFree && (
        <Paragraph bold>
          {t(`support.toll`)}
          &nbsp;
          <Anchor
            type="telephone"
            value={SupportPhoneTollFree}
            text={SupportPhoneTollFree}
          />
        </Paragraph>
      )}
      {isHAA && SupportPhoneInternational && (
        <Paragraph bold>
          {t(`support.tollInternational`)}
          &nbsp;
          <Anchor
            type="telephone"
            value={SupportPhoneInternational}
            text={SupportPhoneInternational}
          />
        </Paragraph>
      )}
    </SingleColumnList>
  );
};

SupportLinks.propTypes = {
  brokerSettings: PropTypes.shape({
    FrequentlyAskedQuestionsUrl: PropTypes.string,
    SupportLiveChatUrl: PropTypes.string,
    SupportEmail: PropTypes.string,
    SupportPhone: PropTypes.string,
    SupportPhoneTollFree: PropTypes.string,
    SupportPhoneInternational: PropTypes.string,
  }),
  isLPM: PropTypes.bool,
};

export default SupportLinks;
