export const PRODUCT_TYPE_BAR = 'Bar';
export const PRODUCT_TYPE_COIN = 'Coin';
export const PRODUCT_TYPE_FRACTIONAL = 'Fractional';
export const PRODUCT_TYPE_BAGS = 'Bags';

export const productTypes = {
  Bar: PRODUCT_TYPE_BAR,
  Coin: PRODUCT_TYPE_COIN,
  Fractional: PRODUCT_TYPE_FRACTIONAL,
  Bags: PRODUCT_TYPE_BAGS,
};

export const productTypesArray = Object.values(productTypes);

export const productLocationTypes = [
  'NewYork',
  'SaltLakeCity',
  'Zurich',
  'London',
  'Sydney',
  'Singapore',
  'Toronto',
  'Ohio',
  'Dallas',
  '',
  'HongKong',
  'Ship to door',
  'FractionalHoldings',
  'Delaware',
];

export const productLocationStateAbbr = ['NY', 'UT'];

export const productLocationUSType = [
  'NewYork',
  'SaltLakeCity',
  'Ohio',
  'Dallas',
  'Delaware',
];

export const productMetalTypes = [
  'Gold',
  'Silver',
  'Platinum',
  'Palladium',
  'Diamond',
];

export const productLocationTypesByName = {
  NewYork: 1,
  SaltLakeCity: 2,
  Zurich: 3,
  London: 4,
  Sydney: 5,
  Singapore: 6,
  Toronto: 7,
  Ohio: 8,
  Dallas: 9,
  HongKong: 11,
  Delivery: 12,
  FractionalHoldings: 13,
  Delaware: 14,
};

export const productMetalType = {
  Gold: 'Gold',
  Silver: 'Silver',
  Platinum: 'Platinum',
  Palladium: 'Palladium',
  Diamond: 'Diamond',
};

export const LOCATION_TYPE_DELIVERY = 12;
export const LOCATION_TYPE_ZURICH = 3;

export const DIAMOND_PRODUCT_TYPE = 'Diamond';
