import React from 'react';
import { ReactComponent as HAALogo } from '../../assets/images/Logo/HardAssetsAllianceLogo.svg';
import GSLogo from '../../assets/images/Logo/GoldSilver_Logo.png';
import ContosoLogo from '../../assets/images/Logo/ContosoLogo.png';
import LegacyPMLogo from '../../assets/images/Logo/LegacyPreciousMetalsLogo.png';
import config from '../../config';

const Logo = () => {
  const { brokerTheme } = config;

  if (brokerTheme === 'HardAssetsAlliance') {
    return <HAALogo />;
  }

  if (brokerTheme === 'GoldSilver') {
    return <img src={GSLogo} alt="Broker Logo" width="210px" />;
  }

  if (brokerTheme === 'Contoso') {
    return <img src={ContosoLogo} alt="Broker Logo" />;
  }

  if (brokerTheme === 'LegacyPM') {
    return <img src={LegacyPMLogo} alt="Broker Logo" />;
  }

  return null;
};

Logo.propTypes = {};

export default Logo;
