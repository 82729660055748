import React from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from '../Button/Button';
import { ReactComponent as AlertTriangle } from '../../assets/images/svg/alert-triangle.svg';
import Section from '../Section/Section';
import { logoutUser } from '../../store/actions/login/loginActions';
import { getCurrentAccount } from '../../store/selectors/accountSelectors';
import { useGetIsImpersonating } from '../../util/hooks/useGetIsImpersonating';
import themeColors from '../../assets/styles/themeColors';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';

const ImpersonateBannerContainer = styled.div`
  background-color: ${themeColors.colorRed};
  padding: ${pxToRem(8)} 0;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: ${pxToRemMd(16)};
  }
`;

const ImpersonateBannerIcon = styled(AlertTriangle)`
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};
  color: ${themeColors.colorTextPrimary};
  margin-right: ${pxToRem(16)};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    width: ${pxToRemMd(20)};
    height: ${pxToRemMd(20)};
    margin-right: ${pxToRemMd(16)};
  }
`;

const ImpersonateBannerTypography = styled.p`
  color: ${themeColors.colorTextPrimary};
  font-weight: 600;
  letter-spacing: 0;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    text-align: center;
  }
`;

const ImpersonateBannerLogout = styled(Button)`
  color: ${themeColors.colorTextPrimary};
  margin-left: ${pxToRem(24)};
  min-width: ${pxToRem(104)};
  padding: ${pxToRem(4)} ${pxToRem(22)};
  text-transform: capitalize;
  font-size: ${pxToRem(16)};
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.5;
  border: 1px solid ${themeColors.colorTextPrimary};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    margin-left: 0;
    margin-top: ${pxToRemMd(12)};
    max-width: ${pxToRemMd(320)};
    min-width: 0;
    width: 100%;
    padding: ${pxToRemMd(4)} ${pxToRemMd(22)};
    font-size: ${pxToRemMd(16)};
    border: 1px solid ${themeColors.colorTextPrimary};
  }
`;

const ImpersonateBannerContent = styled(Section)`
  display: flex;
  align-items: center;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    flex-direction: column;
  }
`;

const ImpersonateBanner = () => {
  const dispatch = useDispatch();
  const account = useSelector(getCurrentAccount);
  const { isImpersonating } = useGetIsImpersonating();

  const logoutImpersonateUser = () => {
    dispatch(logoutUser());
  };

  if (!isImpersonating) {
    return null;
  }

  return (
    <ImpersonateBannerContainer>
      <ImpersonateBannerContent>
        <ImpersonateBannerIcon />
        <ImpersonateBannerTypography>
          {i18next.t('impersonate.caution', {
            name: String(account.DisplayName).trim(),
          })}
        </ImpersonateBannerTypography>
        <ImpersonateBannerLogout size="lg" onClick={logoutImpersonateUser}>
          {i18next.t('impersonate.logout')}
        </ImpersonateBannerLogout>
      </ImpersonateBannerContent>
    </ImpersonateBannerContainer>
  );
};

ImpersonateBanner.propTypes = {};

export default ImpersonateBanner;
