import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Modal from '../../Modal/Modal';

import StepByStep from '../../StepByStep';
import Paragraph from '../../Paragraph/Paragraph';
import Status from '../Status';
import ChangeAutoPaySettings from './ChangeAutoPaySettings';
import { selectCreditCards } from '../../../store/selectors/creditCardSelectors';

const steps = [ChangeAutoPaySettings, Status];

const ChangeAutoPaySettingsModal = ({
  isOpen,
  handleClose,
  storageFeeAmount,
  setIsOpenPayStorageFeeByCard,
  isOpenPayStorageFeeByCard,
  setIsAutoPaySettingsModalOpen,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [selectedCreditCard, setSelectedCreditCard] = useState();
  const creditCards = useSelector(selectCreditCards);
  const [isAutoPayOn, setIsAutoPayOn] = useState(false);

  useEffect(() => {
    setIsAutoPayOn(creditCards?.some((card) => card.IsDefaultForStorage));
  }, [creditCards]);

  useEffect(() => {
    setSelectedCreditCard(
      creditCards?.find((card) => card.IsDefaultForStorage) || creditCards[0],
    );
  }, [creditCards]);

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        close={handleClose}
        title={t('storageFee.changeAutoPaySettingsModal.label')}
        size="sm"
      >
        <StepByStep saveStepDisabled>
          {({ goStepForward, goStepBack }) =>
            steps.map((step) =>
              React.createElement(step, {
                handleBack: goStepBack,
                handleNext: goStepForward,
                handleClose,
                setError,
                hasError: !!error,
                text: (
                  <>
                    <Paragraph bold>
                      {t('storageFee.changeAutoPaySettingsModal.successTitle')}
                    </Paragraph>
                    {isAutoPayOn
                      ? t(
                          'storageFee.changeAutoPaySettingsModal.successDescription2',
                        )
                      : t(
                          'storageFee.changeAutoPaySettingsModal.successDescription1',
                        )}
                  </>
                ),
                backButtonText: t('common.ok'),
                onButtonClick: handleClose,
                storageFeeAmount,
                setSelectedCreditCard,
                selectedCreditCard,
                showAutoPayStatus: true,
                isAutoPayOn,
                setIsAutoPayOn,
                setIsOpenPayStorageFeeByCard,
                isOpenPayStorageFeeByCard,
                setIsAutoPaySettingsModalOpen,
                showChangeSettingsButton: false,
              }),
            )
          }
        </StepByStep>
      </Modal>
    </>
  );
};

ChangeAutoPaySettingsModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  storageFeeAmount: PropTypes.number,
  setIsOpenPayStorageFeeByCard: PropTypes.func,
  isOpenPayStorageFeeByCard: PropTypes.bool,
  setIsAutoPaySettingsModalOpen: PropTypes.func,
};

export default ChangeAutoPaySettingsModal;
