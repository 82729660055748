import { call, takeLatest, all, put } from '@redux-saga/core/effects';
import { rejectErrorCodeHelper } from '../../util/helpers/rejectErrorCodeHelper';
import { SUBMIT_MAIL_IN_REQUEST } from '../actions/mailIn/mailInActionConstants';
import { submitMailInStorageRequest } from '../../request/mailInRequest';
import {
  submitMailInRequestError,
  submitMailInRequestSuccess,
} from '../actions/mailIn/mailInActions';

function* submitMailInRequest({ payload }) {
  const { data, onSuccess } = payload;
  try {
    yield call(submitMailInStorageRequest, payload);
    yield call(onSuccess);
    yield put(submitMailInRequestSuccess(data));
    yield put(submitMailInRequestError(''));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(submitMailInRequestError(errorMessage));
    console.log(error); // eslint-disable-line
  }
}

export default function* mailInSaga() {
  yield all([takeLatest(SUBMIT_MAIL_IN_REQUEST, submitMailInRequest)]);
}
