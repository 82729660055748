import { createSelector } from 'reselect';
import { PERIOD } from '../../constants/period';

const valuationSelector = (state) => state.portfolio.valuation;

export const getMaximumPeriodType = createSelector(
  valuationSelector,
  (state) => state.MaximumPeriodType,
);

export const getPeriodType = createSelector(
  getMaximumPeriodType,
  (maximumPeriodType) => PERIOD[maximumPeriodType],
);

export const getPortfolioValuation = createSelector(
  valuationSelector,
  (state) => state.TotalValuation,
);

export const getPortfolioValuationTimestamp = createSelector(
  valuationSelector,
  (state) => state.ValuationTimestamp,
);

export const getDataSeries = createSelector(
  valuationSelector,
  (state) => state.Ticks,
);

export const getMarketPricesTimestamp = createSelector(
  valuationSelector,
  (state) => state.MarketPricesTimestamp,
);

export const getPortfolioChartError = createSelector(
  valuationSelector,
  (state) => state.errorMessage,
);
