import {
  createErrorType,
  createSuccessType,
  createFetchType,
  createLoadingType,
} from '../actionHelpers';

const SECURITY_QUESTIONS_SCOPE = 'SECURITY_QUESTIONS';
export const GET_SECURITY_QUESTIONS = createFetchType(SECURITY_QUESTIONS_SCOPE);
export const GET_SECURITY_QUESTIONS_SUCCESS = createSuccessType(
  SECURITY_QUESTIONS_SCOPE,
);
export const GET_SECURITY_QUESTIONS_ERROR = createErrorType(
  SECURITY_QUESTIONS_SCOPE,
);
export const GET_SECURITY_QUESTIONS__LOADING = createLoadingType(
  SECURITY_QUESTIONS_SCOPE,
);

const PORTAL_SECURITY_QUESTIONS_SCOPE = 'PORTAL_SECURITY_QUESTIONS';
export const GET_PORTAL_SECURITY_QUESTIONS = createFetchType(
  PORTAL_SECURITY_QUESTIONS_SCOPE,
);
export const GET_PORTAL_SECURITY_QUESTIONS_SUCCESS = createSuccessType(
  PORTAL_SECURITY_QUESTIONS_SCOPE,
);
export const GET_PORTAL_SECURITY_QUESTIONS_ERROR = createErrorType(
  PORTAL_SECURITY_QUESTIONS_SCOPE,
);
export const GET_PORTAL_SECURITY_QUESTIONS__LOADING = createLoadingType(
  PORTAL_SECURITY_QUESTIONS_SCOPE,
);

// Countries
const COUNTRIES_SCOPE = 'COUNTRIES';
export const GET_COUNTRIES = createFetchType(COUNTRIES_SCOPE);
export const GET_COUNTRIES_SUCCESS = createSuccessType(COUNTRIES_SCOPE);
export const GET_COUNTRIES_ERROR = createErrorType(COUNTRIES_SCOPE);
export const GET_COUNTRIES_LOADING = createLoadingType(COUNTRIES_SCOPE);

// States
const STATES_SCOPE = 'STATES';
export const GET_STATES = createFetchType(STATES_SCOPE);
export const GET_STATES_SUCCESS = createSuccessType(STATES_SCOPE);
export const GET_STATES_ERROR = createErrorType(STATES_SCOPE);
export const GET_STATES_LOADING = createLoadingType(STATES_SCOPE);

// Primary states
const PRIMARY_STATES_SCOPE = 'PRIMARY_STATES';
export const GET_PRIMARY_STATES = createFetchType(PRIMARY_STATES_SCOPE);
export const GET_PRIMARY_STATES_SUCCESS = createSuccessType(
  PRIMARY_STATES_SCOPE,
);
export const GET_PRIMARY_STATES_ERROR = createErrorType(PRIMARY_STATES_SCOPE);

const MAILING_STATES_SCOPE = 'MAILING_STATES';
export const GET_MAILING_STATES = createFetchType(MAILING_STATES_SCOPE);
export const GET_MAILING_STATES_SUCCESS = createSuccessType(
  MAILING_STATES_SCOPE,
);
export const GET_MAILING_STATES_ERROR = createErrorType(MAILING_STATES_SCOPE);

// Tax Forms
const TAX_FORMS_SCOPE = 'TAX_FORMS';
export const GET_TAX_FORMS = createFetchType(TAX_FORMS_SCOPE);
export const GET_TAX_FORMS_SUCCESS = createSuccessType(TAX_FORMS_SCOPE);
export const GET_TAX_FORMS_ERROR = createErrorType(TAX_FORMS_SCOPE);
export const GET_TAX_FORMS_LOADING = createLoadingType(TAX_FORMS_SCOPE);

// Countries
const CONTRIBUTION_YEARS_SCOPE = 'CONTRIBUTION_YEARS';
export const GET_CONTRIBUTION_YEARS = createFetchType(CONTRIBUTION_YEARS_SCOPE);
export const GET_CONTRIBUTION_YEARS_SUCCESS = createSuccessType(
  CONTRIBUTION_YEARS_SCOPE,
);
export const GET_CONTRIBUTION_YEARS_ERROR = createErrorType(
  CONTRIBUTION_YEARS_SCOPE,
);
export const GET_CONTRIBUTION_YEARS_LOADING = createLoadingType(
  CONTRIBUTION_YEARS_SCOPE,
);
