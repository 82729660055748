import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash.isequal';

import { useSelector } from 'react-redux';
import styled from 'styled-components';
import BankAccountItem from './BankAccountItem';
import { selectNumberOfAllowedAchDepositAccounts } from '../../../../../store/selectors/settingsSelectors';
import useCheckPermissions from '../../../../../util/hooks/useCheckPermissions';
import Label from '../../../../Notes/Label';
import {
  uButtonClear,
  uFlexCenter,
  uFlexColumn,
  uOutlineNone,
} from '../../../../../assets/styles/utility';
import themeColors from '../../../../../assets/styles/themeColors';
import {
  getStylesByProps,
  mediaBelow,
  pxToRem,
  pxToRemMd,
} from '../../../../../assets/styles/helper';
import { variables } from '../../../../../assets/styles/variables';
import { isBankAccountVerified } from '../../../../../util/helpers/bankAccountHelper';

export const BankAccountsListAddButton = styled.button`
  ${uOutlineNone};
  ${uButtonClear};
  width: 100%;
  background-color: ${themeColors.colorWhite};
  color: ${themeColors.colorTextPrimary};
  border: 1px solid ${themeColors.colorTextPrimary};
  padding: ${pxToRem(4)};
  text-align: center;
  font-weight: 600;
  letter-spacing: 0;
  font-size: ${pxToRem(16)};
  line-height: 1.5;
  border-radius: ${variables.borderRadius.borderRadius};
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
  }
`;

export const BankAccountsList = styled.div`
  ${uFlexColumn};
  ${(props) => getStylesByProps(props)};
  ${(props) =>
    props.empty &&
    `
    ${BankAccountsListAddButton} {
      background-color: ${themeColors.colorTextPrimary};
      color: ${themeColors.colorButtonAddNewBank};
    }
  `};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    ${(props) =>
      props.empty &&
      `
        font-size: ${pxToRemMd(14)};
        padding: ${pxToRemMd(6)};

        ${BankAccountsListAddButton} {
          padding: ${pxToRemMd(4)};
          font-size: ${pxToRemMd(16)};
        }
      `}
`;

export const BankAccountsListContent = styled.div`
  ${uFlexColumn};
`;

export const BankAccountsListEmpty = styled.p`
  ${uFlexCenter};
  width: 100%;
  color: ${themeColors.colorLabel};
  font-size: ${pxToRem(14)};
  line-height: 1.72;
  letter-spacing: 0;
  text-align: center;
  padding: ${pxToRem(6.5)};
  background-color: ${themeColors.colorWhite};
  border-radius: ${variables.borderRadius.borderRadius};
  border: 1px solid ${themeColors.colorBorderPrimary};
`;

const BankAccountList = ({
  empty,
  selectedBankAccount,
  handleSelectBankAccount,
  handleAddBankAccount,
  useAccountBalance,
  label,
  noAddOption,
  marginBottom,
  marginTop,
  handleFixBankAccount,
  bankAccounts,
}) => {
  const { t } = useTranslation();
  const { allowAccountChangesPermission } = useCheckPermissions();
  const numberOfAllowedAchDepositAccounts = useSelector(
    selectNumberOfAllowedAchDepositAccounts,
  );

  const canAddACHAccount = () => {
    const canAddMoreAccounts =
      bankAccounts?.length < numberOfAllowedAchDepositAccounts;

    return allowAccountChangesPermission && canAddMoreAccounts;
  };

  if (useAccountBalance) {
    return (
      <>
        <Label text={label} />
      </>
    );
  }

  const shouldRenderAddButton = () => {
    if (noAddOption) {
      return false;
    }

    if (canAddACHAccount()) {
      return true;
    }

    return false;
  };

  const bankAccountsAvailableFirst = bankAccounts?.sort(
    (bankAccountA, bankAccountB) => {
      if (
        isBankAccountVerified(bankAccountA) &&
        !isBankAccountVerified(bankAccountB)
      ) {
        return -1;
      }
      if (
        isBankAccountVerified(bankAccountA.IsVerified) &&
        !isBankAccountVerified(bankAccountB)
      ) {
        return 1;
      }
      return 0;
    },
  );

  return (
    <BankAccountsList
      empty={empty}
      marginBottom={marginBottom}
      marginTop={marginTop}
    >
      <Label marginBottom={4} text={label} />
      <BankAccountsListContent>
        {empty ? (
          <BankAccountsListEmpty>
            <p>
              {t('standardDepositWizard.achDeposit.noConnectedBankAccounts')}
            </p>
          </BankAccountsListEmpty>
        ) : (
          <ul>
            {bankAccountsAvailableFirst.map((bankAccount) => {
              const accountMaskPadding = Array(8).fill('*').join('');
              const selected = isEqual(selectedBankAccount, bankAccount);
              const label = `${bankAccount.BankName}, ${
                accountMaskPadding + bankAccount.AccountMask
              }`;
              return (
                <BankAccountItem
                  key={bankAccount.BankAccountUid}
                  label={label}
                  selected={selected}
                  bankAccount={bankAccount}
                  handleSelectBankAccount={handleSelectBankAccount}
                  handleFixBankAccount={handleFixBankAccount}
                />
              );
            })}
          </ul>
        )}
      </BankAccountsListContent>
      {shouldRenderAddButton() && (
        <div>
          <BankAccountsListAddButton
            type="button"
            onClick={handleAddBankAccount}
          >
            {t('standardDepositWizard.achDeposit.connectANewBankAccount')}
          </BankAccountsListAddButton>
        </div>
      )}
    </BankAccountsList>
  );
};

BankAccountList.propTypes = {
  empty: PropTypes.bool,
  selectedBankAccount: PropTypes.shape({}),
  handleSelectBankAccount: PropTypes.func,
  handleAddBankAccount: PropTypes.func,
  useAccountBalance: PropTypes.bool,
  label: PropTypes.string,
  noAddOption: PropTypes.bool,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  handleFixBankAccount: PropTypes.func,
  bankAccounts: PropTypes.arrayOf(PropTypes.shape({})),
};

export default BankAccountList;
