import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TypeCard from '../../../../TypeCard/TypeCard';
import { WITHDRAWAL_WIZARD_TYPES } from '../../../../../constants/standardWithdrawalWizardConstants';
import ModalBody from '../../../../Modal/ModalBody';
import TypeList from '../../../../TypeList/TypeList';
import useGtmHook from '../../../../../util/hooks/useGtmHook';
import { FUND_WITHDRAW_START } from '../../../../../constants/gtmEvents';

const EntrustChooseType = ({ handleNext, setWithdrawalType, setTitleType }) => {
  const { fundGtmEvent } = useGtmHook();

  useEffect(() => {
    setTitleType('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalBody>
      <TypeList>
        <TypeCard
          title="Wire Transfer to Custodian"
          onClick={() => {
            setWithdrawalType(WITHDRAWAL_WIZARD_TYPES.WIRE);
            handleNext();
            fundGtmEvent(FUND_WITHDRAW_START, {
              fund: {
                method: WITHDRAWAL_WIZARD_TYPES.WIRE,
              },
            });
          }}
        />
        <TypeCard
          title="Check"
          onClick={() => {
            setWithdrawalType(WITHDRAWAL_WIZARD_TYPES.CHECK);
            handleNext();
            fundGtmEvent(FUND_WITHDRAW_START, {
              fund: {
                method: WITHDRAWAL_WIZARD_TYPES.CHECK,
              },
            });
          }}
        />
      </TypeList>
    </ModalBody>
  );
};

EntrustChooseType.propTypes = {
  handleNext: PropTypes.func,
  setWithdrawalType: PropTypes.func,
  setTitleType: PropTypes.func,
};

export default EntrustChooseType;
