import React from 'react';
import PropTypes from 'prop-types';
import Radio from './Radio';

const RoundedRadio = (props) => <Radio variant="RadioRounded" {...props} />;

RoundedRadio.propTypes = {
  props: PropTypes.shape({}),
};

export default RoundedRadio;
