import { decode } from 'jsonwebtoken';
import { JWT_TOKEN } from '../../constants/localStorage';
import { authScopeStringGetHelper } from '../helpers/authScopeHelpers';

export const useGetIsImpersonating = () => {
  const jwtToken = authScopeStringGetHelper(JWT_TOKEN);

  if (jwtToken) {
    const { accessedByUserUid } = decode(jwtToken);

    const isImpersonating = !!accessedByUserUid;
    return { isImpersonating };
  }

  return false;
};
