import {
  SUBMIT_MAIL_IN_REQUEST,
  SUBMIT_MAIL_IN_REQUEST_ERROR,
  SUBMIT_MAIL_IN_REQUEST_SUCCESS,
} from './mailInActionConstants';

export const submitMailInRequest = (payload) => ({
  type: SUBMIT_MAIL_IN_REQUEST,
  payload,
});

export const submitMailInRequestSuccess = (payload) => ({
  type: SUBMIT_MAIL_IN_REQUEST_SUCCESS,
  payload,
});

export const submitMailInRequestError = (payload) => ({
  type: SUBMIT_MAIL_IN_REQUEST_ERROR,
  payload,
});
