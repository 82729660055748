import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from '../../Modal/Modal';

import StepByStep from '../../StepByStep';
import Status from '../Status';
import EditFullName from './EditFullName/EditFullName';

const steps = [EditFullName, Status];

const EditFullNameModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');

  return (
    <>
      <Modal
        title={t('profileSettings.changeFullName')}
        isOpen={isOpen}
        close={onClose}
        size="sm"
      >
        <StepByStep saveStepDisabled>
          {({ goStepForward, goStepBack }) =>
            steps.map((step) =>
              React.createElement(step, {
                handleBack: goStepBack,
                handleNext: goStepForward,
                handleClose: onClose,
                backButtonText: t('common.ok'),
                onButtonClick: onClose,
                setError,
                text: error || t('profileSettings.fullNameChanged'),
                hasError: error,
              }),
            )
          }
        </StepByStep>
      </Modal>
    </>
  );
};

EditFullNameModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default EditFullNameModal;
