import {
  FETCH_CLIENT_TOKEN_ERROR,
  FETCH_CLIENT_TOKEN_SUCCESS,
  SUBMIT_CREDIT_CARD_ERROR,
  SUBMIT_CREDIT_CARD_SUCCESS,
  CREDIT_CARD_ERROR,
  CREDIT_CARD_SUCCESS,
  CREDIT_CARD_DELETE_SUCCESS,
  CREDIT_CARD_DELETE_ERROR,
} from '../../actions/creditCard/creditCardActionConstants';
import createReducer from '../../utils/createReducer';

const initialState = {
  clientToken: {
    data: {},
    error: '',
  },
  newCreditCard: {
    data: {},
    error: '',
  },
  creditCards: {
    data: [],
    errorMessage: '',
  },
  isSuccessfulDelete: null,
  isSuccessfulSubmit: null,
};

export default createReducer(
  {
    [FETCH_CLIENT_TOKEN_SUCCESS]: fetchClientTokenSuccess,
    [FETCH_CLIENT_TOKEN_ERROR]: fetchClientTokenError,
    [SUBMIT_CREDIT_CARD_SUCCESS]: submitCreditCardSuccess,
    [SUBMIT_CREDIT_CARD_ERROR]: submitCreditCardError,
    [CREDIT_CARD_SUCCESS]: creditCardSuccess,
    [CREDIT_CARD_ERROR]: creditCardError,
    [CREDIT_CARD_DELETE_SUCCESS]: creditCardDeleteSuccess,
    [CREDIT_CARD_DELETE_ERROR]: creditCardDeleteError,
  },
  initialState,
);

function fetchClientTokenSuccess(state, { payload }) {
  return {
    ...state,
    clientToken: {
      ...state.clientToken,
      data: payload,
    },
  };
}
function creditCardSuccess(state, action) {
  return {
    ...state,
    creditCards: {
      ...state.creditCards,
      data: action.payload,
    },
  };
}

function fetchClientTokenError(state, { payload }) {
  return {
    ...state,
    clientToken: {
      ...state.clientToken,
      error: payload,
    },
  };
}
function creditCardError(state, action) {
  return {
    ...state,
    creditCards: {
      ...state.creditCards,
      errorMessage: action.payload,
    },
  };
}

function submitCreditCardSuccess(state) {
  return {
    ...state,
    isSuccessfulSubmit: true,
  };
}

function submitCreditCardError(state, { payload }) {
  return {
    ...state,
    newCreditCard: {
      ...state.newCreditCard,
      error: payload,
    },
  };
}

function creditCardDeleteSuccess(state) {
  return {
    ...state,
    isSuccessfulDelete: true,
  };
}

function creditCardDeleteError(state) {
  return {
    ...state,
    isSuccessfulDelete: false,
  };
}
