import { call, takeLatest, all, put } from '@redux-saga/core/effects';

import {
  ACCOUNT_SETTINGS_FETCH,
  FETCH_ACCOUNT_ADDRESSES,
  FETCH_ACCOUNT_USERS,
  USER_PERMISSIONS_FETCH,
  ACCOUNT_ADDRESS_UPDATE,
  ACCOUNT_ADDRESS_DELETE,
  SUBMIT_NEW_ACCOUNT_USER,
  UPDATE_ACCOUNT_USER,
  GET_ACCOUNT,
  SUBMIT_AGREEMENT,
  SUBMIT_ADDRESS,
  ACCOUNT_NAG_DASHBOARD,
  DISMISSED_NAG_DASHBOARD,
} from '../actions/account/accountActionConstants';
import {
  getAccountAddresses,
  getAccountSettingsRequest,
  getAccountUsers,
  getUserPermissions,
  deleteAccountAddressRequest,
  updateAccountAddressRequest,
  postNewAccountUserRequest,
  updateAccountUserRequest,
  getAccount,
  postAgreementRequest,
  postAccountAddressRequest,
  getNagDashboardRequest,
  postDismissedDashboardRequest,
} from '../../request/accountRequest';
import {
  deleteAccountAddressesError,
  deleteAccountAddressesSuccess,
  fetchAccountAddresses,
  fetchAccountAddressesError,
  fetchAccountAddressesSuccess,
  fetchAccountSettingsError,
  fetchAccountSettingsSuccess,
  fetchAccountUsersError,
  fetchAccountUsersSuccess,
  submitNewAccountUserError,
  updateAccountAddressesError,
  updateAccountAddressesSuccess,
  updateAccountUserError,
  updateAccountUserSuccess,
  userPermissionsError,
  userPermissionsSuccess,
  postAgreementSuccess,
  postAgreementError,
  postAccountAddressSuccess,
  postAccountAddressError,
  fetchNagDashboardSuccess,
  fetchNagDashboardError,
  postDismissedDashboardError,
  postDismissedDashboardSuccess,
} from '../actions/account/accountActions';
import { rejectErrorCodeHelper } from '../../util/helpers/rejectErrorCodeHelper';

function* fetchSingleAccount({ payload }) {
  try {
    const { data } = yield call(getAccount, payload.AccountUid);

    if (payload.setImpersonateAccount) {
      yield call(payload.setImpersonateAccount, data);
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    console.log(errorMessage); // eslint-disable-line
  } finally {
    if (payload?.onSuccess) {
      yield call(payload.onSuccess);
    }
  }
}

export function* fetchAccountUsers({ payload }) {
  try {
    const { data } = yield call(getAccountUsers, payload.accountUid);

    const permissionsResponse = data.map(async (user) => {
      const response = await getUserPermissions(
        payload.accountUid,
        user.UserUid,
      );
      return {
        id: user.UserUid,
        permissions: response.data.Permissions,
      };
    });
    const usersPermissions = yield all(permissionsResponse);

    const usersData = data.map((user) => {
      const foundPermission = usersPermissions.find(
        (u) => u.id === user.UserUid,
      );
      return {
        ...user,
        Permissions: foundPermission.permissions,
      };
    });
    yield put(fetchAccountUsersSuccess(usersData));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchAccountUsersError(errorMessage));
  }
}

function* fetchAccountAddressesSaga({ payload }) {
  try {
    const { data } = yield call(getAccountAddresses, payload.accountUid);
    yield put(fetchAccountAddressesSuccess(data));
    yield put(fetchAccountAddressesError(''));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchAccountAddressesError(errorMessage));
  }
}

function* fetchUserPermissions({ payload }) {
  try {
    const { data } = yield call(
      getUserPermissions,
      payload.accountUid,
      payload.userUid,
    );
    yield put(userPermissionsSuccess(data));
    yield put(userPermissionsError(''));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(userPermissionsError(errorMessage));
  }
}

function* fetchAccountSettings({ payload }) {
  try {
    const { data } = yield call(getAccountSettingsRequest, payload.accountUid);
    yield put(fetchAccountSettingsSuccess(data));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchAccountSettingsError(errorMessage));
  }
}

function* updateAccountAddress({ payload }) {
  try {
    const { data } = yield call(
      updateAccountAddressRequest,
      payload.accountUid,
      payload.addressUid,
      payload.data,
    );
    yield put(updateAccountAddressesSuccess(data));
    yield put(updateAccountAddressesError(''));
    yield put(fetchAccountAddresses({ accountUid: payload.accountUid }));
    yield call(payload.handleGtm);
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(updateAccountAddressesError(errorMessage));
  }
}

function* deleteAccountAddress({ payload }) {
  yield call(payload.setIsLoading, true);
  try {
    const { data } = yield call(
      deleteAccountAddressRequest,
      payload.accountUid,
      payload.addressUid,
    );
    yield put(deleteAccountAddressesSuccess(data));
    yield call(payload.handleGtm);
    yield put(fetchAccountAddresses({ accountUid: payload.accountUid }));
    yield call(payload.setError, false);
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(deleteAccountAddressesError(errorMessage));

    yield call(payload.setError, errorMessage);
  } finally {
    yield call(payload.setIsLoading, false);
    yield call(payload.handleNext);
  }
}

function* postAccountAddress({ payload }) {
  yield call(payload.setIsLoading, true);
  try {
    const { data } = yield call(postAccountAddressRequest, payload);
    yield put(postAccountAddressSuccess(data));
    yield put(fetchAccountAddresses({ accountUid: payload.accountUid }));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(postAccountAddressError(errorMessage));

    yield call(payload.setError, errorMessage);
  } finally {
    yield call(payload.setIsLoading, false);
    yield call(payload.handleNext);
  }
}

function* submitNewAccountUser({
  payload: { accountUid, data, setError, setIsLoading, handleNext },
}) {
  yield call(setIsLoading, true);
  try {
    yield call(postNewAccountUserRequest, accountUid, data);
    yield call(fetchAccountUsers, { payload: { accountUid } });
    yield call(setError, '');
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(submitNewAccountUserError(errorMessage));
    yield call(setError, errorMessage);
  } finally {
    yield call(setIsLoading, false);
    yield call(handleNext);
  }
}

function* updateAccountUser({ payload }) {
  const { accountUid } = { ...payload };
  try {
    if (payload?.setIsLoading) {
      yield call(payload.setIsLoading, true);
    }
    const { data } = yield call(
      updateAccountUserRequest,
      payload.accountUid,
      payload.userUid,
      payload.actionType,
      payload.data,
    );
    yield put(updateAccountUserSuccess(data));
    if (payload?.setError) {
      yield call(payload.setError, '');
    }
    yield call(fetchAccountUsers, { payload: { accountUid } });
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(updateAccountUserError(errorMessage));
    yield call(payload.setError, errorMessage);
  } finally {
    if (payload?.setIsLoading) {
      yield call(payload.setIsLoading, false);
    }
    if (payload?.handleNext) {
      yield call(payload.handleNext);
    }
  }
}

function* submitAgreement({ payload }) {
  try {
    const { data } = yield call(postAgreementRequest, payload.data);
    yield call(postAgreementSuccess, data);
    yield call(postAgreementError, '');
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(postAgreementError(errorMessage));
  }
}

function* fetchNagDashboardAccount({ payload }) {
  try {
    const { data } = yield call(getNagDashboardRequest, payload.accountUid);
    if (data) {
      yield put(fetchNagDashboardSuccess(data));
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield call(fetchNagDashboardError, errorMessage);
  } finally {
    if (payload?.onSuccess) {
      yield call(payload.onSuccess);
    }
  }
}

function* postDismissedNagDashboard({ payload }) {
  try {
    const { data } = yield call(
      postDismissedDashboardRequest,
      payload.accountUid,
    );
    if (data) {
      yield put(postDismissedDashboardSuccess(data));
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield call(postDismissedDashboardError, errorMessage);
  } finally {
    if (payload?.onSuccess) {
      yield call(payload.onSuccess);
    }
  }
}
export default function* accountSaga() {
  yield all([
    takeLatest(FETCH_ACCOUNT_USERS, fetchAccountUsers),
    takeLatest(FETCH_ACCOUNT_ADDRESSES, fetchAccountAddressesSaga),
    takeLatest(USER_PERMISSIONS_FETCH, fetchUserPermissions),
    takeLatest(ACCOUNT_SETTINGS_FETCH, fetchAccountSettings),
    takeLatest(ACCOUNT_ADDRESS_UPDATE, updateAccountAddress),
    takeLatest(ACCOUNT_ADDRESS_DELETE, deleteAccountAddress),
    takeLatest(SUBMIT_NEW_ACCOUNT_USER, submitNewAccountUser),
    takeLatest(UPDATE_ACCOUNT_USER, updateAccountUser),
    takeLatest(GET_ACCOUNT, fetchSingleAccount),
    takeLatest(SUBMIT_AGREEMENT, submitAgreement),
    takeLatest(SUBMIT_ADDRESS, postAccountAddress),
    takeLatest(ACCOUNT_NAG_DASHBOARD, fetchNagDashboardAccount),
    takeLatest(DISMISSED_NAG_DASHBOARD, postDismissedNagDashboard),
  ]);
}
