import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { parseEnumType } from '../../util/helpers/enumMappers';
import { accountTypeKeys } from '../../util/enum/api/accountTypes';
import Anchor from '../Anchor/Anchor';

const ProfileSettingsEmailNote = ({
  account,
  accountTypes,
  supportEmail,
  uploadCenterUrl,
}) => {
  const { t } = useTranslation();

  if (!account && !accountTypes) {
    return null;
  }

  const accountType = parseEnumType(accountTypeKeys, account.AccountType);

  if (
    accountType === accountTypes.JointTenantsInCommon ||
    accountType === accountTypes.JointTenantsWithRightOfSurvivorship
  ) {
    return (
      <>
        {t('profileSettings.changeEmail.jointAccountsOne')}{' '}
        <Anchor type="email" value={`${supportEmail}`} />{' '}
        {t('profileSettings.changeEmail.jointAccountsTwo')}{' '}
        <Anchor
          type="website"
          value={`${uploadCenterUrl}`}
          text={t('profileSettings.changeEmail.documentCenter')}
        />{' '}
        {t('profileSettings.changeEmail.jointAccountsThree')}
      </>
    );
  }

  if (accountType === accountTypes.IRA) {
    return <>{t('profileSettings.changeEmail.iraAccount')}</>;
  }

  return (
    <>
      {t('profileSettings.changeEmail.nonIraOne')}{' '}
      <Anchor type="email" value={`${supportEmail}`} />{' '}
      {t('profileSettings.changeEmail.nonIraTwo')}{' '}
      <Anchor
        type="website"
        value={`${uploadCenterUrl}`}
        text={t('profileSettings.changeEmail.documentCenter')}
      />{' '}
      {t('profileSettings.changeEmail.nonIraThree')}
    </>
  );
};

ProfileSettingsEmailNote.propTypes = {
  account: PropTypes.shape({
    AccountType: PropTypes.number,
  }),
  accountTypes: PropTypes.shape({
    JointTenantsInCommon: PropTypes.string,
    JointTenantsWithRightOfSurvivorship: PropTypes.string,
    IRA: PropTypes.string,
  }),
  supportEmail: PropTypes.string,
  uploadCenterUrl: PropTypes.string,
};

export default ProfileSettingsEmailNote;
