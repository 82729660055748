import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Paragraph from '../../Paragraph/Paragraph';
import ModalButtons from '../../Modal/ModalButtons';
import ModalBody from '../../Modal/ModalBody';
import { getCurrentAccount } from '../../../store/selectors/accountSelectors';
import { removeUser } from '../../../store/actions/user/userActions';
import SectionLoader from '../../Loader/SectionLoader';

const RemoveUser = ({ userUid, handleClose, setHasError, handleNext }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { AccountUid } = useSelector(getCurrentAccount);

  const handleRemoveUser = () => {
    if (userUid && AccountUid) {
      dispatch(
        removeUser({
          accountUid: AccountUid,
          userUid,
          setHasError,
          handleNext,
          setIsLoading,
        }),
      );
    }
  };

  return (
    <ModalBody>
      <SectionLoader isLoading={isLoading}>
        <Paragraph>{t('userManagement.confirmRemoveUser')}</Paragraph>

        <ModalButtons
          isHorizontal
          marginTop
          primaryButtonProps={{
            onClick: handleRemoveUser,
            label: t('common.delete'),
          }}
          secondaryButtonProps={{
            onClick: handleClose,
            label: t('common.cancel'),
          }}
        />
      </SectionLoader>
    </ModalBody>
  );
};

RemoveUser.propTypes = {
  handleClose: PropTypes.func,
  userUid: PropTypes.string,
  setHasError: PropTypes.func,
  handleNext: PropTypes.func,
};

export default RemoveUser;
