import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Formik } from 'formik';
import styled from 'styled-components';
import PortfolioSection from '../Section/PortfolioSection';
import Button from '../../Button/Button';
import { fetchPortfolioHoldings } from '../../../store/actions/portfolio/portfolioActions';
import {
  selectHoldingsData,
  // selectHoldingsError,
} from '../../../store/selectors/portfolioHoldingsSelectors';
import { getCurrentAccount } from '../../../store/selectors/accountSelectors';
import { BUY_PAGE, SELL_PAGE } from '../../../constants/pages';
import { selectIsLoadingByActionType } from '../../../store/selectors/loadingSelectors';
import { PORTFOLIO_HOLDINGS_LOADING } from '../../../store/actions/portfolio/portfolioActionConstants';
import BlockSectionLoader from '../../Loader/BlockSectionLoader';
import HoldingView from './HoldingView';
import SelectField from '../../InputFields/SelectField';
import { VIEW_TYPE_OPTIONS } from '../../../constants/holdingsConstants';
import { HOLDINGS_VIEW_TYPE } from '../../../constants/sessionStorage';
import {
  retrieveFromSessionStorage,
  storeInSessionStorage,
} from '../../../util/helpers/sessionStorageHelper';
import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import useCheckPermissions from '../../../util/hooks/useCheckPermissions';

const SummarySelect = styled.form`
  width: 100%;
`;

const HoldingsCtaStyle = styled.div`
  margin-left: auto;
  display: grid;
  grid-template-columns: ${pxToRem(180)} ${pxToRem(120)} ${pxToRem(120)};
  grid-column-gap: ${pxToRem(24)};

  .c-select__control {
    height: ${pxToRem(42)} !important;
  }

  ${mediaBelow(450)} {
    margin-left: 0;
    margin-right: auto;
    grid-template-columns: ${pxToRemMd(120)} ${pxToRemMd(120)};
    grid-gap: ${pxToRemMd(16)};

    ${SummarySelect} {
      grid-column: 1 / 3;
      grid-row: 2 / 3;
    }
  }
`;

const Holdings = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const account = useSelector(getCurrentAccount);
  const holdings = useSelector(selectHoldingsData);
  // const holdingsError = useSelector(selectHoldingsError);
  const { displayBasicAccountOptionsPermission } = useCheckPermissions();
  const isLoading = useSelector(
    selectIsLoadingByActionType(PORTFOLIO_HOLDINGS_LOADING),
  );

  const [viewType, setViewType] = useState(
    () =>
      retrieveFromSessionStorage(HOLDINGS_VIEW_TYPE) || VIEW_TYPE_OPTIONS[0],
  );

  useEffect(() => {
    dispatch(
      fetchPortfolioHoldings({
        accountUid: account.AccountUid,
      }),
    );
  }, [dispatch, account.AccountUid]);

  const hasPortfolioProducts = () => !isEmpty(holdings);

  const calculateTotalValuation = () => {
    if (!isEmpty(holdings)) {
      return Object.values(holdings)
        .flat()
        .reduce((acc, curr) => acc + curr.Valuation, 0);
    }

    return 0;
  };

  const calculateTotalOunces = () => {
    if (!isEmpty(holdings)) {
      return Object.values(holdings)
        .flat()
        .reduce((acc, curr) => acc + curr.EffectiveFineWeight, 0);
    }

    return 0;
  };

  const totalValuation = calculateTotalValuation();

  const totalOunces = calculateTotalOunces();

  return (
    <PortfolioSection
      title="Holdings"
      cta={
        hasPortfolioProducts() ? (
          <HoldingsCtaStyle>
            <Formik initialValues={{ valueType: {}, viewType: {} }}>
              <SummarySelect data-cy="button-holdings-view-type">
                <SelectField
                  name="viewType"
                  defaultSelected={viewType}
                  options={VIEW_TYPE_OPTIONS}
                  selectOption={(value) => {
                    setViewType(value);
                    storeInSessionStorage(HOLDINGS_VIEW_TYPE, value);
                  }}
                />
              </SummarySelect>
            </Formik>

            {!displayBasicAccountOptionsPermission && (
              <Button
                variant="primaryOutlined"
                onClick={() => history.push(SELL_PAGE)}
                data-cy="button-sell"
              >
                {t('portfolio.holdings.sellBtnLabel')}
              </Button>
            )}
            <Button
              variant="primary"
              onClick={() => history.push(BUY_PAGE)}
              data-cy="button-buy"
            >
              {t('portfolio.holdings.buyBtnLabel')}
            </Button>
          </HoldingsCtaStyle>
        ) : null
      }
    >
      {isLoading || !viewType ? (
        <BlockSectionLoader isLoading={isLoading} />
      ) : (
        <>
          <HoldingView
            viewType={viewType}
            holdings={holdings}
            showProducts={hasPortfolioProducts()}
            totalValuation={totalValuation}
            totalOunces={totalOunces}
          />
        </>
      )}
    </PortfolioSection>
  );
};

export default Holdings;
