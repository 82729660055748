import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { pxToRem } from '../../assets/styles/helper';

export const TableDataElement = styled.div`
  display: flex;
  align-items: center;
  font-size: ${pxToRem(18)};
  line-height: 1.35;
  width: 100%;
  ${(props) => props.css};
`;

const TableData = ({ children, ...props }) => (
  <TableDataElement {...props}>{children}</TableDataElement>
);

TableData.propTypes = {
  children: PropTypes.node,
};

export default TableData;
