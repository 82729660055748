import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { isEmpty, startCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { getCurrentAccount } from '../../store/selectors/accountSelectors';
import { fetchPortfolioBalances } from '../../store/actions/portfolio/portfolioActions';
import { parseEnumType } from '../../util/helpers/enumMappers';
import ModalBody from '../Modal/ModalBody';
import Label from '../Notes/Label';
import SectionLoader from '../Loader/SectionLoader';
import Paragraph from '../Paragraph/Paragraph';
import ModalTitle from '../Modal/ModalTitle';
import ChoosePaymentMethod from './ChoosePaymentMethod';
import { selectSettings } from '../../store/selectors/settingsSelectors';
import { fetchCreditCards } from '../../store/actions/creditCard/creditCardActions';
import ModalButtons from '../Modal/ModalButtons';
import { selectBalancesAvailable } from '../../store/selectors/portfolioBalancesSelectors';
import { productLocationTypes } from '../../util/enum/api/productTypes';
import InformationList from '../InformationList/InformationList';
import InformationListItem from '../InformationList/InformationListItem';
import { formatMoneyNumeral } from '../../util/helpers/numeralHelpers';
import themeColors from '../../assets/styles/themeColors';
import { pxToRem } from '../../assets/styles/helper';
import { getQuotesForOrderStorageBuy } from '../../store/actions/orders/orderActions';
import paymentTypes from '../../util/enum/api/paymentTypes';
import loadSignifydDeviceFingerprintScript from '../../signifydDeviceFingerprint';
import {
  retrieveFromSessionStorage,
  storeInSessionStorage,
} from '../../util/helpers/sessionStorageHelper';
import { SIGNIFYD_SESSION_ID } from '../../constants/sessionStorage';
import { generateSessionOrderId } from '../../util/helpers/sinigfyd';

const Error = styled.div`
  dispay: flex;
  color: ${themeColors.colorRed};
  font-size: ${pxToRem(16)};
  line-height: 1.35;
  font-weight: 500;
`;

const PricesWrapper = styled.div``;

const OrderForStoragePayment = ({
  handleNext,
  handleBack,
  wizardData,
  setWizardData,
  setWizardTitle,
  closeModal,
  handleCloseModalOnBuy,
  setPaymentMethods,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const account = useSelector(getCurrentAccount);

  const {
    orderType,
    orderValues,
    storageProduct,
    selectedVault,
    calculation,
    isDefaultPaymentMethod,
  } = wizardData;

  const settings = useSelector(selectSettings);
  const { paymentMethodStepData } = calculation;
  const [quoteID, setQuoteID] = useState('');
  const [expiryTime, setExpiryTime] = useState('');
  const [postPaidCalculation, setPostPaidCalculation] = useState({});
  const [nextClicked, setNextClicked] = useState(false);
  const [isLoadingGetQuotes, setIsLoadingGetQuotes] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const balance = useSelector(selectBalancesAvailable);
  const paymentMethods = balance?.PaymentMethods;
  const availableForTrading = balance?.AvailableForTrading;

  // const getQuotes = useSelector(getQuotes);

  const isSegregated = wizardData?.isSegregated;
  const segregatedCaption = settings?.SegregatedCaption;

  const [finalOrder, setFinalOrder] = useState({});
  const [updatedPaymentMethods, setUpdatedPaymentMethods] = useState(
    paymentMethods,
  );

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();

  useEffect(() => {
    if (!selectedPaymentMethod && wizardData?.paymentMethod) {
      setSelectedPaymentMethod(wizardData.paymentMethod);
    }
    if (!selectedPaymentMethod && !wizardData?.paymentMethod) {
      setSelectedPaymentMethod(isDefaultPaymentMethod);
    }
  }, [selectedPaymentMethod, isDefaultPaymentMethod, wizardData.paymentMethod]);

  const newPricePerUnit =
    selectedPaymentMethod?.PaymentMethodType === 3
      ? paymentMethodStepData?.DataWithCCFee?.ClientPricePerUnit
      : paymentMethodStepData?.DataWithoutCCFee?.ClientPricePerUnit;

  const clientExecutionPrice =
    selectedPaymentMethod?.PaymentMethodType === 3
      ? paymentMethodStepData?.DataWithCCFee?.TotalClientExecutionPrice
      : paymentMethodStepData?.DataWithoutCCFee?.TotalClientExecutionPrice;

  const volumeDiscount =
    selectedPaymentMethod?.PaymentMethodType === 3
      ? paymentMethodStepData?.DataWithCCFee?.VolumeDiscount
      : paymentMethodStepData?.DataWithoutCCFee?.VolumeDiscount;

  const quantityExecuted =
    selectedPaymentMethod?.PaymentMethodType === 3
      ? paymentMethodStepData?.DataWithCCFee?.QuantityExecuted
      : paymentMethodStepData?.DataWithoutCCFee?.QuantityExecuted;

  const totalAmountWithCreditCard =
    paymentMethodStepData?.DataWithCCFee?.TotalClientExecutionPrice;

  const totalAmountWithoutCreditCard =
    paymentMethodStepData?.DataWithoutCCFee?.TotalClientExecutionPrice;

  const priceWithDiscount = () => {
    if (selectedPaymentMethod?.PaymentMethodType !== 3) {
      return (
        <PricesWrapper>
          <Paragraph color={themeColors.colorSuccess} bold>
            {formatMoneyNumeral(
              paymentMethodStepData?.DataWithoutCCFee
                ?.TotalClientExecutionPrice,
            )}
          </Paragraph>
          <Paragraph textDecoration="line-through" marginLeft={8} bold>
            {formatMoneyNumeral(
              paymentMethodStepData?.DataWithoutCCFee
                ?.TotalClientExecutionPriceWithFee,
            )}
          </Paragraph>
        </PricesWrapper>
      );
    }
    return (
      <Paragraph bold>
        {formatMoneyNumeral(
          paymentMethodStepData?.DataWithCCFee?.TotalClientExecutionPrice,
        )}
      </Paragraph>
    );
  };

  const isAccountBalanceDisabled =
    availableForTrading <
    paymentMethodStepData?.DataWithoutCCFee?.TotalClientExecutionPrice;

  useEffect(() => {
    const sessionId = retrieveFromSessionStorage(SIGNIFYD_SESSION_ID);
    const existingScript = document.getElementById('sig-api');
    if (!sessionId) {
      if (existingScript) {
        existingScript.dataset.orderSessionId = generateSessionOrderId();
        storeInSessionStorage(
          SIGNIFYD_SESSION_ID,
          existingScript.dataset.orderSessionId,
        );
        return;
      }
      loadSignifydDeviceFingerprintScript();
      const script = document.getElementById('sig-api');
      storeInSessionStorage(SIGNIFYD_SESSION_ID, script.dataset.orderSessionId);
    }
  }, []);

  const onError = (error) => {
    const errorCode = error?.response?.data?.Errors[0]?.Code;

    if (
      errorCode === 'BuyMinimumAmountIsNotReached' ||
      errorCode === 'OrderMinimumValidationError'
    ) {
      setErrorMessage(
        t('apiErrors.BuyForDeliveryAmountMinimumIsNotReached', {
          orderMinimum: `$${selectedVault?.BuyMinimumAmount}`,
        }),
      );
    } else {
      setErrorMessage(t(`apiErrors.${errorCode}`));
    }
  };

  useEffect(() => {
    const updatedPaymentMethodsList = paymentMethods?.map((paymentMethod) => {
      if (!paymentMethod.PaymentMethodUid) {
        return { ...paymentMethod, PaymentMethodUid: '1' };
      }
      return paymentMethod;
    });
    setUpdatedPaymentMethods(updatedPaymentMethodsList);
  }, [paymentMethods]);

  useEffect(() => {
    if (account.AccountUid) {
      dispatch(fetchPortfolioBalances({ accountUid: account.AccountUid }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHandleGetQuotesSuccess = (getQuoteResult) => {
    const {
      QuoteUid,
      QuoteStatusItem: {
        QuantityExecuted,
        ClientPricePerUnit,
        TotalClientExecutionPrice,
        VolumeDiscount,
        PaymentTypeDiscount,
        PaymentMethodStepData,
      },
      ExpiresOnUtc,
    } = getQuoteResult;

    const postPaidCalculation = {
      estimatedOrderTotal: TotalClientExecutionPrice,
      newPricePerUnit: ClientPricePerUnit,
      volumeDiscount: VolumeDiscount,
      quantity: QuantityExecuted,
      paymentTypeDiscount: PaymentTypeDiscount,
      paymentMethodStepData: PaymentMethodStepData,
    };
    setQuoteID(QuoteUid);
    setExpiryTime(ExpiresOnUtc);
    setPostPaidCalculation(postPaidCalculation);
    setNextClicked(true);
  };

  useEffect(() => {
    if (!isEmpty(postPaidCalculation) && !isEmpty(orderValues)) {
      setWizardData({
        ...wizardData,
        postPaidCalculation,
        quoteID,
        expiryTime,
        orderValues,
        orderType,
      });
    }
  }, [
    orderValues,
    postPaidCalculation,
    setWizardData,
    wizardData,
    quoteID,
    expiryTime,
    orderType,
  ]);

  useEffect(() => {
    if (!isEmpty(wizardData?.orderValues) && nextClicked) {
      setNextClicked(false);
      handleNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardData.orderValues, nextClicked]);

  useEffect(() => {
    setWizardTitle(t('buyWizard.postPaid.modalTitle'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (account.AccountUid && settings?.IsCreditCardActive) {
      dispatch(fetchCreditCards({ accountUid: account.AccountUid }));
    }
  }, [dispatch, account, settings]);

  useEffect(() => {
    if (!selectedVault || !orderValues) {
      return;
    }

    if (orderType === 'Cash') {
      setFinalOrder({
        pricePerUnit: newPricePerUnit,
        quantity: quantityExecuted,
        estimatedTotal: clientExecutionPrice,
        volumeDiscount: volumeDiscount ?? 0,
      });
    }

    if (orderType === 'Quantity') {
      setFinalOrder({
        pricePerUnit: newPricePerUnit,
        quantity: quantityExecuted,
        estimatedTotal: clientExecutionPrice,
        volumeDiscount: volumeDiscount ?? 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderValues, orderType, selectedPaymentMethod]);

  // const fetchQuotes = () => {
  //   const paymentMethod = wizardData?.paymentMethod
  //     ? wizardData?.paymentMethod
  //     : wizardData?.isDefaultPaymentMethod;

  //   const paymentTypeUid =
  //     paymentMethod?.PaymentMethodType !== 1
  //       ? paymentMethod?.PaymentMethodUid
  //       : '00000000-0000-0000-0000-000000000000';
  //   const payload = {
  //     getQuotesPayload: {
  //       accountUid: account.AccountUid,
  //       symbolCode: selectedVault?.SymbolCode,
  //       quantityOrAmount:
  //         wizardData?.orderValues?.CashAmount ||
  //         wizardData?.orderValues?.QuantityAmount,
  //       sideType: 'Buy',
  //       unitType: orderType,
  //       paymentType: paymentTypes[paymentMethod?.PaymentMethodType],
  //     },
  //     isLoading: setIsLoadingGetQuotes,
  //     onSuccess: onHandleGetQuotesSuccess,
  //     onError,
  //     isSegregated,
  //     paymentTypeUid,
  //   };
  //   setIsLoadingGetQuotes(true);
  //   dispatch(
  //     getQuotesForOrderStorageBuy({
  //       ...payload,
  //     }),
  //   );
  // };

  const submitForm = (values) => {
    const paymentMethod = updatedPaymentMethods?.find(
      (method) => method.PaymentMethodUid === values.paymentMethodUid,
    );

    setWizardData({
      ...wizardData,
      paymentMethod,
      // selectedPaymentMethod,
    });
    // orderGtmHelper(
    //   gtmDialogScreenView,
    //   gtmCustomEvent,
    //   values,
    //   wizardData,
    //   activeType,
    //   unitPrice,
    //   currentAccount,
    //   false,
    // );

    // handleNext();

    const paymentTypeUid =
      paymentMethod?.PaymentMethodType !== 1
        ? paymentMethod?.PaymentMethodUid
        : '00000000-0000-0000-0000-000000000000';
    const payload = {
      getQuotesPayload: {
        accountUid: account.AccountUid,
        symbolCode: selectedVault?.SymbolCode,
        quantityOrAmount:
          wizardData?.orderValues?.CashAmount ||
          wizardData?.orderValues?.QuantityAmount,
        sideType: 'Buy',
        unitType: orderType,
        paymentType: paymentTypes[paymentMethod?.PaymentMethodType],
      },
      isSegregated,
      paymentTypeUid,
      addPaymentMethodData: true,
      isLoading: setIsLoadingGetQuotes,
      onSuccess: onHandleGetQuotesSuccess,
      onError,
    };
    setIsLoadingGetQuotes(true);
    dispatch(
      getQuotesForOrderStorageBuy({
        ...payload,
      }),
    );
  };

  if (isEmpty(wizardData)) {
    return null;
  }

  const orderTotalsData = [
    {
      label: t('buyWizard.postPaid.pricePerUnit'),
      value: formatMoneyNumeral(finalOrder.pricePerUnit),
      isBold: true,
    },
    {
      label: t('buyWizard.postPaid.quantity'),
      value: finalOrder.quantity,
    },
    finalOrder?.volumeDiscount > 0
      ? {
          value: `(${formatMoneyNumeral(finalOrder.volumeDiscount)})`,
          label: t(
            'buyWizard.buyForDelivery.reviewOrder.orderTotal.volumeDiscount',
          ),
          isBold: true,
        }
      : { value: '', label: '' },
    {
      label: t('buyWizard.postPaid.total'),
      value: priceWithDiscount(),
      isBold: true,
    },
  ];
  // As BE returns null for Balance payment type uid, we changed to be 1 so it has some value for checked radio button
  const defaultPaymentUid =
    wizardData?.isDefaultPaymentMethod?.PaymentMethodType === 1
      ? '1'
      : wizardData?.isDefaultPaymentMethod?.PaymentMethodUid;

  return (
    <SectionLoader isLoading={isLoadingGetQuotes}>
      <ModalBody>
        <ModalTitle marginBottom={4}>
          {isSegregated
            ? `${storageProduct?.ProductCaption} ${startCase(
                parseEnumType(
                  productLocationTypes,
                  selectedVault?.LocationType,
                ),
              )} (${segregatedCaption})`
            : `${storageProduct?.ProductCaption} ${startCase(
                parseEnumType(
                  productLocationTypes,
                  selectedVault?.LocationType,
                ),
              )}`}
        </ModalTitle>
        <Label marginBottom={16} text={t('buyWizard.postPaid.paymentMethod')} />

        <InformationList withBackground marginTop={12} marginBottom={24}>
          {orderTotalsData.map(({ isBold, value, label, isError }) => (
            <InformationListItem
              fullWidth
              isBold={isBold}
              value={value}
              label={label}
              isError={isError}
            />
          ))}
        </InformationList>

        <Paragraph fontSize={16} marginBottom={4}>
          <strong>{t('buyWizard.postPaid.selectPaymentMethod')}</strong>
        </Paragraph>
        {(!isEmpty(updatedPaymentMethods) || !updatedPaymentMethods) && (
          <>
            <Formik
              initialValues={{
                paymentMethodUid: wizardData?.paymentMethod
                  ? wizardData?.paymentMethod?.PaymentMethodUid
                  : defaultPaymentUid,
              }}
              onSubmit={submitForm}
              enableReinitialize
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <ChoosePaymentMethod
                    paymentMethods={updatedPaymentMethods}
                    values={values}
                    setWizardData={setWizardData}
                    wizardData={wizardData}
                    setPaymentMethods={setPaymentMethods}
                    setFieldValue={setFieldValue}
                    closeModal={closeModal}
                    handleCloseModalOnBuy={handleCloseModalOnBuy}
                    setErrorMessage={setErrorMessage}
                    errorMessage={errorMessage}
                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                    clientExecutionPrice={clientExecutionPrice}
                    isAccountBalanceDisabled={isAccountBalanceDisabled}
                    totalAmountWithCreditCard={totalAmountWithCreditCard}
                    totalAmountWithoutCreditCard={totalAmountWithoutCreditCard}
                  />

                  {errorMessage && <Error>{errorMessage}</Error>}

                  <ModalButtons
                    marginTop
                    isHorizontal
                    secondaryButtonProps={{
                      onClick: handleBack,
                      label: t('common.back'),
                    }}
                    primaryButtonProps={{
                      type: 'submit',
                      disabled: !values.paymentMethodUid,
                    }}
                  />
                </Form>
              )}
            </Formik>
          </>
        )}
      </ModalBody>
    </SectionLoader>
  );
};

OrderForStoragePayment.propTypes = {
  wizardData: PropTypes.shape({
    storageProduct: PropTypes.shape({
      ProductCaption: PropTypes.string,
    }),
    selectedVault: PropTypes.shape({
      LocationType: PropTypes.number,
      PricePerUnit: PropTypes.number,
      IsCashOrderOnly: PropTypes.bool,
      SymbolCode: PropTypes.string,
      BuyMinimumAmount: PropTypes.number,
    }),
    isSegregated: PropTypes.bool,
    cashBuffer: PropTypes.number,
    calculation: PropTypes.shape({
      estimatedOrderTotal: PropTypes.number,
      newPricePerUnit: PropTypes.number,
      volumeDiscount: PropTypes.number,
      paymentMethodStepData: PropTypes.shape({
        DataWithCCFee: PropTypes.shape({
          ClientPricePerUnit: PropTypes.number,
          TotalClientExecutionPrice: PropTypes.number,
          VolumeDiscount: PropTypes.number,
          QuantityExecuted: PropTypes.number,
          TotalClientExecutionPriceWithFee: PropTypes.number,
        }),
        DataWithoutCCFee: PropTypes.shape({
          ClientPricePerUnit: PropTypes.number,
          TotalClientExecutionPrice: PropTypes.number,
          VolumeDiscount: PropTypes.number,
          QuantityExecuted: PropTypes.number,
          TotalClientExecutionPriceWithFee: PropTypes.number,
        }),
      }),
    }),
    orderValues: PropTypes.shape({
      CashAmount: PropTypes.number,
      QuantityAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    paymentMethod: PropTypes.shape({
      PaymentMethodUid: PropTypes.number,
    }),
    isDefaultPaymentMethod: PropTypes.shape({
      PaymentMethodUid: PropTypes.number,
      PaymentMethodType: PropTypes.number,
    }),
    orderType: PropTypes.string,
    activeType: PropTypes.string,
    balancePaymentMethodUid: PropTypes.string,
  }),
  setWizardTitle: PropTypes.func,
  setWizardData: PropTypes.func,
  handleBack: PropTypes.bool,
  handleNext: PropTypes.bool,
  closeModal: PropTypes.func,
  handleCloseModalOnBuy: PropTypes.func,
  setPaymentMethods: PropTypes.func,
};

export default OrderForStoragePayment;
