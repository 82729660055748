import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { transactionDetailsHelper } from '../../../../util/helpers/transcation/transactionDetailsHelper';
import {
  mediaBelow,
  pxToRem,
  pxToRemMd,
} from '../../../../assets/styles/helper';
import { variables } from '../../../../assets/styles/variables';
import { uFlexColumn } from '../../../../assets/styles/utility';
import {
  StatusIconWrap,
  StatusContainer,
  StatusLabel,
} from '../../../Portfolio/RecentTransactions/Status';

export const TransactionDetailRow = styled.div`
  padding: 0 ${pxToRem(16)};
  display: grid;
  grid-template-columns: auto minMax(0, 1fr);
  grid-column-gap: ${pxToRem(16)};
  margin-bottom: ${pxToRem(4)};

  &:last-child {
    margin-bottom: ${pxToRem(16)};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(4)} ${pxToRemMd(16)};
    margin-bottom: ${pxToRemMd(4)};

    &:last-child {
      margin-bottom: ${pxToRemMd(16)};
    }
  }
`;

export const TransactionDetailLabel = styled.p`
  font-size: ${pxToRem(14)};
  line-height: 1.72;
  font-weight: 600;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(14)};
  }
`;

export const TransactionDetailValue = styled.p`
  ${uFlexColumn};
  text-align: right;
  font-size: ${pxToRem(14)};
  line-height: 1.72;

  ${StatusContainer} {
    justify-content: flex-end;
    max-width: 100%;
  }

  ${StatusIconWrap} {
    margin-right: ${pxToRem(8)};
  }

  a {
    width: max-content;
    align-self: flex-end;
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(14)};

    ${StatusContainer} {
      ${StatusIconWrap} {
        margin-right: ${pxToRemMd(8)};
      }
      ${StatusLabel} {
        display: block;
      }
    }
  }
`;

const DetailRow = ({ field, brokerSettings }) => {
  const values = transactionDetailsHelper(field, brokerSettings);

  if (!values) {
    return null;
  }

  return (
    <TransactionDetailRow>
      <TransactionDetailLabel>
        {values?.fieldCaption || ''}:
      </TransactionDetailLabel>
      <TransactionDetailValue>
        {values?.fieldValue || ''}
      </TransactionDetailValue>
    </TransactionDetailRow>
  );
};

DetailRow.propTypes = {
  field: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  brokerSettings: PropTypes.shape({}),
};

export default DetailRow;
