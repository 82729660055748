import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {
  BUY_WIZARD,
  STATUS_NEW_ADDRESS_ADDED,
  WIZARD_DATA,
  SIGNIFYD_SESSION_ID,
} from '../../../constants/sessionStorage';
import StepByStep from '../../StepByStep';
import ChooseVault from '../../Vault/ChooseVault';
import BuyOrder from './BuyOrder';
import { selectStorageProduct } from '../../../store/selectors/buyStorageSelectors';
import Modal from '../../Modal/Modal';
import {
  removeFromSessionStorage,
  retrieveFromSessionStorage,
  storeInSessionStorage,
} from '../../../util/helpers/sessionStorageHelper';
import { getWizardContent } from '../../../util/helpers/wizardHelpers';
import { isActionRestrictedHelper } from '../../../util/helpers/restrictionHelper';
import useRestrictionHook from '../../../util/hooks/useRestrictionHook';
import { restrictionTypes } from '../../../util/enum/api/restrictionTypes';
import Restriction from '../RestrictionModal/Restriction';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import {
  accountTypeKeys,
  accountTypes,
} from '../../../util/enum/api/accountTypes';
import { getCurrentAccount } from '../../../store/selectors/accountSelectors';
import { selectSettings } from '../../../store/selectors/settingsSelectors';

const BuyWizard = ({
  isModalOpen,
  handleCloseModal,
  unverifiedBankAccounts,
  isLockedPrice,
  counter,
  unity,
  isSegregated,
}) => {
  const [wizardTitle, setWizardTitle] = useState('Buy Wizard');
  const [wizardData, setWizardData] = useState(
    () => retrieveFromSessionStorage(WIZARD_DATA) || {},
  );
  const [modalSize, setModalSize] = useState('sm');
  const [isSupportOpen, setIsSupportOpen] = useState(false);
  const storageProduct = useSelector(selectStorageProduct);
  const skipChooseLocation = getWizardContent('ShortFlow');
  const restrictions = useRestrictionHook();
  const currentAccount = useSelector(getCurrentAccount);
  const settings = useSelector(selectSettings);
  const isPostPaidEnabled = settings?.IsPostPaidActive;
  const isIRAAccount =
    parseEnumType(accountTypeKeys, currentAccount.AccountType) ===
    accountTypes.IRA;
  const isPostPaidVisible = !isIRAAccount && isPostPaidEnabled;

  const setDataForWizard = (value) => {
    setWizardData(value);
    storeInSessionStorage(WIZARD_DATA, value);
  };

  useEffect(() => {
    if (isEmpty(wizardData?.storageProduct)) {
      setDataForWizard({ storageProduct });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageProduct]);

  const closeModal = () => {
    handleCloseModal();
    removeFromSessionStorage(WIZARD_DATA);
    removeFromSessionStorage(STATUS_NEW_ADDRESS_ADDED);
    removeFromSessionStorage(SIGNIFYD_SESSION_ID);
  };

  const steps = skipChooseLocation ? [BuyOrder] : [ChooseVault, BuyOrder];

  const closeSupport = () => {
    setIsSupportOpen(false);
    setModalSize('sm');
  };

  const openSupport = () => {
    setIsSupportOpen(true);
    setModalSize('lg');
  };

  return (
    <Modal
      title={wizardTitle}
      size={modalSize}
      isOpen={isModalOpen}
      close={closeModal}
      goBack={isSupportOpen && closeSupport}
      dataCy={
        wizardData?.selectedVault &&
        wizardData?.selectedVault?.LocationType !== 12
          ? 'container-buy-for-storage'
          : ''
      }
    >
      {isActionRestrictedHelper(restrictions, [
        restrictionTypes.LogonIsPrevented,
        restrictionTypes.RestrictBuy,
      ]) ? (
        <Restriction restrictions={restrictions} />
      ) : (
        <StepByStep sessionScopeStep={BUY_WIZARD}>
          {({ goStepForward, goStepBack }) =>
            steps.map((buyWizardStep) =>
              React.createElement(buyWizardStep, {
                handleBack: skipChooseLocation ? closeModal : goStepBack,
                handleNext: goStepForward,
                handleClose: closeModal,
                wizardData,
                setWizardData: setDataForWizard,
                setWizardTitle,
                openSupport,
                isSupportOpen,
                skipChooseLocation,
                unverifiedBankAccounts,
                isLockedPrice,
                counter,
                unity,
                isSegregated,
                isPostPaidVisible,
              }),
            )
          }
        </StepByStep>
      )}
    </Modal>
  );
};

BuyWizard.propTypes = {
  handleCloseModal: PropTypes.func,
  isModalOpen: PropTypes.bool,
  unverifiedBankAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      BankAccountUid: PropTypes.string,
      BankName: PropTypes.string,
      AccountMask: PropTypes.string,
      IsVerified: PropTypes.bool,
    }),
  ),
  isLockedPrice: PropTypes.bool,
  counter: PropTypes.number,
  unity: PropTypes.bool,
  isSegregated: PropTypes.bool,
};

export default BuyWizard;
