import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import HavingTroubleIsClosed from '../Components/HavingTroubleIsClosed';
import HavingTroubleIsOpen from '../Components/HavingTroubleIsOpen';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import { Phone, twoFaTypesEnum } from '../../../util/enum/api/twoFATypes';
import {
  TWO_FA_FORGOT_PASSWORD_IDENTITY_PAGE,
  TWO_FA_FORGOT_PASSWORD_IDENTITY_PHONE_PAGE,
} from '../../../constants/pages';
import { fetchTwoFaCustomerSupportIsOpen } from '../../../store/actions/twoFA/twoFAActions';
import { selectTwoFaIsCustomerSupportOpen } from '../../../store/selectors/twoFASelector';
import { selectIsLoadingByActionType } from '../../../store/selectors/loadingSelectors';
import { GET_TWO_FA_CUSTOMER_SUPPORT_IS_OPEN_LOADING } from '../../../store/actions/twoFA/twoFAActionConstants';
import SectionLoader from '../../../components/Loader/SectionLoader';

const TwoFaForgotPasswordHavingTroublePage = ({
  username,
  twoFaApplication,
  twoFaValue,
}) => {
  const dispatch = useDispatch();
  const { AuthenticationMethodType } = twoFaApplication;
  const history = useHistory();
  const isOpen = useSelector(selectTwoFaIsCustomerSupportOpen);
  const isLoading = useSelector(
    selectIsLoadingByActionType(GET_TWO_FA_CUSTOMER_SUPPORT_IS_OPEN_LOADING),
  );

  useEffect(() => {
    dispatch(fetchTwoFaCustomerSupportIsOpen());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBackClick = () => {
    if (
      parseEnumType(twoFaTypesEnum, AuthenticationMethodType) === Phone &&
      !twoFaValue?.phoneMessageType
    ) {
      return history.push(TWO_FA_FORGOT_PASSWORD_IDENTITY_PHONE_PAGE);
    }

    history.push(TWO_FA_FORGOT_PASSWORD_IDENTITY_PAGE);
  };

  if (isOpen) {
    return (
      <SectionLoader isLoading={isLoading}>
        <HavingTroubleIsOpen
          username={username}
          handleBackClick={handleBackClick}
        />
      </SectionLoader>
    );
  }

  return (
    <SectionLoader isLoading={isLoading}>
      <HavingTroubleIsClosed handleBackClick={handleBackClick} />
    </SectionLoader>
  );
};

TwoFaForgotPasswordHavingTroublePage.propTypes = {
  username: PropTypes.string,
  twoFaApplication: PropTypes.shape({
    AuthenticationMethodType: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
  twoFaValue: PropTypes.shape({
    phoneMessageType: PropTypes.string,
  }),
};

export default TwoFaForgotPasswordHavingTroublePage;
