import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as CopyIcon } from '../../../assets/images/svg/copy.svg';
import IconButton from '../../../components/IconButton/IconButton';
import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import { variables } from '../../../assets/styles/variables';

const CopyButtonStyle = styled(IconButton)`
  width: ${pxToRem(22)};
  height: ${pxToRem(22)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    width: ${pxToRemMd(22)};
    height: ${pxToRemMd(22)};
  }
`;

const CopyButton = ({ onClick }) => (
  <CopyButtonStyle onClick={onClick}>
    <CopyIcon />
  </CopyButtonStyle>
);

CopyButton.propTypes = {
  onClick: PropTypes.func,
};

export default CopyButton;
