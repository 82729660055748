import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import { SETTINGS_FETCH } from '../actions/settings/settingsActionConstants';
import {
  setSettings,
  setSettingsError,
} from '../actions/settings/settingsActions';
import { getSettingsRequest } from '../../request/settingsRequests';
import { rejectErrorCodeHelper } from '../../util/helpers/rejectErrorCodeHelper';

function* fetchSettings() {
  try {
    const { data } = yield call(getSettingsRequest);
    yield put(setSettings(data));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);

    yield put(setSettingsError(errorMessage));
  }
}

export default function* settingsSaga() {
  yield all([takeLatest(SETTINGS_FETCH, fetchSettings)]);
}
