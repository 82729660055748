import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import BuyForStorage from '../../BuyForStorage/BuyForStorage';
import BuyForDelivery from '../../BuyForDelivery/BuyForDelivery';
import { selectProductTiers } from '../../../store/selectors/productsSelector';

const BuyOrder = ({
  setWizardData,
  wizardData,
  handleBack,
  handleNext,
  handleClose,
  setWizardTitle,
  openSupport,
  isSupportOpen,
  skipChooseLocation,
  unverifiedBankAccounts,
  isSegregated,
  isPostPaidVisible,
}) => {
  const productTiers = useSelector(selectProductTiers);
  const { selectedVault } = wizardData;
  const { LocationType } = selectedVault;

  if (isEmpty(wizardData)) {
    return <div />;
  }

  if (!selectedVault) {
    return <div />;
  }

  if (LocationType === 12) {
    return (
      <BuyForDelivery
        closeModal={handleClose}
        setWizardData={setWizardData}
        wizardData={wizardData}
        handleBack={handleBack}
        handleNext={handleNext}
        setWizardTitle={setWizardTitle}
        openSupport={openSupport}
        isSupportOpen={isSupportOpen}
        skipChooseLocation={skipChooseLocation}
        unverifiedBankAccounts={unverifiedBankAccounts}
        tiers={productTiers?.Tiers}
        isSegregated={isSegregated}
        isPostPaidVisible={isPostPaidVisible}
      />
    );
  }

  return (
    <BuyForStorage
      closeModal={handleClose}
      setWizardData={setWizardData}
      wizardData={wizardData}
      handleBack={handleBack}
      handleNext={handleNext}
      setWizardTitle={setWizardTitle}
      skipChooseLocation={skipChooseLocation}
      tiers={productTiers?.Tiers}
      isSegregated={isSegregated}
      isPostPaidVisible={isPostPaidVisible}
    />
  );
};

BuyOrder.propTypes = {
  wizardData: PropTypes.shape({
    selectedVault: PropTypes.shape({
      LocationType: PropTypes.number,
      SymbolCode: PropTypes.string,
    }),
    storageProduct: PropTypes.shape({
      Locations: PropTypes.arrayOf(PropTypes.shape({})),
      SymbolCode: PropTypes.string,
    }),
  }),
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  handleClose: PropTypes.func,
  setWizardData: PropTypes.func,
  setWizardTitle: PropTypes.func,
  openSupport: PropTypes.func,
  isSupportOpen: PropTypes.bool,
  skipChooseLocation: PropTypes.bool,
  unverifiedBankAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      BankAccountUid: PropTypes.string,
      BankName: PropTypes.string,
      AccountMask: PropTypes.string,
      IsVerified: PropTypes.bool,
    }),
  ),
  isPostPaidVisible: PropTypes.bool,
  isSegregated: PropTypes.bool,
};

export default BuyOrder;
