import { variables } from '../variables';
import GothamBold from '../../fonts/GoldSilver/Gotham-Bold.woff2';
import GothamMedium from '../../fonts/GoldSilver/Gotham-Medium.woff2';
import GothamBook from '../../fonts/GoldSilver/Gotham-Book.woff2';

export const goldSilverColors = {
  colorPrimary: '#D89948',
  colorPrimaryLight: '#DD9F4F',
  colorPrimaryDark: '#C28434',
  colorWhite: '#ffffff',
  colorBlack: '#000000',
  colorRed: '#FF5028',
  colorGrey: '#f4f4f4',

  // Backgrounds
  colorBackgroundPrimary: '#ffffff',
  colorBackgroundSecondary: '#fafafa',
  colorWarningnBackground: '#FFECB5',

  // Typography Colors
  // Text
  colorTextPrimary: '#000000',
  colorTextSecondary: '#797979',
  colorTextContrast: '#ffffff',
  colorTextHeader: '#ffffff',
  colorTextLink: '#ffffff',

  colorSuccess: '#00876A',
  colorError: '#FF5028',
  colorWarning: '#E2930A',
  colorIncomplete: '#212528',

  // Links
  colorLinkPrimary: '#3881c2',
  colorLinkRegistration: '#003246', // semiDark'

  // Button Colors
  colorButtonPrimary: 'linear-gradient(90deg,#dd9f4f 0%,#c18333 100%)',
  colorButtonPrimaryText: '#ffffff',
  colorButtonPrimaryHover: 'linear-gradient(90deg,#c18333 0%,#dd9f4f 100%)',
  colorButtonPrimaryFocused: 'linear-gradient(90deg,#c18333 0%,#dd9f4f 100%)',
  colorButtonPrimaryHoverText: '#ffffff',
  colorButtonOutlined: '#ffffff',
  colorButtonOutlinedBorder: '#D89948',
  colorButtonOutlinedText: '#D89948',
  colorButtonDisabled: 'rgba(226, 147, 10, 0.5)',

  colorButtonDefault: '#ffffff',
  colorButtonDefaultText: '#003246',
  colorButtonDefaultBorder: '#003246',
  colorButtonDefaultHover: '#8b8b8b',
  colorButtonAddNewBank: '#ffffff',

  // Input
  colorInputBorder: '#c4c4c4',
  colorInputBackground: '#ffffff',
  colorInputBorderFocused: '#555',
  colorInputBorderActive: '#c8c8c8',
  colorInputBackgroundDisabled: '#EBF2F2',
  colorInputText: '#555',
  colorInputPlaceholder: '#4E7A8C',
  colorInputSelectItemFocused: '#f4f4f4',

  // Label
  colorLabel: '#555',

  // Modal
  colorModalBackground: 'rgba(0, 0, 0, 0.3)',

  // Tabs
  colorTabBackground: '#ffffff',
  colorTabText: '#D89948',
  colorTabSelectedBackground: '#D89948',
  colorTabSelectedText: '#ffffff',

  // Pills
  colorPillsBackground: '#ffffff',
  colorPillsText: '#000',
  colorPillsBackgroundActive: '#D89948',
  colorPillsTextActive: '#ffffff',

  // Filters
  colorFilterItemBackground: '#003246',
  colorFilterItemText: '#ffffff',
  colorFilterIcon: '#ffffff',

  // Icons
  colorIconPrimary: '#003246',
  colorIconSecondary: '#D89948',
  colorLoaderIcon: '#D89948',

  // Radio Button
  colorRadioButton: '#003246',
  colorRadioButtonWrapperBorderSelected: '#D89948',

  // Tooltip
  colorTooltipBackground: '#003246',
  colorTooltipText: '#ffffff',

  // Password Strength
  colorPasswordStrengthDefault: '#EBF2F2',
  colorPasswordStrengthWeak: '#FF5028',
  colorPasswordStrengthAverage: '#FDB942',
  colorPasswordStrengthGood: '#06BEE7',
  colorPasswordStrengthStrong: '#00876A',

  // Progress Bar Colors, Linear Gradient
  colorLinearGradientStart: '#FFC45E',
  colorLinearGradientEnd: '#D36E1C',
  colorProgressBarDefault: '#F4F4F4',
  colorProgressBarFilled: '#D89948',

  // Header Colors
  colorMainHeaderBackground: '#202020',
  colorSecondaryHeaderBackground: '#ffffff',
  colorHeaderLogoLight: '#ffffff',
  colorHeaderLogoDark: '#003246',

  // Navigation Colors
  colorNavigationItemHover: '#D89948',

  // Navigation Dropdown Color
  colorNavigationDropdownBackground: 'rgba(32, 38, 43, 0.9)',
  colorNavigationDropdownItemHoverBackground: 'rgba(7, 44, 100, 0.5)',

  // Account Colors
  colorAccountBackground: '#000000',
  colorAccountBorder: 'rgba(255,255,255, 0.2)',

  // Footer Colors
  colorFooterBackground: '#202020',
  colorFooterText: '#dddddd',

  // Horizontal Break and Borders, Separators
  colorBorderPrimary: '#c9d6db',
  colorBorderSecondary: '#D89948',

  // Table Colors
  colorTableHeaderBackground: '#f4f4f4',
  colorTableEvenRowBackground: '#fafafa',
  colorTableEvenRowHoverBackground: '#f4f4f4',
  colorTableSelectedBackground: 'rgba(226, 147, 10, 0.14)',
  colorHeadingRowItem: '#F0F5F6',

  // Shippment Status Colors
  colorShippmentSuccess: '#09846b',
  colorShippmentPending: '#E2930A',
  colorShippmentShipped: '#024f86',
  colorShippmentFailed: '#ff5028',

  // Address Status Colors
  colorVerifiedSuccess: '#09846b',
  colorNotVerified: '#8E8E8E',

  // Card Colors
  colorCardBackground: '#ffffff',
  colorCardSelected: 'rgba(226, 147, 10, 0.14)',

  // Scrollbar Colors
  colorScrollbarBackground: '#c2c5c6',

  // MakeOver Banner
  colorMakeOverBannerBackground: '#f5f5f5',
  colorMakeOverBanner: '#003246',

  // Pending Banner
  colorPendingBannerBackground: '#f1f5f6',

  // Onboarding
  colorOnboardingBoxBorder: '#f3f3f3',
  colorOnboardingBoxBackground: 'rgba(196, 196, 196, 0.2)',
  colorOnboardingTextPrimary: '#ffffff',

  // Empty Box
  colorEmptyBoxTextPrimary: '#C2C5C6',

  // Graphic
  colorGraphicGridLines: '#ebeff2',

  // Info Banner
  colorInfoBannerBackground: '#003246',
  colorInfoBannerText: '#ffffff',

  // Auth card
  colorAuthCardSubtitle: 'rgb(0, 50, 70)',

  // Registration
  colorRegistration: '#2B5769',

  // Pie chart
  colorPieOne: '#003246',
  colorPieTwo: '#D89948',
  colorPieThree: '#4E7A8C',
  colorPieFour: '#808285',

  // Sidebar Gradients
  colorSidebarBegin: '#444444',
  colorSidebarEnd: '#202020',
};

export const goldSilverFonts = `
  @font-face {
    font-family:  ${variables.family.goldSilver};
    src: url(${GothamBold}) format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family:  ${variables.family.goldSilver};
    src: url(${GothamMedium}) format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family:  ${variables.family.goldSilver};
    src: url(${GothamMedium}) format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family:  ${variables.family.goldSilver};
    src: url(${GothamBook}) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }

  *,
  *::after,
  *::before {
    font-family: ${variables.family.goldSilver};
  }
`;
