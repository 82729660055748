import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import config from '../../config';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';

const ReviewBarContainer = styled.div`
  padding: ${pxToRem(3)} ${pxToRem(16)};

  background-color: ${themeColors.colorTextPrimary};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(4)} ${pxToRemMd(24)};
  }
`;

const ReviewBarTitle = styled.p`
  ${({ isWhiteText }) =>
    isWhiteText
      ? `  color: ${themeColors.colorWhite};
`
      : ` color:${themeColors.colorTextContrast};
`}

  font-weight: 600;
`;

const ReviewBar = ({ title }) => {
  const isWhiteText = () => {
    const { brokerTheme } = config;
    if (brokerTheme === 'Contoso' || brokerTheme === 'LegacyPM') {
      return true;
    }
    return false;
  };

  return (
    <ReviewBarContainer>
      <ReviewBarTitle isWhiteText={isWhiteText()}>{title}</ReviewBarTitle>
    </ReviewBarContainer>
  );
};

ReviewBar.propTypes = {
  title: PropTypes.string,
};

export default ReviewBar;
