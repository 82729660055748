import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { parseEnumType } from '../../util/helpers/enumMappers';
import {
  accountSubTypeKeys,
  accountTypeKeys,
  IRATrusteesType,
} from '../../util/enum/api/accountTypes';
import { uFlexColumn } from '../../assets/styles/utility';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import themeColors from '../../assets/styles/themeColors';

const AccountCurrentContainer = styled.div`
  ${uFlexColumn};
  padding: ${pxToRem(16)};
  flex-shrink: 0;
  background-color: rgba(221, 229, 231, 0.5);
  border-bottom: 2px solid ${themeColors.colorTextPrimary};
  box-shadow: ${variables.shadow.boxShadow};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: ${pxToRemMd(16)};
    box-shadow: none;
  }
`;

const AccountCurrentLabel = styled.p`
  font-weight: 600;
  font-size: ${pxToRem(16)};
  line-height: 1.75;
  letter-spacing: 0;
  margin-bottom: ${pxToRem(16)};
  padding-bottom: ${pxToRem(8)};
  border-bottom: 2px solid rgba(0, 50, 70, 0.1);

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(16)};
    padding-bottom: ${pxToRemMd(8)};
    margin-bottom: ${pxToRemMd(12)};
  }
`;

const AccountCurrentName = styled.p`
  font-size: ${pxToRem(18)};
  line-height: 1.35;
  letter-spacing: 0;
  margin-bottom: ${pxToRem(4)};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(18)};
  }
`;

const AccountCurrentCode = styled.p`
  color: ${themeColors.colorTextSecondary};
`;

const AccountCurrent = ({ account }) => {
  const { t } = useTranslation();
  return (
    <AccountCurrentContainer>
      <AccountCurrentLabel>{t('account.currentAccount')}</AccountCurrentLabel>
      <AccountCurrentName>
        {String(account.DisplayName)
          .replace(`(${account.AccountCode})`, '')
          .trim()}
      </AccountCurrentName>
      <AccountCurrentCode>
        {account.IRATrusteesType && (
          <span>
            {t(
              `account.iraTrusteeTypes.${parseEnumType(
                IRATrusteesType,
                account.IRATrusteesType,
              )}`,
            )}
          </span>
        )}
        {account.AccountSubType && (
          <span>
            {t(
              `account.subtypes.${parseEnumType(
                accountSubTypeKeys,
                account.AccountSubType,
              )}`,
            )}
          </span>
        )}
        <span>
          {t(
            `account.types.${parseEnumType(
              accountTypeKeys,
              account.AccountType,
            )}`,
          )}
        </span>{' '}
        &#183; <span>{account.AccountCode}</span>
      </AccountCurrentCode>
    </AccountCurrentContainer>
  );
};

AccountCurrent.propTypes = {
  account: PropTypes.shape({
    DisplayName: PropTypes.string,
    AccountCode: PropTypes.string,
    AccountType: PropTypes.number,
    AccountSubType: PropTypes.number,
    IRATrusteesType: PropTypes.number,
  }),
};

export default AccountCurrent;
