import { parseEnumType } from '../util/helpers/enumMappers';

export const PENDING_STATUS = 'Pending';
export const APPROVED_STATUS = 'Approved';
export const REJECTED_STATUS = 'Rejected';
export const REQUIRES_ATTENTION_STATUS = 'RequiresAttention';
export const EXPIRED = 'Expired';
export const CLOSED = 'Closed';

export const accountStatusTypes = {
  Pending: 'Pending ',
  Approved: 'Approved',
  Rejected: 'Rejected',
  RequiresAttention: 'RequiresAttention',
  Expired: 'Expired',
  Closed: 'Closed',
  Err: 'Err',
};

export const isUserAccountApproved = (account) => {
  if (!account) {
    return false;
  }

  return account?.AccountStatus === 2;
};

export const accountStatusTypesArray = Object.keys(accountStatusTypes);

export const isUserAccountPendingApproval = (account) => {
  if (!account) {
    return true;
  }

  const status = parseEnumType(accountStatusTypesArray, account?.AccountStatus);
  return status === PENDING_STATUS || status === REQUIRES_ATTENTION_STATUS;
};

export const BASIC_ACCOUNT_STEP = 'BasicAccount';
export const VERIFY_ACCOUNT_STEP = 'VerifyAccount';
export const VERIFIED_POST_PAID = 'VerifiedPostPaid';
export const VERIFIED_NON_POST_PAID = 'NonVerifiedPostPaid';

export const nagDashboardSteps = {
  BasicAccount: 'BasicAccount ',
  VerifyAccount: 'VerifyAccount',
  NonVerifiedPostPaid: 'NonVerifiedPostPaid',
  VerifiedPostPaid: 'Executed',
};

export const nagDashboardStepsArray = Object.keys(nagDashboardSteps);

export const documentUploadStatusTypes = {
  1: 'NotUploaded',
  2: 'PendingReview',
  3: 'ReuploadNeeded',
  4: 'Approved',
};

export const uploadedList = [
  {
    title: 'Drivers license or other government ID',
    description: 'Re-upload requested. Please see your email for details.',
    buttonText: 'Upload ID',
    status: 'NotUploaded',
  },
  {
    title: 'Utility bill to verify your address',
    buttonText: 'Upload Utility bill',
    status: 'PendingReview',
  },
  {
    title: 'Trust documents showing your full name and the name of the trust ',
    buttonText: 'Upload trust documents',
    status: 'NotUploaded',
  },
];

export const UPGRADE_STATUS_TYPES = {
  NotUpgraded: 1,
  InProgress: 2,
  Upgraded: 3,
};

export const UPGRADE_STATUS_TYPES_LABELS = {
  [UPGRADE_STATUS_TYPES.NotUpgraded]: 'notUpraged',
  [UPGRADE_STATUS_TYPES.InProgress]: 'inProgress',
  [UPGRADE_STATUS_TYPES.Upgraded]: 'upgraded',
};

export const DEPOSIT_STATUS_TYPES = {
  NoDepositMade: 1,
  WaitingForVerification: 2,
  Pending: 3,
  Completed: 4,
};

export const DEPOSIT_STATUS_TYPES_LABELS = {
  [DEPOSIT_STATUS_TYPES.NoDepositMade]: 'NoDepositMade',
  [DEPOSIT_STATUS_TYPES.WaitingForVerification]: 'WaitingForVerification',
  [DEPOSIT_STATUS_TYPES.Pending]: 'Pending',
  [DEPOSIT_STATUS_TYPES.Completed]: 'Completed',
};
