import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useBrokerName } from '../../util/hooks/userBrokerName';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { uFlexCenter } from '../../assets/styles/utility';
import { variables } from '../../assets/styles/variables';

export const AuthWrapper = styled.div`
  ${uFlexCenter};
  flex-direction: column;
  padding: ${pxToRem(56)} ${pxToRem(24)} 0;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(70)} ${pxToRemMd(24)} ${pxToRemMd(24)};
  }
`;

export const AuthTitle = styled.h1`
  font-size: ${pxToRem(36)};
  margin-bottom: ${pxToRem(80)};
  font-weight: bold;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    text-align: left;
    font-size: ${pxToRemMd(24)};
    margin-bottom: ${pxToRemMd(48)};
    align-self: flex-start;
  }
`;

const Auth = ({ children }) => {
  const { t } = useTranslation();
  const brokerName = useBrokerName();

  return (
    <AuthWrapper>
      <AuthTitle>{t(`login.welcome`, { brokerName })}</AuthTitle>
      {children}
    </AuthWrapper>
  );
};

Auth.propTypes = {
  children: PropTypes.node,
};

export default Auth;
