import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import isEmpty from 'lodash.isempty';
import {
  selectBankAccountDetailsByRoutingNum,
  selectNewWireAccountData,
} from '../../../store/selectors/bankAccountSelectors';
import ReviewBar from '../../ReviewBar/ReviewBar';
import { separateByUppercase } from '../../../util/helpers/stringHelpers';
import NewWireWithdrawalHeading from './NewWireWithdrawalHeading';
import { USA_COUNTRY_LABEL } from '../../../util/constants';
import SectionLoader from '../../Loader/SectionLoader';
import { getWizardContent } from '../../../util/helpers/wizardHelpers';
import {
  ACCOUNT_DETAILS_INFO,
  BANK_DETAILS_BY_ROUTING_NUMBER,
  NEW_WIRE_ACC_DETAILS,
  NON_US_COUNTRY_SELECT_MODAL_INFO,
} from '../../../constants/sessionStorage';
import useGetCountries from '../../../util/hooks/useGetCountries';
import ModalButtons from '../../Modal/ModalButtons';
import ModalBody from '../../Modal/ModalBody';
import InformationList from '../../InformationList/InformationList';
import InformationListItem from '../../InformationList/InformationListItem';
import { ACC_PURPOSE_WIRE } from '../../../util/enum/api/bankAccountTypes';

const ReviewNewBankAccountModal = ({ sbsBack, sbsOnConfirm, isLoading }) => {
  const { t } = useTranslation();
  const [bankAccountDetails, setBankAccountDetails] = useState({});
  const [newWireAccount, setNewWireAccount] = useState({});
  const countries = useGetCountries();
  const bankDetails = useSelector(selectBankAccountDetailsByRoutingNum);
  const newWireAcc = useSelector(selectNewWireAccountData);

  useEffect(() => {
    if (!isEmpty(bankDetails)) {
      setBankAccountDetails(bankDetails);
      setNewWireAccount(newWireAcc);
    } else if (getWizardContent(BANK_DETAILS_BY_ROUTING_NUMBER)) {
      const { country } = getWizardContent(BANK_DETAILS_BY_ROUTING_NUMBER);
      setNewWireAccount({ ...getWizardContent(NEW_WIRE_ACC_DETAILS), country });
      setBankAccountDetails(
        getWizardContent(BANK_DETAILS_BY_ROUTING_NUMBER).routingNumData,
      );
    } else {
      const { accountType } = getWizardContent(ACCOUNT_DETAILS_INFO);

      setNewWireAccount({
        ...getWizardContent(NON_US_COUNTRY_SELECT_MODAL_INFO),
        ...getWizardContent(ACCOUNT_DETAILS_INFO),
        accountType: accountType.label,
      });
    }
  }, [bankDetails, newWireAcc]);

  const handleConfirm = () => {
    const countryIso3Code = countries.find(
      (country) => country.Caption === newWireAccount?.country,
    );
    const data =
      newWireAccount.country === USA_COUNTRY_LABEL
        ? {
            BankAccountUid: uuid(),
            InternationalBank: null,
            DomesticBank: {
              Name: bankAccountDetails.Name,
              Address: {
                AddressLine: bankAccountDetails.Address.AddressLine,
                AddressLine2: bankAccountDetails.Address.AddressLine2,
                City: bankAccountDetails.Address.City,
                PostalCode: bankAccountDetails.Address.PostalCode,
                StateRegion: bankAccountDetails.Address.StateRegion,
                CountryIso3Code: bankAccountDetails.Address.CountryIso3Code,
              },
              Phone: bankAccountDetails.Phone,
              ABARoutingNumber: bankAccountDetails.ABARoutingNumber,
              ACHRoutingNumber: bankAccountDetails.ACHRoutingNumber,
              WireRoutingNumber: bankAccountDetails.WireRoutingNumber,
            },
            NameOnAccount: newWireAccount.accountHolderName,
            AccountNumber: newWireAccount.accountNumber,
            BankAccountType: newWireAccount.accountType,
            PurposeType: ACC_PURPOSE_WIRE,
            PlaidDetails: null,
            ObiMessages: {
              ObiMessage1:
                newWireAccount.ObiMessage1 !== ''
                  ? newWireAccount.ObiMessage1
                  : null,
              ObiMessage2:
                newWireAccount.ObiMessage2 !== ''
                  ? newWireAccount.ObiMessage2
                  : null,
              ObiMessage3:
                newWireAccount.ObiMessage3 !== ''
                  ? newWireAccount.ObiMessage3
                  : null,
              ObiMessage4:
                newWireAccount.ObiMessage4 !== ''
                  ? newWireAccount.ObiMessage4
                  : null,
            },
          }
        : {
            BankAccountUid: uuid(),
            InternationalBank: {
              Name: newWireAccount.bankName,
              Address: {
                AddressLine: newWireAccount.bankStreetAddress,
                AddressLine2: null,
                City: newWireAccount.bankCity,
                PostalCode: newWireAccount.bankPostalCode,
                StateRegion: newWireAccount.bankState,
                CountryIso3Code: countryIso3Code?.Iso3Code,
              },
              SwiftCode: newWireAccount.swiftCode,
            },
            DomesticBank: null,
            NameOnAccount: newWireAccount.accountHolderName,
            AccountNumber: newWireAccount.accountNumber,
            BankAccountType: newWireAccount.accountType,
            PurposeType: ACC_PURPOSE_WIRE,
            PlaidDetails: null,
            ObiMessages: {
              ObiMessage1:
                newWireAccount.ObiMessage1 !== ''
                  ? newWireAccount.ObiMessage1
                  : null,
              ObiMessage2:
                newWireAccount.ObiMessage2 !== ''
                  ? newWireAccount.ObiMessage2
                  : null,
              ObiMessage3:
                newWireAccount.ObiMessage3 !== ''
                  ? newWireAccount.ObiMessage3
                  : null,
              ObiMessage4:
                newWireAccount.ObiMessage4 !== ''
                  ? newWireAccount.ObiMessage4
                  : null,
            },
          };
    sbsOnConfirm(data);
  };

  const reviewData = [
    {
      label: t('bankAccounts.bankCountryLabel'),
      value: newWireAccount.country,
    },
    {
      label: t('bankAccounts.abaRoutingNumberLabel'),
      value: bankAccountDetails.ABARoutingNumber,
    },
    {
      label: t('bankAccounts.bankNameLabel'),
      value: bankAccountDetails.Name,
    },
    {
      label: t('bankAccounts.bankNameLabel'),
      value: newWireAccount.bankName,
    },
    {
      label: t('bankAccounts.swiftCodeLabel'),
      value: newWireAccount.swiftCode,
    },
    {
      label: t('bankAccounts.streetAddressLabel'),
      value: newWireAccount.bankStreetAddress,
    },
    {
      label: t('bankAccounts.cityLabel'),
      value: newWireAccount.bankCity,
    },
    {
      label: t('bankAccounts.stateProvinceLabel'),
      value: newWireAccount.bankState,
    },
    {
      label: t('bankAccounts.postalCodeLabel'),
      value: newWireAccount.bankPostalCode,
    },
    {
      label: t('bankAccounts.accountHolderLabel'),
      value: newWireAccount.accountHolderName,
    },
    {
      label: t('bankAccounts.accountTypeLabel'),
      value: newWireAccount.accountType
        ? separateByUppercase(newWireAccount.accountType)
        : null,
    },
    {
      label: t('bankAccounts.accountNumberLabel'),
      value: newWireAccount.accountNumber,
    },
    {
      label: t('bankAccounts.wireWithdrawal.obi1'),
      value: newWireAccount.ObiMessage1,
    },
    {
      label: t('bankAccounts.wireWithdrawal.obi2'),
      value: newWireAccount.ObiMessage2,
    },
    {
      label: t('bankAccounts.wireWithdrawal.obi3'),
      value: newWireAccount.ObiMessage3,
    },
    {
      label: t('bankAccounts.wireWithdrawal.obi4'),
      value: newWireAccount.ObiMessage4,
    },
  ];

  return (
    <SectionLoader isLoading={isLoading}>
      <ReviewBar title={t('bankAccounts.pleaseReviewBankDetails')} />
      <ModalBody>
        <NewWireWithdrawalHeading />
        <InformationList withBackground noMargin>
          {reviewData.map(({ label, value }) => (
            <InformationListItem
              key={uuid()}
              fullWidth
              label={label}
              value={value}
            />
          ))}
        </InformationList>
        <ModalButtons
          isHorizontal
          marginTop
          secondaryButtonProps={{ onClick: sbsBack }}
          primaryButtonProps={{
            label: t('common.confirm'),
            onClick: handleConfirm,
          }}
        />
      </ModalBody>
    </SectionLoader>
  );
};

ReviewNewBankAccountModal.propTypes = {
  sbsBack: PropTypes.func,
  sbsOnConfirm: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default ReviewNewBankAccountModal;
