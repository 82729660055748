import {
  createClearType,
  createErrorType,
  createFetchType,
  createLoadingType,
  createSubmitType,
  createSuccessType,
  createUpdateType,
} from '../actionHelpers';

// buy for storage
const BUY_PRODUCT_FOR_STORAGE_SCOPE = 'BUY_PRODUCT_FOR_STORAGE';
export const BUY_PRODUCT_FOR_STORAGE = createUpdateType(
  BUY_PRODUCT_FOR_STORAGE_SCOPE,
);
export const BUY_PRODUCT_FOR_STORAGE_SUCCESS = createSuccessType(
  BUY_PRODUCT_FOR_STORAGE_SCOPE,
);
export const BUY_PRODUCT_FOR_STORAGE_ERROR = createErrorType(
  BUY_PRODUCT_FOR_STORAGE_SCOPE,
);
export const BUY_PRODUCT_FOR_STORAGE_LOADING = createLoadingType(
  BUY_PRODUCT_FOR_STORAGE_SCOPE,
);

export const BUY_PRODUCT_FOR_STORAGE_STATE_CLEAR = createClearType(
  'BUY_PRODUCT_FOR_STORAGE_STATE',
);

// buy for delivery
const BUY_PRODUCT_FOR_DELIVERY_SCOPE = 'BUY_PRODUCT_FOR_DELIVERY';
export const BUY_PRODUCT_FOR_DELIVERY = createUpdateType(
  BUY_PRODUCT_FOR_DELIVERY_SCOPE,
);
export const BUY_PRODUCT_FOR_DELIVERY_SUCCESS = createSuccessType(
  BUY_PRODUCT_FOR_DELIVERY_SCOPE,
);
export const BUY_PRODUCT_FOR_DELIVERY_ERROR = createErrorType(
  BUY_PRODUCT_FOR_DELIVERY_SCOPE,
);
export const BUY_PRODUCT_FOR_DELIVERY_LOADING = createLoadingType(
  BUY_PRODUCT_FOR_DELIVERY_SCOPE,
);

// fractional conversion
const FRACTIONAL_CONVERSION_SCOPE = 'FRACTIONAL_CONVERSION';
export const FRACTIONAL_CONVERSION_SUBMIT = createSubmitType(
  FRACTIONAL_CONVERSION_SCOPE,
);

// ira entrust withdraw
const IRA_ENTRUST_WITHDRAW_SCOPE = 'IRA_ENTRUST_WITHDRAW';
export const IRA_ENTRUST_WITHDRAW_SUBMIT = createSubmitType(
  IRA_ENTRUST_WITHDRAW_SCOPE,
);

// ira fee withdrawal
const IRA_FEE_WITHDRAWAL_SCOPE = 'IRA_FEE_WITHDRAWAL';
export const IRA_FEE_WITHDRAWAL_SUBMIT = createSubmitType(
  IRA_FEE_WITHDRAWAL_SCOPE,
);
export const IRA_FEE_WITHDRAWAL_LOADING = createLoadingType(
  IRA_FEE_WITHDRAWAL_SCOPE,
);

const UPDATE_ACH_DEPOSIT_SCOPE = 'UPDATE_ACH_DEPOSIT';
export const UPDATE_ACH_DEPOSIT = createUpdateType(UPDATE_ACH_DEPOSIT_SCOPE);
export const UPDATE_ACH_DEPOSIT_SUCCESS = createSuccessType(
  UPDATE_ACH_DEPOSIT_SCOPE,
);
export const UPDATE_ACH_DEPOSIT_ERROR = createErrorType(
  UPDATE_ACH_DEPOSIT_SCOPE,
);
export const UPDATE_ACH_DEPOSIT_LOADING = createLoadingType(
  UPDATE_ACH_DEPOSIT_SCOPE,
);

const UPDATE_WIRE_WITHDRAWAL_SCOPE = 'UPDATE_WIRE_WITHDRAWAL';
export const UPDATE_WIRE_WITHDRAWAL = createUpdateType(
  UPDATE_WIRE_WITHDRAWAL_SCOPE,
);
export const UPDATE_WIRE_WITHDRAWAL_SUCCESS = createSuccessType(
  UPDATE_WIRE_WITHDRAWAL_SCOPE,
);
export const UPDATE_WIRE_WITHDRAWAL_ERROR = createErrorType(
  UPDATE_WIRE_WITHDRAWAL_SCOPE,
);
export const UPDATE_WIRE_WITHDRAWAL_LOADING = createLoadingType(
  UPDATE_WIRE_WITHDRAWAL_SCOPE,
);

const UPDATE_CHECK_WITHDRAWAL_SCOPE = 'UPDATE_CHECK_WITHDRAWAL';
export const UPDATE_CHECK_WITHDRAWAL = createUpdateType(
  UPDATE_CHECK_WITHDRAWAL_SCOPE,
);
export const UPDATE_CHECK_WITHDRAWAL_SUCCESS = createSuccessType(
  UPDATE_CHECK_WITHDRAWAL_SCOPE,
);
export const UPDATE_CHECK_WITHDRAWAL_ERROR = createErrorType(
  UPDATE_CHECK_WITHDRAWAL_SCOPE,
);
export const UPDATE_CHECK_WITHDRAWAL_LOADING = createLoadingType(
  UPDATE_CHECK_WITHDRAWAL_SCOPE,
);

export const WITHDRAWAL_WIZARD_STATE_RESET = createClearType(
  UPDATE_CHECK_WITHDRAWAL_SCOPE,
);

const VERIFY_BFD = 'VERIFY_BFD';
export const VERIFY_BUY_FOR_DELIVERY = createUpdateType(VERIFY_BFD);
export const VERIFY_BUY_FOR_DELIVERY_LOADING = createLoadingType(VERIFY_BFD);
export const VERIFY_BUY_FOR_DELIVERY_SUCCESS = createSuccessType(VERIFY_BFD);
export const VERIFY_BUY_FOR_DELIVERY_ERROR = createErrorType(VERIFY_BFD);

const GET_QUOTES_STORAGE = 'GET_QUOTES_STORAGE';
export const GET_QUOTES_FOR_BUY_STORAGE = createFetchType(GET_QUOTES_STORAGE);
export const GET_QUOTES_FOR_ORDER_STORAGE_LOADING = createLoadingType(
  GET_QUOTES_STORAGE,
);
export const GET_QUOTES_FOR_ORDER_STORAGE_SUCCESS = createSuccessType(
  GET_QUOTES_STORAGE,
);
export const GET_QUOTES_FOR_ORDER_STORAGE_ERROR = createErrorType(
  GET_QUOTES_STORAGE,
);
export const GET_QUOTES_STORAGE_EMPTY = createClearType(GET_QUOTES_STORAGE);

const EXECUTE_QUOTES_STORAGE = 'EXECUTE_QUOTES_STORAGE';
export const EXECUTE_QUOTES_FOR_BUY_STORAGE = createSubmitType(
  EXECUTE_QUOTES_STORAGE,
);
export const EXECUTE_QUOTES_FOR_BUY_STORAGE_LOADING = createLoadingType(
  EXECUTE_QUOTES_STORAGE,
);
export const EXECUTE_QUOTES_FOR_ORDER_STORAGE_SUCCESS = createSuccessType(
  GET_QUOTES_STORAGE,
);
export const EXECUTE_QUOTES_FOR_ORDER_STORAGE_ERROR = createErrorType(
  GET_QUOTES_STORAGE,
);
export const EXECUTE_QUOTES_STORAGE_EMPTY = createClearType(GET_QUOTES_STORAGE);

const GET_QUOTES_DELIVERY = 'GET_QUOTES_DELIVERY';
export const GET_QUOTES_FOR_ORDER_DELIVERY_BUY = createFetchType(
  GET_QUOTES_DELIVERY,
);
export const GET_QUOTES_FOR_ORDER_DELIVERY_LOADING = createLoadingType(
  GET_QUOTES_DELIVERY,
);
export const GET_QUOTES_FOR_ORDER_DELIVERY_SUCCESS = createSuccessType(
  GET_QUOTES_DELIVERY,
);
export const GET_QUOTES_FOR_ORDER_DELIVERY_ERROR = createErrorType(
  GET_QUOTES_DELIVERY,
);
export const GET_QUOTES_DELIVERY_EMPTY = createClearType(GET_QUOTES_DELIVERY);

const GET_QUOTES_SELL_SCOPE = 'GET_QUOTES_SELL';
export const GET_QUOTES_FOR_SELL = createFetchType(GET_QUOTES_SELL_SCOPE);
export const GET_QUOTES_FOR_SELL_LOADING = createLoadingType(
  GET_QUOTES_SELL_SCOPE,
);
export const GET_QUOTES_FOR_SELL_SUCCESS = createSuccessType(
  GET_QUOTES_SELL_SCOPE,
);
export const GET_QUOTES_FOR_SELL_ERROR = createErrorType(GET_QUOTES_SELL_SCOPE);
export const GET_QUOTES_SELL_EMPTY = createClearType(GET_QUOTES_SELL_SCOPE);

const EXECUTE_QUOTES_SELL = 'EXECUTE_QUOTES_SELL';
export const EXECUTE_QUOTES_FOR_SELL = createSubmitType(EXECUTE_QUOTES_SELL);
export const EXECUTE_QUOTES_FOR_SELL_LOADING = createLoadingType(
  EXECUTE_QUOTES_SELL,
);
export const EXECUTE_QUOTES_FOR_SELL_SUCCESS = createSuccessType(
  EXECUTE_QUOTES_SELL,
);
export const EXECUTE_QUOTES_FOR_SELL_ERROR = createErrorType(
  EXECUTE_QUOTES_SELL,
);
export const EXECUTE_QUOTES_SELL_EMPTY = createClearType(EXECUTE_QUOTES_SELL);
