import isEmpty from 'lodash.isempty';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InformationList from '../../../InformationList/InformationList';
import InformationListItem from '../../../InformationList/InformationListItem';
import Paragraph from '../../../Paragraph/Paragraph';
import { formatMailingAddress } from '../../../../util/helpers/addressHelper';

const EquityCheckTab = ({ checkDetails, isPrefunded }) => {
  const { t } = useTranslation();

  if (isEmpty(checkDetails)) {
    return null;
  }

  return (
    <>
      {!isPrefunded && (
        <Paragraph marginBottom={24} fontSize={14}>
          {t('depositWizard.equityCheck.textOne')}
        </Paragraph>
      )}
      <InformationList withContainer ignoreBackground>
        <InformationListItem
          label={t('depositWizard.equityCheck.payableTo')}
          value={checkDetails.PayableTo}
        />
        <InformationListItem
          label={t('depositWizard.equityCheck.memo')}
          value={
            <>
              {checkDetails.Memo}
              {isPrefunded && (
                <>
                  <br />
                  {t('depositWizard.equityCheck.storageFeeBalance')}
                </>
              )}
            </>
          }
        />
      </InformationList>
      <InformationList ignoreBackground whiteBackground withContainer>
        <InformationListItem
          label={t('depositWizard.equityCheck.sendCheckTo')}
          noValue
        />
      </InformationList>
      <InformationList withContainer ignoreBackground>
        {!isPrefunded ? (
          <InformationList>
            <InformationListItem
              label={t('depositWizard.equityCheck.mailing')}
              value={
                <span>
                  {formatMailingAddress(checkDetails.Addresses.MailingAddress)}
                </span>
              }
            />
            {checkDetails.Addresses.Overnight && (
              <InformationListItem
                label={t('depositWizard.equityCheck.overnight')}
                value={
                  <span>
                    {formatMailingAddress(checkDetails.Addresses.Overnight)}
                  </span>
                }
              />
            )}
          </InformationList>
        ) : (
          <InformationList>
            {checkDetails.Addresses.FundingCheckMailingAddress && (
              <InformationListItem
                label={t(
                  'depositWizard.equityCheck.fundingCheckMailingAddress',
                )}
                value={
                  <span>
                    {formatMailingAddress(
                      checkDetails.Addresses.FundingCheckMailingAddress,
                    )}
                  </span>
                }
              />
            )}
            {checkDetails.Addresses
              .FundingCheckMailingAddressThirdPartyShipper && (
              <InformationListItem
                label={t('depositWizard.equityCheck.mailingAddress')}
                value={
                  <span>
                    {formatMailingAddress(
                      checkDetails.Addresses
                        .FundingCheckMailingAddressThirdPartyShipper,
                    )}
                  </span>
                }
              />
            )}
          </InformationList>
        )}
      </InformationList>
    </>
  );
};

EquityCheckTab.propTypes = {
  checkDetails: PropTypes.shape({
    PayableTo: PropTypes.string,
    Memo: PropTypes.string,
    Addresses: PropTypes.shape({
      FundingCheckMailingAddress: PropTypes.shape({
        Receipient: PropTypes.string,
        Attn: PropTypes.string,
        AddressLine: PropTypes.string,
        City: PropTypes.string,
        PostalCode: PropTypes.string,
        StateRegion: PropTypes.string,
      }),
      MailingAddress: PropTypes.shape({
        Receipient: PropTypes.string,
        Attn: PropTypes.string,
        AddressLine: PropTypes.string,
        City: PropTypes.string,
        PostalCode: PropTypes.string,
        StateRegion: PropTypes.string,
      }),
      Overnight: PropTypes.shape({
        Receipient: PropTypes.string,
        Attn: PropTypes.string,
        AddressLine: PropTypes.string,
        City: PropTypes.string,
        StateRegion: PropTypes.string,
        PostalCode: PropTypes.string,
      }),
      FundingCheckMailingAddressThirdPartyShipper: PropTypes.shape({
        Receipient: PropTypes.string,
        Attn: PropTypes.string,
        AddressLine: PropTypes.string,
        City: PropTypes.string,
        PostalCode: PropTypes.string,
        StateRegion: PropTypes.string,
      }),
    }),
  }),
  isPrefunded: PropTypes.bool,
};

export default EquityCheckTab;
