import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import i18next from 'i18next';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  EMAIL_LOGIN_PAGE,
  INVITE_USER_CREDENTIALS_PAGE,
  INVITE_USER_IDENTITY,
  INVITE_USER_TWO_FA_VERIFY_IDENTITY,
  INVITE_USER_TWO_FA_VERIFY_IDENTITY_PHONE,
} from '../../constants/pages';
import {
  ERROR,
  INVITE_EMAIL,
  USER_INVITE_STATUS_MODAL,
} from '../../constants/sessionStorage';
import { fetchUsernames } from '../../store/actions/login/loginActions';
import {
  selectTokens,
  selectTwoFactorAuthenticationResponse,
} from '../../store/selectors/loginSelectors';
import SectionLoader from '../../components/Loader/SectionLoader';
import { selectIsLoadingByActionTypes } from '../../store/selectors/loadingSelectors';
import RegisterStep from '../../components/RegisterStep/RegisterStep';
import FormCard from '../../components/FormCard/FormCard';
import {
  selectIsReCaptchaEnabled,
  selectSettings,
} from '../../store/selectors/settingsSelectors';
import {
  removeFromSessionStorage,
  retrieveFromSessionStorage,
} from '../../util/helpers/sessionStorageHelper';
import { JWT_REFRESH_TOKEN, JWT_TOKEN } from '../../constants/localStorage';
import { inviteUserEmail } from '../../store/actions/user/userActions';

import { useSessionStorageState } from '../../util/hooks/useSessionStorageState';
import StatusModal from '../../components/Modals/StatusModal';
import { closeWizardContent } from '../../util/helpers/wizardHelpers';
import { INVITE_USER_EMAIL_LOADING } from '../../store/actions/user/userActionConstants';
import TextField from '../../components/InputFields/TextField';
import config from '../../config';
import FormContainer from '../../components/FormContainer/FormContainer';
import ModalButtons from '../../components/Modal/ModalButtons';

const Confirm = ({ setCurrentStep, handleNext, userInviteId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const tokens = useSelector(selectTokens);
  const brokerSettings = useSelector(selectSettings);
  const isLoading = useSelector(
    selectIsLoadingByActionTypes([INVITE_USER_EMAIL_LOADING]),
  );
  const twoFaAuthenticationResponse = useSelector(
    selectTwoFactorAuthenticationResponse,
  );

  const { IsBroker2FAActive } = brokerSettings;

  const [error, setError] = useSessionStorageState(ERROR, '');
  const [statusModal, setStatusModal] = useSessionStorageState(
    USER_INVITE_STATUS_MODAL,
    false,
  );

  const [email, setEmail] = useState(
    () => retrieveFromSessionStorage(INVITE_EMAIL) || '',
  );
  const reCaptchaRef = React.createRef();
  const isReCaptchaEnabled = useSelector(selectIsReCaptchaEnabled);

  useEffect(() => {
    if (userInviteId) {
      const decodedId = decodeURIComponent(userInviteId);
      dispatch(
        inviteUserEmail({
          userInviteId: decodedId,
          setEmail,
          setError,
          setStatusModal,
        }),
      );
    }
  }, [userInviteId]); // eslint-disable-line

  const onSuccess = () => {
    handleNext(INVITE_USER_CREDENTIALS_PAGE);
  };
  const handleSubmit = async (values, { setFieldError }) => {
    const formUserInvite = true;
    if (isReCaptchaEnabled) {
      const token = await reCaptchaRef.current.executeAsync();
      reCaptchaRef.current.reset();
      dispatch(
        fetchUsernames({
          email,
          token,
          setFieldError,
          onSuccess,
          formUserInvite,
        }),
      );
      return;
    }
    dispatch(
      fetchUsernames({ email, setFieldError, onSuccess, formUserInvite }),
    );
  };

  useEffect(() => {
    const jwtRefreshToken = retrieveFromSessionStorage(JWT_REFRESH_TOKEN);
    const jwtToken = retrieveFromSessionStorage(JWT_TOKEN);

    if (jwtRefreshToken && jwtToken) {
      removeFromSessionStorage(JWT_TOKEN);
      removeFromSessionStorage(JWT_REFRESH_TOKEN);
    }
  }, []);

  useEffect(() => {
    setCurrentStep(0);
  }, [setCurrentStep]);

  useEffect(() => {
    function redirectClient() {
      if (!tokens.RefreshToken && !tokens.JwtToken) {
        return;
      }

      if (tokens.RefreshToken) {
        if (IsBroker2FAActive && twoFaAuthenticationResponse) {
          if (twoFaAuthenticationResponse.AuthenticationMethodType === 2) {
            return history.push(INVITE_USER_TWO_FA_VERIFY_IDENTITY_PHONE);
          }
          return history.push(INVITE_USER_TWO_FA_VERIFY_IDENTITY);
        }

        return history.push(INVITE_USER_IDENTITY);
      }
    }

    redirectClient();
  }, [IsBroker2FAActive, history, tokens, twoFaAuthenticationResponse]);

  const initialValues = {
    email: '',
  };

  return (
    <RegisterStep title={i18next.t('inviteUser.confirm.title')}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <SectionLoader isLoading={isLoading}>
          <Form>
            <FormContainer>
              <FormCard title={i18next.t('inviteUser.confirm.subtitle')}>
                <FormContainer maxWidth={450}>
                  <Field
                    component={TextField}
                    name="email"
                    label={i18next.t('inviteUser.confirm.email')}
                    disabled
                    value={email}
                  />
                </FormContainer>
              </FormCard>
              <ModalButtons
                isHorizontal
                mediumButtons
                secondaryButtonProps={{
                  onClick: () => history.push(EMAIL_LOGIN_PAGE),
                }}
                primaryButtonProps={{
                  type: 'submit',
                  label: i18next.t('common.confirm'),
                }}
              />
            </FormContainer>
          </Form>
          {error && statusModal && (
            <StatusModal
              isOpen={statusModal}
              hasError={error}
              close={() => {
                setStatusModal(false);
                setError('');
                closeWizardContent(ERROR);
                closeWizardContent(INVITE_EMAIL);
                closeWizardContent(USER_INVITE_STATUS_MODAL);
                history.push(EMAIL_LOGIN_PAGE);
              }}
              text={error}
              modalTitle={i18next.t('inviteUser.confirm.statusModal')}
              backButtonText={i18next.t('common.ok')}
              onButtonClick={() => {
                setStatusModal(false);
                setError('');
                closeWizardContent(ERROR);
                closeWizardContent(INVITE_EMAIL);
                closeWizardContent(USER_INVITE_STATUS_MODAL);
                history.push(EMAIL_LOGIN_PAGE);
              }}
            />
          )}
          {isReCaptchaEnabled && (
            <ReCAPTCHA
              ref={reCaptchaRef}
              sitekey={config.recaptcha.siteKey}
              size="invisible"
            />
          )}
        </SectionLoader>
      </Formik>
    </RegisterStep>
  );
};

Confirm.propTypes = {
  setCurrentStep: PropTypes.func,
  handleNext: PropTypes.func,
  formValues: PropTypes.shape({}),
  userInviteId: PropTypes.string,
};

export default Confirm;
