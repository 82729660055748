import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CurrencyField from '../../../InputFields/CurrencyField';

import { getCurrentAccountUid } from '../../../../store/selectors/accountSelectors';
import { verifyBankAccount } from '../../../../store/actions/bankAccount/bankAccountActions';
import { selectBankAccount } from '../../../../store/selectors/bankAccountSelectors';
import SectionLoader from '../../../Loader/SectionLoader';
import { formatMoneyNumeral } from '../../../../util/helpers/numeralHelpers';
import { VerifyBankAccountAttemptsError } from './VerifyBankAccountAttemptsError';
import { verifyBankAccountValidationSchema } from '../../../../validation/verifyBankAccountValidationSchema';
import ModalBody from '../../../Modal/ModalBody';
import Paragraph from '../../../Paragraph/Paragraph';
import ModalButtons from '../../../Modal/ModalButtons';
import TwoColumnList from '../../../TwoColumnList/TwoColumnList';
import VerifyBankAccountImage from './VerifyBankAccountImage';
import loadSignifydDeviceFingerprintScript from '../../../../signifydDeviceFingerprint';
import { storeInSessionStorage } from '../../../../util/helpers/sessionStorageHelper';
import { SIGNIFYD_SESSION_ID } from '../../../../constants/sessionStorage';

const VerifyBankAccount = ({ handleNext, bankAccountUid, setError }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showAttempts, setShowAttempts] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const accountUid = useSelector(getCurrentAccountUid);
  const bankAccount = useSelector(selectBankAccount(bankAccountUid));

  useEffect(() => {
    loadSignifydDeviceFingerprintScript();
    const script = document.getElementById('sig-api');
    storeInSessionStorage(SIGNIFYD_SESSION_ID, script.dataset.orderSessionId);
  }, []);

  const onVerifySubmit = ({ AmountOne, AmountTwo }) => {
    const data = {
      AmountOne: parseFloat(AmountOne),
      AmountTwo: parseFloat(AmountTwo),
    };
    if (data.AmountOne && data.AmountTwo && accountUid && bankAccountUid) {
      dispatch(
        verifyBankAccount({
          accountUid,
          bankAccountUid,
          data,
          setShowAttempts,
          setIsLoading,
          setError,
          handleNext,
        }),
      );
    }
  };

  const amountHandler = useCallback(
    (setFieldValue, fieldName, value, setFieldTouched) => {
      setFieldTouched({
        [fieldName]: true,
      });

      if (value) {
        const num = `${value}`.split('.');
        setFieldValue(fieldName, `0.${num.length > 1 ? num[1] : num[0]}`);
        return;
      }
      if (value === undefined) {
        setFieldValue(fieldName, '0.0');
        return;
      }
      if (value === '') {
        setFieldValue(fieldName, '0.0');
        return;
      }
      setFieldValue(fieldName, value.floatValue);
    },
    [],
  );

  return (
    <SectionLoader isLoading={isLoading}>
      <ModalBody>
        <VerifyBankAccountImage />
        <Paragraph labelColor centerText bold marginBottom={32}>
          {t('bankAccounts.verify.enterAmount')}
        </Paragraph>
        <Paragraph labelColor centerText marginBottom={32}>
          {t('bankAccounts.verify.wontBeCharged')}
        </Paragraph>
        <Formik
          initialValues={{
            AmountOne: '0.00',
            AmountTwo: '0.00',
          }}
          onSubmit={onVerifySubmit}
          validationSchema={verifyBankAccountValidationSchema}
          initialTouched={{
            AmountOne: true,
            AmountTwo: true,
          }}
        >
          {({ setFieldValue, values, setFieldTouched }) => (
            <Form>
              <TwoColumnList noTextAlign largeGap>
                <CurrencyField
                  name="AmountOne"
                  label={t('bankAccounts.verify.amountOne')}
                  placeholder={formatMoneyNumeral(0)}
                  onChange={(value) => {
                    amountHandler(
                      setFieldValue,
                      'AmountOne',
                      value,
                      setFieldTouched,
                    );
                  }}
                  defaultValue={values.AmountOne}
                  decimalsLimit={10}
                  notBold
                  notCentered
                  value={values.AmountOne}
                />
                <CurrencyField
                  name="AmountTwo"
                  label={t('bankAccounts.verify.amountTwo')}
                  placeholder={formatMoneyNumeral(0)}
                  onChange={(value) => {
                    amountHandler(
                      setFieldValue,
                      'AmountTwo',
                      value,
                      setFieldTouched,
                    );
                  }}
                  defaultValue={values.AmountTwo}
                  decimalsLimit={10}
                  notBold
                  notCentered
                  value={values.AmountTwo}
                />
              </TwoColumnList>
              {showAttempts && (
                <VerifyBankAccountAttemptsError bankAccount={bankAccount} />
              )}
              <ModalButtons
                isVertical
                marginTop
                showOnlyPrimary
                primaryButtonProps={{
                  label: t('bankAccounts.verify.btnLabel'),
                  disabled: !values.AmountOne || !values.AmountTwo,
                  type: 'submit',
                }}
              />
            </Form>
          )}
        </Formik>
      </ModalBody>
    </SectionLoader>
  );
};

VerifyBankAccount.propTypes = {
  bankAccountUid: PropTypes.string,
  setError: PropTypes.func,
  handleNext: PropTypes.func,
};

export default VerifyBankAccount;
