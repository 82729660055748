import createReducer from '../../utils/createReducer';
import {
  GET_SECURITY_QUESTIONS_ERROR,
  GET_SECURITY_QUESTIONS_SUCCESS,
  GET_PORTAL_SECURITY_QUESTIONS_SUCCESS,
  GET_PORTAL_SECURITY_QUESTIONS_ERROR,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_ERROR,
  GET_PRIMARY_STATES_SUCCESS,
  GET_PRIMARY_STATES_ERROR,
  GET_MAILING_STATES_SUCCESS,
  GET_MAILING_STATES_ERROR,
  GET_TAX_FORMS_SUCCESS,
  GET_TAX_FORMS_ERROR,
  GET_STATES_SUCCESS,
  GET_STATES_ERROR,
  GET_CONTRIBUTION_YEARS_SUCCESS,
  GET_CONTRIBUTION_YEARS_ERROR,
} from '../../actions/common/commonConstants';

const initialState = {
  securityQuestions: [],
  countries: [],
  primaryStates: [],
  mailingStates: [],
  states: [],
  taxForms: [],
  error: '',
  contributionYears: [],
};

export default createReducer(
  {
    [GET_SECURITY_QUESTIONS_SUCCESS]: getSecurityQuestions,
    [GET_SECURITY_QUESTIONS_ERROR]: getSecurityQuestionsError,
    [GET_PORTAL_SECURITY_QUESTIONS_SUCCESS]: getSecurityQuestions,
    [GET_PORTAL_SECURITY_QUESTIONS_ERROR]: getSecurityQuestionsError,
    [GET_COUNTRIES_SUCCESS]: getCountries,
    [GET_COUNTRIES_ERROR]: getCountriesError,
    [GET_STATES_SUCCESS]: getStates,
    [GET_STATES_ERROR]: getStatesError,
    [GET_PRIMARY_STATES_SUCCESS]: getPrimaryStates,
    [GET_PRIMARY_STATES_ERROR]: getPrimaryStatesError,
    [GET_MAILING_STATES_SUCCESS]: getMailingStates,
    [GET_MAILING_STATES_ERROR]: getMailingStatesError,
    [GET_TAX_FORMS_SUCCESS]: getTaxForms,
    [GET_TAX_FORMS_ERROR]: getTaxFormsError,
    [GET_CONTRIBUTION_YEARS_SUCCESS]: getContributionYears,
    [GET_CONTRIBUTION_YEARS_ERROR]: getContributionYearsError,
  },
  initialState,
);

function getSecurityQuestions(state, { payload }) {
  return {
    ...state,
    securityQuestions: payload,
    error: '',
  };
}

function getSecurityQuestionsError(state, { payload }) {
  return {
    ...state,
    error: payload,
  };
}

function getCountries(state, { payload }) {
  return {
    ...state,
    countries: payload,
    error: '',
  };
}

function getCountriesError(state, { payload }) {
  return {
    ...state,
    error: payload,
  };
}

function getStates(state, { payload }) {
  return {
    ...state,
    states: payload,
    error: '',
  };
}

function getStatesError(state, { payload }) {
  return {
    ...state,
    error: payload,
  };
}

function getPrimaryStates(state, { payload }) {
  return {
    ...state,
    primaryStates: payload,
    error: '',
  };
}

function getPrimaryStatesError(state, { payload }) {
  return {
    ...state,
    error: payload,
  };
}

function getMailingStates(state, { payload }) {
  return {
    ...state,
    mailingStates: payload,
    error: '',
  };
}

function getMailingStatesError(state, { payload }) {
  return {
    ...state,
    error: payload,
  };
}

function getTaxForms(state, { payload }) {
  return {
    ...state,
    taxForms: payload,
    error: '',
  };
}

function getTaxFormsError(state, { payload }) {
  return {
    ...state,
    error: payload,
  };
}

function getContributionYears(state, { payload }) {
  return {
    ...state,
    contributionYears: payload,
    error: '',
  };
}

function getContributionYearsError(state, { payload }) {
  return {
    ...state,
    error: payload,
  };
}
