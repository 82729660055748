import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import startCase from 'lodash.startcase';
import i18next from 'i18next';
import Status from '../RecentTransactions/Status';
import { formatMoneyNumeral } from '../../../util/helpers/numeralHelpers';
import useWindowSize from '../../../util/hooks/useIsMobileHook';
import Tooltip from '../../Tooltip/Tooltip';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import { transactionTypesArray } from '../../../util/enum/api/transactionTypes';
import Button from '../../Button/Button';

import { pendingTransactionCancel } from '../../Table/Components/PendingTransaction';
import { pxToRem } from '../../../assets/styles/helper';
import themeColors from '../../../assets/styles/themeColors';
import { uFlexColumn } from '../../../assets/styles/utility';
import useCheckPermissions from '../../../util/hooks/useCheckPermissions';

const TransactionTableTransaction = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
`;

const TransactionTableDate = styled.p`
  font-size: ${pxToRem(16)};
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.5;
  color: ${themeColors.colorTextSecondary};
  max-width: ${pxToRem(38)};
  width: 100%;
  text-align: center;
  margin-right: ${pxToRem(32)};
  text-transform: uppercase;
`;

const TransactionTableActions = styled.div`
  ${uFlexColumn};
  justify-content: center;
  min-width: 0;
`;

const TransactionTableMinusSign = styled.span`
  margin-left: ${pxToRem(32)};
`;

const PendingTransactionRow = ({
  date,
  action,
  product,
  amount,
  status,
  accountUid,
  paymentUid,
  canCancel,
  handleModalOpen,
}) => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 576;
  const transactionTypeConst = parseEnumType(transactionTypesArray, action);
  const { allowAccountChangesPermission } = useCheckPermissions();
  const transactionType = amount
    ? i18next.t(
        `portfolio.transactions.portfolioTypes.${transactionTypeConst}`,
        {
          amount: formatMoneyNumeral(amount),
        },
      )
    : i18next.t(
        `portfolio.transactions.portfolioTypesNonAmount.${transactionTypeConst}`,
      );

  return (
    <>
      <TransactionTableTransaction>
        {date && <TransactionTableDate>{date}</TransactionTableDate>}
        {(action || product) && (
          <TransactionTableActions>
            {action && <p>{transactionType}</p>}
          </TransactionTableActions>
        )}
      </TransactionTableTransaction>
      {amount !== undefined && (
        <div>
          <p>
            {amount === null || amount === 0 ? (
              <TransactionTableMinusSign>&#8722;</TransactionTableMinusSign>
            ) : (
              formatMoneyNumeral(amount)
            )}
          </p>
        </div>
      )}
      {isMobile ? (
        <Tooltip text={startCase(status)}>
          <Status status={status} />
        </Tooltip>
      ) : (
        <Status status={status} />
      )}
      <div>
        <Button
          css={pendingTransactionCancel}
          onClick={() => handleModalOpen(accountUid, paymentUid)}
          disabled={!canCancel || !allowAccountChangesPermission}
          variant="primaryOutlined"
          size="sm"
        >
          {i18next.t('common.cancel')}
        </Button>
      </div>
    </>
  );
};

PendingTransactionRow.propTypes = {
  date: PropTypes.string,
  amount: PropTypes.number,
  status: PropTypes.string,
  action: PropTypes.number,
  product: PropTypes.string,
  accountUid: PropTypes.string,
  paymentUid: PropTypes.string,
  handleModalOpen: PropTypes.func,
  canCancel: PropTypes.bool,
};

export default PendingTransactionRow;
