import { getRequest, postRequest, replaceInUrl } from './index';
import apiEndpoints from './apiEndpoints';

export const getStorageFeeAmountRequest = (accountUid) =>
  getRequest(
    replaceInUrl(apiEndpoints.storageFee.getStorageFeeAmount, { accountUid }),
  );

export const payStorageFeeWithCardRequest = (
  accountUid,
  storageFeeCreditCardPayment,
) =>
  postRequest(
    replaceInUrl(apiEndpoints.storageFee.payStorageFeeWithCard, { accountUid }),
    storageFeeCreditCardPayment,
  );

export const changeAutoPaySettingsRequest = (accountUid, setting) =>
  postRequest(
    replaceInUrl(apiEndpoints.storageFee.changeAutoPaySettings, { accountUid }),
    setting,
  );
