import React from 'react';
import { useSelector } from 'react-redux';
import {
  nagDashboardStepsArray,
  BASIC_ACCOUNT_STEP,
  VERIFY_ACCOUNT_STEP,
  VERIFIED_NON_POST_PAID,
  VERIFIED_POST_PAID,
  accountStatusTypesArray,
} from '../../constants/nagDashboardContants';
import { parseEnumType } from '../../util/helpers/enumMappers';
import NagDashboardVerifyAccountStep from './NagDashboardVerifyAccountStep';
import NagDashboardFundAccountStep from './NagDashboardFundAccountStep';
import NagDashboardStorageInvestmentStep from './NagDashboardStorageInvestmentStep';
import { selectNagDashboard } from '../../store/selectors/accountSelectors';

const NagDashboardShortBanner = ({ activeStep }) => {
  const nagDashboard = useSelector(selectNagDashboard);
  const isPending =
    parseEnumType(accountStatusTypesArray, nagDashboard?.AccountStatusType) ===
    'Pending';
  const getShortDashboad = () => {
    switch (parseEnumType(nagDashboardStepsArray, activeStep)) {
      case BASIC_ACCOUNT_STEP:
      case VERIFY_ACCOUNT_STEP:
        return (
          <NagDashboardVerifyAccountStep
            activeStep={activeStep}
            isShortFlow={!isPending}
          />
        );
      case VERIFIED_NON_POST_PAID:
        return <NagDashboardFundAccountStep activeStep={activeStep} />;

      case VERIFIED_POST_PAID:
        return <NagDashboardStorageInvestmentStep activeStep={activeStep} />;

      default:
        return <></>;
    }
  };

  return getShortDashboad();
};

export default NagDashboardShortBanner;
