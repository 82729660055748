import {
  ADDRESSES_PAGE,
  BANK_ACCOUNTS_PAGE,
  BUY_PAGE,
  FINANCIAL_BALANCES_PAGE,
  METAL_STREAM_PAGE,
  SELL_PAGE,
  STATEMENTS_PAGE,
  USER_MANAGEMENT_PAGE,
  CREDIT_CARDS_PAGE,
} from './pages';
import i18next from '../i18n';
import config from '../config';

const { metalStreamCaption } = config;

export const tradingNavItems = [
  {
    id: 1,
    name: i18next.t('header.linkBuy'),
    href: BUY_PAGE,
    dataCyDropdownItem: 'nav-buy',
  },
  {
    id: 2,
    name: i18next.t('header.linkSell'),
    href: SELL_PAGE,
    dataCyDropdownItem: 'nav-sell',
  },
  {
    id: 3,
    name: i18next.t('header.linkMetalStream', {
      metalStream: metalStreamCaption,
    }),
    href: METAL_STREAM_PAGE,
    dataCyDropdownItem: 'nav-metal-stream',
  },
];

export const accountNavItems = [
  {
    id: 1,
    name: i18next.t('header.linkBalances'),
    href: FINANCIAL_BALANCES_PAGE,
    dataCyDropdownItem: 'nav-balances',
  },
  {
    id: 2,
    name: i18next.t('header.linkStatements'),
    href: STATEMENTS_PAGE,
    dataCyDropdownItem: 'nav-statements',
  },
  {
    id: 3,
    name: i18next.t('header.linkBankAccounts'),
    href: BANK_ACCOUNTS_PAGE,
    dataCyDropdownItem: 'nav-bank-accounts',
  },
  {
    id: 4,
    name: i18next.t('header.linkCreditCards'),
    href: CREDIT_CARDS_PAGE,
    dataCyDropdownItem: 'nav-credit-cards',
  },
  {
    id: 5,
    name: i18next.t('header.linkAddresses'),
    href: ADDRESSES_PAGE,
    dataCyDropdownItem: 'nav-addresses',
  },
  {
    id: 6,
    name: i18next.t('header.linkUsers'),
    href: USER_MANAGEMENT_PAGE,
    dataCyDropdownItem: 'nav-users',
  },
];

export const closedAccountNavItems = [
  {
    id: 1,
    name: i18next.t('header.linkBalances'),
    href: FINANCIAL_BALANCES_PAGE,
    dataCyDropdownItem: 'nav-balances',
  },
  {
    id: 2,
    name: i18next.t('header.linkStatements'),
    href: STATEMENTS_PAGE,
    dataCyDropdownItem: 'nav-statements',
  },
];
export const basicAccountTradingNavItems = [
  {
    id: 1,
    name: i18next.t('header.linkBuy'),
    href: BUY_PAGE,
    dataCyDropdownItem: 'nav-buy',
  },
];
export const basicAccountNavItems = [
  {
    id: 1,
    name: i18next.t('header.linkAddresses'),
    href: ADDRESSES_PAGE,
    dataCyDropdownItem: 'nav-addresses',
  },
];
