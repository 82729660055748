import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../Modal/Modal';
import { refreshUserToken } from '../../store/actions/login/loginActions';
import ModalBody from '../Modal/ModalBody';
import ModalButtons from '../Modal/ModalButtons';
import { uFlexColumn } from '../../assets/styles/utility';
import NagDashboardUploadDocumentsList from './NagDashboardUploadDocumentsList';
import {
  getCurrentAccount,
  selectNagDashboard,
} from '../../store/selectors/accountSelectors';
import {
  documentUploadStatusTypes,
  nagDashboardStepsArray,
  VERIFY_ACCOUNT_STEP,
} from '../../constants/nagDashboardContants';
import { parseEnumType } from '../../util/helpers/enumMappers';

export const NagDashboardTableBody = styled.div`
  ${uFlexColumn};
`;

const NagDashboardUploadDocumentsModal = ({ isOpen, close, activeStep }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isPenndingAccount =
    parseEnumType(nagDashboardStepsArray, activeStep) === VERIFY_ACCOUNT_STEP;
  const nagDashboard = useSelector(selectNagDashboard);
  const uploadedList = nagDashboard?.Documents;
  const account = useSelector(getCurrentAccount);
  const accountUid = account?.EncryptedAccountUid;
  const uploadedLink = `https://haa-q1.bullioninternational.info/FileUpload/DocumentUploadCenter/?uid=${accountUid}`;

  const handleConfirm = () => {
    close(false);
    dispatch(refreshUserToken());
  };
  const renderDocumentTitle = (caption) =>
    t(`nagDashboard.table.documentTitle.${caption.replace(/\s+/g, '')}`);

  const renderDocumentButtonText = (caption) =>
    t(`nagDashboard.table.buttonText.${caption.replace(/\s+/g, '')}`);

  const renderDocumentDescription = (documentStatus) => {
    if (documentStatus === 'ReuploadNeeded') {
      return t('nagDashboard.reupload');
    }
    return null;
  };

  return (
    <>
      <Modal
        title={t('nagDashboard.modal.title')}
        isOpen={isOpen}
        size="md"
        close={close}
      >
        <ModalBody>
          <NagDashboardTableBody>
            {uploadedList.map((dashboardData, index) => (
              <NagDashboardUploadDocumentsList
                key={`${dashboardData.Document}_${index}`} // eslint-disable-line react/no-array-index-key
                title={renderDocumentTitle(dashboardData.Document.Caption)} // need logic for calculate this
                description={renderDocumentDescription(
                  dashboardData.DocumentType,
                )}
                buttonText={renderDocumentButtonText(
                  dashboardData.Document.Caption,
                )}
                status={documentUploadStatusTypes[dashboardData.DocumentType]}
                isPendingVerificationStatus={isPenndingAccount}
                action={() => {
                  window.open(uploadedLink);
                }}
              />
            ))}
          </NagDashboardTableBody>
          <ModalButtons
            isVertical
            marginTop
            showOnlySecondary
            secondaryButtonProps={{
              label: t('common.dismiss'),
              onClick: handleConfirm,
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

NagDashboardUploadDocumentsModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  activeStep: PropTypes.string,
};

export default NagDashboardUploadDocumentsModal;
