/* eslint-disable jsx-a11y/anchor-is-valid */
import isEmpty from 'lodash.isempty';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InformationList from '../../../InformationList/InformationList';
import InformationListItem from '../../../InformationList/InformationListItem';
import Anchor from '../../../Anchor/Anchor';
import Paragraph from '../../../Paragraph/Paragraph';

const EquityAchTab = ({
  trusteeDetails,
  achDetails,
  isPrefunded,
  showAltNotice,
}) => {
  const { t } = useTranslation();

  if (isEmpty(achDetails)) {
    return null;
  }

  return (
    <>
      {!isPrefunded && (
        <div>
          <Paragraph marginBottom={24} fontSize={14}>
            {t('depositWizard.equity.wire.completeFormAtBank')}
          </Paragraph>
          {showAltNotice ? (
            <Paragraph marginBottom={24} fontSize={14}>
              {t('depositWizard.equity.wire.textOneAlt')}
            </Paragraph>
          ) : (
            <Paragraph marginBottom={24} fontSize={14}>
              {t('depositWizard.equity.wire.textOne')}
            </Paragraph>
          )}
        </div>
      )}
      <InformationList>
        <InformationListItem
          label={t('depositWizard.equity.routingAba')}
          value={achDetails.RoutingAbaNumber}
        />
        <InformationListItem
          label={t('depositWizard.equity.upic')}
          value={achDetails.Upic}
        />
        <InformationListItem
          label={t('depositWizard.equity.memo')}
          value={achDetails.Memo}
        />
      </InformationList>
      <Paragraph marginTop={24} marginBottom={24} fontSize={14}>
        <strong>{t('depositWizard.equity.note')}</strong>
        {t('depositWizard.equity.wire.textTwo')}
      </Paragraph>
      {trusteeDetails?.Url && (
        <Paragraph fontSize={14}>
          {t('depositWizard.equity.wire.additional')}
          <br />
          <Anchor
            type="website"
            value={`${trusteeDetails?.Url}/incoming-wire-instructions`}
          />
        </Paragraph>
      )}
    </>
  );
};

EquityAchTab.propTypes = {
  achDetails: PropTypes.shape({
    RoutingAbaNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    Upic: PropTypes.string,
    Memo: PropTypes.string,
  }),
  trusteeDetails: PropTypes.shape({
    Url: PropTypes.string,
  }),
  isPrefunded: PropTypes.bool,
  showAltNotice: PropTypes.bool,
};

export default EquityAchTab;
