import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Anchor from '../../Anchor/Anchor';
import { selectSettings } from '../../../store/selectors/settingsSelectors';
import ModalBody from '../../Modal/ModalBody';
import { restrictionTypes } from '../../../util/enum/api/restrictionTypes';
import ModalButtons from '../../Modal/ModalButtons';
import { logoutUser } from '../../../store/actions/login/loginActions';
import Paragraph from '../../Paragraph/Paragraph';
import { selectAuthUser } from '../../../store/selectors/userSelectors';
import Note from '../../Notes/Note';
import { buttonizeDiv } from '../../../util/buttonizeDiv';
import AnchorOnClick from '../../Anchor/AnchorOnClick';

const Restriction = ({
  restrictions,
  isImpersonating,
  isIRA,
  setIsSupportModalOpen,
  hasSupportModalOpening,
  onRestrictionModalButtonClick,
}) => {
  const brokerSettings = useSelector(selectSettings);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userInfo = useSelector(selectAuthUser);
  const { FirstName, LastName, MiddleName, SuffixType } = userInfo;
  const custodianName = `${FirstName || ''} ${MiddleName || ''} ${
    LastName || ''
  } ${SuffixType || ''}`;

  const logout = () => {
    dispatch(logoutUser());
  };
  if (!isImpersonating && isIRA)
    return (
      <ModalBody>
        <Paragraph>
          {t('support.iraWithdrawalPrevented', { custodianName })}
          <Note
            primaryColor
            bold
            text={t('support.iraWithdrawalPreventedCustomerSupport')}
            {...buttonizeDiv((event) => {
              event.preventDefault();
              setIsSupportModalOpen(true);
            })}
          />{' '}
          {t('support.iraWithdrawalPreventedRest')}
        </Paragraph>
      </ModalBody>
    );
  if (restrictions?.includes(restrictionTypes.LogonIsPrevented)) {
    return (
      <ModalBody>
        <Paragraph>
          {t('support.loginIsPrevented')}{' '}
          <Anchor
            type="email"
            value={brokerSettings.SupportEmail}
            text="customer support"
          />
          .
        </Paragraph>
        <ModalButtons
          isVertical
          marginTop
          showOnlyPrimary
          primaryButtonProps={{
            label: t('errorPage.logout'),
            onClick: () => logout(),
          }}
        />
      </ModalBody>
    );
  }

  if (hasSupportModalOpening) {
    return (
      <ModalBody>
        <Paragraph>
          {t('support.actionUnavailable')}{' '}
          <AnchorOnClick
            {...buttonizeDiv((event) => {
              event.preventDefault();
              setIsSupportModalOpen(true);
            })}
          >
            {t('bankAccounts.customerSupport')}
          </AnchorOnClick>
          .
        </Paragraph>
        <ModalButtons
          isVertical
          marginTop
          showOnlyPrimary
          primaryButtonProps={{
            label: t('common.ok'),
            onClick: () => onRestrictionModalButtonClick(),
          }}
        />
      </ModalBody>
    );
  }

  return (
    <ModalBody>
      <Paragraph>
        {t('support.actionUnavailable')}{' '}
        <Anchor
          type="email"
          value={brokerSettings.SupportEmail}
          text="customer support"
        />
        .
      </Paragraph>
    </ModalBody>
  );
};

Restriction.propTypes = {
  restrictions: PropTypes.arrayOf(PropTypes.string),
  isImpersonating: PropTypes.bool,
  isIRA: PropTypes.bool,
  setIsSupportModalOpen: PropTypes.func,
  hasSupportModalOpening: PropTypes.bool,
  onRestrictionModalButtonClick: PropTypes.func,
};

export default Restriction;
