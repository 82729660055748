import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { formatMoneyNumeral } from '../../util/helpers/numeralHelpers';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import themeColors from '../../assets/styles/themeColors';

const BalanceInfoCardHeaderElement = styled.div`
  padding-bottom: ${pxToRem(12.5)};
  border-bottom: 1px solid ${themeColors.colorBorderPrimary};
  margin-bottom: ${pxToRem(11.5)};
  display: flex;
  align-items: center;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding-bottom: ${pxToRemMd(12)};
    margin-bottom: ${pxToRemMd(11)};
    flex-wrap: wrap;
  }
`;

export const BalanceInfoCardTitle = styled.h3`
  font-size: ${pxToRem(18)};
  font-weight: 600;
  margin-right: auto;
  padding-right: ${pxToRem(10)};
  line-height: 1.5;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(18)};
  }
`;

const BalanceInfoCardAmount = styled.p`
  font-size: ${pxToRem(18)};
  font-weight: 600;
  line-height: 1.5;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(18)};
    margin-left: auto;
  }
`;

const BalanceInfoCardHeader = ({ title, balance }) => (
  <BalanceInfoCardHeaderElement>
    <BalanceInfoCardTitle>{title}</BalanceInfoCardTitle>
    <BalanceInfoCardAmount>{formatMoneyNumeral(balance)}</BalanceInfoCardAmount>
  </BalanceInfoCardHeaderElement>
);

BalanceInfoCardHeader.propTypes = {
  title: PropTypes.string,
  balance: PropTypes.number,
};

export default BalanceInfoCardHeader;
