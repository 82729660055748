import React, { useEffect } from 'react';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UnsuccessfulIcon } from '../../assets/images/svg/unsuccessful.svg';
import Paragraph from '../Paragraph/Paragraph';
import { StatusModalBody, StatusModalIconWrap } from '../Modals/StatusModal';
import { SUPPORT_MODAL_OPEN } from '../../constants/sessionStorage';
import AnchorOnClick from '../Anchor/AnchorOnClick';
import Button from '../Button/Button';
import { useSessionStorageState } from '../../util/hooks/useSessionStorageState';
import {
  deleteWizardKey,
  setWizardContent,
} from '../../util/helpers/wizardHelpers';
import SupportModal from '../Modals/SupportModal/SupportModal';

const PaymentFailureStatus = ({
  setModalTitleSuffix,
  callGtm,
  handleBack,
  paymentMethod,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (setModalTitleSuffix) {
      setModalTitleSuffix('');
    }

    if (callGtm) {
      callGtm();
    }
  }, []); // eslint-disable-line

  const [isSupportModalOpen, setIsSupportModalOpen] = useSessionStorageState(
    SUPPORT_MODAL_OPEN,
    false,
  );

  const openSupportModal = () => {
    setIsSupportModalOpen(true);
    setWizardContent(SUPPORT_MODAL_OPEN, true);
  };

  const closeSupportModal = () => {
    setIsSupportModalOpen(false);
    deleteWizardKey(SUPPORT_MODAL_OPEN);
  };

  return (
    <>
      {isSupportModalOpen ? (
        <SupportModal
          avoidClearingStorage
          isOpen={isSupportModalOpen}
          close={closeSupportModal}
          goBack={closeSupportModal}
        />
      ) : (
        <StatusModalBody>
          <StatusModalIconWrap>
            <UnsuccessfulIcon />
          </StatusModalIconWrap>

          <Paragraph isColumn centerText bold marginBottom={24}>
            {t('buyWizard.postPaid.paymentFailureTitle')}
          </Paragraph>

          <Paragraph marginBottom={12}>
            {t('buyWizard.postPaid.paymentFailureParagraph1', {
              paymentType:
                paymentMethod?.PaymentMethodType === 3
                  ? 'credit card.'
                  : 'ACH bank connection.',
            })}
          </Paragraph>
          <Paragraph marginBottom={12}>
            {t('buyWizard.postPaid.paymentFailureParagraph2')}
          </Paragraph>
          <Paragraph marginBottom={24}>
            {t('buyWizard.postPaid.paymentFailureContactSupport1')}
            <AnchorOnClick
              onClick={(event) => {
                event.preventDefault();
                openSupportModal();
              }}
            >
              {t('buyWizard.postPaid.paymentFailureContactSupport2')}
            </AnchorOnClick>

            {t('buyWizard.postPaid.paymentFailureContactSupport3')}
          </Paragraph>

          <Button
            variant="primary"
            marginTop
            showOnlyPrimary
            textTransform="capitalize"
            onClick={handleBack}
          >
            {t('buyWizard.postPaid.chooseDifferentPaymentSupport')}
          </Button>
        </StatusModalBody>
      )}
    </>
  );
};

PaymentFailureStatus.propTypes = {
  setModalTitleSuffix: PropType.func,
  callGtm: PropType.func,
  handleBack: PropType.func,
  paymentMethod: PropType.shape({
    PaymentMethodType: PropType.number,
  }),
};

export default PaymentFailureStatus;
