/* eslint-disable prefer-template */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';
import styled from 'styled-components';
import startCase from 'lodash.startcase';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import Section from '../../components/Section/Section';
import DetailTable from '../../components/DetailTable/DetailTable';
import Button from '../../components/Button/Button';
import BrandTable from '../../components/BrandTable/BrandTable';
import {
  clearPortfolioProductFromStore,
  fetchPortfolioBalances,
  fetchPortfolioProductBySymbol,
  accountTransactionsFetch,
  fetchPortfolioHoldings,
} from '../../store/actions/portfolio/portfolioActions';
import {
  getCurrentAccount,
  getCurrentUserPermissionsError,
} from '../../store/selectors/accountSelectors';
import {
  selectPortfolioProductError,
  selectPortfolioSingleProduct,
} from '../../store/selectors/portfolioProductsSelectors';
import { userPermissionsFetch } from '../../store/actions/account/accountActions';

import SellWizardModal from '../../components/Modals/SellWizard/SellWizardModal';
import { setStorageProduct } from '../../store/actions/buy/buyStorageActions';
import { selectStorageSymbolCode } from '../../store/selectors/buyStorageSelectors';
import {
  emptyProductTiers,
  fetchProductsWithPrices,
  fetchProductSymbolPrices,
  fetchProductTiers,
} from '../../store/actions/products/productActions';
import {
  getProductBySymbol,
  selectProductPrices,
} from '../../store/selectors/productsSelector';
import {
  selectPortfolioTransactions,
  selectPortfolioTransactionsTotalCount,
} from '../../store/selectors/portfolioTransactionsSelector';
import {
  productTypesArray,
  PRODUCT_TYPE_BAR,
  PRODUCT_TYPE_COIN,
  PRODUCT_TYPE_FRACTIONAL,
  productLocationTypes,
  LOCATION_TYPE_DELIVERY,
  productLocationTypesByName,
} from '../../util/enum/api/productTypes';
import FractionalConversion from '../../components/Modals/Product/FractionalConversion';
import { METAL_STREAM_PAGE, PORTFOLIO_PAGE } from '../../constants/pages';
import RequestDelivery from '../../components/Modals/Product/RequestDelivery';
import { selectBalancesAvailableForWithdrawal } from '../../store/selectors/portfolioBalancesSelectors';
import { selectAuthUser } from '../../store/selectors/userSelectors';
import { parseEnumType } from '../../util/helpers/enumMappers';
import {
  ACCOUNT_TRANSACTIONS_FETCH_LOADING,
  PORTFOLIO_HOLDINGS_LOADING,
} from '../../store/actions/portfolio/portfolioActionConstants';
import { selectIsLoadingByActionType } from '../../store/selectors/loadingSelectors';
import BlockSectionLoader from '../../components/Loader/BlockSectionLoader';
import {
  deleteWizardKey,
  setWizardContent,
} from '../../util/helpers/wizardHelpers';
import useGtmHook from '../../util/hooks/useGtmHook';
import {
  CHOSEN_VAULT,
  IS_BUY_FOR_STORAGE_OPEN,
  IS_FRACTIONAL_CONVERSION_OPEN,
  IS_REQUEST_DELIVERY_OPEN,
  IS_SELL_WIZARD_OPEN,
  PRODUCT,
  SUPPORT_MODAL_OPEN,
  WIZARD_DATA,
} from '../../constants/sessionStorage';
import { ACCOUNT_DATA_LOADED, SELECT_ITEM } from '../../constants/gtmEvents';
import useGetPeriodicProductPrices from '../../util/hooks/useGetPeriodicProductPrices';
import useCheckPermissions from '../../util/hooks/useCheckPermissions';
import { selectSettings } from '../../store/selectors/settingsSelectors';
import { useSessionStorageState } from '../../util/hooks/useSessionStorageState';
import ProductTransactions from '../../components/Portfolio/ProductTransactions/ProductTransactions';
import { useTransactionPagination } from '../../util/hooks/useTransactionPagination';
import { PRODUCT_TRANSACTIONS } from '../../constants/accountTransactionConstants';
import FractionalDelivery from '../../components/Modals/FractionalDelivery/FractionalDelivery';
import {
  accountTypeKeys,
  accountTypes,
} from '../../util/enum/api/accountTypes';
import { storeInSessionStorage } from '../../util/helpers/sessionStorageHelper';
import BuyForStorageWizard from '../../components/Modals/BuyWizard/BuyForStorageWizard';
import Note from '../../components/Notes/Note';
import { mediaBelow, pxToRem } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import { uFlexColumn } from '../../assets/styles/utility';
import { clearBuyStorageSuccessState } from '../../store/actions/orders/orderActions';
import { selectBankAccountAchData } from '../../store/selectors/bankAccountSelectors';
import {
  actionRequiredStatus,
  expiredVerificationStatus,
  failedVerificationStatus,
  getBankAccountStatus,
  pendingReviewStatus,
} from '../../util/helpers/bankAccountHelper';
import { BANK_ACCOUNT_LOADING } from '../../store/actions/bankAccount/bankAccountActionConstants';
import { fetchBankAccounts } from '../../store/actions/bankAccount/bankAccountActions';
import NagDashboardBanner from '../../components/NagDashboard/NagDashboardBanner';
import Modal from '../../components/Modal/Modal';
import Restriction from '../../components/Modals/RestrictionModal/Restriction';
import SupportModal from '../../components/Modals/SupportModal/SupportModal';
import { useGetIsImpersonating } from '../../util/hooks/useGetIsImpersonating';
import config from '../../config';
import { COUNTDOWN_TIMER_INTERVAL_MS } from '../../constants/timerConstants';
import useGetPeriodicPriceTiers from '../../util/hooks/useGetPeriodicPriceTiers';
import BasicAccountRestristionsModal from '../../components/Modals/BasicAccountRestrictionsModal/BasicAccountRestrictionsModal';
import { isUserAccountPendingApproval } from '../../constants/nagDashboardContants';

export const ProductPageContainer = styled.div`
  margin: ${pxToRem(40)} 0;
  display: flex;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: 0 ${pxToRem(16)};
  }

  ${mediaBelow(variables.breakpoints.bpLg)} {
    padding: 0;
    flex-direction: column;
    margin: 0;
  }
`;

export const ProductPageDetailsWrap = styled.div`
  ${uFlexColumn};
`;

export const ProductPageSidebar = styled.div`
  ${uFlexColumn};
  max-width: ${pxToRem(400)};
  width: 100%;
  margin-right: ${pxToRem(60)};

  ${mediaBelow(variables.breakpoints.bpLg)} {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: ${pxToRem(34)};
    padding: ${pxToRem(32)} ${pxToRem(16)} 0;
  }
`;

export const ProductPageContent = styled.div`
  width: 100%;
  max-width: 100%;
`;

export const ProductPageActionsWrap = styled.div`
  margin: ${pxToRem(24)} 0;
  ${({ isInstaVaultProduct }) =>
    isInstaVaultProduct
      ? `display: grid;
   grid-template-columns: 1fr 1fr;
   grid-column-gap: ${pxToRem(12)};
   grid-row-gap: ${pxToRem(12)};
   `
      : ` display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   grid-column-gap: ${pxToRem(12)};`}

  ${mediaBelow(variables.breakpoints.bpLg)} {
    margin: ${pxToRem(34)} 0;
  }
`;

export const ProductPageContentTitle = styled.p`
  display: none;
  padding-left: ${pxToRem(16)};
  margin-bottom: ${pxToRem(18)};
  font-size: ${pxToRem(20)};
  line-height: 1.4;
  font-weight: 600;

  ${mediaBelow(variables.breakpoints.bpLg)} {
    display: block;
  }
`;

const ProductPage = () => {
  const { params } = useRouteMatch();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectAuthUser);
  const account = useSelector(getCurrentAccount);
  const isTestAccount = account.IsTestAccount;
  const product = useSelector(selectPortfolioSingleProduct);
  const isTestProduct = product?.IsTestProduct;
  const vault = useSelector(selectStorageSymbolCode);
  const productError = useSelector(selectPortfolioProductError);
  const permissionsError = useSelector(getCurrentUserPermissionsError);
  const productPrices = useSelector(getProductBySymbol);

  const allProductsPrices = useSelector(selectProductPrices);
  const fundsForWithdrawal = useSelector(selectBalancesAvailableForWithdrawal);
  const transactions = useSelector(selectPortfolioTransactions);
  const transactionsTotalCount = useSelector(
    selectPortfolioTransactionsTotalCount,
  );
  const transactionsLoading = useSelector(
    selectIsLoadingByActionType(ACCOUNT_TRANSACTIONS_FETCH_LOADING),
  );
  const isLoadingProductAndAccount = useSelector(
    selectIsLoadingByActionType(PORTFOLIO_HOLDINGS_LOADING),
  );

  const settings = useSelector(selectSettings);
  const path = window.location.pathname.replace(product?.SymbolCode, '');
  const [sellPrice, setSellPrice] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [counterValue, setCounterValue] = useState(
    Math.floor(
      settings.PricingRefreshFrequencyMilliseconds /
        COUNTDOWN_TIMER_INTERVAL_MS,
    ),
  );
  const [isLockedPrice, setIsLockedPrice] = useState(false);
  const [unityValue, setUnityValue] = useState(false);
  const [tiersDone, setTiersDone] = useState(false);
  const [barsArray, setBarsArray] = useState([]);
  const [
    isBasicRestrictionsModalOpen,
    setIsBasicRestrictionsModalOpen,
  ] = useState(false);
  const { brokerTheme } = config;
  const isGS = brokerTheme === 'GoldSilver';
  const isFromFractionalLocation = vault?.LocationType === 13 && isGS;
  const isInstaVaultProduct =
    isGS &&
    (product.ProductCode === settings.InstavaultProductCodes[0] ||
      product.ProductCode === settings.InstavaultProductCodes[1]);
  const [transactionList] = useTransactionPagination(transactions, pageNumber);

  const isUtmaAccount =
    parseEnumType(accountTypeKeys, account.AccountType) === accountTypes.UTMA;
  const isIRAAccount =
    parseEnumType(accountTypeKeys, account.AccountType) === accountTypes.IRA;
  const isBasicAccount =
    parseEnumType(accountTypeKeys, account.AccountType) === accountTypes.Basic;

  const { gtmScreenView, appGtmEvent, ecommerceGtmEvent } = useGtmHook();
  const [isSellWizardOpen, setIsSellWizardOpen] = useSessionStorageState(
    IS_SELL_WIZARD_OPEN,
    false,
  );

  const isSegregated = history.location?.state?.isSegregated;
  const segregatedCaption = settings?.SegregatedCaption;

  const [
    isFractionalConversionOpen,
    setIsFractionalConversionOpen,
  ] = useSessionStorageState(IS_FRACTIONAL_CONVERSION_OPEN, false);

  const [isBuyForStorageOpen, setIsBuyForStorageOpen] = useSessionStorageState(
    IS_BUY_FOR_STORAGE_OPEN,
    false,
  );

  const [
    isRequestDeliveryOpen,
    setIsRequestDeliveryOpen,
  ] = useSessionStorageState(IS_REQUEST_DELIVERY_OPEN, false);
  const { isImpersonating } = useGetIsImpersonating();

  const availableForDelivery = useMemo(
    () => !isEmpty(product) && product.UnitsAvailableForDelivery > 0,
    [product],
  );

  const productType = useMemo(
    () =>
      isEmpty(product)
        ? null
        : parseEnumType(productTypesArray, product.ProductType),
    [product],
  );
  const achBankAccounts = useSelector(selectBankAccountAchData);

  const unverifiedBankAccounts = achBankAccounts?.filter((bankAccount) => {
    const verificationStatus = getBankAccountStatus(bankAccount).status;
    return [
      actionRequiredStatus,
      failedVerificationStatus,
      expiredVerificationStatus,
      pendingReviewStatus,
    ].includes(verificationStatus);
  });

  const isLoadingBankAccounts = useSelector(
    selectIsLoadingByActionType([BANK_ACCOUNT_LOADING]),
  );
  const {
    displayIRAEntrustOptionsPermission,
    displayIRAMilleniumOptionsPermission,
  } = useCheckPermissions();
  const [isRestrictionModalOpen, setIsRestictionModalOpen] = useState();
  const [isSupportModalOpen, setIsSupportModalOpen] = useSessionStorageState(
    SUPPORT_MODAL_OPEN,
    false,
  );
  const [isBuyorDeliverClicked, setIsBuyorDeliverClicked] = useState(false);

  const disableDeliverButton =
    (displayIRAEntrustOptionsPermission ||
      displayIRAMilleniumOptionsPermission) &&
    !isImpersonating;

  useEffect(() => {
    if (account.AccountUid) {
      dispatch(fetchBankAccounts({ accountUid: account.AccountUid }));
    }
  }, [account.AccountUid, dispatch]);

  const canRender = useMemo(
    () =>
      !isEmpty(product) &&
      !isEmpty(productPrices) &&
      !isLoadingBankAccounts &&
      !isLoadingProductAndAccount,
    [product, productPrices, isLoadingBankAccounts, isLoadingProductAndAccount],
  );

  const {
    displayMetalStreamMenuItems,
    allowAccountChangesPermission,
  } = useCheckPermissions();

  const handleBuyMoreButtonClick = () => {
    if (isBasicAccount || isUserAccountPendingApproval(account)) {
      setIsBasicRestrictionsModalOpen(true);
      setIsBuyorDeliverClicked(true);
      return;
    }
    setIsBasicRestrictionsModalOpen(false);
    storeInSessionStorage(WIZARD_DATA, {
      storageProduct: product,
      selectedVault: vault,
      productPrices,
    });
    setWizardContent(IS_BUY_FOR_STORAGE_OPEN, true);
    setIsBuyForStorageOpen(true);
  };

  const handleFetchTransactions = (page) => {
    dispatch(
      accountTransactionsFetch({
        accountUid: account.AccountUid,
        productSymbol: params.symbol,
        pageNumber: page,
        pageSize: 20,
        content: PRODUCT_TRANSACTIONS,
        isSegregated,
      }),
    );
  };

  const handleRefreshTransactions = () => {
    setPageNumber(1);
    handleFetchTransactions(1);
  };

  const handleGtmEvent = (data) => {
    ecommerceGtmEvent(SELECT_ITEM, {
      ecommerce: {
        value: parseFloat(data?.ProductPrice?.toFixed(2)),
        trade_type: 'buy',
        currency: account?.CurrencyIso3Code,
        items: [
          {
            item_name: product?.ProductCaption,
            price: parseFloat(data?.ProductPrice?.toFixed(2)),
            item_id: vault?.SymbolCode,
            delivery_type: 'vault',
            vault_location: startCase(
              parseEnumType(productLocationTypes, vault?.LocationType),
            ),
          },
        ],
      },
    });
  };

  useEffect(() => {
    dispatch(
      fetchPortfolioBalances({
        accountUid: account.AccountUid,
      }),
    );
  }, [dispatch, account.AccountUid]);

  useEffect(() => {
    dispatch(
      fetchProductsWithPrices({
        accountUid: account.AccountUid,
        side: 'Buy',
      }),
    );
  }, []); //eslint-disable-line

  useEffect(() => {
    if (product?.SymbolCode) {
      gtmScreenView({
        path,
        title: 'Portfolio Product',
        symbolCode: product?.SymbolCode,
      });
      appGtmEvent(ACCOUNT_DATA_LOADED);
    }
    if (product?.Bars) {
      setBarsArray(product?.Bars);
    }
  }, [product]); // eslint-disable-line

  useEffect(() => {
    if (vault) {
      setWizardContent(CHOSEN_VAULT, vault);
    }
  }, [vault]);

  useEffect(() => {
    const onSuccess = (data) => {
      const hasProductInList = data?.some(
        (holding) => holding.SymbolCode === params.symbol,
      );
      if (!hasProductInList) {
        return history.push(PORTFOLIO_PAGE);
      }
      if (!isEmpty(account) && account.AccountUid) {
        if (!isEmpty(user) && user.userUid) {
          dispatch(
            userPermissionsFetch({
              accountUid: account.AccountUid,
              userUid: user.userUid,
            }),
          );
        }
        if (params.symbol && hasProductInList) {
          dispatch(
            fetchPortfolioProductBySymbol({
              accountUid: account.AccountUid,
              symbol: params.symbol,
              isSegregated,
            }),
          );
        }
      }

      return () => {
        dispatch(clearPortfolioProductFromStore());
      };
    };

    dispatch(
      fetchPortfolioHoldings({
        accountUid: account.AccountUid,
        onSuccess,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    account.AccountUid,
    params.symbol,
    isSegregated,
    account,
    user,
    user.userUid,
    dispatch,
  ]);

  useEffect(() => {
    if (isBuyForStorageOpen && params.symbol && account.AccountUid) {
      dispatch(
        fetchProductSymbolPrices({
          accountUid: account.AccountUid,
          symbol: params.symbol,
          side: 'Buy',
          setSellPrice,
          onSuccess: handleGtmEvent,
        }),
      );
    }

    if (!isBuyForStorageOpen && params.symbol && account.AccountUid) {
      dispatch(
        fetchProductSymbolPrices({
          accountUid: account.AccountUid,
          symbol: params.symbol,
          side: 'Sell',
          setSellPrice,
        }),
      );
    }
  }, [isBuyForStorageOpen]); // eslint-disable-line

  const periodicCallback = useCallback((epoch) => {
    if (epoch?.data) {
      const [counter, unity] = epoch.data;
      setCounterValue((prevCounter) => prevCounter - prevCounter + counter);
      setUnityValue(unity);
    }
  }, []);

  useGetPeriodicProductPrices(
    isSellWizardOpen ? 'SellPP' : 'BuyPP',
    account.AccountUid,
    params.symbol,
    isSellWizardOpen ? 'Sell' : 'Buy',
    true,
    settings?.PricingRefreshFrequencyMilliseconds,
    !isLockedPrice ? periodicCallback : null,
  );

  useGetPeriodicPriceTiers(
    'priceTiersPP',
    account,
    parseEnumType(productLocationTypes, vault?.LocationType),
    vault?.SymbolCode,
    'Sell',
    dispatch,
    isSellWizardOpen && isLockedPrice,
    settings?.PricingRefreshFrequencyMilliseconds,
    true,
    periodicCallback,
  );

  useEffect(() => {
    if (params.symbol) handleFetchTransactions(1);
    else {
      history.push(PORTFOLIO_PAGE);
    }
  }, [account, account.AccountUid, params.symbol]); // eslint-disable-line

  useEffect(() => {
    if (params.symbol && pageNumber > 1) {
      handleFetchTransactions(pageNumber);
    }
  }, [pageNumber]); // eslint-disable-line

  useEffect(() => {
    if (
      !isEmpty(product) &&
      !isEmpty(productPrices) &&
      !isEmpty(allProductsPrices) &&
      productPrices.ProductPrice &&
      !isBuyForStorageOpen
    ) {
      const productForStoragePrices = allProductsPrices?.filter(
        (item) => item.ProductCode === productPrices.ProductCode,
      );

      dispatch(
        setStorageProduct({
          product: { ...product, ...productForStoragePrices[0] },
          vault: {
            SymbolCode: product.SymbolCode,
            LocationType: product.LocationType,
            PricePerUnit: productPrices.ProductPrice,
            IsCashOrderOnly: productPrices?.Locations[0]?.IsCashOrderOnly,
          },
        }),
      );
    }
  }, [
    dispatch,
    product,
    productPrices,
    isBuyForStorageOpen,
    allProductsPrices,
  ]);

  const brandTableData = useMemo(
    () => (!isEmpty(product) && product.Bars ? product.Bars : []),
    [product],
  );

  const handleLoadMore = () => {
    setPageNumber((old) => old + 1);
  };

  const isFractionalProduct = productType === PRODUCT_TYPE_FRACTIONAL;

  const closeSupportModal = () => {
    setIsSupportModalOpen(false);
    deleteWizardKey(SUPPORT_MODAL_OPEN);
  };

  const isSegregatedTitle = product?.IsSegregated
    ? `${product?.ProductCaption + ` (${segregatedCaption})`}`
    : product?.ProductCaption;

  useEffect(() => {
    if (!vault || isEmpty(vault) || tiersDone) {
      return;
    }
    const { LocationType } = vault;
    dispatch(
      fetchProductTiers({
        accountUid: account.AccountUid,
        symbol: vault?.SymbolCode,
        side: 'Buy',
        location:
          LocationType === LOCATION_TYPE_DELIVERY
            ? Object.keys(productLocationTypesByName).find(
                (key) => productLocationTypesByName[key] === 12,
              )
            : parseEnumType(productLocationTypes, vault?.LocationType),
        trueTiers: true,
      }),
    );
    setTiersDone(true);

    return () => dispatch(emptyProductTiers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vault]);

  useEffect(() => {
    if (!settings || isEmpty(settings)) {
      return;
    }

    setIsLockedPrice(settings?.IsLockedPricingActive);
  }, [settings]);

  return !productError && !permissionsError ? (
    <>
      <NagDashboardBanner />
      <NavigationBar
        hasBack
        mainPage={t('header.portfolioLink')}
        subPage={
          isLoadingProductAndAccount || isEmpty(product)
            ? ''
            : isSegregatedTitle
        }
      />
      <Section>
        <ProductPageContainer>
          <ProductPageSidebar>
            <ProductPageDetailsWrap>
              {canRender ? (
                <DetailTable
                  product={{
                    ...product,
                    EstimatedPrice: productPrices.ProductPrice,
                    SellPrice: sellPrice,
                  }}
                  isUtmaAccount={isUtmaAccount}
                />
              ) : (
                <BlockSectionLoader isLoading={!canRender} />
              )}
            </ProductPageDetailsWrap>
            {canRender && (
              <ProductPageActionsWrap isInstaVaultProduct={isInstaVaultProduct}>
                {allowAccountChangesPermission && (
                  <>
                    {!isEmpty(vault) && (
                      <Button
                        size="lg"
                        variant="primary"
                        disabled={
                          !product.IsAvailableForSell ||
                          (isTestAccount && !isTestProduct) ||
                          product?.UnitsAvailableForSell === 0 ||
                          (isSegregated && isIRAAccount)
                        }
                        onClick={() => {
                          if (
                            isBasicAccount ||
                            isUserAccountPendingApproval(account)
                          ) {
                            setIsBasicRestrictionsModalOpen(true);
                            setIsBuyorDeliverClicked(false);
                            return;
                          }
                          setIsBasicRestrictionsModalOpen(false);
                          if (vault) {
                            setWizardContent('ShortFlow', true);
                          }
                          dispatch(setStorageProduct({ product, vault }));
                          setWizardContent(CHOSEN_VAULT, vault);
                          setWizardContent(PRODUCT, product);
                          storeInSessionStorage(WIZARD_DATA, {
                            storageProduct: product,
                            selectedVault: vault,
                            productPrices,
                          });
                          setWizardContent(IS_SELL_WIZARD_OPEN, true);
                          setIsSellWizardOpen(true);
                        }}
                      >
                        {t('product.sellBtn')}
                      </Button>
                    )}
                    {isFractionalProduct ? (
                      <Button
                        size="lg"
                        variant="primary"
                        onClick={() => {
                          setWizardContent(IS_FRACTIONAL_CONVERSION_OPEN, true);
                          setIsFractionalConversionOpen(true);
                        }}
                        disabled={
                          !product.UnitsAvailableForConversion ||
                          (isTestAccount && !isTestProduct)
                        }
                      >
                        {t('product.convert')}
                      </Button>
                    ) : null}
                    {!isUtmaAccount &&
                    (productType === PRODUCT_TYPE_BAR ||
                      productType === PRODUCT_TYPE_COIN ||
                      isInstaVaultProduct) ? (
                      <Button
                        size="lg"
                        variant="primary"
                        onClick={() => {
                          if (disableDeliverButton) {
                            setIsRestictionModalOpen(true);
                          } else {
                            if (
                              isBasicAccount ||
                              isUserAccountPendingApproval(account)
                            ) {
                              setIsBasicRestrictionsModalOpen(true);
                              setIsBuyorDeliverClicked(true);
                              return;
                            }
                            setWizardContent(IS_REQUEST_DELIVERY_OPEN, true);
                            setIsRequestDeliveryOpen(true);
                          }
                        }}
                        disabled={
                          !allowAccountChangesPermission ||
                          !availableForDelivery ||
                          isTestAccount
                        }
                      >
                        {t('product.deliverBtn')}
                      </Button>
                    ) : null}
                    {isFractionalProduct ? (
                      <Button
                        size="lg"
                        variant="primary"
                        disabled={
                          (!product.IsAvailableForBuy &&
                            !displayMetalStreamMenuItems) ||
                          (isTestAccount && !isTestProduct) ||
                          (isSegregated && isIRAAccount)
                        }
                        onClick={() => {
                          history.push(METAL_STREAM_PAGE);
                        }}
                      >
                        {t('product.buyMoreBtn')}
                      </Button>
                    ) : (
                      <Button
                        size="lg"
                        variant="primary"
                        disabled={
                          !product.IsAvailableForBuy ||
                          (isTestAccount && !isTestProduct) ||
                          (isSegregated && isIRAAccount)
                        }
                        onClick={handleBuyMoreButtonClick}
                      >
                        {t('product.buyMoreBtn')}
                      </Button>
                    )}
                  </>
                )}
              </ProductPageActionsWrap>
            )}
            {canRender && (
              <Note marginBottom={16} text={t('product.valuationNote')} />
            )}
            <Note marginBottom={16} text={t('product.settlementNote')} />
            {!isFractionalProduct && !isInstaVaultProduct && (
              <Note marginBottom={16} text={t('product.note')} />
            )}
            {!isEmpty(brandTableData) && (
              <BrandTable marginTop={24} data={brandTableData} />
            )}
          </ProductPageSidebar>

          <ProductPageContent>
            <ProductPageContentTitle>
              {t('product.yourTransactions')}
            </ProductPageContentTitle>
            {transactionsLoading && pageNumber === 1 ? (
              <BlockSectionLoader isLoading={transactionsLoading} />
            ) : (
              <ProductTransactions
                transactionList={transactionList}
                transactionsLoading={transactionsLoading}
                account={account}
                handleLoadMore={handleLoadMore}
                transactionsTotalCount={transactionsTotalCount}
              />
            )}
          </ProductPageContent>
          {canRender && !isEmpty(vault) && isSellWizardOpen && (
            <SellWizardModal
              isModalOpen={isSellWizardOpen}
              handleRefreshTransactions={handleRefreshTransactions}
              handleCloseModal={() => {
                if (isSellWizardOpen) {
                  dispatch(
                    fetchPortfolioProductBySymbol({
                      accountUid: account.AccountUid,
                      symbol: params.symbol,
                      isSegregated,
                    }),
                  );
                  setIsSellWizardOpen(false);
                }
              }}
              isFractionalProduct={isFractionalProduct}
              isInstaVaultProduct={isInstaVaultProduct}
              isSegregated={isSegregated}
              isProductPage
              barsArray={barsArray}
              isLockedPrice={isLockedPrice}
              counter={counterValue}
              unity={unityValue}
            />
          )}
          {canRender && isFractionalProduct && (
            <FractionalConversion
              isOpen={isFractionalConversionOpen}
              handleRefreshTransactions={handleRefreshTransactions}
              onClose={() => {
                deleteWizardKey(IS_FRACTIONAL_CONVERSION_OPEN);
                setIsFractionalConversionOpen(false);
              }}
              resetProducts={() => {
                dispatch(
                  fetchPortfolioProductBySymbol({
                    accountUid: account.AccountUid,
                    symbol: params.symbol,
                    isSegregated,
                  }),
                );
              }}
              product={product}
            />
          )}
          {canRender && !isFractionalProduct && (
            <RequestDelivery
              isOpen={isRequestDeliveryOpen}
              onClose={() => {
                setIsRequestDeliveryOpen(false);
              }}
              product={product}
              funds={fundsForWithdrawal}
              accountUid={account.AccountUid}
              accountType={account.AccountType}
              resetAfterDelivery={handleRefreshTransactions}
              unverifiedBankAccounts={unverifiedBankAccounts}
              isSegregated={isSegregated}
              isInstaVaultProduct={isInstaVaultProduct}
              isFromFractionalLocation={isFromFractionalLocation}
            />
          )}
          {canRender && isInstaVaultProduct && (
            <FractionalDelivery
              isModalOpen={isRequestDeliveryOpen}
              onClose={() => {
                setIsRequestDeliveryOpen(false);
              }}
              product={product}
              funds={fundsForWithdrawal}
              accountUid={account.AccountUid}
              accountType={account.AccountType}
              resetAfterDelivery={handleRefreshTransactions}
              unverifiedBankAccounts={unverifiedBankAccounts}
              isInstaVaultProduct={isInstaVaultProduct}
              isFromFractionalLocation={isFromFractionalLocation}
            />
          )}
          {isBuyForStorageOpen && canRender && allowAccountChangesPermission && (
            <BuyForStorageWizard
              isModalOpen={isBuyForStorageOpen}
              handleCloseModal={() => {
                if (isBuyForStorageOpen) {
                  dispatch(clearBuyStorageSuccessState());
                  setIsBuyForStorageOpen(false);
                }
              }}
              isSegregated={isSegregated}
              isLockedPrice={isLockedPrice}
              counter={counterValue}
              unity={unityValue}
            />
          )}
        </ProductPageContainer>
        <>
          {!isSupportModalOpen && isRestrictionModalOpen ? (
            <Modal
              title={t('product.requestDelivery.title')}
              close={() => setIsRestictionModalOpen(false)}
              size="sm"
              isOpen={isRestrictionModalOpen}
            >
              <Restriction
                isImpersonating={isImpersonating}
                isIRA
                setIsSupportModalOpen={setIsSupportModalOpen}
              />
            </Modal>
          ) : (
            <SupportModal
              avoidClearingStorage
              isOpen={isSupportModalOpen}
              goBack={closeSupportModal}
            />
          )}
        </>
        {isBasicRestrictionsModalOpen && (
          <BasicAccountRestristionsModal
            isOpen={isBasicRestrictionsModalOpen}
            close={() => {
              setIsBasicRestrictionsModalOpen(false);
              setIsBuyorDeliverClicked(false);
            }}
            title={
              isBuyorDeliverClicked
                ? t('basicAccountRestrictions.verifyAccountBuy.title')
                : t('basicAccountRestrictions.verifyAccountSell.title')
            }
            description={
              isBuyorDeliverClicked
                ? t('basicAccountRestrictions.verifyAccountBuy.description')
                : t('basicAccountRestrictions.verifyAccountSell.description')
            }
          />
        )}
      </Section>
    </>
  ) : (
    <span>{productError}</span>
  );
};

export default ProductPage;
