import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import styled from 'styled-components';
import Modal from '../Modal/Modal';
import { ReactComponent as UnsuccessfulIcon } from '../../assets/images/svg/unsuccessful.svg';
import { ReactComponent as Check } from '../../assets/images/svg/check-round.svg';

import Paragraph from '../Paragraph/Paragraph';
import ModalBody from '../Modal/ModalBody';
import ModalButtons from '../Modal/ModalButtons';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';
import Note from '../Notes/Note';
import SupportModal from './SupportModal/SupportModal';

export const StatusModalBody = styled(ModalBody)`
  align-items: center;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    flex-grow: 1;
  }
`;

export const StatusModalIconWrap = styled.div`
  margin-bottom: ${pxToRem(24)};

  &,
  svg {
    width: ${pxToRem(86)};
    height: ${pxToRem(86)};
    color: ${themeColors.colorRed};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-bottom: ${pxToRemMd(16)};

    &,
    svg {
      width: ${pxToRemMd(86)};
      height: ${pxToRemMd(86)};
    }
  }
`;

const RegistrationConfirmButton = styled.button`
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 12px 24px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${themeColors.colorRegistration};
  width: auto;
  justify-content: center;
  font-size: 1rem;
  line-height: 1.6;
  color: ${themeColors.colorWhite};
  font-weight: 400;
  margin-top: ${pxToRem(32)};
`;

const SuccessOrFailModal = ({
  text,
  isOpen,
  hasError,
  modalTitle,
  onButtonClick,
  backButtonText = i18next.t('common.goBack'),
  close,
  isLoading,
  callGtm,
  isRegistrationButtonVisible,
  isABADisabled,
}) => {
  const { t } = useTranslation();
  const [isSupportModalOpen, setSupportModalOpen] = useState(false);
  const renderIcon = () => {
    if (isLoading) {
      return null;
    }

    return (
      <StatusModalIconWrap>
        {hasError ? <UnsuccessfulIcon /> : <Check />}
      </StatusModalIconWrap>
    );
  };

  useEffect(() => {
    if (callGtm) {
      callGtm();
    }
  }, []); //eslint-disable-line

  return (
    <>
      <Modal
        isOpen={isOpen}
        size="sm"
        title={modalTitle}
        isLoading={isLoading}
        close={close}
      >
        <StatusModalBody>
          {renderIcon()}
          <Paragraph centerText labelColor>
            {text}{' '}
            {isABADisabled && (
              <>
                {' '}
                {t('achDeposit.abaRoutingNumberValidationFirst')}
                <Note
                  primaryColor
                  bold
                  text={t('achDeposit.abaRoutingNumberValidationSecond')}
                  onClick={(event) => {
                    event.preventDefault();
                    setSupportModalOpen(true);
                  }}
                />
                {t('achDeposit.abaRoutingNumberValidationThird')}
              </>
            )}
          </Paragraph>

          {isRegistrationButtonVisible ? (
            <RegistrationConfirmButton onClick={onButtonClick}>
              {hasError ? backButtonText : t('common.ok')}
            </RegistrationConfirmButton>
          ) : (
            <ModalButtons
              isVertical
              marginTop
              showOnlyPrimary
              primaryButtonProps={{
                onClick: onButtonClick,
                label: hasError ? backButtonText : t('common.ok'),
              }}
            />
          )}
        </StatusModalBody>
      </Modal>
      {isSupportModalOpen && (
        <SupportModal isOpen={isSupportModalOpen} close={setSupportModalOpen} />
      )}
    </>
  );
};

SuccessOrFailModal.propTypes = {
  text: PropType.oneOfType([PropType.string, PropType.node]),
  isOpen: PropType.bool,
  hasError: PropType.oneOfType([PropType.bool, PropType.string]),
  modalTitle: PropType.string,
  onButtonClick: PropType.func,
  close: PropType.func,
  backButtonText: PropType.string,
  isLoading: PropType.bool,
  callGtm: PropType.func,
  isRegistrationButtonVisible: PropType.bool,
  isABADisabled: PropType.bool,
};

export default SuccessOrFailModal;
