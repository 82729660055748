import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, startCase } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { parseEnumType } from '../../util/helpers/enumMappers';
import { productLocationTypes } from '../../util/enum/api/productTypes';
import OrderTotal from './OrderTotal';
import {
  selectBuyCashBufferPercentage,
  selectSettings,
} from '../../store/selectors/settingsSelectors';
import {
  emptyQuotesBuyOrderDelivery,
  executeQuotesForOrderStorageBuy,
  getQuotesForBuyDelivery,
} from '../../store/actions/orders/orderActions';
import { getCurrentAccount } from '../../store/selectors/accountSelectors';
import ReviewBar from '../ReviewBar/ReviewBar';
import ModalBody from '../Modal/ModalBody';
import Label from '../Notes/Label';
import { verifyOrderEcommerceGtmHelper } from '../../util/helpers/gtmHelper';
import useGtmHook from '../../util/hooks/useGtmHook';
import SectionLoader from '../Loader/SectionLoader';
import { selectBalancesAvailableForTrading } from '../../store/selectors/portfolioBalancesSelectors';
import { fetchPortfolioBalances } from '../../store/actions/portfolio/portfolioActions';
import ModalButtons from '../Modal/ModalButtons';
import DelayedSettlementWarning from '../DelayedSettlementWarning/DelayedSettlementWarning';
import CountdownTimer from '../Common/Timers/CountdownTimer';
import Paragraph from '../Paragraph/Paragraph';
import AnchorOnClick from '../Anchor/AnchorOnClick';
import LockedPriceVariableWeightDisclaimerModal from '../Modals/BuyWizard/LockedPriceVariableWeightDisclaimerModal';
import useStartTimer from '../../util/hooks/useStartTimer';
import {
  counterValueToTimestamp,
  handleExpiration,
} from '../../util/helpers/timerHelpers';
import { IdleTimeContext } from '../../providers/IdleTimeContextProvider';
import {
  IDLE_TIMEOUT_THRESHOLD,
  COUNTDOWN_TIMER_SEC,
} from '../../constants/timerConstants';
import ModalTitle from '../Modal/ModalTitle';
import ErrorModal from '../Common/ErrorModal';
import { retrieveFromSessionStorage } from '../../util/helpers/sessionStorageHelper';
import { SIGNIFYD_SESSION_ID } from '../../constants/sessionStorage';
import { postPaidApiErrors } from '../../util/enum/api/postPaidApiErrors';
import PaymentFailureStatus from '../BuyForStorage/PaymentFailureStatus';
import paymentTypes from '../../util/enum/api/paymentTypes';

const ReviewOrderForDelivery = ({
  wizardData,
  setWizardData,
  handleBack,
  handleNext,
  setWizardTitle,
  isPostPaidVisible,
}) => {
  const { ecommerceGtmEvent } = useGtmHook();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const buyCashBuffer = useSelector(selectBuyCashBufferPercentage);
  const balanceAvailableForTrading = useSelector(
    selectBalancesAvailableForTrading,
  );
  const [
    balanceAvailableForTradingIsValid,
    setBalanceAvailableForTradingIsValid,
  ] = useState(true);
  const hasDelayedSettlement = wizardData?.selectedVault?.HasDelayedSettlement;

  const account = useSelector(getCurrentAccount);
  const [finalOrder, setFinalOrder] = useState({});
  const [isDisclaimerModalOpen, setIsDisclaimerModalOpen] = useState(false);
  const [counter, setCounter] = useState(COUNTDOWN_TIMER_SEC);
  const [unity, setUnity] = useState(false);
  const [isLoadingGetQuotes, setIsLoadingGetQuotes] = useState(false);
  const [isLoadingExecuteQuotes, setIsLoadingExecuteQuotes] = useState(false);
  const settings = useSelector(selectSettings);
  const getRemainingTime = useContext(IdleTimeContext);

  const {
    storageProduct,
    orderValues,
    selectedVault,
    orderType,
    deliveryAddress,
    activeType,
    paymentMethod,
    isDefaultPaymentMethod,
    quoteID,
    postPaidCalculation,
    calculation,
    expiryTime,
  } = wizardData;

  const sessionId = retrieveFromSessionStorage(SIGNIFYD_SESSION_ID);

  const [paymentMethodData, setPaymentMethodData] = useState(
    postPaidCalculation?.paymentMethodStepData || {},
  );

  const paymentMethodInfo =
    paymentMethod?.PaymentMethodType === 3
      ? paymentMethodData?.DataWithCCFee
      : paymentMethodData?.DataWithoutCCFee;

  const newPricePerUnit = isPostPaidVisible
    ? paymentMethodInfo?.ClientPricePerUnit
    : calculation?.newPricePerUnit;

  const quantityExecuted = isPostPaidVisible
    ? paymentMethodInfo?.QuantityExecuted
    : calculation?.quantity;

  const executionPrice = isPostPaidVisible
    ? paymentMethodInfo?.TotalClientExecutionPrice
    : calculation?.estimatedOrderTotal;

  const volumeDiscount = isPostPaidVisible
    ? paymentMethodInfo?.VolumeDiscount
    : calculation?.volumeDiscount;

  const paymentDiscountValue = isPostPaidVisible
    ? postPaidCalculation?.PaymentTypeDiscount
    : calculation?.paymentTypeDiscount;

  const salesTax = isPostPaidVisible
    ? paymentMethodInfo?.SalesTax
    : calculation?.salesTax;

  const deliveryFee = isPostPaidVisible
    ? paymentMethodInfo?.DeliveryFee
    : calculation?.deliveryFees;

  const [pricePerUnit, setPricePerUnit] = useState(newPricePerUnit || 0);
  const [quoteUid, setQuoteUid] = useState(quoteID || '');
  const [quantity, setQuantity] = useState(quantityExecuted || 0);
  const [volDiscount, setVolumeDiscount] = useState(volumeDiscount || 0);
  const [expirationMs, setExpirationMs] = useState(0);
  const [clientExecutionPrice, setClientExecutionPrice] = useState(
    executionPrice || 0,
  );
  const [deliveryCost, setDeliveryFees] = useState(deliveryFee || 0);
  const [salesTaxUSD, setSalesTax] = useState(salesTax || 0);
  const [paymentDiscount, setPaymentDiscount] = useState(
    paymentDiscountValue || 0,
  );
  const [expiryTimeStored, setExpiryTimeStored] = useState(false);
  const [getQuotesError, setGetQuotesError] = useState('');
  const [executeQuotesError, setExecuteQuotesError] = useState('');
  const [getPostPaidError, setGetPostPaidError] = useState('');
  const { SymbolCode } = selectedVault;

  useEffect(() => {
    if (!expirationMs) {
      if (expiryTime) {
        handleExpiration(expiryTime, setExpirationMs);
      }
    }
  }, [expirationMs, expiryTime]);

  const onHandleGetQuotesSuccess = (getQuoteResult) => {
    const {
      QuoteUid,
      ExpiresOnUtc,
      QuoteStatusItem: {
        QuantityExecuted,
        ClientPricePerUnit,
        TotalClientExecutionPrice,
        DeliveryFee,
        SalesTax,
        VolumeDiscount,
        PaymentTypeDiscount,
        PaymentMethodStepData,
      },
    } = getQuoteResult;
    const calculation = {
      estimatedOrderTotal: TotalClientExecutionPrice,
      newPricePerUnit: ClientPricePerUnit,
      volumeDiscount: VolumeDiscount,
      quantity: QuantityExecuted,
      deliveryFees: DeliveryFee,
      salesTax: SalesTax,
      paymentTypeDiscount: PaymentTypeDiscount,
      paymentMethodStepData: PaymentMethodStepData,
    };
    setQuoteUid(QuoteUid);
    setPricePerUnit(ClientPricePerUnit);
    setQuantity(QuantityExecuted);
    setClientExecutionPrice(TotalClientExecutionPrice);
    setDeliveryFees(DeliveryFee);
    setSalesTax(SalesTax);
    setVolumeDiscount(VolumeDiscount);
    handleExpiration(ExpiresOnUtc, setExpirationMs);
    setPaymentDiscount(PaymentTypeDiscount);
    setPaymentMethodData(PaymentMethodStepData);
    setWizardData({
      ...wizardData,
      calculation,
      quoteID: QuoteUid,
      expiryTime: ExpiresOnUtc,
    });
  };

  const onHandleExecuteQuotesSuccess = () => {
    onConfirmOrder();
  };

  const counterUpdater = useCallback((epoch) => {
    if (epoch?.data) {
      const [counter, unity] = epoch.data;
      setCounter((prevCounter) => prevCounter - prevCounter + counter);
      setUnity(unity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchQuotes = () => {
    const paymentTypeUid =
      paymentMethod?.PaymentMethodType !== 1
        ? paymentMethod?.PaymentMethodUid
        : '00000000-0000-0000-0000-000000000000';

    const payload = {
      getQuotesPayload: {
        accountUid: account.AccountUid,
        symbolCode: SymbolCode,
        quantityOrAmount:
          wizardData?.orderValues?.CashAmount ||
          wizardData?.orderValues?.QuantityAmount,
        sideType: 'Buy',
        unitType: wizardData?.orderType,
        paymentType:
          paymentTypes[paymentMethod?.PaymentMethodType] ??
          paymentTypes[isDefaultPaymentMethod?.PaymentMethodType],
        addressUid: deliveryAddress.AddressUid,
      },
      paymentTypeUid,
      isLoading: setIsLoadingGetQuotes,
      onSuccess: onHandleGetQuotesSuccess,
      onError: onHandleGetQuotesError,
      addPaymentMethodData: true,
    };
    setIsLoadingGetQuotes(true);
    dispatch(
      getQuotesForBuyDelivery({
        ...payload,
      }),
    );
  };

  useEffect(() => {
    if (counter === 2 && SymbolCode) {
      fetchQuotes();
      setCounter(COUNTDOWN_TIMER_SEC);
    }

    return () => dispatch(emptyQuotesBuyOrderDelivery());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, SymbolCode]);

  useEffect(() => {
    const remainingIdleTime = getRemainingTime();
    if (remainingIdleTime < IDLE_TIMEOUT_THRESHOLD && !expiryTimeStored) {
      const expirationTimeStamp = counterValueToTimestamp(counter);
      setExpiryTimeStored(true);
      setWizardData({
        ...wizardData,
        expiryTime: expirationTimeStamp,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, getRemainingTime]);

  useEffect(() => {
    if (!selectedVault || !orderValues) {
      return;
    }
    const estimatedTotal = clientExecutionPrice + deliveryCost + salesTaxUSD;
    setFinalOrder(() => ({
      quantity,
      unitPrice: pricePerUnit,
      subtotal: clientExecutionPrice,
      deliveryFees: deliveryCost,
      salesTax: salesTaxUSD,
      estimatedTotal,
      cashBuffer: buyCashBuffer,
      requiredBalanceWithBuffer:
        estimatedTotal * buyCashBuffer + estimatedTotal,
      volumeDiscount: volDiscount,
      paymentTypeDiscount: paymentDiscount,
      paymentMethodData,
    }));
  }, [
    orderValues,
    orderType,
    buyCashBuffer,
    wizardData,
    selectedVault,
    clientExecutionPrice,
    deliveryCost,
    salesTaxUSD,
    quantity,
    pricePerUnit,
    volDiscount,
    paymentDiscount,
    paymentMethodData,
  ]);

  useEffect(() => {
    if (getPostPaidError) {
      setWizardTitle(t('buyWizard.postPaid.postPaidDeliveryErrorModalTitle'));
      return;
    }

    setWizardTitle(t('buyWizard.verifyOrder.buyForDelivery'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPostPaidError]);

  useEffect(() => {
    if (account.AccountUid) {
      dispatch(fetchPortfolioBalances({ accountUid: account.AccountUid }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.AccountUid]);

  useEffect(() => {
    setPricePerUnit(newPricePerUnit);
  }, []); // eslint-disable-line

  const onHandleSuccess = (orderCode) => {
    verifyOrderEcommerceGtmHelper({
      ecommerceGtmEvent,
      wizardData,
      finalOrder,
      account,
      orderSideType: 'buy',
      quantity: quantityExecuted,
      isForDelivery: true,
      orderCode,
    });
  };

  const closeErrorModal = () => {
    if (getQuotesError) {
      setGetQuotesError('');
      handleBack();
    }
    setExecuteQuotesError('');
    setGetPostPaidError('');
    fetchQuotes();
  };

  const onConfirmOrder = () => {
    setWizardTitle(t('buyWizard.buyForDelivery.title'));
    handleNext();
  };

  const onHandleGetQuotesError = (errorMessage) => {
    setGetQuotesError(errorMessage);
    setExecuteQuotesError('');
    setGetPostPaidError('');
  };

  const onHandleExecuteQuotesError = (error) => {
    const errorCode = error?.response?.data?.Errors[0]?.Code;
    if (postPaidApiErrors.includes(errorCode)) {
      setGetPostPaidError(errorCode);
    } else {
      setExecuteQuotesError(errorCode);
    }

    setGetQuotesError('');
  };

  useEffect(() => {
    if (isEmpty(finalOrder)) {
      return;
    }

    const { estimatedTotal, requiredBalanceWithBuffer } = finalOrder;

    const isValid =
      activeType === 'Quantity'
        ? balanceAvailableForTrading >= requiredBalanceWithBuffer
        : balanceAvailableForTrading >= estimatedTotal;

    setBalanceAvailableForTradingIsValid(isValid);
  }, [activeType, balanceAvailableForTrading, finalOrder]);

  const handleConfirmOrder = () => {
    const payload = {
      executeQuotePayload: {
        quoteUid: quoteUid || quoteID,
        signifydSessionId:
          isPostPaidVisible && paymentMethod?.PaymentMethodType !== 1
            ? sessionId
            : 'null',
      },
      isLoading: setIsLoadingExecuteQuotes,
      onSuccess: onHandleExecuteQuotesSuccess,
      onConfirm: onHandleSuccess,
      onError: onHandleExecuteQuotesError,
    };
    setIsLoadingExecuteQuotes(true);
    dispatch(
      executeQuotesForOrderStorageBuy({
        ...payload,
      }),
    );
  };

  const closeDisclaimerModal = () => {
    setIsDisclaimerModalOpen(false);
  };

  useStartTimer(
    expirationMs,
    counterUpdater,
    isLoadingGetQuotes || isLoadingExecuteQuotes,
  );

  if (isDisclaimerModalOpen) {
    return (
      <LockedPriceVariableWeightDisclaimerModal
        isOpen={isDisclaimerModalOpen}
        close={closeDisclaimerModal}
        goBack={closeDisclaimerModal}
      />
    );
  }

  if (getQuotesError || executeQuotesError) {
    return (
      <ErrorModal
        title={t('buyWizard.buyForDelivery.title')}
        subtitle={t('apiErrors.IssueWithYourOrder')}
        message={
          getQuotesError
            ? t(`apiErrors.${getQuotesError}`)
            : t(`apiErrors.${executeQuotesError}`)
        }
        btnTitle={
          getQuotesError
            ? t('product.chooseAnAmount')
            : t('product.reviewYourOrder')
        }
        isOpen={getQuotesError || executeQuotesError}
        close={closeErrorModal}
        goBack={closeErrorModal}
      />
    );
  }

  if (getPostPaidError) {
    return (
      <PaymentFailureStatus
        handleBack={handleBack}
        paymentMethod={paymentMethod}
      />
    );
  }

  return (
    <SectionLoader isLoading={isLoadingGetQuotes || isLoadingExecuteQuotes}>
      <ReviewBar title={t('buyWizard.verifyOrder.review')} />
      <ModalBody>
        <ModalTitle>{`${storageProduct?.Caption} ${startCase(
          parseEnumType(productLocationTypes, selectedVault?.LocationType),
        )}`}</ModalTitle>
        <Label text={t('buyWizard.verifyOrder.subtitle')} />
        {!isEmpty(finalOrder) && (
          <OrderTotal
            orderType={orderType}
            deliveryAddress={deliveryAddress}
            finalOrder={finalOrder}
            buyCashBuffer={buyCashBuffer}
            balanceAvailableForTradingIsValid={
              balanceAvailableForTradingIsValid
            }
            paymentMethod={paymentMethod}
            isPostPaidVisible={isPostPaidVisible}
          />
        )}
        {hasDelayedSettlement && <DelayedSettlementWarning marginTop={20} />}
        <CountdownTimer
          counter={counter}
          unity={unity}
          loading={isLoadingExecuteQuotes || isLoadingGetQuotes}
        />
        {settings?.IsLockedPricingActive && !storageProduct?.IsFixedWeight && (
          <Paragraph fontSize={14} marginTop={12}>
            {t('lockedPrice.disclaimerInfo')}
            <AnchorOnClick onClick={() => setIsDisclaimerModalOpen(true)}>
              {t('lockedPrice.disclaimerInfoLink')}
            </AnchorOnClick>
          </Paragraph>
        )}
        {getQuotesError ||
          (executeQuotesError && (
            <Paragraph isError fontSize={14} marginTop={12}>
              {getQuotesError ?? executeQuotesError}
            </Paragraph>
          ))}

        <ModalButtons
          isHorizontal
          marginTop
          secondaryButtonProps={{
            onClick: () => {
              handleBack();
              fetchQuotes();
            },
          }}
          primaryButtonProps={{
            label: t('common.confirm'),
            onClick: handleConfirmOrder,
            // disabled: !balanceAvailableForTradingIsValid,
          }}
        />
      </ModalBody>
    </SectionLoader>
  );
};

ReviewOrderForDelivery.propTypes = {
  wizardData: PropTypes.shape({
    activeType: PropTypes.string,
    selectedVault: PropTypes.shape({
      LocationType: PropTypes.number,
      SymbolCode: PropTypes.string,
      HasDelayedSettlement: PropTypes.bool,
      BuyForDeliveryMinimumAmount: PropTypes.number,
    }),
    paymentMethod: PropTypes.shape({
      PaymentMethodType: PropTypes.number,
      PaymentMethodUid: PropTypes.number,
    }),
    isDefaultPaymentMethod: PropTypes.shape({
      PaymentMethodType: PropTypes.number,
      PaymentMethodUid: PropTypes.number,
    }),
    orderValues: PropTypes.shape({
      QuantityAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      CashAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    storageProduct: PropTypes.shape({
      Caption: PropTypes.string,
      IsFixedWeight: PropTypes.bool,
    }),
    orderType: PropTypes.string,
    ExpiresOnUtc: PropTypes.string,
    calculation: PropTypes.shape({
      estimatedOrderTotal: PropTypes.number,
      newPricePerUnit: PropTypes.number,
      volumeDiscount: PropTypes.number,
      quantity: PropTypes.number,
      deliveryFees: PropTypes.number,
      salesTax: PropTypes.number,
      paymentTypeDiscount: PropTypes.number,
    }),
    newCalculation: PropTypes.shape({
      estimatedOrderTotal: PropTypes.number,
      newPricePerUnit: PropTypes.number,
      volumeDiscount: PropTypes.number,
      quantity: PropTypes.number,
      deliveryFees: PropTypes.number,
      salesTax: PropTypes.number,
    }),
    postPaidCalculation: PropTypes.shape({
      paymentMethodStepData: PropTypes.shape({}),
      PaymentTypeDiscount: PropTypes.number,
    }),
    quoteID: PropTypes.string,
    expiryTime: PropTypes.string,
    deliveryAddress: PropTypes.shape({
      AddressUid: PropTypes.string,
    }),
    deliveryOrderFees: PropTypes.shape({
      deliveryCosts: PropTypes.number,
      salesTax: PropTypes.number,
      unassessedStorageFees: PropTypes.number,
    }),
    volumeDiscount: PropTypes.number,
    newPricePerUnit: PropTypes.number,
  }),
  setWizardData: PropTypes.func,
  setWizardTitle: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  isPostPaidVisible: PropTypes.bool,
};

export default ReviewOrderForDelivery;
