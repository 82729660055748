import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { PORTFOLIO_PAGE } from '../../constants/pages';
import {
  UtilitiesPageButton,
  UtilitiesPageContainer,
  UtilitiesPageContentStyle,
  UtilitiesPageSection,
  UtilitiesPageText,
  UtilitiesPageTitle,
} from './Components';

const NotFoundPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <UtilitiesPageContainer>
      <UtilitiesPageSection>
        <UtilitiesPageContentStyle>
          <UtilitiesPageTitle>404</UtilitiesPageTitle>
          <UtilitiesPageText>{t('notFound.text')}</UtilitiesPageText>
          <UtilitiesPageButton
            variant="primaryOutlined"
            onClick={() => history.push(PORTFOLIO_PAGE)}
          >
            {t('notFound.goBack')}
          </UtilitiesPageButton>
        </UtilitiesPageContentStyle>
      </UtilitiesPageSection>
    </UtilitiesPageContainer>
  );
};

NotFoundPage.propTypes = {};

export default NotFoundPage;
