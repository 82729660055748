export default {
  accounts: {
    get: 'accounts/{accountUid}',
    getCurrentUserPermissions:
      'accounts/{currentAccountUid}/users/{currentUserUid}/permissions',
    getAddresses: 'accounts/{accountUid}/addresses',
    updateAddress: 'account/{accountUid}/addresses/{addressUid}',
    deleteAddress: 'accounts/{accountUid}/addresses/{addressUid}',
    addAddress: 'accounts/{accountUid}/address',
    getUsers: 'accounts/{accountUid}/users',
    createUser: 'accounts/{accountUid}/users',
    updateUser: 'account/{accountUid}/users/{userUid}?actionType={actionType}',
    deleteUser: 'accounts/{accountUid}/users/{userUid}',
    getSettings: 'accounts/{accountUid}/settings',
    getIraSettings: 'accounts/{accountUid}/iraSettings',
    getSettingsRegistration: 'application/settings',
    agreement: 'accounts/agreement',
    nagDashboard: 'accounts/{accountUid}/dashboard',
    dismissedNagDashboard: 'accounts/{accountUid}/dashboard-dismissed',
  },
  authentications: {
    getUsernames: 'authenticate/usernames',
    login: 'authenticate',
    getUserSecurityQuestion: 'users/username/securityquestion',
    confirmSecurityQuestion: 'authenticate/confirm',
    confirmForgotPassword: 'users/passwords/reset_token',
    resetPassword: 'users/passwords',
    refreshToken: '/authenticate/refresh',
    generateToken: '/authenticate/generate',
    authenticate:
      '/authenticate?fp={fp}&offer={offer}&landingPageUrl={landingPageUrl}&registrationFlowType={registrationFlowType}',
    confirmAuthentication:
      '/authenticate/confirm?fp={fp}&offer={offer}&landingPageUrl={landingPageUrl}&registrationFlowType={registrationFlowType}',
    registration: '/accounts/basicAccount',
  },
  bankAccounts: {
    get: 'accounts/{accountUid}/bankaccounts',
    getBankAccount:
      'accounts/{accountUid}/bankaccounts/{bankAccountUid}?type={type}',
    getBankAccountsByType: 'accounts/{accountUid}/bankaccounts?type={type}',
    getBankDetailsByRoutingNumber: 'banks/{routingNumber}',
    newAccount: 'accounts/{accountUid}/bankaccounts',
    deleteAccount:
      'accounts/{accountUid}/bankaccounts/{bankAccountUid}?type={type}',
    verify: '/accounts/{accountUid}/bankaccountverification/{bankAccountUid}',
    postBankAccountRegistration: '/accounts/{applicationUid}/bankaccounts',
    getRegistration: 'banks/{applicationUid}/bankaccounts',
    obiMessages: 'bankaccounts/{bankAccountUid}/obimessages',
  },
  creditCards: {
    getCreditCards: 'accounts/{accountUid}/credit-cards',
    deleteCreditCard: 'accounts/{accountUid}/credit-cards/{creditCardUid}',
    getClientToken: '/accounts/{accountUid}/credit-cards/client-token',
    addOrGetCreditCards: '/accounts/{accountUid}/credit-cards',
  },
  storageFee: {
    getStorageFeeAmount: '/accounts/{accountUid}/storagefees/amount',
    payStorageFeeWithCard: '/accounts/{accountUid}/storagefees/pay/credit-card',
    changeAutoPaySettings:
      '/accounts/{accountUid}/credit-cards/storage-autopay',
  },
  documents: {
    getDocuments: 'accounts/{accountUid}/documents?year={year}',
    getDocument: 'accounts/{accountUid}/documents/{documentType}',
  },
  countries: '/countries',
  metalStream: {
    getMetalStreamSettings: 'accounts/{accountUid}/metalstream',
    getMetalStreamFundings: 'applications/{applicationUid}/metalStreamFunding',
  },
  orders: {
    buyForStorage: '/accounts/{accountUid}/orders/buyForStorage',
    buyForDelivery: '/accounts/{accountUid}/orders/buyForDelivery',
    verifyBuyForDelivery: '/accounts/{accountUid}/orders/buyForDelivery/verify',
    sellFromStorage: '/accounts/{accountUid}/orders/sellFromStorage',
    fractionalConversion: '/accounts/{accountUid}/orders/fractionalConversion',
    deliverFromStorageVerify:
      '/accounts/{accountUid}/orders/deliverFromStorage/verify',
    deliverFromStorage: '/accounts/{accountUid}/orders/deliverFromStorage',
    iraCashDistribution: '/accounts/{accountUid}/orders/iraCashDistribution',
    iraCashTransfer: '/accounts/{accountUid}/orders/iraCashTransfer',
    iraFeeWithdrawal: 'accounts/{accountUid}/orders/iraFeeWithdrawal',
    achDeposit: 'accounts/{accountUid}/orders/achDeposit',
    wireWithdrawal: '/accounts/{accountUid}/orders/wireWithdrawal',
    checkWithdrawal: '/accounts/{accountUid}/orders/checkWithdrawal',
    obiMessages: '',
    getQuotesForBuyStorage:
      '/quotes?accountUid={accountUid}&symbolCode={symbolCode}&quantityOrAmount={quantityOrAmount}&sideType={sideType}&unitType={unitType}&paymentType={paymentType}',
    executeQuotesForBuyStorage:
      '/quotes?quoteUid={quoteUid}&signifydSessionId={signifydSessionId}',
    getQuotesForDelivery:
      '/quotes?accountUid={accountUid}&symbolCode={symbolCode}&quantityOrAmount={quantityOrAmount}&sideType={sideType}&unitType={unitType}&addressUid={addressUid}&paymentType={paymentType}',
    getQuotesForSell:
      '/quotes?accountUid={accountUid}&symbolCode={symbolCode}&quantityOrAmount={quantityOrAmount}&sideType={sideType}&unitType={unitType}&isSegregated={isSegregated}',
    executeQuotesForSell: '/quotes?quoteUid={quoteUid}',
  },
  portfolio: {
    getPortfolioValuations: 'accounts/{accountUid}/portfolio/valuations',
    getPortfolioMetalPrices: 'marketprices',
    getPortfolioHoldings:
      'accounts/{accountUid}/portfolio/products?valuation=true',
    getPortfolioProductCodes: '/accounts/{accountUid}/portfolio/productcodes',
    getPortfolioBalances: '/accounts/{accountUid}/portfolio/balances',
    getPortfolioProductBySymbol:
      '/accounts/{accountUid}/portfolio/products/{symbol}',
    getPortfolioTransactions: '/accounts/{accountUid}/transactions',
    getPortfolioSingleTransaction:
      '/accounts/{accountUid}/transactions/{transactionUid}',
    getProductPortoflioTransactions: 'accounts/{accountUid}/transactions',
    getRecentPortfolioTransactions:
      'accounts/{accountUid}/transactions?content=Recent',
    getFinancialPortfolioTransactions: 'accounts/{accountUid}/transactions',
    getFinancialPortfolioPendingTransactions:
      'accounts/{accountUid}/transactions/fundinghistory',
    patchFinancialPortfolioPendingTransactions:
      '/accounts/{accountUid}/transactions/fundinghistory/{depositKey}',
  },
  products: {
    getPrices: '/accounts/{accountUid}/products/prices',
    prices: 'accounts/{accountUid}/products/prices?side={side}',
    tiers:
      '/accounts/{accountUid}/products/prices/{symbol}/tiers?side={side}&location={location}&trueTiers={trueTiers}',
    symbolPrices: '/accounts/{accountUid}/products/{symbol}/prices?side={side}',
    getPricesRegistration: 'applications/{applicationUid}/products/prices',
  },
  settings: {
    get: 'settings',
  },
  taxForms: {
    getTaxForms: 'settings/taxForms/{applicationType}',
  },
  users: {
    getAccounts: '/users/{userUid}/accounts',
    getRegistrationAccounts: '/users/{userUid}/accounts',
    updateUser: '/users/{userUid}?updateUserActionType={actionType}',
    updateUserPassword: '/users/{userUid}/passwords',
    logout: '/users/{userUid}/logout',
    getUsernames: '/users/email',
    createUser:
      '/users?fp={fp}&offer={offer}&landingPageUrl={landingPageUrl}&registrationFlowType={registrationFlowType}',
    updateUserRegistration: '/users/{userUid}',
    invite: '/users/invite',
    email: '/users/email',
    getPhoneNumbers: 'users/{userUid}/phonenumbers',
    getPhoneNumber: 'users/{userUid}/phones/{phoneUid}',
    getPhoneNumbersLogin: 'users/{username}/phonenumbers',
    deletePhoneNumber: 'users/{userUid}/phones/{phoneUid}',
    addPhoneNumber: '/users/{userUid}/phone',
  },
  applications: {
    application: '/applications/{applicationUid}',
    addPerson: '/applications/{applicationUid}/persons',
    updatePerson: '/applications/{applicationUid}/persons/{personUid}',
    addPersonWithGiftState:
      '/applications/{applicationUid}/UTMA/persons?giftState={giftState}',
    updatePersonWithGiftState:
      '/applications/{applicationUid}/UTMA/persons/{personUid}?giftState={giftState}',
    addPersonWithCompanyName:
      '/applications/{applicationUid}/IRA/persons?companyName={companyName}',
    updatePersonWithCompanyName:
      '/applications/{applicationUid}/IRA/persons/{personUid}?companyName={companyName}',
    submitLegalEntity: '/applications/{applicationUid}/legalEntities',
    updateLegalEntity:
      '/applications/{applicationUid}/legalEntities/{personUid}',
    postNonIraFunding: '/applications/{applicationUid}/funding',
    postIraFunding: '/applications/{applicationUid}/iraFunding',
    postMSFunding: '/applications/{applicationUid}/metalStreamFunding',
    consent: '/applications/{applicationUid}/consents',
    updateConsent: `/applications/{applicationUid}/consents/{agreementConsentUid}`,
    submitMetalStreamRegistration:
      '/applications/{applicationUid}/metalStreamFunding',
  },
  common: {
    getCountries: '/countries/',
    getTaxForms: '/taxForms/',
    getContributionYears: 'contributionYears',
    getCountryStates: 'countries/{countryCode}/states',
    getSecurityQuestions: '/registration/securityQuestions/',
    getPortalSecurityQuestions: '/securityQuestions',
  },
  plaid: {
    getToken: '/bankaccounts/createplaidlinktoken',
  },
  affiliate: {
    setCookie: '/affiliate/picture',
    setFingerprint: '/affiliate/fingerprint',
  },
  twoFA: {
    twoFaTypes: '/2fa/availabletypes',
    twoFaApplicationKeys: '/users/{userUid}/2fa/keys',
    twoFaCode: '/users/{userUid}/2fa/code',
    twoFaCodeViaPhone: '/users/2fa/phonenumbers/{phoneUid}/code',
    twoFaCodeViaEmail: '/users/2fa/emails/{emailUid}/code',
    saveTwoFa: '/users/{userUid}/2fa',
    deleteTwoFa: '/users/{userUid}/2fa',
    getTwoFa: '/users/2fa/usernames',
    isOpenCustomerSupport: '/2fa/customerservice',
  },
  mailIn: 'mail-in-storage',
};
