import {
  PRODUCT_FOR_REVIEW_SET,
  PRODUCT_SYMBOL_PRICES_FETCH_SUCCESS,
  PRODUCTS_PRICES_FETCH_ERROR,
  PRODUCTS_PRICES_FETCH_SUCCESS,
  PRODUCT_TIERS_FETCH_ERROR,
  PRODUCT_TIERS_FETCH_SUCCESS,
  PRODUCT_TIERS_EMPTY,
  VERIFY_DELIVER_FROM_STORAGE_DATA_SUCCESS,
  CONVERT_PRODUCT_FETCH_SUCCESS,
  CONVERT_PRODUCT_FETCH_ERROR,
} from '../../actions/products/productActionConstants';
import createReducer from '../../utils/createReducer';

const initialState = {
  productPrices: {
    data: [],
    errorMsg: '',
  },
  productTiers: {
    data: [],
    errorMsg: '',
  },
  productBySymbol: {},
  errorMsg: '',
  reviewProduct: null,
  deliverFromStorageVerifyData: {
    DeliveryCosts: 0,
    ErrorCodeType: null,
    ErrorMessage: null,
    SalesTax: 0,
    UnassessedStorageFees: 0,
  },
  convertProducts: {
    data: [],
    errorMessage: '',
  },
};

export default createReducer(
  {
    [PRODUCTS_PRICES_FETCH_SUCCESS]: productPricesSuccess,
    [PRODUCTS_PRICES_FETCH_ERROR]: productPricesError,
    [PRODUCT_TIERS_FETCH_SUCCESS]: setProductTiers,
    [PRODUCT_TIERS_FETCH_ERROR]: setProductTiersError,
    [PRODUCT_SYMBOL_PRICES_FETCH_SUCCESS]: updateProductBySymbol,
    [PRODUCT_FOR_REVIEW_SET]: setProductForReview,
    [PRODUCT_TIERS_EMPTY]: emptyProductTiersData,
    [VERIFY_DELIVER_FROM_STORAGE_DATA_SUCCESS]: setDeliverFromStorageVerifyData,
    [CONVERT_PRODUCT_FETCH_SUCCESS]: setConvertProducts,
    [CONVERT_PRODUCT_FETCH_ERROR]: setConvertProductsError,
  },
  initialState,
);

function productPricesSuccess(state, action) {
  return {
    ...state,
    productPrices: {
      ...state.productPrices,
      data: action.payload,
    },
  };
}

function productPricesError(state, action) {
  return {
    ...state,
    productPrices: {
      ...state.productPrices,
      errorMsg: action.payload,
    },
  };
}

function emptyProductTiersData(state) {
  return {
    ...state,
    productTiers: {
      ...state.productTiers,
      data: [],
    },
  };
}

function setProductTiers(state, { payload }) {
  return {
    ...state,
    productTiers: {
      ...state.productTiers,
      data: payload,
    },
  };
}

function setProductTiersError(state, { payload }) {
  return {
    ...state,
    productTiers: {
      ...state.productTiers,
      errorMsg: payload,
    },
  };
}

function updateProductBySymbol(state, action) {
  return {
    ...state,
    productBySymbol: action.payload,
  };
}

function setProductForReview(state, action) {
  return {
    ...state,
    reviewProduct: {
      ...state.reviewProduct,
      ...action.payload,
    },
  };
}

function setDeliverFromStorageVerifyData(state, action) {
  return {
    ...state,
    deliverFromStorageVerifyData: action.payload,
  };
}

function setConvertProducts(state, { payload }) {
  return {
    ...state,
    convertProducts: {
      ...state.convertProducts,
      data: payload,
    },
  };
}

function setConvertProductsError(state, { payload }) {
  return {
    ...state,
    convertProducts: {
      ...state.convertProducts,
      errorMessage: payload,
    },
  };
}
