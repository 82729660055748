import React from 'react';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteTwoFa,
  fetchTwoFa,
} from '../../../store/actions/twoFA/twoFAActions';
import { selectAuthUser } from '../../../store/selectors/userSelectors';
import { selectIsLoadingByActionType } from '../../../store/selectors/loadingSelectors';
import { DELETE_TWO_FA_LOADING } from '../../../store/actions/twoFA/twoFAActionConstants';
import SectionLoader from '../../Loader/SectionLoader';
import ModalBody from '../../Modal/ModalBody';
import Label from '../../Notes/Label';
import ModalButtons from '../../Modal/ModalButtons';

const TwoFADeleteConfirmation = ({ handleNext, setIsSuccessful }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userInfo = useSelector(selectAuthUser);
  const handleDeleteSuccess = () => {
    dispatch(fetchTwoFa({ username: userInfo.Username }));
    handleNext();
  };

  const isLoading = useSelector(
    selectIsLoadingByActionType(DELETE_TWO_FA_LOADING),
  );

  const removeTwoFA = () => {
    dispatch(
      deleteTwoFa({
        userUid: userInfo.UserUid,
        handleDeleteSuccess,
        setIsSuccessful,
      }),
    );
  };

  return (
    <SectionLoader isLoading={isLoading}>
      <ModalBody>
        <Label text={t('twoFa.profileSettings.delete.confirmation')} />
        <ModalButtons
          showOnlyPrimary={{
            label: t('common.confirm'),
          }}
          primaryButtonProps={{ onClick: removeTwoFA }}
          marginTop
        />
      </ModalBody>
    </SectionLoader>
  );
};

TwoFADeleteConfirmation.propTypes = {
  handleNext: PropType.func,
  setIsSuccessful: PropType.func,
};

export default TwoFADeleteConfirmation;
