import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IntlTelInput from 'react-intl-tel-input';
import { ErrorMessage, useField } from 'formik';
import 'react-intl-tel-input/dist/main.css';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectPortalGatewayISOCodesListToIgnoreAreaCode } from '../../store/selectors/settingsSelectors';
import { shouldIgnoreAreaCodeHelper } from '../../util/helpers/phoneNumberHelpers';
import {
  InputError,
  InputHeader,
  InputLabel,
} from './Components/InputComponents';
import { uOutlineNone } from '../../assets/styles/utility';
import { pxToRem } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import themeColors from '../../assets/styles/themeColors';
import chevronDown from '../../assets/images/svg/blue-filled-chevron-down.svg';

const getPhoneNumberFieldWrapBorderStyle = (isFocused, isError) => {
  if (isFocused) {
    return `1px solid ${themeColors.colorInputBorderFocused}`;
  }

  if (isError) {
    return `1px solid ${themeColors.colorError}`;
  }

  return `1px solid ${themeColors.colorInputBorder}`;
};

const PhoneNumberFieldWrap = styled.div`
  .intl-tel-input {
    width: 100%;

    input {
      ${uOutlineNone}
      width: 100%;
      height: ${pxToRem(50)};
      border-radius: ${variables.borderRadius.borderRadius};
      border: ${({ isFocused, isError }) =>
        getPhoneNumberFieldWrapBorderStyle(isFocused, isError)};
      padding-left: ${pxToRem(90)} !important;
      font-size: ${pxToRem(16)};
      color: ${themeColors.colorLabel};
      line-height: 1.75;
    }

    .selected-flag {
      border-right: ${({ isFocused, isError }) =>
        getPhoneNumberFieldWrapBorderStyle(isFocused, isError)};
      width: ${pxToRem(80)} !important;
      background-color: transparent !important;

      .iti-flag {
        &::after {
          content: '';
          display: block;
          width: ${pxToRem(16)};
          height: ${pxToRem(16)};
          background-image: url(${chevronDown});
          color: ${themeColors.colorTextPrimary};
          margin-left: ${pxToRem(30)};
          margin-top: -${1};
          transition: all 0.2s;
        }
      }
    }

    .arrow {
      display: none;
    }

    .country-list {
      width: 100%;
      margin-top: 1px;

      li {
        cursor: pointer;
      }

      span {
        font-size: ${pxToRem(16)};
        color: ${themeColors.colorTextSecondary};
        margin-left: ${pxToRem(12)};
      }
    }
  }
`;

const TelephoneInput = ({ name, label, onPhoneChange, isUsa, ...props }) => {
  const [field, meta] = useField(name);
  const [isFocused, setIsFocused] = useState(false);
  const selectedISOCodesListToIgnoreAreaCode = useSelector(
    selectPortalGatewayISOCodesListToIgnoreAreaCode,
  );

  // process number into string with area code for submission
  const processNumber = (isValid, phone, country) => {
    const shouldIgnoreAreaCode = shouldIgnoreAreaCodeHelper(
      country,
      selectedISOCodesListToIgnoreAreaCode,
    );

    if (shouldIgnoreAreaCode) {
      if (country.iso2 === 'pr') {
        return `+${country.dialCode}${phone}`;
      }

      const phoneNumber = `+${country.dialCode}${phone.substring(3)}`;
      return phoneNumber;
    }

    if (!phone) {
      return '';
    }

    if (phone.includes(`+${country.dialCode}`)) {
      return `${phone}`;
    }

    return `+${country.dialCode}${phone}`;
  };

  return (
    <PhoneNumberFieldWrap
      isFocused={isFocused}
      isError={meta.error && meta.touched}
    >
      <InputHeader>
        {label && <InputLabel htmlFor={field.name}>{label}</InputLabel>}
      </InputHeader>{' '}
      <IntlTelInput
        {...props}
        label="telephone"
        defaultValue={field.value}
        fieldId={name}
        fieldName={name}
        type="number"
        onPhoneNumberFocus={() => {
          setIsFocused(true);
        }}
        onPhoneNumberBlur={() => {
          setIsFocused(false);
        }}
        onPhoneNumberChange={(isValid, phone, country) => {
          onPhoneChange(processNumber(isValid, phone, country));
        }}
      />
      <ErrorMessage name={name}>
        {(errorMessage) => {
          const isErrorString = typeof errorMessage === 'string';

          return (
            <InputError>
              {isErrorString ? errorMessage : errorMessage.value}
            </InputError>
          );
        }}
      </ErrorMessage>
    </PhoneNumberFieldWrap>
  );
};

TelephoneInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onPhoneChange: PropTypes.func,
  isUsa: PropTypes.bool,
};
export default TelephoneInput;
