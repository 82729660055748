import {
  createDeleteType,
  createErrorType,
  createFetchType,
  createLoadingType,
  createSubmitType,
  createSuccessType,
  createUpdateType,
} from '../actionHelpers';

export const SET_USER = 'SET_USER';
export const SET_USER_ERROR = 'SET_USER_ERROR';

const USER_ACCOUNTS_SCOPE = 'USER_ACCOUNTS_FETCH';
export const USER_ACCOUNTS_FETCH = createFetchType(USER_ACCOUNTS_SCOPE);
export const USER_ACCOUNTS_FETCH_SUCCESS = createSuccessType(
  USER_ACCOUNTS_SCOPE,
);
export const USER_ACCOUNTS_FETCH_ERROR = createErrorType(USER_ACCOUNTS_SCOPE);

// fetch security questions
const USER_SECURITY_QUESTION_SCOPE = 'USER_SECURITY_QUESTION_FETCH';
export const USER_SECURITY_QUESTION_FETCH = createFetchType(
  USER_SECURITY_QUESTION_SCOPE,
);
export const USER_SECURITY_QUESTION_FETCH_SUCCESS = createSuccessType(
  USER_SECURITY_QUESTION_SCOPE,
);
export const USER_SECURITY_QUESTION_FETCH_ERROR = createErrorType(
  USER_SECURITY_QUESTION_SCOPE,
);
export const USER_SECURITY_QUESTION_FETCH_LOADING = createLoadingType(
  USER_SECURITY_QUESTION_SCOPE,
);

// forgotten password
const PASSWORD_FORGOTTEN_REQUEST_SCOPE = 'PASSWORD_FORGOTTEN_REQUEST';
export const PASSWORD_FORGOTTEN_REQUEST = createUpdateType(
  PASSWORD_FORGOTTEN_REQUEST_SCOPE,
);
export const PASSWORD_FORGOTTEN_REQUEST_SUCCESS = createSuccessType(
  PASSWORD_FORGOTTEN_REQUEST_SCOPE,
);
export const PASSWORD_FORGOTTEN_REQUEST_ERROR = createErrorType(
  PASSWORD_FORGOTTEN_REQUEST_SCOPE,
);

export const PASSWORD_FORGOTTEN_REQUEST_LOADING = createLoadingType(
  PASSWORD_FORGOTTEN_REQUEST_SCOPE,
);

// Forgot Password Registration
const FORGOT_PASSWORD_REQUEST_SCOPE = 'FORGOT_PASSWORD_REQUEST_SCOPE';
export const FORGOT_PASSWORD_REQUEST = createUpdateType(
  FORGOT_PASSWORD_REQUEST_SCOPE,
);
export const FORGOT_PASSWORD_REQUEST_SUCCESS = createSuccessType(
  FORGOT_PASSWORD_REQUEST_SCOPE,
);
export const FORGOT_PASSWORD_REQUEST_ERROR = createErrorType(
  FORGOT_PASSWORD_REQUEST_SCOPE,
);
export const FORGOT_PASSWORD_REQUEST_LOADING = createLoadingType(
  FORGOT_PASSWORD_REQUEST_SCOPE,
);

// reset password authentication
const RESET_PASSWORD_REQUEST_SCOPE = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_REQUEST = createUpdateType(
  RESET_PASSWORD_REQUEST_SCOPE,
);
export const RESET_PASSWORD_REQUEST_SUCCESS = createSuccessType(
  RESET_PASSWORD_REQUEST_SCOPE,
);
export const RESET_PASSWORD_REQUEST_ERROR = createErrorType(
  RESET_PASSWORD_REQUEST_SCOPE,
);

// registration password authentication
const REGISTRATION_RESET_PASSWORD_REQUEST_SCOPE =
  'REGISTRATION_RESET_PASSWORD_REQUEST';
export const REGISTRATION_RESET_PASSWORD_REQUEST = createUpdateType(
  REGISTRATION_RESET_PASSWORD_REQUEST_SCOPE,
);
export const REGISTRATION_RESET_PASSWORD_REQUEST_SUCCESS = createSuccessType(
  REGISTRATION_RESET_PASSWORD_REQUEST_SCOPE,
);
export const REGISTRATION_RESET_PASSWORD_REQUEST_ERROR = createErrorType(
  REGISTRATION_RESET_PASSWORD_REQUEST_SCOPE,
);
export const REGISTRATION_RESET_PASSWORD_REQUEST_LOADING = createLoadingType(
  REGISTRATION_RESET_PASSWORD_REQUEST_SCOPE,
);

// remoove user
const REMOVE_USER_SCOPE = 'REMOVE_USER';
export const REMOVE_USER = createDeleteType(REMOVE_USER_SCOPE);

// update user
const UPDATE_USER_SCOPE = 'UPDATE_USER';
export const UPDATE_USER = createSubmitType(UPDATE_USER_SCOPE);

// update user account state
const UPDATE_USER_STATE_SCOPE = 'UPDATE_USER_STATE';
export const UPDATE_USER_STATE = createSubmitType(UPDATE_USER_STATE_SCOPE);
export const UPDATE_USER_STATE_SUCCESS = createSuccessType(
  UPDATE_USER_STATE_SCOPE,
);
export const UPDATE_USER_STATE_ERROR = createErrorType(UPDATE_USER_STATE_SCOPE);
export const UPDATE_USER_STATE_LOADING = createLoadingType(
  UPDATE_USER_STATE_SCOPE,
);

// update user password
const UPDATE_USER_PASS_SCOPE = 'UPDATE_USER_PASS';
export const UPDATE_USER_PASS = createSubmitType(UPDATE_USER_PASS_SCOPE);

// register - get usernames by email
const FETCH_USERSNAMES_SCOPE = 'FETCH_USERNAMES';
export const FETCH_USERSNAMES = createFetchType(FETCH_USERSNAMES_SCOPE);
export const FETCH_USERSNAMES_SUCCESS = createSuccessType(
  FETCH_USERSNAMES_SCOPE,
);
export const FETCH_USERSNAMES_ERROR = createErrorType(FETCH_USERSNAMES_SCOPE);
export const FETCH_USERSNAMES_LOADING = createLoadingType(
  FETCH_USERSNAMES_SCOPE,
);

// create a new user
const CREATE_USER_SCOPE = 'CREATE_USER';
export const CREATE_USER = createSubmitType(CREATE_USER_SCOPE);
export const CREATE_USER_SUCCESS = createSuccessType(CREATE_USER_SCOPE);
export const CREATE_USER_ERROR = createErrorType(CREATE_USER_SCOPE);
export const CREATE_USER_LOADING = createLoadingType(CREATE_USER_SCOPE);

// User Registration Accounts
const USER_REGISTRATION_ACCOUNTS_SCOPE = 'USER_REGISTRATION_ACCOUNTS_FETCH';
export const USER_REGISTRATION_ACCOUNTS_FETCH = createFetchType(
  USER_REGISTRATION_ACCOUNTS_SCOPE,
);
export const USER_REGISTRATION_ACCOUNTS_FETCH_SUCCESS = createSuccessType(
  USER_REGISTRATION_ACCOUNTS_SCOPE,
);
export const USER_REGISTRATION_ACCOUNTS_FETCH_ERROR = createErrorType(
  USER_REGISTRATION_ACCOUNTS_SCOPE,
);
export const USER_REGISTRATION_ACCOUNTS_LOADING = createLoadingType(
  USER_REGISTRATION_ACCOUNTS_SCOPE,
);
// create a new user
const INVITE_USER_SCOPE = 'INVITE_USER';
export const INVITE_USER = createSubmitType(INVITE_USER_SCOPE);
export const INVITE_USER_SUCCESS = createSuccessType(INVITE_USER_SCOPE);
export const INVITE_USER_ERROR = createErrorType(INVITE_USER_SCOPE);
export const INVITE_USER_LOADING = createLoadingType(INVITE_USER_SCOPE);

// get user email
const INVITE_USER_EMAIL_SCOPE = 'INVITE_USER_EMAIL';
export const INVITE_USER_EMAIL = createSubmitType(INVITE_USER_EMAIL_SCOPE);
export const INVITE_USER_EMAIL_SUCCESS = createSuccessType(
  INVITE_USER_EMAIL_SCOPE,
);
export const INVITE_USER_EMAIL_ERROR = createErrorType(INVITE_USER_EMAIL_SCOPE);
export const INVITE_USER_EMAIL_LOADING = createLoadingType(
  INVITE_USER_EMAIL_SCOPE,
);

// update user registration
const UPDATE_USER_REGISTRATION_SCOPE = 'UPDATE_USER_REGISTRATION';
export const UPDATE_USER_REGISTRATION = createSubmitType(
  UPDATE_USER_REGISTRATION_SCOPE,
);
export const UPDATE_USER_REGISTRATION_SUCCESS = createSuccessType(
  UPDATE_USER_REGISTRATION_SCOPE,
);
export const UPDATE_USER_REGISTRATION_ERROR = createErrorType(
  UPDATE_USER_REGISTRATION_SCOPE,
);
export const UPDATE_USER_REGISTRATION_LOADING = createLoadingType(
  UPDATE_USER_REGISTRATION_SCOPE,
);

const SECURITY_QUESTION_BY_USERNAME_SCOPE =
  'SECURITY_QUESTION_BY_USERNAME_SCOPE';
export const SECURITY_QUESTION_BY_USERNAME = createFetchType(
  SECURITY_QUESTION_BY_USERNAME_SCOPE,
);
export const SECURITY_QUESTION_BY_USERNAME_SUCCESS = createSuccessType(
  SECURITY_QUESTION_BY_USERNAME_SCOPE,
);
export const SECURITY_QUESTION_BY_USERNAME_ERROR = createErrorType(
  SECURITY_QUESTION_BY_USERNAME_SCOPE,
);
export const SECURITY_QUESTION_BY_USERNAME_LOADING = createLoadingType(
  SECURITY_QUESTION_BY_USERNAME_SCOPE,
);

const USER_PHONE_NUMBERS_SCOPE = 'USER_PHONE_NUMBERS_SCOPE';

export const USER_PHONE_NUMBERS = createFetchType(USER_PHONE_NUMBERS_SCOPE);
export const USER_PHONE_NUMBERS_SUCCESS = createSuccessType(
  USER_PHONE_NUMBERS_SCOPE,
);
export const USER_PHONE_NUMBERS_ERROR = createErrorType(
  USER_PHONE_NUMBERS_SCOPE,
);
export const USER_PHONE_NUMBERS_LOADING = createLoadingType(
  USER_PHONE_NUMBERS_SCOPE,
);

const USER_PHONE_NUMBER_SCOPE = 'USER_PHONE_NUMBER_SCOPE';
export const USER_PHONE_NUMBER = createFetchType(USER_PHONE_NUMBER_SCOPE);
export const USER_PHONE_NUMBER_SUCCESS = createSuccessType(
  USER_PHONE_NUMBER_SCOPE,
);
export const USER_PHONE_NUMBER_ERROR = createErrorType(USER_PHONE_NUMBER_SCOPE);
export const USER_PHONE_NUMBER_LOADING = createLoadingType(
  USER_PHONE_NUMBER_SCOPE,
);

const DELETE_USER_PHONE_NUMBER_SCOPE = 'DELETE_USER_PHONE_NUMBER_SCOPE';

export const DELETE_USER_PHONE_NUMBER = createDeleteType(
  DELETE_USER_PHONE_NUMBER_SCOPE,
);
export const DELETE_USER_PHONE_NUMBER_SUCCESS = createSuccessType(
  DELETE_USER_PHONE_NUMBER_SCOPE,
);
export const DELETE_USER_PHONE_NUMBER_ERROR = createErrorType(
  DELETE_USER_PHONE_NUMBER_SCOPE,
);
export const DELETE_USER_PHONE_NUMBER_LOADING = createLoadingType(
  DELETE_USER_PHONE_NUMBER_SCOPE,
);

// Add new phone number
const SUBMIT_PHONE_SCOPE = 'SUBMIT_PHONE';
export const SUBMIT_PHONE = createSubmitType(SUBMIT_PHONE_SCOPE);
export const SUBMIT_PHONE_SUCCESS = createSuccessType(SUBMIT_PHONE_SCOPE);
export const SUBMIT_PHONE_ERROR = createErrorType(SUBMIT_PHONE_SCOPE);
export const SUBMIT_PHONE_LOADING = createLoadingType(SUBMIT_PHONE_SCOPE);
