export const ADDRESS_SET_TO_DEFAULT = 'address_set_to_default';
export const ADDRESS_DELETED = 'address_deleted';
export const USER_LOGOUT = 'user_logout';
export const TRADE_INITIATED = 'trade_initiated';
export const TRADE_EXECUTED = 'trade_executed';
export const DEPOSIT_INITIAITED = 'deposit_initiated';
export const DEPOSIT = 'deposit';
export const AIP_UPDATED = 'aip_updated';
export const AIP_ENABLED = 'aip_enabled';
export const DELIVERY_EXECUTED = 'delivery_executed';
export const PASSWORD_CHANGED = 'password_changed';
export const SECURITY_QUESTION_CHANGED = 'security_question_changed';
export const FULL_NAME_EDITED = 'full_name_edited';
export const WITHDRAW_INITIATED = 'withdraw_initiated';
export const WITHDRAW = 'withdraw';
export const AIP_RESTARTED = 'aip_restarted';
export const AIP_DISABLED = 'aip_disabled';
export const USER_LOGIN = 'login';
export const DELIVERY_INITIATED = 'delivery_initiated';
export const PURCHASE = 'purchase';
export const ADD_TO_CART = 'add_to_cart';
export const APPLICATION_STARTED = 'application_started';
export const VIEW_ITEM = 'view_item';
export const SELECT_ITEM = 'select_item';
export const ACCOUNT_DATA_LOADED = 'account_data_loaded';
export const BANK_ACCOUNT_ADDED = 'app.bank_account_added';
export const BANK_ACCOUNT_REMOVED = 'app.bank_account_removed';
export const FUND_DEPOSIT_START = 'app.fund_deposit_start';
export const FUND_DEPOSIT_COMPLETE = 'app.fund_deposit_complete';
export const FUND_WITHDRAW_START = 'app.fund_withdraw_start';
export const FUND_WITHDRAW_COMPLETE = 'app.fund_withdraw_complete';
