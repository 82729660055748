import { isAfter, add } from 'date-fns';
import {
  COUNTDOWN_TIMER_INTERVAL_MS,
  COUNTDOWN_TIMER_SEC,
} from '../../constants/timerConstants';

export const handleExpiration = (timeStampString, setExpirationMs) => {
  const quoteExpirationTime = new Date(timeStampString.toString());
  const now = new Date(Date.now());
  if (isAfter(quoteExpirationTime, now)) {
    const millisecondsToTimerExpiration = quoteExpirationTime - now;
    setExpirationMs(millisecondsToTimerExpiration);
  } else {
    setExpirationMs(COUNTDOWN_TIMER_SEC * COUNTDOWN_TIMER_INTERVAL_MS);
  }
};

export const counterValueToTimestamp = (counterValue) => {
  const now = new Date(Date.now());
  let secondsToAdd;
  if (!counterValue || counterValue <= 0) {
    secondsToAdd = COUNTDOWN_TIMER_SEC;
  }
  secondsToAdd = counterValue;

  const expirationDate = add(now, { seconds: secondsToAdd });

  return expirationDate.toISOString();
};
