import styled from 'styled-components';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { uFlexCenter, uFlexColumn } from '../../assets/styles/utility';

import Button from '../../components/Button/Button';
import Section from '../../components/Section/Section';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';

export const UtilitiesPageTitle = styled.div`
  font-size: ${pxToRem(160)};
  line-height: 1.35;
  margin-bottom: ${pxToRem(32)};
  color: ${themeColors.colorPrimary};
  font-weight: bold;
`;

export const UtilitiesPageText = styled.div`
  font-weight: 600;
  margin-bottom: ${pxToRem(24)};
  text-align: center;
`;

export const UtilitiesPageContainer = styled.div`
  margin-top: ${pxToRem(120)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-top: ${pxToRemMd(120)};

    ${UtilitiesPageTitle} {
      font-size: ${pxToRemMd(160)};
      margin-bottom: ${pxToRemMd(27)};
    }

    ${UtilitiesPageText} {
      margin-bottom: ${pxToRemMd(24)};
    }
  }
`;

export const UtilitiesPageSection = styled(Section)`
  ${uFlexCenter};
`;

export const UtilitiesPageContentStyle = styled.div`
  ${uFlexColumn};
  align-items: center;
  padding: 0 ${pxToRem(32)};
`;

export const UtilitiesPageButton = styled(Button)`
  margin-bottom: ${pxToRem(16)};
  min-width: ${pxToRem(250)};
`;
