import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LineChart from '../../Charts/LineChart';
import BlockSectionLoader from '../../Loader/BlockSectionLoader';
import PortfolioHeader from '../Header/PortfolioHeader';
import Section from '../../Section/Section';
import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import { variables } from '../../../assets/styles/variables';

const PortfolioChartContainer = styled.div`
  margin-bottom: ${pxToRem(56)};
  padding-bottom: ${pxToRem(17)};
  min-width: 0;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    box-shadow: none;
    margin-bottom: ${pxToRemMd(40)};
    padding-bottom: 0;
  }
`;

const PortfolioChart = ({
  data,
  period,
  getPeriod,
  selectedPeriod,
  selectedType,
  setSelectedPeriod,
  maximumPeriodType,
  domain,
  isLoading,
  timestamp,
  portfolioValuation,
  currentPrice,
  types,
  setSelectedType,
  change,
  percentage,
  chartError,
  showDashboardOnPortfolio,
}) => (
  <PortfolioChartContainer data-cy="container-portfolio-graphs">
    <Section withoutPadding={showDashboardOnPortfolio}>
      <PortfolioHeader
        timestamp={timestamp}
        portfolioValuation={portfolioValuation}
        currentPrice={currentPrice}
        types={types}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        change={change}
        percentage={percentage}
        isLoading={isLoading}
        chartError={chartError}
      />
      {isLoading ? (
        <BlockSectionLoader isLoading noShadow />
      ) : (
        <LineChart
          data={data}
          period={period}
          getPeriod={getPeriod}
          selectedPeriod={selectedPeriod}
          selectedType={selectedType}
          setSelectedPeriod={setSelectedPeriod}
          maximumPeriodType={maximumPeriodType}
          domain={domain}
          chartError={chartError}
        />
      )}
    </Section>
  </PortfolioChartContainer>
);

PortfolioChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  selectedPeriod: PropTypes.string,
  setSelectedPeriod: PropTypes.func,
  selectedType: PropTypes.string,
  maximumPeriodType: PropTypes.string,
  period: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
  getPeriod: PropTypes.func,
  domain: PropTypes.shape({}),
  timestamp: PropTypes.string,
  portfolioValuation: PropTypes.number,
  currentPrice: PropTypes.number,
  types: PropTypes.arrayOf(PropTypes.string),
  setSelectedType: PropTypes.func,
  change: PropTypes.number,
  percentage: PropTypes.number,
  chartError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      ticks: PropTypes.arrayOf(null),
    }),
  ]),
  showDashboardOnPortfolio: PropTypes.bool,
};

export default PortfolioChart;
