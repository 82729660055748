import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import HoldingTableHeader from './HoldingTableHeader';
import HoldingTableRow from './HoldingTableRow';
import { formatMoneyNumeral } from '../../../util/helpers/numeralHelpers';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import { productLocationTypes } from '../../../util/enum/api/productTypes';
import { separateByUppercase } from '../../../util/helpers/stringHelpers';
import { uFlexColumn } from '../../../assets/styles/utility';
import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import { variables } from '../../../assets/styles/variables';

export const HoldingTableContainer = styled.div`
  ${uFlexColumn};

  &:not(:last-of-type) {
    margin-bottom: ${pxToRem(32)};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    &:not(:last-of-type) {
      margin-bottom: ${pxToRemMd(16)};
    }
  }
`;

export const HoldingTableBody = styled.div`
  ${uFlexColumn};
`;

const HoldingTable = ({
  title,
  value,
  ounces,
  quantity,
  data,
  isSortByProduct,
  isSortByVault,
  isSortByType,
  isDiamondProduct,
}) => {
  const [showTableBody, setShowTableBody] = useState(false);
  const onHeaderClick = () => setShowTableBody(!showTableBody);

  return (
    <HoldingTableContainer>
      <HoldingTableHeader
        title={title}
        value={value}
        ounces={ounces}
        quantity={quantity}
        onClick={onHeaderClick}
        isOpen={showTableBody}
        isSortByVault={isSortByVault}
        isSortByType={isSortByType}
        isSortByProduct={isSortByProduct}
        isDiamondProduct={isDiamondProduct}
      />
      {showTableBody && (
        <HoldingTableBody>
          {data.map((item, index) => (
            <>
              <HoldingTableRow
                key={`${item}_${index}`} // eslint-disable-line react/no-array-index-key
                title={
                  isSortByProduct
                    ? separateByUppercase(
                        parseEnumType(productLocationTypes, item.LocationType),
                      )
                    : item.ProductCaption
                }
                isTestProduct={item.IsTestProduct}
                isSegregated={item.IsSegregated}
                isSortByVault={isSortByVault}
                quantity={item.Quantity}
                symbol={item.SymbolCode}
                productCode={item.ProductCode}
                value={formatMoneyNumeral(item.Valuation)}
              />
            </>
          ))}
        </HoldingTableBody>
      )}
    </HoldingTableContainer>
  );
};

HoldingTable.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  ounces: PropTypes.string,
  quantity: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  isSortByProduct: PropTypes.bool,
  isSortByVault: PropTypes.bool,
  isSortByType: PropTypes.bool,
  isDiamondProduct: PropTypes.bool,
};

export default HoldingTable;
