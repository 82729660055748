import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { isEmpty } from 'lodash';
import AccountSection from './AccountSection';
import AccountCurrent from './AccountCurrent';
import Button from '../Button/Button';

import { PROFILE_SETTINGS_PAGE } from '../../constants/pages';

import { ReactComponent as DropdownIcon } from '../../assets/images/svg/dropdown.svg';
import { logoutUser } from '../../store/actions/login/loginActions';
import {
  getCurrentAccount,
  selectGroupedAccounts,
} from '../../store/selectors/accountSelectors';
import useGtmHook from '../../util/hooks/useGtmHook';
import { parseEnumType } from '../../util/helpers/enumMappers';
import {
  accountSubTypeKeys,
  accountTypeKeys,
  IRATrusteesType,
} from '../../util/enum/api/accountTypes';
import { USER_LOGOUT } from '../../constants/gtmEvents';
import { selectSettings } from '../../store/selectors/settingsSelectors';
import { useIsClosedAccount } from '../../util/hooks/useIsClosedAccount';
import themeColors from '../../assets/styles/themeColors';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import {
  uFlexColumn,
  uResetPosition,
  uTextEllipsis,
  uTransition,
} from '../../assets/styles/utility';
import { selectAuthUser } from '../../store/selectors/userSelectors';
import config from '../../config';
import { lpmWebsiteUrl } from '../../util/constants';

const AccountContainer = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  background-color: ${themeColors.colorAccountBackground};
  flex-shrink: 0;
  z-index: 1231231231;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    background-color: ${themeColors.colorWhite};
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
`;

const AccountDetails = styled.div`
  display: flex;
  padding: 0 ${pxToRem(24)} 0 ${pxToRem(16)};
  align-items: center;
  width: 100%;
  cursor: pointer;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    display: none;
  }
`;

const AccountInfo = styled.div`
  ${uFlexColumn};
  margin-right: ${pxToRem(16)};
  min-width: 0;
`;

export const AccountIconWrapper = styled.div`
  margin-left: auto;

  svg {
    ${uTransition};
    width: ${pxToRem(24)};
    height: ${pxToRem(24)};
    color: ${themeColors.colorTextContrast};
  }
`;

export const AccountDropdown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  width: 100%;
  max-height: 75vh;
  background-color: ${themeColors.colorWhite};
  z-index: ${variables.zIndex.indexXs};
  box-shadow: ${variables.shadow.accountDropdownShadow};
  border-radius: ${variables.borderRadius.borderRadius};
  display: none;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    ${uResetPosition};
    ${uFlexColumn};
    box-shadow: none;
    height: 100%;
    overflow: auto;
    max-height: 100%;
  }
`;

const AccountCta = styled.div`
  padding: ${pxToRem(16)};
  border-top: 2px solid ${themeColors.colorAccountBorder};

  > :not(:last-child) {
    margin-bottom: ${pxToRem(16)};
  }

  button {
    width: 100%;
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: ${pxToRemMd(32)};
    margin-top: auto;
  }
`;

const AccountList = styled.div`
  ${uFlexColumn};
  flex-grow: 1;
  overflow: auto;
`;

export const AccountName = styled.p`
  ${uTextEllipsis};
  font-size: ${pxToRem(20)};
  line-height: 1.4;
  color: ${themeColors.colorTextContrast};
`;

export const AccountCode = styled.p`
  font-weight: 600;
  font-size: ${pxToRem(14)};
  line-height: 1.715;

  > span {
    color: ${themeColors.colorTextContrast};
  }
`;

const Account = ({
  setIsDropdownOpen,
  setIsAccountOpen,
  setNoPermissionsModal,
  isBasicAccount,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const brokerSettings = useSelector(selectSettings);
  const currentAccount = useSelector(getCurrentAccount);
  const otherAccounts = useSelector(selectGroupedAccounts);
  const { gtmEventUser } = useGtmHook();
  const isClosedAccount = useIsClosedAccount();
  const { RegistrationUrl } = brokerSettings;
  const userInfo = useSelector(selectAuthUser);
  const settings = useSelector(selectSettings);
  const { Email } = userInfo;
  const { brokerTheme } = config;
  const [newAccountUrl, setNewAccountUrl] = useState(
    `${RegistrationUrl}?email=${encodeURIComponent(Email)}`,
  );

  const otherAccountsFiltered = () => {
    const allAccounts = Object.values(otherAccounts).flat();

    if (isEmpty(allAccounts)) {
      return [];
    }

    const accounts = allAccounts.filter((item) => item.AccountStatus !== 6);
    const closedAccounts = allAccounts.filter(
      (item) => item.AccountStatus === 6,
    );

    return [...accounts, ...closedAccounts];
  };

  useEffect(() => {
    if (
      Email &&
      RegistrationUrl &&
      settings &&
      !isEmpty(settings) &&
      settings.BrokerCode === 'LPM'
    ) {
      setNewAccountUrl(
        `${RegistrationUrl}/r/start?email=${encodeURIComponent(Email)}`,
      );
    }
  }, [Email, RegistrationUrl, settings]);

  return (
    <AccountContainer>
      <AccountDetails>
        {currentAccount && currentAccount.AccountType !== 0 && (
          <AccountInfo>
            <AccountName>
              {String(currentAccount.DisplayName)
                .replace(`(${currentAccount.AccountCode})`, '')
                .trim()}
            </AccountName>
            <AccountCode>
              {currentAccount.IRATrusteesType && (
                <span>
                  {t(
                    `account.iraTrusteeTypes.${parseEnumType(
                      IRATrusteesType,
                      currentAccount.IRATrusteesType,
                    )}`,
                  )}
                </span>
              )}
              {currentAccount.AccountSubType && (
                <span>
                  {t(
                    `account.subtypes.${parseEnumType(
                      accountSubTypeKeys,
                      currentAccount.AccountSubType,
                    )}`,
                  )}
                </span>
              )}
              <span>
                {t(
                  `account.types.${parseEnumType(
                    accountTypeKeys,
                    currentAccount.AccountType,
                  )}`,
                )}
              </span>{' '}
              &#183; <span>{currentAccount.AccountCode}</span>
            </AccountCode>
          </AccountInfo>
        )}
        <AccountIconWrapper>
          <DropdownIcon />
        </AccountIconWrapper>
      </AccountDetails>
      <AccountDropdown>
        {currentAccount.AccountType ? (
          <AccountCurrent account={currentAccount} />
        ) : null}
        <AccountList>
          <AccountSection
            setIsDropdownOpen={setIsDropdownOpen}
            setIsAccountOpen={setIsAccountOpen}
            accounts={otherAccountsFiltered()}
          />
        </AccountList>
        <AccountCta>
          {!isClosedAccount && (
            <Button
              variant="primaryOutlined"
              onClick={() => {
                setIsDropdownOpen(false);
                setIsAccountOpen(false);
                history.push(PROFILE_SETTINGS_PAGE);
              }}
              data-cy="button-user-settings"
            >
              {i18n.t('user.userSettings')}
            </Button>
          )}
          <Button
            variant="primaryOutlined"
            onClick={() => {
              setIsDropdownOpen(false);
              setIsAccountOpen(false);
              window.open(newAccountUrl);
            }}
            disabled={isBasicAccount}
            data-cy="button-new-account"
          >
            {i18n.t('user.openNewAccount')}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              gtmEventUser(USER_LOGOUT);
              dispatch(
                logoutUser({
                  setNoPermissionsModal,
                  redirectPage: brokerTheme === 'LegacyPM' && lpmWebsiteUrl,
                }),
              );
            }}
            data-cy="button-logout"
          >
            {i18n.t('user.logout')}
          </Button>
        </AccountCta>
      </AccountDropdown>
    </AccountContainer>
  );
};

Account.propTypes = {
  setIsDropdownOpen: PropTypes.func,
  setIsAccountOpen: PropTypes.func,
  setNoPermissionsModal: PropTypes.func,
  isBasicAccount: PropTypes.bool,
};

export default Account;
