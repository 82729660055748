import { createSelector } from 'reselect';

export const portfolioProductsSelector = (state) => state.portfolio.products;

export const selectPortfolioSingleProduct = createSelector(
  portfolioProductsSelector,
  (state) => state.singleProduct,
);

export const selectPortfolioProductError = createSelector(
  portfolioProductsSelector,
  (state) => state.error,
);

export const selectPortfolioSingleProductExists = createSelector(
  portfolioProductsSelector,
  (state) => state.productDoesntExist,
);
