import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import isEmpty from 'lodash.isempty';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import SelectField from '../../../InputFields/SelectField';
import NumberField from '../../../InputFields/NumberField';
import TextField from '../../../InputFields/TextField';

import { separateByUppercase } from '../../../../util/helpers/stringHelpers';
import { selectBankAccountDetailsByRoutingNum } from '../../../../store/selectors/bankAccountSelectors';
import { selectSupportEmail } from '../../../../store/selectors/settingsSelectors';
import {
  clearBaByRoutingNum,
  fetchBaByRoutingNum,
} from '../../../../store/actions/bankAccount/bankAccountActions';
import { ReactComponent as AccountNumber } from '../../../../assets/images/svg/account-number.svg';
import { ReactComponent as RoutingNumber } from '../../../../assets/images/svg/routing-number.svg';
import { ReactComponent as CheckBlank } from '../../../../assets/images/svg/check-blank.svg';
import { newAchValidationSchema } from '../../../../validation/newAchValidationSchema';
import ModalBody from '../../../Modal/ModalBody';
import ModalButtons from '../../../Modal/ModalButtons';
import FormContainer from '../../../FormContainer/FormContainer';
import SingleColumnList from '../../../TwoColumnList/SingleColumnList';
import Label from '../../../Notes/Label';
import Paragraph from '../../../Paragraph/Paragraph';
import Anchor from '../../../Anchor/Anchor';
import { variables } from '../../../../assets/styles/variables';
import { bankAccountTypes } from '../../../../util/enum/api/bankAccountTypes';
import { selectIsLoadingByActionType } from '../../../../store/selectors/loadingSelectors';
import { BA_BY_ROUTING_NUM_LOADING } from '../../../../store/actions/bankAccount/bankAccountActionConstants';
import SectionLoader from '../../../Loader/SectionLoader';

const BANK_ACC_TYPE_SELECT_OPTIONS = bankAccountTypes
  .filter((account) => account !== '')
  .map((account) => ({
    label: `${separateByUppercase(account)} Account`,
    value: account,
  }));

const IMAGE_BLANK = 'blank';
const IMAGE_ACC_NUM = 'account';
const IMAGE_ROUTING = 'routing';

const NewAchImage = styled.div`
  > img {
    object-fit: contain;
    border-radius: ${variables.borderRadius.borderRadius};
    box-shadow: ${variables.shadow.boxShadow};
  }
`;

const NewAchForm = ({ handleClose, setWizardData, handleNext, wizardData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(
    selectIsLoadingByActionType(BA_BY_ROUTING_NUM_LOADING),
  );

  const [bankDetailsShown, setBankDetailsShown] = useState(false);
  const [currentCheckImage, setCurrentCheckImage] = useState(IMAGE_BLANK);
  const bankDetails = useSelector(selectBankAccountDetailsByRoutingNum);
  const subject = 'VOIDED CHECK';
  const email = useSelector(selectSupportEmail);
  const formatBankDetails = (bank) =>
    `${bank.Address.AddressLine}, ${bank.Address.City}, ${bank.Address.StateRegion} ${bank.Address.PostalCode}`;

  const handleRoutingNumberChange = debounce(
    (routingNum, setErrors, errors) => {
      if (routingNum) {
        dispatch(
          fetchBaByRoutingNum({
            side: 'ach',
            routingNum,
            meta: { setErrors, errors },
          }),
        );
      }
    },
    700,
  );

  const handleBack = () => {
    // dispatch(clearBaByRoutingNum());
    handleClose();
  };

  const onFormSubmit = (values) => {
    setWizardData({ achManualFormValues: values });
    handleNext();
  };

  const bankDetailsCanRender = useMemo(
    () => !isEmpty(bankDetails) && !!bankDetails.Address && !!bankDetails.Name,
    [bankDetails],
  );

  const renderCheckImage = useCallback(() => {
    if (currentCheckImage === IMAGE_BLANK) return <CheckBlank />;
    if (currentCheckImage === IMAGE_ROUTING) return <RoutingNumber />;
    if (currentCheckImage === IMAGE_ACC_NUM) return <AccountNumber />;
    return null;
  }, [currentCheckImage]);

  const validateRoutNum = (errors) => {
    if (errors.routingNumber) {
      return t('apiErrors.Banking_RoutingNumberInvalid');
    }
    return '';
  };

  useEffect(() => {
    if (!isEmpty(bankDetails)) {
      setBankDetailsShown(true);
    }
  }, [bankDetails, dispatch]);

  const initialValues = wizardData?.achManualFormValues || {
    accountType: BANK_ACC_TYPE_SELECT_OPTIONS[0],
    routingNumber: '',
    accountNumber: '',
    nameOnAccount: '',
  };

  return (
    <SectionLoader isLoading={isLoading}>
      <ModalBody>
        <Formik
          initialValues={initialValues}
          onSubmit={onFormSubmit}
          validationSchema={newAchValidationSchema(bankDetailsCanRender)}
        >
          {({ isValid, setErrors, setFieldTouched, errors }) => (
            <Form>
              <FormContainer>
                <SelectField
                  name="accountType"
                  options={BANK_ACC_TYPE_SELECT_OPTIONS}
                  label={t('bankAccounts.newAchAccount.accountTypeLabel')}
                  onClick={() => setCurrentCheckImage(IMAGE_BLANK)}
                />
                <NewAchImage>{renderCheckImage()}</NewAchImage>
                <Field
                  name="routingNumber"
                  validate={() => validateRoutNum(errors)}
                  component={NumberField}
                  preventAllExceptNumbers
                  onKeyUp={({ target: { value } }) => {
                    if (value.length === 9) {
                      handleRoutingNumberChange(value, setErrors, errors);
                      setFieldTouched('routingNumber', true);
                    }

                    if (bankDetailsShown) {
                      dispatch(clearBaByRoutingNum());
                    }
                  }}
                  onKeyPress={() => {
                    const err = { ...errors };
                    if (err.routingNumber) delete err.routingNumber;
                    setErrors(err);
                  }}
                  onClick={() => setCurrentCheckImage(IMAGE_ROUTING)}
                  label={t('bankAccounts.newAchAccount.routingNumberLabel')}
                />
                <Field
                  name="accountNumber"
                  component={TextField}
                  preventAllExceptNumbers
                  label={t('bankAccounts.newAchAccount.accountNumberLabel')}
                  onClick={() => setCurrentCheckImage(IMAGE_ACC_NUM)}
                />

                {bankDetailsShown && bankDetailsCanRender && (
                  <SingleColumnList>
                    <Label
                      isBold
                      marginBottom={4}
                      text={t('achDeposit.bankName')}
                    />
                    <Paragraph>{bankDetails.Name}</Paragraph>
                    <Paragraph>{formatBankDetails(bankDetails)}</Paragraph>
                  </SingleColumnList>
                )}
                <Field
                  name="nameOnAccount"
                  component={TextField}
                  label={t('bankAccounts.newAchAccount.nameOnAccountLabel')}
                  onClick={() => setCurrentCheckImage(IMAGE_BLANK)}
                />
                <Anchor
                  value={`${email}?subject=${subject}`}
                  text={t('bankAccounts.newAchAccount.voidedCheckNote')}
                  type="email"
                />
              </FormContainer>
              <ModalButtons
                isHorizontal
                marginTop
                secondaryButtonProps={{ onClick: handleBack }}
                primaryButtonProps={{
                  type: 'submit',
                  disabled: !isValid || isEmpty(bankDetails),
                }}
              />
            </Form>
          )}
        </Formik>
      </ModalBody>
    </SectionLoader>
  );
};

NewAchForm.propTypes = {
  handleNext: PropTypes.func,
  handleClose: PropTypes.func,
  setWizardData: PropTypes.func,
  wizardData: PropTypes.shape,
};

export default NewAchForm;
