export default {
  app: {
    title: '{{brokerName}}',
    impersonatingTitle: '{{brokerName}} - Impersonating - {{accountCode}}',
  },
  account: {
    title: 'Account',
    verified: 'Account Verified',
    addresses: {
      title: 'Addresses',
      nonIraDescriptionFirst:
        'The following addresses are associated with this account and can be used as the destination for deliveries.',
      nonIraDescriptionSecond:
        'For security, all new addresses must be validated. Please',
      nonIraDescriptionSecondLink: 'contact customer service ',
      nonIraDescriptionThird: ' to add a new address to your account.',
      iraDescriptionFirst:
        'The following addresses are associated with this account and can be used as the destination for deliveries.',
      iraDescriptionSecond:
        'Please contact your trust provider to add or change an address to your account:',
      changeOrAddAddressFirst:
        'To add or change your legal or eligible delivery addresses, please ',
      changeOrAddAddressSecond: 'contact customer service',
      changeOrAddAddressThird: '.',
      phone: 'Phone:',
      email: 'Email:',
      noAddresses: 'No addresses added.',
      adddNewAddress: 'Add a New Address',
      addAddressSuccessfulMessage: 'Address has been added successfully!',
      removeAddress: 'Address has been removed successfully. ',
    },
    types: {
      Individual: 'Individual',
      JointTenantsWithRightOfSurvivorship:
        'Joint Tenants With Right Of Survivorship',
      JointTenantsInCommon: 'Joint Tenants In Common',
      Corporate: 'Corporate',
      Partnership: 'Partnership',
      SoleProprietorship: 'Sole Proprietorship',
      LimitedLiabilityCorporation: 'Limited Liability Corporation',
      Trust: 'Trust',
      IRA: 'IRA',
      UTMA: 'UTMA',
      Basic: 'Basic',
    },
    subtypes: {
      Traditional: 'Traditional ',
      Roth: 'Roth ',
      Simple: 'Simple ',
      Sep: 'Sep ',
    },
    iraTrusteeTypes: {
      Entrust: 'Entrust ',
      Millennium: 'Millennium ',
      Equity: 'Equity ',
    },
    currentAccount: 'Current Account:',
    needOurAddress: {
      title: 'Need our address?',
      sendCheck: 'To Send a check use this address:',
      sendApplication:
        'To send an account application or other documents by mail or courier (do not send checks/payment to this address):',
    },
    legacyOrderHistory: {
      description:
        'GoldSilver.com migrated to a new system on Dec. 12th, 2022. The following orders were placed on the old system prior to this date.',
      searchLabel: 'Search orders',
      button: 'Legacy order history',
      tableRow: {
        orderTotal: 'Order Total',
        source: 'Source',
        destination: 'Destination',
      },
      viewDetails: 'View details',
      filterOrders: 'Filter Orders',
      showCanceledOrders: 'Show canceled orders',
      orderType: 'Order type',
      filters: {
        selectOrderType: 'Select order type',
        selectOrderStatus: 'Select order status',
        selectProduct: 'Select product',
      },
      orderTypeLabels: {
        buyForDelivery: 'Buy for delivery',
        buyForStorage: 'Buy for storage',
        sellFromStorage: 'Sell from storage',
        sellFromPP: 'Sell from private possession',
        deliveryFromStorage: 'Delivery from storage',
        buyForIRA: 'Buy for IRA',
        sellFromIRA: 'Sell from IRA',
        clientOffsetBuyOrder: 'Client offset buy order',
      },
      orderStatusTypes: {
        completed: 'Order completed',
        canceled: 'Order canceled',
        declined: 'Order declined',
        paymentIssued: 'Payment Issued',
      },
    },
    legacyOrderDetails: {
      orderDetails: 'Order details',
      datePlaced: 'Date placed',
      referenceNumber: 'Reference number',
      destination: 'Destination',
      source: 'Source',
      shipping: 'Shipping',
      salesTax: 'Sales tax',
      paymentMethod: 'Payment method',
      payPal: 'PayPal',
      printInvoice: 'Print invoice',
      marketLossCharge: 'Market loss charge',
      disbursementMethod: 'Disbursement method',
      refundAmount: 'Refund amount',
      subtotal: 'Subtotal',
      orderTotal: 'Order Total',
      wireFee: 'Wire fee',
    },
  },
  accountNoPermissions: {
    text: 'You do not have permission to work with this account.',
  },
  apiErrors: {
    NetworkError: 'Network Error.',
    InternalServerError: 'Internal Server Error.',
    Error_BrokerNotFound: 'Broker not found.',
    Error_AccountNotFound: 'Account not found.',
    Error_PermissionDenied: 'Permission denied.',
    Order_InsufficientFunds: 'Insufficient funds.',
    Order_InsufficientQuantity: 'Insufficient quantity.',
    Order_OrderTypeIsNotAllowed: 'Order type is not allowed.',
    Order_ProductNotFound: 'Product not found.',
    Order_ProductIsNotAllowedForBuy: 'Product is not allowed for buy.',
    Order_ProductIsNotAllowedForTrading: 'Product is not allowed for trading.',
    Order_ProductIsNotAllowedForIraAccount:
      'Product is not allowed for ira account.',
    Order_InvalidQuantity: 'Invalid quantity.',
    Order_InvalidAmount: 'Invalid amount.',
    Order_InvalidPercentage: 'Invalid percentage.',
    Order_ProductIsAllowedOnlyForCash: 'Product is allowed only for cash.',
    Order_CashSellOrderIsNotAllowed: 'Cash sell order is not allowed.',
    Order_ProductIsNotAvailableForBuy: 'Product is not available for buy.',
    Order_ProductIsNotAvailableForSell: 'Product is not available for sell.',
    Order_EntrustAccountCannotSubmitIraCHECKFeeWithdrawalOrder:
      'Entrust account cannot submit Ira Check Fee Withdrawal Order',
    Order_ProductIsNotAvailableForImmediateExecution:
      'Product is not available for immediate execution.',
    Order_AchDepositMinLimit: 'ACH deposit min limit.',
    Order_AchDepositMaxLimit: 'ACH deposit max limit.',
    Order_FractionalOrder_PriceIsNotAvailable:
      'Fractional product is not available for trading.',
    Order_NotTestProduct: 'This order is not allowed for non test products.',
    Account_StatementNotFound: 'Statement not found.',
    Account_InvoiceNotFound: 'Invoice not found.',
    Account_1099DocumentNotFound: 'Document not found.',
    Banking_BankNotFound: 'Bank not found.',
    Banking_BankAccountNotFound: 'Bank account not found.',
    Banking_BankAccountMustBeActivated: 'Bank account must be activated.',
    Banking_ReceivingBankAccountDoesNotExist:
      'Receiving bank account does not exist.',
    BankAccountsAddedThresholdExceeded:
      'Exceeded number of added ACH bank accounts.',
    Banking_RoutingNumberInvalid: 'Routing number invalid',
    InvalidABARoutingNumber: 'Routing number invalid.',
    Users_OldPasswordNotMatching: 'Old password not matching.',
    UserAlreadyHasAccess: 'User already has access.',
    UserDoesNotHavePermissions: 'User does not have permissions.',
    WrongCredentialsChangePassword: 'Wrong credentials.',
    PhoneIsNull: "Phone can't be null.",
    PhoneDoNotBelongToUser: 'Phone do not belong to user.',
    CantDeleteOnlyPhoneNumber: 'Deleting only phone number is not allowed.',
    DuplicatePhoneNumber: 'Duplicated phone number',
    EmailNotFound: 'Email does not exist.',
    PhoneUidIsNull: "Phone Uid can't be null.",
    PhoneMesageTypeIsNull: "Phone message type can't be null.",
    CountryNotFound: 'Country not found',
    AccessedByUserUidIsNull: 'Accessed by user Uid is null.',
    ThereArePendingACHDepositRequests:
      'Remove bank account could not be completed. There are pending ACH Deposit Requests.',
    securityQuestionWrong: 'Wrong answer.',
    NotYetApproved:
      'Sorry, your account has not yet been approved for active trading. New applications are typically processed within two business days, unless additional information is required. A representative will contact you if necessary.',
    wrongUsernameOrPassword:
      'An incorrect username or password has been entered. Please try again.',
    TradecoreExecutionError: 'The provided amount is not valid.',
    Error_AddressUidIsNull: 'The provided address is invalid.',
    Error_BrokerSymbolNotFound: 'The provided broker symbol is not found.',
    Order_RegistrationCompletedChangesNotAllowed:
      'Order registration completed changes is not allowed.',
    Order_WithdrawalIsLocked: 'Withdrawal is locked.',
    Order_UnknownEmailType: 'Unknown email type.',
    Order_UnsupportedIRATrusteeType: 'Unsupported IRA Trustee type.',
    Order_ActionNotAllowedForNonIRAAccount:
      'Action not allowed for non IRA Account.',
    Order_EquityAccountCannotSubmitIraWIREFeeWithdrawalOrder:
      'Equity account cannot submit IRA Wire withdrawal order.',
    Order_ProductOutOfStock: 'This product is no longer in stock.',
    BankAccountAchStateMustBeActivated:
      'Bank Account ACH state must be activated.',
    Order_AutomaticFundingNotEnabled: 'Automatic funding is not enabled.',
    Order_UnassessedStorageFees: 'Unassessed Storage Fees.',
    Order_LocationForDeliveryNotAllowed:
      'Selected product can not be delivered to the selected delivery address. Please change the delivery address or product to be able to proceed. Please contact customer service for additional assistance.',
    AccountIsNotIRA: 'Account is not IRA type.',
    IRATypeNotSupported:
      'This order type is no longer eligible for IRA accounts.',
    ChangeRoleToNotificationOnlyNotAllowed:
      'Change to Notification Only role is not allowed.',
    UTMAFullAccessNotAllowed: 'UTMA Full access is not allowed.',
    EquityIRAServiceError: 'Equity IRA Service Error.',
    Banking_BankAccountNotSecure: 'Bank Account is not secure.',
    Banking_UnknownClientBankAccountType: 'Unknown Bank Account type.',
    BankAccountInUse: 'Bank Account is in use.',
    BothBanksAreSet: 'Both Banks are set.',
    BankMustBeSet: 'Bank must be set.',
    BankAddressMustBeSet: 'Bank Address must be set.',
    ContextDtosIsNull: 'Context Dtos is null.',
    BankAccountStateFilterTypeIsNotDefined:
      'Bank account state is not defined.',
    BankAccountStateFilterIsNotValid: 'Bank account state is not valid.',
    BankAccountUidIsEmpty: 'Bank account Uid is empty.',
    BankAccountDoesNotExist: 'Bank Account does not exist.',
    SubmitterUidIsEmpty: 'Submitter Uid is empty.',
    NoteIsEmpty: 'Note is empty.',
    UserActionIsNotAllowed: 'User action is not allowed.',
    BankAccountACHStateIsNotValidForVerificationPaymentsSubmission:
      'Bank Account ACH state is not valid for verification payments submission.',
    InternalBankAccountUidIsEmpty: 'Internal bank account Uid is empty.',
    InternalBankAccountDoesNotExist: 'Internal bank account does not exist.',
    BankAccountDtoIsNull: 'Bank Account Dto is empty.',
    UidIsEmpty: 'Uid is empty.',
    SubmitterGuidIsEmpty: 'Submitter Guid is empty.',
    NicknameLengthIsNotValid: 'Nickname length is not valid.',
    AccountHolderFullNameIsNotValid: 'Account holder full name is not valid.',
    AccountHolderFullNameLengthIsNotValid:
      'Account holder full name is not valid.',
    AccountHolderFullName2IsNotValid: 'Account holder full name is not valid.',
    AccountHolderFullName2LengthIsNotValid:
      'Account holder full name is not valid.',
    AccountTypeIsUnknown: 'Account Type is unknown.',
    AccountNumberIsNotValid: 'Account Number is not valid.',
    AccountNumberLengthIsNotValid: 'Account Number length is not valid.',
    AccountTypeIsNotDefined: 'Account Type is not defined.',
    AccountHolderFullNameIsNullOrEmpty: 'Account holder full name is empty.',
    BankAccountStatusTypeIsUnknown: 'Bank Account status type is unknown.',
    BankAccountStatusTypeIsNotDefined:
      'Bank Account status type is not defined.',
    UidAlreadyExists: 'Uid already exists.',
    ExistingBankAccountIsPendingVerification:
      'Existing Bank Account is pending verification.',
    OfficialSourceGuidDoesNotExists: 'Official source Guid does not exist.',
    BankAccountIsNotPendingVerification:
      'Bank account is not pending verification.',
    BankAccountIsExpired: 'Bank account has expired.',
    BankDtoIsNull: 'Bank Dto is not valid.',
    NameLengthIsNotValid: 'Name length is not valid.',
    ABARoutingCodeIsNullOrEmptyAndCountryIsUSA:
      'ABA routing code is not valid.',
    ACHRoutingCodeIsNullOrEmptyAndCountryIsUSA:
      'ACH routing code is not valid.',
    IncomingWireRoutingCodeIsNullOrEmptyAndCountryIsUSA:
      'Incoming wire routing code is not valid.',
    SwiftCodeIsNullOrEmptyAndCountryIsNotUSA: 'Swift code is not valid.',
    SwiftCodeFormatIsNotValid: 'Swift code format is not valid.',
    NameIsNullOrEmpty: 'Name is not valid.',
    ABARoutingNumberLengthIsInvalid: 'ABA routing number is not valid.',
    ABARoutingNumberIsNotNumeric: 'ABA routing number is not valid.',
    OfficialSourceGuidIsEmpty: 'Official source Guid is not valid.',
    SortCodeIsNullOrEmptyAndCountryIsUSA: 'Sort code is not valid.',
    SortCodeLengthIsInvalid: 'Sort code is not valid.',
    SortCodeFormatIsNotValid: 'Sort code is not valid.',
    ABARoutingNumberIsNullOrEmpty: 'ABA routing number is not valid.',
    ABARoutingNumberFormatIsNotValid: 'ABA routing number is not valid.',
    NoBankName: 'Bank name is not valid.',
    NoBankAccountsToAdd: 'No bank accounts to add.',
    ABARoutingNumberIsNotSupported: 'ABA routing number is not supported.',
    ABARoutingNumberIsBlockedForWire: 'ABA routing number is blocked for wire.',
    ABARoutingNumberIsBlockedForACH:
      'There was an unexpected error connecting to this account.',
    BankAccountActivationDaysIs0: 'Bank account activation days is 0.',
    BankAccountUnknown: 'Bank account is unknown.',
    BankAccountFailed: 'Bank Account has failed.',
    BankAccountTypeCannotBeBoth: 'Bank account type can not be both.',
    SwiftCodeIsNotSupported: 'Swiift Code is not supported',
    BankAccountTypeNotSet: 'Bank account type is not set.',
    BankAccountPurposeTypeNotSet: 'Bank account purpose type is not set.',
    BankAccountAlreadyAdded: 'Bank account already added.',
    InvalidPlaidPublicToken: 'Public plaid token is invalid.',
    BankingPaymentNotFound: 'Payment is not found.',
    BankingPaymentStatusUpdateNotAllowed:
      'Payment status is not allowed to be updated.',
    BankAccountIsPendingVerification: 'Bank Account is pending verification.',
    InvalidMetalType: 'Invalid metal type.',
    MetalStreamProductConfigurationIsNotSet:
      'Legacy Builder production configuration is not set.',
    InvalidProductCode: 'Invalid product code.',
    BrokerAccountIsAipAdministrative: 'Broker account is Aip administrative.',
    BrokerIsNotInAipProgram:
      'Metal stream is not allowed for {{brokerName}} broker.',
    BrokerAccountIsAccumulationActive: 'Broker account is accumulation active.',
    InstructionsAmountIsNegative: 'Instructions amount is negative.',
    AchBankAccountIsRequired: 'ACH bank account is required.',
    UseClientBalanceAndAchBankAccountProvided:
      'Use client balance and ACH bank account are provided.',
    InstructionsPercentageIsInvalid: 'Instructions percentage is not valid.',
    InvalidProduct: 'Invalid product.',
    InstructionsPercentageSumIsInvalid:
      'Instructions percentage sum is not valid.',
    InvalidParameters: 'Invalid parameters.',
    DocumentNameIsRequired: 'Document name is required.',
    UnsupportedAccountDocumentType: 'Unsupported account document type.',
    FileForDownloadNotFound: 'File for download not found.',
    DownloadCenterContentsCategoryNotFound:
      'Download center contents category not found.',
    DownloadCenterDocumentNotFound: 'Download center document not found.',
    TradingNotOpen:
      'The trading desk is no longer open.  Please try again later.',
    OrderTypeNotAllowed: 'Order type not allowed.',
    AddressUidIsNull: 'Address Uid is not valid.',
    FinancialTransactionForDeliveryNotFound:
      'Financial transaction for delivery is not found.',
    Order_FinancialOrdersNotAllowed:
      'Financial Orders are not allowed for test accounts.',
    Order_IRAFeeWithdrawalTypeNotAllowe:
      'Fee Withdrawal Orders not allowed for IRA accounts.',
    Order_IRAFeeWithdrawalTypeNotAllowed:
      'Fee Withdrawal Orders not allowed for IRA accounts.',
    ForFractionalAipMustBeActive: 'For fractional products Aip must be active.',
    FractionalNotAllowedForDelivery:
      'Fractional products are not allowed for delivery.',
    BrokerAccountNotFound: 'Broker Account not found.',
    AuthorizedUsersNotFound: 'Authorized User not found.',
    AddressNotFound: 'Address not found.',
    PermissionsNotFound: 'Permission not found.',
    UsernameDoesNotExist: 'User with provided username or email does not exist',
    AccountIsPrevented: 'Account is locked. Please Contact Customer Support.',
    AccountIsRejectedOrExpired: 'Account is rejected or expired.',
    AccountNotYetApproved: 'Account has not yet been approved.',
    WrongPasswordAccountIsLocked: 'Wrong password provided, account is locked.',
    WrongCredentials: 'Invalid username or password.',
    LogonIsMissingIdentityEntityGuid: 'Login is missing identity entity Guid.',
    AccountIsInvited: 'Account is invited.',
    AccountIsLocked: 'Account is locked.',
    AccountIsInactive: 'Account is inactive.',
    UnknownAccountStatus: 'Unknown account status.',
    UpdateIsNotSupported: 'Update is not supported.',
    UserNotFound: 'Invalid username or password.',
    IncorrectAnswer: 'Incorrect answer.',
    LogonNotFound: 'Login not found.',
    TokenHasExpired: 'The provided reset token has expired.',
    ErrorSendingMail: 'Error sending mail.',
    RemoveAddressNotAllowed: 'Remove address is not allowed.',
    UserStatusChangeNotAllowed: 'User status change is not allowed.',
    EmailNullOrEmpty: 'Email is not valid.',
    BrokerSymbolNotFound: 'Broker symbol has not been found.',
    BrokerSecurityNotFound: 'Broker security has not been found.',
    SomethingWentWrong: 'Something went wrong',
    WizardValidation_UserNotFound:
      'An incorrect password has been entered for the selected username.  Please try again.',
    InvalidCredentials:
      'An incorrect password has been entered for the selected username.  Please try again.',
    TokenDoesNotMatch: 'The provided reset token has expired or is not valid.',
    WizardValidation_TokenDoesNotMatch:
      'The provided reset token has expired or is not valid.',
    InvalidRecaptchaToken: 'Invalid recaptcha token.',
    ClientIpAddressIsNullOrEmpty: 'Client ip address is invalid.',
    AccountDoesNotHavePositiveBalance:
      'Account does not have positive balance.',
    AccountBalanceForDeliveryBalanceTooLow:
      'Account Balance For Delivery Balance is too low.',
    AuthenticationMethodTypeNotImplemented:
      'Authentication Method Type is not implemented.',
    LogonTwoFactorAutenticationNotFound:
      'Two Factor Authentication user is not found.',
    LogonTwoFactorAuthenticationNotFound:
      'Two Factor Authentication user is not found.',
    WrongAuthenticationMethodType: 'Wrong authentication method type.',
    WrongSecurityCode: 'Wrong security code.',
    PhoneNumberNotFound: 'Wrong phone number.',
    GeneteratingSecretKeyFailed: 'Generating Secret Key is failed',
    GeneratingSecretKeyFailed: 'Generating Secret Key is failed',
    SecretKeyNotFound: 'Wrong secret key.',
    PhoneNumberNotSet: 'Phone number is not set.',
    TwoFactorAuthenticationNotActiveForBroker:
      'Two Factor Authentication is not active.',
    PhoneMessageTypeNotImplemented: 'Phone message type is not implemented',
    VoiceMessageNotSent: 'Voice message is not sent.',
    SMSMessageIsNotSent: 'SMS message is not sent.',
    CouldNotRetrieveVoiceMessageStatus: '',
    SecretKeyRequired: 'Secret Key is required.',
    CantDelete2FaPhone: 'Deleting this phone is disabled.',
    WrongPhoneNumber: 'Wrong phone number.',
    SecurityCodeNotFound: 'Wrong security code.',
    TwoFaCodeExpired: 'The code you entered is invalid or has expired.',
    TwoFaCodeCheck: ' Please check the code is correct or request a new one.',
    SecurityCodeRequired: 'Security Code is required.',
    BankAccountModelIsNull: 'Must add bank account.',
    BankAccountModel_AccountNumberIsNullOrEmpty: 'Bank account is invalid.',
    BankAccountModel_NameOnAccountIsNullOrEmpty:
      'Name on bank account is invalid.',
    BankAccountModel_BankAccountTypeIsUnknown: 'Bank account type is unknown.',
    BankAccountModel_BankAccountTypeIsNotDefined:
      'Bank account type is not defined.',
    BankAccountModel_BankAccountPurposeTypeIsUnknown:
      'Bank account purpose type is unknown.',
    BankAccountModel_BankAccountPurposeTypeIsNotDefined:
      'Bank account purpose type is not defined.',
    PlaidDetailsModelIsNull: 'Plaid details value is invalid',
    PlaidDetailsModel_PublicTokenIsNullOrEmpty: 'Plaid public token is invalid',
    PlaidDetailsModel_BankNameIsNullOrEmpty: 'Bank name is invalid',
    PlaidDetailsModel_BankAccountsIsNullOrEmpty: 'Bank account is invalid',
    BankAccountModel_BothBanksAreSet: 'Both banks are set.',
    BankAccountModel_BanksAreNullOrEmpty: 'Banks are invalid.',
    DomesticBankModelIsNull: 'Domestic bank is invalid.',
    DomesticBankModel_PhoneIsNullOrEmpty: 'Phone is invalid.',
    DomesticBankModel_NameIsNullOrEmpty: 'Bank name is invalid',
    DomesticBankModel_ACHRoutingNumberIsNullOrEmpty:
      'Ach Routing number is invalid',
    DomesticBankModel_WireRoutingNumberIsNullOrEmpty:
      'Wire Routing number is invalid',
    DomesticBankModel_ABARoutingNumberIsNullOrEmpty:
      'ABA routing number is invalid.',
    BankAccountModel_BankAccountUidIsNull: 'Bank Account Uid is invalid',
    BankAccountModel_BankAccountUidIsEmpty: 'Bank Account uid is invalid',
    AddressModel_AddressLineIsNullOrEmpty: 'Address line is invalid.',
    AddressModel_AddressLineIsInvalidLength: 'Address line has invalid length.',
    AddressModel_CityIsNullOrEmpty: 'City is invalid.',
    AddressModel_CityIsInvalidLength: 'City has invalid length.',
    AddressModel_CountryIso3CodeIsNullOrEmpty: 'Country Iso3 code is invalid.',
    AddressModel_CountryIso3CodeIsInvalidLength:
      'Country Iso3 code has invalid length.',
    AddressModel_PostalCodeIsNullOrEmpty: 'Postal code is invalid.',
    AddressModel_StateRegionIsNullOrEmpty: 'State Region is invalid',
    AddressModelIsNull: 'Address modal is null.',
    InternationalBankModelIsNull: 'International Bank is null.',
    InternationalBankModel_SwiftCodeIsNullOrEmpty: 'Swift code is invalid.',
    InternationalBankModel_NameIsNullOrEmpty: 'Bank name is invalid.',
    MetalStreamConfigurationModelIsNull:
      'Metal stream configuration is invalid.',
    MetalStreamConfigurationModel_BankAccountUidIsNull:
      'Bank account uid is invalid.',
    MetalStreamConfigurationModel_BankAccountUidIsEmpty:
      'Bank account uid is invalid.',
    MetalStreamConfigurationModel_ProductConfigurationsIsNotSet:
      'Product configuration is not set.',
    MetalStreamConfigurationModel_InstructionsPercentageSumIsInvalid:
      'Percentage sum is invalid.',
    MetalStreamProductConfigurationModel_IsNull: 'Configuration is invalid.',
    MetalStreamProductConfigurationModel_BrokerSymbolCodeIsNullOrEmpty:
      'Broker symbol code is invalid.',
    MetalStreamProductConfigurationModel_PercentageIsInvalid:
      'Percentage is invalid.',
    AuthorizedUserModelIsNull: 'Authorized user is invalid.',
    EmailIsNullOrEmpty: 'Email is invalid.',
    EmailIsNotValid: 'Email is not valid',
    EmailInvalidLength: 'Email length is not valid',
    AuthenticateModelIsNull: 'Authenticate model is invalid.',
    PasswordIsNullOrEmpty: 'Password is invalid.',
    UsernameIsNullOrEmpty: 'Username is invalid.',
    UsernameInvalidLength: 'Username has not valid length.',
    ClientIpAddressInvalidLength: 'Client ip address  has not valid length.',
    BrowserInfoInvalidLength: 'Browser info has invalid length.',
    UpdateUserActionTypeIsUnknown: 'User action type is unknown.',
    UpdateUserActionTypeIsNotDefined: 'User action type is not defined.',
    SecurityAnswerIsNullOrEmpty: 'Security answer is invalid.',
    SecurityAnswerInvalidLength: 'Security answer has invalid length.',
    SecurityQuestionTypeIsUnknown: 'Security question type is unknown.',
    SecurityQuestionTypeIsNotDefined: 'Security question type is not defined.',
    AuthenticatedUserIsNull: 'Authenticated user is invalid.',
    FirstNameIsNullOrEmpty: 'First name is invalid.',
    FirstNameIsInvalidLength: 'First name has invalid length.',
    LastNameIsNullOrEmpty: 'Last name is invalid.',
    LastNameIsInvalidLength: 'Last name has invalid length.',
    MiddleNameIsInvalidLength: 'Middle name has invalid length.',
    SuffixTypeIsUnknown: 'Suffix type is unknown.',
    SuffixTypeIsNotDefined: 'Suffix type is not defined.',
    OriginIsNotSet: 'Origin is not set.',
    HttpRequestMessageIsNull: 'Http request message is invalid.',
    IpAddressIsNotSet: 'Ip address is not set.',
    DeliveryNotAllowedForUTMAAccounts:
      'Delivery not allowed for UTMA accounts.',
    ActionNotAllowedForIRAAccounts: 'Action not allowed for IRA Accounts.',
    WireWithdrawalFeeCreditNegative: 'Wire Withdrawal Fee credit is negative.',
    WireWithdrawalFeeCreditGreatherThanWireWithdrawalFee:
      'Wire Withdrawal Fee credit is greater then Wire Withdrawal Fee.',
    AvailableForWithdrawalBalanceTooLow:
      'Available Withdrawal Balance is too low.',
    UsernameAlreadyExists:
      'This email address is already associated with an account. ',
    EncryptedPersonEntityIsNotValid: 'Invite URL is not valid.',
    RestrictBuy: 'Buy order is restricted.',
    BrokerAccountHolderNotFound: 'Broker Account Holder is not found',
    BrokerAccountCanNotDeleteCreditCard:
      'Broker Account can not delete credit card',
    BraintreeServiceNotAvailible:
      'This service is not available at this time. Please try again later.',
    BraintreeCreateTokenError:
      'This service is not available at this time. Please try again later.',
    BraintreeCreateCustomerError:
      'This service is not available at this time. Please try again later.',
    BraintreeGetCustomerError:
      'This service is not available at this time. Please try again later.',
    BraintreeSaveCustomerError:
      'This service is not available at this time. Please try again later.',
    BraintreePaymentNonceIsEmpty:
      'This service is not available at this time. Please try again later.',
    BraintreeServiceCreditCardTokenIsEmpty:
      'This service is not available at this time. Please try again later.',
    BraintreeServiceDeletePaymentMethodError:
      'This service is not available at this time. Please try again later.',
    BraintreeServiceBillingAddressCodeIsEmpty:
      'This service is not available at this time. Please try again later.',
    CreditCardNotFound: 'Credit card is not found',
    CreditCardIsNotActive: 'Credit card is not active',
    UnhandledException: 'Address is not verified.',
    AccountIsInEnhancedReviewRequired:
      'There is currently a problem with your account, please contact customer service or check your email for details.',
    TwoFactorAuthenticationLimitIsExceeded:
      'For your security, sign in has been temporarily blocked. Please try again later.',
    IssueWithYourOrder: 'There was an issue with your order.',
    BuyForDeliveryAmountMinimumIsNotReached:
      'The subtotal for metals must be at least {{orderMinimum}}.  Please increase your order amount.',
    NoQuoteAvailable:
      'Your order quote has expired. Prices have now refreshed, so please review and place your order again.',
    Order_ProductIsNotAvailableForSegregatedStorage:
      'This product is not eligible for segregated storage.  Please choose a different vault option.',
    BraintreeAuthorizationError: 'BraintreeAuthorizationError',
    BraintreeCaptureError: 'BraintreeCaptureError',
    SignifydInsuranceError: 'SignifydInsuranceError',
    CreditCardProcessingTimeout: 'CreditCardProcessingTimeout',
    OrderMaximumValidationError: 'Insufficient funds available.',
    BuyMinimumAmountIsNotReached:
      'The subtotal for metals must be at least {{orderMinimum}}. Please increase your order amount.',
    AvailableBalanceTooLow: 'Insufficient funds available.',
    OrderMinimumValidationError:
      'The subtotal for metals must be at least {{orderMinimum}}. Please increase your order amount.',
  },
  validationSchema: {
    addCreditCardValidation: {
      cardNumberIsNotValid: 'Card number is not valid.',
      cardNumberRequired: 'Card number is required.',
      nameOnCardIsRequired: 'Name on card is required.',
      expirationMonthAndYearIsRequired:
        'Expiration month and year is required.',
      expirationMonthAndYearIsNotValid:
        'Expiration month or year is not valid.',
      securityCodeIsRequired: 'Security code is required.',
      securityCodeIsNotValid: 'Security code is not valid.',
      fieldIsRequired: 'Field is required.',
      countryIsRequired: 'Country is required.',
      addressIsRequired: 'Street Address is required.',
      cityIsRequired: 'City is required.',
      stateRegionIsRequired: 'State Region is required.',
      PostalCodeIsRequired: 'Postal Code is required.',
    },
    creditCardPaymentValidation: {
      creditCardRequired: 'Please choose credit card first.',
    },
    verifyBankAccountValidationSchema: {
      amountDecimal: 'Amount must be a decimal number',
    },
    quantity: {
      quantityRequired: 'Quantity is required.',
      minDecimalNumber: 'Quantity must be greater or equal to 0.0001.',
      minRoundNumber: 'Quantity must be greater or equal to 1.',
      wholeNumber: "Quantity can't be decimal.",
      notValid: 'Quantity is not valid.',
    },
    amount: {
      minAmount: 'Minimum amount is: {{min}}.',
      maxAmount: 'Maximum allowed amount is: {{max}}',
    },
    cash: {
      cashRequired: 'Cash amount is required.',
    },
    bankAccount: {
      bankAccountNameRequired: 'Bank account name is required.',
      bankAccountRoutingNumberRequired:
        'Bank account routing number is required.',
      bankAccountNumberRequired: 'Bank account number is required.',
      bankAccountTypeRequired: 'Bank account type is required.',
      bankAccountRoutingNumberLength:
        'Bank account routing number must be exactly 9 digits',
      swiftCodeRequired: 'Bank account swift code is required.',
      bankStreetRequired: 'Bank street address is required.',
      bankCityRequired: 'Bank city is required.',
      bankStateRequired: 'Bank state is required.',
      bankPostalCodeRequirement:
        'Zip/Postal code may not exceed 10 alphanumeric characters. Spaces are not allowed.',
      bankPostalCodeRequired: 'Bank postal code is required.',
      bankAccountHolderName: 'Bank account Holder Name is required.',
      bankAccountNumberRepeat: 'Account numbers do not match.',
      bankAccountNumberRepeatRequired:
        'Repeat account number field is required.',
      obi1: 'Maximum length of the OBI1 is 70.',
      obi2: 'Maximum length of the OBI2 is 70.',
      obi3: 'Maximum length of the OBI3 is 70.',
      obi4: 'Maximum length of the OBI4 is 70.',
    },
    withdrawal: {
      wireWithdrawalFeeCreditRequired:
        'Wire withdrawal fee credit is required.',
      withdrawalMinAmount: 'You must withdraw at least {{amount}}.',
    },
    deposit: {
      minimumAmount: '{{amount}} per month minimum.',
      maximumAmount: '{{amount}} per month maximum.',
    },
    orders: {
      minimumFunds:
        'The amount entered is too low. The minimum amount is 1 unit, which equals to {{productPrice}}. Please enter a valid amount.',
      minimumCashFundsForLocation:
        'The amount entered does not meet the location minimum of {{amount}}. Please increase the amount to proceed.',
      minimumCashFundsForLocationDelivery:
        'The amount entered is below our delivery minimum of {{amount}} before taxes and fees. Please add more to your order to qualify for delivery.',
      minimumQuantityFundsForLocationDelivery:
        'The amount entered is below our delivery minimum of {{productPrice}} before taxes and fees.  Please add more to your order to qualify for delivery.  At least {{amount}} is required.',
      minimumQuantityFundsForLocation:
        'Orders for the {{location}} vault are currently subject to at least {{productPrice}} minimum per purchase. At least {{amount}} is required.',
      insufficientFunds:
        'The amount entered exceeds your current balance of {{balance}}. Please enter a lower amount or add funds to your account.',
      limitationExceeds:
        'The amount entered exceeds our maximum delivery limit of {{amount}}. Please reduce the amount to proceed.',
    },
    general: {
      usernameRequired: 'Username is required.',
      passwordRequired: 'Password is required.',
      amountRequired: 'Amount is required.',
      firstNameRequired: 'First Name is required.',
      lastNameRequired: 'Last Name is required.',
      phoneNumberRequired: 'Phone Number is required.',
      userNameRequired: 'Username is required.',
      passwordNoMatch: 'Passwords do not match.',
      securityQuestionRequired: 'Security Question is required.',
      answerRequired: 'Answer is required.',
      securityAnswerRequired: 'Security Answer is required.',
      securityAnswerNoMatch: 'Answers do not match.',
      passwordStrength:
        'Must be between 8 and 49 characters long. Must contain at least three characters out of the following, one lower case letter, one upper case letter, one digit and one non-alphanumeric character.',
      messageTypeRequired: 'Message type is required.',
      phoneNumberNotValidFormat: 'Phone number is not valid format.',
      phoneNumberNotValidLength: 'Phone number is not valid length.',
      phoneNumberIsNotValid: 'Phone number is not valid.',
      securityCodeRequired: 'Security Code is required.',
      insufficientUnits: 'Insufficient units available.',
      insufficientFunds: 'Insufficient funds available.',
      deliveryAddress: 'Delivery address is required.',
      countryIsRequired: 'Country is required.',
      emailRequired: 'Email is required.',
      emailOrUsernameRequired: 'Email or username is required.',
      addressRequired: 'Address is required.',
      invalidEmailFormat: 'Invalid email address format.',
      phoneNumberOnlyNumbers: 'Please enter only numbers.',
    },
  },
  sortBy: {
    popularity: 'Popularity',
    lowToHigh: 'Total Price Low to High',
    highToLow: 'Total Price High to Low',
  },
  balance: {
    balanceCardTitle: 'Cash Balance',
    balanceCardSubtitle: 'Available for Trading',
    balanceCardAmount: '{{amount}}',
    balanceCardAmountSub: 'Manage, Withdraw & More',
  },
  balances: {
    effectiveBalanceTitle: 'Effective Balance',
    prefunded: 'Pre-Funded Storage Fee Balance',
    effectiveBalanceDescription:
      'Your core account balance, it reflects all settled and pending activity and is the best picture of current funds.',
    availableForTradingTitle: 'Available for Trading',
    availableForTradingDescription:
      'Does not include pending financial activity, such as waiting for a check or ACH to clear. Pending trade proceeds are included.',
    availableForWithdrawalTitle: 'Available for Withdrawal',
    availableForWithdrawalDescription:
      'Does not include either pending trades or deposits. Funds are only available to withdraw from fully settled activity.',
    noRecentTransactions: 'No recent requests.',
    disabledBasicAccountOne:
      'Your balance cannot be withdrawn pending a security hold on the account; please verify your account to remove this hold or ',
    disableBasicAccountTwo: ' contact customer service ',
    disableBasicAccountThree: 'for assistance.',
  },
  storageFee: {
    storageFeeDueBannerTitle: 'Storage Fee Due',
    storageFeeDueBannerSubtext1:
      'There is a payment due for the amount of {{amount}}. Please make a payment as soon as possible by ',
    storageFeeDueBannerSubtext2: 'paying via card',
    storageFeeDueBannerSubtext3: ' or by ',
    storageFeeDueBannerSubtext4: 'making a deposit',
    storageFeeDueBannerSubtext5: ' to your balance.',
    storageFeeDueBannerButtonText: 'Pay by card',
    payStorageFeeByCardModal: {
      label: 'Pay storage fee by card',
      selectACardForPayment: 'Select a card for payment',
      creditCardTransactionLimit: '{{transactionLimit}} limit',
      amountToBeChargedToday: 'Amount to be charged today',
      allMajorCardTypesAccepted: 'All major card types accepted.',
      paymentSuccessfulTitle: 'Payment successful',
      paymentSuccessfulMessage:
        'Your account is now paid in full. Thank you for your business.',
      paymentFailureTitle: 'There was a problem with your payment',
      paymentFailureMessage:
        'We could not process your payment using this card.',
      cardAutoPayIsOn: 'Card Auto-pay is on ',
      usingCreditCardType: '(using {{cardType}} *{{last4Digits}})',
      cardAutoPayIsOff: 'Card auto-pay is off',
      changeSettings: 'Change settings',
      turnOnCardAutoPay: 'Turn on card auto-pay',
      turnOnCardAutoPayMessage:
        'When auto-pay is enabled, any storage fee balances are paid automatically using the selected card.',
      storageFeePayments: 'Storage Fee Payments',
    },
    changeAutoPaySettingsModal: {
      label: 'Change auto-pay settings',
      successTitle: 'Your auto-pay settings have been changed',
      successDescription1: 'Storage fees will be paid from your balance.',
      successDescription2:
        'Storage fees will be paid automatically using the selected credit card.',
    },
  },
  allFunds: {
    dontSee: 'Don’t see all your funds?',
    description:
      'The funds available to withdraw from your account may differ from your Effective Balance as they only include proceeds from fully settled activity. Pending trades, pending deposits, and any holds will affect the amount available to withdraw.',
    effectiveBalance: 'Effective Balance:',
    forTrading: 'Available for Trading:',
    forWithdrawal: 'Available for Withdrawal:',
    history: 'Balance History',
  },
  bankAccounts: {
    modalTitle: 'Add a Bank Account',
    addNew: 'Add a new {{accountType}} account',
    cardTitle: 'Accounts for {{accountType}}',
    listEmpty: "You haven't added any accounts for {{accountType}} yet.",
    pageDescription:
      'You can connect your {{brokerName}} account to one or more bank accounts to make deposits and withdrawals easier. You can add or remove connections at any time here. ',
    moreInfo:
      'Adding your bank account for withdrawal can take up to 48 hours for activation. More information may be requested such as voided checks, and bank statements for verification.',
    title: 'Bank Accounts',
    verifyBtn: 'Verify',
    depositBtn: 'Deposit',
    routingNumberLength: 'Must be exactly 9 digits',
    routingNumberWrong: 'Wrong Bank ABA/Routing Number.',
    pleaseReviewBankDetails: 'Please review and confirm your information.',
    bankCountryLabel: 'Bank Country:',
    abaRoutingNumberLabel: 'Bank ABA/Routing Number:',
    bankNameLabel: 'Bank Name:',
    swiftCodeLabel: 'SWIFT Code:',
    streetAddressLabel: 'Street Address:',
    cityLabel: 'City:',
    stateProvinceLabel: 'State/Province:',
    postalCodeLabel: 'Postal Code:',
    accountHolderLabel: 'Account Holder Name:',
    accountTypeLabel: 'Account Type:',
    accountNumberLabel: 'Account Number:',
    accountNumberIbanLabel: 'Account Number or IBAN:',
    repeatAccountNumberLabel: 'Repeat Account Number:',
    repeatAccountNumberIbanLabel: 'Repeat Account Number or IBAN:',
    newWireHeadingLabel: 'New account for wire withdrawal',
    newWireBankDetailsLabel: 'Bank Details:',
    newWireBankMailingDetailsLabel: 'Bank Mailing Address:',
    reachedLimit:
      'You can currently only connect {{number}} ACH accounts at this time. Please remove a connection before adding another.',
    newAccountConnected: 'Wire Connection Pending',
    newAccountConnectSuccess:
      'Almost there. Your bank is successfully verified. After a quick security review, it will be available for funding.',
    newWireAccountDetailsLabel: 'Bank Details',
    removeModal: {
      title: 'Remove a bank account',
      areYouSure: 'Are you sure you wish to remove this bank account?',
      wentWrong: 'Remove bank account could not be completed.',
      successful: 'Your bank account has been removed.',
    },
    errorModal: {
      title: 'Bank Account Verify Error',
      text: 'There is an issue communicating with this bank account.',
      link: 'Please contact customer support',
    },
    newAchAccount: {
      accountTypeLabel: 'Account type:',
      routingNumberLabel: 'Routing number:',
      accountNumberLabel: 'Account number:',
      nameOnAccountLabel: 'Name on account:',
      voidedCheckNote: 'SEND PHOTO OF VOIDED CHECK',
      success: 'You have successfully added a new bank account.',
    },
    dontSeeAccountModal: {
      title: "Don't see your bank account?",
      pendingDeposit: 'Pending ACH deposit bank accounts',
    },
    verify: {
      title: 'Verify a bank account',
      enterAmount:
        'Enter the amount of the two deposits as they appear on your bank statement',
      wontBeCharged: 'You will not be charged for these transactions.',
      btnLabel: 'Verify Deposit Amounts',
      amountOne: 'Amount 1:',
      amountTwo: 'Amount 2:',
      attemptLeft:
        "Amounts do not match deposits. {{num}} attempt left. If unsuccessful, you'll have to start the process over.",
      attemptsLeft: 'Amounts do not match deposits. {{num}} attempts left.',
      errorOccurred:
        'The verify process was not successful, please contact support if you are having trouble verifying.',
      success: 'You have successfully verified your bank account.',
    },
    errors: {
      ThereArePendingACHDepositRequests:
        'Remove bank account could not be completed. There are pending ACH Deposit Requests.',
    },
    wireInstructionsCopy:
      'Note: if you’re looking to make a deposit via bank wire, click',
    wireInstructionsLink: 'here for instructions.',
    wireWithdrawal: {
      obi1: 'OBI1:',
      obi2: 'OBI2:',
      obi3: 'OBI3:',
      obi4: 'OBI4:',
      note:
        'Note: For the OBI fields, enter any additional information or beneficiary instructions regarding this wire. Each of these fields holds up to 70 characters. These beneficiary fields are not required and may be left blank.',
    },
    documentReUploadRequested:
      'Document re-upload requested. Please see your email for details.',
    moreInfoIsRequired:
      'More information is required to verify ownership of this account.',
    verificationFailed:
      'We were not able to verify ownership of this bank account. Please delete the connection and try again or',
    contactCustomerSupport: 'Contact customer support',
    customerSupport: 'customer support',
    confirmMicroDepositsTitle: 'Confirm Micro-deposits',
    confirmMicroDepositsDescription:
      'Enter the value of two small deposits made to this account.',
    confirmVoidedCheckTitle: 'Upload a Voided Check',
    confirmVoidedCheckDescription:
      'A photo or color scan of a bank-issued check or alternatively your most recent bank account statement. Processing may take longer for bank statements.',
    toKeepAppSafeNote:
      'To help keep {{brokerName}} safe for all, we regularly screen connections to bank accounts for security.  The following connection(s) were selected for additional verification.  Before you can request delivery, please help confirm you are authorized on the connected account(s).  The process is easy and secure.',
    verificationStatus: {
      disabled: 'Disabled',
      incomplete: 'Incomplete',
      complete: 'Complete',
      pending: 'Pending',
      actionRequired: 'Action Required',
      pendingReview: 'Pending Review',
      verified: 'Verified',
      failed: 'Failed',
      notReady: 'Not Ready',
      preparing: 'Preparing a secure upload link',
      expiredVerification: 'Expired verification',
      processing: 'Processing new connection',
      failedVerification: 'Failed verification',
    },
  },
  creditCards: {
    creditCardTypes: {
      visa: 'Visa',
      masterCard: 'Mastercard',
      americanExpress: 'American Express',
      discover: 'Discover',
      jcb: 'JCB',
      dinersClub: 'Diners Club',
    },
    creditCardIntro:
      'You can store credit and debit cards on your {{brokerName}} account to make purchases easier. Any card added will be immediately activated for use and cards can be removed at any time on this page.',
    cardMask: '****{{cardMask}}',
    editActionTitle: 'Edit',
    confirmationScreenRemoveTitle: 'Remove a credit card',
    confirmationScreenRemoveMessage: 'Your card has been deleted successfully.',
    removeActionModalTitle: 'Delete Card',
    removeActionModalDescription: 'Are you sure you want to delete this card?',
    removeActionModalAutoPayNote:
      'This card is being used for storage fee auto-pay. By deleting this card, auto-pay will be turned off. Any storage fees will be deducted from balance until you activate auto-pay using a different card.',
    removeActionModalConfirmationTitle: 'Remove',
    emptyList: 'You haven’t added any cards yet.',
    addNewCreditCard: 'Add a New Credit Card',
    expires: 'Expires: {{expirationMonth}}/{{expirationYear}}',
    cardTypeAndNumberLabel: '{{cardType}} *{{last4Digits}}',
    expiredWithDate: 'Expired: {{expirationMonth}}/{{expirationYear}}',
    thisCardIsUsedForAutoPay:
      'This card is being used for storage fee auto-pay',
  },
  refresh: {
    title: 'Are you active?',
    cta:
      "You were registered as not active, please confirm that you are active in the next minute, if you don't you will be logged out.",
  },
  restriction: {
    warning:
      'Current user has limited permissions for these functions. Please contact owner to make changes.',
    nonApprovedAccount: {
      text: 'Account awaiting approval',
      subtext:
        'Welcome to {{brokerName}}. You will have limited access until your account has been approved. Please feel free to explore.',
    },
    verifyBankAccount: {
      warning: 'Verify your bank account',
      description:
        'Your new bank account connection was flagged for additional review. Please upload a photograph of a voided check to prove you are the account owner.',
      cta: 'Manage bank connections',
      documentReUpload:
        'Document re-upload requested. Please see your email for details',
    },
  },
  common: {
    close: 'Close',
    trademark: 'TM',
    search: 'Search',
    error: 'Error',
    continue: 'Continue',
    labelUsername: 'Username:',
    labelPassword: 'Password:',
    next: 'Next',
    back: 'Back',
    goBack: 'Go Back',
    ok: 'Ok',
    getStarted: 'Get Started',
    done: 'Done',
    confirm: 'Confirm',
    printDownload: 'Print/Download',
    cancel: 'Cancel',
    remove: 'Remove',
    invite: 'Invite',
    save: 'Save',
    cash: 'Cash',
    complete: 'Complete',
    quantity: 'Quantity',
    download: 'Download',
    moneyCurrencyLeft: '{{money}}',
    moneyCurrencyRight: '{{money}}{{currency}}',
    deposit: 'Deposit',
    withdraw: 'Withdraw',
    start: 'Start',
    gold: 'Gold',
    silver: 'Silver',
    agree: 'I Agree',
    delete: 'Delete',
    verify: 'Verify',
    submit: 'Submit',
    yes: 'Yes',
    no: 'No',
    clearFilters: 'Clear all filters',
    makeDefault: 'Make Default',
    defaultAddress: 'Default Address',
    noResults: 'There are no results.',
    skipForNow: 'Skip for now',
    to: 'to',
    select: 'Select...',
    none: 'None',
    all: 'All',
    fix: 'Fix',
    date: {
      range: '{{start}} to {{end}}',
    },
    contact: 'Contact',
    dismiss: 'Dismiss',
    sendRequest: 'Send request',
    secondsToRefresh: 'seconds until prices may refresh',
    secondToRefresh: 'second until price may refresh',
  },
  header: {
    linkAddresses: 'My Addresses',
    linkBankAccounts: 'Bank Accounts',
    linkBuy: 'Buy',
    linkMetalStream: '{{metalStream}}',
    linkSell: 'Sell',
    linkBalances: 'Balances / Activity',
    linkStatements: 'Statements',
    linkLegacyOrderistory: 'Order History',
    linkUsers: 'Users',
    navDropdownTrading: 'Trading',
    navDropdownAccount: 'Account',
    portfolioLink: 'Portfolio',
    fundBtn: 'Fund',
    supportBtn: 'Support',
    profileSettings: 'Profile Settings',
    statements: 'Statements, Invoices and 1099s',
    accessManagement: 'Account Access Management',
    accessLegacyOrderHistory: 'Legacy Order History',
    financialBalances: 'Financial Balances',
    addresses: 'Addresses',
    linkCreditCards: 'Credit/Debit Cards',
    registration: 'Registration',
    createUser: 'Create user',
  },
  portfolio: {
    title: 'Portfolio',
    valuation: 'Portfolio Valuation',
    cashBalace: 'Excludes cash balance;',
    updated: 'Last updated:',
    couldNotLoad: 'The chart data could not be loaded.',
    totalValuationByProduct: 'Total valuation by product',
    totalValuationByVault: 'Total valuation by vault',
    totalValuationByType: 'Total valuation by type',
    metalTypes: {
      Gold: 'Gold Price',
      Silver: 'Silver Price',
      Platinum: 'Platinum Price',
      Palladium: 'Palladium Price',
      Diamond: 'Diamond',
      'Portfolio Valuation': 'Portfolio Valuation',
    },
    holdings: {
      totalValuation: 'Total Valuation',
      tableEmptyOne: 'Your holdings portfolio is empty.',
      tableEmptyTwo: 'View all metals you could add to your holdings.',
      valuationLabel: 'Valuation',
      totlaOuncesLabel: 'Total ounces',
      quantityLabel: 'Quantity',
      viewDetails: 'View Details',
      myHoldingsOnlyLabel: 'My Holdings Only',
      sellBtnLabel: 'Sell',
      buyBtnLabel: 'Buy',
    },
    mailIn: {
      label: 'Have metals at home you wish to store?',
      cta: 'Start a mail-in storage request',
      link: 'Learn more',
    },
    pendingTransactions: {
      tableHeadings: {
        yourTransactions: 'Your Transaction Requests',
        amount: 'Amount',
        status: 'Status',
      },
      modal: {
        title: 'Cancel Pending Transaction',
        text: 'Are you sure you want to cancel the Pending Transaction?',
      },
      status: {
        success: 'The Pending Transaction has been canceled.',
        error: 'The Pending Transaction could not been canceled.',
      },
    },
    transactions: {
      common: {
        currency: 'Currency',
      },
      title: 'Transaction Details',
      tableEmpty: 'There is no transaction history.',
      portfolioTypes: {
        BuyforStorage: 'Buy for storage {{amount}}',
        BuyforDeliveryOrder: 'Buy for delivery {{amount}}',
        BuyforDeliveryDelivery: 'Delivery for Buy {{amount}}',
        SellfromStorage: 'Sell from Storage {{amount}}',
        DeliveryfromStorage: 'Delivery from Storage {{amount}}',
        PositionIn: 'Position In for {{amount}}',
        PositionOut: 'Position Out for {{amount}}',
        StorageFee: 'Storage Fee - {{amount}}',
        CheckDeposit: 'Check Deposit for {{amount}}',
        WireDeposit: 'WIRE Deposit for {{amount}}',
        AchDeposit: 'ACH Deposit for {{amount}}',
        AchDepositRejection: 'ACH Deposit Rejection for {{amount}}',
        CheckDepositRejection: 'Check Deposit Rejection for {{amount}}',
        WireWithdrawalRejection: 'Wire Withdrawal Rejection for {{amount}}',
        WireWithdrawalFeeRejection:
          'Wire Withdrawal  Fee Rejection for {{amount}}',
        GenericDeposit: 'Generic Deposit for {{amount}}',
        IraDeposit: 'IRA Deposit for {{amount}}',
        IraFeeDeposit: 'IRA Fee Deposit for {{amount}}',
        CheckWithdrawal: 'Check Withdrawal for {{amount}}',
        WireWithdrawal: 'Wire Withdrawal for {{amount}}',
        WireWithdrawalFee: 'Wire Withdrawal Fee - {{amount}}',
        GenericWithdrawal: 'Generic Withdrawal - {{amount}}',
        IraTransfer: 'IRA Transfer for {{amount}}',
        IraDistribution: 'IRA Distribution for {{amount}}',
        IraFeeWithdrawal: 'IRA Fee Withdrawal for {{amount}}',
        AipBuy: 'AIP Buy for {{amount}}',
        AipSell: 'AIP Sell for {{amount}}',
        AipAllocation: 'AIP Allocation for {{amount}}',
        InKindTransferOut: 'In Kind Transfer Out for {{amount}}',
        InKindTransferIn: 'In Kind Transfer In for {{amount}}',
        IraFeeDepositRejection: 'IRA Fee Deposit Rejection',
        StorageFeePayment: 'Storage Fee Payment',
      },
      portfolioTypesNonAmount: {
        BuyforStorage: 'Buy for storage',
        BuyforDeliveryOrder: 'Buy for delivery',
        BuyforDeliveryDelivery: 'Delivery for Buy',
        SellfromStorage: 'Sell from Storage',
        DeliveryfromStorage: 'Delivery from Storage',
        PositionIn: 'Position In',
        PositionOut: 'Position Out',
        StorageFee: 'Storage Fee',
        CheckDeposit: 'Check Deposit',
        WireDeposit: 'WIRE Deposit',
        AchDeposit: 'ACH Deposit',
        AchDepositRejection: 'ACH Deposit Rejection',
        CheckDepositRejection: 'Check Deposit Rejection',
        WireWithdrawalRejection: 'Wire Withdrawal Rejection',
        WireWithdrawalFeeRejection: 'Wire Withdrawal Fee Rejection',
        GenericDeposit: 'Generic Deposit',
        IraDeposit: 'IRA Deposit',
        IraFeeDeposit: 'IRA Fee Deposit',
        CheckWithdrawal: 'Check Withdrawal',
        WireWithdrawal: 'Wire Withdrawal',
        WireWithdrawalFee: 'Wire Withdrawal Fee',
        GenericWithdrawal: 'Generic Withdrawal',
        IraTransfer: 'IRA Transfer',
        IraDistribution: 'IRA Distribution',
        IraFeeWithdrawal: 'IRA Fee Withdrawal',
        AipBuy: 'AIP Buy',
        AipSell: 'AIP Sell',
        AipAllocation: 'AIP Allocation',
        InKindTransferOut: 'In Kind Transfer Out',
        InKindTransferIn: 'In Kind Transfer In',
        IraFeeDepositRejection: 'IRA Fee Deposit Rejection',
        StorageFeePayment: 'Storage Fee Payment',
      },
      details: {
        currency: '{{type}} for {{amount}}',
        amount: '{{type}} for {{amount}} unit',
        amount_plural: '{{type}} for {{amount}} units',
        decimal: '{{count}} unit',
        decimal_plural: '{{count}} units',
        singleUnit: 'unit',
        pluralUnit: 'units',
      },
      modalTypes: {
        BuyforStorage: 'Buy for Storage',
        BuyforDeliveryOrder: 'Buy for Delivery Order',
        BuyforDeliveryDelivery: 'Delivery for Buy',
        SellfromStorage: 'Sell from Storage',
        DeliveryfromStorage: 'Delivery from Storage',
        PositionIn: 'Position In',
        PositionOut: 'Position Out',
        StorageFee: 'Storage Fee',
        CheckDeposit: 'Check Deposit',
        WireDeposit: 'Wire Deposit',
        AchDeposit: 'Ach Deposit',
        AchDepositRejection: 'Ach Deposit Rejection',
        CheckDepositRejection: 'Check Deposit Rejection',
        WireWithdrawalRejection: 'Wire Withdrawal Rejection',
        WireWithdrawalFeeRejection: 'Wire Withdrawal  Fee Rejection',
        GenericDeposit: 'Generic Deposit',
        IraDeposit: 'Ira Deposit',
        IraFeeDeposit: 'Ira Fee Deposit',
        CheckWithdrawal: 'Check Withdrawal',
        WireWithdrawal: 'Wire Withdrawal',
        WireWithdrawalFee: 'Wire Withdrawal Fee',
        GenericWithdrawal: 'Generic Withdrawal',
        IraTransfer: 'Ira Transfer',
        IraDistribution: 'Ira Distribution',
        IraFeeWithdrawal: 'Ira Fee Withdrawal',
        AipBuy: 'Aip Buy',
        AipSell: 'Aip Sell',
        AipAllocation: 'Aip Allocation',
        InKindTransferOut: 'In Kind Transfer Out',
        InKindTransferIn: 'In Kind Transfer In',
        IraFeeDepositRejection: 'IRA Fee Deposit Rejection',
      },
      captionTypes: {
        BankAccountNickname: 'Bank Account Nickname',
        ACHTraceNumber: 'ACH Trace Number',
        Address: 'Address',
        Amount: 'Amount',
        BalanceDue: 'Balance Due',
        BankName: 'Bank Name',
        CheckNumber: 'Check Number',
        ClearingDate: 'Clearing Date',
        DeliveryFee: 'Delivery Fee',
        DueDate: 'Due Date',
        FederalReferenceNumber: 'Federal Reference Number',
        Icon: 'Icon',
        InvoicePeriod: 'Invoice Period',
        Message: 'Message',
        Notes: 'Notes',
        OrderTotal: 'Order Total',
        PricePerOz: 'Price Per Oz',
        PricePerUnit: 'Price Per Unit',
        Product: 'Product',
        Quantity: 'Quantity',
        QuantityExecuted: 'Quantity Executed',
        ReceivedOn: 'Received On',
        ReferenceNumber: 'Reference Number',
        RelatedReferenceNumber: 'Related Reference Number',
        RoutingNumber: 'Routing Number',
        SalesTax: 'Sales Tax',
        Status: 'Status',
        Submitter: 'Submitter',
        Summary: 'Summary',
        TrackingNumbers: 'Tracking Numbers',
        TransactedOn: 'Transacted On',
        Valuation: 'Valuation',
        SettlementDate: 'Settlement Date',
        AvailableOn: 'Available On',
        CreditCardLast4: 'Credit Card',
        TransactionID: 'Transaction ID',
      },
      formatTransactionTypes: {
        Address: 'Address: {{address}}',
        Product: '{{product}}',
        DateTime: 'Date Time: {{dateTime}}',
        TransactionStatus: 'Status: {{transactionStatus}}',
        TransactionSummary: '{{transactionType}} {{amount}}',
        TransactionType: '{{transactionType}}',
      },
      tableHeadings: {
        yourTransactions: 'Your Transactions',
        quantity: 'Quantity',
        status: 'Status',
        amount: 'Amount',
        effectiveBalance: 'Effective Balance',
      },
    },
    marketPrices: {
      error:
        'Sorry, we are having trouble accessing pricing data at this time. Please come back later.',
    },
  },
  product: {
    buyBtn: 'Buy',
    buyMoreBtn: 'Buy More',
    sellBtn: 'Sell',
    convert: 'Convert',
    yourTransactions: 'Your Transactions',
    deliverBtn: 'Deliver',
    detailedPricingBtn: 'DETAILED PRICING',
    price: 'As low as',
    locationsError: 'An error has occurred: {{error}}',
    priceTiersBtn: 'Price Tiers',
    note:
      'Note: Fractional products are denoted in ounces. Each ounce equals one unit.',
    settlementNote:
      'Withdrawal and delivery of cash and holdings is available after settlement unless otherwise indicated.',
    valuationNote:
      'Note: \n Valuations are based on the current sell price of this product.',
    segregatedNote: '(Segregated)',
    table: {
      vaultLabel: 'Vault Location',
      deliveryLabel: 'Delivery',
      pricePerUnitLabel: 'Price Per Unit',
      pricePerUnitStarLabel: 'Price Per Unit*',
      pricePerOzLabel: 'Price Per Oz',
      dollarAmountLabel: 'Dollar Amount',
    },
    chooseLocation: 'Choose a Location',
    priceEstimationNote:
      '*Note: Prices are estimated until executed with our dealer network.',
    priceTiers: 'Price Tiers',
    fractionalConversion: {
      title: 'Fractional Conversion',
      unitsAvailableForConversion: 'Units available for conversion:',
      unitsToConvert: 'Units to convert:',
      reviewConversion: 'Please review and confirm the conversion.',
      quantityToConvert: 'Quantity to convert:',
      conversionInfo:
        'This is one way conversion. Your fractional metals will be used for conversion and this process cannot be undone.',
      responseSuccess: 'Conversion has been completed.',
      responseError: 'Conversion could not be completed.',
    },
    fractionalDelivery: {
      title: 'Request Delivery',
      subtitle: 'Request Delivery of Fractional Holdings',
      description:
        "In order to deliver fractional allocated metals you must first select a form in which to take delivery from a set of common products. We'll then calculate the cost for small exchange fee and delivery to your preferred location. For now, to start this process please contact customer service:",
      emailUs: 'Email Customer Support',
      callUs: 'Or call us {{days}} excluding holidays.',
      tollFree: 'Toll free:',
      localTime: 'Local (Arizona) and from outside the US:',
      fractionalGoldOptions: 'Fractional Gold Delivery Options',
      fractionalSilverOptions: 'Fractional Silver Delivery Options',
    },
    requestDelivery: {
      convertTo: 'Convert to:',
      title: 'Request Delivery',
      unitsAvailableDelivery: 'Units available for delivery:',
      fundsAvailableDelivery: 'Funds available for Delivery Fees:',
      unitsInsufficient: 'Insufficient units available for delivery',
      fundsInsufficient: 'Insufficient funds available for delivery',
      orderPlaced: 'Your delivery order has been placed',
      orderPlacedInstavault:
        'Your request has been sent. We will contact you within 48 hours with a conversion, delivery cost and taxes if applicable.',
      orderNotPlaced: 'Your delivery order could not be placed.',
      noAddedAddresses: 'No Added addresses.',
      deliverTo: 'Deliver to:',
      deliverCheckTo: 'Deliver Check to:',
      dontSeeAddress: "Don't see your address?",
      quantityLabel: 'Quantity:',
      deliveryCost: 'Delivery Cost:',
      salesTax: 'Sales Tax:',
      accStorageFees: 'Accumulated Storage Fees:',
      orderTotal: 'Order Total:',
      reviewInfo: 'Please review and confirm to place your order.',
      deliveryAddress: 'Delivery Address:',
      unitsToDeliver: 'Units to Deliver:',
      convertFractionalTo: 'Convert fractional to',
      fractionalNoteTitle: 'Request a quote',
      fractionalNoteParagaph1:
        'Shortly after you place your order, our team will give you a quote after we calculate the cost of conversion and any delivery fees.',
      fractionalNoteParagaph2:
        'Once a quote is given, it is valid for 48 hours. ',
      fractionalNoteParagaph3: 'You will not be charged',
      fractionalNoteParagaph4:
        ' until you accept the quote within that 48 hour window.',
      fractionalNoteParagaph5: 'Please ',
      fractionalNoteParagaph6: 'contact customer service ',
      fractionalNoteParagaph7: 'if you have any questions.',
      remainingBalance: 'Remaining Balance:',
      locationValidation1:
        'These order instructions currently require a quote request due to the high variability of international shipping costs. Please ',
      locationValidation2: 'contact customer service',
      locationValidation3: ' to start your delivery quote request.',
      information: {
        salesTax: {
          title: 'Why is there sales tax on my delivery?',
          charge:
            'Some U.S. states charge sales tax on home delivery of bullion purchases.',
          chargeTax:
            'While your metals are stored in our vault, they are exempt from sales taxes. However, if you take delivery of those metals in a state/locality that charges this tax, we are required to collect it at that time based on the current value.',
          noChargeTax:
            'There is no sales tax if you sell your metals out of vault storage.',
        },
        storageFees: {
          title: 'What are accumulated storage fees?',
          storageFees:
            'These are storage fees owed for keeping your metals safely in the vault since your last bill. Before taking home delivery of your metals, you will be asked to settle this amount.',
          charge:
            'These are storage fees owed for keeping your metals safely in the vault since your last bill. Before taking home delivery of your metals, you will be asked to settle this amount.',
        },
        contactCustomerService:
          'If you have any questions, please contact us at',
        contactCustomerSupport: 'Contact customer support',
      },
      review: {
        title: 'Please review and confirm to place your order.',
      },
      agreement: {
        title: 'Ira Distribution Disclosure',
        agree: 'I authorize {{brokerCode}} to make this delivery',
        type: 'IRA Distribution Disclosure',
        iraDistributionDisclosure: {
          title: 'Physical delivery distribution disclosure',
          textOne:
            'I certify that I am the proper party to receive physical delivery from this IRA, and that all information provided by me is true and accurate. I acknowledge that I have read the Notice of Withholding below and have elected not to have federal income taxes withheld. I further certify that no tax advice has been given to me by the Administrator and/or Custodian that distributions are reported to the IRS, and that all decisions regarding this withdrawal are my own. I expressly assume the responsibility for any adverse consequences which may arise from this withdrawal and I agree that the Administrator or Custodian shall in no way be responsible for those consequences.',
          textTwo:
            'I elect not to have federal income tax withheld from my in-kind delivery of the IRA owned metal.',
          textThree:
            'The distributions you receive from your individual retirement account established at this institution are subject to federal income tax withholding unless you elect not to have withholding apply. You may elect not to have withholding apply to your distribution payments by completing the “Withholding Election” section below. If you do not complete the “Withholding Election” section by the date your distribution is scheduled to begin, federal income tax will be withheld from the amount withdrawn at a rate of 10%. If you elect not to have withholding apply to your distribution payments, or if you do not have enough federal income tax withheld from your distribution, you may be responsible for payment of estimated tax. You may incur penalties under the estimated tax rules if your withholding and estimated tax payments are not sufficient.',
          textFour:
            'I declare that I have examined this information, and to the best of my knowledge and belief, it is true, correct, and complete.',
        },
      },
    },
    detailsTable: {
      location: 'Location:',
      quantity: 'Quantity:',
      unitsAvailableSale: 'Units available for sale:',
      unitsAvailableConversion: 'Units available for conversion:',
      unitsAvailableDelivery: 'Units available for delivery:',
      estimatedPrice: 'Est. Price Per Unit*:',
      pricePerUnit: 'Price Per Unit:',
      valuation: 'Valuation:',
    },
    brandsTable: {
      brandCode: 'Brand code',
      serialNumber: 'Serial Number',
      fineWeight: 'Fine Weight',
    },
    chooseAnAmount: 'Choose an amount',
    reviewYourOrder: 'Review your order',
  },
  login: {
    title: 'Log In',
    subtitle: 'Please choose your username and type in your password:',
    welcome: 'Welcome to {{brokerName}}',
    dontHaveAccount: "Don't have an account? ",
    startQuickRegistration: 'Do you want to register?',
    quickRegistrationSignUp: 'Start',
    passwordStrength: 'Your password is {{strength}}.',
    signUpRecommendation: 'Sign up',
    email: 'Please enter your email address or username to log in:',
    logInTitle: 'Log In',
    signUp: 'Sign Up',
    forgotYourPassword: 'Forgot your password?',
    identityPage: {
      title: 'Confirm Your Identity',
      description: `We apologize for the inconvenience but we don't recognize the computer you're using. In order to prevent an unauthorized person from accessing your accounts we need to verify your identity before you log in.`,
      dontRecognizeComputer: "Why don't you recognise my computer?",
      whatToDo: 'What do I need to do?',
      newDevice: `You might be using a new device, new browser, have deleted your cookies or adjusted privacy settings`,
      providerChangedSettings:
        'Your internet provider may have changed settings.',
      needToAnswerSecurityQuestion:
        'All you need to do is answer your security question.',
      willBeNotified: `You will be notified by email any time your computer is not recognized and are required to answer your security question. If you have any difficulty, please contact Customer Support and provide the web browser you are using, the version, and any details regarding your issue.`,
      answerSecurityQuestion: 'Answer the security question',
    },
    securityQuestion: {
      pleaseAnswer:
        'Please answer the security question to gain access to your account:',
      rememberMe: 'Remember this device and skip future prompts.',
      gainAccess: 'Gain Access',
    },
    securityQuestionTypes: {
      0: 'Something went wrong, please contact support.',
      WhatWasYourChildhoodNickname: 'What was your childhood nickname?',
      InWhatCityDidYouMeetYourSpouseSignificantOther:
        'In what city did you meet your spouse or significant other?',
      WhatIsTheNameOfYourFavoriteChildhoodFriend:
        'What is the name of your favorite childhood friend?',
      WhatStreetDidYouLiveOnInThirdGrade:
        'What street did you live on in third grade?',
      WhatSchoolDidYouAttendForSixthGrade:
        'What school did you attend for sixth grade?',
      WhatWasYourChildhoodPhoneNumberIncludingAreaCode:
        'What was your childhood phone number including area code?',
      WhatWasTheNameOfYourFirstStuffedAnimal:
        'What was the name of your first stuffed animal?',
      InWhatCityOrTownDidYourMotherAnd:
        'In what city or town did your mother and father meet?',
      WhereWereYouWhenYouHadYourFirstKiss:
        'Where were you when you had your first kiss?',
      WhatIsTheFirstNameOfTheBoyOr:
        'What is the first name of the boy or girl you kissed?',
      WhatWasTheLastNameOfYourThirdGrade:
        'What was the last name of your third grade teacher?',
      InWhatCityDoesYourNearestSiblingLive:
        'In what city does your nearest sibling live?',
      WhatIsYourMaternalGrandmothersMaidenName:
        "What is your maternal grandmother's maiden name?",
      InWhatCityOrTownWasYourFirstJob:
        'In what city or town was your first job?',
      WhatIsTheNameOfACollegeYouApplied:
        'What is the name of a college you applied?',
      WhatWasTheNameOfYourElementaryPrimary:
        'What was the name of your elementary / primary school?',
      WhatIsTheNameOfTheCompanyOfYour:
        'What is the name of the company of your first job?',
      WhatWasYourFavoritePlaceToVisitAsA:
        'What was your favorite place to visit as a child?',
      WhatIsTheCountryOfYourUltimateDreamVacation:
        'What is the country of your ultimate dream vacation?',
      WhatIsTheNameOfYourFavoriteChildhoodTeacher:
        'What is the name of your favorite childhood teacher?',
      WhatWasYourDreamJobAsAChild: 'What was your dream job as a child?',
      WhoWasYourChildhoodHero: 'Who was your childhood hero?',
      WhatWasTheFirstConcertYouAttended:
        'What was the first concert you attended?',
      WhatAreTheLast5DigitsOfYourCredit:
        'What are the last 5 digits of your credit card?',
      WhatIsYourGrandmothersNickname: 'What is your grandmothers nickname?',
      WhatIsTheLastNameOfYourFavoriteHigh:
        'What is the last name of your favorite high school teacher?',
      WhatWasTheMakeAndModelOfYourFirst:
        'What was the make and model of your first car?',
      WhatIsTheNameOfYourGrandmothersPet:
        "What is the name of your grandmother's pet?",
      WhatWasTheNameOfYourFirstPet: 'What was the name of your first pet?',
    },
    useDifferentEmail: 'Use different email address or username',
    fields: {
      emailOrUsername: 'Email address or username:',
    },
  },
  sellWizard: {
    sellWizardTitle: 'Sell From Storage',
    reviewSale: 'Please review and confirm your sale.',
    estimatedPricePerUnit: 'Est. Price Per Unit*:',
    pricePerUnit: 'Price Per Unit:',
    quantityToSell: 'Quantity to Sell:',
    estimatedMetalsTotal: 'Est. Total*:',
    metalsTotal: 'Total:',
    note: '*Note: Prices are estimated until executed with our dealer network.',
    availableForSale: 'Units available for sale:',
    acknowledgeFinality: 'I acknowledge that all sales are final.',
    orderPlaced: 'Your sell order has been placed.',
    orderCouldNotBePlaced: 'Your sale order could not be placed.',
    thinMarketWarning:
      'Given the recent launch of Diamond Standard, the demand and liquidity for the diamond commodities are uncertain. There may be times where an order will not be executed, or pricing would need to be significantly adjusted to execute. Any order that is not executed by the end of a trading day will be cancelled.',
    volumeCredit: 'Volume Credit:',
  },
  order: {
    cashOrderFor: 'Cash order for',
    quantityOrderFor: 'Quantity order for',
    estimated: 'Est. Price Per Unit*:',
    estQuantity: 'Est. Quantity:',
    estimatedTotal: 'Est. Total*:',
    pricePerUnit: 'Price Per Unit*:',
    quantity: 'Quantity:',
    buffer: 'Cash Buffer (1.5%):',
    requiredBalance: 'Required Balance with Buffer:',
  },
  calculation: {
    estimatedOrderTotal: 'Est. Order Total*:',
    buffer: 'Cash Buffer (1.5%):',
    requiredBalance: 'Required Balance:',
  },
  password: {
    weak: 'weak',
    average: 'average',
    good: 'good',
    strong: 'strong',
  },
  statements: {
    statements: 'Account Statements',
    Invoices: 'Storage Invoices',
    taxes: 'Tax Documents',
    noTypeSelected: 'No {{type}} for the selected period.',
    noTaxForms: 'Tax forms for {{year}} are not yet available',
    note:
      'If you are having trouble downloading statements, you need to allow pop-ups in your browser. If the problem persists please contact support.',
    popUp: {
      title: 'Pop Up Instructions',
      generalNote:
        'If you are having trouble opening the download links, please enable pop-ups in your browser. If you are still having trouble downloading, contact support.',
      chrome: {
        list: {
          1: '1. Open Chrome, then go to the web page that you want to allow pop-ups.',
          2: "2. In Chrome's URL window, highlight the entire web address of the current page, then choose the Copy command from the Edit menu or right-click and choose the Copy command.",
          3: '3. Click the icon with three horizontal bars in the upper right side of the browser toolbar.',
          4: '4. Click Settings.',
          5: '5. Scroll to the bottom of the Settings section and click Show advanced settings...',
          6: '6. In the Privacy section, click Content settings... The Content settings window appears.',
          7: '7. Scroll to the Pop-ups section, and then ensure the Do not allow any site to show pop-ups radio button is selected.',
          8: '8. Click Manage exceptions...',
          9: '9. In the Hostname pattern field, choose the Paste command from the Edit menu or right-click and choose the Paste command, and ensure the Behavior dropdown menu is set to Allow.',
        },
        note:
          'Notes: Pasting the web address of a page you wish to allow pop-ups is recommended, rather than typing the generic main web address. For example, you might visit www.example.com/requirepopup.html, but the page is actually hosted at popup.example.com/forms/popupform.html. In this example, you must allow popup.example.com, not www.example.com. Note also that entire websites must be allowed, not individual directories or pages, so in this example Chrome will automatically add the top level popup.example.com to its Allowed sites.',
      },
      firefox: {
        list: {
          1: '1. Open Firefox, then go to the web page that you want to allow pop-ups.',
          2: "2. In Firefox's URL window, highlight the entire web address of the current page, then choose the Copy command from the Edit menu or right-click and choose the Copy command.",
          3: ' 3. Click the icon with three horizontal bars in the upper right side of the browser toolbar.',
          4: '4. Select Options (Windows) or Preferences (macOS).',
          5: '5. In the left sidebar, click on the Content icon to display the Content section.',
          6: '6. In the Pop-ups section, ensure the Block pop-up windows checkbox is selected, then click the adjacent Exceptions... button.',
          7: '7. In the Address of web site: field, choose the Paste command from the Edit menu or right-click and choose the Paste command, and then click Allow.',
          8: '8. Click Save Changes.',
          9: '9. Close any remaining dialog boxes.',
        },
        note:
          'Notes: Pasting the web address of a page you wish to allow pop-ups is recommended, rather than typing the generic main web address. For example, you might visit www.example.com/requirepopup.html, but the page is actually hosted at popup.example.com/forms/popupform.html. In this example, you must allow popup.example.com, not www.example.com. Note also that entire websites must be allowed, not individual directories or pages, so in this example Firefox will automatically add the top level popup.example.com to its Allowed sites.',
      },
      edge: {
        list: {
          1: '1. Click the ellipsis icon (...) in the upper right corner of your web browser, and then click Settings.',
          2: '2. In the Advanced settings section, click View advanced settings.',
          3: '3. In the Block pop-ups section, click the switch to Off. Pop-ups are now allowed.',
          4: '4. To block pop-ups once again, click the switch to On.',
        },
        note:
          'Note: If you are concerned about allowing pop-ups globally for all websites that you browse, consider using another web browser that allows website exceptions such as Chrome or Firefox.',
      },
      safari: {
        list: {
          1: '1. From the Safari menu, choose Preferences... and click the Security tab.',
          2: '2. Ensure the Block pop-up windows option is not checked. Unchecking this option will allow pop-ups.',
          3: '3. To block pop-ups once again, check the Block pop-up windows checkbox.',
        },
        note:
          'Note: If you are concerned about allowing pop-ups globally for all websites that you browse, consider using another web browser that allows website exceptions such as Chrome or Firefox.',
      },
    },
  },
  userManagement: {
    searchLabel: 'Search...',
    noUsers: 'No users added.',
    addNewBtn: 'Add new',
    activate: {
      title: 'Activate Profile',
      activated: `The profile has been activated.`,
      emailSent: 'An email has been sent to',
      email: '{{email}}.',
      errorOccurred:
        'An error has occurred while trying to activate the account. Please try again.',
    },
    resendInvite: {
      title: 'Resend Invite',
      invitationSent: 'An invitation has been re-sent to',
      email: '{{email}}.',
      errorOccurred: 'An error has occurred. Please try again.',
    },
    actionBtns: {
      editPermissions: 'Edit Permissions',
      activateProfile: 'Activate Profile',
      ResendInvite: 'Resend invite',
    },
    tableHeader: {
      users: 'Users',
      email: 'Email Address',
      permissions: 'Permissions',
      status: 'Status',
    },
    managementDescription:
      "You can grant either read-only, notifications only or full access to your account to other users, such as a partner, business administrator, accountant or financial manager. This will not change the ownership of your account, but will grant these other users rights to login and either view all of the account's activity or to trade, deposit, and withdraw from the account.",
    confirmRemoveUser: 'Are you sure you want to remove this user?',
    removeUserTitle: 'Remove User',
    wentWrong: 'Something went wrong. Please try again.',
    successful: 'User removal was successful.',
    roleTypes: {
      fullAccess: 'Full Access',
      readOnly: 'Read Only',
      notificationsOnly: 'Notifications Only',
    },
  },
  profileSettings: {
    title: 'Profile Settings',
    profileSettingsDescription:
      'Here you can change settings associated with your login, including your name and password. For security, you will receive email confirming each change to your profile.',
    profileSettingsNote:
      'Note that changes here apply only to your login and do not transfer to the accounts you have access to.',
    basicInformation: 'Basic Information',
    fullName: 'Full Name:',
    editFullName: 'Edit full name',
    userName: 'Username:',
    email: 'Email:',
    changeEmail: {
      documentCenter: 'document center',
      iraAccount:
        'To change your email address, please contact customer service, as requests must be verified for security.',
      jointAccountsOne: 'To change your email address, please e-mail',
      jointAccountsTwo:
        "color copies/photos of your and your joint owner's ID or upload to our secure",
      jointAccountsThree: 'as requests must be verified for security.',
      nonIraOne: 'To change your email address, please e-mail',
      nonIraTwo: ' a color copy/photo of your ID or upload to our secure',
      nonIraThree: 'as requests must be verified for security.',
    },
    password: 'Password:',
    changePassword: 'Change password',
    securityQuestion: 'Security Question:',
    securityQuestionTitle: 'Security Question',
    changeSecurityQuestion: 'Change Security Question',
    answer: 'Answer:',
    changeFullName: 'Change Full Name',
    firstName: 'First name:',
    lastName: 'Last name:',
    middleName: 'Middle name:',
    suffix: 'Suffix:',
    fullNameChanged: 'Full Name Changed Successfully',
    securityQuestionChanged: 'Security Question Changed Successfully',
    errorOccurred: 'An error has occurred. Please try again.',
    oldPass: 'Old password:',
    newPass: 'New password:',
    passwordUpdated: 'Password successfully updated',
    wrongCurrentPassword: 'The provided old password is wrong.',
  },
  fundingSource: {
    title: 'Funding Source:',
    paused: 'Funded from account balance (no automatic deposits)',
  },
  investment: {
    price: 'Current Price: {{pricePerOz}}/oz',
    selectedInvestment: 'Selected Investments:',
  },
  metalStream: {
    monthlyAmountInvestmentLabel: 'Monthly Investment Amount:',
    automaticInvestmentDescription:
      "With {{metalStream}}, you can make automatic investments in precious metals each month. As you accumulate whole bars or coins, we'll automatically place them in storage on your behalf.",
    noExtraCharge:
      "There is no extra charge for using {{metalStream}}. Your holdings, including fractions of bars and coins you haven't fully accumulated, can be sold at any time. And you can take delivery of whole bars and coins whenever you want.",
    toGetStarted: "To get started, you'll tell us:",
    whichProductsToBuy:
      'Which products to buy: one gold, one silver, or one of each from our most popular investments.',
    howToFund:
      'How to fund your investments (via automatic bank transfer or from your account balance)',
    changeSettings:
      'You can change these settings or stop/start {{metalStream}} at any time.',
    disabledMetalStream:
      'Your {{metalStream}} settings are currently disabled. Restart any time, and your investments will automatically continue with the next scheduled monthly cycle.',
    activeMetalStream:
      'Your {{metalStream}} settings are currently active. You can pause/stop your automatic investments at any time.',
    restartMetalStream: 'Restart {{metalStream}}',
    pauseMetalStream: 'Pause {{metalStream}}',
    adjustPreference:
      'Adjust your investment preferences any time, too; changes will take effect with the next monthly cycle.',
    editPreference: 'Edit Preferences',
    review: 'Please review and submit your settings.',
    setup: 'Set up {{metalStream}}',
    status: 'Status: ',
    setupRequired: 'Setup Required',
    automaticWithdrawal: 'Automatic Withdrawal from:',
    byStarting: 'I understand and agree to the',
    byStartingRegistration:
      'By starting {{metalStream}}, you agree to the supplemental',
    termsAndConditions: '{{metalStream}} terms of use',
    autoDeposit: 'Auto-deposits process on the 16th of the month',
    autoTrades: 'Auto-trades are executed between the 23rd and 26th',
    whileEnabled:
      'While enabled, even if your auto-deposit is unavailable/delayed, auto-trades will execute up to the lower of your available balance or this amount',
    selectAllocation: 'Select Allocation',
    selectProportion:
      "Select the proportion of your investment you'd like to allocate to gold and/or silver.",
    monthlyInvestment: 'Monthly Investment:',
    pickProducts:
      "In the next step, you'll pick the specific products you want to buy.",
    goldToBuy: 'Gold to buy',
    silverToBuy: 'Silver to buy',
    chooseInvestment: 'Choose your preferred {{metalType}} investment.',
    gold: 'gold',
    silver: 'silver',
    modalTitle: '{{metalStream}} Setup',
    setupSuccessful: 'Setup successful.',
    setupUnsuccessful: 'Setup unsuccessful.',
  },
  funding: {
    funding: 'Funding',
    depositTo: 'Deposit to:',
    pleaseSelect:
      "Please select how you'd like to fund your monthly investments.",
    monthlyInvestment: 'Monthly Investment:',
    fundFromBankAccount: 'Fund from your bank account:',
    fundFrom: 'Fund from:',
    fundFromAccountBalance: 'Fund from your account balance:',
    restricted:
      "This account type is restricted to only use it's balance for payments. You cannot configure automatic deposits at this time.",
    myBalance:
      'Use my account balance for {{metalStream}} purchases. No automatic deposits.',
    checkingOption:
      'By checking this option, you will need sufficient pre-funded balance. {{metalStream}} auto-trades execute once monthly in the amount you specify (or up to your available balance, whichever is less).',
  },
  investmentSetup: {
    automatic: 'Configure Automatic Investments',
    setup:
      "Let's get set up. First, let us know how much you want to invest. Then you'll choose what investments to buy and finally where the funds will come from.",
    howMuch: 'How much would you like to invest each month?',
  },
  termsAndConditions: {
    textOne:
      '{{metalStream}} allows for systematic investments into physical gold or silver on a monthly basis. Your participation in this program is subject to the {{brokerName}} customer agreement and the following terms:',
    textTwo:
      'To be eligible for any current month’s automatic investment program, you must enroll prior to the 15th of the month',
    textThree:
      'Trade execution dates are on the 24th of each month, or in the event the 24th is not a valid business day, the next business day after the 24th.',
    textFour:
      'In selecting this service, you hereby authorize {{brokerName}} to execute monthly purchase transactions for the desired product and dollar amount that you have specified.',
    textFive:
      'To participate in {{metalStream}}, you must specify a funding method – via on-demand charge of an outside account or from your pre-funded balance – and total investment, as well as directing funds to specific products, from the choices available in the Application’s {{metalStream}} settings.',
    textSix:
      'If you opt to fund via ACH, you hereby authorize the automatic pulling of the required pre-set amount of funds from your checking or savings account on a monthly basis, and understand that:',
    textSeven:
      'Automatic investments are drawn from your account on the 17th of every month, or if not a business day then the next business day. It is your responsibility to ensure adequate funds are available in your account.',
    textEight:
      'This automatic funding will continue until such time you revoke the ACH authorization using the Application’s {{metalStream}} settings tool.',
    textNine:
      'The revocation of the ACH instruction must be completed prior to 7:00 p.m. EST on the 16th day of the month, or if the 16th is not a business day, prior to 7:00 p.m. EST on the next valid business day. Clients that revoke the ACH authorization can continue participation in the {{metalStream}} program by selecting an alternative funding mechanism as listed above.',
    textTen:
      'If you opt to pre-fund your balance instead of drawing funds from an outside account:',
    textEleven:
      'Funds required for purchase must be in your account and cleared for trading prior to the execution of the monthly purchase transaction(s).',
    textTwelve:
      'In the event of inadequate available funds, we will execute the transaction for the amount of funds available in your account, up to the amount that was specified during the {{metalStream}} registration.',
    textThirteen: 'Product Offering and Conversion',
    textFourteen:
      'The products offered within {{metalStream}} each indicate an interest in fractionally allocated physical precious metals. When sufficient interest is available for any one product, {{brokerCode}} will automatically convert your interest into whole bar(s) and/or coin(s). Once holdings are converted, they can be held for further storage or be made available for delivery. Both whole and fractional positions are available for sale at any time.',
    textFifteen:
      'We reserve the right to discontinue or modify {{metalStream}} at any time and for any reason.',
  },
  IRAWithdraw: {
    withdrawFunds: 'Withdraw Funds',
    iraDistribution: 'IRA Distribution',
    iraDistributionDescription:
      'Distributions may involve taxes or penalties depending on your account type and your age. Please consult a tax advisor or your trust partner for more questions.',
    iraTransferOut: 'IRA Transfer out',
    iraTransferOutDescription:
      'Transfer money from an IRA account to a different retirement or IRA account.',
    takeAnIra: 'Take an IRA Distribution (Cash)',
    toRequestDistribution:
      'To request a distribution from your Equity IRA, log onto ',
    myEquity: 'myEQUITY.com',
    completeDistribution: ' and complete the online Distribution Wizard.',
    distributionMayBeMade:
      'Distributions may only be made payable to the name of the account holder and can be delivered through wire, check, or ACH.',
    visit: 'Visit myEquity.com',
    processing:
      '{{brokerCode}} will process the funds over to the IRA custodian within one business day.',
    takeAnIraDelivery: 'Take an IRA Distribution (Delivery of metals)',
    pleaseVisit: 'Please visit the ',
    portfolioPage: 'portfolio page',
    select: ' and select which metals you want to deliver.',
    transferOut: {
      heading: 'IRA Transfer Out',
      title: 'Transfer to Another Custodian (Assets and/or Cash)',
      toTransfer:
        'To transfer all or part of your funds or assets out of your Equity IRA to another custodian, you should first ',
      contact: 'contact your new financial institution',
      complete: ' and complete their required Transfer In Request.',
      whenCompleted:
        'Once you have completed the new custodian’s paperwork and established an account, they will contact Equity to request the funds/assets be transferred.',
      pleaseNote: 'Please Note:',
      deliveryInstructions:
        'Instructions for the delivery of all transferred funds/assets must be sent with the transfer form or other required paperwork.',
      notRequired:
        'Equity Trust Company does not require a Medallion Signature Guarantee to complete a transfer of your account.',
      processingTime:
        'Transfer outs may take up to four weeks to  complete. Processing times vary and are dependent upon the delivery of the necessary documents from the receiving provider.',
    },
    prefundedWithdraw: {
      title: 'Withdraw funds',
      titleByType: 'Withdraw funds by {{type}}',
    },
    prefundedWithdrawReview: {
      reviewBarTitle: 'Please review and confirm the withdrawal.',
      withdrawAmount: "You're about to withdraw {{amount}}",
      mailTo: 'The check will be mailed to:',
      checksInfo:
        'Checks will typically be mailed out within 2-3 business days',
    },
    amountForm: {
      amountAvailable: 'Available for withdrawal:',
      dontSeeFunds: "Don't see all of your funds?",
      amountLabel: 'Amount:',
    },
  },
  achDeposit: {
    chooseAchAddMethod: {
      modalTitle: 'New Account For ACH Deposit',
      plaidCardTitle: 'Log into your bank account',
      plaidCardDescription:
        "Using the popular service Plaid, connect by simply logging into your bank account. It's fast, secure, and your username and password are never shared with {{brokerName}}.",
      plaidCardNote: 'Account will be immediately available.',
      customCardTitle: 'Enter the information from the bottom of a check',
      customCardDescription:
        "After confirming your routing and account number, we'll make a few small deposits/withdrawals from your account that net to zero. Once you see them on your account, come back here to verify the account.",
      customCardNote: 'Available after verification; 2-3 business days.',
    },
    terms: {
      labels: {
        depositAmount: 'Deposit Amount:',
        depositoryName: 'Depository Name:',
        bankCity: 'Bank City:',
        bankState: 'Bank State:',
        bankZip: 'Bank Zip:',
        routingNumber: 'Routing Number:',
        abaRoutingNumber: 'ABA Routing Number:',
        achRoutingNumber: 'ACH Routing Number:',
        wireRoutingNumber: 'Wire Routing Number:',
      },
    },
    secure: 'Secure',
    private: 'Private',
    newAccountForAchDeposit: 'New Account for ACH Deposit',
    accountType: 'Account Type:',
    routingNumber: 'Routing Number:',
    accountNumber: 'Account Number:',
    nameOnAccount: 'Name on Account:',
    transferIsEncrypted: 'Transfer of your information is encrypted end-to-end',
    credentials: 'Your credentials will never be made accessible to Coinbase',
    bySelecting: 'By selecting “Continue” you agree to the ',
    plaidPolicy: 'Plaid End User Privacy Policy',
    bankName: 'Bank Name',
    smallDeposits: "We're making two small deposits into your bank account.",
    inTwoThreeDays:
      'In 2-3 days, these amounts will appear in your bank account. Come back then to verify the amount of the deposits and finish your connection.',
    bankAccount: 'Log into your bank account',
    bankAccountNote: 'Account will be immediately available.',
    checkAccount: 'Enter the information from the bottom of a check',
    checkAccountDescription:
      "After confirming your routing and account number, we'll make a few small deposits/withdrawals from your account that net to zero. Once you see them on your account, come back here to verify the account.",
    checkAccountNote: 'Available after verification; 2-3 business days.',
    abaRoutingNumberValidationFirst: 'Please ',
    abaRoutingNumberValidationSecond: 'contact customer service ',
    abaRoutingNumberValidationThird: 'for assistance (code: 002).',
  },
  withdrawalWizard: {
    withdrawFunds: 'Withdraw funds',
    withdrawFundsByType: 'Withdraw funds by {{type}}',
    withdrawFundsByCheck: 'Withdraw funds by Check',
    check: 'Check',
    wire: 'Wire',
  },
  depositWizard: {
    depositFunds: 'Deposit Funds',
    fundYourIra: 'Fund Your IRA',
    equity: {
      title: 'Add prefunded storage fee balance',
      contributionTextOne:
        'If you want to get every dollar out of the compounding of your tax-advantaged investment, you can pay fees and expenses with additional funds that will not affect your IRA balance or count towards your contribution limits.',
      contributionTextTwo:
        "We maintain an additional balance for your account that allows you to do this with your storage fees. Simply deposit funds to this additional balance and your storage fees will be paid from those funds instead of your IRA's balance. (If you wish to do the same with your annual custodial fee, please contact {{type}} to arrange.)",
      contributionTextThree:
        'Just select a deposit method below and follow the instructions.',
      haveQuestions:
        'Have questions on IRA deposits? <1>Click here</1> to contact us.',
      haveQuestionsWithoutContact: 'Have questions on IRA deposits?',
      contactInfo: "Contact {{type}}'s precious metals service center.",
      phone: 'Phone:',
      fax: 'Fax:',
      email: 'Email:',
      contact: 'Contact:',
      bank: 'Bank:',
      routingAba: 'Routing/ABA:',
      contactLink: 'www.entrustpmc.com/contact-us',
      forCreditTo: 'For credit to:',
      beneficiary: 'Beneficiary',
      memo: 'Memo:',
      accountNumber: 'Account Number:',
      upic: 'Upic',
      note: 'Please note: ',
      wire: {
        completeFormAtBank:
          'To make a contribution with a wire or ACH transfer, you will have to complete a form at your bank (usually available online, but sometimes wires require in-person signatures; check with your bank). The information required is below.',
        textOne:
          'IMPORTANT: Please be sure that you provide the exact information below in your memo field for accurate processing. Also please indicate on the check what tax year the contribution should apply to.',
        textOneAlt:
          'IMPORTANT: Please be sure that you provide the exact information below in your memo field for accurate processing.',
        textTwo:
          'You should contact Equity Trust prior to making a contribution this way to ensure they process it correctly, including providing the tax year it should apply to.',
        textThree:
          'An incoming bank wire fee will be automatically deducted from the amount sent by Equity. There is no charge for receiving an ACH.',
        additional:
          'For additional details on international wires and ACH instructions, please visit:',
        wireLink: 'www.equityinstitutional.com/wires',
      },
      withdrawalResponse: {
        submitted: 'Withdrawal Submitted',
        couldNotSubmit: 'Withdrawal could not be submitted',
        errorOccurred: 'An error occurred while submitting.',
      },
    },
    iraTypeSelection: {
      iraTransfer:
        'A transfer is the movement of funds directly from your IRA current custodian to a like account at {{type}}/{{brokerCode}}.',
      rolloverDeposit:
        'A rollover can be used to move money from a 401(k) to an IRA, or from any other qualified plan (403b, ESOP, etc.)',
      annualIraContribution:
        'The fastest way to fund an IRA is with a contribution. You can mail a check, or send a bank wire or ACH transfer, to {{type}} to make your annual contribution, up to the current limits for your account type.',
    },
    rollover: {
      title: 'Rollover Deposit',
      forAccount: 'instructions for {{account}}',
      textOne:
        'A rollover can be used to move money from a 401(k) to an IRA, or from any another qualified plan (403b, ESOP, etc.)',
      textTwo:
        'To complete a rollover, contact your current custodian/plan administrator to initiate the move. They will supply you with their rollover request form and can detail any restrictions that might govern your rollover.',
      textThree:
        'When completing the form supplied by your current custodian, use the following information to ensure the fastest processing into your {{type}}/{{brokerCode}} account:',
      accountNumber: 'Account number: {{num}}',
      equityNumber: 'Equity number: {{num}}',
      receivingCustodian: 'Receiving Custodian:',
      mailingAddress: 'Mailing Address:',
      phoneField: '{{phoneNum}}',
      haveQuestions: 'Have questions on IRA deposits?',
      contactText: "Contact {{type}}'s precious metals service center.",
      noteForPayable:
        'Please note that all rollover checks should be payable to:',
      paylableTo: 'Payable to:',
      memo: 'Memo:',
      address: 'Address:',
      phone: 'Phone:',
      fax: 'Fax:',
      email: 'Email:',
      contact: 'Contact:',
      rolloverNoteOne:
        'Rollover funds will be available in your account within one business day of receipt from your current custodian',
      rolloverNoteTwo:
        'Please note that rollovers from employer sponsored plans typically take 10-15 business days, depending on the turnaround time of the current plan administrator.',
    },
    iraTransfer: {
      title: 'IRA to IRA Transfer',
      description:
        'A transfer is the movement of funds directly from your IRA current custodian to a like account at Equity Trust/{{brokerCode}}.',
      whatIsATransfer:
        'A transfer is the movement of funds directly from your IRA current custodian to a like account at {{type}}/{{brokerCode}}.',
      toInitiateATransfer:
        'To initiate a transfer, a Transfer Form should be completed, signed, and sent to {{type}}.',
      formDescription:
        'The form should detail the specific cash and/or assets that should be moved to your new account with {{brokerCode}}, and the detail of your current custodian (including name, address, and account number).',
      phone: 'Phone:',
      fax: 'Fax:',
      email: 'Email:',
      contact: 'Contact:',
      transferRequest:
        'Transfer requests will be reviewed by {{type}} within one to three business days and sent to your current custodian for the delivery of the transferring cash/assets.',
      processLength:
        'The process may take anywhere from 5 - 7 business days, dependent on the current custodian’s processing timeframes. {{type}} will work to get your funds transferred as quickly as possible.',
      recommendation:
        'We recommend transferring cash only for the fastest processing. When transferring other assets, please ensure they match the products for sale at {{brokerCode}} or there may be a delay and costs to return ineligible assets (such as non-precious metals, numismatics, sizes we do not support, etc.).',
      haveQuestions: 'Have questions on IRA deposits?',
      contactServiceCenter:
        "Contact {{type}}'s precious metals service center.",
      constactBrokerCenter:
        'Contact {{brokerName}} precious metals service center.',
      downloadForm: 'Download/Print IRA Transfer Form',
      pleaseWaitForFunds:
        "The process may take anywhere from few days up to 60 days, dependent on the current custodian's processing timeframes. {{type}} will work to get your funds transferred as quickly as possible.",
    },
    iraContribution: {
      title: 'Annual IRA Contribution',
      fastestWayToFund:
        'The fastest way to fund an IRA is with a contribution. You can mail a check, or send a bank wire or ACH transfer, to {{type}} to make your annual contribution, up to the current limits for your account type.',
      haveAnyQuestions:
        "If you have any questions about the annual contribution limits for your account type, deductibility, income limits for Roth contributions, or similar issues, please reach out to {{type}}'s precious metal service desk (details below).",
      questions: 'Have questions on IRA deposits?',
      contactCenter: "Contact {{type}}'s precious metals service center.",
      phone: 'Phone:',
      fax: 'Fax:',
      email: 'Email:',
      contact: 'Contact:',
    },
    equityCheck: {
      textOne:
        'IMPORTANT: Please be sure that you provide the exact information below in your memo field for accurate processing. Also please indicate on the check what tax year the contribution should apply to.',
      payableTo: 'Payable to: ',
      memo: 'Memo:',
      sendCheckTo: 'Send check to:',
      mailingAddress:
        'Mailing Address Third Party Shipper: (UPS, FedEx, DHL etc...):',
      overnight: 'Overnight:',
      mailing: 'Mailing Address:',
      fundingCheckMailingAddress: 'Mailing Address (USPS):',
      iraFeeBalance: 'IRA Fee Balance',
      storageFeeBalance: 'Storage Fee balance',
    },
  },
  standardDepositWizard: {
    typeSelectTitle: 'Deposit Funds',
    achDeposit: {
      pleaseReviewAndConfirmTheWithdrawal:
        'Please review and confirm the withdrawal.',
      achDepositTitle: 'ACH Deposit',
      achDepositHighlight: 'Most Popular',
      achDepositDescription:
        'Also known as direct deposit or EFT, connect to your bank electronically for free transfers. This is the same method used by PayPal and most banks/brokers in the U.S.',
      achDepositNote: '{{amount}} per week limit; funds available in {{days}}.',
      achDepositVoidedCheckNote:
        'Further proof may be requested such as Voided Check or Bank Statement in order to verify your banking details.',
      depositSubmitted: 'Deposit submitted',
      achAmount: 'Amount:',
      wireWithdrawalFeeCredit: 'Wire withdrawal fee credit:',
      depositFrom: 'Deposit from:',
      dontSeeYourBankAccount: "Don't see your bank account?",
      connect:
        'You can connect your {{brokerName}} account to one or more bank accounts to make deposits and withdrawals easier. You can add or remove connections at any time here. To make a transfer, please use the Add Funds or Withdraw Funds tools.',
      pendingAchDepositBankAccounts: 'Pending ACH deposit bank accounts:',
      allBankAccounts: 'All Bank Accounts',
      noConnectedBankAccounts: 'No connected bank accounts',
      connectANewBankAccount: '+ Connect a new bank account',
      pleaseReviewAndConfirmTheDeposit:
        'Please review and confirm the deposit.',
      depositFromBank: 'Deposit from {{bankName}}',
      fundsAvailable: 'Available for trading in {{days}}.',
      checkboxText:
        'I authorize {{brokerCode}} to make this deposit and agree to the',
      checkboxLink: 'ACH supplemental terms',
      terms: {
        title: 'ACH supplemental terms',
        authorize:
          'I hereby authorize {{brokerCode}} to debit my (our) account at the depository financial institution as detailed below. I (we) acknowledge that the origination of ACH transactions to my (our) account must comply with the provisions of US law.',
        remain:
          'This authorization is to remain in full force and effect until {{brokerCode}} has received written notification from me (us) of its termination in such time and in such manner as to afford {{brokerCode}} and/or the depository institution a reasonable opportunity to act on it.',
        debit:
          'This authorization for ACH debit(s) can be revoked as follows: The client must sign into their account and access the funding request status screen prior to 7:00 pm EST and select the cancel option for the applicable ACH debit.',
        understandTextOne: 'I understand that ACH transfers are subject to a',
        understandTextTwo:
          ' hold starting the day the funds are posted to the {{brokerName}} account. They will become available for trading/withdrawal beginning at midnight after the final day of the hold (estimated availability of this deposit:',
      },
      fixBankAccount: {
        title: 'Verify your bank connection',
        connectBankAccounts:
          'You can connect your {{brokerName}} account to one or more bank accounts to make deposits and withdrawals easier.',
        verifyBankConnections:
          'All bank connections need to be verified before transactions can be made.',
      },
    },
    wireDeposit: {
      wireDepositTitle: 'Wire Deposit',
      wireDepositHighlight: 'Quickest availability',
      bankWireDepositTitle: 'Bank Wire Deposit',
      pendingWireWithdrawalBankAccounts:
        'Pending wire withdrawal  bank accounts:',
      checkOnlineWire:
        'Bank wires are the fastest way to make a deposit. Many banks allow you to send wires online, but others may require you to go in person. Please check with your bank.',
      useGuideBelow:
        'Use the guide below for filling out online wire forms, or print it to take with you to your bank.',
      pleaseProvideInformation:
        'PLEASE BE SURE that the wire instructions you provide your bank include both your name and your {{brokerCode}} account number in the beneficiary field (sometimes called OBI1/2) to avoid significant delays. Your bank should be familiar with this process.',
      brokerDoesNotChargeFee:
        '{{brokerCode}} does not charge a fee to receive a wire. However, most banks do charge a fee to send one. Those fees may be deducted from your deposit amount or charged separately, depending on your bank.',

      wireDepositDescription:
        'We accept bank wires from both U.S. banks and most international banks (those that use SWIFT). They are the fastest way to fund your account.',
      wireDepositNote: 'No limit;  funds available same to next business day.',
      otherCurrencyAutomaticConversion:
        'This option will automatically convert your deposit from other currencies to U.S. Dollars when received by our bank.',
      pleaseWaitForFunds:
        'Because of the correspondent bank, please allow up to 5-7 business days for the funds to reach our bank. Funds are typically credited to your account the same day our bank receives them.',
      wireDepositList: {
        BankName: 'Bank Name:',
        BankAddress: 'Bank Address:',
        BankPhone: 'Bank Phone:',
        RoutingAbaNumber: 'Routing Number:',
        AccountNumber: 'Bank Account Number:',
        PayableTo: 'Beneficiary:',
        Memo: 'Memo:',
        SwiftCode: 'SWIFT Code:',
        AccountName: 'Bank Account Name:',
        IntermediaryBankName: 'Intermediary Bank Name:',
        IntermediarySwift: 'Intermediary SWIFT:',
        BeneficiarySwiftCode: 'Beneficiary SWIFT Code:',
        BeneficiaryAccountName: 'Beneficiary Account Name:',
        BeneficiaryNumber: 'Beneficiary Account Number:',
        Reference: 'Reference:',
        CompanyAddress: 'Company Address:',
      },
    },
    checkDeposit: {
      checkDepositTitle: 'Check Deposit',
      checkDepositDescription:
        'You can also send us a check by mail to make your deposit. We accept U.S. and International checks.',
      checkDepositNote:
        '{{amount}} per day limit; funds available 7 business days from receipt.',
      toMakeDeposit:
        'To make a deposit via personal or business check, follow these instructions.',
      addBrokerAccountNumber:
        'PLEASE BE SURE to add your {{brokerCode}} account number, and name if it can fit, to the memo field of the check.',
      haveQuestions:
        'If you have any questions about funding your account, <1>please contact customer service.</1>',
      fundingYourAccount: 'Funding your account',
      againstFraud:
        'To help combat fraud, check deposits are held for 7 business days from receipt before they become available for trading.',
      whenDepositReceived:
        'Once we receive and deposit your check it will be reflected as pending on your account history.',
      checkDepositList: {
        payableTo: 'Payable to:',
        memo: 'Memo:',
        fundingCheckMailingAddress: 'Mailing Address (USPS):',
        fundingCheckMailingAddressThirdPartySHipper:
          'Mailing Address Third Party Shipper (UPS, FedEx, DHL etc...)',
      },
    },
  },
  standardWithdrawalWizard: {
    amount: 'Amount:',
    availableForWithdrawal: 'Available for withdrawal:',
    dontSeeFunds: "Don't see all your funds?",
    successfulWithdrawal: 'Withdrawal Submitted',
    unsuccessfulWithdrawal: 'Withdrawal Not Submitted',
    wireWithdrawal: {
      title: 'Wire Withdrawal',
      wireFee: 'Wire fee:',
      typeSelectDescription:
        'Funds can be sent via wire to both U.S. banks and most international banks (which use SWIFT). This is the fastest method to withdraw.',
      typeSelectNote: '{{fee}} fee; wires are sent same or next business day',
      aboutToWithdraw:
        "You're about to withdraw {{amount}} with a wire fee of {{fee}}",
      wireAvailable:
        'Domestic wires will typically be available same day if submitted before 3:00 PM ET',
      amountDepositedTo:
        '{{amount}} will be deposited into {{bankName}} {{accountMask}}',
    },
    checkWithdrawal: {
      title: 'Check Withdrawal',
      typeSelectDescription:
        "Check will be sent by mail to the account's registered owner.",
      typeSelectNote: 'No fee; checks mailed every week.',
      dontSeeAddress: "Don't see your address?",
      dontSeeAllAddresses: 'Don’t see all of your address?',
      validateAddressesDisabled:
        'For your security we validate all requests to add a new address for withdrawal or delivery. Please contact our customer support to add a new address. ',
      validateAddresses:
        'For your security, usage of unverified addresses is limited to buy for delivery orders. To verify an address, please contact customer service.',
      validateAddresses1:
        'In order to make the verification process as quick as possible, be prepared to send a scan or picture of:',
      validateAddresses2:
        'The account holder’s ID (a driver’s license or passport).  An ID will be required for both members in a joint account.',
      validateAddresses3:
        'If that ID does not have the address printed on it, a recent utility bill will be required in addition to the ID.',
      contactCustomerSupport: 'Contact customer support',
      deliverAddress: 'Deliver to:',
      noAddressSelected: 'No added addresses',
    },
    noFunds: {
      availableFundsDescription:
        'The funds available to withdraw from your account may differ from your Effective Balance as they only include proceeds from fully settled activity. Pending trades, pending deposits, and any holds will affect the amount available to withdraw.',
      effectiveBalance: 'Effective Balance:',
      availableForTrading: 'Available for Trading:',
      availableForWithdrawal: 'Available for Withdrawal:',
      balanceHistory: 'Balance History',
    },
  },
  entrust: {
    transferOut: 'IRA Transfer Out',
    distribution: 'IRA Distribution',
    amount: {
      label: 'Amount:',
      available: 'Available for withdrawal:',
      dontSee: "Don't see all of your funds?",
      download: 'Download IRA Distribution Form',
    },
    review: {
      reviewBar: 'Please review and confirm the withdrawal.',
      amount: 'Withdrawal amount:',
      mailingAddress: 'Mailing address:',
      notice:
        'By Submitting this request, the funds will be transferred to Millenium Trust and held in the IRA account until the necessary forms have been received.',
      description:
        'By confirming, I certify that I own or have the right to use and access this bank account. I understand that fraud is ILLEGAL, punishable by jail time AND/OR monetary fines. I further acknowledge that if I engage in any fraudulent or other illegal activity with respect to this or any other account with GBI, GBI WILL prosecute any such activity to the fullest extent of the law.',
    },
    disclosure: {
      title: 'Cash Distribution Disclosure:',
      textOne:
        'I certify that I am the proper party to receive a cash distribution from this IRA, and that all information provided by me is true and accurate. I acknowledge that I have read the Notice of Withholding below and have elected not to have federal or state income taxes withheld. I further certify that no tax advice has been given to me by the Administrator and/or Custodian that distributions are reported to the IRS, and that all decisions regarding this withdrawal are my own. I expressly assume the responsibility for any adverse consequences which may arise from this withdrawal and I agree that the Administrator or Custodian shall in no way be responsible for those consequences.',
      textTwo:
        'I elect not to have federal or state income tax withheld from my cash distribution.',
      textThree:
        'The distributions you receive from your individual retirement account established at this institution are subject to federal and state income tax withholding unless you elect not to have withholding apply. If you elect not to have withholding apply to your distribution payments, or if you do not have enough federal income tax withheld from your distribution, you may be responsible for payment of estimated tax. You may incur penalties under the estimated tax rules if your withholding and estimated tax payments are not sufficient.',
      checkbox:
        'I declare that I have examined this information, and to the best of my knowledge and belief, it is true, correct, and complete.',
    },
  },
  millenium: {
    inOrder:
      'In order to fund your Millenium Trust IRA, you must visit the Millenium Trust website for instructions.',
    tapTheButton: 'Tap the button below to be directed to Millenium Trust.',
    fundAccount: 'Fund your account',
    transferOut: 'IRA Transfer Out',
    distribution: 'IRA Distribution',
  },
  address: {
    areYouSure: 'Are you sure you want to remove this address?',
    youWillNoLonger:
      'You will no longer be able to request deliveries to that location.',
  },
  user: {
    switchAccount: 'Switch Account',
    userSettings: 'User Settings',
    openNewAccount: 'Open New Account',
    activateProfile: 'Activate Profile',
    emailAddress: 'Email Address',
    logout: 'Logout',
    addNew: {
      label: 'Add New User',
      successful: 'You have successfully added a new user.',
      unsuccessful: 'You have already assigned permissions to this user.',
    },
    editPermissions: {
      label: 'Edit Permissions',
      successful: 'You have successfully edited the users permission.',
    },
    email: {
      label: 'Email:',
    },
    permissions: {
      label: 'Permissions:',
      NotificationOnly: {
        label: 'Notifications Only',
        description:
          'No invitation will be sent; user will receive a copy of all emails but not be able to log in to the account',
      },
      ReadOnly: {
        label: 'Read Only',
        description:
          'User will be invited to create a login or use their existing one; they can login and see all positions and activity but cannot trade, withdraw, or take any other actions.',
      },
      FullAccess: {
        label: 'Full Access',
        description:
          'User will have unrestricted access to trade, deposit, withdraw, and change account settings (including adding/removing user access for you or others).',
      },
    },
  },
  resetPassword: {
    title: 'Reset your password',
    securityQuestion: {
      title: 'Security Question',
      subtitle:
        'Please answer the security question to gain access to your account:',
      label: 'Reset Password',
    },
    resetSuccessful: {
      description:
        'You have successfully reset your password. Continue to the login page.',
    },
    resetSent: {
      description:
        "If the information you've entered is associated with an account, you will receive an email with a link to reset your password.",
    },
    newPassword: {
      subtitle: 'Please enter your new password.',
      label: 'New Password:',
      savePassword: 'Save Password',
    },
  },
  footer: {
    copyright:
      'Copyright © {{year}} {{corporateLegalName}}. All Rights Reserved.',
    menu: {
      disclaimer: 'Disclaimer',
      privacy: 'Privacy Policy',
      termsAndConditions: 'Terms & Conditions',
      accessibilityStatement: 'Accessibility Statement',
      accessibilityAssistant: 'Accessibility Assistant',
      caPrivacyRights: 'CA Privacy Rights',
      saleTax: 'Sales Tax',
      gdpr: 'Gdpr',
    },
  },
  status: {
    verified: 'Verified',
    nonVerified: 'Not verified',
    executed: 'Executed',
    completed: 'Completed',
    complete: 'Complete',
    pending: 'Pending',
    pendingExecution: 'Pending Execution',
    settled: 'Settled',
    shipped: 'Shipped',
    paidInFull: 'Paid in full',
    notExecuted: 'Not executed',
    partiallyExecuted: 'Partially executed',
    pendingFineWeight: 'Pending Fine Weight',
    paid: 'Paid in full',
    pendingShipment: 'Pending shipment',
    returned: 'Returned',
    pendingSettlement: 'Pending Settlement Of Buy',
    onHold: 'Complete on hold',
    rejected: 'Rejected',
    partiallyPaid: 'Partially Paid',
    pendingPayment: 'Pending payment',
    inGrace: 'In Grace Period',
    actionRequired: 'Needs attention',
    paymentIssued: 'Payment Issued',
    productsReceived: 'Products Received',
    quoteConfiemed: 'Quote Confirmed',
    partialTrackingReceived: 'Partial tracking received',
    pendingCancelation: 'Pending cancelation',
    pendingEstradingSubmission: 'Pending estrading submission',
    pendingInstructions: 'Pending Instructions',
    pendingShipmentInstructions: 'Pending shippment instructions',
    pendingProductsReceipt: 'Pending products peceipt',
    pendingFESubmission: 'Pending FE submission',
    pendingUpdate: 'Pending update',
    pendingFractionalPositionOutSubmission:
      'Pending fractional position out submission',
    pendingFractionalPositionInSubmission:
      'Pending fractional position in submission',
    pendingQuoteRequest: 'Pending quote request',
    awaitingPayout: 'Awaiting payout',
    quoted: 'Quoted',
    shipmentRequested: 'Shipment requested',
    quoteRequested: 'Quote requested',
    declined: 'Declined',
    cancelled: 'Cancelled',
    errored: 'Errored',
    approved: 'Approved',
    inTransit: 'In Transit',
    pendingVerification: 'Pending verification',
  },
  support: {
    contactSupport: 'Contact Us',
    note: "We're here to help",
    questions:
      'Questions about your {{brokerName}} account? Our support team is available to help via multiple convenient channels.',
    youCanEmail:
      'You can email us with questions 24x7 or call us during our office hours. Our team is currently available {{timerange}}.',
    quickestAnswer:
      'For the quickest answers, also try searching the most common questions:',
    supportCenter: 'Support Center:',
    browseQuestions: 'Browse frequently asked questions',
    liveChat: 'Live Chat:',
    chatWithAgents: 'Click to chat with our agents',
    emailUs: 'Email Us:',
    callUs: 'Call Us:',
    toll: 'Toll-free:',
    tollInternational: 'Toll/International:',
    actionUnavailable: 'This action is currently unavailable, please contact',
    loginIsPrevented:
      'Access to this account is unavailable, all actions are disabled, please contact',
    iraWithdrawalPrevented:
      '{{custodianName}} requires additional paperwork when requesting a balance withdrawal or IRA distribution. Please ',
    iraWithdrawalPreventedCustomerSupport: 'contact customer service',
    iraWithdrawalPreventedRest: 'for more information on that process.',
  },
  registration: {
    fieldLabels: {
      password: 'Password*',
    },
    signIn: 'Sign in ',
    loginDescription: 'Already have an account? ',
    instead: 'instead',
  },
  buyWizard: {
    chooseVault: {
      modalTitle: 'Buy Wizard - Choose a Location',
      subtitle: 'Choose a Vault',
      vaultTableHeaders: {
        vaultLocation: 'Vault Location',
        pricePerUnit: 'Price Per Unit',
        delivery: 'Delivery',
      },
      priceTiers: 'Price Tiers',
      segregatedStorage: 'Use {{segregatedCaption}} storage',
      learnMore: 'Learn more',
      requireSegregatedStorage:
        'Need extra security? Choose {{segregatedCaption}} storage.',
      segregatedStorageModal: {
        title: '{{segregatedCaption}} storage',
        textTitle: 'Increase Protection for Your Most Precious Assets',
        textIntroduction:
          'For extra security, choose {{segregatedCaption}} Storage and receive:',
        paragraplLabel1: 'Individualized V.I.P. Care: ',
        paragraph1:
          'Your metals are wrapped, meticulously labeled, and stored in an isolated, reserved space in the vault, ensuring they are never mixed with other investors holdings.',
        paragraplLabel2: 'Total Peace of Mind: ',
        paragraph2:
          'You get added assurance of signed and verified warehouse certificates that detail your holdings, available on demand.',
        paragraplLabel3: 'Transparent Pricing: ',
        paragraph3:
          'Enjoy this premium service for only 0.96% per year, with a minimum quarterly charge of $99 per metal type at each vault location.',
        checkboxInfo:
          'Yes, I want the upmost security for my assets, place my metals in {{segregatedCaption}} Storage.',
      },
    },
    buyForDelivery: {
      title: 'Buy for Delivery',
      chooseDeliveryAddress: {
        modalTitle: 'Buy For Delivery - Choose a Delivery Address',
        subtitle: 'Choose a Delivery Address',
        validateAddress:
          'For your security, usage of unverified addresses is limited to buy for delivery orders. To verify an address, please contact customer service.',
        validateAddress1:
          'In order to make the verification process as quick as possible, be prepared to send a scan or picture of:',
        validateAddress2:
          'The account holder’s ID (a driver’s license or passport).  An ID will be required for both members in a joint account.',
        validateAddress3:
          'If that ID does not have the address printed on it, a recent utility bill will be required in addition to the ID.',
      },
      locationValidation:
        'Purchases for delivery to international addresses are not currently supported.  You may still purchase for international or domestic storage instead.',
      orderEntry: {
        modalTitle: 'Buy for Delivery - Order Entry',
        cashNote:
          "We'll purchase the maximum whole quantity of this product up to the amount indicated at current market prices.",
      },
      calculations: {
        estimatedSubTotal: 'Est. Subtotal*:',
        subTotal: 'Subtotal:',
        deliveryCosts: 'Delivery Cost:',
        estimatedSalesTax: 'Sales Tax:',
        salesTax: 'Sales Tax:',
        unassessedStorageFees: 'Unassessed Storage Fees:',
        estimatedOrderTotal: 'Est. Order Total*:',
        orderTotal: 'Order Total:',
        cashBuffer: 'Cash Buffer ({{cashBuffer}}%):',
        requiredBalance: 'Required Balance:',
        notEnoughFunds: 'Not enough funds for delivery.',
        notEnoughWithdrawalFunds: 'Not enough withdrawal funds for delivery.',
        notEnoughFundsDueToPriceFluctuation:
          'Due to price fluctuation, there are not enough funds available for delivery.',
      },
      reviewOrder: {
        modalTitle: 'Buy for Delivery - Verify Order',
        subtitle: 'Verify Order',
        cashOrder: 'Cash order for',
        quantityOrder: 'Quantity order for',
        quantityOrderEstimated: 'Est. Order Total*: {{estimatedTotal}}',
        quantityOrderTotal: 'Order Total: {{estimatedTotal}}',
        orderTotal: {
          estimatedPriceUnit: 'Est. Price Per Unit*:',
          estimatedQuantity: 'Est. Quantity:',
          cardFee: 'Card fee ({{creditCardFee}})%',
          paymentMethod: 'Payment method:',
          estTotal: 'Est. Total*:',
          total: 'Order Total:',
          volumeDiscount: 'Volume Discount:',
          deliveryAddress: 'Delivery Address:',
          pricePerUnit: 'Price Per Unit*:',
          quantity: 'Quantity:',
          subtotal: 'Subtotal:',
          deliveryCost: 'Delivery Cost:',
          estimatedSalesTax: 'Sales Tax:',
          salesTax: 'Sales Tax:',
          unassessedStorageFees: 'Unassessed Storage Fees:',
          estimatedTotal: 'Est. Order Total*:',
          cashBuffer: 'Cash Buffer ({{cashBuffer}}):',
          requiredBalanceWithBuffer: 'Required Balance with Buffer:',
          requiredBalance: 'Required Balance:',
        },
      },
      limitationCopy:
        'This order exceeds standard shipment limits. Please contact customer service to arrange delivery.',
    },
    buyForStorage: {
      title: 'Buy for Storage',
      cashOnly: 'Cash orders only - Extended hours',
      orderEntry: {
        modalTitle: 'Buy for Storage - Order Entry',
      },
      calculations: {
        estimatedOrderTotal: 'Est. Order Total*:',
        orderTotal: 'Order Total:',
        cashBuffer: 'Cash Buffer ({{cashBuffer}}):',
        requiredBalance: 'Required Balance with Buffer:',
        volumeDiscount: 'Volume Discount:',
      },
    },
    verifyOrder: {
      buyForStorage: 'Buy for Storage - Verify Order',
      buyForDelivery: 'Buy for Delivery - Verify Order',
      review: 'Please review and confirm to place your order',
      subtitle: 'Verify Order',
      paymentMethod: 'Payment method:',
      dalayedSettlementWarning:
        'Due to the nature of markets for diamond products, it may take up to 30 days before these holdings are available to sell.',
    },
    common: {
      pricePerUnit: 'Price Per Unit*:',
      pricePerUnitLockedPrice: 'Price Per Unit:',
      availableForTrading: 'Available for trading:',
      selectBuyType: 'Select Buy Type:',
      deliverTo: 'Deliver to:',
      volatilityNote:
        '** Because of volatility in market orders, available cash must be at least 1.5% more than the estimated total.',
      note:
        '*Note: Prices are estimated until executed with our dealer network.',
      orderEntry: 'Order Entry',
      quantity: 'Quantity',
      cash: 'Cash',
      cashOrderLabel: 'Buy up to amount:',
      quantityOrderLabel: 'Number of items to buy:',
      dontSeeAddress: "Don't see your address?",
      contactCustomerSupport: 'Contact Customer Support',
      contactUs: 'Contact Us',
      reviewBar: 'Please review and confirm to place your oder',
      unit: 'unit.',
      units: 'units.',
      noAddressAdded: 'No address added.',
      addAddress: 'Add address',
    },
    confirmationMessage: {
      orderPlaced: 'Your order has been placed.',
      onTradeConfirmedMessage: "We'll alert you when the trade is confirmed.",
    },
    postPaid: {
      modalTitle: 'Buy for Storage - Payment',
      deliveryModalTitle: 'Buy for Delivery - Payment',
      paymentMethod: 'Payment method',
      quantityOrder: 'Quantity order',
      pricePerUnit: 'Price Per Unit:',
      price: 'Price',
      quantity: 'Quantity:',
      total: 'Order Total:',
      subTotal: 'Subtotal:',
      shippingAndHandling: 'Shipping & handling:',
      salesTax: 'Sales tax:',
      selectPaymentMethod: 'Select a payment method',
      paymentMethods: {
        AccountBalance: 'Account balance',
        ACH: 'ACH bank connection',
        CreditCard: 'Credit or debit card',
      },
      availableForTrading: '{{accountBalanceAmount}} available for trading',
      limitPerOrder: '{{limitAmount}} limit per order',
      limitPerDay: '{{limitAmount}} limit per day',
      paymentFailureTitle: 'There was an issue with your payment option.',
      paymentFailureParagraph1:
        'Unfortunately we’ve encountered an error using this {{paymentType}} ',
      paymentFailureParagraph2:
        'All of our transactions are screened and even if you have enough funds in your account, there may still be an issue.',
      paymentFailureContactSupport1:
        'Please choose a different payment method or ',
      paymentFailureContactSupport2: 'contact customer support',
      paymentFailureContactSupport3: ' for help.',
      chooseDifferentPaymentSupport: 'Choose a Different Payment Method',
      onlyPlaidAccepted: 'Only Plaid connections accepted',
      postPaidStorageErrorModalTitle: 'Buy for Storage',
      postPaidDeliveryErrorModalTitle: 'Buy for Delivery',
      creditCardExpired: 'Expired {{expirationMonth}}/{{expirationYear}}',
      insufficientBalance: 'Insufficient balance',
    },
  },
  inviteUser: {
    steps: {
      credentials: 'Provide Credentials',
      review: 'Review',
      welcome: 'Welcome',
    },
    securityQuestion: {
      title: 'Security Question:',
    },
    credentials: {
      titleExisting: 'Step 2: Log In To Accept',
      titleCreate: 'Step 2: Create Login to Accept',
      existingUsername: {
        title: 'Do you have an existing username that you would like to use?',
        noExistingUsername:
          "I don't have a username and would like to create one.",
        existingUsername:
          'I have a username and would like to link it to this account.',
      },
      personalInformation: {
        title: 'Personal information',
        subtitle: 'Please provide your full legal name.',
      },
      phoneNumber: {
        title: 'Phone Number',
        subtitle: '(country code + area code + xxx-xxxx)',
      },
      loginAccess: {
        title: 'Set up login for account access',
        subtitleOne: 'Please set a password to secure your account.',
        subtitleTwo:
          'Your email address will serve as your username when logging in.',
      },
      securityQuestion: {
        title: 'Security Question',
        subtitle:
          'For an added layer of protection on your account, you may be asked a security question to confirm your identity when using {{brokerCode}} on a new device.',
      },
      loginInfo: {
        titleOne:
          'It looks like you already have a login associated with this email address.  Please log in to accept this invitation.',
        titleMultiple:
          'It looks like you already have logins associated with this email address.  Please choose which username you wish to associate with this account, then log in to accept this invitation.',
      },
      labels: {
        username: 'Username*',
        password: 'Password*',
        suffix: 'Suffix',
        legalFirstName: 'First Name*',
        legalMiddleName: 'Middle Name',
        legalLastName: 'Last Name*',
        phoneNumber: 'Phone Number*',
        confirmPassword: 'Confirm Password',
        securityQuestion: 'Security Question*',
        answer: 'Answer*',
        confirmAnswer: 'Confirm Answer*',
        securityAnswer: 'Security Answer*',
      },
      cta: {
        addAnother: 'Add Another',
        addPhoneNumber: 'Add Phone Number',
        makePrimary: 'Make Primary',
      },
    },
    confirm: {
      title: 'Step 1: Confirm your email.',
      subtitle: 'Please confirm your email',
      email: 'Email:',
      statusModal: 'Email validation',
    },
    review: {
      title: 'Step 2: Review Information',
      subtitle:
        "You're almost done! Please review the information on this page.",
      ownerInformation: "Primary owner's information",
      labels: {
        accountHolder: 'Account Holder:',
        phoneNumber: 'Phone number:',
        username: 'Username:',
        password: 'Password:',
        securityQuestion: 'Security question:',
        answer: 'Answer:',
      },
      statusModal: 'User creation',
    },
    welcome: {
      title: 'Welcome!',
      subtitle: 'You are now set up as an authorized user at {{brokerName}}.',
      cta: 'Proceed to {{brokerCode}}',
    },
  },
  impersonate: {
    caution:
      'Caution! You are now Impersonating {{name}}. After you are done, please log out.',
    logout: 'Log Out',
  },
  notFound: {
    text: "We're sorry but we couldn't find the page you were looking for.",
    goBack: 'Go back to homepage',
  },
  errorPage: {
    text:
      "We're sorry, an internal server error came up. Please be patient or try again later.",
    goBack: 'Go back to homepage',
    logout: 'Log Out',
  },
  makeoverBanner: {
    beta: {
      title: 'Welcome to the new {{brokerCode}} experience.',
      text: 'All trades here are REAL. Please send feedback to',
    },
    qa: {
      title:
        '{{brokerCode}} is getting a makeover. Try the new experience now.',
      link:
        'Click to open contoso-registration-qa.bullioninternational.info in a new tab and log in.',
      text: 'Testing Environment: All Transactions Simulated',
    },
    equityIra:
      'Due to extended maintenance, trading will be unavailable from 7:00am-9:00pm EST on Saturday, August 14th.',
  },
  maintenance: {
    title: 'Website under maintenance',
    text:
      'Our website is undergoing scheduled maintenance.\nWe should be back shortly. Thank you for your patience.',
  },
  twoFa: {
    loginSetup: {
      chooseMethod: {
        title: 'Secure your account with Two-Factor Authentication (2FA)',
        description:
          'Keep your account secure by entering a verification code when you login from an unrecognized device or take certain actions. Please choose your preferred method to receive those codes.',
        email: {
          title: 'Email',
          description:
            'Use the email address associated with your user account to receive codes.',
        },
        phone: {
          title: 'Phone (Text or Call)',
          description:
            'Provide a phone number to receive a code by automated text message or phone call.',
        },
        authenticator: {
          title: 'Authenticator Application',
          description:
            'Use an authenticator app on your mobile device to generate codes.',
        },
      },
      phone: {
        title: 'Two-Factor Authentication (2FA) Setup',
        subtitle: 'Authentication Phone Number',
        note:
          'Please select a phone number and a method for delivery of your verification code. You can choose to get your code by either a text message (SMS) or automated voice call.',
        receiveType: {
          label: 'Select how to receive your code:',
          message: {
            label: 'Text Message',
            note: 'Text or data rates may apply',
          },
          phoneCall: {
            label: 'Phone Call',
          },
        },
        phoneList: {
          label: 'Select a Phone Number:',
          addNewPhone: 'Add a Phone Number',
          addNewPhoneInfo: 'All carriers and networks supported.',
          addNewPhoneWarning: 'Max 2 phones per account',
          addNewPhoneDetails:
            'Please enter a trusted phone number where you can receive verification phones either by text message (SMS) or automated voice call.',
          cancelAddingNewPhone: 'x Cancel / select from existing phones',
          maxPhones:
            'Max 2 phone numbers per user. To use a new number, delete one of the existing.',
          addNewPhoneNumberSuccess: 'Phone number has been added successfully!',
        },
      },
      codeVerification: {
        email: {
          title: 'Two-Factor Authentication (2FA) Setup',
          subtitle: 'Email Authentication',
          sentText:
            'A code was sent to your email {{email}}.  When you receive it, please enter your code below.',
        },
        phone: {
          title: 'Two-Factor Authentication (2FA) Setup',
          subtitle: 'Phone (Text or Call)',
          sentText:
            'A code was sent to {{phoneNumber}}. When you receive it, please enter your code below.',
        },
      },
      authenticatior: {
        title: 'Two-Factor Authentication (2FA) Setup',
        subtitle: 'Authentication Application',
        instruction:
          'Open your authenticator app and scan this QR code or enter the setup code. The app will generate a 6-digit code which you can enter in the field below.',
        manual: 'Or enter the following code manually:',
        somethingWentWrong:
          'Something went wrong while trying to fetch the data.',
        tryAgain: 'Please try again',
      },
      success: {
        title: 'Setup successful!',
        note:
          'You have successfully setup your Two-Factor Authentication to sign in to {{brokerName}}!',
        proceed: 'Proceed to {{brokerCode}}',
      },
    },
    loginVerify: {
      title: 'Please verify your sign in',
      havingTrouble: 'Having trouble? We can help.',
      sendCode: 'Send code',
      rememberMe: 'Remember me on this device',
      codeExpires: 'Codes expire after 10 minutes',
      codeExpiresEmail: 'Codes expire after {{emailCodeDuration}} minutes',
      note: {
        authenticator:
          'For your security, we need to verify your identity.  Please enter the verification code generated by your authenticator application.',
        email:
          'For your security, we need to verify your identity. A code was sent your email {{email}}',
        phone:
          'A code was sent to {{phoneNumber}}. When you receive it, please enter your code below.',
      },
      phone: {
        note:
          "For your security, we need to verify your identity. We'll send you an authorization code to: {{phoneNumber}}",
        subnote:
          'You are consenting to be contacted at the selected phone number for the purpose of receiving an authorization code. Wireless and text message fees may apply from your carrier.',
        selectText:
          'You can receive this code through text or voice message. How would you like to receive it?',
        method: {
          select: 'Select how to receive your code:',
          textMessage: {
            label: 'Text Message',
            note: 'Text or data rates may apply',
          },
          phoneCall: {
            label: 'Phone Call',
          },
        },
      },
      trouble: {
        havingTrouble: 'Having trouble? We can help.',
        instructions:
          'Please contact support to assist with an alternate 2FA method, confirmation of your identification will be required.',
        toll: {
          free: 'Toll free:',
          international: 'Toll/International:',
        },
        title: 'Temporary code access',
        note:
          'Having trouble getting your code to work? We can help; just give us a call.',

        closed: {
          worktime: {
            note: 'Phones are open:',
            weekdays: '- Weekdays: {{time}}',
            weekends: '- Weekends: {{time}}',
          },
        },
      },
    },
    profileSettings: {
      title: 'Two-Factor Authentication (2FA) Setup',
      card: {
        button: {
          setup: 'Set up primary method',
          change: 'Change 2fa method',
          remove: 'Remove 2FA',
        },
        description:
          'Two-factor authentication or 2FA, (along with similar terms) is an electronic authentication method in which a device user is granted access to a website or application only after successfully presenting two or more pieces of evidence',
      },
      chooseMethod: {
        title: 'Choose Verification Method',
        phone: {
          title: 'Phone (Text or Call)',
          description:
            'Provide a phone number to receive a code by automated text message or phone call.',
        },
        email: {
          title: 'Email',
          description:
            'Use the email address associated with your user account to receive codes.',
        },
        authentication: {
          title: 'Authenticator Application',
          description:
            'Use an authenticator app on your mobile device to generate codes.',
        },
      },
      authentication: {
        title: 'Register {{brokerCode}}',
        scanQr: 'Open the Authenticator app and scan this QR code.',
        enterManually: 'Or enter the following code manually:',
        enterCodeHere:
          "After you've scanned in the app will generate a code. Please enter the code here.",
      },
      phone: {
        title: 'Authentication Phone Number',
        note:
          'Please select a phone number and a method for delivery of your verification code. You can choose to get your code by either a text message (SMS) or automated voice call.',
      },
      verify: {
        phone: {
          title: 'Phone Number Verification',
          note:
            'A [voice/text] message was sent to {{phoneNumber}}. When you receive it, please enter your code below.',
        },
        email: {
          title: 'Email Verification',
          note:
            'A code was sent your email {{email}} When you receive it, please enter your code below.',
        },
      },
      success: {
        authentication:
          'You have successfully set up your authenticator application to sign in to {{brokerName}}!',
        email:
          'You have successfully setup your Email Two-Factor Authentication',
        phone:
          'You have successfully setup your Phone Two-Factor Authentication',
      },
      delete: {
        title: 'Remove Two-Factor Authentication (2FA)',
        confirmation:
          'Are you sure you want to remove  Two-Factor Authentication?',
        success: 'Two-Factor Authentication has been successfully removed.',
        error: 'Something went wrong. Please, try again',
      },
      error: {
        message:
          'For your security, 2FA changes are temporarily blocked.  Please try again later.',
      },
    },
  },
  portfolioSummary: {
    viewByVault: 'View by Vault',
    viewByProduct: 'View by Product',
    viewByMetal: 'View by Metal',
    viewByValue: 'View by Value',
    viewByPercentage: 'View as Percentage',
    viewByType: 'View by Type',
  },
  creditCard: {
    addACreditOrDebitCard: 'Add Credit Or Debit Card',
    enterCardDetails: 'Enter card details',
    enterBillingAddress: 'Enter billing address',
    saveThisDetails: 'Save these details',
    saveThisAddress: 'Save this address',
    successfulMessage: 'Your card has been added successfully.',
    unsuccessfulMessage: 'Failed to add credit card.',
    addNewAddress: '+ Add new Billing Address',
    cancelAddingNewAddress: 'x Cancel / select from existing addresses',
    fields: {
      cardNumber: 'Card number',
      nameOnCard: 'Name on card (as it appears on card)',
      expires: 'Expires',
      securityCode: 'Security code',
      country: 'Country',
      billingAddress: 'Billing Address',
      streetAddress: 'Street Address',
      streetAddress2: 'Address Line 2',
      city: 'City',
      stateProvince: 'State/Province',
      postalCode: 'Postal Code',
    },
    nextStepText: 'Next step: Enter a billing address for this card',
    stepNumber: 'Step {{currentStepNumber}} of {{numberOfSteps}}',
  },
  mailIn: {
    modalTitle: 'Start a mail-in storage request',
    requestSubmitted: 'Request submitted',
    successfulMessage:
      'Thank you for your request. We will respond with mailing instructions within a few business days. You will be responsible only for the cost of shipping.',
    note:
      'We will respond with mailing instructions within a few business days.',
    description:
      'Please describe the metals you would like to send into storage',
    productDescription: 'Product description',
    quantity: 'Quantity',
    addAnotherProduct: 'Add another product',
    ctaLabel: 'Send request',
  },
  quickRegistration: {
    title: 'Create new user',
    description:
      'Enter your information below as the first authorized user on the new account',
    personalInformation: {
      title: 'Personal information',
      description: 'Please provide your full legal name.',
    },
    email: {
      title: 'Email',
      description: 'Please provide your email.',
    },
    password: {
      title: 'Set up password for account access',
      description: 'Please set a password to secure your account.',
    },
    labels: {
      firstName: 'First Name*',
      lastName: 'Last Name*',
      middleName: 'Middle Initial',
      suffix: 'Suffix',
      email: 'Email Address*',
      password: 'Password*',
    },
    modal: {
      titleCompleted: 'Registration completed',
      titleError: 'Registration failed',
      text:
        'Your account has been created successfully. Please log in to continue.',
    },
  },
  fields: {
    emailOrUsername: 'Email address or username:',
    errors: {
      validation: {
        emailRequired: 'Email address is required.',
        emailFormat: 'Invalid email address format.',
        firstName: 'First Name is a required field.',
        lastName: 'Last Name is a required field.',
        middleName: 'Maximum length of the Middle Name is 10.',
        name: 'Name is a required field.',
        phoneNumber: 'Phone Number is a required field.',
        invalidPhoneNumber: 'Phone Number is invalid.',
        password: 'Password is a required field.',
        passwordDifferent: 'Password must not be the same as email.',
        passwordStrength:
          'Must be at least 8 characters long. Must contain at least three characters out of the following, one lower case letter, one upper case letter, one digit and one non-alphanumeric character.',
      },
    },
  },
  nagDashboard: {
    table: {
      title: 'Quick Start Guide',
      uploadButton: 'Upload documents',
      numOfUploaded: '{{num}} of {{length}} uploaded',
      dismissDashboard: 'x Dismiss',
      buttonText: {
        PassportFront: 'Upload Passport Front',
        PassportBack: 'Upload Passport Back',
        DriverLicenseOrStateIssuedIDCardFront:
          'Upload Drivers license or other government ID Front',
        DriverLicenseOrStateIssuedIDCardBack:
          'Upload Drivers license or other government ID Back',
        DriverLicenseOrPassportFront: 'Upload Driver license or passport Front',
        DriverLicenseOrPassportBack: 'Upload Driver license or passport Back',
        UtilityBill: 'Upload Utility Bill',
        UtilityBillFront: 'Upload Utility Bill Front',
        UtilityBillBack: 'Upload Utility Bill Back',
        BankStatement: 'Upload Bank Statement',
        BankStatementFront: 'Upload Bank Statement Front',
        BankStatementBack: 'Upload Bank Statement Back',
        SocialSecurityOrMedicareCard: 'Upload Social security or medicare card',
        SocialSecurityOrMedicareCardFront:
          'Upload Social security or medicare card Front',
        SocialSecurityOrMedicareCardBack:
          'Upload Social security or medicare card Back',
        VoidedCheck: 'Upload Voided Check',
        VoidedCheckFront: 'Upload Voided Check Front',
        VoidedCheckBack: 'Upload Voided Check Back',
      },
      reupload: 'Re-upload requested. Please see your email for details.',
      documentTitle: {
        PassportFront: 'Passport Front',
        PassportBack: 'Passport Back',
        DriverLicenseOrStateIssuedIDCardFront:
          'Drivers license or other government ID Front',
        DriverLicenseOrStateIssuedIDCardBack:
          'Drivers license or other government ID Back',
        DriverLicenseOrPassportFront: 'Driver license or passport Front',
        DriverLicenseOrPassportBack: 'Driver license or passport Back',
        UtilityBill: 'Utility Bill',
        UtilityBillFront: 'Utility Bill Front',
        UtilityBillBack: 'Utility Bill Back',
        BankStatement: 'Bank Statement',
        BankStatementFront: 'Bank Statement Front',
        BankStatementBack: 'Bank Statement Back',
        SocialSecurityOrMedicareCard: 'Social security or medicare card',
        SocialSecurityOrMedicareCardFront:
          'Social security or medicare card Front',
        SocialSecurityOrMedicareCardBack:
          'Social security or medicare card Back',
        VoidedCheck: 'Voided Check',
        VoidedCheckFront: 'Voided Check Front',
        VoidedCheckBack: 'Voided Check Back',
      },
    },
    verifyAccountBanner: {
      status: 'Requires attention',
    },
    modal: {
      title: 'Upload documents for account verification',
    },
    takeAdvantageOfSecureVaultStorage:
      'Take advantage of secure vault storage and the best prices we have to offer. ',
    browseMostPopularMetalInvestments:
      'Browse our most popular metal investments.',
    openAccountStep: {
      nonVerifiedOpenAccountStep: {
        title: 'Open your account',
        description:
          'Welcome to {{brokerName}}. Your account is secured and you can now take advantage of delivery.',
        status: 'Completed',
      },
      verifiedOpenAccountStep: {
        title: 'Open and verify your account',
        description:
          'Congratulations, you’ve got a verified account.  All features are now available.',
        status: 'Completed',
      },
    },
  },
  basicAccountRestrictions: {
    deposit: {
      title: 'Make a deposit',
      description:
        'Your  account details are not confirmed; for security you cannot deposit or withdraw from your cash balance. Please verify your information to unlock all features.',
    },
    withdraw: {
      title: 'Withdraw balance',
      description:
        'Your  account details are not confirmed; for security you cannot deposit or withdraw from your cash balance. Please verify your information to unlock all features.',
    },
    buy: {
      title: 'Buy for storage',
      description:
        'You have a basic account, which cannot take advantage of our secure, independent storage. Please verify all informations to unlock all features.',
    },
    sell: {
      title: 'Sell from possession',
      description:
        'You have a basic account, which cannot take advantage of our secure, independent storage. Please verify all informations to unlock all features.',
    },
    twoFa: {
      title: 'Two-Factor Authentication (2FA) Setup',
      description:
        'To take advantage of two-factor authentication security, please upgrade to a verified account.',
    },
    twoFaPending: {
      title: 'Two-Factor Authentication (2FA) Setup',
      description:
        'You need an approved account in order to set up two factor authentication.',
    },
    verifyAccountBuy: {
      title: 'Verify account to use storage',
      description:
        'You have a basic account, which cannot take advantage of our secure, independent storage. Please verify all information to unlock all features.',
    },
    verifyAccountSell: {
      title: 'Verify your account',
      description:
        'You have a basic account and can only order for delivery. Verify your account to unlock all features like vault storage.',
    },
  },
  lockedPrice: {
    disclaimerInfo:
      'This product is a variable weight product. Additional charges may apply. ',
    disclaimerInfoLink: 'Learn more.',
    disclaimerInfoDetails:
      "This product is a variable weight product, meaning that the final weight and therefore price of the product is not known until traded on our dealer network. The weight of each item ordered could vary by plus or minus 20 ounces from the weight shown.\n\nWhen you place your order, we will charge your payment method assuming the final weight will be equal to the weight shown, and issue a refund to your account balance if the final weight is less. Your payment method will be charged a second time if the final weight is more.\n\nAfter purchase, if you elect to sell the product, it'll be sold at the exact final weight.",
    deliveryFeesError:
      'There was an error refetching delivery fees. Please retry.',
  },
  requestForQuote: {
    quoteExpired:
      'Your order quote has expired. Prices have now refreshed, so please review and place your order again.',
  },
};
