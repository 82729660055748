import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import EmailField from '../../InputFields/EmailField';
import { submitNewAccountUser } from '../../../store/actions/account/accountActions';
import { getCurrentAccount } from '../../../store/selectors/accountSelectors';
import { selectAuthUser } from '../../../store/selectors/userSelectors';
import {
  userStateTypeByIndex,
  ACC_TYPE_INACTIVE,
  userPermissionTypes,
  USER_ROLE_TYPES,
  USER_ROLE_TYPES_LABELS,
} from '../../../util/enum/api/accountTypes';
import SectionLoader from '../../Loader/SectionLoader';
import { inviteNewUserValidationSchema } from '../../../validation/addNewUserValidationSchema';
import ModalBody from '../../Modal/ModalBody';
import ModalButtons from '../../Modal/ModalButtons';
import Label from '../../Notes/Label';
import Paragraph from '../../Paragraph/Paragraph';
import SingleColumnList from '../../TwoColumnList/SingleColumnList';
import TypeList from '../../TypeList/TypeList';
import RoundedRadio from '../../InputFields/RoundedRadio';

const AddNewUser = ({ handleNext, setError, handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const account = useSelector(getCurrentAccount);
  const user = useSelector(selectAuthUser);
  const [isLoading, setIsLoading] = useState(false);

  const roleTypes = Object.values(USER_ROLE_TYPES)?.map((roleType) =>
    roleType.toString(),
  );

  const onFormSubmit = ({ email, permissionOption }) => {
    dispatch(
      submitNewAccountUser({
        accountUid: account.AccountUid,
        data: {
          RoleType: permissionOption,
          UserUid: user.UserUid,
          Email: email,
          FirstName: null,
          MiddleName: null,
          LastName: null,
          SuffixType: null,
          StateType: userStateTypeByIndex[ACC_TYPE_INACTIVE],
        },
        setError,
        setIsLoading,
        handleNext,
      }),
    );
  };

  return (
    <SectionLoader isLoading={isLoading}>
      <ModalBody>
        <Formik
          initialValues={{
            email: '',
            permissionOption: userPermissionTypes[0],
          }}
          onSubmit={onFormSubmit}
          validationSchema={inviteNewUserValidationSchema}
        >
          {({ values }) => (
            <Form>
              <Field label="Email:" name="email" component={EmailField} />
              <Label
                text={t('user.permissions.label')}
                marginBottom={8}
                marginTop={32}
              />
              <TypeList>
                {!!roleTypes?.length &&
                  roleTypes.map((type) => (
                    <Field
                      key={type}
                      id={type}
                      type="radio"
                      name="permissionOption"
                      component={RoundedRadio}
                      value={type}
                      selected={values.permissionOption === type}
                    >
                      <SingleColumnList>
                        <Label
                          text={t(
                            `user.permissions.${USER_ROLE_TYPES_LABELS[type]}.label`,
                          )}
                          marginBottom={8}
                        />
                        <Paragraph>
                          {t(
                            `user.permissions.${USER_ROLE_TYPES_LABELS[type]}.description`,
                          )}
                        </Paragraph>
                      </SingleColumnList>
                    </Field>
                  ))}
              </TypeList>

              <ModalButtons
                marginTop
                isHorizontal
                secondaryButtonProps={{
                  label: t('common.cancel'),
                  onClick: handleClose,
                }}
                primaryButtonProps={{
                  label: t('common.invite'),
                  type: 'submit',
                }}
              />
            </Form>
          )}
        </Formik>
      </ModalBody>
    </SectionLoader>
  );
};

AddNewUser.propTypes = {
  setError: PropTypes.func,
  handleClose: PropTypes.func,
  handleNext: PropTypes.func,
};

export default AddNewUser;
