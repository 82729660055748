import {
  createFetchType,
  createLoadingType,
  createSubmitType,
} from '../actionHelpers';

// get storage fee amount
const STORAGE_FEE_AMOUNT_SCOPE = 'STORAGE FEE AMOUNT';
export const FETCH_STORAGE_FEE_AMOUNT = createFetchType(
  STORAGE_FEE_AMOUNT_SCOPE,
);

// pay storage fee
const CARD_PAY_STORAGE_FEE = 'CARD PAY STORAGE FEE';
export const SUBMIT_CARD_PAY_STORAGE_FEE = createSubmitType(
  CARD_PAY_STORAGE_FEE,
);
export const SUBMIT_CARD_PAY_STORAGE_FEE_LOADING = createLoadingType(
  CARD_PAY_STORAGE_FEE,
);

// change auto-pay settings
const AUTO_PAY_SETTINGS_SCOPE = 'AUTO_PAY_SETTINGS';
export const CHANGE_AUTO_PAY_SETTINGS = createSubmitType(
  AUTO_PAY_SETTINGS_SCOPE,
);
export const CHANGE_AUTO_PAY_SETTINGS_LOADING = createLoadingType(
  AUTO_PAY_SETTINGS_SCOPE,
);
