import React from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, ResponsiveContainer, Cell } from 'recharts';
import { v4 as uuid } from 'uuid';
import { formatNumber } from '../../util/helpers/numeralHelpers';
import themeColors from '../../assets/styles/themeColors';

const COLORS = ['#003246', themeColors.colorPrimary, '#4E7A8C', '#808285'];

const CustomPieChart = ({ data, totalValuation, isOuncesView }) => (
  <ResponsiveContainer width="100%" height="100%">
    <PieChart>
      <Pie
        isAnimationActive={false}
        outerRadius={80}
        cy="50%"
        cx="50%"
        data={data}
        dataKey="value"
        label={(data) =>
          isOuncesView
            ? `${formatNumber(parseFloat(data.value).toFixed(2))} oz`
            : `${parseFloat((data.value / totalValuation) * 100).toFixed(2)}%`
        }
        fontSize={14}
        fontWeight="bold"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${[uuid()]}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  </ResponsiveContainer>
);

CustomPieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.shape({
        value: PropTypes.number,
      }),
    }),
  ),
  viewBy: PropTypes.shape({ value: PropTypes.string }),
  totalValuation: PropTypes.number,
  isOuncesView: PropTypes.bool,
};

export default CustomPieChart;
