import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash.isequal';

import startCase from 'lodash.startcase';
import { upperFirst } from 'lodash';
import ProductItem from './ProductItem';

import { selectMetalStreamProduct } from '../../../store/selectors/metalStreamSelectors';
import { fetchMetalStreamProduct } from '../../../store/actions/metalStream/metalStreamActions';
import { selectIsLoadingByActionType } from '../../../store/selectors/loadingSelectors';
import { METAL_STREAM_PRODUCT_LOADING } from '../../../store/actions/metalStream/metalStreamActionConstants';
import config from '../../../config';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import { productLocationTypes } from '../../../util/enum/api/productTypes';
import { formatMoneyNumeral } from '../../../util/helpers/numeralHelpers';
import useGtmHook from '../../../util/hooks/useGtmHook';
import { selectSettings } from '../../../store/selectors/settingsSelectors';
import ModalBody from '../../Modal/ModalBody';
import ModalTitle from '../../Modal/ModalTitle';
import Paragraph from '../../Paragraph/Paragraph';
import TypeList from '../../TypeList/TypeList';
import ModalButtons from '../../Modal/ModalButtons';
import { getCurrentAccount } from '../../../store/selectors/accountSelectors';
import SectionLoader from '../../Loader/SectionLoader';

const productConfig = {
  gold: {
    configKey: 'GoldProductConfiguration',
    title: 'goldToBuy',
  },
  silver: {
    configKey: 'SilverProductConfiguration',
    title: 'silverToBuy',
  },
};

const SelectProduct = ({
  handleBack,
  handleNext,
  metalType,
  defaultValues,
  wizardData,
  setWizardData,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { gtmDialogScreenView } = useGtmHook();
  const { RootUrl } = useSelector(selectSettings);
  const { AccountUid } = useSelector(getCurrentAccount);
  const metalStreamProducts = useSelector(
    selectMetalStreamProduct(metalType),
    isEqual,
  );

  const isLoading = useSelector(
    selectIsLoadingByActionType(METAL_STREAM_PRODUCT_LOADING),
  );

  const { title, configKey } = productConfig
    ? productConfig[`${metalType}`]
    : null;

  const configuration = defaultValues ? defaultValues[`${configKey}`] : null;

  const handleSelectProduct = (product) => {
    if (metalType === 'gold') {
      setWizardData({
        GoldProductConfiguration: {
          ...wizardData?.GoldProductConfiguration,
          SymbolCode: product?.SymbolCode,
        },
      });
    } else {
      setWizardData({
        SilverProductConfiguration: {
          ...wizardData?.SilverProductConfiguration,
          SymbolCode: product?.SymbolCode,
        },
      });
    }
  };

  useEffect(() => {
    dispatch(
      fetchMetalStreamProduct({
        accountUid: AccountUid,
        side: 'buy',
        symbols: configuration ? [configuration.SymbolCode] : null,
        metalType,
        productType: 'fractional',
      }),
    );
  }, [AccountUid, metalType]); // eslint-disable-line

  useEffect(() => {
    if (metalType) {
      gtmDialogScreenView({
        title: `MetalStream Set Up ${upperFirst(metalType)} to Buy`,
      });
    }
  }, [metalType]); //eslint-disable-line

  const getSelectedProduct = () => {
    if (metalType === 'gold') {
      return (
        wizardData?.GoldProductConfiguration?.SymbolCode ||
        defaultValues?.GoldProductConfiguration?.SymbolCode
      );
    }

    if (metalType === 'silver') {
      return (
        wizardData?.SilverProductConfiguration?.SymbolCode ||
        defaultValues?.SilverProductConfiguration?.SymbolCode
      );
    }
  };

  const onNextStep = () => {
    const selectedProduct = () => {
      const result = metalStreamProducts.find((product) =>
        product.Locations.some(
          (location) => location.SymbolCode === getSelectedProduct(),
        ),
      );

      return result;
    };

    if (metalType === 'gold') {
      setWizardData({
        GoldProductConfiguration: {
          ...wizardData?.GoldProductConfiguration,
          SymbolCode: getSelectedProduct(),
        },
        SelectedGoldProduct: {
          ...selectedProduct(),
          SymbolCode: getSelectedProduct(),
        },
      });
    } else {
      setWizardData({
        SilverProductConfiguration: {
          ...wizardData?.SilverProductConfiguration,
          SymbolCode: getSelectedProduct(),
        },
        SelectedSilverProduct: {
          ...selectedProduct(),
          SymbolCode: getSelectedProduct(),
        },
      });
    }

    handleNext();
  };

  return (
    <SectionLoader isLoading={isLoading}>
      <ModalBody>
        <ModalTitle>{t(`metalStream.${title}`)}</ModalTitle>
        <Paragraph marginBottom={24}>
          {t('metalStream.chooseInvestment', {
            metalType: t(`metalStream.${metalType}`),
          })}
        </Paragraph>
        <TypeList>
          {metalStreamProducts.map((product) => {
            const imgSrc = config.algoliaSearch.imageCode
              .replace('{product_code}', product.ProductCode)
              .replace('{base_url}', RootUrl);

            return product.Locations.map(
              ({ LocationType, PricePerOz, SymbolCode }) => {
                const productName = `${product.ProductCaption} - ${startCase(
                  parseEnumType(productLocationTypes, LocationType),
                )}`;

                const pricePerOz = formatMoneyNumeral(PricePerOz);
                // const pricePerUnit = formatMoneyNumeral(PricePerUnit);
                const productPrice = `${pricePerOz}/oz`;

                return (
                  <ProductItem
                    key={SymbolCode}
                    imgSrc={imgSrc}
                    selected={getSelectedProduct() === SymbolCode}
                    product={{ ...product, SymbolCode }}
                    productName={productName}
                    alt={product.ProductCode}
                    productPrice={productPrice}
                    name={metalType}
                    selectedProduct={getSelectedProduct() === SymbolCode}
                    handleSelectProduct={handleSelectProduct}
                  />
                );
              },
            );
          })}
        </TypeList>
        <ModalButtons
          isHorizontal
          marginTop
          secondaryButtonProps={{ onClick: handleBack }}
          primaryButtonProps={{
            onClick: onNextStep,
            disabled: !getSelectedProduct(),
          }}
        />
      </ModalBody>
    </SectionLoader>
  );
};

SelectProduct.propTypes = {
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  metalType: PropTypes.string,
  selectedProduct: PropTypes.shape({
    ProductCode: PropTypes.string,
    ProductCaption: PropTypes.string,
    SymbolCode: PropTypes.string,
    Locations: PropTypes.arrayOf(
      PropTypes.shape({
        LocationType: PropTypes.number,
        PricePerOz: PropTypes.number,
        PricePerUnit: PropTypes.number,
      }),
    ),
  }),
  metalStreamSettings: PropTypes.shape({}),
  defaultValues: PropTypes.shape({
    GoldProductConfiguration: PropTypes.shape({
      SymbolCode: PropTypes.string,
    }),
    SilverProductConfiguration: PropTypes.shape({
      SymbolCode: PropTypes.string,
    }),
  }),
  wizardData: PropTypes.shape({
    GoldProductConfiguration: PropTypes.shape({
      SymbolCode: PropTypes.string,
    }),
    SilverProductConfiguration: PropTypes.shape({
      SymbolCode: PropTypes.string,
    }),
  }),
  setWizardData: PropTypes.func,
};

export default SelectProduct;
