import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import PhoneNumberField from '../../../components/InputFields/PhoneNumberField';
import Note from '../../../components/Notes/Note';
import SectionLoader from '../../../components/Loader/SectionLoader';
import ModalBody from '../../../components/Modal/ModalBody';
import ModalButtons from '../../../components/Modal/ModalButtons';
import { postPhoneNumber } from '../../../store/actions/user/userActions';
import { enteredPhoneNumberValidatorAndFormatter } from '../../../util/helpers/phoneNumberHelpers';
import { selectPortalGatewayISOCodesListToIgnoreAreaCode } from '../../../store/selectors/settingsSelectors';
import { selectAuthUser } from '../../../store/selectors/userSelectors';
import { twoFaPhoneNumberValidationSchema } from '../../../validation/twoFaPhoneValidationSchema';

const TwoFaPhoneAddPhone = ({
  onButtonClick,
  handleNext,
  setError,
  onSuccess,
  onError,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const selectedISOCodesListToIgnoreAreaCode = useSelector(
    selectPortalGatewayISOCodesListToIgnoreAreaCode,
  );
  const user = useSelector(selectAuthUser);
  const { UserUid } = user;

  const handleSubmitPhoneNumber = (values, { setFieldError }) => {
    const { PhoneNumber } = values;

    const isOnlyNumbers =
      PhoneNumber.replace('+', '').match(/^[0-9]+$/) != null;

    if (!isOnlyNumbers && PhoneNumber.length < 15) {
      setFieldError(
        'PhoneNumber',
        t('validationSchema.general.phoneNumberOnlyNumbers'),
      );
    } else {
      const phoneNumberJsonParsed = JSON.parse(
        PhoneNumber.replace('+', '').replace('-', ''),
      );

      if (!isPossiblePhoneNumber(`+${phoneNumberJsonParsed}`)) {
        setFieldError(
          'PhoneNumber',
          t('validationSchema.general.phoneNumberNotValidLength'),
        );
        return;
      }

      if (!isValidPhoneNumber(`+${phoneNumberJsonParsed}`)) {
        setFieldError(
          'PhoneNumber',
          t('validationSchema.general.phoneNumberNotValidFormat'),
        );
        return;
      }

      const phoneNumber = enteredPhoneNumberValidatorAndFormatter(
        phoneNumberJsonParsed,
        setFieldError,
        selectedISOCodesListToIgnoreAreaCode,
      );

      if (!phoneNumber) {
        setFieldError(
          'PhoneNumber',
          t('validationSchema.general.phoneNumberNotValidFormat'),
        );
        return;
      }

      dispatch(
        postPhoneNumber({
          userUid: UserUid,
          data: {
            ...phoneNumber,
          },
          handleNext,
          onButtonClick,
          setIsLoading,
          setError,
          onSuccess,
          onError,
        }),
      );
    }
  };

  return (
    <SectionLoader isLoading={isLoading}>
      <ModalBody>
        <Formik
          onSubmit={handleSubmitPhoneNumber}
          validationSchema={twoFaPhoneNumberValidationSchema}
          initialValues={{
            Iso3CountryCode: '',
            AreaCode: '',
            LocalNumber: '',
          }}
          enableReinitialize
        >
          {({ isValid, setFieldValue }) => (
            <Form>
              <PhoneNumberField
                name="PhoneNumber"
                onPhoneChange={(phoneNum) =>
                  setFieldValue('PhoneNumber', phoneNum)
                }
              />
              <Note
                marginTop={24}
                textAlignCenter
                bold
                text={t('twoFa.loginSetup.phone.phoneList.addNewPhoneDetails')}
              />

              <ModalButtons
                isHorizontal
                marginTop
                primaryButtonProps={{
                  type: 'submit',
                  label: t('common.submit'),
                  disabled: !isValid,
                }}
                secondaryButtonProps={{
                  onClick: onButtonClick,
                  label: t('common.cancel'),
                }}
              />
            </Form>
          )}
        </Formik>
      </ModalBody>
    </SectionLoader>
  );
};

TwoFaPhoneAddPhone.propTypes = {
  onButtonClick: PropTypes.func,
  handleNext: PropTypes.func,
  setError: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  errors: PropTypes.shape({
    PhoneNumber: PropTypes.string,
  }),
};

export default TwoFaPhoneAddPhone;
