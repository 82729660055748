import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import InformationList from '../../../InformationList/InformationList';
import InformationListItem from '../../../InformationList/InformationListItem';
import Paragraph from '../../../Paragraph/Paragraph';
import { formatMailingAddress } from '../../../../util/helpers/addressHelper';

const EntrustWireTab = ({ wireDetails }) => {
  const { t } = useTranslation();

  if (isEmpty(wireDetails)) {
    return null;
  }

  return (
    <div>
      <Paragraph marginBottom={24} fontSize={14}>
        {t('depositWizard.equity.wire.completeFormAtBank')}
      </Paragraph>
      <Paragraph marginBottom={24} fontSize={14}>
        {t('depositWizard.equity.wire.textOneAlt')}
      </Paragraph>
      <InformationList>
        <InformationListItem
          label={t('depositWizard.equity.bank')}
          value={
            <span>
              {formatMailingAddress(
                wireDetails.BankAddress,
                wireDetails.BankName,
              )}
            </span>
          }
        />
        <InformationListItem
          label={t('depositWizard.equity.routingAba')}
          value={wireDetails.RoutingAbaNumber}
        />
        <InformationListItem
          label={t('depositWizard.equity.accountNumber')}
          value={wireDetails.AccountNumber}
        />
        <InformationListItem
          label={t('depositWizard.equity.beneficiary')}
          value={wireDetails.PayableTo}
        />
        <InformationListItem
          label={t('depositWizard.equity.memo')}
          value={wireDetails.Memo}
        />
      </InformationList>
    </div>
  );
};

EntrustWireTab.propTypes = {
  wireDetails: PropTypes.shape({
    BankName: PropTypes.string,
    BankAddress: PropTypes.shape({
      AddressLine: PropTypes.string,
      City: PropTypes.string,
      PostalCode: PropTypes.string,
      StateRegion: PropTypes.string,
    }),
    RoutingAbaNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    AccountNumber: PropTypes.string,
    PayableTo: PropTypes.string,
    Memo: PropTypes.string,
  }),
};

export default EntrustWireTab;
