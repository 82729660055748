import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import i18next from 'i18next';
import jwt from 'jsonwebtoken';
import history from '../utils/history';
import {
  AUTHENTICATE_USER,
  LOGIN_USER_FETCH,
  LOGIN_USERNAMES_FETCH,
  LOGOUT_USER,
  SECURITY_QUESTION_ANSWER_UPDATE,
  REFRESH_TOKEN,
  GENERATE_TOKEN,
  REGISTER_USER_FETCH,
} from '../actions/login/loginActionConstants';
import {
  attemptLogin,
  getUsernames,
  logoutUserRequest,
  refreshTokenRequest,
  updateSecurityAnswer,
  generateTokenRequest,
  attemptRegistration,
} from '../../request/loginRequest';
import {
  fetchUserError,
  fetchUsernamesError,
  fetchUsernamesSuccess,
  fetchUserSuccess,
  registerUserError,
  registerUserSuccess,
  resetLoginState,
  updateSecurityQuestionAnswerError,
  updateUserToken,
} from '../actions/login/loginActions';
import { resetMyHoldingState } from '../actions/portfolio/portfolioActions';
import { EMAIL_LOGIN_PAGE } from '../../constants/pages';
import { setUser } from '../actions/user/userActions';
import {
  addHeaderCookie,
  addHeaderToken,
  removeHeaderToken,
} from '../../request';
import { closeWizardContent } from '../../util/helpers/wizardHelpers';
import {
  IMPERSONATE_USER_UID,
  REGISTRATION_USER_UID,
  LOGIN_EMAIL,
  USERNAMES,
  USERNAME,
} from '../../constants/sessionStorage';
import {
  JWT_REFRESH_TOKEN,
  JWT_TOKEN,
  REFRESH_TOKEN_CONST,
  TWO_FA,
} from '../../constants/localStorage';
import {
  authScopeClearHelper,
  authScopeStringGetHelper,
  authScopeRemoveHelper,
  authScopeSetHelper,
} from '../../util/helpers/authScopeHelpers';
import { rejectErrorCodeHelper } from '../../util/helpers/rejectErrorCodeHelper';
import {
  removeFromSessionStorage,
  storeInSessionStorage,
} from '../../util/helpers/sessionStorageHelper';
import { closeTwoFaLoginContent } from '../../util/helpers/twoFALoginSetupHelper';

function* fetchUsernames(payload) {
  const {
    email,
    setFieldError,
    setIsLoading,
    onSuccess,
    formUserInvite,
  } = payload;
  try {
    const response = yield call(getUsernames, payload);

    if (response && response.data && !!response.data.length) {
      storeInSessionStorage(LOGIN_EMAIL, email);
      yield call(storeInSessionStorage, USERNAMES, response.data);
      if (onSuccess) {
        yield call(onSuccess);
      }
      return yield put(fetchUsernamesSuccess(response.data));
    }
    removeFromSessionStorage(LOGIN_EMAIL);
    return setFieldError('email', i18next.t('apiErrors.UsernameDoesNotExist'));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    if (errorMessage) {
      if (formUserInvite && onSuccess) {
        yield call(onSuccess);
      } else {
        yield call(setFieldError, 'email', errorMessage);
        yield put(fetchUsernamesError(errorMessage));
      }
    }
  } finally {
    if (setIsLoading) {
      yield call(setIsLoading, false);
    }
  }
}

function* fetchUser({ payload }) {
  try {
    yield call(storeInSessionStorage, USERNAME, payload.Username);
    const { data } = yield call(attemptLogin, payload);
    if (data.JwtToken) {
      const user = jwt.decode(data.JwtToken);
      yield call(authScopeSetHelper, JWT_TOKEN, data.JwtToken);
      yield call(authScopeSetHelper, JWT_REFRESH_TOKEN, data.JwtRefreshToken);
      yield call(authScopeSetHelper, REFRESH_TOKEN_CONST, data.RefreshToken);
      yield call(addHeaderToken, data.JwtToken);
      yield put(setUser(user));
      if (payload?.onSuccess) {
        yield call(payload.onSuccess);
      }
    }
    if (data.TwoFactorAuthenticationResponse) {
      yield call(
        authScopeSetHelper,
        TWO_FA,
        data.TwoFactorAuthenticationResponse,
      );
    }
    yield put(fetchUserSuccess(data));
  } catch (e) {
    if (e.response && e.response.data) {
      if (e.response && e.response.status === 412) {
        if (payload.handleConfirmSecurityQuestion) {
          payload.handleConfirmSecurityQuestion();
        }
        if (e.response.data.TwoFactorAuthenticationResponse) {
          yield call(
            authScopeSetHelper,
            TWO_FA,
            e.response.data.TwoFactorAuthenticationResponse,
          );
        }
        if (payload?.onSuccess) {
          yield call(payload.onSuccess);
        }
        yield put(
          fetchUserSuccess({
            ...e.response.data,
          }),
        );
        return;
      }

      const errorMessage = yield call(rejectErrorCodeHelper, e);
      yield put(fetchUserError(errorMessage));
    }
  }
}

function* updateSecurityQuestionAnswer({ payload }) {
  const {
    isTrustedLocation,
    isInviteUserFlow,
    setIsLoading,
    existingLoginHandleNextClick,
    handleGtm,
    username,
    handleFieldError,
    ...requestData
  } = payload;

  yield put(updateSecurityQuestionAnswerError(''));
  if (setIsLoading) {
    yield call(setIsLoading, true);
  }
  if (isTrustedLocation) {
    if (username) {
      yield call(addHeaderCookie, 'ITLC2FA', true);
    } else {
      yield call(addHeaderCookie, 'ITLC', true);
    }
  }

  try {
    const {
      data: { JwtToken, JwtRefreshToken },
    } = yield call(updateSecurityAnswer, requestData);

    if (JwtToken) {
      yield call(authScopeSetHelper, JWT_TOKEN, JwtToken);
      yield call(authScopeSetHelper, JWT_REFRESH_TOKEN, JwtRefreshToken);
      const user = jwt.decode(JwtToken);
      addHeaderToken(JwtToken);
      yield put(setUser(user));
      if (isInviteUserFlow && existingLoginHandleNextClick) {
        existingLoginHandleNextClick();
      }
    }

    yield put(updateUserToken(JwtToken));
    if (!isInviteUserFlow) {
      yield call(removeFromSessionStorage, USERNAMES);
    }
    if (setIsLoading) {
      yield call(setIsLoading, false);
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(updateSecurityQuestionAnswerError(errorMessage));
    if (handleFieldError) {
      yield call(handleFieldError, errorMessage);
    }
    if (setIsLoading) {
      yield call(setIsLoading, false);
    }
  }
}

function* authenticateUser() {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);

    if (!JwtToken) {
      yield call(history.push, EMAIL_LOGIN_PAGE);
    }

    return yield put(
      fetchUserSuccess({
        JwtToken,
      }),
    );
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchUserError(errorMessage));
    yield call(authScopeRemoveHelper, JWT_TOKEN);
    yield call(authScopeRemoveHelper, JWT_REFRESH_TOKEN);
    yield call(authScopeRemoveHelper, REFRESH_TOKEN_CONST);
  }
}

export function* logoutUser({ payload }) {
  try {
    closeTwoFaLoginContent();
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    const user = jwt.decode(JwtToken);
    if (user) {
      yield call(logoutUserRequest, user.UserUid);
    }

    if (payload && payload?.onSuccessLogout) {
      yield call(payload.onSuccessLogout, payload.email, payload.setFieldError);
    }
  } catch (error) {
    console.log(error); // eslint-disable-line
  } finally {
    closeWizardContent();
    if (payload && payload?.setNoPermissionsModal) {
      yield call(payload?.setNoPermissionsModal, false);
    }
    yield call(authScopeClearHelper);
    yield call(removeHeaderToken);
    yield put(resetMyHoldingState());

    if (payload?.redirectPage) {
      window.location.replace(payload?.redirectPage);
    } else {
      yield put(resetLoginState());
      yield call(history.replace, EMAIL_LOGIN_PAGE);
    }
  }
}

export function* refreshToken({ payload }) {
  try {
    const JwtToken = yield call(authScopeStringGetHelper, JWT_TOKEN);
    const JwtRefreshToken = yield call(
      authScopeStringGetHelper,
      JWT_REFRESH_TOKEN,
    );

    if (JwtToken && JwtRefreshToken) {
      const { data } = yield call(refreshTokenRequest, {
        JwtRefreshToken,
        JwtToken,
      });
      yield call(authScopeSetHelper, JWT_TOKEN, data.JwtToken);
      yield call(authScopeSetHelper, JWT_REFRESH_TOKEN, data.JwtRefreshToken);
      const user = jwt.decode(data.JwtToken);
      addHeaderToken(data.JwtToken);
      yield put(setUser(user));
      yield put(updateUserToken(data.JwtToken));
    }
  } catch (error) {
    yield call(logoutUser);
    console.log(error); // eslint-disable-line
  } finally {
    if (payload?.onFinally) {
      yield call(payload?.onFinally);
    }
  }
}

export function* generateToken({ payload }) {
  try {
    const { data } = yield call(generateTokenRequest, payload.data);
    const { JwtToken, JwtRefreshToken } = data;

    if (JwtToken && JwtRefreshToken) {
      yield call(authScopeSetHelper, JWT_TOKEN, data.JwtToken);
      yield call(authScopeSetHelper, JWT_REFRESH_TOKEN, data.JwtRefreshToken);

      if (payload.impersonate) {
        storeInSessionStorage(IMPERSONATE_USER_UID, payload.accountUid);
      }

      if (payload.registration) {
        storeInSessionStorage(REGISTRATION_USER_UID, payload.accountUid);
      }

      const user = jwt.decode(data.JwtToken);
      addHeaderToken(data.JwtToken);
      if (user) {
        yield put(setUser(user));
      }
      yield put(updateUserToken(data.JwtToken));

      if (payload.onSuccess) {
        yield call(payload.onSuccess);
      }
    }
  } catch (error) {
    yield call(logoutUser);
    console.log(error); // eslint-disable-line
  }
}

function* registerUser({ payload }) {
  try {
    sessionStorage.setItem('Email', payload.Email);
    const { data } = yield call(attemptRegistration, payload);
    if (data.JwtToken) {
      const user = jwt.decode(data.JwtToken);
      yield call(authScopeSetHelper, JWT_TOKEN, data.JwtToken);
      yield call(authScopeSetHelper, JWT_REFRESH_TOKEN, data.JwtRefreshToken);
      yield call(authScopeSetHelper, REFRESH_TOKEN_CONST, data.RefreshToken);
      yield call(addHeaderToken, data.JwtToken);
      yield call(payload.handleGtm);
      if (user) {
        yield put(setUser(user));
      }
    }
    if (data.TwoFactorAuthenticationResponse) {
      yield call(
        authScopeSetHelper,
        TWO_FA,
        data.TwoFactorAuthenticationResponse,
      );
    }
    yield put(fetchUserSuccess(data));
    yield put(registerUserSuccess(data));
    yield call(payload.setHasError, false);
  } catch (e) {
    if (e.response && e.response.data) {
      const errorMessage = yield call(rejectErrorCodeHelper, e);
      yield put(registerUserError(errorMessage));
      yield call(payload.setErrorCode, e?.response?.data?.Errors[0]?.Code);
      yield call(payload.setErrorMessage, errorMessage);
      yield call(payload.setFieldError, 'Email', ' ');
    }
  }
}

export default function* loginSaga() {
  yield all([
    takeLatest(LOGIN_USERNAMES_FETCH, fetchUsernames),
    takeLatest(LOGIN_USER_FETCH, fetchUser),
    takeLatest(SECURITY_QUESTION_ANSWER_UPDATE, updateSecurityQuestionAnswer),
    takeLatest(AUTHENTICATE_USER, authenticateUser),
    takeLatest(LOGOUT_USER, logoutUser),
    takeLatest(REFRESH_TOKEN, refreshToken),
    takeLatest(GENERATE_TOKEN, generateToken),
    takeLatest(REGISTER_USER_FETCH, registerUser),
  ]);
}
