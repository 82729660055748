import { useEffect, useState } from 'react';
import { getCountries } from '../../request/countriesRequest';

const useGetCountries = () => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const triggerCountriesGet = async () => {
      const { data } = await getCountries();
      setCountries(
        data.sort((a, b) => {
          if (a.Caption > b.Caption) {
            return 1;
          }
          if (b.Caption > a.Caption) {
            return -1;
          }
          return 0;
        }),
      );
    };
    triggerCountriesGet();
  }, []);

  return countries;
};

export default useGetCountries;
