import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { EMAIL_LOGIN_PAGE, PORTFOLIO_PAGE } from '../../constants/pages';
import { logoutUser } from '../../store/actions/login/loginActions';
import { selectIsUserAuthenticated } from '../../store/selectors/userSelectors';
import {
  UtilitiesPageButton,
  UtilitiesPageContainer,
  UtilitiesPageContentStyle,
  UtilitiesPageSection,
  UtilitiesPageText,
  UtilitiesPageTitle,
} from './Components';

const ErrorPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isUserAuthenticated = useSelector(selectIsUserAuthenticated);

  return (
    <UtilitiesPageContainer>
      <UtilitiesPageSection>
        <UtilitiesPageContentStyle>
          <UtilitiesPageTitle>500</UtilitiesPageTitle>
          <UtilitiesPageText>{t('errorPage.text')}</UtilitiesPageText>
          <UtilitiesPageButton
            variant="primaryOutlined"
            onClick={() => history.push(PORTFOLIO_PAGE)}
          >
            {t('errorPage.goBack')}
          </UtilitiesPageButton>
          {isUserAuthenticated && (
            <UtilitiesPageButton
              variant="primaryOutlined"
              onClick={() => {
                dispatch(logoutUser());
                history.push(EMAIL_LOGIN_PAGE);
              }}
            >
              {t('errorPage.logout')}
            </UtilitiesPageButton>
          )}
        </UtilitiesPageContentStyle>
      </UtilitiesPageSection>
    </UtilitiesPageContainer>
  );
};

ErrorPage.propTypes = {};

export default ErrorPage;
