import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';

import styled from 'styled-components';
import Button from '../Button/Button';
import FundingSource from './FundingSource';
import MonthlyInvestment from './MonthlyInvestment';
import SelectedInvestment from './SelectedInvestment';

import { selectIsLoadingByActionType } from '../../store/selectors/loadingSelectors';
import { UPDATE_MS_SETTINGS_LOADING } from '../../store/actions/metalStream/metalStreamActionConstants';
import { setWizardContent } from '../../util/helpers/wizardHelpers';
import BlockSectionLoader from '../Loader/BlockSectionLoader';
import {
  METAL_STREAM_EDITING,
  METAL_STREAM_SETTINGS,
  PREVENT_SUPPORT_MODAL,
} from '../../constants/sessionStorage';
import { storeInSessionStorage } from '../../util/helpers/sessionStorageHelper';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';
import { uFlexCenter } from '../../assets/styles/utility';
import config from '../../config';

export const SetMetalStreamTitle = styled.h2`
  font-size: ${pxToRem(24)};
  line-height: 1.33;
  margin-bottom: ${pxToRem(32)};
  font-weight: bold;

  > span {
    color: ${themeColors.colorSuccess};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    text-align: center;
    font-size: ${pxToRemMd(24)};
    margin-bottom: ${pxToRemMd(32)};
  }
`;

export const SetMetalStreamContainer = styled.div`
  padding: ${pxToRem(32)} 0;
  max-width: ${pxToRem(973)};

  ${({ isPaused }) =>
    isPaused &&
    `
    ${SetMetalStreamTitle} {
      > span {
        color: ${themeColors.colorPrimary};
      }
    }
  `};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: ${pxToRem(32)} ${pxToRem(24)};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRem(50)} ${pxToRemMd(24)};
  }
`;

export const SetMetalStreamPauseButton = styled(Button)`
  margin-top: ${pxToRem(40)};
  margin-bottom: ${pxToRem(48)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    display: none;
  }
`;

export const SetMetalStreamPreference = styled.p`
  margin-bottom: ${pxToRem(23)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-bottom: ${pxToRemMd(32)};
  }
`;

export const SetMetalStreamEditButton = styled(Button)`
  margin-top: ${pxToRem(48)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    ${uFlexCenter};
    margin-top: ${pxToRemMd(31)};
  }
`;

const SetupedMetalStream = ({
  msSettings,
  accountUid,
  handleOpenSetup,
  switchActiveState,
  isTestAccount,
  allowAccountChangesPermission,
}) => {
  const { t } = useTranslation();
  const { metalStreamCaption } = config;
  const isSwitchStateLoading = useSelector(
    selectIsLoadingByActionType(UPDATE_MS_SETTINGS_LOADING),
  );

  const hasInvestments = useMemo(
    () =>
      !isEmpty(msSettings) &&
      (msSettings.GoldProductConfiguration ||
        msSettings.SilverProductConfiguration),
    [msSettings],
  );

  const renderMonthlyInvestments = () => {
    if (!hasInvestments) {
      return null;
    }

    return (
      <SelectedInvestment
        gold={msSettings.GoldProductConfiguration}
        silver={msSettings.SilverProductConfiguration}
        accountUid={accountUid}
        amount={msSettings.Amount}
      />
    );
  };

  const renderPageContent = () => {
    if (isSwitchStateLoading) {
      return <BlockSectionLoader isLoading />;
    }

    return (
      <SetMetalStreamContainer isPaused={!msSettings.IsActive}>
        <SetMetalStreamTitle>
          {t('metalStream.status')}
          <span>{!msSettings.IsActive ? 'Paused' : 'Active'}</span>
        </SetMetalStreamTitle>
        <p>
          {t(
            !msSettings.IsActive
              ? 'metalStream.disabledMetalStream'
              : 'metalStream.activeMetalStream',
            {
              metalStream: metalStreamCaption,
            },
          )}
        </p>
        <SetMetalStreamPauseButton
          size="lg"
          variant="primary"
          type="button"
          disabled={!allowAccountChangesPermission || isTestAccount}
          onClick={switchActiveState}
        >
          {!msSettings.IsActive
            ? t('metalStream.restartMetalStream', {
                metalStream: metalStreamCaption,
              })
            : t('metalStream.pauseMetalStream', {
                metalStream: metalStreamCaption,
              })}
        </SetMetalStreamPauseButton>
        <SetMetalStreamPreference>
          {t('metalStream.adjustPreference')}
        </SetMetalStreamPreference>
        <FundingSource
          accountUid={accountUid}
          bankAccountUid={msSettings.BankAccountUid}
          isFromAccountBalance={msSettings.UseAccountBalance}
        />
        <MonthlyInvestment value={msSettings.Amount} />
        {renderMonthlyInvestments()}
        <SetMetalStreamEditButton
          disabled={!allowAccountChangesPermission || isTestAccount}
          size="lg"
          variant="primaryOutlined"
          onClick={() => {
            handleOpenSetup();
            storeInSessionStorage(PREVENT_SUPPORT_MODAL, true);
            setWizardContent(METAL_STREAM_SETTINGS, msSettings);
            setWizardContent(METAL_STREAM_EDITING, true);
          }}
        >
          {t('metalStream.editPreference')}
        </SetMetalStreamEditButton>
      </SetMetalStreamContainer>
    );
  };

  return renderPageContent();
};

SetupedMetalStream.propTypes = {
  msSettings: PropTypes.shape({
    IsActive: PropTypes.bool,
    UseAccountBalance: PropTypes.bool,
    BankAccountUid: PropTypes.string,
    Amount: PropTypes.number,
    GoldProductConfiguration: PropTypes.shape({}),
    SilverProductConfiguration: PropTypes.shape({}),
  }),
  accountUid: PropTypes.string,
  isTestAccount: PropTypes.bool,
  handleOpenSetup: PropTypes.func,
  switchActiveState: PropTypes.func,
  allowAccountChangesPermission: PropTypes.bool,
};

export default SetupedMetalStream;
