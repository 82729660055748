import { SearchBox } from 'react-instantsearch-dom';
import styled from 'styled-components';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';

export const ProductSearch = styled(SearchBox)`
  .ais-SearchBox {
    display: flex;
    justify-content: flex-end;
    margin-bottom: ${pxToRem(24)};
  }

  .ais-SearchBox-input {
    border: none;
    color: ${themeColors.colorLabel};
    font-size: ${pxToRem(16)};
    letter-spacing: 0;
    line-height: 1.5;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    flex-grow: 1;

    &::placeholder {
      color: ${themeColors.colorLabel};
      font-size: ${pxToRem(16)};
    }

    ${mediaBelow(variables.breakpoints.bpXl)} {
      font-size: ${pxToRemMd(16)};

      &::placeholder {
        font-size: ${pxToRemMd(16)};
      }
    }
  }

  .ais-SearchBox-form {
    border: 1px solid ${themeColors.colorInputBorder};
    border-radius: ${variables.borderRadius.borderRadius};
    overflow: hidden;
    padding: 0 ${pxToRem(12)};
    height: ${pxToRem(33)};
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-width: ${pxToRem(340)};
    height: 100%;
  }

  .ais-SearchBox-submit,
  .ais-SearchBox-reset {
    border: none;
    background: transparent;
    outline: none;
    height: ${pxToRem(16)};
  }

  .ais-SearchBox-submitIcon,
  .ais-SearchBox-resetIcon {
    &,
    svg {
      width: ${pxToRem(16)};
      height: ${pxToRem(16)};
      color: ${themeColors.colorIconPrimary};
    }
  }

  .ais-SearchBox-reset {
    margin-left: ${pxToRem(10)};
    cursor: pointer;
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    .ais-SearchBox-submitIcon,
    .ais-SearchBox-resetIcon {
      &,
      svg {
        width: ${pxToRemMd(16)};
        height: ${pxToRemMd(16)};
      }
    }
  }

  ${mediaBelow(variables.breakpoints.bpSm)} {
    .ais-SearchBox-form {
      min-width: 100%;
    }
  }
`;
