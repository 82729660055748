import {
  FETCH_ACCOUNT_USERS,
  FETCH_ACCOUNT_USERS_ERROR,
  FETCH_ACCOUNT_USERS_SUCCESS,
  FETCH_CURRENT_ACCOUNT_USERNAMES,
  FETCH_CURRENT_ACCOUNT_USERNAMES_ERROR,
  FETCH_CURRENT_ACCOUNT_USERNAMES_SUCCESS,
  SET_CURRENT_ACCOUNT,
  FETCH_ACCOUNT_ADDRESSES,
  FETCH_ACCOUNT_ADDRESSES_ERROR,
  FETCH_ACCOUNT_ADDRESSES_SUCCESS,
  USER_PERMISSIONS_FETCH,
  USER_PERMISSIONS_SUCCESS,
  USER_PERMISSIONS_ERROR,
  ACCOUNT_SETTINGS_FETCH,
  ACCOUNT_SETTINGS_SUCCESS,
  ACCOUNT_SETTINGS_ERROR,
  ACCOUNT_ADDRESS_UPDATE,
  ACCOUNT_ADDRESS_UPDATE_SUCCESS,
  ACCOUNT_ADDRESS_UPDATE_ERROR,
  CLEAR_ACCOUNT_ADDRESS_UPDATE_STATE,
  ACCOUNT_ADDRESS_DELETE,
  ACCOUNT_ADDRESS_DELETE_SUCCESS,
  ACCOUNT_ADDRESS_DELETE_ERROR,
  CLEAR_ACCOUNT_ADDRESS_DELETE_STATE,
  SUBMIT_NEW_ACCOUNT_USER,
  SUBMIT_NEW_ACCOUNT_USER_ERROR,
  SUBMIT_NEW_ACCOUNT_USER_SUCCESS,
  UPDATE_ACCOUNT_USER,
  UPDATE_ACCOUNT_USER_SUCCESS,
  UPDATE_ACCOUNT_USER_ERROR,
  GET_ACCOUNT,
  ACCOUNT_SETTINGS_REGISTRATION_FETCH,
  ACCOUNT_SETTINGS_REGISTRATION_SUCCESS,
  ACCOUNT_SETTINGS_REGISTRATION_ERROR,
  SUBMIT_AGREEMENT,
  SUBMIT_AGREEMENT_ERROR,
  SUBMIT_AGREEMENT_SUCCESS,
  SUBMIT_ADDRESS,
  SUBMIT_ADDRESS_SUCCESS,
  SUBMIT_ADDRESS_ERROR,
  ACCOUNT_NAG_DASHBOARD,
  ACCOUNT_NAG_DASHBOARD_SUCCESS,
  ACCOUNT_NAG_DASHBOARD_ERROR,
  DISMISSED_NAG_DASHBOARD,
  DISMISSED_NAG_DASHBOARD_ERROR,
  DISMISSED_NAG_DASHBOARD_SUCCESS,
} from './accountActionConstants';
import { USER_ACCOUNTS_FETCH_SUCCESS } from '../user/userActionConstants';

export const fetchSingleAccount = (payload) => ({
  type: GET_ACCOUNT,
  payload,
});

export const setCurrentAccount = (payload) => ({
  type: SET_CURRENT_ACCOUNT,
  payload,
});

export const setAccounts = (payload) => ({
  type: USER_ACCOUNTS_FETCH_SUCCESS,
  payload,
});

// usernames for account
export const fetchCurrentAccountUsernames = (payload) => ({
  type: FETCH_CURRENT_ACCOUNT_USERNAMES,
  payload,
});

export const fetchCurrentAccountUsernamesSuccess = (payload) => ({
  type: FETCH_CURRENT_ACCOUNT_USERNAMES_SUCCESS,
  payload,
});

export const fetchCurrentAccountUsernamesError = (payload) => ({
  type: FETCH_CURRENT_ACCOUNT_USERNAMES_ERROR,
  payload,
});

// users for account
export const fetchAccountUsers = (payload) => ({
  type: FETCH_ACCOUNT_USERS,
  payload,
});

export const fetchAccountUsersSuccess = (payload) => ({
  type: FETCH_ACCOUNT_USERS_SUCCESS,
  payload,
});

export const fetchAccountUsersError = (payload) => ({
  type: FETCH_ACCOUNT_USERS_ERROR,
  payload,
});

// addresses for account
export const fetchAccountAddresses = (payload) => ({
  type: FETCH_ACCOUNT_ADDRESSES,
  payload,
});

export const fetchAccountAddressesSuccess = (payload) => ({
  type: FETCH_ACCOUNT_ADDRESSES_SUCCESS,
  payload,
});

export const fetchAccountAddressesError = (payload) => ({
  type: FETCH_ACCOUNT_ADDRESSES_ERROR,
  payload,
});

// Update account Addresses
export const updateAccountAddresses = (payload) => ({
  type: ACCOUNT_ADDRESS_UPDATE,
  payload,
});

export const updateAccountAddressesSuccess = (payload) => ({
  type: ACCOUNT_ADDRESS_UPDATE_SUCCESS,
  payload,
});

export const updateAccountAddressesError = (payload) => ({
  type: ACCOUNT_ADDRESS_UPDATE_ERROR,
  payload,
});

export const updateAccountAddressesClearState = () => ({
  type: CLEAR_ACCOUNT_ADDRESS_UPDATE_STATE,
});

// Delete Account Addresses

export const deleteAccountAddresses = (payload) => ({
  type: ACCOUNT_ADDRESS_DELETE,
  payload,
});

export const deleteAccountAddressesSuccess = (payload) => ({
  type: ACCOUNT_ADDRESS_DELETE_SUCCESS,
  payload,
});

export const deleteAccountAddressesError = (payload) => ({
  type: ACCOUNT_ADDRESS_DELETE_ERROR,
  payload,
});

export const deleteAccountAddressesClearState = () => ({
  type: CLEAR_ACCOUNT_ADDRESS_DELETE_STATE,
});

// Add Account Address

export const postAccountAddress = (payload) => ({
  type: SUBMIT_ADDRESS,
  payload,
});

export const postAccountAddressSuccess = (payload) => ({
  type: SUBMIT_ADDRESS_SUCCESS,
  payload,
});

export const postAccountAddressError = (payload) => ({
  type: SUBMIT_ADDRESS_ERROR,
  payload,
});

// user permissions
export const userPermissionsFetch = (payload) => ({
  type: USER_PERMISSIONS_FETCH,
  payload,
});

export const userPermissionsSuccess = (payload) => ({
  type: USER_PERMISSIONS_SUCCESS,
  payload,
});

export const userPermissionsError = (payload) => ({
  type: USER_PERMISSIONS_ERROR,
  payload,
});

export const fetchAccountSettings = (payload) => ({
  type: ACCOUNT_SETTINGS_FETCH,
  payload,
});

export const fetchAccountSettingsSuccess = (payload) => ({
  type: ACCOUNT_SETTINGS_SUCCESS,
  payload,
});

export const fetchAccountSettingsError = (payload) => ({
  type: ACCOUNT_SETTINGS_ERROR,
  payload,
});

// submit new user
export const submitNewAccountUser = (payload) => ({
  type: SUBMIT_NEW_ACCOUNT_USER,
  payload,
});

export const submitNewAccountUserSuccess = (payload) => ({
  type: SUBMIT_NEW_ACCOUNT_USER_SUCCESS,
  payload,
});

export const submitNewAccountUserError = (payload) => ({
  type: SUBMIT_NEW_ACCOUNT_USER_ERROR,
  payload,
});

// update account user
export const updateAccountUser = (payload) => ({
  type: UPDATE_ACCOUNT_USER,
  payload,
});

export const updateAccountUserSuccess = (payload) => ({
  type: UPDATE_ACCOUNT_USER_SUCCESS,
  payload,
});

export const updateAccountUserError = (payload) => ({
  type: UPDATE_ACCOUNT_USER_ERROR,
  payload,
});

export const fetchAccountSettingsRegistration = (payload) => ({
  type: ACCOUNT_SETTINGS_REGISTRATION_FETCH,
  payload,
});

export const fetchAccountSettingsRegistrationSuccess = (payload) => ({
  type: ACCOUNT_SETTINGS_REGISTRATION_SUCCESS,
  payload,
});

export const fetchAccountSettingsRegistrationError = (payload) => ({
  type: ACCOUNT_SETTINGS_REGISTRATION_ERROR,
  payload,
});

export const postAgreement = (payload) => ({
  type: SUBMIT_AGREEMENT,
  payload,
});

export const postAgreementSuccess = (payload) => ({
  type: SUBMIT_AGREEMENT_SUCCESS,
  payload,
});

export const postAgreementError = (payload) => ({
  type: SUBMIT_AGREEMENT_ERROR,
  payload,
});

// nag dashboard for account
export const fetchNagDashboard = (payload) => ({
  type: ACCOUNT_NAG_DASHBOARD,
  payload,
});

export const fetchNagDashboardSuccess = (payload) => ({
  type: ACCOUNT_NAG_DASHBOARD_SUCCESS,
  payload,
});

export const fetchNagDashboardError = (payload) => ({
  type: ACCOUNT_NAG_DASHBOARD_ERROR,
  payload,
});

// dismissed nag dashboard
export const postDismissedDashboard = (payload) => ({
  type: DISMISSED_NAG_DASHBOARD,
  payload,
});

export const postDismissedDashboardSuccess = (payload) => ({
  type: DISMISSED_NAG_DASHBOARD_SUCCESS,
  payload,
});

export const postDismissedDashboardError = (payload) => ({
  type: DISMISSED_NAG_DASHBOARD_ERROR,
  payload,
});
