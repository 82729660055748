import React from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';

const VirtualRefinementList = connectRefinementList(() => null);

export default function VirtualFilter() {
  return (
    <>
      <VirtualRefinementList attribute="MetalType" />
      <VirtualRefinementList attribute="ProductType" />
      <VirtualRefinementList attribute="Vaults.Location" />
      <VirtualRefinementList attribute="WeightCaption" />
    </>
  );
}

VirtualFilter.propTypes = {};
