import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import StepByStep from '../../StepByStep';
import VerifyBankAccount from '../BankAccount/VerifyBankAccount/VerifyBankAccount';
import Status from '../Status';

const steps = [VerifyBankAccount, Status];

const VerifyBankAccountWizard = ({ onClose, bankAccountUid }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);

  return (
    <StepByStep saveStepDisabled>
      {({ goStepForward, goStepBack }) =>
        steps.map((step) =>
          React.createElement(step, {
            handleBack: goStepBack,
            handleNext: goStepForward,
            handleClose: onClose,
            bankAccountUid,
            setError,
            text: error || t('bankAccounts.verify.success'),
            hasError: !!error,
            backButtonText: t('common.ok'),
            onButtonClick: onClose,
          }),
        )
      }
    </StepByStep>
  );
};

VerifyBankAccountWizard.propTypes = {
  onClose: PropTypes.func,
  bankAccountUid: PropTypes.string,
};

export default VerifyBankAccountWizard;
