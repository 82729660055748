import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash-es/isEmpty';
import Button from '../Button/Button';
import TwoFaEnabled from './Components/TwoFaEnabled';
import useWindowSize from '../../util/hooks/useIsMobileHook';
import { selectTwoFaApplication } from '../../store/selectors/twoFASelector';
import { useGetIsImpersonating } from '../../util/hooks/useGetIsImpersonating';
import SectionLoader from '../Loader/SectionLoader';
import ProfileSettingsCard from '../ProfileSettings/ProfileSettingsCard';
import ProfileSettingsItem from '../ProfileSettings/ProfileSettingsItem';
import Paragraph from '../Paragraph/Paragraph';
import ProfileSettingsButtonGroup from '../ProfileSettings/ProfileSettingsButtonGroup';
import useCheckPermissions from '../../util/hooks/useCheckPermissions';
import { parseEnumType } from '../../util/helpers/enumMappers';
import {
  accountTypeKeys,
  accountTypes,
} from '../../util/enum/api/accountTypes';
import {
  getCurrentAccount,
  selectAccounts,
} from '../../store/selectors/accountSelectors';
import BasicAccountRestristionsModal from '../Modals/BasicAccountRestrictionsModal/BasicAccountRestrictionsModal';
import { useSessionStorageState } from '../../util/hooks/useSessionStorageState';
import { IS_RESTRICTION_MODAL_OPEN } from '../../constants/sessionStorage';

const TwoFACard = ({ openModal, openDeleteModal, email, isLoading }) => {
  const windowSize = useWindowSize();
  const { t } = useTranslation();
  const isMobile = windowSize.width < 768;
  const { isImpersonating } = useGetIsImpersonating();
  const twoFactorAuthenticationResponse = useSelector(selectTwoFaApplication);
  const account = useSelector(getCurrentAccount);
  const getAllAcounts = useSelector(selectAccounts);
  const { displayBasicAccountOptionsPermission } = useCheckPermissions();
  const isBasicAccount =
    parseEnumType(accountTypeKeys, account.AccountType) === accountTypes.Basic;
  const isPendingAccount =
    account?.AccountStatus === 4 ||
    account?.AccountStatus === 1 ||
    account?.AccountStatus === 7;

  const approvedAccounts = getAllAcounts.filter(
    (account) => account?.AccountStatus === 2,
  );

  const [
    is2faSettingsRestrictionModalOpen,
    setIs2faSettingsRestrictionModalOpen,
  ] = useSessionStorageState(IS_RESTRICTION_MODAL_OPEN, false);

  return (
    <>
      <ProfileSettingsCard>
        <SectionLoader isLoading={isLoading}>
          <ProfileSettingsItem marginBottom={32}>
            <Paragraph bold fontSize={24}>
              Two-Factor Authentication (2FA)
            </Paragraph>
            {!isMobile && (
              <ProfileSettingsButtonGroup>
                <Button
                  size="sm"
                  variant="primaryOutlined"
                  type="button"
                  onClick={() => {
                    if (
                      isEmpty(approvedAccounts) ||
                      (displayBasicAccountOptionsPermission &&
                        !isEmpty(approvedAccounts))
                    ) {
                      setIs2faSettingsRestrictionModalOpen(true);
                      return;
                    }
                    openModal();
                  }}
                  disabled={
                    displayBasicAccountOptionsPermission &&
                    !isBasicAccount &&
                    !isPendingAccount
                  }
                  lockStyle={
                    isEmpty(approvedAccounts) ||
                    (displayBasicAccountOptionsPermission &&
                      !isEmpty(approvedAccounts))
                  }
                >
                  {!isEmpty(twoFactorAuthenticationResponse)
                    ? t('twoFa.profileSettings.card.button.change')
                    : t('twoFa.profileSettings.card.button.setup')}
                </Button>
              </ProfileSettingsButtonGroup>
            )}
          </ProfileSettingsItem>

          <Paragraph marginBottom={24}>
            {t('twoFa.profileSettings.card.description')}
          </Paragraph>
          {!isEmpty(twoFactorAuthenticationResponse) && (
            <TwoFaEnabled
              twoFactorAuthenticationResponse={twoFactorAuthenticationResponse}
              email={email}
            />
          )}
          {isMobile && (
            <ProfileSettingsItem>
              {isImpersonating && !isEmpty(twoFactorAuthenticationResponse) && (
                <Button
                  size="sm"
                  variant="primary"
                  type="button"
                  onClick={openDeleteModal}
                >
                  {t('twoFa.profileSettings.card.button.remove')}
                </Button>
              )}
              <Button
                size="sm"
                variant="primaryOutlined"
                type="button"
                onClick={() => {
                  if (
                    isEmpty(approvedAccounts) ||
                    (displayBasicAccountOptionsPermission &&
                      !isEmpty(approvedAccounts))
                  ) {
                    setIs2faSettingsRestrictionModalOpen(true);
                    return;
                  }
                  openModal();
                }}
                disabled={
                  displayBasicAccountOptionsPermission &&
                  !isBasicAccount &&
                  !isPendingAccount
                }
                lockStyle={
                  isEmpty(approvedAccounts) ||
                  (displayBasicAccountOptionsPermission &&
                    !isEmpty(approvedAccounts))
                }
              >
                {twoFactorAuthenticationResponse
                  ? t('twoFa.profileSettings.card.button.change')
                  : t('twoFa.profileSettings.card.button.setup')}
              </Button>
            </ProfileSettingsItem>
          )}
        </SectionLoader>
      </ProfileSettingsCard>
      {isBasicAccount && (
        <BasicAccountRestristionsModal
          isOpen={is2faSettingsRestrictionModalOpen}
          close={() => setIs2faSettingsRestrictionModalOpen(false)}
          title={t('basicAccountRestrictions.twoFa.title')}
          description={t('basicAccountRestrictions.twoFa.description')}
        />
      )}
      {isPendingAccount && (
        <BasicAccountRestristionsModal
          isOpen={is2faSettingsRestrictionModalOpen}
          close={() => setIs2faSettingsRestrictionModalOpen(false)}
          title={t('basicAccountRestrictions.twoFaPending.title')}
          description={t('basicAccountRestrictions.twoFaPending.description')}
          isHiddenButton
        />
      )}
    </>
  );
};

TwoFACard.propTypes = {
  openModal: PropTypes.func,
  openDeleteModal: PropTypes.func,
  email: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default TwoFACard;
