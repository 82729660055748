import React, { useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { get } from 'lodash';
import Auth from '../components/Auth/Auth';
import TextField from '../components/InputFields/TextField';
import {
  getUserSecurityQuestion,
  selectForgotPasswordError,
} from '../store/selectors/userSelectors';
import { selectIsReCaptchaEnabled } from '../store/selectors/settingsSelectors';
import {
  fetchUserSecurityQuestion,
  postPasswordForgottenRequest,
} from '../store/actions/user/userActions';
import {
  PASSWORD_FORGOTTEN_REQUEST_LOADING,
  USER_SECURITY_QUESTION_FETCH_LOADING,
} from '../store/actions/user/userActionConstants';
import { accountSecurityQuestionTypes } from '../util/enum/api/accountTypes';
import { RESET_SENT_PAGE } from '../constants/pages';
import { selectIsLoadingByActionTypes } from '../store/selectors/loadingSelectors';

import SectionLoader from '../components/Loader/SectionLoader';
import config from '../config';
import { securityQuestionValidationSchema } from '../validation/securityQuestionValidationSchema';
import AuthCard from '../components/Auth/AuthCard';
import Paragraph from '../components/Paragraph/Paragraph';
import FormContainer from '../components/FormContainer/FormContainer';
import AuthButtons from '../components/Auth/AuthButtons';

const ResetSecurityQuestionPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const username = params.get('username');
  const dispatch = useDispatch();
  const reCaptchaRef = React.createRef();
  const isReCaptchaEnabled = useSelector(selectIsReCaptchaEnabled);
  const securityQuestionData = useSelector(getUserSecurityQuestion);
  const error = useSelector(selectForgotPasswordError);
  const isLoading = useSelector(
    selectIsLoadingByActionTypes([
      USER_SECURITY_QUESTION_FETCH_LOADING,
      PASSWORD_FORGOTTEN_REQUEST_LOADING,
    ]),
  );
  const securityQuestion = securityQuestionData?.data
    ? t(
        `login.securityQuestionTypes.${get(
          accountSecurityQuestionTypes,
          securityQuestionData.data - 1,
          null,
        )}`,
      )
    : '';

  useEffect(() => {
    dispatch(fetchUserSecurityQuestion({ username }));
  }, [dispatch, username]);

  const handleSubmit = async (values, { setFieldError }) => {
    if (isReCaptchaEnabled) {
      const token = await reCaptchaRef.current.executeAsync();
      reCaptchaRef.current.reset();
      dispatch(
        postPasswordForgottenRequest({
          data: {
            Username: username,
            SecurityAnswer: values.answer,
            RecaptchaToken: token,
          },
          handleSuccess: () => {
            history.push(RESET_SENT_PAGE);
          },
          setFieldError,
        }),
      );
      return;
    }

    dispatch(
      postPasswordForgottenRequest({
        data: {
          Username: username,
          SecurityAnswer: values.answer,
        },
        handleSuccess: () => {
          history.push(RESET_SENT_PAGE);
        },
        setFieldError,
      }),
    );
  };

  return (
    <SectionLoader isLoading={isLoading}>
      <Auth>
        <AuthCard
          title={t('resetPassword.securityQuestion.title')}
          subtitle={t('resetPassword.securityQuestion.subtitle')}
        >
          {securityQuestion && (
            <Paragraph bold marginBottom={16}>
              {securityQuestion}
            </Paragraph>
          )}

          <Formik
            onSubmit={handleSubmit}
            initialValues={{ answer: '' }}
            validationSchema={securityQuestionValidationSchema}
          >
            <Form>
              <FormContainer>
                <Field
                  label={t('profileSettings.answer')}
                  name="answer"
                  errorMessage={error}
                  component={TextField}
                />
              </FormContainer>
              <AuthButtons
                showOnlyPrimary
                primaryButtonProps={{
                  type: 'submit',
                  label: t('resetPassword.securityQuestion.label'),
                }}
              />

              {isReCaptchaEnabled && (
                <ReCAPTCHA
                  ref={reCaptchaRef}
                  sitekey={config.recaptcha.siteKey}
                  size="invisible"
                />
              )}
            </Form>
          </Formik>
        </AuthCard>
      </Auth>
    </SectionLoader>
  );
};

export default ResetSecurityQuestionPage;
