import differenceInMinutes from 'date-fns/differenceInMinutes';
import { decode } from 'jsonwebtoken';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';

import {
  logoutUser,
  refreshUserToken,
} from '../../store/actions/login/loginActions';
import { JWT_TOKEN } from '../../constants/localStorage';
import { authScopeStringGetHelper } from '../helpers/authScopeHelpers';

const INACTIVE_INTERVAL = 240000;
const DEBOUNCE_TIME = 500;
const REFRESH_TIMEOUT = 60000;
const INACTIVE_ALERT_POPUP_TIMOUT = 1080000;
const EXPIRE_TIME_TOKEN_TRIGGER = 10;

export default function useRefreshHook(showAlert, alertActive) {
  const [inactiveTimerId, setInactiveTimerId] = useState(null);
  const [timerId, setTimerId] = useState(null);
  const dispatch = useDispatch();

  const showInactiveAlert = () => {
    showAlert(true);
  };

  const clearTimeouts = () => {
    clearTimeout(timerId);
    setTimerId(null);
    clearTimeout(inactiveTimerId);
    setInactiveTimerId(null);
  };

  // One minute time to do an action
  const startIdleCountdown = () =>
    setTimerId(
      setTimeout(() => {
        dispatch(logoutUser({}));
        showAlert(false);
      }, REFRESH_TIMEOUT),
    );

  const startIdleAlertShowCountdown = () =>
    setInactiveTimerId(
      setTimeout(() => {
        showInactiveAlert();
        startIdleCountdown();
      }, INACTIVE_ALERT_POPUP_TIMOUT),
    );

  // Idle callback
  const handleOnIdle = () => {
    const JwtToken = getToken();

    if (JwtToken) {
      dispatch(refreshUserToken());
      startIdleAlertShowCountdown();
    }
  };

  // Active callback (from idle to active state)
  const handleOnActive = () => {
    const JwtToken = getToken();
    if (JwtToken) {
      if (timerId || inactiveTimerId) {
        clearTimeouts();
      }
    }
  };

  // Every action callback (mouse move, keyboard stroke, etc.)
  const handleOnAction = () => {
    const JwtToken = getToken();
    const userLoggedIn = decode(JwtToken);

    if (userLoggedIn) {
      const timeUntilSessionExpire = differenceInMinutes(
        new Date(decode(JwtToken).exp * 1000),
        new Date(),
      );

      if (timeUntilSessionExpire < EXPIRE_TIME_TOKEN_TRIGGER && !alertActive) {
        dispatch(refreshUserToken());

        if (timerId || inactiveTimerId) {
          clearTimeouts();
        }
      }
    }
  };

  const { getRemainingTime } = useIdleTimer({
    timeout: INACTIVE_INTERVAL,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: DEBOUNCE_TIME,
  });

  return getRemainingTime;
}

function getToken() {
  return authScopeStringGetHelper(JWT_TOKEN);
}
