import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import SectionLoader from '../../Loader/SectionLoader';
import ModalButtons from '../../Modal/ModalButtons';
import {
  fetchCreditCards,
  submitCreditCard,
} from '../../../store/actions/creditCard/creditCardActions';
import SelectField from '../../InputFields/SelectField';
import Note from '../../Notes/Note';
import { uFlexCenter, uOutlineNone } from '../../../assets/styles/utility';
import { pxToRem } from '../../../assets/styles/helper';
import themeColors from '../../../assets/styles/themeColors';
import { variables } from '../../../assets/styles/variables';
import {
  getCurrentAccountUid,
  selectAccountAddresses,
} from '../../../store/selectors/accountSelectors';
import {
  addCreditCardExistingAddressValidationSchema,
  addCreditCardNewAddressValidationSchema,
} from '../../../validation/addCreditCardValidationSchema';
import { fetchAccountAddresses } from '../../../store/actions/account/accountActions';
import { selectIsLoadingByActionTypes } from '../../../store/selectors/loadingSelectors';
import { SUBMIT_CREDIT_CARD_LOADING } from '../../../store/actions/creditCard/creditCardActionConstants';
import { FETCH_ACCOUNT_ADDRESSES_LOADING } from '../../../store/actions/account/accountActionConstants';
import {
  GET_COUNTRIES_LOADING,
  GET_STATES_LOADING,
} from '../../../store/actions/common/commonConstants';
import ProgressBar from '../../ProgressBar/ProgressBar';
import {
  formatAddressStringInline,
  getStatesOptions,
} from '../../../util/helpers/addressHelper';
import AddressForm from '../../AddressForm/AddressForm';
import { USA_COUNTRY_ISO3CODE } from '../../../util/constants';
import { selectStates } from '../../../store/selectors/commonSelectors';
import useGetCountries from '../../../util/hooks/useGetCountries';

const ProgressBarWrapper = styled.div`
  margin-bottom: ${pxToRem(25)};
  .RSPBprogressBar .RSPBstep {
    display: none;
  }
`;

const StepNumberNote = styled.p`
  font-style: italic;
`;

const StepTitle = styled.p`
  font-size: ${pxToRem(20)};
  font-weight: 600;
  margin-bottom: ${pxToRem(8)};
`;

export const AddAddressPlusIconWrapper = styled.div`
  ${uFlexCenter};
  width: ${pxToRem(16)};
  height: ${pxToRem(16)};
  border-radius: 4px;
  background-color: ${themeColors.colorPrimary};
  box-shadow: ${variables.shadow.boxShadow};
  margin-right: ${pxToRem(16)};
  flex-shrink: 0;
  padding: ${pxToRem(3)};
  color: ${themeColors.colorTextContrast};

  svg {
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
    color: ${themeColors.colorTextContrast};
  }
`;

const BraintreeFieldWrapper = styled.div`
  margin-bottom: ${pxToRem(12)};
  iframe {
    float: none !important;
  }

  .braintree-hosted-field {
    ${uOutlineNone};
    border: 1px solid ${themeColors.colorInputBorder};
    border-radius: ${variables.borderRadius.borderRadius};
    font-size: ${pxToRem(16)};
    line-height: 1.75;
    height: ${pxToRem(38)};
    padding: 0 ${pxToRem(12)};
    color: ${themeColors.colorInputPlaceholder};
    background-color: ${themeColors.colorInputBackground};
    width: 100%;
  }
`;

const EnterBillingAddress = ({ handleBack, handleNext, wizardData, steps }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accountUid = useSelector(getCurrentAccountUid);
  const accountAddresses = useSelector(selectAccountAddresses);

  const isLoading = useSelector(
    selectIsLoadingByActionTypes([
      SUBMIT_CREDIT_CARD_LOADING,
      GET_STATES_LOADING,
      GET_COUNTRIES_LOADING,
      FETCH_ACCOUNT_ADDRESSES_LOADING,
    ]),
  );

  const [isAddingNewAddress, setIsAddingNewAddress] = useState(false);

  const countries = useGetCountries();

  const states = useSelector(selectStates);

  const STATES_OPTIONS = getStatesOptions(states);

  const ADDRESSES_OPTIONS = [
    ...accountAddresses.map((accountAddress) => ({
      value: accountAddress.AddressUid,
      label: formatAddressStringInline(accountAddress, countries),
    })),
  ];

  useEffect(() => {
    if (accountUid) {
      dispatch(fetchAccountAddresses({ accountUid }));
    }
  }, [dispatch, accountUid]);

  const onSuccess = () => {
    dispatch(fetchCreditCards({ accountUid }));
    handleNext();
  };

  const handleSubmitCreditCard = (values) => {
    const addressPayload =
      !isAddingNewAddress && !isEmpty(accountAddresses)
        ? { AddressUid: values.AddressUid.value }
        : {
            BillingAddress: {
              AddressLine: values.AddressLine,
              AddressLine2: values.AddressLine2,
              City: values.City,
              PostalCode: values.PostalCode,
              StateRegion: values.StateRegion,
              CountryIso3Code: values.CountryIso3Code,
            },
          };
    dispatch(
      submitCreditCard({
        AccountUid: accountUid,
        data: {
          ...wizardData,
          ...addressPayload,
        },
        handleNext,
        onSuccess,
      }),
    );
  };

  const defaultAddress = accountAddresses?.find(
    (address) => address.IsDefaultMailingAddress,
  );

  return (
    <SectionLoader isLoading={isLoading}>
      <StepNumberNote>
        {t('creditCard.stepNumber', {
          currentStepNumber: 2,
          numberOfSteps: steps.length - 1,
        })}
      </StepNumberNote>
      <StepTitle>{t('creditCard.enterBillingAddress')}</StepTitle>
      <ProgressBarWrapper>
        <ProgressBar currentStep={100} steps={steps} />
      </ProgressBarWrapper>
      <Formik
        initialValues={{
          AddressUid: {
            value: defaultAddress
              ? defaultAddress.AddressUid
              : accountAddresses[0]?.AddressUid,
            label: formatAddressStringInline(
              defaultAddress ?? accountAddresses[0],
              countries,
            ),
          },
          CountryIso3Code: USA_COUNTRY_ISO3CODE,
          AddressLine: '',
          AddressLine2: '',
          City: '',
          StateRegion: STATES_OPTIONS[0].value,
          PostalCode: '',
        }}
        onSubmit={handleSubmitCreditCard}
        validationSchema={
          isAddingNewAddress || isEmpty(accountAddresses)
            ? addCreditCardNewAddressValidationSchema
            : addCreditCardExistingAddressValidationSchema
        }
        enableReinitialize
      >
        {({ isValid, values, setFieldValue }) => (
          <Form>
            {!isEmpty(accountAddresses) && !isAddingNewAddress && (
              <>
                <BraintreeFieldWrapper>
                  <SelectField
                    name="AddressUid"
                    label={t('creditCard.fields.billingAddress')}
                    disabled={!accountAddresses.length}
                    placeholder={t('common.select')}
                    options={ADDRESSES_OPTIONS}
                  />
                </BraintreeFieldWrapper>

                <Note
                  onClick={() => {
                    setIsAddingNewAddress(true);
                  }}
                  primaryColor
                  text={t('creditCard.addNewAddress')}
                  marginBottom={24}
                  noUnderline
                />
              </>
            )}

            {(isEmpty(accountAddresses) || isAddingNewAddress) && (
              <AddressForm
                stateOptions={STATES_OPTIONS}
                setFieldValue={setFieldValue}
                {...values}
              />
            )}
            <ModalButtons
              isHorizontal
              marginTop
              primaryButtonProps={{
                type: 'submit',
                label: t('common.submit'),
                disabled: !isValid,
              }}
              secondaryButtonProps={{
                onClick: handleBack,
                label: t('common.back'),
              }}
            />
          </Form>
        )}
      </Formik>
    </SectionLoader>
  );
};

EnterBillingAddress.propTypes = {
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  wizardData: PropTypes.shape({
    Bin: PropTypes.number,
    Last4Digits: PropTypes.number,
    ExpirationDate: PropTypes.string,
    NameOnCard: PropTypes.string,
    Nonce: PropTypes.string,
    CreditCardType: PropTypes.string,
  }),
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      percentage: PropTypes.number,
    }),
  ),
};

export default EnterBillingAddress;
