import createReducer from '../../utils/createReducer';
import {
  SET_CURRENT_ACCOUNT,
  FETCH_CURRENT_ACCOUNT_USERNAMES_ERROR,
  FETCH_CURRENT_ACCOUNT_USERNAMES_SUCCESS,
  FETCH_ACCOUNT_USERS_SUCCESS,
  FETCH_ACCOUNT_USERS_ERROR,
  FETCH_ACCOUNT_ADDRESSES_SUCCESS,
  FETCH_ACCOUNT_ADDRESSES_ERROR,
  USER_PERMISSIONS_SUCCESS,
  USER_PERMISSIONS_ERROR,
  ACCOUNT_ADDRESS_DELETE_SUCCESS,
  ACCOUNT_ADDRESS_DELETE_ERROR,
  ACCOUNT_ADDRESS_UPDATE_SUCCESS,
  ACCOUNT_ADDRESS_UPDATE_ERROR,
  CLEAR_ACCOUNT_ADDRESS_DELETE_STATE,
  CLEAR_ACCOUNT_ADDRESS_UPDATE_STATE,
  SUBMIT_NEW_ACCOUNT_USER_SUCCESS,
  SUBMIT_NEW_ACCOUNT_USER_ERROR,
  UPDATE_ACCOUNT_USER_SUCCESS,
  UPDATE_ACCOUNT_USER_ERROR,
  SUBMIT_AGREEMENT_ERROR,
  SUBMIT_AGREEMENT_SUCCESS,
  SUBMIT_ADDRESS_SUCCESS,
  SUBMIT_ADDRESS_ERROR,
  ACCOUNT_NAG_DASHBOARD_SUCCESS,
  ACCOUNT_NAG_DASHBOARD_ERROR,
  DISMISSED_NAG_DASHBOARD_ERROR,
} from '../../actions/account/accountActionConstants';
import { RESET_LOGIN_STATE } from '../../actions/login/loginActionConstants';
import { USER_ACCOUNTS_FETCH_SUCCESS } from '../../actions/user/userActionConstants';

const initialState = {
  current: {
    AccountUid: null,
    DisplayName: '',
    AccountCode: '',
    AccountType: 0,
  },
  accountUsernames: {
    data: [],
    errorMessage: '',
  },
  accountUsers: {
    data: [],
    errorMessage: '',
  },
  allAccounts: {
    data: [],
    errorMessage: '',
  },
  accountAddresses: {
    data: [],
    errorMessage: '',
  },
  newAddress: {
    data: {},
    error: '',
  },
  userPermissions: {
    permissions: [],
    permissionsError: '',
  },
  agreement: {
    data: '',
    errorMessage: [],
  },
  nagDashboard: {
    data: [],
    errorMessage: '',
  },
  dismissedDashboard: {
    errorMessage: '',
  },
  isSuccessfulSubmit: null,
};

export default createReducer(
  {
    [SET_CURRENT_ACCOUNT]: setCurrentAccount,
    [FETCH_CURRENT_ACCOUNT_USERNAMES_SUCCESS]: fetchCurrentAccountUsernamesSuccess,
    [FETCH_CURRENT_ACCOUNT_USERNAMES_ERROR]: fetchCurrentAccountUsernamesError,
    [FETCH_ACCOUNT_USERS_SUCCESS]: fetchAccountUsersSuccess,
    [FETCH_ACCOUNT_USERS_ERROR]: fetchAccountUsersError,
    [FETCH_ACCOUNT_ADDRESSES_SUCCESS]: setAccountAddresses,
    [FETCH_ACCOUNT_ADDRESSES_ERROR]: setAccountAddressesError,
    [USER_PERMISSIONS_SUCCESS]: setCurrentUserPermissions,
    [USER_PERMISSIONS_ERROR]: setCurrentUserPermissionsError,
    [ACCOUNT_ADDRESS_DELETE_SUCCESS]: setDeleteAccountAddressSuccess,
    [ACCOUNT_ADDRESS_DELETE_ERROR]: setDeleteAccountAddressError,
    [ACCOUNT_ADDRESS_UPDATE_SUCCESS]: setUpdateAccountAddressSuccess,
    [ACCOUNT_ADDRESS_UPDATE_ERROR]: setUpdateAccountAddressError,
    [CLEAR_ACCOUNT_ADDRESS_DELETE_STATE]: clearAccountAddressDeleteState,
    [CLEAR_ACCOUNT_ADDRESS_UPDATE_STATE]: clearAccountAddressUpdateState,
    [USER_ACCOUNTS_FETCH_SUCCESS]: setUserAccounts,
    [SUBMIT_NEW_ACCOUNT_USER_SUCCESS]: submitNewAccountUser,
    [SUBMIT_NEW_ACCOUNT_USER_ERROR]: submitNewAccountUserError,
    [UPDATE_ACCOUNT_USER_SUCCESS]: updateAccountUserSuccess,
    [UPDATE_ACCOUNT_USER_ERROR]: updateAccountUserError,
    [RESET_LOGIN_STATE]: resetAccountState,
    [SUBMIT_AGREEMENT_SUCCESS]: postAgreement,
    [SUBMIT_AGREEMENT_ERROR]: postAgreementError,
    [SUBMIT_ADDRESS_SUCCESS]: postAddressSuccess,
    [SUBMIT_ADDRESS_ERROR]: postAddressError,
    [ACCOUNT_NAG_DASHBOARD_SUCCESS]: getNagDashboard,
    [ACCOUNT_NAG_DASHBOARD_ERROR]: getNagDashboardError,
    [DISMISSED_NAG_DASHBOARD_ERROR]: postDismissedDashboardError,
  },
  initialState,
);

function setCurrentUserPermissions(state, action) {
  return {
    ...state,
    userPermissions: {
      ...state.userPermissions,
      permissions: action.payload,
    },
  };
}

function setCurrentUserPermissionsError(state, action) {
  return {
    ...state,
    userPermissions: {
      ...state.userPermissions,
      permissionsError: action.payload,
    },
  };
}

function setCurrentAccount(state, action) {
  return {
    ...state,
    current: {
      ...state.current,
      ...action.payload,
    },
  };
}

function fetchCurrentAccountUsernamesSuccess(state, action) {
  return {
    ...state,
    accountUsernames: {
      ...state.accountUsernames,
      data: action.payload,
    },
  };
}

function fetchCurrentAccountUsernamesError(state, action) {
  return {
    ...state,
    accountUsernames: {
      ...state.accountUsernames,
      errorMessage: action.payload,
    },
  };
}

function fetchAccountUsersSuccess(state, action) {
  return {
    ...state,
    accountUsers: {
      ...state.accountUsers,
      data: action.payload,
    },
  };
}

function fetchAccountUsersError(state, { payload }) {
  return {
    ...state,
    accountUsers: {
      ...state.accountUsers,
      errorMessage: payload,
    },
  };
}

function setAccountAddresses(state, { payload }) {
  return {
    ...state,
    accountAddresses: {
      ...state.accountAddresses,
      data: payload,
    },
  };
}

function setAccountAddressesError(state, { payload }) {
  return {
    ...state,
    accountAddresses: {
      ...state.accountAddresses,
      errorMessage: payload,
    },
  };
}

function clearAccountAddressDeleteState(state) {
  return {
    ...state,
    isSuccessfulDelete: null,
  };
}

function clearAccountAddressUpdateState(state) {
  return {
    ...state,
    isSuccessfulUpdate: null,
  };
}

function setDeleteAccountAddressSuccess(state) {
  return {
    ...state,
    isSuccessfulDelete: true,
  };
}

function setDeleteAccountAddressError(state) {
  return {
    ...state,
    isSuccessfulDelete: false,
  };
}

function setUpdateAccountAddressSuccess(state) {
  return {
    ...state,
    isSuccessfulUpdate: true,
  };
}

function setUpdateAccountAddressError(state) {
  return {
    ...state,
    isSuccessfulUpdate: false,
  };
}

function postAddressSuccess(state) {
  return {
    ...state,
    isSuccessfulSubmit: true,
  };
}

function postAddressError(state, { payload }) {
  return {
    ...state,
    newAddress: {
      ...state.newAddress,
      error: payload,
    },
  };
}

function setUserAccounts(state, { payload }) {
  return {
    ...state,
    allAccounts: {
      ...state.allAccounts,
      data: payload,
    },
  };
}

function submitNewAccountUser(state, { payload }) {
  return {
    ...state,
    accountUsers: {
      ...state.accountUsers,
      data: [...state.accountUsers.data, payload],
    },
  };
}

function submitNewAccountUserError(state, { payload }) {
  return {
    ...state,
    accountUsers: {
      ...state.accountUsers,
      errorMessage: payload,
    },
  };
}

function updateAccountUserSuccess(state) {
  return {
    ...state,
    isSuccessfulUpdate: true,
    errorMessage: '',
  };
}

function updateAccountUserError(state) {
  return {
    ...state,
    isSuccessfulUpdate: false,
  };
}

function resetAccountState() {
  return initialState;
}

function postAgreement(state, action) {
  return {
    ...state,
    agreement: {
      data: action.payload,
      errorMessage: '',
    },
  };
}

function postAgreementError(state, action) {
  return {
    ...state,
    agreement: {
      ...state.agreement,
      errorMessage: action.payload,
    },
  };
}

function getNagDashboard(state, { payload }) {
  return {
    ...state,
    nagDashboard: {
      ...state.nagDashboard,
      data: payload,
    },
  };
}

function getNagDashboardError(state, action) {
  return {
    ...state,
    nagDashboard: {
      ...state.nagDashboard,
      errorMessage: action.payload,
    },
  };
}

function postDismissedDashboardError(action) {
  return {
    dismissedDashboard: {
      errorMessage: action.payload,
    },
  };
}
