import React from 'react';
import PropTypes from 'prop-types';
import { uuid } from 'uuidv4';
import styled from 'styled-components';
import { ReactComponent as Wallet } from '../../assets/images/svg/wallet-round.svg';
import { ReactComponent as Location } from '../../assets/images/svg/location-round.svg';
import { ReactComponent as Clock } from '../../assets/images/svg/clock-round.svg';
import { ReactComponent as Check } from '../../assets/images/svg/check-round.svg';
import { ReactComponent as CardRound } from '../../assets/images/svg/card-round.svg';
import Paragraph from '../Paragraph/Paragraph';
import { pxToRem } from '../../assets/styles/helper';
import { uFlexColumn } from '../../assets/styles/utility';
import themeColors from '../../assets/styles/themeColors';

const ListWithIconContainer = styled.div`
  display: grid;
  grid-row-gap: ${pxToRem(16)};

  > :last-child {
    display: none;
  }
`;

const ListWithIconItem = styled.div`
  display: grid;
  grid-template-columns: ${pxToRem(40)} 1fr;
  grid-column-gap: ${pxToRem(16)};
  align-items: center;

  > svg {
    width: ${pxToRem(40)};
    height: ${pxToRem(40)};
  }

  p {
    ${uFlexColumn};
  }
`;

const ListWithIconSeparator = styled.div`
  height: ${pxToRem(13)};
  width: 1px;
  background-color: ${themeColors.colorBorderPrimary};
  margin-left: ${pxToRem(19.5)};
`;

const ListWithIcon = ({ items }) => {
  if (!items) {
    return null;
  }

  const getIcon = (iconName) => {
    switch (iconName) {
      case 'wallet':
        return <Wallet />;

      case 'location':
        return <Location />;

      case 'clock':
        return <Clock />;

      case 'check':
        return <Check />;

      case 'card':
        return <CardRound />;
      default:
        break;
    }
  };

  return (
    <ListWithIconContainer>
      {items.map((item) => (
        <>
          <ListWithIconItem key={uuid()}>
            {getIcon(item?.icon)}
            <Paragraph>
              {item?.labels.map((item) => (
                <span>{item}</span>
              ))}
            </Paragraph>
          </ListWithIconItem>
          <ListWithIconSeparator key={uuid()} />
        </>
      ))}
    </ListWithIconContainer>
  );
};

ListWithIcon.propTypes = {
  items: PropTypes.arrayOf(),
};

export default ListWithIcon;
