/* eslint-disable no-restricted-globals */
import i18next from 'i18next';
import {
  transactionFormatTypes,
  transactionTypesArray,
  transactionUnitTypesArray,
} from '../../enum/api/transactionTypes';
import { formatDate } from '../dateHelpers';
import { parseEnumType } from '../enumMappers';
import { formatMoneyNumeral } from '../numeralHelpers';

export const formatByTransactionSummary = (field) => {
  const amountOrQuantity = field.AmountOrQuantity;
  const precision = field.Precision;
  const unitType =
    typeof field.UnitType === 'number'
      ? parseEnumType(transactionUnitTypesArray, field.UnitType)
      : field.UnitType;

  const decimalAmount =
    amountOrQuantity > 1
      ? `${parseFloat(amountOrQuantity).toFixed(precision)} ${i18next.t(
          `portfolio.transactions.details.pluralUnit`,
        )}`
      : `${parseFloat(amountOrQuantity).toFixed(precision)} ${i18next.t(
          `portfolio.transactions.details.singleUnit`,
        )}`;

  const amount =
    unitType === 'Cash' ? formatMoneyNumeral(amountOrQuantity) : decimalAmount;

  const transactionTypeLiteral =
    typeof field.TransactionType === 'number'
      ? parseEnumType(transactionTypesArray, field.TransactionType)
      : field.TransactionType;

  const transactionType = amountOrQuantity
    ? i18next.t(
        `portfolio.transactions.portfolioTypes.${transactionTypeLiteral}`,
        {
          amount,
        },
      )
    : i18next.t(
        `portfolio.transactions.portfolioTypesNonAmount.${transactionTypeLiteral}`,
      );

  return transactionType;
};

export const formatByDateRange = (field) => {
  const formatedStartDate = formatDate(field.StartDate);
  const formatedEndDate = formatDate(field.EndDate);

  return `(${formatedStartDate} ${i18next.t('common.to')} ${formatedEndDate})`;
};

const formatByProduct = (field) => {
  const product = field.ProductCaption;

  return product;
};

export function transactionFieldFormatter(field) {
  const formatType = parseEnumType(transactionFormatTypes, field.FormatType);
  switch (formatType) {
    case 'Product':
      return formatByProduct(field);

    case 'DateRange':
      return formatByDateRange(field);

    case 'TransactionSummary':
      return formatByTransactionSummary(field);

    default:
      break;
  }
}
