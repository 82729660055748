import { getRequest, replaceInUrl, postRequest, deleteRequest } from './index';
import apiEndpoints from './apiEndpoints';

export const fetchTwoFaTypesRequest = () =>
  getRequest(apiEndpoints.twoFA.twoFaTypes);

export const fetchTwoFaKeysRequest = (userUid) =>
  getRequest(
    replaceInUrl(apiEndpoints.twoFA.twoFaApplicationKeys, {
      userUid,
    }),
  );

export const postTwoFaCodeRequest = (userUid, requestData) =>
  postRequest(
    replaceInUrl(apiEndpoints.twoFA.twoFaCode, {
      userUid,
    }),
    requestData,
  );

export const fetchTwoFaCodeViaPhoneRequest = (
  username,
  phoneUid,
  phoneMessageType,
) =>
  getRequest(
    replaceInUrl(apiEndpoints.twoFA.twoFaCodeViaPhone, {
      phoneUid,
    }),
    { username, phoneMessageType },
  );

export const fetchTwoFaCodeViaEmailRequest = (username, emailUid) =>
  getRequest(
    replaceInUrl(apiEndpoints.twoFA.twoFaCodeViaEmail, {
      emailUid,
    }),
    { username },
  );

export const postTwoFaRequest = (userUid, requestData) =>
  postRequest(
    replaceInUrl(apiEndpoints.twoFA.saveTwoFa, {
      userUid,
    }),
    requestData,
  );

export const deleteTwoFaRequest = (userUid, requestData) =>
  deleteRequest(
    replaceInUrl(apiEndpoints.twoFA.deleteTwoFa, {
      userUid,
    }),
    requestData,
  );

export const fetchTwoFaRequest = (username) =>
  getRequest(apiEndpoints.twoFA.getTwoFa, {
    username,
  });

export const fetchTwoFaCustomerSupportRequest = () =>
  getRequest(apiEndpoints.twoFA.isOpenCustomerSupport);
