import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { NoteElement } from '../Notes/Note';

const NavigationLink = ({ to, children, marginBottom, isPrimaryColor }) => (
  <NoteElement marginBottom={marginBottom} primaryColor={isPrimaryColor}>
    <Link to={to}>{children}</Link>
  </NoteElement>
);

NavigationLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
  marginBottom: PropTypes.number,
  isPrimaryColor: PropTypes.bool,
};

export default NavigationLink;
