import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isEmpty from 'lodash.isempty';
import { v4 as uuid } from 'uuid';
import useWindowSize from '../../util/hooks/useIsMobileHook';
import TableData from './TableData';
import { buttonizeDiv } from '../../util/buttonizeDiv';
import { ReactComponent as ChevronRight } from '../../assets/images/svg/chevron-right.svg';
import { ReactComponent as ChevronDown } from '../../assets/images/svg/chevron-down.svg';
import Button from '../Button/Button';
import { pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import { uTransition } from '../../assets/styles/utility';

export const TableRowElement = styled.div`
  display: flex;
  padding: ${pxToRem(8)} ${pxToRem(24)};
  border-radius: ${variables.borderRadius.borderRadius};
  ${(props) => props.css};
`;

export const TableActionButtons = styled.div`
  display: flex;
  ${(props) => props.css};

  > :not(:last-child) {
    margin-right: ${pxToRem(24)};
  }
`;

export const TableArrowIcon = styled.div`
  width: ${pxToRem(16)};
  height: ${pxToRem(16)};

  svg {
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
  }
`;

export const TableDropdownContainer = styled.div`
  ${(props) => props.css};
`;

export const TableDropdownContent = styled.div`
  display: flex;
  width: 100%;
`;

export const TableDropdownIcon = styled.div`
  ${uTransition};
  width: ${pxToRemMd(16)};
  height: ${pxToRemMd(16)};

  ${(props) =>
    props.isOpen &&
    `
  svg {
    transform: rotate(180deg);
  }
  `};
`;

export const TableDropdown = styled.div`
  width: 100%;
  padding: ${pxToRemMd(8)} 0;

  ${TableActionButtons} {
    display: flex;

    > :nth-child(2) {
      margin-left: ${pxToRemMd(14)};
    }

    > button {
      flex: 1 1 0;
    }
  }
`;

const TableRow = ({
  rowData,
  actionButtonsClassName,
  mobileDropdown,
  onClick,
  hasArrow,
  ...props
}) => {
  const [dropdownOpen, setDropdownOpen] = useState('');
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 768;
  const tableWithDropdown = (data) => {
    const openDropdown = (event) => {
      if (event.target.type === 'button') {
        return;
      }

      if (data.id === dropdownOpen) {
        setDropdownOpen('');
      } else {
        setDropdownOpen(data.id);
      }
    };
    return (
      <TableDropdownContainer {...buttonizeDiv(openDropdown)} {...props}>
        <TableDropdownContent>
          {data.content}
          <TableDropdownIcon isOpen={dropdownOpen === data.id}>
            <ChevronDown />
          </TableDropdownIcon>
        </TableDropdownContent>
        {dropdownOpen === data.id && (
          <TableDropdown>
            {!isEmpty(data.actions) && (
              <TableActionButtons css={actionButtonsClassName}>
                {data.actions.map(({ id, label, action, ...restProps }) => (
                  <Button
                    key={id}
                    onClick={action}
                    label={label}
                    {...restProps}
                  />
                ))}
              </TableActionButtons>
            )}
          </TableDropdown>
        )}
      </TableDropdownContainer>
    );
  };

  const tableWithoutDropdown = (data) => (
    <>
      {data.content}
      {!isEmpty(data.actions) && (
        <TableActionButtons css={actionButtonsClassName}>
          {data.actions.map(({ id, label, action, ...restProps }) => (
            <Button
              key={`${id}-${uuid()}`}
              onClick={action}
              label={label}
              {...restProps}
            />
          ))}
        </TableActionButtons>
      )}
      {hasArrow && (
        <TableArrowIcon>
          <ChevronRight />
        </TableArrowIcon>
      )}
    </>
  );

  return rowData.map((data) => (
    <TableRowElement
      key={`${data.id}-${uuid()}`}
      {...buttonizeDiv(() => (onClick ? onClick(data) : null))}
      {...props}
    >
      <TableData>
        {mobileDropdown && isMobile
          ? tableWithDropdown(data)
          : tableWithoutDropdown(data)}
      </TableData>
    </TableRowElement>
  ));
};

TableRow.propTypes = {
  rowData: PropTypes.arrayOf(PropTypes.shape({})),
  onClick: PropTypes.func,
  hasArrow: PropTypes.bool,
  mobileDropdown: PropTypes.bool,
  mobileDropdownRowClass: PropTypes.string,
};

export default TableRow;
