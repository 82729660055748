import createReducer from '../../utils/createReducer';
import {
  USER_PERMISSIONS_SUCCESS,
  USER_PERMISSIONS_ERROR,
} from '../../actions/account/accountActionConstants';

const initialState = {
  permissions: [],
  permissionsError: '',
};

export default createReducer(
  {
    [USER_PERMISSIONS_SUCCESS]: setCurrentUserPermissions,
    [USER_PERMISSIONS_ERROR]: setCurrentUserPermissionsError,
  },
  initialState,
);

function setCurrentUserPermissions(state, action) {
  return {
    ...state,
    permissions: action.payload,
  };
}

function setCurrentUserPermissionsError(state, action) {
  return {
    ...state,
    permissionsError: action.payload,
  };
}
