import { createSelector } from 'reselect';

const holdingsSelector = (state) => state.portfolio.holdings;

export const selectHoldingsData = createSelector(
  holdingsSelector,
  (state) => state.holdings.data,
);

export const selectHoldingsError = createSelector(
  holdingsSelector,
  (state) => state.holdings.errorMsg,
);

export const selectMyHoldingsCodes = createSelector(
  holdingsSelector,
  (state) => state.myHoldingsCodes.data,
);

export const selectMyHoldingsCodesError = createSelector(
  holdingsSelector,
  (state) => state.myHoldingsCodes.errorMsg,
);
