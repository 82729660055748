import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import isEmpty from 'lodash.isempty';
import styled from 'styled-components';
import FormCard from '../../../components/FormCard/FormCard';
import SelectField from '../../../components/InputFields/SelectField';
import TextField from '../../../components/InputFields/TextField';
import PhoneNumberField from '../../../components/InputFields/PhoneNumberField';
import PasswordField from '../../../components/InputFields/PasswordField';
import { selectSecurityQuestions } from '../../../store/selectors/commonSelectors';
import { getPortalSecurityQuestions } from '../../../store/actions/common/commonActions';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import { accountSecurityQuestionTypes } from '../../../util/enum/api/accountTypes';
import { retrieveFromSessionStorage } from '../../../util/helpers/sessionStorageHelper';
import { INVITE_EMAIL } from '../../../constants/sessionStorage';
import FormContainer from '../../../components/FormContainer/FormContainer';
import { useBrokerCode } from '../../../util/hooks/useBrokerCode';

const HiddenField = styled(Field)`
  display: none;
  height: 0;
  width: 0;
`;

const CreateUserFormSection = ({ setFieldValue, suffixOptions, errors }) => {
  const securityQuestions = useSelector(selectSecurityQuestions);
  const email = retrieveFromSessionStorage(INVITE_EMAIL);
  const dispatch = useDispatch();
  const brokerCode = useBrokerCode();

  const QUESTIONS_OPTIONS = useMemo(
    () =>
      securityQuestions.map((question) => ({
        label: i18next.t(
          `login.securityQuestionTypes.${parseEnumType(
            accountSecurityQuestionTypes,
            question,
          )}`,
        ),
        value: parseEnumType(accountSecurityQuestionTypes, question),
      })),
    [securityQuestions],
  );

  useEffect(() => {
    dispatch(getPortalSecurityQuestions());
  }, []); // eslint-disable-line

  return (
    <>
      <FormCard
        title={i18next.t('inviteUser.credentials.personalInformation.title')}
        subtitle={i18next.t(
          'inviteUser.credentials.personalInformation.subtitle',
        )}
      >
        <FormContainer fourColumn>
          <Field
            component={TextField}
            name="LegalFirstName"
            label={i18next.t('inviteUser.credentials.labels.legalFirstName')}
          />
          <Field
            component={TextField}
            name="LegalMiddleName"
            label={i18next.t('inviteUser.credentials.labels.legalMiddleName')}
          />
          <Field
            component={TextField}
            name="LegalLastName"
            label={i18next.t('inviteUser.credentials.labels.legalLastName')}
          />
          <SelectField
            label={i18next.t('inviteUser.credentials.labels.suffix')}
            name="SuffixType"
            options={suffixOptions}
          />
        </FormContainer>
      </FormCard>
      <FormCard
        title={i18next.t('inviteUser.credentials.phoneNumber.title')}
        subtitle={i18next.t('inviteUser.credentials.phoneNumber.subtitle')}
      >
        <FormContainer maxWidth={450}>
          <PhoneNumberField
            name="PhoneNumber"
            label={i18next.t('inviteUser.credentials.labels.phoneNumber')}
            onPhoneChange={(phoneNum) => setFieldValue('PhoneNumber', phoneNum)}
          />
        </FormContainer>
      </FormCard>
      <FormContainer isHorizontal>
        <FormCard
          title={i18next.t('inviteUser.credentials.loginAccess.title')}
          subtitle={
            <>
              <p>
                {i18next.t('inviteUser.credentials.loginAccess.subtitleOne')}
              </p>
              <p>
                {i18next.t('inviteUser.credentials.loginAccess.subtitleTwo')}
              </p>
            </>
          }
        >
          <FormContainer>
            <Field
              name="Password"
              label={i18next.t('registration.fieldLabels.password')}
              component={PasswordField}
              shouldTestPasswordStrength={isEmpty(errors?.Password)}
              autoComplete="new-password"
            />
            <Field
              name="ConfirmPassword"
              component={PasswordField}
              label={i18next.t('inviteUser.credentials.labels.confirmPassword')}
              autoComplete="new-password"
            />
          </FormContainer>
        </FormCard>
        <FormCard
          title={i18next.t('inviteUser.credentials.securityQuestion.title')}
          subtitle={i18next.t(
            'inviteUser.credentials.securityQuestion.subtitle',
            { brokerCode },
          )}
        >
          <FormContainer>
            <SelectField
              label={i18next.t(
                'inviteUser.credentials.labels.securityQuestion',
              )}
              name="SecurityQuestion"
              options={QUESTIONS_OPTIONS}
              disabled={QUESTIONS_OPTIONS.length === 0}
            />
            <Field
              component={TextField}
              name="Answer"
              label={i18next.t('inviteUser.credentials.labels.answer')}
            />
          </FormContainer>
        </FormCard>

        <HiddenField
          component={TextField}
          name="Username"
          autoComplete="username"
          value={email}
          disabled
        />
      </FormContainer>
    </>
  );
};

CreateUserFormSection.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.shape({
    PhoneNumber: PropTypes.string,
  }),
  suffixOptions: PropTypes.arrayOf(PropTypes.shape({})),
  errors: PropTypes.shape({
    Password: PropTypes.string,
  }),
};

export default CreateUserFormSection;
