import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import QrCode from '../Components/QrCode';
import CodeField from '../../InputFields/CodeField';
import {
  fetchTwoFaKeys,
  postTwoFa,
} from '../../../store/actions/twoFA/twoFAActions';
import { selectAuthUser } from '../../../store/selectors/userSelectors';
import { selectIsLoadingByActionTypes } from '../../../store/selectors/loadingSelectors';
import {
  SAVE_TWO_FA_LOADING,
  TWO_FA_APPLICATION_KEYS_LOADING,
} from '../../../store/actions/twoFA/twoFAActionConstants';
import SectionLoader from '../../Loader/SectionLoader';
import { selectTwoFAApplicationKeys } from '../../../store/selectors/twoFASelector';
import QrCodeFailed from '../Components/QrCodeFailed';
import { securityCodeValidationSchema } from '../../../validation/securityCodeValidationSchema';
import ModalBody from '../../Modal/ModalBody';
import ModalTitle from '../../Modal/ModalTitle';
import Paragraph from '../../Paragraph/Paragraph';
import ModalButtons from '../../Modal/ModalButtons';
import SingleColumnList from '../../TwoColumnList/SingleColumnList';
import useWindowSize from '../../../util/hooks/useIsMobileHook';
import CopyButton from '../../../pages/TwoFA/Components/CopyButton';
import { useBrokerCode } from '../../../util/hooks/useBrokerCode';

const initialValues = {
  SecurityCode: '',
};

const TwoFAQrCode = ({
  handleBack,
  handleNext,
  setTwoFaValues,
  twoFaValue,
  setIsSuccessful,
  setErrorMessage,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const windowSize = useWindowSize();
  const user = useSelector(selectAuthUser);
  const brokerCode = useBrokerCode();
  const { QrCodeImageUrl, ManualEntryKey } = useSelector(
    selectTwoFAApplicationKeys,
  );
  const isLoading = useSelector(
    selectIsLoadingByActionTypes([
      TWO_FA_APPLICATION_KEYS_LOADING,
      SAVE_TWO_FA_LOADING,
    ]),
  );
  const [didKeysFetchFail, setDidKeysFetchFail] = useState(false);

  const { UserUid, Username } = user;
  const { AuthenticationMethodType } = twoFaValue;

  const goBack = () => {
    setTwoFaValues(null);
    handleBack();
  };

  const handleSubmit = (values, { setFieldError }) => {
    const { SecurityCode } = values;
    const requestData = {
      AuthenticationMethodType,
      SecurityCode,
      Username,
    };

    dispatch(
      postTwoFa({
        userUid: UserUid,
        requestData,
        handleNext,
        setFieldError,
        setIsSuccessful,
        setErrorMessage,
      }),
    );
  };

  const getTwoFaKeys = () => {
    dispatch(fetchTwoFaKeys({ userUid: UserUid, setDidKeysFetchFail }));
  };

  useEffect(() => {
    getTwoFaKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const copyEntryKey = (id) => {
    const r = document.createRange();
    r.selectNode(document.getElementById(id));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(r);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
  };

  const isMobile = windowSize.width < 768;

  return (
    <SectionLoader isLoading={isLoading}>
      <ModalBody>
        <ModalTitle>
          {t('twoFa.profileSettings.authentication.title', { brokerCode })}
        </ModalTitle>
        <Paragraph>
          {t('twoFa.profileSettings.authentication.scanQr')}
        </Paragraph>
        <>
          {didKeysFetchFail ? (
            <QrCodeFailed onClick={getTwoFaKeys} />
          ) : (
            <>
              <SingleColumnList alignCenter marginBottom={24}>
                <QrCode QrCodeImageUrl={QrCodeImageUrl} />
                <Paragraph centerText marginBottom={16}>
                  {t('twoFa.profileSettings.authentication.enterManually')}
                </Paragraph>
                <Paragraph marginBottom={8} centerText id="entry-key" bold>
                  {ManualEntryKey?.match(/.{1,4}/g).map((part) => `${part} `)}
                </Paragraph>
                <CopyButton onClick={() => copyEntryKey('entry-key')} />
              </SingleColumnList>
            </>
          )}
        </>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={securityCodeValidationSchema}
        >
          {({ errors, setFieldValue }) => (
            <Form>
              <Paragraph marginBottom={32}>
                {t('twoFa.profileSettings.authentication.enterCodeHere')}
              </Paragraph>
              <CodeField
                fixedSize={isMobile}
                name="SecurityCode"
                errors={errors}
                onCodeChange={(value) => setFieldValue('SecurityCode', value)}
                hideExpireCodeNote
              />
              <ModalButtons
                isHorizontal
                marginTop
                secondaryButtonProps={{ onClick: goBack }}
                primaryButtonProps={{ type: 'submit' }}
              />
            </Form>
          )}
        </Formik>
      </ModalBody>
    </SectionLoader>
  );
};

TwoFAQrCode.propTypes = {
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  setTwoFaValues: PropTypes.func,
  twoFaValue: PropTypes.shape({
    AuthenticationMethodType: PropTypes.string,
  }),
  setIsSuccessful: PropTypes.func,
  setErrorMessage: PropTypes.func,
};

export default TwoFAQrCode;
