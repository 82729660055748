import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import themeColors from '../../assets/styles/themeColors';

const VaultTableContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const VaultTableHeader = styled.div`
  display: grid;
  grid-template-columns: 1.3fr 1fr 1fr;
  grid-column-gap: ${pxToRem(4)};
  background-color: ${themeColors.colorTableHeaderBackground};
  padding: ${pxToRem(3)} 0;
  z-index: 1;
  box-shadow: ${variables.shadow.boxShadow};

  p {
    font-size: ${pxToRem(14)};
    line-height: 1.5;
  }

  > :first-child {
    padding-left: ${pxToRem(8)};
  }

  > :last-child {
    padding-right: ${pxToRem(8)};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    p {
      font-size: ${pxToRemMd(14)};
    }
  }
`;

const VaultTableBody = styled.div`
  > :nth-child(even) {
    background-color: ${themeColors.colorTableEvenRowBackground};
    border-color: ${themeColors.colorTableEvenRowBackground};
  }
`;

const VaultTable = ({ headerLabels, children }) => (
  <VaultTableContainer>
    <VaultTableHeader>
      {headerLabels.map((item) => (
        <p key={item}>{item}</p>
      ))}
    </VaultTableHeader>
    <VaultTableBody>{children}</VaultTableBody>
  </VaultTableContainer>
);

VaultTable.propTypes = {
  headerLabels: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
};

export default VaultTable;
