import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { USERNAME } from '../../../constants/sessionStorage';
import RouterStepByStep from '../../../components/RouterStepByStep/RouterStepByStep';
import {
  fetchTwoFaCodeViaEmail,
  fetchTwoFaCodeViaPhone,
} from '../../../store/actions/twoFA/twoFAActions';
import { retrieveFromSessionStorage } from '../../../util/helpers/sessionStorageHelper';
import TwoFAVerifyIdentityPage from './TwoFAVerifyIdentityPage';
import TwoFAVerifyIdentityPhonePage from './TwoFAVerifyIdentityPhonePage';
import {
  selectTokens,
  selectTwoFactorAuthenticationResponse,
} from '../../../store/selectors/loginSelectors';
import TwoFAHavingTroublePage from './TwoFAHavingTroublePage';
import { twoFaTypesEnum } from '../../../util/enum/api/twoFATypes';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import { TWO_FA_TYPES } from '../../../constants/twoFaConstants';
import { USER_LOGIN_PAGE } from '../../../constants/pages';
import { resetLoginState } from '../../../store/actions/login/loginActions';
import { landingPageHelper } from '../../../util/helpers/landingPageHelper';

const TwoFAAuthenticatePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const username = retrieveFromSessionStorage(USERNAME);
  const currentPath = history.location.pathname;
  const [twoFaValue, setValues] = useState();
  const tokens = useSelector(selectTokens);
  const twoFactorAuthenticationResponse = useSelector(
    selectTwoFactorAuthenticationResponse,
  );

  const getTwoFaCode = (value) => {
    const { AuthenticationMethodType } = twoFactorAuthenticationResponse;

    const parsedAuthenticationMethodType = parseEnumType(
      twoFaTypesEnum,
      AuthenticationMethodType,
    );

    if (parsedAuthenticationMethodType === TWO_FA_TYPES.Phone) {
      dispatch(
        fetchTwoFaCodeViaPhone({
          ...value,
        }),
      );
    }

    if (parsedAuthenticationMethodType === TWO_FA_TYPES.Email) {
      dispatch(
        fetchTwoFaCodeViaEmail({
          ...value,
        }),
      );
    }
  };

  useEffect(() => {
    const { RefreshToken, JwtToken } = tokens;

    if (JwtToken) {
      return history.push({
        pathname: landingPageHelper(),
        state: {
          from: history.location.pathname,
        },
      });
    }

    if (!RefreshToken) {
      dispatch(resetLoginState());
      return history.push(USER_LOGIN_PAGE);
    }
  }, [dispatch, history, tokens]);

  const twoFaAuthenticateSteps = useCallback(
    (goStepBack, goStepForward) => {
      const compProps = {
        goStepBack,
        goStepForward,
        username,
        getTwoFaCode,
        twoFactorAuthenticationResponse,
        twoFaValue,
        setValues,
      };

      return {
        '/verify-identity': <TwoFAVerifyIdentityPage {...compProps} />,
        '/verify-identity-phone': (
          <TwoFAVerifyIdentityPhonePage {...compProps} />
        ),
        '/having-trouble': <TwoFAHavingTroublePage {...compProps} />,
      };
    },
    [twoFactorAuthenticationResponse, twoFaValue], // eslint-disable-line
  );

  return (
    <RouterStepByStep currentPath={currentPath}>
      {({ goStepBack, goStepForward, currentFormStep, goToFirstStep }) =>
        twoFaAuthenticateSteps(
          goStepBack,
          goStepForward,
          currentFormStep,
          goToFirstStep,
          twoFaValue,
          setValues,
        )
      }
    </RouterStepByStep>
  );
};

TwoFAAuthenticatePage.propTypes = {};

export default TwoFAAuthenticatePage;
