import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { buttonizeDiv } from '../../util/buttonizeDiv';
import {
  fetchSingleAccount,
  setCurrentAccount,
} from '../../store/actions/account/accountActions';
import { parseEnumType } from '../../util/helpers/enumMappers';
import {
  accountSubTypeKeys,
  accountTypeKeys,
  IRATrusteesType,
} from '../../util/enum/api/accountTypes';
import { ReactComponent as ChevronRight } from '../../assets/images/svg/chevron-right.svg';
import {
  ACCOUNT_UID,
  IMPERSONATE_USER_UID,
  REGISTRATION_USER_UID,
} from '../../constants/sessionStorage';
import {
  retrieveFromSessionStorage,
  storeInSessionStorage,
} from '../../util/helpers/sessionStorageHelper';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';

const AccountSectionItem = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  cursor: pointer;
  padding: ${pxToRem(12)} ${pxToRem(16)};

  &:not(:last-of-type) {
    border-bottom: 2px solid ${themeColors.colorAccountBorder};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: ${pxToRemMd(12)} ${pxToRemMd(16)};
  }
`;

const AccountSectionInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
`;

const AccountSectionName = styled.p`
  font-size: ${pxToRem(18)};
  line-height: 1.35;
  margin-bottom: ${pxToRem(4)};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(18)};
  }
`;

const AccountSectionCode = styled.p`
  color: ${themeColors.colorTextSecondary};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(16)};
  }
`;

const AccountSectionIconWrapper = styled.div`
  margin-left: ${pxToRem(16)};

  svg {
    width: ${pxToRem(24)};
    color: ${themeColors.colorLabel};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    svg {
      width: ${pxToRemMd(24)};
    }
  }
`;

const AccountSection = ({ accounts, setIsDropdownOpen, setIsAccountOpen }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const impersonateAccountUid = retrieveFromSessionStorage(
    IMPERSONATE_USER_UID,
  );
  const registrationAccountUid = retrieveFromSessionStorage(
    REGISTRATION_USER_UID,
  );

  const handleAccountChange = (account) => {
    const onSuccess = () => {
      history.push(window.location.pathname);
    };

    const { AccountUid } = account;
    storeInSessionStorage(ACCOUNT_UID, AccountUid);
    if (impersonateAccountUid) {
      storeInSessionStorage(IMPERSONATE_USER_UID, AccountUid);
    }
    if (registrationAccountUid) {
      storeInSessionStorage(REGISTRATION_USER_UID, AccountUid);
    }

    dispatch(setCurrentAccount(account));
    dispatch(fetchSingleAccount({ AccountUid, onSuccess }));
    setIsDropdownOpen(false);
    setIsAccountOpen(false);
  };

  if (!accounts) {
    return null;
  }

  return (
    <>
      {accounts.map((account) => (
        <AccountSectionItem
          {...buttonizeDiv(() => handleAccountChange(account))}
          key={account.AccountCode + account.DisplayName}
          data-cy="list-item-account"
        >
          <AccountSectionInfo>
            <AccountSectionName>
              {String(account.DisplayName)
                .replace(`(${account.AccountCode})`, '')
                .trim()}
            </AccountSectionName>
            <AccountSectionCode>
              {account.IRATrusteesType && (
                <span>
                  {t(
                    `account.iraTrusteeTypes.${parseEnumType(
                      IRATrusteesType,
                      account.IRATrusteesType,
                    )}`,
                  )}
                </span>
              )}
              {account.AccountSubType && (
                <span>
                  {t(
                    `account.subtypes.${parseEnumType(
                      accountSubTypeKeys,
                      account.AccountSubType,
                    )}`,
                  )}
                </span>
              )}
              <span>
                {t(
                  `account.types.${parseEnumType(
                    accountTypeKeys,
                    account.AccountType,
                  )}`,
                )}
              </span>{' '}
              &#183; <span>{account.AccountCode}</span>
            </AccountSectionCode>
          </AccountSectionInfo>
          <AccountSectionIconWrapper>
            <ChevronRight />
          </AccountSectionIconWrapper>
        </AccountSectionItem>
      ))}
    </>
  );
};

AccountSection.propTypes = {
  accountType: PropTypes.string,
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      DisplayName: PropTypes.string,
      AccountCode: PropTypes.string,
    }),
  ),
  setIsDropdownOpen: PropTypes.func,
  setIsAccountOpen: PropTypes.func,
};

export default AccountSection;
