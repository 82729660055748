import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  replaceInUrl,
} from './index';
import apiEndpoints from './apiEndpoints';

export const getUserAccountsRequest = (userUid) =>
  getRequest(replaceInUrl(apiEndpoints.users.getAccounts, { userUid }));

export const removeUserRequest = (accountUid, userUid) =>
  deleteRequest(
    replaceInUrl(apiEndpoints.accounts.deleteUser, { accountUid, userUid }),
  );

export const updateUserRequest = (userUid, data, actionType) =>
  patchRequest(
    replaceInUrl(apiEndpoints.users.updateUser, { userUid, actionType }),
    data,
  );

export const updateUserStateRequest = (userUid, data, actionType) =>
  patchRequest(
    replaceInUrl(apiEndpoints.users.updateUser, { userUid, actionType }),
    data,
  );

export const updateUserPassRequest = (userUid, data) =>
  postRequest(
    replaceInUrl(apiEndpoints.users.updateUserPassword, {
      userUid,
    }),
    data,
  );

export const getUserSecurityQuestion = (username) =>
  getRequest(apiEndpoints.authentications.getUserSecurityQuestion, {
    username,
  });

export const postForgottenPasswordRequest = (data) =>
  postRequest(apiEndpoints.authentications.confirmForgotPassword, data);

export const postResetPasswordRequest = (data) =>
  postRequest(apiEndpoints.authentications.resetPassword, data);

export const inviteUserRequest = (data) =>
  postRequest(apiEndpoints.users.invite, data);

export const inviteUserEmailRequest = (data) =>
  getRequest(apiEndpoints.users.email, {
    encryptedPersonEntity: data,
  });
export const getPhoneNumbersRequest = (userUid) =>
  getRequest(replaceInUrl(apiEndpoints.users.getPhoneNumbers, { userUid }));

export const getPhoneNumberRequest = (data) =>
  getRequest(replaceInUrl(apiEndpoints.users.getPhoneNumber, data));

export const getPhoneNumbersLoginRequest = (username) =>
  getRequest(
    replaceInUrl(apiEndpoints.users.getPhoneNumbersLogin, { username }),
  );

export const deletePhoneNumberRequest = ({ userUid, phoneUid }) =>
  deleteRequest(
    replaceInUrl(apiEndpoints.users.deletePhoneNumber, { userUid, phoneUid }),
  );

export const postPhoneNumberRequest = ({ userUid, data }) =>
  postRequest(
    replaceInUrl(apiEndpoints.users.addPhoneNumber, { userUid }),
    data,
  );
