import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { pxToRem } from '../../../assets/styles/helper';
import themeColors from '../../../assets/styles/themeColors';

import Paragraph from '../../Paragraph/Paragraph';

const CountdownWrapper = styled.div`
  flex-direction: row;
  margin-top: ${pxToRem(8)};
  margin-bottom: ${pxToRem(8)};
`;

const CountdownTimerContainer = styled.div`
  background-color: ${themeColors.colorBackgroundSecondary};
  align-items: center;
  border-radius: ${pxToRem(8)};
  padding: ${pxToRem(4)};
`;

const CountdownTimerText = styled(Paragraph)`
  text-align: center;
  font-size: ${pxToRem(24)};
  margin-left: ${pxToRem(8)};
`;

const CountdownText = styled(Paragraph)`
  font-size: ${pxToRem(16)};
  margin-left: ${pxToRem(4)};
`;

const CountdownTimer = ({ unity, counter, loading }) => {
  const { t } = useTranslation();

  return (
    <CountdownWrapper>
      <CountdownTimerContainer>
        <CountdownTimerText variant="primary">
          {loading || counter === 1 ? 0 : counter - 2}
        </CountdownTimerText>
        <CountdownText variant="primary">
          {unity ? t('common.secondToRefresh') : t('common.secondsToRefresh')}
        </CountdownText>
      </CountdownTimerContainer>
    </CountdownWrapper>
  );
};

CountdownTimer.propTypes = {
  unity: PropTypes.bool,
  counter: PropTypes.number,
  loading: PropTypes.bool,
};

export default CountdownTimer;
