import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropType from 'prop-types';
import { ReactComponent as ChevronLeft } from '../../assets/images/svg/chevron-left.svg';
import { ReactComponent as Close } from '../../assets/images/svg/close.svg';
import SectionLoader from '../Loader/SectionLoader';
import {
  ModalBack,
  ModalClose,
  ModalContainer,
  ModalElement,
  ModalHeader,
  ModalTitle,
  ModalBodyWrap,
} from './Modal';
import { filterModal } from '../Filter/Components/FilterComponent';

const FilterModal = ({
  isOpen,
  children,
  goBack,
  close,
  title,
  size,
  isLoading,
  ...props
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <ModalContainer isOpen={isOpen} {...props} css={filterModal}>
      <ModalElement size={size} data-cy="modal-filters">
        <SectionLoader isLoading={isLoading}>
          <ModalHeader>
            {goBack && (
              <ModalBack onClick={() => goBack()}>
                <ChevronLeft />
              </ModalBack>
            )}
            {title && <ModalTitle>{title}</ModalTitle>}
            {close && (
              <ModalClose onClick={() => close(false)}>
                <Close />
              </ModalClose>
            )}
          </ModalHeader>
          <ModalBodyWrap>{children}</ModalBodyWrap>
        </SectionLoader>
      </ModalElement>
    </ModalContainer>,
    document.getElementById('modal-root'),
  );
};

FilterModal.propTypes = {
  children: PropType.node,
  isOpen: PropType.bool,
  goBack: PropType.func,
  close: PropType.func,
  title: PropType.string,
  size: PropType.oneOf(['sm', 'md']),
  isLoading: PropType.bool,
};

export default FilterModal;
