import { useEffect } from 'react';
import { createWorker } from '../../workers';
import { apiDefaultUrl, replaceInUrl } from '../../request';
import apiEndpoints from '../../request/apiEndpoints';
import { PRODUCT_TIERS_FETCH_SUCCESS } from '../../store/actions/products/productActionConstants';
import { JWT_TOKEN } from '../../constants/localStorage';
import { authScopeStringGetHelper } from '../helpers/authScopeHelpers';
import { emptyProductTiers } from '../../store/actions/products/productActions';

export default function useGetPeriodicPriceTiers(
  workerName,
  account,
  location,
  symbol,
  side,
  dispatch,
  canFetch,
  interval = 5000,
  trueTiers = false,
  secondCallback,
) {
  useEffect(() => {
    const token = authScopeStringGetHelper(JWT_TOKEN);

    const accountUid = account.AccountUid;
    if (accountUid && canFetch && location) {
      const worker = createWorker(
        `${workerName}-${accountUid}-${symbol}-${location}-${token}`,
        {
          request: {
            url:
              apiDefaultUrl +
              replaceInUrl(apiEndpoints.products.tiers, {
                accountUid,
                symbol,
                side,
                location,
                trueTiers,
              }),
            method: 'get',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
          actionSuccessType: PRODUCT_TIERS_FETCH_SUCCESS,
          workerInterval: interval,
          periodicCallback: secondCallback,
        },
      );
      const stop = worker.start();
      return () => {
        stop();
        dispatch(emptyProductTiers());
      };
    }
  }, [
    account.AccountUid,
    interval,
    side,
    workerName,
    symbol,
    location,
    dispatch,
    canFetch,
    trueTiers,
    secondCallback,
  ]);
}
