/* eslint-disable no-param-reassign */
import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  EMPTY_PORTFOLIO_TRANSACTION_SINGLE,
  MY_HOLDINGS_CODES_FETCH,
  PORTFOLIO_BALANCES_FETCH,
  PORTFOLIO_HOLDINGS_FETCH,
  PORTFOLIO_PRODUCT_BY_SYMBOL_FETCH,
  PORTFOLIO_TRANSACTIONS_SINGLE_FETCH,
  PORTFOLIO_VALUATION_FETCH,
  PORTFOLIO_MARKET_PRICES_FETCH,
  ACCOUNT_TRANSACTIONS_FETCH,
  PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS,
  PATCH_PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS,
} from '../actions/portfolio/portfolioActionConstants';
import {
  getMyHoldingsCodes,
  getPortfolioBalances,
  getPortfolioHoldingsRequest,
  getPortfolioProductBySymbol,
  getAccountTransactions,
  getPortfolioTransactionSingle,
  getPortfolioValuationRequest,
  getPortfolioMarketPricesRequest,
  getFinancialPortfolioPendingTransactions,
  patchFinancialPortfolioPendingTransactions,
} from '../../request/portfolioRequests';

import {
  fetchMyHoldingsCodesError,
  fetchMyHoldingsCodesSuccess,
  fetchPortfolioBalancesError,
  fetchPortfolioBalancesSuccess,
  fetchPortfolioHoldingsError,
  fetchPortfolioHoldingsSuccess,
  fetchPortfolioProductBySymbolSuccess,
  fetchValuationError,
  fetchValuationSuccess,
  fetchMarketPricesSuccess,
  fetchMarketPricesError,
  portfolioTransactionsSingleError,
  portfolioTransactionsSingleSuccess,
  accountTransactionsSuccess,
  accountTransactionsError,
  portfolioFinancialPendingTransactionsSuccess,
  portfolioFinancialPendingTransactionsError,
  patchPortfolioFinancialPendingTransactionsError,
} from '../actions/portfolio/portfolioActions';
import {
  transactionStatusTypes,
  transactionTypeByKey,
  transactionTypesArray,
} from '../../util/enum/api/transactionTypes';
import { periodTypes } from '../../util/enum/api/portfolioTypes';
import { parseEnumType } from '../../util/helpers/enumMappers';
import { rejectErrorCodeHelper } from '../../util/helpers/rejectErrorCodeHelper';
import { calculateElapsedDays } from '../../util/math/date';

function* getPortfolioValuations({
  accountUid,
  periodType,
  accountCreatedDate,
}) {
  try {
    const numberOfTicks = calculateElapsedDays(accountCreatedDate);
    const { data } = yield call(
      getPortfolioValuationRequest,
      accountUid,
      periodType,
      numberOfTicks,
    );
    const valuation = data;
    yield put(
      fetchValuationSuccess({
        ...valuation,
        MaximumPeriodType: periodTypes[valuation.MaximumPeriodType - 1],
      }),
    );
    yield put(fetchValuationError(''));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchValuationError(errorMessage));
  }
}

function* getPortfolioMarketPrices({ metalType, periodType }) {
  try {
    const { data } = yield call(
      getPortfolioMarketPricesRequest,
      metalType,
      periodType,
    );
    const marketPrices = data;
    yield put(
      fetchMarketPricesSuccess({
        ...marketPrices,
        MaximumPeriodType: periodTypes[marketPrices.MaximumPeriodType - 1],
      }),
    );
    yield put(fetchMarketPricesError(''));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchMarketPricesError(errorMessage));
  }
}

function* fetchPortfolioHoldings({ payload }) {
  try {
    const { data } = yield call(getPortfolioHoldingsRequest, payload);
    yield put(fetchPortfolioHoldingsSuccess(data));
    if (payload?.onSuccess) {
      yield call(payload?.onSuccess, data);
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchPortfolioHoldingsError(errorMessage));
  }
}

function* fetchMyHoldingsCodes({ payload }) {
  try {
    const { data } = yield call(getMyHoldingsCodes, payload.accountUid);
    yield put(fetchMyHoldingsCodesSuccess(data));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchMyHoldingsCodesError(errorMessage));
  }
}

function* fetchPortfolioBalances({ payload }) {
  try {
    const { data } = yield call(getPortfolioBalances, payload.accountUid);
    yield put(fetchPortfolioBalancesSuccess(data));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchPortfolioBalancesError(errorMessage));
  }
}

function* fetchPortfolioProductBySymbol({ payload }) {
  try {
    const { data } = yield call(
      getPortfolioProductBySymbol,
      payload.accountUid,
      payload.symbol,
      payload.isSegregated,
    );
    yield put(fetchPortfolioProductBySymbolSuccess(data));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchPortfolioBalancesError(errorMessage));
  }
}
export function* fetchPortfolioTransactions({ payload }) {
  try {
    const { data } = yield call(getAccountTransactions, payload.accountUid);
    const pl = data.Data.map((transaction) => {
      const t = {};
      t.Fields = transaction.Fields.map((field) =>
        Object.keys(field).reduce((acc, key) => {
          const transactionTypes = transactionTypeByKey[key];
          if (transactionTypes) {
            field[key] = parseEnumType(transactionTypes, field[key]);
          }

          return { ...acc, [key]: field[key] };
        }, {}),
      );
      t.StatusType = parseEnumType(
        Object.keys(transactionStatusTypes),
        transaction.StatusType,
      );
      t.Type = parseEnumType(transactionTypesArray, transaction.Type);
      return { ...transaction, ...t };
    });
    yield put(accountTransactionsSuccess({ Data: pl }));
    yield put(accountTransactionsError(''));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(accountTransactionsError(errorMessage));
  }
}

function* fetchPortfolioSingleTransaction({ payload }) {
  try {
    const { data } = yield call(
      getPortfolioTransactionSingle,
      payload.accountUid,
      payload.transactionUid,
    );
    data.Fields.forEach((field) =>
      Object.keys(field).forEach((key) => {
        const transactionTypes = transactionTypeByKey[key];

        if (transactionTypes) {
          field[key] = parseEnumType(transactionTypes, field[key]);
        }
      }),
    );
    data.StatusType = Object.keys(transactionStatusTypes)[data.StatusType];
    data.Type = transactionTypesArray[data.Type];
    yield put(portfolioTransactionsSingleSuccess(data));
    yield put(portfolioTransactionsSingleError(''));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(portfolioTransactionsSingleError(errorMessage));
  }
}

function* emptySingleTransaction() {
  yield put(portfolioTransactionsSingleSuccess({}));
}

export function* fetchAccountTransactions({ payload }) {
  try {
    const { data } = yield call(
      getAccountTransactions,
      payload.accountUid,
      payload.productSymbol,
      payload.content,
      payload.pageNumber,
      payload.pageSize,
      payload.isSegregated,
    );

    yield put(accountTransactionsSuccess(data));
    yield put(accountTransactionsError(''));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(accountTransactionsError(errorMessage));
  } finally {
    if (payload.onFinally) {
      yield call(payload.onFinally);
    }
  }
}

export function* fetchFinancialPendingPortfolioTransactions({ payload }) {
  try {
    const { data } = yield call(
      getFinancialPortfolioPendingTransactions,
      payload.accountUid,
    );

    yield put(portfolioFinancialPendingTransactionsSuccess(data));
    yield put(portfolioFinancialPendingTransactionsError(''));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(portfolioFinancialPendingTransactionsError(errorMessage));
  }
}

export function* patchFinancialPendingPortfolioTransactions({ payload }) {
  try {
    yield call(
      patchFinancialPortfolioPendingTransactions,
      payload.accountUid,
      payload.depositKey,
      payload.data,
    );

    yield put(patchPortfolioFinancialPendingTransactionsError(''));
    yield call(payload.setError, '');
    yield call(payload.refreshTransactions);
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(patchPortfolioFinancialPendingTransactionsError(errorMessage));
    yield call(payload.setError, errorMessage);
  } finally {
    yield call(payload.handleNext);
  }
}

export default function* portfolioSaga() {
  yield all([
    takeLatest(PORTFOLIO_VALUATION_FETCH, getPortfolioValuations),
    takeLatest(PORTFOLIO_MARKET_PRICES_FETCH, getPortfolioMarketPrices),
    takeLatest(PORTFOLIO_HOLDINGS_FETCH, fetchPortfolioHoldings),
    takeLatest(MY_HOLDINGS_CODES_FETCH, fetchMyHoldingsCodes),
    takeLatest(PORTFOLIO_BALANCES_FETCH, fetchPortfolioBalances),
    takeLatest(
      PORTFOLIO_PRODUCT_BY_SYMBOL_FETCH,
      fetchPortfolioProductBySymbol,
    ),
    takeLatest(
      PORTFOLIO_TRANSACTIONS_SINGLE_FETCH,
      fetchPortfolioSingleTransaction,
    ),
    takeLatest(EMPTY_PORTFOLIO_TRANSACTION_SINGLE, emptySingleTransaction),
    takeLatest(ACCOUNT_TRANSACTIONS_FETCH, fetchAccountTransactions),
    takeLatest(
      PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS,
      fetchFinancialPendingPortfolioTransactions,
    ),
    takeLatest(
      PATCH_PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS,
      patchFinancialPendingPortfolioTransactions,
    ),
  ]);
}
