import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from '../../Modal/Modal';

import StepByStep from '../../StepByStep';
import AddNewUser from './AddNewUser';
import Status from '../Status';

const steps = [AddNewUser, Status];

const AddNewUserModal = ({ isOpen, handleClose }) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        close={handleClose}
        title={t('user.addNew.label')}
        size="sm"
      >
        <StepByStep saveStepDisabled>
          {({ goStepForward, goStepBack }) =>
            steps.map((step) =>
              React.createElement(step, {
                handleBack: goStepBack,
                handleNext: goStepForward,
                handleClose,
                setError,
                hasError: !!error,
                text: error || t('user.addNew.successful'),
                backButtonText: t('common.ok'),
                onButtonClick: handleClose,
              }),
            )
          }
        </StepByStep>
      </Modal>
    </>
  );
};

AddNewUserModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedUser: PropTypes.shape({}),
};

export default AddNewUserModal;
