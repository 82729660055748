import cubicFunction from './math/cubicFunction';

export default function smoothScrollTo(
  topPosition,
  duration = 600,
  offset = 0,
) {
  // if (process.client) {
  const targetPosition = topPosition || 0;
  const startPosition = window.scrollY;
  const distance = targetPosition - startPosition - offset;
  let start = null;
  const step = (timeStamp) => {
    if (!start) {
      start = timeStamp;
    }
    const progress = timeStamp - start;
    window.scrollTo(
      0,
      cubicFunction(progress, startPosition, distance, duration),
    );
    if (progress < duration) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
  // }
}
