import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import Modal from '../../Modal/Modal';

import { ReactComponent as Dollar } from '../../../assets/images/svg/dollar-round.svg';
import ModalBody from '../../Modal/ModalBody';
import SingleColumnList from '../../TwoColumnList/SingleColumnList';
import ModalButtons from '../../Modal/ModalButtons';
import Paragraph from '../../Paragraph/Paragraph';
import Label from '../../Notes/Label';
import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import { variables } from '../../../assets/styles/variables';

const DollarIcon = styled(Dollar)`
  width: ${pxToRem(56)};
  height: ${pxToRem(56)};
  margin: 0 auto ${pxToRem(32)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    width: ${pxToRemMd(56)};
    height: ${pxToRemMd(56)};
    margin: ${pxToRemMd(136)} auto ${pxToRemMd(32)};
  }
`;

const AchDoneAccount = ({ isOpen, close, onButtonClick }) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('achDeposit.chooseAchAddMethod.modalTitle')}
      size="sm"
      isOpen={isOpen}
      close={close}
      skipListeningToLocations
    >
      <ModalBody>
        <SingleColumnList textAlignCenter>
          <DollarIcon />
          <Label
            text={t('achDeposit.smallDeposits')}
            isBold
            marginBottom={32}
          />
          <Paragraph>{t('achDeposit.inTwoThreeDays')}</Paragraph>
          <ModalButtons
            marginTop
            isVertical
            showOnlyPrimary
            primaryButtonProps={{
              label: t('common.done'),
              onClick: onButtonClick,
            }}
          />
        </SingleColumnList>
      </ModalBody>
    </Modal>
  );
};

AchDoneAccount.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  onButtonClick: PropTypes.func,
};

export default AchDoneAccount;
