import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as Caret } from '../../../assets/images/svg/down.svg';
import { FINANCIAL_BALANCES_PAGE } from '../../../constants/pages';
import i18next from '../../../i18n';
import { formatMoneyNumeral } from '../../../util/helpers/numeralHelpers';
import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import themeColors from '../../../assets/styles/themeColors';
import { variables } from '../../../assets/styles/variables';

export const BalanceCardContent = styled.p`
  margin-right: auto;
  text-align: left;
  font-weight: 600;
  padding-right: ${pxToRem(16)};
`;

export const BalanceCardValueStyle = styled.p`
  text-align: right;
  flex-shrink: 0;

  > :last-child {
    text-decoration: underline;
  }
`;

export const BalanceCardIcon = styled.div`
  display: flex;
  justify-self: flex-end;
  flex-shrink: 0;
  transform: rotate(-90deg);
  color: ${themeColors.colorLabel};
`;

export const BalanceCardTitle = styled.span`
  font-weight: 600;
`;

export const BalanceCardSubtitle = styled.span`
  font-size: ${pxToRem(14)};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(14)};
  }
`;

export const BalanceCardContainer = styled(Link)`
  border-radius: ${variables.borderRadius.borderRadius};
  background-color: ${themeColors.colorBackgroundPrimary};
  box-shadow: ${variables.shadow.boxShadow};
  padding: ${pxToRem(15)} ${pxToRem(16)} ${pxToRem(15)} ${pxToRem(24)};
  align-items: center;
  font-size: ${pxToRem(18)};
  line-height: 1.35;
  font-weight: 500;
  letter-spacing: 0;
  cursor: pointer;
  text-decoration: none;
  display: grid;
  grid-template-columns: 1fr auto ${pxToRem(32)};
  grid-gap: ${pxToRem(8)};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: ${pxToRemMd(10)} ${pxToRemMd(16)};
    font-size: ${pxToRemMd(14)};
    line-height: 1.7;
  }
`;

const BalanceCard = ({ availableForTrading }) => (
  <BalanceCardContainer to={FINANCIAL_BALANCES_PAGE}>
    <BalanceCardContent>
      <BalanceCardTitle>
        {i18next.t('balance.balanceCardTitle')}
      </BalanceCardTitle>
      <br />
      <BalanceCardSubtitle>
        {i18next.t('balance.balanceCardSubtitle')}
      </BalanceCardSubtitle>
    </BalanceCardContent>
    <BalanceCardValueStyle>
      <BalanceCardTitle>
        {formatMoneyNumeral(availableForTrading)}
      </BalanceCardTitle>
      <br />
      <BalanceCardSubtitle>
        {i18next.t('balance.balanceCardAmountSub')}
      </BalanceCardSubtitle>
    </BalanceCardValueStyle>
    <BalanceCardIcon>
      <Caret />
    </BalanceCardIcon>
  </BalanceCardContainer>
);

BalanceCard.propTypes = {
  availableForTrading: PropTypes.number,
};

export default BalanceCard;
