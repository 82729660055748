import { connectCurrentRefinements } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ClearRefinementsContainer,
  ClearRefinementsButton,
} from './Components/FilterComponent';

const ClearRefinements = ({ items, refine }) => {
  const { t } = useTranslation();

  const isDisabled = items.length === 0;

  return (
    <ClearRefinementsContainer>
      <ClearRefinementsButton
        onClick={() => {
          refine(items);
        }}
        disabled={isDisabled}
        data-cy="button-clear-filters"
      >
        {t('common.clearFilters')}
      </ClearRefinementsButton>
    </ClearRefinementsContainer>
  );
};

ClearRefinements.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  refine: PropTypes.func,
};

export const CustomClearRefinements = connectCurrentRefinements(
  ClearRefinements,
);
