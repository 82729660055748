import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import smoothScrollTo from '../../util/smoothScroll';
import { ReactComponent as ChevronUp } from '../../assets/images/svg/top.svg';
import Button from '../Button/Button';
import { uFlexCenter, uFlexColumn } from '../../assets/styles/utility';
import { pxToRem } from '../../assets/styles/helper';

export const LoadMoreButton = styled(Button)`
  ${uFlexColumn};
  ${uFlexCenter};
  min-width: ${pxToRem(56)};
  min-height: ${pxToRem(56)};
  flex-shrink: 0;
  text-transform: none;

  > svg {
    ${uFlexCenter};
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
    transform: scale(-1);
  }
`;

const ScrollToTop = ({ scrollToElement }) => {
  const handleScrollToTop = () => {
    if (scrollToElement) {
      smoothScrollTo(scrollToElement);
    }
    smoothScrollTo(0);
  };

  return (
    <LoadMoreButton
      variant="default"
      onClick={handleScrollToTop}
      data-cy="button-to-list-top"
    >
      <ChevronUp />
      Top
    </LoadMoreButton>
  );
};

ScrollToTop.propTypes = {
  scrollToElement: PropTypes.number,
};

export default ScrollToTop;
