import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import HavingTroubleButtonGroup from '../Components/HavingTroubleButtonGroup';
import {
  INVITE_USER_PAGE,
  INVITE_USER_TWO_FA_VERIFY_IDENTITY,
  TWO_FA_VERIFY_IDENTITY,
} from '../../../constants/pages';
import TwoFaPhoneSelectRecieveType from '../Components/TwoFaPhoneSelectRecieveType';
import { fetchTwoFaCodeViaPhone } from '../../../store/actions/twoFA/twoFAActions';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import { twoFaMessageType } from '../../../util/enum/api/twoFATypes';
import { selectIsLoadingByActionType } from '../../../store/selectors/loadingSelectors';
import { TWO_FA_CODE_VIA_PHONE_LOADING } from '../../../store/actions/twoFA/twoFAActionConstants';
import { selectTokens } from '../../../store/selectors/loginSelectors';
import TypeList from '../../../components/TypeList/TypeList';
import SingleColumnList from '../../../components/TwoColumnList/SingleColumnList';
import Paragraph from '../../../components/Paragraph/Paragraph';
import Auth from '../../../components/Auth/Auth';
import AuthCard from '../../../components/Auth/AuthCard';

const TwoFAVerifyIdentityPhonePage = ({
  setValues,
  username,
  twoFactorAuthenticationResponse,
  isInviteUserFlow,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { PhoneUid, MaskedPhone } = twoFactorAuthenticationResponse;
  const isLoading = useSelector(
    selectIsLoadingByActionType(TWO_FA_CODE_VIA_PHONE_LOADING),
  );
  const tokens = useSelector(selectTokens);

  useEffect(() => {
    const { RefreshToken } = tokens;

    if (isInviteUserFlow && !RefreshToken) {
      history.push(INVITE_USER_PAGE);
    }
  }, [tokens]); // eslint-disable-line

  const onFormSubmit = (value) => {
    const { Type } = value;
    const onSuccess = () => {
      setValues({
        phoneMessageType: Type,
      });

      if (isInviteUserFlow) {
        history.push(INVITE_USER_TWO_FA_VERIFY_IDENTITY);
      } else {
        history.push(TWO_FA_VERIFY_IDENTITY);
      }
    };

    dispatch(
      fetchTwoFaCodeViaPhone({
        username,
        phoneUid: PhoneUid,
        phoneMessageType: value.Type,
        onSuccess,
      }),
    );
  };

  return (
    <Auth>
      <AuthCard title={t('twoFa.loginVerify.title')} isLoading={isLoading}>
        {MaskedPhone && (
          <Paragraph marginBottom={56}>
            {t('twoFa.loginVerify.phone.note', {
              phoneNumber: MaskedPhone,
            })}
          </Paragraph>
        )}

        <Formik
          onSubmit={onFormSubmit}
          initialValues={{ Type: parseEnumType(twoFaMessageType, 1) }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <SingleColumnList marginBottom={72} alignCenter>
                <TypeList maxWidth={342}>
                  <TwoFaPhoneSelectRecieveType
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                </TypeList>
              </SingleColumnList>
              <HavingTroubleButtonGroup
                isInviteUserFlow={isInviteUserFlow}
                buttonLabel={t('twoFa.loginVerify.sendCode')}
              />
            </Form>
          )}
        </Formik>
      </AuthCard>
    </Auth>
  );
};

TwoFAVerifyIdentityPhonePage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
  setValues: PropTypes.func,
  twoFactorAuthenticationResponse: PropTypes.oneOfType([
    PropTypes.shape({
      PhoneUid: PropTypes.string,
      MaskedPhone: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  username: PropTypes.string,
  isInviteUserFlow: PropTypes.bool,
};
export default TwoFAVerifyIdentityPhonePage;
