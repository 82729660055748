import React from 'react';
import PropTypes from 'prop-types';
import LoaderContainer from './LoaderContainer';
import LoaderWrap from './LoaderWrap';
import LoaderIcon from './LoaderIcon';

const SectionLoader = ({ children, isLoading }) => (
  <LoaderContainer>
    {children}
    {isLoading && (
      <LoaderWrap data-cy="loading-indicator">
        <LoaderIcon />
      </LoaderWrap>
    )}
  </LoaderContainer>
);

SectionLoader.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
};

export default SectionLoader;
