export const PERIOD = {
  OneHour: '1H',
  OneDay: '1D',
  OneWeek: '1W',
  OneMonth: '1M',
  ThreeMonths: '3M',
  SixMonths: '6M',
  OneYear: '1Y',
  All: 'ALL',
};

export const period = [
  PERIOD.All,
  PERIOD.OneYear,
  PERIOD.SixMonths,
  PERIOD.ThreeMonths,
  PERIOD.OneMonth,
  PERIOD.OneWeek,
  PERIOD.OneDay,
  PERIOD.OneHour,
];

export const periodsData = {
  '1H': 'OneHour',
  '1D': 'OneDay',
  '1W': 'OneWeek',
  '1M': 'OneMonth',
  '3M': 'ThreeMonths',
  '6M': 'SixMonths',
  '1Y': 'OneYear',
  ALL: 'ALL',
};

export const periodToDateDurationMapper = {
  W: 'weeks',
  M: 'months',
  Y: 'years',
};
