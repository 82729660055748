import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connectSortBy } from 'react-instantsearch-dom';
import styled from 'styled-components';
import { buttonizeDiv } from '../../util/buttonizeDiv';
import { ReactComponent as ChevronDown } from '../../assets/images/svg/chevron-down.svg';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import { uTransition } from '../../assets/styles/utility';
import themeColors from '../../assets/styles/themeColors';

export const SortByContainer = styled.div`
  margin-right: ${pxToRem(16)};
  font-size: ${pxToRem(16)};
  position: relative;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    margin-right: 0;
  }
`;

export const DropdownIcon = styled.div`
  margin-left: auto;
  user-select: none;

  &,
  svg {
    color: ${themeColors.colorLabel};
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    ${uTransition};

    &,
    svg {
      color: ${themeColors.colorIconPrimary};
      width: ${pxToRemMd(24)};
      height: ${pxToRemMd(24)};
    }
  }
`;

export const DropdownWrap = styled.div`
  border: 1px solid ${themeColors.colorBorderPrimary};
  border-radius: ${variables.borderRadius.borderRadius};
  overflow: hidden;
  padding: 0 ${pxToRem(12)};
  height: ${pxToRem(33)};
  align-items: center;
  display: flex;
  color: ${themeColors.colorLabel};
  font-size: ${pxToRem(16)};
  cursor: pointer;
  z-index: ${variables.zIndex.indexLg};
  background-color: ${themeColors.colorWhite};
  min-width: ${pxToRem(240)};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    border: none;
    box-shadow: none;
    padding: ${pxToRemMd(12)} ${pxToRemMd(16)};
    font-size: ${pxToRemMd(18)};
    font-weight: 600;
    line-height: 1.35;
    color: ${themeColors.colorTextPrimary};
    height: auto;

    ${(props) =>
      props.isSelectOpen &&
      `
        background-color: ${themeColors.colorBackgroundSecondary};

        ${DropdownIcon} {
          transform: rotate(180deg);
        }
    `};
  }
`;

export const DropdownList = styled.ul`
  position: absolute;
  background-color: ${themeColors.colorWhite};
  top: ${pxToRem(30)};
  margin: 0;
  width: 100%;
  font-size: ${pxToRem(16)};
  color: ${themeColors.colorLabel};
  border: 1px solid ${themeColors.colorBorderPrimary};
  border-top: none;
  border-bottom-left-radius: ${variables.borderRadius.borderRadius};
  border-bottom-right-radius: ${variables.borderRadius.borderRadius};
  z-index: ${variables.zIndex.indexXl};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    position: relative;
    top: 0;
    border: none;
    padding: 0 ${pxToRemMd(16)};
  }
`;

export const DropdownValue = styled.span`
  color: ${themeColors.colorTextPrimary};
  margin: 0 ${pxToRem(6)};
`;

export const DropdownListItem = styled.li`
  ${uTransition};
  padding: ${pxToRem(12)};
  cursor: pointer;

  &:hover {
    border-color: ${themeColors.colorPrimaryLight};
    color: ${themeColors.colorPrimaryLight};
    background-color: ${themeColors.colorInputSelectItemFocused};
  }

  &:active {
    border-color: ${themeColors.colorPrimaryDark};
    color: ${themeColors.colorPrimaryDark};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(16)};
    padding: ${pxToRemMd(8)} 0;

    &:hover {
      font-weight: 500;
    }
  }
`;

const SortBy = ({ items, refine, defaultRefinement, getPrices }) => {
  const [selected, setSelected] = useState(defaultRefinement);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const wrapperRef = useRef(null);
  const selectedRef = useRef(null);

  useEffect(() => {
    items.forEach((item) => item.isRefined && setSelected(item), []);
  });

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClickOutside = (event) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target) &&
      event.target !== selectedRef.current &&
      !selectedRef.current.contains(event.target)
    ) {
      setIsSelectOpen(false);
    }
  };

  const toggleSelect = () => {
    setIsSelectOpen((prevState) => !prevState);
  };

  return (
    <SortByContainer>
      <DropdownWrap
        isSelectOpen={isSelectOpen}
        {...buttonizeDiv(toggleSelect)}
        ref={selectedRef}
        data-cy="button-product-sort"
      >
        Sort:
        <DropdownValue>{selected ? selected.label : ''}</DropdownValue>
        <DropdownIcon>
          <ChevronDown />
        </DropdownIcon>
      </DropdownWrap>
      {isSelectOpen && (
        <DropdownList ref={wrapperRef}>
          {items.map((item) => (
            <DropdownListItem key={item.value}>
              <div
                style={{ fontWeight: item.isRefined ? 'bold' : '' }}
                {...buttonizeDiv((event) => {
                  event.preventDefault();
                  setSelected(item);
                  setIsSelectOpen(false);
                  refine(item.value);
                  setTimeout(() => {
                    getPrices();
                  }, 0);
                })}
                data-cy={item.dataCy}
              >
                {item.label}
              </div>
            </DropdownListItem>
          ))}
        </DropdownList>
      )}
    </SortByContainer>
  );
};

SortBy.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  refine: PropTypes.func,
  defaultRefinement: PropTypes.string,
  getPrices: PropTypes.func,
  dataCy: PropTypes.string,
};

export default connectSortBy(SortBy);
