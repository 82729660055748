import { PORTFOLIO_PAGE } from '../../constants/pages';
import { LANDING_PAGE } from '../../constants/sessionStorage';
import {
  removeFromSessionStorage,
  retrieveFromSessionStorage,
} from './sessionStorageHelper';

export const landingPageHelper = () => {
  const landingPage = retrieveFromSessionStorage(LANDING_PAGE);

  if (landingPage) {
    const page = landingPage;
    removeFromSessionStorage(LANDING_PAGE);
    return page;
  }

  return PORTFOLIO_PAGE;
};
