import styled from 'styled-components';
import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import themeColors from '../../../assets/styles/themeColors';
import {
  uFlexCenter,
  uFlexColumn,
  uHide,
  uOutlineNone,
} from '../../../assets/styles/utility';
import { variables } from '../../../assets/styles/variables';

export const InputWrap = styled.div`
  ${uFlexColumn};
  position: relative;
  ${({ flex }) => flex && `flex:${pxToRem(flex)}`};
`;

export const InputHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${pxToRem(4)};
`;

export const InputLabel = styled.label`
  color: ${themeColors.colorLabel};
  font-size: ${pxToRem(16)};
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.75;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(16)};
  }
`;

export const InputLink = styled.div`
  a,
  span {
    color: ${themeColors.colorTextSecondary};
    font-size: ${pxToRem(14)};
    letter-spacing: 0;
    line-height: 1.15;
    text-decoration: underline;
    cursor: pointer;
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    a,
    span {
      font-size: ${pxToRemMd(14)};
    }
  }
`;

export const InputError = styled.div`
  ${uFlexColumn};
  color: ${themeColors.colorError};
  font-size: ${pxToRem(14)};
  line-height: 1.35;
  font-weight: 500;
  margin: ${pxToRem(4)} 0;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(14)};
  }
`;

const IconWrap = styled.div`
  ${uFlexCenter}
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: ${pxToRem(24)};
  height: ${pxToRem(24)};

  svg {
    width: ${pxToRem(24)};
    height: ${pxToRem(24)};
  }
`;

export const SearchIconWrap = styled(IconWrap)`
  margin-right: ${pxToRem(12)};
`;

export const CapsLocksIconWrap = styled(IconWrap)`
  margin-right: ${pxToRem(40)};
`;

export const PasswordIconWrap = styled(IconWrap)`
  margin-right: ${pxToRem(12)};
`;

export const InputFieldWrap = styled.div`
  width: 100%;
  position: relative;
`;

export const InputField = styled.input`
  ${uOutlineNone};
  border: ${({ isError }) =>
    isError
      ? `1px solid ${themeColors.colorError}`
      : `1px solid ${themeColors.colorInputBorder}`};
  border-radius: ${variables.borderRadius.borderRadius};
  font-size: ${pxToRem(16)};
  line-height: 1.75;
  height: ${pxToRem(50)};
  padding: 0 ${pxToRem(12)};
  color: ${themeColors.colorInputPlaceholder};
  background-color: ${themeColors.colorInputBackground};
  width: 100%;
  text-align: ${({ centerText }) => (centerText ? 'center' : 'left')};

  ${(props) =>
    props.type === 'number' &&
    `
    font-weight: 600;
  `};

  &:disabled {
    background-color: ${themeColors.colorInputBackgroundDisabled};
  }

  &:focus {
    border-color: ${({ isError }) =>
      isError ? themeColors.colorError : themeColors.colorInputBorderFocused};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(16)};
  }
`;

export const InputHidden = styled.div`
  ${uHide};
`;
