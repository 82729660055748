import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as ChevronRight } from '../../assets/images/svg/chevron-right.svg';
import { buttonizeDiv } from '../../util/buttonizeDiv';
import Note from '../Notes/Note';
import Paragraph from '../Paragraph/Paragraph';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { uFlexCenter, uFlexColumn } from '../../assets/styles/utility';
import { variables } from '../../assets/styles/variables';
import themeColors from '../../assets/styles/themeColors';
import { ReactComponent as Lock } from '../../assets/images/svg/lock.svg';

const getTypeCardContainerStyles = (isDisabled, isSelected) => {
  if (isDisabled) {
    return `
    opacity: 0.5;
    cursor: not-allowed;
    `;
  }

  if (isSelected) {
    return `border-color: ${themeColors.colorPrimary};`;
  }
};

export const TypeCardContainer = styled.div`
  ${uFlexColumn};
  padding: ${pxToRem(10)} ${pxToRem(12)};
  box-shadow: ${variables.shadow.boxShadow};
  background-color: ${themeColors.colorBackgroundPrimary};
  border-radius: ${variables.borderRadius.borderRadius};
  cursor: pointer;
  border: 1px solid transparent;
  ${({ disabled, isSelected }) =>
    getTypeCardContainerStyles(disabled, isSelected)}

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(10)} ${pxToRemMd(12)} ${pxToRemMd(24)};
  }
`;

export const TypeCardHeader = styled(Paragraph)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TypeCardIconWrap = styled(ChevronRight)`
  ${uFlexCenter}
  margin-left: ${pxToRem(8)};
  width: ${pxToRem(16)};
  height: ${pxToRem(16)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-left: ${pxToRemMd(8)};

    width: ${pxToRemMd(16)};
    height: ${pxToRemMd(16)};
  }
`;

export const AppovedAccountRequiredNote = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
export const LockIconWrap = styled.div`
  &,
  svg {
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
    fill: ${themeColors.colorError};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    &,
    svg {
      width: ${pxToRemMd(16)};
      height: ${pxToRemMd(16)};
    }
  }
`;

const TypeCard = ({
  title,
  highlight,
  description,
  note,
  disabled,
  onClick,
  isSelected,
  limit,
  approvedAccountRequired,
}) => (
  <TypeCardContainer
    {...buttonizeDiv(disabled ? undefined : onClick)}
    disabled={disabled}
    isSelected={isSelected}
  >
    <TypeCardHeader bold marginBottom={!highlight ? 24 : 0}>
      {title}
      <TypeCardIconWrap />
    </TypeCardHeader>
    {highlight && (
      <Paragraph bold fontSize={14} isPrimaryColor marginBottom={16}>
        {highlight}
      </Paragraph>
    )}
    {description && <Paragraph>{description}</Paragraph>}
    {note && <Note text={note} bold italic marginTop={16} limit={limit} />}
    {approvedAccountRequired && (
      <AppovedAccountRequiredNote>
        <LockIconWrap>
          <Lock />
        </LockIconWrap>
        <Note text="Approved account required" isError bold limit={limit} />
      </AppovedAccountRequiredNote>
    )}
  </TypeCardContainer>
);

TypeCard.propTypes = {
  title: PropTypes.string,
  highlight: PropTypes.string,
  description: PropTypes.string,
  note: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  limit: PropTypes.bool,
  approvedAccountRequired: PropTypes.bool,
};

TypeCard.defaultProps = {
  onClick: () => {},
};
export default TypeCard;
