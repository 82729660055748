import React from 'react';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';
import Support from '../../Support/Support';
import Modal from '../../Modal/Modal';
import ModalBody from '../../Modal/ModalBody';

const SupportModal = ({
  className,
  isOpen,
  goBack,
  close,
  overModal,
  avoidClearingStorage,
  handleOutsideClick,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      goBack={goBack}
      className={className}
      close={close}
      title={t(`support.contactSupport`)}
      size="lg"
      overModal={overModal}
      avoidClearingStorage={avoidClearingStorage}
      handleOutsideClick={handleOutsideClick}
      dataCy="container-support-modal"
    >
      <ModalBody>
        <Support />
      </ModalBody>
    </Modal>
  );
};

SupportModal.propTypes = {
  isOpen: PropType.bool,
  goBack: PropType.func,
  close: PropType.func,
  className: PropType.string,
  overModal: PropType.bool,
  avoidClearingStorage: PropType.bool,
  handleOutsideClick: PropType.bool,
};

export default SupportModal;
