import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as ExecutedIcon } from '../../../assets/images/svg/executed.svg';
import { ReactComponent as PendingIcon } from '../../../assets/images/svg/pending.svg';
import { ReactComponent as SettledIcon } from '../../../assets/images/svg/settled.svg';
import { ReactComponent as ShippedIcon } from '../../../assets/images/svg/shipped.svg';
import { ReactComponent as PaidIcon } from '../../../assets/images/svg/paid.svg';
import { ReactComponent as NotExecuted } from '../../../assets/images/svg/notExecuted.svg';
import { ReactComponent as Returned } from '../../../assets/images/svg/returned.svg';
import { ReactComponent as Hold } from '../../../assets/images/svg/hold.svg';
import { ReactComponent as Transit } from '../../../assets/images/svg/transit.svg';

import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import themeColors from '../../../assets/styles/themeColors';
import { variables } from '../../../assets/styles/variables';
import { uTextEllipsis } from '../../../assets/styles/utility';

const getStatusContainerStyles = ({ variant }) => {
  switch (variant) {
    case 'Settled':
    case 'Executed':
      return `
      svg {
        color: ${themeColors.colorShippmentSuccess};
      }
    `;

    case 'Pending':
      return `
      svg {
        color: ${themeColors.colorShippmentPending};
      }
    `;

    case 'Shipped':
    case 'InTransit':
      return `
      svg {
        color: ${themeColors.colorShippmentShipped};
      }
    `;

    case 'NotExecuted':
    case 'Rejected':
      return `
      svg {
        color: ${themeColors.colorShippmentFailed};
      }
    `;

    default:
      break;
  }
};

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  max-width: ${pxToRem(226)};

  ${(props) => getStatusContainerStyles(props)}
`;

export const StatusIconWrap = styled.div`
  margin-right: ${pxToRem(16)};

  &,
  svg {
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    &,
    svg {
      width: ${pxToRemMd(16)};
      height: ${pxToRemMd(16)};
    }
  }

  ${mediaBelow(variables.breakpoints.bpSm)} {
    margin-right: 0;
  }
`;

export const StatusLabel = styled.p`
  ${uTextEllipsis};
  min-width: 0;
  color: ${themeColors.colorTextSecondary};
  font-weight: 500;

  ${mediaBelow(variables.breakpoints.bpSm)} {
    display: none;
  }
`;

const StatusElement = ({ icon, label, variant }) => (
  <StatusContainer variant={variant} data-cy="row-status">
    <StatusIconWrap>{icon}</StatusIconWrap>
    <StatusLabel>{label}</StatusLabel>
  </StatusContainer>
);

const Status = ({ status }) => {
  const { t } = useTranslation();

  const statusComponent = (status) => {
    switch (status) {
      case 'Executed':
        return (
          <StatusElement
            variant="Executed"
            label={t('status.executed')}
            icon={<ExecutedIcon />}
          />
        );
      case 'Completed':
        return (
          <StatusElement
            variant="Executed"
            label={t('status.completed')}
            icon={<ExecutedIcon />}
          />
        );
      case 'Pending':
        return (
          <StatusElement
            variant="Pending"
            label={t('status.pending')}
            icon={<PendingIcon />}
          />
        );
      case 'PendingExecution':
        return (
          <StatusElement
            variant="Pending"
            label={t('status.pendingExecution')}
            icon={<PendingIcon />}
          />
        );

      case 'Settled':
        return (
          <StatusElement
            variant="Settled"
            label={t('status.settled')}
            icon={<SettledIcon />}
          />
        );
      case 'Shipped':
        return (
          <StatusElement
            variant="Shipped"
            label={t('status.shipped')}
            icon={<ShippedIcon />}
          />
        );
      case 'Paid':
        return (
          <StatusElement
            variant="Executed"
            label={t('status.paidInFull')}
            icon={<PaidIcon />}
          />
        );
      case 'NotExecuted':
        return (
          <StatusElement
            variant="NotExecuted"
            label={t('status.notExecuted')}
            icon={<NotExecuted />}
          />
        );

      case 'PartiallyExecuted':
        return (
          <StatusElement
            variant="Pending"
            label={t('status.partiallyExecuted')}
            icon={<ExecutedIcon />}
          />
        );

      case 'PendingFineWeight':
        return (
          <StatusElement
            variant="Pending"
            label={t('status.pendingFineWeight')}
            icon={<PendingIcon />}
          />
        );

      case 'Canceled':
        return (
          <StatusElement
            variant="Executed"
            label={t('status.paid')}
            icon={<PaidIcon />}
          />
        );

      case 'PendingShipment':
        return (
          <StatusElement
            variant="Pending"
            label={t('status.pendingShipment')}
            icon={<ShippedIcon />}
          />
        );

      case 'PendingPickUp':
        return (
          <StatusElement
            variant="Executed"
            label={t('status.paid')}
            icon={<PaidIcon />}
          />
        );

      case 'Returned':
        return (
          <StatusElement
            variant="Rejected"
            label={t('status.returned')}
            icon={<Returned />}
          />
        );
      case 'PendingSettlementOfBuy':
        return (
          <StatusElement
            variant="Pending"
            label={t('status.pendingSettlement')}
            icon={<ExecutedIcon />}
          />
        );

      case 'PendingExecutionOfBuy':
        return (
          <StatusElement
            variant="Pending"
            label={t('status.pendingExecution')}
            icon={<PendingIcon />}
          />
        );

      case 'CompletedOnHold':
        return (
          <StatusElement
            variant="Pending"
            label={t('status.onHold')}
            icon={<Hold />}
          />
        );

      case 'Rejected':
        return (
          <StatusElement
            variant="Rejected"
            label={t('status.rejected')}
            icon={<NotExecuted />}
          />
        );

      case 'PaidInFull':
        return (
          <StatusElement
            variant="Executed"
            label={t('status.paid')}
            icon={<PaidIcon />}
          />
        );

      case 'PartiallyPaid':
        return (
          <StatusElement
            variant="Pending"
            label={t('status.partiallyPaid')}
            icon={<PaidIcon />}
          />
        );

      case 'PendingPayment':
        return (
          <StatusElement
            variant="Pending"
            label={t('status.pendingPayment')}
            icon={<PaidIcon />}
          />
        );

      case 'InGracePeriod':
        return (
          <StatusElement
            variant="Pending"
            label={t('status.inGrace')}
            icon={<PendingIcon />}
          />
        );

      case 'InternalError':
        return (
          <StatusElement
            variant="NotExecuted"
            label={t('status.notExecuted')}
            icon={<NotExecuted />}
          />
        );

      case 'InTransit':
        return (
          <StatusElement
            variant="InTransit"
            label={t('status.inTransit')}
            icon={<Transit />}
          />
        );

      default:
        return <>{status}</>;
    }
  };
  return statusComponent(status);
};

Status.propTypes = {
  status: PropTypes.string,
  className: PropTypes.string,
};

StatusElement.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string,
  variant: PropTypes.string,
};

export default Status;
