import {
  createErrorType,
  createFetchType,
  createLoadingType,
  createSetType,
  createSubmitType,
  createSuccessType,
} from '../actionHelpers';

// product prices
const PRODUCTS_PRICES_SCOPE = 'PRODUCTS_PRICES';

export const PRODUCTS_PRICES_FETCH = createFetchType(PRODUCTS_PRICES_SCOPE);
export const PRODUCTS_PRICES_FETCH_SUCCESS = createSuccessType(
  PRODUCTS_PRICES_SCOPE,
);
export const PRODUCTS_PRICES_FETCH_ERROR = createErrorType(
  PRODUCTS_PRICES_SCOPE,
);
export const PRODUCT_PRICES_LOADING = createLoadingType(PRODUCTS_PRICES_SCOPE);

// product tiers
const PRODUCT_TIERS_SCOPE = 'PRODUCT_TIERS';

export const PRODUCT_TIERS_EMPTY = createSetType(PRODUCT_TIERS_SCOPE);
export const PRODUCT_TIERS_FETCH = createFetchType(PRODUCT_TIERS_SCOPE);
export const PRODUCT_TIERS_FETCH_SUCCESS = createSuccessType(
  PRODUCT_TIERS_SCOPE,
);
export const PRODUCT_TIERS_FETCH_ERROR = createErrorType(PRODUCT_TIERS_SCOPE);
export const PRODUCT_TIERS_LOADING = createLoadingType(PRODUCT_TIERS_SCOPE);

const PRODUCT_SYMBOL_PRICES_SCOPE = 'PRODUCT_SYMBOL_PRICES';

export const PRODUCT_SYMBOL_PRICES_FETCH = createFetchType(
  PRODUCT_SYMBOL_PRICES_SCOPE,
);
export const PRODUCT_SYMBOL_PRICES_FETCH_SUCCESS = createSuccessType(
  PRODUCT_SYMBOL_PRICES_SCOPE,
);
export const PRODUCT_SYMBOL_PRICES_FETCH_ERROR = createErrorType(
  PRODUCT_SYMBOL_PRICES_SCOPE,
);
export const PRODUCT_SYMBOL_PRICES_LOADING = createLoadingType(
  PRODUCT_SYMBOL_PRICES_SCOPE,
);

const PRODUCT_FOR_REVIEW_SCOPE = 'PRODUCT_FOR_REVIEW';
export const PRODUCT_FOR_REVIEW_SET = createSetType(PRODUCT_FOR_REVIEW_SCOPE);

// deliver from storage
const VERIFY_DELIVER_FROM_STORAGE_SCOPE = 'VERIFY_DELIVER_FROM_STORAGE';
const DELIVER_FROM_STORAGE_SCOPE = 'DELIVER_FROM_STORAGE';

export const VERIFY_DELIVER_FROM_STORAGE_DATA = createSubmitType(
  VERIFY_DELIVER_FROM_STORAGE_SCOPE,
);
export const VERIFY_DELIVER_FROM_STORAGE_DATA_SUCCESS = createSuccessType(
  VERIFY_DELIVER_FROM_STORAGE_SCOPE,
);
export const VERIFY_DELIVER_FROM_STORAGE_DATA_ERROR = createErrorType(
  VERIFY_DELIVER_FROM_STORAGE_SCOPE,
);
export const DELIVER_FROM_STORAGE_SUBMIT = createSubmitType(
  DELIVER_FROM_STORAGE_SCOPE,
);

// fractional vault convert products

const CONVERT_PRODUCT_SCOPE = 'CONVERT_PRODUCT';
export const CONVERT_PRODUCT_FETCH = createFetchType(CONVERT_PRODUCT_SCOPE);
export const CONVERT_PRODUCT_FETCH_SUCCESS = createSuccessType(
  CONVERT_PRODUCT_SCOPE,
);
export const CONVERT_PRODUCT_FETCH_ERROR = createErrorType(
  CONVERT_PRODUCT_SCOPE,
);
export const CONVERT_PRODUCT_FETCH_LOADING = createLoadingType(
  CONVERT_PRODUCT_SCOPE,
);
