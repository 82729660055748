import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import { useHistory } from 'react-router';

import { useTranslation } from 'react-i18next';
import { fetchBankAccounts } from '../../store/actions/bankAccount/bankAccountActions';
import {
  selectPendingAchBankAccounts,
  selectPendingWireBankAccounts,
} from '../../store/selectors/bankAccountSelectors';
import Modal from '../Modal/Modal';
import { BANK_ACCOUNTS_PAGE } from '../../constants/pages';
import SectionLoader from '../Loader/SectionLoader';
import { selectIsLoadingByActionType } from '../../store/selectors/loadingSelectors';
import { BANK_ACCOUNT_LOADING } from '../../store/actions/bankAccount/bankAccountActionConstants';
import {
  closeWizardContent,
  deleteWizardKey,
} from '../../util/helpers/wizardHelpers';
import { IS_CHECK_ACCOUNT_MODAL_OPEN } from '../../constants/sessionStorage';
import useCheckPermissions from '../../util/hooks/useCheckPermissions';
import { useBrokerName } from '../../util/hooks/userBrokerName';
import ModalBody from '../Modal/ModalBody';
import Paragraph from '../Paragraph/Paragraph';
import Label from '../Notes/Label';
import ModalButtons from '../Modal/ModalButtons';
import InformationList from '../InformationList/InformationList';
import InformationListItem from '../InformationList/InformationListItem';

const PendingBankAccountsModal = ({
  accountUid,
  isOpen,
  setIsOpen,
  overModal,
  handleClose,
  isWire,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const brokerName = useBrokerName();
  const bankAccounts = useSelector(
    isWire ? selectPendingWireBankAccounts : selectPendingAchBankAccounts,
  );
  const { displayStandardOptionsPermission } = useCheckPermissions();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchBankAccounts({ accountUid }));
  }, []); // eslint-disable-line

  const isLoading = useSelector(
    selectIsLoadingByActionType(BANK_ACCOUNT_LOADING),
  );

  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      overModal={overModal}
      close={() => {
        handleClose();
        setIsOpen();
      }}
      title={t('standardDepositWizard.achDeposit.dontSeeYourBankAccount')}
    >
      <SectionLoader isLoading={isLoading}>
        <ModalBody>
          <Paragraph marginBottom={24}>
            {t('standardDepositWizard.achDeposit.connect', { brokerName })}
          </Paragraph>
          <Label
            marginBottom={8}
            text={
              isWire
                ? t(
                    'standardDepositWizard.wireDeposit.pendingWireWithdrawalBankAccounts',
                  )
                : t(
                    'standardDepositWizard.achDeposit.pendingAchDepositBankAccounts',
                  )
            }
          />
          <InformationList>
            {bankAccounts.map(({ BankAccountUid, BankName, AccountMask }) => (
              <InformationListItem
                noValue
                withBackground
                key={BankAccountUid}
                label={`${BankName}, ${
                  Array(8).fill('*').join('') + AccountMask
                }`}
              />
            ))}
          </InformationList>
          <ModalButtons
            isVertical
            marginTop
            primaryButtonProps={{
              label: i18n.t('standardDepositWizard.achDeposit.allBankAccounts'),
              disabled: !displayStandardOptionsPermission,
              onClick: () => {
                closeWizardContent();
                history.push(BANK_ACCOUNTS_PAGE);
              },
            }}
            secondaryButtonProps={{
              onClick: () => {
                deleteWizardKey(IS_CHECK_ACCOUNT_MODAL_OPEN);
                setIsOpen(false);
              },
            }}
          />
        </ModalBody>
      </SectionLoader>
    </Modal>
  );
};

PendingBankAccountsModal.propTypes = {
  accountUid: PropTypes.string,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  overModal: PropTypes.bool,
  handleClose: PropTypes.func,
  isWire: PropTypes.bool,
};

export default PendingBankAccountsModal;
