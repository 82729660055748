import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as AlertTriangle } from '../../assets/images/svg/alert-triangle.svg';
import { getCurrentAccount } from '../../store/selectors/accountSelectors';
import useCheckPermissions from '../../util/hooks/useCheckPermissions';
import Section from '../Section/Section';
import Banner from './Banner';
import themeColors from '../../assets/styles/themeColors';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import { useBrokerName } from '../../util/hooks/userBrokerName';

export const RestrictionBannerContainer = styled(Section)`
  background-color: ${themeColors.colorTextPrimary};
  padding-top: ${pxToRem(14)};
  padding-bottom: ${pxToRem(14)};
  display: flex;
  align-items: center;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: ${pxToRemMd(12)} ${pxToRemMd(16)};
    font-size: ${pxToRemMd(14)};
  }
`;

export const RestrictionBannerIcon = styled(AlertTriangle)`
  margin-right: ${pxToRem(16)};

  &,
  svg {
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};
    color: ${themeColors.colorTextContrast};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    margin-right: ${pxToRemMd(20)};

    &,
    svg {
      width: ${pxToRemMd(20)};
      height: ${pxToRemMd(20)};
    }
  }
`;

export const RestrictionBannerText = styled.p`
  color: ${themeColors.colorTextLink};
  font-size: ${pxToRem(14)};
  line-height: 1.45;
  font-weight: 600;
`;

const RestrictionBanner = () => {
  const { t } = useTranslation();
  const currentAccount = useSelector(getCurrentAccount);
  const accountStatus = currentAccount?.AccountStatus;
  const {
    allowAccountChangesPermission,
    displayBasicAccountOptionsPermission,
  } = useCheckPermissions();
  const brokerName = useBrokerName();

  if (allowAccountChangesPermission || displayBasicAccountOptionsPermission) {
    return null;
  }

  if (accountStatus === 4 || accountStatus === 1) {
    return (
      <Banner
        variant="pending"
        text={t('restriction.nonApprovedAccount.text')}
        subtext={t('restriction.nonApprovedAccount.subtext', { brokerName })}
      />
    );
  }

  return (
    <RestrictionBannerContainer>
      <RestrictionBannerIcon />
      <RestrictionBannerText>{t('restriction.warning')}</RestrictionBannerText>
    </RestrictionBannerContainer>
  );
};

export default RestrictionBanner;
