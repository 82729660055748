import { getRequest, replaceInUrl, postRequest, deleteRequest } from './index';
import apiEndpoints from './apiEndpoints';

export const getBankAccountsRequest = (accountUid, type, active) =>
  getRequest(replaceInUrl(apiEndpoints.bankAccounts.get, { accountUid }), {
    type,
    active,
  });

export const getSingleBankAccount = ({ accountUid, bankAccountUid, type }) =>
  getRequest(
    replaceInUrl(apiEndpoints.bankAccounts.getBankAccount, {
      accountUid,
      bankAccountUid,
      type,
    }),
  );

export const deleteBankAccountsRequest = (accountUid, bankAccountUid, type) =>
  deleteRequest(
    replaceInUrl(apiEndpoints.bankAccounts.deleteAccount, {
      accountUid,
      bankAccountUid,
      type,
    }),
  );

export const getBankDetailsByRoutingNum = (routingNumber) =>
  getRequest(
    replaceInUrl(apiEndpoints.bankAccounts.getBankDetailsByRoutingNumber, {
      routingNumber,
    }),
  );

export const postNewAccount = (accountUid, data) =>
  postRequest(
    replaceInUrl(apiEndpoints.bankAccounts.newAccount, {
      accountUid,
    }),
    data,
  );

export const verifyBankAccountRequest = (accountUid, bankAccountUid, data) =>
  postRequest(
    replaceInUrl(apiEndpoints.bankAccounts.verify, {
      accountUid,
      bankAccountUid,
    }),
    data,
  );

export const getBankAccountsByTypeRequest = (accountUid, type) =>
  getRequest(
    replaceInUrl(apiEndpoints.bankAccounts.getBankAccountsByType, {
      accountUid,
      type,
    }),
  );

export const fetchObiMessagesRequest = (bankAccountUid) =>
  getRequest(
    replaceInUrl(apiEndpoints.bankAccounts.obiMessages, { bankAccountUid }),
  );
