/* eslint-disable no-restricted-globals */
const worker = () => {
  self.getData = () => {
    new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          valuation: 2132840.2,
          ticks: [],
          maximumPeriodType: 'OneYear',
        });
      }, 500);
    }).then((data) => postMessage(data));
  };
  self.workerMethods = {
    GET_DATA: self.getData,
  };

  self.executeWorkerMethod = function workerMethod(method, data) {
    const workerMethod = self.workerMethods[method];
    if (!workerMethod) {
      // eslint-disable-next-line no-console
      console.error(`No worker method '${method}' specified.`);
    }

    return workerMethod(data);
  };

  onmessage = function onMessage({ data }) {
    self.executeWorkerMethod(data.method, data.data);
  };
};

export default worker;
