import createReducer from '../../utils/createReducer';
import {
  MAXIMUM_PERIOD_TYPE_SET,
  PORTFOLIO_VALUATION_SUCCESS,
  PORTFOLIO_VALUATION_ERROR,
  PORTFOLIO_MARKET_PRICES_ERROR,
  PORTFOLIO_MARKET_PRICES_SUCCESS,
  CLEAR_CHART_ERROR,
} from '../../actions/portfolio/portfolioActionConstants';

const initialState = {
  TotalValuation: 0,
  Ticks: [],
  MaximumPeriodType: 'OneYear',
  isMock: false,
  errorMessage: '',
};

export default createReducer(
  {
    [PORTFOLIO_VALUATION_SUCCESS]: updateValuation,
    [PORTFOLIO_VALUATION_ERROR]: updateValuationError,
    [PORTFOLIO_MARKET_PRICES_ERROR]: updateMarketPricesError,
    [PORTFOLIO_MARKET_PRICES_SUCCESS]: updateMarketPrices,
    [CLEAR_CHART_ERROR]: clearChartError,
    [MAXIMUM_PERIOD_TYPE_SET]: setMaximumPeriodType,
  },
  initialState,
);

function updateValuation(state, action) {
  return {
    ...state,
    ...action.payload,
    Ticks: action.payload.TotalTicks,
  };
}

function updateValuationError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}

function updateMarketPrices(state, action) {
  return {
    ...state,
    ...action.payload,
  };
}

function updateMarketPricesError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}

function setMaximumPeriodType(state, action) {
  return {
    ...state,
    ...action.payload,
  };
}

function clearChartError(state) {
  return {
    ...state,
    errorMessage: '',
  };
}
