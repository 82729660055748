import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import {
  buyProductForDeliverySuccess,
  buyProductForStorageError,
  buyProductForStorageSuccess,
  updateAchDepositError,
  updateAchDepositSuccess,
  updateCheckWithdrawalError,
  updateCheckWithdrawalSuccess,
  updateWireWithdrawalError,
  updateWireWithdrawalSuccess,
  verifyBuyForDeliverySuccess,
  verifyBuyForDeliveryError,
  getQuotesForOrderStorageBuySuccess,
  getQuotesFoOrderStorageBuyError,
  getQuotesForOrderDeliveryBuySuccess,
  getQuotesFoOrderDeliveryBuyError,
  executeQuotesForBuyStorageSuccess,
  executeQuotesFoBuyStorageError,
  getQuotesForSellSuccess,
  getQuotesForSellError,
  executeQuotesForSellSuccess,
  executeQuotesForSellError,
} from '../actions/orders/orderActions';
import {
  BUY_PRODUCT_FOR_DELIVERY,
  BUY_PRODUCT_FOR_STORAGE,
  FRACTIONAL_CONVERSION_SUBMIT,
  IRA_ENTRUST_WITHDRAW_SUBMIT,
  IRA_FEE_WITHDRAWAL_SUBMIT,
  UPDATE_ACH_DEPOSIT,
  UPDATE_CHECK_WITHDRAWAL,
  UPDATE_WIRE_WITHDRAWAL,
  VERIFY_BUY_FOR_DELIVERY,
  GET_QUOTES_FOR_BUY_STORAGE,
  GET_QUOTES_FOR_ORDER_DELIVERY_BUY,
  EXECUTE_QUOTES_FOR_BUY_STORAGE,
  GET_QUOTES_FOR_SELL,
  EXECUTE_QUOTES_FOR_SELL,
} from '../actions/orders/orderActionConstants';
import {
  buyProductForDeliveryRequest,
  buyProductForStorageRequest,
  entrustWithdrawalRequestDistribution,
  entrustWithdrawalRequestTransfer,
  executeQuotesForBuyStorage,
  fractionalConversionRequest,
  getQuotesForBuyStorage,
  getQuotesForDelivery,
  postAchDepositRequest,
  sellProductFromStorageRequest,
  submitIraFeeWithdrawal,
  updateCheckWithdrawalRequest,
  updateWireWithdrawalRequest,
  verifyBuyForDeliveryRequest,
  getQuotesForSell,
  executeQuotesForSell,
} from '../../request/orderRequest';
import { SELL_PRODUCT_FROM_STORAGE } from '../actions/sell/sellWizardActionConstants';
import {
  sellProductFromStorageError,
  sellProductFromStorageSuccess,
} from '../actions/sell/sellWizardActions';
import i18next from '../../i18n';
import { TYPE_DISTRIBUTION, TYPE_TRANSFER_OUT } from '../../util/constants';
import { setWizardContent } from '../../util/helpers/wizardHelpers';
import { fetchPortfolioProductBySymbol } from '../actions/portfolio/portfolioActions';
import {
  BUY_ERROR_MESSAGE,
  ERROR,
  SIGNIFYD_SESSION_ID,
} from '../../constants/sessionStorage';
import { rejectErrorCodeHelper } from '../../util/helpers/rejectErrorCodeHelper';
import { fetchObiMessagesSuccess } from '../actions/bankAccount/bankAccountActions';
import loadSignifydDeviceFingerprintScript from '../../signifydDeviceFingerprint';
import {
  retrieveFromSessionStorage,
  storeInSessionStorage,
} from '../../util/helpers/sessionStorageHelper';

function* buyProductForStorage({ payload }) {
  try {
    const { data } = yield call(
      buyProductForStorageRequest,
      payload.reviewProduct.AccountUid,
      payload.reviewProduct,
    );
    yield put(buyProductForStorageSuccess(data));
    if (payload.onHandleSuccess) {
      yield call(payload.onHandleSuccess, data?.OrderCode);
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(buyProductForStorageError(errorMessage));
    yield call(setWizardContent, BUY_ERROR_MESSAGE, errorMessage);
  } finally {
    yield call(payload.onConfirmOrder);
  }
}

function* buyProductForDelivery({ payload }) {
  try {
    const { data } = yield call(
      buyProductForDeliveryRequest,
      payload.reviewProduct.AccountUid,
      payload.reviewProduct,
    );
    yield put(buyProductForDeliverySuccess(data));
    if (payload?.onHandleSuccess) {
      yield call(payload.onHandleSuccess, data?.OrderCode);
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(buyProductForStorageError(errorMessage));
    yield call(setWizardContent, BUY_ERROR_MESSAGE, errorMessage);
  } finally {
    yield call(payload.onConfirmOrder);
  }
}

function* sellProductFromStorage({ payload }) {
  const {
    reviewProduct,
    isSegregated,
    setIsLoading,
    handleNext,
    handleError,
    handleGtm,
    onRequestSuccess,
  } = payload;

  try {
    yield call(setIsLoading, true);
    const { data } = yield call(
      sellProductFromStorageRequest,
      reviewProduct.AccountUid,
      { ...reviewProduct, isSegregated },
    );
    yield put(sellProductFromStorageSuccess(data));
    yield call(setIsLoading, false);

    if (onRequestSuccess) {
      yield call(onRequestSuccess);
    }

    yield call(handleError, false);
    yield call(handleGtm);
    yield call(handleNext);
    yield put(
      fetchPortfolioProductBySymbol({
        accountUid: reviewProduct.AccountUid,
        symbol: reviewProduct.SymbolCode,
        isSegregated,
      }),
    );
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(sellProductFromStorageError(errorMessage));
    yield call(setIsLoading, false);
    yield call(handleError, true);
    yield call(handleNext);
  }
}

function* submitFractionalConversion({
  payload: { accountUid, data, handleApiResponse, onRequestSuccess },
}) {
  try {
    yield call(fractionalConversionRequest, accountUid, data);
    yield call(
      handleApiResponse,
      false,
      i18next.t('product.fractionalConversion.responseSuccess'),
    );
    if (onRequestSuccess) {
      yield call(onRequestSuccess);
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield call(handleApiResponse, true, errorMessage);
  }
}

function* iraEntrustWithdrawSubmit({ payload }) {
  const {
    type,
    data,
    accountUid,
    setIsLoading,
    handleApiResponse,
    onRequestSuccess,
  } = payload;
  try {
    yield call(setIsLoading, true);
    if (type === TYPE_DISTRIBUTION) {
      yield call(entrustWithdrawalRequestDistribution, accountUid, data);
    }
    if (type === TYPE_TRANSFER_OUT) {
      yield call(entrustWithdrawalRequestTransfer, accountUid, data);
    }
    yield call(
      handleApiResponse,
      i18next.t('depositWizard.equity.withdrawalResponse.submitted'),
      false,
    );
    if (onRequestSuccess) yield call(onRequestSuccess);
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield call(handleApiResponse, errorMessage, true);
  } finally {
    yield call(setIsLoading, false);
  }
}

function* iraFeeWithdrawalSubmit({ payload }) {
  const { data, accountUid, handleResponse, onRequestSuccess } = payload;

  try {
    yield call(submitIraFeeWithdrawal, accountUid, data);
    yield call(
      handleResponse,
      false,
      i18next.t('depositWizard.equity.withdrawalResponse.submitted'),
    );
    if (onRequestSuccess) yield call(onRequestSuccess);
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield call(handleResponse, true, errorMessage);
  }
}

function* updateAchDeposit({ payload }) {
  try {
    const sessionId = retrieveFromSessionStorage(SIGNIFYD_SESSION_ID);
    const { data } = yield call(postAchDepositRequest, payload.accountUid, {
      ...payload.data,
      SessionId: sessionId,
    });

    yield put(updateAchDepositSuccess(data));

    // load Signifyd script again with different order session id
    loadSignifydDeviceFingerprintScript();
    const script = document.getElementById('sig-api');
    storeInSessionStorage(SIGNIFYD_SESSION_ID, script.dataset.orderSessionId);

    if (payload.afterSubmit) {
      yield call(payload.afterSubmit, false);
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(updateAchDepositError(errorMessage));
    if (payload.afterSubmit) {
      yield call(payload.afterSubmit, true, errorMessage);
    }
  }
}

function* updateWireWithdrawal({ payload }) {
  setWizardContent(ERROR, '');
  payload.setError('');

  try {
    const { data } = yield call(
      updateWireWithdrawalRequest,
      payload.accountUid,
      payload.data,
    );
    yield put(updateWireWithdrawalSuccess(data));
    yield put(fetchObiMessagesSuccess({}));
    // yield call(payload.handleGtm);
    yield call(payload.handleNext);
    yield call(payload.onSuccess);
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(updateWireWithdrawalError(errorMessage));
    setWizardContent(ERROR, errorMessage);
    payload.setError(errorMessage);

    yield call(payload.handleNext);
  }
}

function* updateCheckWithdrawal({ payload }) {
  try {
    const { data } = yield call(
      updateCheckWithdrawalRequest,
      payload.accountUid,
      payload.data,
    );
    yield put(updateCheckWithdrawalSuccess(data));
    // yield call(payload.handleGtm);
    yield call(payload.handleNext);
    yield call(payload.onSuccess);
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(updateCheckWithdrawalError(errorMessage));
    yield call(payload.handleNext);
  }
}

function* verifyBuyForDelivery({ payload }) {
  try {
    const { data } = yield call(
      verifyBuyForDeliveryRequest,
      payload.accountUid,
      payload.data,
    );
    yield put(verifyBuyForDeliverySuccess(data));
    if (payload.onSuccess) {
      yield call(payload.onSuccess, data, payload?.cashAmount);
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(verifyBuyForDeliveryError(errorMessage));
    if (payload.onError) {
      yield call(payload.onError, errorMessage);
    }
  }
}

function* getQuotesForOrderStorageBuy({ payload }) {
  try {
    const { data } = yield call(
      getQuotesForBuyStorage,
      payload.getQuotesPayload,
      payload.isSegregated,
      payload.paymentTypeUid,
      payload.addPaymentMethodData,
    );
    yield put(getQuotesForOrderStorageBuySuccess(data));
    if (payload.onSuccess) {
      yield call(payload.onSuccess, data);
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(getQuotesFoOrderStorageBuyError(errorMessage));
    if (payload.onError) {
      yield call(payload.onError, error);
    }
    yield call(setWizardContent, BUY_ERROR_MESSAGE, errorMessage);
  } finally {
    yield call(payload.isLoading, false);
  }
}

function* executeQuotesForOrderStorageBuy({ payload }) {
  try {
    const { data } = yield call(executeQuotesForBuyStorage, {
      ...payload.executeQuotePayload,
    });
    yield put(executeQuotesForBuyStorageSuccess(data));
    if (payload.onSuccess) {
      yield call(payload.onSuccess, data);
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(executeQuotesFoBuyStorageError(errorMessage));
    if (payload.onError) {
      yield call(payload.onError, error);
    }
    yield call(setWizardContent, BUY_ERROR_MESSAGE, errorMessage);
  } finally {
    yield call(payload.isLoading, false);
    yield call(payload.onConfirm);
  }
}

function* getQuotesForBuyDelivery({ payload }) {
  try {
    const { data } = yield call(
      getQuotesForDelivery,
      payload.getQuotesPayload,
      payload.paymentTypeUid,
      payload.addPaymentMethodData,
    );
    yield put(getQuotesForOrderDeliveryBuySuccess(data));
    if (payload.onSuccess) {
      yield call(payload.onSuccess, data);
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(getQuotesFoOrderDeliveryBuyError(errorMessage));
    yield call(payload.onError, errorMessage);
  } finally {
    yield call(payload.isLoading, false);
  }
}

function* getQuotesForOrderSell({ payload }) {
  try {
    const { data } = yield call(
      getQuotesForSell,
      payload.getQuotesPayload,
      payload.isSegregated,
    );
    yield put(getQuotesForSellSuccess(data));
    if (payload.onSuccess) {
      yield call(payload.onSuccess, data);
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(getQuotesForSellError(errorMessage));

    if (payload.onError) {
      yield call(payload.onError, error);
    }
    yield call(setWizardContent, BUY_ERROR_MESSAGE, errorMessage);
  } finally {
    yield call(payload.isLoading, false);
  }
}

function* executeQuotesForOrderSell({ payload }) {
  try {
    const { data } = yield call(executeQuotesForSell, {
      ...payload.executeQuotePayload,
    });
    yield put(executeQuotesForSellSuccess(data));
    if (payload.onSuccess) {
      yield call(payload.onSuccess, data);
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);

    yield put(executeQuotesForSellError(errorMessage));
    yield call(payload.onError, error);
    yield call(setWizardContent, BUY_ERROR_MESSAGE, errorMessage);
  } finally {
    yield call(payload.isLoading, false);
    yield call(payload.onConfirm);
  }
}

export default function* ordersSaga() {
  yield all([
    takeLatest(VERIFY_BUY_FOR_DELIVERY, verifyBuyForDelivery),
    takeLatest(BUY_PRODUCT_FOR_DELIVERY, buyProductForDelivery),
    takeLatest(BUY_PRODUCT_FOR_STORAGE, buyProductForStorage),
    takeLatest(SELL_PRODUCT_FROM_STORAGE, sellProductFromStorage),
    takeLatest(FRACTIONAL_CONVERSION_SUBMIT, submitFractionalConversion),
    takeLatest(IRA_ENTRUST_WITHDRAW_SUBMIT, iraEntrustWithdrawSubmit),
    takeLatest(IRA_FEE_WITHDRAWAL_SUBMIT, iraFeeWithdrawalSubmit),
    takeLatest(UPDATE_ACH_DEPOSIT, updateAchDeposit),
    takeLatest(GET_QUOTES_FOR_BUY_STORAGE, getQuotesForOrderStorageBuy),
    takeLatest(EXECUTE_QUOTES_FOR_BUY_STORAGE, executeQuotesForOrderStorageBuy),
    takeLatest(GET_QUOTES_FOR_ORDER_DELIVERY_BUY, getQuotesForBuyDelivery),
    takeLatest(UPDATE_WIRE_WITHDRAWAL, updateWireWithdrawal),
    takeLatest(UPDATE_CHECK_WITHDRAWAL, updateCheckWithdrawal),
    takeLatest(GET_QUOTES_FOR_SELL, getQuotesForOrderSell),
    takeLatest(EXECUTE_QUOTES_FOR_SELL, executeQuotesForOrderSell),
  ]);
}
