import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import i18next from 'i18next';
import { useHistory } from 'react-router';
import { uFlexCenter, uFlexColumn } from '../../assets/styles/utility';
import themeColors from '../../assets/styles/themeColors';
import { mediaBelow, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import { setWizardContent } from '../../util/helpers/wizardHelpers';
import {
  IS_DEPOSIT_MODAL_OPEN,
  STANDARD_DEPOSIT_WIZARD,
  WIZARD_DATA,
} from '../../constants/sessionStorage';
import { ACH_ACCOUNT_NAME } from '../../constants/bankAccounts';
import { FINANCIAL_BALANCES_PAGE } from '../../constants/pages';
import BankAccountCard from './BankAccountCard';
import { storeInSessionStorage } from '../../util/helpers/sessionStorageHelper';

export const BankAccountCardTableContainer = styled.div`
  ${uFlexColumn}
  flex-grow: 1;
`;

export const BankAccountCardList = styled.ul`
  ${uFlexColumn};
`;

export const BankAccountCardEmpty = styled.div`
  ${uFlexCenter};
  flex-grow: 1;
  font-weight: 600;
  color: ${themeColors.colorTextSecondary};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    max-width: ${pxToRemMd(302)};
    text-align: center;
    margin: auto;
  }
`;

const BankAccountCardTable = ({
  bankAccounts,
  handleVerifyAccount,
  handleErrorAccount,
  handleRemoveAccount,
  bankAccountType,
  accountType,
  allowAccountChangesPermission,
  contactSupport,
  isDepositButtonVisible,
}) => {
  const history = useHistory();
  const isACHAccount = accountType === ACH_ACCOUNT_NAME;
  const handleDeposit = (account) => {
    history.push(FINANCIAL_BALANCES_PAGE);
    setWizardContent(IS_DEPOSIT_MODAL_OPEN, true);
    setWizardContent(STANDARD_DEPOSIT_WIZARD, 1);
    storeInSessionStorage(WIZARD_DATA, {
      depositType: 'ach',
      bankAccount: account,
    });
  };

  return (
    <BankAccountCardTableContainer>
      {!isEmpty(bankAccounts) ? (
        <>
          <BankAccountCardList>
            {bankAccounts.map((account) => (
              <BankAccountCard
                bankAccount={account}
                bankAccountType={bankAccountType}
                handleVerifyAccount={handleVerifyAccount}
                handleErrorAccount={handleErrorAccount}
                isACHAccount={isACHAccount}
                handleDeposit={handleDeposit}
                handleRemoveAccount={handleRemoveAccount}
                allowAccountChangesPermission={allowAccountChangesPermission}
                key={account.BankAccountUid}
                contactSupport={contactSupport}
                isDepositButtonVisible={isDepositButtonVisible}
              />
            ))}
          </BankAccountCardList>
        </>
      ) : (
        <BankAccountCardEmpty>
          <p>{i18next.t('bankAccounts.listEmpty', { accountType })}</p>
        </BankAccountCardEmpty>
      )}
    </BankAccountCardTableContainer>
  );
};

BankAccountCardTable.propTypes = {
  bankAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      BankAccountUid: PropTypes.string,
      BankName: PropTypes.string,
      AccountMask: PropTypes.string,
      IsVerified: PropTypes.bool,
    }),
  ),
  accountType: PropTypes.string,
  handleVerifyAccount: PropTypes.func,
  handleRemoveAccount: PropTypes.func,
  allowAccountChangesPermission: PropTypes.bool,
  bankAccountType: PropTypes.string,
  handleErrorAccount: PropTypes.func,
  contactSupport: PropTypes.func,
  isDepositButtonVisible: PropTypes.bool,
};

export default BankAccountCardTable;
