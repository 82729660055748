import { TWO_FA_LOGIN_SETUP } from '../../constants/sessionStorage';

export function closeTwoFaLoginContent() {
  sessionStorage.removeItem(TWO_FA_LOGIN_SETUP);
}

export function setTwoFaLoginContent(key, value) {
  const data = JSON.parse(sessionStorage.getItem(TWO_FA_LOGIN_SETUP));
  const newData = { ...data, ...value };
  sessionStorage.setItem(TWO_FA_LOGIN_SETUP, JSON.stringify(newData));
}

export function getTwoFaLoginContent() {
  if (sessionStorage.getItem(TWO_FA_LOGIN_SETUP)) {
    return JSON.parse(sessionStorage.getItem(TWO_FA_LOGIN_SETUP));
  }
}
