import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SectionLoader from '../../../components/Loader/SectionLoader';
import { selectSettings } from '../../../store/selectors/settingsSelectors';
import { useBrokerName } from '../../../util/hooks/userBrokerName';
import { closeWizardContent } from '../../../util/helpers/wizardHelpers';
import StatusModal from '../../../components/Modals/StatusModal';
import { useSessionStorageState } from '../../../util/hooks/useSessionStorageState';
import { ERROR } from '../../../store/actions/actionHelpers';
import { USER_INVITE_STATUS_MODAL } from '../../../constants/sessionStorage';
import { selectIsLoadingByActionType } from '../../../store/selectors/loadingSelectors';
import { INVITE_USER_LOADING } from '../../../store/actions/user/userActionConstants';
import Paragraph from '../../../components/Paragraph/Paragraph';
import Anchor from '../../../components/Anchor/Anchor';
import HavingTroubleBackButton from './HavingTroubleBackButton';
import AuthCard from '../../../components/Auth/AuthCard';
import Auth from '../../../components/Auth/Auth';

const HavingTroubleIsOpen = ({ handleBackClick, isOpenLoading }) => {
  const { t } = useTranslation();
  const brokerName = useBrokerName();
  const brokerSettings = useSelector(selectSettings);

  const [isLoading] = useState(false);
  const isLoadingSelector = useSelector(
    selectIsLoadingByActionType(INVITE_USER_LOADING),
  );

  const [errorInviteUser, setError] = useSessionStorageState(ERROR, '');
  const [statusModal, setStatusModal] = useSessionStorageState(
    USER_INVITE_STATUS_MODAL,
    false,
  );

  return (
    <SectionLoader isLoading={isLoading || isOpenLoading || isLoadingSelector}>
      <Auth>
        <AuthCard title={t('twoFa.loginVerify.trouble.title')}>
          <Paragraph marginBottom={24}>
            {t('twoFa.loginVerify.trouble.note')}
          </Paragraph>
          <Paragraph>
            {t('twoFa.loginVerify.trouble.toll.free')}{' '}
            <Anchor
              bold
              type="telephone"
              value={brokerSettings?.SupportPhoneTollFree}
              text={brokerSettings?.SupportPhoneTollFree}
            />
          </Paragraph>
          <Paragraph>
            {t('twoFa.loginVerify.trouble.toll.international')}{' '}
            <Anchor
              bold
              type="telephone"
              value={brokerSettings?.CustomerServicePhoneNumber}
              text={brokerSettings?.CustomerServicePhoneNumber}
            />
          </Paragraph>

          <Paragraph marginTop={24} marginBottom={56}>
            {t('twoFa.loginVerify.trouble.instructions', { brokerName })}
          </Paragraph>
          <HavingTroubleBackButton onClick={handleBackClick} />
        </AuthCard>
      </Auth>
      {errorInviteUser && statusModal && (
        <StatusModal
          isOpen={statusModal}
          hasError={errorInviteUser}
          close={() => {
            setStatusModal(false);
            setError('');
            closeWizardContent(ERROR);
            closeWizardContent(USER_INVITE_STATUS_MODAL);
          }}
          text={errorInviteUser}
          modalTitle={t('inviteUser.review.statusModal')}
          backButtonText={t('common.ok')}
          onButtonClick={() => {
            setStatusModal(false);
            setError('');
            closeWizardContent(ERROR);
            closeWizardContent(USER_INVITE_STATUS_MODAL);
          }}
        />
      )}
    </SectionLoader>
  );
};

HavingTroubleIsOpen.propTypes = {
  twoFactorAuthenticationResponse: PropTypes.shape({
    AuthenticationMethodType: PropTypes.string,
  }),
  twoFaValue: PropTypes.shape({}),
  handleBackClick: PropTypes.func,
  isOpenLoading: PropTypes.bool,
  formValues: PropTypes.shape({
    values: PropTypes.shape({
      ExistingUsername: PropTypes.string,
      LegalFirstName: PropTypes.string,
      LegalMiddleName: PropTypes.string,
      LegalLastName: PropTypes.string,
      Suffix: PropTypes.string,
      Username: PropTypes.string,
      Password: PropTypes.string,
      SecurityQuestion: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
        PropTypes.string,
      ]),
      Answer: PropTypes.string,
      SecurityCode: PropTypes.string,
      Remember: PropTypes.bool,
    }),
    phoneNumbers: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default HavingTroubleIsOpen;
