import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { isEmpty, startCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { getCurrentAccount } from '../../store/selectors/accountSelectors';
import {
  selectBalancesAvailable,
  selectBalancesAvailableForTrading,
} from '../../store/selectors/portfolioBalancesSelectors';
import { fetchPortfolioBalances } from '../../store/actions/portfolio/portfolioActions';
import { parseEnumType } from '../../util/helpers/enumMappers';
import { productLocationTypes } from '../../util/enum/api/productTypes';
import { formatMoneyNumeral } from '../../util/helpers/numeralHelpers';
import { selectSettings } from '../../store/selectors/settingsSelectors';
import ModalBody from '../Modal/ModalBody';
import ModalButtons from '../Modal/ModalButtons';
import Label from '../Notes/Label';
import useGtmHook from '../../util/hooks/useGtmHook';
import {
  ecommerceGtmHelper,
  onConfirmOrderEcommerceGtmHelper,
} from '../../util/helpers/gtmHelper';
import useProductPrice from '../../util/hooks/useProductPrice';
import BuyFields from '../InputFields/BuyFields';
import SectionLoader from '../Loader/SectionLoader';
import { buyForStorageValidationSchema } from '../../validation/buyForStorageValidationSchema';
import Paragraph from '../Paragraph/Paragraph';
import ModalTitle from '../Modal/ModalTitle';
import TwoColumnList from '../TwoColumnList/TwoColumnList';
import SingleColumnList from '../TwoColumnList/SingleColumnList';
import { getQuotesForOrderStorageBuy } from '../../store/actions/orders/orderActions';
import paymentTypes from '../../util/enum/api/paymentTypes';

const OrderForStorage = ({
  handleNext,
  setWizardData,
  wizardData,
  handleBuyWizardBack,
  setWizardTitle,
  isProductPage,
  skipChooseLocation,
  isPostPaidVisible,
}) => {
  const { t } = useTranslation();
  const [activeType, setActiveType] = useState(
    wizardData?.activeType || 'Cash',
  );
  const dispatch = useDispatch();
  const account = useSelector(getCurrentAccount);
  const balance = useSelector(selectBalancesAvailable);
  const balanceAvailableForTrading = useSelector(
    selectBalancesAvailableForTrading,
  );
  const settings = useSelector(selectSettings);
  const [orderValues, setOrderValues] = useState({});
  const [calculation, setCalculation] = useState({});
  const [quoteID, setQuoteID] = useState('');
  const [expiryTime, setExpiryTime] = useState('');
  const [nextClicked, setNextClicked] = useState(false);
  const [isLoadingGetQuotes, setIsLoadingGetQuotes] = useState(false);
  const [getQuotesError, setGetQuotesError] = useState('');
  const [isDefaultPaymentMethod, setIsDefaultPaymentMethod] = useState('');

  const { gtmDialogScreenView, ecommerceGtmEvent } = useGtmHook();

  const isSegregated = wizardData?.isSegregated;
  const segregatedCaption = settings?.SegregatedCaption;

  const unitPrice = useProductPrice(
    wizardData?.storageProduct,
    wizardData?.selectedVault,
    isProductPage,
  );
  const [pricePerUnit, setPricePerUnit] = useState(unitPrice);

  const balancePaymentMethodUid = '00000000-0000-0000-0000-000000000000';

  useEffect(() => {
    if (account.AccountUid) {
      dispatch(fetchPortfolioBalances({ accountUid: account.AccountUid }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setWizardTitle(t('buyWizard.buyForStorage.orderEntry.modalTitle'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    gtmDialogScreenView({
      title: `Buy for Storage - Order Entry ${wizardData?.storageProduct?.ProductCaption}`,
    });
  }, []); //eslint-disable-line

  useEffect(() => {
    if (unitPrice) {
      setPricePerUnit(unitPrice);
      if (dispatch && !isProductPage) {
        ecommerceGtmHelper({
          ecommerceGtmEvent,
          wizardData,
          orderSideType: 'buy',
          account,
          pricePerUnit: unitPrice,
          isForDelivery: false,
        });
      }
    }
  }, [unitPrice, dispatch]); //eslint-disable-line

  useEffect(() => {
    setWizardData({
      ...wizardData,
      balanceAvailable: balanceAvailableForTrading,
      activeType,
      isDefaultPaymentMethod,
      balancePaymentMethodUid,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balanceAvailableForTrading, isDefaultPaymentMethod]);

  const { storageProduct, selectedVault } = wizardData;

  const onHandleGetQuotesSuccess = (getQuoteResult) => {
    const {
      QuoteUid,
      QuoteStatusItem: {
        QuantityExecuted,
        ClientPricePerUnit,
        TotalClientExecutionPrice,
        VolumeDiscount,
        PaymentMethodStepData,
      },
      ExpiresOnUtc,
    } = getQuoteResult;

    const calculation = {
      estimatedOrderTotal: TotalClientExecutionPrice,
      newPricePerUnit: ClientPricePerUnit,
      volumeDiscount: VolumeDiscount,
      quantity: QuantityExecuted,
      paymentMethodStepData: PaymentMethodStepData,
    };
    setQuoteID(QuoteUid);
    setExpiryTime(ExpiresOnUtc);
    setCalculation(calculation);
    setNextClicked(true);
  };

  useEffect(() => {
    if (!isEmpty(calculation) && !isEmpty(orderValues)) {
      setWizardData({
        ...wizardData,
        calculation,
        quoteID,
        expiryTime,
        orderValues,
        orderType: activeType,
      });
    }
  }, [
    orderValues,
    calculation,
    setWizardData,
    wizardData,
    quoteID,
    expiryTime,
    activeType,
  ]);

  useEffect(() => {
    if (!isEmpty(wizardData?.orderValues) && nextClicked) {
      setNextClicked(false);
      handleNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardData.orderValues, nextClicked]);

  const onHandleGetQuotesError = (error) => {
    const errorCode = error?.response?.data?.Errors[0]?.Code;

    if (
      errorCode === 'BuyMinimumAmountIsNotReached' ||
      errorCode === 'OrderMinimumValidationError'
    ) {
      setGetQuotesError(
        t('apiErrors.BuyForDeliveryAmountMinimumIsNotReached', {
          orderMinimum: selectedVault?.BuyMinimumAmount
            ? `$${selectedVault?.BuyMinimumAmount}`
            : wizardData?.productPrices?.Locations[0]?.BuyMinimumAmount,
        }),
      );
    } else {
      setGetQuotesError(t(`apiErrors.${errorCode}`));
    }
  };

  const getMinimumOrderAmount = () => {
    if (isEmpty(wizardData) || !wizardData?.selectedVault) {
      return 0;
    }

    return wizardData?.selectedVault?.BuyMinimumAmount;
  };

  const submitForm = (values) => {
    const calculateMaxQuantity = () =>
      Math.floor(balanceAvailableForTrading / pricePerUnit);

    const isValueReachedMaximumLimit =
      values?.CashAmount > balanceAvailableForTrading ||
      values?.QuantityAmount > calculateMaxQuantity();

    const defaultPaymentMethod = balance?.PaymentMethods?.find(
      (paymentMethod) => paymentMethod.IsDefault,
    );

    setIsDefaultPaymentMethod(balance?.PaymentMethods?.[0]);

    if (isValueReachedMaximumLimit) {
      setIsDefaultPaymentMethod(defaultPaymentMethod);
    }

    setOrderValues({
      CashAmount: activeType === 'Cash' ? values.CashAmount : 0,
      QuantityAmount: activeType === 'Quantity' ? values.QuantityAmount : 0,
    });

    onConfirmOrderEcommerceGtmHelper({
      ecommerceGtmEvent,
      wizardData,
      orderSideType: 'buy',
      account,
      pricePerUnit,
      activeType,
      values,
      isForDelivery: false,
    });

    const paymentTypeUid =
      isValueReachedMaximumLimit &&
      defaultPaymentMethod?.PaymentMethodType !== 1
        ? defaultPaymentMethod?.PaymentMethodUid
        : balancePaymentMethodUid;

    const payload = {
      getQuotesPayload: {
        accountUid: account.AccountUid,
        symbolCode: selectedVault?.SymbolCode,
        quantityOrAmount:
          activeType === 'Cash' ? values?.CashAmount : values?.QuantityAmount,
        sideType: 'Buy',
        unitType: activeType,
        paymentType: !isValueReachedMaximumLimit
          ? paymentTypes[balance?.PaymentMethods?.[0]?.PaymentMethodType]
          : paymentTypes[defaultPaymentMethod?.PaymentMethodType],
      },
      isLoading: setIsLoadingGetQuotes,
      onSuccess: onHandleGetQuotesSuccess,
      onError: onHandleGetQuotesError,
      isSegregated,
      paymentTypeUid,
      addPaymentMethodData: true,
    };
    setIsLoadingGetQuotes(true);
    dispatch(
      getQuotesForOrderStorageBuy({
        ...payload,
      }),
    );
  };

  const displayError = () => {
    if (getQuotesError) {
      return (
        <Paragraph isError fontSize={14} bold>
          {getQuotesError}
        </Paragraph>
      );
    }
  };

  if (isEmpty(wizardData)) {
    return null;
  }

  return (
    <SectionLoader isLoading={isLoadingGetQuotes}>
      <ModalBody>
        <ModalTitle marginBottom={4}>
          {isSegregated
            ? `${storageProduct?.ProductCaption} ${startCase(
                parseEnumType(
                  productLocationTypes,
                  selectedVault?.LocationType,
                ),
              )} (${segregatedCaption})`
            : `${storageProduct?.ProductCaption} ${startCase(
                parseEnumType(
                  productLocationTypes,
                  selectedVault?.LocationType,
                ),
              )}`}
        </ModalTitle>
        <Label marginBottom={32} text={t('buyWizard.common.orderEntry')} />
        <TwoColumnList marginBottom={24}>
          <SingleColumnList>
            <Label
              marginBottom={4}
              text={
                settings?.IsLockedPricingActive
                  ? t('product.detailsTable.pricePerUnit')
                  : t('buyWizard.common.pricePerUnit')
              }
            />
            <Paragraph fontSize={18}>
              {formatMoneyNumeral(pricePerUnit)}
            </Paragraph>
          </SingleColumnList>
          <SingleColumnList>
            <Label
              marginBottom={4}
              text={t('buyWizard.common.availableForTrading')}
            />
            <Paragraph fontSize={18}>
              {formatMoneyNumeral(balanceAvailableForTrading)}
            </Paragraph>
          </SingleColumnList>
        </TwoColumnList>
        <Formik
          initialValues={{
            CashAmount: wizardData?.orderValues?.CashAmount || '',
            QuantityAmount: wizardData?.orderValues?.QuantityAmount || '',
            applyMax: !isPostPaidVisible,
          }}
          validationSchema={buyForStorageValidationSchema(
            activeType,
            pricePerUnit,
            balanceAvailableForTrading,
            productLocationTypes,
            selectedVault,
            getMinimumOrderAmount,
          )}
          onSubmit={submitForm}
          validateOnBlur={false}
        >
          {({ values, setFieldValue, errors, setFieldTouched }) => (
            <Form>
              <BuyFields
                activeType={activeType}
                setActiveType={setActiveType}
                values={values}
                setFieldValue={setFieldValue}
                selectedVault={selectedVault}
                setFieldTouched={setFieldTouched}
                unitPrice={pricePerUnit}
                setOrderValues={setOrderValues}
                displayError={displayError}
                setGetQuotesError={setGetQuotesError}
              />
              <ModalButtons
                marginTop
                isHorizontal
                secondaryButtonProps={{
                  onClick: handleBuyWizardBack,
                  label:
                    skipChooseLocation || isProductPage
                      ? t('common.cancel')
                      : t('common.back'),
                  'data-cy': 'button-cancel',
                }}
                primaryButtonProps={{
                  type: 'submit',
                  disabled:
                    (activeType === 'Quantity' &&
                      selectedVault.IsCashOrderOnly) ||
                    errors?.CashAmount ||
                    errors?.QuantityAmount,
                  'data-cy': 'button-next',
                }}
              />
            </Form>
          )}
        </Formik>
      </ModalBody>
    </SectionLoader>
  );
};

OrderForStorage.propTypes = {
  handleNext: PropTypes.func,
  setWizardData: PropTypes.func,
  wizardData: PropTypes.shape({
    isSegregated: PropTypes.bool,
    storageProduct: PropTypes.shape({
      ProductCaption: PropTypes.string,
    }),
    productPrices: PropTypes.shape({
      Locations: PropTypes.shape({}),
    }),
    selectedVault: PropTypes.shape({
      LocationType: PropTypes.number,
      PricePerUnit: PropTypes.number,
      IsCashOrderOnly: PropTypes.bool,
      SymbolCode: PropTypes.string,
      BuyMinimumAmount: PropTypes.number,
    }),
    cashBuffer: PropTypes.number,
    calculation: PropTypes.shape({
      estimatedOrderTotalWithCashBuffer: PropTypes.number,
      newPricePerUnit: PropTypes.number,
    }),
    orderValues: PropTypes.shape({
      CashAmount: PropTypes.number,
      QuantityAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    activeType: PropTypes.string,
    orderType: PropTypes.string,
    quoteID: PropTypes.string,
  }),
  handleBuyWizardBack: PropTypes.func,
  setWizardTitle: PropTypes.func,
  isProductPage: PropTypes.bool,
  skipChooseLocation: PropTypes.bool,
  isSegregated: PropTypes.bool,
  isPostPaidVisible: PropTypes.bool,
};

export default OrderForStorage;
