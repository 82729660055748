import { css } from 'styled-components';
import { mediaBelow, pxToRem } from '../../../assets/styles/helper';
import { variables } from '../../../assets/styles/variables';
import { TableDataElement } from '../TableData';

export const pendingTransactionStyle = css``;

export const pendingTransactionHead = css`
  display: grid;
  grid-template-columns: 2fr 1fr ${pxToRem(226)} minMax(
      ${pxToRem(120)},
      ${pxToRem(150)}
    );
  grid-column-gap: ${pxToRem(24)};

  > :nth-child(2),
  > :nth-child(3),
  > :nth-child(4) {
    display: flex;
    justify-content: center;

    > p {
      text-align: right;
    }
  }

  ${mediaBelow(variables.breakpoints.bpLg)} {
    grid-template-columns: 1fr 1fr ${pxToRem(226)} minMax(
        ${pxToRem(120)},
        ${pxToRem(150)}
      );
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    grid-template-columns: 1fr ${pxToRem(226)} minMax(
        ${pxToRem(120)},
        ${pxToRem(150)}
      );

    > :nth-child(2) {
      display: none;
    }
  }

  ${mediaBelow(variables.breakpoints.bpSm)} {
    grid-template-columns: 1fr;

    > :nth-child(3) {
      display: none;
    }
  }
`;

export const pendingTransactionRow = css`
  cursor: pointer;

  ${TableDataElement} {
    display: grid;
    grid-template-columns: 2fr 1fr ${pxToRem(226)} minMax(
        ${pxToRem(120)},
        ${pxToRem(150)}
      );
    grid-column-gap: ${pxToRem(24)};

    > :nth-child(2),
    > :nth-child(3),
    > :nth-child(4) {
      display: flex;
      justify-content: center;

      > p {
        text-align: right;
      }
    }
  }

  ${mediaBelow(variables.breakpoints.bpLg)} {
    ${TableDataElement} {
      grid-template-columns: 1fr 1fr ${pxToRem(226)} minMax(
          ${pxToRem(120)},
          ${pxToRem(150)}
        );
    }
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    ${TableDataElement} {
      grid-template-columns: 1fr ${pxToRem(226)} minMax(
          ${pxToRem(120)},
          ${pxToRem(150)}
        );

      > :nth-child(2) {
        display: none;
      }
    }
  }

  ${mediaBelow(variables.breakpoints.bpSm)} {
    ${TableDataElement} {
      grid-template-columns: 1fr ${pxToRem(16)} auto;
    }
  }
`;

export const pendingTransactionCancel = css`
  width: max-content;
`;
