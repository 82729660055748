import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';
import startCase from 'lodash.startcase';
import { useDispatch, useSelector } from 'react-redux';
import { MetalStreamTerms } from './MetalStreamTerms';
import ModalButtons from '../../Modal/ModalButtons';
import BulletList from '../../BulletList/BulletList';
import BulletItem from '../../BulletItem/BulletItem';
import AgreementCheckbox from '../../InputFields/AgreementCheckbox';
import { formatMoneyNumeral } from '../../../util/helpers/numeralHelpers';
import Paragraph from '../../Paragraph/Paragraph';
import Label from '../../Notes/Label';
import TypeList from '../../TypeList/TypeList';
import ModalBody from '../../Modal/ModalBody';
import SectionLoader from '../../Loader/SectionLoader';
import ReviewBar from '../../ReviewBar/ReviewBar';
import TwoColumnList from '../../TwoColumnList/TwoColumnList';
import ProductItem from './ProductItem';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import { productLocationTypes } from '../../../util/enum/api/productTypes';
import config from '../../../config';
import { postMetalStreamSetup } from '../../../store/actions/metalStream/metalStreamActions';
import { AIP_ENABLED, AIP_UPDATED } from '../../../constants/gtmEvents';
import { accountTypeKeys } from '../../../util/enum/api/accountTypes';
import { getCurrentAccount } from '../../../store/selectors/accountSelectors';
import { selectSettings } from '../../../store/selectors/settingsSelectors';
import useGtmHook from '../../../util/hooks/useGtmHook';
import { selectIsLoadingByActionType } from '../../../store/selectors/loadingSelectors';
import { METAL_STREAM_FINISH_SETUP_LOADING } from '../../../store/actions/metalStream/metalStreamActionConstants';
import { useBrokerName } from '../../../util/hooks/userBrokerName';

const accountMaskPadding = Array(8).fill('*').join('');
const MetalStreamReview = ({
  handleBack,
  handleNext,
  wizardData,
  setWizardData,
  defaultValues,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { gtmEvent, gtmDialogScreenView } = useGtmHook();
  const account = useSelector(getCurrentAccount);
  const { RootUrl } = useSelector(selectSettings);
  const isLoading = useSelector(
    selectIsLoadingByActionType(METAL_STREAM_FINISH_SETUP_LOADING),
  );
  const { metalStreamCaption } = config;
  const brokerName = useBrokerName();
  useEffect(() => {
    gtmDialogScreenView({
      title: `MetalStream Setup Please Review and Submit Your Settings`,
    });
  }, []); //eslint-disable-line

  const handleSubmit = () => {
    const handleGtm = () => {
      gtmEvent(isEmpty(defaultValues) ? AIP_ENABLED : AIP_UPDATED, {
        AccountType: parseEnumType(accountTypeKeys, account.AccountType),
        AIPFundingType: wizardData?.UseAccountBalance
          ? 'Balance'
          : 'BankAccount',
        GoldSymbolCode: wizardData?.GoldProductConfiguration?.SymbolCode,
        GoldPercentage: Number(
          wizardData?.GoldProductConfiguration?.Percentage,
        ),
        SilverSymbolCode: wizardData?.SilverProductConfiguration?.SymbolCode,
        SilverPercentage: Number(
          wizardData?.SilverProductConfiguration?.Percentage,
        ),
        BankAccountSummary: wizardData?.UseAccountBalance
          ? null
          : {
              BankName:
                wizardData?.BankAccount && wizardData?.BankAccount?.BankName,
              AccountMask:
                wizardData?.BankAccount && wizardData?.BankAccount?.AccountMask,
            },
      });
    };

    const data = {
      Amount: Number(wizardData?.Amount),
      BankAccountUid: wizardData?.UseAccountBalance
        ? ''
        : wizardData?.BankAccount?.BankAccountUid || '',
      UseAccountBalance: wizardData?.UseAccountBalance,
      IsActive: true,
      GoldProductConfiguration:
        wizardData?.GoldProductConfiguration?.Percentage === 0
          ? null
          : wizardData?.GoldProductConfiguration,
      SilverProductConfiguration:
        wizardData?.SilverProductConfiguration?.Percentage === 0
          ? null
          : wizardData?.SilverProductConfiguration,
    };

    const goNext = (errorMessage) => {
      setWizardData({ errorMessage });
      handleNext();
    };

    dispatch(
      postMetalStreamSetup({
        data,
        accountUid: account.AccountUid,
        brokerName,
        goNext,
        handleGtm,
      }),
    );
  };

  const productItem = (product, productType) => {
    if (isEmpty(product)) {
      return null;
    }

    const imgSrc = config.algoliaSearch.imageCode
      .replace('{product_code}', product.ProductCode)
      .replace('{base_url}', RootUrl);

    const productLocation = product.Locations.filter(
      (productLocation) => product.SymbolCode === productLocation.SymbolCode,
    );

    const { LocationType, PricePerOz } = productLocation[0];
    const productName = `${product.ProductCaption} - ${startCase(
      parseEnumType(productLocationTypes, LocationType),
    )}`;
    const pricePerOz = formatMoneyNumeral(PricePerOz);

    const productPrice = `${pricePerOz}/oz`;

    const getProductPercentage = () => {
      if (productType === 'gold') {
        return wizardData?.GoldProductConfiguration?.Percentage;
      }

      if (productType === 'silver') {
        return wizardData?.SilverProductConfiguration?.Percentage;
      }
    };
    const amount = wizardData?.Amount * getProductPercentage();
    const allocationInfo = `${Math.round(
      getProductPercentage() * 100,
    )}% (${formatMoneyNumeral(amount)})`;

    return (
      <>
        <TwoColumnList rightFullWidth>
          <Paragraph bold>{startCase(productType)}</Paragraph>
          <Paragraph bold>{allocationInfo}</Paragraph>
        </TwoColumnList>
        <ProductItem
          key={product.ProductCode}
          imgSrc={imgSrc}
          product={product}
          display
          productName={productName}
          alt={product.ProductCode}
          productPrice={productPrice}
        />
      </>
    );
  };

  const bankAccount = wizardData?.UseAccountBalance
    ? t('fundingSource.paused')
    : `${wizardData?.BankAccount.BankName}, ${
        accountMaskPadding + wizardData?.BankAccount.AccountMask
      }`;

  const handleNextClick = () => {
    handleSubmit();
  };

  const openTermsModal = (event) => {
    event.preventDefault();
    setWizardData({ isTermsModalOpen: true });
  };

  const closeTermsModal = () => {
    setWizardData({ isTermsModalOpen: false });
  };

  const handleCheckboxClick = () => {
    setWizardData({ areTermsChecked: !wizardData?.areTermsChecked });
  };

  const handleNextTermsModal = () => {
    setWizardData({ areTermsChecked: true, isTermsModalOpen: false });
  };

  if (wizardData?.isTermsModalOpen) {
    return (
      <MetalStreamTerms
        handleNext={handleNextTermsModal}
        handleBack={closeTermsModal}
      />
    );
  }

  return (
    <>
      <ReviewBar title={t('metalStream.review')} />
      <SectionLoader isLoading={isLoading}>
        <ModalBody>
          <Label
            marginBottom={4}
            isBold
            text={t('metalStream.automaticWithdrawal')}
          />
          <Paragraph bold fontSize={14} marginBottom={4}>
            {bankAccount}
          </Paragraph>
          <Paragraph bold fontSize={18} marginBottom={32}>
            {formatMoneyNumeral(wizardData?.Amount)}
          </Paragraph>
          <TypeList marginBottom={24}>
            {productItem(wizardData?.SelectedGoldProduct, 'gold')}
            {productItem(wizardData?.SelectedSilverProduct, 'silver')}
          </TypeList>
          <AgreementCheckbox
            checked={wizardData?.areTermsChecked}
            onChange={handleCheckboxClick}
            name="terms"
            text={t('metalStream.byStarting')}
            link={t('metalStream.termsAndConditions', {
              metalStream: metalStreamCaption,
            })}
            onLinkClick={(event) => {
              openTermsModal(event);
            }}
          />

          <BulletList marginTop={32}>
            <BulletItem text={t('metalStream.autoDeposit')} />
            <BulletItem text={t('metalStream.autoTrades')} />
            <BulletItem text={t('metalStream.whileEnabled')} />
          </BulletList>
          <ModalButtons
            isHorizontal
            marginTop
            secondaryButtonProps={{ onClick: handleBack }}
            primaryButtonProps={{
              onClick: handleNextClick,
              disabled: !wizardData?.areTermsChecked,
              label: t('common.start'),
            }}
          />
        </ModalBody>
      </SectionLoader>
    </>
  );
};

MetalStreamReview.propTypes = {
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  wizardData: PropTypes.shape({
    areTermsChecked: PropTypes.bool,
    isTermsModalOpen: PropTypes.bool,
    GoldProductConfiguration: PropTypes.shape({
      Percentage: PropTypes.number,
      SymbolCode: PropTypes.string,
    }),
    SilverProductConfiguration: PropTypes.shape({
      Percentage: PropTypes.number,
      SymbolCode: PropTypes.string,
    }),
    SelectedGoldProduct: PropTypes.shape({}),
    SelectedSilverProduct: PropTypes.shape({}),
    Amount: PropTypes.number,
    UseAccountBalance: PropTypes.bool,
    BankAccount: PropTypes.shape({
      BankName: PropTypes.string,
      BankAccount: PropTypes.string,
      AccountMask: PropTypes.string,
      BankAccountUid: PropTypes.string,
    }),
  }),
  setWizardData: PropTypes.func,
  defaultValues: PropTypes.shape({}),
};

export default MetalStreamReview;
