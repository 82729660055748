import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import creditCardTypes, {
  CREDIT_CARDS_TYPES_ICONS,
} from '../../util/enum/api/creditCardTypes';
import { formatDate } from '../../util/helpers/dateHelpers';
import Paragraph from '../Paragraph/Paragraph';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { ReactComponent as DeleteIcon } from '../../assets/images/svg/delete-icon.svg';
import { variables } from '../../assets/styles/variables';
import themeColors from '../../assets/styles/themeColors';
import { Dot } from '../StorageFee/AutoPayStatus';
import IconButtonWithLabel from '../BankAccountsCard/IconButtonWithLabel';

const CreditCardTypeContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 40px;
    height: 40px;
    margin-right: 12px;
  }
`;

const CreditCardItem = styled.li`
  padding: ${pxToRem(16)} ${pxToRem(12)};
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${themeColors.colorBorderPrimary};
  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(24)} ${pxToRemMd(16)};
  }
`;

export const DotAndTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${[pxToRem(10)]};
`;

export const RemoveActionLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CreditCard = ({ creditCard, handleRemoveCreditCard }) => {
  const { t } = useTranslation();

  return (
    <CreditCardItem key={creditCard.CreditCardUid}>
      <RemoveActionLineWrapper>
        <Paragraph fontSize={18} bold>
          {creditCard.NameOnCard}
        </Paragraph>
        <IconButtonWithLabel
          type="button"
          color={themeColors.colorIconPrimary}
          onClick={() => handleRemoveCreditCard(creditCard.CreditCardUid)}
        >
          <DeleteIcon width={12} height={12} />
          <Paragraph fontSize={16} marginLeft={6} textDecoration="underline">
            {t('common.delete')}
          </Paragraph>
        </IconButtonWithLabel>
      </RemoveActionLineWrapper>
      <CreditCardTypeContainer>
        {CREDIT_CARDS_TYPES_ICONS[creditCard.CreditCardType]}
        <Paragraph fontSize={16} bold marginRight={24}>
          {t(
            `creditCards.creditCardTypes.${
              creditCardTypes[creditCard.CreditCardType]
            }`,
          )}
        </Paragraph>
        <Paragraph fontSize={16} bold>
          {t('creditCards.cardMask', { cardMask: creditCard.Last4Digits })}
        </Paragraph>
      </CreditCardTypeContainer>
      <Paragraph fontSize={16} marginBottom={24}>
        {t('creditCards.expires', {
          expirationMonth: formatDate(creditCard.ExpirationDate, 'MM'),
          expirationYear: formatDate(creditCard.ExpirationDate, 'yy'),
        })}
      </Paragraph>
      {creditCard.IsDefaultForStorage && (
        <DotAndTextWrapper>
          <Dot isAutoPayOn />
          <Paragraph fontSize={16} bold color={themeColors.colorSuccess}>
            {t('creditCards.thisCardIsUsedForAutoPay')}
          </Paragraph>
        </DotAndTextWrapper>
      )}
    </CreditCardItem>
  );
};

export default CreditCard;

CreditCard.propTypes = {
  creditCard: PropTypes.shape({
    CreditCardUid: PropTypes.string,
    NameOnCard: PropTypes.string,
    CreditCardType: PropTypes.number,
    Last4Digits: PropTypes.string,
    ExpirationDate: PropTypes.string,
    IsDefaultForStorage: PropTypes.bool,
  }),
  handleRemoveCreditCard: PropTypes.func,
};
