export const BASE_PAGE = '/';
export const BUY_PAGE = '/buy';
export const PORTFOLIO_PAGE = '/portfolio';
export const FINANCIAL_BALANCES_PAGE = `${PORTFOLIO_PAGE}/financial-balances`;
export const PORTFOLIO_PRODUCT_PAGE = `${PORTFOLIO_PAGE}/product/:symbol`;
export const IMPERSONATE_PAGE = `/impersonate`;
export const REGISTER_LOGIN_PAGE = `/register-login`;
export const SELL_PAGE = '/sell';
export const METAL_STREAM_PAGE = '/aip';
export const EMAIL_LOGIN_PAGE = '/login-email';
export const USER_LOGIN_PAGE = '/login-user';
export const SECURITY_QUESTION_PAGE = '/security-question';
export const RESET_PASSWORD = '/reset-password';
export const RESET_SUCCESSFUL_PAGE = '/reset-successful';
export const RESET_SECURITY_QUESTION_PAGE = `/reset-security-question`;

export const RESET_SENT_PAGE = '/reset-sent';
export const IDENTITY_PAGE = '/identity';
export const ACCOUNT_PAGE = '/account';
export const BANK_ACCOUNTS_PAGE = `${ACCOUNT_PAGE}/bank-accounts`;
export const CREDIT_CARDS_PAGE = `${ACCOUNT_PAGE}/credit-cards`;
export const USER_MANAGEMENT_PAGE = `${ACCOUNT_PAGE}/user-management`;
export const LEGACY_ORDER_HISTORY_PAGE = `${ACCOUNT_PAGE}/legacy-history`;
export const PROFILE_SETTINGS_PAGE = `${ACCOUNT_PAGE}/profile-settings`;
export const ADDRESSES_PAGE = `${ACCOUNT_PAGE}/addresses`;
export const STATEMENTS_PAGE = `${ACCOUNT_PAGE}/statements`;
export const INVITE_USER_PAGE = `/invite-user`;
export const QUICK_REGISTRATION_PAGE = `/registration`;
export const INVITE_USER_CREDENTIALS_PAGE = `${INVITE_USER_PAGE}/credentials`;
export const INVITE_USER_REVIEW_PAGE = `${INVITE_USER_PAGE}/review`;
export const INVITE_USER_WELCOME_PAGE = `${INVITE_USER_PAGE}/welcome`;
export const INVITE_USER_SECURITY_QUESTION = `${INVITE_USER_PAGE}/security-question`;
export const INVITE_USER_IDENTITY = `${INVITE_USER_PAGE}/identity`;
export const INVITE_USER_TWO_FA_VERIFY_IDENTITY = `${INVITE_USER_PAGE}/verify-identity`;
export const INVITE_USER_TWO_FA_VERIFY_CHOOSE_METHOD = `${INVITE_USER_PAGE}/verify-choose-method`;
export const INVITE_USER_TWO_FA_VERIFY_IDENTITY_PHONE = `${INVITE_USER_PAGE}/verify-identity-phone`;
export const INVITE_USER_TWO_FA_HAVING_TROUBLE = `${INVITE_USER_PAGE}/having-trouble`;
export const INVITE_USER_TWO_FA_VERIFY_CODE = `${INVITE_USER_PAGE}/verify-code`;
export const INVITE_USER_TWO_FA_QR_CODE_PAGE = `${INVITE_USER_PAGE}/authentication-code`;
export const INVITE_USER_TWO_FA_CHOOSE_PHONE_METHOD = `${INVITE_USER_PAGE}/verify-phone-method`;
export const INVITE_USER_TWO_FA_SUCCESSFUL_SETUP = `${INVITE_USER_PAGE}/successful-setup`;
export const MAINTENANCE_PAGE = `/maintenance`;
export const STYLEGUIDE_PAGE = `/styleguide`;

export const ERROR_PAGE = '/error';
export const NOT_FOUND_PAGE = '/not-found';
export const VERIFY_CHOOSE_METHOD_PAGE = '/verify-choose-method';
export const TWO_FA_QR_CODE_PAGE = '/authentication-code';
export const TWO_FA_CHOOSE_PHONE_METHOD = '/verify-phone-method';
export const TWO_FA_VERIFY_CODE = '/verify-code';
export const TWO_FA_SUCCESSFUL_SETUP = '/successful-setup';
export const TWO_FA_VERIFY_IDENTITY = '/verify-identity';
export const TWO_FA_VERIFY_IDENTITY_PHONE = '/verify-identity-phone';
export const TWO_FA_HAVING_TROUBLE_PAGE = '/having-trouble';
export const TWO_FA_FORGOT_PASSWORD_PAGE = '/forgot-password';
export const TWO_FA_FORGOT_PASSWORD_IDENTITY_PAGE = `${TWO_FA_FORGOT_PASSWORD_PAGE}/verify-identity`;
export const TWO_FA_FORGOT_PASSWORD_IDENTITY_PHONE_PAGE = `${TWO_FA_FORGOT_PASSWORD_PAGE}/verify-identity-phone`;
export const TWO_FA_FORGOT_PASSWORD_IDENTITY_HAVING_TROUBLE_PAGE = `${TWO_FA_FORGOT_PASSWORD_PAGE}/having-trouble`;

export const REGISTRATION_PAGE =
  'https://contoso-registration-qa.bullioninternational.info/r/start';
