import React from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import styled from 'styled-components';
import { formatMoneyNumeral } from '../../util/helpers/numeralHelpers';
import { PERIOD } from '../../constants/period';
import { uFlexColumn } from '../../assets/styles/utility';
import { variables } from '../../assets/styles/variables';
import themeColors from '../../assets/styles/themeColors';

export const ChartTooltip = styled.div`
  ${uFlexColumn};
  position: relative;
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: 0;
  padding: 5px 8px;
  box-shadow: ${variables.shadow.boxShadow};
  background-color: ${themeColors.colorWhite};
  border-radius: ${variables.borderRadius.borderRadius};
  transform: translateY(-50%);
`;

export const ChartTooltipValue = styled.p`
  font-weight: 600;
`;

const CustomizedTooltip = ({ active, payload, selectedPeriod }) => {
  if (active) {
    let date = new Date().toISOString();
    let valuation = 0;

    if (payload) {
      date = payload[0].payload.Date;
      valuation = payload[0].payload.Value;
    }

    const dateISO = parseISO(date);

    const formatedDate =
      selectedPeriod === PERIOD.OneDay || selectedPeriod === PERIOD.OneHour
        ? `${format(dateISO, 'dd/MMM HH:mm')} h`
        : format(dateISO, 'MMM dd yyyy');

    return (
      <ChartTooltip>
        <ChartTooltipValue>{formatMoneyNumeral(valuation)}</ChartTooltipValue>
        <p>{formatedDate}</p>
      </ChartTooltip>
    );
  }
  return null;
};

CustomizedTooltip.propTypes = {
  active: PropTypes.bool,
  selectedPeriod: PropTypes.string,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.shape({
        Date: PropTypes.string,
        Value: PropTypes.number,
      }),
    }),
  ),
};

export default CustomizedTooltip;
