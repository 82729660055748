import React from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import NagDashboardTableRow from './NagDashboardTableRow';
import { parseEnumType } from '../../util/helpers/enumMappers';
import {
  BASIC_ACCOUNT_STEP,
  nagDashboardStepsArray,
  VERIFIED_NON_POST_PAID,
  VERIFIED_POST_PAID,
  VERIFY_ACCOUNT_STEP,
} from '../../constants/nagDashboardContants';
import { BUY_PAGE } from '../../constants/pages';
import Paragraph from '../Paragraph/Paragraph';
import Note from '../Notes/Note';
import { pxToRem } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';

const basicAccountStorageInvestmentStep = {
  title: 'Make your first storage investment',
  description:
    'Take advantage of secure vault storage and the best prices we have to offer.',
  buttonText: 'Buy precious metals',
  status: 'Disabled',
};

const nonVerifiedAccountStorageInvestmentStep = {
  title: 'Make your first storage investment',
  description:
    'Take advantage of secure vault storage and the best prices we have to offer.',
  buttonText: 'Buy precious metals',
  status: 'Disabled',
};

const verifiedAccountStorageInvestmentPostpaidStep = {
  title: 'Make your first storage investment',
  description:
    'Take advantage of secure vault storage and the best prices we have to offer.',
  buttonText: 'Buy precious metals',
  status: 'GetStarted',
};

const verifiedAccountStorageInvestmentNonPostpaidStep = {
  title: 'Make your first storage investment',
  description:
    'Take advantage of secure vault storage and the best prices we have to offer.',
  buttonText: 'Buy precious metals',
  status: 'Disabled',
};

const NagDashboardStorageInvestmentStep = ({ activeStep }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const goToBuyPage = () => {
    history.push(BUY_PAGE);
  };

  const descriptionText = (
    <Paragraph
      marginBottom={16}
      color={`${themeColors.colorTextSecondary}`}
      fontSize={14}
    >
      {t('nagDashboard.takeAdvantageOfSecureVaultStorage')}
      <Note
        primaryColor
        bold
        text={t('nagDashboard.browseMostPopularMetalInvestments')}
        onClick={(event) => {
          event.preventDefault();
          history.push(BUY_PAGE);
        }}
        fontSize={pxToRem(24)}
      />
    </Paragraph>
  );

  const getDashboardFundAccount = () => {
    switch (parseEnumType(nagDashboardStepsArray, activeStep)) {
      case BASIC_ACCOUNT_STEP:
        return {
          ...basicAccountStorageInvestmentStep,
          description: descriptionText,
        };
      case VERIFY_ACCOUNT_STEP:
        return {
          ...nonVerifiedAccountStorageInvestmentStep,
          description: descriptionText,
        };
      case VERIFIED_POST_PAID:
        return {
          ...verifiedAccountStorageInvestmentPostpaidStep,
          action: () => {
            goToBuyPage();
          },
        };
      case VERIFIED_NON_POST_PAID:
        return {
          ...verifiedAccountStorageInvestmentNonPostpaidStep,
          description: descriptionText,
        };

      default:
        return basicAccountStorageInvestmentStep;
    }
  };

  const dashboardData = getDashboardFundAccount();

  return (
    <NagDashboardTableRow
      title={dashboardData.title}
      description={dashboardData.description}
      buttonText={dashboardData.buttonText}
      status={dashboardData.status}
      action={dashboardData.action}
    />
  );
};

NagDashboardStorageInvestmentStep.propTypes = {
  activeStep: PropTypes.number,
};

export default NagDashboardStorageInvestmentStep;
