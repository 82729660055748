import isEmpty from 'lodash.isempty';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash.get';
import { getCurrentUserPermissions } from '../../store/selectors/accountSelectors';
import {
  DISPLAY_IRA_ENTRUST_OPTIONS,
  DISPLAY_IRA_EQUITY_OPTIONS,
  DISPLAY_IRA_MILLENIUM_OPTIONS,
  DISPLAY_IRA_OPTIONS,
  DISPLAY_METALSTREAM_MENUITEM,
  DISPLAY_STANDARD_OPTIONS,
  ALLOW_CLOSED_ACCOUNT_OPTIONS,
  UP_ALLOW_ACC_CHANGES,
  ALLOW_BASIC_ACCOUNT_CHANGES,
  userPermissions,
} from '../enum/api/accountTypes';

const useCheckPermissions = () => {
  const permissions = useSelector(getCurrentUserPermissions);

  const checkPermission = useCallback(
    (type) => {
      if (isEmpty(permissions)) {
        return false;
      }
      const permissionEnumerated = permissions.Permissions.map((permission) =>
        get(userPermissions, permission, 0),
      );
      return permissionEnumerated.includes(type);
    },
    [permissions],
  );

  const allowAccountChangesPermission = checkPermission(UP_ALLOW_ACC_CHANGES);
  const displayIRAOptionsPermission = checkPermission(DISPLAY_IRA_OPTIONS);
  const displayMetalStreamMenuItems = checkPermission(
    DISPLAY_METALSTREAM_MENUITEM,
  );
  const displayIRAEntrustOptionsPermission = checkPermission(
    DISPLAY_IRA_ENTRUST_OPTIONS,
  );
  const displayIRAMilleniumOptionsPermission = checkPermission(
    DISPLAY_IRA_MILLENIUM_OPTIONS,
  );
  const displayIRAEquityOptionsPermission = checkPermission(
    DISPLAY_IRA_EQUITY_OPTIONS,
  );
  const displayStandardOptionsPermission = checkPermission(
    DISPLAY_STANDARD_OPTIONS,
  );
  const displayClosedAccountOptionsPermission = checkPermission(
    ALLOW_CLOSED_ACCOUNT_OPTIONS,
  );

  const displayBasicAccountOptionsPermission = checkPermission(
    ALLOW_BASIC_ACCOUNT_CHANGES,
  );

  return {
    allowAccountChangesPermission,
    displayIRAOptionsPermission,
    displayIRAEntrustOptionsPermission,
    displayIRAMilleniumOptionsPermission,
    displayIRAEquityOptionsPermission,
    displayStandardOptionsPermission,
    displayMetalStreamMenuItems,
    displayClosedAccountOptionsPermission,
    displayBasicAccountOptionsPermission,
  };
};

export default useCheckPermissions;
