import numeral from 'numeral';

numeral.register('locale', 'CAD', {
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: (number) => (number === 1 ? 'er' : 'ème'),
  currency: {
    symbol: 'CAD ',
    position: 'prefix',
    spaceSeparated: 'true',
  },
  defaults: {
    format: '0,0.00',
    currencyFormat: '0[.]00a',
  },
});

numeral.register('locale', 'USD', {
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: (number) => (number === 1 ? 'er' : 'ème'),
  currency: {
    symbol: '$',
    position: 'prefix',
    spaceSeparated: 'true',
  },
  defaults: {
    format: '0,0.00',
    currencyFormat: '0[.]00a',
  },
});

numeral.nullFormat(0);
numeral.zeroFormat(0);
numeral.options.scalePercentBy100 = false;

export const setLocale = (locale = 'en') => {
  numeral.locale(locale);
};

export function formatPercentage(value, formatString = '0%') {
  const number = numeral(value);
  return {
    defaultValue: number.value(),
    formattedValue: number.format(formatString),
  };
}

export function fromPercentage(percentage) {
  return numeral(percentage);
}

export function formatPercentageString(value) {
  return `${value * 100}%`;
}

export function percentageToCurrency(percentage, formatString = '$0,0.00') {
  const number = numeral(percentage).multiply(100);
  return number.format(formatString);
}

export function formatMoneyNumeral(value, formatString = '$0,0.00') {
  const isNegativeValue = value < 0;
  return isNegativeValue
    ? `(${numeral(Math.abs(value)).format(formatString)})`
    : numeral(value).format(formatString);
}

export function formatAbsolutMoneyNumeral(value, formatString = '$0,0.00') {
  return numeral(Math.abs(value)).format(formatString);
}

export function formatCurrency(formatString = '$') {
  return `${formatString}`;
}

export function formatNumber(value, formatString = '0,0.00') {
  return numeral(value).format(formatString);
}
