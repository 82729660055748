import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { ReactComponent as Dollar } from '../../../../assets/images/svg/dollar-round.svg';
import ModalBody from '../../../Modal/ModalBody';
import SingleColumnList from '../../../TwoColumnList/SingleColumnList';
import ModalButtons from '../../../Modal/ModalButtons';
import Paragraph from '../../../Paragraph/Paragraph';
import Label from '../../../Notes/Label';
import {
  mediaBelow,
  pxToRem,
  pxToRemMd,
} from '../../../../assets/styles/helper';
import { variables } from '../../../../assets/styles/variables';
import Status from '../../Status';

const DollarIcon = styled(Dollar)`
  width: ${pxToRem(56)};
  height: ${pxToRem(56)};
  margin: 0 auto ${pxToRem(32)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    width: ${pxToRemMd(56)};
    height: ${pxToRemMd(56)};
    margin: ${pxToRemMd(136)} auto ${pxToRemMd(32)};
  }
`;

const AchDone = ({ text, hasError, onButtonClick }) => {
  const { t } = useTranslation();

  if (hasError) {
    return (
      <Status text={text} hasError={hasError} onButtonClick={onButtonClick} />
    );
  }

  return (
    <ModalBody>
      <SingleColumnList textAlignCenter>
        <DollarIcon />
        <Label text={t('achDeposit.smallDeposits')} isBold marginBottom={32} />
        <Paragraph>{t('achDeposit.inTwoThreeDays')}</Paragraph>
        <ModalButtons
          marginTop
          isVertical
          showOnlyPrimary
          primaryButtonProps={{
            label: t('common.done'),
            onClick: onButtonClick,
          }}
        />
      </SingleColumnList>
    </ModalBody>
  );
};

AchDone.propTypes = {
  onButtonClick: PropTypes.func,
  text: PropTypes.string,
  hasError: PropTypes.bool,
};

export default AchDone;
