import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Paragraph from '../Paragraph/Paragraph';
import themeColors from '../../assets/styles/themeColors';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';

const BulletItemElement = styled.li`
  display: flex;

  > span {
    width: 6px;
    height: 6px;
    background-color: ${themeColors.colorTextPrimary};
    border-radius: 100px;
    margin-right: ${pxToRem(8)};
    margin-top: ${pxToRem(8)};
    border: 1px solid ${themeColors.colorTextPrimary};
    flex-grow: 0;
    flex-shrink: 0;
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    > span {
      margin-right: ${pxToRemMd(8)};
      margin-top: ${pxToRemMd(8)};
    }
  }
`;

const NumItemElement = styled.li`
  display: flex;
  align-items: center;

  > span {
    margin-right: ${pxToRem(8)};
    font-weight: bold;
    flex-grow: 0;
    flex-shrink: 0;
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    > span {
      margin-right: ${pxToRemMd(8)};
      margin-top: ${pxToRemMd(8)};
    }
  }
`;

const BulletItem = ({ num, text }) =>
  num ? (
    <NumItemElement>
      <span>{num}</span>
      <Paragraph>{text}</Paragraph>
    </NumItemElement>
  ) : (
    <BulletItemElement>
      <span />
      <Paragraph>{text}</Paragraph>
    </BulletItemElement>
  );

BulletItem.propTypes = {
  text: PropTypes.string,
  num: PropTypes.string,
};

export default BulletItem;
