import {
  BANK_ACCOUNT_FETCH,
  BANK_ACCOUNT_ERROR,
  BANK_ACCOUNT_SUCCESS,
  BANK_ACCOUNT_DELETE,
  BANK_ACCOUNT_DELETE_ERROR,
  BANK_ACCOUNT_DELETE_SUCCESS,
  BA_BY_ROUTING_NUM_FETCH,
  BA_BY_ROUTING_NUM_SUCCESS,
  BA_BY_ROUTING_NUM_ERROR,
  NEW_WIRE_ACC_DETAILS_UPDATE,
  NEW_WIRE_ACC_DETAILS_SET,
  SUBMIT_NEW_WIRE_ACC,
  SUBMIT_NEW_WIRE_ACC_SUCCESS,
  SUBMIT_NEW_WIRE_ACC_ERROR,
  CLEAR_BANK_ACCOUNT_DELETE_STATE,
  FETCH_SINGLE_BA,
  FETCH_SINGLE_BA_SUCCESS,
  FETCH_SINGLE_BA_ERROR,
  SUBMIT_NEW_ACH,
  VERIFY_BANK_ACCOUNT,
  BA_BY_ROUTING_NUM_CLEAR,
  BANK_ACCOUNTS_BY_TYPE_FETCH,
  BANK_ACCOUNTS_BY_TYPE_FETCH_SUCCESS,
  BANK_ACCOUNTS_BY_TYPE_FETCH_ERROR,
  BA_BY_ROUTING_NUM_REGISTRATION_FETCH,
  BA_BY_ROUTING_NUM_REGISTRATION_SUCCESS,
  BA_BY_ROUTING_NUM_REGISTRATION_ERROR,
  BA_BY_ROUTING_NUM_REGISTRATION_CLEAR,
  CONNECT_ACH_BANK_ACCOUNTS_REGISTRATION,
  CONNECT_ACH_BANK_ACCOUNTS_REGISTRATION_SUCCESS,
  CONNECT_ACH_BANK_ACCOUNTS_REGISTRATION_ERROR,
  FETCH_BANK_ACCOUNTS_REGISTRATION,
  FETCH_BANK_ACCOUNTS_REGISTRATION_SUCCESS,
  FETCH_BANK_ACCOUNTS_REGISTRATION_ERROR,
  FETCH_BANK_ACCOUNTS_REGISTRATION_LOADING,
  FETCH_OBI_MESSAGES,
  FETCH_OBI_MESSAGES_SUCCESS,
  FETCH_OBI_MESSAGES_ERROR,
  CLEAR_OBI_MESSAGES,
  SUBMIT_NEW_ACH_SUCCESS,
  SUBMIT_NEW_ACH_ERROR,
} from './bankAccountActionConstants';

// get bank accouts
export const fetchBankAccounts = (payload) => ({
  type: BANK_ACCOUNT_FETCH,
  payload,
});

export const fetchBankAccountsSuccess = (payload) => ({
  type: BANK_ACCOUNT_SUCCESS,
  payload,
});

export const fetchBankAccountsError = (payload) => ({
  type: BANK_ACCOUNT_ERROR,
  payload,
});

// get single bank accounts
export const fetchSingleBankAccount = (payload) => ({
  type: FETCH_SINGLE_BA,
  payload,
});

export const fetchSingleBankAccountSuccess = (payload) => ({
  type: FETCH_SINGLE_BA_SUCCESS,
  payload,
});

export const fetchSingleBankAccountError = (payload) => ({
  type: FETCH_SINGLE_BA_ERROR,
  payload,
});

// delete bank account
export const deleteBankAccounts = (payload) => ({
  type: BANK_ACCOUNT_DELETE,
  payload,
});

export const deleteBankAccountsSuccess = (payload) => ({
  type: BANK_ACCOUNT_DELETE_SUCCESS,
  payload,
});

export const deleteBankAccountsError = (payload) => ({
  type: BANK_ACCOUNT_DELETE_ERROR,
  payload,
});

// get bank details by routing num
export const fetchBaByRoutingNum = (payload) => ({
  type: BA_BY_ROUTING_NUM_FETCH,
  payload,
});

export const fetchBaByRoutingNumSuccess = (payload) => ({
  type: BA_BY_ROUTING_NUM_SUCCESS,
  payload,
});

export const fetchBaByRoutingNumError = (payload) => ({
  type: BA_BY_ROUTING_NUM_ERROR,
  payload,
});

// clear bank deatails by routing num
export const clearBaByRoutingNum = (payload) => ({
  type: BA_BY_ROUTING_NUM_CLEAR,
  payload,
});

// update new wire account details
export const updateNewWireAccDetails = (payload) => ({
  type: NEW_WIRE_ACC_DETAILS_UPDATE,
  payload,
});

export const setNewWireAccDetails = (payload) => ({
  type: NEW_WIRE_ACC_DETAILS_SET,
  payload,
});

// submit new wire account
export const submitNewWireAccount = (payload) => ({
  type: SUBMIT_NEW_WIRE_ACC,
  payload,
});

export const submitNewWireAccountSuccess = (payload) => ({
  type: SUBMIT_NEW_WIRE_ACC_SUCCESS,
  payload,
});

export const submitNewWireAccountError = (payload) => ({
  type: SUBMIT_NEW_WIRE_ACC_ERROR,
  payload,
});

export const clearBankAccountDeleteError = () => ({
  type: CLEAR_BANK_ACCOUNT_DELETE_STATE,
});

// submit new wire account
export const submitNewAchAccount = (payload) => ({
  type: SUBMIT_NEW_ACH,
  payload,
});

export const submitNewAchAccountSuccess = (payload) => ({
  type: SUBMIT_NEW_ACH_SUCCESS,
  payload,
});

export const submitNewAchAccountError = (payload) => ({
  type: SUBMIT_NEW_ACH_ERROR,
  payload,
});

// verify bank account
export const verifyBankAccount = (payload) => ({
  type: VERIFY_BANK_ACCOUNT,
  payload,
});

// get Wire bank accouts
export const fetchBankAccountsByType = (payload) => ({
  type: BANK_ACCOUNTS_BY_TYPE_FETCH,
  payload,
});

export const fetchBankAccountsByTypeSuccess = (payload) => ({
  type: BANK_ACCOUNTS_BY_TYPE_FETCH_SUCCESS,
  payload,
});

export const fetchBankAccountsByTypeError = (payload) => ({
  type: BANK_ACCOUNTS_BY_TYPE_FETCH_ERROR,
  payload,
});

// get bank details by routing num registration
export const fetchBaByRoutingNumRegistration = (payload) => ({
  type: BA_BY_ROUTING_NUM_REGISTRATION_FETCH,
  payload,
});

export const fetchBaByRoutingNumRegistrationSuccess = (payload) => ({
  type: BA_BY_ROUTING_NUM_REGISTRATION_SUCCESS,
  payload,
});

export const fetchBaByRoutingNumRegistrationError = (payload) => ({
  type: BA_BY_ROUTING_NUM_REGISTRATION_ERROR,
  payload,
});
// clear bank deatails by routing num
export const clearBaByRoutingNumRegistration = (payload) => ({
  type: BA_BY_ROUTING_NUM_REGISTRATION_CLEAR,
  payload,
});
// connect bank account ACH registration
export const connectACHBankAccountRegistration = (payload) => ({
  type: CONNECT_ACH_BANK_ACCOUNTS_REGISTRATION,
  payload,
});

export const connectACHBankAccountRegistrationSuccess = (payload) => ({
  type: CONNECT_ACH_BANK_ACCOUNTS_REGISTRATION_SUCCESS,
  payload,
});

export const connectACHBankAccountRegistrationError = (payload) => ({
  type: CONNECT_ACH_BANK_ACCOUNTS_REGISTRATION_ERROR,
  payload,
});

// get bank accouts registration
export const fetchBankAccountsRegistration = (payload) => ({
  type: FETCH_BANK_ACCOUNTS_REGISTRATION,
  payload,
});

export const fetchBankAccountsRegistrationSuccess = (payload) => ({
  type: FETCH_BANK_ACCOUNTS_REGISTRATION_SUCCESS,
  payload,
});

export const fetchBankAccountsRegistrationError = (payload) => ({
  type: FETCH_BANK_ACCOUNTS_REGISTRATION_ERROR,
  payload,
});

export const fetchBankAccountsRegistrationLoadig = (payload) => ({
  type: FETCH_BANK_ACCOUNTS_REGISTRATION_LOADING,
  payload,
});

// fetch obi messages

export const fetchObiMessages = (payload) => ({
  type: FETCH_OBI_MESSAGES,
  payload,
});

export const fetchObiMessagesSuccess = (payload) => ({
  type: FETCH_OBI_MESSAGES_SUCCESS,
  payload,
});

export const fetchObiMessagesError = (payload) => ({
  type: FETCH_OBI_MESSAGES_ERROR,
  payload,
});

export const clearObiMessages = (payload) => ({
  type: CLEAR_OBI_MESSAGES,
  payload,
});
