import { createSelector } from 'reselect';

const twoFASelector = (state) => state.twoFA;

export const selectTwoFaTypes = createSelector(
  twoFASelector,
  (state) => state.TwoFaTypes,
);

export const selectTwoFAApplicationKeys = createSelector(
  twoFASelector,
  (state) => state.TwoFaApplicationKeys,
);

export const selectTwoFACode = createSelector(
  twoFASelector,
  (state) => state.TwoFaCode,
);

export const selectTwoFaApplication = createSelector(
  twoFASelector,
  (state) => state.TwoFaApplication,
);

export const selectTwoFaIsCustomerSupportOpen = createSelector(
  twoFASelector,
  (state) => state.IsCustomerSupportOpen,
);

export const selectTwoFaMaskedEmail = createSelector(
  twoFASelector,
  (state) => state.TwoFaCode.MaskedEmail,
);

export const selectTwoFaMaskedPhoneNumber = createSelector(
  twoFASelector,
  (state) => state.TwoFaCode.MaskedPhoneNumber,
);

export const selectTwoFaError = createSelector(
  twoFASelector,
  (state) => state.ErrorMessage,
);
