import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';

import isEmpty from 'lodash.isempty';
import CountrySelectModal from '../../BankAccountsPageComponents/NewWireWithdrawal/CountrySelectModal';
import AccountDetailsModal from '../../BankAccountsPageComponents/NewWireWithdrawal/AccountDetailsModal';
import ReviewNewBankAccountModal from '../../BankAccountsPageComponents/NewWireWithdrawal/ReviewNewBankAccountModal';
import Modal from '../../Modal/Modal';
import StepByStep from '../../StepByStep';
import StatusModal from '../StatusModal';

import { submitNewWireAccount } from '../../../store/actions/bankAccount/bankAccountActions';
import {
  closeWizardContent,
  getWizardContent,
  setWizardContent,
} from '../../../util/helpers/wizardHelpers';
import {
  ADD_WIRE_WITHDRAWAL_WIZARD,
  ERROR_MESSAGES,
  NEW_WIRE_RESPONSE_ERROR,
  NEW_WIRE_RESPONSE_MODAL_OPEN,
  PREVENT_SUPPORT_MODAL,
  SELECT_TYPE,
  STANDARD_WITHDRAWAL_WIZARD,
  WITHDRAW_MODAL_OPEN,
} from '../../../constants/sessionStorage';
import { useSessionStorageState } from '../../../util/hooks/useSessionStorageState';
import { retrieveFromSessionStorage } from '../../../util/helpers/sessionStorageHelper';
import { BANK_ACCOUNT_ADDED } from '../../../constants/gtmEvents';
import useGtmHook from '../../../util/hooks/useGtmHook';

const AddWireWithdrawalModal = ({
  accountUid,
  isModalOpen,
  setIsModalOpen,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [
    newWireResponseModalOpen,
    setNewWireResponseModalOpen,
  ] = useSessionStorageState(NEW_WIRE_RESPONSE_MODAL_OPEN, false);
  const [newWireError, setNewWireError] = useSessionStorageState(
    NEW_WIRE_RESPONSE_ERROR,
    false,
  );
  const [isLoading, setIsLoading] = useState(false);

  const { bankAccountGtmEvent } = useGtmHook();

  const handleGtmEvent = (data) => {
    bankAccountGtmEvent(
      BANK_ACCOUNT_ADDED,
      data?.PurposeType,
      data?.BankAccountType,
      !isEmpty(data?.DomesticBank)
        ? data?.DomesticBank?.Address?.CountryIso3Code
        : data?.InternationalBank?.Address?.CountryIso3Code,
    );
  };

  const handleNewWireOnConfirm = (data) => {
    dispatch(
      submitNewWireAccount({
        accountUid,
        data,
        meta: { setNewWireResponseModalOpen, setNewWireError },
        onSuccess: handleGtmEvent(data),
      }),
    );
    setIsLoading(true);
  };

  useEffect(() => {
    const isSupportPrevented = retrieveFromSessionStorage(
      PREVENT_SUPPORT_MODAL,
    );
    if (newWireResponseModalOpen) {
      setIsLoading(false);
      setIsModalOpen(false);

      if (isSupportPrevented) {
        closeWizardContent();
        setWizardContent(STANDARD_WITHDRAWAL_WIZARD, 1);
        setWizardContent(SELECT_TYPE, 'wire');
        setWizardContent(WITHDRAW_MODAL_OPEN, true);
      }
    }
  }, [newWireResponseModalOpen, setIsModalOpen]);

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        size="sm"
        title={i18next.t('bankAccounts.modalTitle')}
        close={() => {
          setIsModalOpen(false);
          onClose();
        }}
      >
        <StepByStep
          sessionScopeStep={ADD_WIRE_WITHDRAWAL_WIZARD}
          onConfirm={handleNewWireOnConfirm}
        >
          {({ goStepBack, goStepForward, onConfirm }) => [
            <CountrySelectModal
              key="country-select-modal"
              cancel={() => {
                closeWizardContent();
                setIsModalOpen(false);
                onClose();
              }}
              sbsForward={goStepForward}
            />,
            <AccountDetailsModal
              key="account-details-modal"
              sbsBack={goStepBack}
              sbsForward={goStepForward}
            />,
            <ReviewNewBankAccountModal
              key="review-new-bank-account-modal"
              sbsBack={goStepBack}
              sbsOnConfirm={onConfirm}
              isLoading={isLoading}
            />,
          ]}
        </StepByStep>
      </Modal>
      <StatusModal
        isOpen={newWireResponseModalOpen}
        hasError={newWireError}
        close={() => setNewWireResponseModalOpen(false)}
        text={
          newWireError
            ? getWizardContent(ERROR_MESSAGES)
            : i18next.t('bankAccounts.newAccountConnected')
        }
        modalTitle={i18next.t('bankAccounts.modalTitle')}
        backButtonText={i18next.t('common.cancel')}
        onButtonClick={
          newWireError
            ? () => {
                onClose();
                closeWizardContent();
                setNewWireResponseModalOpen(false);
                setNewWireError(null);
              }
            : () => {
                onClose();
                closeWizardContent();
                setNewWireResponseModalOpen(false);
                setIsModalOpen(false);
                setNewWireError(null);
              }
        }
      />
    </>
  );
};

AddWireWithdrawalModal.propTypes = {
  accountUid: PropTypes.string,
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  onClose: PropTypes.func,
};

export default AddWireWithdrawalModal;
