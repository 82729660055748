import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';
import Auth from '../../components/Auth/Auth';
import AuthCard from '../../components/Auth/AuthCard';
import { selectTokens } from '../../store/selectors/loginSelectors';
import {
  selectSettings,
  selectIsReCaptchaEnabled,
} from '../../store/selectors/settingsSelectors';
import { USER_LOGIN_PAGE } from '../../constants/pages';
import {
  clearLoginState,
  fetchUsernames,
  logoutUser,
} from '../../store/actions/login/loginActions';
import TextField from '../../components/InputFields/TextField';
import { emailLoginValidationSchema } from '../../validation/emailLoginValidationSchema';
import useGtmHook from '../../util/hooks/useGtmHook';
import config from '../../config';
import { getCurrentAccount } from '../../store/selectors/accountSelectors';
import FormContainer from '../../components/FormContainer/FormContainer';
import AuthCta from '../../components/Auth/AuthCta';
import AuthButtons from '../../components/Auth/AuthButtons';
import { removeFromSessionStorage } from '../../util/helpers/sessionStorageHelper';
import { JWT_REFRESH_TOKEN, JWT_TOKEN } from '../../constants/localStorage';
import Paragraph from '../../components/Paragraph/Paragraph';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';

export const AuthCtaParagraph = styled(Paragraph)`
  margin-top: ${pxToRem(24)};

  > a {
    color: ${themeColors.colorLinkPrimary};
  }
  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-top: ${pxToRemMd(40)};
  }
`;

const EmailLoginPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { gtmScreenView } = useGtmHook();
  const brokerSettings = useSelector(selectSettings);
  const reCaptchaRef = React.createRef();
  const isReCaptchaEnabled = useSelector(selectIsReCaptchaEnabled);
  const tokens = useSelector(selectTokens);
  const currentAccount = useSelector(getCurrentAccount);
  const { RefreshToken, JwtToken } = tokens;

  useEffect(() => {
    gtmScreenView({
      path: window.location.pathname,
      title: 'Login',
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    if (JwtToken) {
      dispatch(logoutUser());
    }
  }, [dispatch, JwtToken]);

  useEffect(() => {
    if (RefreshToken) {
      dispatch(clearLoginState());
    }
  }, [RefreshToken, dispatch]);

  useEffect(() => {
    removeFromSessionStorage(JWT_TOKEN);
    removeFromSessionStorage(JWT_REFRESH_TOKEN);
  }, []); //eslint-disable-line

  const onSuccess = () => {
    history.push(USER_LOGIN_PAGE);
  };

  const onSuccessLogout = async (email, setFieldError) => {
    if (isReCaptchaEnabled) {
      const token = await reCaptchaRef.current.executeAsync();
      reCaptchaRef.current.reset();
      setIsLoading(true);
      dispatch(
        fetchUsernames({
          email: email.trim(),
          token,
          setFieldError,
          setIsLoading,
          onSuccess,
        }),
      );
      return;
    }
    setIsLoading(true);
    dispatch(
      fetchUsernames({
        email: email.trim(),
        setFieldError,
        setIsLoading,
        onSuccess,
      }),
    );
  };

  const onEmailFormSubmit = ({ email }, { setFieldError }) => {
    if (currentAccount) {
      dispatch(logoutUser({ onSuccessLogout, email, setFieldError }));
    } else {
      onSuccessLogout(email, setFieldError);
    }
  };

  return (
    <Auth>
      <AuthCard
        title={t('login.logInTitle')}
        subtitle={t('login.email')}
        isLoading={isLoading}
      >
        <Formik
          initialValues={{ email: '' }}
          onSubmit={onEmailFormSubmit}
          validationSchema={emailLoginValidationSchema}
        >
          <Form>
            <FormContainer>
              <Field
                label={t('login.fields.emailOrUsername')}
                name="email"
                component={TextField}
                data-cy="input-username"
              />
            </FormContainer>
            <AuthButtons
              showOnlyPrimary
              primaryButtonProps={{
                type: 'submit',
                label: t('common.continue'),
                'data-cy': 'button-login-confirm',
              }}
            />
            {isReCaptchaEnabled && (
              <ReCAPTCHA
                ref={reCaptchaRef}
                sitekey={config.recaptcha.siteKey}
                size="invisible"
              />
            )}
          </Form>
        </Formik>
        <AuthCta
          text={t('login.dontHaveAccount')}
          link={brokerSettings.RegistrationUrl}
          linkText={t('login.signUpRecommendation')}
          dataCy="link-sign-up"
        />
      </AuthCard>
    </Auth>
  );
};
export default EmailLoginPage;
