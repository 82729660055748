import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import startCase from 'lodash.startcase';
import Status from './Status';
import { ReactComponent as Caret } from '../../../assets/images/svg/down.svg';
import { buttonizeDiv } from '../../../util/buttonizeDiv';
import { transactionStatusTypesArray } from '../../../util/enum/api/transactionTypes';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import Tooltip from '../../Tooltip/Tooltip';
import useWindowSize from '../../../util/hooks/useIsMobileHook';
import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import themeColors from '../../../assets/styles/themeColors';
import { variables } from '../../../assets/styles/variables';
import { uFlexColumn } from '../../../assets/styles/utility';
import { selectSettings } from '../../../store/selectors/settingsSelectors';

const RecentTransactionCardContainer = styled.div`
  padding: ${pxToRem(18)} ${pxToRem(24)};
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr ${pxToRem(226)} ${pxToRem(16)};
  grid-column-gap: ${pxToRem(24)};
  align-items: center;

  &:nth-child(even) {
    background-color: ${themeColors.colorTableEvenRowBackground};
  }

  &:hover {
    background-color: ${themeColors.colorTableEvenRowHoverBackground};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: ${pxToRemMd(22)} ${pxToRemMd(12)} ${pxToRemMd(22)} ${pxToRemMd(20)};
  }

  ${mediaBelow(variables.breakpoints.bpSm)} {
    grid-template-columns: 1fr auto ${pxToRemMd(16)};
  }
`;

const RecentTransactionCardInfo = styled.div`
  display: flex;
  align-items: center;
`;

const RecentTransactionCardDate = styled.div`
  ${uFlexColumn};
  align-items: center;
  justify-content: center;
  color: ${themeColors.colorTextSecondary};
  margin-right: ${pxToRem(24)};
  font-weight: 600;
  flex-shrink: 0;
`;

const RecentTransactionCardMonth = styled.p`
  text-align: center;
  font-size: ${pxToRem(14)};
  line-height: 1.6;
  letter-spacing: 0;
  text-transform: uppercase;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(14)};
    line-height: 1.72;
  }
`;

const RecentTransactionCardDay = styled.p`
  text-align: center;
  font-size: ${pxToRem(14)};
  line-height: 1.6;
  letter-spacing: 0;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(14)};
    line-height: 1.72;
  }
`;

const RecentTransactionCardTitle = styled.div`
  ${uFlexColumn};
  width: 100%;
`;

const RecentTransactionCardAction = styled.p`
  text-align: left;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    line-height: 1.5;
  }
`;

const RecentTransactionCardProduct = styled.p`
  text-align: left;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    line-height: 1.5;
  }
`;

const RecentTransactionCardStatus = styled.div`
  display: flex;
  justify-content: flex-start;

  ${mediaBelow(variables.breakpoints.bpSm)} {
    margin-right: ${pxToRemMd(12)};
    flex-shrink: 0;
    min-width: 0;
  }
`;

const RecentTransactionCardIcon = styled.div`
  flex-shrink: 0;

  svg {
    color: ${themeColors.colorLabel};
    transform: rotate(-90deg);
  }
`;

const RecentTransactionCard = ({
  month,
  day,
  action,
  actionSubject,
  status,
  onClick,
  isSegregated,
}) => {
  const settings = useSelector(selectSettings);
  const statusType = parseEnumType(transactionStatusTypesArray, status);
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 576;
  const segregatedCaption = settings?.SegregatedCaption;

  return (
    <RecentTransactionCardContainer
      {...buttonizeDiv(onClick)}
      data-cy="list-item-recent-transaction"
    >
      <RecentTransactionCardInfo>
        <RecentTransactionCardDate>
          <RecentTransactionCardMonth>
            {month.substring(0, 3)}
          </RecentTransactionCardMonth>
          <RecentTransactionCardDay>{day}</RecentTransactionCardDay>
        </RecentTransactionCardDate>
        <RecentTransactionCardTitle>
          <RecentTransactionCardAction>{action}</RecentTransactionCardAction>
          <RecentTransactionCardProduct>
            {actionSubject}
            {isSegregated && ` (${segregatedCaption})`}
          </RecentTransactionCardProduct>
        </RecentTransactionCardTitle>
      </RecentTransactionCardInfo>
      <RecentTransactionCardStatus>
        {isMobile ? (
          <Tooltip text={startCase(statusType)}>
            <Status status={statusType} />
          </Tooltip>
        ) : (
          <Status status={statusType} />
        )}
      </RecentTransactionCardStatus>
      <RecentTransactionCardIcon>
        <Caret />
      </RecentTransactionCardIcon>
    </RecentTransactionCardContainer>
  );
};

RecentTransactionCard.propTypes = {
  month: PropTypes.string,
  day: PropTypes.string,
  action: PropTypes.string,
  actionSubject: PropTypes.string,
  status: PropTypes.number,
  onClick: PropTypes.func,
  isSegregated: PropTypes.bool,
};

export default RecentTransactionCard;
