import {
  METAL_STREAM_BANK_ACCOUNTS_ERROR,
  METAL_STREAM_BANK_ACCOUNTS_FETCH,
  METAL_STREAM_BANK_ACCOUNTS_SUCCESS,
  METAL_STREAM_FINISH_SETUP_ERROR,
  METAL_STREAM_FINISH_SETUP_SUCCESS,
  METAL_STREAM_FINISH_SETUP_UPDATE,
  METAL_STREAM_PRODUCT_ERROR,
  METAL_STREAM_PRODUCT_FETCH,
  METAL_STREAM_PRODUCT_SUCCESS,
  MS_SETTINGS_ERROR,
  MS_SETTINGS_FETCH,
  MS_SETTINGS_SUCCESS,
  UPDATE_MS_SETTINGS,
  UPDATE_MS_SETTINGS_ERROR,
  UPDATE_MS_SETTINGS_SUCCESS,
  MS_FUNDING_REGISTRATION_FETCH,
  MS_FUNDING_REGISTRATION_SUCCESS,
  MS_FUNDING_REGISTRATION_ERROR,
  MS_PRODUCT_REGISTRATION_FETCH,
  MS_PRODUCT_REGISTRATION_SUCCESS,
  MS_PRODUCT_REGISTRATION_ERROR,
  METAL_STREAM_FINISH_SETUP_REGISTRATION,
  METAL_STREAM_FINISH_SETUP_REGISTRATION_SUCCESS,
  METAL_STREAM_FINISH_SETUP_REGISTRATION_ERROR,
  MS_AUTO_INVEST,
} from './metalStreamActionConstants';

// metal stream settings

export const fetchMetalStreamSettings = (payload) => ({
  type: MS_SETTINGS_FETCH,
  payload,
});

export const fetchMetalStreamSettingsSuccess = (payload) => ({
  type: MS_SETTINGS_SUCCESS,
  payload,
});

export const fetchMetalStreamSettingsError = (payload) => ({
  type: MS_SETTINGS_ERROR,
  payload,
});

// update metal stream settings

export const updateMetalStreamSettings = (payload) => ({
  type: UPDATE_MS_SETTINGS,
  payload,
});

export const updateMetalStreamSettingsSuccess = (payload) => ({
  type: UPDATE_MS_SETTINGS_SUCCESS,
  payload,
});

export const updateMetalStreamSettingsError = (payload) => ({
  type: UPDATE_MS_SETTINGS_ERROR,
  payload,
});

export const fetchMetalStreamProduct = (payload) => ({
  type: METAL_STREAM_PRODUCT_FETCH,
  payload,
});

export const fetchMetalStreamProductSuccess = (payload) => ({
  type: METAL_STREAM_PRODUCT_SUCCESS,
  payload,
});

export const fetchMetalStreamProductError = (payload) => ({
  type: METAL_STREAM_PRODUCT_ERROR,
  payload,
});

export const fetchMetalStreamBankAccounts = (payload) => ({
  type: METAL_STREAM_BANK_ACCOUNTS_FETCH,
  payload,
});

export const fetchMetalStreamBankAccountsSuccess = (payload) => ({
  type: METAL_STREAM_BANK_ACCOUNTS_SUCCESS,
  payload,
});

export const fetchMetalStreamBankAccountsError = (payload) => ({
  type: METAL_STREAM_BANK_ACCOUNTS_ERROR,
  payload,
});

export const postMetalStreamSetup = (payload) => ({
  type: METAL_STREAM_FINISH_SETUP_UPDATE,
  payload,
});
export const postMetalStreamSetupSuccess = (payload) => ({
  type: METAL_STREAM_FINISH_SETUP_SUCCESS,
  payload,
});

export const postMetalStreamSetupError = (payload) => ({
  type: METAL_STREAM_FINISH_SETUP_ERROR,
  payload,
});

// metal stream fundings registration

export const fetchMetalStreamFundingsRegistration = (payload) => ({
  type: MS_FUNDING_REGISTRATION_FETCH,
  payload,
});

export const fetchMetalStreamFundingsRegistrationSuccess = (payload) => ({
  type: MS_FUNDING_REGISTRATION_SUCCESS,
  payload,
});

export const fetchMetalStreamFundingsRegistrationError = (payload) => ({
  type: MS_FUNDING_REGISTRATION_ERROR,
  payload,
});

// metal stream products registration

export const fetchMetalStreamProductsRegistration = (payload) => ({
  type: MS_PRODUCT_REGISTRATION_FETCH,
  payload,
});

export const fetchMetalStreamProductsRegistrationSuccess = (payload) => ({
  type: MS_PRODUCT_REGISTRATION_SUCCESS,
  payload,
});

export const fetchMetalStreamProductsRegistrationError = (payload) => ({
  type: MS_PRODUCT_REGISTRATION_ERROR,
  payload,
});

// metal stream submit funding registration

export const submitMetalStreamFundingRegistration = (payload) => ({
  type: METAL_STREAM_FINISH_SETUP_REGISTRATION,
  payload,
});
export const submitMetalStreamFundingRegistrationSuccess = (payload) => ({
  type: METAL_STREAM_FINISH_SETUP_REGISTRATION_SUCCESS,
  payload,
});

export const submitMetalStreamFundingRegistrationError = (payload) => ({
  type: METAL_STREAM_FINISH_SETUP_REGISTRATION_ERROR,
  payload,
});

export const setAutoInvest = (payload) => ({
  type: MS_AUTO_INVEST,
  payload,
});
