import { css } from 'styled-components';
import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import { variables } from '../../../assets/styles/variables';
import { TableDataElement } from '../TableData';

export const transactionProductTable = css``;

export const transactionProductTableHead = css`
  display: grid;
  grid-template-columns: 2fr 1fr ${pxToRem(226)} ${pxToRem(16)};
  grid-column-gap: ${pxToRem(24)};

  > :nth-child(2) {
    display: flex;
    justify-content: center;
  }

  ${mediaBelow(variables.breakpoints.bpSm)} {
    display: grid;
    grid-template-columns: 2fr 1fr ${pxToRemMd(16)} ${pxToRemMd(16)};
    grid-column-gap: ${pxToRemMd(24)};

    > :nth-child(2) {
      display: flex;
      justify-content: center;
    }

    > :nth-child(3) {
      p {
        display: none;
      }
    }
  }
`;

export const transactionProductTableBody = css``;

export const transactionProductTableRow = css`
  cursor: pointer;

  ${TableDataElement} {
    display: grid;
    grid-template-columns: 2fr 1fr ${pxToRem(226)} ${pxToRem(16)};
    grid-column-gap: ${pxToRem(24)};

    > :nth-child(2) {
      display: flex;
      justify-content: center;
    }
  }

  ${mediaBelow(variables.breakpoints.bpSm)} {
    ${TableDataElement} {
      display: grid;
      grid-template-columns: 2fr 1fr ${pxToRemMd(16)} ${pxToRemMd(16)};
      grid-column-gap: ${pxToRemMd(24)};

      > :nth-child(2) {
        display: flex;
        justify-content: center;
      }
    }
  }
`;
