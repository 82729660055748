import React from 'react';
import PropTypes from 'prop-types';
import BlockSectionLoader from '../../Loader/BlockSectionLoader';
import LoadMore from '../../LoadMore/LoadMore';
import ProductTransactionsTable from './ProductTransactionsTable';

const ProductTransactions = ({
  transactionList,
  transactionsLoading,
  account,
  handleLoadMore,
  transactionsTotalCount,
}) => {
  const element = document.getElementById('scrollTo')?.offsetTop;

  return (
    <div id="scrollTo">
      <ProductTransactionsTable
        data={transactionList}
        accountUid={account.AccountUid}
      />
      <BlockSectionLoader noShadow isLoading={transactionsLoading} />
      {transactionsTotalCount > 0 && (
        <LoadMore
          loadMoreDisabled={transactionList.length >= transactionsTotalCount}
          onLoadClick={handleLoadMore}
          scrollToElement={element}
        />
      )}
    </div>
  );
};

ProductTransactions.propTypes = {
  transactionList: PropTypes.arrayOf(PropTypes.shape({})),
  transactionsLoading: PropTypes.bool,
  account: PropTypes.shape({
    AccountUid: PropTypes.string,
  }),
  handleLoadMore: PropTypes.func,
  transactionsTotalCount: PropTypes.number,
};

export default ProductTransactions;
