import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash-es/isEmpty';
import CodeField from '../InputFields/CodeField';
import ResendCode from '../../pages/TwoFA/Components/ResendCode';
import { parseEnumType } from '../../util/helpers/enumMappers';
import {
  AuthenticatorApplication,
  twoFaTypesEnum,
} from '../../util/enum/api/twoFATypes';
import { postTwoFa } from '../../store/actions/twoFA/twoFAActions';
import { selectAuthUser } from '../../store/selectors/userSelectors';
import {
  selectTwoFaMaskedEmail,
  selectTwoFaMaskedPhoneNumber,
} from '../../store/selectors/twoFASelector';
import { getTwoFaContent } from '../../util/helpers/twoFaSetupHelper';
import { selectIsLoadingByActionTypes } from '../../store/selectors/loadingSelectors';
import {
  SAVE_TWO_FA_LOADING,
  TWO_FA_CODE_LOADING,
  TWO_FA_TYPES,
} from '../../store/actions/twoFA/twoFAActionConstants';
import SectionLoader from '../Loader/SectionLoader';
import { securityCodeValidationSchema } from '../../validation/securityCodeValidationSchema';
import ModalTitle from '../Modal/ModalTitle';
import Paragraph from '../Paragraph/Paragraph';
import ModalBody from '../Modal/ModalBody';
import SingleColumnList from '../TwoColumnList/SingleColumnList';
import ModalButtons from '../Modal/ModalButtons';
import useWindowSize from '../../util/hooks/useIsMobileHook';

const initialValues = {
  SecurityCode: '',
};

const TwoFAVerify = ({
  goStepBack,
  handleNext,
  twoFaValue,
  getTwoFaCode,
  setIsSuccessful,
  setErrorMessage,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { UserUid, Username } = useSelector(selectAuthUser);
  const maskedEmail =
    useSelector(selectTwoFaMaskedEmail) || getTwoFaContent()?.MaskedEmail;
  const maskedPhoneNumber =
    useSelector(selectTwoFaMaskedPhoneNumber) ||
    getTwoFaContent()?.MaskedPhoneNumber;
  const isLoading = useSelector(
    selectIsLoadingByActionTypes([TWO_FA_CODE_LOADING, SAVE_TWO_FA_LOADING]),
  );
  const { AuthenticationMethodType, MessageType, Phone } = twoFaValue;
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 768;

  const [error, setError] = useState(null);

  const RenderText = () => {
    switch (AuthenticationMethodType) {
      case parseEnumType(twoFaTypesEnum, 2):
        return (
          <>
            <ModalTitle>
              {t('twoFa.profileSettings.verify.phone.title')}
            </ModalTitle>
            {maskedPhoneNumber && (
              <Paragraph marginBottom={32}>
                {t('twoFa.profileSettings.verify.phone.note', {
                  phoneNumber: maskedPhoneNumber,
                })}
              </Paragraph>
            )}
          </>
        );

      case parseEnumType(twoFaTypesEnum, 3):
        return (
          <>
            <ModalTitle>
              {t('twoFa.profileSettings.verify.email.title')}
            </ModalTitle>
            {maskedEmail && (
              <Paragraph marginBottom={32}>
                {t('twoFa.profileSettings.verify.email.note', {
                  email: maskedEmail,
                })}
              </Paragraph>
            )}
          </>
        );

      default:
        return null;
    }
  };
  const emailVerifyObject = () => ({
    AuthenticationMethodType,
    Username,
  });

  const phoneVerifyObject = () => ({
    Phone,
    MessageType,
    AuthenticationMethodType,
    Username,
  });
  const resendCode = () => {
    const isEmailAuthenticationMethodType =
      AuthenticationMethodType === TWO_FA_TYPES.Email;

    const emailVerifyData = () => ({
      ...emailVerifyObject(),
    });

    const phoneVerifyData = () => ({
      ...phoneVerifyObject(),
    });

    const onError = (error) => {
      setError(error);
    };

    const requestData = isEmailAuthenticationMethodType
      ? emailVerifyData()
      : phoneVerifyData();

    if (UserUid && !isEmpty(requestData)) {
      getTwoFaCode({
        userUid: UserUid,
        requestData,
        onSuccess: null,
        onError,
      });
    }
  };

  const formSubmit = (values, { setFieldError }) => {
    const { SecurityCode } = values;
    const isEmailAuthenticationMethodType =
      AuthenticationMethodType === TWO_FA_TYPES.Email;

    const emailVerifyData = () => ({
      SecurityCode,
      ...emailVerifyObject(),
    });

    const phoneVerifyData = () => ({
      SecurityCode,
      ...phoneVerifyObject(),
    });

    const requestData = isEmailAuthenticationMethodType
      ? emailVerifyData()
      : phoneVerifyData();

    dispatch(
      postTwoFa({
        requestData,
        handleNext,
        setFieldError,
        userUid: UserUid,
        setIsSuccessful,
        setErrorMessage,
      }),
    );
  };

  return (
    <SectionLoader isLoading={isLoading}>
      <ModalBody>
        <RenderText />
        <Formik
          onSubmit={formSubmit}
          initialValues={initialValues}
          validationSchema={securityCodeValidationSchema}
        >
          {({ errors, setFieldValue }) => (
            <Form>
              <CodeField
                fixedSize={isMobile}
                name="SecurityCode"
                errors={errors}
                onCodeChange={(value) => setFieldValue('SecurityCode', value)}
                hideExpireCodeNote={
                  parseEnumType(twoFaTypesEnum, AuthenticationMethodType) ===
                  AuthenticatorApplication
                }
                isEmail={maskedEmail}
              />
              <SingleColumnList>
                {error && (
                  <Paragraph isError marginTop={8}>
                    {error ===
                    t('apiErrors.TwoFactorAuthenticationLimitIsExceeded') ? (
                      <strong>
                        {t('twoFa.profileSettings.error.message')}
                      </strong>
                    ) : (
                      <strong>{error}</strong>
                    )}
                  </Paragraph>
                )}
              </SingleColumnList>
              <SingleColumnList marginTop={24} alignCenter>
                <ResendCode onClick={resendCode} />
              </SingleColumnList>
              <ModalButtons
                isHorizontal
                marginTop
                secondaryButtonProps={{ onClick: goStepBack }}
                primaryButtonProps={{ type: 'submit' }}
              />
            </Form>
          )}
        </Formik>
      </ModalBody>
    </SectionLoader>
  );
};

TwoFAVerify.propTypes = {
  twoFaValue: PropTypes.shape({
    AuthenticationMethodType: PropTypes.string,
    MessageType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    Phone: PropTypes.shape({}),
    IsFromSetup: PropTypes.bool,
  }),
  goStepBack: PropTypes.func,
  handleNext: PropTypes.func,
  getTwoFaCode: PropTypes.func,
  setIsSuccessful: PropTypes.func,
  setErrorMessage: PropTypes.func,
};

export default TwoFAVerify;
