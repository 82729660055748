import styled from 'styled-components';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';

const TradingContainer = styled.div`
  margin-top: ${pxToRem(40)};
  display: flex;
  ${(props) => props.css};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: 0 ${pxToRemMd(16)};
  }
`;

export default TradingContainer;
