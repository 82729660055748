import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import NagDashboardTableRow from './NagDashboardTableRow';
import {
  nagDashboardStepsArray,
  BASIC_ACCOUNT_STEP,
  VERIFY_ACCOUNT_STEP,
} from '../../constants/nagDashboardContants';
import { parseEnumType } from '../../util/helpers/enumMappers';
import { useBrokerName } from '../../util/hooks/userBrokerName';

const NagDashboardOpenAccountStep = ({ activeStep }) => {
  const brokerName = useBrokerName();
  const { t } = useTranslation();

  const nonVerifiedOpenAccountStep = {
    title: t('nagDashboard.openAccountStep.nonVerifiedOpenAccountStep.title'),
    description: t(
      'nagDashboard.openAccountStep.nonVerifiedOpenAccountStep.description',
      {
        brokerName,
      },
    ),
    status: t('nagDashboard.openAccountStep.nonVerifiedOpenAccountStep.status'),
  };

  const verifiedOpenAccountStep = {
    title: t('nagDashboard.openAccountStep.verifiedOpenAccountStep.title'),
    description: t(
      'nagDashboard.openAccountStep.verifiedOpenAccountStep.description',
    ),
    status: t('nagDashboard.openAccountStep.verifiedOpenAccountStep.status'),
  };

  const getDashboardData = () => {
    switch (parseEnumType(nagDashboardStepsArray, activeStep)) {
      case BASIC_ACCOUNT_STEP:
      case VERIFY_ACCOUNT_STEP:
        return nonVerifiedOpenAccountStep;

      default:
        return verifiedOpenAccountStep;
    }
  };
  const dashboardData = getDashboardData();

  return (
    <NagDashboardTableRow
      title={dashboardData.title}
      description={dashboardData.description}
      status={dashboardData.status}
    />
  );
};

NagDashboardOpenAccountStep.propTypes = {
  activeStep: PropTypes.number,
};

export default NagDashboardOpenAccountStep;
