import i18next from 'i18next';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as AlertTriangle } from '../../assets/images/svg/alert-triangle.svg';
import themeColors from '../../assets/styles/themeColors';
import { pxToRem } from '../../assets/styles/helper';

export const CashOrderOnlyContainer = styled.div`
  border: 1px solid ${themeColors.colorPrimary};
  border-radius: 4px;
  padding: ${pxToRem(4)};
  margin-top: ${pxToRem(24)};
`;

export const CashOrderOnlyText = styled.p`
  color: ${themeColors.colorPrimary};
  font-size: ${pxToRem(14)};
  line-height: 1.72;
  letter-spacing: 0;

  svg {
    display: inline;
    color: ${themeColors.colorPrimary};
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
    margin-bottom: -2px;
  }
`;

export const CashOrderOnly = () => (
  <CashOrderOnlyContainer>
    <CashOrderOnlyText>
      <AlertTriangle /> {i18next.t('buyWizard.buyForStorage.cashOnly')}
    </CashOrderOnlyText>
  </CashOrderOnlyContainer>
);
