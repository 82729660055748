import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

import Modal from '../../Modal/Modal';
import StepByStep from '../../StepByStep';
import RemoveBankAccount from './RemoveBankAccount';
import Status from '../Status';
import {
  REMOVE_BANK_ACCOUNT_WIZARD,
  WIZARD,
} from '../../../constants/sessionStorage';
import { removeFromSessionStorage } from '../../../util/helpers/sessionStorageHelper';
import { selectIsSuccessfulDelete } from '../../../store/selectors/bankAccountSelectors';

const steps = [RemoveBankAccount, Status];

const RemoveBankAccountModal = ({
  isOpen,
  onClose,
  bankAccountUid,
  removeAccountType,
  removeType,
}) => {
  const isDeleteSuccessful = useSelector(selectIsSuccessfulDelete);
  const [errorMessage, setErrorMessage] = useState('');

  const handleClose = () => {
    removeFromSessionStorage(WIZARD);
    onClose();
  };

  return (
    <Modal
      title={i18next.t('bankAccounts.removeModal.title')}
      close={onClose}
      isOpen={isOpen}
      size="sm"
    >
      <StepByStep
        saveStepDisabled
        sessionScopeStep={REMOVE_BANK_ACCOUNT_WIZARD}
      >
        {({ goStepForward, goStepBack }) =>
          steps.map((step) =>
            React.createElement(step, {
              handleBack: goStepBack,
              handleNext: goStepForward,
              handleClose,
              hasError: !isDeleteSuccessful,
              text: isDeleteSuccessful
                ? i18next.t('bankAccounts.removeModal.successful')
                : errorMessage,

              onButtonClick: handleClose,
              backButtonText: i18next.t('common.cancel'),
              bankAccountUid,
              removeAccountType,
              setErrorMessage,
              removeType,
            }),
          )
        }
      </StepByStep>
    </Modal>
  );
};

RemoveBankAccountModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  bankAccountUid: PropTypes.string,
  removeAccountType: PropTypes.string,
  removeType: PropTypes.number,
};

export default RemoveBankAccountModal;
