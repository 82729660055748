import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { pxToRem } from '../../assets/styles/helper';

const ProfileSettingsButtonGroupStyles = styled.div`
  display: flex;
  align-items: center;

  > :nth-child(2) {
    margin-left: ${pxToRem(16)};
  }
`;

const ProfileSettingsButtonGroup = ({ children }) => (
  <ProfileSettingsButtonGroupStyles>
    {children}
  </ProfileSettingsButtonGroupStyles>
);

ProfileSettingsButtonGroup.propTypes = {
  children: PropTypes.node,
};

export default ProfileSettingsButtonGroup;
