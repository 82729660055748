import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import RemoveAddress from './RemoveAddress';
import Status from '../Status';
import Modal from '../../Modal/Modal';
import StepByStep from '../../StepByStep';
import { REMOVE_ADDRESS_WIZARD } from '../../../constants/sessionStorage';

const steps = [RemoveAddress, Status];

const RemoveAddressModal = ({ isOpen, onClose, addressUid }) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');

  return (
    <Modal title="Delete Address" size="sm" isOpen={isOpen} close={onClose}>
      <StepByStep saveStepDisabled sessionScopeStep={REMOVE_ADDRESS_WIZARD}>
        {({ goStepForward, goStepBack }) =>
          steps.map((step) =>
            React.createElement(step, {
              handleBack: goStepBack,
              handleNext: goStepForward,
              handleClose: onClose,
              onButtonClick: onClose,
              addressUid,
              hasError: !!error,
              text: error || t('account.addresses.removeAddress'),
              backButtonText: t('common.ok'),
              setError,
            }),
          )
        }
      </StepByStep>
    </Modal>
  );
};

RemoveAddressModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  addressUid: PropTypes.string,
};

export default RemoveAddressModal;
