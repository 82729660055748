import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';

import Modal from '../../../Modal/Modal';
import BankAccountList from '../../Deposit/StandardDepositWizard/Components/BankAccountList';
import { formatMoneyNumeral } from '../../../../util/helpers/numeralHelpers';
import PendingBankAccountsModal from '../../PendingBankAccountsModal';
import AddWireWithdrawalModal from '../../BankAccount/AddWireWithdrawalModal';
import CurrencyField from '../../../InputFields/CurrencyField';
import {
  deleteWizardKey,
  setWizardContent,
} from '../../../../util/helpers/wizardHelpers';
import {
  BANK_ACCOUNT,
  IS_ADD_ACCOUNT_MODAL_OPEN,
  IS_CHECK_ACCOUNT_MODAL_OPEN,
  WIRE_WITHDRAWAL_FEE_CREDIT,
  WITHDRAWAL_AMOUNT,
} from '../../../../constants/sessionStorage';
import { useSessionStorageState } from '../../../../util/hooks/useSessionStorageState';
import { wireWithdrawalValidationSchema } from '../../../../validation/wireWIthdrawalValidationSchema';
import ModalBody from '../../../Modal/ModalBody';
import Paragraph from '../../../Paragraph/Paragraph';
import Note from '../../../Notes/Note';
import FormContainer from '../../../FormContainer/FormContainer';
import ModalButtons from '../../../Modal/ModalButtons';
import Label from '../../../Notes/Label';

const WireWithdrawalInformation = ({
  isModalOpen,
  handleBack,
  handleNext,
  handleClose,
  availableBalance,
  wireWithdrawalFee,
  setWithdrawalAccount,
  setWithdrawalAmount,
  setWireWithdrawalFeeCreditAmount,
  wireWithdrawalFeeCreditAmount,
  bankAndAmount,
  bankAccounts,
  accountUid,
  balances,
  setFundsModalInfo,
  isLoading,
  isImpersonating,
}) => {
  const { t } = useTranslation();

  const [
    isAddAccountModalOpen,
    setIsAddAccountModalOpen,
  ] = useSessionStorageState(IS_ADD_ACCOUNT_MODAL_OPEN, false);

  const [
    isCheckAccountModalOpen,
    setIsCheckAccountModalOpen,
  ] = useSessionStorageState(IS_CHECK_ACCOUNT_MODAL_OPEN, false);

  if (isAddAccountModalOpen) {
    return (
      <AddWireWithdrawalModal
        accountUid={accountUid}
        isModalOpen={isAddAccountModalOpen}
        setIsModalOpen={() => {
          deleteWizardKey(IS_ADD_ACCOUNT_MODAL_OPEN);
          setIsAddAccountModalOpen(false);
        }}
        handleClose={() => {
          handleClose();
          setIsAddAccountModalOpen(false);
        }}
      />
    );
  }

  if (isCheckAccountModalOpen) {
    return (
      <PendingBankAccountsModal
        accountUid={accountUid}
        isOpen={isCheckAccountModalOpen}
        setIsOpen={setIsCheckAccountModalOpen}
        handleClose={handleClose}
        isWire
      />
    );
  }

  const handleSubmit = (values) => {
    if (isImpersonating) {
      setWireWithdrawalFeeCreditAmount(values.wireWithdrawalFeeCredit);
      setWizardContent(
        WIRE_WITHDRAWAL_FEE_CREDIT,
        values.wireWithdrawalFeeCredit,
      );
    }
    setWizardContent(WITHDRAWAL_AMOUNT, values.amount);
    setWithdrawalAmount(values.amount);
    handleNext();
  };

  const wireWithdrawalMaxAmmount = availableBalance - wireWithdrawalFee;

  const initialValues = () => {
    const { amount } = bankAndAmount;

    if (isImpersonating) {
      return {
        amount,
        wireWithdrawalFeeCredit: wireWithdrawalFeeCreditAmount,
      };
    }

    return {
      amount,
    };
  };

  const handleBackButtonClick = () => {
    handleBack();
    deleteWizardKey(WITHDRAWAL_AMOUNT);
    deleteWizardKey(BANK_ACCOUNT);
  };

  return (
    <Modal
      title={t('standardWithdrawalWizard.wireWithdrawal.title')}
      size="sm"
      isOpen={isModalOpen}
      close={handleClose}
      isLoading={isLoading}
    >
      <ModalBody>
        <Label
          marginBottom={4}
          isBold
          text={t('standardWithdrawalWizard.availableForWithdrawal')}
        />
        <Paragraph fontSize={18} marginBottom={8}>
          {formatMoneyNumeral(availableBalance)}
        </Paragraph>
        <Note
          text={t('standardWithdrawalWizard.dontSeeFunds')}
          marginBottom={24}
          onClick={() => {
            setWizardContent(
              'FundsModalInfo',
              JSON.stringify({ isOpen: true, balances }),
            );
            setFundsModalInfo({ isOpen: true, balances });
          }}
        />
        <Label
          marginBottom={4}
          isBold
          text={t('standardWithdrawalWizard.wireWithdrawal.wireFee')}
        />
        <Paragraph fontSize={18} marginBottom={24}>
          {formatMoneyNumeral(wireWithdrawalFee)}
        </Paragraph>

        <Formik
          initialValues={initialValues()}
          onSubmit={handleSubmit}
          validationSchema={wireWithdrawalValidationSchema(
            isImpersonating,
            wireWithdrawalMaxAmmount,
          )}
        >
          {({ values, errors, setFieldValue, setFieldTouched }) => (
            <Form>
              <FormContainer>
                <BankAccountList
                  label={t('funding.depositTo')}
                  empty={!bankAccounts.length}
                  handleAddBankAccount={() => {
                    setWizardContent(IS_ADD_ACCOUNT_MODAL_OPEN, true);
                    setIsAddAccountModalOpen(true);
                  }}
                  handleCheckBankAccounts={() => {
                    setWizardContent(IS_CHECK_ACCOUNT_MODAL_OPEN, true);
                    setIsCheckAccountModalOpen(true);
                  }}
                  handleSelectBankAccount={setWithdrawalAccount}
                  bankAccounts={bankAccounts}
                  selectedBankAccount={bankAndAmount.account}
                  noAddOption
                />

                <CurrencyField
                  defaultValue={values.amount}
                  label={t('standardDepositWizard.achDeposit.achAmount')}
                  name="amount"
                  placeholder={formatMoneyNumeral(0)}
                  onChange={(value) => {
                    setFieldValue('amount', value);
                    setFieldTouched('amount', true);
                  }}
                />
                {isImpersonating && (
                  <CurrencyField
                    defaultValue={values.wireWithdrawalFeeCredit}
                    label={t(
                      'standardDepositWizard.achDeposit.wireWithdrawalFeeCredit',
                    )}
                    name="wireWithdrawalFeeCredit"
                    placeholder={formatMoneyNumeral(0)}
                    onChange={(value) => {
                      setFieldValue('wireWithdrawalFeeCredit', value);
                      setFieldTouched('wireWithdrawalFeeCredit', true);
                    }}
                  />
                )}
              </FormContainer>
              <ModalButtons
                isHorizontal
                marginTop
                secondaryButtonProps={{ onClick: handleBackButtonClick }}
                primaryButtonProps={{
                  type: 'submit',
                  disabled:
                    !values.amount ||
                    !isEmpty(errors) ||
                    isEmpty(bankAndAmount.account),
                }}
              />
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

WireWithdrawalInformation.propTypes = {
  isModalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  availableBalance: PropTypes.number,
  wireWithdrawalFee: PropTypes.number,
  setWithdrawalAccount: PropTypes.func,
  setWithdrawalAmount: PropTypes.func,
  setWireWithdrawalFeeCreditAmount: PropTypes.func,
  bankAndAmount: PropTypes.shape({
    account: PropTypes.shape({}),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  bankAccounts: PropTypes.arrayOf(PropTypes.shape({})),
  balances: PropTypes.shape({}),
  setFundsModalInfo: PropTypes.func,
  accountUid: PropTypes.string,
  isLoading: PropTypes.bool,
  isImpersonating: PropTypes.bool,
  wireWithdrawalFeeCreditAmount: PropTypes.number,
};

export default WireWithdrawalInformation;
