import {
  createErrorType,
  createFetchType,
  createLoadingType,
  createSuccessType,
} from '../actionHelpers';

const ACCOUNT_IRA_SETTINGS = 'ACCOUNT_IRA_SETTINGS';

export const FETCH_ACCOUNT_IRA_SETTINGS = createFetchType(ACCOUNT_IRA_SETTINGS);

export const FETCH_ACCOUNT_IRA_SETTINGS_SUCCESS = createSuccessType(
  ACCOUNT_IRA_SETTINGS,
);

export const FETCH_ACCOUNT_IRA_SETTINGS_ERROR = createErrorType(
  ACCOUNT_IRA_SETTINGS,
);

export const FETCH_ACCOUNT_IRA_SETTINGS_LOADER = createLoadingType(
  ACCOUNT_IRA_SETTINGS,
);
