import { combineReducers } from 'redux';
import portfolioValuationReducer from './portfolio/portfolioValuationReducer';
import accountReducer from './account/accountReducer';
import settingsReducer from './settings/settingsReducer';
import bankAccountReducer from './bankAccounts/bankAccountReducer';
import portfolioHoldingsReducer from './portfolio/portfolioHoldingsReducer';
import loginReducer from './login/loginReducer';
import productsReducer from './products/productsReducer';
import documentsReducer from './documents/documentsReducer';
import portfolioBalancesReducer from './portfolio/portfolioBalancesReducer';
import portfolioProductsReducer from './portfolio/portfolioProductsReducer';
import userPermissionsReducer from './account/userPermissionsReducer';
import portfolioTransactions from './portfolio/portfolioTransactionsReducer';
import loadingReducer from './loading/loadingReducer';
import orderReducer from './order/orderReducer';
import metalStreamReducer from './metalStream/metalStreamReducer';
import accountSettingsReducer from './account/accountSettingsReducer';
import iraSettingsReducer from './iraSettings/iraSettingsReducer';
import userReducer from './user/userReducer';
import commonReducer from './common/commonReducer';
import plaidReducer from './plaid/plaidReducer';
import twoFAReducer from './twoFA/twoFAReducer';
import creditCardReducer from './creditCard/creditCardReducer';

export default combineReducers({
  portfolio: combineReducers({
    valuation: portfolioValuationReducer,
    holdings: portfolioHoldingsReducer,
    balances: portfolioBalancesReducer,
    products: portfolioProductsReducer,
    transactions: portfolioTransactions,
  }),
  account: combineReducers({
    current: accountReducer,
    userPermissions: userPermissionsReducer,
    settings: accountSettingsReducer,
  }),
  settings: settingsReducer,
  bankAccount: bankAccountReducer,
  creditCard: creditCardReducer,
  login: loginReducer,
  products: productsReducer,
  documents: documentsReducer,
  iraSettings: iraSettingsReducer,
  loading: loadingReducer,
  order: orderReducer,
  metalStream: metalStreamReducer,
  user: userReducer,
  common: commonReducer,
  plaid: plaidReducer,
  twoFA: twoFAReducer,
});
