import React from 'react';
import PropTypes from 'prop-types';
import PrefundedCheckWithdrawalWizard from '../PrefundedCheckWithdrawalWizard';
import { WITHDRAWAL_WIZARD_TYPES } from '../../../../../constants/standardWithdrawalWizardConstants';
import PrefundedWireWithdrawalWizard from '../PrefundedWireWithdrawalWizard';
import { deleteWizardKey } from '../../../../../util/helpers/wizardHelpers';
import { WITHDRAWAL_VALUE } from '../../../../../constants/sessionStorage';

const EntrustChosenWizard = ({
  withdrawalType,
  accountUid,
  handleBack,
  handleClose,
  handleRefreshTransactions,
  type,
  setWithdrawalType,
  setTitleType,
}) => {
  const goBack = () => {
    deleteWizardKey(WITHDRAWAL_VALUE);
    handleBack();
  };

  return (
    <>
      {withdrawalType === WITHDRAWAL_WIZARD_TYPES.CHECK && (
        <PrefundedCheckWithdrawalWizard
          accountUid={accountUid}
          goBack={goBack}
          closeModal={handleClose}
          handleRefreshTransactions={handleRefreshTransactions}
          type={type}
          setWithdrawalType={setWithdrawalType}
          setTitleType={setTitleType}
        />
      )}
      {withdrawalType === WITHDRAWAL_WIZARD_TYPES.WIRE && (
        <PrefundedWireWithdrawalWizard
          accountUid={accountUid}
          goBack={goBack}
          closeModal={handleClose}
          handleRefreshTransactions={handleRefreshTransactions}
          type={type}
          setWithdrawalType={setWithdrawalType}
          setTitleType={setTitleType}
        />
      )}
    </>
  );
};

EntrustChosenWizard.propTypes = {
  handleBack: PropTypes.func,
  handleRefreshTransactions: PropTypes.func,
  accountUid: PropTypes.string,
  withdrawalType: PropTypes.string,
  handleClose: PropTypes.func,
  type: PropTypes.string,
  setWithdrawalType: PropTypes.func,
  setTitleType: PropTypes.func,
};

export default EntrustChosenWizard;
