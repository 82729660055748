import { createSelector } from 'reselect';
import { authScopeGetHelper } from '../../util/helpers/authScopeHelpers';
import { IS_METAL_STREAM_REGISTRATION_FLOW } from '../../constants/sessionStorage';

const metalStreamSelector = (state) => state.metalStream;
const metalStreamProductsSelector = (state) => state.metalStream.products;
const metaStreamIsAutoInvestSelector = (state) =>
  state.metalStream.registration.autoInvest;
const metalStreamProductsRegistrationSelector = (state) =>
  state.metalStream.registration.products;

export const selectMetalStreamSettings = createSelector(
  metalStreamSelector,
  (state) => state.metalStreamSettings,
);

export const selectMetalStreamError = createSelector(
  metalStreamSelector,
  (state) => state.error,
);

export const selectMetalStreamProduct = (metalType) =>
  createSelector(metalStreamProductsSelector, (state) => state[metalType]);

export const selectMetalStreamBankAccounts = createSelector(
  metalStreamSelector,
  (state) => state.bankAccounts,
);

export const selectMetalStreamSetupError = createSelector(
  metalStreamSelector,
  (state) => state.metalStreamSetupError,
);

export const selectMetalStreamProductRegistration = (metalType) =>
  createSelector(
    metalStreamProductsRegistrationSelector,
    (state) => state[metalType],
  );

export const selectAutoInvestRegistration = createSelector(
  metaStreamIsAutoInvestSelector,
  (state) => state || authScopeGetHelper(IS_METAL_STREAM_REGISTRATION_FLOW),
);
