import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Field } from 'formik';
import Paragraph from '../../Paragraph/Paragraph';
import Checkbox from '../../InputFields/Checkbox';
import themeColors from '../../../assets/styles/themeColors';
import { pxToRem } from '../../../assets/styles/helper';

export const CheckboxWrap = styled.div`
  margin: ${pxToRem(24)} 0;
`;

export const CheckboxText = styled.span`
  font-size: ${pxToRem(16)};
`;

const AutoPayCheckbox = ({ isAutoPayOn, setIsAutoPayOn }) => {
  const { t } = useTranslation();

  return (
    <CheckboxWrap>
      <Field
        name="autoPay"
        component={Checkbox}
        checked={isAutoPayOn}
        variant="Checkbox"
        onChange={() => setIsAutoPayOn(!isAutoPayOn)}
      >
        <Paragraph fontSize={16} bold>
          {t('storageFee.payStorageFeeByCardModal.turnOnCardAutoPay')}
        </Paragraph>
      </Field>
      <Paragraph fontSize={16} color={themeColors.colorTextSecondary} bold>
        {t('storageFee.payStorageFeeByCardModal.turnOnCardAutoPayMessage')}
      </Paragraph>
    </CheckboxWrap>
  );
};

AutoPayCheckbox.propTypes = {
  isAutoPayOn: PropTypes.bool,
  setIsAutoPayOn: PropTypes.func,
};

export default AutoPayCheckbox;
