import styled from 'styled-components';
import { mediaBelow, pxToRem } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';

const scrollHeightControl = pxToRem(350);

const ContainerWithCustomScrollbar = styled.div`
  max-height: calc(100vh - ${scrollHeightControl});
  padding-right: ${pxToRem(16)};
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: ${pxToRem(8)};
    background-color: ${themeColors.colorScrollbarBackground};
    border-radius: $border-radius;
  }

  &::-webkit-scrollbar-thumb {
    width: ${pxToRem(8)};
    background: ${themeColors.colorPrimary};
    border-radius: ${variables.borderRadius.borderRadius};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    max-height: 100%;
    padding-right: 0;
    overflow: visible;
  }
`;

export default ContainerWithCustomScrollbar;
