import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import {
  setAccounts,
  setCurrentAccount,
  userPermissionsError,
} from '../actions/account/accountActions';
import {
  fetchUserSecurityQuestionSuccess,
  fetchUserSecurityQuestionError,
  postPasswordForgottenRequestSuccess,
  postPasswordForgottenRequestError,
  postResetPasswordRequestSuccess,
  postResetPasswordRequestError,
  updateUserStateSuccess,
  updateUserStateError,
  inviteUserSuccess,
  inviteUserError,
  fetchUserPhoneNumbersSuccess,
  fetchUserPhoneNumbersError,
  fetchUserPhoneNumberSuccess,
  fetchUserPhoneNumberError,
  deleteUserPhoneNumberSuccess,
  deleteUserPhoneNumberError,
  inviteUserEmailSuccess,
  inviteUserEmailError,
  postPhoneNumberSuccess,
  fetchUserPhoneNumbers,
  postPhoneNumberError,
} from '../actions/user/userActions';
import {
  REMOVE_USER,
  UPDATE_USER,
  UPDATE_USER_PASS,
  USER_ACCOUNTS_FETCH,
  PASSWORD_FORGOTTEN_REQUEST,
  RESET_PASSWORD_REQUEST,
  UPDATE_USER_STATE,
  USER_SECURITY_QUESTION_FETCH,
  INVITE_USER,
  USER_PHONE_NUMBERS,
  USER_PHONE_NUMBER,
  DELETE_USER_PHONE_NUMBER,
  INVITE_USER_EMAIL,
  SUBMIT_PHONE,
} from '../actions/user/userActionConstants';
import {
  getUserAccountsRequest,
  getUserSecurityQuestion,
  postForgottenPasswordRequest,
  postResetPasswordRequest,
  removeUserRequest,
  updateUserRequest,
  updateUserPassRequest,
  updateUserStateRequest,
  inviteUserRequest,
  getPhoneNumbersRequest,
  getPhoneNumberRequest,
  deletePhoneNumberRequest,
  inviteUserEmailRequest,
  postPhoneNumberRequest,
} from '../../request/userRequests';
import { fetchAccountUsers } from './accountSaga';

import {
  ACCOUNT_UID,
  ERROR,
  INVITE_EMAIL,
  USER_INVITE_STATUS_MODAL,
} from '../../constants/sessionStorage';
import { rejectErrorCodeHelper } from '../../util/helpers/rejectErrorCodeHelper';
import {
  retrieveFromSessionStorage,
  storeInSessionStorage,
} from '../../util/helpers/sessionStorageHelper';
import { setWizardContent } from '../../util/helpers/wizardHelpers';
import history from '../utils/history';
import { EMAIL_LOGIN_PAGE } from '../../constants/pages';

function* fetchUserAccounts({ payload }) {
  try {
    const { data } = yield call(getUserAccountsRequest, payload.userUid);

    if (payload.setDefaultAccount) {
      const currentAccount = getAccount(data);
      yield put(setCurrentAccount(currentAccount));
    }

    yield put(setAccounts(data));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(userPermissionsError(errorMessage));
  }
}

function* removeUser({ payload }) {
  yield call(payload.setIsLoading, true);
  try {
    yield call(removeUserRequest, payload.accountUid, payload.userUid);
    yield call(fetchAccountUsers, {
      payload: { accountUid: payload.accountUid },
    });
    yield call(payload.setHasError, false);
  } catch (error) {
    yield call(payload.setHasError, true);
  } finally {
    yield call(payload.setIsLoading, false);
    yield call(payload?.handleNext);
  }
}

function* updateUser({ payload }) {
  try {
    yield call(payload.setError, '');
    yield call(payload.setIsLoading, true);
    yield call(
      updateUserRequest,
      payload.userUid,
      payload.data,
      payload.actionType,
    );
    yield call(payload.refreshUser);
    yield call(payload.handleGtm);
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield call(payload.setError, errorMessage);
  } finally {
    yield call(payload.setIsLoading, false);
    yield call(payload.handleNext);
  }
}

function* updateUserState({ payload }) {
  try {
    const { data } = yield call(
      updateUserStateRequest,
      payload.userUid,
      payload.data,
      payload.actionType,
    );
    yield put(updateUserStateSuccess(data));
    yield put(updateUserStateError(''));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(updateUserStateError(errorMessage));
  }
}

function* updateUserPass({ payload }) {
  try {
    yield call(payload.setIsLoading, true);
    yield call(updateUserPassRequest, payload.userUid, payload.data);
    yield call(payload.refreshUser);
    yield call(payload.handleGtm);
    yield call(payload.handleNext);
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchUserSecurityQuestionError(errorMessage));

    if (error.response.status === 400) {
      yield call(payload.setFieldError, 'oldPass', errorMessage);
      return;
    }
    yield call(payload.setError, errorMessage);
  } finally {
    yield call(payload.setIsLoading, false);
  }
}

function* fetchUserSecurityQuestion({ payload }) {
  try {
    const { data } = yield call(getUserSecurityQuestion, payload.username);
    if (payload?.onSuccessfullSecurityQuestionFetch) {
      yield call(payload.onSuccessfullSecurityQuestionFetch, data);
    }
    yield put(fetchUserSecurityQuestionSuccess(data));
    yield put(fetchUserSecurityQuestionError(''));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchUserSecurityQuestionError(errorMessage));
  }
}

function* postForgottenPassword({ payload }) {
  try {
    const { data } = yield call(postForgottenPasswordRequest, payload.data);
    yield put(postPasswordForgottenRequestSuccess(data));
    yield put(postPasswordForgottenRequestError(''));
    yield call(payload.handleSuccess);
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(postPasswordForgottenRequestError(errorMessage));
    yield call(payload.setFieldError, 'SecurityCode', errorMessage);
  }
}

function* postResetPassword({ payload }) {
  try {
    const { data } = yield call(postResetPasswordRequest, payload.data);
    yield put(postResetPasswordRequestSuccess(data));
    yield put(postResetPasswordRequestError(''));
    yield call(payload.handleSuccess);
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield call(payload.handleError, errorMessage);
    yield put(postResetPasswordRequestError(errorMessage));
  }
}

function* inviteUser({ payload }) {
  setWizardContent(ERROR, '');
  if (payload?.meta?.setError) {
    payload.meta.setError('');
  }
  if (payload.meta.setIsLoading) {
    yield call(payload.meta.setIsLoading, true);
  }
  try {
    const { data } = yield call(inviteUserRequest, payload.data);
    yield put(inviteUserSuccess(data));
    yield put(inviteUserError(''));
    if (payload.meta.setIsLoading) {
      yield call(payload.meta.setIsLoading, false);
    }
    yield call(payload.meta.handleNext);
    yield call(payload.meta.createSessionUser);
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    setWizardContent(ERROR, errorMessage);
    setWizardContent(USER_INVITE_STATUS_MODAL, true);
    payload.meta.setError(errorMessage);
    payload.meta.setStatusModal(true);
    if (payload.meta.setIsLoading) {
      yield call(payload.meta.setIsLoading, true);
    }
    yield put(inviteUserError(errorMessage));
  }
}

function* inviteUserEmail({ payload }) {
  setWizardContent(ERROR, '');
  payload.setError('');
  try {
    const { data } = yield call(inviteUserEmailRequest, payload.userInviteId);
    if (payload.setEmail) {
      payload.setEmail(data);
      storeInSessionStorage(INVITE_EMAIL, data);
    }
    yield put(inviteUserEmailSuccess(data));
    yield put(inviteUserEmailError(''));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    setWizardContent(ERROR, errorMessage);
    setWizardContent(USER_INVITE_STATUS_MODAL, true);
    payload.setError(errorMessage);
    payload.setStatusModal(true);
    yield put(inviteUserEmailError(errorMessage));
  }
}

function getAccount(accounts) {
  const accountUid = retrieveFromSessionStorage(ACCOUNT_UID);

  if (!accountUid) {
    const account = accounts[0];
    storeInSessionStorage(ACCOUNT_UID, account.AccountUid);
    return account;
  }

  const findCurrentAccount = () =>
    accounts.find((account) => account.AccountUid === accountUid);

  if (findCurrentAccount()) {
    return findCurrentAccount();
  }

  history.push(EMAIL_LOGIN_PAGE);
}

function* getUserPhoneNumbers({ payload }) {
  try {
    const { userUid } = payload;
    if (userUid) {
      const { data } = yield call(getPhoneNumbersRequest, userUid);
      yield put(fetchUserPhoneNumbersSuccess(data));
      if (payload.onFetchSuccess) {
        yield call(payload.onFetchSuccess, data);
      }
    }
    yield put(fetchUserPhoneNumbersError(''));
  } catch (error) {
    const errorMessage = rejectErrorCodeHelper(error);
    yield put(fetchUserPhoneNumbersError(errorMessage));
  }
}

function* getUserPhoneNumber({ payload }) {
  try {
    const { data } = yield call(getPhoneNumberRequest, payload);
    yield put(fetchUserPhoneNumberSuccess(data));
    yield put(fetchUserPhoneNumberError(''));
  } catch (error) {
    const errorMessage = rejectErrorCodeHelper(error);
    yield put(fetchUserPhoneNumberError(errorMessage));
  }
}

function* deleteUserPhoneNumber({ payload }) {
  try {
    yield call(payload.activateDeletingPhoneNumberLoader, true);
    const { data } = yield call(deletePhoneNumberRequest, { ...payload.data });
    if (data) {
      yield put(deleteUserPhoneNumberSuccess());
      yield put(deleteUserPhoneNumberError(''));
      if (payload.onDeleteSuccess) {
        yield call(payload.onDeleteSuccess);
      }
    }
  } catch (error) {
    const errorMessage = rejectErrorCodeHelper(error);
    yield put(deleteUserPhoneNumberError(errorMessage));
    if (payload.setErrorMessage) {
      yield call(payload.setErrorMessage, errorMessage);
    }
  } finally {
    yield call(payload.activateDeletingPhoneNumberLoader, false);
  }
}

function* postPhoneNumber({ payload }) {
  yield call(payload.setIsLoading, true);
  try {
    const { data } = yield call(postPhoneNumberRequest, payload);
    yield put(postPhoneNumberSuccess(data));
    yield put(fetchUserPhoneNumbers({ userUid: payload.userUid }));
    if (data) {
      if (payload.onSuccess) {
        yield call(payload.onSuccess);
      }
      if (payload.onError) {
        yield call(payload.onError, null);
      }
    }
    yield call(payload.setError, false);
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(postPhoneNumberError(errorMessage));

    yield call(payload.setError, errorMessage);
  } finally {
    yield call(payload.setIsLoading, false);
    yield call(payload.handleNext);
  }
}

// postPhoneNumber({
//   userUid: UserUid,
//   data: {
//     ...phoneNumber,
//   },
//   handleNext,
//   onButtonClick,
//   setIsLoading,
//   setError,
// }),

export default function* userSaga() {
  return yield all([
    takeLatest(USER_ACCOUNTS_FETCH, fetchUserAccounts),
    takeLatest(REMOVE_USER, removeUser),
    takeLatest(UPDATE_USER, updateUser),
    takeLatest(UPDATE_USER_STATE, updateUserState),
    takeLatest(UPDATE_USER_PASS, updateUserPass),
    takeLatest(USER_SECURITY_QUESTION_FETCH, fetchUserSecurityQuestion),
    takeLatest(PASSWORD_FORGOTTEN_REQUEST, postForgottenPassword),
    takeLatest(RESET_PASSWORD_REQUEST, postResetPassword),
    takeLatest(INVITE_USER, inviteUser),
    takeLatest(INVITE_USER_EMAIL, inviteUserEmail),
    takeLatest(USER_PHONE_NUMBERS, getUserPhoneNumbers),
    takeLatest(USER_PHONE_NUMBER, getUserPhoneNumber),
    takeLatest(DELETE_USER_PHONE_NUMBER, deleteUserPhoneNumber),
    takeLatest(SUBMIT_PHONE, postPhoneNumber),
  ]);
}
