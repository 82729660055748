/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectSettings } from '../../store/selectors/settingsSelectors';
import { useBrokerName } from '../../util/hooks/userBrokerName';
import SupportSearch from './SupportSearch';
import ModalTitle from '../Modal/ModalTitle';
import TwoColumnList from '../TwoColumnList/TwoColumnList';
import SingleColumnList from '../TwoColumnList/SingleColumnList';
import Paragraph from '../Paragraph/Paragraph';
import SupportLinks from './SupportLinks';
import SupportSearchContainer from './SupportSearchContainer';
import themeColors from '../../assets/styles/themeColors';
import config from '../../config';

const Support = () => {
  const { t } = useTranslation();
  const brokerName = useBrokerName();
  const brokerSettings = useSelector(selectSettings);
  const { brokerTheme } = config;
  const isLPM = brokerTheme === 'LegacyPM';

  return (
    <>
      <ModalTitle color={themeColors.colorPrimary}>
        {t(`support.note`)}
      </ModalTitle>
      <TwoColumnList supportContainer>
        <SingleColumnList>
          <Paragraph marginBottom={24}>
            {t(`support.questions`, { brokerName })}
          </Paragraph>
          <Paragraph marginBottom={24}>
            {t('support.youCanEmail', {
              timerange: brokerSettings.SupportWorkingHours,
            })}
          </Paragraph>
          {!isLPM && (
            <Paragraph marginBottom={24}>
              {t(`support.quickestAnswer`)}
            </Paragraph>
          )}

          {!isLPM && (
            <SupportSearchContainer>
              <SupportSearch />
            </SupportSearchContainer>
          )}
        </SingleColumnList>

        <SupportLinks brokerSettings={brokerSettings} isLPM={isLPM} />
      </TwoColumnList>
    </>
  );
};

Support.propTypes = {};

export default Support;
