import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { pxToRem } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';

const ThinMarketWarningWrap = styled.div`
  background-color: ${themeColors.colorWarningnBackground};
  border-radius: ${variables.borderRadius.borderRadius};
  max-width: ${pxToRem(730)};
  margin-bottom: ${pxToRem(16)};
  padding: ${pxToRem(16)};
`;

const ThinMarketWarning = () => {
  const { t } = useTranslation();

  return (
    <ThinMarketWarningWrap>
      {t('sellWizard.thinMarketWarning')}
    </ThinMarketWarningWrap>
  );
};

ThinMarketWarning.propTypes = {};

export default ThinMarketWarning;
