import { createSelector } from 'reselect';

export const portfolioBalancesSelector = (state) => state.portfolio.balances;

export const selectBalancesAvailable = createSelector(
  portfolioBalancesSelector,
  (state) => state,
);

export const selectBalancesAvailableForTrading = createSelector(
  portfolioBalancesSelector,
  (state) => state.AvailableForTrading,
);

export const selectBalancesAvailableForWithdrawal = createSelector(
  portfolioBalancesSelector,
  (state) => state.AvailableForWithdrawal,
);
