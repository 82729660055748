import { getRequest, replaceInUrl } from './index';
import apiEndpoints from './apiEndpoints';

export const getPortalSecurityQuestionsRequest = () =>
  getRequest(apiEndpoints.common.getPortalSecurityQuestions);

export const getCountryStatesRequest = (countryCode) =>
  getRequest(
    replaceInUrl(apiEndpoints.common.getCountryStates, {
      countryCode,
    }),
  );
