/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '../../Button/Button';
import { getIraSettings } from '../../../store/selectors/iraSettingsSelectors';
import { fetchAccountIraSettings } from '../../../store/actions/iraSettings/iraSettingsActions';
import { getCurrentAccountUid } from '../../../store/selectors/accountSelectors';
import useCheckPermissions from '../../../util/hooks/useCheckPermissions';
import { selectIsLoadingByActionType } from '../../../store/selectors/loadingSelectors';
import { FETCH_ACCOUNT_IRA_SETTINGS_LOADER } from '../../../store/actions/iraSettings/iraSettingsActionConstants';
import SectionLoader from '../../Loader/SectionLoader';
import ModalTitle from '../../Modal/ModalTitle';
import ModalButtons from '../../Modal/ModalButtons';
import Anchor from '../../Anchor/Anchor';
import Paragraph from '../../Paragraph/Paragraph';
import { useBrokerCode } from '../../../util/hooks/useBrokerCode';

const IRADistribution = ({ handleBack, setModalTitle }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector(getIraSettings);
  const accountUid = useSelector(getCurrentAccountUid);
  const brokerCode = useBrokerCode();
  const { displayStandardOptionsPermission } = useCheckPermissions();
  const isLoading = useSelector(
    selectIsLoadingByActionType(FETCH_ACCOUNT_IRA_SETTINGS_LOADER),
  );

  useEffect(() => {
    if (!displayStandardOptionsPermission && accountUid) {
      dispatch(
        fetchAccountIraSettings({
          accountUid,
        }),
      );
    }
  }, [dispatch, accountUid, displayStandardOptionsPermission]);

  useEffect(() => {
    setModalTitle(t('IRAWithdraw.iraDistribution'));
  }, [setModalTitle, t]);

  const myEquityUrl = () => {
    if (!data.IRATrusteeDetails) {
      return '#';
    }

    if (
      data.IraTrusteeDetails &&
      data.IraTrusteeDetails.Url.includes('https')
    ) {
      return data.IraTrusteeDetails.Url;
    }

    return `https://${data.IRATrusteeDetails.Url}`;
  };

  const openLink = () => {
    window.open(myEquityUrl(), '_blank').focus();
  };

  return (
    <SectionLoader isLoading={isLoading}>
      <ModalTitle>{t('IRAWithdraw.takeAnIra')}</ModalTitle>
      <Paragraph marginBottom={24}>
        {t('IRAWithdraw.toRequestDistribution')}
        <Anchor
          type="website"
          text={t('IRAWithdraw.myEquity')}
          value={myEquityUrl()}
        />
        {t('IRAWithdraw.completeDistribution')}
      </Paragraph>
      <Paragraph marginBottom={24}>
        {t('IRAWithdraw.distributionMayBeMade')}
      </Paragraph>
      <Button onClick={openLink} variant="primaryOutlined" size="lg">
        {t('IRAWithdraw.visit')}
      </Button>
      <Paragraph marginBottom={24} marginTop={24}>
        {t('IRAWithdraw.processing', { brokerCode })}
      </Paragraph>
      <ModalTitle>{t('IRAWithdraw.takeAnIraDelivery')}</ModalTitle>
      <Paragraph>
        {t('IRAWithdraw.pleaseVisit')}
        <NavLink to="/portfolio">{t('IRAWithdraw.portfolioPage')}</NavLink>
        {t('IRAWithdraw.select')}
      </Paragraph>
      <ModalButtons
        marginTop
        showOnlyPrimary
        primaryButtonProps={{
          onClick: handleBack,
          size: 'lg',
          label: t('common.back'),
        }}
      />
    </SectionLoader>
  );
};

IRADistribution.propTypes = {
  handleBack: PropTypes.func,
  setModalTitle: PropTypes.func,
};

export default IRADistribution;
