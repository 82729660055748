import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Paragraph from '../Paragraph/Paragraph';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';

const DetailRowElement = styled.div`
  padding: ${pxToRem(9)} ${pxToRem(16)};
  display: grid;
  grid-template-columns: 60% 35%;
  grid-column-gap: 5%;

  > :last-child {
    text-align: right;
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(9)} ${pxToRemMd(16)};
  }
`;

const DetailRow = ({ label, value }) => (
  <DetailRowElement>
    <Paragraph fontSize={14}>{label}</Paragraph>
    <Paragraph fontSize={14}>{value}</Paragraph>
  </DetailRowElement>
);

DetailRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DetailRow;
