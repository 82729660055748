import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';

import i18next from 'i18next';
import TextField from '../../../InputFields/TextField';
import SelectField from '../../../InputFields/SelectField';

import {
  userSuffixTypes,
  UUAT_NAME_CHANGE,
} from '../../../../util/enum/api/accountTypes';
import { selectAuthUser } from '../../../../store/selectors/userSelectors';
import { updateUser } from '../../../../store/actions/user/userActions';
import useGtmHook from '../../../../util/hooks/useGtmHook';
import { refreshUserToken } from '../../../../store/actions/login/loginActions';
import { FULL_NAME_EDITED } from '../../../../constants/gtmEvents';
import { editFullNameValidationSchema } from '../../../../validation/editFullNameValidationSchema';
import ModalButtons from '../../../Modal/ModalButtons';
import FormContainer from '../../../FormContainer/FormContainer';
import ModalBody from '../../../Modal/ModalBody';
import SectionLoader from '../../../Loader/SectionLoader';

const SUFFIX_OPTIONS = [
  { label: i18next.t('common.none'), value: '' },
  ...userSuffixTypes.map((suffix) => ({
    label: suffix,
    value: suffix,
  })),
];

const EditFullName = ({ handleNext, setError, handleClose }) => {
  const { t } = useTranslation();
  const { gtmEvent } = useGtmHook();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector(selectAuthUser);

  const handleGtm = () => {
    gtmEvent(FULL_NAME_EDITED);
  };

  const refreshUser = () => {
    dispatch(refreshUserToken());
  };

  const handleSubmit = (values) =>
    dispatch(
      updateUser({
        userUid: user.UserUid,
        data: [
          { op: 'replace', path: '/FirstName', value: values.firstName },
          { op: 'replace', path: '/LastName', value: values.lastName },
          {
            op: 'replace',
            path: '/MiddleName',
            value: values.middleName ? values.middleName : null,
          },
          {
            op: 'replace',
            path: '/SuffixType',
            value: values.suffix.value
              ? userSuffixTypes.indexOf(values.suffix.value) + 1
              : null,
          },
        ],
        actionType: UUAT_NAME_CHANGE,
        handleGtm,
        refreshUser,
        handleNext,
        setError,
        setIsLoading,
      }),
    );

  const initialValues = {
    firstName: user.FirstName || '',
    middleName: user.MiddleName || '',
    lastName: user.LastName || '',
    suffix: {
      label: user.SuffixType ? user.SuffixType : SUFFIX_OPTIONS[0].label,
      value: user.SuffixType ? user.SuffixType : SUFFIX_OPTIONS[0].value,
    },
  };

  if (isEmpty(user)) {
    return null;
  }

  return (
    <SectionLoader isLoading={isLoading}>
      <ModalBody>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={editFullNameValidationSchema}
        >
          {({ dirty }) => (
            <Form>
              <FormContainer>
                <Field
                  label={t('profileSettings.firstName')}
                  name="firstName"
                  component={TextField}
                />
                <Field
                  label={t('profileSettings.middleName')}
                  name="middleName"
                  component={TextField}
                />
                <Field
                  label={t('profileSettings.lastName')}
                  name="lastName"
                  component={TextField}
                />
                <SelectField
                  label={t('profileSettings.suffix')}
                  name="suffix"
                  options={SUFFIX_OPTIONS}
                />
              </FormContainer>
              <ModalButtons
                isHorizontal
                marginTop
                primaryButtonProps={{
                  type: 'submit',
                  disabled: !dirty,
                  label: t('common.save'),
                }}
                secondaryButtonProps={{
                  onClick: handleClose,
                  label: t('common.cancel'),
                }}
              />
            </Form>
          )}
        </Formik>
      </ModalBody>
    </SectionLoader>
  );
};

EditFullName.propTypes = {
  handleClose: PropTypes.func,
  handleNext: PropTypes.func,
  setError: PropTypes.func,
};

export default EditFullName;
