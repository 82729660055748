import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRA_ENTRUST_TYPE, IRA_EQUITY_TYPE } from '../../../../util/constants';
import InformationList from '../../../InformationList/InformationList';
import InformationListItem from '../../../InformationList/InformationListItem';
import Anchor from '../../../Anchor/Anchor';
import PrintFromURLModal from '../../../PrintFromURLModal/PrintFromURLModal';
import ModalBody from '../../../Modal/ModalBody';
import ModalImage from '../../../Modal/ModalImage';
import ModalTitle from '../../../Modal/ModalTitle';
import Paragraph from '../../../Paragraph/Paragraph';
import { useBrokerCode } from '../../../../util/hooks/useBrokerCode';
import { selectSettings } from '../../../../store/selectors/settingsSelectors';
import AnchorOnClick from '../../../Anchor/AnchorOnClick';
import { SUPPORT_MODAL_OPEN } from '../../../../constants/sessionStorage';
import { useSessionStorageState } from '../../../../util/hooks/useSessionStorageState';
import {
  deleteWizardKey,
  setWizardContent,
} from '../../../../util/helpers/wizardHelpers';
import SupportModal from '../../SupportModal/SupportModal';

const IraToIra = ({
  type,
  onOkClick,
  iraSettings,
  setIsDocumentLoading,
  handleNext,
}) => {
  const { t } = useTranslation();
  const brokerCode = useBrokerCode();
  const isEquity = type === IRA_EQUITY_TYPE;
  const brokerSettings = useSelector(selectSettings);
  const [isSupportModalOpen, setIsSupportModalOpen] = useSessionStorageState(
    SUPPORT_MODAL_OPEN,
    false,
  );

  const typeOfIra = useCallback(() => {
    if (type === IRA_EQUITY_TYPE) {
      return 'Equity Trust';
    }
    return 'Entrust';
  }, [type]);

  const iraDetails = useMemo(() => {
    if (isEmpty(iraSettings)) {
      return {};
    }

    if (type === IRA_EQUITY_TYPE || type === IRA_ENTRUST_TYPE) {
      return isEmpty(iraSettings.IRATrusteeDetails)
        ? {}
        : iraSettings.IRATrusteeDetails;
    }
  }, [iraSettings, type]);

  const openSupportModal = () => {
    setIsSupportModalOpen(true);
    setWizardContent(SUPPORT_MODAL_OPEN, true);
  };

  const closeSupportModal = () => {
    setIsSupportModalOpen(false);
    deleteWizardKey(SUPPORT_MODAL_OPEN);
  };
  return (
    <>
      {isSupportModalOpen ? (
        <SupportModal
          avoidClearingStorage
          isOpen={isSupportModalOpen}
          close={closeSupportModal}
          goBack={closeSupportModal}
        />
      ) : (
        <ModalBody>
          <PrintFromURLModal
            description={
              <>
                <ModalTitle>{t('depositWizard.iraTransfer.title')}</ModalTitle>
                <Paragraph marginBottom={24}>
                  {t('depositWizard.iraTransfer.whatIsATransfer', {
                    type: typeOfIra(),
                    brokerCode,
                  })}
                </Paragraph>
                <Paragraph marginBottom={24}>
                  {t('depositWizard.iraTransfer.toInitiateATransfer', {
                    type: typeOfIra(),
                  })}
                </Paragraph>
                <Paragraph>
                  {t('depositWizard.iraTransfer.formDescription', {
                    brokerCode,
                  })}
                </Paragraph>
              </>
            }
            content={
              <>
                {!isEquity && iraDetails?.LogoImageUrl && (
                  <ModalImage src={iraDetails.LogoImageUrl} alt={typeOfIra()} />
                )}
                {!isEquity && (
                  <Paragraph marginBottom={4} bold>
                    {t('depositWizard.equity.haveQuestionsWithoutContact')}
                  </Paragraph>
                )}
                {isEquity ? (
                  <Paragraph marginBottom={24}>
                    <Trans i18nKey="depositWizard.equity.haveQuestions">
                      {t('depositWizard.iraTransfer.haveQuestions')}
                      <AnchorOnClick
                        onClick={(event) => {
                          event.preventDefault();
                          openSupportModal();
                        }}
                      >
                        Click here
                      </AnchorOnClick>
                      to contact us.
                    </Trans>
                  </Paragraph>
                ) : (
                  <>
                    <Paragraph marginBottom={24}>
                      {t('depositWizard.iraTransfer.contactServiceCenter', {
                        type: typeOfIra(),
                      })}
                    </Paragraph>
                    {iraDetails && (
                      <InformationList withBackground isSmall>
                        <InformationListItem
                          label={t('depositWizard.iraTransfer.phone')}
                          value={
                            <Anchor
                              type="telephone"
                              value={
                                isEquity
                                  ? brokerSettings?.SupportPhoneTollFree
                                  : iraDetails.Phone
                              }
                            />
                          }
                        />
                        {!isEquity && (
                          <InformationListItem
                            label={t('depositWizard.iraTransfer.fax')}
                            value={
                              <Anchor type="telephone" value={iraDetails.Fax} />
                            }
                          />
                        )}
                        <InformationListItem
                          label={t('depositWizard.iraTransfer.email')}
                          value={
                            <Anchor
                              type="email"
                              value={
                                isEquity
                                  ? brokerSettings?.SupportEmail
                                  : iraDetails.Email
                              }
                            />
                          }
                        />
                        {!isEquity && iraDetails.Url && (
                          <InformationListItem
                            label={t('depositWizard.iraTransfer.contact')}
                            value={
                              <Anchor type="website" value={iraDetails.Url} />
                            }
                          />
                        )}
                      </InformationList>
                    )}
                  </>
                )}
                <Paragraph marginBottom={24}>
                  {t('depositWizard.iraTransfer.transferRequest', {
                    type: typeOfIra(),
                  })}
                </Paragraph>
                {type === IRA_EQUITY_TYPE ? (
                  <Paragraph marginBottom={24}>
                    {t('depositWizard.iraTransfer.pleaseWaitForFunds', {
                      type: typeOfIra(),
                    })}
                  </Paragraph>
                ) : (
                  <Paragraph marginBottom={24}>
                    {t('depositWizard.iraTransfer.processLength', {
                      type: typeOfIra(),
                    })}
                  </Paragraph>
                )}
                <Paragraph marginBottom={24}>
                  {t('depositWizard.iraTransfer.recommendation', {
                    brokerCode,
                  })}
                </Paragraph>
              </>
            }
            close={onOkClick}
            setIsDownloadLoading={setIsDocumentLoading}
            documentUrl={
              iraSettings?.IRATrusteeDetails?.IraToIraTransferDocumentUrl
            }
            onHavingTroubleClick={handleNext}
          />
        </ModalBody>
      )}
    </>
  );
};

IraToIra.propTypes = {
  type: PropTypes.string,
  onOkClick: PropTypes.func,
  iraSettings: PropTypes.shape({
    IRATrusteeDetails: PropTypes.shape({
      IraToIraTransferDocumentUrl: PropTypes.string,
    }),
  }),
  setIsDocumentLoading: PropTypes.func,
  handleNext: PropTypes.func,
};

export default IraToIra;
