import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  generateToken,
  resetLoginState,
} from '../../store/actions/login/loginActions';
import SectionLoader from '../../components/Loader/SectionLoader';
import { EMAIL_LOGIN_PAGE } from '../../constants/pages';
import { SESSION_STORAGE_SCOPE } from '../../constants/sessionStorage';
import { useGetIsImpersonating } from '../../util/hooks/useGetIsImpersonating';
import { storeInSessionStorage } from '../../util/helpers/sessionStorageHelper';
import { useQuery } from '../../util/hooks/useQuery';
import { EmptyPage } from '../../components/EmptyPage/EmptyPage';

const ImpersonatePage = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isImpersonating } = useGetIsImpersonating();

  useEffect(() => {
    storeInSessionStorage(SESSION_STORAGE_SCOPE, true);
    dispatch(resetLoginState());

    const { token, accountUid } = query;

    if (!token) {
      history.push(EMAIL_LOGIN_PAGE);
    }

    if (accountUid && token) {
      dispatch(
        generateToken({
          data: `"${token}"`,
          accountUid,
          impersonate: true,
        }),
      );
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    const { page } = query;
    if (page && isImpersonating) {
      history.push(page);
    }
  }, [history, isImpersonating, query]);

  return (
    <EmptyPage>
      <SectionLoader isLoading />
    </EmptyPage>
  );
};

export default ImpersonatePage;
