import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';
import StepByStep from '../../StepByStep';
import {
  METAL_STREAM_WIZARD,
  WIZARD,
  WIZARD_DATA,
} from '../../../constants/sessionStorage';
import Modal from '../../Modal/Modal';
import {
  removeFromSessionStorage,
  retrieveFromSessionStorage,
  storeInSessionStorage,
} from '../../../util/helpers/sessionStorageHelper';
import MetalStreamAmount from './MetalStreamAmount';
import MetalStreamAllocation from './MetalStreamAllocation';
import SelectGoldProduct from './SelectGoldProduct';
import SelectSilverProduct from './SelectSilverProduct';
import MetalStreamReview from './MetalStreamReview';
import Status from '../Status';
import MetalStreamFunding from './MetalStreamFunding';
import config from '../../../config';

const steps = [
  MetalStreamAmount,
  MetalStreamAllocation,
  { metalType: 'gold', component: SelectGoldProduct },
  { metalType: 'silver', component: SelectSilverProduct },
  MetalStreamFunding,
  MetalStreamReview,
  Status,
];

const MetalStreamWizard = ({ isOpen, handleClose, metalStreamSettings }) => {
  const { t } = useTranslation();
  const { metalStreamCaption } = config;
  const [wizardTitle, setWizardTitle] = useState(
    t('metalStream.modalTitle', {
      metalStream: metalStreamCaption,
    }),
  );
  const [modalSize, setModalSize] = useState('sm');
  const [wizardBack, setWizardBack] = useState(null);

  const [wizardData, setWizardData] = useState(
    () => retrieveFromSessionStorage(WIZARD_DATA) || {},
  );

  const setDataForWizard = (value) => {
    setWizardData({ ...wizardData, ...value });
    storeInSessionStorage(WIZARD_DATA, { ...wizardData, ...value });
  };
  const goldProductAllocationCalculation = () => {
    if (!isEmpty(metalStreamSettings)) {
      if (wizardData?.GoldProductConfiguration) {
        return wizardData?.GoldProductConfiguration?.Percentage * 100;
      }

      if (metalStreamSettings?.GoldProductConfiguration) {
        return metalStreamSettings?.GoldProductConfiguration?.Percentage * 100;
      }

      return 0;
    }
  };

  const silverProductAllocationCalculation = () => {
    if (!isEmpty(metalStreamSettings)) {
      if (wizardData?.SilverProductConfiguration) {
        return wizardData?.SilverProductConfiguration?.Percentage * 100;
      }

      if (metalStreamSettings?.SilverProductConfiguration) {
        return (
          metalStreamSettings?.SilverProductConfiguration?.Percentage * 100
        );
      }

      return 0;
    }
  };

  const [productAllocation, setProductAllocation] = useState({
    gold: goldProductAllocationCalculation() || 0,
    silver: silverProductAllocationCalculation() || 100,
  });
  const closeModal = () => {
    handleClose();
    removeFromSessionStorage(WIZARD);
    removeFromSessionStorage(WIZARD_DATA);
  };
  return (
    <Modal
      title={wizardTitle}
      size={modalSize}
      isOpen={isOpen}
      close={closeModal}
      goBack={wizardBack}
    >
      <StepByStep sessionScopeStep={METAL_STREAM_WIZARD}>
        {({ goStepForward, goStepBack }) =>
          steps
            .map((StandardDepositWizardStep) => {
              if (StandardDepositWizardStep.metalType) {
                const allocation = Number(
                  productAllocation[StandardDepositWizardStep.metalType],
                );
                if (!allocation) {
                  return null;
                }
                return React.createElement(
                  StandardDepositWizardStep.component,
                  {
                    handleBack: goStepBack,
                    handleNext: goStepForward,
                    handleClose: closeModal,
                    wizardData,
                    setWizardData: setDataForWizard,
                    setWizardTitle,
                    setWizardBack,
                    setModalSize,
                    onButtonClick: closeModal,
                    productAllocation,
                    setProductAllocation,
                    defaultValues: metalStreamSettings,
                    hasError: wizardData?.errorMessage,
                    text:
                      wizardData?.errorMessage ||
                      t('metalStream.setupSuccessful'),
                  },
                );
              }
              return React.createElement(StandardDepositWizardStep, {
                handleBack: goStepBack,
                handleNext: goStepForward,
                handleClose: closeModal,
                wizardData,
                setWizardData: setDataForWizard,
                setWizardTitle,
                setWizardBack,
                setModalSize,
                onButtonClick: closeModal,
                productAllocation,
                setProductAllocation,
                defaultValues: metalStreamSettings,
                hasError: wizardData?.errorMessage,
                text:
                  wizardData?.errorMessage || t('metalStream.setupSuccessful'),
              });
            })
            .filter(Boolean)
        }
      </StepByStep>
    </Modal>
  );
};

MetalStreamWizard.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  metalStreamSettings: PropTypes.shape({
    GoldProductConfiguration: PropTypes.shape({
      Percentage: PropTypes.number,
    }),
    SilverProductConfiguration: PropTypes.shape({
      Percentage: PropTypes.number,
    }),
  }),
};

export default MetalStreamWizard;
