import { combineReducers } from 'redux';
import buyStorageReducer from '../buy/buyStorageReducer';
import sellWizardReducer from '../sell/sellWizardReducer';
import standardDepositWizardReducer from './standardDepositWizardReducer';
import withdrawalWizardReducer from './withdrawalWizardReducer';

export default combineReducers({
  buyStorage: buyStorageReducer,
  sellWizard: sellWizardReducer,
  standardDepositWizard: standardDepositWizardReducer,
  withdrawalWizard: withdrawalWizardReducer,
});
