import {
  GET_SECURITY_QUESTIONS,
  GET_SECURITY_QUESTIONS_ERROR,
  GET_SECURITY_QUESTIONS_SUCCESS,
  GET_PORTAL_SECURITY_QUESTIONS,
  GET_PORTAL_SECURITY_QUESTIONS_SUCCESS,
  GET_PORTAL_SECURITY_QUESTIONS_ERROR,
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_ERROR,
  GET_PRIMARY_STATES,
  GET_PRIMARY_STATES_SUCCESS,
  GET_PRIMARY_STATES_ERROR,
  GET_MAILING_STATES,
  GET_MAILING_STATES_SUCCESS,
  GET_MAILING_STATES_ERROR,
  GET_TAX_FORMS,
  GET_TAX_FORMS_SUCCESS,
  GET_TAX_FORMS_ERROR,
  GET_STATES,
  GET_STATES_SUCCESS,
  GET_STATES_ERROR,
  GET_CONTRIBUTION_YEARS,
  GET_CONTRIBUTION_YEARS_SUCCESS,
  GET_CONTRIBUTION_YEARS_ERROR,
} from './commonConstants';

export const getSecurityQuestions = (payload) => ({
  type: GET_SECURITY_QUESTIONS,
  payload,
});

export const getSecurityQuestionsSuccess = (payload) => ({
  type: GET_SECURITY_QUESTIONS_SUCCESS,
  payload,
});

export const getSecurityQuestionsError = (payload) => ({
  type: GET_SECURITY_QUESTIONS_ERROR,
  payload,
});

export const getPortalSecurityQuestions = (payload) => ({
  type: GET_PORTAL_SECURITY_QUESTIONS,
  payload,
});

export const getPortalSecurityQuestionsSuccess = (payload) => ({
  type: GET_PORTAL_SECURITY_QUESTIONS_SUCCESS,
  payload,
});

export const getPortalSecurityQuestionsError = (payload) => ({
  type: GET_PORTAL_SECURITY_QUESTIONS_ERROR,
  payload,
});

export const getCountries = (payload) => ({
  type: GET_COUNTRIES,
  payload,
});

export const getCountriesSuccess = (payload) => ({
  type: GET_COUNTRIES_SUCCESS,
  payload,
});

export const getCountriesError = (payload) => ({
  type: GET_COUNTRIES_ERROR,
  payload,
});

export const getStates = (payload) => ({
  type: GET_STATES,
  payload,
});

export const getStatesSuccess = (payload) => ({
  type: GET_STATES_SUCCESS,
  payload,
});

export const getStatesError = (payload) => ({
  type: GET_STATES_ERROR,
  payload,
});

export const getPrimaryStates = (payload) => ({
  type: GET_PRIMARY_STATES,
  payload,
});

export const getPrimaryStatesSuccess = (payload) => ({
  type: GET_PRIMARY_STATES_SUCCESS,
  payload,
});

export const getPrimaryStatesError = (payload) => ({
  type: GET_PRIMARY_STATES_ERROR,
  payload,
});

export const getMailingStates = (payload) => ({
  type: GET_MAILING_STATES,
  payload,
});

export const getMailingStatesSuccess = (payload) => ({
  type: GET_MAILING_STATES_SUCCESS,
  payload,
});

export const getMailingStatesError = (payload) => ({
  type: GET_MAILING_STATES_ERROR,
  payload,
});

export const getTaxForms = (payload) => ({
  type: GET_TAX_FORMS,
  payload,
});

export const getTaxFormsSuccess = (payload) => ({
  type: GET_TAX_FORMS_SUCCESS,
  payload,
});

export const getTaxFormsError = (payload) => ({
  type: GET_TAX_FORMS_ERROR,
  payload,
});

export const getContributionYears = (payload) => ({
  type: GET_CONTRIBUTION_YEARS,
  payload,
});

export const getContributionYearsSuccess = (payload) => ({
  type: GET_CONTRIBUTION_YEARS_SUCCESS,
  payload,
});

export const getContributionYearsError = (payload) => ({
  type: GET_CONTRIBUTION_YEARS_ERROR,
  payload,
});
