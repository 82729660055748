import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import i18next from 'i18next';
import {
  BANK_ACCOUNT_FETCH,
  BA_BY_ROUTING_NUM_FETCH,
  NEW_WIRE_ACC_DETAILS_UPDATE,
  SUBMIT_NEW_WIRE_ACC,
  BANK_ACCOUNT_DELETE,
  FETCH_SINGLE_BA,
  SUBMIT_NEW_ACH,
  VERIFY_BANK_ACCOUNT,
  BA_BY_ROUTING_NUM_CLEAR,
  BANK_ACCOUNTS_BY_TYPE_FETCH,
  BA_BY_ROUTING_NUM_REGISTRATION_CLEAR,
  FETCH_OBI_MESSAGES,
} from '../actions/bankAccount/bankAccountActionConstants';
import {
  deleteBankAccountsError,
  deleteBankAccountsSuccess,
  fetchBaByRoutingNumError,
  fetchBaByRoutingNumSuccess,
  fetchBankAccountsError,
  fetchBankAccountsSuccess,
  fetchSingleBankAccountError,
  fetchSingleBankAccountSuccess,
  fetchBankAccountsByTypeSuccess,
  fetchBankAccountsByTypeError,
  setNewWireAccDetails,
  submitNewWireAccountError,
  submitNewWireAccountSuccess,
  fetchBaByRoutingNumRegistrationSuccess,
  fetchObiMessagesSuccess,
  fetchObiMessagesError,
  submitNewAchAccountSuccess,
  submitNewAchAccountError,
} from '../actions/bankAccount/bankAccountActions';
import {
  getBankAccountsRequest,
  getBankDetailsByRoutingNum,
  postNewAccount,
  deleteBankAccountsRequest,
  getSingleBankAccount,
  verifyBankAccountRequest,
  getBankAccountsByTypeRequest,
  fetchObiMessagesRequest,
} from '../../request/bankAccountRequest';

import { setWizardContent } from '../../util/helpers/wizardHelpers';
import {
  BANK_DETAILS_BY_ROUTING_NUMBER,
  ERROR_MESSAGES,
  NEW_WIRE_ACC_DETAILS,
  NEW_WIRE_RESPONSE_ERROR,
  NEW_WIRE_RESPONSE_MODAL_OPEN,
  SIGNIFYD_SESSION_ID,
} from '../../constants/sessionStorage';
import { rejectErrorCodeHelper } from '../../util/helpers/rejectErrorCodeHelper';
import {
  retrieveFromSessionStorage,
  storeInSessionStorage,
} from '../../util/helpers/sessionStorageHelper';
import loadSignifydDeviceFingerprintScript from '../../signifydDeviceFingerprint';

function* fetchBankAccounts({ payload }) {
  try {
    const { data } = yield call(
      getBankAccountsRequest,
      payload.accountUid,
      payload.accountType,
      payload.accountActive,
    );
    yield put(fetchBankAccountsSuccess(data));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchBankAccountsError(errorMessage));
  }
}

function* fetchSingleBankAccount({ payload }) {
  try {
    const { data } = yield call(getSingleBankAccount, payload);
    yield put(fetchSingleBankAccountSuccess(data));
    yield put(fetchSingleBankAccountError(''));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchSingleBankAccountError(errorMessage));
  }
}

function* deleteBankAccounts({ payload }) {
  const {
    accountUid,
    bankAccountUid,
    type,
    handleNext,
    setIsLoading,
    setErrorMessage,
  } = payload;
  yield call(setIsLoading, true);

  try {
    const { data } = yield call(
      deleteBankAccountsRequest,
      accountUid,
      bankAccountUid,
      type,
    );
    yield put(deleteBankAccountsSuccess(data));
    if (payload.onSuccess) {
      yield call(payload.onSuccess, data);
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(deleteBankAccountsError(errorMessage));
    yield call(setErrorMessage, errorMessage);
  } finally {
    yield call(fetchBankAccounts, { payload: { accountUid } });
    yield call(setIsLoading, false);
    yield call(handleNext);
  }
}
function* fetchBankDetailsByRoutingNum({
  payload: { side, routingNum, country, meta },
}) {
  try {
    const { data } = yield call(getBankDetailsByRoutingNum, routingNum);
    if (side === 'wire') {
      if (Object.values(data).every((elem) => elem === null)) {
        yield call(
          meta.setFieldError,
          meta.fieldName,
          i18next.t('bankAccounts.routingNumberWrong'),
        );
        return;
      }
      yield put(fetchBaByRoutingNumSuccess({ routingNumData: data, country }));
      yield put(fetchBaByRoutingNumError(''));
      setWizardContent(BANK_DETAILS_BY_ROUTING_NUMBER, {
        routingNumData: data,
        country,
      });
      meta.goNextModal();
      return;
    }
    if (side === 'ach') {
      if (Object.values(data).every((elem) => elem === null)) {
        yield call(meta.setErrors, {
          ...meta.errors,
          routingNumber: i18next.t('apiErrors.Banking_RoutingNumberInvalid'),
        });
        yield put(fetchBaByRoutingNumSuccess({}));
        return;
      }
      if (meta) {
        const err = { ...meta.errors };
        if (err.routingNumber) delete err.routingNumber;
        yield call(meta.setErrors, err);
      }
      yield put(fetchBaByRoutingNumSuccess({ routingNumData: data }));
      yield put(fetchBaByRoutingNumError(''));
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchBaByRoutingNumError(errorMessage));
    if (meta.setErrors) {
      yield call(meta.setErrors, {
        ...meta.errors,
        routingNumber: errorMessage,
      });
    }
    if (meta.setFieldError) {
      yield call(meta.setFieldError, meta.fieldName, errorMessage);
    }
  } finally {
    if (meta?.setIsLoading) {
      yield call(meta.setIsLoading, false);
    }
  }
}

function* newWireAccountDetailsUpdate({ payload }) {
  yield put(setNewWireAccDetails(payload));
  setWizardContent(NEW_WIRE_ACC_DETAILS, payload);
}

function* submitNewWireAccount({
  payload: {
    accountUid,
    data,
    meta: { setNewWireResponseModalOpen, setNewWireError },
    onSuccess,
  },
}) {
  try {
    const response = yield call(postNewAccount, accountUid, data);
    yield put(submitNewWireAccountSuccess(response.data));
    if (onSuccess) {
      yield call(onSuccess, data);
    }
    yield call(fetchBankAccounts, { payload: { accountUid } });
    setWizardContent(NEW_WIRE_RESPONSE_ERROR, false);
    setWizardContent(ERROR_MESSAGES, '');
    setNewWireError(false);
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(submitNewWireAccountError(errorMessage));
    setWizardContent(NEW_WIRE_RESPONSE_ERROR, true);
    setWizardContent(ERROR_MESSAGES, errorMessage);
    setNewWireError(true);
  } finally {
    setWizardContent(NEW_WIRE_RESPONSE_MODAL_OPEN, true);
    setNewWireResponseModalOpen(true);
  }
}

function* submitNewAchAccount({
  payload: { data, accountUid, handleApiResponse, onSuccess },
}) {
  try {
    yield call(postNewAccount, accountUid, data);
    yield call(fetchBankAccounts, {
      payload: { accountUid },
    });
    yield call(handleApiResponse, '', false);
    yield put(submitNewAchAccountSuccess(''));
    if (onSuccess) {
      yield call(onSuccess, data);
    }
  } catch (error) {
    const isABADisabled =
      error?.response?.data?.Errors[0].Code ===
      'ABARoutingNumberIsBlockedForACH';
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield call(handleApiResponse, errorMessage, isABADisabled);
    yield put(submitNewAchAccountError(errorMessage));
  }
}

function* verifyBankAccount({ payload }) {
  try {
    const sessionId = retrieveFromSessionStorage(SIGNIFYD_SESSION_ID);
    yield call(payload.setIsLoading, true);
    const { data } = yield call(
      verifyBankAccountRequest,
      payload.accountUid,
      payload.bankAccountUid,
      { ...payload.data, SessionId: sessionId },
    );
    yield call(fetchBankAccounts, {
      payload: { accountUid: payload.accountUid },
    });

    if (data.IsVerified) {
      yield call(payload.setShowAttempts, false);
      yield call(payload.setError, '');
      yield call(payload.handleNext);
      loadSignifydDeviceFingerprintScript();
      const script = document.getElementById('sig-api');
      storeInSessionStorage(SIGNIFYD_SESSION_ID, script.dataset.orderSessionId);
      return;
    }

    if (!data.IsVerified) {
      if (!data.VerificationAttemptsLeft) {
        yield call(
          payload.setError,
          i18next.t('bankAccounts.verify.errorOccurred'),
        );
        yield call(payload.handleNext);
        return;
      }
      // show dialog with verification attempts left
      yield call(payload.setShowAttempts, true);
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield call(payload.setError, i18next.t(errorMessage));
    yield call(payload.handleNext);
  } finally {
    yield call(payload.setIsLoading, false);
  }
}

function* clearBaByRoutingNum() {
  yield put(fetchBaByRoutingNumSuccess({}));
}

function* fetchBankAccountsByType({ payload }) {
  try {
    const { data } = yield call(
      getBankAccountsByTypeRequest,
      payload.accountUid,
      payload.type,
    );
    yield put(fetchBankAccountsByTypeSuccess(data));
    yield put(fetchBankAccountsByTypeError(''));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchBankAccountsByTypeError(errorMessage));
  }
}

function* clearBaByRoutingNumRegistration() {
  yield put(fetchBaByRoutingNumRegistrationSuccess({}));
}

function* fetchObiMessages({ payload }) {
  try {
    const { data } = yield call(
      fetchObiMessagesRequest,
      payload.bankAccountUid,
    );
    yield put(fetchObiMessagesSuccess(data));
    yield put(fetchObiMessagesError, '');
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchObiMessagesError(errorMessage));
  }
}

export default function* bankAccountSaga() {
  yield all([
    takeLatest(BANK_ACCOUNT_FETCH, fetchBankAccounts),
    takeLatest(BA_BY_ROUTING_NUM_FETCH, fetchBankDetailsByRoutingNum),
    takeLatest(NEW_WIRE_ACC_DETAILS_UPDATE, newWireAccountDetailsUpdate),
    takeLatest(SUBMIT_NEW_WIRE_ACC, submitNewWireAccount),
    takeLatest(BANK_ACCOUNT_DELETE, deleteBankAccounts),
    takeLatest(FETCH_SINGLE_BA, fetchSingleBankAccount),
    takeLatest(SUBMIT_NEW_ACH, submitNewAchAccount),
    takeLatest(VERIFY_BANK_ACCOUNT, verifyBankAccount),
    takeLatest(BA_BY_ROUTING_NUM_CLEAR, clearBaByRoutingNum),
    takeLatest(BANK_ACCOUNTS_BY_TYPE_FETCH, fetchBankAccountsByType),
    takeLatest(
      BA_BY_ROUTING_NUM_REGISTRATION_CLEAR,
      clearBaByRoutingNumRegistration,
    ),
    takeLatest(FETCH_OBI_MESSAGES, fetchObiMessages),
  ]);
}
