import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import AddAchManualBankAccountWizard from './AddAchManualBankAccountWizard';
import Status from '../../Status';
import StepByStep from '../../../StepByStep';
import { ADD_NEW_ACH_WIZARD } from '../../../../constants/sessionStorage';
import AddAchBankAccountSelectType from './AddAchBankAccountSelectType';
import { deleteWizardKey } from '../../../../util/helpers/wizardHelpers';

const steps = [
  AddAchBankAccountSelectType,
  AddAchManualBankAccountWizard,
  Status,
];

const AddAchBankAccountWizard = ({
  onClose,
  setWizardData,
  wizardData,
  disableManualAch,
}) => {
  const { t } = useTranslation();

  return (
    <StepByStep sessionScopeStep={ADD_NEW_ACH_WIZARD}>
      {({ goStepForward, goStepBack, goTwoStepsForward }) =>
        steps.map((step) =>
          React.createElement(step, {
            handleBack: goStepBack,
            handleNext: goStepForward,
            handleClose: () => {
              onClose();
              deleteWizardKey(ADD_NEW_ACH_WIZARD);
            },
            goTwoStepsForward,
            text:
              wizardData?.errorMessage ||
              t('bankAccounts.newAccountConnectSuccess'),
            hasError: !!wizardData?.errorMessage,
            backButtonText: t('common.ok'),
            onButtonClick: () => {
              onClose();
              deleteWizardKey(ADD_NEW_ACH_WIZARD);
            },
            setWizardData,
            wizardData,
            disableManualAch,
          }),
        )
      }
    </StepByStep>
  );
};

AddAchBankAccountWizard.propTypes = {
  onClose: PropTypes.func,
  setWizardData: PropTypes.func,
  wizardData: PropTypes.func,
  disableManualAch: PropTypes.bool,
};

export default AddAchBankAccountWizard;
