/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import creditCardTypes from '../../util/enum/api/creditCardTypes';
import Paragraph from '../Paragraph/Paragraph';
import Button from '../Button/Button';
import themeColors from '../../assets/styles/themeColors';
import { pxToRem } from '../../assets/styles/helper';

export const AutoPayStatusWrapper = styled.div`
  display: flex;
  flex-direction: ${({ isOnBalancesPage }) =>
    isOnBalancesPage ? 'column' : 'row'};
  width: 100%;
  justify-content: space-between;
  align-items: ${({ isOnBalancesPage }) =>
    isOnBalancesPage ? 'flex-start' : 'center'};
  margin-top: ${({ isOnBalancesPage }) =>
    isOnBalancesPage ? `${pxToRem(10)}` : `${pxToRem(24)}`};
`;

export const AutoPayStatusTextAndDotWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Dot = styled.div`
  margin-right: ${pxToRem(6)};
  height: ${pxToRem(15)};
  width: ${pxToRem(15)};
  ${({ isAutoPayOn }) =>
    isAutoPayOn && `background-color: ${themeColors.colorSuccess}`};
  border: ${({ isAutoPayOn }) =>
    isAutoPayOn
      ? `1px solid ${themeColors.colorSuccess}`
      : `1px solid ${themeColors.colorTextSecondary}`};
  border-radius: 50%;
`;

export const AutoPayStatusTextWrapper = styled.div`
  flex: 1;
`;

export const AutoPayStatusTextPartOne = styled(Paragraph)`
  padding-right: ${({ isOnBalancesPage, showChangeSettingsButton }) =>
    (isOnBalancesPage || !showChangeSettingsButton) && `${pxToRem(3)}`};
`;

export const ChangeSettingsButton = styled(Button)`
  margin-top: ${({ isOnBalancesPage }) => isOnBalancesPage && `${pxToRem(10)}`};
`;

const AutoPayStatus = ({
  creditCard,
  isAutoPayOn,
  setIsAutoPaySettingsModalOpen,
  isOpenPayStorageFeeByCard,
  setIsOpenPayStorageFeeByCard,
  showChangeSettingsButton,
  isOnBalancesPage,
  hasError,
  setIsAutoPayOn,
  removeCreditCardModalOpen,
  setRemoveCreditCardModalOpen,
  disabled,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (hasError) {
      setIsAutoPayOn(false);
    }
  }, [hasError]);

  const openAutoPaySettings = () => {
    setIsAutoPaySettingsModalOpen(true);
    if (isOpenPayStorageFeeByCard) {
      setIsOpenPayStorageFeeByCard(false);
    }
    if (removeCreditCardModalOpen) {
      setRemoveCreditCardModalOpen(false);
    }
  };

  return (
    <>
      <AutoPayStatusWrapper isOnBalancesPage={isOnBalancesPage}>
        <>
          {isAutoPayOn && !hasError ? (
            <AutoPayStatusTextAndDotWrapper>
              <Dot isAutoPayOn />
              <AutoPayStatusTextWrapper isOnBalancesPage={isOnBalancesPage}>
                <AutoPayStatusTextPartOne
                  fontSize={16}
                  bold
                  color={themeColors.colorSuccess}
                  isOnBalancesPage={isOnBalancesPage}
                  showChangeSettingsButton={showChangeSettingsButton}
                >
                  {t('storageFee.payStorageFeeByCardModal.cardAutoPayIsOn')}
                </AutoPayStatusTextPartOne>
                <Paragraph fontSize={16} bold color={themeColors.colorSuccess}>
                  {t(
                    'storageFee.payStorageFeeByCardModal.usingCreditCardType',
                    {
                      cardType: t(
                        `creditCards.creditCardTypes.${
                          creditCardTypes[creditCard?.CreditCardType]
                        }`,
                      ),
                      last4Digits: creditCard?.Last4Digits,
                    },
                  )}
                </Paragraph>
              </AutoPayStatusTextWrapper>
            </AutoPayStatusTextAndDotWrapper>
          ) : (
            <AutoPayStatusTextAndDotWrapper>
              <Dot />
              <Paragraph
                fontSize={16}
                bold
                color={themeColors.colorTextSecondary}
              >
                {t('storageFee.payStorageFeeByCardModal.cardAutoPayIsOff')}
              </Paragraph>
            </AutoPayStatusTextAndDotWrapper>
          )}
        </>
        {showChangeSettingsButton && (
          <ChangeSettingsButton
            textTransform="capitalize"
            variant="primaryOutlined"
            onClick={openAutoPaySettings}
            isOnBalancesPage={isOnBalancesPage}
            disabled={disabled}
          >
            {t('storageFee.payStorageFeeByCardModal.changeSettings')}
          </ChangeSettingsButton>
        )}
      </AutoPayStatusWrapper>
    </>
  );
};

AutoPayStatus.defaultProps = {
  showChangeSettingsButton: true,
};

AutoPayStatus.propTypes = {
  creditCard: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  isAutoPayOn: PropTypes.bool,
  isOnBalancesPage: PropTypes.bool,
  setIsAutoPaySettingsModalOpen: PropTypes.func,
  isOpenPayStorageFeeByCard: PropTypes.bool,
  setIsOpenPayStorageFeeByCard: PropTypes.func,
  showChangeSettingsButton: PropTypes.bool,
  hasError: PropTypes.bool,
  setIsAutoPayOn: PropTypes.func,
  removeCreditCardModalOpen: PropTypes.bool,
  setRemoveCreditCardModalOpen: PropTypes.func,
  disabled: PropTypes.bool,
};

export default AutoPayStatus;
