import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { twoFaMessageType } from '../../../util/enum/api/twoFATypes';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import Label from '../../../components/Notes/Label';
import TypeList from '../../../components/TypeList/TypeList';
import Paragraph from '../../../components/Paragraph/Paragraph';
import SingleColumnList from '../../../components/TwoColumnList/SingleColumnList';
import RadioBox from '../../../components/InputFields/RadioBox';

const TwoFaPhoneSelectRecieveType = ({ values, errors }) => {
  const { t } = useTranslation();

  return (
    <SingleColumnList>
      <Label
        text={t('twoFa.loginSetup.phone.receiveType.label')}
        marginBottom={8}
      />

      <TypeList>
        <Field
          component={RadioBox}
          selected={values?.Type === parseEnumType(twoFaMessageType, 1)}
          type="radio"
          name="Type"
          value={parseEnumType(twoFaMessageType, 1)}
          label={t('twoFa.loginSetup.phone.receiveType.message.label')}
          cta={t('twoFa.loginSetup.phone.receiveType.message.note')}
        />
        <Field
          component={RadioBox}
          selected={values?.Type === parseEnumType(twoFaMessageType, 2)}
          type="radio"
          name="Type"
          value={parseEnumType(twoFaMessageType, 2)}
          label={t('twoFa.loginSetup.phone.receiveType.phoneCall.label')}
          disabled
        />
      </TypeList>
      {errors?.Type && (
        <Paragraph bold isError>
          {errors.Type}
        </Paragraph>
      )}
    </SingleColumnList>
  );
};

TwoFaPhoneSelectRecieveType.propTypes = {
  values: PropTypes.shape({
    Type: PropTypes.string,
  }),
  errors: PropTypes.shape({
    Type: PropTypes.string,
  }),
};

export default TwoFaPhoneSelectRecieveType;
