export const ACC_PURPOSE_ACH = 'AchDeposit';
export const ACC_PURPOSE_WIRE = 'WireWithdrawal';
export const bankAccountPurposeTypes = [ACC_PURPOSE_ACH, ACC_PURPOSE_WIRE];

export const bankAccountTypes = [
  'Checking',
  'Savings',
  '',
  '',
  'BrokerageWithChecking',
];

export const bankAccountApprovalUnknownStatusType = 'Unknown';
export const bankAccountApprovalDisabledStatusType = 'Disabled';
export const bankAccountApprovalPendingVerificationStatusType =
  'PendingVerification';
export const bankAccountApprovalPendingActivationStatusType =
  'PendingActivation';
export const bankAccountApprovalActivatedStatusType = 'Activated';
export const bankAccountApprovalFailedVerificationStatusType =
  'FailedVerification';
export const bankAccountApprovalExpiredVerificationStatusType =
  'ExpiredVerification';
export const bankAccountApprovalPendingVerificationPaymentSubmissionStatusType =
  'PendingVerificationPaymentSubmission';

export const bankAccountApprovalStatus = [
  bankAccountApprovalUnknownStatusType,
  bankAccountApprovalDisabledStatusType,
  bankAccountApprovalPendingVerificationStatusType,
  bankAccountApprovalPendingActivationStatusType,
  bankAccountApprovalActivatedStatusType,
  bankAccountApprovalFailedVerificationStatusType,
  bankAccountApprovalExpiredVerificationStatusType,
  bankAccountApprovalPendingVerificationPaymentSubmissionStatusType,
];

export const voidedCheckIncompleteStatusType = 'Incomplete';
export const voidedCheckReviewStatusType = 'InReview';
export const voidedCheckCompleteStatusType = 'Complete';
export const voidedCheckReUploadStatusType = 'ReuploadNeeded';
export const voidedCheckDeclinedStatusType = 'Declined';
export const voidedCheckNotReadyStatusType = 'NotReady';

export const VOIDED_CHECK_ENUM_STATUS_TYPES = {
  INCOMPLETE: 1,
  IN_REVIEW: 2,
  COMPLETE: 3,
  RE_UPLOAD_NEEDED: 4,
  DECLINED: 5,
  NOT_READY: 6,
};

export const voidedCheckStatusTypes = [
  voidedCheckIncompleteStatusType,
  voidedCheckReviewStatusType,
  voidedCheckCompleteStatusType,
  voidedCheckReUploadStatusType,
  voidedCheckDeclinedStatusType,
  voidedCheckNotReadyStatusType,
];

export const BANK_ACCOUNT_APPROVAL_STATE_PENDING_VERIFICATION =
  'PendingVerification';
export const BANK_ACCOUNT_APPROVAL_STATE_PENDING_ACTIVATION =
  'PendingActivation';
export const BANK_ACCOUNT_FAILED_VERIFICATION = 'FailedVerification';
export const BANK_ACCOUNT_EXPIRED_VERIFICATION = 'ExpiredVerification';

export const bankAccountSetupTypes = ['None', 'Manual', 'Plaid'];
