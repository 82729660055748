import React from 'react';
import PropTypes from 'prop-types';
import { buttonizeDiv } from '../../util/buttonizeDiv';

const AnchorOnClick = ({ onClick, children }) => (
  <a {...buttonizeDiv(onClick)}>{children}</a>
);

AnchorOnClick.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default AnchorOnClick;
