import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { usePopUpInstructions } from '../../../util/hooks/usePopUpInstructions';
import ModalBody from '../../Modal/ModalBody';
import ModalTitle from '../../Modal/ModalTitle';

const PopUpInstructions = ({ setWizardData, handleBack }) => {
  const { t } = useTranslation();

  useEffect(() => {
    setWizardData({
      wizardHandleBack: handleBack,
      wizardSize: 'lg',
      wizardTitle: t('statements.popUp.title'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalBody>
      <ModalTitle>{t('statements.popUp.title')}</ModalTitle>
      {usePopUpInstructions()}
    </ModalBody>
  );
};
PopUpInstructions.propTypes = {
  setWizardData: PropTypes.func,
  handleBack: PropTypes.func,
};

export default PopUpInstructions;
