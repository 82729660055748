/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { uuid } from 'uuidv4';
import { formatMoneyNumeral } from '../../../util/helpers/numeralHelpers';
import InformationList from '../../InformationList/InformationList';
import InformationListItem from '../../InformationList/InformationListItem';
import { selectSettings } from '../../../store/selectors/settingsSelectors';
import { lockedPriceQuantityEstimatedSubtotal } from '../../../util/helpers/sellOrderHelpers';

const SellCalculation = ({
  unitPrice,
  quantity,
  wizardData,
  setWizardData,
  tiers,
  barsArray = [],
  isLockedPriceUser,
  setPricePerUnit,
  isProductPage,
  isReviewModal,
  isFractionalProduct,
}) => {
  const { t } = useTranslation();
  const { calculation } = wizardData;
  const settings = useSelector(selectSettings);
  const [sellCalculationData, setSellCalculationData] = useState([]);

  const calculateEstimatedOrderTotal = () => {
    if (
      !isLockedPriceUser ||
      !tiers ||
      isEmpty(tiers) ||
      !isFractionalProduct
    ) {
      return {
        estimatedOrderTotal: unitPrice * quantity,
        newPricePerUnit: unitPrice,
      };
    }

    const lockedPriceQuantityOrderSubtotal = lockedPriceQuantityEstimatedSubtotal(
      quantity,
      tiers,
      setPricePerUnit,
      isProductPage,
      unitPrice,
      barsArray,
    );

    return lockedPriceQuantityOrderSubtotal;
  };

  useEffect(() => {
    const {
      estimatedOrderTotal,
      newPricePerUnit,
    } = calculateEstimatedOrderTotal();

    const calculation = {
      estimatedOrderTotal,
      newPricePerUnit: newPricePerUnit ?? unitPrice,
      quantity,
    };

    setWizardData({ ...wizardData, calculation });
  }, [unitPrice, quantity, tiers]);

  useEffect(() => {
    if (!settings || isEmpty(settings) || !settings?.BrokerCode) {
      return;
    }

    const dataForSellCalculation = [
      {
        value: formatMoneyNumeral(calculation?.estimatedOrderTotal),
        label:
          settings.IsLockedPricingActive && !isFractionalProduct
            ? t('buyWizard.buyForStorage.calculations.orderTotal')
            : t('buyWizard.buyForStorage.calculations.estimatedOrderTotal'),
        isBold: true,
      },
    ];
    setSellCalculationData(dataForSellCalculation);
  }, [quantity, calculation, settings, tiers]);

  if (isEmpty(wizardData)) {
    return null;
  }

  if (calculation && quantity > 0 && !isReviewModal) {
    return (
      <InformationList marginTop={24}>
        {sellCalculationData.map(({ isBold, value, label, isError }) => (
          <InformationListItem
            key={uuid()}
            fullWidth
            isBold={isBold}
            value={value}
            label={label}
            isError={isError}
          />
        ))}
      </InformationList>
    );
  }

  return null;
};

SellCalculation.propTypes = {
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  unitPrice: PropTypes.number,
  setWizardData: PropTypes.func,
  wizardData: PropTypes.shape({
    calculation: PropTypes.shape({
      estimatedOrderTotal: PropTypes.number,
      cashBuffer: PropTypes.number,
      estimatedOrderTotalWithCashBuffer: PropTypes.number,
    }),
    balanceAvailable: PropTypes.number,
  }),
  tiers: PropTypes.arrayOf(
    PropTypes.shape({
      HighBound: PropTypes.number,
      LowBound: PropTypes.number,
      PricePerOz: PropTypes.number,
      PricePerUnit: PropTypes.number,
    }),
  ),
  barsArray: PropTypes.arrayOf(
    PropTypes.shape({
      BrandCode: PropTypes.string,
      SerialNumber: PropTypes.string,
      FineWeight: PropTypes.number,
      SequenceNumber: PropTypes.number,
    }),
  ),
  isLockedPriceUser: PropTypes.bool,
  setPricePerUnit: PropTypes.func,
  isProductPage: PropTypes.bool,
  isReviewModal: PropTypes.bool,
  isFractionalProduct: PropTypes.bool,
};

export default SellCalculation;
