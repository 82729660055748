import { useEffect } from 'react';
import { createWorker } from '../../workers';
import { apiDefaultUrl, replaceInUrl } from '../../request';
import apiEndpoints from '../../request/apiEndpoints';
import { PRODUCTS_PRICES_FETCH_SUCCESS } from '../../store/actions/products/productActionConstants';
import { JWT_TOKEN } from '../../constants/localStorage';
import { authScopeStringGetHelper } from '../helpers/authScopeHelpers';

export default function useGetPeriodicProductCodes(
  workerName,
  account,
  side,
  hasProducts,
  interval = 5000,
  secondCallback,
) {
  useEffect(() => {
    const token = authScopeStringGetHelper(JWT_TOKEN);

    const accountUid = account.AccountUid;
    if (accountUid && hasProducts) {
      const worker = createWorker(`${workerName}-${accountUid}-${token}`, {
        request: {
          url:
            apiDefaultUrl +
            replaceInUrl(apiEndpoints.products.prices, {
              accountUid,
              side,
            }),
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
        actionSuccessType: PRODUCTS_PRICES_FETCH_SUCCESS,
        workerInterval: interval,
        periodicCallback: secondCallback,
      });
      const stop = worker.start();
      return () => stop();
    }
  }, [
    account.AccountUid,
    interval,
    side,
    workerName,
    hasProducts,
    secondCallback,
  ]);
}
