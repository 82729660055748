import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectIsLoadingByActionType } from '../../../../store/selectors/loadingSelectors';
import { METAL_STREAM_PRODUCT_LOADING } from '../../../../store/actions/metalStream/metalStreamActionConstants';
import config from '../../../../config';
import { selectSettings } from '../../../../store/selectors/settingsSelectors';
import ModalBody from '../../../Modal/ModalBody';
import ModalTitle from '../../../Modal/ModalTitle';
import TypeList from '../../../TypeList/TypeList';
import { getCurrentAccount } from '../../../../store/selectors/accountSelectors';
import SectionLoader from '../../../Loader/SectionLoader';
import { storeInSessionStorage } from '../../../../util/helpers/sessionStorageHelper';
import { WIZARD_DATA } from '../../../../constants/sessionStorage';
import { fetchConvertProduct } from '../../../../store/actions/products/productActions';
import { selectConvertProducts } from '../../../../store/selectors/productsSelector';
import ProductItemFractional from './ProductItemFractional';

const ConvertToForm = ({ metalType, wizardData, setWizardData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { RootUrl } = useSelector(selectSettings);
  const { AccountUid } = useSelector(getCurrentAccount);
  const convertProducts = useSelector(selectConvertProducts).sort(
    (a, b) => a.FineWeightDefault - b.FineWeightDefault,
  );
  const isLoading = useSelector(
    selectIsLoadingByActionType(METAL_STREAM_PRODUCT_LOADING),
  );

  const handleSelectProduct = (product) => {
    storeInSessionStorage(WIZARD_DATA, {
      ProductConfiguration: {
        ...wizardData?.ProductConfiguration,
        SymbolCode: product?.SymbolCode,
      },
    });
    setWizardData({
      ProductConfiguration: {
        ...wizardData?.ProductConfiguration,
        SymbolCode: product?.SymbolCode,
      },
    });
  };

  useEffect(() => {
    dispatch(
      fetchConvertProduct({
        accountUid: AccountUid,
        side: 'buy',
        metalType,
      }),
    );
  }, [AccountUid, metalType]); // eslint-disable-line

  const getSelectedProduct = () => wizardData?.ProductConfiguration?.SymbolCode;

  return (
    <SectionLoader isLoading={isLoading}>
      <ModalBody>
        <ModalTitle>{t('product.requestDelivery.convertTo')}</ModalTitle>
        <TypeList>
          {convertProducts?.map((product) => {
            const imgSrc = config.algoliaSearch.imageCode
              .replace('{product_code}', product.ProductCode)
              .replace('{base_url}', RootUrl);

            return product.Locations?.filter(
              (selectedProduct) => selectedProduct.LocationType === 2,
            ).map(({ SymbolCode }) => {
              const productName = `${product.ProductCaption}`;

              return (
                <ProductItemFractional
                  key={product?.SymbolCode}
                  imgSrc={imgSrc}
                  selected={getSelectedProduct() === SymbolCode}
                  product={{ ...product, SymbolCode }}
                  productName={productName}
                  alt={product.ProductCode}
                  name={metalType}
                  selectedProduct={getSelectedProduct() === SymbolCode}
                  handleSelectProduct={handleSelectProduct}
                />
              );
            });
          })}
        </TypeList>
      </ModalBody>
    </SectionLoader>
  );
};

ConvertToForm.propTypes = {
  metalType: PropTypes.string,
  selectedProduct: PropTypes.shape({
    ProductCode: PropTypes.string,
    ProductCaption: PropTypes.string,
    SymbolCode: PropTypes.string,
    Locations: PropTypes.arrayOf(
      PropTypes.shape({
        LocationType: PropTypes.number,
        PricePerOz: PropTypes.number,
        PricePerUnit: PropTypes.number,
      }),
    ),
  }),
  metalStreamSettings: PropTypes.shape({}),
  wizardData: PropTypes.shape({
    ProductConfiguration: PropTypes.shape({
      SymbolCode: PropTypes.string,
    }),
  }),
  setWizardData: PropTypes.func,
};

export default ConvertToForm;
