import styled from 'styled-components';
import { pxToRem } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import {
  uDanger,
  uFlex,
  uFlexColumn,
  uLabel,
  uPrimaryColor,
} from '../../assets/styles/utility';

const Paragraph = styled.p`
  font-weight: ${({ bold }) => (bold ? 600 : 'normal')};
  text-align: ${({ centerText }) => (centerText ? 'center' : 'unset')};
  font-size: ${({ fontSize }) => fontSize && pxToRem(fontSize)};
  color: ${({ color }) => color || themeColors.colorTextPrimary};
  text-decoration: ${({ textDecoration }) => textDecoration || 'initial'};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${pxToRem(marginBottom)}` : 0};
  margin-top: ${({ marginTop }) => (marginTop ? `${pxToRem(marginTop)}` : 0)};
  margin-left: ${({ marginLeft }) =>
    marginLeft ? `${pxToRem(marginLeft)}` : 0};
  ${({ isError }) => isError && uDanger};
  ${({ isColumn }) => isColumn && uFlexColumn};
  ${({ isPrimaryColor }) => isPrimaryColor && uPrimaryColor};
  ${({ labelColor }) => labelColor && uLabel};
  ${({ isFlex }) => isFlex && uFlex};
  ${({ whiteSpace }) => whiteSpace && 'white-space: nowrap'};
  ${({ disabled }) => disabled && `opacity: 0.5`};
  ${({ fullWidth }) => fullWidth && `width: 100%`};

  > strong {
    font-weight: 600;
  }

  a {
    font-weight: 600;
  }
`;

export default Paragraph;
