import { REGISTER } from '../../constants/sessionStorage';
import {
  retrieveFromSessionStorage,
  storeInSessionStorage,
} from './sessionStorageHelper';

export function setRegisterContent(key, value) {
  const data = retrieveFromSessionStorage(REGISTER);
  const newData = { ...data, [key]: value };
  storeInSessionStorage(REGISTER, newData);
}

export function getRegisterContent(key) {
  if (retrieveFromSessionStorage(REGISTER)) {
    return retrieveFromSessionStorage(REGISTER)[`${key}`];
  }
}
