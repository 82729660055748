import React from 'react';
import { useTranslation } from 'react-i18next';
import Auth from '../components/Auth/Auth';
import AuthCard from '../components/Auth/AuthCard';
import Paragraph from '../components/Paragraph/Paragraph';

const ResetSentPage = () => {
  const { t } = useTranslation();

  return (
    <Auth>
      <AuthCard title={t('resetPassword.title')}>
        <Paragraph bold isPrimaryColor>
          {t('resetPassword.resetSent.description')}
        </Paragraph>
      </AuthCard>
    </Auth>
  );
};

export default ResetSentPage;
