export const AuthenticatorApplication = 'AuthenticatorApplication';
export const Phone = 'Phone';
export const Email = 'Email';
export const CustomerService = 'CustomerService';

export const twoFaTypesObject = {
  AuthenticatorApplication: 1,
  Phone: 2,
  Email: 3,
  CustomerService: 4,
};

export const twoFaTypesEnum = Object.keys(twoFaTypesObject);

export const twoFaMessageType = ['SMS', 'VoiceMessage'];
