import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BalanceInfoCardHeader from './BalanceInfoCardHeader';
import Paragraph from '../Paragraph/Paragraph';
import { buttonizeDiv } from '../../util/buttonizeDiv';
import { variables } from '../../assets/styles/variables';
import themeColors from '../../assets/styles/themeColors';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';

const BalanceInfoCardContainer = styled.div`
  border-radius: ${variables.borderRadius.borderRadius};
  background-color: ${themeColors.colorBackgroundPrimary};
  box-shadow: ${variables.shadow.boxShadow};
  border: 1px solid ${themeColors.colorWhite};
  padding: ${pxToRem(16)};
  ${({ isActive }) => isActive && `border-color: ${themeColors.colorPrimary}`};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: ${pxToRemMd(16)} ${pxToRemMd(16)} ${pxToRemMd(12)};
  } ;
`;

const BalanceInfoCard = ({
  onClick,
  title,
  balance,
  description,
  isActive,
}) => (
  <BalanceInfoCardContainer isActive={isActive} {...buttonizeDiv(onClick)}>
    <BalanceInfoCardHeader title={title} balance={balance} />
    <Paragraph>{description}</Paragraph>
  </BalanceInfoCardContainer>
);

BalanceInfoCard.propTypes = {
  title: PropTypes.string,
  balance: PropTypes.number,
  description: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

export default BalanceInfoCard;
