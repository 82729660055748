/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { EMAIL_LOGIN_PAGE, PORTFOLIO_PAGE } from '../../constants/pages';
import Registration from '../../components/Registration/Registration';
import SidebarBackgroundImage from '../../assets/images/svg/background-1.svg';
import Logo from '../../components/Logo/Logo';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';
import { mediaBelow } from '../../assets/styles/helper';
import { ReactComponent as ArrowLeft } from '../../assets/images/svg/chevron-left.svg';
import config from '../../config';

const getSidebarBackground = () => {
  const { brokerTheme } = config;

  switch (brokerTheme) {
    case 'HardAssetsAlliance':
      return SidebarBackgroundImage;

    case 'GoldSilver':
      return `linear-gradient(180deg, ${themeColors.colorSidebarBegin} 0%, ${themeColors.colorSidebarEnd} 100%)`;
    case 'Contoso':
      return;
    case 'LegacyPM':
      return;

    default:
      return SidebarBackgroundImage;
  }
};

const RegistrationContainer = styled.div`
  flex: 1 1 auto;
`;

const RegistrationBody = styled.div`
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  height: 100%;
  min-height: calc(100vh - 60px);
  margin-bottom: -7rem;
  overflow: auto;
  z-index: 0;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    margin-bottom: -4rem;
  }

  ${mediaBelow(variables.breakpoints.bpLg)} {
    display: flex;
    flex-direction: column;
  }
`;

const RegistrationSidebar = styled.div`
  ${({ isGS }) =>
    isGS
      ? `background: ${getSidebarBackground()};`
      : `background-image: url(${getSidebarBackground()});
    `}
  background-size: cover;
  padding: 1.5rem;

  svg {
    color: ${themeColors.colorHeaderLogoLight};
    height: 1.75rem;
    object-fit: contain;
  }
`;

const RegistrationStepWrapper = styled.div`
  padding: 2rem 1.5rem 3rem;
  max-width: 568px;
  width: 100%;
`;

const RegistrationContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RegistrationTitle = styled.h2`
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.6;
  color: ${themeColors.colorTextPrimary};
  margin-bottom: 12px;
  margin-top: 0px;
  text-align: left;
`;

const RegistrationSubtitle = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: ${themeColors.colorTextPrimary};
  font-weight: 400;
  margin-bottom: 28px;
`;

const RegistrationBackButton = styled.button`
  display: flex;
  align-items: center;
  color: ${themeColors.colorTextPrimary};
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 400;
  background: none;
  cursor: pointer;
  outline: none;
  border: none;
  width: max-content;
  margin-bottom: 0.75rem;

  svg {
    height: 14px;
    margin-right: 13px;
  }
`;

const RegistrationPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { brokerTheme } = config;
  const lpmBroker = brokerTheme === 'LegacyPM';

  useEffect(() => {
    if (lpmBroker) {
      history.push(PORTFOLIO_PAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpmBroker]);

  const goStepBack = () => {
    history.push(EMAIL_LOGIN_PAGE);
  };

  return (
    <RegistrationContainer>
      <RegistrationBody>
        <RegistrationSidebar isGS={config?.brokerTheme === 'GoldSilver'}>
          <Logo />
        </RegistrationSidebar>
        <RegistrationContent>
          <RegistrationStepWrapper>
            <RegistrationBackButton onClick={goStepBack}>
              <ArrowLeft />
              {t('common.back')}
            </RegistrationBackButton>
            <RegistrationTitle>
              {t('quickRegistration.title')}
            </RegistrationTitle>
            <RegistrationSubtitle>
              {t('quickRegistration.description')}
            </RegistrationSubtitle>
            <Registration />
          </RegistrationStepWrapper>
        </RegistrationContent>
      </RegistrationBody>
    </RegistrationContainer>
  );
};

RegistrationPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
};
export default RegistrationPage;
