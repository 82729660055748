import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import isEmpty from 'lodash.isempty';
import { useDispatch, useSelector } from 'react-redux';

import SelectField from '../../InputFields/SelectField';
import TextField from '../../InputFields/TextField';

import {
  selectBankAccountDetailsByRoutingNum,
  selectNewWireAccountData,
} from '../../../store/selectors/bankAccountSelectors';
import { updateNewWireAccDetails } from '../../../store/actions/bankAccount/bankAccountActions';
import { separateByUppercase } from '../../../util/helpers/stringHelpers';
import NewWireWithdrawalHeading from './NewWireWithdrawalHeading';
import { USA_COUNTRY_LABEL } from '../../../util/constants';
import {
  getWizardContent,
  setWizardContent,
} from '../../../util/helpers/wizardHelpers';
import {
  ACCOUNT_DETAILS_INFO,
  BANK_DETAILS_BY_ROUTING_NUMBER,
  NON_US_COUNTRY_SELECT_MODAL_INFO,
} from '../../../constants/sessionStorage';
import { accountDetailsValidationSchema } from '../../../validation/accountDetailsValidationSchema';
import Label from '../../Notes/Label';
import Paragraph from '../../Paragraph/Paragraph';
import FormContainer from '../../FormContainer/FormContainer';
import ModalButtons from '../../Modal/ModalButtons';
import Note from '../../Notes/Note';
import ModalBody from '../../Modal/ModalBody';
import { bankAccountTypes } from '../../../util/enum/api/bankAccountTypes';

const AccountDetailsModal = ({ sbsBack, sbsForward }) => {
  const { t } = useTranslation();
  const [bankDetails, setBankDetails] = useState({});
  const [newAccount, setNewAccount] = useState({});
  const fetchedBankDetails = useSelector(selectBankAccountDetailsByRoutingNum);
  const newAcc = useSelector(selectNewWireAccountData);
  const dispatch = useDispatch();

  const BANK_ACC_TYPE_SELECT_OPTIONS = bankAccountTypes
    .filter((account) => account !== '')
    .map((account) => ({
      label: separateByUppercase(account),
      value: account,
    }));

  useEffect(() => {
    if (fetchedBankDetails) {
      setBankDetails(fetchedBankDetails);
      setNewAccount(newAcc.country);
    } else {
      if (getWizardContent(BANK_DETAILS_BY_ROUTING_NUMBER)) {
        setBankDetails(
          getWizardContent(BANK_DETAILS_BY_ROUTING_NUMBER).routingNumData,
        );

        setNewAccount(getWizardContent(BANK_DETAILS_BY_ROUTING_NUMBER).country);
      }

      if (getWizardContent(NON_US_COUNTRY_SELECT_MODAL_INFO)) {
        setBankDetails(getWizardContent(NON_US_COUNTRY_SELECT_MODAL_INFO));

        setNewAccount(
          getWizardContent(NON_US_COUNTRY_SELECT_MODAL_INFO).country,
        );
      }
    }
  }, [fetchedBankDetails]); // eslint-disable-line

  const formatBankDetails = (bank) =>
    `${bank.Address.AddressLine}, ${bank.Address.City}, ${bank.Address.StateRegion} ${bank.Address.PostalCode}`;

  const handleSubmit = ({
    accountHolderName,
    accountType,
    accountNumber,
    accountNumberRepeat,
    ObiMessage1,
    ObiMessage2,
    ObiMessage3,
    ObiMessage4,
  }) => {
    setWizardContent(ACCOUNT_DETAILS_INFO, {
      accountHolderName,
      accountType,
      accountNumber,
      accountNumberRepeat,
      ObiMessage1,
      ObiMessage2,
      ObiMessage3,
      ObiMessage4,
    });

    dispatch(
      updateNewWireAccDetails({
        accountHolderName,
        accountType: accountType.value,
        accountNumber,
        ObiMessage1,
        ObiMessage2,
        ObiMessage3,
        ObiMessage4,
      }),
    );
    sbsForward();
  };

  const initialValues = getWizardContent(ACCOUNT_DETAILS_INFO) || {
    accountHolderName: '',
    accountType: BANK_ACC_TYPE_SELECT_OPTIONS[0],
    accountNumber: '',
    accountNumberRepeat: '',
    ObiMessage1: '',
    ObiMessage2: '',
    ObiMessage3: '',
    ObiMessage4: '',
  };

  if (isEmpty(bankDetails) || isEmpty(newAccount)) {
    return null;
  }

  return (
    <ModalBody>
      <NewWireWithdrawalHeading accountDetailsParagraph />
      {newAccount === USA_COUNTRY_LABEL && (
        <>
          <Label
            text={t('bankAccounts.bankNameLabel')}
            marginBottom={4}
            isBold
          />

          {!isEmpty(bankDetails) && (
            <>
              <Paragraph>{bankDetails.Name}</Paragraph>
              <Paragraph marginBottom={32}>
                {formatBankDetails(bankDetails)}
              </Paragraph>
            </>
          )}
        </>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={accountDetailsValidationSchema}
        enableReinitialize
      >
        {({ submitForm }) => (
          <Form>
            <FormContainer>
              <Field
                component={TextField}
                name="accountHolderName"
                label={t('bankAccounts.accountHolderLabel')}
              />
              <SelectField
                name="accountType"
                label={t('bankAccounts.accountTypeLabel')}
                options={BANK_ACC_TYPE_SELECT_OPTIONS}
              />
              <Field
                component={TextField}
                name="accountNumber"
                label={
                  newAccount === USA_COUNTRY_LABEL
                    ? t('bankAccounts.accountNumberLabel')
                    : t('bankAccounts.accountNumberIbanLabel')
                }
              />
              <Field
                component={TextField}
                name="accountNumberRepeat"
                label={
                  newAccount === USA_COUNTRY_LABEL
                    ? t('bankAccounts.repeatAccountNumberLabel')
                    : t('bankAccounts.repeatAccountNumberIbanLabel')
                }
              />
              <Field
                component={TextField}
                name="ObiMessage1"
                label={t('bankAccounts.wireWithdrawal.obi1')}
              />
              <Field
                component={TextField}
                name="ObiMessage2"
                label={t('bankAccounts.wireWithdrawal.obi2')}
              />
              <Field
                component={TextField}
                name="ObiMessage3"
                label={t('bankAccounts.wireWithdrawal.obi3')}
              />
              <Field
                component={TextField}
                name="ObiMessage4"
                label={t('bankAccounts.wireWithdrawal.obi4')}
              />
              <Note text={t('bankAccounts.wireWithdrawal.note')} />
            </FormContainer>
            <ModalButtons
              marginTop
              isHorizontal
              secondaryButtonProps={{ onClick: sbsBack }}
              primaryButtonProps={{ onClick: submitForm }}
            />
          </Form>
        )}
      </Formik>
    </ModalBody>
  );
};

AccountDetailsModal.propTypes = {
  sbsBack: PropTypes.func,
  sbsForward: PropTypes.func,
};

export default AccountDetailsModal;
