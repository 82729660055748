import React from 'react';
import PropTypes from 'prop-types';
import startcase from 'lodash.startcase';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { ReactComponent as ChevronRight } from '../../assets/images/svg/chevron-right.svg';
import { ReactComponent as RadioOn } from '../../assets/images/svg/radio-on.svg';
import { ReactComponent as RadioOff } from '../../assets/images/svg/radio-off.svg';
import { parseEnumType } from '../../util/helpers/enumMappers';
import { productLocationTypes } from '../../util/enum/api/productTypes';
import { formatMoneyNumeral } from '../../util/helpers/numeralHelpers';
import themeColors from '../../assets/styles/themeColors';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import { uButtonClear, uOutlineNone } from '../../assets/styles/utility';

const getVaultSelectContainerStyles = (isVaultSelected) => {
  if (isVaultSelected) {
    return `
    border-color: ${themeColors.colorPrimary};
    background: ${themeColors.colorTableSelectedBackground};
    `;
  }
};

export const VaultSelectContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2px;
  padding: ${pxToRem(8)} 0;
  cursor: pointer;
  border: 1px solid transparent;
  ${({ isVaultSelected }) => getVaultSelectContainerStyles(isVaultSelected)};

  p {
    text-align: left;
  }

  svg {
    width: ${pxToRem(16)};
    margin: 0 ${pxToRem(8)};
  }

  button {
    cursor: pointer;
  }

  &:hover {
    background-color: ${themeColors.colorTableEvenRowHoverBackground};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(14)};

    svg {
      width: ${pxToRemMd(16)};
      margin: 0 ${pxToRemMd(8)};
    }
  }
`;

export const VaultSelectButton = styled.button`
  ${uButtonClear};
  ${uOutlineNone};
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-column: 1 / 3;
  grid-gap: 2px;
  align-items: center;
`;

export const VaultSelectIndicator = styled.div`
  display: flex;
  align-items: center;
`;

export const VaultSelectValue = styled.p`
  font-weight: 600;
`;

export const VaultSelectPrice = styled.p`
  font-size: ${pxToRem(14)};
  display: flex;
  align-self: center;
`;

export const VaultSelectTiers = styled.button`
  ${uButtonClear};
  ${uOutlineNone};
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(14)};
  }
`;

const VaultSelect = ({
  isVaultSelected,
  isTestProduct,
  vault,
  selectVault,
  setPriceTierLocation,
}) => {
  const { t } = useTranslation();

  if (isEmpty(vault)) {
    return null;
  }

  const { SymbolCode, LocationType, PricePerUnit } = vault;

  return (
    <>
      <VaultSelectContainer isVaultSelected={isVaultSelected} key={SymbolCode}>
        <VaultSelectButton onClick={() => selectVault(vault)}>
          <VaultSelectIndicator>
            {isVaultSelected ? <RadioOn /> : <RadioOff />}
            <VaultSelectValue>
              {isTestProduct
                ? `${startcase(
                    parseEnumType(productLocationTypes, LocationType),
                  )} (test)`
                : startcase(parseEnumType(productLocationTypes, LocationType))}
            </VaultSelectValue>
          </VaultSelectIndicator>
          <VaultSelectPrice>
            {formatMoneyNumeral(PricePerUnit)}
          </VaultSelectPrice>
        </VaultSelectButton>
        <VaultSelectTiers
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            selectVault(vault);
            setPriceTierLocation(vault);
          }}
        >
          {t('buyWizard.chooseVault.priceTiers')}
          <ChevronRight />
        </VaultSelectTiers>
      </VaultSelectContainer>
    </>
  );
};

VaultSelect.propTypes = {
  isVaultSelected: PropTypes.bool,
  isTestProduct: PropTypes.bool,
  vault: PropTypes.shape({
    SymbolCode: PropTypes.string,
    LocationType: PropTypes.number,
    PricePerUnit: PropTypes.number,
  }),
  selectVault: PropTypes.func,
  setPriceTierLocation: PropTypes.func,
};

export default VaultSelect;
