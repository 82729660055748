import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { useTranslation } from 'react-i18next';
import { uuid } from 'uuidv4';
import {
  formatMoneyNumeral,
  formatPercentageString,
} from '../../util/helpers/numeralHelpers';
import { formatAddress } from '../../util/helpers/addressHelper';
import Paragraph from '../Paragraph/Paragraph';
import InformationList from '../InformationList/InformationList';
import InformationListItem from '../InformationList/InformationListItem';
import { selectSettings } from '../../store/selectors/settingsSelectors';
import Note from '../Notes/Note';
import creditCardTypes from '../../util/enum/api/creditCardTypes';
import themeColors from '../../assets/styles/themeColors';

const PricesWrapper = styled.div``;

const OrderTotal = ({
  orderType,
  deliveryAddress,
  finalOrder,
  buyCashBuffer,
  balanceAvailableForTradingIsValid,
  paymentMethod,
  isPostPaidVisible,
}) => {
  const { t } = useTranslation();
  const settings = useSelector(selectSettings);
  const [deliveryOrderData, setDeliveryOrderData] = useState([]);
  const [deliveryOrderDataWithFees, setDeliveryOrderDataWithFees] = useState(
    [],
  );
  const [quantityDeliveryOrderData, setQuantityDeliveryOrderData] = useState(
    [],
  );

  const paymentMethodValue = () => {
    switch (paymentMethod?.PaymentMethodType) {
      case 1:
        return t('buyWizard.postPaid.paymentMethods.AccountBalance');
      case 2:
        return `${paymentMethod?.BankName}, ${paymentMethod?.BankAccountMask}`;
      case 3:
        return t('creditCards.cardTypeAndNumberLabel', {
          cardType: t(
            `creditCards.creditCardTypes.${
              creditCardTypes[paymentMethod?.CreditCardType]
            }`,
          ),
          last4Digits: paymentMethod?.Last4Digits,
        });

      default:
        return t('buyWizard.postPaid.paymentMethods.AccountBalance');
    }
  };

  const showPaymentMethodValue = paymentMethodValue();

  const strikedTotal =
    finalOrder.paymentMethodData?.DataWithoutCCFee
      ?.TotalClientExecutionPriceWithFee +
    finalOrder.paymentMethodData?.DataWithCCFee?.SalesTax +
    finalOrder.paymentMethodData?.DataWithCCFee?.DeliveryFee;
  const discountPrice =
    finalOrder.paymentMethodData?.DataWithoutCCFee?.TotalClientExecutionPrice +
    finalOrder.salesTax +
    finalOrder.deliveryFees;

  const priceWithDiscount = () => {
    if (paymentMethod?.PaymentMethodType !== 3) {
      return (
        <PricesWrapper>
          <Paragraph color={themeColors.colorSuccess} bold>
            {formatMoneyNumeral(discountPrice)}
          </Paragraph>
          <Paragraph textDecoration="line-through" marginLeft={8} bold>
            {formatMoneyNumeral(strikedTotal)}
          </Paragraph>
        </PricesWrapper>
      );
    }
    return (
      <Paragraph bold>
        {formatMoneyNumeral(finalOrder.estimatedTotal)}
      </Paragraph>
    );
  };

  useEffect(() => {
    if (
      !settings ||
      isEmpty(settings) ||
      !settings.BrokerCode ||
      isEmpty(finalOrder)
    ) {
      return;
    }
    const {
      quantity,
      unitPrice,
      subtotal,
      deliveryFees,
      salesTax,
      estimatedTotal,
      cashBuffer,
      requiredBalanceWithBuffer,
      volumeDiscount,
    } = finalOrder;
    const dataForDeliveryOrder = [
      {
        label: t(
          'buyWizard.buyForDelivery.reviewOrder.orderTotal.deliveryAddress',
        ),
        value: formatAddress(deliveryAddress),
      },
      {
        label: settings.IsLockedPricingActive
          ? t('product.detailsTable.pricePerUnit')
          : t('buyWizard.buyForDelivery.reviewOrder.orderTotal.pricePerUnit'),
        value: formatMoneyNumeral(unitPrice),
      },
      {
        label:
          orderType === 'Quantity' || settings?.IsLockedPricingActive
            ? t('buyWizard.buyForDelivery.reviewOrder.orderTotal.quantity')
            : t(
                'buyWizard.buyForDelivery.reviewOrder.orderTotal.estimatedQuantity',
              ),
        value: quantity,
      },
      settings.IsLockedPricingActive && finalOrder?.volumeDiscount > 0
        ? {
            value: `(${formatMoneyNumeral(volumeDiscount)})`,
            label: t(
              'buyWizard.buyForDelivery.reviewOrder.orderTotal.volumeDiscount',
            ),
            isBold: true,
          }
        : { value: '', label: '' },
      {
        label: settings.IsLockedPricingActive
          ? t('buyWizard.buyForDelivery.reviewOrder.orderTotal.subtotal')
          : t('buyWizard.buyForDelivery.calculations.estimatedSubTotal'),
        value: formatMoneyNumeral(subtotal),
        isBold: true,
      },
    ];
    setDeliveryOrderData(dataForDeliveryOrder);

    const dataForDeliveryWithFees = [
      {
        label: t(
          'buyWizard.buyForDelivery.reviewOrder.orderTotal.deliveryCost',
        ),
        value: formatMoneyNumeral(deliveryFees),
      },
      {
        label: settings.IsLockedPricingActive
          ? t('buyWizard.buyForDelivery.reviewOrder.orderTotal.salesTax')
          : t(
              'buyWizard.buyForDelivery.reviewOrder.orderTotal.estimatedSalesTax',
            ),
        value: formatMoneyNumeral(salesTax),
      },
      {
        label: settings.IsLockedPricingActive
          ? t('buyWizard.buyForDelivery.reviewOrder.orderTotal.total')
          : t('buyWizard.buyForDelivery.reviewOrder.orderTotal.estimatedTotal'),
        value: isPostPaidVisible
          ? priceWithDiscount()
          : formatMoneyNumeral(estimatedTotal),
        isBold: true,
        isError: orderType !== 'Quantity' && !balanceAvailableForTradingIsValid,
      },
      isPostPaidVisible
        ? {
            value: showPaymentMethodValue,
            label: t('buyWizard.verifyOrder.paymentMethod'),
          }
        : { label: '', value: '' },
    ];
    setDeliveryOrderDataWithFees(dataForDeliveryWithFees);

    const dataForQuantityOrder = [
      {
        label: t('buyWizard.buyForDelivery.reviewOrder.orderTotal.cashBuffer', {
          cashBuffer: formatPercentageString(buyCashBuffer),
        }),
        value: formatMoneyNumeral(cashBuffer),
      },
      {
        label: t(
          'buyWizard.buyForDelivery.reviewOrder.orderTotal.requiredBalanceWithBuffer',
        ),
        value: formatMoneyNumeral(requiredBalanceWithBuffer),
        isBold: true,
        isError: !balanceAvailableForTradingIsValid,
      },
    ];
    setQuantityDeliveryOrderData(dataForQuantityOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    balanceAvailableForTradingIsValid,
    buyCashBuffer,
    deliveryAddress,
    finalOrder,
    orderType,
    settings,
    t,
    isPostPaidVisible,
    showPaymentMethodValue,
  ]);

  return (
    <>
      <InformationList marginTop={8} withBackground>
        {deliveryOrderData.map(({ label, value, isBold }) => (
          <InformationListItem
            key={uuid()}
            fullWidth
            label={label}
            value={value}
            isBold={isBold}
          />
        ))}
      </InformationList>

      <InformationList withBackground>
        {deliveryOrderDataWithFees.map(({ label, value, isBold, isError }) => (
          <InformationListItem
            key={uuid()}
            fullWidth
            label={label}
            value={value}
            isBold={isBold}
            isError={isError}
          />
        ))}
      </InformationList>

      {orderType === 'Quantity' &&
        settings &&
        !isEmpty(settings) &&
        !settings?.IsLockedPricingActive && (
          <InformationList withBackground>
            {quantityDeliveryOrderData.map(
              ({ label, value, isBold, isError }) => (
                <InformationListItem
                  key={uuid()}
                  fullWidth
                  label={label}
                  value={value}
                  isBold={isBold}
                  isError={isError}
                />
              ),
            )}
          </InformationList>
        )}
      {!balanceAvailableForTradingIsValid && (
        <Paragraph isError>
          {t(
            'buyWizard.buyForDelivery.calculations.notEnoughFundsDueToPriceFluctuation',
          )}
        </Paragraph>
      )}
      {settings && !isEmpty(settings) && !settings?.IsLockedPricingActive && (
        <Paragraph marginTop={24}>
          <Note text={t('buyWizard.common.note')} />
        </Paragraph>
      )}
    </>
  );
};

OrderTotal.propTypes = {
  orderType: PropTypes.string,
  deliveryAddress: PropTypes.shape({}),
  finalOrder: PropTypes.shape({
    quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    unitPrice: PropTypes.number,
    subtotal: PropTypes.number,
    deliveryFees: PropTypes.number,
    salesTax: PropTypes.number,
    unassessedStorageFees: PropTypes.number,
    estimatedTotal: PropTypes.number,
    cashBuffer: PropTypes.number,
    requiredBalanceWithBuffer: PropTypes.number,
    volumeDiscount: PropTypes.number,
    newPricePerUnit: PropTypes.number,
    paymentTypeDiscount: PropTypes.number,
    paymentMethodData: PropTypes.shape({
      DataWithoutCCFee: PropTypes.shape({
        TotalClientExecutionPriceWithFee: PropTypes.number,
        TotalClientExecutionPrice: PropTypes.number,
      }),
      DataWithCCFee: PropTypes.shape({
        SalesTax: PropTypes.number,
        DeliveryFee: PropTypes.number,
      }),
    }),
  }),
  buyCashBuffer: PropTypes.number,
  balanceAvailableForTradingIsValid: PropTypes.bool,
  paymentMethod: PropTypes.shape({
    PaymentMethodType: PropTypes.number,
    BankName: PropTypes.string,
    BankAccountMask: PropTypes.number,
    CreditCardType: PropTypes.number,
    Last4Digits: PropTypes.number,
  }),
  isPostPaidVisible: PropTypes.bool,
};

export default React.memo(OrderTotal);
