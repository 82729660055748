import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReviewBar from '../../ReviewBar/ReviewBar';
import { sellProductFromStorage } from '../../../store/actions/sell/sellWizardActions';
import { formatMoneyNumeral } from '../../../util/helpers/numeralHelpers';
import SectionLoader from '../../Loader/SectionLoader';
import {
  getWizardContent,
  setWizardContent,
} from '../../../util/helpers/wizardHelpers';
import useGtmHook from '../../../util/hooks/useGtmHook';
import { ERROR_TEXT, HAS_ERROR } from '../../../constants/sessionStorage';
import { PURCHASE } from '../../../constants/gtmEvents';
import Note from '../../Notes/Note';
import ModalBody from '../../Modal/ModalBody';
import ModalTitle from '../../Modal/ModalTitle';
import ModalButtons from '../../Modal/ModalButtons';
import AgreementCheckbox from '../../InputFields/AgreementCheckbox';
import ReviewList from '../../ReviewList/ReviewList';
import ThinMarketWarning from '../../ThinMarketWarning/ThinMarketWarning';
import { verifySellGtmHelper } from '../../../util/helpers/gtmHelper';
import CountdownTimer from '../../Common/Timers/CountdownTimer';
import {
  counterValueToTimestamp,
  handleExpiration,
} from '../../../util/helpers/timerHelpers';
import useStartTimer from '../../../util/hooks/useStartTimer';
import {
  emptyQuotesOrderStorageBuy,
  executeQuotesForOrderSell,
  getQuotesForOrderSell,
} from '../../../store/actions/orders/orderActions';
import Paragraph from '../../Paragraph/Paragraph';
import { IdleTimeContext } from '../../../providers/IdleTimeContextProvider';
import {
  COUNTDOWN_TIMER_SEC,
  IDLE_TIMEOUT_THRESHOLD,
} from '../../../constants/timerConstants';
import SellCalculation from './SellCalculation';
import ErrorModal from '../../Common/ErrorModal';
import { selectSettings } from '../../../store/selectors/settingsSelectors';

const SellConfirm = ({
  handleBack,
  handleNext,
  handleRefreshTransactions,
  wizardData,
  setWizardData,
  product,
  unitPrice,
  setPricePerUnit,
  vault,
  tiers,
  reviewProduct,
  setHasError,
  setErrorText,
  account,
  isFractionalProduct,
  isInstaVaultProduct,
  isLockedPrice,
  isProductPage,
  counter,
  unity,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { ecommerceGtmEvent } = useGtmHook();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const isSegregated = product?.IsSegregated;
  const [reviewData, setReviewData] = useState([]);
  const settings = useSelector(selectSettings);

  const {
    orderType,
    quoteID,
    calculation: {
      volumeDiscount,
      newPricePerUnit,
      estimatedOrderTotal,
      quantity,
    },
    expiryTime,
  } = wizardData;

  const [quoteUid, setQuoteUid] = useState(quoteID || '');
  const [quantityExecuted, setQuantity] = useState(quantity || 0);
  const [clientExecutionPrice, setClientExecutionPrice] = useState(
    estimatedOrderTotal || 0,
  );
  const [volDiscount, setVolumeDiscount] = useState(volumeDiscount || 0);
  const [expirationMs, setExpirationMs] = useState(0);
  const [counterValue, setCounter] = useState(COUNTDOWN_TIMER_SEC);
  const [unityValue, setUnity] = useState(false);
  const [isLoadingGetQuotes, setIsLoadingGetQuotes] = useState(false);
  const [isLoadingExecuteQuotes, setIsLoadingExecuteQuotes] = useState(false);
  const [getQuotesError, setGetQuotesError] = useState('');
  const [executeQuotesError, setExecuteQuotesError] = useState('');
  const getRemainingTime = useContext(IdleTimeContext);
  const [expiryTimeStored, setExpiryTimeStored] = useState(false);
  const [nonFractionalPricePerUnit, setNonFractionalPricePerUnit] = useState(
    newPricePerUnit,
  );
  const segregatedCaption = settings?.SegregatedCaption;

  useEffect(() => {
    if (!expirationMs) {
      if (expiryTime) {
        handleExpiration(expiryTime, setExpirationMs);
      }
    }
  }, [expirationMs, expiryTime]);

  const onHandleGetQuotesSuccess = (getQuoteResult) => {
    const {
      QuoteUid,
      ExpiresOnUtc,
      QuoteStatusItem: {
        QuantityExecuted,
        ClientPricePerUnit,
        TotalClientExecutionPrice,
        VolumeDiscount,
      },
    } = getQuoteResult;

    const calculation = {
      estimatedOrderTotal: TotalClientExecutionPrice,
      newPricePerUnit: ClientPricePerUnit,
      volumeDiscount: VolumeDiscount,
      quantity: QuantityExecuted,
    };
    setQuoteUid(QuoteUid);
    setNonFractionalPricePerUnit(ClientPricePerUnit);
    setQuantity(QuantityExecuted);
    setClientExecutionPrice(TotalClientExecutionPrice);
    setVolumeDiscount(VolumeDiscount);
    handleExpiration(ExpiresOnUtc, setExpirationMs);

    setWizardData({
      ...wizardData,
      calculation,
      quoteID: QuoteUid,
      expiryTime: ExpiresOnUtc,
    });
  };

  const onHandleExecuteQuotesSuccess = () => {
    handleNext();
  };

  const counterUpdater = useCallback((epoch) => {
    if (epoch?.data) {
      const [counter, unity] = epoch.data;
      setCounter((prevCounter) => prevCounter - prevCounter + counter);
      setUnity(unity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchQuotes = (symbolCode) => {
    const payload = {
      getQuotesPayload: {
        accountUid: account.AccountUid,
        symbolCode,
        quantityOrAmount: quantityExecuted,
        sideType: 'Sell',
        unitType: wizardData?.orderType,
        isSegregated,
      },
      isLoading: setIsLoadingGetQuotes,
      onSuccess: onHandleGetQuotesSuccess,
      onError: onHandleGetQuotesError,
    };
    setIsLoadingGetQuotes(true);
    dispatch(
      getQuotesForOrderSell({
        ...payload,
      }),
    );
  };

  useEffect(() => {
    if (isFractionalProduct && !isInstaVaultProduct) {
      return;
    }

    const { SymbolCode } = vault;
    if (counterValue === 2 && SymbolCode) {
      fetchQuotes(SymbolCode);
      setCounter(COUNTDOWN_TIMER_SEC);
    }

    return () => dispatch(emptyQuotesOrderStorageBuy());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counterValue, account.AccountUid, dispatch, orderType, quantityExecuted]);

  const handleError = (value) => {
    setWizardContent(HAS_ERROR, value);
    setHasError(value);

    if (getWizardContent(HAS_ERROR)) {
      const text = isFractionalProduct
        ? t('apiErrors.Order_FractionalOrder_PriceIsNotAvailable')
        : t('sellWizard.orderCouldNotBePlaced');
      setWizardContent(ERROR_TEXT, text);
      setErrorText(text);
    } else {
      const text = t('sellWizard.orderPlaced');
      setWizardContent(ERROR_TEXT, text);
      setErrorText(text);
    }
  };

  const onRequestSuccess = () => {
    const handleRefreshTransactionAvailable = !!handleRefreshTransactions;

    if (handleRefreshTransactionAvailable) {
      handleRefreshTransactions();
    }
  };

  const handleGtm = () => {
    verifySellGtmHelper({
      ecommerceGtmEvent,
      eventName: PURCHASE,
      reviewProduct,
      product,
      vault,
      account,
      orderSideType: 'sell',
    });
  };

  const onHandleGetQuotesError = (errorMessage) => {
    setGetQuotesError(errorMessage);
  };

  const onHandleExecuteQuotesError = (errorMessage) => {
    setExecuteQuotesError(errorMessage);
  };

  const handleConfirm = () => {
    if (!isFractionalProduct || isInstaVaultProduct) {
      const payload = {
        executeQuotePayload: {
          quoteUid: quoteUid || quoteID,
        },
        isLoading: setIsLoadingExecuteQuotes,
        onSuccess: onHandleExecuteQuotesSuccess,
        onConfirm: onRequestSuccess,
        onError: onHandleExecuteQuotesError,
        handleGtm,
      };
      setIsLoadingExecuteQuotes(true);
      dispatch(
        executeQuotesForOrderSell({
          ...payload,
        }),
      );
    } else {
      dispatch(
        sellProductFromStorage({
          reviewProduct,
          isSegregated,
          setIsLoading,
          handleNext,
          handleError,
          handleGtm,
          onRequestSuccess,
        }),
      );
    }
  };

  const closeErrorModal = () => {
    if (getQuotesError) {
      setGetQuotesError('');
      handleBack();
    }
    setExecuteQuotesError('');
    fetchQuotes(vault?.SymbolCode);
  };

  useEffect(() => {
    const remainingIdleTime = getRemainingTime();
    if (remainingIdleTime < IDLE_TIMEOUT_THRESHOLD && !expiryTimeStored) {
      const expirationTimeStamp = counterValueToTimestamp(counterValue);
      setExpiryTimeStored(true);
      setWizardData({
        ...wizardData,
        expiryTime: expirationTimeStamp,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counterValue, getRemainingTime]);

  const handleCheckboxCheck = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  useEffect(() => {
    const dataForReview = [
      {
        label:
          isInstaVaultProduct || (isLockedPrice && !isFractionalProduct)
            ? t('sellWizard.pricePerUnit')
            : t('sellWizard.estimatedPricePerUnit'),
        value: formatMoneyNumeral(
          isInstaVaultProduct || (isLockedPrice && !isFractionalProduct)
            ? newPricePerUnit
            : nonFractionalPricePerUnit,
        ),
      },
      {
        label: t('sellWizard.quantityToSell'),
        value: quantityExecuted,
      },
      volDiscount < 0
        ? {
            label: t('sellWizard.volumeCredit'),
            value: formatMoneyNumeral(volDiscount)
              .replace('(', '')
              .replace(')', ''),
          }
        : {
            label: '',
            value: '',
          },
      {
        label:
          isInstaVaultProduct || (isLockedPrice && !isFractionalProduct)
            ? t('sellWizard.metalsTotal')
            : t('sellWizard.estimatedMetalsTotal'),
        value: formatMoneyNumeral(clientExecutionPrice),
      },
    ];
    setReviewData(dataForReview);
  }, [
    clientExecutionPrice,
    isLockedPrice,
    quantityExecuted,
    volDiscount,
    t,
    isFractionalProduct,
    isInstaVaultProduct,
    newPricePerUnit,
    tiers,
    nonFractionalPricePerUnit,
  ]);

  useStartTimer(
    expirationMs,
    counterUpdater,
    (isLoadingGetQuotes || isLoadingExecuteQuotes) && !isFractionalProduct,
  );

  if (getQuotesError || executeQuotesError) {
    return (
      <ErrorModal
        title={t('sellWizard.sellWizardTitle')}
        subtitle={t('apiErrors.IssueWithYourOrder')}
        message={getQuotesError || executeQuotesError}
        btnTitle={
          getQuotesError
            ? t('product.chooseAnAmount')
            : t('product.reviewYourOrder')
        }
        isOpen={getQuotesError || executeQuotesError}
        close={closeErrorModal}
        goBack={closeErrorModal}
      />
    );
  }

  return (
    <SectionLoader
      isLoading={isLoading || isLoadingGetQuotes || isLoadingExecuteQuotes}
    >
      <ReviewBar title={t('sellWizard.reviewSale')} />
      <ModalBody>
        <ModalTitle>
          {isSegregated
            ? `${product.ProductCaption} (${segregatedCaption})`
            : `${product.ProductCaption}`}
        </ModalTitle>
        <ReviewList data={reviewData} />
        <AgreementCheckbox
          checked={isCheckboxChecked}
          onChange={handleCheckboxCheck}
          text={t('sellWizard.acknowledgeFinality')}
          marginBottom={16}
          marginTop={16}
          data-cy="checkbox-acknowledge-sales-final"
        />
        {product.HasThinMarket && <ThinMarketWarning />}
        {isFractionalProduct && !isInstaVaultProduct && (
          <SellCalculation
            unitPrice={unitPrice}
            quantity={reviewProduct?.Quantity}
            wizardData={wizardData}
            setWizardData={setWizardData}
            tiers={tiers}
            barsArray={product?.Bars}
            isLockedPriceUser={isLockedPrice}
            setPricePerUnit={setPricePerUnit}
            isProductPage={isProductPage}
            isReviewModal
            isFractionalProduct={isFractionalProduct}
          />
        )}
        {getQuotesError ||
          (executeQuotesError && (
            <Paragraph isError fontSize={14} marginTop={12}>
              {getQuotesError ?? executeQuotesError}
            </Paragraph>
          ))}
        {!isLockedPrice ||
          (isFractionalProduct && !isInstaVaultProduct && (
            <Note text={t('product.priceEstimationNote')} />
          ))}
        <CountdownTimer
          counter={
            isFractionalProduct && !isInstaVaultProduct ? counter : counterValue
          }
          unity={
            isFractionalProduct && !isInstaVaultProduct ? unity : unityValue
          }
          loading={isLoadingExecuteQuotes || isLoadingGetQuotes}
        />
        <ModalButtons
          isHorizontal
          marginTop
          secondaryButtonProps={{
            onClick: handleBack,
            'data-cy': 'button-back',
          }}
          primaryButtonProps={{
            disabled: !isCheckboxChecked,
            onClick: handleConfirm,
            label: t('common.confirm'),
            'data-cy': 'button-confirm',
          }}
        />
      </ModalBody>
    </SectionLoader>
  );
};

SellConfirm.propTypes = {
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  handleRefreshTransactions: PropTypes.func,
  wizardData: PropTypes.shape({
    calculation: PropTypes.shape({
      estimatedOrderTotal: PropTypes.number,
      newPricePerUnit: PropTypes.number,
      volumeDiscount: PropTypes.number,
      quantity: PropTypes.number,
    }),
    orderValues: PropTypes.shape({
      QuantityAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      CashAmount: PropTypes.number,
    }),
    orderType: PropTypes.string,
    ExpiresOnUtc: PropTypes.string,
    selectedVault: PropTypes.shape({
      LocationType: PropTypes.number,
      SymbolCode: PropTypes.string,
      IsCashOrderOnly: PropTypes.bool,
    }),
    storageProduct: PropTypes.shape({
      Locations: PropTypes.arrayOf(PropTypes.shape({})),
      Caption: PropTypes.string,
      ProductCaption: PropTypes.string,
    }),
    quoteID: PropTypes.string,
    expiryTime: PropTypes.string,
  }),
  setWizardData: PropTypes.func,
  product: PropTypes.shape({
    ProductCaption: PropTypes.string,
    SymbolCode: PropTypes.string,
    HasThinMarket: PropTypes.bool,
    IsSegregated: PropTypes.bool,
    Locations: PropTypes.arrayOf(
      PropTypes.shape({
        PricePerUnit: PropTypes.number,
        SymbolCode: PropTypes.string,
      }),
    ),
    Bars: PropTypes.arrayOf(
      PropTypes.shape({
        BrandCode: PropTypes.string,
        SerialNumber: PropTypes.string,
        FineWeight: PropTypes.number,
        SequenceNumber: PropTypes.number,
      }),
    ),
  }),
  unitPrice: PropTypes.number,
  setPricePerUnit: PropTypes.func,
  vault: PropTypes.shape({
    SymbolCode: PropTypes.string,
    PricePerUnit: PropTypes.number,
  }),
  tiers: PropTypes.arrayOf(
    PropTypes.shape({
      HighBound: PropTypes.number,
      LowBound: PropTypes.number,
      PricePerOz: PropTypes.number,
      PricePerUnit: PropTypes.number,
    }),
  ),
  isFractionalProduct: PropTypes.bool,
  isInstaVaultProduct: PropTypes.bool,
  reviewProduct: PropTypes.shape({
    Quantity: PropTypes.number,
    SymbolCode: PropTypes.string,
    QuantityAmount: PropTypes.number,
    Type: PropTypes.string,
    UnitType: PropTypes.string,
  }),
  setHasError: PropTypes.func,
  setErrorText: PropTypes.func,
  account: PropTypes.shape({
    AccountType: PropTypes.number,
    AccountUid: PropTypes.string,
  }),
  isProductPage: PropTypes.bool,
  isLockedPrice: PropTypes.bool,
  counter: PropTypes.number,
  unity: PropTypes.bool,
};

export default SellConfirm;
