import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import Modal from '../../Modal/Modal';
import { fetchAccountIraSettings } from '../../../store/actions/iraSettings/iraSettingsActions';
import {
  getIraSettings,
  getIraSettingsError,
} from '../../../store/selectors/iraSettingsSelectors';
import CheckInformation from '../IRA/IraFund/CheckInformation';
import { selectIsLoadingByActionType } from '../../../store/selectors/loadingSelectors';
import { FETCH_ACCOUNT_IRA_SETTINGS_LOADER } from '../../../store/actions/iraSettings/iraSettingsActionConstants';
import { getCurrentAccountUid } from '../../../store/selectors/accountSelectors';
import useCheckPermissions from '../../../util/hooks/useCheckPermissions';
import InformationList from '../../InformationList/InformationList';
import InformationListItem from '../../InformationList/InformationListItem';
import Anchor from '../../Anchor/Anchor';
import ModalBody from '../../Modal/ModalBody';
import ModalTitle from '../../Modal/ModalTitle';
import ModalImage from '../../Modal/ModalImage';
import Paragraph from '../../Paragraph/Paragraph';
import ModalButtons from '../../Modal/ModalButtons';
import config from '../../../config';
import { IRA_EQUITY_TYPE } from '../../../util/constants';
import AnchorOnClick from '../../Anchor/AnchorOnClick';
import { useSessionStorageState } from '../../../util/hooks/useSessionStorageState';
import { SUPPORT_MODAL_OPEN } from '../../../constants/sessionStorage';
import {
  deleteWizardKey,
  setWizardContent,
} from '../../../util/helpers/wizardHelpers';
import SupportModal from '../SupportModal/SupportModal';

const PrefundedDepositWizard = ({ isModalOpen, handleClose, type }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const accountUid = useSelector(getCurrentAccountUid);
  const iraSettings = useSelector(getIraSettings);
  const iraSettingsError = useSelector(getIraSettingsError);
  const isLoading = useSelector(
    selectIsLoadingByActionType(FETCH_ACCOUNT_IRA_SETTINGS_LOADER),
  );
  const { brokerTheme } = config;
  const isLPM = brokerTheme === 'LegacyPM';
  const isEquity = type === IRA_EQUITY_TYPE;

  const { displayStandardOptionsPermission } = useCheckPermissions();
  const [isSupportModalOpen, setIsSupportModalOpen] = useSessionStorageState(
    SUPPORT_MODAL_OPEN,
    false,
  );

  useEffect(() => {
    if (isModalOpen && !displayStandardOptionsPermission && accountUid) {
      dispatch(
        fetchAccountIraSettings({
          accountUid,
        }),
      );
    }
  }, [dispatch, accountUid, displayStandardOptionsPermission, isModalOpen]);

  const iraDetails = useMemo(() => {
    if (isEmpty(iraSettings) || !!iraSettingsError) {
      return {};
    }
    if (isEmpty(iraSettings.IRATrusteeDetails)) return {};
    return iraSettings.IRATrusteeDetails;
  }, [iraSettings, iraSettingsError]);

  const openSupportModal = () => {
    setIsSupportModalOpen(true);
    setWizardContent(SUPPORT_MODAL_OPEN, true);
  };

  const closeSupportModal = () => {
    setIsSupportModalOpen(false);
    deleteWizardKey(SUPPORT_MODAL_OPEN);
  };

  return (
    <>
      {isSupportModalOpen ? (
        <SupportModal
          avoidClearingStorage
          isOpen={isSupportModalOpen}
          close={closeSupportModal}
          goBack={closeSupportModal}
        />
      ) : (
        <Modal
          title={t('depositWizard.depositFunds')}
          close={handleClose}
          size="lg"
          isOpen={isModalOpen}
          isLoading={isLoading}
        >
          <ModalBody>
            <ModalTitle>{t('depositWizard.equity.title')}</ModalTitle>
            <Paragraph marginBottom={24}>
              {t('depositWizard.equity.contributionTextOne')}
            </Paragraph>
            <Paragraph marginBottom={24}>
              {t('depositWizard.equity.contributionTextTwo', {
                type,
              })}
            </Paragraph>

            <Paragraph marginBottom={24}>
              {t('depositWizard.equity.contributionTextThree')}
            </Paragraph>
            <CheckInformation
              isPrefunded
              checkDetails={
                isEmpty(iraSettings)
                  ? {}
                  : iraSettings.PrefundedCheckDepositInstructions
              }
            />
            {!isEquity && iraDetails?.LogoImageUrl && (
              <ModalImage src={iraDetails.LogoImageUrl} alt="IRA Trustee" />
            )}

            {!isEquity && (
              <Paragraph bold marginBottom={4}>
                {t('depositWizard.equity.haveQuestionsWithoutContact')}
              </Paragraph>
            )}
            {!isLPM && (
              <Paragraph marginBottom={24}>
                {t('depositWizard.equity.contactInfo', {
                  type,
                })}
              </Paragraph>
            )}
            {isEquity ? (
              <Paragraph marginBottom={24}>
                <Trans i18nKey="depositWizard.equity.haveQuestions">
                  {t('depositWizard.iraTransfer.haveQuestions')}
                  <AnchorOnClick
                    onClick={(event) => {
                      event.preventDefault();
                      openSupportModal();
                    }}
                  >
                    Click here
                  </AnchorOnClick>
                  to contact us.
                </Trans>
              </Paragraph>
            ) : (
              <>
                {iraDetails && (
                  <InformationList withBackground isSmall>
                    <InformationListItem
                      label={t('depositWizard.equity.phone')}
                      value={
                        <Anchor type="telephone" value={iraDetails.Phone} />
                      }
                    />

                    <InformationListItem
                      label={t('depositWizard.equity.fax')}
                      value={<Anchor type="telephone" value={iraDetails.Fax} />}
                    />

                    <InformationListItem
                      label={t('depositWizard.equity.email')}
                      value={<Anchor type="email" value={iraDetails.Email} />}
                    />
                  </InformationList>
                )}
              </>
            )}
            <ModalButtons
              showOnlyPrimary
              marginTop
              primaryButtonProps={{
                onClick: handleClose,
                label: t('common.ok'),
              }}
              smallButtons
              buttonOnRight
            />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

PrefundedDepositWizard.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  type: PropTypes.string,
};

export default PrefundedDepositWizard;
