import createReducer from '../../utils/createReducer';
import {
  SETTINGS_ERROR,
  SETTINGS_SUCCESS,
  SETTINGS_REGISTRATIONS_ERROR,
  SETTINGS_REGISTRATION_SUCCESS,
} from '../../actions/settings/settingsActionConstants';

const initialState = {
  BrokerName: '',
  RootUrl: '',
  BuyCashBufferPercentage: 0,
  WireWithdrawalFee: 0,
  AchDepositLimit: 0,
  MetaltreamMinimumDepositAmount: 0,
  SupportSendQuickMessageUrl: '',
  SupportEmail: '',
  SupportPhone: '',
  SupportPhoneTollFree: '',
  SupportPhoneInternational: '',
  SupportWorkingHours: '',
  PricingRefreshFrequencyMilliseconds: 0,
  FractionalQuantityPrecision: 0,
  FrequentlyAskedQuestionsUrl: '',
  TermsAndConditionsUrl: '',
  PrivacyPolicyDocumentUrl: '',
  NumberOfAllowedAchDepositAccounts: 0,
  TrackingNumberCourierUrlFormats: {},
  RegistrationUrl: '',
  errorMessage: '',
};

export default createReducer(
  {
    [SETTINGS_SUCCESS]: setSettings,
    [SETTINGS_ERROR]: setSettingsError,
    [SETTINGS_REGISTRATION_SUCCESS]: setSettingsRegistration,
    [SETTINGS_REGISTRATIONS_ERROR]: setSettingsRegistrationError,
  },
  initialState,
);

function setSettings(state, action) {
  return action.payload || initialState;
}

function setSettingsError(state, action) {
  return {
    ...state,
    ...action.errorMessage,
  };
}

function setSettingsRegistration(state, action) {
  return action.payload.ResponseObject || initialState;
}

function setSettingsRegistrationError(state, action) {
  return {
    ...state,
    ...action.errorMessage,
  };
}
