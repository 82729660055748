import i18next from 'i18next';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
  trackingNumberCourierType,
  transactionStatusTypesArray,
} from '../../enum/api/transactionTypes';
import { formatDate, formatDateTime } from '../dateHelpers';
import { parseEnumType } from '../enumMappers';
import { formatByTransactionSummary } from './transactionHelper';
import Status from '../../../components/Portfolio/RecentTransactions/Status';
import { formatAbsolutMoneyNumeral } from '../numeralHelpers';
import { formatAddressString } from '../addressHelper';

const transactionSummaryHelper = (field) => {
  const fieldCaption = i18next.t(
    `portfolio.transactions.captionTypes.${field.CaptionType}`,
  );

  const fieldValue = formatByTransactionSummary(field);

  return { fieldCaption, fieldValue };
};

const transactionStatusHelper = (field) => {
  const fieldCaption = i18next.t(
    `portfolio.transactions.captionTypes.${field.CaptionType}`,
  );

  const fieldValue = (
    <Status
      status={parseEnumType(
        transactionStatusTypesArray,
        field.TransactionStatusType,
      )}
    />
  );

  return { fieldCaption, fieldValue };
};

const transactionDateTimeHelper = (field) => {
  const fieldCaption = i18next.t(
    `portfolio.transactions.captionTypes.${field.CaptionType}`,
  );
  const fieldValue = formatDateTime(field.DateTime);

  return { fieldCaption, fieldValue };
};

const transactionDateRangeHelper = (field) => {
  const fieldCaption = i18next.t(
    `portfolio.transactions.captionTypes.${field.CaptionType}`,
  );

  const formatedStartDate = formatDate(field.StartDate);
  const formatedEndDate = formatDate(field.EndDate);

  const fieldValue = `${formatedStartDate} ${i18next.t(
    'common.to',
  )} ${formatedEndDate}`;

  return { fieldCaption, fieldValue };
};

const transactionStringHelper = (field) => {
  const fieldCaption = i18next.t(
    `portfolio.transactions.captionTypes.${field.CaptionType}`,
  );
  const fieldValue = field.Caption;

  return { fieldCaption, fieldValue };
};

const transactionCurrencyHelper = (field) => {
  const fieldCaption = i18next.t(
    `portfolio.transactions.captionTypes.${field.CaptionType}`,
  );
  const fieldValue = field.Amount ? (
    formatAbsolutMoneyNumeral(field.Amount)
  ) : (
    <span>&#8722;</span>
  );
  return { fieldCaption, fieldValue };
};

const transactionMemoHelper = (field) => {
  const fieldCaption = i18next.t(
    `portfolio.transactions.captionTypes.${field.CaptionType}`,
  );
  const fieldValue = <>{ReactHtmlParser(field.Memo)}</>;

  return { fieldCaption, fieldValue };
};

const transactionAddressHelper = (field) => {
  if (field && field.Address) {
    const fieldCaption = i18next.t(
      `portfolio.transactions.captionTypes.${field.CaptionType}`,
    );

    const { Address } = field;

    const fieldValue = formatAddressString(Address);

    return { fieldCaption, fieldValue };
  }
};

const transactionDecimalHelper = (field) => {
  const fieldCaption = i18next.t(
    `portfolio.transactions.captionTypes.${field.CaptionType}`,
  );

  if (!field.Quantity) {
    const fieldValue = <span>&#8722;</span>;
    return { fieldCaption, fieldValue };
  }

  const decimalAmount =
    field.Quantity > 1
      ? `${parseFloat(field.Quantity).toFixed(field.Precision)} ${i18next.t(
          `portfolio.transactions.details.pluralUnit`,
        )}`
      : `${parseFloat(field.Quantity).toFixed(field.Precision)} ${i18next.t(
          `portfolio.transactions.details.singleUnit`,
        )}`;

  const fieldValue = decimalAmount;

  return { fieldCaption, fieldValue };
};

const transactionDateHelper = (field) => {
  const fieldCaption = i18next.t(
    `portfolio.transactions.captionTypes.${field.CaptionType}`,
  );
  const fieldValue = formatDate(field.Date);

  return { fieldCaption, fieldValue };
};

const transactionTrackingNumberHelper = (field, brokerSettings) => {
  const fieldCaption = i18next.t(
    `portfolio.transactions.captionTypes.${field.CaptionType}`,
  );

  const url = `${
    brokerSettings.TrackingNumberCourierUrlFormats[
      `${parseEnumType(
        trackingNumberCourierType,
        field?.TrackingNumbers[0]?.CourierType,
      )}`
    ]
  }`;

  const link = url.replace(
    '{TRACKINGNUMBER}',
    field?.TrackingNumbers[0]?.TrackingNumbers,
  );

  const fieldValue = (
    <a href={link} rel="noopener noreferrer" target="_blank">
      {field?.TrackingNumbers[0]?.TrackingNumbers}
    </a>
  );

  return { fieldCaption, fieldValue };
};

export function transactionDetailsHelper(field, brokerSettings) {
  const formatType = field.FormatType;

  switch (formatType) {
    case 'TransactionSummary':
      return transactionSummaryHelper(field);

    case 'TransactionStatus':
      return transactionStatusHelper(field);

    case 'Date':
      return transactionDateHelper(field);

    case 'DateTime':
      return transactionDateTimeHelper(field);

    case 'DateRange':
      return transactionDateRangeHelper(field);

    case 'Currency':
      return transactionCurrencyHelper(field);

    case 'String':
      return transactionStringHelper(field);

    case 'Memo':
      return transactionMemoHelper(field);

    case 'Address':
      return transactionAddressHelper(field);

    case 'Decimal':
      return transactionDecimalHelper(field);

    case 'TrackingNumbers':
      return transactionTrackingNumberHelper(field, brokerSettings);

    default:
      break;
  }
}
