import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import Modal from '../../Modal/Modal';
import { refreshUserToken } from '../../../store/actions/login/loginActions';
import ModalBody from '../../Modal/ModalBody';
import Paragraph from '../../Paragraph/Paragraph';
import ModalButtons from '../../Modal/ModalButtons';

const RefreshModal = ({ isOpen, close }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleConfirm = () => {
    close(false);
    dispatch(refreshUserToken());
  };

  return (
    <>
      <Modal title={t('refresh.title')} isOpen={isOpen} size="sm">
        <ModalBody>
          <Paragraph>{t('refresh.cta')}</Paragraph>
          <ModalButtons
            isVertical
            marginTop
            showOnlyPrimary
            primaryButtonProps={{
              label: t('common.confirm'),
              onClick: handleConfirm,
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

RefreshModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
};

export default RefreshModal;
