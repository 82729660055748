import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { uuid } from 'uuidv4';
import { useSelector } from 'react-redux';
import { useBrokerCode } from '../../../../../util/hooks/useBrokerCode';
import ModalButtons from '../../../../Modal/ModalButtons';
import BulletItem from '../../../../BulletItem/BulletItem';
import BulletList from '../../../../BulletList/BulletList';
import InformationList from '../../../../InformationList/InformationList';
import InformationListItem from '../../../../InformationList/InformationListItem';
import { ModalTitle } from '../../../../Modal/Modal';
import AnchorOnClick from '../../../../Anchor/AnchorOnClick';
import Paragraph from '../../../../Paragraph/Paragraph';
import ModalBody from '../../../../Modal/ModalBody';
import { selectCheckDepositInstructions } from '../../../../../store/selectors/accountSelectors';
import Support from '../../../../Support/Support';

const CheckStandardDepositWizard = ({
  handleClose,
  goToInitialModal,
  setModalSize,
  setWizardTitle,
  setWizardBack,
}) => {
  const { t } = useTranslation();
  const brokerCode = useBrokerCode();
  const checkDeposit = useSelector(selectCheckDepositInstructions);
  const [isSupportOpen, setIsSupportOpen] = useState(false);
  const payableToLiteral = () => checkDeposit?.PayableTo;

  useEffect(() => {
    if (isSupportOpen) {
      setWizardTitle(t('support.contactSupport'));
    } else {
      setWizardTitle(t('standardDepositWizard.checkDeposit.checkDepositTitle'));
    }
    setModalSize('lg');
  }, [setModalSize, setWizardTitle, isSupportOpen, t]);

  useEffect(() => {
    if (isSupportOpen) {
      setWizardBack(() => () => setIsSupportOpen(false));
      return;
    }

    setWizardBack(null);
  }, [isSupportOpen, setWizardBack]);

  const formatAddress = (value) => {
    if (!value) {
      return null;
    }

    const {
      Receipient,
      AddressLine,
      AddressLine2,
      City,
      StateRegion,
      PostalCode,
    } = value;
    return (
      <>
        <span>{Receipient} </span>
        <br />
        <span>{AddressLine}</span>
        {!!AddressLine2 && <br />}
        <span>{AddressLine2}</span>
        <br />
        <span>
          {City}, {StateRegion} {PostalCode}
        </span>
      </>
    );
  };

  const fundingCheckMailingAddressLiteral = () => {
    const { Addresses } = checkDeposit;

    if (!Addresses) {
      return null;
    }

    const { FundingCheckMailingAddress } = Addresses;

    if (!FundingCheckMailingAddress) {
      return null;
    }

    return formatAddress(FundingCheckMailingAddress);
  };

  const fundingCheckMailingAddressThirdPartyShipperLiteral = () => {
    const { Addresses } = checkDeposit;

    if (!Addresses) {
      return null;
    }

    const { FundingCheckMailingAddressThirdPartyShipper } = Addresses;

    if (!FundingCheckMailingAddressThirdPartyShipper) {
      return null;
    }

    return formatAddress(FundingCheckMailingAddressThirdPartyShipper);
  };

  const listData = [
    {
      label: t('standardDepositWizard.checkDeposit.checkDepositList.payableTo'),
      value: payableToLiteral(),
    },
    {
      label: t(
        'standardDepositWizard.checkDeposit.checkDepositList.fundingCheckMailingAddress',
      ),
      value: fundingCheckMailingAddressLiteral(),
    },
    {
      label: t(
        'standardDepositWizard.checkDeposit.checkDepositList.fundingCheckMailingAddressThirdPartySHipper',
      ),
      value: fundingCheckMailingAddressThirdPartyShipperLiteral(),
    },
    {
      label: t('standardDepositWizard.checkDeposit.checkDepositList.memo'),
      value: checkDeposit?.Memo,
    },
  ];

  if (isSupportOpen) {
    return (
      <ModalBody>
        <Support />
      </ModalBody>
    );
  }

  return (
    <ModalBody>
      <ModalTitle>
        {t('standardDepositWizard.checkDeposit.checkDepositTitle')}
      </ModalTitle>
      <Paragraph marginBottom={24}>
        {t('standardDepositWizard.checkDeposit.toMakeDeposit')}
      </Paragraph>
      <Paragraph marginBottom={24}>
        {t('standardDepositWizard.checkDeposit.addBrokerAccountNumber', {
          brokerCode,
        })}
      </Paragraph>
      <Paragraph marginBottom={24}>
        <Trans i18nKey="standardDepositWizard.checkDeposit.haveQuestions">
          If you have any questions about funding your account,
          <AnchorOnClick onClick={() => setIsSupportOpen(true)}>
            please contact customer service.
          </AnchorOnClick>
        </Trans>
      </Paragraph>

      <ModalTitle>
        {t('standardDepositWizard.checkDeposit.fundingYourAccount')}
      </ModalTitle>

      <InformationList withBackground>
        {listData.map(({ label, value }) => (
          <InformationListItem key={uuid()} label={label} value={value} />
        ))}
      </InformationList>

      <BulletList>
        <BulletItem
          text={t('standardDepositWizard.checkDeposit.againstFraud')}
        />
        <BulletItem
          text={t('standardDepositWizard.checkDeposit.whenDepositReceived')}
        />
      </BulletList>
      <ModalButtons
        marginTop
        secondaryButtonProps={{
          onClick: goToInitialModal,
          label: t('common.back'),
        }}
        primaryButtonProps={{ onClick: handleClose, label: t('common.ok') }}
        smallButtons
      />
    </ModalBody>
  );
};

CheckStandardDepositWizard.propTypes = {
  handleClose: PropTypes.func,
  goToInitialModal: PropTypes.func,
  setModalSize: PropTypes.func,
  setWizardTitle: PropTypes.func,
  setWizardBack: PropTypes.func,
};

export default CheckStandardDepositWizard;
