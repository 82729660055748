import { createSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import { getRegisterContent } from '../../util/helpers/registerHelpers';
import { authScopeStringGetHelper } from '../../util/helpers/authScopeHelpers';

import { JWT_TOKEN } from '../../constants/localStorage';
import { APPLICATION_USER } from '../../constants/sessionStorage';

export const userSelector = (state) => state.user;

export const selectAuthUser = createSelector(
  userSelector,
  (state) => state.user,
);

export const selectIsUserAuthenticated = createSelector(
  selectAuthUser,
  (state) => !isEmpty(state) || authScopeStringGetHelper(JWT_TOKEN),
);

export const getUserSecurityQuestion = createSelector(
  userSelector,
  (state) => state.securityQuestion,
);

export const getForgotPasswordRequest = createSelector(
  userSelector,
  (state) => state.user,
);

export const selectForgotPasswordError = createSelector(
  userSelector,
  (state) => state.errorMessage,
);

export const getResetPasswordRequest = createSelector(
  userSelector,
  (state) => state.user,
);

export const getUpdateUserStateError = createSelector(
  userSelector,
  (state) => state.errorMessage,
);

export const selectUsernames = createSelector(
  userSelector,
  (state) => state.registration.users,
);

export const selectUsernamesError = createSelector(
  userSelector,
  (state) => state.registration.error,
);

export const selectCreatedUser = createSelector(userSelector, (state) =>
  !isEmpty(state.registration.createdUser)
    ? state.registration.createdUser
    : getRegisterContent(APPLICATION_USER),
);

export const selectRegistrationUserAccounts = createSelector(
  userSelector,
  (state) => state.registration.accounts,
);

export const selectSecurityQuestion = createSelector(
  userSelector,
  (state) => state.registration.securityQuestion,
);

export const selectUserPhoneNumbers = createSelector(
  userSelector,
  (state) => state.phoneNumbers,
);

export const selectUserTwoFAPhoneNumber = createSelector(
  userSelector,
  (state) => state.twoFAPhoneNumber,
);
