import i18next from 'i18next';

export const ACH_ACCOUNT_NAME = 'ACH Deposit';
export const WIRE_ACCOUNT_NAME = 'Wire Withdrawal';
export const CHECK_ACCOUNT_NAME = 'Check Withdrawal';

export const VERIFICATION_STATUS = {
  INCOMPLETE: {
    text: i18next.t('bankAccounts.verificationStatus.incomplete'),
    color: '#212529',
  },
  ACTION_REQUIRED: {
    text: i18next.t('bankAccounts.verificationStatus.actionRequired'),
    color: '#DC3545',
  },
  COMPLETE: {
    text: i18next.t('bankAccounts.verificationStatus.complete'),
    color: '#198754',
  },
  PENDING_REVIEW: {
    text: i18next.t('bankAccounts.verificationStatus.pendingReview'),
    color: '#FFC107',
  },
  VERIFIED: {
    text: i18next.t('bankAccounts.verificationStatus.verified'),
    color: '#198754',
  },
};

export const VOIDED_CHECK_STATUS_TYPES = {
  INCOMPLETE: 1,
  IN_REVIEW: 2,
  COMPLETE: 3,
  RE_UPLOAD_NEEDED: 4,
};

export const MICRO_DEPOSIT_STATUS_TYPES = {
  INCOMPLETE: 1,
  COMPLETE: 2,
};

export const VOIDED_CHECK_STATUS = {
  [VOIDED_CHECK_STATUS_TYPES.INCOMPLETE]: VERIFICATION_STATUS.INCOMPLETE,
  [VOIDED_CHECK_STATUS_TYPES.RE_UPLOAD_NEEDED]: VERIFICATION_STATUS.INCOMPLETE,
  [VOIDED_CHECK_STATUS_TYPES.IN_REVIEW]: VERIFICATION_STATUS.PENDING_REVIEW,
  [VOIDED_CHECK_STATUS_TYPES.COMPLETE]: VERIFICATION_STATUS.COMPLETE,
};

export const MICRO_DEPOSITS_STATUS = {
  [MICRO_DEPOSIT_STATUS_TYPES.COMPLETE]: VERIFICATION_STATUS.COMPLETE,
  [MICRO_DEPOSIT_STATUS_TYPES.INCOMPLETE]: VERIFICATION_STATUS.INCOMPLETE,
};
