import React, { useEffect, useMemo } from 'react';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  IRA_ENTRUST_TYPE,
  IRA_EQUITY_TRUST_TYPE,
  IRA_EQUITY_TYPE,
} from '../../../../util/constants';
import InformationList from '../../../InformationList/InformationList';
import InformationListItem from '../../../InformationList/InformationListItem';
import Anchor from '../../../Anchor/Anchor';
import Note from '../../../Notes/Note';
import RolloverCustodianInformation from './RolloverCustodianInformation';
import ModalBody from '../../../Modal/ModalBody';
import ModalTitle from '../../../Modal/ModalTitle';
import ModalImage from '../../../Modal/ModalImage';
import Paragraph from '../../../Paragraph/Paragraph';
import ModalButtons from '../../../Modal/ModalButtons';
import { useBrokerCode } from '../../../../util/hooks/useBrokerCode';

const RolloverDeposit = ({ type, onOkClick, iraSettings }) => {
  const { t } = useTranslation();
  const brokerCode = useBrokerCode();

  const typeOfIra = () => {
    if (type === IRA_EQUITY_TYPE) {
      return IRA_EQUITY_TRUST_TYPE;
    }
    return IRA_ENTRUST_TYPE;
  };

  useEffect(() => {
    if (!iraSettings) {
      return null;
    }
  }, [iraSettings]);

  const iraDetails = useMemo(() => {
    if (isEmpty(iraSettings)) {
      return {};
    }
    if (type === IRA_EQUITY_TYPE || IRA_ENTRUST_TYPE) {
      return isEmpty(iraSettings.IRATrusteeDetails)
        ? {}
        : iraSettings.IRATrusteeDetails;
    }
  }, [iraSettings, type]);

  return (
    <ModalBody>
      <ModalTitle>{t('depositWizard.rollover.title')}</ModalTitle>
      <Paragraph marginBottom={24}>
        {t('depositWizard.rollover.textOne')}
      </Paragraph>
      <Paragraph marginBottom={24}>
        {t('depositWizard.rollover.textTwo')}
      </Paragraph>
      <Paragraph marginBottom={24}>
        {t('depositWizard.rollover.textThree', {
          type: typeOfIra(),
          brokerCode,
        })}
      </Paragraph>

      <RolloverCustodianInformation
        type={type}
        iraSettings={iraSettings}
        iraDetails={iraDetails}
      />
      {iraDetails && iraDetails.LogoImageUrl && (
        <ModalImage src={iraDetails.LogoImageUrl} alt="IRA Trustee" />
      )}
      <Paragraph bold marginBottom={4}>
        {t('depositWizard.rollover.haveQuestions')}
      </Paragraph>
      <Paragraph marginBottom={24}>
        {t('depositWizard.rollover.contactText', { type: typeOfIra() })}
      </Paragraph>
      {iraDetails && (
        <InformationList withBackground>
          <InformationListItem
            label={t('depositWizard.rollover.phone')}
            value={<Anchor type="telephone" value={iraDetails.Phone} />}
          />
          <InformationListItem
            label={t('depositWizard.rollover.fax')}
            value={<Anchor type="telephone" value={iraDetails.Fax} />}
          />
          <InformationListItem
            label={t('depositWizard.rollover.email')}
            value={<Anchor type="email" value={iraDetails.Email} />}
            whiteSpace
          />
          {type !== IRA_EQUITY_TYPE && iraDetails.Url && (
            <InformationListItem
              label={t('depositWizard.rollover.contact')}
              value={<Anchor type="website" value={iraDetails.Url} />}
            />
          )}
        </InformationList>
      )}
      {type === IRA_EQUITY_TYPE ? (
        <Note text={t('depositWizard.rollover.rolloverNoteOne')} />
      ) : (
        <Note text={t('depositWizard.rollover.rolloverNoteTwo')} />
      )}
      <ModalButtons
        showOnlyPrimary
        marginTop
        primaryButtonProps={{ onClick: onOkClick, label: t('common.ok') }}
        smallButtons
        buttonOnRight
      />
    </ModalBody>
  );
};

RolloverDeposit.propTypes = {
  type: PropTypes.string,
  onOkClick: PropTypes.func,
  iraSettings: PropTypes.shape({
    IRATrusteeDetails: PropTypes.shape({}),
    AccountNumber: PropTypes.string,
    CheckDepositInstructions: PropTypes.shape({
      Memo: PropTypes.string,
    }),
  }),
};

export default RolloverDeposit;
