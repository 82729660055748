import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';
import styled from 'styled-components';
import { formatMoneyNumeral } from '../../../util/helpers/numeralHelpers';
import { formatDateTime } from '../../../util/helpers/dateHelpers';
import PortfolioTrend from './PortfolioTrend';
import PortfolioType from './PortfolioType';
import { uFlexColumn } from '../../../assets/styles/utility';
import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import { variables } from '../../../assets/styles/variables';
import themeColors from '../../../assets/styles/themeColors';

const PortfolioHeaderContainer = styled.div`
  ${uFlexColumn};
  margin-bottom: ${pxToRem(24)};
  padding-top: ${pxToRem(17)};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    box-shadow: ${variables.shadow.boxShadow};
    padding-bottom: ${pxToRem(16)};
    padding-top: ${pxToRem(24)};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding-bottom: ${pxToRemMd(16)};
    padding-top: ${pxToRemMd(24)};
  }
`;

export const PortfolioTypesWrap = styled.div`
  display: flex;
  margin-bottom: ${pxToRem(18)};

  > :not(:last-child) {
    margin-right: ${pxToRem(16)};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    overflow: scroll;
    padding-left: ${pxToRem(24)};

    &::-webkit-scrollbar {
      display: none;
    }

    & {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    > button {
      white-space: nowrap;
    }
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding-left: ${pxToRemMd(24)};
  }
`;

const PortfolioHeaderValues = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: 0 ${pxToRem(24)};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: 0 ${pxToRemMd(24)};
  }
`;

const PortfolioHeaderValue = styled.div`
  font-size: ${pxToRem(36)};
  line-height: 1.25;
  letter-spacing: 0;
  margin-right: ${pxToRem(16)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(24)};
    margin-right: ${pxToRemMd(16)};
  }
`;

const PortfolioHeaderChartInfo = styled.p`
  font-size: ${pxToRem(14)};
  line-height: 1.75;
  color: ${themeColors.colorTextSecondary};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRem(16)};
    padding: 0 ${pxToRem(24)};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(14)};
    padding: 0 ${pxToRemMd(24)};
  }
`;

const PortfolioHeader = ({
  portfolioValuation,
  currentPrice,
  types,
  selectedType,
  setSelectedType,
  change,
  percentage,
  timestamp,
  isLoading,
  chartError,
}) => {
  const { t } = useTranslation();

  return (
    <PortfolioHeaderContainer>
      <PortfolioTypesWrap>
        {types.map((type) => {
          const dataCyAttribute = () => {
            switch (type) {
              case 'Portfolio Valuation':
                return 'tab-valuation';
              case 'Gold':
                return 'tab-gold-price';
              case 'Silver':
                return 'tab-silver-price';
              case 'Platinum':
                return 'tab-platinum-price';
              case 'Palladium':
                return 'tab-palladium-price';
              default:
                return 'tab-valuation';
            }
          };
          const dataCy = dataCyAttribute();

          return (
            <PortfolioType
              key={type}
              type={type}
              selectedType={selectedType}
              isLoading={isLoading}
              setSelectedType={setSelectedType}
              dataCy={dataCy}
            />
          );
        })}
      </PortfolioTypesWrap>
      <>
        {isEmpty(chartError) ? (
          <PortfolioHeaderValues>
            {selectedType === 'Portfolio Valuation' ? (
              <PortfolioHeaderValue>
                {formatMoneyNumeral(portfolioValuation)}
              </PortfolioHeaderValue>
            ) : (
              <>
                <PortfolioHeaderValue>
                  {formatMoneyNumeral(currentPrice)}
                </PortfolioHeaderValue>
                {!isLoading && (
                  <PortfolioTrend change={change} percentage={percentage} />
                )}
              </>
            )}
          </PortfolioHeaderValues>
        ) : (
          <PortfolioHeaderValues>
            {selectedType === 'Portfolio Valuation' ? (
              <PortfolioHeaderValue>
                {formatMoneyNumeral(0)}
              </PortfolioHeaderValue>
            ) : (
              <>
                <PortfolioHeaderValue>
                  {formatMoneyNumeral(0)}
                </PortfolioHeaderValue>
                {!isLoading && <PortfolioTrend noData />}
              </>
            )}
          </PortfolioHeaderValues>
        )}
      </>
      {!isLoading && (
        <PortfolioHeaderChartInfo>
          {t('portfolio.updated')}{' '}
          {timestamp && isEmpty(chartError) && formatDateTime(timestamp)}
        </PortfolioHeaderChartInfo>
      )}
    </PortfolioHeaderContainer>
  );
};

PortfolioHeader.propTypes = {
  portfolioValuation: PropTypes.number,
  currentPrice: PropTypes.number,
  timestamp: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.string),
  selectedType: PropTypes.string,
  setSelectedType: PropTypes.func,
  change: PropTypes.number,
  percentage: PropTypes.number,
  isLoading: PropTypes.bool,
  chartError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      ticks: PropTypes.arrayOf(null),
    }),
  ]),
};

export default PortfolioHeader;
