import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../../Modal/Modal';
import StepByStep from '../../../StepByStep';
import { fractionalConversionSubmit } from '../../../../store/actions/orders/orderActions';
import { getCurrentAccount } from '../../../../store/selectors/accountSelectors';
import { FRACTIONAL_CONVERSION } from '../../../../util/enum/api/transactionTypes';
import StatusModal from '../../StatusModal';
import QuantityForm from './QuantityForm';
import ConfirmConversion from './ConfirmConversion';
import {
  closeWizardContent,
  setWizardContent,
} from '../../../../util/helpers/wizardHelpers';
import {
  ERROR_OCCURED,
  FRACTIONAL_CONVERSION_WIZARD,
  QUANTITY_VALUE,
  STATUS_MESSAGE,
  STATUS_MODAL_OPEN,
} from '../../../../constants/sessionStorage';
import { useSessionStorageState } from '../../../../util/hooks/useSessionStorageState';

const FractionalConversion = ({
  isOpen,
  onClose,
  handleRefreshTransactions,
  product,
  resetProducts,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const account = useSelector(getCurrentAccount);

  const [quantity, setQuantity] = useSessionStorageState(QUANTITY_VALUE, '');

  const [statusModalOpen, setStatusModalOpen] = useSessionStorageState(
    STATUS_MODAL_OPEN,
    false,
  );

  const [errorOccurred, setErrorOccurred] = useSessionStorageState(
    ERROR_OCCURED,
    false,
  );

  const [statusMessage, setStatusMessage] = useSessionStorageState(
    STATUS_MESSAGE,
    '',
  );

  const [isLoading, setIsLoading] = useState(false);

  const handleApiResponse = (errorOccurred, message) => {
    setWizardContent(ERROR_OCCURED, errorOccurred);
    setErrorOccurred(errorOccurred);
    setWizardContent(STATUS_MESSAGE, message);
    setStatusMessage(message);
    setIsLoading(false);
    onClose();
    setWizardContent(STATUS_MODAL_OPEN, true);
    setStatusModalOpen(true);
    resetProducts();
  };

  const onRequestSuccess = () => {
    handleRefreshTransactions();
  };

  const handleConversionConfirm = () => {
    setIsLoading(true);
    dispatch(
      fractionalConversionSubmit({
        accountUid: account.AccountUid,
        data: {
          Quantity: quantity,
          SymbolCode: product.SymbolCode,
          AccountUid: account.AccountUid,
          Type: FRACTIONAL_CONVERSION,
        },
        onRequestSuccess,
        handleApiResponse,
      }),
    );
  };

  return (
    <>
      <Modal
        size="sm"
        title={t('product.fractionalConversion.title')}
        isOpen={isOpen}
        close={onClose}
      >
        <StepByStep
          onConfirm={handleConversionConfirm}
          sessionScopeStep={FRACTIONAL_CONVERSION_WIZARD}
        >
          {({ goStepBack, goStepForward, onConfirm }) => [
            <QuantityForm
              key="quantity-form"
              product={product}
              quantity={quantity}
              setQuantity={setQuantity}
              goStepForward={goStepForward}
              onClose={onClose}
            />,
            <ConfirmConversion
              key="confirm-conversion"
              product={product}
              quantity={quantity}
              goStepBack={goStepBack}
              onConfirm={onConfirm}
              isLoading={isLoading}
            />,
          ]}
        </StepByStep>
      </Modal>
      <StatusModal
        isOpen={statusModalOpen}
        onButtonClick={() => {
          onClose();
          closeWizardContent();
          setStatusModalOpen(false);
        }}
        close={() => {
          onClose();
          setStatusModalOpen(false);
        }}
        modalTitle={t('product.fractionalConversion.title')}
        hasError={errorOccurred}
        text={statusMessage}
      />
    </>
  );
};

FractionalConversion.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  handleRefreshTransactions: PropTypes.func,
  product: PropTypes.shape({
    ProductCaption: PropTypes.string,
    UnitsAvailableForConversion: PropTypes.number,
    SymbolCode: PropTypes.string,
  }),
  resetProducts: PropTypes.func,
};

export default FractionalConversion;
