import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { uuid } from 'uuidv4';
import { selectSettings } from '../../store/selectors/settingsSelectors';
import { ReactComponent as WheelchairIcon } from '../../assets/images/svg/wheelchair.svg';

import { getDate } from '../../util/helpers/dateHelpers';
import Anchor from '../Anchor/Anchor';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { uFlexCenter } from '../../assets/styles/utility';
import { variables } from '../../assets/styles/variables';

export const FooterAnchor = styled(Anchor)`
  text-decoration: none;
  color: ${themeColors.colorFooterText};
  text-transform: uppercase;
  font-size: ${pxToRem(12)};

  > svg {
    height: ${pxToRem(12)};
    margin-right: ${pxToRem(4)};
    color: currentColor;
    flex-shrink: 0;
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(11)};
    line-height: 2;
  }
`;

export const FooterElement = styled.div`
  ${uFlexCenter};
  width: 100%;
  padding: ${pxToRem(12)} 0;
  background-color: ${themeColors.colorFooterBackground};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    flex-direction: column-reverse;
    align-items: center;
    padding: ${pxToRemMd(16)} 0;
  }
`;

export const FooterCopyright = styled.p`
  color: ${themeColors.colorFooterText};
  margin-right: ${pxToRem(48)};
  text-transform: uppercase;
  text-align: center;
  font-size: ${pxToRem(12)};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    margin-right: 0;
    margin-top: ${pxToRemMd(16)};
    font-size: ${pxToRemMd(11)};
    line-height: 2;
  }
`;

export const FooterList = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;

  & > :not(:last-child) {
    &:after {
      content: '|';
      display: inline;
      font-size: ${pxToRem(16)};
      line-height: 1.5;
      color: ${themeColors.colorFooterText};
      margin: 0 ${pxToRem(16)};
    }
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    display: flex;
    align-items: center;
    list-style: none;

    & > :not(:last-child) {
      &:after {
        font-size: ${pxToRemMd(12)};
      }
    }
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    flex-direction: column;

    & > :not(:last-child) {
      &:after {
        display: none;
      }
    }
  }
`;

export const FooterItem = styled.li`
  cursor: pointer;
`;

export const FooterItemText = styled.p`
  text-decoration: none;
  color: ${themeColors.colorFooterText};
  text-transform: uppercase;
  font-size: ${pxToRem(12)};

  > svg {
    height: ${pxToRem(12)};
    margin-right: ${pxToRem(4)};
    color: currentColor;
    flex-shrink: 0;
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(11)};
    line-height: 2;
  }
`;

const Footer = ({ text, link }) => {
  const { t } = useTranslation();
  const settings = useSelector(selectSettings);
  const { date } = getDate();
  const copyrightYear = date.getFullYear();

  const footerData = settings?.FooterUrls?.map(
    (item) => item && { label: item.Caption, value: item.Url },
  );

  return (
    <FooterElement>
      <FooterCopyright>
        {text ||
          t(`footer.copyright`, {
            year: copyrightYear,
            corporateLegalName: settings?.CorporateLegalName,
          })}
      </FooterCopyright>
      <FooterList>
        {link ? (
          <FooterItem>
            <FooterAnchor text={link} />
          </FooterItem>
        ) : (
          <>
            {footerData &&
              footerData.map(({ label, value }) => (
                <FooterItem key={uuid()}>
                  <FooterAnchor type="website" value={value} text={label} />
                </FooterItem>
              ))}

            <FooterItem>
              <FooterItemText>
                <WheelchairIcon />
                {t(`footer.menu.accessibilityAssistant`)}
              </FooterItemText>
            </FooterItem>
          </>
        )}
      </FooterList>
    </FooterElement>
  );
};

Footer.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
};

export default Footer;
