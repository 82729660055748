import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatMoneyNumeral } from '../../../../../util/helpers/numeralHelpers';
import SectionLoader from '../../../../Loader/SectionLoader';
import ReviewBar from '../../../../ReviewBar/ReviewBar';
import { IRA_MILLENIUM_TYPE } from '../../../../../util/constants';
import ModalBody from '../../../../Modal/ModalBody';
import Paragraph from '../../../../Paragraph/Paragraph';
import ModalButtons from '../../../../Modal/ModalButtons';
import Label from '../../../../Notes/Label';

const WireInformationReview = ({
  handleNext,
  handleBack,
  value,
  confirmWithdraw,
  type,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { amount } = value;

  return (
    <SectionLoader isLoading={isLoading}>
      <ReviewBar title={t('entrust.review.reviewBar')} />
      <ModalBody>
        <Label marginBottom={4} isBold text={t('entrust.review.amount')} />
        <Paragraph fontSize={18} marginBottom={24}>
          {formatMoneyNumeral(amount)}
        </Paragraph>
        {type === IRA_MILLENIUM_TYPE && (
          <Paragraph marginBottom={24}>
            <strong>{t('entrust.review.notice')}</strong>
          </Paragraph>
        )}
        <Paragraph>{t('entrust.review.description')}</Paragraph>
        <ModalButtons
          marginTop
          isHorizontal
          secondaryButtonProps={{ onClick: handleBack }}
          primaryButtonProps={{
            onClick: () => confirmWithdraw(handleNext),
            label: t('common.confirm'),
          }}
        />
      </ModalBody>
    </SectionLoader>
  );
};

WireInformationReview.propTypes = {
  value: PropTypes.shape({
    address: PropTypes.shape({
      label: PropTypes.string,
    }),
    amount: PropTypes.number,
  }),
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  confirmWithdraw: PropTypes.func,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default WireInformationReview;
