export const buyStorageUnitType = {
  cash: 'Cash',
  quantity: 'Quantity',
};

export const orderType = {
  buyForDelivery: 'BuyForDelivery',
  buyForStorage: 'BuyForStorage',
  sellFromStorage: 'SellFromStorage',
  deliverFromStorage: 'DeliverFromStorage',
  wireWithdrawal: 'WireWithdrawal',
  checkWithdrawal: 'CheckWithdrawal',
  achDeposit: 'ACHDeposit',
  iraFeeWithdrawal: 'IRAFeeWithdrawal',
  iraCashTransfer: 'IRACashTransfer',
  iraCashDistribution: 'IRACashDistribution',
  fractionalConversion: 'FractionalConversion',
};
