import {
  createErrorType,
  createSubmitType,
  createSuccessType,
  createLoadingType,
} from '../actionHelpers';

// submit new credit card
const SUBMIT_MAIL_IN_SCOPE = 'SUBMIT_MAIL_IN_SCOPE';
export const SUBMIT_MAIL_IN_REQUEST = createSubmitType(SUBMIT_MAIL_IN_SCOPE);
export const SUBMIT_MAIL_IN_REQUEST_SUCCESS = createSuccessType(
  SUBMIT_MAIL_IN_SCOPE,
);
export const SUBMIT_MAIL_IN_REQUEST_ERROR = createErrorType(
  SUBMIT_MAIL_IN_SCOPE,
);
export const SUBMIT_MAIL_IN_REQUEST_LOADING = createLoadingType(
  SUBMIT_MAIL_IN_SCOPE,
);
