import { call, takeLatest, all, put } from '@redux-saga/core/effects';

import { PLAID_LINK_TOKEN_FETCH } from '../actions/plaid/PlaidActionConstants';
import { getLinkToken } from '../../request/plaidRequest';
import {
  fetchLinkTokenSuccess,
  fetchLinkTokenError,
} from '../actions/plaid/PlaidActions';
import { rejectErrorCodeHelper } from '../../util/helpers/rejectErrorCodeHelper';

function* fetchLinkToken({ payload }) {
  try {
    const { data } = yield call(getLinkToken, payload);
    yield put(fetchLinkTokenSuccess(data));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchLinkTokenError(errorMessage));
    console.log(error); // eslint-disable-line
  }
}

export default function* plaidSaga() {
  yield all([takeLatest(PLAID_LINK_TOKEN_FETCH, fetchLinkToken)]);
}
