import {
  createErrorType,
  createFetchType,
  createLoadingType,
  createSuccessType,
} from '../actionHelpers';

const DOCUMENTS_BY_YEAR_SCOPE = 'DOCUMENT_BY_YEAR_SCOPE';

// usernames for account
export const FETCH_DOCUMENT_BY_YEAR = createFetchType(DOCUMENTS_BY_YEAR_SCOPE);
export const FETCH_DOCUMENT_BY_YEAR_SUCCESS = createSuccessType(
  DOCUMENTS_BY_YEAR_SCOPE,
);
export const FETCH_DOCUMENT_BY_YEAR_ERROR = createErrorType(
  DOCUMENTS_BY_YEAR_SCOPE,
);
export const FETCH_DOCUMENT_BY_YEAR_LOADING = createLoadingType(
  DOCUMENTS_BY_YEAR_SCOPE,
);

// get single document by document url
const DOCUMENT_BY_DOCUMENT_URL_SCOPE = 'DOCUMENT_BY_DOCUMENT_URL';
export const FETCH_DOCUMENT_BY_DOCUMENT_URL = createFetchType(
  DOCUMENT_BY_DOCUMENT_URL_SCOPE,
);
export const FETCH_DOCUMENT_BY_DOCUMENT_URL_LOADING = createLoadingType(
  DOCUMENT_BY_DOCUMENT_URL_SCOPE,
);

// get single document by type
const DOCUMENT_BY_TYPE_SCOPE = 'DOCUMENT_BY_TYPE';
export const FETCH_DOCUMENT_BY_TYPE = createFetchType(DOCUMENT_BY_TYPE_SCOPE);
export const FETCH_DOCUMENT_BY_TYPE_LOADING = createLoadingType(
  DOCUMENT_BY_TYPE_SCOPE,
);
