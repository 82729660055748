import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import TypeCard from '../TypeCard/TypeCard';
import {
  selectTwoFaApplication,
  selectTwoFaTypes,
} from '../../store/selectors/twoFASelector';
import { TWO_FA_TYPES_LOADING } from '../../store/actions/twoFA/twoFAActionConstants';
import { selectIsLoadingByActionType } from '../../store/selectors/loadingSelectors';
import { fetchTwoFaTypes } from '../../store/actions/twoFA/twoFAActions';
import BlockSectionLoaderWrap from '../Loader/BlockSectionWrapper';
import { parseEnumType } from '../../util/helpers/enumMappers';
import { twoFaTypesEnum } from '../../util/enum/api/twoFATypes';
import { selectAuthUser } from '../../store/selectors/userSelectors';
import ModalBody from '../Modal/ModalBody';
import ModalTitle from '../Modal/ModalTitle';
import TypeList from '../TypeList/TypeList';
import SingleColumnList from '../TwoColumnList/SingleColumnList';
import Paragraph from '../Paragraph/Paragraph';

const TwoFAChooseMethod = ({ handleNext, setTwoFaValues, getTwoFaCode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectAuthUser);
  const twoFaTypes = useSelector(selectTwoFaTypes);
  const twoFactorAuthenticationResponse = useSelector(selectTwoFaApplication);
  const isLoading = useSelector(
    selectIsLoadingByActionType(TWO_FA_TYPES_LOADING),
  );
  const [types, setTypes] = useState([]);

  const [error, setError] = useState(null);

  const getTypeValues = (type) => {
    switch (type) {
      case twoFaTypes[0]:
        return {
          value: parseEnumType(twoFaTypesEnum, 1),
          title: t('twoFa.profileSettings.chooseMethod.authentication.title'),
          description: t(
            'twoFa.profileSettings.chooseMethod.authentication.description',
          ),
        };

      case twoFaTypes[1]:
        return {
          value: parseEnumType(twoFaTypesEnum, 2),
          title: t('twoFa.profileSettings.chooseMethod.phone.title'),
          description: t(
            'twoFa.profileSettings.chooseMethod.phone.description',
          ),
        };

      case twoFaTypes[2]:
        return {
          value: parseEnumType(twoFaTypesEnum, 3),
          title: t('twoFa.profileSettings.chooseMethod.email.title'),
          description: t(
            'twoFa.profileSettings.chooseMethod.email.description',
          ),
        };

      default:
        break;
    }
  };

  useEffect(() => {
    const types = twoFaTypes.map((type) => getTypeValues(type));

    if (!isEmpty(types)) {
      setTypes(types);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twoFaTypes]);

  useEffect(() => {
    dispatch(fetchTwoFaTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onError = (error) => {
    setError(error);
  };

  const handleClick = (type) => {
    if (type === parseEnumType(twoFaTypesEnum, 3)) {
      setTwoFaValues({ AuthenticationMethodType: type });

      getTwoFaCode({
        userUid: user.UserUid,
        requestData: {
          IsFromSetup: true,
          AuthenticationMethodType: type,
        },
        onSuccess: handleNext,
        onError,
      });
      return;
    }
    setTwoFaValues({ AuthenticationMethodType: type });
    handleNext();
  };

  return (
    <ModalBody>
      <ModalTitle>{t('twoFa.profileSettings.chooseMethod.title')}</ModalTitle>
      <BlockSectionLoaderWrap isLoading={isLoading}>
        <TypeList>
          {types.map((type) => (
            <TypeCard
              onClick={() => handleClick(type.value)}
              title={type.title}
              description={type.description}
              key={type.value}
              isSelected={
                parseEnumType(
                  twoFaTypesEnum,
                  twoFactorAuthenticationResponse?.AuthenticationMethodType,
                ) === type.value
              }
            />
          ))}
        </TypeList>
        <SingleColumnList>
          {error && (
            <Paragraph isError marginTop={8}>
              {error ===
              t('apiErrors.TwoFactorAuthenticationLimitIsExceeded') ? (
                <strong>{t('twoFa.profileSettings.error.message')}</strong>
              ) : (
                <strong>{error}</strong>
              )}
            </Paragraph>
          )}
        </SingleColumnList>
      </BlockSectionLoaderWrap>
    </ModalBody>
  );
};

TwoFAChooseMethod.propTypes = {
  handleNext: PropTypes.func,
  setTwoFaValues: PropTypes.func,
  getTwoFaCode: PropTypes.func,
};

export default TwoFAChooseMethod;
