import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import { variables } from '../../../assets/styles/variables';

export const QrImage = styled.img`
  width: ${pxToRem(200)};
  height: ${pxToRem(200)};
  margin: ${pxToRem(32)} auto;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin: ${pxToRemMd(32)} auto;
    width: ${pxToRemMd(200)};
    height: ${pxToRemMd(200)};
  }
`;

const QrCode = ({ QrCodeImageUrl }) => {
  if (!QrCodeImageUrl) {
    return null;
  }

  return <QrImage src={QrCodeImageUrl} alt="qr-code" />;
};

QrCode.propTypes = {
  QrCodeImageUrl: PropTypes.string,
};

export default QrCode;
