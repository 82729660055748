import React from 'react';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Button from '../Button/Button';
import {
  ACC_TYPE_INACTIVE,
  ACC_TYPE_INVITED,
} from '../../util/enum/api/accountTypes';
import Paragraph from '../Paragraph/Paragraph';
import TwoColumnList from '../TwoColumnList/TwoColumnList';
import SingleColumnList from '../TwoColumnList/SingleColumnList';
import { variables } from '../../assets/styles/variables';
import { mediaBelow, pxToRem } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';

const ManagementCardContainer = styled.div`
  padding: ${pxToRem(14)} ${pxToRem(16)} ${pxToRem(16)};
  border-radius: ${variables.borderRadius.borderRadius};
  background-color: ${themeColors.colorCardBackground};
  box-shadow: ${variables.shadow.boxShadow};
`;

const ManagementCardActions = styled.div`
  margin-top: ${pxToRem(24)};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-gap: ${pxToRem(10)};

  > :nth-child(3) {
    grid-column: 1 / span 2;
  }

  ${mediaBelow(variables.breakpoints.bpXs)} {
    grid-template-columns: 1fr;
    grid-template-rows: auto;

    > :nth-child(3) {
      grid-column: unset;
    }
  }
`;

const ManagementCard = ({
  name,
  permission,
  status,
  editPermission,
  handleRemoveUser,
  handleActivateAccount,
  handleResendInvitation,
  userHasChangePermission,
  isBasicAccount,
  currentUser,
  user,
  isRemoveButtonAllowedForCurrentUser,
  isImpersonating,
  permissionType,
}) => {
  const { t } = useTranslation();

  return (
    <ManagementCardContainer>
      <TwoColumnList marginBottom={16}>
        <Paragraph bold fontSize={20}>
          {name}
        </Paragraph>
        <Paragraph bold>{status}</Paragraph>
      </TwoColumnList>
      <SingleColumnList marginBottom={16}>
        <Paragraph bold marginBottom={4}>
          {t('user.emailAddress')}
        </Paragraph>
        <Paragraph>{user?.Email}</Paragraph>
      </SingleColumnList>
      <SingleColumnList marginBottom={16}>
        <Paragraph bold marginBottom={4}>
          {t('user.permissions.label')}
        </Paragraph>
        <Paragraph>{permission}</Paragraph>
      </SingleColumnList>

      <ManagementCardActions>
        <Button
          onClick={handleRemoveUser}
          size="sm"
          textTransform="capitalize"
          variant="primaryOutlined"
          disabled={!userHasChangePermission || isBasicAccount}
          hidden={
            currentUser?.UserUid === user?.UserUid &&
            isRemoveButtonAllowedForCurrentUser
          }
        >
          {t('common.remove')}
        </Button>

        <Button
          onClick={editPermission}
          size="sm"
          textTransform="capitalize"
          variant="primaryOutlined"
          disabled={!userHasChangePermission || isBasicAccount}
        >
          {t('user.editPermissions.label')}
        </Button>

        {status === ACC_TYPE_INACTIVE && isImpersonating && (
          <Button
            onClick={handleActivateAccount}
            size="sm"
            textTransform="capitalize"
            variant="primary"
            disabled={!userHasChangePermission || isBasicAccount}
          >
            {t('userManagement.activate.title')}
          </Button>
        )}
        {status === ACC_TYPE_INVITED && permissionType !== 1 && (
          <Button
            onClick={handleResendInvitation}
            size="sm"
            textTransform="capitalize"
            variant="primary"
            disabled={!userHasChangePermission || isBasicAccount}
          >
            {t('userManagement.resendInvite.title')}
          </Button>
        )}
      </ManagementCardActions>
    </ManagementCardContainer>
  );
};

ManagementCard.propTypes = {
  name: PropType.string,
  isRemoveButtonAllowedForCurrentUser: PropType.bool,
  permission: PropType.string,
  status: PropType.string,
  editPermission: PropType.func,
  handleRemoveUser: PropType.func,
  handleActivateAccount: PropType.func,
  handleResendInvitation: PropType.func,
  userHasChangePermission: PropType.bool,
  user: PropType.shape({
    Permissions: PropType.arrayOf(PropType.number),
    UserUid: PropType.string,
    Email: PropType.string,
  }),
  currentUser: PropType.shape({
    Permissions: PropType.arrayOf(PropType.number),
    UserUid: PropType.string,
  }),
  isBasicAccount: PropType.bool,
  isImpersonating: PropType.bool,
  permissionType: PropType.number,
};

export default ManagementCard;
