import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18next from '../../../i18n';

import BalanceCard from './BalanceCard';
import PortfolioSection from '../Section/PortfolioSection';
import Button from '../../Button/Button';
import MilleniumDepositWizard from '../../Modals/DepositWizard/MilleniumDepositWizard';
import IraDepositWizard from '../../Modals/DepositWizard/IraDepositWizard';

import { fetchPortfolioBalances } from '../../../store/actions/portfolio/portfolioActions';
import { getCurrentAccount } from '../../../store/selectors/accountSelectors';
import { selectBalancesAvailableForTrading } from '../../../store/selectors/portfolioBalancesSelectors';
import { selectIsLoadingByActionType } from '../../../store/selectors/loadingSelectors';
import { PORTFOLIO_BALANCES_LOADING } from '../../../store/actions/portfolio/portfolioActionConstants';
import BlockSectionLoader from '../../Loader/BlockSectionLoader';
import { setWizardContent } from '../../../util/helpers/wizardHelpers';
import {
  IS_DEPOSIT_MODAL_OPEN,
  PREVENT_SUPPORT_MODAL,
} from '../../../constants/sessionStorage';
import useCheckPermissions from '../../../util/hooks/useCheckPermissions';
import useGtmHook from '../../../util/hooks/useGtmHook';
import { useSessionStorageState } from '../../../util/hooks/useSessionStorageState';
import { storeInSessionStorage } from '../../../util/helpers/sessionStorageHelper';
import { IRA_ENTRUST_TYPE, IRA_EQUITY_TYPE } from '../../../util/constants';
import StandardDepositWizard from '../../Modals/Deposit/StandardDepositWizard/StandardDepositWizard';
import BasicAccountRestristionsModal from '../../Modals/BasicAccountRestrictionsModal/BasicAccountRestrictionsModal';
import {
  accountTypeKeys,
  accountTypes,
} from '../../../util/enum/api/accountTypes';
import { parseEnumType } from '../../../util/helpers/enumMappers';

const Balances = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { gtmDialogScreenView } = useGtmHook();
  const account = useSelector(getCurrentAccount);
  const availableForTrading = useSelector(selectBalancesAvailableForTrading);
  const [isModalOpen, setIsModalOpen] = useSessionStorageState(
    IS_DEPOSIT_MODAL_OPEN,
    false,
  );
  const isBalanceLoading = useSelector(
    selectIsLoadingByActionType(PORTFOLIO_BALANCES_LOADING),
  );
  const {
    allowAccountChangesPermission,
    displayIRAEntrustOptionsPermission,
    displayIRAMilleniumOptionsPermission,
    displayIRAEquityOptionsPermission,
    displayStandardOptionsPermission,
    displayBasicAccountOptionsPermission,
  } = useCheckPermissions();

  const isBasicAccount =
    parseEnumType(accountTypeKeys, account.AccountType) === accountTypes.Basic;

  useEffect(() => {
    dispatch(
      fetchPortfolioBalances({
        accountUid: account.AccountUid,
      }),
    );
  }, [dispatch, account.AccountUid]);

  return (
    <PortfolioSection
      title="Balances"
      cta={
        allowAccountChangesPermission && (
          <Button
            disabled={
              isBalanceLoading ||
              (displayBasicAccountOptionsPermission && !isBasicAccount)
            }
            lockStyle={isBasicAccount}
            onClick={() => {
              storeInSessionStorage(PREVENT_SUPPORT_MODAL, true);
              setWizardContent(IS_DEPOSIT_MODAL_OPEN, true);
              setIsModalOpen(true);
              gtmDialogScreenView({
                title: 'Deposit Funds',
              });
            }}
            variant="primary"
            data-cy="button-deposit"
          >
            {i18next.t('common.deposit')}
          </Button>
        )
      }
    >
      <div>
        <div>
          {isBalanceLoading ? (
            <BlockSectionLoader isLoading={isBalanceLoading} />
          ) : (
            <BalanceCard availableForTrading={availableForTrading} />
          )}
        </div>
      </div>
      {isBasicAccount && (
        <BasicAccountRestristionsModal
          isOpen={isModalOpen}
          close={() => setIsModalOpen(false)}
          title={t('basicAccountRestrictions.deposit.title')}
          description={t('basicAccountRestrictions.deposit.description')}
        />
      )}
      {displayStandardOptionsPermission && (
        <StandardDepositWizard
          isModalOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
        />
      )}
      {displayIRAEquityOptionsPermission && (
        <IraDepositWizard
          isModalOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          type={IRA_EQUITY_TYPE}
        />
      )}
      {displayIRAEntrustOptionsPermission && (
        <IraDepositWizard
          isModalOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          type={IRA_ENTRUST_TYPE}
        />
      )}
      {displayIRAMilleniumOptionsPermission && (
        <MilleniumDepositWizard
          isModalOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
        />
      )}
    </PortfolioSection>
  );
};

export default Balances;
