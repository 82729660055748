import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import StepByStep from '../../../StepByStep';
import { MANUAL_ADD_NEW_ACH_WIZARD } from '../../../../constants/sessionStorage';
import { selectBankAccountDetailsByRoutingNum } from '../../../../store/selectors/bankAccountSelectors';
import AchForm from './AchForm';
import AchReview from './AchReview';
import { deleteWizardKey } from '../../../../util/helpers/wizardHelpers';
import AchDone from './AchDone';

const steps = [AchForm, AchReview, AchDone];

const AddAchManualBankAccountWizard = ({
  wizardData,
  setWizardData,
  handleBack,
  onButtonClick,
}) => {
  const { t } = useTranslation();
  const bankDetails = useSelector(selectBankAccountDetailsByRoutingNum);

  useEffect(() => {
    if (bankDetails) {
      setWizardData({
        bankAccountDetailsByRoutingNumber: bankDetails,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankDetails]);

  return (
    <StepByStep sessionScopeStep={MANUAL_ADD_NEW_ACH_WIZARD}>
      {({ goStepForward, goStepBack, goTwoStepsForward }) =>
        steps.map((step) =>
          React.createElement(step, {
            handleBack: goStepBack,
            handleNext: goStepForward,
            handleClose: () => {
              handleBack();
            },
            goTwoStepsForward,
            text: wizardData?.errorMessage,
            hasError: !!wizardData?.errorMessage,
            backButtonText: t('common.ok'),
            onButtonClick: () => {
              onButtonClick();
              deleteWizardKey(MANUAL_ADD_NEW_ACH_WIZARD);
            },
            setWizardData,
            wizardData,
          }),
        )
      }
    </StepByStep>
  );
};

AddAchManualBankAccountWizard.propTypes = {
  handleBack: PropTypes.func,
  wizardData: PropTypes.shape({
    errorMessage: PropTypes.string,

    bankAccountDetailsByRoutingNumber: PropTypes.shape({
      Name: PropTypes.string,
      Address: PropTypes.shape({
        Address: PropTypes.string,
        AddressUid: PropTypes.string,
        AddressLine: PropTypes.string,
        AddressLine2: PropTypes.string,
        City: PropTypes.string,
        PostalCode: PropTypes.string,
        StateRegion: PropTypes.string,
        CountryIso3Code: PropTypes.string,
      }),
      Phone: PropTypes.string,
      ABARoutingNumber: PropTypes.string,
      ACHRoutingNumber: PropTypes.string,
      WireRoutingNumber: PropTypes.string,
    }),
    achManualFormValues: PropTypes.shape({
      nameOnAccount: PropTypes.string,
      accountNumber: PropTypes.string,
      accountType: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  setWizardData: PropTypes.func,
  onButtonClick: PropTypes.func,
};

export default AddAchManualBankAccountWizard;
