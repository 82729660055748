import { createGlobalStyle } from 'styled-components';
import { mediaBelow, pxToRem, pxToRemMd } from './helper';
import themeColors from './themeColors';
import { uFlexColumn } from './utility';
import { variables } from './variables';
import themeFonts from './themeFonts';

const GlobalStyle = createGlobalStyle`
  ${themeFonts}

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-anchor: none;
  }

  * {
    box-sizing: border-box;
  }

html {
  min-height: 100%;
  font-size: 16px;

  ${mediaBelow(variables.breakpoints.bpXxl)} {
        font-size: 14px;
  }

  ${mediaBelow(variables.breakpoints.bpXs)} {
    font-size: 13px;
  }

  ${mediaBelow(variables.breakpoints.bpXxs)} {
    font-size: 10.5px;
  }
}

html,
body,
#root {
  ${uFlexColumn};
  flex: 1 0 auto;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

ul {
  list-style: none;
  padding: 0;
}

svg {
  color: ${themeColors.colorIconPrimary};
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
button,
input,
textarea,
p,
span,
blockquote,
th,
td {
  color: ${themeColors.colorTextPrimary};
}

p {
  vertical-align: middle;
  display: inline-block;
  word-break: break-word;
  font-size: ${pxToRem(16)};
  line-height: 1.5;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(16)};
  }
}

span,
a {
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

a {
  text-decoration: underline;
  color: ${themeColors.colorLinkPrimary};
  cursor: pointer;
}

strong {
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  color: ${themeColors.colorTextPrimary};
}

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-anchor: none;
  }
  
  * {
    box-sizing: border-box;
  }
  
  html {
    min-height: 100%;
    font-size: 16px;

    ${mediaBelow(variables.breakpoints.bpXxl)} {
      font-size: 14px;
    }
    
    ${mediaBelow(variables.breakpoints.bpXs)} {
      font-size: 13px;
    }
  
    ${mediaBelow(variables.breakpoints.bpXxs)} {
      font-size: 10.5px;
    }
  }
  
  html,
  body,
  #root {
    ${uFlexColumn}
    flex: 1 0 auto;
  }
  
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  svg {
    color: ${themeColors.colorIconPrimary};
  }

*,
*:before,
*:after {
  box-sizing: border-box;
}

*,
body,
button,
input,
textarea,
select {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
button,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
em,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

caption,
th {
  text-align: left;
}

q:before,
q:after {
  content: '';
}

abbr,
acronym {
  border: 0;
}

svg {
  flex-shrink: 0;
}

textarea,
input:matches([type='email'], [type='number'], [type='password'], [type='search'], [type='tel'], [type='text'], [type='url']) {
  -webkit-appearance: none;

  &::-webkit-autofill,
  &::-webkit-contacts-auto-fill-button,
  &::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    user-select: none;
    display: none !important;
    position: absolute;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;

  &::-webkit-autofill,
  &::-webkit-contacts-auto-fill-button,
  &::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    user-select: none;
    display: none !important;
    position: absolute;
  }
}

button {
  &:disabled {
    cursor: not-allowed;
  }
}
.acsb-trigger {
  display: none !important;
  visibility: hidden !important;
  width: 0 !important;
  height: 0 !important;
}
`;

export default GlobalStyle;
