import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as ChevronRight } from '../../assets/images/svg/chevron-right.svg';
import { ReactComponent as ChevronLeft } from '../../assets/images/svg/chevron-left.svg';
import RestrictionBanner from '../RestrictionBanner/RestrictionBanner';
import ImpersonateBanner from '../ImpersonateBanner/ImpersonateBanner';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import { uFlexCenter } from '../../assets/styles/utility';
import themeColors from '../../assets/styles/themeColors';
import Section from '../Section/Section';

export const NavigationBack = styled(ChevronLeft)`
  margin-right: ${pxToRemMd(28.5)};
  width: ${pxToRemMd(16)};
  height: ${pxToRemMd(16)};
`;

export const NavigationBarCta = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  padding-left: ${pxToRem(32)};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    margin-top: ${pxToRemMd(16)};
    padding-left: 0;
  }
`;

export const NavigationBarWrap = styled(Section)`
  display: flex;
  justify-content: space-between;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    ${uFlexCenter};
    flex-direction: column;
  }
`;

export const NavigationBarSubPageParagraph = styled.p`
  font-size: ${pxToRem(36)};
  line-height: 1.22;
  text-decoration: none;
`;

export const NavigationBarMainLink = styled(NavLink)`
  color: ${themeColors.colorTextPrimary};
  font-size: ${pxToRem(36)};
  line-height: 1.22;
  text-decoration: none;
`;

export const NavigationBarMainPageParagraph = styled.p`
  color: ${themeColors.colorTextPrimary};
  font-size: ${pxToRem(36)};
  line-height: 1.22;
  text-decoration: none;
`;

export const NavigationBarRouteStyle = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: ${pxToRem(24)};
    height: ${pxToRem(24)};
    margin: 0 ${pxToRem(23)};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    ${NavigationBarSubPageParagraph} {
      text-align: center;
      font-size: ${pxToRemMd(24)};
    }

    > :first-child {
      display: none;
    }

    svg {
      display: none;
    }
  }
`;

export const NavigationBarContainer = styled.div`
  padding: ${pxToRem(26)} 0 ${pxToRem(18)};
  box-shadow: ${variables.shadow.boxShadow};
  background-color: ${themeColors.colorWhite};
  display: flex;
  align-items: center;

  ${(props) =>
    props.hasBack &&
    `
  ${NavigationBack} {
    display: none;
  }
  `};

  ${Section} {
    width: 100%;
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: ${pxToRemMd(24)} ${pxToRemMd(16)};

    ${(props) =>
      props.hideCtaOnMobile &&
      `
      ${NavigationBarCta} {
        display: none;
      }
    `};

    ${(props) =>
      props.hasBack &&
      `
      ${NavigationBack} {
        display: block;
      }

      ${NavigationBarWrap} {
        align-items: flex-start;
      }
    `};
  }
`;

const NavigationBar = ({
  mainPage,
  subPage,
  cta,
  hasBack,
  hideCtaOnMobile,
  pageRoute,
}) => {
  const mainPageRoute = pageRoute || `/${mainPage.toLowerCase()}`;

  return (
    <>
      <NavigationBarContainer
        hasBack={hasBack}
        hideCtaOnMobile={hideCtaOnMobile}
      >
        {hasBack && (
          <NavLink to={mainPageRoute}>
            <NavigationBack />
          </NavLink>
        )}

        <NavigationBarWrap>
          <NavigationBarRouteStyle>
            {hasBack ? (
              <NavigationBarMainLink to={mainPageRoute}>
                {mainPage}
              </NavigationBarMainLink>
            ) : (
              <NavigationBarMainPageParagraph>
                {mainPage}
              </NavigationBarMainPageParagraph>
            )}

            <ChevronRight />
            <NavigationBarSubPageParagraph>
              {subPage}
            </NavigationBarSubPageParagraph>
          </NavigationBarRouteStyle>
          {cta && <NavigationBarCta>{cta}</NavigationBarCta>}
        </NavigationBarWrap>
      </NavigationBarContainer>
      <RestrictionBanner />
      <ImpersonateBanner />
    </>
  );
};

NavigationBar.propTypes = {
  mainPage: PropTypes.string,
  subPage: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  cta: PropTypes.node,
  hasBack: PropTypes.bool,
  hideCtaOnMobile: PropTypes.bool,
  pageRoute: PropTypes.string,
};
export default NavigationBar;
