import {
  FETCH_ACCOUNT_IRA_SETTINGS,
  FETCH_ACCOUNT_IRA_SETTINGS_ERROR,
  FETCH_ACCOUNT_IRA_SETTINGS_SUCCESS,
} from './iraSettingsActionConstants';

// users for account
export const fetchAccountIraSettings = (payload) => ({
  type: FETCH_ACCOUNT_IRA_SETTINGS,
  payload,
});

export const fetchAccountIraSettingsSuccess = (payload) => ({
  type: FETCH_ACCOUNT_IRA_SETTINGS_SUCCESS,
  payload,
});

export const fetchAccountIraSettingsError = (payload) => ({
  type: FETCH_ACCOUNT_IRA_SETTINGS_ERROR,
  payload,
});
