import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as ExecutedIcon } from '../../assets/images/svg/executed.svg';
import { ReactComponent as PendingIcon } from '../../assets/images/svg/pending.svg';
import { ReactComponent as Alert } from '../../assets/images/svg/alert-triangle.svg';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';
import { uTextEllipsis } from '../../assets/styles/utility';
import Button from '../Button/Button';

const getStatusContainerStyles = ({ variant }) => {
  switch (variant) {
    case 'Settled':
    case 'Executed':
      return `
      p{
      color: ${themeColors.colorShippmentSuccess};
      };
      svg {
        color: ${themeColors.colorShippmentSuccess};
      }
    `;

    case 'Pending':
      return `
      p{
      color: ${themeColors.colorShippmentPending};
      };
      svg {
        color: ${themeColors.colorShippmentPending};
      }
    `;

    case 'NotExecuted':
    case 'Rejected':
    case 'ActionRequired':
      return `
      p{
      color: ${themeColors.colorShippmentPending};
      };
      svg {
        color: ${themeColors.colorShippmentPending};
      }
    `;
    case 'Disabled':
      return `
      p{
      color: ${themeColors.colorTextContrast}; 
      };
    svg {
      color: ${themeColors.colorTextContrast};
    }
  `;

    default:
      break;
  }
};

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  max-width: ${pxToRem(226)};
  line-height: 1;
  ${(props) => getStatusContainerStyles(props)}
`;

export const StatusIconWrap = styled.div`
  margin-right: ${pxToRem(8)};

  &,
  svg {
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    &,
    svg {
      width: ${pxToRemMd(16)};
      height: ${pxToRemMd(16)};
    }
  }

  ${mediaBelow(variables.breakpoints.bpSm)} {
    margin-right: ${pxToRemMd(10)};
  }
`;

export const StatusLabel = styled.p`
  ${uTextEllipsis};
  min-width: 0;
  color: ${themeColors.colorTextSecondary};
  font-weight: 500;
  font-size: 14px;
`;

export const ButtonContainer = styled.div``;

const StatusElement = ({ icon, label, variant }) => (
  <StatusContainer variant={variant}>
    <StatusIconWrap>{icon}</StatusIconWrap>
    <StatusLabel>{label}</StatusLabel>
  </StatusContainer>
);

const NagDashboardItemStatus = ({ status, action, buttonText }) => {
  const { t } = useTranslation();
  const statusComponent = (status) => {
    switch (status) {
      case 'Completed':
        return (
          <StatusElement
            variant="Executed"
            label={t('status.complete')}
            icon={<ExecutedIcon />}
          />
        );

      case 'Approved':
        return (
          <StatusElement
            variant="Executed"
            label={t('status.approved')}
            icon={<ExecutedIcon />}
          />
        );

      case 'Pending':
      case 'PendingReview':
        return (
          <StatusElement
            variant="Executed"
            label={t('status.pending')}
            icon={<ExecutedIcon />}
          />
        );

      case 'WaitingForVerification':
        return (
          <StatusElement
            variant="Pending"
            label={t('status.pendingVerification')}
            icon={<PendingIcon />}
          />
        );
      case 'ActionRequired':
        return (
          <StatusElement
            variant="ActionRequired"
            label={t('nagDashboard.verifyAccountBanner.status')}
            icon={<Alert />}
          />
        );

      case 'GetStarted':
      case 'NotUploaded':
      case 'ReuploadNeeded':
      case 'NoDepositMade':
        return (
          <ButtonContainer>
            <Button
              variant="primary"
              onClick={() => action()}
              textTransform="capitalize"
              fontSize="14px"
            >
              {buttonText}
            </Button>
          </ButtonContainer>
        );
      case 'Disabled':
        return (
          <ButtonContainer>
            <Button
              variant="primary"
              color={themeColors.colorButtonDefaultBorder}
              textTransform="capitalize"
              disabled
              fontSize="14px"
            >
              {buttonText}
            </Button>
          </ButtonContainer>
        );
      default:
        return <>{status}</>;
    }
  };
  return statusComponent(status);
};

NagDashboardItemStatus.propTypes = {
  status: PropTypes.string,
  buttonText: PropTypes.string,
  className: PropTypes.string,
};

StatusElement.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string,
  variant: PropTypes.string,
};

export default NagDashboardItemStatus;
