import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { pxToRem } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';

const DalayedSettlementWarningWrap = styled.div`
  background-color: ${themeColors.colorWarningnBackground};
  border-radius: ${variables.borderRadius.borderRadius};
  max-width: ${pxToRem(730)};
  margin-bottom: ${pxToRem(16)};
  padding: ${pxToRem(16)};
`;

const DalayedSettlementWarningNote = styled.div`
  font-weight: bold;
  padding-top: ${pxToRem(10)};
`;

const DelayedSettlementWarning = () => {
  const { t } = useTranslation();

  return (
    <DalayedSettlementWarningWrap>
      {t('buyWizard.verifyOrder.dalayedSettlementWarning')}
      <DalayedSettlementWarningNote>
        {t('buyWizard.verifyOrder.dalyedSettlementWarningNote')}
      </DalayedSettlementWarningNote>
    </DalayedSettlementWarningWrap>
  );
};

DelayedSettlementWarning.propTypes = {};

export default DelayedSettlementWarning;
