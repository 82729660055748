import { getRequest, postRequest, replaceInUrl } from './index';
import apiEndpoints from './apiEndpoints';

export const getProductsWithPrices = (accountUid, side, symbols, productType) =>
  getRequest(
    replaceInUrl(apiEndpoints.products.prices, {
      accountUid,
      side,
      symbols,
      productType,
    }),
    { symbols, productType },
  );

export const getProductTiers = (
  accountUid,
  symbol,
  side,
  location,
  trueTiers = false,
) =>
  getRequest(
    replaceInUrl(apiEndpoints.products.tiers, {
      accountUid,
      symbol,
      side,
      location,
      trueTiers,
    }),
  );

export const getProductSymbolPrices = (accountUid, symbol, side) =>
  getRequest(
    replaceInUrl(apiEndpoints.products.symbolPrices, {
      accountUid,
      symbol,
      side,
    }),
  );

export const verifyDeliverFromStorage = (accountUid, data) =>
  postRequest(
    replaceInUrl(apiEndpoints.orders.deliverFromStorageVerify, {
      accountUid,
    }),
    data,
  );

export const submitDeliverFromStorageRequest = (accountUid, data) =>
  postRequest(
    replaceInUrl(apiEndpoints.orders.deliverFromStorage, {
      accountUid,
    }),
    data,
  );

export const getConvertProductRequest = ({
  accountUid,
  side,
  metalType,
  productType,
}) =>
  getRequest(replaceInUrl(apiEndpoints.products.getPrices, { accountUid }), {
    side,
    metaltype: metalType,
    producttype: productType,
    fractionalConversion: true,
  });
