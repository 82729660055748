import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import useGtmHook from '../../../util/hooks/useGtmHook';
import MetalSlider from '../../MetalSlider/MetalSlider';
import ModalButtons from '../../Modal/ModalButtons';
import Note from '../../Notes/Note';
import { formatMoneyNumeral } from '../../../util/helpers/numeralHelpers';
import Paragraph from '../../Paragraph/Paragraph';
import Label from '../../Notes/Label';
import ModalBody from '../../Modal/ModalBody';
import ModalTitle from '../../Modal/ModalTitle';

const MetalStreamAllocation = ({
  handleBack,
  handleNext,
  wizardData,
  setWizardData,
  productAllocation,
  setProductAllocation,
}) => {
  const { t } = useTranslation();
  const { gtmDialogScreenView } = useGtmHook();

  const handleChangeProductAllocation = (value) => {
    const gold = value;
    const silver = 100 - value;

    setProductAllocation({
      gold,
      silver,
    });

    setWizardData({
      GoldProductConfiguration: {
        Percentage: gold / 100,
      },
      SilverProductConfiguration: {
        Percentage: silver / 100,
      },
    });

    return { gold, silver };
  };

  const handleSliderChange = (handleChange) => {
    const handleGoldChange = handleChange('gold');
    const handleSilverChange = handleChange('silver');

    return (value) => {
      const { gold, silver } = handleChangeProductAllocation(value);

      handleGoldChange(`${gold}`);
      handleSilverChange(`${silver}`);
    };
  };

  const handleInputChange = (field, handleChange) => {
    const handleFieldChange = handleChange(field);
    const handleGoldChange = handleChange('gold');
    const handleSilverChange = handleChange('silver');

    return (value) => {
      if (!value) {
        return handleFieldChange(value);
      }

      const nextValueParsed = Number(value);
      const nextValue =
        field === 'gold' ? nextValueParsed : 100 - nextValueParsed;

      const { gold, silver } = handleChangeProductAllocation(nextValue);
      handleGoldChange(gold.toString());
      handleSilverChange(silver.toString());
    };
  };

  const handleSubmit = (values) => {
    handleChangeProductAllocation(values.gold);
    handleNext();
  };

  useEffect(() => {
    gtmDialogScreenView({
      title: 'MetalStream Set Up - Select Allocation',
    });
  }, []); //eslint-disable-line

  return (
    <ModalBody>
      <ModalTitle>{t('metalStream.selectAllocation')}</ModalTitle>
      <Paragraph marginBottom={24}>
        {t('metalStream.selectProportion')}
      </Paragraph>
      <Label
        marginBottom={4}
        isBold
        text={t('metalStream.monthlyInvestment')}
      />
      <Paragraph marginBottom={32}>
        {formatMoneyNumeral(wizardData?.Amount)}
      </Paragraph>
      <Formik
        initialValues={{
          gold: productAllocation.gold,
          silver: productAllocation.silver,
        }}
        onSubmit={handleSubmit}
      >
        {({ handleChange }) => (
          <Form>
            <MetalSlider
              productAllocation={productAllocation}
              handleSliderChange={handleSliderChange}
              handleChange={handleChange}
              handleInputChange={handleInputChange}
              investmentAmount={wizardData?.Amount}
            />

            <Note text={t('metalStream.pickProducts')} marginTop={16} />
            <ModalButtons
              isHorizontal
              marginTop
              secondaryButtonProps={{ onClick: handleBack }}
              primaryButtonProps={{ type: 'submit' }}
            />
          </Form>
        )}
      </Formik>
    </ModalBody>
  );
};

MetalStreamAllocation.propTypes = {
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  wizardData: PropTypes.shape({
    Amount: PropTypes.number,
    GoldProductConfiguration: PropTypes.shape({
      Percentage: PropTypes.number,
    }),
    SilverProductConfiguration: PropTypes.shape({
      Percentage: PropTypes.number,
    }),
  }),
  defaultValues: PropTypes.shape({
    Amount: PropTypes.number,
    GoldProductConfiguration: PropTypes.shape({
      Percentage: PropTypes.number,
    }),
    SilverProductConfiguration: PropTypes.shape({
      Percentage: PropTypes.number,
    }),
  }),
  setWizardData: PropTypes.func,
  productAllocation: PropTypes.shape({
    gold: PropTypes.number,
    silver: PropTypes.number,
  }),
  setProductAllocation: PropTypes.func,
};

export default MetalStreamAllocation;
