export const percentageMultiply = (value, percentage) => {
  return (value * percentage) / 100;
};

export const valuesMultiply = (firstValue, secondValue) => {
  return firstValue * secondValue;
};

export const calculateQuantity = (maxValue, currentValue) => {
  return maxValue / currentValue;
};
