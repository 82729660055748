import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { uFlexColumn } from '../../assets/styles/utility';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import NagDashboardTableHeader from './NagDashboardTableHeader';
import NagDashboardOpenAccountStep from './NagDashboardOpenAccountStep';
import NagDashboardVerifyAccountStep from './NagDashboardVerifyAccountStep';
import NagDashboardFundAccountStep from './NagDashboardFundAccountStep';
import NagDashboardStorageInvestmentStep from './NagDashboardStorageInvestmentStep';
import NagDashboardShortBanner from './NagDashboardShortBanner';
import {
  retrieveFromSessionStorage,
  storeInSessionStorage,
} from '../../util/helpers/sessionStorageHelper';
import { IS_NAG_DASHBOARD_OPEN } from '../../constants/sessionStorage';

export const NagDashboardTableContainer = styled.div`
  ${uFlexColumn};
  margin-bottom: ${pxToRem(32)};
  min-width: 0;
  &:not(:last-of-type) {
    margin-bottom: ${pxToRem(32)};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    &:not(:last-of-type) {
      margin-bottom: ${pxToRemMd(16)};
    }
  }
`;

export const NagDashboardTableBody = styled.div`
  ${uFlexColumn};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${pxToRem(16)};
`;
const NagDashboardTable = ({
  title,
  setIsDissmised,
  activeStep,
  canDismiss,
  dismissedDasboard,
  accountUid,
}) => {
  const isNagDashboardOpen = retrieveFromSessionStorage(IS_NAG_DASHBOARD_OPEN);
  const [showTableBody, setShowTableBody] = useState(
    isNagDashboardOpen !== null ? isNagDashboardOpen : true,
  );
  const onHeaderClick = () => {
    storeInSessionStorage(IS_NAG_DASHBOARD_OPEN, !showTableBody);
    setShowTableBody(!showTableBody);
  };

  useEffect(() => {
    if (canDismiss) setShowTableBody(true);
  }, [canDismiss]);

  return (
    <NagDashboardTableContainer data-cy="container-quick-start-guide">
      <NagDashboardTableHeader
        title={title}
        onClick={onHeaderClick}
        isOpen={showTableBody}
        canDismiss={canDismiss}
        setIsDissmised={setIsDissmised}
        dismissedDasboard={dismissedDasboard}
        accountUid={accountUid}
        setIsOpen={setShowTableBody}
      />
      {showTableBody ? (
        <NagDashboardTableBody>
          <NagDashboardOpenAccountStep activeStep={activeStep} />
          <NagDashboardVerifyAccountStep activeStep={activeStep} />
          <NagDashboardFundAccountStep activeStep={activeStep} />
          <NagDashboardStorageInvestmentStep activeStep={activeStep} />
        </NagDashboardTableBody>
      ) : (
        <NagDashboardShortBanner isShortFlow activeStep={activeStep} />
      )}
    </NagDashboardTableContainer>
  );
};

NagDashboardTable.propTypes = {
  title: PropTypes.string,
  setIsDissmised: PropTypes.func,
  activeStep: PropTypes.number,
  canDismiss: PropTypes.bool,
  dismissedDasboard: PropTypes.func,
  accountUid: PropTypes.string,
};

export default NagDashboardTable;
