import { startCase } from 'lodash';
import {
  ADD_TO_CART,
  PURCHASE,
  SELECT_ITEM,
  TRADE_EXECUTED,
} from '../../constants/gtmEvents';
import { accountTypeKeys } from '../enum/api/accountTypes';
import { productLocationTypes } from '../enum/api/productTypes';
import { parseEnumType } from './enumMappers';
import { formatMoneyNumeral, formatNumber } from './numeralHelpers';

export const orderGtmHelper = (
  gtmDialogScreenView,
  gtmCustomEvent,
  values,
  wizardData,
  activeType,
  unitPrice,
  currentAccount,
  isForDeliver,
) => {
  if (!wizardData) {
    return;
  }

  const { storageProduct, selectedVault } = wizardData;

  gtmDialogScreenView({
    title: isForDeliver
      ? 'Buy for Delivery - Verify Order'
      : 'Buy for Storage - Verify Order',
    path: window.location.pathname,
    productName: storageProduct.ProductCaption,
    location: startCase(
      parseEnumType(productLocationTypes, selectedVault?.LocationType),
    ),
    total:
      activeType === 'Quantity'
        ? values?.QuantityAmount
        : formatMoneyNumeral(values?.CashAmount),
  });

  gtmCustomEvent(ADD_TO_CART, {
    ecommerce: {
      value:
        activeType === 'Quantity'
          ? formatNumber(unitPrice * values?.QuantityAmount)
          : formatNumber(values?.CashAmount),
      currency: currentAccount?.CurrencyIso3Code,
      items: [
        {
          item_name: storageProduct.ProductCaption,
          price: formatMoneyNumeral(unitPrice),
          quantity:
            activeType === 'Quantity'
              ? values?.QuantityAmount
              : Math.floor(values?.CashAmount / unitPrice),
        },
      ],
    },
  });
};

export const onConfirmOrderGtmHelper = ({
  gtmEvent,
  gtmCustomEvent,
  wizardData,
  finalOrder,
  account,
  isForDelivery,
  orderCode,
}) => {
  const { storageProduct, selectedVault, orderType } = wizardData;

  gtmCustomEvent(PURCHASE, {
    ecommerce: {
      value:
        orderType === 'Cash'
          ? finalOrder.estimatedTotal
          : finalOrder.requiredBalanceWithBuffer,
      currency: account?.CurrencyIso3Code,
      transaction_id: orderCode,
      items: [
        {
          item_name: storageProduct.ProductCaption,
          price: finalOrder?.pricePerUnit || finalOrder?.unitPrice,
          quantity: finalOrder.quantity,
        },
      ],
    },
  });

  if (isForDelivery) {
    gtmEvent(TRADE_EXECUTED, {
      account_type: parseEnumType(accountTypeKeys, account.AccountType),
      terminal_point_type: 'Delivery',
      direction_type: 'In',
      symbol: selectedVault.SymbolCode,
      order_side_type: 'Buy',
      quantity_executed: finalOrder.quantity,
      order_subtotal: finalOrder.subtotal,
      shipping_fee: finalOrder.deliveryCosts,
      sales_tax: finalOrder.salesTax,
      order_total:
        orderType === 'Cash'
          ? finalOrder.estimatedTotal
          : finalOrder.requiredBalanceWithBuffer,
    });
  } else {
    gtmEvent(TRADE_EXECUTED, {
      account_type: parseEnumType(accountTypeKeys, account.AccountType),
      terminal_point_type: 'Storage',
      direction_type: 'In',
      symbol: selectedVault.SymbolCode,
      order_side_type: 'Buy',
      quantity_executed: finalOrder.quantity,
      order_subtotal: finalOrder.estimatedTotal,
      order_total:
        orderType === 'Cash'
          ? finalOrder.estimatedTotal
          : finalOrder.requiredBalanceWithBuffer,
    });
  }
};

export const ecommerceGtmHelper = ({
  ecommerceGtmEvent,
  // eventName,
  wizardData,
  orderSideType,
  account,
  unitPrice,
  isForDelivery,
}) => {
  const { storageProduct, selectedVault } = wizardData;

  ecommerceGtmEvent(SELECT_ITEM, {
    ecommerce: {
      value: parseFloat(unitPrice?.toFixed(2)),
      trade_type: orderSideType,
      currency: account?.CurrencyIso3Code,
      items: [
        {
          item_name: storageProduct?.ProductCaption,
          price: parseFloat(unitPrice?.toFixed(2)),
          item_id: selectedVault?.SymbolCode,
          delivery_type: isForDelivery ? 'ship' : 'vault',
          vault_location: startCase(
            parseEnumType(productLocationTypes, selectedVault?.LocationType),
          ),
        },
      ],
    },
  });
};

export const onConfirmOrderEcommerceGtmHelper = ({
  ecommerceGtmEvent,
  // eventName,
  wizardData,
  orderSideType,
  // orderCode,
  account,
  unitPrice,
  activeType,
  values,
  isForDelivery,
}) => {
  const { storageProduct, selectedVault } = wizardData;
  ecommerceGtmEvent(ADD_TO_CART, {
    ecommerce: {
      // transaction_id: orderCode || '',
      value:
        activeType === 'Quantity'
          ? parseFloat((unitPrice * values?.QuantityAmount)?.toFixed(2))
          : parseFloat(values?.CashAmount?.toFixed(2)),
      trade_type: orderSideType,
      currency: account?.CurrencyIso3Code,
      items: [
        {
          item_name: storageProduct.ProductCaption,
          price: parseFloat(unitPrice?.toFixed(2)),
          item_id: selectedVault.SymbolCode,
          delivery_type: isForDelivery ? 'ship' : 'vault',
          buy_type: activeType,
          quantity:
            activeType === 'Quantity'
              ? parseFloat(values?.QuantityAmount)
              : parseFloat(Math.floor(values?.CashAmount / unitPrice)),
          vault_location: startCase(
            parseEnumType(productLocationTypes, selectedVault?.LocationType),
          ),
        },
      ],
    },
  });
};

export const verifyOrderEcommerceGtmHelper = ({
  ecommerceGtmEvent,
  // eventName,
  wizardData,
  finalOrder,
  account,
  orderSideType,
  quantity,
  isForDelivery,
  orderCode,
}) => {
  const { storageProduct, selectedVault, orderType } = wizardData;

  ecommerceGtmEvent(PURCHASE, {
    ecommerce: {
      transaction_id: orderCode || '',
      value:
        orderType === 'Cash'
          ? parseFloat(finalOrder.estimatedTotal)
          : parseFloat(finalOrder.requiredBalanceWithBuffer),
      trade_type: orderSideType,
      currency: account?.CurrencyIso3Code,
      items: [
        {
          item_name: storageProduct.ProductCaption,
          price:
            parseFloat(finalOrder?.pricePerUnit?.toFixed(2)) ||
            parseFloat(finalOrder?.unitPrice?.toFixed(2)),
          item_id: selectedVault.SymbolCode,
          delivery_type: isForDelivery ? 'ship' : 'vault',
          buy_type: orderType,
          quantity,
          vault_location: startCase(
            parseEnumType(productLocationTypes, selectedVault?.LocationType),
          ),
        },
      ],
    },
  });
};

export const sellGtmHelper = ({
  ecommerceGtmEvent,
  product,
  vault,
  orderSideType,
  account,
}) => {
  ecommerceGtmEvent(SELECT_ITEM, {
    ecommerce: {
      value: parseFloat(vault?.PricePerUnit?.toFixed(2)),
      trade_type: orderSideType,
      currency: account?.CurrencyIso3Code,
      items: [
        {
          item_name: product?.ProductCaption,
          price: parseFloat(vault?.PricePerUnit?.toFixed(2)),
          item_id: product?.SymbolCode,
          vault_location: startCase(
            parseEnumType(productLocationTypes, product?.LocationType),
          ),
        },
      ],
    },
  });
};

export const onConfirmSellGtmHelper = ({
  ecommerceGtmEvent,
  eventName,
  product,
  vault,
  orderSideType,
  account,
  values,
}) => {
  ecommerceGtmEvent(eventName, {
    ecommerce: {
      value: parseFloat((values.quantity * vault.PricePerUnit)?.toFixed(2)),
      trade_type: orderSideType,
      currency: account?.CurrencyIso3Code,
      items: [
        {
          item_name: product?.ProductCaption,
          price: parseFloat(vault?.PricePerUnit?.toFixed(2)),
          item_id: product?.SymbolCode,
          quantity: parseFloat(values.quantity),
          vault_location: startCase(
            parseEnumType(productLocationTypes, product?.LocationType),
          ),
        },
      ],
    },
  });
};

export const verifySellGtmHelper = ({
  ecommerceGtmEvent,
  eventName,
  reviewProduct,
  product,
  vault,
  account,
  orderSideType,
}) => {
  ecommerceGtmEvent(eventName, {
    ecommerce: {
      value: parseFloat(
        (reviewProduct?.Quantity * vault?.PricePerUnit)?.toFixed(2),
      ),
      trade_type: orderSideType,
      currency: account?.CurrencyIso3Code,
      items: [
        {
          item_name: product?.ProductCaption,
          price: parseFloat(vault?.PricePerUnit?.toFixed(2)),
          item_id: product?.SymbolCode,
          quantity: parseFloat(reviewProduct?.Quantity),
          vault_location: startCase(
            parseEnumType(productLocationTypes, product?.LocationType),
          ),
        },
      ],
    },
  });
};

export const deliveryGtmHelper = ({
  ecommerceGtmEvent,
  eventName,
  product,
  account,
}) => {
  ecommerceGtmEvent(eventName, {
    ecommerce: {
      trade_type: 'Delivery',
      currency: account?.CurrencyIso3Code,
      items: [
        {
          item_name: product?.ProductCaption,
          item_id: product?.SymbolCode,
          vault_location: startCase(
            parseEnumType(productLocationTypes, product?.LocationType),
          ),
        },
      ],
    },
  });
};

export const onConfirmDeliveryGtmHelper = ({
  ecommerceGtmEvent,
  product,
  getDeliveryTotal,
  account,
  quantity,
  deliveryLocation,
}) => {
  ecommerceGtmEvent(PURCHASE, {
    ecommerce: {
      value: parseFloat(getDeliveryTotal?.toFixed(2)),
      trade_type: 'Delivery',
      currency: account?.CurrencyIso3Code,
      items: [
        {
          item_name: product?.ProductCaption,
          price: parseFloat(getDeliveryTotal?.toFixed(2)),
          item_id: product?.SymbolCode,
          quantity,
          vault_location: startCase(
            parseEnumType(productLocationTypes, product?.LocationType),
          ),
          delivery_location: deliveryLocation,
        },
      ],
    },
  });
};
