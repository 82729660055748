import React from 'react';
import ReactCodeInput from 'react-code-input';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectSettings } from '../../store/selectors/settingsSelectors';
import Note from '../Notes/Note';
import Paragraph from '../Paragraph/Paragraph';
import { uFlexColumn } from '../../assets/styles/utility';
import { mediaBelow, pxToRem } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';

export const CodeFieldContainer = styled.div`
  ${uFlexColumn};
  align-items: center;
  width: 100%;

  .react-code-input {
    display: grid !important;
    width: 100% !important;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: ${pxToRem(8)};

    > input {
      width: 100% !important;
      height: 49px !important;
      border: 1px solid ${themeColors.colorInputBorder} !important;
      border-radius: 4px !important;
      text-align: center !important;
      padding: 0 !important;
      font-family: ${variables.family.regular} !important;
      box-shadow: none !important;
      color: ${themeColors.colorTextPrimary} !important;
      outline: none !important;
      margin: 0 !important;

      &:disabled {
        background-color: ${
          themeColors.colorInputBackgroundDisabled
        } !important;
        border-color: ${themeColors.colorInputBorder} !important;
      }

      &:focus {
        border-color: ${themeColors.colorPrimary} !important;
      }
    }
  }

  ${({ hasError }) =>
    hasError &&
    `.react-code-input {
      > input {
        border-color: ${themeColors.colorError} !important;
      }
    }
  `};

  ${({ fixedSize }) =>
    fixedSize &&
    `
    .react-code-input {
      grid-template-columns: repeat(6, 49px);
      grid-gap: ${pxToRem(24)};
      width: auto !important;

      > input {
        height: 49px !important;
      }
    }
  `};

  
}

  ${mediaBelow(400)} {
    .react-code-input {
      grid-gap: ${pxToRem(12)};
    }
  }

  ${mediaBelow(480)} {
    .react-code-input {
      grid-template-columns: repeat(3, 49px);
      grid-template-rows: repeat(2, 49px);
    }
  }
`;

const CodeField = ({
  onCodeChange,
  errors,
  hideExpireCodeNote,
  isEmail,
  fixedSize,
  name,
  ...props
}) => {
  const { t } = useTranslation();
  const settings = useSelector(selectSettings);
  const emailCodeDuration = settings?.EmailSecurityCodeDurationMinutes;

  return (
    <>
      <CodeFieldContainer
        hasError={!isEmpty(errors)}
        fixedSize={fixedSize}
        {...props}
      >
        <ReactCodeInput
          fields={6}
          type="number"
          {...props}
          onChange={(pinCode) => {
            onCodeChange(pinCode);
          }}
        />
        {errors[name] && (
          <Paragraph marginTop={8} isError>
            {errors[name] === t('apiErrors.WrongSecurityCode') ||
            errors[name] === t('apiErrors.securityQuestionWrong') ||
            errors[name] === t('apiErrors.SecurityCodeNotFound') ? (
              <>
                <span>{t('apiErrors.TwoFaCodeExpired')}</span>
                <span>{t('apiErrors.TwoFaCodeCheck')}</span>
              </>
            ) : (
              errors[name]
            )}
          </Paragraph>
        )}
        {!hideExpireCodeNote && (
          <Note
            marginTop={16}
            text={
              isEmail
                ? t('twoFa.loginVerify.codeExpiresEmail', {
                    emailCodeDuration,
                  })
                : t('twoFa.loginVerify.codeExpires')
            }
          />
        )}
      </CodeFieldContainer>
    </>
  );
};

CodeField.propTypes = {
  onCodeChange: PropTypes.func,
  errors: PropTypes.shape({}),
  name: PropTypes.string,
  isEmail: PropTypes.string,
  hideExpireCodeNote: PropTypes.bool,
  fixedSize: PropTypes.bool,
};

export default CodeField;
