import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropType from 'prop-types';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { ReactComponent as ChevronLeft } from '../../assets/images/svg/chevron-left.svg';
import { ReactComponent as Close } from '../../assets/images/svg/close.svg';
import IconButton from '../IconButton/IconButton';
import SectionLoader from '../Loader/SectionLoader';
import { closeWizardContent } from '../../util/helpers/wizardHelpers';
import { useOutsideClickHandler } from '../../util/hooks/useOutsideClickHandler';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { uFlexColumn, uTextEllipsis } from '../../assets/styles/utility';
import { variables } from '../../assets/styles/variables';
import themeColors from '../../assets/styles/themeColors';

const headerHeightDesktop = pxToRem(80);
const headerHeightMobile = pxToRemMd(74);

const getModalSize = (size) => {
  if (size === 'sm') {
    return `
    max-width: ${pxToRem(390)};
    width: 100%;

  ${mediaBelow(variables.breakpoints.bpMd)} {
      margin: ${headerHeightMobile} auto ${headerHeightMobile};
      max-height: calc(100vh - (2 * ${headerHeightMobile}));
  }`;
  }

  if (size === 'md') {
    return `
    max-width: ${pxToRem(521)};
     width: 100%;
   

   ${mediaBelow(variables.breakpoints.bpMd)} {
       margin: ${headerHeightMobile} auto ${headerHeightMobile};
       max-height: calc(100vh - (2 * ${headerHeightMobile}));
   }`;
  }

  if (size === 'lg') {
    return `
        max-width: ${pxToRem(782)};
        width: 100%;`;
  }
};

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${variables.zIndex.indexXl};
  background-color: ${themeColors.colorModalBackground};
  ${(props) => props.css};
  ${({ overModal }) =>
    overModal &&
    `
      background-color: transparent;
      z-index: ${variables.zIndex.indexXxl};
    `};
`;

export const ModalElement = styled.div`
  ${uFlexColumn};
  box-shadow: ${variables.shadow.boxShadow};
  border-radius: ${variables.borderRadius.borderRadius};
  background-color: ${themeColors.colorWhite};
  margin: ${headerHeightDesktop} auto ${headerHeightDesktop};
  max-height: calc(100vh - (2 * ${headerHeightDesktop}));
  position: relative;

  ${({ size }) => getModalSize(size)}

  ${mediaBelow(variables.breakpoints.bpMd)} {
    border-radius: 0;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    max-width: 100vw;
    margin: 0;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  box-shadow: ${variables.shadow.boxShadow};
  z-index: ${variables.zIndex.indexXxs};
  padding: ${pxToRem(12)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(12)} ${pxToRemMd(16)};
  }
`;

export const ModalClose = styled(IconButton)`
  &,
  svg {
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    &,
    svg {
      width: ${pxToRemMd(24)};
      height: ${pxToRemMd(24)};
    }
  }
`;

export const ModalBack = styled(ModalClose)`
  margin-right: ${pxToRem(10)};
`;

export const ModalBodyWrap = styled.div`
  ${uFlexColumn}
  flex: 1 1 auto;
  overflow: auto;
`;

export const ModalTitle = styled.h3`
  ${uTextEllipsis};
  font-size: ${pxToRem(16)};
  font-weight: 600;
  line-height: 1.5;
  padding-right: ${pxToRem(10)};
  margin-right: auto;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(16)};
  }
`;

const Modal = ({
  isOpen,
  children,
  goBack,
  close,
  title,
  size,
  isLoading,
  overModal,
  avoidClearingStorage,
  handleOutsideClick,
  skipListeningToLocations,
  dataCy,
}) => {
  const history = useHistory();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.removeAttribute('style');
    };
  }, [isOpen]);

  const closeModal = () => {
    if (!avoidClearingStorage) {
      closeWizardContent();
    }

    if (typeof close === 'function') {
      close();
    }
  };

  useEffect(() => {
    if (history && !skipListeningToLocations) {
      return history.listen(() => closeModal());
    }

    return () => {
      if (history) {
        history.listen(() => undefined);
      }
    };
  }, []); //eslint-disable-line

  const modalRef = useRef(null);

  useOutsideClickHandler(modalRef, close, handleOutsideClick);

  return isOpen
    ? ReactDOM.createPortal(
        <ModalContainer overModal={overModal} isOpen={isOpen}>
          <ModalElement size={size} ref={modalRef} data-cy={dataCy}>
            <SectionLoader isLoading={isLoading}>
              <ModalHeader>
                {goBack && (
                  <ModalBack onClick={() => goBack()}>
                    <ChevronLeft />
                  </ModalBack>
                )}
                {title && <ModalTitle>{title}</ModalTitle>}
                {close && (
                  <ModalClose onClick={closeModal} data-cy="button-close-modal">
                    <Close />
                  </ModalClose>
                )}
              </ModalHeader>
              <ModalBodyWrap>{children}</ModalBodyWrap>
            </SectionLoader>
          </ModalElement>
        </ModalContainer>,
        document.getElementById('root'),
      )
    : null;
};

Modal.propTypes = {
  children: PropType.node,
  isOpen: PropType.oneOfType([PropType.bool, PropType.shape({})]),
  goBack: PropType.oneOfType([PropType.func, PropType.bool]),
  close: PropType.func,
  title: PropType.string,
  size: PropType.oneOf(['sm', 'md', 'lg']),
  className: PropType.string,
  isLoading: PropType.bool,
  avoidClearingStorage: PropType.bool,
  handleOutsideClick: PropType.bool,
  skipListeningToLocations: PropType.bool,
  dataCy: PropType.string,
};

export default Modal;
