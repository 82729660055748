import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

export const useTransactionPagination = (transactions, pageNumber) => {
  const [transactionList, setTransactionList] = useState([]);

  useEffect(() => {
    if (!isEmpty(transactions)) {
      if (pageNumber === 1) {
        setTransactionList([...transactions]);
        return;
      }

      setTransactionList((items) => [...items, ...transactions]);
      return;
    }

    setTransactionList([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions]);

  return [transactionList];
};
