import {
  CLEAR_SELL_WIZARD_STATE,
  SELL_PRODUCT_FROM_STORAGE,
  SELL_PRODUCT_FROM_STORAGE_ERROR,
  SELL_PRODUCT_FROM_STORAGE_SUCCESS,
} from './sellWizardActionConstants';

export function clearSellWizardState() {
  return {
    type: CLEAR_SELL_WIZARD_STATE,
  };
}

export function sellProductFromStorage(payload) {
  return {
    type: SELL_PRODUCT_FROM_STORAGE,
    payload,
  };
}

export function sellProductFromStorageSuccess(payload) {
  return {
    type: SELL_PRODUCT_FROM_STORAGE_SUCCESS,
    payload,
  };
}

export function sellProductFromStorageError(payload) {
  return {
    type: SELL_PRODUCT_FROM_STORAGE_ERROR,
    payload,
  };
}
