import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as Pending } from '../../assets/images/svg/pending.svg';
import { ReactComponent as Alert } from '../../assets/images/svg/alert-triangle.svg';
import { uFlexColumn } from '../../assets/styles/utility';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';
import Button from '../Button/Button';

const getBannerContainerVariantStyles = () =>
  `background-color: ${themeColors.colorPendingBannerBackground};`;

const getBannerIconVariantStyles = (variant) => {
  switch (variant) {
    case 'pending':
      return themeColors.colorPrimary;

    case 'danger':
      return themeColors.colorRed;

    default:
      break;
  }
};

export const BannerContainer = styled.div`
  ${uFlexColumn};
  padding: ${pxToRem(24)} 3.25rem;
  ${({ variant }) => getBannerContainerVariantStyles(variant)};

  button {
    margin-top: 8px;
    width: max-content;
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: ${pxToRemMd(24)};
  }
`;

export const BannerText = styled.p`
  font-size: 18px;
  line-height: 1.5;
  font-weight: 600;
  display: flex;
  align-items: center;
`;

export const BannerSubText = styled.p`
  color: ${themeColors.colorTextSecondary};
`;

export const BannerStatus = styled.span`
  color: ${({ variant }) => getBannerIconVariantStyles(variant)};
  font-size: ${pxToRem(17)};
  display: flex;
  align-items: center;
  flex-shrink: 0;

  svg {
    width: ${pxToRem(16)};
    margin: 0 ${pxToRem(8)};
    color: ${({ variant }) => getBannerIconVariantStyles(variant)};
  }
`;

const Banner = ({
  className,
  variant,
  text,
  subtext,
  onClick,
  buttonLabel,
  ...props
}) => {
  const renderStatus = () => {
    if (!variant) {
      return;
    }

    if (variant === 'pending') {
      return (
        <>
          <Pending />
          Pending
        </>
      );
    }

    if (variant === 'danger') {
      return (
        <>
          <Alert />
          Action Required
        </>
      );
    }
  };

  return (
    <BannerContainer {...props}>
      {text && (
        <BannerText>
          <span>{text}</span>
          <BannerStatus variant={variant}>{renderStatus()}</BannerStatus>
        </BannerText>
      )}
      {subtext && <BannerSubText>{subtext}</BannerSubText>}
      {onClick && buttonLabel && (
        <Button onClick={onClick} type="button" variant="primary">
          {buttonLabel}
        </Button>
      )}
    </BannerContainer>
  );
};

Banner.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  subtext: PropTypes.string,
  variant: PropTypes.oneOf(['polite', 'danger', 'pending']),
  onClick: PropTypes.func,
  buttonLabel: PropTypes.string,
};

export default Banner;
