import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';
import NagDashboardItemStatus from './NagDashboardItemStatus';
import { ReactComponent as Lock } from '../../assets/images/svg/lock.svg';
import { uTextEllipsis } from '../../assets/styles/utility';
import NagDashboardUploadDocumentsModal from './NagDashbordUploadDocumentsModal';
import useWindowSize from '../../util/hooks/useIsMobileHook';

export const NagDashboardTableRowContent = styled.div`
  ${({ isMobile, displayStatusInline }) =>
    isMobile &&
    displayStatusInline &&
    `grid-template-columns: auto;
display:flex;
flex-direction:row;
justify-content:space-between;
width:100%;
`}
  ${({ isShortFlow }) =>
    isShortFlow &&
    `
flex-direction:column;
`}
${({ isMobile }) =>
    !isMobile &&
    `padding: ${pxToRem(24)};
`}
  margin-right: auto;
  text-align: left;
  font-weight: 600;
`;

export const NagDashboardTableRowDescription = styled.span`
  font-size: ${pxToRem(14)};
  font-weight: 400;
  color: ${themeColors.colorTextSecondary};
  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(14)};
  }
`;

export const NagDashboardTableRowContainer = styled.div`
  border-radius: ${variables.borderRadius.borderRadius};
  background-color: ${themeColors.colorBackgroundSecondary};
  width: 100%;
  ${({ isActive }) =>
    isActive &&
    `
  background-color: ${themeColors.colorPendingBannerBackground};
  
`}
  align-items: center;
  line-height: 1.35;
  letter-spacing: 0;
  text-decoration: none;
  ${({ isMobile }) =>
    !isMobile &&
    `display: grid;
 grid-template-columns: 1fr auto ${pxToRem(16)};
grid-gap: ${pxToRem(8)};
`}

  ${mediaBelow(variables.breakpoints.bpXl)} {
    ${({ isMobile }) =>
      !isMobile &&
      `    display: grid;
     flex-direction: column;
    `}
    padding: ${pxToRemMd(10)} ${pxToRemMd(16)};
    font-size: ${pxToRemMd(14)};
    line-height: 1.7;
    grid-template-columns: 1fr auto;
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    ${({ isMobile }) =>
      !isMobile &&
      `    grid-template-columns: auto;
    `}
  }
`;

export const NagDashboardTableRowTitle = styled.div`
  font-weight: 700;
  color: ${themeColors.colorTextPrimary};
  margin-bottom: ${pxToRem(8)};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    margin-bottom: ${pxToRemMd(8)};
  }
`;

export const NagDashboardTableRowStatusContainer = styled.div`
  display: flex;
  align-items: center;
  line-height: 1;
  min-width: 0;
  margin-bottom: ${pxToRem(8)};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    ${({ isLock }) =>
      isLock ? `flex-direction: row;` : `flex-direction: column;`}
  }
`;

export const NagDashboardTableRowStatusIconWrap = styled.div`
  margin-left: ${pxToRem(8)};
  svg {
    color: ${themeColors.colorTextPrimary};
  }

  &,
  svg {
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    &,
    svg {
      width: ${pxToRemMd(16)};
      height: ${pxToRemMd(16)};
    }
  }

  ${mediaBelow(variables.breakpoints.bpSm)} {
    margin-right: ${pxToRemMd(10)};
  }
`;
export const NagDashboardTableRowStatusTitle = styled.div`
  font-weight: 700;
  font-size: ${pxToRem(16)};
  color: ${themeColors.colorTextPrimary};
  min-width: 0;
`;

export const NagDashboardTableRowUploadedStatusText = styled.div`
  font-weight: 400;
  color: ${themeColors.colorTextPrimary};
  min-width: 0;
  ${({ isMobile }) =>
    !isMobile &&
    `
  margin-left: ${pxToRem(10)};
`}
  ${({ isMobile }) =>
    isMobile &&
    `
margin-top: ${pxToRem(10)};
`}
`;

export const NagDashboardVerifyAccountBannerContainer = styled.p`
  border-radius: ${variables.borderRadius.borderRadius};
  background-color: ${themeColors.colorPendingBannerBackground};
  cursor: pointer;
  ${({ isDefault }) =>
    isDefault &&
    `
  background-color: ${themeColors.colorPendingBannerBackground};
`}
  ${({ isPendingVerificationStatus }) =>
    isPendingVerificationStatus &&
    `
    &:nth-child(3) {
        background-color: ${themeColors.colorWhite};
      }
      &:nth-child(4) {
        background-color: ${themeColors.colorWhite};
      }
      &:nth-child(5) {
        background-color: ${themeColors.colorWhite};
      }
`}
  
  line-height: 1.35;
  letter-spacing: 0;
  cursor: pointer;
  text-decoration: none;
  display: grid;
  grid-template-columns: 1fr auto ${pxToRem(16)};
  grid-gap: ${pxToRem(8)};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    display: grid;
    flex-direction: row;
    padding: ${pxToRemMd(10)} ${pxToRemMd(16)};
    font-size: ${pxToRemMd(14)};
    line-height: 1.7;
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    grid-template-columns: auto;
  }
`;

export const NagDashboardVerifyAccountBannerContent = styled.p`
  ${({ isMobile }) =>
    isMobile &&
    `    grid-template-columns: auto;
       justify-content: space-between;
       padding: ${pxToRem(24)};
  `};
  ${({ isMobile }) =>
    !isMobile &&
    `    margin-right: auto;
  `}
  text-align: left;
  font-weight: 600;
`;

export const NagDashboardVerifyAccountBannerTitle = styled.div`
  font-weight: 700;
  color: ${themeColors.colorTextPrimary};
  margin-bottom: ${pxToRem(8)};
  ${mediaBelow(variables.breakpoints.bpXl)} {
    margin-bottom: ${pxToRem(8)};
  }
`;

export const NagDashboardVerifyAccountBannerDescription = styled.span`
  font-size: ${pxToRem(14)};
  font-weight: 400;
  color: ${themeColors.colorTextSecondary};
  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(14)};
  }
`;

export const NagDashboardVerifyAccountStatusContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 0;
  max-width: ${pxToRem(852)};
  margin-bottom: ${pxToRem(8)};
  ${({ isMobile }) =>
    isMobile &&
    `   flex-direction:row;
  `};
  ${mediaBelow(variables.breakpoints.bpXl)} {
    flex-direction: column;
  }
`;

export const NagDashboardVerifyAccountStatusIconWrap = styled.div`
  margin-right: ${pxToRem(4)};
  margin-left: ${pxToRem(10)};
  svg {
    color: ${themeColors.colorShippmentFailed};
  }

  &,
  svg {
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    &,
    svg {
      width: ${pxToRemMd(16)};
      height: ${pxToRemMd(16)};
    }
  }

  ${mediaBelow(variables.breakpoints.bpSm)} {
    margin-right: ${pxToRemMd(10)};
  }
`;

export const NagDashboardVerifyAccountStatusLabel = styled.p`
  ${uTextEllipsis};
  min-width: 0;
  color: ${themeColors.colorShippmentFailed};
  font-weight: 500;
`;

export const NagDashboardVerifyAccountStatusTitle = styled.p`
  font-weight: 700;
  color: ${themeColors.colorTextPrimary};
  min-width: 0;
`;
export const NagDashboardVerifyAccountStatusUploadLabel = styled.div`
  font-weight: 400;
  color: ${themeColors.colorTextPrimary};
  margin-left: ${pxToRem(10)};
  min-width: 0;
  ${({ isMobile }) =>
    !isMobile &&
    `     margin-left: ${pxToRem(10)};
  `};
  ${mediaBelow(variables.breakpoints.bpXl)} {
    margin-left: 0;
    margin-top: ${pxToRem(8)};
  }
`;

export const ButtonContainer = styled.div`
  ${mediaBelow(variables.breakpoints.bpSm)} {
    max-width: ${pxToRem(300)};
  }
`;
export const NagDashboardVerifyStatusContainer = styled.div`
  margin-left: ${pxToRem(16)};
`;
export const NagDashboardTitleContainer = styled.div`
  ${({ isMobile }) =>
    !isMobile &&
    `  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr;
  ${mediaBelow(variables.breakpoints.bpXxs, variables.breakpoints.bpXl)} {
    grid-template-columns: 0.5fr 0.5fr 1fr;
  }
`};

  ${({ isMobile }) =>
    isMobile &&
    `  display: flex;
    flex-direction:row;
    justify-content:space-between;

}
`};
`;

export const NagDashboardPendingStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${mediaBelow(variables.breakpoints.bpXl)} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const NagDashboardTableRow = ({
  title,
  buttonText,
  description,
  status,
  action,
  isActive,
  isPendingVerificationStatus,
  isShortFlow,
  activeStep,
}) => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState();
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 1200;
  const displayStatusInline =
    status === 'Completed' ||
    status === 'Pending' ||
    status === 'ActionRequired' ||
    status === 'RequiresAttention';

  const getPendingAccountTitle = () => (
    <NagDashboardTableRowStatusContainer isLock>
      <NagDashboardPendingStatusContainer>
        <NagDashboardTableRowStatusTitle>
          {title}
        </NagDashboardTableRowStatusTitle>
      </NagDashboardPendingStatusContainer>
    </NagDashboardTableRowStatusContainer>
  );
  const getActionRequiredAccountTitle = () => {
    if (isShortFlow) {
      return (
        <NagDashboardVerifyAccountStatusContainer isMobile={isMobile}>
          {windowSize.width < variables.breakpoints.bpMd ? (
            <>
              <NagDashboardTitleContainer isMobile={isMobile}>
                <NagDashboardVerifyAccountStatusTitle>
                  {title}
                </NagDashboardVerifyAccountStatusTitle>
                <NagDashboardVerifyStatusContainer>
                  <NagDashboardItemStatus
                    status={status}
                    buttonText={buttonText}
                    action={action}
                  />
                </NagDashboardVerifyStatusContainer>
              </NagDashboardTitleContainer>
            </>
          ) : (
            <NagDashboardTitleContainer>
              <NagDashboardVerifyAccountStatusTitle>
                {title}
              </NagDashboardVerifyAccountStatusTitle>
            </NagDashboardTitleContainer>
          )}
        </NagDashboardVerifyAccountStatusContainer>
      );
    }
    return (
      <NagDashboardTableRowStatusContainer>
        <NagDashboardTableRowStatusTitle>
          {title}
        </NagDashboardTableRowStatusTitle>
      </NagDashboardTableRowStatusContainer>
    );
  };

  const renderTitleBasedOnStatus = () => {
    switch (status) {
      case 'Disabled':
        return (
          <NagDashboardTableRowStatusContainer isLock>
            <NagDashboardTableRowStatusTitle>
              {title}
            </NagDashboardTableRowStatusTitle>
            <NagDashboardTableRowStatusIconWrap>
              <Lock />
            </NagDashboardTableRowStatusIconWrap>
          </NagDashboardTableRowStatusContainer>
        );
      case 'ActionRequired':
        return getActionRequiredAccountTitle();
      case 'Pending':
        return getPendingAccountTitle();
      default:
        return <NagDashboardTableRowTitle>{title}</NagDashboardTableRowTitle>;
    }
  };

  return (
    <>
      {isMobile && displayStatusInline && !isShortFlow ? (
        <NagDashboardTableRowContainer
          isActive={isActive}
          isPendingVerificationStatus={isPendingVerificationStatus}
          isMobile={isMobile}
        >
          <NagDashboardTableRowContent
            isMobile={isMobile}
            displayStatusInline={displayStatusInline}
            isShortFlow={isShortFlow}
          >
            {renderTitleBasedOnStatus()}

            <NagDashboardItemStatus
              status={status}
              buttonText={buttonText}
              action={action}
            />
          </NagDashboardTableRowContent>
          <NagDashboardTableRowDescription>
            {description}
          </NagDashboardTableRowDescription>
          {isUploadModalOpen && (
            <NagDashboardUploadDocumentsModal
              isOpen={isUploadModalOpen}
              close={() => setIsUploadModalOpen(false)}
            />
          )}
        </NagDashboardTableRowContainer>
      ) : (
        <NagDashboardTableRowContainer
          isActive={isActive}
          isPendingVerificationStatus={isPendingVerificationStatus}
        >
          <NagDashboardTableRowContent
            isMobile={isMobile}
            displayStatusInline={displayStatusInline}
            isShortFlow={isShortFlow}
          >
            {renderTitleBasedOnStatus()}
            <NagDashboardTableRowDescription>
              {description}
            </NagDashboardTableRowDescription>
          </NagDashboardTableRowContent>

          <NagDashboardItemStatus
            status={status}
            buttonText={buttonText}
            action={action}
          />

          {isUploadModalOpen && (
            <NagDashboardUploadDocumentsModal
              isOpen={isUploadModalOpen}
              close={() => setIsUploadModalOpen(false)}
              activeStep={activeStep}
            />
          )}
        </NagDashboardTableRowContainer>
      )}
    </>
  );
};

NagDashboardTableRow.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string,
  description: PropTypes.number,
  status: PropTypes.string,
  action: PropTypes.func,
  isPendingVerificationStatus: PropTypes.bool,
  isShortFlow: PropTypes.bool,
  isActive: PropTypes.bool,
  activeStep: PropTypes.string,
};

export default NagDashboardTableRow;
