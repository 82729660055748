import styled, { keyframes } from 'styled-components';
import { pxToRem } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';

const loaderAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoaderIcon = styled.div`
  border-radius: 50%;
  border: 10px solid transparent;
  border-bottom-color: ${themeColors.colorLoaderIcon};
  border-top-color: ${themeColors.colorLoaderIcon};
  animation: 1s ${loaderAnimation} linear infinite;
  width: ${pxToRem(100)};
  height: ${pxToRem(100)};
`;

export default LoaderIcon;
