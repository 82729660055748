import { createSelector } from 'reselect';

const holdingsSelector = (state) => state.portfolio.transactions;

export const selectPortfolioTransactions = createSelector(
  holdingsSelector,
  (state) => state.transactions.data.Data,
);

export const selectPortfolioTransactionsTotalCount = createSelector(
  holdingsSelector,
  (state) => state.transactions.data.TotalCount,
);

export const selectPortfolioTransactionsError = createSelector(
  holdingsSelector,
  (state) => state.error,
);

export const selectPortfolioSingleTransaction = createSelector(
  holdingsSelector,
  (state) => state.singleTransaction.data,
);

export const selectPortfolioSingleTransactionError = createSelector(
  holdingsSelector,
  (state) => state.singleTransaction.error,
);

export const selectPortfolioPendingTransactions = createSelector(
  holdingsSelector,
  (state) => state.pendingTransactions.data,
);

export const selectPortfolioPendingTransactionsError = createSelector(
  holdingsSelector,
  (state) => state,
);
