/* eslint-disable no-restricted-globals */
const worker = () => {
  self.executeWorkerMethod = function workerMethod(request) {
    fetch(request.url, { headers: request.headers })
      .then((response) => response.json())
      .then((data) => postMessage(data))
      // eslint-disable-next-line no-console
      .catch((error) => console.error(error));
  };

  onmessage = function onMessage({ data }) {
    self.executeWorkerMethod(data.request, data.data);
  };
};

export default worker;
