import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import Table from '../../Table/Table';
import TableHead from '../../Table/TableHead';
import TableBody from '../../Table/TableBody';
import TableRow from '../../Table/TableRow';
import { formatDate } from '../../../util/helpers/dateHelpers';
import { transactionRequiestStatusTypesArray } from '../../../util/enum/api/transactionTypes';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import { selectIsLoadingByActionType } from '../../../store/selectors/loadingSelectors';
import { PORTFOLIO_TRANSACTIONS_SINGLE_LOADING } from '../../../store/actions/portfolio/portfolioActionConstants';
import SectionLoader from '../../Loader/SectionLoader';
import PendingTransactionRow from './PendingTransactionRow';
import {
  pendingTransactionHead,
  pendingTransactionRow,
  pendingTransactionStyle,
} from '../../Table/Components/PendingTransaction';
import {
  HoldingTableEmptyStyle,
  HoldingTableEmptyTextStyle,
} from '../../Table/Components/HoldingTable';

const PendingTransactionsTable = ({
  data,
  accountUid,
  shouldShowTransactions,
  handleModalOpen,
}) => {
  const { t } = useTranslation();
  const singleTransactionLoading = useSelector(
    selectIsLoadingByActionType(PORTFOLIO_TRANSACTIONS_SINGLE_LOADING),
  );

  const [rowData, setRowData] = useState([]);

  const headerData = [
    {
      id: 1,
      content: t(
        'portfolio.pendingTransactions.tableHeadings.yourTransactions',
      ),
    },
    { id: 2, content: t('portfolio.pendingTransactions.tableHeadings.amount') },
    { id: 3, content: t('portfolio.pendingTransactions.tableHeadings.status') },
    { id: 4, content: '' },
  ];

  const tableBodyData = data && data?.length > 0 ? data : []; //eslint-disable-line

  useEffect(() => {
    if (tableBodyData && tableBodyData.length) {
      setRowData(
        tableBodyData.map((transaction) => ({
          id: transaction.Uid,
          content: (
            <PendingTransactionRow
              key={`${transaction.DepositKey}-${uuid()}`}
              date={formatDate(transaction.RequestDate, 'dd MMM yy')}
              amount={transaction.Amount}
              bankAccountNumber={transaction.BankAccountNumber}
              status={parseEnumType(
                transactionRequiestStatusTypesArray,
                transaction.RequestStatus,
              )}
              canCancel={transaction.CanCancel}
              action={transaction.TransactionType}
              accountUid={accountUid}
              paymentUid={transaction.DepositKey}
              handleModalOpen={handleModalOpen}
            />
          ),
        })),
      );
    }
  }, [data]); //eslint-disable-line

  return (
    <>
      <Table css={pendingTransactionStyle}>
        <TableHead data={headerData} css={pendingTransactionHead} />
        <SectionLoader isLoading={singleTransactionLoading}>
          {shouldShowTransactions ? (
            <TableBody>
              <TableRow
                key={uuid()}
                css={pendingTransactionRow}
                rowData={rowData}
                data-cy="list-item-transaction-request"
              />
            </TableBody>
          ) : (
            <HoldingTableEmptyStyle isSmall>
              <HoldingTableEmptyTextStyle>
                {t('balances.noRecentTransactions')}
              </HoldingTableEmptyTextStyle>
            </HoldingTableEmptyStyle>
          )}
        </SectionLoader>
      </Table>
    </>
  );
};

PendingTransactionsTable.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        DateTime: PropTypes.string,
        Amount: PropTypes.number,
        EffectiveBalance: PropTypes.number,
        StatusType: PropTypes.number,
        Caption: PropTypes.string,
      }),
    ),
    PropTypes.shape({}),
  ]),
  accountUid: PropTypes.string,
  shouldShowTransactions: PropTypes.bool,
  handleModalOpen: PropTypes.func,
};

export default PendingTransactionsTable;
