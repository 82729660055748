import styled from 'styled-components';
import {
  uButtonClear,
  uFlexCenter,
  uOutlineNone,
} from '../../assets/styles/utility';

const IconButton = styled.button`
  ${uFlexCenter};
  ${uOutlineNone};
  ${uButtonClear};
  user-select: none;
  cursor: pointer;
`;

export default IconButton;
