import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

import Modal from '../../Modal/Modal';
import TypeCard from '../../TypeCard/TypeCard';
import { WITHDRAWAL_WIZARD_TYPES } from '../../../constants/standardWithdrawalWizardConstants';
import { selectWireWithdrawalFee } from '../../../store/selectors/settingsSelectors';
import { formatMoneyNumeral } from '../../../util/helpers/numeralHelpers';
import useGtmHook from '../../../util/hooks/useGtmHook';
import { FUND_WITHDRAW_START } from '../../../constants/gtmEvents';
import { CHECK_WITHDRAWAL, WIRE_WITHDRAWAL } from '../../../util/constants';
import { useIsClosedAccount } from '../../../util/hooks/useIsClosedAccount';
import TypeList from '../../TypeList/TypeList';
import ModalBody from '../../Modal/ModalBody';

const StandardWithdrawalTypeSelect = ({
  isModalOpen,
  handleClose,
  handleNext,
  setSelectedType,
}) => {
  const wireWithdrawalFee = useSelector(selectWireWithdrawalFee);
  const { gtmDialogScreenView, fundGtmEvent } = useGtmHook();
  const isClosedAccount = useIsClosedAccount();

  const handleCloseModal = () => {
    handleClose();
  };

  return (
    <Modal
      isOpen={isModalOpen}
      size="sm"
      close={handleCloseModal}
      title={i18next.t('withdrawalWizard.withdrawFunds')}
    >
      <ModalBody>
        <TypeList>
          <TypeCard
            title={i18next.t('standardWithdrawalWizard.wireWithdrawal.title')}
            description={i18next.t(
              'standardWithdrawalWizard.wireWithdrawal.typeSelectDescription',
            )}
            note={i18next.t(
              'standardWithdrawalWizard.wireWithdrawal.typeSelectNote',
              { fee: formatMoneyNumeral(wireWithdrawalFee) },
            )}
            onClick={() => {
              // gtmEvent(WITHDRAW_INITIATED, {
              //   WithDrawalType: WITHDRAWAL_WIZARD_TYPES.WIRE,
              // });
              fundGtmEvent(FUND_WITHDRAW_START, {
                fund: {
                  method: WITHDRAWAL_WIZARD_TYPES.WIRE,
                },
              });
              setSelectedType(WITHDRAWAL_WIZARD_TYPES.WIRE, handleNext);
              gtmDialogScreenView({
                title: `Withdraw Funds - ${WIRE_WITHDRAWAL}`,
              });
            }}
            disabled={isClosedAccount}
          />
          <TypeCard
            title={i18next.t('standardWithdrawalWizard.checkWithdrawal.title')}
            description={i18next.t(
              'standardWithdrawalWizard.checkWithdrawal.typeSelectDescription',
            )}
            note={i18next.t(
              'standardWithdrawalWizard.checkWithdrawal.typeSelectNote',
            )}
            onClick={() => {
              // gtmEvent(WITHDRAW_INITIATED, {
              //   WithDrawalType: WITHDRAWAL_WIZARD_TYPES.CHECK,
              // });
              fundGtmEvent(FUND_WITHDRAW_START, {
                fund: {
                  method: WITHDRAWAL_WIZARD_TYPES.CHECK,
                },
              });
              setSelectedType(WITHDRAWAL_WIZARD_TYPES.CHECK, handleNext);
              gtmDialogScreenView({
                title: `Withdraw Funds - ${CHECK_WITHDRAWAL}`,
              });
            }}
          />
        </TypeList>
      </ModalBody>
    </Modal>
  );
};

StandardWithdrawalTypeSelect.propTypes = {
  isModalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleNext: PropTypes.func,
  setSelectedType: PropTypes.func,
};

export default StandardWithdrawalTypeSelect;
