import React from 'react';
import { Formik, Form, Field } from 'formik';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import Auth from '../components/Auth/Auth';
import PasswordField from '../components/InputFields/PasswordField';
import { postResetPasswordRequest } from '../store/actions/user/userActions';
import { RESET_SUCCESSFUL_PAGE } from '../constants/pages';
import { getBrowserPayload } from '../util/helpers/browserHelper';
import { resetPasswordFormValidationSchema } from '../validation/resetPasswordFormValidationSchema';
import { useQuery } from '../util/hooks/useQuery';
import AuthCard from '../components/Auth/AuthCard';
import AuthButtons from '../components/Auth/AuthButtons';
import FormContainer from '../components/FormContainer/FormContainer';

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const { rpt } = query;

  const handleSubmit = (values, { setFieldError }) => {
    const payload = getBrowserPayload({
      PasswordResetToken: rpt,
      NewPassword: values.Password,
    });

    dispatch(
      postResetPasswordRequest({
        data: { ...payload },
        handleSuccess: () => {
          history.push(RESET_SUCCESSFUL_PAGE);
        },
        handleError: (errorMsg) => {
          setFieldError('Password', errorMsg);
        },
      }),
    );
  };

  return (
    <Auth>
      <AuthCard
        title={t('resetPassword.title')}
        subtitle={t('resetPassword.newPassword.subtitle')}
      >
        <Formik
          onSubmit={handleSubmit}
          validationSchema={resetPasswordFormValidationSchema}
          validateOnBlur
          initialValues={{ Password: '' }}
          initialTouched={{ Password: true }}
        >
          {({ values, errors }) => (
            <Form>
              <FormContainer>
                <Field
                  label={t('resetPassword.newPassword.label')}
                  name="Password"
                  component={PasswordField}
                  shouldTestPasswordStrength={isEmpty(errors?.Password)}
                />
              </FormContainer>
              <AuthButtons
                showOnlyPrimary
                primaryButtonProps={{
                  label: t('resetPassword.newPassword.savePassword'),
                  disabled: !values.Password,
                  type: 'submit',
                }}
              />
            </Form>
          )}
        </Formik>
      </AuthCard>
    </Auth>
  );
};

export default ResetPasswordPage;
