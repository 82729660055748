import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Modal from '../../Modal/Modal';
import IraWithdrawType from '../IRA/IraWithdraw/IraWithdrawType';
import StepByStep from '../../StepByStep';
import IraWithdrawAmount from '../IRA/IraWithdraw/IraWithdrawAmount';
import IraWithdrawReview from '../IRA/IraWithdraw/IraWithdrawReview';

import { TYPE_DISTRIBUTION, TYPE_TRANSFER_OUT } from '../../../util/constants';
import { iraEntrustWithdrawSubmit } from '../../../store/actions/orders/orderActions';
import { TT_BUY_FOR_DELIVERY } from '../../../util/enum/api/transactionTypes';
import IraWithdrawDistributionDisclosure from '../IRA/IraWithdraw/IraWithdrawDistributionDisclosure';
import {
  IRA_CASH_DISTRIBUTION_WITHDRAWAL_WIZARD,
  IRA_TRANSFER_OUT_WITHDRAWAL_WIZARD,
  IS_SUCCESS_OR_FAIL_MODAL_OPEN,
  STATUS_MESSAGE,
  SUPPORT_MODAL_OPEN,
  TYPE,
  WITHDRAWAL_AMOUNT,
} from '../../../constants/sessionStorage';
import {
  closeWizardContent,
  deleteWizardKey,
  setWizardContent,
} from '../../../util/helpers/wizardHelpers';
import Status from '../Status';
import { useSessionStorageState } from '../../../util/hooks/useSessionStorageState';
import { isActionRestrictedHelper } from '../../../util/helpers/restrictionHelper';
import useRestrictionHook from '../../../util/hooks/useRestrictionHook';
import { restrictionTypes } from '../../../util/enum/api/restrictionTypes';
import Restriction from '../RestrictionModal/Restriction';
import SupportModal from '../SupportModal/SupportModal';
import { FUND_WITHDRAW_COMPLETE } from '../../../constants/gtmEvents';
import useGtmHook from '../../../util/hooks/useGtmHook';

const IraWithdrawalWizard = ({
  isModalOpen,
  handleClose,
  handleRefreshTransactions,
  balance,
  accountUid,
  type,
  isImpersonating,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const restrictions = useRestrictionHook();
  const { fundGtmEvent } = useGtmHook();
  const [typeSelected, setTypeSelected] = useSessionStorageState(TYPE, '');
  const [amountToWithdraw, setAmountToWithdraw] = useSessionStorageState(
    WITHDRAWAL_AMOUNT,
    null,
  );
  const [statusMessage, setStatusMessage] = useSessionStorageState(
    STATUS_MESSAGE,
    null,
  );
  const [isRequestSuccessful, setIsRequestSuccessful] = useSessionStorageState(
    IS_SUCCESS_OR_FAIL_MODAL_OPEN,
    null,
  );
  const [isSupportModalOpen, setIsSupportModalOpen] = useSessionStorageState(
    SUPPORT_MODAL_OPEN,
    false,
  );
  const [isLoading, setIsLoading] = useState(false);

  const closeModal = useCallback(() => {
    setTypeSelected('');
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose]);

  const onTypeSelect = useCallback((type) => {
    setTypeSelected(type);
    setWizardContent(TYPE, type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirmStatus = (statusMessage, isRequestSuccessful) => {
    setStatusMessage(statusMessage);
    setWizardContent(STATUS_MESSAGE, statusMessage);
    setIsRequestSuccessful(isRequestSuccessful);
    setWizardContent(IS_SUCCESS_OR_FAIL_MODAL_OPEN, isRequestSuccessful);
  };

  const onWithdrawalSubmit = useCallback(
    (handleNextStep) => {
      const handleApiResponse = (statusMessage, isRequestSuccessful) => {
        handleConfirmStatus(statusMessage, isRequestSuccessful);
        handleNextStep();
      };

      const onRequestSuccess = () => {
        handleRefreshTransactions();
        fundGtmEvent(FUND_WITHDRAW_COMPLETE, {
          fund: {
            method: typeSelected,
            amount: amountToWithdraw,
          },
        });
      };

      if (
        typeSelected === TYPE_DISTRIBUTION ||
        typeSelected === TYPE_TRANSFER_OUT
      ) {
        dispatch(
          iraEntrustWithdrawSubmit({
            data: {
              Amount: amountToWithdraw,
              AccountUid: accountUid,
              Type: TT_BUY_FOR_DELIVERY,
            },
            type:
              typeSelected === TYPE_DISTRIBUTION
                ? TYPE_DISTRIBUTION
                : TYPE_TRANSFER_OUT,
            handleApiResponse,
            onRequestSuccess,
            accountUid,
            setIsLoading,
          }),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, typeSelected, accountUid, amountToWithdraw, setIsLoading],
  );

  const renderModalContent = useCallback(() => {
    switch (typeSelected) {
      case TYPE_DISTRIBUTION:
        return (
          <StepByStep
            sessionScopeStep={IRA_CASH_DISTRIBUTION_WITHDRAWAL_WIZARD}
            onConfirm={onWithdrawalSubmit}
          >
            {({ goStepBack, goStepForward, onConfirm }) => [
              <IraWithdrawAmount
                key="ira-withdraw-amount"
                balance={balance.AvailableForWithdrawal}
                type={
                  typeSelected === TYPE_DISTRIBUTION
                    ? TYPE_DISTRIBUTION
                    : TYPE_TRANSFER_OUT
                }
                goStepForward={goStepForward}
                goInitialScreen={() => {
                  setTypeSelected('');
                  deleteWizardKey(TYPE);
                }}
                setAmountToWithdraw={setAmountToWithdraw}
                closeModal={closeModal}
              />,
              <IraWithdrawDistributionDisclosure
                key="entrust-distribution-disclosure"
                goStepForward={goStepForward}
                goStepBack={goStepBack}
                close={closeModal}
                overModal
              />,
              <IraWithdrawReview
                key="ira-withdraw-review"
                goStepBack={goStepBack}
                onConfirm={onConfirm}
                amountToWithdraw={amountToWithdraw}
                isLoading={isLoading}
                goStepForward={goStepForward}
                type={type}
              />,
              <Status
                hasError={isRequestSuccessful}
                text={statusMessage}
                onButtonClick={() => {
                  closeModal();
                  closeWizardContent();
                }}
                key="ira-withdraw-status"
              />,
            ]}
          </StepByStep>
        );

      case TYPE_TRANSFER_OUT:
        return (
          <StepByStep
            sessionScopeStep={IRA_TRANSFER_OUT_WITHDRAWAL_WIZARD}
            onConfirm={onWithdrawalSubmit}
          >
            {({ goStepBack, goStepForward, onConfirm }) => [
              <IraWithdrawAmount
                key="ira-withdraw-amount"
                balance={balance.AvailableForWithdrawal}
                type={typeSelected === TYPE_TRANSFER_OUT}
                goStepForward={goStepForward}
                goInitialScreen={() => {
                  setTypeSelected('');
                  deleteWizardKey(TYPE);
                }}
                setAmountToWithdraw={setAmountToWithdraw}
                closeModal={closeModal}
              />,
              <IraWithdrawReview
                key="ira-withdraw-review"
                goStepBack={goStepBack}
                onConfirm={onConfirm}
                amountToWithdraw={amountToWithdraw}
                isLoading={isLoading}
                goStepForward={goStepForward}
                type={type}
              />,
              <Status
                hasError={isRequestSuccessful}
                text={statusMessage}
                onButtonClick={() => {
                  closeModal();
                  closeWizardContent();
                }}
                key="ira-withdraw-status"
              />,
            ]}
          </StepByStep>
        );
      default:
        return <IraWithdrawType onTypeSelect={onTypeSelect} />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    typeSelected,
    onWithdrawalSubmit,
    onTypeSelect,
    balance.AvailableForWithdrawal,
    closeModal,
    amountToWithdraw,
    isLoading,
    isRequestSuccessful,
    statusMessage,
    type,
  ]);

  const modalTitle = useMemo(() => {
    switch (typeSelected) {
      case TYPE_DISTRIBUTION:
        return t('millenium.distribution');
      case TYPE_TRANSFER_OUT:
        return t('millenium.transferOut');
      default:
        return t('withdrawalWizard.withdrawFunds');
    }
  }, [typeSelected, t]);

  const closeSupportModal = () => {
    setIsSupportModalOpen(false);
    deleteWizardKey(SUPPORT_MODAL_OPEN);
  };
  return (
    <>
      {!isSupportModalOpen ? (
        <Modal
          title={modalTitle}
          close={closeModal}
          size="sm"
          isOpen={isModalOpen}
        >
          {isActionRestrictedHelper(restrictions, [
            restrictionTypes.LogonIsPrevented,
            restrictionTypes.WithdrawalsLocked,
          ]) || !isImpersonating ? (
            <Restriction
              restrictions={restrictions}
              isImpersonating={isImpersonating}
              isIRA
              setIsSupportModalOpen={setIsSupportModalOpen}
            />
          ) : (
            renderModalContent()
          )}
        </Modal>
      ) : (
        <SupportModal
          avoidClearingStorage
          isOpen={isSupportModalOpen}
          goBack={closeSupportModal}
        />
      )}
    </>
  );
};

IraWithdrawalWizard.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  handleRefreshTransactions: PropTypes.func,
  balance: PropTypes.shape({
    AvailableForWithdrawal: PropTypes.number,
  }),
  accountUid: PropTypes.string,
  type: PropTypes.string,
  isImpersonating: PropTypes.bool,
};

export default IraWithdrawalWizard;
