const scenarios = [
  {
    maximumPeriodType: 'OneWeek',
    date: '1/6/2019'
  },
  {
    maximumPeriodType: 'OneMonth',
    date: '1/27/2019'
  },
  {
    maximumPeriodType: 'ThreeMonths',
    date: '3/19/2019'
  },
  {
    maximumPeriodType: 'SixMonths',
    date: '5/21/2019'
  },
  {
    maximumPeriodType: 'OneYear',
    date: '12/15/2019'
  },
  {
    maximumPeriodType: 'All',
    date: '8/30/2020'
  }
];

export default scenarios;
