import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash.isempty';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  getCurrentAccount,
  getCurrentAccountUid,
  selectAccountAddresses,
  selectAccountAddressesError,
} from '../../store/selectors/accountSelectors';
import AddressCard from '../../components/AddressCard/AddressCard';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import Section from '../../components/Section/Section';
import { fetchAccountAddresses } from '../../store/actions/account/accountActions';

import useGtmHook from '../../util/hooks/useGtmHook';
import { selectIsLoadingByActionTypes } from '../../store/selectors/loadingSelectors';
import { FETCH_ACCOUNT_ADDRESSES_LOADING } from '../../store/actions/account/accountActionConstants';
import BlockSectionLoader from '../../components/Loader/BlockSectionLoader';
import useCheckPermissions from '../../util/hooks/useCheckPermissions';
import { fetchAccountIraSettings } from '../../store/actions/iraSettings/iraSettingsActions';
import { FETCH_ACCOUNT_IRA_SETTINGS_LOADER } from '../../store/actions/iraSettings/iraSettingsActionConstants';
import Paragraph from '../../components/Paragraph/Paragraph';
import TypeList from '../../components/TypeList/TypeList';
import RemoveAddressModal from '../../components/Modals/Address/RemoveAddressModal';
import AddressIraContact from '../../components/AddressIraContact/AddressIraContact';
import {
  uFlexCenter,
  uFlexColumn,
  uFullWidth,
} from '../../assets/styles/utility';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import {
  BankAccountAddElement,
  BankAccountAddIcon,
} from '../../components/BankAccountsCard/BankAccountAdd';
import { ReactComponent as Plus } from '../../assets/images/svg/plus.svg';
import AddAddressModal from '../../components/Modals/Address/AddAddressModal';
import { ADD_ADDRESS_WIZARD } from '../../constants/sessionStorage';
import { removeFromSessionStorage } from '../../util/helpers/sessionStorageHelper';
import NagDashboardBanner from '../../components/NagDashboard/NagDashboardBanner';
import { parseEnumType } from '../../util/helpers/enumMappers';
import {
  accountTypeKeys,
  accountTypes,
} from '../../util/enum/api/accountTypes';
import { selectIsAddAddressesEnabled } from '../../store/selectors/settingsSelectors';
import { ACCOUNT_DATA_LOADED } from '../../constants/gtmEvents';

export const AddressPageContainer = styled.div`
  ${uFlexColumn};
  padding: ${pxToRem(25)} 0;
  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: ${pxToRemMd(32)} ${pxToRemMd(16)};
  }
`;

export const AddressesPageContent = styled.div`
  ${uFlexColumn};
  max-width: ${pxToRem(640)};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    max-width: ${pxToRemMd(640)};
  }
`;

export const AddressesPageEmpty = styled.div`
  ${uFlexCenter};
  ${uFullWidth};
  min-height: ${pxToRem(200)};
  box-shadow: ${variables.shadow.boxShadow};
`;

const AddressesPage = () => {
  const { t } = useTranslation();
  const { gtmScreenView, appGtmEvent } = useGtmHook();
  const dispatch = useDispatch();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddAddressModalOpen, setIsAddAddressModalOpen] = useState(false);
  const [addressUid, setAddressUid] = useState('');
  const accountUid = useSelector(getCurrentAccountUid);
  const account = useSelector(getCurrentAccount);
  const addresses = useSelector(selectAccountAddresses);
  const isAddAddressesEnabled = useSelector(selectIsAddAddressesEnabled);
  const isLoading = useSelector(
    selectIsLoadingByActionTypes([
      FETCH_ACCOUNT_ADDRESSES_LOADING,
      FETCH_ACCOUNT_IRA_SETTINGS_LOADER,
    ]),
  );

  const addressesError = useSelector(selectAccountAddressesError);
  const {
    allowAccountChangesPermission,
    displayStandardOptionsPermission,
    displayBasicAccountOptionsPermission,
    displayIRAEntrustOptionsPermission,
    displayIRAMilleniumOptionsPermission,
    displayIRAEquityOptionsPermission,
  } = useCheckPermissions();

  const isPendingEquityAccount =
    parseEnumType(accountTypeKeys, account.AccountType) === accountTypes.IRA &&
    displayBasicAccountOptionsPermission;
  const isIRA =
    displayIRAEntrustOptionsPermission ||
    displayIRAMilleniumOptionsPermission ||
    displayIRAEquityOptionsPermission ||
    isPendingEquityAccount;

  const removeAddAddressButtonForIRA =
    displayIRAEntrustOptionsPermission ||
    displayIRAMilleniumOptionsPermission ||
    displayIRAEquityOptionsPermission ||
    isPendingEquityAccount;

  useEffect(() => {
    gtmScreenView({
      path: window.location.pathname,
      title: 'Addresses',
    });
    appGtmEvent(ACCOUNT_DATA_LOADED);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (accountUid) {
      dispatch(fetchAccountAddresses({ accountUid }));
    }
  }, [accountUid, dispatch]);

  useEffect(() => {
    if (
      (!displayStandardOptionsPermission &&
        !displayBasicAccountOptionsPermission &&
        accountUid) ||
      isPendingEquityAccount
    ) {
      dispatch(
        fetchAccountIraSettings({
          accountUid,
        }),
      );
    }
  }, [
    isPendingEquityAccount,
    displayStandardOptionsPermission,
    displayBasicAccountOptionsPermission,
    accountUid,
    dispatch,
  ]);

  return (
    <div data-cy="container-addresses-page">
      <NagDashboardBanner />
      <NavigationBar
        mainPage={t('header.navDropdownAccount')}
        subPage={t('header.addresses')}
      />
      <Section>
        <AddressPageContainer>
          <AddressesPageContent>
            {isLoading ? (
              <BlockSectionLoader isLoading={isLoading} />
            ) : (
              <>
                {addressesError && (
                  <Paragraph isError>{addressesError}</Paragraph>
                )}
                {!isEmpty(addresses) ? (
                  <TypeList>
                    {addresses.map((address) => {
                      const { AddressUid } = address;

                      return (
                        <AddressCard
                          key={AddressUid}
                          address={address}
                          hasChangePermission={allowAccountChangesPermission}
                          accountUid={accountUid}
                          setAddressUid={setAddressUid}
                          setIsDeleteModalOpen={setIsDeleteModalOpen}
                        />
                      );
                    })}
                  </TypeList>
                ) : (
                  <AddressesPageEmpty>
                    <Paragraph>{t('account.addresses.noAddresses')}</Paragraph>
                  </AddressesPageEmpty>
                )}
                {isIRA && <AddressIraContact />}
              </>
            )}
            {isAddAddressesEnabled && !removeAddAddressButtonForIRA && (
              <BankAccountAddElement
                onClick={() => setIsAddAddressModalOpen(true)}
              >
                <BankAccountAddIcon>
                  <Plus />
                </BankAccountAddIcon>
                {t('account.addresses.adddNewAddress')}
              </BankAccountAddElement>
            )}
          </AddressesPageContent>
        </AddressPageContainer>

        {isAddAddressesEnabled && !removeAddAddressButtonForIRA && (
          <AddAddressModal
            isOpen={isAddAddressModalOpen}
            onClose={() => {
              removeFromSessionStorage(ADD_ADDRESS_WIZARD);
              setIsAddAddressModalOpen(false);
            }}
          />
        )}

        <RemoveAddressModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          addressUid={addressUid}
        />
      </Section>
    </div>
  );
};

export default AddressesPage;
