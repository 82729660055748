import createReducer from '../../utils/createReducer';
import {
  PLAID_LINK_TOKEN_SUCCESS,
  PLAID_LINK_TOKEN_ERROR,
  PLAID_LINK_TOKEN_CLEAR,
} from '../../actions/plaid/PlaidActionConstants';

const initialState = {
  linkToken: '',
  error: '',
};

export default createReducer(
  {
    [PLAID_LINK_TOKEN_SUCCESS]: setLinkToken,
    [PLAID_LINK_TOKEN_ERROR]: setLinkTokenError,
    [PLAID_LINK_TOKEN_CLEAR]: clearToken,
  },
  initialState,
);

function setLinkToken(state, { payload }) {
  return {
    ...state,
    linkToken: payload,
  };
}

function setLinkTokenError(state, { payload }) {
  return {
    ...state,
    error: payload,
  };
}

function clearToken() {
  return initialState;
}
