import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { uuid } from 'uuidv4';
import { fetchBaByRoutingNum } from '../../../../../store/actions/bankAccount/bankAccountActions';
import { selectBankAccountDetailsByRoutingNum } from '../../../../../store/selectors/bankAccountSelectors';
import { selectSettings } from '../../../../../store/selectors/settingsSelectors';
import { formatDate } from '../../../../../util/helpers/dateHelpers';
import { selectIsLoadingByActionType } from '../../../../../store/selectors/loadingSelectors';
import { BA_BY_ROUTING_NUM_LOADING } from '../../../../../store/actions/bankAccount/bankAccountActionConstants';
import { formatMoneyNumeral } from '../../../../../util/helpers/numeralHelpers';
import { useBrokerName } from '../../../../../util/hooks/userBrokerName';
import ModalTitle from '../../../../Modal/ModalTitle';
import Paragraph from '../../../../Paragraph/Paragraph';
import InformationList from '../../../../InformationList/InformationList';
import InformationListItem from '../../../../InformationList/InformationListItem';
import BlockSectionLoader from '../../../../Loader/BlockSectionLoader';
import { useBrokerCode } from '../../../../../util/hooks/useBrokerCode';
import ModalButtons from '../../../../Modal/ModalButtons';

const AchDepositTerms = ({
  bankAccount,
  depositAmount,
  handleBack,
  handleAgree,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const brokerName = useBrokerName();
  const bankAccountDetails = useSelector(selectBankAccountDetailsByRoutingNum);
  const settings = useSelector(selectSettings);
  const brokerCode = useBrokerCode();
  const isLoading = useSelector(
    selectIsLoadingByActionType(BA_BY_ROUTING_NUM_LOADING),
  );

  useEffect(() => {
    if (bankAccount?.ABARoutingNumber) {
      dispatch(
        fetchBaByRoutingNum({
          side: 'ach',
          routingNum: bankAccount.ABARoutingNumber,
        }),
      );
    }
  }, [bankAccount, dispatch]);

  const hideRoutingNumber = (routingNumber) => {
    const hidden = 'XXXXXX';

    if (routingNumber) {
      return `${hidden}${routingNumber.slice(6)}`;
    }
  };

  if (isLoading) {
    return <BlockSectionLoader isLoading={isLoading} />;
  }

  if (!bankAccountDetails) {
    return null;
  }

  const {
    Address,
    Name,
    ABARoutingNumber,
    ACHRoutingNumber,
    WireRoutingNumber,
  } = bankAccountDetails;

  const informationData = [
    {
      label: t('achDeposit.terms.labels.depositAmount'),
      value: formatMoneyNumeral(depositAmount),
    },
    {
      label: t('achDeposit.terms.labels.depositoryName'),
      value: Name,
    },
    {
      label: t('achDeposit.terms.labels.bankCity'),
      value: Address?.City,
    },
    {
      label: t('achDeposit.terms.labels.bankState'),
      value: Address?.StateRegion,
    },
    {
      label: t('achDeposit.terms.labels.bankZip'),
      value: Address?.PostalCode,
    },
    {
      label: t('achDeposit.terms.labels.abaRoutingNumber'),
      value: hideRoutingNumber(ABARoutingNumber),
    },
    {
      label: t('achDeposit.terms.labels.achRoutingNumber'),
      value: hideRoutingNumber(ACHRoutingNumber),
    },
    {
      label: t('achDeposit.terms.labels.wireRoutingNumber'),
      value: hideRoutingNumber(WireRoutingNumber),
    },
  ];

  return (
    <>
      <ModalTitle>
        {t('standardDepositWizard.achDeposit.terms.title')}
      </ModalTitle>
      <Paragraph marginBottom={24}>
        {t('standardDepositWizard.achDeposit.terms.authorize', { brokerCode })}
      </Paragraph>
      <InformationList withBackground>
        {informationData.map(({ value, label }) => (
          <InformationListItem key={uuid()} value={value} label={label} />
        ))}
      </InformationList>
      <Paragraph marginBottom={24}>
        {t('standardDepositWizard.achDeposit.terms.remain', { brokerCode })}
      </Paragraph>
      <Paragraph marginBottom={24}>
        {t('standardDepositWizard.achDeposit.terms.debit')}
      </Paragraph>
      <Paragraph>
        {t('standardDepositWizard.achDeposit.terms.understandTextOne')}{' '}
        <strong>{settings?.AchSettlementDaysInfo}</strong>{' '}
        {t('standardDepositWizard.achDeposit.terms.understandTextTwo', {
          brokerName,
        })}{' '}
        <strong>{formatDate(settings?.AchSettlementDate)}</strong>.
      </Paragraph>
      <ModalButtons
        isHorizontal
        marginTop
        secondaryButtonProps={{ onClick: handleBack }}
        primaryButtonProps={{
          onClick: handleAgree,
          label: t('common.agree'),
        }}
      />
    </>
  );
};

AchDepositTerms.propTypes = {
  bankAccount: PropTypes.shape({
    ABARoutingNumber: PropTypes.string,
  }),
  depositAmount: PropTypes.number,
  handleBack: PropTypes.func,
  handleAgree: PropTypes.func,
};

export default AchDepositTerms;
