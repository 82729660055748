import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';

import NumberField from '../../InputFields/NumberField';
import { orderType } from '../../../util/enum/api/orderTypes';
import { setProductForReview } from '../../../store/actions/products/productActions';
import { formatMoneyNumeral } from '../../../util/helpers/numeralHelpers';
import {
  getWizardContent,
  setWizardContent,
} from '../../../util/helpers/wizardHelpers';
import { QUANTITY_VALUE } from '../../../constants/sessionStorage';
import useGtmHook from '../../../util/hooks/useGtmHook';
import { sellOrderValidationSchema } from '../../../validation/sellOrderValidationSchema';
import ModalTitle from '../../Modal/ModalTitle';
import ModalBody from '../../Modal/ModalBody';
import Paragraph from '../../Paragraph/Paragraph';
import ModalButtons from '../../Modal/ModalButtons';
import Label from '../../Notes/Label';
import {
  onConfirmSellGtmHelper,
  sellGtmHelper,
} from '../../../util/helpers/gtmHelper';
import { ADD_TO_CART } from '../../../constants/gtmEvents';
import { getQuotesForOrderSell } from '../../../store/actions/orders/orderActions';
import SectionLoader from '../../Loader/SectionLoader';
import SellCalculation from './SellCalculation';
import { selectSettings } from '../../../store/selectors/settingsSelectors';

const SellQuantity = ({
  handleClose,
  handleNext,
  wizardData,
  setWizardData,
  product,
  tiers,
  isProductPage,
  vault,
  account,
  isFractionalProduct,
  isInstaVaultProduct,
  unitPrice,
  isLockedPrice,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { gtmDialogScreenView, ecommerceGtmEvent } = useGtmHook();
  const settings = useSelector(selectSettings);
  const [calculation, setCalculation] = useState({});
  const [quoteID, setQuoteID] = useState('');
  const [expiryTime, setExpiryTime] = useState('');
  const [nextClicked, setNextClicked] = useState(false);
  const [isLoadingGetQuotes, setIsLoadingGetQuotes] = useState(false);
  const [getQuotesError, setGetQuotesError] = useState('');
  const [pricePerUnit, setPricePerUnit] = useState(unitPrice);
  const isSegregated = product?.IsSegregated;
  const segregatedCaption = settings?.SegregatedCaption;

  const handleSubmit = (values) => {
    setWizardContent(QUANTITY_VALUE, values.quantity);

    const payload = {
      Quantity: values.quantity,
      SymbolCode: vault.SymbolCode,
      AccountUid: account.AccountUid,
      Type: orderType.sellFromStorage,
    };

    dispatch(setProductForReview(payload));
    gtmDialogScreenView({
      title: `${t('sellWizard.sellWizardTitle')} ${t('common.confirm')} ${
        product.ProductCaption
      }`,
    });
    onConfirmSellGtmHelper({
      ecommerceGtmEvent,
      eventName: ADD_TO_CART,
      product,
      vault,
      orderSideType: 'sell',
      account,
      values,
    });

    if (!isFractionalProduct || isInstaVaultProduct) {
      const getQuotesPayload = {
        getQuotesPayload: {
          accountUid: account.AccountUid,
          symbolCode: vault?.SymbolCode,
          quantityOrAmount: values?.quantity,
          sideType: 'Sell',
          unitType: 'Quantity',
          isSegregated,
        },
        isLoading: setIsLoadingGetQuotes,
        onSuccess: onHandleGetQuotesSuccess,
        onError: onHandleGetQuotesError,
      };
      setIsLoadingGetQuotes(true);
      dispatch(
        getQuotesForOrderSell({
          ...getQuotesPayload,
        }),
      );
    } else {
      setWizardContent(QUANTITY_VALUE, values.quantity);

      const payload = {
        Quantity: values.quantity,
        SymbolCode: vault.SymbolCode,
        AccountUid: account.AccountUid,
        Type: orderType.sellFromStorage,
      };

      dispatch(setProductForReview(payload));
      gtmDialogScreenView({
        title: `${t('sellWizard.sellWizardTitle')} ${t('common.confirm')} ${
          product.ProductCaption
        }`,
      });
      onConfirmSellGtmHelper({
        ecommerceGtmEvent,
        eventName: ADD_TO_CART,
        product,
        vault,
        orderSideType: 'sell',
        account,
        values,
      });

      handleNext();
    }
  };

  const initialValues = {
    quantity: getWizardContent(QUANTITY_VALUE) || '',
  };

  useEffect(() => {
    setPricePerUnit(unitPrice);
  }, [unitPrice]);

  useEffect(() => {
    if (product?.ProductCaption) {
      gtmDialogScreenView({
        title: `${t('sellWizard.sellWizardTitle')} ${product.ProductCaption}`,
      });
      sellGtmHelper({
        ecommerceGtmEvent,
        product,
        vault,
        orderSideType: 'sell',
        account,
      });
    }
  }, [product?.ProductCaption]); //eslint-disable-line

  const onHandleGetQuotesSuccess = (getQuoteResult) => {
    const {
      QuoteUid,
      QuoteStatusItem: {
        QuantityExecuted,
        ClientPricePerUnit,
        TotalClientExecutionPrice,
        VolumeDiscount,
      },
      ExpiresOnUtc,
    } = getQuoteResult;

    const calculation = {
      estimatedOrderTotal: TotalClientExecutionPrice,
      newPricePerUnit: ClientPricePerUnit,
      volumeDiscount: VolumeDiscount,
      quantity: QuantityExecuted,
    };
    setQuoteID(QuoteUid);
    setExpiryTime(ExpiresOnUtc);
    setCalculation(calculation);
    setNextClicked(true);
  };

  const onHandleGetQuotesError = (error) => {
    const errorCode = error?.response?.data?.Errors[0]?.Code;
    setGetQuotesError(t(`apiErrors.${errorCode}`));
  };

  useEffect(() => {
    if (!isEmpty(calculation)) {
      const quantity = getWizardContent(QUANTITY_VALUE);
      setWizardData({
        ...wizardData,
        calculation,
        quoteID,
        expiryTime,
        orderValues: {
          CashAmount: 0,
          QuantityAmount: quantity,
        },
        orderType: 'Quantity',
      });
    }
  }, [calculation, setWizardData, wizardData, quoteID, expiryTime]);

  useEffect(() => {
    if (!isEmpty(wizardData?.orderValues) && nextClicked) {
      setNextClicked(false);
      handleNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardData?.orderValues, nextClicked]);

  return (
    <SectionLoader isLoading={isLoadingGetQuotes}>
      <ModalBody>
        <ModalTitle>
          {isSegregated
            ? `${product.ProductCaption} (${segregatedCaption})`
            : `${product.ProductCaption}`}
        </ModalTitle>
        <Label
          marginBottom={4}
          isBold
          text={t('sellWizard.availableForSale')}
        />
        <Paragraph marginBottom={24}>{product.UnitsAvailableForSell}</Paragraph>
        <Label
          isBold
          text={
            isInstaVaultProduct || (isLockedPrice && !isFractionalProduct)
              ? t('sellWizard.pricePerUnit')
              : t('sellWizard.estimatedPricePerUnit')
          }
          marginBottom={4}
        />
        <Paragraph marginBottom={24}>
          {t('common.moneyCurrencyLeft', {
            money: formatMoneyNumeral(pricePerUnit),
          })}
        </Paragraph>
        <Formik
          initialValues={initialValues}
          validationSchema={sellOrderValidationSchema(
            isFractionalProduct,
            product?.UnitsAvailableForSell,
            isInstaVaultProduct,
          )}
          onSubmit={handleSubmit}
          initialTouched={{
            quantity: true,
          }}
          validateOnBlur={false}
        >
          {({ values, errors }) => (
            <Form>
              <Field
                label="Quantity:"
                name="quantity"
                placeholder="0"
                component={NumberField}
                centerText
                autoFocus
                data-cy="input-sell-quantity"
              />
              {isFractionalProduct && !isInstaVaultProduct && (
                <SellCalculation
                  unitPrice={unitPrice}
                  quantity={values.quantity}
                  wizardData={wizardData}
                  setWizardData={setWizardData}
                  tiers={tiers}
                  barsArray={product?.Bars}
                  isLockedPriceUser={isLockedPrice}
                  setPricePerUnit={setPricePerUnit}
                  isProductPage={isProductPage}
                  isFractionalProduct={isFractionalProduct}
                />
              )}
              {getQuotesError && (
                <Paragraph isError fontSize={14} marginTop={12}>
                  {getQuotesError}
                </Paragraph>
              )}
              <ModalButtons
                isHorizontal
                marginTop
                primaryButtonProps={{
                  type: 'submit',
                  disabled: !values.quantity || !!errors.quantity,
                  'data-cy': 'button-next',
                }}
                secondaryButtonProps={{
                  label: t('common.cancel'),
                  onClick: handleClose,
                  'data-cy': 'button-cancel',
                }}
              />
            </Form>
          )}
        </Formik>
      </ModalBody>
    </SectionLoader>
  );
};

SellQuantity.propTypes = {
  handleNext: PropTypes.func,
  handleClose: PropTypes.func,
  wizardData: PropTypes.shape({
    calculation: PropTypes.shape({
      estimatedOrderTotal: PropTypes.number,
      newPricePerUnit: PropTypes.number,
    }),
    selectedVault: PropTypes.shape({
      LocationType: PropTypes.number,
      SymbolCode: PropTypes.string,
      IsCashOrderOnly: PropTypes.bool,
    }),
    storageProduct: PropTypes.shape({
      Locations: PropTypes.arrayOf(PropTypes.shape({})),
      Caption: PropTypes.string,
      ProductCaption: PropTypes.string,
    }),
    orderValues: PropTypes.shape({
      QuantityAmount: PropTypes.number,
      CashAmount: PropTypes.number,
    }),
  }),
  setWizardData: PropTypes.func,
  product: PropTypes.shape({
    UnitsAvailableForSell: PropTypes.number,
    ProductCaption: PropTypes.string,
    SymbolCode: PropTypes.string,
    IsSegregated: PropTypes.bool,
    Bars: PropTypes.arrayOf(
      PropTypes.shape({
        BrandCode: PropTypes.string,
        SerialNumber: PropTypes.string,
        FineWeight: PropTypes.number,
        SequenceNumber: PropTypes.number,
      }),
    ),
  }),
  tiers: PropTypes.arrayOf(
    PropTypes.shape({
      HighBound: PropTypes.number,
      LowBound: PropTypes.number,
      PricePerOz: PropTypes.number,
      PricePerUnit: PropTypes.number,
    }),
  ),
  isProductPage: PropTypes.bool,
  vault: PropTypes.shape({
    PricePerUnit: PropTypes.number,
    SymbolCode: PropTypes.string,
  }),
  account: PropTypes.shape({
    AccountUid: PropTypes.string,
  }),
  reviewProduct: PropTypes.shape({}),
  isFractionalProduct: PropTypes.bool,
  isInstaVaultProduct: PropTypes.bool,
  unitPrice: PropTypes.number,
  isLockedPrice: PropTypes.bool,
};

export default SellQuantity;
