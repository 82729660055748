import createReducer from '../../utils/createReducer';
import {
  CLEAR_BUY_STORAGE_STATE,
  SET_BUY_STORAGE_INPUT_VALUE,
  SET_STORAGE_PRODUCT,
  SET_BUY_STORAGE_TYPE,
} from '../../actions/buy/buyStorageConstants';
import {
  BUY_PRODUCT_FOR_STORAGE_ERROR,
  BUY_PRODUCT_FOR_STORAGE_STATE_CLEAR,
  BUY_PRODUCT_FOR_STORAGE_SUCCESS,
  GET_QUOTES_STORAGE_EMPTY,
  GET_QUOTES_DELIVERY_EMPTY,
} from '../../actions/orders/orderActionConstants';

const initialState = {
  product: {},
  deliveryFees: {},
  vault: null,
  typeSelection: null,
  inputValue: 0,
  isSuccessful: null,
  errorMessage: '',
};

export default createReducer(
  {
    [SET_STORAGE_PRODUCT]: setBuyStorageProduct,
    [SET_BUY_STORAGE_TYPE]: setBuyStorageType,
    [SET_BUY_STORAGE_INPUT_VALUE]: setBuyStorageInputValue,
    [CLEAR_BUY_STORAGE_STATE]: clearBuyStorageState,
    [BUY_PRODUCT_FOR_STORAGE_SUCCESS]: setBuyStorageSuccess,
    [BUY_PRODUCT_FOR_STORAGE_ERROR]: setBuyStorageError,
    [BUY_PRODUCT_FOR_STORAGE_STATE_CLEAR]: clearBuyStorageSuccessState,
    [GET_QUOTES_STORAGE_EMPTY]: emptyQuotesStorageReducer,
    [GET_QUOTES_DELIVERY_EMPTY]: emptyQuotesDeliveryReducer,
  },
  initialState,
);

function setBuyStorageProduct(state, action) {
  return {
    ...state,
    ...action.payload,
  };
}

function setBuyStorageType(state, action) {
  return {
    ...state,
    typeSelection: action.payload,
  };
}

function setBuyStorageInputValue(state, action) {
  return {
    ...state,
    inputValue: action.payload,
  };
}

function clearBuyStorageState() {
  return initialState;
}

function setBuyStorageSuccess(state) {
  return {
    ...state,
    isSuccessful: true,
    errorMessage: '',
  };
}

function setBuyStorageError(state, action) {
  return {
    ...state,
    isSuccessful: false,
    errorMessage: action.payload,
  };
}

function clearBuyStorageSuccessState(state) {
  return {
    ...state,
    isSuccessful: null,
    errorMessage: '',
  };
}

function emptyQuotesStorageReducer(state, action) {
  return {
    ...state,
    quotes: {},
    errorMessage: action.payload,
  };
}

function emptyQuotesDeliveryReducer(state, action) {
  return {
    ...state,
    quotes: {},
    errorMessage: action.payload,
  };
}
