import React from 'react';
import PropTypes from 'prop-types';
import { uuid } from 'uuidv4';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Paragraph from '../Paragraph/Paragraph';
import IconButton from '../IconButton/IconButton';
import { ReactComponent as QuestionMark } from '../../assets/images/svg/question.svg';
import { uFlexColumn } from '../../assets/styles/utility';
import { pxToRem } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { buttonizeDiv } from '../../util/buttonizeDiv';
import { variables } from '../../assets/styles/variables';

export const ReviewListContainer = styled.ul`
  ${uFlexColumn};

  > :last-child {
    background-color: ${themeColors.colorBackgroundSecondary};
    font-weight: 600;
  }
`;

const ReviewListItem = styled.li`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${pxToRem(8)};
  padding: ${pxToRem(3)} ${pxToRem(8)} ${pxToRem(5)};

  > :last-child {
    text-align: right;
  }

  > :first-child {
    > * {
      display: inline;
    }
  }
`;

const ReviewListIconButton = styled(IconButton)`
  margin-left: 4px;

  &,
  svg {
    width: 16px;
    height: 16px;
    color: ${themeColors.colorPrimary};
  }
`;

const ParagrapUnderline = styled.span`
  text-decoration: underline;
`;

const FractionalNoteContainer = styled.div`
  background-color: ${themeColors.colorBackgroundSecondary};
  padding: 0.188rem 0.5rem 0.313rem;
  border-radius: ${variables.borderRadius.borderRadiusMd};
`;

const Spacer = styled.div`
  margin-top: ${pxToRem(16)};
`;

const ReviewList = ({ data, displayFractionalNote, openSupport }) => {
  const { t } = useTranslation();
  return (
    <ReviewListContainer>
      {data.map(({ label, value, action }) => (
        <ReviewListItem key={uuid()}>
          <Paragraph>
            <strong>{label}</strong>
            {action && (
              <ReviewListIconButton onClick={action}>
                <QuestionMark />
              </ReviewListIconButton>
            )}
          </Paragraph>
          <Paragraph>{value}</Paragraph>
        </ReviewListItem>
      ))}
      {displayFractionalNote && (
        <FractionalNoteContainer>
          <Paragraph>
            <strong>{t('product.requestDelivery.fractionalNoteTitle')}</strong>
          </Paragraph>
          <Spacer />
          <Paragraph>
            {t('product.requestDelivery.fractionalNoteParagaph1')}
          </Paragraph>
          <Spacer />
          <Paragraph>
            {t('product.requestDelivery.fractionalNoteParagaph2')}
            <ParagrapUnderline>
              {t('product.requestDelivery.fractionalNoteParagaph3')}
            </ParagrapUnderline>
            {t('product.requestDelivery.fractionalNoteParagaph4')}
          </Paragraph>
          <Spacer />
          <Paragraph>
            {t('product.requestDelivery.fractionalNoteParagaph5')}
            <ParagrapUnderline {...buttonizeDiv(() => openSupport())}>
              {t('product.requestDelivery.fractionalNoteParagaph6')}
            </ParagrapUnderline>
            {t('product.requestDelivery.fractionalNoteParagaph7')}
          </Paragraph>
        </FractionalNoteContainer>
      )}
    </ReviewListContainer>
  );
};

ReviewList.propTypes = {
  data: PropTypes.arrayOf(),
  displayFractionalNote: PropTypes.bool,
  openSupport: PropTypes.func,
};

export default React.memo(ReviewList);
