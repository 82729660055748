import {
  CLEAR_BUY_STORAGE_STATE,
  SET_BUY_STORAGE_INPUT_VALUE,
  SET_STORAGE_PRODUCT,
  SET_BUY_STORAGE_TYPE,
} from './buyStorageConstants';

export function setStorageProduct(payload) {
  return {
    type: SET_STORAGE_PRODUCT,
    payload,
  };
}

export function clearBuyStorageState() {
  return {
    type: CLEAR_BUY_STORAGE_STATE,
  };
}

export function setBuyStorageType(payload) {
  return {
    type: SET_BUY_STORAGE_TYPE,
    payload,
  };
}

export function setBuyStorageInputValue(payload) {
  return {
    type: SET_BUY_STORAGE_INPUT_VALUE,
    payload,
  };
}
