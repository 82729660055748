import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import Button from '../Button/Button';
import EditFullNameModal from '../Modals/ProfileSettings/EditFullNameModal';
import ChangePasswordModal from '../Modals/ProfileSettings/ChangePasswordModal';
import ChangeSecurityQuestionModal from '../Modals/ProfileSettings/ChangeSecurityQuestionModal';
import {
  selectIsTwoFAEnabled,
  selectSettings,
} from '../../store/selectors/settingsSelectors';
import { accountTypes } from '../../util/enum/api/accountTypes';
import { getCurrentAccount } from '../../store/selectors/accountSelectors';
import Paragraph from '../Paragraph/Paragraph';
import SingleColumnList from '../TwoColumnList/SingleColumnList';
import ProfileSettingsItem from './ProfileSettingsItem';
import HorizontalLine from '../HorizontalLine/HorizontalLine';
import ProfileSettingsCard from './ProfileSettingsCard';
import ProfileSettingsEmailNote from './ProfileSettingsEmailNote';

const ProfileSettings = ({
  FirstName,
  LastName,
  MiddleName,
  Username,
  Email,
  SecurityQuestionType,
  SuffixType,
}) => {
  const { t } = useTranslation();
  const account = useSelector(getCurrentAccount);
  const settings = useSelector(selectSettings);
  const [isEditFullNameOpen, setIsEditFullNameOpen] = useState(false);
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
  const [
    isChangeSecurityQuestionOpen,
    setIsChangeSecurityQuestionOpen,
  ] = useState(false);

  const isTwoFAEnabled = useSelector(selectIsTwoFAEnabled);
  const fullName = `${FirstName || ''} ${MiddleName || ''} ${LastName || ''} ${
    SuffixType || ''
  }`;

  const { UploadDocumentUrlFormat, SupportEmail } = settings;
  const { EncryptedAccountUid } = account;

  const uploadCenterUrl = `${UploadDocumentUrlFormat}${EncryptedAccountUid}`;

  return (
    <>
      <ProfileSettingsCard>
        <Paragraph bold fontSize={24} marginBottom={24}>
          {t('profileSettings.basicInformation')}
        </Paragraph>
        <ProfileSettingsItem marginBottom={32}>
          <SingleColumnList>
            <Paragraph bold marginBottom={4}>
              {t('profileSettings.fullName')}
            </Paragraph>
            <Paragraph>{fullName}</Paragraph>
          </SingleColumnList>
          <Button
            size="sm"
            variant="primaryOutlined"
            type="button"
            onClick={() => setIsEditFullNameOpen(true)}
          >
            {t('profileSettings.editFullName')}
          </Button>
        </ProfileSettingsItem>
        <ProfileSettingsItem marginBottom={32}>
          <SingleColumnList>
            <Paragraph bold marginBottom={4}>
              {t('profileSettings.userName')}
            </Paragraph>
            <Paragraph>{Username}</Paragraph>
          </SingleColumnList>
        </ProfileSettingsItem>
        <ProfileSettingsItem marginBottom={32}>
          <SingleColumnList>
            <Paragraph bold marginBottom={4}>
              {t('profileSettings.email')}
            </Paragraph>
            <Paragraph>{Email}</Paragraph>
          </SingleColumnList>
        </ProfileSettingsItem>
        <Paragraph>
          <ProfileSettingsEmailNote
            uploadCenterUrl={uploadCenterUrl}
            account={account}
            accountTypes={accountTypes}
            supportEmail={SupportEmail}
          />
        </Paragraph>
        <HorizontalLine marginBottom={16} marginTop={16} />
        <ProfileSettingsItem>
          <SingleColumnList>
            <Paragraph bold marginBottom={4}>
              {t('profileSettings.password')}
            </Paragraph>
            <Paragraph>********</Paragraph>
          </SingleColumnList>
          <Button
            size="sm"
            variant="primaryOutlined"
            type="button"
            onClick={() => setIsChangePasswordOpen(true)}
          >
            {t('profileSettings.changePassword')}
          </Button>
        </ProfileSettingsItem>
        {!isTwoFAEnabled && (
          <>
            <HorizontalLine marginBottom={16} marginTop={16} />
            <ProfileSettingsItem marginBottom={32}>
              <SingleColumnList>
                <Paragraph bold marginBottom={4}>
                  {t('profileSettings.securityQuestion')}
                </Paragraph>
                <Paragraph>
                  {t(`login.securityQuestionTypes.${SecurityQuestionType}`)}
                </Paragraph>
              </SingleColumnList>
              <Button
                size="sm"
                variant="primaryOutlined"
                type="button"
                onClick={() => setIsChangeSecurityQuestionOpen(true)}
              >
                {t('profileSettings.changeSecurityQuestion')}
              </Button>
            </ProfileSettingsItem>
            <ProfileSettingsItem>
              <SingleColumnList>
                <Paragraph bold marginBottom={4}>
                  {t('profileSettings.answer')}
                </Paragraph>
                <Paragraph>**********</Paragraph>
              </SingleColumnList>
            </ProfileSettingsItem>
          </>
        )}
      </ProfileSettingsCard>

      <EditFullNameModal
        isOpen={isEditFullNameOpen}
        onClose={() => setIsEditFullNameOpen(false)}
      />
      <ChangePasswordModal
        isOpen={isChangePasswordOpen}
        onClose={() => setIsChangePasswordOpen(false)}
      />
      <ChangeSecurityQuestionModal
        isOpen={isChangeSecurityQuestionOpen}
        onClose={() => setIsChangeSecurityQuestionOpen(false)}
      />
    </>
  );
};

ProfileSettings.propTypes = {
  FirstName: PropTypes.string,
  LastName: PropTypes.string,
  MiddleName: PropTypes.string,
  Username: PropTypes.string,
  Email: PropTypes.string,
  SecurityQuestionType: PropTypes.string,
  SuffixType: PropTypes.string,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
};

export default ProfileSettings;
