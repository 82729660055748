import { variables } from '../variables';
import AvenirNextBold from '../../fonts/HardAssetsAlliance/AvenirNext-Bold.woff2';
import AvenirNextDemiBold from '../../fonts/HardAssetsAlliance/AvenirNext-DemiBold.woff2';
import AvenirNextMedium from '../../fonts/HardAssetsAlliance/AvenirNext-Medium.woff2';
import AvenirNextRegular from '../../fonts/HardAssetsAlliance/AvenirNext-Regular.woff2';

export const contosoColors = {
  colorPrimary: '#187a7c',
  colorPrimaryLight: '#64b3a9',
  colorPrimaryDark: '#135d5e',
  colorWhite: '#ffffff',
  colorBlack: '#000000',
  colorRed: '#FF5028',
  colorGrey: '#f4f4f4',

  colorBackgroundPrimary: '#ffffff',
  colorBackgroundSecondary: '#fafafa',
  colorWarningnBackground: '#FFECB5',

  colorMainHeaderBackground: '#FFFFFF',
  colorSecondaryHeaderBackground: '#FFFFFF',
  colorFooterBackground: '#187a7c',
  colorFooterText: '#fff',

  colorTextPrimary: '#003246',
  colorTextSecondary: '#8b8b8b',
  colorTextSubtitlePrimary: '#003246',
  colorTextSubtitleSecondary: '#187a7c',
  colorTextContrast: '#003246',
  colorTextHeader: '#ffffff',
  colorTextLink: '#003246',

  // Button Colors
  colorButtonPrimary: '#187a7c',
  colorButtonPrimaryText: '#fff',
  colorButtonPrimaryHover: '#64b3a9',
  colorButtonPrimaryFocused: '#135d5e',
  colorButtonPrimaryHoverText: '#ffffff',
  colorButtonOutlined: '#fff',
  colorButtonOutlinedBorder: '#187a7c',
  colorButtonOutlinedText: '#187a7c',
  colorButtonWhite: '#fff',
  colorButtonWhiteText: '#8b8b8b',
  colorButtonWhiteTextDisabled: '#c2c5c6',
  colorButtonWhiteBorder: '#c9d6db',
  colorButtonDisabled: '#80B5E1',

  colorLinkPrimary: '#187a7c',
  colorLinkSecondary: '#187a7c',
  colorLinkThin: '#8b8b8b',
  colorLinkRegistration: '#003246', // semiDark'
  colorButtonAddNewBank: '#ffffff',

  colorInputBorder: '#c9d6db',
  colorInputBorderFocused: '#187a7c',
  colorInputBorderActive: '#c9d6db',
  colorInputBackgroundDisabled: 'rgba(201,214,219,0.25)',
  colorInputText: '#4e7a8c',
  colorInputPlaceholder: '#4e7a8c',
  colorInputSelectItemFocused: '#f4f4f4',

  // Label
  colorLabel: '#4E7A8C',

  colorSuccess: '#09846b',
  colorError: '#FF5028',

  // colorTitleText: '',
  colorNoteAlertText: '#187a7c',
  colorInfoBannerBackground: '#003246',
  colorInfoBannerText: '#ffffff',
  // colorListBackground: '',
  // colorListText: '',

  colorTabBackground: '#ffffff',
  colorTabText: '#187a7c',
  colorTabSelectedBackground: '#187a7c',
  colorTabSelectedText: '#ffffff',

  colorProgressBarDefault: '#F4F4F4',
  colorProgressBarFilled: '#187a7c',
  colorLinearGradientStart: '#FFC45E',
  colorLinearGradientEnd: '#D36E1C',

  colorPillsBackground: '#EBF2F2',
  colorPillsText: '#003246',
  colorPillsBackgroundActive: '#003246',
  colorPillsTextActive: '#ffffff',

  colorIconPrimary: '#003246',
  colorIconSecondary: '#187a7c',

  colorRadioButton: '#003246',
  // colorRadioButtonWrapperBorderSelected: '#006bc3',
  colorCheckboxText: '#4E7A8C',
  colorRadioButtonSecondary: '#187a7c',

  colorDropdownBorder: '#C9D6DB',
  colorDropdownBorderOpen: '#187a7c',
  colorDropdownItemText: '#003246',

  colorBorderPrimary: '#c9d6db',
  colorBorderSecondary: '#187a7c',

  colorBorderSelectedRowPrimary: '#187a7c',
  colorBackgroundSelectedRowPrimary: 'rgba(226,147,10,0.14)',

  colorModalBackground: 'rgba(0, 0, 0, 0.2)',

  colorOnboardingBoxBorder: '#f3f3f3',
  colorOnboardingBoxBackground: 'rgba(196, 196, 196, 0.2)',
  colorOnboardingTextPrimary: '#ffffff',

  colorEmptyBoxBackgroundPrimary: '#ffffff',
  colorEmptyBoxTextPrimary: '#C2C5C6',
  colorEmptyBoxBackgroundSecondary: 'rgba(201, 214, 219, 0.25)',

  colorTooltipBackground: '#003246',
  colorTooltipText: '#ffffff',

  colorSwitchActive: '#187a7c',

  colorLoaderIcon: '#187a7c',

  colorGraphicPrimary: '#187a7c',
  colorGraphicGridLines: '#EBEFF2',

  colorShadowHeader: '#808080',

  colorPasswordStrengthDefault: '#EBF2F2',
  colorPasswordStrengthWeak: '#FF5028',
  colorPasswordStrengthAverage: '#FDB942',
  colorPasswordStrengthGood: '#06BEE7',
  colorPasswordStrengthStrong: '#00876A',

  colorFilterItemBackground: '#187a7c',
  colorFilterItemText: '#ffffff',
  colorFilterIcon: '#ffffff',

  // MakeOver Banner
  colorMakeOverBannerBackground: '#187a7c',
  colorMakeOverBanner: '#ffffff',

  // Navigation Dropdown Color
  colorNavigationDropdownBackground: 'rgba(32, 38, 43, 0.9)',
  colorNavigationDropdownItemHoverBackground: 'rgba(7, 44, 100, 0.5)',

  // Table Colors
  colorTableHeaderBackground: '#f4f4f4',
  colorTableEvenRowBackground: '#fafafa',
  colorTableEvenRowHoverBackground: '#f4f4f4',
  colorTableSelectedBackground: 'rgba(226, 147, 10, 0.14)',
  colorHeadingRowItem: '#F4F4F4',

  // Card Colors
  colorCardBackground: '#ffffff',
  colorCardSelected: 'rgba(226, 147, 10, 0.14)',

  // Shippment Status Colors
  colorShippmentSuccess: '#09846b',
  colorShippmentPending: '#E2930A',
  colorShippmentShipped: '#024f86',
  colorShippmentFailed: '#ff5028',

  // Address Status Colors
  colorVerifiedSuccess: '#09846b',
  colorNotVerified: '#8E8E8E',

  // Scrollbar Colors
  colorScrollbarBackground: '#c2c5c6',

  // Account Colors
  colorAccountBackground: '#FFFFF',
  colorAccountBorder: '#dde5e7',

  // Auth card
  colorAuthCardSubtitle: '#187a7c',
  colortAuthCard: '#FFFF',

  // Registration
  colorRegistration: '#2B5769',

  // Pie chart
  colorPieOne: '#003246',
  colorPieTwo: '#187a7c',
  colorPieThree: '#4E7A8C',
  colorPieFour: '#808285',
};

export const contosoFonts = `
  @font-face {
    font-family: "Segoe UI", ${variables.family.contoso}, sans-serif;
    src: url(${AvenirNextBold}) format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Segoe UI", ${variables.family.contoso}, sans-serif;
    src: url(${AvenirNextDemiBold}) format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Segoe UI", ${variables.family.contoso}, sans-serif;
    src: url(${AvenirNextMedium}) format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Segoe UI", ${variables.family.contoso}, sans-serif;
    src: url(${AvenirNextRegular}) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  *,
  *::after,
  *::before {
    font-family:  "Segoe UI", ${variables.family.contoso},sans-serif;
  }
`;
