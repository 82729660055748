import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  patchPortfolioFinancialPendingTransactions,
  portfolioFinancialPendingTransactions,
} from '../../../store/actions/portfolio/portfolioActions';
import { PATCH_PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_LOADING } from '../../../store/actions/portfolio/portfolioActionConstants';
import { selectIsLoadingByActionType } from '../../../store/selectors/loadingSelectors';
import SectionLoader from '../../Loader/SectionLoader';
import ModalButtons from '../../Modal/ModalButtons';
import Paragraph from '../../Paragraph/Paragraph';
import ModalBody from '../../Modal/ModalBody';

const CancelPendingTransaction = ({
  setError,
  handleNext,
  handleClose,
  cancelTransactionData,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { paymentUid, accountUid } = cancelTransactionData;
  const isLoading = useSelector(
    selectIsLoadingByActionType(
      PATCH_PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_LOADING,
    ),
  );

  const refreshTransactions = () => {
    dispatch(
      portfolioFinancialPendingTransactions({
        accountUid,
      }),
    );
  };
  const handleCancelPendingTransaction = () => {
    dispatch(
      patchPortfolioFinancialPendingTransactions({
        data: [{ op: 'replace', path: '/RequestStatus', value: 'Canceled' }],
        accountUid,
        depositKey: paymentUid,
        handleNext,
        setError,
        refreshTransactions,
      }),
    );
  };

  return (
    <SectionLoader isLoading={isLoading}>
      <ModalBody>
        <Paragraph>{t('portfolio.pendingTransactions.modal.text')}</Paragraph>
        <ModalButtons
          isHorizontal
          marginTop
          secondaryButtonProps={{
            onClick: handleClose,
            label: t('common.cancel'),
          }}
          primaryButtonProps={{
            onClick: handleCancelPendingTransaction,
            label: t('common.confirm'),
          }}
        />
      </ModalBody>
    </SectionLoader>
  );
};

CancelPendingTransaction.propTypes = {
  setError: PropTypes.func,
  handleNext: PropTypes.func,
  handleClose: PropTypes.func,
  cancelTransactionData: PropTypes.shape({
    paymentUid: PropTypes.string,
    accountUid: PropTypes.string,
  }),
};

export default CancelPendingTransaction;
