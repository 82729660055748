import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import isEmpty from 'lodash.isempty';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import NumberField from '../../../InputFields/NumberField';
import SelectField from '../../../InputFields/SelectField';
import { selectAccountAddresses } from '../../../../store/selectors/accountSelectors';
import {
  FRACTIONAL_CONVERSION,
  TT_BUY_FOR_DELIVERY,
} from '../../../../util/enum/api/transactionTypes';
import { verifyDeliverFromStorageData } from '../../../../store/actions/products/productActions';
import { formatMoneyNumeral } from '../../../../util/helpers/numeralHelpers';

import SectionLoader from '../../../Loader/SectionLoader';
import {
  closeWizardContent,
  deleteWizardKey,
  getWizardContent,
  setWizardContent,
} from '../../../../util/helpers/wizardHelpers';
import NoAddressModal from '../../WithdrawalWizard/NoAddressModal';
import {
  DELIVERY_ADDRESS,
  DELIVERY_QUANTITY,
  IS_NO_ADDRESS_MODAL_OPEN,
  WIZARD_DATA,
} from '../../../../constants/sessionStorage';
import { selectIsLoadingByActionType } from '../../../../store/selectors/loadingSelectors';
import { FETCH_ACCOUNT_ADDRESSES_LOADING } from '../../../../store/actions/account/accountActionConstants';
import { useSessionStorageState } from '../../../../util/hooks/useSessionStorageState';
import { formatAddressString } from '../../../../util/helpers/addressHelper';
import { quantityAndAddressValidationSchema } from '../../../../validation/quantityAndAddressValidationSchema';
import ModalTitle from '../../../Modal/ModalTitle';
import Paragraph from '../../../Paragraph/Paragraph';
import ModalButtons from '../../../Modal/ModalButtons';
import FormContainer from '../../../FormContainer/FormContainer';
import Label from '../../../Notes/Label';
import ModalBody from '../../../Modal/ModalBody';
import Note from '../../../Notes/Note';
import { parseEnumType } from '../../../../util/helpers/enumMappers';
import {
  productLocationTypes,
  productLocationUSType,
} from '../../../../util/enum/api/productTypes';
import themeColors from '../../../../assets/styles/themeColors';
import { buttonizeDiv } from '../../../../util/buttonizeDiv';
import SupportModal from '../../SupportModal/SupportModal';
import { pxToRem } from '../../../../assets/styles/helper';
import ConvertToForm from './ConvertToForm';
import { retrieveFromSessionStorage } from '../../../../util/helpers/sessionStorageHelper';
import { deliveryGtmHelper } from '../../../../util/helpers/gtmHelper';
import useGtmHook from '../../../../util/hooks/useGtmHook';
import { ADD_TO_CART, SELECT_ITEM } from '../../../../constants/gtmEvents';

const NoAddressModalWrap = styled(NoAddressModal)`
  background-color: transparent;
`;
const Error = styled.div`
  dispay: flex;
  color: ${themeColors.colorRed};
  font-size: ${pxToRem(16)};
  line-height: 1.35;
  font-weight: 500;
`;
export const RegistrationLoginLink = styled.a`
  color: ${themeColors.colorRed};
  text-decoration: underline;
  font-size: ${pxToRem(16)};
  line-height: 1.35;
  font-weight: 500;
`;
const AddressAndQuantityForm = ({
  product,
  setModalExtended,
  goStepForward,
  accountUid,
  funds,
  onClose,
  setDeliveryAddress,
  setUnitsToDeliver,
  isInstaVaultProduct,
  isSegregated,
  isFromFractionalLocation,
  account,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const addresses = useSelector(selectAccountAddresses);
  const verifiedAddresses = addresses.filter((address) => address.IsVerified);
  const addressesLoading = useSelector(
    selectIsLoadingByActionType(FETCH_ACCOUNT_ADDRESSES_LOADING),
  );
  const [wizardData, setWizardData] = useState(
    () => retrieveFromSessionStorage(WIZARD_DATA) || {},
  );
  const ref = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [
    isNoAddressModalOpen,
    setisNoAddressModalOpen,
  ] = useSessionStorageState(IS_NO_ADDRESS_MODAL_OPEN, false);
  const [isSupportModalOpen, setSupportModalOpen] = useState(false);
  const [refValues, setRefValues] = useState(null);
  const productLocation = parseEnumType(
    productLocationTypes,
    product?.LocationType,
  );
  const { ecommerceGtmEvent } = useGtmHook();

  useEffect(() => {
    if (ref.current !== null) {
      setRefValues(ref?.current?.values);
    }
  }, [ref]);

  useEffect(() => {
    deliveryGtmHelper({
      ecommerceGtmEvent,
      eventName: SELECT_ITEM,
      product,
      account,
    });
  }, []); //eslint-disable-line

  const addressInitialValue = useMemo(() => {
    const defaultAddress = verifiedAddresses.find(
      (address) => address.IsDefaultMailingAddress,
    );

    if (defaultAddress) {
      const { AddressUid } = defaultAddress;
      return {
        value: AddressUid,
        label: formatAddressString(defaultAddress),
      };
    }

    return {
      label: t('common.select'),
      value: t('common.select'),
    };
  }, [verifiedAddresses, t]);

  const addressesOptions = verifiedAddresses.map((address) => ({
    value: address?.AddressUid,
    label: formatAddressString(address),
  }));

  const handleOpenNoAddressModal = () => {
    setWizardContent(IS_NO_ADDRESS_MODAL_OPEN, true);
    setisNoAddressModalOpen(true);
  };

  const displayError = () => {
    const addressUid = refValues?.address?.value;

    if (addressUid !== t('common.select')) {
      const fullAddress = verifiedAddresses?.filter(
        (selectedAddress) => selectedAddress.AddressUid === addressUid,
      )[0];

      const isSelectedAddressEnabled =
        fullAddress?.CountryIso3Code === 'USA' &&
        productLocationUSType.includes(
          parseEnumType(productLocationTypes, product.LocationType),
        );

      if (isSelectedAddressEnabled) {
        return;
      }

      return (
        <Error>
          {t('product.requestDelivery.locationValidation1')}
          <RegistrationLoginLink
            {...buttonizeDiv(() => {
              setSupportModalOpen(true);
            })}
          >
            {t('product.requestDelivery.locationValidation2')}
          </RegistrationLoginLink>
          {t('product.requestDelivery.locationValidation3')}
        </Error>
      );
    }
  };

  const onFormSubmit = (
    { quantity, address },
    { setFieldError },
    goStepForward,
  ) => {
    if (isInstaVaultProduct) {
      dispatch(
        verifyDeliverFromStorageData({
          accountUid,
          verifyData: {
            Quantity: quantity,
            SymbolCode: product.SymbolCode,
            AddressUid: address.value,
            AccountUid: accountUid,
            ConversionSymbolCode: wizardData?.ProductConfiguration?.SymbolCode,
            Type: FRACTIONAL_CONVERSION,
          },
          setFieldError,
          setModalExtended,
          goStepForward,
          setDeliveryAddress,
          setUnitsToDeliver,
          setIsLoading,
          address,
        }),
      );
      return;
    }
    dispatch(
      verifyDeliverFromStorageData({
        accountUid,
        verifyData: {
          Quantity: quantity,
          SymbolCode: product.SymbolCode,
          AddressUid: address.value,
          AccountUid: accountUid,
          Type: TT_BUY_FOR_DELIVERY,
          IsSegregated: isSegregated,
        },
        setFieldError,
        setModalExtended,
        goStepForward,
        setDeliveryAddress,
        setUnitsToDeliver,
        setIsLoading,
        address,
      }),
    );
    deliveryGtmHelper({
      ecommerceGtmEvent,
      eventName: ADD_TO_CART,
      product,
      account,
    });
  };

  const initialValues = {
    address: getWizardContent(DELIVERY_ADDRESS) || addressInitialValue,
    quantity: getWizardContent(DELIVERY_QUANTITY) || '',
  };

  if (isNoAddressModalOpen) {
    return (
      <NoAddressModalWrap
        overModal
        isOpen={isNoAddressModalOpen}
        handleClose={() => {
          closeWizardContent();
          onClose();
        }}
        handleBack={() => {
          deleteWizardKey(IS_NO_ADDRESS_MODAL_OPEN);
          setisNoAddressModalOpen(false);
        }}
      />
    );
  }

  return (
    <SectionLoader isLoading={isLoading || addressesLoading}>
      <ModalBody>
        <ModalTitle>{product?.ProductCaption}</ModalTitle>
        <Label
          marginBottom={4}
          isBold
          text={t('product.requestDelivery.unitsAvailableDelivery')}
        />
        <Paragraph marginBottom={8}>
          {product?.UnitsAvailableForDelivery}
        </Paragraph>
        <Label
          marginBottom={4}
          isBold
          text={t('product.requestDelivery.fundsAvailableDelivery')}
        />
        <Paragraph marginBottom={24}>{formatMoneyNumeral(funds)}</Paragraph>
        <Formik
          innerRef={ref}
          initialValues={initialValues}
          onSubmit={(values, form) => onFormSubmit(values, form, goStepForward)}
          validationSchema={quantityAndAddressValidationSchema(
            product?.UnitsAvailableForDelivery,
          )}
          initialTouched={{
            quantity: true,
          }}
          enableReinitialize
        >
          {({ values }) => {
            const isNextButtonDisabled =
              !(
                values.quantity &&
                values.quantity <= product?.UnitsAvailableForDelivery
              ) ||
              values.address.value ===
                t('product.requestDelivery.noAddedAddresses') ||
              (!isFromFractionalLocation && displayError()) ||
              (isInstaVaultProduct &&
                !wizardData.ProductConfiguration?.SymbolCode);
            return (
              <Form>
                <FormContainer>
                  <div>
                    <SelectField
                      name="address"
                      label={t('product.requestDelivery.deliverTo')}
                      disabled={isEmpty(verifiedAddresses)}
                      options={addressesOptions}
                      link={
                        <Note
                          onClick={handleOpenNoAddressModal}
                          text={t(
                            'standardWithdrawalWizard.checkWithdrawal.dontSeeAddress',
                          )}
                        />
                      }
                    />

                    {productLocationUSType.includes(productLocation) &&
                      displayError()}
                  </div>
                  <div>
                    <Field
                      component={NumberField}
                      label={t('product.requestDelivery.quantityLabel')}
                      name="quantity"
                      placeholder={0}
                      centerText
                    />

                    {!isFromFractionalLocation &&
                      !productLocationUSType.includes(productLocation) &&
                      displayError()}
                  </div>
                  {isInstaVaultProduct && (
                    <ConvertToForm
                      metalType={product?.MetalType === 1 ? 'gold' : 'silver'}
                      wizardData={wizardData}
                      setWizardData={setWizardData}
                    />
                  )}
                </FormContainer>
                <ModalButtons
                  marginTop
                  isHorizontal
                  secondaryButtonProps={{
                    onClick: () => {
                      closeWizardContent();
                      onClose();
                    },
                    label: t('common.cancel'),
                  }}
                  primaryButtonProps={{
                    disabled: isNextButtonDisabled,
                    type: 'submit',
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </ModalBody>
      <SupportModal isOpen={isSupportModalOpen} close={setSupportModalOpen} />
    </SectionLoader>
  );
};

AddressAndQuantityForm.propTypes = {
  product: PropTypes.shape({
    SymbolCode: PropTypes.string,
    ProductCaption: PropTypes.string,
    LocationType: PropTypes.string,
    UnitsAvailableForDelivery: PropTypes.number,
    MetalType: PropTypes.number,
  }),
  setModalExtended: PropTypes.func,
  goStepForward: PropTypes.func,
  accountUid: PropTypes.string,
  funds: PropTypes.number,
  verifyData: PropTypes.shape({
    DeliveryCosts: PropTypes.number,
    SalesTax: PropTypes.number,
    UnassessedStorageFees: PropTypes.number,
  }),
  onClose: PropTypes.func,
  setDeliveryAddress: PropTypes.func,
  setUnitsToDeliver: PropTypes.func,
  isInstaVaultProduct: PropTypes.bool,
  isSegregated: PropTypes.bool,
  isFromFractionalLocation: PropTypes.bool,
  account: PropTypes.shape({}),
};

export default AddressAndQuantityForm;
