import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import {
  getProductsWithPrices,
  getProductTiers,
  getProductSymbolPrices,
  verifyDeliverFromStorage,
  submitDeliverFromStorageRequest,
  getConvertProductRequest,
} from '../../request/productsRequest';
import {
  PRODUCTS_PRICES_FETCH,
  PRODUCT_TIERS_FETCH,
  PRODUCT_SYMBOL_PRICES_FETCH,
  VERIFY_DELIVER_FROM_STORAGE_DATA,
  DELIVER_FROM_STORAGE_SUBMIT,
  CONVERT_PRODUCT_FETCH,
} from '../actions/products/productActionConstants';
import {
  fetchProductsWithPricesError,
  fetchProductsWithPricesSuccess,
  fetchProductTiersError,
  fetchProductTiersSuccess,
  fetchProductSymbolPricesError,
  fetchProductSymbolPricesSuccess,
  verifyDeliverFromStorageDataSuccess,
  verifyDeliverFromStorageDataError,
  fetchConvertProductSuccess,
  fetchConvertProductError,
} from '../actions/products/productActions';
import { rejectErrorCodeHelper } from '../../util/helpers/rejectErrorCodeHelper';
import i18next from '../../i18n';
import { setWizardContent } from '../../util/helpers/wizardHelpers';
import {
  DELIVERY_ADDRESS,
  DELIVERY_QUANTITY,
  DELIVERY_VERIFIED_DATA,
  DELIVERY_VERIFY_DATA,
  MODAL_EXTENDED,
} from '../../constants/sessionStorage';

function* fetchProductPrices({ payload }) {
  try {
    const { data } = yield call(
      getProductsWithPrices,
      payload.accountUid,
      payload.side,
      payload.symbols,
      payload.productType,
    );
    yield put(fetchProductsWithPricesSuccess(data));
  } catch (e) {
    const errorMessage = yield call(rejectErrorCodeHelper, e);
    yield put(fetchProductsWithPricesError(errorMessage));
  }
}

function* fetchProductTiers({ payload }) {
  try {
    const { data } = yield call(
      getProductTiers,
      payload.accountUid,
      payload.symbol,
      payload.side,
      payload.location,
      !!payload?.trueTiers,
    );
    yield put(fetchProductTiersSuccess(data));
    yield put(fetchProductTiersError(''));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchProductTiersError(errorMessage));
  }
}

function* fetchProductSymbolPrices({ payload }) {
  try {
    const { data } = yield call(
      getProductSymbolPrices,
      payload.accountUid,
      payload.symbol,
      payload.side,
    );
    yield put(fetchProductSymbolPricesSuccess(data));
    if (payload.onSuccess) {
      yield call(payload.onSuccess, data);
    }

    if (payload.side === 'Sell') {
      yield call(payload.setSellPrice, data.ProductPrice);
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchProductSymbolPricesError(errorMessage));
  }
}

function* verifyDeliverFromStorageData({ payload }) {
  const {
    accountUid,
    verifyData,
    setFieldError,
    setModalExtended,
    goStepForward,
    setDeliveryAddress,
    setUnitsToDeliver,
    address,
    setIsLoading,
    isSegregated,
  } = payload;
  try {
    yield call(setIsLoading, true);
    const { data } = yield call(
      verifyDeliverFromStorage,
      accountUid,
      verifyData,
      isSegregated,
    );

    yield put(verifyDeliverFromStorageDataSuccess(data));
    yield put(verifyDeliverFromStorageDataError(''));
    if (data.ErrorCodeType === 5100) {
      setWizardContent(MODAL_EXTENDED, true);
      yield call(setModalExtended, true);
      return;
    }
    if (data.ErrorCodeType === 5101) {
      yield call(
        setFieldError,
        'quantity',
        i18next.t('product.requestDelivery.unitsInsufficient'),
      );
      return;
    }
    yield call(setDeliveryAddress, address);
    yield call(setUnitsToDeliver, verifyData.Quantity);
    setWizardContent(DELIVERY_VERIFIED_DATA, data);
    setWizardContent(DELIVERY_ADDRESS, address);
    setWizardContent(DELIVERY_QUANTITY, verifyData.Quantity);
    setWizardContent(DELIVERY_VERIFY_DATA, verifyData);
    yield call(goStepForward);
  } catch (error) {
    const errorCode = error?.response?.data?.Errors[0]?.Code;
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    if (errorCode !== 'Order_LocationForDeliveryNotAllowed') {
      yield call(setFieldError, 'quantity', errorMessage);
    }

    yield put(verifyDeliverFromStorageDataError(errorMessage));
    if (payload.onError) {
      yield call(payload.onError, errorCode);
    }
  } finally {
    yield call(setIsLoading, false);
  }
}

function* submitDeliverFromStorage({ payload }) {
  const {
    accountUid,
    dfsData,
    handleApiResponse,
    setConfirmLoading,
    isInstaVaultProduct,
  } = payload;
  yield call(setConfirmLoading, true);

  try {
    yield call(submitDeliverFromStorageRequest, accountUid, dfsData);
    yield call(
      handleApiResponse,
      false,
      isInstaVaultProduct
        ? i18next.t('product.requestDelivery.orderPlacedInstavault')
        : i18next.t('product.requestDelivery.orderPlaced'),
      dfsData,
    );
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield call(handleApiResponse, true, errorMessage);
  } finally {
    yield call(setConfirmLoading, false);
  }
}

function* fetchConvertProducts({ payload }) {
  try {
    const { data } = yield call(getConvertProductRequest, payload);
    yield put(fetchConvertProductSuccess(data));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchConvertProductError(errorMessage));
  }
}

export default function* productsSaga() {
  yield all([
    takeLatest(PRODUCTS_PRICES_FETCH, fetchProductPrices),
    takeLatest(PRODUCT_TIERS_FETCH, fetchProductTiers),
    takeLatest(PRODUCT_SYMBOL_PRICES_FETCH, fetchProductSymbolPrices),
    takeLatest(VERIFY_DELIVER_FROM_STORAGE_DATA, verifyDeliverFromStorageData),
    takeLatest(DELIVER_FROM_STORAGE_SUBMIT, submitDeliverFromStorage),
    takeLatest(CONVERT_PRODUCT_FETCH, fetchConvertProducts),
  ]);
}
