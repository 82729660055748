export function buttonizeDiv(handlerFn) {
  return {
    role: 'button',
    onClick: handlerFn,
    onKeyDown: (event) => {
      // insert your preferred method for detecting the keypress
      if (event.keycode === 13) handlerFn(event);
    },
  };
}
