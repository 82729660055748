import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { useSelector } from 'react-redux';
import { ReactComponent as Caret } from '../../../assets/images/svg/down.svg';
import { buttonizeDiv } from '../../../util/buttonizeDiv';
import { PORTFOLIO_PRODUCT_PAGE } from '../../../constants/pages';
import { formatMoneyNumeral } from '../../../util/helpers/numeralHelpers';
import config from '../../../config';
import { selectSettings } from '../../../store/selectors/settingsSelectors';
import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import themeColors from '../../../assets/styles/themeColors';
import { variables } from '../../../assets/styles/variables';
import {
  uFlexColumn,
  uLineClamp,
  uTextEllipsis,
} from '../../../assets/styles/utility';

const HoldingTableRowContainer = styled.div`
  padding: ${pxToRem(16)};
  border-radius: ${variables.borderRadius.borderRadius};
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr auto ${pxToRem(10)};
  grid-gap: ${pxToRem(8)};

  &:nth-child(even) {
    background-color: ${themeColors.colorTableEvenRowBackground};
  }

  &:hover {
    background-color: ${themeColors.colorTableEvenRowHoverBackground};
    box-shadow: ${variables.shadow.boxShadow};
    z-index: ${variables.zIndex.indexXxs};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: ${pxToRemMd(16)};
  }
`;

const HoldingTableTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HoldingTableRowImageWrap = styled.div`
  margin-right: ${pxToRem(16)};
  display: flex;
  align-items: center;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    margin-right: ${pxToRemMd(16)};
  }
`;

const HoldingTableRowImage = styled.img`
  width: ${pxToRem(48)};
  height: ${pxToRem(48)};
  object-fit: contain;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    width: ${pxToRemMd(48)};
    height: ${pxToRemMd(48)};
  }
`;

const HoldingTableRowInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  grid-column-gap: ${pxToRem(16)};
  align-items: center;
  flex-grow: 1;

  ${mediaBelow(variables.breakpoints.bpSm)} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
`;

const HoldingTableRowTitle = styled.p`
  ${uLineClamp};
  letter-spacing: 0;
  line-height: 1.5;
  text-align: left;
  font-weight: 600;

  ${mediaBelow(variables.breakpoints.bpSm)} {
    margin-bottom: ${pxToRemMd(12)};
    ${uLineClamp};
  }
`;

const HoldingTableRowDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${pxToRem(16)};
`;

const HoldingTableRowValue = styled.p`
  ${uTextEllipsis};
  font-weight: 600;
  font-size: ${pxToRem(16)};
  line-height: 1.5;
  letter-spacing: 0;
  width: 100%;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(12)};
    line-height: 1.3;
  }
`;

const HoldingTableRowQuantity = styled.div`
  ${uFlexColumn};
  flex-shrink: 0;

  ${mediaBelow(variables.breakpoints.bpSm)} {
    flex-direction: row;

    ${HoldingTableRowValue} {
      text-align: left;
    }
  }
`;

const HoldingTableRowLabel = styled.p`
  font-size: ${pxToRem(14)};
  line-height: 1.57;
  letter-spacing: 0;
  flex-shrink: 0;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(12)};
    line-height: 1.3;
  }

  ${mediaBelow(variables.breakpoints.bpSm)} {
    margin-right: ${pxToRemMd(4)};
  }
`;

const HoldingTableRowValuation = styled.div`
  ${uFlexColumn};
  min-width: 0;

  ${mediaBelow(variables.breakpoints.bpSm)} {
    flex-direction: row;

    ${HoldingTableRowValue} {
      text-align: left;
    }
  }
`;

const HoldingTableRowIcon = styled.div`
  align-self: center;
  justify-self: flex-end;

  svg {
    transform: rotate(-90deg);
    color: ${themeColors.colorLabel};
    flex-shrink: 0;
  }

  ${mediaBelow(variables.breakpoints.bpSm)} {
    margin-left: ${pxToRemMd(14)};
  }
`;

const HoldingTableRow = ({
  title,
  quantity,
  value,
  symbol,
  productCode,
  isTestProduct,
  isSegregated,
  isSortByVault,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { RootUrl, SegregatedCaption } = useSelector(selectSettings);
  const handleRowClick = () => {
    history.push({
      pathname: PORTFOLIO_PRODUCT_PAGE.replace(':symbol', symbol),
      state: { isSegregated },
    });
  };

  const imageUrl = config.algoliaSearch.imageCode
    .replace('{product_code}', productCode)
    .replace('{base_url}', RootUrl);

  const renderTitle = (isTestProduct, isSegregated) => {
    if (isTestProduct) {
      return `${title} (test)`;
    }
    if (isSegregated && !isSortByVault) {
      return `${title} (${SegregatedCaption})`;
    }
    return title;
  };

  return (
    <HoldingTableRowContainer
      {...buttonizeDiv(handleRowClick)}
      data-cy="list-item-holdings-vault-product"
    >
      <HoldingTableRowInfo>
        <HoldingTableTitleContainer>
          <HoldingTableRowImageWrap>
            <HoldingTableRowImage
              src={imageUrl}
              alt="Product Name Value Etc..."
            />
          </HoldingTableRowImageWrap>
          <HoldingTableRowTitle>
            {renderTitle(isTestProduct, isSegregated)}
          </HoldingTableRowTitle>
        </HoldingTableTitleContainer>
        <HoldingTableRowDetails>
          <HoldingTableRowQuantity data-cy="container-holdings-vault-product-quantity">
            <HoldingTableRowLabel>
              {t('portfolio.holdings.quantityLabel')}
            </HoldingTableRowLabel>
            <HoldingTableRowValue>{quantity}</HoldingTableRowValue>
          </HoldingTableRowQuantity>
          <HoldingTableRowValuation>
            <HoldingTableRowLabel>
              {t('portfolio.holdings.valuationLabel')}
            </HoldingTableRowLabel>
            <HoldingTableRowValue>
              {formatMoneyNumeral(value)}
            </HoldingTableRowValue>
          </HoldingTableRowValuation>
        </HoldingTableRowDetails>
      </HoldingTableRowInfo>
      <HoldingTableRowIcon>
        <Caret />
      </HoldingTableRowIcon>
    </HoldingTableRowContainer>
  );
};

HoldingTableRow.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  quantity: PropTypes.number,
  symbol: PropTypes.string,
  productCode: PropTypes.string,
  isTestProduct: PropTypes.bool,
  isSegregated: PropTypes.bool,
  isSortByVault: PropTypes.bool,
};

export default HoldingTableRow;
