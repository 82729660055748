import React from 'react';
import { useTranslation } from 'react-i18next';

const SafariPopUpNote = () => {
  const { t } = useTranslation();

  return (
    <>
      <ul>
        <li>{t('statements.popUp.safari.list.1')}</li>
        <li>{t('statements.popUp.safari.list.2')}</li>
        <li>{t('statements.popUp.safari.list.3')}</li>
      </ul>

      <p>{t('statements.popUp.safari.note')}</p>
    </>
  );
};

export default SafariPopUpNote;
