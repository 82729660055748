import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Modal from '../../Modal/Modal';
import StepByStep from '../../StepByStep';
import {
  removeFromSessionStorage,
  retrieveFromSessionStorage,
  storeInSessionStorage,
} from '../../../util/helpers/sessionStorageHelper';
import {
  IRA_DEPOSIT_WIZARD,
  WIZARD,
  WIZARD_DATA,
} from '../../../constants/sessionStorage';
import IraFundTypes from '../IRA/IraFund/IraFundTypes';
import IraDepositType from '../IRA/IraFund/IraDepositType';
import PopUpInstructions from '../PopUpInstructions/PopUpInstructions';
import { getCurrentAccountUid } from '../../../store/selectors/accountSelectors';
import { getIraSettings } from '../../../store/selectors/iraSettingsSelectors';
import { selectIsLoadingByActionType } from '../../../store/selectors/loadingSelectors';
import { FETCH_ACCOUNT_IRA_SETTINGS_LOADER } from '../../../store/actions/iraSettings/iraSettingsActionConstants';
import { fetchAccountIraSettings } from '../../../store/actions/iraSettings/iraSettingsActions';

const steps = [IraFundTypes, IraDepositType, PopUpInstructions];

const IraDepositWizard = ({ type, isModalOpen, handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accountUid = useSelector(getCurrentAccountUid);
  const iraSettings = useSelector(getIraSettings);
  const isLoading = useSelector(
    selectIsLoadingByActionType(FETCH_ACCOUNT_IRA_SETTINGS_LOADER),
  );

  const [wizardData, setWizardData] = useState(
    () => retrieveFromSessionStorage(WIZARD_DATA) || {},
  );
  const [isDocumentLoading, setIsDocumentLoading] = useState(false);

  const setDataForWizard = (value) => {
    if (isEmpty(wizardData)) {
      setWizardData({ ...value });
      storeInSessionStorage(WIZARD_DATA, { ...value });
      return;
    }

    setWizardData({ ...wizardData, ...value });
    storeInSessionStorage(WIZARD_DATA, { ...wizardData, ...value });
  };

  const closeModalHandle = () => {
    handleClose();
    removeFromSessionStorage(WIZARD_DATA);
  };

  useEffect(() => {
    if (isModalOpen && accountUid) {
      dispatch(
        fetchAccountIraSettings({
          accountUid,
        }),
      );
    }
  }, [accountUid, dispatch, isModalOpen]);

  const { wizardHandleBack, wizardTitle, wizardSize } = wizardData;

  return (
    <Modal
      title={wizardTitle || t('depositWizard.fundYourIra')}
      size={wizardSize || 'sm'}
      isOpen={isModalOpen}
      close={closeModalHandle}
      goBack={wizardHandleBack}
      isLoading={isLoading || isDocumentLoading}
    >
      <StepByStep sessionScopeStep={IRA_DEPOSIT_WIZARD}>
        {({ goStepForward, goStepBack }) =>
          steps.map((iraDepositStep) =>
            React.createElement(iraDepositStep, {
              handleBack: goStepBack,
              handleNext: goStepForward,
              handleClose: () => {
                closeModalHandle();
                removeFromSessionStorage(WIZARD);
              },
              wizardData,
              setWizardData: setDataForWizard,
              type,
              iraSettings,
              setIsDocumentLoading,
            }),
          )
        }
      </StepByStep>
    </Modal>
  );
};

IraDepositWizard.propTypes = {
  isModalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  type: PropTypes.string,
};

export default IraDepositWizard;
