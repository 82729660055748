import styled from 'styled-components';
import { uFlexColumn } from '../../assets/styles/utility';
import { variables } from '../../assets/styles/variables';
import { mediaBelow, pxToRem } from '../../assets/styles/helper';

const Table = styled.div`
  ${uFlexColumn};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${pxToRem(marginBottom)}` : 0};
  margin-top: ${({ marginTop }) => (marginTop ? `${pxToRem(marginTop)}` : 0)};

  ${(props) => props.css};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    ${({ isLocationTableMobile }) =>
      isLocationTableMobile &&
      `
      margin-bottom: 0;
    `};
  }
`;

export default Table;
