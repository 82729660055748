import i18next from 'i18next';
import * as Yup from 'yup';

export const addNewAddressValidationSchema = Yup.object().shape({
  CountryIso3Code: Yup.string().required(
    i18next.t('validationSchema.addCreditCardValidation.countryIsRequired'),
  ),
  AddressLine: Yup.string().required(
    i18next.t('validationSchema.addCreditCardValidation.addressIsRequired'),
  ),
  City: Yup.string().required(
    i18next.t('validationSchema.addCreditCardValidation.cityIsRequired'),
  ),
  StateRegion: Yup.string().required(
    i18next.t('validationSchema.addCreditCardValidation.stateRegionIsRequired'),
  ),
  PostalCode: Yup.string().required(
    i18next.t('validationSchema.addCreditCardValidation.PostalCodeIsRequired'),
  ),
});
