import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  ADDRESSES_PAGE,
  BANK_ACCOUNTS_PAGE,
  BUY_PAGE,
  EMAIL_LOGIN_PAGE,
  FINANCIAL_BALANCES_PAGE,
  IDENTITY_PAGE,
  METAL_STREAM_PAGE,
  PORTFOLIO_PAGE,
  PORTFOLIO_PRODUCT_PAGE,
  PROFILE_SETTINGS_PAGE,
  RESET_SECURITY_QUESTION_PAGE,
  RESET_SENT_PAGE,
  RESET_SUCCESSFUL_PAGE,
  SECURITY_QUESTION_PAGE,
  SELL_PAGE,
  STATEMENTS_PAGE,
  USER_LOGIN_PAGE,
  USER_MANAGEMENT_PAGE,
  IMPERSONATE_PAGE,
  RESET_PASSWORD,
  INVITE_USER_PAGE,
  INVITE_USER_WELCOME_PAGE,
  INVITE_USER_SECURITY_QUESTION,
  INVITE_USER_TWO_FA_VERIFY_IDENTITY,
  INVITE_USER_TWO_FA_VERIFY_IDENTITY_PHONE,
  INVITE_USER_TWO_FA_HAVING_TROUBLE,
  NOT_FOUND_PAGE,
  ERROR_PAGE,
  BASE_PAGE,
  REGISTER_LOGIN_PAGE,
  VERIFY_CHOOSE_METHOD_PAGE,
  TWO_FA_QR_CODE_PAGE,
  TWO_FA_CHOOSE_PHONE_METHOD,
  TWO_FA_VERIFY_CODE,
  TWO_FA_SUCCESSFUL_SETUP,
  TWO_FA_VERIFY_IDENTITY,
  TWO_FA_VERIFY_IDENTITY_PHONE,
  TWO_FA_HAVING_TROUBLE_PAGE,
  MAINTENANCE_PAGE,
  TWO_FA_FORGOT_PASSWORD_IDENTITY_HAVING_TROUBLE_PAGE,
  TWO_FA_FORGOT_PASSWORD_IDENTITY_PAGE,
  TWO_FA_FORGOT_PASSWORD_IDENTITY_PHONE_PAGE,
  INVITE_USER_IDENTITY,
  INVITE_USER_CREDENTIALS_PAGE,
  CREDIT_CARDS_PAGE,
  QUICK_REGISTRATION_PAGE,
  // STYLEGUIDE_PAGE,
  // LEGACY_ORDER_HISTORY_PAGE,
  INVITE_USER_TWO_FA_VERIFY_CHOOSE_METHOD,
  INVITE_USER_TWO_FA_VERIFY_CODE,
  INVITE_USER_TWO_FA_QR_CODE_PAGE,
  INVITE_USER_TWO_FA_CHOOSE_PHONE_METHOD,
  INVITE_USER_TWO_FA_SUCCESSFUL_SETUP,
} from './constants/pages';

import PrivateRoute from './components/Router/PrivateRoute';
import AuthRoute from './components/Router/AuthRoute';
import BuyPage from './pages/BuyPage';
import SellPage from './pages/SellPage';
import MetalStreamPage from './pages/MetalStreamPage';
import PortfolioPage from './pages/PortfolioPage/index';
import EmailLoginPage from './pages/LoginPage/EmailLoginPage';
import UserLoginPage from './pages/LoginPage/UserLoginPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import SecurityQuestionPage from './pages/LoginPage/SecurityQuestionPage';
import IdentityPage from './pages/IdentityPage';
import ResetSuccessfulPage from './pages/ResetSuccessfulPage';
import ResetSecurityQuestionPage from './pages/ResetSecurityQuestionPage';
import ResetSentPage from './pages/ResetSentPage';
import FinancialBalancesPage from './pages/FinancialBalances/FinancialBalancesPage';
import AddressesPage from './pages/Account/AddressesPage';
import BankAccountsPage from './pages/Account/BankAccountsPage';
import UserManagementPage from './pages/Account/UserManagementPage';
import ProfileSettingsPage from './pages/Account/ProfileSettingsPage';
import StatementsPage from './pages/Account/StatementsPage';
import PortfolioProductPage from './pages/PortfolioPage/ProductPage';
import ImpersonatePage from './pages/Impersonate/ImpersonatePage';
import InviteUserPage from './pages/UserInvite/InviteUserPage';
import NotFoundPage from './pages/UtilitiesPage/NotFoundPage';
import ErrorPage from './pages/UtilitiesPage/ErrorPage';
import RegistrationLoginPage from './pages/Impersonate/RegistrationLoginPage';
import TwoFASetupPage from './pages/TwoFA/Setup/TwoFASetupPage';
import MaintenancePage from './pages/Maintenance/MaintenancePage';
import TwoFAAuthenticatePage from './pages/TwoFA/Authenticate/TwoFAAuthenticatePage';
import TwoFaForgotPasswordPage from './pages/TwoFA/ForgotPassword/TwoFaForgotPasswordPage';
import CreditCardsPage from './pages/Account/CreditCardsPage';
import RegistrationPage from './pages/Registration/RegistrationPage';
// import StyleguidePage from './pages/Styleguide/Styleguide';
// import LegacyOrderHistoryPage from './pages/Account/LegacyOrderHistoryPage';
import RegistrationRoute from './components/Router/RegistrationRoute';

const AppRoutes = () => (
  <Switch>
    <Route path={IMPERSONATE_PAGE} component={ImpersonatePage} />
    <AuthRoute path={REGISTER_LOGIN_PAGE} component={RegistrationLoginPage} />
    <AuthRoute exact path={EMAIL_LOGIN_PAGE} component={EmailLoginPage} />
    <AuthRoute exact path={USER_LOGIN_PAGE} component={UserLoginPage} />
    <AuthRoute exact path={RESET_PASSWORD} component={ResetPasswordPage} />
    <AuthRoute
      exact
      path={SECURITY_QUESTION_PAGE}
      component={SecurityQuestionPage}
    />
    <AuthRoute
      exact
      path={VERIFY_CHOOSE_METHOD_PAGE}
      component={TwoFASetupPage}
    />
    <AuthRoute exact path={TWO_FA_QR_CODE_PAGE} component={TwoFASetupPage} />
    <AuthRoute
      exact
      path={TWO_FA_CHOOSE_PHONE_METHOD}
      component={TwoFASetupPage}
    />
    <AuthRoute exact path={TWO_FA_VERIFY_CODE} component={TwoFASetupPage} />
    <AuthRoute
      exact
      path={TWO_FA_SUCCESSFUL_SETUP}
      component={TwoFASetupPage}
    />
    <AuthRoute
      exact
      path={TWO_FA_VERIFY_IDENTITY}
      component={TwoFAAuthenticatePage}
    />
    <AuthRoute
      exact
      path={TWO_FA_VERIFY_IDENTITY_PHONE}
      component={TwoFAAuthenticatePage}
    />
    <AuthRoute
      exact
      path={TWO_FA_HAVING_TROUBLE_PAGE}
      component={TwoFAAuthenticatePage}
    />
    <AuthRoute
      exact
      path={TWO_FA_FORGOT_PASSWORD_IDENTITY_PAGE}
      component={TwoFaForgotPasswordPage}
    />
    <AuthRoute
      exact
      path={TWO_FA_FORGOT_PASSWORD_IDENTITY_PHONE_PAGE}
      component={TwoFaForgotPasswordPage}
    />
    <AuthRoute
      exact
      path={TWO_FA_FORGOT_PASSWORD_IDENTITY_HAVING_TROUBLE_PAGE}
      component={TwoFaForgotPasswordPage}
    />
    <AuthRoute exact path={IDENTITY_PAGE} component={IdentityPage} />
    <AuthRoute
      exact
      path={RESET_SUCCESSFUL_PAGE}
      component={ResetSuccessfulPage}
    />
    <AuthRoute
      exact
      path={RESET_SECURITY_QUESTION_PAGE}
      component={ResetSecurityQuestionPage}
    />
    <AuthRoute exact path={RESET_SENT_PAGE} component={ResetSentPage} />
    <AuthRoute exact path={INVITE_USER_PAGE} component={InviteUserPage} />
    <AuthRoute
      exact
      path={INVITE_USER_WELCOME_PAGE}
      component={InviteUserPage}
    />
    <AuthRoute
      exact
      path={INVITE_USER_CREDENTIALS_PAGE}
      component={InviteUserPage}
    />
    <AuthRoute exact path={INVITE_USER_IDENTITY} component={InviteUserPage} />
    <RegistrationRoute
      exact
      path={QUICK_REGISTRATION_PAGE}
      component={RegistrationPage}
    />
    <AuthRoute
      exact
      path={INVITE_USER_SECURITY_QUESTION}
      component={InviteUserPage}
    />
    <AuthRoute
      exact
      path={INVITE_USER_TWO_FA_VERIFY_IDENTITY}
      component={InviteUserPage}
    />
    <AuthRoute
      exact
      path={INVITE_USER_TWO_FA_VERIFY_IDENTITY_PHONE}
      component={InviteUserPage}
    />
    <AuthRoute
      exact
      path={INVITE_USER_TWO_FA_HAVING_TROUBLE}
      component={InviteUserPage}
    />

    <AuthRoute
      exact
      path={INVITE_USER_TWO_FA_VERIFY_CHOOSE_METHOD}
      component={InviteUserPage}
    />
    <AuthRoute
      exact
      path={INVITE_USER_TWO_FA_VERIFY_CODE}
      component={InviteUserPage}
    />
    <AuthRoute
      exact
      path={INVITE_USER_TWO_FA_QR_CODE_PAGE}
      component={InviteUserPage}
    />
    <AuthRoute
      exact
      path={INVITE_USER_TWO_FA_CHOOSE_PHONE_METHOD}
      component={InviteUserPage}
    />
    <AuthRoute
      exact
      path={INVITE_USER_TWO_FA_SUCCESSFUL_SETUP}
      component={InviteUserPage}
    />
    <Route path={NOT_FOUND_PAGE} component={NotFoundPage} />
    <Route path={ERROR_PAGE} component={ErrorPage} />
    <Route path={MAINTENANCE_PAGE} component={MaintenancePage} />

    <PrivateRoute
      exact
      path={FINANCIAL_BALANCES_PAGE}
      component={FinancialBalancesPage}
    />
    <PrivateRoute
      exact
      path={USER_MANAGEMENT_PAGE}
      component={UserManagementPage}
    />
    <PrivateRoute
      exact
      path={BANK_ACCOUNTS_PAGE}
      component={BankAccountsPage}
    />
    <PrivateRoute
      exact
      path={PROFILE_SETTINGS_PAGE}
      component={ProfileSettingsPage}
    />
    <PrivateRoute exact path={BASE_PAGE} component={PortfolioPage} />
    <PrivateRoute exact path={ADDRESSES_PAGE} component={AddressesPage} />
    <PrivateRoute exact path={STATEMENTS_PAGE} component={StatementsPage} />
    <PrivateRoute path={BUY_PAGE} component={BuyPage} />
    <PrivateRoute path={SELL_PAGE} component={SellPage} />
    <PrivateRoute path={METAL_STREAM_PAGE} component={MetalStreamPage} />
    <PrivateRoute exact path={PORTFOLIO_PAGE} component={PortfolioPage} />
    <PrivateRoute
      exact
      path={PORTFOLIO_PRODUCT_PAGE}
      component={PortfolioProductPage}
    />
    <PrivateRoute exact path={CREDIT_CARDS_PAGE} component={CreditCardsPage} />

    <Redirect from="*" to={NOT_FOUND_PAGE} />
  </Switch>
);

export default AppRoutes;
