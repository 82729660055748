import config from '../../config';
import { goldSilverFonts } from './Themes/GoldSilverTheme';
import { hardAssetsAllianceFonts } from './Themes/HardAssetsAllianceTheme';
import { contosoFonts } from './Themes/ContosoTheme';
import { legacyPMFonts } from './Themes/LegacyPreciousMetalsTheme';

const getThemeFonts = () => {
  const { brokerTheme } = config;

  switch (brokerTheme) {
    case 'HardAssetsAlliance':
      return hardAssetsAllianceFonts;

    case 'GoldSilver':
      return goldSilverFonts;
    case 'Contoso':
      return contosoFonts;
    case 'LegacyPM':
      return legacyPMFonts;

    default:
      return hardAssetsAllianceFonts;
  }
};

const themeFonts = getThemeFonts();

export default themeFonts;
