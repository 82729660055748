import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import * as jwt from 'jsonwebtoken';
import get from 'lodash.get';
import styled from 'styled-components';
import {
  INVITE_USER_PAGE,
  INVITE_USER_WELCOME_PAGE,
} from '../../../constants/pages';
import CodeField from '../../../components/InputFields/CodeField';
import ResendCode from '../Components/ResendCode';
import RememberDevice from '../Components/RememberDevice';
import { TWO_FA_TYPES } from '../../../constants/twoFaConstants';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import {
  AuthenticatorApplication,
  twoFaTypesEnum,
} from '../../../util/enum/api/twoFATypes';
import { updateSecurityQuestionAnswer } from '../../../store/actions/login/loginActions';
import { selectTokens } from '../../../store/selectors/loginSelectors';
import SectionLoader from '../../../components/Loader/SectionLoader';
import { selectIsLoadingByActionTypes } from '../../../store/selectors/loadingSelectors';
import { TWO_FA_CODE_VIA_PHONE_LOADING } from '../../../store/actions/twoFA/twoFAActionConstants';
import { useSessionStorageState } from '../../../util/hooks/useSessionStorageState';
import {
  ERROR,
  INVITE_USER_DATA,
  USER_INVITE_STATUS_MODAL,
} from '../../../constants/sessionStorage';
import { accountSecurityQuestionTypesObject } from '../../../util/enum/api/accountTypes';
import { inviteUser } from '../../../store/actions/user/userActions';
import { setRegisterContent } from '../../../util/helpers/registerHelpers';
import { closeWizardContent } from '../../../util/helpers/wizardHelpers';
import StatusModal from '../../../components/Modals/StatusModal';
import { getUsernames } from '../../../util/helpers/userHelpers';
import { INVITE_USER_LOADING } from '../../../store/actions/user/userActionConstants';
import { getBrowserPayload } from '../../../util/helpers/browserHelper';
import { securityCodeValidationSchema } from '../../../validation/securityCodeValidationSchema';
import Paragraph from '../../../components/Paragraph/Paragraph';
import SingleColumnList from '../../../components/TwoColumnList/SingleColumnList';
import HavingTroubleButtonGroup from '../Components/HavingTroubleButtonGroup';
import Auth from '../../../components/Auth/Auth';
import AuthCard from '../../../components/Auth/AuthCard';

const FullWidthForm = styled(Form)`
  width: 100%;
`;

const TwoFAVerifyIdentityPage = ({
  username,
  twoFactorAuthenticationResponse,
  getTwoFaCode,
  twoFaValue,
  isInviteUserFlow,
  formValues,
  userInviteId,
  handleNext,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const tokens = useSelector(selectTokens);
  const isLoadingSelector = useSelector(
    selectIsLoadingByActionTypes([
      TWO_FA_CODE_VIA_PHONE_LOADING,
      INVITE_USER_LOADING,
    ]),
  );
  const [isLoading, setIsLoading] = useState(false);
  const [securityQuestionData, setSecurityQuestionData] = useState({
    RefreshToken: '',
    SecurityQuestionType: '',
  });

  const [errorInviteUser, setError] = useSessionStorageState(ERROR, '');
  const [statusModal, setStatusModal] = useSessionStorageState(
    USER_INVITE_STATUS_MODAL,
    false,
  );

  const {
    AuthenticationMethodType,
    EmailUid,
    PhoneUid,
    MaskedPhone,
    MaskedEmail,
  } = twoFactorAuthenticationResponse;

  const [errorMessage, setErrorMessage] = useState(null);

  const parsedAuthenticationMethodType = parseEnumType(
    twoFaTypesEnum,
    AuthenticationMethodType,
  );

  const displayResendCode =
    parsedAuthenticationMethodType !== TWO_FA_TYPES.Authenticator;

  const onError = (errorMessage) => {
    setErrorMessage(errorMessage);
  };

  useEffect(() => {
    const { RefreshToken } = tokens;

    if (isInviteUserFlow) {
      if (!RefreshToken) {
        return history.push(INVITE_USER_PAGE);
      }
    }

    if (RefreshToken) {
      const { SecurityQuestionType } = jwt.decode(RefreshToken);
      setSecurityQuestionData({ RefreshToken, SecurityQuestionType });
    }
  }, [tokens]); // eslint-disable-line

  const existingLoginHandleNextClick = () => {
    const { values } = formValues;
    const { RefreshToken } = tokens;
    const { Username, Password } = values;
    const { SecurityQuestionType } = jwt.decode(RefreshToken);
    const usernames = getUsernames();
    const LinkWithExistingLogin = usernames.length > 0;
    const inviteUserData = {
      Username: Username.value,
      Password,
      LinkWithExistingLogin,
      InvitedUserInfo: {
        EncryptedPersonEntity: userInviteId,
        SecurityQuestionType: get(
          accountSecurityQuestionTypesObject,
          SecurityQuestionType,
          null,
        ),
      },
    };

    dispatch(
      inviteUser({
        data: inviteUserData,
        meta: {
          handleNext: () => handleNext(INVITE_USER_WELCOME_PAGE),
          createSessionUser: () =>
            setRegisterContent(INVITE_USER_DATA, inviteUserData),
          setIsLoading,
          setError,
          setStatusModal,
        },
      }),
    );
  };

  const handleFormSubmit = (values, { setFieldError }) => {
    const handleFieldError = (value) => {
      setFieldError('SecurityCode', value);
    };

    const payload = getBrowserPayload({
      Security2FaCode: values.SecurityCode,
      ...securityQuestionData,
    });

    dispatch(
      updateSecurityQuestionAnswer({
        ...payload,
        username,
        isTrustedLocation: values.Remember,
        isInviteUserFlow,
        handleFieldError,
        existingLoginHandleNextClick,
        setIsLoading,
      }),
    );
  };

  const resendCode = () => {
    getTwoFaCode({
      username,
      phoneUid: PhoneUid,
      emailUid: EmailUid,
      phoneMessageType: twoFaValue?.phoneMessageType,
      onError,
    });
  };

  const renderContent = () => {
    switch (parsedAuthenticationMethodType) {
      case TWO_FA_TYPES.Authenticator:
        return {
          note: t('twoFa.loginVerify.note.authenticator'),
        };

      case TWO_FA_TYPES.Email:
        return {
          note: t('twoFa.loginVerify.note.email', {
            email: MaskedEmail,
          }),
        };

      case TWO_FA_TYPES.Phone:
        return {
          note: t('twoFa.loginVerify.note.phone', {
            phoneNumber: MaskedPhone,
          }),
        };

      default:
        return {};
    }
  };

  return (
    <SectionLoader isLoading={isLoading || isLoadingSelector}>
      <Auth>
        <AuthCard title={t('twoFa.loginVerify.title')}>
          <Paragraph marginBottom={56}>
            {!isEmpty(renderContent()) && renderContent().note}
          </Paragraph>
          <Formik
            onSubmit={handleFormSubmit}
            validationSchema={securityCodeValidationSchema}
            initialValues={{ SecurityCode: '', Remember: false }}
          >
            {({ values, errors, setFieldValue }) => (
              <FullWidthForm>
                <SingleColumnList marginBottom={24}>
                  <CodeField
                    fixedSize
                    name="SecurityCode"
                    errors={errors}
                    onCodeChange={(value) =>
                      setFieldValue('SecurityCode', value)
                    }
                    hideExpireCodeNote={
                      parseEnumType(
                        twoFaTypesEnum,
                        AuthenticationMethodType,
                      ) === AuthenticatorApplication
                    }
                    isEmail={MaskedEmail}
                  />
                </SingleColumnList>
                <SingleColumnList>
                  {errorMessage && (
                    <Paragraph isError marginTop={8}>
                      <strong>{errorMessage}</strong>
                    </Paragraph>
                  )}
                </SingleColumnList>
                <SingleColumnList alignCenter marginBottom={56}>
                  {displayResendCode && (
                    <SingleColumnList marginBottom={32} alignCenter>
                      <ResendCode onClick={resendCode} />
                    </SingleColumnList>
                  )}
                  <RememberDevice values={values} />
                </SingleColumnList>
                <HavingTroubleButtonGroup isInviteUserFlow={isInviteUserFlow} />
              </FullWidthForm>
            )}
          </Formik>
        </AuthCard>
      </Auth>
      {errorInviteUser && statusModal && (
        <StatusModal
          isOpen={statusModal}
          hasError={errorInviteUser}
          close={() => {
            setStatusModal(false);
            setError('');
            closeWizardContent(ERROR);
            closeWizardContent(USER_INVITE_STATUS_MODAL);
          }}
          text={errorInviteUser}
          modalTitle={t('inviteUser.review.statusModal')}
          backButtonText={t('common.ok')}
          onButtonClick={() => {
            setStatusModal(false);
            setError('');
            closeWizardContent(ERROR);
            closeWizardContent(USER_INVITE_STATUS_MODAL);
          }}
        />
      )}
    </SectionLoader>
  );
};

TwoFAVerifyIdentityPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
  twoFactorAuthenticationResponse: PropTypes.oneOfType([
    PropTypes.shape({
      AuthenticationMethodType: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      EmailUid: PropTypes.string,
      PhoneUid: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  username: PropTypes.string,
  getTwoFaCode: PropTypes.func,
  twoFaValue: PropTypes.shape({
    phoneMessageType: PropTypes.string,
  }),
  isInviteUserFlow: PropTypes.bool,
  handleNext: PropTypes.func,
  userInviteId: PropTypes.string,
  formValues: PropTypes.shape({
    values: PropTypes.shape({
      ExistingUsername: PropTypes.string,
      LegalFirstName: PropTypes.string,
      LegalMiddleName: PropTypes.string,
      LegalLastName: PropTypes.string,
      Suffix: PropTypes.string,
      Username: PropTypes.string,
      Password: PropTypes.string,
      SecurityQuestion: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
        PropTypes.string,
      ]),
      Answer: PropTypes.string,
      SecurityCode: PropTypes.string,
      Remember: PropTypes.bool,
    }),
    phoneNumbers: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};
export default TwoFAVerifyIdentityPage;
