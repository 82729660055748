import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash.startcase';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Status from '../Portfolio/RecentTransactions/Status';
import { formatMoneyNumeral } from '../../util/helpers/numeralHelpers';
import useWindowSize from '../../util/hooks/useIsMobileHook';
import Tooltip from '../Tooltip/Tooltip';
import themeColors from '../../assets/styles/themeColors';
import { uFlexColumn } from '../../assets/styles/utility';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import useCheckPermissions from '../../util/hooks/useCheckPermissions';
import { selectSettings } from '../../store/selectors/settingsSelectors';

const Transaction = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
`;

const TransactionActions = styled.div`
  ${uFlexColumn};
  justify-content: center;
  min-width: 0;
`;

const TransactionSymbol = styled.span`
  margin-left: ${pxToRem(32)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-left: ${pxToRemMd(32)};
  }
`;
const TransactionDate = styled.div`
  ${uFlexColumn};
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.5;
  align-items: center;
  justify-content: center;
  color: ${themeColors.colorTextSecondary};
  max-width: ${pxToRem(38)};
  width: 100%;
  margin-right: ${pxToRem(24)};
  font-weight: 600;
  flex-shrink: 0;
`;

const TransactionMonth = styled.p`
  text-align: center;
  font-size: ${pxToRem(14)};
  line-height: 1.6;
  letter-spacing: 0;
  text-transform: uppercase;
  color: ${themeColors.colorTextSecondary};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(14)};
    line-height: 1.72;
  }
`;

const TransactionDay = styled.p`
  text-align: center;
  font-size: ${pxToRem(14)};
  line-height: 1.6;
  letter-spacing: 0;
  color: ${themeColors.colorTextSecondary};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(14)};
    line-height: 1.72;
  }
`;

const TransactionRow = ({
  month,
  day,
  action,
  product,
  amount,
  balance,
  quantity,
  status,
  showQuantity,
  isSegregated,
}) => {
  const settings = useSelector(selectSettings);
  const { displayIRAEquityOptionsPermission } = useCheckPermissions();
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 576;
  const segregatedCaption = settings?.SegregatedCaption;

  return (
    <>
      <Transaction>
        {month && day && (
          <TransactionDate>
            <TransactionMonth>{month.substring(0, 3)}</TransactionMonth>
            <TransactionDay>{day}</TransactionDay>
          </TransactionDate>
        )}
        {(action || product) && (
          <TransactionActions>
            {action && <p>{action}</p>}
            {product && (
              <p>
                {product}
                {isSegregated && ` (${segregatedCaption})`}
              </p>
            )}
          </TransactionActions>
        )}
      </Transaction>
      {amount !== undefined && (
        <div>
          <p>
            {amount === null || amount === 0 ? (
              <TransactionSymbol>&#8722;</TransactionSymbol>
            ) : (
              formatMoneyNumeral(amount)
            )}
          </p>
        </div>
      )}
      {balance && !displayIRAEquityOptionsPermission && (
        <div>
          <p>{balance}</p>
        </div>
      )}
      {showQuantity && (
        <div>
          <p>{quantity === null ? <span>&#8722;</span> : quantity}</p>
        </div>
      )}
      {isMobile ? (
        <Tooltip text={startCase(status)}>
          <Status status={status} />
        </Tooltip>
      ) : (
        <Status status={status} />
      )}
    </>
  );
};

TransactionRow.propTypes = {
  month: PropTypes.string,
  day: PropTypes.string,
  amount: PropTypes.number,
  balance: PropTypes.string,
  status: PropTypes.string,
  action: PropTypes.string,
  product: PropTypes.string,
  quantity: PropTypes.number,
  showQuantity: PropTypes.bool,
  isSegregated: PropTypes.bool,
};

export default TransactionRow;
