import { css } from 'styled-components';
import { mediaBelow, pxToRem } from '../../../assets/styles/helper';
import themeColors from '../../../assets/styles/themeColors';
import { uTextEllipsis } from '../../../assets/styles/utility';
import { variables } from '../../../assets/styles/variables';
import { TableDataElement } from '../TableData';
import { TableRowElement } from '../TableRow';

export const managementTable = css`
  box-shadow: ${variables.shadow.boxShadow};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    display: none;
  }
`;

export const managementTableHead = css`
  display: flex;
  min-width: 0;

  > div {
    ${uTextEllipsis};
    width: 100%;
    min-width: 0;
    padding-right: ${pxToRem(16)};
  }

  > :nth-child(1) {
    max-width: ${pxToRem(396)};
  }

  > :nth-child(2) {
    max-width: ${pxToRem(443)};
  }

  > :nth-child(3) {
    max-width: ${pxToRem(240)};
  }

  > :nth-child(4) {
    max-width: ${pxToRem(171)};
  }

  > :nth-child(5) {
    max-width: ${pxToRem(432)};
    flex-shrink: 0;
    padding-right: 0;
  }
`;

export const managementTableRow = css`
  padding: ${pxToRem(12)} ${pxToRem(24)};

  ${TableDataElement} {
    width: 100%;

    div {
      font-size: ${pxToRem(16)};
      line-height: 1.5;
      letter-spacing: 0;
      width: 100%;
      min-width: 0;
    }

    div:not(:last-child) {
      ${uTextEllipsis};
      padding-right: ${pxToRem(16)};
    }

    > :nth-child(1) {
      max-width: ${pxToRem(396)};
    }

    > :nth-child(2) {
      max-width: ${pxToRem(443)};
    }

    > :nth-child(3) {
      max-width: ${pxToRem(240)};
    }

    > :nth-child(4) {
      max-width: ${pxToRem(171)};
    }

    > :nth-child(5) {
      max-width: ${pxToRem(432)};
      flex-shrink: 0;
      padding-right: 0;
    }
  }
`;

export const managementTableBody = css`
  ${TableRowElement} {
    &:nth-child(even) {
      background-color: ${themeColors.colorWhite} !important;
    }
    &:hover {
      background-color: ${themeColors.colorTableEvenRowHoverBackground} !important;
    }
  }
`;

export const managementTableActions = css`
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: flex-end;

  > :not(:last-child) {
    margin-right: ${pxToRem(16)};
  }
`;
