import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import Paragraph from '../../../Paragraph/Paragraph';
import BankAccountCard from '../../../BankAccountsCard/BankAccountCard';
import IconButtonWithLabel from '../../../BankAccountsCard/IconButtonWithLabel';
import BankAccountCardWrapper from '../../../BankAccountsCard';
import useCheckPermissions from '../../../../util/hooks/useCheckPermissions';
import ModalButtons from '../../../Modal/ModalButtons';
import { BANK_ACCOUNTS_PAGE } from '../../../../constants/pages';
import VerifyBankAccountWizard from '../../VerifyBankAccount/VerifyBankAccountWizard';

const FixBankAccountCard = styled.div`
  width: 100%;

  ${BankAccountCardWrapper} {
    padding: 0;
  }

  ${IconButtonWithLabel} {
    display: none;
  }
`;

const FixBankAccount = ({ setWizardData, wizardData, closeEverything }) => {
  const { t } = useTranslation();
  const { allowAccountChangesPermission } = useCheckPermissions();
  const history = useHistory();

  const handleVerifyAccount = (bankAccountUid) => {
    setWizardData({ bankAccountUid, isConfirmMicroDepositsModalOpen: true });
  };

  const closeVerifyAccount = () => {
    setWizardData({
      bankAccountUid: null,
      isConfirmMicroDepositsModalOpen: false,
    });
  };

  if (!wizardData?.bankAccountForFix) {
    return null;
  }

  if (wizardData?.isConfirmMicroDepositsModalOpen) {
    return (
      <VerifyBankAccountWizard
        bankAccountUid={wizardData?.bankAccountUid}
        onClose={closeVerifyAccount}
      />
    );
  }

  return (
    <>
      <Paragraph marginBottom={24}>
        {t(
          'standardDepositWizard.achDeposit.fixBankAccount.connectBankAccounts',
        )}
      </Paragraph>
      <Paragraph marginBottom={24}>
        {t(
          'standardDepositWizard.achDeposit.fixBankAccount.verifyBankConnections',
        )}
      </Paragraph>
      <FixBankAccountCard>
        <BankAccountCard
          allowAccountChangesPermission={allowAccountChangesPermission}
          bankAccount={wizardData?.bankAccountForFix}
          handleVerifyAccount={handleVerifyAccount}
        />
      </FixBankAccountCard>
      <ModalButtons
        marginTop
        isVertical
        showOnlyPrimary
        primaryButtonProps={{
          label: 'Manage bank accounts',
          onClick: () => {
            history.push(BANK_ACCOUNTS_PAGE);
            closeEverything();
          },
        }}
      />
    </>
  );
};

FixBankAccount.propTypes = {
  wizardData: PropTypes.shape({
    bankAccountForFix: PropTypes.shape({}),
    isConfirmMicroDepositsModalOpen: PropTypes.bool,
    bankAccountUid: PropTypes.string,
  }),
  closeEverything: PropTypes.func,
  setWizardData: PropTypes.func,
};

export default FixBankAccount;
