import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import Modal from '../../Modal/Modal';

import { METHOD_VIA_CHECK } from '../../../util/constants';
import {
  closeWizardContent,
  deleteWizardKey,
  setWizardContent,
} from '../../../util/helpers/wizardHelpers';
import {
  fetchBankAccountsByType,
  submitNewAchAccount,
} from '../../../store/actions/bankAccount/bankAccountActions';
import { getCurrentAccountUid } from '../../../store/selectors/accountSelectors';
import SectionLoader from '../../Loader/SectionLoader';
import StatusModal from '../StatusModal';
import {
  CHOSEN_ACH_METHOD,
  IS_ADD_ACCOUNT_MODAL_OPEN,
  NEW_ACH_ERROR,
  PLAID_STATUS_MODAL_OPEN,
  PREVENT_SUPPORT_MODAL,
} from '../../../constants/sessionStorage';
import { fetchLinkToken } from '../../../store/actions/plaid/PlaidActions';
import { selectLinkToken } from '../../../store/selectors/plaidSelectors';
import { selectIsLoadingByActionType } from '../../../store/selectors/loadingSelectors';
import { PLAID_LINK_TOKEN_LOADING } from '../../../store/actions/plaid/PlaidActionConstants';
import { useSessionStorageState } from '../../../util/hooks/useSessionStorageState';
import PlaidCard from '../../PlaidCard/PlaidCard';
import { retrieveFromSessionStorage } from '../../../util/helpers/sessionStorageHelper';
import TypeCard from '../../TypeCard/TypeCard';
import ModalBody from '../../Modal/ModalBody';
import TypeList from '../../TypeList/TypeList';
import useGtmHook from '../../../util/hooks/useGtmHook';
import { BANK_ACCOUNT_ADDED } from '../../../constants/gtmEvents';

const ChooseAchAddMethod = ({
  isOpen,
  setChooseAchMethodOpen,
  setChosenAchMethod,
  handleClose,
  overModal,
  baseModal,
  disableManualAch,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accountUid = useSelector(getCurrentAccountUid);
  const [isNewAchLoading, setIsNewAchLoading] = useState(false);
  const isLoading =
    useSelector(selectIsLoadingByActionType(PLAID_LINK_TOKEN_LOADING)) ||
    isNewAchLoading;
  const [
    plaidStatusModalOpen,
    setPlaidStatusModalOpen,
  ] = useSessionStorageState(PLAID_STATUS_MODAL_OPEN, false);
  const [newAchError, setNewAchError] = useState(null);
  const [isABADisabled, setIsABADisabled] = useState(false);
  const linkToken = useSelector(selectLinkToken);

  const { bankAccountGtmEvent } = useGtmHook();

  useEffect(() => {
    if (accountUid && isOpen) {
      dispatch(fetchLinkToken());
    }
  }, [accountUid, isOpen]); // eslint-disable-line

  const handleGtmEvent = () => {
    bankAccountGtmEvent(BANK_ACCOUNT_ADDED, 'AchDeposit', 'Savings', 'USA');
  };

  const onSuccess = useCallback(
    (token, metadata) => {
      const handleApiResponse = (errorMessage, isABADisabled) => {
        const isSupportModalPrevented = retrieveFromSessionStorage(
          PREVENT_SUPPORT_MODAL,
        );
        setIsABADisabled(isABADisabled);

        if (isSupportModalPrevented) {
          deleteWizardKey(IS_ADD_ACCOUNT_MODAL_OPEN);
          setChooseAchMethodOpen(false);
          dispatch(
            fetchBankAccountsByType({
              accountUid,
              type: 'achdeposit',
            }),
          );
        } else {
          setChooseAchMethodOpen(false);
          closeWizardContent();
          setNewAchError(errorMessage);
          setPlaidStatusModalOpen(true);
          setWizardContent(NEW_ACH_ERROR, errorMessage);
          setWizardContent(PLAID_STATUS_MODAL_OPEN, true);
          dispatch(
            fetchBankAccountsByType({
              accountUid,
              type: 'achdeposit',
            }),
          );
        }
        setIsNewAchLoading(false);
      };

      const bankAccountIdArray = metadata.accounts.map((account) => account.id);
      const data = {
        PlaidDetails: {
          PublicToken: token,
          BankName: metadata.institution.name,
          BankAccounts: bankAccountIdArray,
        },
        BankAccountType: 'Savings',
        BankAccountUid: uuid(),
        PurposeType: 'AchDeposit',
      };
      setIsNewAchLoading(true);
      dispatch(
        submitNewAchAccount({
          data,
          accountUid,
          handleApiResponse,
          onSuccess: handleGtmEvent,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, accountUid, setChooseAchMethodOpen],
  );

  const onExit = useCallback((err) => {
    if (err != null && err.error_code === 'INVALID_LINK_TOKEN') {
      dispatch(fetchLinkToken());
    }
  }, []); // eslint-disable-line
  const handleBack = baseModal
    ? null
    : () => {
        setChooseAchMethodOpen(false);
        deleteWizardKey(CHOSEN_ACH_METHOD);
        deleteWizardKey(IS_ADD_ACCOUNT_MODAL_OPEN);
        setChosenAchMethod(null);
      };
  return (
    <>
      <Modal
        size="sm"
        title={t('achDeposit.chooseAchAddMethod.modalTitle')}
        isOpen={isOpen}
        overModal={overModal}
        close={() => {
          setChooseAchMethodOpen(false);
          setChosenAchMethod(null);
          handleClose();
        }}
        goBack={handleBack}
      >
        <SectionLoader isLoading={isLoading}>
          <ModalBody>
            <TypeList>
              {linkToken && (
                <PlaidCard
                  linkToken={linkToken}
                  onSuccess={onSuccess}
                  onExit={onExit}
                />
              )}
              <TypeCard
                title={t('achDeposit.chooseAchAddMethod.customCardTitle')}
                description={`${t(
                  'standardDepositWizard.achDeposit.achDepositVoidedCheckNote',
                )} \n ${t(
                  'achDeposit.chooseAchAddMethod.customCardDescription',
                )}`}
                note={t('achDeposit.chooseAchAddMethod.customCardNote')}
                onClick={() => {
                  setChosenAchMethod(METHOD_VIA_CHECK);
                  setWizardContent(CHOSEN_ACH_METHOD, METHOD_VIA_CHECK);
                  setChooseAchMethodOpen(false);
                  deleteWizardKey(baseModal ? '' : IS_ADD_ACCOUNT_MODAL_OPEN);
                }}
                disabled={disableManualAch}
                approvedAccountRequired={disableManualAch}
              />
            </TypeList>
          </ModalBody>
        </SectionLoader>
      </Modal>
      <StatusModal
        isOpen={plaidStatusModalOpen}
        hasError={newAchError}
        close={() => setPlaidStatusModalOpen(false)}
        text={newAchError || t('bankAccounts.newAccountConnectSuccess')}
        modalTitle={t('achDeposit.chooseAchAddMethod.modalTitle')}
        backButtonText={t('common.ok')}
        onButtonClick={() => {
          closeWizardContent();
          setPlaidStatusModalOpen(false);
        }}
        isABADisabled={isABADisabled}
      />
    </>
  );
};

ChooseAchAddMethod.propTypes = {
  isOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  setChooseAchMethodOpen: PropTypes.func,
  setChosenAchMethod: PropTypes.func,
  handleClose: PropTypes.func,
  overModal: PropTypes.bool,
  baseModal: PropTypes.bool,
  disableManualAch: PropTypes.bool,
};

export default ChooseAchAddMethod;
