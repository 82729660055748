/* eslint react/prop-types: 0 */

import React from 'react';
import i18next from 'i18next';
import SuccessOrFailModal from './Modals/StatusModal';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, isSuccessOrFailModalOpen: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true, isSuccessOrFailModalOpen: true };
  }

  componentDidCatch(error, info) {
    console.error('ErrorBoundary caught an error', error, info); // eslint-disable-line
  }

  render() {
    const { hasError, isSuccessOrFailModalOpen } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <SuccessOrFailModal
          key="success-or-fail-modal"
          text={i18next.t('userManagement.wentWrong')}
          isOpen={isSuccessOrFailModalOpen}
          hasError
          modalTitle={i18next.t('common.error')}
          close={() => window.location.reload()}
          onButtonClick={() => {
            window.location.reload();
          }}
          backButtonText={i18next.t('common.ok')}
        />
      );
    }

    return children;
  }
}

export default ErrorBoundary;
