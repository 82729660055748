import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';

import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SetupMetalStream from '../components/MetalStream/SetupMetalStream';
import SetupedMetalStream from '../components/MetalStream/SetupedMetalStream';
import NavigationBar from '../components/NavigationBar/NavigationBar';
import Section from '../components/Section/Section';
import {
  fetchMetalStreamSettings,
  updateMetalStreamSettings,
} from '../store/actions/metalStream/metalStreamActions';
import {
  selectMetalStreamError,
  selectMetalStreamSettings,
} from '../store/selectors/metalStreamSelectors';
import { selectIsLoadingByActionType } from '../store/selectors/loadingSelectors';
import { MS_SETTINGS_LOADING } from '../store/actions/metalStream/metalStreamActionConstants';
import MetalStreamWizard from '../components/Modals/MetalStream/MetalStreamWizard';
import { getCurrentAccount } from '../store/selectors/accountSelectors';
import { setWizardContent } from '../util/helpers/wizardHelpers';
import useGtmHook from '../util/hooks/useGtmHook';
import { PORTFOLIO_PAGE } from '../constants/pages';
import Button from '../components/Button/Button';
import BlockSectionLoader from '../components/Loader/BlockSectionLoader';
import {
  IS_METAL_STREAM_SETUP_DONE,
  METAL_STREAM_EDITING,
  METAL_STREAM_SETTINGS,
  PREVENT_SUPPORT_MODAL,
} from '../constants/sessionStorage';
import { AIP_DISABLED, AIP_RESTARTED } from '../constants/gtmEvents';
import useCheckPermissions from '../util/hooks/useCheckPermissions';
import { useSessionStorageState } from '../util/hooks/useSessionStorageState';
import { storeInSessionStorage } from '../util/helpers/sessionStorageHelper';
import { uFlexCenter, uSuperscript } from '../assets/styles/utility';
import { mediaBelow, pxToRem, pxToRemMd } from '../assets/styles/helper';
import { variables } from '../assets/styles/variables';
import themeColors from '../assets/styles/themeColors';
import config from '../config';
import NagDashboardBanner from '../components/NagDashboard/NagDashboardBanner';

const MetalStreamFloatingButtonWrap = styled.div`
  margin-top: ${pxToRem(40)};
  ${uFlexCenter};
  box-shadow: 0 -20px 24px 0 rgba(29, 42, 68, 0.12);
  padding: ${pxToRemMd(24)};
  background-color: ${themeColors.colorWhite};
  width: 100%;
  margin: 0;
  position: sticky;
  bottom: 0;
  display: none;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    display: flex;
  }
`;

export const MetalStreamContainer = styled.div`
  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-bottom: -4rem;
  }
`;

export const MetalStreamTrademark = styled.sup`
  ${uSuperscript};
`;

const MetalStreamPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { gtmEvent } = useGtmHook();
  const {
    displayMetalStreamMenuItems,
    allowAccountChangesPermission,
  } = useCheckPermissions();
  const account = useSelector(getCurrentAccount);
  const isTestAccount = account.IsTestAccount;
  const msSettings = useSelector(selectMetalStreamSettings);
  const metalStreamError = useSelector(selectMetalStreamError);
  const isLoading = useSelector(
    selectIsLoadingByActionType(MS_SETTINGS_LOADING),
  );
  const { metalStreamCaption, brokerTheme } = config;
  const isHAA = brokerTheme === 'HardAssetsAlliance';
  const isGS = brokerTheme === 'GoldSilver';

  const [
    isMetalStreamSetupOpen,
    setIsMetalStreamSetupOpen,
  ] = useSessionStorageState(IS_METAL_STREAM_SETUP_DONE, false);

  useEffect(() => {
    if (!displayMetalStreamMenuItems || isGS) {
      history.push(PORTFOLIO_PAGE);
    }
  }, [displayMetalStreamMenuItems, history, isGS]);

  const switchActiveState = () => {
    const handleGtm = () => {
      gtmEvent(!msSettings.IsActive ? AIP_RESTARTED : AIP_DISABLED);
    };

    dispatch(
      updateMetalStreamSettings({
        accountUid: account.AccountUid,
        data: [
          { op: 'replace', path: '/isactive', value: !msSettings.IsActive },
        ],
        handleGtm,
      }),
    );
  };

  const handleOpenSetup = () => {
    setWizardContent(IS_METAL_STREAM_SETUP_DONE, true);
    setIsMetalStreamSetupOpen(true);
  };

  const handleCloseSetup = () => {
    setIsMetalStreamSetupOpen(false);
  };

  const renderMetalStreamContent = useCallback(() => {
    if (account.AccountUid) {
      if (metalStreamError) {
        return <span>{metalStreamError}</span>;
      }
      if (!isEmpty(msSettings)) {
        return (
          <SetupedMetalStream
            msSettings={msSettings}
            accountUid={account.AccountUid}
            handleOpenSetup={handleOpenSetup}
            switchActiveState={switchActiveState}
            isTestAccount={isTestAccount}
            allowAccountChangesPermission={allowAccountChangesPermission}
          />
        );
      }
      if (msSettings === null) {
        return (
          <SetupMetalStream
            handleOpenSetup={handleOpenSetup}
            isTestAccount={isTestAccount}
            allowAccountChangesPermission={allowAccountChangesPermission}
          />
        );
      }
    }
    return null;
  }, [msSettings, metalStreamError, account.AccountUid]); // eslint-disable-line

  const renderMetalStreamStickyButtons = useCallback(() => {
    if (account.AccountUid) {
      if (metalStreamError) {
        return null;
      }

      if (!isEmpty(msSettings)) {
        return (
          <MetalStreamFloatingButtonWrap>
            <Button
              size="lg"
              variant="primary"
              type="button"
              disabled={!allowAccountChangesPermission || isTestAccount}
              onClick={switchActiveState}
            >
              {!msSettings?.IsActive
                ? t('metalStream.restartMetalStream', {
                    metalStream: metalStreamCaption,
                  })
                : t('metalStream.pauseMetalStream')}
            </Button>
          </MetalStreamFloatingButtonWrap>
        );
      }

      if (msSettings === null) {
        return (
          <MetalStreamFloatingButtonWrap>
            <Button
              size="lg"
              variant="primary"
              disabled={!allowAccountChangesPermission || isTestAccount}
              onClick={() => {
                handleOpenSetup();
                storeInSessionStorage(PREVENT_SUPPORT_MODAL, true);
                setWizardContent(METAL_STREAM_SETTINGS, {});
                setWizardContent(METAL_STREAM_EDITING, false);
              }}
            >
              {t('metalStream.setup', {
                metalStream: metalStreamCaption,
              })}
            </Button>
          </MetalStreamFloatingButtonWrap>
        );
      }
    }

    return null;
  }, [msSettings, metalStreamError, account.AccountUid]); // eslint-disable-line

  const { gtmScreenView } = useGtmHook();

  useEffect(() => {
    gtmScreenView({
      path: window.location.pathname,
      title: 'Metal Stream',
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    if (account.AccountUid) {
      dispatch(
        fetchMetalStreamSettings({
          accountUid: account.AccountUid,
        }),
      );
    }
  }, [dispatch, account.AccountUid]);

  const renderPageContent = () => {
    if (isLoading) {
      return <BlockSectionLoader isLoading />;
    }

    return renderMetalStreamContent();
  };
  return (
    <MetalStreamContainer data-cy="container-metal-stream-page">
      <NagDashboardBanner />
      <NavigationBar
        mainPage={t('header.navDropdownTrading')}
        subPage={
          <span>
            {t('header.linkMetalStream', {
              metalStream: metalStreamCaption,
            })}
            {isHAA && (
              <MetalStreamTrademark>
                {t('common.trademark')}
              </MetalStreamTrademark>
            )}
          </span>
        }
      />
      <Section>{renderPageContent()}</Section>
      {isMetalStreamSetupOpen && (
        <MetalStreamWizard
          isOpen={isMetalStreamSetupOpen}
          handleClose={handleCloseSetup}
          metalStreamSettings={msSettings}
        />
      )}
      {renderMetalStreamStickyButtons()}
    </MetalStreamContainer>
  );
};

export default MetalStreamPage;
