import React from 'react';
import { CAN_COUNTRY_ISO3CODE, USA_COUNTRY_ISO3CODE } from '../constants';
import i18n from '../../i18n';

export const formatAddress = (address) => {
  if (!address) {
    return 'Loading...';
  }

  const {
    AddressLine,
    AddressLine2,
    CountryIso3Code,
    City,
    StateRegion,
    PostalCode,
  } = address;

  return (
    <span>
      {AddressLine ? `${AddressLine} \n` : ''}
      {AddressLine2 ? `${AddressLine2} \n` : ''}
      {City ? `${City}, ` : ``}
      {StateRegion ? `${StateRegion} ` : ``}
      {PostalCode ? `${PostalCode}` : ``}
      {CountryIso3Code ? `\n ${CountryIso3Code}` : ''}
    </span>
  );
};

export const formatAddressString = (address) => {
  if (!address) {
    return null;
  }

  const {
    AddressLine,
    AddressLine2,
    CountryIso3Code,
    City,
    StateRegion,
    PostalCode,
  } = address;

  return `${AddressLine ? `${AddressLine}` : ''}${
    AddressLine2 ? `\n ${AddressLine2}` : ''
  }
      ${City ? `${City}, ` : ``}${StateRegion ? `${StateRegion} ` : ``}${
    PostalCode ? `${PostalCode}` : ``
  }
      ${CountryIso3Code ? `${CountryIso3Code}` : ''}`;
};

export const formatAddressStringInline = (address, countries) => {
  if (!address) {
    return null;
  }

  const {
    AddressLine,
    AddressLine2,
    AptOrSuite,
    City,
    StateRegion,
    PostalCode,
    CountryIso3Code,
  } = address;

  return `${AddressLine ? `${AddressLine}` : ''}${
    AddressLine2 ? `, ${AddressLine2}` : ''
  } ${AptOrSuite ? `, ${AptOrSuite}` : ''}${City ? `${City}, ` : ``}${
    StateRegion ? `${StateRegion}` : ``
  }${PostalCode ? `, ${PostalCode}` : ``}${
    CountryIso3Code !== 'USA'
      ? countries.find((country) => country?.Iso3Code === CountryIso3Code)
          ?.Caption
      : ''
  }`;
};

export function getCountryStates(countryCode, countyField) {
  return (
    countryCode === USA_COUNTRY_ISO3CODE ||
    countryCode === CAN_COUNTRY_ISO3CODE ||
    countyField === USA_COUNTRY_ISO3CODE ||
    countyField === CAN_COUNTRY_ISO3CODE
  );
}

export const getStatesOptions = (states = []) => [
  { label: i18n.t('common.select'), value: '' },
  ...states.map((state) => ({
    value: state.Code,
    label: state.Caption,
  })),
];

export const formatMailingAddress = (address, bankName) => {
  const {
    Receipient,
    Attn,
    AddressLine,
    AddressLine2,
    City,
    StateRegion,
    PostalCode,
  } = address;
  return `${Receipient ? `${Receipient}` : ''}${bankName ? `${bankName}` : ''}${
    Attn ? `\n Attn: ${Attn}` : ''
  }${AddressLine ? `\n ${AddressLine}` : ''}${
    AddressLine2 ? `\n ${AddressLine2}` : ''
  }${City ? `\n ${City}, ` : ``}${StateRegion ? `${StateRegion} ` : ''}${
    PostalCode ? `${PostalCode}` : ''
  }`;
};
