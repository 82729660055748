/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useCheckPermissions from '../../util/hooks/useCheckPermissions';
import NagDashboardTable from './NagDashboardTable';
import { BASE_PAGE, PORTFOLIO_PAGE } from '../../constants/pages';
import {
  retrieveFromSessionStorage,
  storeInSessionStorage,
} from '../../util/helpers/sessionStorageHelper';
import { NAG_DASHBOARD_ACTIVE_STEP } from '../../util/constants';
import NagDashboardShortBanner from './NagDashboardShortBanner';
import { parseEnumType } from '../../util/helpers/enumMappers';
import {
  accountStatusTypesArray,
  DEPOSIT_STATUS_TYPES,
  UPGRADE_STATUS_TYPES,
} from '../../constants/nagDashboardContants';
import { pxToRem } from '../../assets/styles/helper';
import {
  fetchNagDashboard,
  postDismissedDashboard,
} from '../../store/actions/account/accountActions';
import {
  getCurrentAccount,
  getCurrentAccountUid,
  selectNagDashboard,
} from '../../store/selectors/accountSelectors';
import { selectIsLoadingByActionType } from '../../store/selectors/loadingSelectors';
import { ACCOUNT_NAG_DASHBOARD_LOADING } from '../../store/actions/account/accountActionConstants';

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${pxToRem(16)};
`;

const NagDashboardBanner = ({
  isDimissed,
  setIsDissmised,
  setShowDashboardOnPortfolio,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const account = useSelector(getCurrentAccount);
  const accountUid = useSelector(getCurrentAccountUid);
  const nagDashboard = useSelector(selectNagDashboard);
  const accountStatus = nagDashboard?.AccountStatusType;
  const isPortfolioPage =
    history.location.pathname === PORTFOLIO_PAGE ||
    history.location.pathname === BASE_PAGE;
  const [canDismiss, setCanDismiss] = useState(false);
  const {
    displayBasicAccountOptionsPermission,
    displayClosedAccountOptionsPermission,
  } = useCheckPermissions();
  const [activeStep, setActiveStep] = useState(
    retrieveFromSessionStorage(NAG_DASHBOARD_ACTIVE_STEP) || 1,
  );
  const [showDashboard, setShowDashboard] = useState(false);
  const isLoadingNagDashboard = useSelector(
    selectIsLoadingByActionType(ACCOUNT_NAG_DASHBOARD_LOADING),
  );
  const isBuySubmitted = nagDashboard.IsBuyForStorageSubmitted;
  const isDashboardDismissed = nagDashboard?.IsDashboardDismissed;
  const isUpgraded =
    nagDashboard.UpgradeStatusType === UPGRADE_STATUS_TYPES.Upgraded;

  const isDeposit =
    nagDashboard.DepositStatusType !== DEPOSIT_STATUS_TYPES.Completed;

  const checkDashbard = () => {
    if (
      displayBasicAccountOptionsPermission &&
      !isUpgraded &&
      nagDashboard.UpgradeStatusType
    ) {
      storeInSessionStorage(NAG_DASHBOARD_ACTIVE_STEP, 1);
      return setActiveStep(1);
    }

    if (
      parseEnumType(accountStatusTypesArray, accountStatus) ===
        'RequiresAttention' ||
      parseEnumType(accountStatusTypesArray, accountStatus) === 'Pending' ||
      (parseEnumType(accountStatusTypesArray, accountStatus) === 'Err' &&
        (isUpgraded || nagDashboard?.UpgradeStatusType === null))
    ) {
      storeInSessionStorage(NAG_DASHBOARD_ACTIVE_STEP, 2);
      return setActiveStep(2);
    }

    if (
      parseEnumType(accountStatusTypesArray, accountStatus) !==
        'RequiresAttention' ||
      parseEnumType(accountStatusTypesArray, accountStatus) !== 'Pending' ||
      (parseEnumType(accountStatusTypesArray, accountStatus) === 'Err' &&
        (isUpgraded || nagDashboard?.UpgradeStatusType === null) &&
        isDeposit)
    ) {
      storeInSessionStorage(NAG_DASHBOARD_ACTIVE_STEP, 3);
      return setActiveStep(3);
    }

    if (!isDeposit) {
      storeInSessionStorage(NAG_DASHBOARD_ACTIVE_STEP, 4);
      return setActiveStep(4);
    }
  };

  useEffect(() => {
    dispatch(fetchNagDashboard({ accountUid: account.AccountUid }));
  }, [account]); // eslint-disable-line

  useEffect(() => {
    if (displayClosedAccountOptionsPermission) {
      setShowDashboard(false);
      if (isPortfolioPage) {
        setShowDashboardOnPortfolio(false);
      }
      return;
    }
    if (displayBasicAccountOptionsPermission || isUpgraded || !isBuySubmitted) {
      setShowDashboard(true);
      if (isPortfolioPage) {
        setShowDashboardOnPortfolio(true);
      }
    }
    if (isBuySubmitted || isDashboardDismissed) {
      setShowDashboard(false);
      if (isPortfolioPage) {
        setShowDashboardOnPortfolio(false);
      }
    }
    // eslint-disable-next-line no-sparse-arrays
  }, [
    isUpgraded,
    displayBasicAccountOptionsPermission,
    displayClosedAccountOptionsPermission,
    isBuySubmitted,
    isDashboardDismissed,
  ]); // eslint-disable-line

  useEffect(() => {
    if (
      parseEnumType(accountStatusTypesArray, accountStatus) === 'Pending' ||
      parseEnumType(accountStatusTypesArray, accountStatus) ===
        'RequiresAttention' ||
      parseEnumType(accountStatusTypesArray, accountStatus) === 'Err'
    ) {
      setShowDashboard(true);
    }
  }, [accountStatus]); // eslint-disable-line

  useEffect(() => {
    switch (parseEnumType(accountStatusTypesArray, accountStatus)) {
      case 'RequiresAttention':
      case 'Pending':
      case 'Err':
        storeInSessionStorage(NAG_DASHBOARD_ACTIVE_STEP, 2);
        return setActiveStep(2);
      case 'Approved':
      case 'Rejected':
      case 'Executed':
      case 'Expired':
      case 'Closed':
        return checkDashbard(nagDashboard);

      default:
        storeInSessionStorage(NAG_DASHBOARD_ACTIVE_STEP, 1);
        return setActiveStep(1);
    }
  }, [accountStatus, nagDashboard]); // eslint-disable-line

  useEffect(() => {
    setCanDismiss(false);
    if (
      (!isDeposit &&
        (parseEnumType(accountStatusTypesArray, accountStatus) !==
          'RequiresAttention' ||
          parseEnumType(accountStatusTypesArray, accountStatus) !==
            'Pending')) ||
      parseEnumType(accountStatusTypesArray, accountStatus) !== 'Err'
    ) {
      setCanDismiss(true);
    }
  }, [isDeposit, accountStatus, account]); // eslint-disable-line

  useEffect(() => {
    if (isLoadingNagDashboard && setShowDashboardOnPortfolio)
      setShowDashboardOnPortfolio(false);
  }, [isLoadingNagDashboard]); // eslint-disable-line

  if (isLoadingNagDashboard) return <></>;

  if (isDimissed) {
    return <></>;
  }

  const onSuccess = () => {
    dispatch(fetchNagDashboard({ accountUid: account.AccountUid }));
  };

  const dismissedDasboard = () => {
    dispatch(postDismissedDashboard({ accountUid, onSuccess }));
  };

  if (showDashboard) {
    return (
      <>
        {isPortfolioPage ? (
          <NagDashboardTable
            title={t('nagDashboard.table.title')}
            setIsDissmised={setIsDissmised}
            canDismiss={canDismiss}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            dismissedDasboard={dismissedDasboard}
            accountUid={accountUid}
          />
        ) : (
          <NagDashboardShortBanner activeStep={activeStep} />
        )}
      </>
    );
  }
  return <></>;
};

NagDashboardBanner.propTypes = {
  isDimissed: PropTypes.bool,
  setIsDissmised: PropTypes.func,
  setShowDashboardOnPortfolio: PropTypes.func,
};
export default NagDashboardBanner;
