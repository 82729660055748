import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatMoneyNumeral } from '../../../../../util/helpers/numeralHelpers';
import ReviewBar from '../../../../ReviewBar/ReviewBar';
import { IRA_MILLENIUM_TYPE } from '../../../../../util/constants';
import ModalBody from '../../../../Modal/ModalBody';
import Paragraph from '../../../../Paragraph/Paragraph';
import ModalButtons from '../../../../Modal/ModalButtons';
import Label from '../../../../Notes/Label';

const CheckInformationTextReview = ({
  amount,
  address,
  type,
  confirmWithdraw,
  handleNext,
  handleBack,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ReviewBar title={t('entrust.review.reviewBar')} />
      <ModalBody>
        <Label isBold text={t('entrust.review.amount')} marginBottom={4} />
        <Paragraph fontSize={18} marginBottom={8}>
          {formatMoneyNumeral(amount)}
        </Paragraph>
        <Label
          marginBottom={4}
          isBold
          text={t('entrust.review.mailingAddress')}
        />
        <Paragraph fontSize={18} marginBottom={24}>
          {address.label}
        </Paragraph>
        {type === IRA_MILLENIUM_TYPE && (
          <Paragraph marginBottom={24}>
            <strong>{t('entrust.review.notice')}</strong>
          </Paragraph>
        )}
        <Paragraph>{t('entrust.review.description')}</Paragraph>
        <ModalButtons
          marginTop
          isHorizontal
          secondaryButtonProps={{ onClick: handleBack }}
          primaryButtonProps={{
            onClick: () => confirmWithdraw(handleNext),
            label: t('common.confirm'),
          }}
        />
      </ModalBody>
    </>
  );
};

CheckInformationTextReview.propTypes = {
  amount: PropTypes.number,
  address: PropTypes.shape({
    label: PropTypes.string,
  }),
  type: PropTypes.string,
  confirmWithdraw: PropTypes.func,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
};

export default CheckInformationTextReview;
