import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as VerifiedIcon } from '../../assets/images/svg/executed.svg';
import { ReactComponent as UnverifiedIcon } from '../../assets/images/svg/unverified.svg';

import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';
import { uTextEllipsis } from '../../assets/styles/utility';

const getStatusContainerStyles = ({ variant }) => {
  switch (variant) {
    case 'Verified':
      return `
      svg {
        color: ${themeColors.colorVerifiedSuccess};
      }
    `;

    case 'NonVerified':
      return `
      svg {
        color: ${themeColors.colorNotVerified};
      }
    `;

    default:
      break;
  }
};

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  max-width: ${pxToRem(226)};

  ${(props) => getStatusContainerStyles(props)}
`;

export const StatusIconWrap = styled.div`
  margin-right: ${pxToRem(8)};

  &,
  svg {
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    &,
    svg {
      width: ${pxToRemMd(16)};
      height: ${pxToRemMd(16)};
    }
  }
`;

export const StatusLabel = styled.p`
  ${uTextEllipsis};
  min-width: 0;
  color: ${themeColors.colorTextSecondary};
  font-weight: 500;
`;

const StatusElement = ({ icon, label, variant }) => (
  <StatusContainer variant={variant}>
    <StatusIconWrap>{icon}</StatusIconWrap>
    <StatusLabel>{label}</StatusLabel>
  </StatusContainer>
);

const AddressStatus = ({ isVerified }) => {
  const { t } = useTranslation();

  const statusComponent = (isVerified) => {
    if (isVerified) {
      return (
        <StatusElement
          variant="Verified"
          label={t('status.verified')}
          icon={<VerifiedIcon />}
        />
      );
    }
    return (
      <StatusElement
        variant="NonVerified"
        label={t('status.nonVerified')}
        icon={<UnverifiedIcon />}
      />
    );
  };

  return statusComponent(isVerified);
};

AddressStatus.propTypes = {
  isVerified: PropTypes.bool,
};

StatusElement.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string,
  variant: PropTypes.string,
};

export default AddressStatus;
