import React from 'react';
import styled from 'styled-components';
import { variables } from '../../assets/styles/variables';
import { pxToRem } from '../../assets/styles/helper';
import LoaderWrap from './LoaderWrap';
import LoaderIcon from './LoaderIcon';

const FullPageLoaderWrap = styled(LoaderWrap)`
  position: fixed;
  z-index: ${variables.zIndex.indexXxl};
`;

const Loader = styled(LoaderIcon)`
  width: ${pxToRem(200)};
  height: ${pxToRem(200)};
  border-width: ${pxToRem(15)};
`;

const FullPageLoader = () => (
  <FullPageLoaderWrap>
    <Loader />
  </FullPageLoaderWrap>
);

export default FullPageLoader;
