import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from '../../Modal/Modal';
import StepByStep from '../../StepByStep';
import EditPassword from './EditPassword/EditPassword';
import Status from '../Status';

const steps = [EditPassword, Status];
const ChangePasswordModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');

  return (
    <Modal
      title={t('profileSettings.changePassword')}
      isOpen={isOpen}
      close={onClose}
      size="sm"
    >
      <StepByStep saveStepDisabled>
        {({ goStepForward, goStepBack }) =>
          steps.map((step) =>
            React.createElement(step, {
              handleBack: goStepBack,
              handleNext: goStepForward,
              handleClose: onClose,
              backButtonText: t('common.ok'),
              onButtonClick: onClose,
              setError,
              text: error || t('profileSettings.passwordUpdated'),
              hasError: error,
            }),
          )
        }
      </StepByStep>
    </Modal>
  );
};

ChangePasswordModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ChangePasswordModal;
