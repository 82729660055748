import { variables } from '../variables';
import AvenirNextBold from '../../fonts/HardAssetsAlliance/AvenirNext-Bold.woff2';
import AvenirNextDemiBold from '../../fonts/HardAssetsAlliance/AvenirNext-DemiBold.woff2';
import AvenirNextMedium from '../../fonts/HardAssetsAlliance/AvenirNext-Medium.woff2';
import AvenirNextRegular from '../../fonts/HardAssetsAlliance/AvenirNext-Regular.woff2';

export const hardAssetsAllianceColors = {
  colorPrimary: '#E2930A',
  colorPrimaryLight: '#eea527',
  colorPrimaryDark: '#d08400',
  colorWhite: '#ffffff',
  colorBlack: '#000000',
  colorRed: '#FF5028',
  colorGrey: '#f4f4f4',

  // Backgrounds
  colorBackgroundPrimary: '#ffffff',
  colorBackgroundSecondary: '#fafafa',
  colorWarningnBackground: '#FFECB5',

  // Typography Colors
  // Text
  colorTextPrimary: '#003246',
  colorTextSecondary: '#8b8b8b',
  colorTextContrast: '#ffffff',
  colorTextHeader: '#ffffff',
  colorTextLink: '#ffffff',

  colorSuccess: '#00876A',
  colorError: '#FF5028',
  colorWarning: '#E2930A',
  colorIncomplete: '#212528',

  // Links
  colorLinkPrimary: '#E2930A',
  colorLinkRegistration: '#003246', // semiDark'

  // Button Colors
  colorButtonPrimary: '#E2930A',
  colorButtonPrimaryText: '#ffffff',
  colorButtonPrimaryHover: '#eea527',
  colorButtonPrimaryFocused: '#d08400',
  colorButtonPrimaryHoverText: '#ffffff',
  colorButtonOutlined: '#ffffff',
  colorButtonOutlinedBorder: '#E2930A',
  colorButtonOutlinedText: '#E2930A',
  colorButtonDisabled: 'rgba(226, 147, 10, 0.5)',

  colorButtonDefault: '#ffffff',
  colorButtonDefaultText: '#003246',
  colorButtonDefaultBorder: '#003246',
  colorButtonDefaultHover: '#8b8b8b',
  colorButtonAddNewBank: '#ffffff',

  // Input
  colorInputBorder: '#C9D6DB',
  colorInputBackground: '#ffffff',
  colorInputBackgroundDisabled: '#EBF2F2',
  colorInputBorderFocused: '#E2930A',
  colorInputBorderActive: '#E2930A',
  colorInputText: '#4E7A8C',
  colorInputPlaceholder: '#4E7A8C',
  colorInputSelectItemFocused: '#f4f4f4',

  // Label
  colorLabel: '#4E7A8C',

  // Modal
  colorModalBackground: 'rgba(0, 0, 0, 0.3)',

  // Tabs
  colorTabBackground: '#ffffff',
  colorTabText: '#E2930A',
  colorTabSelectedBackground: '#E2930A',
  colorTabSelectedText: '#ffffff',

  // Pills
  colorPillsBackground: '#EBF2F2',
  colorPillsText: '#003246',
  colorPillsBackgroundActive: '#003246',
  colorPillsTextActive: '#ffffff',

  // Filters
  colorFilterItemBackground: '#003246',
  colorFilterItemText: '#ffffff',
  colorFilterIcon: '#ffffff',

  // Icons
  colorIconPrimary: '#003246',
  colorIconSecondary: '#E2930A',
  colorLoaderIcon: '#E2930A',

  // Radio Button
  colorRadioButton: '#003246',
  colorRadioButtonWrapperBorderSelected: '#E2930A',

  // Tooltip
  colorTooltipBackground: '#003246',
  colorTooltipText: '#ffffff',

  // Password Strength
  colorPasswordStrengthDefault: '#EBF2F2',
  colorPasswordStrengthWeak: '#FF5028',
  colorPasswordStrengthAverage: '#FDB942',
  colorPasswordStrengthGood: '#06BEE7',
  colorPasswordStrengthStrong: '#00876A',

  // Progress Bar Colors, Linear Gradient
  colorLinearGradientStart: '#FFC45E',
  colorLinearGradientEnd: '#D36E1C',
  colorProgressBarDefault: '#F4F4F4',
  colorProgressBarFilled: '#E2930A',

  // Header Colors
  colorMainHeaderBackground: '#F4F4F4',
  colorSecondaryHeaderBackground: '#ffffff',
  colorHeaderLogoLight: '#ffffff',
  colorHeaderLogoDark: '#003246',

  // Navigation Colors
  colorNavigationItemHover: '#fafafa',

  // Navigation Dropdown Color
  colorNavigationDropdownBackground: 'rgba(32, 38, 43, 0.9)',
  colorNavigationDropdownItemHoverBackground: 'rgba(7, 44, 100, 0.5)',

  // Account Colors
  colorAccountBackground: '#000000',
  colorAccountBorder: '#dde5e7',

  // Footer Colors
  colorFooterBackground: '#172029',
  colorFooterText: '#6e8fae',

  // Horizontal Break and Borders, Separators
  colorBorderPrimary: '#c9d6db',
  colorBorderSecondary: '#E2930A',

  // Table Colors
  colorTableHeaderBackground: '#f4f4f4',
  colorTableEvenRowBackground: '#fafafa',
  colorTableEvenRowHoverBackground: '#f4f4f4',
  colorTableSelectedBackground: 'rgba(226, 147, 10, 0.14)',
  colorHeadingRowItem: '#F0F5F6',

  // Shippment Status Colors
  colorShippmentSuccess: '#09846b',
  colorShippmentPending: '#E2930A',
  colorShippmentShipped: '#024f86',
  colorShippmentFailed: '#ff5028',

  // Address Status Colors
  colorVerifiedSuccess: '#09846b',
  colorNotVerified: '#8E8E8E',

  // Card Colors
  colorCardBackground: '#ffffff',
  colorCardSelected: 'rgba(226, 147, 10, 0.14)',

  // Scrollbar Colors
  colorScrollbarBackground: '#c2c5c6',

  // MakeOver Banner
  colorMakeOverBannerBackground: '#f5f5f5',
  colorMakeOverBanner: '#003246',

  // Pending Banner
  colorPendingBannerBackground: '#f1f5f6',

  // Onboarding
  colorOnboardingBoxBorder: '#f3f3f3',
  colorOnboardingBoxBackground: 'rgba(196, 196, 196, 0.2)',
  colorOnboardingTextPrimary: '#ffffff',

  // Empty Box
  colorEmptyBoxTextPrimary: '#C2C5C6',

  // Graphic
  colorGraphicGridLines: '#ebeff2',

  // Info Banner
  colorInfoBannerBackground: '#003246',
  colorInfoBannerText: '#ffffff',

  // Auth card
  colorAuthCardSubtitle: 'rgb(0, 50, 70)',

  // Registration
  colorRegistration: '#2B5769',
};

export const hardAssetsAllianceFonts = `
@font-face {
  font-family: "Segoe UI", ${variables.family.hardAssetsAlliance},sans-serif;
  src: url(${AvenirNextBold}) format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Segoe UI", ${variables.family.hardAssetsAlliance},sans-serif;
  src: url(${AvenirNextDemiBold}) format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Segoe UI", ${variables.family.hardAssetsAlliance}, sans-serif;
  src: url(${AvenirNextMedium}) format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Segoe UI", ${variables.family.hardAssetsAlliance},sans-serif;
  src: url(${AvenirNextRegular}) format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

*,
*::after,
*::before {
  font-family: "Segoe UI", ${variables.family.hardAssetsAlliance}, sans-serif;
}
`;
