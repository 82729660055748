import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Plus } from '../../assets/images/svg/plus.svg';

import Button from '../Button/Button';
import themeColors from '../../assets/styles/themeColors';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import { uFlexCenter } from '../../assets/styles/utility';

export const BankAccountAddElement = styled(Button)`
  border-radius: 0;
  border: 1px solid ${themeColors.colorBorderPrimary};
  background-color: ${themeColors.colorBackgroundPrimary};
  padding: ${pxToRem(12.5)} ${pxToRem(16.5)};
  text-transform: capitalize;
  justify-content: flex-start;
  font-weight: 600;
  color: ${themeColors.colorTextPrimary};
  font-size: ${pxToRem(16)};

  ${({ disabled }) =>
    disabled &&
    `opacity: 0.5;
    pointer-events: none;
    `}

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(16)};
  }
`;

export const BankAccountAddIcon = styled.div`
  ${uFlexCenter};
  width: ${pxToRem(32)};
  height: ${pxToRem(32)};
  border-radius: 4px;
  background-color: ${themeColors.colorPrimary};
  box-shadow: ${variables.shadow.boxShadow};
  margin-right: ${pxToRem(16)};
  flex-shrink: 0;
  padding: ${pxToRem(3)};
  color: ${themeColors.colorWhite};

  svg {
    width: ${pxToRem(32)};
    height: ${pxToRem(32)};
    color: ${themeColors.colorWhite};
  }
`;

const BankAccountAdd = ({ onClick, disabled, accountType }) => {
  const { t } = useTranslation();

  return (
    <BankAccountAddElement onClick={onClick} disabled={disabled}>
      <BankAccountAddIcon>
        <Plus />
      </BankAccountAddIcon>
      {accountType && t('bankAccounts.addNew', { accountType })}
    </BankAccountAddElement>
  );
};

BankAccountAdd.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  accountType: PropTypes.string,
};

export default BankAccountAdd;
