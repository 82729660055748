import styled from 'styled-components';
import { uFlexColumn } from '../../assets/styles/utility';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';

const ProfileSettingsCard = styled.div`
  ${uFlexColumn}
  padding: ${pxToRem(24)};
  background-color: ${themeColors.colorCardBackground};
  box-shadow: ${variables.shadow.boxShadow};
  border-radius: ${variables.borderRadius.borderRadius};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(24)};
  }
`;

export default ProfileSettingsCard;
