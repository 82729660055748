import {
  UPDATE_CHECK_WITHDRAWAL_ERROR,
  UPDATE_CHECK_WITHDRAWAL_SUCCESS,
  UPDATE_WIRE_WITHDRAWAL_ERROR,
  UPDATE_WIRE_WITHDRAWAL_SUCCESS,
  WITHDRAWAL_WIZARD_STATE_RESET,
} from '../../actions/orders/orderActionConstants';
import createReducer from '../../utils/createReducer';

const initialState = {
  wireWithdrawal: {},
  checkWithdrawal: {},
  errorMessage: '',
};

export default createReducer(
  {
    [UPDATE_WIRE_WITHDRAWAL_SUCCESS]: setUpdateWireWithdrawal,
    [UPDATE_WIRE_WITHDRAWAL_ERROR]: setUpdateWireWithdrawalError,
    [UPDATE_CHECK_WITHDRAWAL_SUCCESS]: setUpdateCheckWithdrawal,
    [UPDATE_CHECK_WITHDRAWAL_ERROR]: setUpdateCheckWithdrawalError,
    [WITHDRAWAL_WIZARD_STATE_RESET]: resetWithdrawalWizardState,
  },
  initialState,
);

function setUpdateWireWithdrawal(state, action) {
  return {
    ...state,
    wireWithdrawal: action.payload,
  };
}
function setUpdateWireWithdrawalError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}
function setUpdateCheckWithdrawal(state, action) {
  return {
    ...state,
    checkWithdrawal: action.payload,
  };
}
function setUpdateCheckWithdrawalError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}

function resetWithdrawalWizardState() {
  return initialState;
}
