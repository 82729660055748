import {
  createFetchType,
  createErrorType,
  createLoadingType,
  createSuccessType,
  createClearType,
} from '../actionHelpers';

// buy for storage
const PLAID_LINK_TOKEN_SCOPE = 'PLAID_LINK_TOKEN';
export const PLAID_LINK_TOKEN_FETCH = createFetchType(PLAID_LINK_TOKEN_SCOPE);
export const PLAID_LINK_TOKEN_SUCCESS = createSuccessType(
  PLAID_LINK_TOKEN_SCOPE,
);
export const PLAID_LINK_TOKEN_ERROR = createErrorType(PLAID_LINK_TOKEN_SCOPE);
export const PLAID_LINK_TOKEN_LOADING = createLoadingType(
  PLAID_LINK_TOKEN_SCOPE,
);
export const PLAID_LINK_TOKEN_CLEAR = createClearType(PLAID_LINK_TOKEN_SCOPE);
