import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  generateToken,
  resetLoginState,
} from '../../store/actions/login/loginActions';
import SectionLoader from '../../components/Loader/SectionLoader';
import { EMAIL_LOGIN_PAGE } from '../../constants/pages';
import { SESSION_STORAGE_SCOPE } from '../../constants/sessionStorage';
import { removeFromSessionStorage } from '../../util/helpers/sessionStorageHelper';
import { useQuery } from '../../util/hooks/useQuery';
import { EmptyPage } from '../../components/EmptyPage/EmptyPage';

const RegistrationLoginPage = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();

  const onSuccess = () => {
    const { page } = query;

    if (page) {
      history.push(page);
    }
  };

  useEffect(() => {
    removeFromSessionStorage(SESSION_STORAGE_SCOPE);
    dispatch(resetLoginState());

    const { token, accountUid } = query;

    if (!token) {
      history.push(EMAIL_LOGIN_PAGE);
    }

    if (accountUid && token) {
      dispatch(
        generateToken({
          data: `"${token}"`,
          accountUid,
          registration: true,
          onSuccess,
        }),
      );
    }
  }, []); //eslint-disable-line

  return (
    <EmptyPage>
      <SectionLoader isLoading />
    </EmptyPage>
  );
};

export default RegistrationLoginPage;
