import { all } from 'redux-saga/effects';

import bankAccountSaga from './bankAccountSaga';
import portfolioSaga from './portfolioSaga';
import settingsSaga from './settingsSaga';
import loginSaga from './loginSaga';
import accountSaga from './accountSaga';
import productsSaga from './productsSaga';
import documentsSaga from './documentsSaga';
import ordersSaga from './ordersSaga';
import metalStreamSaga from './metalStreamSaga';
import iraSettingsSaga from './iraSettingsSaga';
import userSaga from './userSaga';
import commonSaga from './commonSaga';
import plaidSaga from './plaidSaga';
import twoFASaga from './twoFASaga';
import creditCardSaga from './creditCardSaga';
import storageFeeSaga from './storageFeeSaga';
import mailInSaga from './mailInSaga';

export default function* rootSaga() {
  yield all([
    portfolioSaga(),
    settingsSaga(),
    bankAccountSaga(),
    creditCardSaga(),
    loginSaga(),
    accountSaga(),
    productsSaga(),
    documentsSaga(),
    ordersSaga(),
    metalStreamSaga(),
    iraSettingsSaga(),
    userSaga(),
    commonSaga(),
    plaidSaga(),
    twoFASaga(),
    storageFeeSaga(),
    mailInSaga(),
  ]);
}
