import i18next from 'i18next';
import * as Yup from 'yup';

export const accountDetailsValidationSchema = Yup.object().shape({
  accountHolderName: Yup.string().required(
    i18next.t('validationSchema.bankAccount.bankAccountHolderName'),
  ),
  accountType: Yup.object().shape({
    value: Yup.string().required(
      i18next.t('validationSchema.bankAccount.bankAccountTypeRequired'),
    ),
  }),
  accountNumber: Yup.string().required(
    i18next.t('validationSchema.bankAccount.bankAccountNumberRequired'),
  ),
  accountNumberRepeat: Yup.string()
    .oneOf(
      [Yup.ref('accountNumber'), null],
      i18next.t('validationSchema.bankAccount.bankAccountNumberRepeat'),
    )
    .required(
      i18next.t('validationSchema.bankAccount.bankAccountNumberRepeatRequired'),
    ),
  ObiMessage1: Yup.string()
    .nullable()
    .max(70, i18next.t(`validationSchema.bankAccount.obi1`)),
  ObiMessage2: Yup.string()
    .nullable()
    .max(70, i18next.t(`validationSchema.bankAccount.obi2`)),
  ObiMessage3: Yup.string()
    .nullable()
    .max(70, i18next.t(`validationSchema.bankAccount.obi3`)),
  ObiMessage4: Yup.string()
    .nullable()
    .max(70, i18next.t(`validationSchema.bankAccount.obi4`)),
});
