export const accountTypes = {
  Individual: 'Individual',
  JointTenantsWithRightOfSurvivorship: 'JointTenantsWithRightOfSurvivorship',
  JointTenantsInCommon: 'JointTenantsInCommon',
  Corporate: 'Corporate',
  Partnership: 'Partnership',
  SoleProprietorship: 'SoleProprietorship',
  LimitedLiabilityCorporation: 'LimitedLiabilityCorporation',
  Trust: 'Trust',
  IRA: 'IRA',
  UTMA: 'UTMA',
  Basic: 'Basic',
};

export const accountTypeKeys = Object.keys(accountTypes);

export const accountSubTypeKeys = ['Traditional', 'Roth', 'Simple', 'Sep'];

export const IRATrusteesType = ['Entrust', 'Millennium', 'Equity'];

export const UUAT_NAME_CHANGE = 'NameChange';
export const UUAT_SECURITY_QUESTION_CHANGE = 'SecurityQuestionChange';
export const UUAT_ACTIVATE_PROFILE = 'ActivateProfile';

export const ACCOUNT_RESEND_INVITE = 'ResendInvite';
export const ACCOUNT_CHANGE_USER_ROLE = 'ChangeUserRole';

export const userSuffixTypes = [
  'I',
  'II',
  'III',
  'IV',
  'IX',
  'JR',
  'SR',
  'V',
  'VI',
  'VII',
  'VIII',
  'ESQ',
  'MD',
];

export const ACC_TYPE_INACTIVE = 'Inactive';
export const ACC_TYPE_ACTIVE = 'Active';
export const ACC_TYPE_INVITED = 'Invited';
export const ACC_TYPE_LOCKED = 'Locked';

export const userStateType = [
  ACC_TYPE_INVITED,
  ACC_TYPE_LOCKED,
  ACC_TYPE_INACTIVE,
  ACC_TYPE_ACTIVE,
];

export const userStateTypeByIndex = {
  [ACC_TYPE_INVITED]: 1,
  [ACC_TYPE_LOCKED]: 2,
  [ACC_TYPE_INACTIVE]: 3,
  [ACC_TYPE_ACTIVE]: 4,
};

export const userPermissionTypes = [
  'NotificationOnly',
  'BackOffice',
  'PortalBackOfficeAdministrator',
  'FullAccess',
  'ReadOnly',
];

export const USER_ROLE_TYPES = {
  NOTIFICATION_ONLY: 1,
  FULL_ACCESS: 4,
  READ_ONLY: 5,
};

export const USER_ROLE_TYPES_LABELS = {
  [USER_ROLE_TYPES.NOTIFICATION_ONLY]: 'NotificationOnly',
  [USER_ROLE_TYPES.FULL_ACCESS]: 'FullAccess',
  [USER_ROLE_TYPES.READ_ONLY]: 'ReadOnly',
};

export const userPermissionLiterals = {
  1: 'Notification Only',
  2: 'BackOffice',
  3: 'PortalBackOfficeAdministrator',
  4: 'Full Access',
  5: 'Read Only',
};

export const userPermissions = {
  323: 'Display_MetalStream_MenuItem',
  324: 'Display_IRA_Options',
  325: 'Display_Standard_Options',
  326: 'Display_IRA_Millenium_Options',
  327: 'Display_IRA_Entrust_Options',
  328: 'Display_IRA_Equity_Options',
  329: 'Allow_Account_Changes',
  331: 'AllowClosedAccountOptions',
  333: 'AllowBasicAccountChanges',
};

export const UP_ALLOW_ACC_CHANGES = 'Allow_Account_Changes';
export const DISPLAY_IRA_OPTIONS = 'Display_IRA_Options';
export const DISPLAY_IRA_ENTRUST_OPTIONS = 'Display_IRA_Entrust_Options';
export const DISPLAY_IRA_MILLENIUM_OPTIONS = 'Display_IRA_Millenium_Options';
export const DISPLAY_IRA_EQUITY_OPTIONS = 'Display_IRA_Equity_Options';
export const DISPLAY_STANDARD_OPTIONS = 'Display_Standard_Options';
export const DISPLAY_METALSTREAM_MENUITEM = 'Display_MetalStream_MenuItem';
export const ALLOW_CLOSED_ACCOUNT_OPTIONS = 'AllowClosedAccountOptions';
export const ALLOW_BASIC_ACCOUNT_CHANGES = 'AllowBasicAccountChanges';

export const accountSecurityQuestionTypes = [
  'WhatWasYourChildhoodNickname',
  'InWhatCityDidYouMeetYourSpouseSignificantOther',
  'WhatIsTheNameOfYourFavoriteChildhoodFriend',
  'WhatStreetDidYouLiveOnInThirdGrade',
  'WhatSchoolDidYouAttendForSixthGrade',
  'WhatWasYourChildhoodPhoneNumberIncludingAreaCode',
  'WhatWasTheNameOfYourFirstStuffedAnimal',
  'InWhatCityOrTownDidYourMotherAnd',
  'WhereWereYouWhenYouHadYourFirstKiss',
  'WhatIsTheFirstNameOfTheBoyOr',
  'WhatWasTheLastNameOfYourThirdGrade',
  'InWhatCityDoesYourNearestSiblingLive',
  'WhatIsYourMaternalGrandmothersMaidenName',
  'InWhatCityOrTownWasYourFirstJob',
  'WhatIsTheNameOfACollegeYouApplied',
  'WhatWasTheNameOfYourElementaryPrimary',
  'WhatIsTheNameOfTheCompanyOfYour',
  'WhatWasYourFavoritePlaceToVisitAsA',
  'WhatIsTheCountryOfYourUltimateDreamVacation',
  'WhatIsTheNameOfYourFavoriteChildhoodTeacher',
  'WhatWasYourDreamJobAsAChild',
  'WhoWasYourChildhoodHero',
  'WhatWasTheFirstConcertYouAttended',
  'WhatAreTheLast5DigitsOfYourCredit',
  'WhatIsYourGrandmothersNickname',
  'WhatIsTheLastNameOfYourFavoriteHigh',
  'WhatWasTheMakeAndModelOfYourFirst',
  'WhatIsTheNameOfYourGrandmothersPet',
  'WhatWasTheNameOfYourFirstPet',
];

export const accountSecurityQuestionTypesObject = {
  WhatWasYourChildhoodNickname: 1,
  InWhatCityDidYouMeetYourSpouseSignificantOther: 2,
  WhatIsTheNameOfYourFavoriteChildhoodFriend: 3,
  WhatStreetDidYouLiveOnInThirdGrade: 4,
  WhatSchoolDidYouAttendForSixthGrade: 5,
  WhatWasYourChildhoodPhoneNumberIncludingAreaCode: 6,
  WhatWasTheNameOfYourFirstStuffedAnimal: 7,
  InWhatCityOrTownDidYourMotherAnd: 8,
  WhereWereYouWhenYouHadYourFirstKiss: 9,
  WhatIsTheFirstNameOfTheBoyOr: 10,
  WhatWasTheLastNameOfYourThirdGrade: 11,
  InWhatCityDoesYourNearestSiblingLive: 12,
  WhatIsYourMaternalGrandmothersMaidenName: 13,
  InWhatCityOrTownWasYourFirstJob: 14,
  WhatIsTheNameOfACollegeYouApplied: 15,
  WhatWasTheNameOfYourElementaryPrimary: 16,
  WhatIsTheNameOfTheCompanyOfYour: 17,
  WhatWasYourFavoritePlaceToVisitAsA: 18,
  WhatIsTheCountryOfYourUltimateDreamVacation: 19,
  WhatIsTheNameOfYourFavoriteChildhoodTeacher: 20,
  WhatWasYourDreamJobAsAChild: 21,
  WhoWasYourChildhoodHero: 22,
  WhatWasTheFirstConcertYouAttended: 23,
  WhatAreTheLast5DigitsOfYourCredit: 24,
  WhatIsYourGrandmothersNickname: 25,
  WhatIsTheLastNameOfYourFavoriteHigh: 26,
  WhatWasTheMakeAndModelOfYourFirst: 27,
  WhatIsTheNameOfYourGrandmothersPet: 28,
  WhatWasTheNameOfYourFirstPet: 29,
};
