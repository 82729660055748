import { WIZARD } from '../../constants/sessionStorage';
import {
  removeFromSessionStorage,
  retrieveFromSessionStorage,
  storeInSessionStorage,
} from './sessionStorageHelper';

export function closeWizardContent() {
  removeFromSessionStorage(WIZARD);
}

export function setWizardContent(key, value) {
  const data = retrieveFromSessionStorage(WIZARD);
  const newData = { ...data, [key]: value };
  storeInSessionStorage(WIZARD, newData);
}

export function setWizardSubContent(key, subkey, value) {
  const data = retrieveFromSessionStorage(WIZARD);
  data[key] = {
    ...data[key],
    [subkey]: value,
  };
  storeInSessionStorage(WIZARD, data);
}

export function setWizardSubObjectContent(key, subkey, objkey, value) {
  const data = retrieveFromSessionStorage(WIZARD);
  data[key][subkey] = {
    ...data[key][subkey],
    [objkey]: value,
  };
  storeInSessionStorage(WIZARD, data);
}

export function getWizardContent(key) {
  if (retrieveFromSessionStorage(WIZARD)) {
    return retrieveFromSessionStorage(WIZARD)[key];
  }
}

export function editWizardSubKey(key, subkey, value) {
  if (retrieveFromSessionStorage(WIZARD)) {
    const data = retrieveFromSessionStorage(WIZARD);
    data[key][subkey] = value;
    storeInSessionStorage(WIZARD, data);
  }
}

export function editWizardSubKeyObject(key, subkey, objKey, value) {
  if (retrieveFromSessionStorage(WIZARD)) {
    const data = retrieveFromSessionStorage(WIZARD);
    data[key][subkey][objKey] = value;
    storeInSessionStorage(WIZARD, data);
  }
}

export function deleteWizardKey(key) {
  if (retrieveFromSessionStorage(WIZARD)) {
    const data = retrieveFromSessionStorage(WIZARD);
    delete data[key];
    storeInSessionStorage(WIZARD, data);
  }
}
