import React from 'react';
import PropTypes from 'prop-types';
import { DEPOSIT_WIZARD_TYPES } from '../../../../constants/standardDepositWizardConstants';
import AchDepositWizard from './Ach/AchDepositWizard';
import WireStandardDeposit from './Wire/WireStandardDeposit';
import CheckStandardDepositWizard from './CheckDeposit/CheckStandardDepositWizard';

const StandardDepositSelectedFlow = ({
  wizardData,
  handleBack,
  setModalSize,
  setWizardData,
  handleClose,
  setWizardTitle,
  setWizardBack,
  handleRefreshTransactions,
}) => {
  const { depositType } = wizardData;

  const goToInitialModal = () => {
    handleBack();
    setWizardData({ depositType: null });
  };

  if (depositType === DEPOSIT_WIZARD_TYPES.ACH) {
    return (
      <AchDepositWizard
        setModalSize={setModalSize}
        goToInitialModal={goToInitialModal}
        wizardData={wizardData}
        setWizardData={setWizardData}
        handleClose={handleClose}
        setWizardBack={setWizardBack}
        setWizardTitle={setWizardTitle}
        handleRefreshTransactions={handleRefreshTransactions}
      />
    );
  }

  if (depositType === DEPOSIT_WIZARD_TYPES.WIRE) {
    return (
      <WireStandardDeposit
        setModalSize={setModalSize}
        goToInitialModal={goToInitialModal}
        setWizardTitle={setWizardTitle}
        setWizardBack={setWizardBack}
        handleClose={handleClose}
      />
    );
  }

  if (depositType === DEPOSIT_WIZARD_TYPES.CHECK) {
    return (
      <CheckStandardDepositWizard
        setModalSize={setModalSize}
        goToInitialModal={goToInitialModal}
        setWizardTitle={setWizardTitle}
        setWizardBack={setWizardBack}
        handleClose={handleClose}
      />
    );
  }
};

StandardDepositSelectedFlow.propTypes = {
  wizardData: PropTypes.shape({
    depositType: PropTypes.string,
  }),
  handleBack: PropTypes.func,
  setModalSize: PropTypes.func,
  setWizardData: PropTypes.func,
  handleClose: PropTypes.func,
  setWizardTitle: PropTypes.func,
  setWizardBack: PropTypes.func,
  handleRefreshTransactions: PropTypes.func,
};

export default StandardDepositSelectedFlow;
