import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import themeColors from '../../assets/styles/themeColors';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';

export const LabelElement = styled.span`
  color: ${themeColors.colorLabel};
  font-weight: ${({ isBold }) => (isBold ? '600' : '500')};
  font-size: ${({ fontSize }) => (fontSize ? pxToRem(fontSize) : pxToRem(14))};
  line-height: 1.75;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${pxToRem(marginBottom)}` : 0};
  margin-top: ${({ marginTop }) => (marginTop ? `${pxToRem(marginTop)}` : 0)};
  ${({ disabled }) => disabled && `opacity: 0.5`};
  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${({ fontSize }) =>
      fontSize ? pxToRemMd(fontSize) : pxToRemMd(14)};
  }
`;

const Label = ({
  text,
  marginBottom,
  marginTop,
  fontSize,
  isBold,
  disabled,
}) => (
  <LabelElement
    marginBottom={marginBottom}
    marginTop={marginTop}
    fontSize={fontSize}
    isBold={isBold}
    disabled={disabled}
  >
    {text}
  </LabelElement>
);

Label.propTypes = {
  text: PropTypes.string,
  marginBottom: PropTypes.number,
  marginTop: PropTypes.number,
  fontSize: PropTypes.number,
  isBold: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Label;
