import React, { useEffect, useRef, useState } from 'react';
import { Braintree, HostedField } from 'react-braintree-fields';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import SectionLoader from '../../Loader/SectionLoader';
import ModalButtons from '../../Modal/ModalButtons';
import { fetchClientToken } from '../../../store/actions/creditCard/creditCardActions';
import { FETCH_CLIENT_TOKEN_LOADING } from '../../../store/actions/creditCard/creditCardActionConstants';
import { selectGetClientToken } from '../../../store/selectors/creditCardSelectors';
import { getCurrentAccountUid } from '../../../store/selectors/accountSelectors';
import { selectIsLoadingByActionType } from '../../../store/selectors/loadingSelectors';
import {
  InputError,
  InputLabel,
} from '../../InputFields/Components/InputComponents';
import { ReactComponent as QuestionMark } from '../../../assets/images/svg/question.svg';
import { uOutlineNone } from '../../../assets/styles/utility';
import { variables } from '../../../assets/styles/variables';
import { mediaBelow, pxToRem } from '../../../assets/styles/helper';
import themeColors from '../../../assets/styles/themeColors';
import ProgressBar from '../../ProgressBar/ProgressBar';

const ProgressBarWrapper = styled.div`
  margin-bottom: ${pxToRem(25)};
  .RSPBprogressBar .RSPBstep {
    display: none;
  }
`;

const StepNumberNote = styled.p`
  font-style: italic;
`;

const StepTitle = styled.p`
  font-size: ${pxToRem(20)};
  font-weight: 600;
  margin-bottom: ${pxToRem(8)};
`;

const BraintreeFieldWrapper = styled.div`
  margin-bottom: ${({ withoutBottomMargin }) =>
    !withoutBottomMargin && `${pxToRem(12)}`};
  iframe {
    float: none !important;
  }

  .braintree-hosted-field {
    border-radius: ${variables.borderRadius.borderRadius};
    ${uOutlineNone};
    font-size: ${pxToRem(16)};
    line-height: 1.75;
    height: ${pxToRem(38)};
    padding: 0 ${pxToRem(12)};
    color: ${themeColors.colorInputPlaceholder};
    background-color: ${themeColors.colorInputBackground};
    width: 100%;
    border: ${({ error }) =>
      error
        ? `1px solid ${themeColors.colorError}`
        : `1px solid ${themeColors.colorInputBorder}`};
  }

  .braintree-hosted-fields-focused {
    border: ${({ error }) =>
      error
        ? `1px solid ${themeColors.colorError}`
        : `1px solid ${themeColors.colorPrimary}`};
  }
`;

const FlexRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
  margin-bottom: ${({ withBottomMargin }) =>
    withBottomMargin && `${pxToRem(12)}`};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    width: 100%;
  }
`;

const FieldSeparator = styled.div`
  margin: 0 6px;
  color: ${themeColors.colorLabel};
  font-size: ${pxToRem(16)};
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.75;
`;

const IconWrapper = styled.div`
  margin-left: 12px;

  svg {
    width: 16px;
    height: 16px;
    color: ${themeColors.colorPrimary};
  }
`;

const NextStepNote = styled.p`
  font-style: italic;
  margin-top: ${pxToRem(12)};
`;

const CardTypeText = styled.p`
  color: ${themeColors.colorTextSecondary};
`;

const EnterCardDetails = ({
  onButtonClick,
  handleNext,
  setWizardData,
  steps,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [tokenize, setTokenizeFunc] = useState();
  const [isFormValid, setIsFormValid] = useState(false);
  const [error, setError] = useState();
  const [cardNumberError, setCardNumberError] = useState('');
  const [cardholderNameError, setCardholderNameError] = useState('');
  const [expirationMonthError, setExpirationMonthError] = useState('');
  const [expirationYearError, setExpirationYearError] = useState('');
  const [cvvError, setCvvError] = useState('');
  const [cardType, setCardType] = useState();

  const accountUid = useSelector(getCurrentAccountUid);
  const clientToken = useSelector(selectGetClientToken);

  const cardNumberField = useRef();
  const cardholderNameField = useRef();
  const expirationMonthField = useRef();
  const expirationYearField = useRef();
  const cvvField = useRef();

  const isFetchingClientToken = useSelector(
    selectIsLoadingByActionType(FETCH_CLIENT_TOKEN_LOADING),
  );

  const onCardTypeChange = ({ cards }) => {
    if (cards.length === 1) {
      const [card] = cards;

      setCardType(card.type);
    } else {
      setCardType('');
    }
  };

  useEffect(() => {
    dispatch(fetchClientToken({ accountUid }));
  }, [dispatch, accountUid]);

  const onAuthorizationSuccess = () => cardNumberField?.current?.focus();
  const getNonceToken = () => {
    try {
      tokenize()
        .then((response) => {
          setWizardData({
            Bin: response.details.bin,
            Last4Digits: response.details.lastFour,
            ExpirationDate: `${response.details.expirationMonth}/${response.details.expirationYear}`,
            NameOnCard: response.details.cardholderName,
            Nonce: response.nonce,
            CreditCardType: response.details.cardType.replace(' ', ''),
          });
        })
        .then(handleNext);
    } catch (error) {
      setError(error);
    }
  };

  return (
    <SectionLoader isLoading={isFetchingClientToken}>
      <StepNumberNote>
        {t('creditCard.stepNumber', {
          currentStepNumber: 1,
          numberOfSteps: steps.length - 1,
        })}
      </StepNumberNote>
      <StepTitle>{t('creditCard.enterCardDetails')}</StepTitle>{' '}
      <ProgressBarWrapper>
        <ProgressBar currentStep={50} steps={steps} />
      </ProgressBarWrapper>
      {clientToken && (
        <>
          <Braintree
            authorization={clientToken}
            onAuthorizationSuccess={onAuthorizationSuccess}
            getTokenRef={(ref) => setTokenizeFunc(() => ref)}
            onCardTypeChange={onCardTypeChange}
            onValidityChange={(event) => {
              const {
                number,
                cardholderName,
                expirationMonth,
                expirationYear,
                cvv,
              } = event.fields;

              setIsFormValid(
                number.isValid &&
                  cardholderName.isValid &&
                  expirationMonth.isValid &&
                  expirationYear.isValid &&
                  cvv.isValid,
              );
            }}
            styles={{
              input: {
                'font-size': pxToRem(16),
                color: themeColors.colorInputPlaceholder,
                position: 'static',
              },
              ':focus': {
                color: themeColors.colorInputPlaceholder,
              },
              '.number': {
                'font-size': pxToRem(16),
              },
              select: {
                'font-size': pxToRem(16),
                color: themeColors.colorInputPlaceholder,
              },
            }}
          >
            <BraintreeFieldWrapper error={cardNumberError}>
              <InputLabel>{t('creditCard.fields.cardNumber')}</InputLabel>
              <HostedField
                onError={cardNumberError}
                type="number"
                ref={cardNumberField}
                autocomplete="cc-number"
                onEmpty={() =>
                  setCardNumberError(
                    t(
                      'validationSchema.addCreditCardValidation.cardNumberRequired',
                    ),
                  )
                }
                onBlur={(event) => {
                  if (event.isEmpty) {
                    setCardNumberError(
                      t(
                        'validationSchema.addCreditCardValidation.cardNumberRequired',
                      ),
                    );
                  } else if (!event.isEmpty && !event.isValid) {
                    setCardNumberError(
                      t(
                        'validationSchema.addCreditCardValidation.cardNumberIsNotValid',
                      ),
                    );
                  }
                }}
                onValidityChange={(event) => {
                  if (!event.isValid) {
                    setCardNumberError(
                      t(
                        'validationSchema.addCreditCardValidation.cardNumberIsNotValid',
                      ),
                    );
                  } else if (event.isValid) {
                    setCardNumberError('');
                  }
                }}
              />

              {cardNumberError && <InputError>{cardNumberError}</InputError>}
              <CardTypeText>
                Card type: {cardType ? cardType.toUpperCase() : '-'}
              </CardTypeText>
            </BraintreeFieldWrapper>
            <BraintreeFieldWrapper error={cardholderNameError}>
              <InputLabel>{t('creditCard.fields.nameOnCard')}</InputLabel>

              <HostedField
                type="cardholderName"
                ref={cardholderNameField}
                autocomplete="cc-name"
                onBlur={(event) => {
                  if (event.isEmpty) {
                    setCardholderNameError(
                      t(
                        'validationSchema.addCreditCardValidation.nameOnCardIsRequired',
                      ),
                    );
                  }
                }}
                onValidityChange={(event) => {
                  if (!event.isValid) {
                    setCardholderNameError(
                      t(
                        'validationSchema.addCreditCardValidation.nameOnCardIsRequired',
                      ),
                    );
                  } else if (event.isValid) {
                    setCardholderNameError('');
                  }
                }}
              />

              {cardholderNameError && (
                <InputError>{cardholderNameError}</InputError>
              )}
            </BraintreeFieldWrapper>
            <InputLabel>{t('creditCard.fields.expires')}</InputLabel>
            <InRowWrapper withBottomMargin>
              <BraintreeFieldWrapper
                error={expirationMonthError}
                withoutBottomMargin
              >
                <HostedField
                  type="expirationMonth"
                  ref={expirationMonthField}
                  autocomplete="cc-exp-month"
                  placeholder="MM"
                  onBlur={(event) => {
                    if (event.isEmpty) {
                      setExpirationMonthError(
                        t(
                          'validationSchema.addCreditCardValidation.expirationMonthAndYearIsRequired',
                        ),
                      );
                    }
                  }}
                  onValidityChange={(event) => {
                    if (!event.isValid) {
                      setExpirationMonthError(
                        t(
                          event.isEmpty
                            ? 'validationSchema.addCreditCardValidation.expirationMonthAndYearIsRequired'
                            : 'validationSchema.addCreditCardValidation.expirationMonthAndYearIsNotValid',
                        ),
                      );
                    } else if (event.isValid) {
                      setExpirationMonthError('');
                    }
                  }}
                />
              </BraintreeFieldWrapper>

              <FieldSeparator>/</FieldSeparator>
              <BraintreeFieldWrapper
                error={expirationYearError}
                withoutBottomMargin
              >
                <HostedField
                  type="expirationYear"
                  ref={expirationYearField}
                  autocomplete="cc-exp-year"
                  placeholder="YY"
                  onBlur={(event) => {
                    if (event.isEmpty) {
                      setExpirationYearError(
                        t(
                          'validationSchema.addCreditCardValidation.expirationMonthAndYearIsRequired',
                        ),
                      );
                    }
                  }}
                  onValidityChange={(event) => {
                    if (!event.isValid) {
                      setExpirationYearError(
                        t(
                          event.isEmpty
                            ? 'validationSchema.addCreditCardValidation.expirationMonthAndYearIsRequired'
                            : 'validationSchema.addCreditCardValidation.expirationMonthAndYearIsNotValid',
                        ),
                      );
                    } else if (event.isValid) {
                      setExpirationYearError('');
                    }
                  }}
                />
              </BraintreeFieldWrapper>
            </InRowWrapper>
            {(expirationMonthError || expirationYearError) && (
              <InputError>
                {expirationMonthError || expirationYearError}
              </InputError>
            )}
            <BraintreeFieldWrapper error={cvvError}>
              <InputLabel>{t('creditCard.fields.securityCode')}</InputLabel>
              <FlexRowWrapper>
                <InRowWrapper>
                  <HostedField
                    type="cvv"
                    ref={cvvField}
                    autocomplete="cc-exp-year"
                    onEmpty={() =>
                      setCvvError(
                        t(
                          'validationSchema.addCreditCardValidation.securityCodeIsRequired',
                        ),
                      )
                    }
                    onBlur={(event) => {
                      if (event.isEmpty) {
                        setCvvError(
                          t(
                            'validationSchema.addCreditCardValidation.securityCodeIsRequired',
                          ),
                        );
                      } else if (!event.isEmpty && !event.isValid) {
                        setCvvError(
                          t(
                            'validationSchema.addCreditCardValidation.securityCodeIsNotValid',
                          ),
                        );
                      }
                    }}
                    onValidityChange={(event) => {
                      if (!event.isValid && !event.isEmpty) {
                        setCvvError(
                          t(
                            'validationSchema.addCreditCardValidation.securityCodeIsNotValid',
                          ),
                        );
                      } else if (event.isValid) {
                        setCvvError('');
                      }
                    }}
                  />
                </InRowWrapper>
                <IconWrapper onClick={() => {}}>
                  <QuestionMark />
                </IconWrapper>
              </FlexRowWrapper>
              {cvvError && <InputError>{cvvError}</InputError>}
            </BraintreeFieldWrapper>
            {error && (
              <InputError>{t('apiErrors.SomethingWentWrong')}</InputError>
            )}
          </Braintree>
          <ModalButtons
            isHorizontal
            marginTop
            secondaryButtonProps={{
              onClick: onButtonClick,
              label: t('common.cancel'),
            }}
            primaryButtonProps={{
              type: 'submit',
              label: t('common.next'),
              onClick: getNonceToken,
              disabled: !isFormValid,
            }}
          />
        </>
      )}
      <NextStepNote>{t('creditCard.nextStepText')}</NextStepNote>
    </SectionLoader>
  );
};

EnterCardDetails.propTypes = {
  onButtonClick: PropTypes.func,
  handleNext: PropTypes.func,
  setWizardData: PropTypes.func,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      percentage: PropTypes.number,
    }),
  ),
};

export default EnterCardDetails;
