import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';

import Table from '../../Table/Table';
import TableHead from '../../Table/TableHead';
import TableBody from '../../Table/TableBody';
import TableRow from '../../Table/TableRow';
import TransactionRow from '../../TransactionTable/TransactionRow';
import { formatDate } from '../../../util/helpers/dateHelpers';
import {
  emptyPortfolioTransactionSingle,
  portfolioTransactionsSingleFetch,
} from '../../../store/actions/portfolio/portfolioActions';
import TransactionDetails from '../../Modals/Transaction/TransactionDetails';
import {
  selectPortfolioSingleTransaction,
  selectPortfolioSingleTransactionError,
} from '../../../store/selectors/portfolioTransactionsSelector';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import {
  transactionTypesArray,
  transactionStatusTypesArray,
} from '../../../util/enum/api/transactionTypes';
import { selectIsLoadingByActionType } from '../../../store/selectors/loadingSelectors';
import { PORTFOLIO_TRANSACTIONS_SINGLE_LOADING } from '../../../store/actions/portfolio/portfolioActionConstants';
import SectionLoader from '../../Loader/SectionLoader';
import {
  transactionProductTable,
  transactionProductTableBody,
  transactionProductTableHead,
  transactionProductTableRow,
} from '../../Table/Components/TransactionProductTable';

const ProductTransactionsTable = ({ data, accountUid }) => {
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState(null);
  const [isTransactionDetailsOpen, setIsTransactionDetailsOpen] = useState(
    false,
  );
  const singleTransaction = useSelector(selectPortfolioSingleTransaction);
  const singleTransactionError = useSelector(
    selectPortfolioSingleTransactionError,
  );
  const singleTransactionLoading = useSelector(
    selectIsLoadingByActionType(PORTFOLIO_TRANSACTIONS_SINGLE_LOADING),
  );

  useEffect(() => {
    if (data && data.length) {
      setRowData(
        data
          .map((transaction) => {
            const action = i18next.t(
              `portfolio.transactions.modalTypes.${parseEnumType(
                transactionTypesArray,
                transaction.Type,
              )}`,
            );

            const statusType = parseEnumType(
              transactionStatusTypesArray,
              transaction.StatusType,
            );
            return {
              id: transaction.Uid,
              content: (
                <TransactionRow
                  showQuantity
                  key={transaction.Uid}
                  month={formatDate(transaction.DateTime, 'MMM')}
                  day={formatDate(transaction.DateTime, 'dd')}
                  status={statusType}
                  quantity={transaction.Quantity}
                  action={action}
                  isSegregated={transaction.IsSegregated}
                />
              ),
            };
          })
          .filter(Boolean),
      );
    }
  }, [data]);

  const onTransactionCardClick = (transactionUid) => {
    dispatch(
      portfolioTransactionsSingleFetch({
        accountUid,
        transactionUid,
      }),
    );
    setIsTransactionDetailsOpen(true);
  };

  if (isEmpty(rowData)) {
    return null;
  }

  const headerData = [
    {
      id: 1,
      content: i18next.t(
        'portfolio.transactions.tableHeadings.yourTransactions',
      ),
    },
    {
      id: 2,
      content: i18next.t('portfolio.transactions.tableHeadings.quantity'),
    },
    {
      id: 4,
      content: i18next.t('portfolio.transactions.tableHeadings.status'),
    },
    { id: 5, content: '' },
  ];

  return (
    <>
      <Table css={transactionProductTable}>
        <TableHead data={headerData} css={transactionProductTableHead} />
        <SectionLoader isLoading={singleTransactionLoading}>
          <TableBody css={transactionProductTableBody}>
            <TableRow
              css={transactionProductTableRow}
              hasArrow
              onClick={(transaction) => onTransactionCardClick(transaction.id)}
              rowData={rowData}
            />
          </TableBody>
        </SectionLoader>
      </Table>
      <TransactionDetails
        isOpen={!isEmpty(singleTransaction) && isTransactionDetailsOpen}
        onClose={() => {
          dispatch(emptyPortfolioTransactionSingle());
          setIsTransactionDetailsOpen(false);
        }}
        transaction={singleTransaction}
        error={singleTransactionError}
      />
    </>
  );
};

ProductTransactionsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      DateTime: PropTypes.string,
      Amount: PropTypes.number,
      EffectiveBalance: PropTypes.number,
      StatusType: PropTypes.number,
      Caption: PropTypes.string,
    }),
  ),
  accountUid: PropTypes.string,
};

export default ProductTransactionsTable;
