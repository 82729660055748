import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Modal from '../../Modal/Modal';
import { formatMoneyNumeral } from '../../../util/helpers/numeralHelpers';
import { deleteWizardKey } from '../../../util/helpers/wizardHelpers';
import ModalBody from '../../Modal/ModalBody';
import ModalButtons from '../../Modal/ModalButtons';
import Paragraph from '../../Paragraph/Paragraph';
import InformationList from '../../InformationList/InformationList';
import InformationListItem from '../../InformationList/InformationListItem';

const NoFundsModal = ({ balances, isOpen, handleBack, handleClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      title={t('standardWithdrawalWizard.dontSeeFunds')}
      close={handleClose}
      size="sm"
    >
      <ModalBody>
        <Paragraph marginBottom={32}>
          {t('standardWithdrawalWizard.noFunds.availableFundsDescription')}
        </Paragraph>
        <InformationList>
          <InformationListItem
            withBackground
            label={t('allFunds.effectiveBalance')}
            value={formatMoneyNumeral(balances.Effective)}
          />
          <InformationListItem
            withBackground
            label={t('allFunds.forTrading')}
            value={formatMoneyNumeral(balances.AvailableForTrading)}
          />
          <InformationListItem
            withBackground
            label={t('allFunds.forWithdrawal')}
            value={formatMoneyNumeral(balances.AvailableForWithdrawal)}
          />
        </InformationList>

        <ModalButtons
          isVertical
          marginTop
          secondaryButtonProps={{
            onClick: () => {
              deleteWizardKey('FundsModalInfo');
              handleBack();
            },
          }}
          primaryButtonProps={{
            label: t('standardWithdrawalWizard.noFunds.balanceHistory'),
            onClick: handleClose,
          }}
        />
      </ModalBody>
    </Modal>
  );
};

NoFundsModal.propTypes = {
  balances: PropTypes.shape({
    Effective: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    AvailableForTrading: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    AvailableForWithdrawal: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
  isOpen: PropTypes.bool,
  handleBack: PropTypes.func,
  handleClose: PropTypes.func,
};

export default NoFundsModal;
