import React from 'react';
import { Formik, Form, Field } from 'formik';

import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import i18next from '../../i18n';
import TextField from '../InputFields/TextField';
import Button from '../Button/Button';
import { selectSettings } from '../../store/selectors/settingsSelectors';

const SupportSearch = () => {
  const settings = useSelector(selectSettings);

  if (isEmpty(settings)) {
    return null;
  }

  const { FrequentlyAskedQuestionsUrl } = settings;

  if (!FrequentlyAskedQuestionsUrl) {
    return null;
  }

  const handleSearchSubmit = ({ searchTerm }) =>
    window.open(
      FrequentlyAskedQuestionsUrl?.replace(':query', searchTerm),
      '_blank',
    );

  return (
    <Formik initialValues={{ searchTerm: '' }} onSubmit={handleSearchSubmit}>
      <Form>
        <Field
          name="searchTerm"
          placeholder="Search..."
          component={TextField}
        />
        <Button variant="primary" type="submit">
          {i18next.t('common.search')}
        </Button>
      </Form>
    </Formik>
  );
};

export default SupportSearch;
