import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import * as jwt from 'jsonwebtoken';
import get from 'lodash.get';
import {
  INVITE_USER_TWO_FA_VERIFY_CHOOSE_METHOD,
  INVITE_USER_WELCOME_PAGE,
  TWO_FA_SUCCESSFUL_SETUP,
  VERIFY_CHOOSE_METHOD_PAGE,
} from '../../../constants/pages';
import QrCode from '../../../components/TwoFactorAuthentication/Components/QrCode';
import CodeField from '../../../components/InputFields/CodeField';
import {
  fetchTwoFaKeys,
  postTwoFa,
} from '../../../store/actions/twoFA/twoFAActions';
import {
  TWO_FA_APPLICATION_KEYS_LOADING,
  SAVE_TWO_FA_LOADING,
} from '../../../store/actions/twoFA/twoFAActionConstants';
import { selectIsLoadingByActionTypes } from '../../../store/selectors/loadingSelectors';
import { selectTwoFAApplicationKeys } from '../../../store/selectors/twoFASelector';
import QrCodeFailed from '../../../components/TwoFactorAuthentication/Components/QrCodeFailed';
import { securityCodeValidationSchema } from '../../../validation/securityCodeValidationSchema';
import Paragraph from '../../../components/Paragraph/Paragraph';
import SingleColumnList from '../../../components/TwoColumnList/SingleColumnList';
import SectionLoader from '../../../components/Loader/SectionLoader';
import AuthCard from '../../../components/Auth/AuthCard';
import Auth from '../../../components/Auth/Auth';
import AuthButtons from '../../../components/Auth/AuthButtons';
import { useSessionStorageState } from '../../../util/hooks/useSessionStorageState';
import {
  ERROR,
  INVITE_USER_DATA,
  USER_INVITE_STATUS_MODAL,
} from '../../../constants/sessionStorage';
import { retrieveFromSessionStorage } from '../../../util/helpers/sessionStorageHelper';
import { JWT_TOKEN } from '../../../constants/localStorage';
import { getUsernames } from '../../../util/helpers/userHelpers';
import { accountSecurityQuestionTypesObject } from '../../../util/enum/api/accountTypes';
import { closeWizardContent } from '../../../util/helpers/wizardHelpers';
import { inviteUser } from '../../../store/actions/user/userActions';
import { setRegisterContent } from '../../../util/helpers/registerHelpers';

const initialValues = {
  SecurityCode: '',
};

const TwoFAQRCodePage = ({
  goStepBack,
  goStepForward,
  twoFaValue,
  setTwoFaValues,
  user,
  isInviteUserFlow,
  formValues,
  userInviteId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [didKeysFetchFail, setDidKeysFetchFail] = useState(false);
  const { QrCodeImageUrl, ManualEntryKey } = useSelector(
    selectTwoFAApplicationKeys,
  );
  const [errorInviteUser, setError] = useSessionStorageState(ERROR, '');
  const [statusModal, setStatusModal] = useSessionStorageState(
    USER_INVITE_STATUS_MODAL,
    false,
  );

  const isLoading = useSelector(
    selectIsLoadingByActionTypes([
      TWO_FA_APPLICATION_KEYS_LOADING,
      SAVE_TWO_FA_LOADING,
    ]),
  );

  const { UserUid } = user;
  const { AuthenticationMethodType } = twoFaValue;

  const getTwoFaKeys = () => {
    dispatch(fetchTwoFaKeys({ userUid: UserUid, setDidKeysFetchFail }));
  };

  useEffect(() => {
    if (!isEmpty(user) && UserUid) getTwoFaKeys();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleBack = () => {
    setTwoFaValues(null);
    goStepBack(
      isInviteUserFlow
        ? INVITE_USER_TWO_FA_VERIFY_CHOOSE_METHOD
        : VERIFY_CHOOSE_METHOD_PAGE,
    );
  };

  const handleNext = () => {
    goStepForward(
      isInviteUserFlow ? INVITE_USER_WELCOME_PAGE : TWO_FA_SUCCESSFUL_SETUP,
    );
  };
  const existingLoginHandleNextClick = () => {
    const { values } = formValues;
    const JwtToken = retrieveFromSessionStorage(JWT_TOKEN);
    const { Username, Password } = values;
    const { SecurityQuestionType } = jwt.decode(JwtToken);
    const usernames = getUsernames();
    const LinkWithExistingLogin = usernames.length > 0;
    const inviteUserData = {
      Username: Username.value,
      Password,
      LinkWithExistingLogin,
      InvitedUserInfo: {
        EncryptedPersonEntity: userInviteId,
        SecurityQuestionType: get(
          accountSecurityQuestionTypesObject,
          SecurityQuestionType,
          null,
        ),
      },
    };

    dispatch(
      inviteUser({
        data: inviteUserData,
        meta: {
          handleNext: () => handleNext(INVITE_USER_WELCOME_PAGE),
          createSessionUser: () =>
            setRegisterContent(INVITE_USER_DATA, inviteUserData),
          // setIsLoading,
          setError,
          setStatusModal,
        },
      }),
    );
  };
  const handleSubmit = (values, { setFieldError }) => {
    const { SecurityCode } = values;
    const requestData = {
      AuthenticationMethodType,
      SecurityCode,
    };

    dispatch(
      postTwoFa({
        userUid: UserUid,
        requestData,
        handleNext,
        setFieldError,
        isInviteUserFlow,
        existingLoginHandleNextClick,
      }),
    );
  };

  return (
    <SectionLoader isLoading={isLoading}>
      <Auth>
        <AuthCard title={t('twoFa.loginSetup.authenticatior.title')}>
          <Paragraph marginBottom={40} isPrimaryColor bold>
            {t('twoFa.loginSetup.authenticatior.subtitle')}
          </Paragraph>
          <Paragraph>
            {t('twoFa.loginSetup.authenticatior.instruction')}
          </Paragraph>
          {didKeysFetchFail ? (
            <QrCodeFailed onClick={getTwoFaKeys} />
          ) : (
            <SingleColumnList marginBottom={64} alignCenter>
              <QrCode QrCodeImageUrl={QrCodeImageUrl} />
              <Paragraph bold>
                {ManualEntryKey?.match(/.{1,4}/g).map((part) => `${part} `)}
              </Paragraph>
            </SingleColumnList>
          )}
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={securityCodeValidationSchema}
          >
            {({ errors, setFieldValue }) => (
              <Form>
                <SingleColumnList marginBottom={64}>
                  <CodeField
                    fixedSize
                    name="SecurityCode"
                    errors={errors}
                    onCodeChange={(value) =>
                      setFieldValue('SecurityCode', value)
                    }
                    hideExpireCodeNote
                  />
                </SingleColumnList>
                <AuthButtons
                  isHorizontal
                  secondaryButtonProps={{ onClick: handleBack }}
                  primaryButtonProps={{
                    type: 'submit',
                  }}
                />
              </Form>
            )}
          </Formik>
        </AuthCard>
      </Auth>
      {errorInviteUser && statusModal && (
        <statusModal
          isOpen={statusModal}
          hasError={errorInviteUser}
          close={() => {
            setStatusModal(false);
            setError('');
            closeWizardContent(ERROR);
            closeWizardContent(USER_INVITE_STATUS_MODAL);
          }}
          text={errorInviteUser}
          modalTitle={t('inviteUser.review.statusModal')}
          backButtonText={t('common.ok')}
          onButtonClick={() => {
            setStatusModal(false);
            setError('');
            closeWizardContent(ERROR);
            closeWizardContent(USER_INVITE_STATUS_MODAL);
          }}
        />
      )}
    </SectionLoader>
  );
};

TwoFAQRCodePage.propTypes = {
  goStepBack: PropTypes.func,
  goStepForward: PropTypes.func,
  setTwoFaValues: PropTypes.func,
  twoFaValue: PropTypes.shape({
    AuthenticationMethodType: PropTypes.string,
  }),
  user: PropTypes.shape({
    UserUid: PropTypes.string,
    Username: PropTypes.string,
  }),
  formValues: PropTypes.shape({
    values: PropTypes.shape({
      ExistingUsername: PropTypes.string,
      LegalFirstName: PropTypes.string,
      LegalMiddleName: PropTypes.string,
      LegalLastName: PropTypes.string,
      Suffix: PropTypes.string,
      Username: PropTypes.string,
      Password: PropTypes.string,
      SecurityQuestion: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
        PropTypes.string,
      ]),
      Answer: PropTypes.string,
      SecurityCode: PropTypes.string,
      Remember: PropTypes.bool,
    }),
  }),
  isInviteUserFlow: PropTypes.bool,
  userInviteId: PropTypes.string,
};
export default TwoFAQRCodePage;
