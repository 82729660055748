import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatMoneyNumeral } from '../../../util/helpers/numeralHelpers';
import HoldingTable from './HoldingTable';
import { variables } from '../../../assets/styles/variables';
import themeColors from '../../../assets/styles/themeColors';
import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import { DIAMOND_PRODUCT_TYPE } from '../../../util/enum/api/productTypes';

const HoldingTotal = styled.div`
  border-radius: ${variables.borderRadius.borderRadius};
  background-color: ${themeColors.colorWhite};
  box-shadow: ${variables.shadow.boxShadow};
  padding: ${pxToRem(28)} ${pxToRem(16)} ${pxToRem(28)} ${pxToRem(24)};
  display: grid;
  grid-template-columns: 1fr auto ${pxToRem(32)};
  grid-gap: ${pxToRem(8)};

  p {
    font-weight: 600;
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: ${pxToRemMd(28)} ${pxToRemMd(16)} ${pxToRemMd(28)} ${pxToRemMd(16)};
    line-height: 1.75;
  }
`;

const HoldingTotalValue = styled.p`
  margin-left: auto;
`;

const HoldingsContainer = ({
  sortedHoldings,
  totalValuation,
  isSortByProduct,
  isSortByVault,
  isSortByType,
}) => {
  const { t } = useTranslation();

  const getPortfolioProductsValuations = (city) =>
    sortedHoldings[city].reduce((acc, product) => acc + product.Valuation, 0);

  const getPortfolioProductOunces = (city) =>
    sortedHoldings[city].reduce(
      (acc, product) => acc + product.EffectiveFineWeight,
      0,
    );

  const getPortfolioProductQuantity = (city) =>
    sortedHoldings[city].reduce((acc, product) => acc + product.Quantity, 0);

  return (
    <>
      <HoldingTotal>
        <p>{t('portfolio.holdings.totalValuation')}</p>
        <HoldingTotalValue>
          {formatMoneyNumeral(totalValuation)}
        </HoldingTotalValue>
      </HoldingTotal>
      {Object.keys(sortedHoldings).map((item) => (
        <HoldingTable
          key={item}
          title={item}
          value={formatMoneyNumeral(getPortfolioProductsValuations(item))}
          ounces={getPortfolioProductOunces(item)}
          quantity={getPortfolioProductQuantity(item)}
          data={sortedHoldings[item]}
          isSortByProduct={isSortByProduct}
          isSortByVault={isSortByVault}
          isSortByType={isSortByType}
          isDiamondProduct={item === DIAMOND_PRODUCT_TYPE}
        />
      ))}
    </>
  );
};

HoldingsContainer.propTypes = {
  sortedHoldings: PropTypes.shape({}),
  totalValuation: PropTypes.number,
  isSortByProduct: PropTypes.bool,
  isSortByVault: PropTypes.bool,
  isSortByType: PropTypes.bool,
};

export default HoldingsContainer;
