import { createSelector } from 'reselect';
import isEmpty from 'lodash.isempty';
import { getWizardContent } from '../../util/helpers/wizardHelpers';
import {
  DELIVERY_VERIFIED_DATA,
  PRODUCT_BY_SYMBOL,
  REVIEW_PRODUCT,
} from '../../constants/sessionStorage';

const productsSelector = (state) => state.products;

export const selectProductLocations = (productCode) =>
  createSelector(productsSelector, (state) => {
    const foundProduct = state.productPrices.data.find(
      (product) => product.ProductCode === productCode,
    );
    return foundProduct ? foundProduct.Locations : [];
  });

export const selectProductPrices = createSelector(
  productsSelector,
  (state) => state.productPrices.data,
);

export const selectProductLocationsError = createSelector(
  productsSelector,
  (state) => state.productPrices.errorMsg,
);

export const selectProductTiers = createSelector(
  productsSelector,
  (state) => state.productTiers.data,
);

export const selectProductTiersError = createSelector(
  productsSelector,
  (state) => state.productTiers.errorMsg,
);

export const getProductBySymbol = createSelector(productsSelector, (state) =>
  !isEmpty(state.productBySymbol)
    ? state.productBySymbol
    : getWizardContent(PRODUCT_BY_SYMBOL),
);

export const getReviewProduct = createSelector(
  productsSelector,
  (state) => state.reviewProduct || getWizardContent(REVIEW_PRODUCT),
);

export const selectDeliverFromStorageVerifyData = createSelector(
  productsSelector,
  (state) =>
    state.deliverFromStorageVerifyData ||
    getWizardContent(DELIVERY_VERIFIED_DATA),
);

export const selectConvertProducts = createSelector(
  productsSelector,
  (state) => state?.convertProducts?.data,
);
