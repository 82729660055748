import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Tooltip from '../Tooltip/Tooltip';
import { uTextEllipsis } from '../../assets/styles/utility';
import { pxToRem } from '../../assets/styles/helper';

export const ProductCardTableData = styled.p`
  ${uTextEllipsis};
  display: block;
  font-size: ${pxToRem(18)};
  line-height: 1.3;
  font-weight: 600;
`;

const ProductCardTableRow = ({
  vault,
  perUnit,
  perOz,
  removePricePerUnit,
  removePricePerOz,
}) => {
  const [isPricePerUnitTruncated, setIsPricePerUnitTruncated] = useState(false);
  const [isPricePerOzTruncated, setIsPricePerOzTruncated] = useState(false);
  const pricePerUnitRef = useRef(null);
  const pricePerOzRef = useRef(null);

  const isTruncated = (el) => el?.scrollWidth > el?.clientWidth;

  useEffect(() => {
    if (pricePerUnitRef) {
      setIsPricePerUnitTruncated(isTruncated(pricePerUnitRef.current));
    }
  }, [pricePerUnitRef]);

  useEffect(() => {
    if (pricePerOzRef) {
      setIsPricePerOzTruncated(isTruncated(pricePerOzRef.current));
    }
  }, [pricePerOzRef]);

  return (
    <>
      <div>
        <ProductCardTableData>{vault}</ProductCardTableData>
      </div>
      <div>
        {!removePricePerUnit && (
          <div>
            {isPricePerUnitTruncated ? (
              <Tooltip fullWidth text={perUnit}>
                <ProductCardTableData ref={pricePerUnitRef}>
                  {perUnit}
                </ProductCardTableData>
              </Tooltip>
            ) : (
              <ProductCardTableData ref={pricePerUnitRef}>
                {perUnit}
              </ProductCardTableData>
            )}
          </div>
        )}
      </div>

      <div>
        {removePricePerOz ? (
          <div>
            {isPricePerOzTruncated ? (
              <Tooltip fullWidth text={perOz}>
                <ProductCardTableData ref={pricePerOzRef}>
                  {}
                </ProductCardTableData>
              </Tooltip>
            ) : (
              <ProductCardTableData ref={pricePerOzRef}>
                {}
              </ProductCardTableData>
            )}
          </div>
        ) : (
          <div>
            {isPricePerOzTruncated ? (
              <Tooltip fullWidth text={perOz}>
                <ProductCardTableData ref={pricePerOzRef}>
                  {perOz}
                </ProductCardTableData>
              </Tooltip>
            ) : (
              <ProductCardTableData ref={pricePerOzRef}>
                {perOz}
              </ProductCardTableData>
            )}
          </div>
        )}
      </div>
    </>
  );
};

ProductCardTableRow.propTypes = {
  vault: PropTypes.string,
  perUnit: PropTypes.string,
  perOz: PropTypes.string,
  removePricePerUnit: PropTypes.bool,
  removePricePerOz: PropTypes.bool,
};

export default ProductCardTableRow;
