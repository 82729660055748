import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import themeColors from '../../assets/styles/themeColors';

const PriceTierListHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${pxToRem(4)};
  background-color: ${themeColors.colorTableHeaderBackground};
  padding: ${pxToRem(3)} ${pxToRem(16)};
  z-index: 1;
  box-shadow: ${variables.shadow.boxShadow};

  > :first-child {
    text-align: left;
  }

  > :last-child {
    text-align: right;
  }

  p {
    font-size: ${pxToRem(14)};
    line-height: 1.5;
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(3)} ${pxToRemMd(16)};

    p {
      font-size: ${pxToRemMd(14)};
    }
  }
`;

const PriceTierList = ({ headerLabels, children }) => (
  <div>
    <PriceTierListHeader>
      {headerLabels.map((item) => (
        <p>{item}</p>
      ))}
    </PriceTierListHeader>
    <ul>{children}</ul>
  </div>
);

PriceTierList.propTypes = {
  headerLabels: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
};

export default PriceTierList;
