import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { pxToRem } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import Paragraph from '../Paragraph/Paragraph';
import Badge from './Badge';
import { ReactComponent as ArrowRight } from '../../assets/images/svg/chevron-right.svg';
import {
  uButtonClear,
  uFlexColumn,
  uOutlineNone,
} from '../../assets/styles/utility';
import { ReactComponent as AlertTriangle } from '../../assets/images/svg/alert-triangle.svg';
import { completeStatus } from '../../util/helpers/bankAccountHelper';

const BankAccountRequirementItemContainer = styled.button`
  ${uOutlineNone}
  ${uButtonClear}
  display: flex;
  text-align: left;
  align-items: flex-start;
  background-color: ${themeColors.colorBackgroundSecondary};
  padding: ${pxToRem(16)};
  cursor: pointer;

  &:not(:first-child) {
    margin-top: ${pxToRem(16)};
  }

  svg {
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
    margin-left: ${pxToRem(16)};
  }
`;

const ReUploadRequested = styled(Paragraph)`
  display: flex;
  align-items: center;

  svg {
    color: ${themeColors.colorWarning};
    margin-left: 0;
    margin-right: ${pxToRem(8)};
    width: ${pxToRem(24)};
    height: ${pxToRem(24)};
  }
`;

const BankAccountRequirementItemContent = styled.div`
  ${uFlexColumn}
  width: 100%;
`;

const BankAccountRequirementItem = ({
  title,
  description,
  badgeProps,
  onClick,
  shouldReUploadVoidedCheck,
  isUploadVoidedCheck,
  disabled,
}) => {
  const { t } = useTranslation();

  if (!badgeProps) {
    return null;
  }

  const { status } = badgeProps;

  const isBankAccountRequirementDisabled = (status) => {
    const incompleteStatus = t('bankAccounts.verificationStatus.incomplete');
    return (
      (!isUploadVoidedCheck && status !== incompleteStatus) ||
      (isUploadVoidedCheck &&
        status !== completeStatus &&
        status !== incompleteStatus)
    );
  };

  const isBankAccountRequirementComplete = (status) => {
    const completeStatus = t('bankAccounts.verificationStatus.complete');
    return status === completeStatus;
  };

  return (
    <BankAccountRequirementItemContainer
      type="button"
      disabled={isBankAccountRequirementDisabled(status) || disabled}
      onClick={onClick}
    >
      <BankAccountRequirementItemContent>
        <Paragraph>{title}</Paragraph>
        <Badge {...badgeProps} />
        {shouldReUploadVoidedCheck && (
          <ReUploadRequested marginTop={16}>
            <AlertTriangle />
            {t('restriction.verifyBankAccount.documentReUpload')}
          </ReUploadRequested>
        )}
        {!isBankAccountRequirementComplete(status) && (
          <Paragraph marginTop={16}>{description}</Paragraph>
        )}
      </BankAccountRequirementItemContent>
      {!isBankAccountRequirementDisabled(status) && <ArrowRight />}
    </BankAccountRequirementItemContainer>
  );
};

export default BankAccountRequirementItem;

BankAccountRequirementItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
  badgeProps: PropTypes.shape({
    status: PropTypes.string,
  }),
  shouldReUploadVoidedCheck: PropTypes.bool,
  isUploadVoidedCheck: PropTypes.bool,
  disabled: PropTypes.bool,
};
