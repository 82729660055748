import PropTypes from 'prop-types';
import React from 'react';
import 'react-step-progress-bar/styles.css';
import {
  ProgressBar as ProgressBarComponent,
  Step,
} from 'react-step-progress-bar';
import styled from 'styled-components';
import ProgressBarStep from './ProgressBarStep';
import themeColors from '../../assets/styles/themeColors';

const ProgressBarContainer = styled.div`
  width: 100%;

  > :first-child {
    width: 100%;
  }
`;

const ProgressBar = ({ steps, currentStep = 0 }) => (
  <ProgressBarContainer>
    <ProgressBarComponent
      percent={currentStep}
      filledBackground={`linear-gradient(to right, ${themeColors.colorLinearGradientStart}, ${themeColors.colorLinearGradientEnd})`}
    >
      {steps.map((step, index) => {
        const stepNumber = index + 1;

        return (
          <Step transition="scale">
            {() => (
              <ProgressBarStep
                currentStep={currentStep}
                stepNumber={stepNumber}
                step={step}
              />
            )}
          </Step>
        );
      })}
    </ProgressBarComponent>
  </ProgressBarContainer>
);

ProgressBar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({})),
  currentStep: PropTypes.number,
};

export default ProgressBar;
