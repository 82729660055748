import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalTitle from '../../../Modal/ModalTitle';
import Paragraph from '../../../Paragraph/Paragraph';

const IraDeliveryAgreement = () => {
  const { t } = useTranslation();

  return (
    <>
      <ModalTitle>
        {t('product.requestDelivery.agreement.iraDistributionDisclosure.title')}
      </ModalTitle>
      <Paragraph marginBottom={24}>
        {t(
          'product.requestDelivery.agreement.iraDistributionDisclosure.textOne',
        )}
      </Paragraph>
      <Paragraph marginBottom={24}>
        {t(
          'product.requestDelivery.agreement.iraDistributionDisclosure.textTwo',
        )}
      </Paragraph>
      <Paragraph marginBottom={24}>
        {t(
          'product.requestDelivery.agreement.iraDistributionDisclosure.textThree',
        )}
      </Paragraph>
      <Paragraph bold>
        {t(
          'product.requestDelivery.agreement.iraDistributionDisclosure.textFour',
        )}
      </Paragraph>
    </>
  );
};

export default IraDeliveryAgreement;
