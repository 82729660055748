import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TwoFaPhoneAddPhone from '../../../pages/TwoFA/Components/TwoFaPhoneAddPhone';
import Status from '../../Modals/Status';
import Modal from '../../Modal/Modal';
import StepByStep from '../../StepByStep';
// import { ADD_ADDRESS_WIZARD } from '../../../constants/sessionStorage';

const steps = [TwoFaPhoneAddPhone, Status];

const TwoFaPhoneAddPhoneModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');

  return (
    <Modal
      title="Add new Phone Number"
      size="sm"
      isOpen={isOpen}
      close={onClose}
    >
      <StepByStep saveStepDisabled>
        {({ goStepForward, goStepBack }) =>
          steps.map((step) =>
            React.createElement(step, {
              handleBack: goStepBack,
              handleNext: goStepForward,
              handleClose: onClose,
              onButtonClick: onClose,
              hasError: !!error,
              text:
                error ||
                t('twoFa.loginSetup.phone.phoneList.addNewPhoneNumberSuccess'),
              backButtonText: t('common.ok'),
              setError,
            }),
          )
        }
      </StepByStep>
    </Modal>
  );
};

TwoFaPhoneAddPhoneModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default TwoFaPhoneAddPhoneModal;
