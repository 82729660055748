import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { clearChartError } from '../../../store/actions/portfolio/portfolioActions';
import { uButtonClear, uOutlineNone } from '../../../assets/styles/utility';
import themeColors from '../../../assets/styles/themeColors';
import { pxToRem } from '../../../assets/styles/helper';
import { variables } from '../../../assets/styles/variables';

const getPortfolioTypeButtonActiveStyles = (isSelected) => {
  if (isSelected) {
    return `
      opacity: 1;
      color: ${themeColors.colorPillsTextActive};
      background-color: ${themeColors.colorPillsBackgroundActive};`;
  }
};

export const PortfolioTypeButton = styled.button`
  ${uButtonClear};
  ${uOutlineNone};
  color: ${themeColors.colorPillsText};
  background-color: ${themeColors.colorPillsBackground};
  font-weight: 600;
  font-size: ${pxToRem(16)};
  line-height: 1.25;
  padding: ${pxToRem(6)} ${pxToRem(8)};
  border-radius: ${variables.borderRadius.borderRadiusMd};
  cursor: pointer;
  ${({ isSelected }) => getPortfolioTypeButtonActiveStyles(isSelected)}

  &:not(:last-child) {
    margin-right: ${pxToRem(16)};
  }

  &:disabled {
    opacity: 0.5;
  }
`;

const PortfolioType = ({
  type,
  selectedType,
  isLoading,
  setSelectedType,
  dataCy,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <PortfolioTypeButton
      key={type}
      onClick={() => {
        dispatch(clearChartError());
        setSelectedType(type);
      }}
      isSelected={type === selectedType}
      disabled={isLoading}
      data-cy={dataCy}
    >
      {t(`portfolio.metalTypes.${type}`)}
    </PortfolioTypeButton>
  );
};

PortfolioType.propTypes = {
  type: PropTypes.string,
  selectedType: PropTypes.string,
  isLoading: PropTypes.bool,
  setSelectedType: PropTypes.func,
  dataCy: PropTypes.string,
};

export default PortfolioType;
