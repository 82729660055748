import {
  createClearType,
  createDeleteType,
  createErrorType,
  createFetchType,
  createLoadingType,
  createSubmitType,
  createSuccessType,
  createUpdateType,
} from '../actionHelpers';

const ACCOUNT_SCOPE = 'ACCOUNT';
export const GET_ACCOUNT = createFetchType(ACCOUNT_SCOPE);

export const SET_CURRENT_ACCOUNT = 'SET_CURRENT_ACCOUNT';

// usernames for account
export const FETCH_CURRENT_ACCOUNT_USERNAMES = createFetchType(
  'CURRENT_ACCOUNT_USERNAMES',
);
export const FETCH_CURRENT_ACCOUNT_USERNAMES_SUCCESS = createSuccessType(
  'CURRENT_ACCOUNT_USERNAMES',
);
export const FETCH_CURRENT_ACCOUNT_USERNAMES_ERROR = createErrorType(
  'CURRENT_ACCOUNT_USERNAMES',
);

// users for account
const ACCOUNT_USERS_SCOPE = 'ACCOUNT_USERS';
export const FETCH_ACCOUNT_USERS = createFetchType(ACCOUNT_USERS_SCOPE);
export const FETCH_ACCOUNT_USERS_SUCCESS = createSuccessType(
  ACCOUNT_USERS_SCOPE,
);
export const FETCH_ACCOUNT_USERS_ERROR = createErrorType(ACCOUNT_USERS_SCOPE);
export const FETCH_ACCOUNT_USERS_LOADING = createLoadingType(
  ACCOUNT_USERS_SCOPE,
);

// account addresses
const ACCOUNT_ADDRESSES_SCOPE = 'ACCOUNT_ADDRESSES';

export const FETCH_ACCOUNT_ADDRESSES = createFetchType(ACCOUNT_ADDRESSES_SCOPE);
export const FETCH_ACCOUNT_ADDRESSES_SUCCESS = createSuccessType(
  ACCOUNT_ADDRESSES_SCOPE,
);
export const FETCH_ACCOUNT_ADDRESSES_ERROR = createErrorType(
  ACCOUNT_ADDRESSES_SCOPE,
);
export const FETCH_ACCOUNT_ADDRESSES_LOADING = createLoadingType(
  ACCOUNT_ADDRESSES_SCOPE,
);

// Update Account Address
const ACCOUNT_ADDRESS_UPDATE_SCOPE = 'ACCOUNT_ADDRESSES_UPDATE';

export const ACCOUNT_ADDRESS_UPDATE = createUpdateType(
  ACCOUNT_ADDRESS_UPDATE_SCOPE,
);

export const ACCOUNT_ADDRESS_UPDATE_SUCCESS = createSuccessType(
  ACCOUNT_ADDRESS_UPDATE_SCOPE,
);

export const ACCOUNT_ADDRESS_UPDATE_ERROR = createErrorType(
  ACCOUNT_ADDRESS_UPDATE_SCOPE,
);

export const ACCOUNT_ADDRESS_UPDATE_LOADING = createLoadingType(
  ACCOUNT_ADDRESS_UPDATE_SCOPE,
);

export const CLEAR_ACCOUNT_ADDRESS_UPDATE_STATE = createClearType(
  'ACCOUNT_ADDRESSES_UPDATE_STATE',
);

// Delete Account Address
const ACCOUNT_ADDRESS_DELETE_SCOPE = 'ACCOUNT_ADDRESSES_DELETE';

export const ACCOUNT_ADDRESS_DELETE = createDeleteType(
  ACCOUNT_ADDRESS_DELETE_SCOPE,
);

export const ACCOUNT_ADDRESS_DELETE_SUCCESS = createSuccessType(
  ACCOUNT_ADDRESS_DELETE_SCOPE,
);

export const ACCOUNT_ADDRESS_DELETE_ERROR = createErrorType(
  ACCOUNT_ADDRESS_DELETE_SCOPE,
);

export const ACCOUNT_ADDRESS_DELETE_LOADING = createLoadingType(
  ACCOUNT_ADDRESS_DELETE_SCOPE,
);

export const CLEAR_ACCOUNT_ADDRESS_DELETE_STATE = createClearType(
  'ACCOUNT_ADDRESSES_DELETE_STATE',
);

// Add new account address
const SUBMIT_ADDRESS_SCOPE = 'SUBMIT_ADDRESS';
export const SUBMIT_ADDRESS = createSubmitType(SUBMIT_ADDRESS_SCOPE);
export const SUBMIT_ADDRESS_SUCCESS = createSuccessType(SUBMIT_ADDRESS_SCOPE);
export const SUBMIT_ADDRESS_ERROR = createErrorType(SUBMIT_ADDRESS_SCOPE);
export const SUBMIT_ADDRESS_LOADING = createLoadingType(SUBMIT_ADDRESS_SCOPE);

// user permissions
const USER_PERMISSIONS_SCOPE = 'USER_PERMISSIONS';
export const USER_PERMISSIONS_FETCH = createFetchType(USER_PERMISSIONS_SCOPE);
export const USER_PERMISSIONS_SUCCESS = createSuccessType(
  USER_PERMISSIONS_SCOPE,
);
export const USER_PERMISSIONS_ERROR = createErrorType(USER_PERMISSIONS_SCOPE);
export const USER_PERMISSIONS_LOADING = createLoadingType(
  USER_PERMISSIONS_SCOPE,
);

const ACCOUNT_SETTINGS_SCOPE = 'ACCOUNT_SETTINGS';
export const ACCOUNT_SETTINGS_FETCH = createFetchType(ACCOUNT_SETTINGS_SCOPE);
export const ACCOUNT_SETTINGS_SUCCESS = createSuccessType(
  ACCOUNT_SETTINGS_SCOPE,
);
export const ACCOUNT_SETTINGS_ERROR = createErrorType(ACCOUNT_SETTINGS_SCOPE);
export const ACCOUNT_SETTINGS_LOADING = createLoadingType(
  ACCOUNT_SETTINGS_SCOPE,
);

// submit new user
const SUBMIT_NEW_ACCOUNT_USER_SCOPE = 'SUBMIT_NEW_ACCOUNT_USER';
export const SUBMIT_NEW_ACCOUNT_USER = createSubmitType(
  SUBMIT_NEW_ACCOUNT_USER_SCOPE,
);
export const SUBMIT_NEW_ACCOUNT_USER_ERROR = createErrorType(
  SUBMIT_NEW_ACCOUNT_USER_SCOPE,
);
export const SUBMIT_NEW_ACCOUNT_USER_SUCCESS = createSuccessType(
  SUBMIT_NEW_ACCOUNT_USER_SCOPE,
);

// update user
const UPDATE_ACCOUNT_USER_SCOPE = 'UPDATE_ACCOUNT_USER';
export const UPDATE_ACCOUNT_USER = createUpdateType(UPDATE_ACCOUNT_USER_SCOPE);
export const UPDATE_ACCOUNT_USER_ERROR = createErrorType(
  UPDATE_ACCOUNT_USER_SCOPE,
);
export const UPDATE_ACCOUNT_USER_SUCCESS = createSuccessType(
  UPDATE_ACCOUNT_USER_SCOPE,
);

export const UPDATE_ACCOUNT_USER_LOADING = createLoadingType(
  UPDATE_ACCOUNT_USER_SCOPE,
);

// Account settings registration
const ACCOUNT_SETTINGS_REGISTRATION_SCOPE = 'ACCOUNT_SETTINGS_REGISTRATION';
export const ACCOUNT_SETTINGS_REGISTRATION_FETCH = createFetchType(
  ACCOUNT_SETTINGS_REGISTRATION_SCOPE,
);
export const ACCOUNT_SETTINGS_REGISTRATION_SUCCESS = createSuccessType(
  ACCOUNT_SETTINGS_REGISTRATION_SCOPE,
);
export const ACCOUNT_SETTINGS_REGISTRATION_ERROR = createSuccessType(
  ACCOUNT_SETTINGS_REGISTRATION_SCOPE,
);
export const ACCOUNT_SETTINGS_REGISTRATION_LOADING = createLoadingType(
  ACCOUNT_SETTINGS_REGISTRATION_SCOPE,
);

// submit account agreement
const SUBMIT_AGREEMENT_SCOPE = 'SUBMIT_AGREEMENT';
export const SUBMIT_AGREEMENT = createSubmitType(SUBMIT_AGREEMENT_SCOPE);
export const SUBMIT_AGREEMENT_ERROR = createErrorType(SUBMIT_AGREEMENT_SCOPE);
export const SUBMIT_AGREEMENT_SUCCESS = createSuccessType(
  SUBMIT_AGREEMENT_SCOPE,
);

// account nag dashboard
const ACCOUNT_NAG_DASHBOARD_SCOPE = 'ACCOUNT_NAG_DASHBOARD';
export const ACCOUNT_NAG_DASHBOARD = createFetchType(
  ACCOUNT_NAG_DASHBOARD_SCOPE,
);
export const ACCOUNT_NAG_DASHBOARD_SUCCESS = createSuccessType(
  ACCOUNT_NAG_DASHBOARD_SCOPE,
);
export const ACCOUNT_NAG_DASHBOARD_ERROR = createErrorType(
  ACCOUNT_NAG_DASHBOARD_SCOPE,
);
export const ACCOUNT_NAG_DASHBOARD_LOADING = createLoadingType(
  ACCOUNT_NAG_DASHBOARD_SCOPE,
);

// account dismissed nag dashboard
const ACCOUNT_DISMISSED_NAG_DASHBOARD_SCOPE = 'DISMISSED_NAG_DASHBOARD';
export const DISMISSED_NAG_DASHBOARD = createSubmitType(
  ACCOUNT_DISMISSED_NAG_DASHBOARD_SCOPE,
);
export const DISMISSED_NAG_DASHBOARD_ERROR = createErrorType(
  ACCOUNT_DISMISSED_NAG_DASHBOARD_SCOPE,
);
export const DISMISSED_NAG_DASHBOARD_SUCCESS = createSuccessType(
  ACCOUNT_DISMISSED_NAG_DASHBOARD_SCOPE,
);
