import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from '../../../Modal/Modal';
import ModalBody from '../../../Modal/ModalBody';
import ModalTitle from '../../../Modal/ModalTitle';
import Paragraph from '../../../Paragraph/Paragraph';
import ModalButtons from '../../../Modal/ModalButtons';
import AgreementCheckbox from '../../../InputFields/AgreementCheckbox';

const IraWithdrawDistributionDisclosure = ({
  overModal,
  goStepForward,
  goStepBack,
  close,
}) => {
  const { t } = useTranslation();
  const [areTermsChecked, setAreTermsChecked] = useState(false);

  const handleSubmit = () => {
    goStepForward();
  };

  const onTermsClick = () => {
    setAreTermsChecked(!areTermsChecked);
  };

  return (
    <Modal
      overModal={overModal}
      size="lg"
      isOpen
      close={close}
      title={t('IRAWithdraw.iraDistribution')}
    >
      <ModalBody>
        <ModalTitle>{t('entrust.disclosure.title')}</ModalTitle>
        <Paragraph marginBottom={24}>
          {t('entrust.disclosure.textOne')}
        </Paragraph>
        <Paragraph marginBottom={24}>
          {t('entrust.disclosure.textTwo')}
        </Paragraph>
        <Paragraph marginBottom={24}>
          {t('entrust.disclosure.textThree')}
        </Paragraph>

        <AgreementCheckbox
          checked={areTermsChecked}
          onChange={onTermsClick}
          name="check"
          text={t('entrust.disclosure.checkbox')}
        />
        <ModalButtons
          smallButtons
          isHorizontal
          marginTop
          primaryButtonProps={{
            onClick: handleSubmit,
            disabled: !areTermsChecked,
          }}
          secondaryButtonProps={{ onClick: goStepBack }}
        />
      </ModalBody>
    </Modal>
  );
};

IraWithdrawDistributionDisclosure.propTypes = {
  goStepBack: PropTypes.func,
  goStepForward: PropTypes.func,
  overModal: PropTypes.bool,
  close: PropTypes.func,
};

export default IraWithdrawDistributionDisclosure;
