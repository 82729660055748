import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import BulletItem from '../BulletItem/BulletItem';
import Button from '../Button/Button';
import { setWizardContent } from '../../util/helpers/wizardHelpers';
import {
  METAL_STREAM_EDITING,
  METAL_STREAM_SETTINGS,
  PREVENT_SUPPORT_MODAL,
} from '../../constants/sessionStorage';
import { storeInSessionStorage } from '../../util/helpers/sessionStorageHelper';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import themeColors from '../../assets/styles/themeColors';
import config from '../../config';

export const SetupMetalStreamContainer = styled.div`
  padding: ${pxToRem(32)} 0;
  max-width: ${pxToRem(971)};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: ${pxToRem(50)} ${pxToRem(24)};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(50)} ${pxToRemMd(24)};
  }
`;

export const SetupMetalStreamTitle = styled.h2`
  font-size: ${pxToRem(24)};
  line-height: 1.33;
  margin-bottom: ${pxToRem(32)};
  font-weight: bold;

  > span {
    color: ${themeColors.colorTextSecondary};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    text-align: center;
    margin-bottom: ${pxToRem(40)};
  }
`;

export const SetupMetalStreamParagraph = styled.p`
  margin-bottom: ${pxToRem(24)};
  font-size: ${pxToRem(18)};
  line-height: 1.33;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-bottom: ${pxToRemMd(24)};
    font-size: ${pxToRemMd(18)};
  }
`;

export const SetupMetalStreamList = styled.ul`
  margin: 0 0 ${pxToRem(24)};
  display: grid;
  grid-row-gap: ${pxToRem(2)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin: 0 0 ${pxToRemMd(48)};
    grid-row-gap: ${pxToRemMd(16)};
  }
`;

export const SetupMetalStreamButton = styled(Button)`
  margin-top: ${pxToRem(48)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    display: none;
  }
`;

const SetupMetalStream = ({
  handleOpenSetup,
  allowAccountChangesPermission,
  isTestAccount,
}) => {
  const { t } = useTranslation();
  const { metalStreamCaption } = config;

  return (
    <SetupMetalStreamContainer>
      <SetupMetalStreamTitle>
        {t('metalStream.status')}
        <span>{t('metalStream.setupRequired')}</span>
      </SetupMetalStreamTitle>
      <SetupMetalStreamParagraph>
        {t('metalStream.automaticInvestmentDescription', {
          metalStream: metalStreamCaption,
        })}
      </SetupMetalStreamParagraph>

      <SetupMetalStreamParagraph>
        {t('metalStream.noExtraCharge', {
          metalStream: metalStreamCaption,
        })}
      </SetupMetalStreamParagraph>

      <SetupMetalStreamParagraph>
        {t('metalStream.toGetStarted')}
      </SetupMetalStreamParagraph>
      <SetupMetalStreamList>
        <BulletItem text="How much you want to invest each month;" />
        <BulletItem text={t('metalStream.whichProductsToBuy')} />
        <BulletItem text={t('metalStream.howToFund')} />
      </SetupMetalStreamList>
      <SetupMetalStreamParagraph>
        {t('metalStream.changeSettings', {
          metalStream: metalStreamCaption,
        })}
      </SetupMetalStreamParagraph>
      <SetupMetalStreamButton
        size="lg"
        variant="primary"
        disabled={!allowAccountChangesPermission || isTestAccount}
        onClick={() => {
          handleOpenSetup();
          storeInSessionStorage(PREVENT_SUPPORT_MODAL, true);
          setWizardContent(METAL_STREAM_SETTINGS, {});
          setWizardContent(METAL_STREAM_EDITING, false);
        }}
      >
        {t('metalStream.setup', {
          metalStream: metalStreamCaption,
        })}
      </SetupMetalStreamButton>
    </SetupMetalStreamContainer>
  );
};

SetupMetalStream.propTypes = {
  handleOpenSetup: PropTypes.func,
  allowAccountChangesPermission: PropTypes.bool,
  isTestAccount: PropTypes.bool,
};

export default SetupMetalStream;
