import createReducer from '../../utils/createReducer';
import {
  ACCOUNT_SETTINGS_ERROR,
  ACCOUNT_SETTINGS_SUCCESS,
} from '../../actions/account/accountActionConstants';

const initialState = {
  CheckDepositInstructions: {},
  WireDepositInstructions: {},
  AccountRestrictionFlags: [],
};

export default createReducer(
  {
    [ACCOUNT_SETTINGS_SUCCESS]: setAccountSettings,
    [ACCOUNT_SETTINGS_ERROR]: setAccountSettingsError,
  },
  initialState,
);

function setAccountSettings(state, action) {
  return {
    ...state,
    ...action.payload,
  };
}

function setAccountSettingsError(state, action) {
  return {
    ...state,
    ...action.payload,
  };
}
