import createReducer from '../../utils/createReducer';
import {
  METAL_STREAM_BANK_ACCOUNTS_ERROR,
  METAL_STREAM_BANK_ACCOUNTS_SUCCESS,
  METAL_STREAM_FINISH_SETUP_ERROR,
  METAL_STREAM_FINISH_SETUP_SUCCESS,
  METAL_STREAM_PRODUCT_ERROR,
  METAL_STREAM_PRODUCT_SUCCESS,
  MS_SETTINGS_ERROR,
  MS_SETTINGS_SUCCESS,
  MS_FUNDING_REGISTRATION_SUCCESS,
  MS_FUNDING_REGISTRATION_ERROR,
  MS_PRODUCT_REGISTRATION_SUCCESS,
  MS_PRODUCT_REGISTRATION_ERROR,
  MS_AUTO_INVEST,
} from '../../actions/metalStream/metalStreamActionConstants';

const initialState = {
  metalStreamSettings: {},
  error: '',
  products: {
    gold: [],
    silver: [],
    errorMessage: '',
  },
  bankAccounts: [],
  bankAccountsError: '',
  metalStreamSetupError: '',
  registration: {
    funding: {},
    error: '',
    products: {
      gold: [],
      silver: [],
      errorMessage: '',
    },
    autoInvest: false,
  },
};

export default createReducer(
  {
    [MS_SETTINGS_SUCCESS]: setMsSettings,
    [MS_SETTINGS_ERROR]: setMetalStreamError,
    [METAL_STREAM_PRODUCT_SUCCESS]: setMetalStreamProduct,
    [METAL_STREAM_PRODUCT_ERROR]: setMetalStreamProductError,
    [METAL_STREAM_BANK_ACCOUNTS_SUCCESS]: setMetalStreamBankAccounts,
    [METAL_STREAM_BANK_ACCOUNTS_ERROR]: setMetalStreamBankAccountsError,
    [METAL_STREAM_FINISH_SETUP_ERROR]: setMetalStreamSetupError,
    [METAL_STREAM_FINISH_SETUP_SUCCESS]: setMetalStreamSetup,
    [MS_FUNDING_REGISTRATION_SUCCESS]: setMsFundingsRegistration,
    [MS_FUNDING_REGISTRATION_ERROR]: setMsFundingsRegistrationError,
    [MS_PRODUCT_REGISTRATION_SUCCESS]: setMetalStreamProductRegistration,
    [MS_PRODUCT_REGISTRATION_ERROR]: setMetalStreamProductRegistrationError,
    [MS_AUTO_INVEST]: setAutoInvest,
  },
  initialState,
);

function setMsSettings(state, { payload }) {
  return {
    ...state,
    metalStreamSettings: payload,
  };
}

function setMetalStreamError(state, { payload }) {
  return {
    ...state,
    error: payload,
  };
}

function setMetalStreamProduct(state, { payload }) {
  const { type, data } = payload;
  return {
    ...state,
    products: {
      ...state.products,
      [type]: data,
    },
  };
}

function setMetalStreamProductError(state, { payload }) {
  return {
    ...state,
    products: {
      ...state.products,
      errorMessage: payload,
    },
  };
}

function setMetalStreamBankAccounts(state, { payload }) {
  return {
    ...state,
    bankAccounts: payload,
  };
}

function setMetalStreamBankAccountsError(state, { payload }) {
  return {
    ...state,
    bankAccountsError: payload,
  };
}

function setMetalStreamSetupError(state, { payload }) {
  return {
    ...state,
    metalStreamSetupError: payload,
  };
}

function setMetalStreamSetup(state, { payload }) {
  return {
    ...state,
    metalStreamSettings: payload,
  };
}

function setMsFundingsRegistration(state, { payload }) {
  return {
    ...state,
    registration: {
      ...state.registration,
      funding: payload,
    },
  };
}
function setMsFundingsRegistrationError(state, { payload }) {
  return {
    ...state,
    registration: {
      ...state.registration,
      error: payload,
    },
  };
}
function setMetalStreamProductRegistration(state, { payload }) {
  const { type, data } = payload;
  return {
    ...state,
    registration: {
      ...state.registration,
      products: {
        ...state.registration.products,
        [type]: data,
      },
    },
  };
}
function setMetalStreamProductRegistrationError(state, { payload }) {
  return {
    ...state,
    registration: {
      ...state.registration,
      products: {
        ...state.products,
        errorMessage: payload,
      },
    },
  };
}
function setAutoInvest(state, { payload }) {
  return {
    ...state,
    registration: {
      ...state.registration,
      autoInvest: payload,
    },
  };
}
