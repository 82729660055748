import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { ReactComponent as Upload } from '../../../../assets/images/svg/upload.svg';
import { ReactComponent as IncomingIcon } from '../../../../assets/images/svg/incoming.svg';
import { ReactComponent as OutgoingIcon } from '../../../../assets/images/svg/outgoing.svg';
import { uFlexCenter } from '../../../../assets/styles/utility';
import {
  mediaBelow,
  pxToRem,
  pxToRemMd,
} from '../../../../assets/styles/helper';
import { variables } from '../../../../assets/styles/variables';

export const TransactionImageWrap = styled.div`
  ${uFlexCenter};
  box-shadow: ${variables.shadow.boxShadow};
  border-radius: ${variables.borderRadius.borderRadius};

  &,
  > img {
    width: ${pxToRem(88)};
    height: ${pxToRem(88)};
  }

  > img {
    object-fit: contain;
  }

  > svg {
    width: ${pxToRem(40)};
    height: ${pxToRem(40)};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    &,
    > img {
      width: ${pxToRemMd(88)};
      height: ${pxToRemMd(88)};
    }

    > svg {
      width: ${pxToRemMd(40)};
      height: ${pxToRemMd(40)};
    }
  }
`;

export const TransactionImageContainer = styled.div`
  ${uFlexCenter};
  margin-top: ${pxToRem(24)};
  margin-bottom: ${pxToRem(24)};
  ${({ transactionType }) =>
    transactionType &&
    `
    ${TransactionImageWrap} {
      ${uFlexCenter};
      box-shadow: none;
      border-radius: none;

      > img,
      > svg {
        width: ${pxToRem(162)};
        height: auto;
      }
    }
  `};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-top: ${pxToRemMd(24)};
    margin-bottom: ${pxToRemMd(24)};
  }
`;

const ImageField = ({ url, transactionType }) => {
  const renderIcon = () => {
    switch (transactionType) {
      case 'PositionIn':
      case 'CheckDeposit':
      case 'WireDeposit':
      case 'AchDeposit':
      case 'AchDepositRejection':
      case 'CheckDepositRejection':
      case 'GenericDeposit':
      case 'IraDeposit':
      case 'IraFeeDeposit':
      case 'InKindTransferOut':
      case 'AipBuy':
      case 'StorageFeePayment':
        return <IncomingIcon />;

      case 'IraFeeDepositRejection':
      case 'PositionOut':
      case 'StorageFee':
      case 'WireWithdrawalRejection':
      case 'WireWithdrawalFeeRejection':
      case 'CheckWithdrawal':
      case 'WireWithdrawal':
      case 'WireWithdrawalFee':
      case 'GenericWithdrawal':
      case 'InKindTransferIn':
      case 'IraFeeWithdrawal':
      case 'IraDistribution':
      case 'IraTransfer':
      case 'AipSell':
      case 'CreditCardChagreback':
        return <OutgoingIcon />;

      default:
        return <Upload />;
    }
  };

  return (
    <TransactionImageContainer transactionType={transactionType}>
      <TransactionImageWrap>
        {url ? <img src={url} alt="Product" /> : renderIcon()}
      </TransactionImageWrap>
    </TransactionImageContainer>
  );
};

ImageField.propTypes = {
  url: PropTypes.string,
  transactionType: PropTypes.string,
};

export default ImageField;
