import i18next from 'i18next';
import { isEmpty } from 'lodash';
import {
  formatPhoneNumberIntl,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input';
import getCountryISO3 from 'country-iso-2-to-3';

export const formatLocalNumber = (localNumber) =>
  `${localNumber.substring(0, 3)}-${localNumber.substring(3)}`;

export const formatPhoneNumber = (
  phoneNumber,
  countries,
  selectedISOCodesListToIgnoreAreaCode,
) => {
  if (!isEmpty(phoneNumber)) {
    const { Iso3CountryCode, AreaCode, LocalNumber } = phoneNumber;
    const country = countries?.find(
      (item) => item.Iso3Code === Iso3CountryCode,
    );

    if (
      shouldIgnoreAreaCodeHelperViaIso3(
        Iso3CountryCode,
        selectedISOCodesListToIgnoreAreaCode,
      )
    ) {
      const telephoneCode = `${country?.TelephoneCode}`.substring(0, 1);
      const areaCode = `${country?.TelephoneCode}`.substring(1, 4);

      return `+${telephoneCode || '#'} (${areaCode || '#'}) ${formatLocalNumber(
        LocalNumber,
      )}`;
    }

    return `+${country?.TelephoneCode || '#'} (${
      AreaCode || '#'
    }) ${formatLocalNumber(LocalNumber)}`;
  }

  return '';
};

export const isPhoneSelected = (values, phoneNumber) => {
  if (!isEmpty(values) && values?.PhoneNumber) {
    const parsedPhoneNumberValue = JSON.parse(values.PhoneNumber);
    const parsedPhoneNumber = JSON.parse(phoneNumber);

    if (
      parsedPhoneNumberValue.PhoneNumberUid === parsedPhoneNumber.PhoneNumberUid
    ) {
      return true;
    }
  }

  return false;
};

export const selectedPhoneNumberValidatorAndFormatter = (
  phoneNumber,
  setFieldError,
  countries,
  selectedISOCodesListToIgnoreAreaCode,
) => {
  if (isEmpty(phoneNumber)) {
    return false;
  }

  const {
    Iso3CountryCode,
    AreaCode,
    LocalNumber,
    PhoneNumberUid,
  } = phoneNumber;
  const country = countries?.find((item) => item.Iso3Code === Iso3CountryCode);
  const number = `+${country?.TelephoneCode}${AreaCode || ''}${LocalNumber}`;

  if (!isPossiblePhoneNumber(number) || !isValidPhoneNumber(number)) {
    setFieldError(
      'PhoneNumber',
      i18next.t('validationSchema.general.phoneNumberNotValid'),
    );
    return;
  }

  return {
    Iso3CountryCode,
    AreaCode: shouldIgnoreAreaCodeHelperViaIso3(
      Iso3CountryCode,
      selectedISOCodesListToIgnoreAreaCode,
    )
      ? ''
      : AreaCode,
    LocalNumber,
    PhoneNumberUid,
  };
};

export const enteredPhoneNumberValidatorAndFormatter = (
  phoneNumber,
  setFieldError,
  selectedISOCodesListToIgnoreAreaCode,
) => {
  const parsedNumber = parsePhoneNumber(`+${phoneNumber}`);
  const formatPhoneNumber = formatPhoneNumberIntl(`+${phoneNumber}`);
  const splitPhoneNumber = formatPhoneNumber.split(' ');

  if (!parsedNumber.country) {
    setFieldError(
      'PhoneNumber',
      i18next.t('validationSchema.general.phoneNumberNotValid'),
    );
    return;
  }

  const iso3CountryCode = parsedNumber && getCountryISO3(parsedNumber.country);
  const areaCode = splitPhoneNumber[1];
  const localNumber = splitPhoneNumber
    .splice(2, splitPhoneNumber.length)
    .join()
    .replaceAll(',', '');

  return {
    Iso3CountryCode: iso3CountryCode,
    AreaCode: shouldIgnoreAreaCodeHelperViaIso3(
      iso3CountryCode,
      selectedISOCodesListToIgnoreAreaCode,
    )
      ? ''
      : areaCode,
    LocalNumber: localNumber,
  };
};

export const phoneNumberPostObject = (phoneNumber) => {
  if (phoneNumber) {
    const { Iso3CountryCode, AreaCode, LocalNumber } = phoneNumber;

    return {
      Iso3CountryCode,
      AreaCode,
      LocalNumber,
    };
  }

  return {};
};

export const shouldIgnoreAreaCodeHelper = (country, countries) => {
  if (!country || !countries) {
    return false;
  }

  const { iso2 } = country;

  const iso3 = getCountryISO3(iso2.toUpperCase());

  if (iso3 && countries.includes(iso3)) {
    return true;
  }

  return false;
};

export const shouldIgnoreAreaCodeHelperViaIso3 = (iso3, countries) => {
  if (!iso3 || !countries) {
    return false;
  }

  if (countries.includes(iso3)) {
    return true;
  }

  return false;
};

export const phoneNumberToObjectHelper = (
  phoneNumber,
  setFieldError,
  selectedISOCodesListToIgnoreAreaCode,
) => {
  if (!phoneNumber) {
    return {
      Iso3CountryCode: null,
      AreaCode: null,
      LocalNumber: null,
      StateCode: null,
    };
  }
  const parsedNumber = parsePhoneNumber(phoneNumber);
  const formatPhoneNumber = formatPhoneNumberIntl(phoneNumber);
  const splitPhoneNumber = formatPhoneNumber.split(' ');
  if (!parsedNumber.country) {
    setFieldError(
      'PhoneNumber',
      i18next.t('validationSchema.general.phoneNumberNotValid'),
    );
  }

  const iso3CountryCode = parsedNumber && getCountryISO3(parsedNumber.country);
  const countryCode = parsedNumber.countryCallingCode;
  const areaCode = splitPhoneNumber[1];
  const localNumber = splitPhoneNumber
    .splice(2, splitPhoneNumber.length)
    .join()
    .replaceAll(',', '');

  return {
    Iso3CountryCode: iso3CountryCode,
    AreaCode: shouldIgnoreAreaCodeHelperViaIso3(
      iso3CountryCode,
      selectedISOCodesListToIgnoreAreaCode,
    )
      ? ''
      : areaCode,
    LocalNumber: localNumber,
    StateCode: countryCode,
  };
};

export const validatePhoneNumber = (fieldValue) => {
  if (!(isPossiblePhoneNumber(fieldValue) && isValidPhoneNumber(fieldValue))) {
    return true;
  }

  return false;
};

export const formatPhoneNumberAsString = (
  phoneNumber,
  countries,
  selectedISOCodesListToIgnoreAreaCode,
) => {
  if (!isEmpty(phoneNumber)) {
    const { Iso3CountryCode, AreaCode, LocalNumber } = phoneNumber;
    const country = countries?.find(
      (item) => item.Iso3Code === Iso3CountryCode,
    );

    if (
      shouldIgnoreAreaCodeHelperViaIso3(
        Iso3CountryCode,
        selectedISOCodesListToIgnoreAreaCode,
      )
    ) {
      const telephoneCode = `${country?.TelephoneCode}`.substring(0, 1);
      const areaCode = `${country?.TelephoneCode}`.substring(1, 4);
      return `+${telephoneCode}${areaCode}${LocalNumber}`;
    }
    return `+${country?.TelephoneCode}${AreaCode}${LocalNumber}`;
  }

  return '';
};

export const formatPhoneNumberForView = (
  phoneNumber,
  countries,
  selectedISOCodesListToIgnoreAreaCode,
) => {
  if (!isEmpty(phoneNumber)) {
    const { Iso3CountryCode, AreaCode, LocalNumber } = phoneNumber;
    const country = countries?.find(
      (item) => item.Iso3Code === Iso3CountryCode,
    );

    if (
      shouldIgnoreAreaCodeHelperViaIso3(
        Iso3CountryCode,
        selectedISOCodesListToIgnoreAreaCode,
      )
    ) {
      const telephoneCode = `${country?.TelephoneCode}`.substring(0, 1);
      const areaCode = `${country?.TelephoneCode}`.substring(1, 4);

      return `+${telephoneCode || '#'} (${areaCode || '#'}) ${formatLocalNumber(
        LocalNumber,
      )}`;
    }

    return `+${country?.TelephoneCode || '#'} (${
      AreaCode || '#'
    }) ${formatLocalNumber(LocalNumber)}`;
  }

  return '';
};
