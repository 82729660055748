import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import {
  fetchTwoFaTypesRequest,
  fetchTwoFaKeysRequest,
  postTwoFaCodeRequest,
  fetchTwoFaCodeViaPhoneRequest,
  fetchTwoFaCodeViaEmailRequest,
  postTwoFaRequest,
  deleteTwoFaRequest,
  fetchTwoFaRequest,
  fetchTwoFaCustomerSupportRequest,
} from '../../request/twoFARequest';
import {
  fetchTwoFaTypesSuccess,
  fetchTwoFaTypesError,
  fetchTwoFaKeysSuccess,
  fetchTwoFaKeysError,
  postTwoFaCodeSuccess,
  postTwoFaCodeError,
  postTwoFaSuccess,
  postTwoFaError,
  deleteTwoFaSuccess,
  deleteTwoFaError,
  fetchTwoFaSuccess,
  fetchTwoFaError,
  fetchTwoFaCodeViaPhoneSuccess,
  fetchTwoFaCodeViaPhoneError,
  fetchTwoFaCodeViaEmailSuccess,
  fetchTwoFaCodeViaEmailError,
  fetchTwoFaCustomerSupportIsOpenSuccess,
  fetchTwoFaCustomerSupportIsOpenError,
} from '../actions/twoFA/twoFAActions';
import {
  TWO_FA_TYPES,
  TWO_FA_APPLICATION_KEYS,
  TWO_FA_CODE,
  TWO_FA_CODE_VIA_PHONE,
  TWO_FA_CODE_VIA_EMAIL,
  SAVE_TWO_FA,
  DELETE_TWO_FA,
  GET_TWO_FA,
  GET_TWO_FA_CUSTOMER_SUPPORT_IS_OPEN,
} from '../actions/twoFA/twoFAActionConstants';
import { rejectErrorCodeHelper } from '../../util/helpers/rejectErrorCodeHelper';

function* fetchTwoFaTypes() {
  try {
    const { data } = yield call(fetchTwoFaTypesRequest);
    yield put(fetchTwoFaTypesSuccess(data));
    yield put(fetchTwoFaTypesError(''));
  } catch (error) {
    const errMsg = rejectErrorCodeHelper(error);
    yield put(fetchTwoFaTypesError(errMsg));
  }
}

function* fetchTwoFaKeys({ payload }) {
  const { userUid, setDidKeysFetchFail } = payload;
  try {
    const { data } = yield call(fetchTwoFaKeysRequest, userUid);
    yield put(fetchTwoFaKeysSuccess(data));
    yield call(setDidKeysFetchFail, false);
    yield put(fetchTwoFaKeysError(''));
  } catch (error) {
    const errMsg = rejectErrorCodeHelper(error);
    yield call(setDidKeysFetchFail, true);
    yield put(fetchTwoFaKeysError(errMsg));
  }
}

function* fetchTwoFa({ payload }) {
  const { username } = payload;
  try {
    const { data } = yield call(fetchTwoFaRequest, username);
    yield put(fetchTwoFaSuccess(data));
    yield put(fetchTwoFaError(''));

    if (payload.onSuccessfulTwoFaFetch) {
      yield call(payload.onSuccessfulTwoFaFetch, data);
    }
  } catch (error) {
    const errMsg = rejectErrorCodeHelper(error);
    yield put(fetchTwoFaError(errMsg));
  }
}

function* postTwoFaCode({ payload }) {
  const {
    userUid,
    requestData,
    saveFormValuesOnSuccess,
    onSuccess,
    onError,
    onReset,
  } = payload;
  try {
    const { data } = yield call(postTwoFaCodeRequest, userUid, requestData);
    yield put(postTwoFaCodeSuccess(data));
    yield put(postTwoFaCodeError(''));
    if (data) {
      const submitData = { ...requestData, ...data };
      if (saveFormValuesOnSuccess) {
        yield call(saveFormValuesOnSuccess, submitData);
      }
      if (onSuccess) {
        yield call(onSuccess);
      }
      if (onError) {
        yield call(onError, null);
      }
    }
  } catch (error) {
    const errMsg = rejectErrorCodeHelper(error);
    if (onReset) {
      yield call(onReset);
    }
    if (onError) {
      yield call(onError, errMsg);
    }
    yield put(postTwoFaCodeError(errMsg));
  }
}

function* getTwoFaCodeViaPhone({ payload }) {
  const { username, phoneUid, phoneMessageType } = payload;

  try {
    const { data } = yield call(
      fetchTwoFaCodeViaPhoneRequest,
      username,
      phoneUid,
      phoneMessageType,
    );
    yield put(fetchTwoFaCodeViaPhoneSuccess(data));
    yield put(fetchTwoFaCodeViaPhoneError(''));
    if (payload.onSuccess) {
      yield call(payload.onSuccess);
    }
  } catch (error) {
    const errMsg = rejectErrorCodeHelper(error);
    console.log('errMsg', errMsg);
    if (payload.onReset) {
      yield call(payload.onReset);
    }
    if (payload.onError) {
      yield call(payload.onError, errMsg);
    }
    if (payload.onReset) {
      yield call(payload.onReset);
    }
    yield put(fetchTwoFaCodeViaPhoneError(errMsg));
  }
}

function* getTwoFaCodeViaEmail({ payload }) {
  const { username, emailUid, onReset } = payload;
  try {
    const { data } = yield call(
      fetchTwoFaCodeViaEmailRequest,
      username,
      emailUid,
    );
    yield put(fetchTwoFaCodeViaEmailSuccess(data));
    yield put(fetchTwoFaCodeViaEmailError(''));
    if (payload.onReset) {
      yield call(payload.onReset);
    }
  } catch (error) {
    const errMsg = rejectErrorCodeHelper(error);
    if (onReset) {
      yield call(onReset);
    }
    if (payload.onError) {
      yield call(payload.onError, errMsg);
    }
    yield put(fetchTwoFaCodeViaEmailError(errMsg));
  }
}

function* postTwoFa({ payload }) {
  const {
    userUid,
    requestData,
    handleNext,
    setFieldError,
    setIsSuccessful,
    setErrorMessage,
    isInviteUserFlow,
    existingLoginHandleNextClick,
    onReset,
  } = payload;
  try {
    const { data } = yield call(postTwoFaRequest, userUid, requestData);
    if (data && setIsSuccessful) {
      setErrorMessage('');
      setIsSuccessful(true);
    }
    yield put(postTwoFaSuccess(data));
    yield put(postTwoFaError(''));
    if (isInviteUserFlow && existingLoginHandleNextClick) {
      yield call(existingLoginHandleNextClick);
    }
    if (handleNext && !isInviteUserFlow) {
      yield call(handleNext);
    }
  } catch (error) {
    const errMsg = rejectErrorCodeHelper(error);
    yield call(setFieldError, 'SecurityCode', errMsg);
    if (onReset) {
      yield call(onReset);
    }
    if (setErrorMessage) {
      setErrorMessage(errMsg);
      setIsSuccessful(false);
    }

    yield put(postTwoFaError(errMsg));
  }
}

function* deleteTwoFa({ payload }) {
  const { userUid, handleDeleteSuccess, setIsSuccessful } = payload;
  try {
    const { data } = yield call(deleteTwoFaRequest, userUid);
    yield put(deleteTwoFaSuccess(data));
    yield put(deleteTwoFaError(''));
    yield call(setIsSuccessful, true);
    yield call(handleDeleteSuccess);
  } catch (error) {
    const errMsg = rejectErrorCodeHelper(error);
    yield put(deleteTwoFaError(errMsg));
    yield call(setIsSuccessful, false);
  }
}

function* getTwoFaCustomerSupportIsOpen() {
  try {
    const { data } = yield call(fetchTwoFaCustomerSupportRequest);
    yield put(fetchTwoFaCustomerSupportIsOpenSuccess(data));
    yield put(fetchTwoFaCustomerSupportIsOpenError(''));
  } catch (error) {
    const errMsg = rejectErrorCodeHelper(error);
    yield put(fetchTwoFaCustomerSupportIsOpenError(errMsg));
  }
}

export default function* twoFASaga() {
  yield all([
    takeLatest(TWO_FA_TYPES, fetchTwoFaTypes),
    takeLatest(TWO_FA_APPLICATION_KEYS, fetchTwoFaKeys),
    takeLatest(GET_TWO_FA, fetchTwoFa),
    takeLatest(TWO_FA_CODE, postTwoFaCode),
    takeLatest(TWO_FA_CODE_VIA_PHONE, getTwoFaCodeViaPhone),
    takeLatest(TWO_FA_CODE_VIA_EMAIL, getTwoFaCodeViaEmail),
    takeLatest(SAVE_TWO_FA, postTwoFa),
    takeLatest(DELETE_TWO_FA, deleteTwoFa),
    takeLatest(
      GET_TWO_FA_CUSTOMER_SUPPORT_IS_OPEN,
      getTwoFaCustomerSupportIsOpen,
    ),
  ]);
}
