import * as Yup from 'yup';
import { formatMoneyNumeral } from '../util/helpers/numeralHelpers';
import i18next from '../i18n';

export const achDepositValidationSchema = (depositLimit) =>
  Yup.object().shape({
    amount: Yup.number()
      .required(i18next.t('validationSchema.general.amountRequired'))
      .min(
        1,
        i18next.t('validationSchema.amount.minAmount', {
          min: formatMoneyNumeral(1),
        }),
      )
      .max(
        depositLimit,
        i18next.t('validationSchema.amount.maxAmount', {
          max: formatMoneyNumeral(depositLimit),
        }),
      ),
  });
