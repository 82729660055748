import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Button from '../Button/Button';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';

const getModalButtonsLayout = (isHorizontal, isVertical) => {
  if (isHorizontal) {
    return `
      grid-template-columns: 1fr 1fr;
      grid-gap: ${pxToRem(28)};
    `;
  }

  if (isVertical) {
    return `
      grid-template-columns: 1fr;
      grid-gap: ${pxToRem(16)};

      button:nth-child(2) {
        order: -1;
      }
    `;
  }
};

const getModalButtonsMargin = (marginBottom, marginTop) => {
  if (marginTop) {
    return `
        margin-top: ${pxToRem(32)};

      ${mediaBelow(variables.breakpoints.bpMd)} {
        padding-top: ${pxToRemMd(32)};
        margin-top: auto;
      }
    `;
  }

  if (marginBottom) {
    return `
      margin-bottom: ${pxToRem(32)};
  
      ${mediaBelow(variables.breakpoints.bpMd)} {
        margin-bottom: ${pxToRemMd(32)};
      }`;
  }
};

const getModalButtonsStyles = (smallButtons, mediumButtons) => {
  const buttonStyles = `
      display: flex;
      justify-content: space-between;
      ${mediaBelow(variables.breakpoints.bpMd)} {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: ${pxToRem(16)};

        button {
          max-width: 100%;
        }

        button:nth-child(2) {
          order: -1;
        }
      }`;

  if (smallButtons) {
    return `
      button {
        min-width: ${pxToRem(160)};
      }

      ${buttonStyles}`;
  }

  if (mediumButtons) {
    return `
      button {
        min-width: ${pxToRem(240)};
      }
      
      ${buttonStyles}`;
  }
};

export const ModalButtonsContainer = styled.div`
  width: 100%;
  display: grid;
  margin-top: auto;

  ${({ isHorizontal, isVertical }) =>
    getModalButtonsLayout(isHorizontal, isVertical)}
  ${({ marginTop, marginBottom }) =>
    getModalButtonsMargin(marginBottom, marginTop)}
  ${({ smallButtons, mediumButtons }) =>
    getModalButtonsStyles(smallButtons, mediumButtons)}
  
  ${(props) => props.buttonOnRight && `justify-content: flex-end;`};
  ${(props) => props.css};
`;

const ModalButtons = ({
  isHorizontal,
  isVertical,
  primaryButtonProps,
  secondaryButtonProps,
  showOnlyPrimary,
  showOnlySecondary,
  marginTop,
  marginBottom,
  smallButtons,
  mediumButtons,
  buttonOnRight,
  ...restProps
}) => {
  const { t } = useTranslation();

  return (
    <ModalButtonsContainer
      isHorizontal={isHorizontal}
      isVertical={isVertical}
      marginTop={marginTop}
      marginBottom={marginBottom}
      smallButtons={smallButtons}
      mediumButtons={mediumButtons}
      buttonOnRight={buttonOnRight}
      {...restProps}
    >
      {!showOnlyPrimary && (
        <Button size="lg" variant="primaryOutlined" {...secondaryButtonProps}>
          {secondaryButtonProps?.label || t('common.back')}
        </Button>
      )}
      {!showOnlySecondary && (
        <Button size="lg" variant="primary" {...primaryButtonProps}>
          {primaryButtonProps?.label || t('common.next')}
        </Button>
      )}
    </ModalButtonsContainer>
  );
};

ModalButtons.propTypes = {
  isHorizontal: PropTypes.bool,
  isVertical: PropTypes.bool,
  primaryButtonProps: PropTypes.shape({
    label: PropTypes.string,
  }),
  secondaryButtonProps: PropTypes.shape({
    label: PropTypes.string,
  }),
  showOnlyPrimary: PropTypes.bool,
  showOnlySecondary: PropTypes.bool,
  marginTop: PropTypes.bool,
  marginBottom: PropTypes.bool,
  smallButtons: PropTypes.bool,
  mediumButtons: PropTypes.bool,
  buttonOnRight: PropTypes.bool,
};

export default ModalButtons;
