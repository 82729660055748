import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { groupBy } from 'lodash';
import { BUY_PAGE } from '../../../constants/pages';
import useCheckPermissions from '../../../util/hooks/useCheckPermissions';
import { VIEW_TYPE_OPTIONS } from '../../../constants/holdingsConstants';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import {
  productLocationTypes,
  productMetalTypes,
} from '../../../util/enum/api/productTypes';
import HoldingsContainer from './HoldingsContainer';
import SummaryContainer from '../Summary/SummaryContainer';
import { EmptyTable, EmptyTableButton } from '../../EmptyTable/EmptyTable';
import { separateByUppercase } from '../../../util/helpers/stringHelpers';
import {
  selectMailInEnabled,
  selectSettings,
} from '../../../store/selectors/settingsSelectors';
// import { selectMailInEnabled } from '../../../store/selectors/settingsSelectors';
import MailInCta from '../MailInCta/MailInCta';
import { useSessionStorageState } from '../../../util/hooks/useSessionStorageState';
import {
  ADD_MAIL_IN_STORAGE_WIZARD,
  IS_ADD_MAIL_IN_WIZARD_OPEN,
} from '../../../constants/sessionStorage';
import MailInStorageWizard from '../../Modals/MailInStorage/MailInStorageWizard';
import { removeFromSessionStorage } from '../../../util/helpers/sessionStorageHelper';
import {
  accountTypeKeys,
  accountTypes,
} from '../../../util/enum/api/accountTypes';
import { getCurrentAccount } from '../../../store/selectors/accountSelectors';

const HoldingView = ({ holdings, showProducts, viewType, totalValuation }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { allowAccountChangesPermission } = useCheckPermissions();
  const settings = useSelector(selectSettings);
  const segregatedCaption = settings?.SegregatedCaption;
  const account = useSelector(getCurrentAccount);
  const mailInEnabled = useSelector(selectMailInEnabled);
  const isIRAAccount =
    parseEnumType(accountTypeKeys, account.AccountType) === accountTypes.IRA;
  const mailInAllowed = mailInEnabled && !isIRAAccount;

  const [mailInWizardOpen, setMailInWizardOpen] = useSessionStorageState(
    ADD_MAIL_IN_STORAGE_WIZARD,
    false,
  );

  const sortProductsByViewType = () => {
    const { value } = viewType;

    switch (value) {
      case VIEW_TYPE_OPTIONS[0].value:
        return groupBy(
          holdings,
          (holdingItem) =>
            `${separateByUppercase(
              parseEnumType(productLocationTypes, holdingItem.LocationType),
            )} ${holdingItem.IsSegregated ? `(${segregatedCaption})` : ''}`,
        );

      case VIEW_TYPE_OPTIONS[1].value:
        return groupBy(holdings, (holdingItem) =>
          parseEnumType(productMetalTypes, holdingItem.MetalType),
        );

      case VIEW_TYPE_OPTIONS[2].value:
        return groupBy(
          holdings,
          (holdingItem) => holdingItem.ProductDetailCaption,
        );

      default:
        break;
    }
  };

  const sortedHoldings = sortProductsByViewType();

  const isSortByProduct = () => {
    if (viewType.value === VIEW_TYPE_OPTIONS[2].value) {
      return true;
    }

    return false;
  };

  const isSortByType = () => {
    if (viewType.value === VIEW_TYPE_OPTIONS[1].value) {
      return true;
    }

    return false;
  };

  const isSortByVault = () => {
    if (viewType.value === VIEW_TYPE_OPTIONS[0].value) {
      return true;
    }

    return false;
  };

  if (showProducts) {
    return (
      <>
        {showProducts && (
          <HoldingsContainer
            sortedHoldings={sortedHoldings}
            totalValuation={totalValuation}
            isSortByProduct={isSortByProduct()}
            isSortByVault={isSortByVault()}
            isSortByType={isSortByType()}
          />
        )}

        {mailInAllowed && (
          <MailInCta setInMailInStorageModalOpen={setMailInWizardOpen} />
        )}
        {mailInWizardOpen && (
          <MailInStorageWizard
            isOpen={mailInWizardOpen}
            onClose={() => {
              removeFromSessionStorage(IS_ADD_MAIL_IN_WIZARD_OPEN);
              setMailInWizardOpen(false);
            }}
          />
        )}

        {showProducts && (
          <SummaryContainer
            holdings={sortedHoldings}
            totalValuation={totalValuation}
            isSortByProduct={isSortByProduct()}
            isSortByVault={isSortByVault()}
            isSortByType={isSortByType()}
          />
        )}
      </>
    );
  }

  return (
    <>
      <EmptyTable>
        <p>{t('portfolio.holdings.tableEmptyOne')}</p>
        <p>{t('portfolio.holdings.tableEmptyTwo')}</p>

        <EmptyTableButton
          disabled={!allowAccountChangesPermission}
          variant="primary"
          onClick={() => history.push(BUY_PAGE)}
        >
          {t('portfolio.holdings.buyBtnLabel')}
        </EmptyTableButton>
      </EmptyTable>
      {mailInAllowed && (
        <MailInCta setInMailInStorageModalOpen={setMailInWizardOpen} />
      )}
      {mailInWizardOpen && (
        <MailInStorageWizard
          isOpen={mailInWizardOpen}
          onClose={() => {
            removeFromSessionStorage(IS_ADD_MAIL_IN_WIZARD_OPEN);
            setMailInWizardOpen(false);
          }}
        />
      )}
    </>
  );
};

HoldingView.propTypes = {
  showProducts: PropTypes.bool,
  holdings: PropTypes.arrayOf(PropTypes.shape({})),
  viewType: PropTypes.shape({
    value: PropTypes.string,
  }),
  totalValuation: PropTypes.number,
};

export default HoldingView;
