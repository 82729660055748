import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import PropTypes from 'prop-types';

import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import {
  bankAccountApprovalStatus,
  bankAccountPurposeTypes,
  bankAccountSetupTypes,
  bankAccountTypes,
  voidedCheckStatusTypes,
} from '../../util/enum/api/bankAccountTypes';
import { parseEnumType } from '../../util/helpers/enumMappers';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import themeColors from '../../assets/styles/themeColors';
import { uBold, uFlexCenter } from '../../assets/styles/utility';
import Badge from './Badge';
import { ReactComponent as DeleteIcon } from '../../assets/images/svg/delete-icon.svg';
import BankAccountRequirementItem from './BankAccountRequirementItem';
import Paragraph from '../Paragraph/Paragraph';
import IconButtonWithLabel from './IconButtonWithLabel';
import Button from '../Button/Button';
import {
  getBankAccountStatus,
  shouldRenderRequirements,
  getMicroDepositsStatus,
  getVoidedCheckStatus,
  isBankAccountVerified,
} from '../../util/helpers/bankAccountHelper';
import { selectSettings } from '../../store/selectors/settingsSelectors';
import { separateByUppercase } from '../../util/helpers/stringHelpers';
import AnchorOnClick from '../Anchor/AnchorOnClick';
import useCheckPermissions from '../../util/hooks/useCheckPermissions';
import { getCurrentAccount } from '../../store/selectors/accountSelectors';
import { isUserAccountApproved } from '../../constants/nagDashboardContants';
import { FUND_DEPOSIT_START } from '../../constants/gtmEvents';
import useGtmHook from '../../util/hooks/useGtmHook';
import { DEPOSIT_WIZARD_TYPES } from '../../constants/standardDepositWizardConstants';

export const BankAccountCardWrapper = styled.li`
  padding: ${pxToRem(12)};
  display: flex;
  flex-direction: column;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(24)} ${pxToRemMd(16)};
  }
`;

export const BankAccountHardHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

export const BankAccountCardHeaderContent = styled.div`
  ${uBold};
  margin-right: auto;
  padding-right: ${pxToRem(32)};
`;

export const BankAccountCardSubtext = styled.span`
  color: ${themeColors.colorTextSecondary};
  font-size: small;
  margin-bottom: ${pxToRemMd(4)};
`;

export const BankAccountStatusText = styled.span`
  color: ${themeColors.colorTextSecondary};
  padding: 0 ${pxToRem(16)};
  min-height: ${pxToRem(32)};
  min-width: ${pxToRem(120)};
  font-size: ${pxToRem(16)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    flex-grow: 1;
    min-height: ${pxToRemMd(40)};
    padding: ${pxToRemMd(8)} ${pxToRemMd(16)};
    font-size: ${pxToRemMd(18)};
    line-height: 1.33;
  }
`;

export const BankAccountCardEmpty = styled.div`
  ${uFlexCenter};
  flex-grow: 1;
  font-weight: 600;
  color: ${themeColors.colorTextSecondary};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    max-width: ${pxToRemMd(302)};
    text-align: center;
    margin: auto;
  }
`;

export const BankAccountRequirementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${pxToRem(8)};
`;

export const BadgeWrapper = styled.div`
  margin-bottom: ${pxToRem(16)};
`;

export const ButtonBankAccountCard = styled(Button)`
  margin-top: ${pxToRem(8)};
  width: max-content;
`;

const BankAccountCard = ({
  bankAccount,
  handleVerifyAccount,
  handleDeposit,
  handleRemoveAccount,
  allowAccountChangesPermission,
  contactSupport,
  isDepositButtonVisible,
}) => {
  const { t } = useTranslation();
  const { displayBasicAccountOptionsPermission } = useCheckPermissions();
  const account = useSelector(getCurrentAccount);
  const { UploadDocumentUrlFormat } = useSelector(selectSettings);
  const type = separateByUppercase(
    parseEnumType(bankAccountTypes, bankAccount?.BankAccountType),
  );
  const { fundGtmEvent } = useGtmHook();

  if (!bankAccount || isEmpty(bankAccount)) {
    return null;
  }

  const bankAccountStatus = getBankAccountStatus(bankAccount);

  const isWireBankAccount =
    parseEnumType(bankAccountPurposeTypes, bankAccount?.PurposeType) ===
    parseEnumType(bankAccountPurposeTypes, 2);

  const shouldReUploadVoidedCheck = () => {
    const { VoidedCheckStatusType } = bankAccount;

    if (
      parseEnumType(voidedCheckStatusTypes, VoidedCheckStatusType) ===
      parseEnumType(voidedCheckStatusTypes, 4)
    ) {
      return true;
    }

    return false;
  };

  const shouldRenderMicroDepositButton = () => {
    const { SetupType } = bankAccount;

    if (
      parseEnumType(bankAccountSetupTypes, SetupType) ===
      parseEnumType(bankAccountSetupTypes, 3)
    ) {
      return false;
    }

    return true;
  };

  const shouldRenderBankAccountBadge = () => {
    const verifiedStatus = t('bankAccounts.verificationStatus.verified');

    if (isWireBankAccount) {
      const isCompleteState =
        parseEnumType(bankAccountApprovalStatus, bankAccount?.StateType) ===
        parseEnumType(bankAccountApprovalStatus, 5);

      if (isCompleteState) {
        return false;
      }

      return true;
    }

    const { status } = bankAccountStatus;

    if (status === verifiedStatus) {
      return false;
    }

    return true;
  };

  const shouldShowBankAccountDepositButton = (bankAccount) => {
    if (isWireBankAccount) {
      return false;
    }

    return isBankAccountVerified(bankAccount);
  };

  const shouldRenderHelperText = () => {
    const expiredVerificationStatus = t(
      'bankAccounts.verificationStatus.expiredVerification',
    );
    const failedVerificationStatus = t(
      'bankAccounts.verificationStatus.failedVerification',
    );
    const bankAccountStatus = getBankAccountStatus(bankAccount);

    return (
      bankAccountStatus?.status === failedVerificationStatus ||
      bankAccountStatus?.status === expiredVerificationStatus
    );
  };

  const handleFundingGtmEvent = () => {
    fundGtmEvent(FUND_DEPOSIT_START, {
      fund: {
        method: DEPOSIT_WIZARD_TYPES.ACH,
      },
    });
  };

  return (
    <BankAccountCardWrapper key={bankAccount.BankAccountUid}>
      <BankAccountHardHeader>
        <BankAccountCardHeaderContent>
          <Paragraph fontSize={18} bold isFlex>
            {`${bankAccount.BankName}, ${bankAccount.AccountMask}`} ({type})
          </Paragraph>

          {shouldRenderBankAccountBadge() && <Badge {...bankAccountStatus} />}
        </BankAccountCardHeaderContent>

        {handleRemoveAccount && (
          <IconButtonWithLabel
            type="button"
            color={themeColors.colorIconPrimary}
            onClick={() =>
              handleRemoveAccount(
                bankAccount?.BankAccountUid,
                parseEnumType(
                  bankAccountPurposeTypes,
                  bankAccount?.PurposeType,
                ),
                bankAccount?.BankAccountType,
              )
            }
            disabled={
              !allowAccountChangesPermission || !isUserAccountApproved(account)
            }
          >
            <DeleteIcon width={12} height={12} />
            <Paragraph fontSize={16} marginLeft={6} textDecoration="underline">
              {t('common.delete')}
            </Paragraph>
          </IconButtonWithLabel>
        )}
      </BankAccountHardHeader>
      {isDepositButtonVisible &&
        shouldShowBankAccountDepositButton(bankAccount) && (
          <ButtonBankAccountCard
            variant="primary"
            type="button"
            disabled={
              !allowAccountChangesPermission ||
              displayBasicAccountOptionsPermission ||
              !isUserAccountApproved(account)
            }
            onClick={() => {
              handleDeposit(bankAccount);
              handleFundingGtmEvent();
            }}
          >
            {i18next.t('bankAccounts.depositBtn')}
          </ButtonBankAccountCard>
        )}
      {!shouldRenderRequirements(bankAccount) &&
        shouldRenderHelperText(bankAccount) && (
          <Paragraph marginBottom={4} marginTop={4}>
            {t('bankAccounts.verificationFailed')}{' '}
            <AnchorOnClick onClick={contactSupport}>
              {t('bankAccounts.contactCustomerSupport')}
            </AnchorOnClick>
            .
          </Paragraph>
        )}
      {shouldRenderRequirements(bankAccount) && (
        <BankAccountRequirementWrapper>
          <Paragraph marginBottom={4}>
            {t('bankAccounts.moreInfoIsRequired')}
          </Paragraph>
          {shouldRenderMicroDepositButton() && (
            <BankAccountRequirementItem
              title={t('bankAccounts.confirmMicroDepositsTitle')}
              description={t('bankAccounts.confirmMicroDepositsDescription')}
              badgeProps={getMicroDepositsStatus(bankAccount)}
              onClick={() => {
                handleVerifyAccount(bankAccount?.BankAccountUid);
              }}
            />
          )}
          <BankAccountRequirementItem
            title={t('bankAccounts.confirmVoidedCheckTitle')}
            description={t('bankAccounts.confirmVoidedCheckDescription')}
            badgeProps={getVoidedCheckStatus(bankAccount)}
            isUploadVoidedCheck
            onClick={() => {
              if (
                bankAccount?.EncriptedBrokerAccountUid &&
                bankAccount?.EncriptedBankAccountUid
              ) {
                const url = `${UploadDocumentUrlFormat}${encodeURIComponent(
                  bankAccount?.EncriptedBrokerAccountUid,
                )}&isBankAccountDocument=true&bankAccountUid=${encodeURIComponent(
                  bankAccount?.EncriptedBankAccountUid,
                )}`;
                window.open(url, '_blank').focus();
              }
            }}
            shouldReUploadVoidedCheck={shouldReUploadVoidedCheck()}
            disabled={!isUserAccountApproved(account)}
          />
        </BankAccountRequirementWrapper>
      )}
    </BankAccountCardWrapper>
  );
};

export default BankAccountCard;
BankAccountCard.defaultProps = {
  isDepositButtonVisible: true,
};
BankAccountCard.propTypes = {
  bankAccount: PropTypes.shape({
    BankAccountUid: PropTypes.string,
    StateType: PropTypes.number,
    PurposeType: PropTypes.number,
    BankAccountType: PropTypes.number,
    BankName: PropTypes.string,
    AccountMask: PropTypes.string,
    IsVerified: PropTypes.bool,
    VoidedCheckStatusType: PropTypes.number,
    MicroDepositStatusType: PropTypes.number,
    EncriptedBrokerAccountUid: PropTypes.string,
    EncriptedBankAccountUid: PropTypes.string,
    SetupType: PropTypes.number,
  }),
  handleVerifyAccount: PropTypes.func,
  handleErrorAccount: PropTypes.func,
  isACHAccount: PropTypes.bool,
  handleDeposit: PropTypes.func,
  handleRemoveAccount: PropTypes.func,
  allowAccountChangesPermission: PropTypes.bool,
  contactSupport: PropTypes.func,
  isDepositButtonVisible: PropTypes.bool,
};
