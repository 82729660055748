import styled, { css } from 'styled-components';
import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import themeColors from '../../../assets/styles/themeColors';
import {
  uButtonClear,
  uFlexCenter,
  uFlexColumn,
  uOutlineNone,
  uTransition,
} from '../../../assets/styles/utility';
import { variables } from '../../../assets/styles/variables';
import checkboxChecked from '../../../assets/images/svg/checked.svg';
import checkboxUnchecked from '../../../assets/images/svg/unchecked.svg';
import chevronDown from '../../../assets/images/svg/chevron-down-filter.svg';
import { SortByContainer } from '../../SortBy/SortBy';

export const FilterContainer = styled.div`
  ${mediaBelow(variables.breakpoints.bpMd)} {
    ${uFlexColumn};
    height: 100%;
  }
`;

export const FilterHeaderWrap = styled.div`
  ${uFlexColumn};
  margin-bottom: ${pxToRem(32)};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    margin-bottom: 0;
  }
`;

export const FilterHead = styled.div`
  width: 100%;
  background-color: ${themeColors.colorTableHeaderBackground};
  box-shadow: ${variables.shadow.boxShadow};
  padding: ${pxToRem(4)} 0 0;
  font-size: ${pxToRem(14)};
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.5;
  z-index: ${variables.zIndex.indexXxs};

  > p {
    padding: 0 ${pxToRem(18)};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    display: none;
  }
`;

export const ClearRefinementsContainer = styled.div``;

export const ClearRefinementsButton = styled.button`
  ${uTransition};
  color: ${themeColors.colorTextSecondary};
  font-size: ${pxToRem(14)};
  letter-spacing: 0;
  line-height: 1.15;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  position: relative;
  outline: none;
  cursor: pointer;

  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
    cursor: auto;
  }

  &:hover {
    color: ${themeColors.colorPrimaryLight};
  }

  &:active {
    color: ${themeColors.colorPrimaryDark};
  }
`;

export const FilterList = styled.div`
  position: relative;
  padding-left: ${pxToRem(16)};

  ${ClearRefinementsContainer} {
    position: absolute;
    right: 0;
    top: 0;
    font-size: ${pxToRem(14)};
    line-height: 1.15;
    letter-spacing: 0;
    color: ${themeColors.colorTextSecondary};
    cursor: pointer;
    height: ${pxToRem(23)};
    display: flex;
    align-items: center;
  }

  .ais-RefinementList {
    margin: 0;
    margin-bottom: ${pxToRem(32)};
  }

  .ais-RefinementList-label {
    font-size: ${pxToRem(16)};
    line-height: 1.5;
    cursor: pointer;
    position: relative;
  }

  div:has(+ .ais-RefinementList--noRefinement) {
    display: none;
  }

  .ais-RefinementList-labelText {
    margin-left: ${pxToRem(24)};

    &::before {
      content: '';
      width: ${pxToRem(16)};
      height: ${pxToRem(16)};
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -55%);
      background-image: url(${checkboxChecked});
      display: none;
    }

    &::after {
      content: '';
      display: block;
      width: ${pxToRem(16)};
      height: ${pxToRem(16)};
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -55%);
      background-image: url(${checkboxUnchecked});
    }
  }

  .ais-RefinementList-checkbox {
    height: 0;
    display: none;

    &:checked + .ais-RefinementList-labelText {
      &::before {
        display: block;
      }

      &::after {
        display: none;
      }
    }
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding-left: 0;

    ${ClearRefinementsContainer} {
      display: none;
    }

    .ais-RefinementList-label {
      font-size: ${pxToRemMd(16)};
    }

    .ais-RefinementList-checkbox {
      width: ${pxToRemMd(24)};
      margin-right: ${pxToRemMd(8)};

      &::before {
        width: ${pxToRemMd(24)};
        height: ${pxToRemMd(24)};
      }

      &::after {
        width: ${pxToRemMd(24)};
        height: ${pxToRemMd(24)};
      }
    }
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    ${uFlexColumn};
    height: 100%;
  }
`;

export const FilterTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${pxToRem(12)};
  align-items: center;

  svg {
    ${uTransition};
    width: ${pxToRemMd(24)};
    height: ${pxToRemMd(24)};
    display: none;
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: ${pxToRemMd(12)} ${pxToRemMd(16)};
    margin-bottom: 0;
    cursor: pointer;

    svg {
      display: block;
      color: ${themeColors.colorIconPrimary};
    }
  }
`;

export const FilterTitle = styled.h2`
  font-weight: 600;
  letter-spacing: 0;
  font-size: ${pxToRem(18)};
  line-height: 1.33;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    line-height: 1.6;
    font-size: ${pxToRemMd(18)};
  }
`;

export const filterModal = css`
  ${mediaBelow(variables.breakpoints.bpXl)} {
    ${SortByContainer} {
      margin-right: 0;
    }

    .ais-RefinementList-item {
      padding: ${pxToRemMd(8)} ${pxToRemMd(16)};
    }

    .ais-RefinementList {
      margin-bottom: ${pxToRemMd(12)};
    }

    ${ClearRefinementsContainer} {
      margin-right: ${pxToRemMd(64)};
      margin-top: ${pxToRemMd(17)};
    }

    .ais-RefinementList-showMore {
      margin-left: ${pxToRemMd(36)} !important;
    }
  }
`;

export const FilterSection = styled.div`
  ${(props) =>
    props.isOpenSection &&
    `
    ${FilterTitleWrap} {
      background-color: ${themeColors.colorBackgroundSecondary};

      svg {
        transform: rotate(180deg);
      }
    }
  `};

  .ais-RefinementList.expanded {
    .ais-RefinementList-showMore::before {
      transform: rotate(180deg);
    }
  }

  .ais-RefinementList-showMore {
    ${uTransition};
    color: ${themeColors.colorPrimary};
    font-size: ${pxToRemMd(12)};
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.56;
    text-align: center;
    background-color: transparent;
    border: none;
    position: relative;
    margin-left: ${pxToRemMd(20)};
    outline: none;
    cursor: pointer;

    &[disabled] {
      display: none;
    }

    &:hover {
      color: ${themeColors.colorPrimaryLight};
    }

    &:active {
      color: ${themeColors.colorPrimaryDark};
    }

    &::before {
      ${uTransition};
      content: '';
      background-image: url(${chevronDown});
      background-position: center;
      width: ${pxToRemMd(18)};
      height: ${pxToRemMd(18)};
      position: absolute;
      left: -${pxToRemMd(22)};
    }
  }
`;

export const FilterCta = styled.div`
  display: none;
  margin-top: auto;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${pxToRemMd(16)};
    padding: ${pxToRemMd(32)} ${pxToRemMd(16)} ${pxToRemMd(16)};

    button {
      font-size: ${pxToRemMd(18)} !important;
      text-transform: capitalize !important;
      width: 100% !important;
    }

    ${ClearRefinementsContainer} {
      margin: 0;
      display: block;
      position: relative;
      width: 100%;
      height: 100%;

      ${ClearRefinementsButton} {
        ${uTransition};
        ${uOutlineNone};
        ${uButtonClear};
        ${uFlexCenter};
        font-size: ${pxToRem(18)};
        line-height: 1.35;
        padding: ${pxToRem(8)} ${pxToRem(8)};
        border-radius: ${variables.borderRadius.borderRadius};
        box-shadow: ${variables.shadow.buttonShadowPressed};
        color: inherit;
        font-weight: 600;
        letter-spacing: 0;
        text-align: center;
        text-transform: uppercase;
        user-select: none;
        white-space: nowrap;
        min-width: ${pxToRem(120)};
        flex-shrink: 0;
        cursor: pointer;
        background-color: transparent;
        color: ${themeColors.colorPrimary};
        border: 1px solid ${themeColors.colorPrimary};
        text-decoration: none;

        &:disabled {
          opacity: 0.5;
          cursor: auto;
        }

        &:disabled {
          &:hover {
            color: ${themeColors.colorPrimary};
            border: 1px solid ${themeColors.colorPrimary};
          }
        }

        &:hover {
          color: ${themeColors.colorPrimary};
          border: 1px solid ${themeColors.colorPrimary};
        }

        &:focus,
        &:active {
          color: ${themeColors.colorPrimary};
          border: 1px solid ${themeColors.colorPrimary};
        }
      }
    }
  }

  ${mediaBelow(variables.breakpoints.bpXs)} {
    grid-template-columns: 1fr;
  }
`;
