import React, { useEffect } from 'react';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ReactComponent as UnsuccessfulIcon } from '../../assets/images/svg/unsuccessful.svg';
import { ReactComponent as Check } from '../../assets/images/svg/check-round.svg';
import Paragraph from '../Paragraph/Paragraph';
import ModalButtons from '../Modal/ModalButtons';
import { StatusModalBody, StatusModalIconWrap } from './StatusModal';
import {
  retrieveFromSessionStorage,
  storeInSessionStorage,
} from '../../util/helpers/sessionStorageHelper';
import {
  STATUS_NEW_ADDRESS_ADDED,
  IS_CARD_USED_FOR_AUTO_PAY,
} from '../../constants/sessionStorage';

import AutoPayStatus from '../StorageFee/AutoPayStatus';

const Status = ({
  hasError,
  onButtonClick,
  text,
  backButtonText = i18next.t('common.goBack'),
  setModalTitleSuffix,
  callGtm,
  showAutoPayStatus,
  selectedCreditCard,
  isAutoPayOn,
  setIsAutoPaySettingsModalOpen,
  setIsOpenPayStorageFeeByCard,
  isOpenPayStorageFeeByCard,
  showChangeSettingsButton,
  setIsAutoPayOn,
  removeCreditCardModalOpen,
  setRemoveCreditCardModalOpen,
  buttonDisabled,
  mailInStorageFlow,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (setModalTitleSuffix) {
      setModalTitleSuffix('');
    }

    if (callGtm) {
      callGtm();
    }
  }, []); // eslint-disable-line

  const isCardUsedForAutoPay = retrieveFromSessionStorage(
    IS_CARD_USED_FOR_AUTO_PAY,
  );

  const onOkClick = () => {
    if (!hasError) storeInSessionStorage(STATUS_NEW_ADDRESS_ADDED, true);
    onButtonClick();
  };

  const buttonLabel = () => {
    if (hasError) {
      return backButtonText;
    }

    return t('common.ok');
  };

  return (
    <StatusModalBody showAutoPayStatus>
      <StatusModalIconWrap>
        {hasError ? <UnsuccessfulIcon /> : <Check />}
      </StatusModalIconWrap>

      {mailInStorageFlow && (
        <Paragraph isColumn bold centerText marginBottom={20}>
          {t('mailIn.requestSubmitted')}
        </Paragraph>
      )}

      <Paragraph isColumn centerText labelColor>
        {text}
      </Paragraph>

      {(showAutoPayStatus || isCardUsedForAutoPay) && (
        <AutoPayStatus
          creditCard={selectedCreditCard}
          isAutoPayOn={isAutoPayOn}
          setIsAutoPaySettingsModalOpen={setIsAutoPaySettingsModalOpen}
          setIsOpenPayStorageFeeByCard={setIsOpenPayStorageFeeByCard}
          isOpenPayStorageFeeByCard={isOpenPayStorageFeeByCard}
          showChangeSettingsButton={showChangeSettingsButton}
          disabled={buttonDisabled}
          hasError={hasError}
          setIsAutoPayOn={setIsAutoPayOn}
          removeCreditCardModalOpen={removeCreditCardModalOpen}
          setRemoveCreditCardModalOpen={setRemoveCreditCardModalOpen}
        />
      )}

      <ModalButtons
        isVertical
        marginTop
        showOnlyPrimary
        primaryButtonProps={{
          onClick: onOkClick,
          label: buttonLabel(),
          'data-cy': 'button-okay',
        }}
      />
    </StatusModalBody>
  );
};

Status.propTypes = {
  text: PropType.oneOfType([PropType.string, PropType.shape({})]),
  hasError: PropType.bool,
  onButtonClick: PropType.func,
  backButtonText: PropType.string,
  setModalTitleSuffix: PropType.func,
  callGtm: PropType.func,
  showAutoPayStatus: PropType.bool,
  selectedCreditCard: PropType.oneOfType([PropType.string, PropType.shape({})]),
  isAutoPayOn: PropType.bool,
  setIsAutoPaySettingsModalOpen: PropType.func,
  setIsOpenPayStorageFeeByCard: PropType.func,
  isOpenPayStorageFeeByCard: PropType.bool,
  showChangeSettingsButton: PropType.bool,
  setIsAutoPayOn: PropType.func,
  removeCreditCardModalOpen: PropType.bool,
  setRemoveCreditCardModalOpen: PropType.func,
  buttonDisabled: PropType.bool,
  mailInStorageFlow: PropType.bool,
};

export default Status;
