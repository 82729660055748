import React from 'react';
import styled from 'styled-components';
import {
  mediaBelow,
  pxToRem,
  pxToRemMd,
} from '../../../../assets/styles/helper';
import themeColors from '../../../../assets/styles/themeColors';
import { variables } from '../../../../assets/styles/variables';

export const TransactionDetailDivider = styled.div`
  border-bottom: 1px solid ${themeColors.colorBorderPrimary};
  margin-bottom: ${pxToRem(8)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-bottom: ${pxToRemMd(7.5)};
  }
`;

const DividerField = () => <TransactionDetailDivider />;

export default DividerField;
