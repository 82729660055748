import isEmpty from 'lodash.isempty';
import { retrieveFromSessionStorage } from './sessionStorageHelper';
import { WIZARD, WIZARD_DATA } from '../../constants/sessionStorage';

const BASE = 0;

export const getTierForAmount = (tiers, cashAmount) => {
  if (!tiers || isEmpty(tiers)) {
    return null;
  }
  const searchTiers = JSON.parse(JSON.stringify(tiers));
  if (searchTiers[0].LowBound !== 0) {
    searchTiers[0].LowBound = 0;
  }

  return searchTiers.find((tier) => {
    const max = tier.HighBound === null ? Number.MAX_VALUE : tier.HighBound;
    const min = tier.LowBound === null ? 0 : tier.LowBound;
    return max >= cashAmount && min <= cashAmount;
  });
};

export const getFineWeight = (isProductPage) => {
  let fineWeight = BASE;

  if (isProductPage) {
    fineWeight = retrieveFromSessionStorage(WIZARD_DATA)?.productPrices
      ?.FineWeightDefault;
  }
  if (!fineWeight) {
    fineWeight = retrieveFromSessionStorage(WIZARD)?.Product?.FineWeightDefault;
  }
  return fineWeight;
};

const getFineWeightTotals = (barsArray, quantity) => {
  if (!barsArray || !quantity || quantity > barsArray.length) {
    return BASE;
  }
  const sortedArray = barsArray.sort(
    (a, b) => a.SequenceNumber - b.SequenceNumber,
  );
  const slicedArray = sortedArray.slice(BASE, quantity);

  return slicedArray.reduce(
    (accumulator, currentValue) => accumulator + currentValue.FineWeight,
    BASE,
  );
};

export const lockedPriceQuantityEstimatedSubtotal = (
  quantityAmount,
  tiers,
  setPricePerUnit,
  isProductPage,
  unitPrice,
  barsArray,
) => {
  if (!tiers || !quantityAmount) {
    return {
      estimatedOrderTotal: quantityAmount * unitPrice,
      newPricePerUnit: unitPrice,
    };
  }

  const isVariableWeightProduct = barsArray && barsArray?.length > 0;
  const worstTier = tiers[BASE];

  let fineWeight = 0;

  if (!fineWeight && isVariableWeightProduct) {
    fineWeight = getFineWeightTotals(barsArray, quantityAmount);
  } else if (!fineWeight && !isVariableWeightProduct) {
    fineWeight = getFineWeight(isProductPage);
  }

  if (!fineWeight) {
    return {
      estimatedOrderTotal: quantityAmount * unitPrice,
      newPricePerUnit: unitPrice,
    };
  }

  if (isVariableWeightProduct) {
    const firstSubtotal = Number(
      (worstTier.PricePerOz * fineWeight).toFixed(2),
    );

    const tierForFirstSubtotal = getTierForAmount(tiers, firstSubtotal);
    if (!tierForFirstSubtotal || isEmpty(tierForFirstSubtotal)) {
      return {
        estimatedOrderTotal: quantityAmount * unitPrice,
        newPricePerUnit: unitPrice,
      };
    }
    const recalculatedSubtotal = Number(
      (tierForFirstSubtotal.PricePerOz * fineWeight).toFixed(2),
    );

    const finalTier = getTierForAmount(tiers, recalculatedSubtotal);
    if (!finalTier || isEmpty(finalTier)) {
      return {
        estimatedOrderTotal: recalculatedSubtotal,
        newPricePerUnit: unitPrice,
      };
    }

    setPricePerUnit(recalculatedSubtotal / quantityAmount);
    return {
      estimatedOrderTotal: recalculatedSubtotal,
      newPricePerUnit: recalculatedSubtotal / quantityAmount,
    };
  }

  // Step 1: Order Amount = Price Per Oz * Quantity * FineWeightDefault
  const worstTierOrderSubtotal = Number(
    (worstTier.PricePerOz * quantityAmount * fineWeight).toFixed(2),
  );

  // Step 2: Get tier for worst tier order amount
  const tierForWorstTierOrderAmount = getTierForAmount(
    tiers,
    worstTierOrderSubtotal,
  );

  // Step 3: Calculate new subtotal using new tier
  const recalculatedSubtotal = Number(
    (
      tierForWorstTierOrderAmount.PricePerOz *
      quantityAmount *
      fineWeight
    ).toFixed(2),
  );

  // Step 3a. Get tier for newSubtotal
  const finalTier = getTierForAmount(tiers, recalculatedSubtotal);
  if (!finalTier || isEmpty(finalTier)) {
    return {
      estimatedOrderTotal: recalculatedSubtotal,
      newPricePerUnit: unitPrice,
    };
  }

  setPricePerUnit(finalTier.PricePerUnit);
  return {
    estimatedOrderTotal: recalculatedSubtotal,
    newPricePerUnit: recalculatedSubtotal / quantityAmount,
  };
};
