import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { formatMoneyNumeral } from '../../util/helpers/numeralHelpers';
import InformationList from '../InformationList/InformationList';
import InformationListItem from '../InformationList/InformationListItem';
import { selectSettings } from '../../store/selectors/settingsSelectors';
import Paragraph from '../Paragraph/Paragraph';
import themeColors from '../../assets/styles/themeColors';
import creditCardTypes from '../../util/enum/api/creditCardTypes';

const PricesWrapper = styled.div``;

const OrderTotal = ({
  finalOrder,
  orderType,
  orderValues,
  buyCashBuffer,
  paymentMethod,
  isPostPaidVisible,
}) => {
  const { t } = useTranslation();
  const settings = useSelector(selectSettings);
  const [
    cashOrderInformationListData,
    setCashOrderInformationListData,
  ] = useState([]);
  const [
    quantityOrderInformationListData,
    setQuantityOrderInformationListData,
  ] = useState([]);
  const [priceLocked, setPriceLocked] = useState(false);

  const paymentMethodValue = () => {
    switch (paymentMethod?.PaymentMethodType) {
      case 1:
        return t('buyWizard.postPaid.paymentMethods.AccountBalance');
      case 2:
        return `${paymentMethod?.BankName}, ${paymentMethod?.BankAccountMask}`;
      case 3:
        return t('creditCards.cardTypeAndNumberLabel', {
          cardType: t(
            `creditCards.creditCardTypes.${
              creditCardTypes[paymentMethod?.CreditCardType]
            }`,
          ),
          last4Digits: paymentMethod?.Last4Digits,
        });

      default:
        return t('buyWizard.postPaid.paymentMethods.AccountBalance');
    }
  };

  const showPaymentMethodValue = paymentMethodValue();

  useEffect(() => {
    if (!settings || isEmpty(settings) || !settings?.BrokerCode) {
      return;
    }
    setPriceLocked(!!settings?.IsLockedPricingActive);
  }, [settings]);

  const discountPrice =
    finalOrder.paymentMethodData?.DataWithoutCCFee?.TotalClientExecutionPrice;
  const crossedOutPrice =
    finalOrder.paymentMethodData?.DataWithoutCCFee
      ?.TotalClientExecutionPriceWithFee;

  const priceWithDiscount = () => {
    if (paymentMethod?.PaymentMethodType !== 3) {
      return (
        <PricesWrapper>
          <Paragraph color={themeColors.colorSuccess} bold>
            {formatMoneyNumeral(discountPrice)}
          </Paragraph>
          <Paragraph textDecoration="line-through" marginLeft={8} bold>
            {formatMoneyNumeral(crossedOutPrice)}
          </Paragraph>
        </PricesWrapper>
      );
    }
    return (
      <Paragraph bold>
        {formatMoneyNumeral(finalOrder.estimatedTotal)}
      </Paragraph>
    );
  };

  useEffect(() => {
    const dataForCashOrder = [
      {
        value: formatMoneyNumeral(finalOrder.pricePerUnit),
        label: settings.IsLockedPricingActive
          ? t('product.detailsTable.pricePerUnit')
          : t('buyWizard.buyForDelivery.reviewOrder.orderTotal.pricePerUnit'),
      },
      {
        value: finalOrder.quantity,
        label: settings.IsLockedPricingActive
          ? t('buyWizard.buyForDelivery.reviewOrder.orderTotal.quantity')
          : t(
              'buyWizard.buyForDelivery.reviewOrder.orderTotal.estimatedQuantity',
            ),
      },
      settings?.IsLockedPricingActive && finalOrder?.volumeDiscount > 0
        ? {
            value: `(${formatMoneyNumeral(finalOrder.volumeDiscount)})`,
            label: t(
              'buyWizard.buyForDelivery.reviewOrder.orderTotal.volumeDiscount',
            ),
            isBold: true,
          }
        : { value: '', label: '' },
      {
        value: isPostPaidVisible
          ? priceWithDiscount()
          : formatMoneyNumeral(finalOrder.estimatedTotal),
        label: settings.IsLockedPricingActive
          ? t('buyWizard.buyForDelivery.reviewOrder.orderTotal.total')
          : t('buyWizard.buyForDelivery.reviewOrder.orderTotal.estimatedTotal'),
        isBold: true,
      },
    ];
    setCashOrderInformationListData(dataForCashOrder);

    const dataForQuantityOrder = [
      {
        value: formatMoneyNumeral(finalOrder.pricePerUnit),
        label: settings.IsLockedPricingActive
          ? t('product.detailsTable.pricePerUnit')
          : t('buyWizard.buyForDelivery.reviewOrder.orderTotal.pricePerUnit'),
      },
      {
        value: finalOrder.quantity,
        label: t('buyWizard.buyForDelivery.reviewOrder.orderTotal.quantity'),
      },
      settings?.IsLockedPricingActive && finalOrder?.volumeDiscount > 0
        ? {
            value: `(${formatMoneyNumeral(finalOrder.volumeDiscount)})`,
            label: t(
              'buyWizard.buyForDelivery.reviewOrder.orderTotal.volumeDiscount',
            ),
            isBold: true,
          }
        : { label: '', value: '' },
      {
        value: isPostPaidVisible
          ? priceWithDiscount()
          : formatMoneyNumeral(finalOrder.estimatedTotal),
        label: settings.IsLockedPricingActive
          ? t('buyWizard.buyForDelivery.reviewOrder.orderTotal.total')
          : t('buyWizard.buyForDelivery.reviewOrder.orderTotal.estimatedTotal'),
        isBold: true,
      },
      isPostPaidVisible
        ? {
            value: showPaymentMethodValue,
            label: t('buyWizard.verifyOrder.paymentMethod'),
          }
        : { label: '', value: '' },
    ];
    setQuantityOrderInformationListData(dataForQuantityOrder);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyCashBuffer, orderValues, orderType, finalOrder, settings]);

  if (orderType === 'Cash' && !priceLocked) {
    return (
      <>
        <InformationList marginTop={8} withBackground>
          {cashOrderInformationListData.map(({ value, label, isBold }) => (
            <InformationListItem
              key={uuidv4()}
              fullWidth
              isBold={isBold}
              value={value}
              label={label}
            />
          ))}
        </InformationList>
      </>
    );
  }

  if (orderType === 'Quantity' || priceLocked) {
    return (
      <>
        <InformationList marginBottom={8} marginTop={8} withBackground>
          {quantityOrderInformationListData.map(({ label, value, isBold }) => (
            <InformationListItem
              key={uuidv4()}
              label={label}
              value={value}
              isBold={isBold}
              fullWidth
            />
          ))}
        </InformationList>
      </>
    );
  }

  return null;
};

OrderTotal.propTypes = {
  finalOrder: PropTypes.shape({
    pricePerUnit: PropTypes.number,
    quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    estimatedTotal: PropTypes.number,
    cashBuffer: PropTypes.number,
    requiredBalanceWithBuffer: PropTypes.number,
    estimatedPriceForUnit: PropTypes.number,
    estimatedQuantity: PropTypes.number,
    volumeDiscount: PropTypes.number,
    newPricePerUnit: PropTypes.number,
    paymentTypeDiscount: PropTypes.number,
    paymentMethodData: PropTypes.shape({
      DataWithoutCCFee: PropTypes.shape({
        TotalClientExecutionPrice: PropTypes.number,
        TotalClientExecutionPriceWithFee: PropTypes.number,
      }),
    }),
  }),
  paymentMethod: PropTypes.shape({
    PaymentMethodType: PropTypes.number,
    BankName: PropTypes.string,
    BankAccountMask: PropTypes.number,
    CreditCardType: PropTypes.number,
    Last4Digits: PropTypes.number,
  }),
  isPostPaidVisible: PropTypes.bool,
  orderType: PropTypes.string,
  orderValues: PropTypes.shape({
    CashAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    QuantityAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  buyCashBuffer: PropTypes.number,
};

export default React.memo(OrderTotal);
