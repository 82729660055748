import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { ReactComponent as Caret } from '../../assets/images/svg/arrow-down-thick.svg';
import { buttonizeDiv } from '../../util/buttonizeDiv';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import themeColors from '../../assets/styles/themeColors';
import { uLineClamp, uTransition } from '../../assets/styles/utility';
import Note from '../Notes/Note';
import Paragraph from '../Paragraph/Paragraph';
import useWindowSize from '../../util/hooks/useIsMobileHook';

const NagDashboardTableHeaderContainer = styled.div`
  padding: ${pxToRem(16)} ${pxToRem(16)} ${pxToRem(16)} 0px;
  font-size: ${pxToRem(48)};
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.5;
  display: flex;
  align-items: center;
  border-radius: ${variables.borderRadius.borderRadius};
  background-color: ${themeColors.colorWhite};
  display: grid;
  grid-template-columns: 1fr auto ${pxToRem(10)};
  grid-gap: ${pxToRem(8)};
  ${(props) =>
    props.isOpen &&
    `
    ${NagDashboardTableHeaderIcon} {
      svg {
        transform: rotate(-180deg);
      }
    }
  `};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: ${pxToRemMd(16)};
  }
`;

const NagDashboardTableHeaderInfo = styled.div``;

const NagDashboardTableHeaderTitle = styled.p`
  ${uLineClamp(2)}
  letter-spacing: 0;
  line-height: 1.5;
  text-align: left;
  font-size: ${pxToRem(24)};
  font-weight: 700;
  ${mediaBelow(variables.breakpoints.bpSm)} {
    margin-bottom: ${pxToRemMd(12)};
    ${uLineClamp(1)};
  }
`;

const NagDashboardTableHeaderIcon = styled.div`
  ${uTransition};
  justify-self: flex-end;
  width: ${pxToRem(18)};
  height: auto;
  cursor: pointer;

  svg {
    ${uTransition};
    width: ${pxToRem(18)};
    height: auto;
  }
`;

const NagDashboardTableHeader = ({
  title,
  onClick,
  isOpen,
  canDismiss,
  dismissedDasboard,
  accountUid,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const windowSize = useWindowSize();
  const areBiggerScreens = windowSize.width > 1199;

  useEffect(() => {
    if (areBiggerScreens) {
      return setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize, isOpen]);

  if (canDismiss) {
    return (
      <NagDashboardTableHeaderContainer isOpen={isOpen}>
        <NagDashboardTableHeaderInfo>
          <NagDashboardTableHeaderTitle>{title}</NagDashboardTableHeaderTitle>
        </NagDashboardTableHeaderInfo>
        <Paragraph>
          <Note
            primaryColor
            bold
            text={t('nagDashboard.table.dismissDashboard')}
            onClick={(event) => {
              event.preventDefault();
              dismissedDasboard({ accountUid });
            }}
          />
        </Paragraph>
      </NagDashboardTableHeaderContainer>
    );
  }

  return (
    <NagDashboardTableHeaderContainer isOpen={isOpen}>
      <NagDashboardTableHeaderInfo>
        <NagDashboardTableHeaderTitle>{title}</NagDashboardTableHeaderTitle>
      </NagDashboardTableHeaderInfo>
      {!areBiggerScreens && (
        <NagDashboardTableHeaderIcon {...buttonizeDiv(onClick)}>
          <Caret />
        </NagDashboardTableHeaderIcon>
      )}
    </NagDashboardTableHeaderContainer>
  );
};

NagDashboardTableHeader.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  ounces: PropTypes.string,
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  canDismiss: PropTypes.bool,
  setIsDissmised: PropTypes.func,
  dismissedDasboard: PropTypes.func,
  accountUid: PropTypes.string,
  setIsOpen: PropTypes.bool,
};

export default NagDashboardTableHeader;
