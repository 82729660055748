import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from '../../../Modal/Modal';

import StepByStep from '../../../StepByStep';
import VerifyBankAccount from './VerifyBankAccount';
import Status from '../../Status';

const steps = [VerifyBankAccount, Status];

const VerifyBankAccountModal = ({ isOpen, onClose, bankAccountUid }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);

  return (
    <Modal
      size="sm"
      title={t('bankAccounts.verify.title')}
      isOpen={isOpen}
      close={onClose}
    >
      <StepByStep saveStepDisabled>
        {({ goStepForward, goStepBack }) =>
          steps.map((step) =>
            React.createElement(step, {
              handleBack: goStepBack,
              handleNext: goStepForward,
              handleClose: onClose,
              bankAccountUid,
              setError,
              text: error || t('bankAccounts.verify.success'),
              hasError: !!error,
              backButtonText: t('common.ok'),
              onButtonClick: onClose,
            }),
          )
        }
      </StepByStep>
    </Modal>
  );
};

VerifyBankAccountModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  bankAccountUid: PropTypes.string,
};

export default VerifyBankAccountModal;
