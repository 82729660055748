import styled from 'styled-components';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import { ProductSearch } from '../InputFields/ProductSearch';
import { SortByContainer } from '../SortBy/SortBy';

const TradingHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${pxToRem(32)};

  > :first-child {
    display: flex;
    justify-content: flex-end;
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    margin-bottom: ${pxToRemMd(24)};
    flex-direction: column;
    max-width: min-content;
    margin-left: auto;

    > div {
      margin-bottom: ${pxToRem(24)};
    }

    ${SortByContainer} {
      display: none;
    }
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-bottom: ${pxToRemMd(24)};
    max-width: 100%;

    ${ProductSearch} {
      width: 100%;
      max-width: 100%;
    }
  }
`;

export default TradingHeader;
