import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import styled from 'styled-components';
import ReactSlider from 'react-slider';
import PercentageField from '../InputFields/PercentageField';
import { formatMoneyNumeral } from '../../util/helpers/numeralHelpers';
import { percentageMultiply } from '../../util/math/money';
import { pxToRem } from '../../assets/styles/helper';
import { uButtonClear, uOutlineNone } from '../../assets/styles/utility';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';

const getSliderTrackStyles = (index) => {
  if (index === 2) {
    return `${themeColors.colorPrimaryDark}`;
  }

  if (index === 1) {
    return `${themeColors.colorProgressBarDefault}`;
  }

  return `linear-gradient(270deg, ${themeColors.colorPrimaryDark}, ${themeColors.colorPrimaryLight})`;
};

export const MetalSliderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MetalSliderHeader = styled(MetalSliderContainer)`
  margin-bottom: ${pxToRem(12)};
`;

export const MetalSliderHeaderLabel = styled.p`
  text-transform: uppercase;
  font-size: ${pxToRem(18)};
  line-height: 1.33;
`;

export const MetalSliderFields = styled(MetalSliderContainer)`
  margin-bottom: ${pxToRem(8)};
  margin-top: ${pxToRem(8)};

  > div {
    max-width: ${pxToRem(89)};
  }

  input {
    height: ${pxToRem(40)};
    font-size: ${pxToRem(16)};
    line-height: 1.5;
  }
`;

export const Slider = styled(ReactSlider)`
  height: ${pxToRem(8)};
`;

export const SliderThumb = styled.div`
  ${uButtonClear};
  ${uOutlineNone};
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};
  border: 4px solid ${themeColors.colorPrimary};
  box-shadow: ${variables.shadow.boxShadow};
  border-radius: 100%;
  background-color: ${themeColors.colorProgressBarDefault};
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const SliderTrack = styled.div`
  height: ${pxToRem(8)};
  border-radius: ${variables.borderRadius.borderRadius};
  background: ${({ index }) => getSliderTrackStyles(index)};
`;

const MetalSlider = ({
  productAllocation,
  handleSliderChange,
  handleChange,
  handleInputChange,
  investmentAmount,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <MetalSliderHeader>
        <MetalSliderHeaderLabel>{t('common.gold')}</MetalSliderHeaderLabel>
        <MetalSliderHeaderLabel>{t('common.silver')}</MetalSliderHeaderLabel>
      </MetalSliderHeader>

      <Slider
        renderThumb={(props) => <SliderThumb {...props} />}
        renderTrack={(props, state) => (
          <SliderTrack index={state.index} {...props} />
        )}
        value={Number(productAllocation.gold)}
        onChange={handleSliderChange(handleChange)}
        min={0}
        max={100}
        withTracks
      />
      <MetalSliderFields>
        <Field
          centerText
          name="gold"
          placeholder="0%"
          component={PercentageField}
          onChange={handleInputChange('gold', handleChange)}
        />
        <Field
          centerText
          name="silver"
          placeholder="0%"
          component={PercentageField}
          onChange={handleInputChange('silver', handleChange)}
        />
      </MetalSliderFields>
      <MetalSliderContainer>
        <p>
          {formatMoneyNumeral(
            percentageMultiply(investmentAmount, productAllocation.gold),
          )}
        </p>
        <p>
          {formatMoneyNumeral(
            percentageMultiply(investmentAmount, productAllocation.silver),
          )}
        </p>
      </MetalSliderContainer>
    </>
  );
};

MetalSlider.propTypes = {
  productAllocation: PropTypes.shape({
    gold: PropTypes.number,
    silver: PropTypes.number,
  }),
  handleSliderChange: PropTypes.func,
  handleChange: PropTypes.func,
  handleInputChange: PropTypes.func,
  investmentAmount: PropTypes.number,
};

export default MetalSlider;
