import {
  SETTINGS_ERROR,
  SETTINGS_FETCH,
  SETTINGS_SUCCESS,
  SETTINGS_REGISTRATION_FETCH,
  SETTINGS_REGISTRATION_SUCCESS,
  SETTINGS_REGISTRATIONS_ERROR,
} from './settingsActionConstants';

export const fetchSettings = (brokerUid) => ({
  type: SETTINGS_FETCH,
  brokerUid,
});

export const setSettings = (payload) => ({
  type: SETTINGS_SUCCESS,
  payload,
});

export const setSettingsError = (payload) => ({
  type: SETTINGS_ERROR,
  payload,
});

export const fetchSettingsRegistration = (brokerUid) => ({
  type: SETTINGS_REGISTRATION_FETCH,
  brokerUid,
});

export const setSettingsRegistration = (payload) => ({
  type: SETTINGS_REGISTRATION_SUCCESS,
  payload,
});

export const setSettingsRegistrationError = (payload) => ({
  type: SETTINGS_REGISTRATIONS_ERROR,
  payload,
});
