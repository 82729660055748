import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import TwoFaPhoneSelectRecieveType from '../../../pages/TwoFA/Components/TwoFaPhoneSelectRecieveType';
import {
  selectAuthUser,
  selectUserPhoneNumbers,
} from '../../../store/selectors/userSelectors';
import { fetchUserPhoneNumbers } from '../../../store/actions/user/userActions';
import { selectedPhoneNumberValidatorAndFormatter } from '../../../util/helpers/phoneNumberHelpers';
import useGetCountries from '../../../util/hooks/useGetCountries';
import { selectIsLoadingByActionTypes } from '../../../store/selectors/loadingSelectors';
import {
  DELETE_USER_PHONE_NUMBER_LOADING,
  USER_PHONE_NUMBERS_LOADING,
} from '../../../store/actions/user/userActionConstants';
import { TWO_FA_CODE_LOADING } from '../../../store/actions/twoFA/twoFAActionConstants';
import SectionLoader from '../../Loader/SectionLoader';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import { twoFaMessageType } from '../../../util/enum/api/twoFATypes';
import { selectPortalGatewayISOCodesListToIgnoreAreaCode } from '../../../store/selectors/settingsSelectors';
import { getTwoFaContent } from '../../../util/helpers/twoFaSetupHelper';
import { twoFaPhoneValidationSchema } from '../../../validation/twoFaPhoneValidationSchema';
import ModalBody from '../../Modal/ModalBody';
import ModalTitle from '../../Modal/ModalTitle';
import Paragraph from '../../Paragraph/Paragraph';
import ModalButtons from '../../Modal/ModalButtons';
import FormContainer from '../../FormContainer/FormContainer';
import SingleColumnList from '../../TwoColumnList/SingleColumnList';
import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import themeColors from '../../../assets/styles/themeColors';
import { variables } from '../../../assets/styles/variables';
import { uFlexCenter } from '../../../assets/styles/utility';
import { ReactComponent as Caret } from '../../../assets/images/svg/down.svg';
import { ReactComponent as Plus } from '../../../assets/images/svg/plus.svg';
import TwoFaPhoneAddPhoneModal from './TwoFaPhoneAddPhoneModal';
import Button from '../../Button/Button';
import { ReactComponent as Warning } from '../../../assets/images/svg/alert-triangle.svg';
import TwoFaPhoneListFull from '../../../pages/TwoFA/Components/TwoFaPhoneListFull';

const AddPhoneNumberSection = styled.div`
  border: 1px solid ${themeColors.colorBorderPrimary};
  background-color: ${themeColors.colorBackgroundPrimary};
  box-shadow: ${variables.shadow.boxShadow};
  ${({ disabled }) =>
    disabled &&
    `opacity: 0.5;
      pointer-events: none;
      `}
`;

const AddPhoneNumberElement = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0;
  box-shadow: none;
  padding: ${pxToRem(12.5)} ${pxToRem(16.5)};
  text-transform: capitalize;
  justify-content: flex-start;
  font-weight: 600;
  color: ${themeColors.colorTextPrimary};
  font-size: ${pxToRem(16)};
  width: 100%;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(16)};
  }
`;

const AddPhoneNumberIconAndTextWrapper = styled.div`
  display: flex;
  flex: 1;
`;

const AddPhoneNumberIcon = styled.div`
  ${uFlexCenter};
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};
  border-radius: 4px;
  background-color: ${themeColors.colorPrimary};
  box-shadow: ${variables.shadow.boxShadow};
  margin-right: ${pxToRem(10)};
  flex-shrink: 0;
  padding: ${pxToRem(3)};
  color: ${themeColors.colorWhite};

  svg {
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};
    color: ${themeColors.colorWhite};
  }
`;

const AddPhoneNumberButtonText = styled.span``;

const BalanceCardIcon = styled.div`
  display: flex;
  justify-self: flex-end;
  flex-shrink: 0;
  transform: rotate(-90deg);
  color: ${themeColors.colorLabel};
`;

const AddPhoneNumberNote = styled.p`
  padding: 0px ${pxToRem(12.5)} ${pxToRem(12.5)} ${pxToRem(16.5)};
`;

const AddPhoneNumberWarningNote = styled.p`
  color: ${themeColors.colorError};
  display: flex;
  align-items: center;
  padding: 0px ${pxToRem(12.5)} ${pxToRem(12.5)} ${pxToRem(16.5)};

  svg {
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};
    color: ${themeColors.colorError};
    margin-right: ${pxToRem(10)};
  }
`;

const TwoFaPhoneNumber = ({
  handleBack,
  goStepForward,
  setTwoFaValues,
  twoFaValue,
  getTwoFaCode,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectAuthUser);
  const phoneNumbers = useSelector(selectUserPhoneNumbers);

  const [initialValues, setInitialValues] = useState({});
  const countries = useGetCountries();
  const selectedISOCodesListToIgnoreAreaCode = useSelector(
    selectPortalGatewayISOCodesListToIgnoreAreaCode,
  );
  const messageType = getTwoFaContent()?.MessageType;
  const [isDeletingPhoneNumber, setIsDeletingPhoneNumber] = useState(null);
  const isLoading =
    useSelector(
      selectIsLoadingByActionTypes([
        USER_PHONE_NUMBERS_LOADING,
        TWO_FA_CODE_LOADING,
        DELETE_USER_PHONE_NUMBER_LOADING,
      ]),
    ) || isDeletingPhoneNumber;
  const { UserUid, Username } = user;

  const [error, setError] = useState(null);

  const [isAddPhoneNumberModalOpen, setIsAddPhoneNumberModalOpen] = useState(
    false,
  );

  useEffect(() => {
    if (!isEmpty(user)) {
      dispatch(fetchUserPhoneNumbers({ userUid: UserUid }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (!isEmpty(phoneNumbers) && phoneNumbers.length === 1) {
      const phoneNumber = JSON.stringify(phoneNumbers[0]);

      if (phoneNumber) {
        setInitialValues({
          PhoneNumber: phoneNumber,
          Type: parseEnumType(twoFaMessageType, messageType || 1),
        });
      }
    } else {
      setInitialValues({
        PhoneNumber: JSON.stringify(phoneNumbers[0]),
        Type: parseEnumType(twoFaMessageType, messageType || 1),
      });
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [phoneNumbers]);

  const goBack = () => {
    handleBack();
    setTwoFaValues(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const onFormSubmitSuccess = (values) => {
    setTwoFaValues(values);
  };

  const twoFaCodeSubmit = (values, { setFieldError }) => {
    const { PhoneNumber, Type } = values;
    const { AuthenticationMethodType } = twoFaValue;
    const phoneNumberJsonParsed = JSON.parse(PhoneNumber.replace('+', ''));
    const phoneNumber = selectedPhoneNumberValidatorAndFormatter(
      phoneNumberJsonParsed,
      setFieldError,
      countries,
      selectedISOCodesListToIgnoreAreaCode,
    );

    const onSuccess = () => {
      onFormSubmitSuccess({
        Phone: { ...phoneNumber },
        IsFromSetup: true,
        MessageType: Type,
        AuthenticationMethodType,
      });
      goStepForward();
    };

    getTwoFaCode({
      userUid: UserUid,
      requestData: {
        Phone: { PhoneNumberUid: phoneNumber.PhoneNumberUid },
        MessageType: Type,
        AuthenticationMethodType,
        Username,
      },
      onSuccess,
      onError,
    });
  };

  return (
    <SectionLoader isLoading={isLoading}>
      <ModalBody>
        <ModalTitle>{t('twoFa.profileSettings.phone.title')}</ModalTitle>
        <Paragraph marginBottom={32}>
          {t('twoFa.profileSettings.phone.note')}
        </Paragraph>
        <Formik
          onSubmit={twoFaCodeSubmit}
          validationSchema={twoFaPhoneValidationSchema}
          initialValues={initialValues}
          enableReinitialize
        >
          {({ errors, values, setFieldValue }) => (
            <Form>
              <FormContainer>
                <TwoFaPhoneListFull
                  phoneNumbers={phoneNumbers}
                  values={values}
                  userUid={UserUid}
                  setFieldValue={setFieldValue}
                  countries={countries}
                  setIsDeletingPhoneNumber={setIsDeletingPhoneNumber}
                  errors={errors}
                />
                <AddPhoneNumberSection
                  onClick={() => setIsAddPhoneNumberModalOpen(true)}
                  disabled={phoneNumbers.length > 1}
                >
                  <AddPhoneNumberElement>
                    <AddPhoneNumberIconAndTextWrapper>
                      <AddPhoneNumberIcon>
                        <Plus />
                      </AddPhoneNumberIcon>
                      <AddPhoneNumberButtonText>
                        {t('twoFa.loginSetup.phone.phoneList.addNewPhone')}
                      </AddPhoneNumberButtonText>
                    </AddPhoneNumberIconAndTextWrapper>
                    <BalanceCardIcon>
                      <Caret />
                    </BalanceCardIcon>
                  </AddPhoneNumberElement>
                  <AddPhoneNumberNote>
                    {t('twoFa.loginSetup.phone.phoneList.addNewPhoneInfo')}
                  </AddPhoneNumberNote>
                  {phoneNumbers.length > 1 && (
                    <AddPhoneNumberWarningNote>
                      <Warning />
                      {t('twoFa.loginSetup.phone.phoneList.addNewPhoneWarning')}
                    </AddPhoneNumberWarningNote>
                  )}
                </AddPhoneNumberSection>

                <TwoFaPhoneAddPhoneModal
                  isOpen={isAddPhoneNumberModalOpen}
                  onClose={() => {
                    setIsAddPhoneNumberModalOpen(false);
                  }}
                />

                <TwoFaPhoneSelectRecieveType
                  setFieldValue={setFieldValue}
                  values={values}
                  errors={errors}
                />
                <SingleColumnList>
                  {error && (
                    <Paragraph isError marginTop={8}>
                      <strong>{error}</strong>
                    </Paragraph>
                  )}
                </SingleColumnList>
              </FormContainer>
              <ModalButtons
                isHorizontal
                marginTop
                secondaryButtonProps={{ onClick: goBack }}
                primaryButtonProps={{ type: 'submit' }}
              />
            </Form>
          )}
        </Formik>
      </ModalBody>
    </SectionLoader>
  );
};

TwoFaPhoneNumber.propTypes = {
  setTwoFaValues: PropTypes.func,
  handleBack: PropTypes.func,
  goStepForward: PropTypes.func,
  twoFaValue: PropTypes.shape({
    AuthenticationMethodType: PropTypes.string,
  }),
  getTwoFaCode: PropTypes.func,
};

export default TwoFaPhoneNumber;
