import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { isEmpty, startCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { getCurrentAccount } from '../../store/selectors/accountSelectors';
import { fetchPortfolioBalances } from '../../store/actions/portfolio/portfolioActions';
import { parseEnumType } from '../../util/helpers/enumMappers';
import ModalBody from '../Modal/ModalBody';
import Label from '../Notes/Label';
import SectionLoader from '../Loader/SectionLoader';
import Paragraph from '../Paragraph/Paragraph';
import ModalTitle from '../Modal/ModalTitle';
import ChoosePaymentMethod from '../BuyForStorage/ChoosePaymentMethod';
import { selectSettings } from '../../store/selectors/settingsSelectors';
import { fetchCreditCards } from '../../store/actions/creditCard/creditCardActions';
import ModalButtons from '../Modal/ModalButtons';
import { selectBalancesAvailable } from '../../store/selectors/portfolioBalancesSelectors';
import { productLocationTypes } from '../../util/enum/api/productTypes';
import InformationList from '../InformationList/InformationList';
import InformationListItem from '../InformationList/InformationListItem';
import { formatMoneyNumeral } from '../../util/helpers/numeralHelpers';
import { getQuotesForBuyDelivery } from '../../store/actions/orders/orderActions';
import paymentTypes from '../../util/enum/api/paymentTypes';
import themeColors from '../../assets/styles/themeColors';
import { pxToRem } from '../../assets/styles/helper';
import loadSignifydDeviceFingerprintScript from '../../signifydDeviceFingerprint';
import {
  retrieveFromSessionStorage,
  storeInSessionStorage,
} from '../../util/helpers/sessionStorageHelper';
import { SIGNIFYD_SESSION_ID } from '../../constants/sessionStorage';
import { generateSessionOrderId } from '../../util/helpers/sinigfyd';

const Error = styled.div`
  dispay: flex;
  color: ${themeColors.colorRed};
  font-size: ${pxToRem(16)};
  line-height: 1.35;
  font-weight: 500;
`;

const PricesWrapper = styled.div``;

const OrderForStoragePayment = ({
  handleNext,
  handleBack,
  wizardData,
  setWizardData,
  setWizardTitle,
  closeModal,
  handleCloseModalOnBuy,
  setPaymentMethods,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const account = useSelector(getCurrentAccount);
  const balance = useSelector(selectBalancesAvailable);
  const {
    orderType,
    orderValues,
    storageProduct,
    selectedVault,
    calculation,
    deliveryAddress,
    isDefaultPaymentMethod,
  } = wizardData;
  const settings = useSelector(selectSettings);
  const {
    // estimatedOrderTotal,
    // newPricePerUnit,
    // volumeDiscount,
    paymentMethodStepData,
  } = calculation;

  const paymentMethods = balance?.PaymentMethods;
  const [finalOrder, setFinalOrder] = useState({});
  const [nextClicked, setNextClicked] = useState(false);
  const [isLoadingGetQuotes, setIsLoadingGetQuotes] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [quoteID, setQuoteID] = useState('');
  const [expiryTime, setExpiryTime] = useState('');
  const [postPaidCalculation, setPostPaidCalculation] = useState({});
  const [updatedPaymentMethods, setUpdatedPaymentMethods] = useState(
    paymentMethods,
  );
  const availableForTrading = balance?.AvailableForTrading;

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();

  useEffect(() => {
    if (!selectedPaymentMethod && wizardData?.paymentMethod) {
      setSelectedPaymentMethod(wizardData.paymentMethod);
    }
    if (!selectedPaymentMethod && !wizardData?.paymentMethod) {
      setSelectedPaymentMethod(isDefaultPaymentMethod);
    }
  }, [selectedPaymentMethod, isDefaultPaymentMethod, wizardData.paymentMethod]);

  const isAccountBalanceDisabled =
    availableForTrading <
    paymentMethodStepData?.DataWithoutCCFee?.TotalClientExecutionPrice;

  const newPricePerUnit =
    selectedPaymentMethod?.PaymentMethodType === 3
      ? paymentMethodStepData?.DataWithCCFee?.ClientPricePerUnit
      : paymentMethodStepData?.DataWithoutCCFee?.ClientPricePerUnit;

  const clientExecutionPrice =
    selectedPaymentMethod?.PaymentMethodType === 3
      ? paymentMethodStepData?.DataWithCCFee?.TotalClientExecutionPrice
      : paymentMethodStepData?.DataWithoutCCFee?.TotalClientExecutionPrice;

  const volumeDiscount =
    selectedPaymentMethod?.PaymentMethodType === 3
      ? paymentMethodStepData?.DataWithCCFee?.VolumeDiscount
      : paymentMethodStepData?.DataWithoutCCFee?.VolumeDiscount;

  const salesTax =
    selectedPaymentMethod?.PaymentMethodType === 3
      ? paymentMethodStepData?.DataWithCCFee?.SalesTax
      : paymentMethodStepData?.DataWithoutCCFee?.SalesTax;

  const deliveryFee =
    selectedPaymentMethod?.PaymentMethodType === 3
      ? paymentMethodStepData?.DataWithCCFee?.DeliveryFee
      : paymentMethodStepData?.DataWithoutCCFee?.DeliveryFee;

  const strikedTotal =
    paymentMethodStepData?.DataWithoutCCFee?.TotalClientExecutionPriceWithFee +
    paymentMethodStepData?.DataWithCCFee?.SalesTax +
    paymentMethodStepData?.DataWithCCFee?.DeliveryFee;

  const quantityExecuted =
    selectedPaymentMethod?.PaymentMethodType === 3
      ? paymentMethodStepData?.DataWithCCFee?.QuantityExecuted
      : paymentMethodStepData?.DataWithoutCCFee?.QuantityExecuted;

  const totalAmountWithCreditCard =
    paymentMethodStepData?.DataWithCCFee?.TotalClientExecutionPrice +
    paymentMethodStepData?.DataWithCCFee?.SalesTax +
    paymentMethodStepData?.DataWithCCFee?.DeliveryFee;

  const totalAmountWithoutCreditCard =
    paymentMethodStepData?.DataWithoutCCFee?.TotalClientExecutionPrice +
    paymentMethodStepData?.DataWithoutCCFee?.SalesTax +
    paymentMethodStepData?.DataWithoutCCFee?.DeliveryFee;

  const priceWithDiscount = (totalPrice, strikedTotal) => {
    if (selectedPaymentMethod?.PaymentMethodType !== 3) {
      return (
        <PricesWrapper>
          <Paragraph bold color={themeColors.colorSuccess}>
            {formatMoneyNumeral(totalPrice)}
          </Paragraph>
          <Paragraph bold textDecoration="line-through" marginLeft={8}>
            {formatMoneyNumeral(strikedTotal)}
          </Paragraph>
        </PricesWrapper>
      );
    }
    return <Paragraph bold>{formatMoneyNumeral(totalPrice)}</Paragraph>;
  };

  useEffect(() => {
    const sessionId = retrieveFromSessionStorage(SIGNIFYD_SESSION_ID);
    const existingScript = document.getElementById('sig-api');
    if (!sessionId) {
      if (existingScript) {
        existingScript.dataset.orderSessionId = generateSessionOrderId();
        storeInSessionStorage(
          SIGNIFYD_SESSION_ID,
          existingScript.dataset.orderSessionId,
        );
        return;
      }
      loadSignifydDeviceFingerprintScript();
      const script = document.getElementById('sig-api');
      storeInSessionStorage(SIGNIFYD_SESSION_ID, script.dataset.orderSessionId);
    }
  }, []);

  const onError = (error) => {
    const errorCode = error?.response?.data?.Errors[0]?.Code;

    if (
      errorCode === 'BuyMinimumAmountIsNotReached' ||
      errorCode === 'OrderMinimumValidationError'
    ) {
      setErrorMessage(
        t('apiErrors.BuyForDeliveryAmountMinimumIsNotReached', {
          orderMinimum: `$${selectedVault?.BuyMinimumAmount}`,
        }),
      );
    } else {
      setErrorMessage(t(`apiErrors.${errorCode}`));
    }
  };

  const onHandleGetQuotesSuccess = (getQuoteResult) => {
    const {
      QuoteUid,
      QuoteStatusItem: {
        QuantityExecuted,
        ClientPricePerUnit,
        TotalClientExecutionPrice,
        DeliveryFee,
        SalesTax,
        VolumeDiscount,
        PaymentTypeDiscount,
        PaymentMethodStepData,
      },
      ExpiresOnUtc,
    } = getQuoteResult;

    const postPaidCalculation = {
      estimatedOrderTotal: TotalClientExecutionPrice,
      newPricePerUnit: ClientPricePerUnit,
      volumeDiscount: VolumeDiscount,
      quantity: QuantityExecuted,
      deliveryFees: DeliveryFee,
      salesTax: SalesTax,
      paymentTypeDiscount: PaymentTypeDiscount,
      paymentMethodStepData: PaymentMethodStepData,
    };

    setQuoteID(QuoteUid);
    setExpiryTime(ExpiresOnUtc);
    setPostPaidCalculation(postPaidCalculation);
    // setDeliveryFees(DeliveryFee);
    // setSalesTax(SalesTax);
    setNextClicked(true);
  };

  useEffect(() => {
    if (!isEmpty(postPaidCalculation) && !isEmpty(orderValues)) {
      setWizardData({
        ...wizardData,
        postPaidCalculation,
        quoteID,
        expiryTime,
        orderValues,
        orderType,
        deliveryFee,
        salesTax,
      });
    }
  }, [
    orderValues,
    postPaidCalculation,
    setWizardData,
    wizardData,
    quoteID,
    expiryTime,
    orderType,
    deliveryFee,
    salesTax,
  ]);
  useEffect(() => {
    if (!isEmpty(wizardData?.orderValues) && nextClicked) {
      setNextClicked(false);
      handleNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardData.orderValues, nextClicked]);

  const estimatedTotal = clientExecutionPrice + salesTax + deliveryFee;

  useEffect(() => {
    const updatedPaymentMethodsList = paymentMethods?.map((paymentMethod) => {
      if (!paymentMethod.PaymentMethodUid) {
        return { ...paymentMethod, PaymentMethodUid: '1' };
      }
      return paymentMethod;
    });
    setUpdatedPaymentMethods(updatedPaymentMethodsList);
  }, [paymentMethods]);

  useEffect(() => {
    if (!selectedVault || !orderValues) {
      return;
    }

    if (orderType === 'Cash') {
      setFinalOrder({
        pricePerUnit: newPricePerUnit,
        quantity: quantityExecuted,
        estimatedSubTotal: clientExecutionPrice,
        volumeDiscount: volumeDiscount ?? 0,
        salesTax,
        deliveryFee,
        estimatedTotal,
      });
    }

    if (orderType === 'Quantity') {
      setFinalOrder({
        pricePerUnit: newPricePerUnit,
        quantity: quantityExecuted,
        estimatedSubTotal: clientExecutionPrice,
        volumeDiscount: volumeDiscount ?? 0,
        salesTax,
        deliveryFee,
        estimatedTotal,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderValues, orderType, selectedPaymentMethod]);

  useEffect(() => {
    if (account.AccountUid) {
      dispatch(fetchPortfolioBalances({ accountUid: account.AccountUid }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setWizardTitle(t('buyWizard.postPaid.deliveryModalTitle'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (account.AccountUid && settings?.IsCreditCardActive) {
      dispatch(fetchCreditCards({ accountUid: account.AccountUid }));
    }
  }, [dispatch, account, settings]);

  const submitForm = (values) => {
    const paymentMethod = updatedPaymentMethods?.find(
      (method) => method.PaymentMethodUid === values.paymentMethodUid,
    );

    setWizardData({
      ...wizardData,
      paymentMethod,
    });
    // orderGtmHelper(
    //   gtmDialogScreenView,
    //   gtmCustomEvent,
    //   values,
    //   wizardData,
    //   activeType,
    //   unitPrice,
    //   currentAccount,
    //   false,
    // );

    const paymentTypeUid =
      paymentMethod?.PaymentMethodType !== 1
        ? paymentMethod?.PaymentMethodUid
        : '00000000-0000-0000-0000-000000000000';

    const payload = {
      getQuotesPayload: {
        accountUid: account.AccountUid,
        symbolCode: selectedVault?.SymbolCode,
        quantityOrAmount:
          wizardData?.orderValues?.CashAmount ||
          wizardData?.orderValues?.QuantityAmount,
        sideType: 'Buy',
        unitType: orderType,
        paymentType: paymentTypes[paymentMethod?.PaymentMethodType],
        addressUid: deliveryAddress?.AddressUid,
      },
      paymentTypeUid,
      addPaymentMethodData: true,
      isLoading: setIsLoadingGetQuotes,
      onSuccess: onHandleGetQuotesSuccess,
      onError,
    };
    setIsLoadingGetQuotes(true);
    dispatch(
      getQuotesForBuyDelivery({
        ...payload,
      }),
    );
  };

  if (isEmpty(wizardData)) {
    return null;
  }

  const orderTotalsData = [
    {
      label: t('buyWizard.postPaid.pricePerUnit'),
      value: formatMoneyNumeral(finalOrder.pricePerUnit),
      isBold: true,
    },
    {
      label: t('buyWizard.postPaid.quantity'),
      value: finalOrder.quantity,
    },
    {
      label: t('buyWizard.postPaid.subTotal'),
      value: formatMoneyNumeral(finalOrder?.estimatedSubTotal),
      isBold: true,
    },
    {
      label: t('buyWizard.postPaid.shippingAndHandling'),
      value: formatMoneyNumeral(finalOrder?.deliveryFee),
    },
    {
      label: t('buyWizard.postPaid.salesTax'),
      value: formatMoneyNumeral(finalOrder?.salesTax),
    },
    finalOrder?.volumeDiscount > 0
      ? {
          value: `(${formatMoneyNumeral(finalOrder.volumeDiscount)})`,
          label: t(
            'buyWizard.buyForDelivery.reviewOrder.orderTotal.volumeDiscount',
          ),
          isBold: true,
        }
      : { value: '', label: '' },
    {
      label: t('buyWizard.postPaid.total'),
      // value: formatMoneyNumeral(finalOrder.estimatedTotal),
      value: priceWithDiscount(finalOrder?.estimatedTotal, strikedTotal),
      isBold: true,
    },
  ];

  // As BE returns null for Balance payment type uid, we changed to be 1 so it has some value for checked radio button
  const defaultPaymentUid =
    wizardData?.isDefaultPaymentMethod?.PaymentMethodType === 1
      ? '1'
      : wizardData?.isDefaultPaymentMethod?.PaymentMethodUid;

  return (
    <SectionLoader isLoading={isLoadingGetQuotes}>
      <ModalBody>
        <ModalTitle marginBottom={4}>
          {`${storageProduct?.ProductCaption} ${startCase(
            parseEnumType(productLocationTypes, selectedVault?.LocationType),
          )}`}
        </ModalTitle>
        <Label marginBottom={16} text={t('buyWizard.postPaid.paymentMethod')} />

        <InformationList withBackground marginTop={12} marginBottom={24}>
          {orderTotalsData.map(({ isBold, value, label, isError }) => (
            <InformationListItem
              fullWidth
              isBold={isBold}
              value={value}
              label={label}
              isError={isError}
            />
          ))}
        </InformationList>
        <Paragraph fontSize={16} marginBottom={4}>
          <strong>{t('buyWizard.postPaid.selectPaymentMethod')}</strong>
        </Paragraph>
        {(!isEmpty(updatedPaymentMethods) || !updatedPaymentMethods) && (
          <Formik
            initialValues={{
              paymentMethodUid: wizardData?.paymentMethod
                ? wizardData?.paymentMethod?.PaymentMethodUid
                : defaultPaymentUid,
            }}
            onSubmit={submitForm}
            enableReinitialize
          >
            {({ setFieldValue, values }) => (
              <Form>
                <ChoosePaymentMethod
                  paymentMethods={updatedPaymentMethods}
                  values={values}
                  setWizardData={setWizardData}
                  wizardData={wizardData}
                  setPaymentMethods={setPaymentMethods}
                  setFieldValue={setFieldValue}
                  closeModal={closeModal}
                  handleCloseModalOnBuy={handleCloseModalOnBuy}
                  setErrorMessage={setErrorMessage}
                  errorMessage={errorMessage}
                  setSelectedPaymentMethod={setSelectedPaymentMethod}
                  clientExecutionPrice={estimatedTotal}
                  isAccountBalanceDisabled={isAccountBalanceDisabled}
                  totalAmountWithCreditCard={totalAmountWithCreditCard}
                  totalAmountWithoutCreditCard={totalAmountWithoutCreditCard}
                />
                {errorMessage && <Error>{errorMessage}</Error>}

                <ModalButtons
                  marginTop
                  isHorizontal
                  secondaryButtonProps={{
                    onClick: handleBack,
                    label: t('common.back'),
                  }}
                  primaryButtonProps={{
                    type: 'submit',
                    disabled: !values.paymentMethodUid,
                  }}
                />
              </Form>
            )}
          </Formik>
        )}
      </ModalBody>
    </SectionLoader>
  );
};

OrderForStoragePayment.propTypes = {
  wizardData: PropTypes.shape({
    storageProduct: PropTypes.shape({
      ProductCaption: PropTypes.string,
    }),
    selectedVault: PropTypes.shape({
      LocationType: PropTypes.number,
      PricePerUnit: PropTypes.number,
      IsCashOrderOnly: PropTypes.bool,
      SymbolCode: PropTypes.string,
      BuyMinimumAmount: PropTypes.number,
    }),
    deliveryAddress: PropTypes.shape({
      AddressUid: PropTypes.string,
      StateRegion: PropTypes.string,
      CountryIso3Code: PropTypes.string,
    }),
    isSegregated: PropTypes.bool,
    cashBuffer: PropTypes.number,
    calculation: PropTypes.shape({
      estimatedOrderTotal: PropTypes.number,
      newPricePerUnit: PropTypes.number,
      estimatedSubTotal: PropTypes.number,
      salesTax: PropTypes.number,
      deliveryFees: PropTypes.number,
      volumeDiscount: PropTypes.number,
      paymentMethodStepData: PropTypes.shape({
        DataWithCCFee: PropTypes.shape({
          ClientPricePerUnit: PropTypes.number,
          TotalClientExecutionPrice: PropTypes.number,
          VolumeDiscount: PropTypes.number,
          SalesTax: PropTypes.number,
          DeliveryFee: PropTypes.number,
          QuantityExecuted: PropTypes.number,
        }),
        DataWithoutCCFee: PropTypes.shape({
          ClientPricePerUnit: PropTypes.number,
          TotalClientExecutionPrice: PropTypes.number,
          VolumeDiscount: PropTypes.number,
          SalesTax: PropTypes.number,
          DeliveryFee: PropTypes.number,
          QuantityExecuted: PropTypes.number,
          TotalClientExecutionPriceWithFee: PropTypes.number,
        }),
      }),
    }),
    orderValues: PropTypes.shape({
      CashAmount: PropTypes.number,
      QuantityAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    paymentMethod: PropTypes.shape({
      PaymentMethodUid: PropTypes.number,
    }),
    isDefaultPaymentMethod: PropTypes.shape({
      PaymentMethodUid: PropTypes.number,
      PaymentMethodType: PropTypes.number,
    }),
    orderType: PropTypes.string,
    activeType: PropTypes.string,
  }),
  setWizardTitle: PropTypes.func,
  setWizardData: PropTypes.func,
  handleBack: PropTypes.bool,
  handleNext: PropTypes.bool,
  closeModal: PropTypes.func,
  handleCloseModalOnBuy: PropTypes.func,
  setPaymentMethods: PropTypes.func,
};

export default OrderForStoragePayment;
