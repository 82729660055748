import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  formatPhoneNumber,
  isPhoneSelected,
} from '../../../util/helpers/phoneNumberHelpers';
import {
  deleteUserPhoneNumber,
  fetchUserPhoneNumbers,
} from '../../../store/actions/user/userActions';
import { selectPortalGatewayISOCodesListToIgnoreAreaCode } from '../../../store/selectors/settingsSelectors';
import TypeList from '../../../components/TypeList/TypeList';
import Paragraph from '../../../components/Paragraph/Paragraph';
import Label from '../../../components/Notes/Label';
import SingleColumnList from '../../../components/TwoColumnList/SingleColumnList';
import RadioBox from '../../../components/InputFields/RadioBox';

const TwoFaPhoneListFull = ({
  values,
  phoneNumbers,
  userUid,
  countries,
  setIsDeletingPhoneNumber,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedISOCodesListToIgnoreAreaCode = useSelector(
    selectPortalGatewayISOCodesListToIgnoreAreaCode,
  );
  const [errorMessage, setErrorMessage] = useState(null);
  const onFetchSuccess = (phoneNumbers) => {
    if (phoneNumbers.length === 1) {
      const phoneNumber = JSON.stringify(phoneNumbers[0]);
      setFieldValue('PhoneNumber', phoneNumber);
    }
  };

  const onDeleteSuccess = () => {
    dispatch(fetchUserPhoneNumbers({ userUid, onFetchSuccess }));
  };

  const activateDeletingPhoneNumberLoader = (state) => {
    setIsDeletingPhoneNumber(state);
  };

  const deletePhoneNumber = (phoneNumber) => {
    if (!isEmpty(phoneNumber) && userUid) {
      dispatch(
        deleteUserPhoneNumber({
          data: {
            userUid,
            phoneUid: phoneNumber.PhoneNumberUid,
          },
          onDeleteSuccess,
          activateDeletingPhoneNumberLoader,
          setErrorMessage,
        }),
      );
    }
  };

  return (
    <SingleColumnList>
      <Label
        text={t('twoFa.loginSetup.phone.phoneList.label')}
        marginBottom={8}
      />

      <TypeList>
        {phoneNumbers.map((phoneNumber) => {
          const formatedPhoneNumber = formatPhoneNumber(
            phoneNumber,
            countries,
            selectedISOCodesListToIgnoreAreaCode,
          );
          const stringifiedPhoneNumber = JSON.stringify(phoneNumber);

          return (
            <Field
              component={RadioBox}
              type="radio"
              name="PhoneNumber"
              value={stringifiedPhoneNumber}
              onChange={() => {}}
              selected={isPhoneSelected(values, stringifiedPhoneNumber)}
              key={formatedPhoneNumber}
              label={formatedPhoneNumber}
              cta={phoneNumbers.length > 1 ? t('common.delete') : ''}
              onCtaClick={() =>
                phoneNumbers.length > 1 ? deletePhoneNumber(phoneNumber) : {}
              }
              disabledOnClick={phoneNumbers.length < 2}
            />
          );
        })}
      </TypeList>
      {errorMessage && <Paragraph isError>{errorMessage}</Paragraph>}
    </SingleColumnList>
  );
};

TwoFaPhoneListFull.propTypes = {
  values: PropTypes.shape({
    PhoneNumber: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  }),
  phoneNumbers: PropTypes.arrayOf(PropTypes.shape({})),
  errors: PropTypes.shape({
    PhoneNumber: PropTypes.shape({}),
  }),
  userUid: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.shape({})),
  setIsDeletingPhoneNumber: PropTypes.func,
  setFieldValue: PropTypes.func,
};

export default TwoFaPhoneListFull;
