import React from 'react';
import PropTypes from 'prop-types';
import StepByStep from '../../StepByStep';
import TwoFAQrCode from './TwoFAQrCode';

const twoFAGoogle = [TwoFAQrCode];

const TwoFAGoogleWizard = ({
  handleNext,
  handleBack,
  setTwoFaValues,
  twoFaValue,
  setIsSuccessful,
  setErrorMessage,
}) => (
  <StepByStep sessionScopeStep="2fa-google-wizard">
    {({ goStepBack, goStepForward }) =>
      twoFAGoogle.map((modal) =>
        React.createElement(modal, {
          handleBack,
          goStepForward,
          goStepBack,
          setTwoFaValues,
          twoFaValue,
          handleNext,
          setIsSuccessful,
          setErrorMessage,
        }),
      )
    }
  </StepByStep>
);

TwoFAGoogleWizard.propTypes = {
  twoFaValue: PropTypes.shape({}),
  setTwoFaValues: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  setIsSuccessful: PropTypes.func,
  setErrorMessage: PropTypes.func,
};

export default TwoFAGoogleWizard;
