import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Paragraph from '../Paragraph/Paragraph';
import Anchor from '../Anchor/Anchor';
import { getIraSettings } from '../../store/selectors/iraSettingsSelectors';

const AddressIraContact = () => {
  const { t } = useTranslation();
  const iraSettings = useSelector(getIraSettings);

  return (
    <>
      <Paragraph marginTop={24}>
        {t('account.addresses.iraDescriptionSecond')}
      </Paragraph>
      <Paragraph>
        {t('account.addresses.email')}{' '}
        <Anchor
          type="email"
          value={iraSettings?.IRATrusteeDetails?.ContactEmail}
        />
      </Paragraph>
      <Paragraph>
        {t('account.addresses.phone')}{' '}
        <Anchor
          type="telephone"
          value={iraSettings?.IRATrusteeDetails?.ContactPhone}
        />
      </Paragraph>
    </>
  );
};

AddressIraContact.propTypes = {};

export default AddressIraContact;
