import {
  createErrorType,
  createFetchType,
  createSuccessType,
  createLoadingType,
} from '../actionHelpers';

export const SETTINGS_FETCH = createFetchType('SETTINGS');
export const SETTINGS_SUCCESS = createSuccessType('SETTINGS');
export const SETTINGS_ERROR = createErrorType('SETTINGS');

export const SETTINGS_REGISTRATION_FETCH = createFetchType(
  'SETTINGS_REGISTRATION',
);
export const SETTINGS_REGISTRATION_SUCCESS = createSuccessType(
  'SETTINGS_REGISTRATION',
);
export const SETTINGS_REGISTRATIONS_ERROR = createErrorType(
  'SETTINGS_REGISTRATION',
);
export const SETTINGS_REGISTRATIONS_LOADING = createLoadingType(
  'SETTINGS_REGISTRATION',
);
