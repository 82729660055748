import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import Button from '../Button/Button';
import {
  getWizardContent,
  setWizardContent,
} from '../../util/helpers/wizardHelpers';
import { uFlexColumn } from '../../assets/styles/utility';
import { pxToRem } from '../../assets/styles/helper';

export const StepContainer = styled.div`
  ${uFlexColumn};
  flex-grow: 1;
`;

const StepActionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${pxToRem(23)};
  margin-top: ${pxToRem(48)};

  button {
    flex-grow: 1;
  }
`;

const StepByStep = ({
  children,
  onConfirm,
  sessionScopeStep,
  saveStepDisabled,
}) => {
  const childrenArray = React.Children.toArray(children);
  const [currentStep, setCurrentStep] = useState(
    !saveStepDisabled && getWizardContent(sessionScopeStep)
      ? Number(getWizardContent(sessionScopeStep))
      : 0,
  );
  const { t } = useTranslation();

  const isLastStep = () => currentStep === children.length - 1;
  const canGoNext = () => currentStep < children.length - 1;
  const canGoBack = () => currentStep > 0;

  const goStepBack = () => {
    if (canGoBack) {
      if (!saveStepDisabled) {
        setWizardContent(sessionScopeStep, currentStep - 1);
      }
      setCurrentStep(currentStep - 1);
    }
  };

  const skipPreviousStep = () => {
    if (canGoBack) {
      if (!saveStepDisabled) {
        setWizardContent(sessionScopeStep, currentStep - 2);
      }
      setCurrentStep(currentStep - 2);
    }
  };

  const goStepForward = () => {
    if (canGoNext) {
      if (!saveStepDisabled) {
        setWizardContent(sessionScopeStep, currentStep + 1);
      }
      setCurrentStep(currentStep + 1);
    }
  };

  const goTwoStepsForward = () => {
    if (canGoNext) {
      if (!saveStepDisabled) {
        setWizardContent(sessionScopeStep, currentStep + 2);
      }
      setCurrentStep(currentStep + 2);
    }
  };

  useEffect(() => {
    const removeCloseListener = addCloseListener();

    if (!saveStepDisabled && !getWizardContent(sessionScopeStep)) {
      setWizardContent(sessionScopeStep, 0);
    }

    return () => removeCloseListener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (typeof children === 'function') {
    return (
      <StepContainer>
        {
          children({
            goStepBack,
            skipPreviousStep,
            goStepForward,
            goTwoStepsForward,
            onConfirm,
            isLastStep,
          })[`${currentStep}`]
        }
      </StepContainer>
    );
  }

  return childrenArray.length ? (
    <StepContainer>
      {childrenArray[`${currentStep}`]}
      <StepActionsContainer>
        <Button
          variant="primaryOutlined"
          type="button"
          onClick={goStepBack}
          disabled={!canGoBack()}
        >
          {t('common.back')}
        </Button>
        {!isLastStep() ? (
          <Button
            variant="primary"
            type="button"
            onClick={goStepForward}
            disabled={!canGoNext()}
          >
            {t('common.next')}
          </Button>
        ) : (
          <Button variant="primary" type="button" onClick={onConfirm}>
            {t('common.confirm')}
          </Button>
        )}
      </StepActionsContainer>
    </StepContainer>
  ) : null;
};

function addCloseListener() {
  function closeCallback(ev) {
    ev.preventDefault();
    ev.returnValue = true; // eslint-disable-line
    return ev.returnValue;
  }

  window.addEventListener('beforeunload', closeCallback);
  return () => window.removeEventListener('beforeunload', closeCallback);
}

StepByStep.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onConfirm: PropTypes.func,
  sessionScopeStep: PropTypes.string,
  saveStepDisabled: PropTypes.bool,
};

export default StepByStep;
