import * as Yup from 'yup';
import i18next from '../i18n';

export const sellOrderValidationSchema = (
  isFractionalProduct,
  unitsAvailableForSale,
  isInstaVaultProduct,
) => {
  if (isFractionalProduct && !isInstaVaultProduct) {
    return Yup.object().shape({
      quantity: Yup.number()
        .required(i18next.t('validationSchema.quantity.quantityRequired'))
        .min(0.0001, i18next.t('validationSchema.quantity.minDecimalNumber'))
        .max(
          unitsAvailableForSale,
          i18next.t('validationSchema.general.insufficientUnits'),
        ),
    });
  }

  return Yup.object().shape({
    quantity: Yup.number()
      .integer(i18next.t('validationSchema.quantity.wholeNumber'))
      .required(i18next.t('validationSchema.quantity.quantityRequired'))
      .min(1, i18next.t('validationSchema.quantity.minRoundNumber'))
      .max(
        unitsAvailableForSale,
        i18next.t('validationSchema.general.insufficientUnits'),
      ),
  });
};
