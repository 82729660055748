import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import TypeCard from '../../../TypeCard/TypeCard';

import { buttonizeDiv } from '../../../../util/buttonizeDiv';
import {
  TYPE_DISTRIBUTION,
  TYPE_TRANSFER_OUT,
} from '../../../../util/constants';
import useGtmHook from '../../../../util/hooks/useGtmHook';
import ModalBody from '../../../Modal/ModalBody';
import TypeList from '../../../TypeList/TypeList';
import { FUND_WITHDRAW_START } from '../../../../constants/gtmEvents';

const IraWithdrawType = ({ onTypeSelect }) => {
  const { t } = useTranslation();
  const { gtmDialogScreenView, fundGtmEvent } = useGtmHook();
  return (
    <ModalBody>
      <TypeList>
        <TypeCard
          {...buttonizeDiv(() => {
            onTypeSelect(TYPE_DISTRIBUTION);
            gtmDialogScreenView({
              title: `Withdraw Funds - ${TYPE_DISTRIBUTION}`,
            });
            fundGtmEvent(FUND_WITHDRAW_START, {
              fund: {
                method: TYPE_DISTRIBUTION,
              },
            });
          })}
          title={t('entrust.distribution')}
          description={t('IRAWithdraw.iraDistributionDescription')}
        />
        <TypeCard
          {...buttonizeDiv(() => {
            onTypeSelect(TYPE_TRANSFER_OUT);
            gtmDialogScreenView({
              title: `Withdraw Funds - ${TYPE_TRANSFER_OUT}`,
            });
            fundGtmEvent(FUND_WITHDRAW_START, {
              fund: {
                method: TYPE_TRANSFER_OUT,
              },
            });
          })}
          title={t('entrust.transferOut')}
          description={t('IRAWithdraw.iraTransferOutDescription')}
        />
      </TypeList>
    </ModalBody>
  );
};

IraWithdrawType.propTypes = {
  onTypeSelect: PropTypes.func,
};

export default IraWithdrawType;
