import styled from 'styled-components';
import { mediaBelow, pxToRem } from '../../assets/styles/helper';
import { uFlexColumn } from '../../assets/styles/utility';
import { variables } from '../../assets/styles/variables';

const TradingFilter = styled.div`
  ${uFlexColumn};
  width: ${pxToRem(264)};
  flex-shrink: 0;
  margin-right: ${pxToRem(96)};
  position: relative;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    display: none;
  }
`;

export default TradingFilter;
