import i18next from 'i18next';
import * as Yup from 'yup';

export const newAchValidationSchema = (bankDetailsCanRender) =>
  bankDetailsCanRender
    ? Yup.object().shape({
        nameOnAccount: Yup.string().required(
          i18next.t('validationSchema.bankAccount.bankAccountNameRequired'),
        ),
        routingNumber: Yup.string().required(
          i18next.t(
            'validationSchema.bankAccount.bankAccountRoutingNumberRequired',
          ),
        ),
        accountNumber: Yup.number().required(
          i18next.t('validationSchema.bankAccount.bankAccountNumberRequired'),
        ),
        accountType: Yup.object().shape({
          value: Yup.string().required(
            i18next.t('validationSchema.bankAccount.bankAccountTypeRequired'),
          ),
        }),
      })
    : Yup.object().shape({
        nameOnAccount: Yup.string().required(
          i18next.t('validationSchema.bankAccount.bankAccountNameRequired'),
        ),
        routingNumber: Yup.string()
          .matches(
            /^\d+$/,
            i18next.t(
              `validationSchema.bankAccount.bankAccountRoutingNumberLength`,
            ),
          )
          .min(
            9,
            i18next.t(
              `validationSchema.bankAccount.bankAccountRoutingNumberLength`,
            ),
          )
          .max(
            9,
            i18next.t(
              `validationSchema.bankAccount.bankAccountRoutingNumberLength`,
            ),
          )
          .required(
            i18next.t(
              'validationSchema.bankAccount.bankAccountRoutingNumberRequired',
            ),
          ),
        accountNumber: Yup.number().required(
          i18next.t('validationSchema.bankAccount.bankAccountNumberRequired'),
        ),
        accountType: Yup.object().shape({
          value: Yup.string().required(
            i18next.t('validationSchema.bankAccount.bankAccountTypeRequired'),
          ),
        }),
      });
