import {
  createErrorType,
  createFetchType,
  createSetType,
  createSubmitType,
  createSuccessType,
  createUpdateType,
  createDeleteType,
  createLoadingType,
  createClearType,
} from '../actionHelpers';

// get bank accounts
export const BANK_ACCOUNT_FETCH = createFetchType('BANK_ACCOUNT');
export const BANK_ACCOUNT_SUCCESS = createSuccessType('BANK_ACCOUNT');
export const BANK_ACCOUNT_ERROR = createErrorType('BANK_ACCOUNT');
export const BANK_ACCOUNT_LOADING = createLoadingType('BANK_ACCOUNT');

// get single bank account
const SINGLE_BA_SCOPE = 'SINGLE_BA';
export const FETCH_SINGLE_BA = createFetchType(SINGLE_BA_SCOPE);
export const FETCH_SINGLE_BA_SUCCESS = createSuccessType(SINGLE_BA_SCOPE);
export const FETCH_SINGLE_BA_ERROR = createErrorType(SINGLE_BA_SCOPE);
export const FETCH_SINGLE_BA_LOADING = createLoadingType(SINGLE_BA_SCOPE);

// delete bank account
const BANK_ACCOUNT_DELETE_SCOPE = 'BANK_ACCOUNT_DELETE';
export const BANK_ACCOUNT_DELETE = createDeleteType(BANK_ACCOUNT_DELETE_SCOPE);
export const BANK_ACCOUNT_DELETE_SUCCESS = createSuccessType(
  BANK_ACCOUNT_DELETE_SCOPE,
);
export const BANK_ACCOUNT_DELETE_ERROR = createErrorType(
  BANK_ACCOUNT_DELETE_SCOPE,
);
export const BANK_ACCOUNT_DELETE_LOADING = createLoadingType(
  BANK_ACCOUNT_DELETE_SCOPE,
);
export const CLEAR_BANK_ACCOUNT_DELETE_STATE = createClearType(
  'BANK_ACCOUNT_DELETE_STATE',
);

// get bank details by routing number
const BA_BY_ROUTING_NUM_SCOPE = 'BA_BY_ROUTING_NUM';

export const BA_BY_ROUTING_NUM_FETCH = createFetchType(BA_BY_ROUTING_NUM_SCOPE);
export const BA_BY_ROUTING_NUM_SUCCESS = createSuccessType(
  BA_BY_ROUTING_NUM_SCOPE,
);
export const BA_BY_ROUTING_NUM_ERROR = createErrorType(BA_BY_ROUTING_NUM_SCOPE);
export const BA_BY_ROUTING_NUM_LOADING = createLoadingType(
  BA_BY_ROUTING_NUM_SCOPE,
);
// clear bank deails by routing number
export const BA_BY_ROUTING_NUM_CLEAR = createClearType(BA_BY_ROUTING_NUM_SCOPE);

// update new wire account data in redux
const NEW_WIRE_ACC_DETAILS_SCOPE = 'NEW_WIRE_ACC_DETAILS';
export const NEW_WIRE_ACC_DETAILS_SET = createSetType(
  NEW_WIRE_ACC_DETAILS_SCOPE,
);
export const NEW_WIRE_ACC_DETAILS_UPDATE = createUpdateType(
  NEW_WIRE_ACC_DETAILS_SCOPE,
);

// submit new wire account
const SUBMIT_NEW_WIRE_ACC_SCOPE = 'SUBMIT_NEW_WIRE_ACC';
export const SUBMIT_NEW_WIRE_ACC = createSubmitType(SUBMIT_NEW_WIRE_ACC_SCOPE);
export const SUBMIT_NEW_WIRE_ACC_ERROR = createErrorType(
  SUBMIT_NEW_WIRE_ACC_SCOPE,
);
export const SUBMIT_NEW_WIRE_ACC_SUCCESS = createSuccessType(
  SUBMIT_NEW_WIRE_ACC_SCOPE,
);

// submit new ach account
const SUBMIT_NEW_ACH_SCOPE = 'SUBMIT_NEW_ACH';
export const SUBMIT_NEW_ACH = createSubmitType(SUBMIT_NEW_ACH_SCOPE);
export const SUBMIT_NEW_ACH_SUCCESS = createSuccessType(SUBMIT_NEW_ACH_SCOPE);
export const SUBMIT_NEW_ACH_ERROR = createErrorType(SUBMIT_NEW_ACH_SCOPE);
export const SUBMIT_NEW_ACH_LOADING = createLoadingType(SUBMIT_NEW_ACH_SCOPE);

// verify bank account
const VERIFY_BANK_ACC_SCOPE = 'VERIFY_BANK_ACCOUNT';
export const VERIFY_BANK_ACCOUNT = createUpdateType(VERIFY_BANK_ACC_SCOPE);
export const VERIFY_BANK_ACCOUNT_LOADING = createLoadingType(
  VERIFY_BANK_ACC_SCOPE,
);

// Get List of bank accounts by type
const BANK_ACCOUNTS_BY_TYPE_SCOPE = 'BANK_ACCOUNTS_BY_TYPE';
export const BANK_ACCOUNTS_BY_TYPE_FETCH = createFetchType(
  BANK_ACCOUNTS_BY_TYPE_SCOPE,
);
export const BANK_ACCOUNTS_BY_TYPE_FETCH_SUCCESS = createSuccessType(
  BANK_ACCOUNTS_BY_TYPE_SCOPE,
);
export const BANK_ACCOUNTS_BY_TYPE_FETCH_ERROR = createErrorType(
  BANK_ACCOUNTS_BY_TYPE_SCOPE,
);
export const BANK_ACCOUNTS_BY_TYPE_FETCH_LOADING = createLoadingType(
  BANK_ACCOUNTS_BY_TYPE_SCOPE,
);
// get bank details by routing number registration
const BA_BY_ROUTING_NUM_REGISTRATION_SCOPE = 'BA_BY_ROUTING_NUM_REGISTRATION';

export const BA_BY_ROUTING_NUM_REGISTRATION_FETCH = createFetchType(
  BA_BY_ROUTING_NUM_REGISTRATION_SCOPE,
);
export const BA_BY_ROUTING_NUM_REGISTRATION_SUCCESS = createSuccessType(
  BA_BY_ROUTING_NUM_REGISTRATION_SCOPE,
);
export const BA_BY_ROUTING_NUM_REGISTRATION_ERROR = createErrorType(
  BA_BY_ROUTING_NUM_REGISTRATION_SCOPE,
);
export const BA_BY_ROUTING_NUM_REGISTRATION_LOADING = createLoadingType(
  BA_BY_ROUTING_NUM_REGISTRATION_SCOPE,
);

// clear bank deails by routing number
export const BA_BY_ROUTING_NUM_REGISTRATION_CLEAR = createClearType(
  BA_BY_ROUTING_NUM_REGISTRATION_SCOPE,
);
// Connect bank account ACH registration

const CONNECT_ACH_BANK_ACCOUNTS_REGISTRATION_SCOPE =
  'CONNECT_ACH_BANK_ACCOUNTS_REGISTRATION';
export const CONNECT_ACH_BANK_ACCOUNTS_REGISTRATION = createSubmitType(
  CONNECT_ACH_BANK_ACCOUNTS_REGISTRATION_SCOPE,
);
export const CONNECT_ACH_BANK_ACCOUNTS_REGISTRATION_SUCCESS = createSuccessType(
  CONNECT_ACH_BANK_ACCOUNTS_REGISTRATION_SCOPE,
);
export const CONNECT_ACH_BANK_ACCOUNTS_REGISTRATION_ERROR = createErrorType(
  CONNECT_ACH_BANK_ACCOUNTS_REGISTRATION_SCOPE,
);
export const CONNECT_ACH_BANK_ACCOUNTS_REGISTRATION_LOADING = createLoadingType(
  CONNECT_ACH_BANK_ACCOUNTS_REGISTRATION_SCOPE,
);

// get bank accounts registration
export const FETCH_BANK_ACCOUNTS_REGISTRATION = createFetchType(
  'BANK_ACCOUNT_REGISTRATION',
);
export const FETCH_BANK_ACCOUNTS_REGISTRATION_SUCCESS = createSuccessType(
  'BANK_ACCOUNT_REGISTRATION',
);
export const FETCH_BANK_ACCOUNTS_REGISTRATION_ERROR = createErrorType(
  'BANK_ACCOUNT_REGISTRATION',
);
export const FETCH_BANK_ACCOUNTS_REGISTRATION_LOADING = createLoadingType(
  'BANK_ACCOUNT_REGISTRATION',
);

const GET_OBI_MESSAGES = 'GET_OBI_MESSAGES';
export const FETCH_OBI_MESSAGES = createFetchType(GET_OBI_MESSAGES);
export const FETCH_OBI_MESSAGES_LOADING = createLoadingType(GET_OBI_MESSAGES);
export const FETCH_OBI_MESSAGES_SUCCESS = createSuccessType(GET_OBI_MESSAGES);
export const FETCH_OBI_MESSAGES_ERROR = createErrorType(GET_OBI_MESSAGES);
export const CLEAR_OBI_MESSAGES = createClearType(GET_OBI_MESSAGES);
