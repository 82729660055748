import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import ReviewBar from '../../../ReviewBar/ReviewBar';
import { formatMoneyNumeral } from '../../../../util/helpers/numeralHelpers';
import SectionLoader from '../../../Loader/SectionLoader';
import { IRA_MILLENIUM_TYPE } from '../../../../util/constants';
import ModalBody from '../../../Modal/ModalBody';
import Paragraph from '../../../Paragraph/Paragraph';
import ModalButtons from '../../../Modal/ModalButtons';
import Label from '../../../Notes/Label';

const IraWithdrawReview = ({
  goStepBack,
  onConfirm,
  amountToWithdraw,
  address,
  isLoading,
  goStepForward,
  type,
}) => {
  const { t } = useTranslation();

  return !amountToWithdraw ? null : (
    <SectionLoader isLoading={isLoading}>
      <ReviewBar title={t('entrust.review.reviewBar')} />
      <ModalBody>
        <Label marginBottom={4} isBold text={t('entrust.review.amount')} />
        <Paragraph fontSize={18} marginBottom={8}>
          {formatMoneyNumeral(amountToWithdraw)}
        </Paragraph>

        {!!address && (
          <>
            <Label
              marginBottom={4}
              isBold
              text={t('entrust.review.mailingAddress')}
            />

            <Paragraph marginBottom={24}>{address.label}</Paragraph>
          </>
        )}
        {type === IRA_MILLENIUM_TYPE && (
          <Paragraph marginBottom={24}>
            <strong>{t('entrust.review.notice')}</strong>
          </Paragraph>
        )}
        <Paragraph>{t('entrust.review.description')}</Paragraph>
        <ModalButtons
          marginTop
          isHorizontal
          secondaryButtonProps={{ onClick: goStepBack }}
          primaryButtonProps={{
            onClick: () => onConfirm(goStepForward),
            label: t('common.confirm'),
          }}
        />
      </ModalBody>
    </SectionLoader>
  );
};

IraWithdrawReview.propTypes = {
  goStepBack: PropTypes.func,
  onConfirm: PropTypes.func,
  amountToWithdraw: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  address: PropTypes.shape({
    label: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  goStepForward: PropTypes.func,
  type: PropTypes.string,
};

export default IraWithdrawReview;
