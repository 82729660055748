import {
  PORTFOLIO_PRODUCT_BY_SYMBOL_CLEAR,
  PORTFOLIO_PRODUCT_BY_SYMBOL_FETCH_ERROR,
  PORTFOLIO_PRODUCT_BY_SYMBOL_FETCH_SUCCESS,
} from '../../actions/portfolio/portfolioActionConstants';
import createReducer from '../../utils/createReducer';

const initialState = {
  singleProduct: {},
  error: '',
};

export default createReducer(
  {
    [PORTFOLIO_PRODUCT_BY_SYMBOL_FETCH_SUCCESS]: setPortfolioSingleProduct,
    [PORTFOLIO_PRODUCT_BY_SYMBOL_FETCH_ERROR]: setPortfolioSingleProductError,
    [PORTFOLIO_PRODUCT_BY_SYMBOL_CLEAR]: clearPortfolioProductState,
  },
  initialState,
);

function clearPortfolioProductState() {
  return initialState;
}

function setPortfolioSingleProduct(state, action) {
  return {
    ...state,
    singleProduct: action.payload,
  };
}

function setPortfolioSingleProductError(state, action) {
  return {
    ...state,
    error: action.payload,
  };
}
