import { css } from 'styled-components';
import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import { variables } from '../../../assets/styles/variables';
import { TableDataElement } from '../TableData';

export const balanceTransactionTableHead = css`
  display: grid;
  grid-template-columns: 2fr 1fr 1.5fr ${pxToRem(226)} ${pxToRem(16)};
  grid-column-gap: ${pxToRem(24)};

  > :nth-child(2),
  > :nth-child(3) {
    display: flex;
    justify-content: flex-end;

    > p {
      text-align: right;
    }
  }

  ${(props) =>
    props.displayIRAEquityOptionsPermission &&
    `
      grid-template-columns: 2fr 1fr ${pxToRem(226)} ${pxToRem(16)};

        > :nth-child(2) {
          justify-content: flex-end;
        }

        > :nth-child(3) {
          justify-content: flex-start;
        }
  `};

  ${mediaBelow(variables.breakpoints.bpLg)} {
    grid-template-columns: 1fr ${pxToRem(226)} ${pxToRem(16)};

    > :nth-child(2),
    > :nth-child(3) {
      display: none;
    }

    ${(props) =>
      props.displayIRAEquityOptionsPermission &&
      `
        grid-template-columns: 1fr ${pxToRem(226)} ${pxToRem(16)};

        > :nth-child(2) {
          display: none;
        }
    
        > :nth-child(3) {
          display: flex;
        }
    `};
  }

  ${mediaBelow(variables.breakpoints.bpSm)} {
    grid-template-columns: 1fr;

    > :not(:first-child) {
      display: none;
    }

    ${(props) =>
      props.displayIRAEquityOptionsPermission &&
      `
        grid-template-columns: 1fr;

        > :not(:first-child) {
          display: none;
        }
      `}
  }
`;

export const balanceTransactionTableRow = css`
  cursor: pointer;

  ${TableDataElement} {
    display: grid;
    grid-template-columns: 2fr 1fr 1.5fr ${pxToRem(226)} ${pxToRem(16)};
    grid-column-gap: ${pxToRem(24)};

    > :nth-child(2),
    > :nth-child(3) {
      display: flex;
      justify-content: flex-end;

      > p {
        text-align: right;
      }
    }
  }

  ${(props) =>
    props.displayIRAEquityOptionsPermission &&
    `
    ${TableDataElement} {
      grid-template-columns: 2fr 1fr ${pxToRem(226)} ${pxToRem(16)};

      > :nth-child(2) {
        justify-content: flex-end;
      }

      > :nth-child(3) {
        justify-content: flex-start;
      }
    }
  `};

  ${mediaBelow(variables.breakpoints.bpLg)} {
    ${TableDataElement} {
      grid-template-columns: 1fr ${pxToRem(226)} ${pxToRem(16)};

      > :nth-child(2),
      > :nth-child(3) {
        display: none;
      }
    }

    ${(props) =>
      props.displayIRAEquityOptionsPermission &&
      `
      ${TableDataElement} {
        grid-template-columns: 1fr ${pxToRem(226)} ${pxToRem(16)};

        > :nth-child(2) {
          display: none;
        }
    
        > :nth-child(3) {
          display: flex;
        }
      }
        
    `};
  }

  ${mediaBelow(variables.breakpoints.bpSm)} {
    ${TableDataElement} {
      grid-template-columns: 1fr ${pxToRemMd(16)} ${pxToRemMd(16)};

      > :nth-child(2),
      > :nth-child(3) {
        display: none;
      }
    }

    ${(props) =>
      props.displayIRAEquityOptionsPermission &&
      `
      ${TableDataElement} {
        grid-template-columns: 1fr ${pxToRemMd(16)} ${pxToRemMd(16)};

        > :nth-child(2) {
          display: none;
        }
      }
      `}
`;

export const balanceTransactionTableStyle = css``;
