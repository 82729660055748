import { all, call, takeLatest } from 'redux-saga/effects';
import { rejectErrorCodeHelper } from '../../util/helpers/rejectErrorCodeHelper';
import {
  changeAutoPaySettingsRequest,
  getStorageFeeAmountRequest,
  payStorageFeeWithCardRequest,
} from '../../request/storageFeeRequest';
import {
  CHANGE_AUTO_PAY_SETTINGS,
  FETCH_STORAGE_FEE_AMOUNT,
  SUBMIT_CARD_PAY_STORAGE_FEE,
} from '../actions/storageFee/storageFeeActionConstants';
// import { getAccountSettingsRequest } from '../../request/accountRequest';

function* fetchStorageFeeAmount({ payload }) {
  try {
    const data = yield call(getStorageFeeAmountRequest, payload.accountUid);
    yield call(payload.setStorageFeeAmount, data?.data);
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    console.log(errorMessage); // eslint-disable-line
  }
}

function* payStorageFeeWithCard({
  payload: {
    accountUid,
    setIsLoading,
    onSuccess,
    onError,
    handleNext,
    storageFeeCreditCardPayment,
    setError,
  },
}) {
  yield call(setIsLoading, true);
  try {
    // yield call(getAccountSettingsRequest, {accountUid});
    yield call(
      payStorageFeeWithCardRequest,
      accountUid,
      storageFeeCreditCardPayment,
    );
    yield call(onSuccess);
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield call(onError);
    yield call(setError, errorMessage); // eslint-disable-line
  } finally {
    yield call(setIsLoading, false);
    yield call(handleNext);
  }
}

function* changeAutoPaySettings({
  payload: {
    accountUid,
    setting,
    setIsLoading,
    handleNext,
    onSuccess,
    setError,
  },
}) {
  if (setIsLoading) {
    yield call(setIsLoading, true);
  }
  try {
    yield call(changeAutoPaySettingsRequest, accountUid, setting);
    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield call(setError(errorMessage)); // eslint-disable-line
  } finally {
    if (setIsLoading) {
      yield call(setIsLoading, false);
    }
    if (handleNext) {
      yield call(handleNext);
    }
  }
}

export default function* storageFeeSaga() {
  yield all([takeLatest(FETCH_STORAGE_FEE_AMOUNT, fetchStorageFeeAmount)]);
  yield all([takeLatest(SUBMIT_CARD_PAY_STORAGE_FEE, payStorageFeeWithCard)]);
  yield all([takeLatest(CHANGE_AUTO_PAY_SETTINGS, changeAutoPaySettings)]);
}
