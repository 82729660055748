import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import isEmpty from 'lodash.isempty';
import {
  selectProductTiers,
  selectProductTiersError,
} from '../../store/selectors/productsSelector';
import { selectIsLoadingByActionType } from '../../store/selectors/loadingSelectors';
import { PRODUCT_TIERS_LOADING } from '../../store/actions/products/productActionConstants';
import { selectSettings } from '../../store/selectors/settingsSelectors';
import {
  emptyProductTiers,
  fetchProductTiers,
} from '../../store/actions/products/productActions';
import { getCurrentAccount } from '../../store/selectors/accountSelectors';
import { parseEnumType } from '../../util/helpers/enumMappers';
import {
  LOCATION_TYPE_DELIVERY,
  productLocationTypes,
  productLocationTypesByName,
} from '../../util/enum/api/productTypes';
import useGetPeriodicPriceTiers from '../../util/hooks/useGetPeriodicPriceTiers';
import SectionLoader from '../Loader/SectionLoader';
import { formatMoneyNumeral } from '../../util/helpers/numeralHelpers';
import ModalBody from '../Modal/ModalBody';
import Note from '../Notes/Note';
import Label from '../Notes/Label';
import PriceTierTable from './PriceTierTable';
import NoResults from '../NoResults/NoResults';
import ModalButtons from '../Modal/ModalButtons';
import { uFlexColumn } from '../../assets/styles/utility';
import { pxToRem } from '../../assets/styles/helper';

const PriceTierDataContainer = styled.div`
  ${uFlexColumn};
  flex-grow: 1;
`;

const PriceTierDataBody = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${pxToRem(24)};
  margin: ${pxToRem(24)} 0;
`;

const PriceTierDataItem = styled.li`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: ${pxToRem(8)} ${pxToRem(16)};

  > :first-child {
    text-align: left;
  }

  > :last-child {
    text-align: right;
  }

  p {
    font-weight: 600;
  }
`;

const PriceTierData = ({
  tradingSide,
  priceTierLocation,
  setPriceTierLocation,
  handleNext,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const productTiers = useSelector(selectProductTiers);
  const productTiersError = useSelector(selectProductTiersError);
  const isLoading = useSelector(
    selectIsLoadingByActionType(PRODUCT_TIERS_LOADING),
  );
  const settings = useSelector(selectSettings);
  const account = useSelector(getCurrentAccount);

  const { LocationType, SymbolCode } = priceTierLocation;

  useEffect(() => {
    dispatch(
      fetchProductTiers({
        accountUid: account.AccountUid,
        symbol: SymbolCode,
        side: tradingSide,
        location:
          LocationType === LOCATION_TYPE_DELIVERY
            ? Object.keys(productLocationTypesByName).find(
                (key) => productLocationTypesByName[key] === 12,
              )
            : parseEnumType(productLocationTypes, LocationType),
      }),
    );

    return () => dispatch(emptyProductTiers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useGetPeriodicPriceTiers(
    'priceTiersPTD',
    account,
    LocationType === LOCATION_TYPE_DELIVERY
      ? Object.keys(productLocationTypesByName).find(
          (key) => productLocationTypesByName[key] === 12,
        )
      : parseEnumType(productLocationTypes, LocationType),
    SymbolCode,
    tradingSide,
    dispatch,
    true,
    settings?.PricingRefreshFrequencyMilliseconds,
  );

  const formatProductTier = (tier) => {
    if (tier.HighBound) {
      return (
        <PriceTierDataItem>
          <p>
            <span>{formatMoneyNumeral(tier.LowBound)}</span> -{' '}
            <span>{formatMoneyNumeral(tier.HighBound)}</span>
          </p>
          <p>{formatMoneyNumeral(tier.PricePerUnit)}</p>
        </PriceTierDataItem>
      );
    }

    return (
      <PriceTierDataItem>
        <p>
          <span>{formatMoneyNumeral(tier.LowBound)} +</span>
        </p>
        <p>{formatMoneyNumeral(tier.PricePerUnit)}</p>
      </PriceTierDataItem>
    );
  };

  const renderPriceTiersTable = () => {
    if (!productTiers) {
      return null;
    }

    const { Tiers } = productTiers;

    if (!Tiers) {
      return null;
    }

    return Tiers.map((item) => formatProductTier(item));
  };

  const headerLabels = ['Dollar Amount', 'Price Per Unit*'];

  return (
    <ModalBody>
      <SectionLoader isLoading={isLoading}>
        <PriceTierDataContainer>
          {productTiers?.ProductCaption && (
            <h2>
              {` ${productTiers?.ProductCaption}, ${parseEnumType(
                productLocationTypes,
                LocationType,
              )}`}
            </h2>
          )}
          <Label isBold text="Price Tiers" />
          {productTiersError ? (
            <NoResults />
          ) : (
            <PriceTierDataBody>
              <PriceTierTable headerLabels={headerLabels}>
                {renderPriceTiersTable()}
              </PriceTierTable>
              {settings &&
                !isEmpty(settings) &&
                !settings?.IsLockedPricingActive && (
                  <Note text={t('product.priceEstimationNote')} />
                )}
            </PriceTierDataBody>
          )}

          <ModalButtons
            isVertical
            showOnlySecondary={tradingSide === 'Sell'}
            primaryButtonProps={{
              label: t('portfolio.holdings.buyBtnLabel'),
              onClick: () => handleNext(),
            }}
            secondaryButtonProps={{ onClick: () => setPriceTierLocation({}) }}
          />
        </PriceTierDataContainer>
      </SectionLoader>
    </ModalBody>
  );
};

PriceTierData.propTypes = {
  tradingSide: PropTypes.string,
  storageProduct: PropTypes.shape({ SymbolCode: PropTypes.string }),
  priceTierLocation: PropTypes.shape({
    LocationType: PropTypes.number,
    SymbolCode: PropTypes.string,
  }),
  setPriceTierLocation: PropTypes.func,
  handleNext: PropTypes.func,
};

export default PriceTierData;
