import styled from 'styled-components';
import {
  getStylesByProps,
  mediaBelow,
  pxToRem,
  pxToRemMd,
} from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';

const TypeList = styled.div`
  display: grid;
  grid-row-gap: ${({ gridRowGap }) =>
    gridRowGap ? `${pxToRem(gridRowGap)}` : `${pxToRem(16)}`};
  ${(props) => getStylesByProps(props)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    grid-row-gap: ${({ gridRowGap }) =>
      gridRowGap ? `${pxToRemMd(gridRowGap)}` : `${pxToRemMd(16)}`};
  }
`;

export default TypeList;
