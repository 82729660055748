import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SectionLoader from '../../../components/Loader/SectionLoader';
import { selectSettings } from '../../../store/selectors/settingsSelectors';
import Paragraph from '../../../components/Paragraph/Paragraph';
import SingleColumnList from '../../../components/TwoColumnList/SingleColumnList';
import Anchor from '../../../components/Anchor/Anchor';
import HavingTroubleBackButton from './HavingTroubleBackButton';
import Auth from '../../../components/Auth/Auth';
import AuthCard from '../../../components/Auth/AuthCard';

const HavingTroubleIsClosed = ({ handleBackClick, isOpenLoading }) => {
  const { t } = useTranslation();
  const brokerSettings = useSelector(selectSettings);

  return (
    <SectionLoader isLoading={isOpenLoading}>
      <Auth>
        <AuthCard title={t('twoFa.loginVerify.trouble.title')}>
          <Paragraph marginBottom={24}>
            {t('twoFa.loginVerify.trouble.note')}
          </Paragraph>
          <SingleColumnList marginBottom={48}>
            <Paragraph>
              {t('twoFa.loginVerify.trouble.toll.free')}{' '}
              <Anchor
                bold
                type="telephone"
                value={brokerSettings?.SupportPhoneTollFree}
                text={brokerSettings?.SupportPhoneTollFree}
              />
            </Paragraph>
            <Paragraph>
              {t('twoFa.loginVerify.trouble.toll.international')}{' '}
              <Anchor
                bold
                type="telephone"
                value={brokerSettings?.CustomerServicePhoneNumber}
                text={brokerSettings?.CustomerServicePhoneNumber}
              />
            </Paragraph>
          </SingleColumnList>
          <SingleColumnList marginBottom={48}>
            <Paragraph marginBottom={4}>
              {t('twoFa.loginVerify.trouble.closed.worktime.note')}
            </Paragraph>
            <Paragraph>
              {t('twoFa.loginVerify.trouble.closed.worktime.weekdays', {
                time: brokerSettings?.CustomerServiceWorkHoursWeekdays,
              })}
            </Paragraph>
            <Paragraph>
              {t('twoFa.loginVerify.trouble.closed.worktime.weekends', {
                time: brokerSettings?.CustomerServiceWorkHoursWeekends,
              })}
            </Paragraph>
          </SingleColumnList>
          <HavingTroubleBackButton onClick={handleBackClick} />
        </AuthCard>
      </Auth>
    </SectionLoader>
  );
};

HavingTroubleIsClosed.propTypes = {
  handleBackClick: PropTypes.func,
  isOpenLoading: PropTypes.bool,
};

export default HavingTroubleIsClosed;
