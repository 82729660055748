import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { variables } from '../../assets/styles/variables';
import LoaderIcon from './LoaderIcon';
import LoaderWrap from './LoaderWrap';
import LoaderContainer from './LoaderContainer';

export const BlockSectionLoaderContainer = styled(LoaderContainer)`
  box-shadow: ${variables.shadow.boxShadow};
  ${({ fullHeight }) => fullHeight && `height: 100%;`}
  ${({ noShadow }) => noShadow && `box-shadow: none;`}
  

  ${LoaderWrap} {
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    bottom: unset;
  }
`;

const BlockSectionLoader = ({ children, isLoading, fullHeight, noShadow }) => (
  <BlockSectionLoaderContainer fullHeight={fullHeight} noShadow={noShadow}>
    {children}
    {isLoading && (
      <LoaderWrap data-cy="loading-indicator">
        <LoaderIcon />
      </LoaderWrap>
    )}
  </BlockSectionLoaderContainer>
);

BlockSectionLoader.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  fullHeight: PropTypes.bool,
  noShadow: PropTypes.bool,
};

export default BlockSectionLoader;
