import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';

import CheckWithdrawalInformation from './CheckWithdrawalInformation';
import StepByStep from '../../../StepByStep';
import WithdrawalReview from '../WithdrawalReview';
import { fetchAccountAddresses } from '../../../../store/actions/account/accountActions';
import {
  getCurrentAccount,
  selectAccountAddresses,
  selectMappedAccountVerifiedAddressesForSelectField,
} from '../../../../store/selectors/accountSelectors';
import { selectIsLoadingByActionType } from '../../../../store/selectors/loadingSelectors';
import { FETCH_ACCOUNT_ADDRESSES_LOADING } from '../../../../store/actions/account/accountActionConstants';
import { transactionTypes } from '../../../../util/enum/api/transactionTypes';
import { updateCheckWithdrawal } from '../../../../store/actions/orders/orderActions';
import SuccessOrFailModal from '../../StatusModal';
import { UPDATE_CHECK_WITHDRAWAL_LOADING } from '../../../../store/actions/orders/orderActionConstants';
import NoFundsModal from '../NoFundsModal';
import NoAddressModal from '../NoAddressModal';
import {
  closeWizardContent,
  deleteWizardKey,
  getWizardContent,
  setWizardContent,
} from '../../../../util/helpers/wizardHelpers';
import useGtmHook from '../../../../util/hooks/useGtmHook';
import { fetchPortfolioBalances } from '../../../../store/actions/portfolio/portfolioActions';
import {
  CHECK_WITHDRAWAL_WIZARD,
  IS_NO_ADDRESS_MODAL_OPEN,
  IS_SUCCESS_OR_FAIL_MODAL_OPEN,
  WITHDRAWAL_ADDRESS,
  WITHDRAWAL_AMOUNT,
} from '../../../../constants/sessionStorage';
import { FUND_WITHDRAW_COMPLETE } from '../../../../constants/gtmEvents';
import { useSessionStorageState } from '../../../../util/hooks/useSessionStorageState';
import { formatAddressString } from '../../../../util/helpers/addressHelper';
import { selectWithdrawalWizardErrorMessage } from '../../../../store/selectors/withdrawalWizardSelector';
import { WITHDRAWAL_WIZARD_TYPES } from '../../../../constants/standardWithdrawalWizardConstants';

const CheckWithdrawalModal = ({
  isCheck,
  isModalOpen,
  handleBack,
  accountUid,
  handleClose,
  balances,
  handleRefreshTransactions,
}) => {
  const dispatch = useDispatch();
  const mappedAddressesForSelectField = useSelector(
    selectMappedAccountVerifiedAddressesForSelectField,
  );
  const addresses = useSelector(selectAccountAddresses);
  const account = useSelector(getCurrentAccount);
  const errorMessage = useSelector(selectWithdrawalWizardErrorMessage);
  const isLoading = useSelector(
    selectIsLoadingByActionType([FETCH_ACCOUNT_ADDRESSES_LOADING]),
  );
  const isSubmittingWithdrawal = useSelector(
    selectIsLoadingByActionType(UPDATE_CHECK_WITHDRAWAL_LOADING),
  );

  const { gtmDialogScreenView, fundGtmEvent } = useGtmHook();

  const [withdrawalAddress, setWithdrawalAddress] = useSessionStorageState(
    WITHDRAWAL_ADDRESS,
    {},
  );

  const [withdrawalAmount, setWithdrawalAmount] = useSessionStorageState(
    WITHDRAWAL_AMOUNT,
    '',
  );

  const [
    isSuccessOrFailModalOpen,
    setIsSuccessOrFailModalOpen,
  ] = useSessionStorageState(IS_SUCCESS_OR_FAIL_MODAL_OPEN, false);

  const [
    isNoAddressModalOpen,
    setIsNoAddressModalOpen,
  ] = useSessionStorageState(IS_NO_ADDRESS_MODAL_OPEN, false);

  const [fundsModalInfo, setFundsModalInfo] = useState(
    () =>
      getWizardContent('FundsModalInfo') || {
        isOpen: false,
        balances: [],
      },
  );

  useEffect(() => {
    dispatch(fetchAccountAddresses({ accountUid }));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (addresses) {
      const defaultAddress = addresses?.find(
        (address) => address.IsDefaultMailingAddress,
      );
      setWithdrawalAddress({
        value: defaultAddress?.AddressUid,
        label: formatAddressString(defaultAddress),
      });
    }
  }, [addresses, setWithdrawalAddress]);

  useEffect(() => {
    if (isSubmittingWithdrawal) {
      setWizardContent(IS_SUCCESS_OR_FAIL_MODAL_OPEN, true);
      setIsSuccessOrFailModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmittingWithdrawal]);

  const handleConfirmCheckWithdrawal = (handleNext) => {
    const onSuccess = () => {
      handleRefreshTransactions();
      dispatch(fetchPortfolioBalances({ accountUid: account.AccountUid }));
      fundGtmEvent(FUND_WITHDRAW_COMPLETE, {
        fund: {
          method: WITHDRAWAL_WIZARD_TYPES.CHECK,
          amount: withdrawalAmount,
        },
      });
    };

    const data = {
      AccountUid: accountUid,
      AddressUid: withdrawalAddress.value,
      Amount: withdrawalAmount,
      type: transactionTypes.CheckWithdrawal,
    };

    // const handleGtm = () => {
    //   gtmEvent(WITHDRAW, {
    //     AccountType: parseEnumType(accountTypeKeys, account.AccountType),
    //     Date: formatDate(date),
    //     Amount: withdrawalAmount,
    //   });
    // };

    dispatch(
      updateCheckWithdrawal({
        accountUid,
        data,
        handleNext,
        onSuccess,
      }),
    );
  };

  const availableBalance = balances.AvailableForWithdrawal;

  if (fundsModalInfo.isOpen) {
    return (
      <NoFundsModal
        balances={fundsModalInfo.balances}
        isOpen={fundsModalInfo.isOpen}
        handleClose={() => {
          closeWizardContent();
          handleClose();
        }}
        handleBack={() =>
          setFundsModalInfo((prevState) => ({ ...prevState, isOpen: false }))
        }
      />
    );
  }

  if (isNoAddressModalOpen) {
    return (
      <NoAddressModal
        isOpen={isNoAddressModalOpen}
        handleClose={() => {
          closeWizardContent();
          handleClose();
        }}
        handleBack={() => {
          deleteWizardKey(IS_NO_ADDRESS_MODAL_OPEN);
          setIsNoAddressModalOpen(false);
        }}
      />
    );
  }

  const callGtm = () => {
    gtmDialogScreenView({
      title: `Check Withdraw Funds Submitted`,
    });
  };

  return (
    <>
      <StepByStep sessionScopeStep={CHECK_WITHDRAWAL_WIZARD}>
        {({ goStepBack, goStepForward }) => [
          <CheckWithdrawalInformation
            key="check-withdrawal-information"
            isModalOpen={isModalOpen}
            handleBack={handleBack}
            handleNext={goStepForward}
            addressAndAmount={{
              address: withdrawalAddress,
              amount: withdrawalAmount,
            }}
            balances={balances}
            handleClose={handleClose}
            addresses={mappedAddressesForSelectField}
            availableBalance={availableBalance}
            setWithdrawalAddress={setWithdrawalAddress}
            setWithdrawalAmount={setWithdrawalAmount}
            isLoading={isLoading}
            setFundsModalInfo={setFundsModalInfo}
            setIsNoAddressModalOpen={() => {
              setWizardContent(IS_NO_ADDRESS_MODAL_OPEN, true);
              setIsNoAddressModalOpen(true);
            }}
          />,
          <WithdrawalReview
            key="withdrawal-review"
            isCheck={isCheck}
            isModalOpen={isModalOpen}
            handleBack={goStepBack}
            addressAndAmount={{
              address: withdrawalAddress,
              amount: withdrawalAmount,
            }}
            isLoading={isSubmittingWithdrawal}
            handleClose={handleClose}
            title={i18next.t('standardWithdrawalWizard.checkWithdrawal.title')}
            onConfirm={() => handleConfirmCheckWithdrawal(goStepForward)}
          />,
          <SuccessOrFailModal
            key="success-or-fail-modal"
            text={
              errorMessage ||
              i18next.t('standardWithdrawalWizard.successfulWithdrawal')
            }
            isOpen={isSuccessOrFailModalOpen}
            hasError={!!errorMessage}
            modalTitle={i18next.t(
              'standardWithdrawalWizard.checkWithdrawal.title',
            )}
            close={() => {
              setIsSuccessOrFailModalOpen(false);
              handleClose();
            }}
            onButtonClick={() => {
              closeWizardContent();
              setIsSuccessOrFailModalOpen(false);
              handleClose();
            }}
            backButtonText={i18next.t('common.ok')}
            callGtm={callGtm}
          />,
        ]}
      </StepByStep>
    </>
  );
};

CheckWithdrawalModal.propTypes = {
  isCheck: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  handleBack: PropTypes.func,
  handleClose: PropTypes.func,
  accountUid: PropTypes.string,
  balances: PropTypes.shape({
    AvailableForWithdrawal: PropTypes.number,
  }),
  handleRefreshTransactions: PropTypes.func,
};

export default CheckWithdrawalModal;
