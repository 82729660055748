// for country check on Add New Wire Withdrawal Account
export const USA_COUNTRY_LABEL = 'United States of America';
export const USA_COUNTRY_ISO3CODE = 'USA';
export const CAN_COUNTRY_ISO3CODE = 'CAN';

// bank accounts
export const METHOD_VIA_PLAID = 'via_plaid';
export const METHOD_VIA_CHECK = 'via_check';

// ira millenium
export const TYPE_DISTRIBUTION = 'distribution';
export const TYPE_TRANSFER_OUT = 'transferOut';

// ira types
export const TYPE_IRA_TO_IRA = 'IRA_TO_IRA';
export const TYPE_ROLLOVER_DEPOSIT = 'ROLLOVER_DEPOSIT';
export const TYPE_ANNUAL_IRA_CONTRIBUTION = 'ANNUAL_IRA_CONTRIBUTION';

export const IRA_EQUITY_TYPE = 'Equity';
export const IRA_MILLENIUM_TYPE = 'Millenium';

// ira fee withdrawal
export const TYPE_IRA_FEE_CHECK_WITHDRAWAL = 'IRAFeeCheckWithdrawal';
export const TYPE_IRA_FEE_WIRE_WITHDRAWAL = 'IRAFeeWireWithdrawal';
export const IRA_FEE_WITHDRAWAL = 'IRAFeeWithdrawal';
export const IRA_EQUITY_TRUST_TYPE = 'Equity Trust';
export const IRA_ENTRUST_TYPE = 'Entrust';
export const TAB_TYPE_CHECK = 'Check';

export const IRA_TO_IRA_TRANSFER = 'IRA to IRA Transfer';
export const ROLLOVER_DEPOSIT = 'Rollover Deposit';
export const ANNUAL_IRA_CONTRIBUTION = 'Annual IRA Contribution';
export const ACH_DEPOSIT = 'ACH Deposit';
export const WIRE_DEPOSIT = 'Wire Deposit';
export const CHECK_DEPOSIT = 'Check Deposit';
export const IRA_DISTRIBUTION = 'IRA Distribution';
export const IRA_TRANSFER_OUT = 'IRA Transfer Out';
export const WIRE_WITHDRAWAL = 'Wire Withdrawal';
export const CHECK_WITHDRAWAL = 'Check Withdrawal';

export const NAG_DASHBOARD_IS_DISMISSED = 'DismissNagDashboard';
export const NAG_DASHBOARD_ACTIVE_STEP = 'NagDashboardActiveStep';

export const lpmWebsiteUrl = 'https://legacypminvestments.com/';

// instavault products
export const instavaultGold = 'GS-Gold-Fractional-32-1037';
export const instavaultSilver = 'GS-Silver-Fractional-31-103';
