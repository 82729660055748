import React from 'react';
import PropTypes from 'prop-types';
import StepByStep from '../../StepByStep';
import TwoFAVerify from '../TwoFAVerify';
import TwoFAPhoneNumber from './TwoFaPhoneNumber';

const twoFAPhoneSteps = [TwoFAPhoneNumber, TwoFAVerify];

const TwoFAPhoneWizard = ({
  handleNext,
  handleBack,
  setTwoFaValues,
  twoFaValue,
  getTwoFaCode,
  setIsSuccessful,
  setErrorMessage,
}) => (
  <StepByStep sessionScopeStep="2fa-phone-wizard">
    {({ goStepBack, goStepForward }) =>
      twoFAPhoneSteps.map((modal) =>
        React.createElement(modal, {
          handleBack,
          goStepForward,
          goStepBack,
          setTwoFaValues,
          twoFaValue,
          handleNext,
          getTwoFaCode,
          setIsSuccessful,
          setErrorMessage,
        }),
      )
    }
  </StepByStep>
);

TwoFAPhoneWizard.propTypes = {
  setTwoFaValues: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  twoFaValue: PropTypes.shape({}),
  getTwoFaCode: PropTypes.func,
  setIsSuccessful: PropTypes.func,
  setErrorMessage: PropTypes.func,
};

export default TwoFAPhoneWizard;
