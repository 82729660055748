export const periodTypes = [
  'OneHour',
  'OneDay',
  'OneWeek',
  'OneMonth',
  'ThreeMonths',
  'SixMonths',
  'OneYear',
  'All',
];
