import React, { useCallback, useEffect, useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import InfiniteHitsWithScrollToTop from '../components/InfiniteHitsWithScrollToTop/InfiniteHitsWithScrollToTop';
import SortBy from '../components/SortBy/SortBy';
import NavigationBar from '../components/NavigationBar/NavigationBar';

import config from '../config';
import Section from '../components/Section/Section';
import Filter from '../components/Filter/Filter';
import { ReactComponent as FilterIcon } from '../assets/images/svg/filter.svg';
import useWindowSize from '../util/hooks/useIsMobileHook';
import { fetchProductsWithPrices } from '../store/actions/products/productActions';
import { getCurrentAccount } from '../store/selectors/accountSelectors';
import useGetPeriodicProductCodes from '../util/hooks/useGetPeriodicProductCodes';
import FilterModal from '../components/Modal/FilterModal';
import { selectIsLoadingByActionType } from '../store/selectors/loadingSelectors';
import { PRODUCT_PRICES_LOADING } from '../store/actions/products/productActionConstants';
import useGtmHook from '../util/hooks/useGtmHook';
import BlockSectionLoader from '../components/Loader/BlockSectionLoader';
import { selectProductPrices } from '../store/selectors/productsSelector';
import { PORTFOLIO_PAGE } from '../constants/pages';
import { selectSettings } from '../store/selectors/settingsSelectors';
import PriceTierModal from '../components/Modals/Prices/PriceTierModal';
import { ProductSearch } from '../components/InputFields/ProductSearch';
import TradingContainer from '../components/Trading/TradingContainer';
import TradingFilter from '../components/Trading/TradingFilter';
import TradingContent from '../components/Trading/TradingContent';
import TradingHeader from '../components/Trading/TradingHeader';
import { TradingFilterButton } from '../components/Trading/TradingFilterButton';
import VirtualFilter from '../components/Filter/VirtualFilters';
import NagDashboardBanner from '../components/NagDashboard/NagDashboardBanner';
import { ACCOUNT_DATA_LOADED } from '../constants/gtmEvents';
import { COUNTDOWN_TIMER_INTERVAL_MS } from '../constants/timerConstants';

const { apiKey, apiSecret, apiBuyIndex } = config.algoliaSearch;
const searchClient = algoliasearch(apiKey, apiSecret);

const BuyPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPriceTiersOpen, setIsPriceTiersOpen] = useState(false);
  const [priceTiersData, setPriceTiersData] = useState({});
  const windowSize = useWindowSize();
  const account = useSelector(getCurrentAccount);
  const isMobile = windowSize.width < 1200;
  const [products, setProducts] = useState(null);
  const [priceTiersProduct, setPriceTiersProduct] = useState(null);
  const isLoadingProducts = useSelector(
    selectIsLoadingByActionType(PRODUCT_PRICES_LOADING),
  );
  const productPrices = useSelector(selectProductPrices);
  const settings = useSelector(selectSettings);
  const [searchState, setSearchState] = useState({});
  const location = useLocation();
  const [isLockedPrice, setIsLockedPrice] = useState(false);

  const priceTiersClickHandler = (product, location) => {
    setIsPriceTiersOpen(true);
    setPriceTiersProduct(product);
    setPriceTiersData(location);
  };

  const [counterValue, setCounterValue] = useState(
    Math.floor(
      settings.PricingRefreshFrequencyMilliseconds /
        COUNTDOWN_TIMER_INTERVAL_MS,
    ),
  );
  const [unityValue, setUnityValue] = useState(false);

  const periodicCallback = useCallback((epoch) => {
    if (epoch?.data) {
      const [counter, unity] = epoch.data;
      setCounterValue((prevCounter) => prevCounter - prevCounter + counter);
      setUnityValue(unity);
    }
  }, []);

  useGetPeriodicProductCodes(
    'buy',
    account,
    'Buy',
    !!products ||
      (!isEmpty(products) && products.length && settings?.IsLockedPriceActive),
    settings?.PricingRefreshFrequencyMilliseconds,
    !isLockedPrice ? periodicCallback : null,
  );

  const { gtmScreenView, appGtmEvent } = useGtmHook();

  const getPrices = () => {
    dispatch(
      fetchProductsWithPrices({
        accountUid: account.AccountUid,
        side: 'Buy',
      }),
    );
  };

  useEffect(() => {
    gtmScreenView({
      path: window.location.pathname,
      title: 'Buy',
    });
    appGtmEvent(ACCOUNT_DATA_LOADED);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!isEmpty(account) && account.AccountUid) {
      getPrices();
    }
  }, [dispatch, account]); //eslint-disable-line

  useEffect(() => {
    if (!settings || isEmpty(settings)) {
      return;
    }

    setIsLockedPrice(settings?.IsLockedPricingActive);
  }, [settings]);

  return (
    <>
      <NagDashboardBanner />
      <NavigationBar
        hasBack
        pageRoute={PORTFOLIO_PAGE}
        mainPage={t('header.navDropdownTrading')}
        subPage={t('header.linkBuy')}
      />
      <Section>
        <TradingContainer>
          <InstantSearch
            indexName={apiBuyIndex}
            searchClient={searchClient}
            searchState={searchState}
            onSearchStateChange={setSearchState}
          >
            {isMobile && <VirtualFilter />}
            {!isMobile && (
              <TradingFilter data-cy="container-filters">
                {isEmpty(products) ? (
                  <BlockSectionLoader isLoading />
                ) : (
                  <Filter products={products} productPrices={productPrices} />
                )}
              </TradingFilter>
            )}
            <TradingContent data-cy="container-product-list">
              <TradingHeader>
                <div>
                  <SortBy
                    items={[
                      {
                        value: apiBuyIndex,
                        label: t('sortBy.popularity'),
                        dataCy: 'menu-item-product-sort-popularity',
                      },
                      {
                        value: `${apiBuyIndex}_prices_asc`,
                        label: t('sortBy.lowToHigh'),
                        dataCy: 'menu-item-product-sort-low-to-high',
                      },
                      {
                        value: `${apiBuyIndex}_prices_desc`,
                        label: t('sortBy.highToLow'),
                        dataCy: 'menu-item-product-sort-high-to-low',
                      },
                    ]}
                    defaultRefinement={apiBuyIndex}
                    getPrices={getPrices}
                  />
                  <ProductSearch data-cy="input-product-search" />
                  <TradingFilterButton
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                  >
                    <FilterIcon />
                  </TradingFilterButton>
                </div>
              </TradingHeader>
              <InfiniteHitsWithScrollToTop
                priceTiersClickHandler={priceTiersClickHandler}
                page="buy"
                side="buy"
                setProducts={setProducts}
                products={products}
                isLoading={isLoadingProducts}
                productCode={location?.state?.productCode}
                isLockedPrice={isLockedPrice}
                counter={counterValue}
                unity={unityValue}
              />
            </TradingContent>
            <PriceTierModal
              isOpen={isPriceTiersOpen}
              data={priceTiersData}
              account={account}
              tradingSide="Buy"
              handleClose={() => setIsPriceTiersOpen(false)}
              handleBack={() => setIsPriceTiersOpen(false)}
              priceTiersProduct={priceTiersProduct}
            />
            {isMobile && (
              <FilterModal
                title="Filters"
                isOpen={isModalOpen && isMobile}
                close={setIsModalOpen}
                size="sm"
              >
                <SortBy
                  items={[
                    {
                      value: apiBuyIndex,
                      label: t('sortBy.popularity'),
                      dataCy: 'menu-item-product-sort-popularity-mobile',
                    },
                    {
                      value: `${apiBuyIndex}_prices_asc`,
                      label: t('sortBy.lowToHigh'),
                      dataCy: 'menu-item-product-sort-low-to-high-mobile',
                    },
                    {
                      value: `${apiBuyIndex}_prices_desc`,
                      label: t('sortBy.highToLow'),
                      dataCy: 'menu-item-product-sort-high-to-low-mobile',
                    },
                  ]}
                  defaultRefinement={apiBuyIndex}
                  getPrices={getPrices}
                />
                {isEmpty(products) ? (
                  <BlockSectionLoader isLoading />
                ) : (
                  <>
                    <InstantSearch
                      indexName={apiBuyIndex}
                      searchClient={searchClient}
                      searchState={searchState}
                      onSearchStateChange={setSearchState}
                    >
                      <Filter
                        close={setIsModalOpen}
                        products={products}
                        productPrices={productPrices}
                      />
                    </InstantSearch>
                  </>
                )}
              </FilterModal>
            )}
          </InstantSearch>
        </TradingContainer>
      </Section>
    </>
  );
};

export default BuyPage;
