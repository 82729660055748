import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { separateByUppercase } from '../../../../util/helpers/stringHelpers';
import { formatNumber } from '../../../../util/helpers/numeralHelpers';
import { uFlexColumn } from '../../../../assets/styles/utility';
import {
  mediaBelow,
  pxToRem,
  pxToRemMd,
} from '../../../../assets/styles/helper';
import { variables } from '../../../../assets/styles/variables';
import themeColors from '../../../../assets/styles/themeColors';

const COLORS = ['#003246', themeColors.colorPrimary, '#4E7A8C', '#808285'];

const SummaryLegendWrap = styled.div`
  ${uFlexColumn};
  align-items: flex-start;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    align-items: flex-end;
  }
`;

const SummaryLegendList = styled.ul`
  ${uFlexColumn};

  > :not(:last-child) {
    margin-bottom: ${pxToRem(24)};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    > :not(:last-child) {
      margin-bottom: ${pxToRemMd(24)};
    }
  }
`;

const SummaryLegendListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SummaryLegendListItemLabel = styled.p`
  font-size: ${pxToRem(14)};
  line-height: 1.35;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(14)};
  }
`;

const SummaryLegendListItemFlag = styled.span`
  width: ${pxToRem(16)};
  height: ${pxToRem(16)};
  border-radius: ${variables.borderRadius.borderRadius};
  margin-right: ${pxToRem(8)};
  display: flex;
  align-self: center;
  flex-shrink: 0;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    width: ${pxToRemMd(16)};
    height: ${pxToRemMd(16)};
    margin-right: ${pxToRemMd(8)};
  }
`;

const SummaryLegend = ({ data, totalValuation, isOuncesView }) => (
  <SummaryLegendWrap data-cy="container-total-valuation-legend">
    <SummaryLegendList>
      {data.map((item, index) => (
        <SummaryLegendListItem
          key={item.name}
          data-cy="list-item-total-valuation-legend"
        >
          <SummaryLegendListItemLabel style={{ color: `${COLORS[index]}` }}>
            <SummaryLegendListItemFlag
              style={{ backgroundColor: `${COLORS[index]}` }}
            />
            {separateByUppercase(item.name)}
            {isOuncesView
              ? ` (${formatNumber(parseFloat(item.value).toFixed(2))} oz)`
              : ` (${parseFloat((item.value / totalValuation) * 100).toFixed(
                  2,
                )}%)`}
          </SummaryLegendListItemLabel>
        </SummaryLegendListItem>
      ))}
    </SummaryLegendList>
  </SummaryLegendWrap>
);

SummaryLegend.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  totalValuation: PropTypes.number,
  isOuncesView: PropTypes.bool,
};

export default SummaryLegend;
