import React from 'react';
import PropTypes from 'prop-types';

const UserManagementRow = ({ name, email, permission, status }) => (
  <>
    <div>{name}</div>
    <div>{email}</div>
    <div>{permission}</div>
    <div>{status}</div>
  </>
);

UserManagementRow.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  permission: PropTypes.string,
  status: PropTypes.string,
};

export default UserManagementRow;
