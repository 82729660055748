import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import {
  METAL_STREAM_BANK_ACCOUNTS_FETCH,
  METAL_STREAM_FINISH_SETUP_UPDATE,
  METAL_STREAM_PRODUCT_FETCH,
  MS_SETTINGS_FETCH,
  UPDATE_MS_SETTINGS,
} from '../actions/metalStream/metalStreamActionConstants';
import {
  getMetalStreamBankAccountsRequest,
  getMetalStreamProductRequest,
  getMetalStreamSettings,
  patchMetalStreamSettings,
  postMetalStreamSetupRequest,
} from '../../request/metalStreamRequest';
import {
  fetchMetalStreamBankAccountsError,
  fetchMetalStreamBankAccountsSuccess,
  fetchMetalStreamProductError,
  fetchMetalStreamProductSuccess,
  fetchMetalStreamSettingsError,
  fetchMetalStreamSettingsSuccess,
  postMetalStreamSetupError,
  postMetalStreamSetupSuccess,
  updateMetalStreamSettingsError,
  updateMetalStreamSettingsSuccess,
} from '../actions/metalStream/metalStreamActions';
import { rejectErrorCodeHelper } from '../../util/helpers/rejectErrorCodeHelper';

function* fetchMetalStreamSettings({ payload }) {
  try {
    const { data } = yield call(getMetalStreamSettings, payload.accountUid);
    yield put(fetchMetalStreamSettingsSuccess(data));
    yield put(fetchMetalStreamSettingsError(''));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchMetalStreamSettingsError(errorMessage));
  }
}

function* updateMetalStreamSettings({ payload }) {
  try {
    yield call(patchMetalStreamSettings, payload);
    yield call(fetchMetalStreamSettings, {
      payload: { accountUid: payload.accountUid },
    });
    yield put(fetchMetalStreamSettingsError(''));
    yield put(updateMetalStreamSettingsSuccess());
    yield call(payload.handleGtm);
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchMetalStreamSettingsError(errorMessage));
    yield put(updateMetalStreamSettingsError());
  }
}

function* fetchMetalStreamProduct({ payload }) {
  try {
    const { data } = yield call(getMetalStreamProductRequest, payload);
    yield put(
      fetchMetalStreamProductSuccess({ data, type: payload.metalType }),
    );
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchMetalStreamProductError(errorMessage));
  }
}

function* fetchMetalStreamBankAccounts({ payload }) {
  try {
    const { data } = yield call(getMetalStreamBankAccountsRequest, payload);
    yield put(fetchMetalStreamBankAccountsSuccess(data));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchMetalStreamBankAccountsError(errorMessage));
  }
}

function* updateMetalStreamSetup({ payload }) {
  try {
    const { data } = yield call(
      postMetalStreamSetupRequest,
      payload.accountUid,
      payload.data,
    );
    yield put(postMetalStreamSetupSuccess(data));
    yield call(payload.handleGtm);
    yield call(payload.goNext, '');
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(postMetalStreamSetupError(errorMessage));
    yield call(payload.goNext, errorMessage);
  }
}

export default function* metalStreamSaga() {
  yield all([
    takeLatest(MS_SETTINGS_FETCH, fetchMetalStreamSettings),
    takeLatest(UPDATE_MS_SETTINGS, updateMetalStreamSettings),
    takeLatest(METAL_STREAM_PRODUCT_FETCH, fetchMetalStreamProduct),
    takeLatest(METAL_STREAM_BANK_ACCOUNTS_FETCH, fetchMetalStreamBankAccounts),
    takeLatest(METAL_STREAM_FINISH_SETUP_UPDATE, updateMetalStreamSetup),
  ]);
}
