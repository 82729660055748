import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as jwt from 'jsonwebtoken';
import isEmpty from 'lodash.isempty';
import { useTranslation } from 'react-i18next';
import { decode } from 'jsonwebtoken';
import Auth from '../../components/Auth/Auth';
import PasswordField from '../../components/InputFields/PasswordField';
import SelectField from '../../components/InputFields/SelectField';
import TextField from '../../components/InputFields/TextField';
import {
  clearLoginErrors,
  clearUsernamesState,
  fetchUser,
} from '../../store/actions/login/loginActions';
import {
  selectLoginError,
  selectTokens,
  selectTwoFactorAuthenticationResponse,
} from '../../store/selectors/loginSelectors';

import {
  EMAIL_LOGIN_PAGE,
  IDENTITY_PAGE,
  RESET_SECURITY_QUESTION_PAGE,
  RESET_SENT_PAGE,
  TWO_FA_FORGOT_PASSWORD_IDENTITY_PAGE,
  TWO_FA_FORGOT_PASSWORD_IDENTITY_PHONE_PAGE,
  TWO_FA_VERIFY_IDENTITY,
  TWO_FA_VERIFY_IDENTITY_PHONE,
  USER_LOGIN_PAGE,
  VERIFY_CHOOSE_METHOD_PAGE,
} from '../../constants/pages';
import { selectSettings } from '../../store/selectors/settingsSelectors';
import { selectIsLoadingByActionTypes } from '../../store/selectors/loadingSelectors';
import { LOGIN_USER_LOADING } from '../../store/actions/login/loginActionConstants';
import { GET_TWO_FA_LOADING } from '../../store/actions/twoFA/twoFAActionConstants';
import useGtmHook from '../../util/hooks/useGtmHook';
import { LOGIN_EMAIL, USERNAMES } from '../../constants/sessionStorage';
import { getUsernames } from '../../util/helpers/userHelpers';
import {
  removeFromSessionStorage,
  storeInSessionStorage,
} from '../../util/helpers/sessionStorageHelper';
import { fetchTwoFa } from '../../store/actions/twoFA/twoFAActions';
import { getBrowserPayload } from '../../util/helpers/browserHelper';
import { userLoginValidationSchema } from '../../validation/userLoginValidationSchema';
import AuthCard from '../../components/Auth/AuthCard';
import FormContainer from '../../components/FormContainer/FormContainer';
import AuthCta from '../../components/Auth/AuthCta';
import Note from '../../components/Notes/Note';
import AuthButtons from '../../components/Auth/AuthButtons';
import { landingPageHelper } from '../../util/helpers/landingPageHelper';
import { InputHidden } from '../../components/InputFields/Components/InputComponents';
import {
  fetchUserSecurityQuestion,
  postPasswordForgottenRequest,
} from '../../store/actions/user/userActions';

const UserLoginPage = ({ history }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { gtmScreenView } = useGtmHook();
  const usernames = getUsernames();
  const tokens = useSelector(selectTokens);
  const error = useSelector(selectLoginError);
  const brokerSettings = useSelector(selectSettings);
  const isLoading = useSelector(
    selectIsLoadingByActionTypes([LOGIN_USER_LOADING, GET_TWO_FA_LOADING]),
  );
  const twoFaAuthenticationResponse = useSelector(
    selectTwoFactorAuthenticationResponse,
  );
  const { RegistrationUrl, IsBroker2FAActive } = brokerSettings;

  useEffect(() => {
    gtmScreenView({
      path: window.location.pathname,
      title: 'Login',
    });
  }, []); // eslint-disable-line

  // When user refreshes page
  useEffect(() => {
    function checkUsers() {
      if (!isEmpty(usernames)) {
        return;
      }

      history.replace(EMAIL_LOGIN_PAGE);
    }

    checkUsers();
  }, [dispatch, history, usernames]);

  useEffect(() => {
    function redirectClient() {
      if (!tokens.RefreshToken && !tokens.JwtToken) {
        return;
      }

      if (IsBroker2FAActive) {
        const authenticationMethodType = decode(tokens.JwtToken)
          ?.AuthenticationMethodType;

        if (tokens.JwtToken) {
          if (authenticationMethodType) {
            return history.push({
              pathname: landingPageHelper(),
              state: {
                from: history.location.pathname,
              },
            });
          }
          const { isRequired2FA } = jwt.decode(tokens.JwtToken);
          if (isRequired2FA === 'True') {
            return history.push({
              pathname: VERIFY_CHOOSE_METHOD_PAGE,
              state: {
                from: history.location.pathname,
              },
            });
          }
        }
      }

      if (tokens.RefreshToken) {
        if (IsBroker2FAActive && twoFaAuthenticationResponse) {
          if (twoFaAuthenticationResponse.AuthenticationMethodType === 2) {
            return history.replace(TWO_FA_VERIFY_IDENTITY_PHONE);
          }
          return history.replace(TWO_FA_VERIFY_IDENTITY);
        }
        return history.replace(IDENTITY_PAGE);
      }

      return history.push({
        pathname: landingPageHelper(),
        state: {
          from: history.location.pathname,
        },
      });
    }

    redirectClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens]);

  const handleSubmit = (values) => {
    const { value: Username } = values?.username;
    const { password: Password } = values;
    const payload = getBrowserPayload({
      Username,
      Password,
    });

    dispatch(clearLoginErrors());
    dispatch(
      fetchUser({
        ...payload,
      }),
    );
  };

  const handleDifferentEmail = () => {
    removeFromSessionStorage(LOGIN_EMAIL);
    removeFromSessionStorage(USERNAMES);
    dispatch(clearUsernamesState());
    history.push(USER_LOGIN_PAGE);
  };

  const onForgotPasswordClick = (username) => {
    const encodedUsername = encodeURIComponent(username);

    const resetPasswordSent = () => {
      dispatch(
        postPasswordForgottenRequest({
          data: {
            Username: username,
          },
          handleSuccess: () => {
            history.push(RESET_SENT_PAGE);
          },
        }),
      );
    };

    const onSuccessfulTwoFaFetch = (value) => {
      const onSuccessfullSecurityQuestionFetch = (value) => {
        if (value) {
          return history.push(
            `${RESET_SECURITY_QUESTION_PAGE}?username=${encodedUsername}`,
          );
        }

        return resetPasswordSent();
      };
      if (!isEmpty(value) && IsBroker2FAActive) {
        const { AuthenticationMethodType } = value;
        storeInSessionStorage('ForgotPasswordUsername', encodedUsername);

        if (AuthenticationMethodType === 2) {
          history.push(
            `${TWO_FA_FORGOT_PASSWORD_IDENTITY_PHONE_PAGE}?username=${encodedUsername}`,
          );
          return;
        }

        history.push(
          `${TWO_FA_FORGOT_PASSWORD_IDENTITY_PAGE}?username=${encodedUsername}`,
        );
        return;
      }

      dispatch(
        fetchUserSecurityQuestion({
          username,
          onSuccessfullSecurityQuestionFetch,
        }),
      );
    };

    if (IsBroker2FAActive) {
      dispatch(fetchTwoFa({ username, onSuccessfulTwoFaFetch }));
    } else {
      history.push(
        `${RESET_SECURITY_QUESTION_PAGE}?username=${encodedUsername}`,
      );
    }
  };

  return (
    <Auth>
      <AuthCard
        title={t('login.title')}
        subtitle={t('login.subtitle')}
        isLoading={isLoading}
      >
        {usernames && (
          <Formik
            initialValues={{ username: usernames[0] }}
            onSubmit={handleSubmit}
            validationSchema={userLoginValidationSchema}
            validateOnBlur
            enableReinitialize
          >
            {({ values }) => (
              <Form>
                <FormContainer marginBottom={16}>
                  <SelectField
                    label={t('common.labelUsername')}
                    name="username"
                    options={usernames}
                    disabled={isEmpty(usernames)}
                    data-cy="select-username"
                  />
                  <InputHidden>
                    <Field
                      value={values?.username?.value}
                      component={TextField}
                      name="username"
                    />
                  </InputHidden>
                  <Field
                    label={t('common.labelPassword')}
                    link={
                      <Note
                        text={t('login.forgotYourPassword')}
                        onClick={() =>
                          onForgotPasswordClick(values?.username?.value)
                        }
                      />
                    }
                    name="password"
                    component={PasswordField}
                    errorMessage={error}
                    autoFocus
                    data-cy="input-password"
                  />
                </FormContainer>
                <Note
                  fontSize={16}
                  onClick={handleDifferentEmail}
                  text={t('login.useDifferentEmail')}
                  primaryColor
                />
                <AuthButtons
                  showOnlyPrimary
                  primaryButtonProps={{
                    type: 'submit',
                    label: t('common.continue'),
                    'data-cy': 'button-continue',
                  }}
                />
              </Form>
            )}
          </Formik>
        )}
        <AuthCta
          text={t('login.dontHaveAccount')}
          link={RegistrationUrl}
          linkText={t('login.signUpRecommendation')}
          dataCy="link-sign-up"
        />
      </AuthCard>
    </Auth>
  );
};

UserLoginPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
};
export default UserLoginPage;
