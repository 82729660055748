import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import scenarios from '../../constants/mockScenarios';
import { setMaximumPeriodType } from '../../store/actions/portfolio/portfolioActions';

export default function useSelectScenarioMock(currentSelectedScenario = 0) {
  const dispatch = useDispatch();
  const [selectedScenarioIndex, setSelectedScenarioIndex] = useState(
    currentSelectedScenario,
  );
  const [selectedScenario, setScenario] = useState(
    scenarios[currentSelectedScenario],
  );
  useEffect(() => {
    setScenario(scenarios[selectedScenarioIndex]);
  }, [selectedScenarioIndex]);

  useEffect(() => {
    dispatch(
      setMaximumPeriodType({
        maximumPeriodType: selectedScenario.maximumPeriodType,
      }),
    );
  }, [dispatch, selectedScenario]);

  const setSelectedScenario = (e) => {
    const { value } = e.target;
    setSelectedScenarioIndex(value);
  };

  return {
    selectedScenarioIndex,
    selectedScenario,
    setSelectedScenario,
  };
}
