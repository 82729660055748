import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatMoneyNumeral } from '../../../../../util/helpers/numeralHelpers';
import ReviewBar from '../../../../ReviewBar/ReviewBar';
import ModalBody from '../../../../Modal/ModalBody';
import ModalButtons from '../../../../Modal/ModalButtons';
import ListWithIcon from '../../../../ListWithIcon/ListWithIcon';

const CheckInformationStepsReview = ({
  amount,
  address,
  confirmWithdraw,
  handleNext,
  handleBack,
}) => {
  const { t } = useTranslation();

  const reviewData = [
    {
      icon: 'wallet',
      labels: [
        t('IRAWithdraw.prefundedWithdrawReview.withdrawAmount', {
          amount: formatMoneyNumeral(amount),
        }),
      ],
    },
    {
      icon: 'location',
      labels: [t('IRAWithdraw.prefundedWithdrawReview.mailTo'), address.label],
    },
    {
      icon: 'clock',
      labels: [t('IRAWithdraw.prefundedWithdrawReview.checksInfo')],
    },
  ];

  return (
    <>
      <ReviewBar
        title={t('IRAWithdraw.prefundedWithdrawReview.reviewBarTitle')}
      />
      <ModalBody>
        <ListWithIcon items={reviewData} />
        <ModalButtons
          marginTop
          isHorizontal
          primaryButtonProps={{
            onClick: () => confirmWithdraw(handleNext),
            label: t('common.confirm'),
          }}
          secondaryButtonProps={{ onClick: handleBack }}
        />
      </ModalBody>
    </>
  );
};

CheckInformationStepsReview.propTypes = {
  amount: PropTypes.number,
  address: PropTypes.shape({
    label: PropTypes.string,
  }),
  confirmWithdraw: PropTypes.func,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
};

export default CheckInformationStepsReview;
