import pIntWoker from './portfolioIntervalWorker';
import {
  DEFAULT_ERROR_DISPATCH_CHANNEL,
  DEFAULT_SUCCESS_DISPATCH_CHANNEL,
  GET_DATA,
  WORKER_INTERVAL,
} from './workerConstants';
import store from '../store/index';
import requestWorker from './requestWorker';
import {
  COUNTDOWN_TIMER_INTERVAL_MS,
  COUNTDOWN_TIMER_INTERVAL_SEC,
} from '../constants/timerConstants';

const blob = new Blob([`(${pIntWoker.toString()})()`]);

const portfolioInternalWorker = window.Worker
  ? new Worker(URL.createObjectURL(blob))
  : {};
const workers = {};

setInterval(
  () =>
    portfolioInternalWorker.postMessage({
      method: GET_DATA,
      data: { currentDate: new Date() },
    }),
  WORKER_INTERVAL,
);

// eslint-disable-next-line no-unused-vars
portfolioInternalWorker.onmessage = function onMessage(e) {
  // console.log(e.data);
};

function InitDispatchWorker(options) {
  let timeIntervalId = null;

  const blob = new Blob([`(${requestWorker.toString()})()`]);
  const dispatchWorker = new Worker(URL.createObjectURL(blob));

  dispatchWorker.onmessage = function onMessage(message) {
    store.dispatch({
      type: options.actionSuccessType || DEFAULT_SUCCESS_DISPATCH_CHANNEL,
      payload: message.data,
    });
  };

  dispatchWorker.onerror = function onError(error) {
    store.dispatch({
      type: options.actionErrorType || DEFAULT_ERROR_DISPATCH_CHANNEL,
      payload: error,
    });
  };

  function start() {
    let counter = Math.floor(
      options.workerInterval / COUNTDOWN_TIMER_INTERVAL_MS,
    );
    let unity = false;

    timeIntervalId = setInterval(() => {
      if (options && options?.periodicCallback) {
        options.periodicCallback({ data: [counter, unity] });
      }
      unity = counter === 2;
      if (counter >= 0) {
        counter -= COUNTDOWN_TIMER_INTERVAL_SEC;
      }
      if (counter < 0) {
        counter = Math.floor(
          options.workerInterval / COUNTDOWN_TIMER_INTERVAL_MS,
        );
        dispatchWorker.postMessage({
          request: options.request,
          data: options.payload,
        });
      }
    }, COUNTDOWN_TIMER_INTERVAL_MS);

    return () => clearInterval(timeIntervalId);
  }

  return {
    start,
  };
}

export function createWorker(workerName, options) {
  if (workers[workerName]) {
    return workers[workerName];
  }

  workers[workerName] = new InitDispatchWorker(options);

  return workers[workerName];
}
