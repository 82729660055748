import {
  createClearType,
  createErrorType,
  createFetchType,
  createLoadingType,
  createSuccessType,
  createSubmitType,
  createUpdateType,
} from '../actionHelpers';

export const LOGIN_USERNAMES_FETCH = createFetchType('LOGIN_USERNAMES');
export const LOGIN_USERNAMES_SUCCESS = createSuccessType('LOGIN_USERNAMES');
export const LOGIN_USERNAMES_ERROR = createErrorType('LOGIN_USERNAMES');
export const LOGIN_USERNAMES_CLEAR = createClearType('LOGIN_USERNAMES');
export const LOGIN_USERNAMES_LOADING = createLoadingType('LOGIN_USERNAMES');

const LOGIN_USER_SCOPE = 'LOGIN_USER';
export const LOGIN_USER_FETCH = createFetchType(LOGIN_USER_SCOPE);
export const LOGIN_USER_SUCCESS = createSuccessType(LOGIN_USER_SCOPE);
export const LOGIN_USER_ERROR = createErrorType(LOGIN_USER_SCOPE);
export const CLEAR_LOGIN_USER_ERROR = createClearType(
  `${LOGIN_USER_SCOPE}_ERROR`,
);
export const LOGIN_USER_LOADING = createLoadingType(LOGIN_USER_SCOPE);

export const SECURITY_QUESTION_ANSWER_CODE = 'SECURITY_QUESTION_ANSWER_POST';
export const SECURITY_QUESTION_ANSWER_UPDATE = createUpdateType(
  SECURITY_QUESTION_ANSWER_CODE,
);
export const SECURITY_QUESTION_ANSWER_ERROR = createErrorType(
  SECURITY_QUESTION_ANSWER_CODE,
);
export const SECURITY_QUESTION_ANSWER_LOADING = createLoadingType(
  SECURITY_QUESTION_ANSWER_CODE,
);

export const UPDATE_USER_JWT_TOKEN = 'UPDATE_USER_JWT_TOKEN';
export const RESET_LOGIN_STATE = 'RESET_LOGIN_STATE';
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const REFRESH_TOKEN_SCOPE = 'REFRESH_TOKEN';

export const REFRESH_TOKEN = createSubmitType(REFRESH_TOKEN_SCOPE);
export const REFRESH_TOKEN_LOADER = createLoadingType(REFRESH_TOKEN_SCOPE);

const REGISTER_AUTHENTICATE_SCOPE = 'REGISTER_AUTHENTICATE';
export const REGISTER_AUTHENTICATE = createSubmitType(
  REGISTER_AUTHENTICATE_SCOPE,
);
export const REGISTER_AUTHENTICATE_SUCCESS = createSuccessType(
  REGISTER_AUTHENTICATE_SCOPE,
);
export const REGISTER_AUTHENTICATE_ERROR = createErrorType(
  REGISTER_AUTHENTICATE_SCOPE,
);
export const REGISTER_AUTHENTICATE_LOADING = createLoadingType(
  REGISTER_AUTHENTICATE_SCOPE,
);

const REGISTER_CONFIRM_AUTHENTICATION_SCOPE = 'REGISTER_CONFIRM_AUTHENTICATION';
export const REGISTER_CONFIRM_AUTHENTICATION = createSubmitType(
  REGISTER_CONFIRM_AUTHENTICATION_SCOPE,
);
export const REGISTER_CONFIRM_AUTHENTICATION_SUCCESS = createSuccessType(
  REGISTER_CONFIRM_AUTHENTICATION_SCOPE,
);
export const REGISTER_CONFIRM_AUTHENTICATION_ERROR = createErrorType(
  REGISTER_CONFIRM_AUTHENTICATION_SCOPE,
);
export const REGISTER_CONFIRM_AUTHENTICATION_LOADING = createLoadingType(
  REGISTER_CONFIRM_AUTHENTICATION_SCOPE,
);

const GENERATE_TOKEN_SCOPE = 'GENERATE_TOKEN';
export const GENERATE_TOKEN = createSubmitType(GENERATE_TOKEN_SCOPE);
export const GENERATE_TOKEN_SUCCESS = createSuccessType(GENERATE_TOKEN_SCOPE);
export const GENERATE_TOKEN_ERROR = createErrorType(GENERATE_TOKEN_SCOPE);

export const CLEAR_LOGIN_STATE = createClearType('CLEAR_LOGIN_STATE');

const REGISTER_USER_SCOPE = 'REGISTER_USER';
export const REGISTER_USER_FETCH = createFetchType(REGISTER_USER_SCOPE);
export const REGISTER_USER_SUCCESS = createSuccessType(REGISTER_USER_SCOPE);
export const REGISTER_USER_ERROR = createErrorType(REGISTER_USER_SCOPE);
export const CLEAR_REGISTER_USER_ERROR = createClearType(
  `${REGISTER_USER_SCOPE}_ERROR`,
);
export const REGISTER_USER_LOADING = createLoadingType(REGISTER_USER_SCOPE);
