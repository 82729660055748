import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from '../../Modal/Modal';
import StepByStep from '../../StepByStep';
import Status from '../Status';
import {
  ADD_MAIL_IN_STORAGE_WIZARD,
  WIZARD_DATA,
} from '../../../constants/sessionStorage';
import {
  removeFromSessionStorage,
  retrieveFromSessionStorage,
  storeInSessionStorage,
} from '../../../util/helpers/sessionStorageHelper';
import ModalBody from '../../Modal/ModalBody';
import AddMailInStorage from './AddMailInStorage';
import { closeWizardContent } from '../../../util/helpers/wizardHelpers';
import { selectMailInEnabled } from '../../../store/selectors/settingsSelectors';

const steps = [AddMailInStorage, Status];

const MailInStorageWizard = ({ isOpen, onClose, goToInitialModal }) => {
  const [wizardData, setWizardData] = useState(
    () => retrieveFromSessionStorage(WIZARD_DATA) || {},
  );
  const addMailInStorageError = false;

  const mailInEnabled = useSelector(selectMailInEnabled);

  const { t } = useTranslation();

  if (!isOpen) {
    return null;
  }

  const handleClose = () => {
    onClose();
    closeWizardContent();
    removeFromSessionStorage(WIZARD_DATA);
  };

  const setDataForWizard = (value) => {
    if (value) {
      setWizardData({ ...wizardData, value });
      storeInSessionStorage(WIZARD_DATA, { ...wizardData, value });
    }
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        close={handleClose}
        title={t('mailIn.modalTitle')}
        size="sm"
        avoidClearingStorage
      >
        <ModalBody>
          <StepByStep sessionScopeStep={ADD_MAIL_IN_STORAGE_WIZARD}>
            {({ goStepForward, goStepBack }) =>
              steps.map((step) =>
                React.createElement(step, {
                  handleBack: goStepBack,
                  handleNext: goStepForward,
                  handleClose,
                  wizardData,
                  setWizardData: setDataForWizard,
                  hasError: !!addMailInStorageError,
                  text: !addMailInStorageError
                    ? t('mailIn.successfulMessage')
                    : t('mailIn.unsuccessfulMessage'),
                  onButtonClick: handleClose,
                  goToInitialModal,
                  mailInStorageFlow: mailInEnabled,
                }),
              )
            }
          </StepByStep>
        </ModalBody>
      </Modal>
    </>
  );
};

MailInStorageWizard.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  goToInitialModal: PropTypes.func,
};

export default MailInStorageWizard;
