import { getRequest, postRequest, replaceInUrl } from './index';
import apiEndpoints from './apiEndpoints';

export const buyProductForStorageRequest = (accountUid, payload) =>
  postRequest(
    replaceInUrl(apiEndpoints.orders.buyForStorage, { accountUid }),
    payload,
  );

export const buyProductForDeliveryRequest = (accountUid, payload) =>
  postRequest(
    replaceInUrl(apiEndpoints.orders.buyForDelivery, { accountUid }),
    payload,
  );

export const sellProductFromStorageRequest = (accountUid, payload) =>
  postRequest(
    replaceInUrl(apiEndpoints.orders.sellFromStorage, { accountUid }),
    payload,
  );

export const fractionalConversionRequest = (accountUid, data) =>
  postRequest(
    replaceInUrl(apiEndpoints.orders.fractionalConversion, { accountUid }),
    data,
  );

export const entrustWithdrawalRequestDistribution = (accountUid, data) =>
  postRequest(
    replaceInUrl(apiEndpoints.orders.iraCashDistribution, { accountUid }),
    data,
  );

export const entrustWithdrawalRequestTransfer = (accountUid, data) =>
  postRequest(
    replaceInUrl(apiEndpoints.orders.iraCashTransfer, { accountUid }),
    data,
  );

export const submitIraFeeWithdrawal = (accountUid, data) =>
  postRequest(
    replaceInUrl(apiEndpoints.orders.iraFeeWithdrawal, { accountUid }),
    data,
  );

export const postAchDepositRequest = (accountUid, data) =>
  postRequest(
    replaceInUrl(apiEndpoints.orders.achDeposit, { accountUid }),
    data,
  );

export const updateWireWithdrawalRequest = (accountUid, data) =>
  postRequest(
    replaceInUrl(apiEndpoints.orders.wireWithdrawal, { accountUid }),
    data,
  );

export const updateCheckWithdrawalRequest = (accountUid, data) =>
  postRequest(
    replaceInUrl(apiEndpoints.orders.checkWithdrawal, { accountUid }),
    data,
  );

export const verifyBuyForDeliveryRequest = (accountUid, data) =>
  postRequest(
    replaceInUrl(apiEndpoints.orders.verifyBuyForDelivery, { accountUid }),
    data,
  );

export const getQuotesForBuyStorage = (
  data,
  isSegregated,
  paymentTypeUid,
  addPaymentMethodData,
) =>
  getRequest(replaceInUrl(apiEndpoints.orders.getQuotesForBuyStorage, data), {
    isSegregated,
    paymentTypeUid,
    addPaymentMethodData,
  });

export const executeQuotesForBuyStorage = (data) =>
  postRequest(
    replaceInUrl(apiEndpoints.orders.executeQuotesForBuyStorage, data),
  );

export const getQuotesForDelivery = (
  data,
  paymentTypeUid,
  addPaymentMethodData,
) =>
  getRequest(replaceInUrl(apiEndpoints.orders.getQuotesForDelivery, data), {
    paymentTypeUid,
    addPaymentMethodData,
  });

export const getQuotesForSell = (data, isSegregated) =>
  getRequest(replaceInUrl(apiEndpoints.orders.getQuotesForSell, data), {
    isSegregated,
  });

export const executeQuotesForSell = (data) =>
  postRequest(replaceInUrl(apiEndpoints.orders.executeQuotesForSell, data));
