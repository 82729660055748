import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { buttonizeDiv } from '../../../../util/buttonizeDiv';
import {
  ANNUAL_IRA_CONTRIBUTION,
  IRA_EQUITY_TYPE,
  IRA_TO_IRA_TRANSFER,
  ROLLOVER_DEPOSIT,
  TYPE_ANNUAL_IRA_CONTRIBUTION,
  TYPE_IRA_TO_IRA,
  TYPE_ROLLOVER_DEPOSIT,
} from '../../../../util/constants';
import TypeCard from '../../../TypeCard/TypeCard';
import ModalBody from '../../../Modal/ModalBody';
import useGtmHook from '../../../../util/hooks/useGtmHook';
import TypeList from '../../../TypeList/TypeList';
import { useBrokerCode } from '../../../../util/hooks/useBrokerCode';
import { FUND_DEPOSIT_START } from '../../../../constants/gtmEvents';

const IraFundTypes = ({ type, setWizardData, handleNext }) => {
  const { t } = useTranslation();
  const { gtmDialogScreenView, fundGtmEvent } = useGtmHook();
  const brokerCode = useBrokerCode();

  const typeOfIra = useCallback(() => {
    if (type === IRA_EQUITY_TYPE) {
      return 'Equity Trust';
    }
    return 'Entrust';
  }, [type]);

  const handleIraDepositTypeSelect = (iraDepositType) => {
    setWizardData({ iraDepositType });
    gtmDialogScreenView({
      title: `Deposit Funds - ${IRA_TO_IRA_TRANSFER}`,
    });
    handleNext();
  };

  useEffect(() => {
    setWizardData({
      wizardHandleBack: false,
      wizardSize: 'sm',
      wizardTitle: t('depositWizard.fundYourIra'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalBody>
      <TypeList>
        <TypeCard
          {...buttonizeDiv(() => {
            handleIraDepositTypeSelect(TYPE_IRA_TO_IRA, IRA_TO_IRA_TRANSFER);
            fundGtmEvent(FUND_DEPOSIT_START, {
              fund: {
                method: TYPE_IRA_TO_IRA,
              },
            });
          })}
          title={t('depositWizard.iraTransfer.title')}
          description={t('depositWizard.iraTypeSelection.iraTransfer', {
            type: typeOfIra(),
            brokerCode,
          })}
        />
        <TypeCard
          {...buttonizeDiv(() => {
            handleIraDepositTypeSelect(TYPE_ROLLOVER_DEPOSIT, ROLLOVER_DEPOSIT);
            fundGtmEvent(FUND_DEPOSIT_START, {
              fund: {
                method: TYPE_ROLLOVER_DEPOSIT,
              },
            });
          })}
          title={t('depositWizard.rollover.title')}
          description={t('depositWizard.iraTypeSelection.rolloverDeposit')}
        />
        <TypeCard
          {...buttonizeDiv(() => {
            handleIraDepositTypeSelect(
              TYPE_ANNUAL_IRA_CONTRIBUTION,
              ANNUAL_IRA_CONTRIBUTION,
            );
            fundGtmEvent(FUND_DEPOSIT_START, {
              fund: {
                method: TYPE_ANNUAL_IRA_CONTRIBUTION,
              },
            });
          })}
          title={t('depositWizard.iraContribution.title')}
          description={t(
            'depositWizard.iraTypeSelection.annualIraContribution',
            {
              type: typeOfIra(),
            },
          )}
        />
      </TypeList>
    </ModalBody>
  );
};

IraFundTypes.propTypes = {
  setWizardData: PropTypes.func,
  handleNext: PropTypes.func,
  type: PropTypes.string,
};

export default IraFundTypes;
