import createReducer from '../../utils/createReducer';
import {
  FETCH_ACCOUNT_IRA_SETTINGS_SUCCESS,
  FETCH_ACCOUNT_IRA_SETTINGS_ERROR,
} from '../../actions/iraSettings/iraSettingsActionConstants';

const initialState = {
  settings: {},
  errorMessage: '',
};

export default createReducer(
  {
    [FETCH_ACCOUNT_IRA_SETTINGS_SUCCESS]: setIraSettingsData,
    [FETCH_ACCOUNT_IRA_SETTINGS_ERROR]: setIraSettingsError,
  },
  initialState,
);

function setIraSettingsData(state, action) {
  return {
    ...state,
    settings: action.payload,
    error: '',
  };
}

function setIraSettingsError(state, action) {
  return {
    ...state,
    error: action.payload,
  };
}
