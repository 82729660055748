import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import * as jwt from 'jsonwebtoken';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { VERIFY_CHOOSE_METHOD_PAGE } from '../../constants/pages';
import RegisterStep from '../../components/RegisterStep/RegisterStep';
import { useBrokerName } from '../../util/hooks/userBrokerName';
import { getUsernames } from '../../util/helpers/userHelpers';
import {
  clearLoginErrors,
  fetchUser,
  updateSecurityQuestionAnswer,
} from '../../store/actions/login/loginActions';
import { selectTokens } from '../../store/selectors/loginSelectors';
import { selectSettings } from '../../store/selectors/settingsSelectors';
import { selectIsLoadingByActionTypes } from '../../store/selectors/loadingSelectors';
import {
  LOGIN_USER_LOADING,
  SECURITY_QUESTION_ANSWER_LOADING,
} from '../../store/actions/login/loginActionConstants';
import SectionLoader from '../../components/Loader/SectionLoader';
import { storeInSessionStorage } from '../../util/helpers/sessionStorageHelper';
import { IGNORE_PERMISSIONS } from '../../constants/sessionStorage';
import { getBrowserPayload } from '../../util/helpers/browserHelper';
import ModalButtons from '../../components/Modal/ModalButtons';
import { landingPageHelper } from '../../util/helpers/landingPageHelper';
import { useBrokerCode } from '../../util/hooks/useBrokerCode';

const Welcome = ({ setCurrentStep, handleNext, formValues }) => {
  const dispatch = useDispatch();
  const brokerName = useBrokerName();
  const { values } = formValues;
  const history = useHistory();
  const tokens = useSelector(selectTokens);
  const brokerSettings = useSelector(selectSettings);
  const brokerCode = useBrokerCode();
  const { IsBroker2FAActive } = brokerSettings;
  const usernames = getUsernames();
  const isExisting = usernames.length > 0;
  const isLoading = useSelector(
    selectIsLoadingByActionTypes([
      LOGIN_USER_LOADING,
      SECURITY_QUESTION_ANSWER_LOADING,
    ]),
  );

  useEffect(() => {
    setCurrentStep(100);
  }, [setCurrentStep]);

  useEffect(() => {
    if (!isExisting) {
      const { RefreshToken, JwtToken } = tokens;
      if (JwtToken) {
        if (IsBroker2FAActive) {
          return history.push({
            pathname: VERIFY_CHOOSE_METHOD_PAGE,
            state: {
              from: history.location.pathname,
            },
          });
        }
        return history.push({
          pathname: landingPageHelper(),
          state: {
            from: history.location.pathname,
          },
        });
      }
      if (RefreshToken) {
        const { SecurityQuestionType } = jwt.decode(RefreshToken);
        const securityQuestionData = { RefreshToken, SecurityQuestionType };
        handleConfirmSecurityQuestion(securityQuestionData);
      }
    }
  }, [tokens]); // eslint-disable-line

  const handleConfirmSecurityQuestion = (securityQuestion) => {
    const { Answer } = values;

    const payload = getBrowserPayload({
      ...securityQuestion,
      SecurityAnswer: Answer,
    });

    dispatch(
      updateSecurityQuestionAnswer({
        ...payload,
      }),
    );
  };

  const handleExistentUserSubmit = () => {
    const { Username, Password } = values;

    const payload = getBrowserPayload({
      Username,
      Password,
    });

    dispatch(clearLoginErrors());
    dispatch(fetchUser({ ...payload }));
  };

  const handleWelcome = () => {
    storeInSessionStorage(IGNORE_PERMISSIONS, false);
    if (isExisting) {
      handleNext(landingPageHelper());
    } else {
      handleExistentUserSubmit();
    }
  };

  return (
    <RegisterStep
      title={i18next.t('inviteUser.welcome.title')}
      subtitle={i18next.t('inviteUser.welcome.subtitle', { brokerName })}
    >
      <SectionLoader isLoading={isLoading}>
        <ModalButtons
          mediumButtons
          showOnlyPrimary
          primaryButtonProps={{
            onClick: handleWelcome,
            label: i18next.t('inviteUser.welcome.cta', { brokerCode }),
          }}
        />
      </SectionLoader>
    </RegisterStep>
  );
};

Welcome.propTypes = {
  setCurrentStep: PropTypes.func,
  handleNext: PropTypes.func,
  formValues: PropTypes.shape({
    values: PropTypes.shape({
      ExistingUsername: PropTypes.string,
      LegalFirstName: PropTypes.string,
      LegalMiddleName: PropTypes.string,
      LegalLastName: PropTypes.string,
      Suffix: PropTypes.string,
      Username: PropTypes.string,
      Password: PropTypes.string,
      SecurityQuestion: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
        PropTypes.string,
      ]),
      Answer: PropTypes.string,
    }),
    phoneNumbers: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default Welcome;
