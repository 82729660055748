export const variables = {
  family: {
    goldSilver: 'Gotham',
    hardAssetsAlliance: 'Avenir Next',
    contoso: 'Avenir Next',
    legacyPM: 'Montserrat',
    legacyPMHeaders: 'Merriweather',
  },
  sizes: {
    baseFontSize: 16,
    baseFontSizeMd: 14,
  },
  shadow: {
    buttonShadowHover: '0 5px 6px 0 rgba(112, 120, 135, 0.24)',
    buttonShadowPressed: '0 2px 4px 0 rgba(112, 120, 135, 0.24)',
    boxShadow: '0 3px 8px 0 rgba(112, 120, 135, 0.24)',
    accountDropdownShadow: '0 6px 38px 0 rgba(112, 120, 135, 0.56)',
  },
  borderRadius: {
    borderRadius: '4px',
    borderRadiusMd: '8px',
  },
  breakpoints: {
    bpXxs: '325',
    bpXs: '400',
    bpSm: '576',
    bpMd: '768',
    bpLg: '992',
    bpXl: '1200',
    bpXxl: '1350',
  },
  zIndex: {
    indexXxs: 1,
    indexXs: 2,
    indexSm: 3,
    indexMd: 4,
    indexLg: 5,
    indexXl: 6,
    indexXxl: 7,
  },
  transitionSpeed: {
    transitionSpeed: '0.3s',
  },
};
