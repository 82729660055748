import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Note from '../../../Notes/Note';
import InformationList from '../../../InformationList/InformationList';
import InformationListItem from '../../../InformationList/InformationListItem';
import Paragraph from '../../../Paragraph/Paragraph';
import config from '../../../../config';

const RolloverCustodianInformation = ({ type, iraSettings, iraDetails }) => {
  const { t } = useTranslation();

  if (!iraSettings || !iraDetails || !type) {
    return null;
  }

  const { AccountNumber, CheckDepositInstructions } = iraSettings;
  const { TrusteeName, MailingAddress } = iraDetails;

  const isLegacyPM = () => {
    const { brokerTheme } = config;
    if (brokerTheme === 'LegacyPM') {
      return true;
    }
    return false;
  };

  return (
    <>
      {isLegacyPM() ? (
        <Paragraph bold marginBottom={24}>
          {t('depositWizard.rollover.equityNumber', {
            num: AccountNumber,
          })}
        </Paragraph>
      ) : (
        <Paragraph bold marginBottom={24}>
          {t('depositWizard.rollover.accountNumber', {
            num: AccountNumber,
          })}
        </Paragraph>
      )}
      <Paragraph bold marginBottom={4}>
        {t('depositWizard.rollover.receivingCustodian')}
      </Paragraph>
      <Note
        marginBottom={8}
        text={t('depositWizard.rollover.noteForPayable')}
      />
      <InformationList withBackground>
        <InformationListItem
          label={t('depositWizard.rollover.paylableTo')}
          value={CheckDepositInstructions?.PayableTo}
        />
        <InformationListItem
          label={t('depositWizard.rollover.memo')}
          value={
            CheckDepositInstructions?.Memo
              ? `${CheckDepositInstructions?.Memo} Rollover`
              : null
          }
        />
        <InformationListItem
          label={t('depositWizard.rollover.address')}
          value={
            MailingAddress || TrusteeName
              ? `${TrusteeName}\n${MailingAddress?.AddressLine}, ${MailingAddress?.AddressLine2}\n${MailingAddress?.City}, ${MailingAddress?.StateRegion} ${MailingAddress?.PostalCode}`
              : null
          }
        />
      </InformationList>
    </>
  );
};

RolloverCustodianInformation.propTypes = {
  type: PropTypes.string,
  iraSettings: PropTypes.shape({
    AccountNumber: PropTypes.string,
    CheckDepositInstructions: PropTypes.shape({
      Memo: PropTypes.string,
      PayableTo: PropTypes.string,
      Addresses: PropTypes.shape({
        checkAddress: PropTypes.shape({
          Receipient: PropTypes.string,
          AddressLine: PropTypes.string,
          AddressLine2: PropTypes.string,
          City: PropTypes.string,
          StateRegion: PropTypes.string,
          PostalCode: PropTypes.string,
        }),
      }),
    }),
  }),
  iraDetails: PropTypes.shape({
    TrusteeName: PropTypes.string,
    MailingAddress: PropTypes.shape({
      AddressLine: PropTypes.string,
      AddressLine2: PropTypes.string,
      City: PropTypes.string,
      StateRegion: PropTypes.string,
      PostalCode: PropTypes.string,
    }),
    Phone: PropTypes.string,
    Url: PropTypes.string,
  }),
};

export default RolloverCustodianInformation;
