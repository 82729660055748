import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';

import ModalBody from '../../Modal/ModalBody';
import Paragraph from '../../Paragraph/Paragraph';
import ModalButtons from '../../Modal/ModalButtons';
import SectionLoader from '../../Loader/SectionLoader';
import { getCurrentAccountUid } from '../../../store/selectors/accountSelectors';
import { deleteBankAccounts } from '../../../store/actions/bankAccount/bankAccountActions';
import { BANK_ACCOUNT_REMOVED } from '../../../constants/gtmEvents';
import useGtmHook from '../../../util/hooks/useGtmHook';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import { bankAccountTypes } from '../../../util/enum/api/bankAccountTypes';

const RemoveBankAccount = ({
  handleClose,
  bankAccountUid,
  removeAccountType,
  handleNext,
  setErrorMessage,
  removeType,
}) => {
  const dispatch = useDispatch();
  const accountUid = useSelector(getCurrentAccountUid);
  const [isLoading, setIsLoading] = useState(false);
  const { bankAccountGtmEvent } = useGtmHook();

  const handleGtmEvent = () => {
    bankAccountGtmEvent(
      BANK_ACCOUNT_REMOVED,
      removeAccountType,
      parseEnumType(bankAccountTypes, removeType),
      '',
    );
  };

  const handleAccountRemove = () => {
    dispatch(
      deleteBankAccounts({
        accountUid,
        bankAccountUid,
        type: removeAccountType,
        handleNext,
        setIsLoading,
        setErrorMessage,
        onSuccess: handleGtmEvent,
      }),
    );
  };

  return (
    <SectionLoader isLoading={isLoading}>
      <ModalBody>
        <Paragraph>
          {i18next.t('bankAccounts.removeModal.areYouSure')}
        </Paragraph>
        <ModalButtons
          isHorizontal
          marginTop
          secondaryButtonProps={{
            onClick: handleClose,
            label: i18next.t('common.cancel'),
          }}
          primaryButtonProps={{
            onClick: handleAccountRemove,
            label: i18next.t('common.remove'),
          }}
        />
      </ModalBody>
    </SectionLoader>
  );
};

RemoveBankAccount.propTypes = {
  handleClose: PropTypes.func,
  bankAccountUid: PropTypes.string,
  removeAccountType: PropTypes.string,
  handleNext: PropTypes.func,
  setErrorMessage: PropTypes.func,
  removeType: PropTypes.number,
};

export default RemoveBankAccount;
