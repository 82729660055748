import styled from 'styled-components';
import { uFlexColumn } from '../../assets/styles/utility';

const LoaderContainer = styled.div`
  ${uFlexColumn};
  flex: 1 1 auto;
  position: relative;
  min-height: 0;
  min-width: 0;
`;

export default LoaderContainer;
