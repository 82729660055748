import i18next from 'i18next';
import { startCase } from 'lodash';
import * as Yup from 'yup';
import { parseEnumType } from '../util/helpers/enumMappers';
import { formatMoneyNumeral } from '../util/helpers/numeralHelpers';

export const buyForStorageValidationSchema = (
  activeType,
  unitPrice,
  balanceAvailableForTrading,
  productLocationTypes,
  selectedVault,
  getMinimumOrderAmount,
) => {
  const minimumOrderByLocation = getMinimumOrderAmount();

  const calculateMaxQuantity = () =>
    Math.floor(balanceAvailableForTrading / unitPrice);

  const calculateMinimumQuantity = () => {
    if (!minimumOrderByLocation) {
      return 1;
    }

    return Math.ceil(
      (unitPrice > minimumOrderByLocation
        ? unitPrice
        : minimumOrderByLocation) / unitPrice,
    );
  };

  if (activeType === 'Cash') {
    if (minimumOrderByLocation) {
      return Yup.object().shape({
        CashAmount: Yup.number()
          // eslint-disable-next-line func-names
          .test('amount-validation', '', function (value) {
            if (
              unitPrice < minimumOrderByLocation &&
              value < minimumOrderByLocation
            ) {
              return this.createError({
                message: i18next.t(
                  'validationSchema.orders.minimumCashFundsForLocation',

                  {
                    amount: formatMoneyNumeral(minimumOrderByLocation),
                    location: startCase(
                      parseEnumType(
                        productLocationTypes,
                        selectedVault.LocationType,
                      ),
                    ),
                  },
                ),
              });
            }
            if (unitPrice > minimumOrderByLocation && value < unitPrice) {
              return this.createError({
                message: i18next.t('validationSchema.orders.minimumFunds', {
                  productPrice: formatMoneyNumeral(unitPrice),
                  location: startCase(
                    parseEnumType(
                      productLocationTypes,
                      selectedVault.LocationType,
                    ),
                  ),
                }),
              });
            }
          })
          .when('applyMax', {
            is: true,
            then: Yup.number().max(
              balanceAvailableForTrading,
              i18next.t('validationSchema.orders.insufficientFunds', {
                balance: `${formatMoneyNumeral(balanceAvailableForTrading)}`,
              }),
            ),
          })
          .typeError(i18next.t('validationSchema.cash.cashRequired'))
          .required(i18next.t('validationSchema.cash.cashRequired')),
      });
    }
    if (!minimumOrderByLocation) {
      return Yup.object().shape({
        CashAmount: Yup.number()
          .min(
            unitPrice,
            i18next.t('validationSchema.orders.minimumFunds', {
              productPrice: `${formatMoneyNumeral(unitPrice)}`,
            }),
          )
          .when('applyMax', {
            is: true,
            then: Yup.number().max(
              balanceAvailableForTrading,
              i18next.t('validationSchema.orders.insufficientFunds', {
                balance: `${formatMoneyNumeral(balanceAvailableForTrading)}`,
              }),
            ),
          })
          .typeError(i18next.t('validationSchema.cash.cashRequired'))
          .required(i18next.t('validationSchema.cash.cashRequired')),
      });
    }
  }

  if (activeType === 'Quantity') {
    if (minimumOrderByLocation) {
      return Yup.object().shape({
        QuantityAmount: Yup.number()
          .integer(i18next.t('validationSchema.quantity.wholeNumber'))
          .min(
            calculateMinimumQuantity(),
            i18next.t(
              'validationSchema.orders.minimumQuantityFundsForLocation',
              {
                amount: calculateMinimumQuantity(),
                productPrice: formatMoneyNumeral(
                  unitPrice > minimumOrderByLocation
                    ? unitPrice
                    : minimumOrderByLocation,
                ),
                location: startCase(
                  parseEnumType(
                    productLocationTypes,
                    selectedVault?.LocationType,
                  ),
                ),
              },
            ),
          )
          .when('applyMax', {
            is: true,
            then: Yup.number().max(
              calculateMaxQuantity(),
              i18next.t('validationSchema.orders.insufficientFunds', {
                balance: `${formatMoneyNumeral(balanceAvailableForTrading)}`,
              }),
            ),
          })
          .typeError(i18next.t('validationSchema.quantity.quantityRequired'))
          .required(i18next.t('validationSchema.quantity.quantityRequired')),
      });
    }

    if (!minimumOrderByLocation) {
      return Yup.object().shape({
        QuantityAmount: Yup.number()
          .integer(i18next.t('validationSchema.quantity.wholeNumber'))
          .min(
            1,
            i18next.t('validationSchema.orders.minimumFunds', {
              productPrice: `${1}`,
            }),
          )
          .when('applyMax', {
            is: true,
            then: Yup.number().max(
              calculateMaxQuantity(),
              i18next.t('validationSchema.orders.insufficientFunds', {
                balance: `${formatMoneyNumeral(balanceAvailableForTrading)}`,
              }),
            ),
          })
          .typeError(i18next.t('validationSchema.quantity.quantityRequired'))
          .required(i18next.t('validationSchema.quantity.quantityRequired')),
      });
    }
  }
};
