import { createSelector } from 'reselect';

export const commonSelector = (state) => state.common;

export const selectSecurityQuestions = createSelector(
  commonSelector,
  (state) => state.securityQuestions,
);

export const selectCountries = createSelector(
  commonSelector,
  (state) => state.countries,
);

export const selectStates = createSelector(
  commonSelector,
  (state) => state.states,
);

export const selectPrimaryStates = createSelector(
  commonSelector,
  (state) => state.primaryStates,
);

export const selectMailingStates = createSelector(
  commonSelector,
  (state) => state.mailingStates,
);

export const selectTaxForms = createSelector(
  commonSelector,
  (state) => state.taxForms,
);

export const selectContributionYears = createSelector(
  commonSelector,
  (state) => state.contributionYears,
);
