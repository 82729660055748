import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';
import Modal from '../../Modal/Modal';
import ReviewBar from '../../ReviewBar/ReviewBar';

import { formatMoneyNumeral } from '../../../util/helpers/numeralHelpers';
import SectionLoader from '../../Loader/SectionLoader';
import ModalBody from '../../Modal/ModalBody';
import ModalButtons from '../../Modal/ModalButtons';
import ListWithIcon from '../../ListWithIcon/ListWithIcon';

const WithdrawalReview = ({
  addressAndAmount,
  bankAndAmount,
  wireWithdrawalFee,
  wireWithdrawalFeeCreditAmount,
  handleBack,
  onConfirm,
  isCheck,
  isModalOpen,
  handleClose,
  title,
  isLoading,
  goStepForward,
}) => {
  const { t } = useTranslation();

  const checkReviewData = [
    {
      icon: 'wallet',
      labels: [
        t('IRAWithdraw.prefundedWithdrawReview.withdrawAmount', {
          amount: formatMoneyNumeral(addressAndAmount?.amount),
        }),
      ],
    },
    {
      icon: 'location',
      labels: [
        t('IRAWithdraw.prefundedWithdrawReview.mailTo'),
        addressAndAmount?.address?.label,
      ],
    },
    {
      icon: 'clock',
      labels: [t('IRAWithdraw.prefundedWithdrawReview.checksInfo')],
    },
  ];

  const wireReviewData = [
    {
      icon: 'wallet',
      labels: [
        t('standardWithdrawalWizard.wireWithdrawal.aboutToWithdraw', {
          amount: formatMoneyNumeral(bankAndAmount?.amount),
          fee: formatMoneyNumeral(
            wireWithdrawalFee - wireWithdrawalFeeCreditAmount,
          ),
        }),
      ],
    },
    {
      icon: 'clock',
      labels: [t('standardWithdrawalWizard.wireWithdrawal.wireAvailable')],
    },
    {
      icon: 'check',
      labels: [
        t('standardWithdrawalWizard.wireWithdrawal.amountDepositedTo', {
          amount: formatMoneyNumeral(bankAndAmount?.amount),
          bankName: bankAndAmount?.bank?.BankName,
          accountMask:
            Array(8).fill('*').join('') + bankAndAmount?.bank?.AccountMask,
        }),
      ],
    },
  ];

  return isEmpty(addressAndAmount) && isEmpty(bankAndAmount) ? null : (
    <Modal title={title} size="sm" isOpen={isModalOpen} close={handleClose}>
      <SectionLoader isLoading={isLoading}>
        <ReviewBar
          title={t('IRAWithdraw.prefundedWithdrawReview.reviewBarTitle')}
        />
        <ModalBody>
          <ListWithIcon items={isCheck ? checkReviewData : wireReviewData} />
          <ModalButtons
            isHorizontal
            marginTop
            primaryButtonProps={{
              size: 'lg',
              onClick: () => onConfirm(goStepForward),
              label: t('common.confirm'),
            }}
            secondaryButtonProps={{
              size: 'lg',
              onClick: handleBack,
              label: t('common.back'),
            }}
          />
        </ModalBody>
      </SectionLoader>
    </Modal>
  );
};

WithdrawalReview.propTypes = {
  addressAndAmount: PropTypes.shape({
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    address: PropTypes.shape({
      label: PropTypes.string,
    }),
  }),
  bankAndAmount: PropTypes.shape({
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    bank: PropTypes.shape({
      AccountMask: PropTypes.string,
      BankName: PropTypes.string,
    }),
  }),
  handleBack: PropTypes.func,
  onConfirm: PropTypes.func,
  handleClose: PropTypes.func,
  isCheck: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  title: PropTypes.string,
  wireWithdrawalFee: PropTypes.number,
  wireWithdrawalFeeCreditAmount: PropTypes.number,
  isLoading: PropTypes.bool,
  goStepForward: PropTypes.func,
};

export default WithdrawalReview;
