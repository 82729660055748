import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';

import ModalBody from '../../Modal/ModalBody';
import Paragraph from '../../Paragraph/Paragraph';
import ModalButtons from '../../Modal/ModalButtons';
import { getCurrentAccountUid } from '../../../store/selectors/accountSelectors';
import {
  deleteCreditCard,
  fetchCreditCards,
} from '../../../store/actions/creditCard/creditCardActions';
import { changeAutoPaySettings } from '../../../store/actions/storageFee/storageFeeActions';
import creditCardTypes from '../../../util/enum/api/creditCardTypes';

const RemoveCreditCard = ({
  handleClose,
  handleNext,
  setErrorMessage,
  isDefaultForStorage,
  creditCard,
}) => {
  const dispatch = useDispatch();
  const accountUid = useSelector(getCurrentAccountUid);

  const onSuccess = () => {
    if (isDefaultForStorage) {
      dispatch(
        changeAutoPaySettings({
          accountUid,
          setting: {
            Autopay: false,
            CreditCardUid: creditCard.CreditCardUid,
          },
        }),
      );
    }

    dispatch(fetchCreditCards({ accountUid }));
  };
  const handleCreditCardRemove = () => {
    dispatch(
      deleteCreditCard({
        accountUid,
        creditCardUid: creditCard.CreditCardUid,
        handleNext,
        setErrorMessage,
        onSuccess,
      }),
    );
  };

  return (
    <ModalBody>
      <Paragraph>
        {i18next.t('creditCards.removeActionModalDescription')}
      </Paragraph>
      <Paragraph marginTop={10}>
        {i18next.t('creditCards.cardTypeAndNumberLabel', {
          cardType: i18next.t(
            `creditCards.creditCardTypes.${
              creditCardTypes[creditCard.CreditCardType]
            }`,
          ),
          last4Digits: creditCard?.Last4Digits,
        })}
      </Paragraph>
      {isDefaultForStorage && (
        <Paragraph marginTop={20}>
          {i18next.t('creditCards.removeActionModalAutoPayNote')}
        </Paragraph>
      )}
      <ModalButtons
        isHorizontal
        marginTop
        secondaryButtonProps={{
          onClick: handleClose,
          label: i18next.t('common.cancel'),
        }}
        primaryButtonProps={{
          onClick: handleCreditCardRemove,
          label: i18next.t('common.delete'),
        }}
      />
    </ModalBody>
  );
};

RemoveCreditCard.propTypes = {
  handleClose: PropTypes.func,
  handleNext: PropTypes.func,
  setErrorMessage: PropTypes.func,
  isDefaultForStorage: PropTypes.bool,
  creditCard: PropTypes.shape({
    CreditCardUid: PropTypes.string,
    CreditCardType: PropTypes.number,
    Last4Digits: PropTypes.number,
  }),
};

export default RemoveCreditCard;
