import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { StepContainer } from '../StepByStep';

const RouterStepByStep = ({ children, currentPath }) => {
  const history = useHistory();
  const childrenArray = React.Children.toArray(children);

  const goStepBack = (param) => history.push(param);
  const goStepForward = (param) => history.push(param);

  if (typeof children === 'function') {
    return (
      <StepContainer>
        {
          children({
            goStepBack,
            goStepForward,
          })[currentPath]
        }
      </StepContainer>
    );
  }

  return childrenArray.length ? (
    <StepContainer>{childrenArray}</StepContainer>
  ) : null;
};

RouterStepByStep.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  currentPath: PropTypes.string,
};

export default RouterStepByStep;
