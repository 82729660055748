import createReducer from '../../utils/createReducer';
import {
  BANK_ACCOUNT_ERROR,
  BANK_ACCOUNT_SUCCESS,
  BA_BY_ROUTING_NUM_ERROR,
  BA_BY_ROUTING_NUM_SUCCESS,
  NEW_WIRE_ACC_DETAILS_SET,
  SUBMIT_NEW_WIRE_ACC_ERROR,
  SUBMIT_NEW_WIRE_ACC_SUCCESS,
  BANK_ACCOUNT_DELETE_SUCCESS,
  BANK_ACCOUNT_DELETE_ERROR,
  CLEAR_BANK_ACCOUNT_DELETE_STATE,
  FETCH_SINGLE_BA_SUCCESS,
  FETCH_SINGLE_BA_ERROR,
  BANK_ACCOUNTS_BY_TYPE_FETCH_SUCCESS,
  BANK_ACCOUNTS_BY_TYPE_FETCH_ERROR,
  BA_BY_ROUTING_NUM_REGISTRATION_SUCCESS,
  BA_BY_ROUTING_NUM_REGISTRATION_ERROR,
  FETCH_BANK_ACCOUNTS_REGISTRATION_SUCCESS,
  FETCH_BANK_ACCOUNTS_REGISTRATION_ERROR,
  FETCH_OBI_MESSAGES_SUCCESS,
  FETCH_OBI_MESSAGES_ERROR,
  CLEAR_OBI_MESSAGES,
} from '../../actions/bankAccount/bankAccountActionConstants';

const initialState = {
  bankAccounts: {
    data: [],
    errorMessage: '',
  },
  obiMessage: {},
  bankAccountsByType: {
    data: [],
    errorMessage: '',
  },
  baDetailsByRoutingNum: {
    data: null,
    errorMessage: '',
  },
  newWireAccountData: {},
  newWireAccount: {
    data: {},
    error: '',
  },
  isSuccessfulDelete: null,
  singleBankAccount: {},
  error: '',
  registration: {
    bankAccounts: {
      data: [],
      errorMessage: '',
    },
    baDetailsByRoutingNum: {
      data: null,
      errorMessage: '',
    },
  },
};

export default createReducer(
  {
    [BANK_ACCOUNT_SUCCESS]: bankAccountSuccess,
    [BANK_ACCOUNT_ERROR]: bankAccountError,
    [BA_BY_ROUTING_NUM_SUCCESS]: setBaByRoutingNum,
    [BA_BY_ROUTING_NUM_ERROR]: setBaByRoutingNumError,
    [NEW_WIRE_ACC_DETAILS_SET]: setNewWireAccData,
    [SUBMIT_NEW_WIRE_ACC_SUCCESS]: setNewWireAccount,
    [SUBMIT_NEW_WIRE_ACC_ERROR]: setNewWireAccountError,
    [BANK_ACCOUNT_DELETE_SUCCESS]: setDeleteAccountSuccess,
    [BANK_ACCOUNT_DELETE_ERROR]: setDeleteAccountError,
    [CLEAR_BANK_ACCOUNT_DELETE_STATE]: clearBankAccountDeleteState,
    [FETCH_SINGLE_BA_SUCCESS]: setSingleBankAccount,
    [FETCH_SINGLE_BA_ERROR]: setBankAccountsError,
    [BANK_ACCOUNTS_BY_TYPE_FETCH_SUCCESS]: fetchBankAccountsByType,
    [BANK_ACCOUNTS_BY_TYPE_FETCH_ERROR]: fetchBankAccountsByTypeError,
    [BA_BY_ROUTING_NUM_REGISTRATION_SUCCESS]: setBaByRoutingNumRegistration,
    [BA_BY_ROUTING_NUM_REGISTRATION_ERROR]: setBaByRoutingNumRegistrationError,
    [FETCH_BANK_ACCOUNTS_REGISTRATION_SUCCESS]: bankAccountRegistrationSuccess,
    [FETCH_BANK_ACCOUNTS_REGISTRATION_ERROR]: bankAccountRegistrationError,
    [FETCH_OBI_MESSAGES_SUCCESS]: fetchObiMessage,
    [FETCH_OBI_MESSAGES_ERROR]: fetchObiMessageError,
    [CLEAR_OBI_MESSAGES]: clearObiMessages,
  },
  initialState,
);

function bankAccountSuccess(state, action) {
  return {
    ...state,
    bankAccounts: {
      ...state.bankAccounts,
      data: action.payload,
    },
  };
}

function bankAccountError(state, action) {
  return {
    ...state,
    bankAccounts: {
      ...state.bankAccounts,
      errorMessage: action.payload,
    },
  };
}

function setBankAccountsError(state, action) {
  return {
    ...state,
    error: action.payload,
  };
}

function setSingleBankAccount(state, action) {
  return {
    ...state,
    singleBankAccount: action.payload,
  };
}

function setBaByRoutingNum(state, { payload }) {
  return {
    ...state,
    baDetailsByRoutingNum: {
      ...state.baDetailsByRoutingNum,
      data: payload.routingNumData,
    },
    newWireAccountData: {
      ...state.newWireAccountData,
      country: payload.country,
    },
  };
}

function setBaByRoutingNumError(state, action) {
  return {
    ...state,
    baDetailsByRoutingNum: {
      ...state.baDetailsByRoutingNum,
      errorMessage: action.payload,
    },
  };
}

function setNewWireAccData(state, { payload }) {
  return {
    ...state,
    newWireAccountData: {
      ...state.newWireAccountData,
      ...payload,
    },
  };
}

function setNewWireAccount(state, { payload }) {
  return {
    ...state,
    newWireAccount: {
      ...state.newWireAccount,
      data: payload,
    },
  };
}

function setNewWireAccountError(state, { payload }) {
  return {
    ...state,
    newWireAccount: {
      ...state.newWireAccount,
      error: payload,
    },
  };
}

function setDeleteAccountSuccess(state) {
  return {
    ...state,
    isSuccessfulDelete: true,
  };
}
function setDeleteAccountError(state) {
  return {
    ...state,
    isSuccessfulDelete: false,
  };
}

function clearBankAccountDeleteState(state) {
  return {
    ...state,
    isSuccessfulDelete: null,
  };
}

function fetchBankAccountsByType(state, action) {
  return {
    ...state,
    bankAccountsByType: {
      data: action.payload,
    },
  };
}

function fetchBankAccountsByTypeError(state, action) {
  return {
    ...state,
    bankAccountsByType: {
      ...state.bankAccountsByType,
      errorMessage: action.payload,
    },
  };
}

function setBaByRoutingNumRegistration(state, { payload }) {
  return {
    ...state,
    registration: {
      ...state.registration,
      baDetailsByRoutingNum: {
        ...state.registration.baDetailsByRoutingNum,
        data: payload.routingNumData,
      },
      newWireAccountData: {
        ...state.registration.newWireAccountData,
        country: payload.country,
      },
    },
  };
}
function setBaByRoutingNumRegistrationError(state, action) {
  return {
    ...state,
    registration: {
      ...state.registration,
      baDetailsByRoutingNum: {
        ...state.registration.baDetailsByRoutingNum,
        errorMessage: action.payload,
      },
    },
  };
}

function bankAccountRegistrationSuccess(state, action) {
  return {
    ...state,
    registration: {
      ...state.registration,
      bankAccounts: {
        ...state.registration.bankAccounts,
        data: action.payload,
      },
    },
  };
}

function bankAccountRegistrationError(state, action) {
  return {
    ...state,
    registration: {
      ...state.registration,
      bankAccounts: {
        ...state.registration.bankAccounts,
        errorMessage: action.payload,
      },
    },
  };
}

function fetchObiMessage(state, action) {
  return {
    ...state,
    obiMessage: action.payload,
  };
}

function fetchObiMessageError(state, action) {
  return {
    ...state,
    error: action.payload.error,
  };
}

function clearObiMessages(state) {
  return {
    ...state,
    obiMessage: {},
  };
}
