import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import creditCardTypes from '../../util/enum/api/creditCardTypes';
import { formatDate } from '../../util/helpers/dateHelpers';
import RoundedRadio from '../InputFields/RoundedRadio';
import Label from '../Notes/Label';
import Paragraph from '../Paragraph/Paragraph';
import SingleColumnList from '../TwoColumnList/SingleColumnList';
import TypeList from '../TypeList/TypeList';
import Button from '../Button/Button';
import { BalanceCardIcon } from '../Portfolio/Balance/BalanceCard';
import AddCreditCardWizard from '../Modals/CreditCard/AddCreditCardWizard';
import { ADD_CREDIT_CARD_WIZARD } from '../../constants/sessionStorage';
import { removeFromSessionStorage } from '../../util/helpers/sessionStorageHelper';
import { variables } from '../../assets/styles/variables';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { uFlexCenter } from '../../assets/styles/utility';
import { ReactComponent as Caret } from '../../assets/images/svg/down.svg';
import { ReactComponent as Plus } from '../../assets/images/svg/plus.svg';
import { selectSettings } from '../../store/selectors/settingsSelectors';
import { formatMoneyNumeral } from '../../util/helpers/numeralHelpers';

export const AddCreditCardSection = styled.div`
  border: 1px solid ${themeColors.colorBorderPrimary};
  background-color: ${themeColors.colorBackgroundPrimary};
  box-shadow: ${variables.shadow.boxShadow};
  ${({ disabled }) =>
    disabled &&
    `opacity: 0.5;
      pointer-events: none;
      `}
`;

export const AddCreditCardIconAndTextWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const AddCreditCardElement = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0;
  box-shadow: none;
  padding: ${pxToRem(12.5)} ${pxToRem(16.5)};
  text-transform: capitalize;
  justify-content: flex-start;
  font-weight: 600;
  color: ${themeColors.colorTextPrimary};
  font-size: ${pxToRem(16)};
  width: 100%;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(16)};
  }
`;

export const AddCreditCardIcon = styled.div`
  ${uFlexCenter};
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};
  border-radius: 4px;
  background-color: ${themeColors.colorPrimary};
  box-shadow: ${variables.shadow.boxShadow};
  margin-right: ${pxToRem(10)};
  flex-shrink: 0;
  padding: ${pxToRem(3)};
  color: ${themeColors.colorWhite};

  svg {
    width: ${pxToRem(20)};
    height: ${pxToRem(20)};
    color: ${themeColors.colorWhite};
  }
`;

export const HorizontalLine = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${themeColors.colorInputBorder};
  border-width: 0;
`;

export const AddCreditCardNote = styled.p`
  padding: 0px ${pxToRem(12.5)} ${pxToRem(12.5)} ${pxToRem(16.5)};
`;

export const AddCreditCardButtonText = styled.span``;

export const AddOrChooseCreditCard = ({
  creditCards,
  values,
  isReachedTransactionLimit,
  setSelectedCreditCard,
}) => {
  const { t } = useTranslation();
  const [isAddCreditCardModalOpen, setIsAddCreditCardModalOpen] = useState(
    false,
  );
  const settings = useSelector(selectSettings);

  const handleChangeValue = (creditCardUid) =>
    setSelectedCreditCard(
      creditCards.find((card) => card.CreditCardUid === creditCardUid),
    );

  return (
    <>
      <Paragraph marginBottom={8} fontSize={16} bold>
        {t('storageFee.payStorageFeeByCardModal.selectACardForPayment')}
      </Paragraph>
      {!isEmpty(creditCards) && (
        <>
          <TypeList gridRowGap={9}>
            {creditCards.map((card) => (
              <Field
                key={card.CreditCardUid}
                id={card.CreditCardUid}
                type="radio"
                name="creditCard"
                component={RoundedRadio}
                value={card.CreditCardUid}
                selected={values.creditCard === card.CreditCardUid}
                style={{ padding: '6px 16px' }}
                disabled={card.IsExpired}
                onChange={(e) => handleChangeValue(e.target.value)}
              >
                <SingleColumnList>
                  <Label
                    text={t('creditCards.cardTypeAndNumberLabel', {
                      cardType: t(
                        `creditCards.creditCardTypes.${
                          creditCardTypes[card.CreditCardType]
                        }`,
                      ),
                      last4Digits: card.Last4Digits,
                    })}
                    disabled={card.IsExpired}
                  />
                  {!card.IsExpired && isReachedTransactionLimit && (
                    <Paragraph
                      color={themeColors.colorTextSecondary}
                      fontSize={13}
                      bold
                    >
                      {t(
                        'storageFee.payStorageFeeByCardModal.creditCardTransactionLimit',
                        {
                          transactionLimit: formatMoneyNumeral(
                            settings.CreditCardTransactionLimit,
                          ),
                        },
                      )}
                    </Paragraph>
                  )}
                  {card.IsExpired && (
                    <Paragraph
                      color={themeColors.colorError}
                      fontSize={13}
                      bold
                    >
                      {t('creditCards.expiredWithDate', {
                        expirationMonth: formatDate(card.ExpirationDate, 'MM'),
                        expirationYear: formatDate(card.ExpirationDate, 'yy'),
                      })}
                    </Paragraph>
                  )}
                </SingleColumnList>
              </Field>
            ))}
          </TypeList>
          <HorizontalLine />
        </>
      )}
      <AddCreditCardSection onClick={() => setIsAddCreditCardModalOpen(true)}>
        <AddCreditCardElement>
          <AddCreditCardIconAndTextWrapper>
            <AddCreditCardIcon>
              <Plus />
            </AddCreditCardIcon>
            <AddCreditCardButtonText>
              {t('creditCards.addNewCreditCard')}
            </AddCreditCardButtonText>
          </AddCreditCardIconAndTextWrapper>
          <BalanceCardIcon>
            <Caret />
          </BalanceCardIcon>
        </AddCreditCardElement>
        <AddCreditCardNote>
          {t('storageFee.payStorageFeeByCardModal.allMajorCardTypesAccepted')}
        </AddCreditCardNote>
      </AddCreditCardSection>
      <AddCreditCardWizard
        isOpen={isAddCreditCardModalOpen}
        onClose={() => {
          removeFromSessionStorage(ADD_CREDIT_CARD_WIZARD);
          setIsAddCreditCardModalOpen(false);
        }}
      />
    </>
  );
};

AddOrChooseCreditCard.propTypes = {
  creditCards: PropTypes.oneOfType([PropTypes.string, PropTypes.shape([])]),
  values: PropTypes.shape({
    creditCard: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  }),
  isReachedTransactionLimit: PropTypes.bool,
  setSelectedCreditCard: PropTypes.func,
};
