import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import themeColors from '../../assets/styles/themeColors';
import { uFlexColumn } from '../../assets/styles/utility';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';

const Brand = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 2fr 1.5fr;
  grid-column-gap: 1rem;
  width: 100%;
`;

const BrandDataCode = styled.div`
  ${uFlexColumn};
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.5;
  align-items: flex-start;
  color: ${themeColors.colorTextSecondary};
  width: 100%;
  font-weight: 600;
  flex-shrink: 0;
`;

const BrandDataSerial = styled.div`
  ${uFlexColumn};
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.5;
  text-align: center;
  color: ${themeColors.colorTextSecondary};
  width: 100%;
  font-weight: 600;
  flex-shrink: 0;
`;

const BrandDataWeight = styled.div`
  ${uFlexColumn};
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.5;
  align-items: flex-end;
  color: ${themeColors.colorTextSecondary};
  width: 100%;
  font-weight: 600;
  flex-shrink: 0;
`;

const BrandCode = styled.p`
  text-align: center;
  font-size: ${pxToRem(14)};
  line-height: 1.6;
  letter-spacing: 0;
  text-transform: uppercase;
  color: ${themeColors.colorTextSecondary};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(14)};
    line-height: 1.72;
  }
`;

const BrandSerial = styled.p`
  text-align: flex-start;
  font-size: ${pxToRem(14)};
  line-height: 1.6;
  letter-spacing: 0;
  text-transform: uppercase;
  color: ${themeColors.colorTextSecondary};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(14)};
    line-height: 1.72;
  }
`;

const BrandWeight = styled.p`
  justify-content: flex-end;
  text-align: right;
  font-size: ${pxToRem(14)};
  line-height: 1.6;
  letter-spacing: 0;
  text-transform: uppercase;
  color: ${themeColors.colorTextSecondary};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(14)};
    line-height: 1.72;
  }
`;

const BrandRow = ({ code, serial, weight }) => (
  <>
    <Brand>
      {code && (
        <BrandDataCode>
          <BrandCode>{code}</BrandCode>
        </BrandDataCode>
      )}
      {serial && (
        <BrandDataSerial>
          <BrandSerial>{serial}</BrandSerial>
        </BrandDataSerial>
      )}
      {weight && (
        <BrandDataWeight>
          <BrandWeight>{weight}</BrandWeight>
        </BrandDataWeight>
      )}
    </Brand>
  </>
);

BrandRow.propTypes = {
  code: PropTypes.string,
  serial: PropTypes.string,
  weight: PropTypes.string,
};

export default BrandRow;
