import { generateSessionOrderId } from './util/helpers/sinigfyd';

const loadSignifydDeviceFingerprintScript = () => {
  const existingScript = document.getElementById('sig-api');
  if (existingScript) {
    document.head.removeChild(existingScript);
  }
  const script = document.createElement('script');
  script.defer = true;
  script.dataset.orderSessionId = generateSessionOrderId();
  script.src = 'https://cdn-scripts.signifyd.com/api/script-tag.js';
  script.id = 'sig-api';
  script.type = 'text/javascript';
  document.head.appendChild(script);
};
export default loadSignifydDeviceFingerprintScript;
