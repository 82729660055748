import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BulletItem from '../../BulletItem/BulletItem';
import ModalBody from '../../Modal/ModalBody';
import ModalButtons from '../../Modal/ModalButtons';
import BulletList from '../../BulletList/BulletList';
import ModalTitle from '../../Modal/ModalTitle';
import Paragraph from '../../Paragraph/Paragraph';

const IRATransferOut = ({ handleBack, setModalTitle }) => {
  const { t } = useTranslation();

  useEffect(() => {
    setModalTitle(t('IRAWithdraw.transferOut.heading'));
  }, [setModalTitle, t]);

  return (
    <ModalBody>
      <ModalTitle>{t('IRAWithdraw.transferOut.title')}</ModalTitle>
      <Paragraph marginBottom={24}>
        {t('IRAWithdraw.transferOut.toTransfer')}
        <strong>{t('IRAWithdraw.transferOut.contact')}</strong>
        {t('IRAWithdraw.transferOut.complete')}
      </Paragraph>
      <Paragraph marginBottom={24}>
        {t('IRAWithdraw.transferOut.whenCompleted')}
      </Paragraph>
      <ModalTitle>{t('IRAWithdraw.transferOut.pleaseNote')}</ModalTitle>
      <BulletList>
        <BulletItem text={t('IRAWithdraw.transferOut.deliveryInstructions')} />
        <BulletItem text={t('IRAWithdraw.transferOut.notRequired')} />
        <BulletItem text={t('IRAWithdraw.transferOut.processingTime')} />
      </BulletList>

      <ModalButtons
        showOnlyPrimary
        primaryButtonProps={{
          size: 'lg',
          onClick: handleBack,
          label: t('common.back'),
        }}
      />
    </ModalBody>
  );
};

IRATransferOut.propTypes = {
  handleBack: PropTypes.func,
  setModalTitle: PropTypes.func,
};

export default IRATransferOut;
