import React from 'react';
import PropTypes from 'prop-types';
import Restriction from './RestrictionModal/Restriction';
import Modal from '../Modal/Modal';

const RestrictedPermissionModal = ({
  isOpen,
  handleClose,
  title,
  setIsSupportModalOpen,
}) => (
  <>
    <Modal title={title} isOpen={isOpen} size="sm" onClose={handleClose}>
      <Restriction
        onRestrictionModalButtonClick={handleClose}
        setIsSupportModalOpen={setIsSupportModalOpen}
        hasSupportModalOpening
      />
    </Modal>
  </>
);

RestrictedPermissionModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  setIsSupportModalOpen: PropTypes.func,
};

export default RestrictedPermissionModal;
