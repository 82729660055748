import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import {
  getCurrentAccount,
  selectAccountAddresses,
  selectNagDashboard,
} from '../../store/selectors/accountSelectors';
import {
  selectAuthUser,
  selectUserPhoneNumbers,
} from '../../store/selectors/userSelectors';
import { parseEnumType } from '../helpers/enumMappers';
import { accountTypeKeys } from '../enum/api/accountTypes';
import { DEPOSIT_STATUS_TYPES } from '../../constants/nagDashboardContants';
import { useGetIsImpersonating } from './useGetIsImpersonating';

const useGtmHook = () => {
  const currentAccount = useSelector(getCurrentAccount);
  const userInfo = useSelector(selectAuthUser);
  const nagDashboard = useSelector(selectNagDashboard);
  const addresses = useSelector(selectAccountAddresses);

  const phoneNumbers = useSelector(selectUserPhoneNumbers);

  const path = window.location.pathname;

  const { isImpersonating } = useGetIsImpersonating();

  const defaultAddress = addresses?.find(
    (address) => address.IsDefaultMailingAddress,
  );

  const gtmEvent = (eventName, eventData) => {
    const accountData =
      currentAccount && currentAccount.AccountUid && currentAccount.AccountCode
        ? {
            account_uid: currentAccount.AccountUid,
            account_code: currentAccount.AccountCode,
          }
        : null;

    const userData =
      userInfo && userInfo.UserUid && userInfo.Username && userInfo.Email
        ? {
            user_uid: userInfo.UserUid,
            customer_uid: userInfo.CustomerUid,
            username: userInfo.Username,
            email: userInfo.Email,
          }
        : null;

    const data = {
      ...accountData,
      ...userData,
      ...eventData,
    };

    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
        eventData: data,
      },
    });
  };

  const gtmCustomEvent = (eventName, eventData) => {
    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
        ...eventData,
      },
    });
  };

  const gtmEventUser = (eventName) => {
    if (userInfo) {
      const data = {
        email: userInfo?.Email,
        user_uid: userInfo?.UserUid,
      };

      TagManager.dataLayer({
        dataLayer: {
          event: eventName,
          eventData: data,
        },
      });
    }
  };

  const gtmEventCustomer = (eventName) => {
    if (userInfo) {
      const data = {
        email: userInfo?.Email,
        customerU_uid: userInfo?.CustomerUid,
      };

      TagManager.dataLayer({
        dataLayer: {
          event: eventName,
          ...data,
        },
      });
    }
  };

  const gtmDialogScreenView = (pageData) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'screen_view',
        page: { path, ...pageData },
      },
    });
  };

  const gtmScreenView = (pageData) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'screen_view',
        page: { ...pageData, referrer: document.referrer },
      },
    });
  };

  const appLoginGtmEvent = (eventName) => {
    if (currentAccount && userInfo) {
      const dataEvent = {
        account: {
          type: parseEnumType(accountTypeKeys, currentAccount?.AccountType),
          id: currentAccount?.AccountUid,
        },
        user: {
          is_account_owner: !isImpersonating,
          id: userInfo?.UserUid,
          email: userInfo?.Email,
          first_name: userInfo?.FirstName,
          last_name: userInfo?.LastName,
          phone: phoneNumbers[0]?.LocalNumber || '',
          phone_area_code: phoneNumbers[0]?.AreaCode || '',
        },
      };
      TagManager.dataLayer({
        dataLayer: {
          event: `app.${eventName}`,
          ...dataEvent,
        },
      });
    }
  };

  const appGtmEvent = (eventName) => {
    if (currentAccount && userInfo) {
      const dataEvent = {
        account: {
          type: parseEnumType(accountTypeKeys, currentAccount?.AccountType),
          is_funded:
            nagDashboard.DepositStatusType === DEPOSIT_STATUS_TYPES.Completed,
          id: currentAccount?.AccountUid,
        },
        user: {
          is_account_owner: !isImpersonating,
          id: userInfo?.UserUid,
          email: userInfo?.Email,
          first_name: userInfo?.FirstName,
          last_name: userInfo?.LastName,
          address: defaultAddress?.AddressLine || '',
          address_2: defaultAddress?.AddressLine2 || '',
          city: defaultAddress?.City || '',
          state: defaultAddress?.StateRegion || '',
          zip: defaultAddress?.PostalCode || '',
          country: defaultAddress?.CountryIso3Code || '',
          phone: phoneNumbers[0]?.LocalNumber || '',
          phone_area_code: phoneNumbers[0]?.AreaCode || '',
        },
      };
      TagManager.dataLayer({
        dataLayer: {
          event: `app.${eventName}`,
          ...dataEvent,
        },
      });
    }
  };

  const ecommerceGtmEvent = (eventName, dataEvent) => {
    TagManager.dataLayer({
      dataLayer: {
        event: `ecommerce.${eventName}`,
        ...dataEvent,
      },
    });
  };

  const bankAccountGtmEvent = (eventName, accountType, type, country) => {
    const dataEvent = {
      bank: {
        account_type: accountType,
        type,
        country: country || '',
      },
    };
    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
        ...dataEvent,
      },
    });
  };

  const fundGtmEvent = (eventName, eventData) => {
    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
        ...eventData,
      },
    });
  };

  return {
    gtmEvent,
    gtmScreenView,
    gtmDialogScreenView,
    gtmEventUser,
    gtmEventCustomer,
    gtmCustomEvent,
    appGtmEvent,
    ecommerceGtmEvent,
    appLoginGtmEvent,
    bankAccountGtmEvent,
    fundGtmEvent,
  };
};

export default useGtmHook;
