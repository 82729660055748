import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../../Button/Button';
import Paragraph from '../../Paragraph/Paragraph';
import { selectMailInLearnMoreLink } from '../../../store/selectors/settingsSelectors';
import Anchor from '../../Anchor/Anchor';
import { pxToRem } from '../../../assets/styles/helper';

const MailInCtaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${pxToRem(10)};
`;

const MailInCtaButton = styled.div`
  display: flex;
`;

const MailInCtaLink = styled(Anchor)``;

const MailInCta = ({ setInMailInStorageModalOpen }) => {
  const { t } = useTranslation();
  const learnMoreLink = useSelector(selectMailInLearnMoreLink);

  return (
    <MailInCtaContainer>
      <Paragraph>{t('portfolio.mailIn.label')}</Paragraph>
      <MailInCtaButton>
        <Button
          variant="primary"
          textTransform="capitalize"
          onClick={() => {
            setInMailInStorageModalOpen(true);
          }}
        >
          {t('portfolio.mailIn.cta')}
        </Button>
      </MailInCtaButton>
      {learnMoreLink && (
        <MailInCtaLink
          bold
          marginBottom={32}
          type="website"
          value={learnMoreLink}
          text={t('portfolio.mailIn.link')}
        />
      )}
    </MailInCtaContainer>
  );
};

MailInCta.propTypes = {
  setInMailInStorageModalOpen: PropTypes.func,
};

export default MailInCta;
