import { getRequest, postRequest, replaceInUrl } from './index';
import apiEndpoints from './apiEndpoints';

export const getUsernames = ({ email, token }) =>
  getRequest(apiEndpoints.authentications.getUsernames, {
    emailorusername: email,
    recaptchaToken: token,
  });

export const attemptLogin = (payload) =>
  postRequest(apiEndpoints.authentications.login, payload);

export const updateSecurityAnswer = (payload) =>
  postRequest(apiEndpoints.authentications.confirmSecurityQuestion, payload);

export const refreshTokenRequest = (payload) =>
  postRequest(apiEndpoints.authentications.refreshToken, payload);

export const logoutUserRequest = (userUid) =>
  postRequest(
    replaceInUrl(apiEndpoints.users.logout, {
      userUid,
    }),
  );

export const generateTokenRequest = (payload) =>
  postRequest(apiEndpoints.authentications.generateToken, payload);

export const attemptRegistration = (payload) =>
  postRequest(apiEndpoints.authentications.registration, payload, {
    recaptchaToken: payload.BasicAccountRequest.RecaptchaToken,
  });
