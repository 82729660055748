import styled from 'styled-components';
import { ReactComponent as DollarIcon } from '../../../../assets/images/svg/dollar-round.svg';
import {
  mediaBelow,
  pxToRem,
  pxToRemMd,
} from '../../../../assets/styles/helper';
import { variables } from '../../../../assets/styles/variables';

const VerifyBankAccountImage = styled(DollarIcon)`
  width: ${pxToRem(86)};
  height: ${pxToRem(86)};
  margin: 0 auto ${pxToRem(25)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    width: ${pxToRemMd(56)};
    height: ${pxToRemMd(56)};
    margin: 0 auto ${pxToRemMd(25)};
  }
`;

export default VerifyBankAccountImage;
