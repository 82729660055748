import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import Modal from '../../../Modal/Modal';
import { buttonizeDiv } from '../../../../util/buttonizeDiv';
import SupportModal from '../../SupportModal/SupportModal';
import ModalBody from '../../../Modal/ModalBody';
import ModalButtons from '../../../Modal/ModalButtons';
import Paragraph from '../../../Paragraph/Paragraph';
import AnchorOnClick from '../../../Anchor/AnchorOnClick';

const VerifyErrorModal = ({ isOpen, onClose }) => {
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);
  const { t } = useTranslation();
  const closeSupportModal = () => {
    setIsSupportModalOpen(false);
  };

  if (isSupportModalOpen) {
    return (
      <SupportModal
        avoidClearingStorage
        isOpen={isSupportModalOpen}
        close={closeSupportModal}
        goBack={closeSupportModal}
      />
    );
  }

  return (
    <Modal
      title={t('bankAccounts.errorModal.title')}
      close={onClose}
      isOpen={isOpen}
      size="sm"
    >
      <ModalBody>
        <Paragraph>
          {t('bankAccounts.errorModal.text')}{' '}
          <AnchorOnClick {...buttonizeDiv(() => setIsSupportModalOpen(true))}>
            {t('bankAccounts.errorModal.link')}
          </AnchorOnClick>
          .
        </Paragraph>

        <ModalButtons
          isHorizontal
          marginTop
          secondaryButtonProps={{
            label: t('common.cancel'),
            onClick: onClose,
          }}
          primaryButtonProps={{ onClick: onClose, label: t('common.ok') }}
        />
      </ModalBody>
    </Modal>
  );
};

VerifyErrorModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default VerifyErrorModal;
