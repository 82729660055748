import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Modal from '../../Modal/Modal';
import ModalBody from '../../Modal/ModalBody';
import Paragraph from '../../Paragraph/Paragraph';
import ModalButtons from '../../Modal/ModalButtons';
import { selectSettings } from '../../../store/selectors/settingsSelectors';
import { authScopeStringGetHelper } from '../../../util/helpers/authScopeHelpers';
import { JWT_TOKEN } from '../../../constants/localStorage';
import {
  getCurrentAccountCode,
  getCurrentAccountUid,
  selectNagDashboard,
} from '../../../store/selectors/accountSelectors';
import { UPGRADE_STATUS_TYPES } from '../../../constants/nagDashboardContants';

const BasicAccountRestristionsModal = ({
  isOpen,
  close,
  title,
  description,
  isHiddenButton,
}) => {
  const { t } = useTranslation();
  const { RegistrationUrlV2 } = useSelector(selectSettings);
  const token = authScopeStringGetHelper(JWT_TOKEN);
  const accountUid = useSelector(getCurrentAccountUid);
  const accountCode = useSelector(getCurrentAccountCode);
  const nagDashboard = useSelector(selectNagDashboard);
  const isUpgradeProgress =
    nagDashboard.UpgradeStatusType === UPGRADE_STATUS_TYPES.InProgress;

  const registrationApi = RegistrationUrlV2;

  const goToRegistrationPage = () => {
    window.location.replace(
      `${registrationApi}/choose-application?accountCode=${accountCode}&accountUid=${accountUid}&token=${token}`,
    );
  };

  return (
    <>
      <Modal title={title} isOpen={isOpen} size="sm" close={close}>
        <ModalBody>
          <Paragraph>{description}</Paragraph>
          {isHiddenButton ? (
            ''
          ) : (
            <ModalButtons
              isVertical
              marginTop
              showOnlyPrimary
              primaryButtonProps={{
                label: isUpgradeProgress
                  ? t('common.continue')
                  : t('common.getStarted'),
                onClick: goToRegistrationPage,
              }}
            />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

BasicAccountRestristionsModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  isHiddenButton: PropTypes.bool,
};

export default BasicAccountRestristionsModal;
