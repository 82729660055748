import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import {
  getClientToken,
  submitCreditCardRequest,
  deleteCreditCardRequest,
  getCreditCardsRequest,
} from '../../request/creditCardRequest';
import {
  FETCH_CLIENT_TOKEN,
  SUBMIT_CREDIT_CARD,
  CREDIT_CARD_DELETE,
  CREDIT_CARD_FETCH,
} from '../actions/creditCard/creditCardActionConstants';
import {
  fetchClientTokenSuccess,
  fetchClientTokenError,
  submitCreditCardError,
  deleteCreditCardError,
  deleteCreditCardSuccess,
  fetchCreditCardsError,
  fetchCreditCardsSuccess,
  submitCreditCardSuccess,
} from '../actions/creditCard/creditCardActions';
import { rejectErrorCodeHelper } from '../../util/helpers/rejectErrorCodeHelper';

function* fetchClientToken({ payload }) {
  try {
    const { data } = yield call(getClientToken, payload.accountUid);
    yield put(fetchClientTokenSuccess(data));
  } catch (error) {
    yield put(fetchClientTokenError(error));
  }
}

function* submitCreditCard({
  payload: { AccountUid, data, handleNext, onSuccess },
}) {
  try {
    yield call(submitCreditCardRequest, AccountUid, data);
    yield call(onSuccess);
    yield put(submitCreditCardSuccess(data));
    yield put(submitCreditCardError(''));
  } catch (error) {
    yield put(submitCreditCardError(error));

    yield call(handleNext);
  }
}

function* fetchCreditCards({ payload }) {
  try {
    const { data } = yield call(getCreditCardsRequest, payload.accountUid);

    yield put(fetchCreditCardsSuccess(data));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchCreditCardsError(errorMessage));
  }
}

function* deleteCreditCards({ payload }) {
  const {
    accountUid,
    creditCardUid,
    handleNext,
    onSuccess,
    setErrorMessage,
  } = payload;

  try {
    const { data } = yield call(
      deleteCreditCardRequest,
      accountUid,
      creditCardUid,
    );
    yield put(deleteCreditCardSuccess(data));
    if (payload?.onSuccess) {
      yield call(onSuccess);
    }
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(deleteCreditCardError(errorMessage));
    yield call(setErrorMessage, errorMessage);
  } finally {
    yield call(handleNext);
  }
}

export default function* creditCardSaga() {
  yield all([
    takeLatest(FETCH_CLIENT_TOKEN, fetchClientToken),
    takeLatest(SUBMIT_CREDIT_CARD, submitCreditCard),
    takeLatest(CREDIT_CARD_FETCH, fetchCreditCards),
    takeLatest(CREDIT_CARD_DELETE, deleteCreditCards),
  ]);
}
