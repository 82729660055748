import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  TWO_FA_QR_CODE_PAGE,
  TWO_FA_CHOOSE_PHONE_METHOD,
  TWO_FA_VERIFY_CODE,
  INVITE_USER_TWO_FA_VERIFY_CODE,
  INVITE_USER_TWO_FA_QR_CODE_PAGE,
  INVITE_USER_TWO_FA_CHOOSE_PHONE_METHOD,
} from '../../../constants/pages';
import TypeCard from '../../../components/TypeCard/TypeCard';
import { fetchTwoFaTypes } from '../../../store/actions/twoFA/twoFAActions';
import { selectTwoFaTypes } from '../../../store/selectors/twoFASelector';
import { selectIsLoadingByActionType } from '../../../store/selectors/loadingSelectors';
import { TWO_FA_TYPES_LOADING } from '../../../store/actions/twoFA/twoFAActionConstants';
import BlockSectionLoaderWrap from '../../../components/Loader/BlockSectionWrapper';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import { twoFaTypesEnum } from '../../../util/enum/api/twoFATypes';
import Paragraph from '../../../components/Paragraph/Paragraph';
import TypeList from '../../../components/TypeList/TypeList';
import AuthCard from '../../../components/Auth/AuthCard';
import Auth from '../../../components/Auth/Auth';
import SingleColumnList from '../../../components/TwoColumnList/SingleColumnList';

const TwoFASetupChooseMethodPage = ({
  goStepForward,
  setTwoFaValues,
  twoFaValue,
  getTwoFaCode,
  user,
  isInviteUserFlow,
}) => {
  const dispatch = useDispatch();
  const twoFaTypes = useSelector(selectTwoFaTypes);
  const isLoading = useSelector(
    selectIsLoadingByActionType(TWO_FA_TYPES_LOADING),
  );
  const [types, setTypes] = useState([]);
  const { UserUid, Username } = user;

  const [error, setError] = useState(null);

  const getTypeValues = (type) => {
    switch (type) {
      case twoFaTypes[0]:
        return {
          value: parseEnumType(twoFaTypesEnum, 1),
          title: i18next.t('twoFa.loginSetup.chooseMethod.authenticator.title'),
          description: i18next.t(
            'twoFa.loginSetup.chooseMethod.authenticator.description',
          ),
        };

      case twoFaTypes[1]:
        return {
          value: parseEnumType(twoFaTypesEnum, 2),
          title: i18next.t('twoFa.loginSetup.chooseMethod.phone.title'),
          description: i18next.t(
            'twoFa.loginSetup.chooseMethod.phone.description',
          ),
        };

      case twoFaTypes[2]:
        return {
          value: parseEnumType(twoFaTypesEnum, 3),
          title: i18next.t('twoFa.loginSetup.chooseMethod.email.title'),
          description: i18next.t(
            'twoFa.loginSetup.chooseMethod.email.description',
          ),
        };

      default:
        break;
    }
  };

  useEffect(() => {
    const types = twoFaTypes.map((type) => getTypeValues(type));

    if (!isEmpty(types)) {
      setTypes(types);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twoFaTypes]);

  useEffect(() => {
    dispatch(fetchTwoFaTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const chooseMethod = (type) => {
    setTwoFaValues({ AuthenticationMethodType: type });
  };

  useEffect(() => {
    if (!isEmpty(twoFaValue)) {
      const { AuthenticationMethodType } = twoFaValue;

      switch (AuthenticationMethodType) {
        case parseEnumType(twoFaTypesEnum, 3):
          getTwoFaCode({
            userUid: UserUid,
            requestData: {
              AuthenticationMethodType,
              Username,
            },
            onError,
            onSuccess: goForward,
          });
          goStepForward(
            isInviteUserFlow
              ? INVITE_USER_TWO_FA_VERIFY_CODE
              : TWO_FA_VERIFY_CODE,
          );
          break;

        case parseEnumType(twoFaTypesEnum, 1):
          goStepForward(
            isInviteUserFlow
              ? INVITE_USER_TWO_FA_QR_CODE_PAGE
              : TWO_FA_QR_CODE_PAGE,
          );
          break;

        case parseEnumType(twoFaTypesEnum, 2):
          goStepForward(
            isInviteUserFlow
              ? INVITE_USER_TWO_FA_CHOOSE_PHONE_METHOD
              : TWO_FA_CHOOSE_PHONE_METHOD,
          );
          break;

        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twoFaValue]);

  const onError = (error) => {
    setError(error);
  };

  const goForward = () => {
    goStepForward(
      isInviteUserFlow ? INVITE_USER_TWO_FA_VERIFY_CODE : TWO_FA_VERIFY_CODE,
    );
  };

  return (
    <Auth>
      <AuthCard title={i18next.t('twoFa.loginSetup.chooseMethod.title')}>
        <Paragraph marginBottom={48}>
          {i18next.t('twoFa.loginSetup.chooseMethod.description')}
        </Paragraph>
        <BlockSectionLoaderWrap isLoading={isLoading}>
          <TypeList>
            {types.map((type) => (
              <TypeCard
                onClick={() => chooseMethod(type.value)}
                title={type.title}
                description={type.description}
                key={type.value}
              />
            ))}
          </TypeList>
          <SingleColumnList>
            {error && (
              <Paragraph isError marginTop={8}>
                <strong>{error}</strong>
              </Paragraph>
            )}
          </SingleColumnList>
        </BlockSectionLoaderWrap>
      </AuthCard>
    </Auth>
  );
};

TwoFASetupChooseMethodPage.propTypes = {
  goStepForward: PropTypes.func,
  setTwoFaValues: PropTypes.func,
  getTwoFaCode: PropTypes.func,
  twoFaValue: PropTypes.shape({
    AuthenticationMethodType: PropTypes.string,
  }),
  user: PropTypes.shape({
    UserUid: PropTypes.string,
    Username: PropTypes.string,
  }),
  isInviteUserFlow: PropTypes.bool,
};
export default TwoFASetupChooseMethodPage;
