import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import themeColors from '../../assets/styles/themeColors';
import { uFlexColumn } from '../../assets/styles/utility';

export const FormCardContainer = styled.div`
  padding: ${pxToRem(30)} ${pxToRem(30)} ${pxToRem(40)};
  box-shadow: ${variables.shadow.boxShadow};
  ${(props) => props.css};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(16)} ${pxToRemMd(16)} ${pxToRemMd(32)};
  }
`;

export const FormCardTitle = styled.h3`
  font-size: ${pxToRem(24)};
  line-height: 1.3;
  font-weight: 600;
  margin-bottom: ${pxToRem(16)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(16)};
    line-height: 1.5;
    margin-bottom: ${pxToRemMd(8)};
    font-weight: bold;
  }
`;

export const FormCardSubtitle = styled.h4`
  ${uFlexColumn};
  color: ${themeColors.colorTextSecondary};
  margin-bottom: ${pxToRem(44)};
  max-width: ${pxToRem(703)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-bottom: ${pxToRemMd(27)};
    max-width: 100%;
  }
`;
const FormCard = ({ title, subtitle, children, ...props }) => (
  <FormCardContainer {...props}>
    {title && <FormCardTitle>{title}</FormCardTitle>}
    {subtitle && <FormCardSubtitle>{subtitle}</FormCardSubtitle>}
    {children}
  </FormCardContainer>
);

FormCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
};

export default FormCard;
