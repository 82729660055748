import React from 'react';
import PropTypes from 'prop-types';

const CustomizedDot = ({ cx, cy }) => (
  <g>
    <filter
      id="dropshadow"
      width="110%"
      height="110%"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      />
      <feOffset dy="4" />
      <feGaussianBlur stdDeviation="4" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow"
        result="shape"
      />
    </filter>
    <circle
      cx={cx}
      cy={cy}
      r={10}
      fill="#fff"
      pointerEvents="none"
      style={{ filter: 'url(#dropshadow)' }}
    />
    <circle cx={cx} cy={cy} r={3} fill="#006bc3" pointerEvents="none" />
  </g>
);
CustomizedDot.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
};

export default CustomizedDot;
