import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as ResendIcon } from '../../../assets/images/svg/resend.svg';
import Paragraph from '../../../components/Paragraph/Paragraph';
import { uButtonClear, uOutlineNone } from '../../../assets/styles/utility';
import themeColors from '../../../assets/styles/themeColors';
import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import { variables } from '../../../assets/styles/variables';

export const ResendCodeButton = styled.button`
  ${uOutlineNone};
  ${uButtonClear};
  display: flex;
  align-items: center;
  text-decoration: underline;
  color: ${themeColors.colorPrimary};
  font-weight: 600;
  cursor: pointer;
`;

const ResendCodeIcon = styled(ResendIcon)`
  width: ${pxToRem(22)};
  height: ${pxToRem(22)};
  margin-right: ${pxToRem(8)};
  fill: ${themeColors.colorPrimary};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    width: ${pxToRemMd(22)};
    height: ${pxToRemMd(22)};
    margin-right: ${pxToRemMd(8)};
  }
`;

const ResendCodeParagraph = styled(Paragraph)`
  color: ${themeColors.colorPrimary};
  font-weight: 600;
`;

const ResendCode = ({ onClick }) => (
  <ResendCodeButton onClick={onClick} type="button">
    <ResendCodeIcon />
    <ResendCodeParagraph>Send a new code</ResendCodeParagraph>
  </ResendCodeButton>
);

ResendCode.propTypes = {
  onClick: PropTypes.func,
};

export default ResendCode;
