import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from '../../Modal/Modal';
import StepByStep from '../../StepByStep';
import CancelPendingTransaction from './CancelPendingTransaction';
import Status from '../Status';

const steps = [CancelPendingTransaction, Status];

const CancelPendingTransactionModal = ({
  isOpen,
  handleClose,
  cancelTransactionData,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      size="sm"
      title={t('portfolio.pendingTransactions.modal.title')}
      close={handleClose}
      isOpen={isOpen}
    >
      <StepByStep saveStepDisabled>
        {({ goStepForward, goStepBack }) =>
          steps.map((step) =>
            React.createElement(step, {
              handleBack: goStepBack,
              handleNext: goStepForward,
              handleClose,
              setError,
              hasError: !!error,
              text: error || t('portfolio.pendingTransactions.status.success'),
              backButtonText: t('common.ok'),
              onButtonClick: handleClose,
              cancelTransactionData,
            }),
          )
        }
      </StepByStep>
    </Modal>
  );
};

CancelPendingTransactionModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  cancelTransactionData: PropTypes.shape({
    paymentUid: PropTypes.string,
    accountUid: PropTypes.string,
  }),
};

export default CancelPendingTransactionModal;
