import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash.isempty';

import SelectField from '../../InputFields/SelectField';
import NumberField from '../../InputFields/NumberField';
import useGetCountries from '../../../util/hooks/useGetCountries';
import {
  fetchBaByRoutingNum,
  updateNewWireAccDetails,
} from '../../../store/actions/bankAccount/bankAccountActions';
import NewWireWithdrawalHeading from './NewWireWithdrawalHeading';
import TextField from '../../InputFields/TextField';
import { USA_COUNTRY_LABEL } from '../../../util/constants';
import {
  deleteWizardKey,
  getWizardContent,
  setWizardContent,
} from '../../../util/helpers/wizardHelpers';
import SectionLoader from '../../Loader/SectionLoader';
import {
  COUNTRY_SELECT_MODAL_INFO,
  NON_US_COUNTRY_DATA,
  NON_US_COUNTRY_SELECT_MODAL_INFO,
  SHOW_NON_US_MODAL,
} from '../../../constants/sessionStorage';
import { useSessionStorageState } from '../../../util/hooks/useSessionStorageState';
import { internationalBankAccountValidationSchema } from '../../../validation/internationalBankAccountValidationSchema';
import { bankAccountAddressValidationSchema } from '../../../validation/bankAccountAddressValidationSchema';
import ModalBody from '../../Modal/ModalBody';
import ModalButtons from '../../Modal/ModalButtons';
import FormContainer from '../../FormContainer/FormContainer';
import TwoColumnList from '../../TwoColumnList/TwoColumnList';

const CountrySelectModal = ({ cancel, sbsForward }) => {
  const countries = useGetCountries();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showNonUsModal, setShowNonUsModal] = useSessionStorageState(
    SHOW_NON_US_MODAL,
    false,
  );

  const [isLoading, setIsLoading] = useState(false);

  const [nonUsCountryData, setNonUsCountryData] = useSessionStorageState(
    NON_US_COUNTRY_DATA,
    {},
  );

  const handleNext = () => {
    setIsLoading(false);
    sbsForward();
  };

  const handleCountrySubmit = (
    { bankAbaOrRoutingNum, countrySelect, bankName, swiftCode },
    { setFieldError },
  ) => {
    setWizardContent(COUNTRY_SELECT_MODAL_INFO, {
      countrySelect,
      bankAbaOrRoutingNum,
      bankName,
      swiftCode,
    });

    if (countrySelect.value === USA_COUNTRY_LABEL) {
      setIsLoading(true);
      dispatch(
        fetchBaByRoutingNum({
          side: 'wire',
          routingNum: bankAbaOrRoutingNum,
          country: countrySelect.value,
          meta: {
            goNextModal: handleNext,
            setIsLoading,
            setFieldError,
            fieldName: 'bankAbaOrRoutingNum',
          },
        }),
      );
      return;
    }

    setWizardContent(NON_US_COUNTRY_DATA, {
      bankAbaOrRoutingNum,
      country: countrySelect.value,
      bankName,
      swiftCode,
    });

    setNonUsCountryData({
      bankAbaOrRoutingNum,
      country: countrySelect.value,
      bankName,
      swiftCode,
    });

    setWizardContent(SHOW_NON_US_MODAL, true);
    setShowNonUsModal(true);
  };

  const handleNonUsSubmit = (values) => {
    setWizardContent(NON_US_COUNTRY_SELECT_MODAL_INFO, {
      ...values,
      ...nonUsCountryData,
    });

    dispatch(
      updateNewWireAccDetails({
        ...values,
        ...nonUsCountryData,
      }),
    );
    sbsForward();
  };

  const initialValues = getWizardContent(COUNTRY_SELECT_MODAL_INFO) || {
    countrySelect: {
      label: USA_COUNTRY_LABEL,
      value: USA_COUNTRY_LABEL,
    },
    bankAbaOrRoutingNum: '',
    bankName: '',
    swiftCode: '',
  };

  const nonUsInitialValues = getWizardContent(
    NON_US_COUNTRY_SELECT_MODAL_INFO,
  ) || {
    bankStreetAddress: '',
    bankCity: '',
    bankState: '',
    bankPostalCode: '',
  };

  const renderForms = () =>
    !showNonUsModal ? (
      <Formik
        initialValues={initialValues}
        onSubmit={handleCountrySubmit}
        validationSchema={internationalBankAccountValidationSchema}
        enableReinitialize
        initialTouched={{
          bankAbaOrRoutingNum: true,
        }}
      >
        {({ submitForm, values, setFieldValue }) => (
          <Form>
            <FormContainer>
              <SelectField
                name="countrySelect"
                label={t('bankAccounts.bankCountryLabel')}
                disabled={!countries.length}
                options={countries.map((country) => ({
                  value: country.Caption,
                  label: country.Caption,
                }))}
              />
              {!isEmpty(values.countrySelect) &&
              values.countrySelect.value === USA_COUNTRY_LABEL ? (
                <Field
                  component={NumberField}
                  name="bankAbaOrRoutingNum"
                  label={t('bankAccounts.abaRoutingNumberLabel')}
                  // added onKeyUp handler because NumberField parses string into integer and it cant start with zero
                  onKeyUp={({ target: { value } }) =>
                    setFieldValue('bankAbaOrRoutingNum', value)
                  }
                />
              ) : (
                <>
                  <Field
                    component={TextField}
                    name="bankName"
                    label={t('bankAccounts.bankNameLabel')}
                  />
                  <Field
                    component={TextField}
                    name="swiftCode"
                    label={t('bankAccounts.swiftCodeLabel')}
                  />
                </>
              )}
            </FormContainer>
            <ModalButtons
              isHorizontal
              marginTop
              secondaryButtonProps={{
                label: t('common.cancel'),
                onClick: cancel,
              }}
              primaryButtonProps={{ onClick: submitForm }}
            />
          </Form>
        )}
      </Formik>
    ) : (
      <Formik
        initialValues={nonUsInitialValues}
        onSubmit={handleNonUsSubmit}
        enableReinitialize
        validationSchema={bankAccountAddressValidationSchema}
      >
        {({ submitForm }) => (
          <Form>
            <FormContainer>
              <Field
                component={TextField}
                name="bankStreetAddress"
                label={t('bankAccounts.streetAddressLabel')}
              />
              <Field
                component={TextField}
                name="bankCity"
                label={t('bankAccounts.cityLabel')}
              />
              <TwoColumnList>
                <Field
                  component={TextField}
                  name="bankState"
                  label={t('bankAccounts.stateProvinceLabel')}
                />
                <Field
                  component={TextField}
                  name="bankPostalCode"
                  label={t('bankAccounts.postalCodeLabel')}
                />
              </TwoColumnList>
            </FormContainer>
            <ModalButtons
              isHorizontal
              marginTop
              secondaryButtonProps={{
                onClick: () => {
                  deleteWizardKey(SHOW_NON_US_MODAL);
                  setShowNonUsModal(false);
                },
              }}
              primaryButtonProps={{ onClick: submitForm }}
            />
          </Form>
        )}
      </Formik>
    );

  return (
    <ModalBody>
      <SectionLoader isLoading={isLoading}>
        <NewWireWithdrawalHeading
          bankDetailsParagraph={!showNonUsModal}
          bankMailingParagraph={showNonUsModal}
        />
        {renderForms()}
      </SectionLoader>
    </ModalBody>
  );
};

CountrySelectModal.propTypes = {
  cancel: PropTypes.func,
  sbsForward: PropTypes.func,
};

export default CountrySelectModal;
