import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { TableRowElement } from './TableRow';
import themeColors from '../../assets/styles/themeColors';

export const TableBodyElement = styled.div`
  ${(props) => props.css};
  ${TableRowElement} {
    &:nth-child(even) {
      background-color: ${themeColors.colorTableEvenRowBackground};
    }
    &:hover {
      background-color: ${themeColors.colorTableEvenRowHoverBackground};
    }
  }
`;

const TableBody = ({ children, ...props }) => (
  <TableBodyElement {...props}>{children}</TableBodyElement>
);

TableBody.propTypes = {
  children: PropTypes.node,
};

export default TableBody;
