import {
  CHANGE_AUTO_PAY_SETTINGS,
  FETCH_STORAGE_FEE_AMOUNT,
  SUBMIT_CARD_PAY_STORAGE_FEE,
} from './storageFeeActionConstants';

export const fetchStorageFeeAmount = (payload) => ({
  type: FETCH_STORAGE_FEE_AMOUNT,
  payload,
});

export const submitCardPayStorageFee = (payload) => ({
  type: SUBMIT_CARD_PAY_STORAGE_FEE,
  payload,
});

export const changeAutoPaySettings = (payload) => ({
  type: CHANGE_AUTO_PAY_SETTINGS,
  payload,
});
