export default {
  algoliaSearch: {
    apiKey: process.env.REACT_APP_ALGOLIA_API_KEY,
    apiSecret: process.env.REACT_APP_ALGOLIA_API_SECRET,
    apiBuyIndex: process.env.REACT_APP_ALGOLIA_API_BUY_INDEX,
    apiSellIndex: process.env.REACT_APP_ALGOLIA_API_SELL_INDEX,
    imageCode: '{base_url}/Content/images/products/{product_code}-large.jpg',
  },
  gtm: {
    gtmId: process.env.REACT_APP_GTM_ID,
  },
  newRelic: {
    agentId: process.env.REACT_APP_NEW_RELIC_AGENT_ID,
    applicationId: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID,
  },
  recaptcha: {
    siteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  },
  branch: {
    key: process.env.REACT_APP_BRANCH_KEY,
  },
  brokerTheme: process.env.REACT_APP_BROKER,
  metalStreamCaption: process.env.REACT_APP_METAL_STREAM_CAPTION,
};
