import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Button from '../Button/Button';
import { fetchDocumentByDocumentUrl } from '../../store/actions/documents/documentActions';
import useCheckPermissions from '../../util/hooks/useCheckPermissions';
import SectionLoader from '../Loader/SectionLoader';
import { uFlexColumn } from '../../assets/styles/utility';
import { variables } from '../../assets/styles/variables';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';

const PrintContainer = styled.div`
  ${uFlexColumn};

  @media print {
    padding: 20px;
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: 0 0 ${pxToRemMd(32)};
  }
`;

const PrintButtonContainer = styled.div`
  margin: ${pxToRem(16)} 0 ${pxToRem(24)};

  > button {
    min-width: ${pxToRem(200)};
  }

  ${mediaBelow(480)} {
    margin: ${pxToRemMd(16)} 0 ${pxToRemMd(24)};

    > button {
      min-width: 100%;
    }
  }

  @media print {
    display: none;
  }
`;

const PrintButtonsContainer = styled.div`
  margin-top: ${pxToRem(16)};
  display: flex;
  justify-content: space-between;

  > button {
    min-width: ${pxToRem(200)};
  }

  ${(props) =>
    props.hideButton &&
    `
    button {
      margin-left: auto;
    }
  `};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: ${pxToRemMd(16)};
    grid-row-gap: ${pxToRemMd(16)};

    ${({ hideButton }) =>
      hideButton &&
      `
      button {
        width: 100%;
        margin-left: 0;
      }
    `};
  }
`;

const PrintFromURLModal = ({
  close,
  description,
  content,
  buttonText,
  documentUrl,
  setIsDownloadLoading,
  isLoading,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    displayIRAMilleniumOptionsPermission,
    displayIRAEquityOptionsPermission,
  } = useCheckPermissions();

  const handleDocPrint = () => {
    if (
      displayIRAMilleniumOptionsPermission ||
      displayIRAEquityOptionsPermission
    ) {
      window.open(documentUrl, '_blank');
    } else {
      dispatch(
        fetchDocumentByDocumentUrl({
          documentUrl,
          setIsDownloadLoading,
        }),
      );
    }
  };

  return (
    <SectionLoader isLoading={isLoading}>
      <PrintContainer>
        {description}
        <PrintButtonContainer>
          <Button
            textTransform="capitalize"
            size="lg"
            variant="primaryOutlined"
            type="button"
            onClick={handleDocPrint}
            disabled={!documentUrl}
          >
            {t('common.printDownload')}
          </Button>
        </PrintButtonContainer>
        {content}
      </PrintContainer>
      <PrintButtonsContainer>
        <Button
          textTransform="capitalize"
          size="lg"
          variant="primaryOutlined"
          type="button"
          onClick={handleDocPrint}
          disabled={!documentUrl}
        >
          {t('common.printDownload')}
        </Button>
        <Button size="lg" variant="primary" onClick={close}>
          {buttonText || t('common.ok')}
        </Button>
      </PrintButtonsContainer>
    </SectionLoader>
  );
};

PrintFromURLModal.propTypes = {
  close: PropTypes.func,
  content: PropTypes.node,
  description: PropTypes.node,
  buttonText: PropTypes.string,
  documentUrl: PropTypes.string,
  setIsDownloadLoading: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default PrintFromURLModal;
