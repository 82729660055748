import React from 'react';
import { Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import isEmpty from 'lodash-es/isEmpty';
import { useHistory } from 'react-router';
import PasswordField from '../../../components/InputFields/PasswordField';
import { selectLoginError } from '../../../store/selectors/loginSelectors';
import FormCard from '../../../components/FormCard/FormCard';
import { getUsernames } from '../../../util/helpers/userHelpers';
import SelectField from '../../../components/InputFields/SelectField';
import { selectSettings } from '../../../store/selectors/settingsSelectors';
import { storeInSessionStorage } from '../../../util/helpers/sessionStorageHelper';
import {
  RESET_SECURITY_QUESTION_PAGE,
  RESET_SENT_PAGE,
  TWO_FA_FORGOT_PASSWORD_IDENTITY_PAGE,
  TWO_FA_FORGOT_PASSWORD_IDENTITY_PHONE_PAGE,
} from '../../../constants/pages';
import { fetchTwoFa } from '../../../store/actions/twoFA/twoFAActions';
import FormContainer from '../../../components/FormContainer/FormContainer';
import Note from '../../../components/Notes/Note';

const CredentialsFormSection = ({ values }) => {
  const error = useSelector(selectLoginError);
  const usernames = getUsernames();
  const history = useHistory();
  const dispatch = useDispatch();
  const brokerSettings = useSelector(selectSettings);

  const { IsBroker2FAActive } = brokerSettings;
  const onForgotPasswordClick = (username) => {
    const encodedUsername = encodeURIComponent(username);

    const onSuccessfulTwoFaFetch = (value) => {
      if (!isEmpty(value)) {
        const { AuthenticationMethodType } = value;
        storeInSessionStorage('ForgotPasswordUsername', encodedUsername);

        if (AuthenticationMethodType === 2) {
          history.push(
            `${TWO_FA_FORGOT_PASSWORD_IDENTITY_PHONE_PAGE}?username=${encodedUsername}`,
          );
          return;
        }

        history.push(
          `${TWO_FA_FORGOT_PASSWORD_IDENTITY_PAGE}?username=${encodedUsername}`,
        );
        return;
      }

      history.push(RESET_SENT_PAGE);
    };

    if (IsBroker2FAActive) {
      dispatch(fetchTwoFa({ username, onSuccessfulTwoFaFetch }));
    } else {
      history.push(
        `${RESET_SECURITY_QUESTION_PAGE}?username=${encodedUsername}`,
      );
    }
  };

  return (
    <FormCard
      title={
        usernames.length > 1
          ? i18next.t('inviteUser.credentials.loginInfo.titleMultiple')
          : i18next.t('inviteUser.credentials.loginInfo.titleOne')
      }
    >
      <FormContainer maxWidth={450}>
        <SelectField
          label={i18next.t('inviteUser.credentials.labels.username')}
          name="Username"
          options={usernames}
          disabled={isEmpty(usernames)}
        />
        <Field
          name="Password"
          errorMessage={error}
          component={PasswordField}
          label="Password*"
          link={
            <Note
              text={i18next.t('login.forgotYourPassword')}
              onClick={() => onForgotPasswordClick(values?.Username?.value)}
            />
          }
        />
      </FormContainer>
    </FormCard>
  );
};
CredentialsFormSection.propTypes = {
  values: PropTypes.shape({
    Username: PropTypes.string,
  }),
  errors: PropTypes.shape({
    Password: PropTypes.string,
  }),
};

export default CredentialsFormSection;
