import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import getCountryIso3 from 'country-iso-2-to-3';
import { Field } from 'formik';
import { useDispatch } from 'react-redux';
import { pxToRem } from '../../assets/styles/helper';
import SelectField from '../InputFields/SelectField';
import TextField from '../InputFields/TextField';
import useGetCountries from '../../util/hooks/useGetCountries';
import {
  USA_COUNTRY_ISO3CODE,
  CAN_COUNTRY_ISO3CODE,
} from '../../util/constants';
import { getStates } from '../../store/actions/common/commonActions';

const BraintreeFieldWrapper = styled.div`
  margin-bottom: ${pxToRem(12)};
  iframe {
    float: none !important;
  }
`;

const HiddenInput = styled.input`
  display: none;
  opacity: 0;
  cursor: 'context-menu';
  width: 0;
  height: 0;
`;

const AddressForm = ({
  stateOptions,
  setFieldValue,
  CountryIso3Code,
  StateRegion,
}) => {
  const { t } = useTranslation();
  const countries = useGetCountries();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      CountryIso3Code === USA_COUNTRY_ISO3CODE ||
      CountryIso3Code === CAN_COUNTRY_ISO3CODE
    ) {
      dispatch(getStates(CountryIso3Code));
    }
  }, [CountryIso3Code, dispatch]);

  const COUNTRY_OPTIONS = [
    ...countries.map((country) => ({
      value: country.Iso3Code,
      label: country.Caption,
    })),
  ];

  const isStateRegionBasicInput =
    CountryIso3Code !== USA_COUNTRY_ISO3CODE &&
    CountryIso3Code !== CAN_COUNTRY_ISO3CODE;

  const handleChangeCountry = (event) => {
    if (event.target.value) {
      setFieldValue('CountryIso3Code', getCountryIso3(event.target.value));
    }
  };

  const handleChangeState = (event) => {
    if (event.target.value) {
      setFieldValue('StateRegion', event.target.value);
    }
  };

  return (
    <div>
      <HiddenInput
        type="text"
        autoComplete="country"
        value={CountryIso3Code}
        onChange={handleChangeCountry}
      />
      <BraintreeFieldWrapper>
        <SelectField
          name="CountryIso3Code"
          label={t('creditCard.fields.country')}
          disabled={!countries.length}
          options={COUNTRY_OPTIONS}
          value={{
            value: CountryIso3Code,
            label: countries.find(
              (country) => country.Iso3Code === CountryIso3Code,
            )?.Caption,
          }}
          selectOption={({ value }) => {
            setFieldValue('CountryIso3Code', value);
          }}
        />
      </BraintreeFieldWrapper>
      <BraintreeFieldWrapper>
        <Field
          component={TextField}
          name="AddressLine"
          label={t('creditCard.fields.streetAddress')}
          autocomplete="street-address"
        />
      </BraintreeFieldWrapper>
      <BraintreeFieldWrapper>
        <Field
          component={TextField}
          name="AddressLine2"
          label={t('creditCard.fields.streetAddress2')}
          autoComplete="new-password"
        />
      </BraintreeFieldWrapper>
      <BraintreeFieldWrapper>
        <Field
          component={TextField}
          name="City"
          label={t('creditCard.fields.city')}
        />
      </BraintreeFieldWrapper>
      {!isStateRegionBasicInput ? (
        <BraintreeFieldWrapper>
          <HiddenInput
            type="text"
            autoComplete="address-level1"
            value={StateRegion}
            onChange={handleChangeState}
          />
          <SelectField
            name="StateRegion"
            label={t('creditCard.fields.stateProvince')}
            placeholder={t('common.select')}
            value={{
              value: StateRegion,
              label: stateOptions.find((state) => state.value === StateRegion)
                ?.label,
            }}
            options={stateOptions}
            selectOption={({ value }) => setFieldValue('StateRegion', value)}
          />
        </BraintreeFieldWrapper>
      ) : (
        <BraintreeFieldWrapper>
          <Field
            component={TextField}
            name="StateRegion"
            label={t('creditCard.fields.stateProvince')}
            autoComplete="address-level1"
          />
        </BraintreeFieldWrapper>
      )}
      <BraintreeFieldWrapper>
        <Field
          component={TextField}
          name="PostalCode"
          label={t('creditCard.fields.postalCode')}
          autoComplete="postal-code"
        />
      </BraintreeFieldWrapper>
    </div>
  );
};

export default AddressForm;

AddressForm.propTypes = {
  stateOptions: PropTypes.arrayOf({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  CountryIso3Code: PropTypes.string,
  StateRegion: PropTypes.string,
  setFieldValue: PropTypes.func,
};
