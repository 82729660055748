import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import ModalButtons from '../../Modal/ModalButtons';
import CurrencyField from '../../InputFields/CurrencyField';
import { formatMoneyNumeral } from '../../../util/helpers/numeralHelpers';
import { minmaxAmountValidationSchema } from '../../../validation/amountValidationSchema';
import ModalBody from '../../Modal/ModalBody';
import Paragraph from '../../Paragraph/Paragraph';
import {
  selectMetalStreamMaximumDepositAmount,
  selectMetalStreamMinimumDepositAmount,
} from '../../../store/selectors/settingsSelectors';
import useGtmHook from '../../../util/hooks/useGtmHook';
import ModalTitle from '../../Modal/ModalTitle';
import { pxToRem } from '../../../assets/styles/helper';

const InvestmentSetup = ({
  wizardData,
  setWizardData,
  handleClose,
  handleNext,
  defaultValues,
}) => {
  const { t } = useTranslation();
  const { gtmDialogScreenView } = useGtmHook();
  const minimumDepositAmount = useSelector(
    selectMetalStreamMinimumDepositAmount,
  );

  const maximumDepositAmount = useSelector(
    selectMetalStreamMaximumDepositAmount,
  );

  const handleSubmit = (values) => {
    setWizardData({ Amount: values.amount });
    handleNext();
  };

  useEffect(() => {
    gtmDialogScreenView({
      title: 'MetalStream Set Up - Configure Automatic Investments',
    });
  }, []); //eslint-disable-line

  return (
    <ModalBody>
      <ModalTitle>{t('investmentSetup.automatic')}</ModalTitle>
      <Paragraph marginBottom={24}>{t('investmentSetup.setup')}</Paragraph>
      <Paragraph fontSize={pxToRem(20)} marginBottom={16} bold>
        {t('investmentSetup.howMuch')}
      </Paragraph>
      <Formik
        initialValues={{ amount: wizardData?.Amount || defaultValues?.Amount }}
        onSubmit={handleSubmit}
        validationSchema={minmaxAmountValidationSchema(
          minimumDepositAmount,
          maximumDepositAmount,
        )}
        initialTouched={{ amount: true }}
        enableReinitialize
      >
        {({ values, setFieldValue }) => (
          <Form>
            <CurrencyField
              label={t('standardDepositWizard.achDeposit.achAmount')}
              name="amount"
              placeholder={formatMoneyNumeral(0)}
              value={values.amount}
              onChange={(value) => setFieldValue('amount', value)}
            />
            <ModalButtons
              isHorizontal
              marginTop
              secondaryButtonProps={{
                onClick: handleClose,
                label: t('common.cancel'),
              }}
              primaryButtonProps={{
                type: 'submit',
                disabled: !values.amount,
              }}
            />
          </Form>
        )}
      </Formik>
    </ModalBody>
  );
};

InvestmentSetup.propTypes = {
  handleClose: PropTypes.func,
  handleNext: PropTypes.func,
  wizardData: PropTypes.shape({
    Amount: PropTypes.number,
  }),
  setWizardData: PropTypes.func,
  defaultValues: PropTypes.shape({
    Amount: PropTypes.number,
  }),
};

export default InvestmentSetup;
