import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import isEmpty from 'lodash.isempty';
import StepByStep from '../../StepByStep';
import SellQuantity from './SellQuantity';
import SellConfirm from './SellConfirm';
import Modal from '../../Modal/Modal';

import { clearSellWizardState } from '../../../store/actions/sell/sellWizardActions';
import {
  fetchProductSymbolPrices,
  fetchProductTiers,
} from '../../../store/actions/products/productActions';
import { selectStorageSymbolCode } from '../../../store/selectors/buyStorageSelectors';
import { getCurrentAccount } from '../../../store/selectors/accountSelectors';
import { fetchPortfolioProductBySymbol } from '../../../store/actions/portfolio/portfolioActions';
import { selectSellWizardProduct } from '../../../store/selectors/sellWizardSelectors';
import { selectIsLoadingByActionTypes } from '../../../store/selectors/loadingSelectors';
import { PORTFOLIO_PRODUCT_BY_SYMBOL_FETCH_LOADING } from '../../../store/actions/portfolio/portfolioActionConstants';
import { PRODUCT_SYMBOL_PRICES_LOADING } from '../../../store/actions/products/productActionConstants';
import {
  getReviewProduct,
  selectProductTiers,
} from '../../../store/selectors/productsSelector';
import {
  closeWizardContent,
  setWizardContent,
} from '../../../util/helpers/wizardHelpers';
import Status from '../Status';
import {
  ERROR_TEXT,
  HAS_ERROR,
  REVIEW_PRODUCT,
  SELL_WIZARD,
  WIZARD_DATA,
} from '../../../constants/sessionStorage';
import { useSessionStorageState } from '../../../util/hooks/useSessionStorageState';
import { restrictionTypes } from '../../../util/enum/api/restrictionTypes';
import useRestrictionHook from '../../../util/hooks/useRestrictionHook';
import { isActionRestrictedHelper } from '../../../util/helpers/restrictionHelper';
import Restriction from '../RestrictionModal/Restriction';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import { productLocationTypes } from '../../../util/enum/api/productTypes';
import useProductPrice from '../../../util/hooks/useProductPrice';
import {
  retrieveFromSessionStorage,
  storeInSessionStorage,
} from '../../../util/helpers/sessionStorageHelper';
import Paragraph from '../../Paragraph/Paragraph';

const sellWizardSteps = [
  {
    children: SellQuantity,
  },
  {
    children: SellConfirm,
  },
  {
    children: Status,
  },
];

const SellWizardModal = ({
  isModalOpen,
  handleCloseModal,
  handleRefreshTransactions,
  isFractionalProduct,
  isInstaVaultProduct,
  barsArray,
  isSegregated,
  isProductPage,
  isLockedPrice,
  counter,
  unity,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const product = useSelector(selectSellWizardProduct);
  const vault = useSelector(selectStorageSymbolCode);
  const isSegregatedVault = vault?.IsSegregated;
  const account = useSelector(getCurrentAccount);
  const reviewProduct = useSelector(getReviewProduct);
  const productTiers = useSelector(selectProductTiers);
  const restrictions = useRestrictionHook();
  const isLoading = useSelector(
    selectIsLoadingByActionTypes([
      PORTFOLIO_PRODUCT_BY_SYMBOL_FETCH_LOADING,
      PRODUCT_SYMBOL_PRICES_LOADING,
    ]),
  );

  const [hasError, setHasError] = useSessionStorageState(HAS_ERROR, false);
  const [errorText, setErrorText] = useSessionStorageState(ERROR_TEXT, '');
  const unitPrice = useProductPrice(product, vault, true);
  const [pricePerUnit, setPricePerUnit] = useState(unitPrice);
  const [wizardData, setWizardData] = useState(
    () => retrieveFromSessionStorage(WIZARD_DATA) || {},
  );
  const setDataForWizard = (value) => {
    setWizardData({ ...value, isSegregated });
    storeInSessionStorage(WIZARD_DATA, value);
  };
  const handleModalClose = () => {
    closeWizardContent();
    dispatch(clearSellWizardState());
    handleCloseModal();
  };

  useEffect(() => {
    if (!isEmpty(reviewProduct)) {
      setWizardContent(REVIEW_PRODUCT, reviewProduct);
    }
  }, [reviewProduct]);

  useEffect(() => {
    if (isModalOpen && vault && vault.SymbolCode) {
      const isSegregatedParam =
        isSegregatedVault === undefined ? isSegregated : isSegregatedVault;
      dispatch(
        fetchPortfolioProductBySymbol({
          accountUid: account.AccountUid,
          symbol: vault.SymbolCode,
          isSegregated: isSegregatedParam,
        }),
      );
      dispatch(
        fetchProductSymbolPrices({
          accountUid: account.AccountUid,
          symbol: vault.SymbolCode,
          side: 'sell',
        }),
      );
      if (isFractionalProduct) {
        dispatch(
          fetchProductTiers({
            accountUid: account.AccountUid,
            symbol: vault.SymbolCode,
            side: 'Sell',
            location: parseEnumType(productLocationTypes, vault.LocationType),
            trueTiers: true,
          }),
        );
      }
    }
  }, [isModalOpen, isSegregated]); // eslint-disable-line

  useEffect(() => {
    setPricePerUnit(unitPrice);
  }, [unitPrice]);

  return (
    <Modal
      title={t('sellWizard.sellWizardTitle')}
      close={handleModalClose}
      size="sm"
      isOpen={isModalOpen}
      isLoading={isLoading}
      dataCy="container-sell-for-storage-modal"
    >
      {isActionRestrictedHelper(restrictions, [
        restrictionTypes.LogonIsPrevented,
        restrictionTypes.RestrictSell,
      ]) ? (
        <Restriction restrictions={restrictions} />
      ) : (
        <StepByStep sessionScopeStep={SELL_WIZARD}>
          {({ goStepForward, goStepBack }) =>
            sellWizardSteps.map((buyStorageStep) =>
              React.createElement(buyStorageStep.children, {
                handleBack: goStepBack,
                handleNext: goStepForward,
                handleClose: handleModalClose,
                handleRefreshTransactions,
                onButtonClick: handleModalClose,
                wizardData,
                setWizardData: setDataForWizard,
                product,
                tiers: productTiers?.Tiers,
                barsArray,
                reviewProduct,
                vault,
                account,
                setHasError,
                setErrorText,
                hasError,
                text: errorText || (
                  <>
                    <Paragraph centerText>
                      {t('buyWizard.confirmationMessage.orderPlaced')}
                    </Paragraph>
                    <Paragraph centerText>
                      {t(
                        'buyWizard.confirmationMessage.onTradeConfirmedMessage',
                      )}
                    </Paragraph>
                  </>
                ),
                isFractionalProduct,
                isInstaVaultProduct,
                isReserved: isSegregated,
                unitPrice: pricePerUnit,
                setPricePerUnit,
                isProductPage,
                isLockedPrice,
                counter,
                unity,
              }),
            )
          }
        </StepByStep>
      )}
    </Modal>
  );
};

SellWizardModal.propTypes = {
  isModalOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  handleRefreshTransactions: PropTypes.func,
  isFractionalProduct: PropTypes.bool,
  isInstaVaultProduct: PropTypes.bool,
  barsArray: PropTypes.arrayOf(
    PropTypes.shape({
      BrandCode: PropTypes.string,
      SerialNumber: PropTypes.string,
      FineWeight: PropTypes.number,
      SequenceNumber: PropTypes.number,
    }),
  ),
  isSegregated: PropTypes.bool,
  isProductPage: PropTypes.bool,
  isLockedPrice: PropTypes.bool,
  counter: PropTypes.number,
  unity: PropTypes.bool,
};

export default SellWizardModal;
