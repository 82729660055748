import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IRAWithdrawType from '../IRA/IRAWithdrawType';
import IRATransferOut from '../IRA/IRATransferOut';
import IRADistribution from '../IRA/IRADistribution';
import { EQUITY_WITHDRAW_WIZARD_TYPES } from '../../../constants/equityWithdrawWizardConstants';
import StepByStep from '../../StepByStep';
import {
  EQUITY_WITHDRAWAL_WIZARD,
  SELECT_TYPE,
} from '../../../constants/sessionStorage';
import { useSessionStorageState } from '../../../util/hooks/useSessionStorageState';
import Modal from '../../Modal/Modal';
import ModalBody from '../../Modal/ModalBody';
import useRestrictionHook from '../../../util/hooks/useRestrictionHook';
import Restriction from '../RestrictionModal/Restriction';
import { restrictionTypes } from '../../../util/enum/api/restrictionTypes';
import { isActionRestrictedHelper } from '../../../util/helpers/restrictionHelper';

const equityDepositModals = [
  IRAWithdrawType,
  {
    bySelectedType: {
      [EQUITY_WITHDRAW_WIZARD_TYPES.DISTRIBUTION]: IRADistribution,
      [EQUITY_WITHDRAW_WIZARD_TYPES.TRANSFER]: IRATransferOut,
    },
  },
];

const EquityWithdrawalWizard = ({ isModalOpen, handleClose }) => {
  const [selectedType, setSelectedType] = useSessionStorageState(
    SELECT_TYPE,
    null,
  );
  const restrictions = useRestrictionHook();
  const [modalTitle, setModalTitle] = useState('Withdraw Funds');

  const handleCloseModal = () => {
    handleClose();
    setSelectedType(null);
  };

  const handleSelectType = (type, handleNext) => {
    setSelectedType(type);
    handleNext();
  };

  return (
    <Modal
      size="sm"
      isOpen={isModalOpen}
      title={modalTitle}
      close={handleCloseModal}
    >
      {isActionRestrictedHelper(restrictions, [
        restrictionTypes.LogonIsPrevented,
        restrictionTypes.WithdrawalsLocked,
      ]) ? (
        <Restriction restrictions={restrictions} />
      ) : (
        <ModalBody>
          <StepByStep sessionScopeStep={EQUITY_WITHDRAWAL_WIZARD}>
            {({ goStepBack, goStepForward, isLastStep }) =>
              equityDepositModals.map((modal) => {
                if (modal.bySelectedType) {
                  if (!selectedType) {
                    return null;
                  }

                  return React.createElement(
                    modal.bySelectedType[selectedType],
                    {
                      handleBack: goStepBack,
                      handleClose: handleCloseModal,
                      handleNext: goStepForward,
                      isModalOpen,
                      setModalTitle,
                      isLastStep,
                    },
                  );
                }

                return React.createElement(modal, {
                  handleBack: goStepBack,
                  handleClose: handleCloseModal,
                  handleNext: goStepForward,
                  setSelectedType: handleSelectType,
                  setModalTitle,
                  isModalOpen,
                });
              })
            }
          </StepByStep>
        </ModalBody>
      )}
    </Modal>
  );
};

EquityWithdrawalWizard.propTypes = {
  isModalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default EquityWithdrawalWizard;
