import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BUY_FOR_STORAGE_WIZARD } from '../../constants/sessionStorage';
import StepByStep from '../StepByStep';
import ReviewOrderForStorage from './ReviewOrderForStorage';
import OrderForStoragePayment from './OrderForStoragePayment';
import OrderForStorage from './OrderForStorage';
import Status from '../Modals/Status';
import {
  selectBuyError,
  selectIsBuyStorageSuccessful,
} from '../../store/selectors/buyStorageSelectors';
import Paragraph from '../Paragraph/Paragraph';

const nonPostPaidSteps = [
  { children: OrderForStorage },
  { children: ReviewOrderForStorage },
  { children: Status },
];

const postPaidSteps = [
  { children: OrderForStorage },
  { children: OrderForStoragePayment },
  { children: ReviewOrderForStorage },
  { children: Status },
];

const BuyForStorage = ({
  closeModal,
  setWizardData,
  wizardData,
  handleBack,
  handleNext,
  handleCloseModalOnBuy,
  setWizardTitle,
  isProductPage,
  skipChooseLocation,
  isSegregated,
  tiers,
  counter,
  unity,
  isPostPaidVisible,
}) => {
  const { t } = useTranslation();
  const isBuyStorageSuccessful = useSelector(selectIsBuyStorageSuccessful);
  const errorMessage = useSelector(selectBuyError);
  const steps = isPostPaidVisible ? postPaidSteps : nonPostPaidSteps;

  useEffect(() => {
    if (isBuyStorageSuccessful || errorMessage) {
      setWizardData({
        ...wizardData,
        isBuyStorageSuccessful: !isBuyStorageSuccessful,
        errorMessage,
        isSegregated,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBuyStorageSuccessful, errorMessage]);

  return (
    <StepByStep sessionScopeStep={BUY_FOR_STORAGE_WIZARD}>
      {({ goStepForward, goStepBack, skipPreviousStep }) =>
        steps.map((buyStorageStep) =>
          React.createElement(buyStorageStep.children, {
            handleBack: goStepBack,
            handleNext: goStepForward,
            skipPreviousStep,
            closeModal,
            setWizardData,
            wizardData,
            handleBuyWizardBack: handleBack,
            handleBuyWizardNext: handleNext,
            handleCloseModalOnBuy,
            hasError: wizardData?.isBuyStorageSuccessful,
            text: wizardData?.errorMessage || (
              <>
                <Paragraph centerText>
                  {t('buyWizard.confirmationMessage.orderPlaced')}
                </Paragraph>
                <Paragraph centerText>
                  {t('buyWizard.confirmationMessage.onTradeConfirmedMessage')}
                </Paragraph>
              </>
            ),
            onButtonClick: closeModal,
            setWizardTitle,
            isProductPage,
            skipChooseLocation,
            tiers,
            counter,
            unity,
            isSegregated,
            isPostPaidVisible,
          }),
        )
      }
    </StepByStep>
  );
};

BuyForStorage.propTypes = {
  closeModal: PropTypes.func,
  setWizardData: PropTypes.func,
  wizardData: PropTypes.shape({
    isBuyStorageSuccessful: PropTypes.bool,
    errorMessage: PropTypes.string,
    isSegregated: PropTypes.bool,
  }),
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  setWizardTitle: PropTypes.func,
  isProductPage: PropTypes.bool,
  skipChooseLocation: PropTypes.bool,
  isSegregated: PropTypes.bool,
  handleCloseModalOnBuy: PropTypes.func,
  tiers: PropTypes.shape({
    HighBound: PropTypes.number,
    LowBound: PropTypes.number,
    PricePerOz: PropTypes.number,
    PricePerUnit: PropTypes.number,
  }),
  counter: PropTypes.number,
  unity: PropTypes.bool,
  isPostPaidVisible: PropTypes.bool,
};

export default BuyForStorage;
