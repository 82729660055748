import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as RadioOn } from '../../assets/images/svg/radio-on.svg';
import { ReactComponent as RadioOff } from '../../assets/images/svg/radio-off.svg';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { uFlexCenter } from '../../assets/styles/utility';
import { variables } from '../../assets/styles/variables';

const getRadioVariantStyles = (variant, selected) => {
  if (variant === 'RadioRounded') {
    return `
    border: 1px solid ${
      selected ? themeColors.colorPrimary : themeColors.colorInputBorder
    };
    padding: ${pxToRem(6)} ${pxToRem(16)} ${pxToRem(12)};

    svg {
      margin-top: ${pxToRem(4)};
    }
    `;
  }

  if (variant === 'RadioBox') {
    return `
    box-shadow: ${variables.shadow.boxShadow};
    align-items: center;
    border: 1px solid ${selected ? themeColors.colorPrimary : 'transparent'};
    padding: ${pxToRem(16)};
    `;
  }
};

const getRadioContentVariantStyles = (variant) => {
  if (variant === 'RadioBox') {
    return `
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        ${mediaBelow(variables.breakpoints.bpMd)} {
          flex-direction: column;
          align-items: flex-start;
        }
    `;
  }
};

export const RadioWrap = styled.label`
  display: flex;
  cursor: pointer;
  border-radius: ${variables.borderRadius.borderRadius};
  ${({ disabled }) =>
    disabled &&
    `
  opacity: 0.5;
  cursor: not-allowed;
`}}

  ${({ variant, selected }) => getRadioVariantStyles(variant, selected)}
`;

export const RadioIndicator = styled.div`
  ${uFlexCenter};
  width: ${pxToRem(16)};
  height: ${pxToRem(16)};
  margin-right: ${pxToRem(16)};

  svg {
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
    ${({ disabled }) =>
      disabled &&
      `
      opacity: 0.5;
    `}}
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    width: ${pxToRemMd(16)};
    height: ${pxToRemMd(16)};

    svg {
      width: ${pxToRemMd(16)};
      height: ${pxToRemMd(16)};
    }
  }
`;

export const RadioContent = styled.div`
  font-size: ${pxToRem(14)};
  line-height: 1.15;
  color: ${themeColors.colorLabel};
  user-select: none;
  margin-right: 1rem;
  ${({ variant }) => getRadioContentVariantStyles(variant)}
  ${({ removeCheckbox }) => removeCheckbox && `margin-left: 2rem;`}
  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(14)};
  }
`;

export const RadioField = styled.input`
  display: none;
`;

const Radio = ({
  children,
  name,
  checked,
  field,
  value,
  selected,
  id = 'radio',
  notCenteredIndicator,
  variant = 'Radio',
  disabled,
  removeCheckbox,
  ...props
}) => (
  <RadioWrap
    htmlFor={name}
    notCenteredIndicator={notCenteredIndicator}
    selected={selected}
    variant={variant}
    disabled={disabled}
    {...props}
  >
    {!removeCheckbox && (
      <>
        <RadioField
          name={name}
          id={id}
          type="radio"
          checked={checked}
          value={value}
          disabled={disabled ?? false}
          {...field}
        />
        <RadioIndicator disabled={disabled}>
          {selected ? <RadioOn /> : <RadioOff />}
        </RadioIndicator>
      </>
    )}
    <RadioContent variant={variant} removeCheckbox={removeCheckbox}>
      {children}
    </RadioContent>
  </RadioWrap>
);

Radio.propTypes = {
  children: PropTypes.node,
  checked: PropTypes.bool,
  name: PropTypes.string,
  field: PropTypes.shape({}),
  form: PropTypes.shape({}),
  className: PropTypes.string,
  value: PropTypes.string,
  selected: PropTypes.bool,
  notCenteredIndicator: PropTypes.bool,
  id: PropTypes.string,
  variant: PropTypes.oneOf(['RadioBox', 'RadioRounded', 'Radio']),
  disabled: PropTypes.bool,
  removeCheckbox: PropTypes.bool,
};

export default Radio;
