import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';

import StepByStep from '../../../StepByStep';
import WithdrawalReview from '../WithdrawalReview';
import WireWithdrawalInformation from './WireWithdrawalInformation';

import { fetchBankAccountsByType } from '../../../../store/actions/bankAccount/bankAccountActions';
import { BANK_ACCOUNTS_BY_TYPE_FETCH_LOADING } from '../../../../store/actions/bankAccount/bankAccountActionConstants';
import { selectIsLoadingByActionType } from '../../../../store/selectors/loadingSelectors';
import { selectBankAccountsByType } from '../../../../store/selectors/bankAccountSelectors';
import { selectWireWithdrawalFee } from '../../../../store/selectors/settingsSelectors';
import { transactionTypes } from '../../../../util/enum/api/transactionTypes';
import { updateWireWithdrawal } from '../../../../store/actions/orders/orderActions';
import { UPDATE_WIRE_WITHDRAWAL_LOADING } from '../../../../store/actions/orders/orderActionConstants';
import SuccessOrFailModal from '../../StatusModal';
import NoFundsModal from '../NoFundsModal';
import {
  closeWizardContent,
  getWizardContent,
  setWizardContent,
} from '../../../../util/helpers/wizardHelpers';
import useGtmHook from '../../../../util/hooks/useGtmHook';
import { fetchPortfolioBalances } from '../../../../store/actions/portfolio/portfolioActions';
import {
  BANK_ACCOUNT,
  IS_SUCCESS_OR_FAIL_MODAL_OPEN,
  WIRE_WITHDRAWAL_FEE_CREDIT,
  WIRE_WITHDRAWAL_WIZARD,
  WITHDRAWAL_AMOUNT,
} from '../../../../constants/sessionStorage';
import { useSessionStorageState } from '../../../../util/hooks/useSessionStorageState';
import { useGetIsImpersonating } from '../../../../util/hooks/useGetIsImpersonating';
import { WITHDRAWAL_WIZARD_TYPES } from '../../../../constants/standardWithdrawalWizardConstants';
import { FUND_WITHDRAW_COMPLETE } from '../../../../constants/gtmEvents';

const WireWithdrawalModal = ({
  isModalOpen,
  handleBack,
  handleClose,
  accountUid,
  isCheck,
  error,
  setError,
  balances,
  handleRefreshTransactions,
}) => {
  const dispatch = useDispatch();
  const { isImpersonating } = useGetIsImpersonating();
  const accounts = useSelector(selectBankAccountsByType);
  const wireWithdrawalFee = useSelector(selectWireWithdrawalFee);
  const isSubmitLoading = useSelector(
    selectIsLoadingByActionType(UPDATE_WIRE_WITHDRAWAL_LOADING),
  );
  const isLoading = useSelector(
    selectIsLoadingByActionType([BANK_ACCOUNTS_BY_TYPE_FETCH_LOADING]),
  );
  const isSubmittingWithdrawal = useSelector(
    selectIsLoadingByActionType(UPDATE_WIRE_WITHDRAWAL_LOADING),
  );
  const { fundGtmEvent, gtmDialogScreenView } = useGtmHook();

  const [withdrawalAccount, setWithdrawalAccount] = useSessionStorageState(
    BANK_ACCOUNT,
    {},
  );

  const [withdrawalAmount, setWithdrawalAmount] = useSessionStorageState(
    WITHDRAWAL_AMOUNT,
    0,
  );
  const [
    wireWithdrawalFeeCreditAmount,
    setWireWithdrawalFeeCreditAmount,
  ] = useSessionStorageState(WIRE_WITHDRAWAL_FEE_CREDIT, 0);

  const [
    isSuccessOrFailModalOpen,
    setIsSuccessOrFailModalOpen,
  ] = useSessionStorageState(IS_SUCCESS_OR_FAIL_MODAL_OPEN, false);

  const [fundsModalInfo, setFundsModalInfo] = useState(
    () =>
      getWizardContent('FundsModalInfo') || {
        isOpen: false,
        balances: [],
      },
  );

  useEffect(() => {
    dispatch(fetchBankAccountsByType({ accountUid, type: 'wirewithdrawal' }));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (isSubmittingWithdrawal) {
      setWizardContent(IS_SUCCESS_OR_FAIL_MODAL_OPEN, true);
      setIsSuccessOrFailModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmittingWithdrawal]);

  const availableBalance = balances.AvailableForWithdrawal;

  const handleConfirmWireWithdrawal = (handleNext) => {
    const data = {
      Amount: withdrawalAmount,
      BankAccountUid: withdrawalAccount.BankAccountUid,
      WirewithdrawalFeeCredit: wireWithdrawalFeeCreditAmount,
      AccountUid: accountUid,
      Type: transactionTypes.WireWithdrawal,
    };

    const onSuccess = () => {
      handleRefreshTransactions();
      dispatch(fetchPortfolioBalances({ accountUid }));
      fundGtmEvent(FUND_WITHDRAW_COMPLETE, {
        fund: {
          method: WITHDRAWAL_WIZARD_TYPES.WIRE,
          amount: withdrawalAmount,
        },
      });
    };

    // const handleGtm = () => {
    //   gtmEvent(WITHDRAW, {
    //     AccountType: parseEnumType(accountTypeKeys, account.AccountType),
    //     Date: formatDate(date),
    //     Amount: withdrawalAmount,
    //   });
    // };

    dispatch(
      updateWireWithdrawal({
        accountUid,
        data,
        handleNext,
        onSuccess,
        setError,
      }),
    );
  };

  if (fundsModalInfo.isOpen) {
    return (
      <NoFundsModal
        balances={fundsModalInfo.balances}
        isOpen={fundsModalInfo.isOpen}
        handleClose={() => {
          closeWizardContent();
          handleClose();
        }}
        handleBack={() =>
          setFundsModalInfo((prevState) => ({ ...prevState, isOpen: false }))
        }
      />
    );
  }

  const callGtm = () => {
    gtmDialogScreenView({
      title: `Wire Withdraw Funds Submitted`,
    });
  };

  return (
    <StepByStep sessionScopeStep={WIRE_WITHDRAWAL_WIZARD}>
      {({ goStepBack, goStepForward }) => [
        <WireWithdrawalInformation
          key="wire-withdrawal-information"
          isModalOpen={isModalOpen}
          handleBack={handleBack}
          handleNext={goStepForward}
          bankAndAmount={{
            account: withdrawalAccount,
            amount: withdrawalAmount,
          }}
          wireWithdrawalFee={wireWithdrawalFee}
          handleClose={handleClose}
          bankAccounts={accounts}
          availableBalance={availableBalance}
          setWithdrawalAccount={setWithdrawalAccount}
          setWithdrawalAmount={setWithdrawalAmount}
          setWireWithdrawalFeeCreditAmount={setWireWithdrawalFeeCreditAmount}
          isLoading={isLoading}
          balances={balances}
          setFundsModalInfo={setFundsModalInfo}
          accountUid={accountUid}
          isImpersonating={isImpersonating}
          wireWithdrawalFeeCreditAmount={wireWithdrawalFeeCreditAmount}
        />,
        <WithdrawalReview
          key="withdrawal-review"
          isCheck={isCheck}
          isModalOpen={isModalOpen}
          handleBack={goStepBack}
          bankAndAmount={{
            bank: withdrawalAccount,
            amount: withdrawalAmount,
          }}
          wireWithdrawalFee={wireWithdrawalFee}
          wireWithdrawalFeeCreditAmount={wireWithdrawalFeeCreditAmount}
          isLoading={isSubmitLoading}
          handleClose={handleClose}
          title={i18next.t('standardWithdrawalWizard.wireWithdrawal.title')}
          onConfirm={() => handleConfirmWireWithdrawal(goStepForward)}
        />,
        <SuccessOrFailModal
          key="success-or-fail-modal"
          text={
            error || i18next.t('standardWithdrawalWizard.successfulWithdrawal')
          }
          isOpen={isSuccessOrFailModalOpen}
          hasError={error}
          modalTitle={i18next.t(
            'standardWithdrawalWizard.wireWithdrawal.title',
          )}
          close={handleClose}
          onButtonClick={() => {
            closeWizardContent();
            handleClose();
          }}
          backButtonText={i18next.t('common.ok')}
          callGtm={callGtm}
        />,
      ]}
    </StepByStep>
  );
};

WireWithdrawalModal.propTypes = {
  isModalOpen: PropTypes.bool,
  handleBack: PropTypes.func,
  handleClose: PropTypes.func,
  accountUid: PropTypes.string,
  isCheck: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  balances: PropTypes.shape({
    AvailableForWithdrawal: PropTypes.number,
  }),
  setError: PropTypes.func,
  handleRefreshTransactions: PropTypes.func,
};

export default WireWithdrawalModal;
