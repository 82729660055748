import styled from 'styled-components';
import { pxToRem } from '../../assets/styles/helper';
import { uFlexCenter, uFlexColumn } from '../../assets/styles/utility';
import Button from '../Button/Button';

export const EmptyTable = styled.div`
  ${uFlexCenter};
  ${uFlexColumn};
  min-height: ${pxToRem(232)};

  p {
    color: var(--color-note);
    font-weight: 600;
  }
`;

export const EmptyTableButton = styled(Button)`
  margin-top: ${pxToRem(32)};
  width: ${pxToRem(200)};
`;
