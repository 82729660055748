import { useEffect } from 'react';

export function useOutsideClickHandler(ref, close, handleOutsideClick) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        close();
      }
    }

    if (handleOutsideClick) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [close, handleOutsideClick, ref]);
}
