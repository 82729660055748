import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { buttonizeDiv } from '../../util/buttonizeDiv';
import themeColors from '../../assets/styles/themeColors';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import { uDanger } from '../../assets/styles/utility';

export const NoteElement = styled.span`
  font-weight: ${({ bold }) => (bold ? '600' : '500')};
  ${({ fontSize }) => (fontSize ? `${pxToRem(fontSize)}` : `${pxToRem(14)}`)}
  font-size: ${pxToRem(14)};
  line-height: 1.75;
  ${({ onClick }) =>
    onClick &&
    `cursor: pointer;
    text-decoration: underline;`};
  margin-top: ${({ marginTop }) => (marginTop ? `${pxToRem(marginTop)}` : 0)};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${pxToRem(marginBottom)}` : 0};
  ${({ noUnderline }) => noUnderline && `text-decoration: none !important;`}
  ${({ textAlignCenter }) => textAlignCenter && `text-align: center;`}
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
}

  &,
  > a {
    color: ${({ primaryColor }) =>
      primaryColor
        ? themeColors.colorLinkPrimary
        : themeColors.colorTextSecondary};
        ${({ limit }) => limit && `color: ${themeColors.colorError};`}
        ${({ isError }) => isError && uDanger};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(14)};
  }
`;

const Note = ({
  text,
  marginBottom,
  marginTop,
  onClick,
  fontSize,
  primaryColor,
  noUnderline,
  textAlignCenter,
  bold,
  italic,
  limit,
  isError,
}) => {
  if (onClick) {
    return (
      <NoteElement
        marginBottom={marginBottom}
        marginTop={marginTop}
        fontSize={fontSize}
        onClick={onClick}
        primaryColor={primaryColor}
        noUnderline={noUnderline}
        textAlignCenter={textAlignCenter}
        bold={bold}
        italic={italic}
        limit={limit}
        isError={isError}
        {...buttonizeDiv(onClick)}
      >
        {text}
      </NoteElement>
    );
  }

  return (
    <NoteElement
      marginBottom={marginBottom}
      marginTop={marginTop}
      fontSize={fontSize}
      primaryColor={primaryColor}
      noUnderline={noUnderline}
      textAlignCenter={textAlignCenter}
      bold={bold}
      italic={italic}
      limit={limit}
      isError={isError}
    >
      {text}
    </NoteElement>
  );
};

Note.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  marginBottom: PropTypes.oneOf([4, 8, 16, 24, 32, 40, 48]),
  onClick: PropTypes.func,
  marginTop: PropTypes.oneOf([4, 8, 16, 24, 32, 40, 48]),
  fontSize: PropTypes.number,
  primaryColor: PropTypes.bool,
  noUnderline: PropTypes.bool,
  textAlignCenter: PropTypes.bool,
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  limit: PropTypes.bool,
  isError: PropTypes.bool,
};

export default Note;
