import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from '../../Modal/Modal';

import StepByStep from '../../StepByStep';
import { REMOVE_USER_WIZARD, WIZARD } from '../../../constants/sessionStorage';
import RemoveUser from './RemoveUser';
import Status from '../Status';
import { removeFromSessionStorage } from '../../../util/helpers/sessionStorageHelper';

const steps = [RemoveUser, Status];

const RemoveUserModal = ({ isOpen, handleClose, userUid }) => {
  const { t } = useTranslation();
  const [hasError, setHasError] = useState(false);

  const onClose = () => {
    handleClose();
    removeFromSessionStorage(WIZARD);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      title={t('userManagement.removeUserTitle')}
      close={handleClose}
      isOpen={isOpen}
      size="sm"
    >
      <StepByStep saveStepDisabled sessionScopeStep={REMOVE_USER_WIZARD}>
        {({ goStepForward, goStepBack }) =>
          steps.map((step) =>
            React.createElement(step, {
              handleBack: goStepBack,
              handleNext: goStepForward,
              handleClose: onClose,
              hasError,
              text: hasError
                ? t('userManagement.wentWrong')
                : t('userManagement.successful'),
              backButtonText: t('common.ok'),
              setHasError,
              userUid,
              onButtonClick: handleClose,
            }),
          )
        }
      </StepByStep>
    </Modal>
  );
};

RemoveUserModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  userUid: PropTypes.string,
};

export default RemoveUserModal;
