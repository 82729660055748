import {
  createErrorType,
  createFetchType,
  createLoadingType,
  createSuccessType,
  createUpdateType,
  createSubmitType,
} from '../actionHelpers';

// metal stream settings
const METAL_STREAM_SETTINGS_SCOPE = 'METAL_STREAM_SETTINGS';

export const MS_SETTINGS_FETCH = createFetchType(METAL_STREAM_SETTINGS_SCOPE);
export const MS_SETTINGS_SUCCESS = createSuccessType(
  METAL_STREAM_SETTINGS_SCOPE,
);
export const MS_SETTINGS_ERROR = createErrorType(METAL_STREAM_SETTINGS_SCOPE);
export const MS_SETTINGS_LOADING = createLoadingType(
  METAL_STREAM_SETTINGS_SCOPE,
);

// update metal stream settings
const UPDATE_MS_SETTINGS_SCOPE = 'UPDATE_MS_SETTINGS';
export const UPDATE_MS_SETTINGS = createUpdateType(UPDATE_MS_SETTINGS_SCOPE);

export const UPDATE_MS_SETTINGS_LOADING = createLoadingType(
  UPDATE_MS_SETTINGS_SCOPE,
);
export const UPDATE_MS_SETTINGS_SUCCESS = createSuccessType(
  UPDATE_MS_SETTINGS_SCOPE,
);
export const UPDATE_MS_SETTINGS_ERROR = createErrorType(
  UPDATE_MS_SETTINGS_SCOPE,
);

const METAL_STREAM_PRODUCT_SCOPE = 'METAL_STREAM_PRODUCT';
export const METAL_STREAM_PRODUCT_FETCH = createFetchType(
  METAL_STREAM_PRODUCT_SCOPE,
);
export const METAL_STREAM_PRODUCT_SUCCESS = createSuccessType(
  METAL_STREAM_PRODUCT_SCOPE,
);
export const METAL_STREAM_PRODUCT_ERROR = createErrorType(
  METAL_STREAM_PRODUCT_SCOPE,
);
export const METAL_STREAM_PRODUCT_LOADING = createLoadingType(
  METAL_STREAM_PRODUCT_SCOPE,
);

const METAL_STREAM_BANK_ACCOUNTS_SCOPE = 'METAL_STREAM_BANK_ACCOUNTS_SCOPE';
export const METAL_STREAM_BANK_ACCOUNTS_FETCH = createFetchType(
  METAL_STREAM_BANK_ACCOUNTS_SCOPE,
);
export const METAL_STREAM_BANK_ACCOUNTS_SUCCESS = createSuccessType(
  METAL_STREAM_BANK_ACCOUNTS_SCOPE,
);
export const METAL_STREAM_BANK_ACCOUNTS_ERROR = createErrorType(
  METAL_STREAM_BANK_ACCOUNTS_SCOPE,
);
export const METAL_STREAM_BANK_ACCOUNTS_LOADING = createLoadingType(
  METAL_STREAM_BANK_ACCOUNTS_SCOPE,
);

const METAL_STREAM_FINISH_SETUP_SCOPE = 'METAL_STREAM_FINISH_SETUP';
export const METAL_STREAM_FINISH_SETUP_UPDATE = createUpdateType(
  METAL_STREAM_FINISH_SETUP_SCOPE,
);
export const METAL_STREAM_FINISH_SETUP_SUCCESS = createSuccessType(
  METAL_STREAM_FINISH_SETUP_SCOPE,
);
export const METAL_STREAM_FINISH_SETUP_ERROR = createErrorType(
  METAL_STREAM_FINISH_SETUP_SCOPE,
);
export const METAL_STREAM_FINISH_SETUP_LOADING = createLoadingType(
  METAL_STREAM_FINISH_SETUP_SCOPE,
);

// metal stream funding registration
const METAL_STREAM_FUNDING_REGISTRATION_SCOPE =
  'METAL_STREAM_FINDING_REGISTRATION';

export const MS_FUNDING_REGISTRATION_FETCH = createFetchType(
  METAL_STREAM_FUNDING_REGISTRATION_SCOPE,
);
export const MS_FUNDING_REGISTRATION_SUCCESS = createSuccessType(
  METAL_STREAM_FUNDING_REGISTRATION_SCOPE,
);
export const MS_FUNDING_REGISTRATION_ERROR = createErrorType(
  METAL_STREAM_FUNDING_REGISTRATION_SCOPE,
);

// metal stream products registration

const METAL_STREAM_PRODUCT_REGISTRATION_SCOPE =
  'METAL_STREAM_PRODUCT_REGISTRATION';
export const MS_PRODUCT_REGISTRATION_FETCH = createFetchType(
  METAL_STREAM_PRODUCT_REGISTRATION_SCOPE,
);
export const MS_PRODUCT_REGISTRATION_SUCCESS = createSuccessType(
  METAL_STREAM_PRODUCT_REGISTRATION_SCOPE,
);
export const MS_PRODUCT_REGISTRATION_ERROR = createErrorType(
  METAL_STREAM_PRODUCT_REGISTRATION_SCOPE,
);
export const MS_PRODUCT_REGISTRATION_LOADING = createLoadingType(
  METAL_STREAM_PRODUCT_REGISTRATION_SCOPE,
);

// metal stream finish setup registration

const METAL_STREAM_FINISH_SETUP_REGISTRATION_SCOPE =
  'METAL_STREAM_FINISH_SETUP_REGISTRATION';
export const METAL_STREAM_FINISH_SETUP_REGISTRATION = createSubmitType(
  METAL_STREAM_FINISH_SETUP_REGISTRATION_SCOPE,
);
export const METAL_STREAM_FINISH_SETUP_REGISTRATION_SUCCESS = createSuccessType(
  METAL_STREAM_FINISH_SETUP_REGISTRATION_SCOPE,
);
export const METAL_STREAM_FINISH_SETUP_REGISTRATION_ERROR = createErrorType(
  METAL_STREAM_FINISH_SETUP_REGISTRATION_SCOPE,
);
export const METAL_STREAM_FINISH_SETUP_REGISTRATION_LOADING = createLoadingType(
  METAL_STREAM_FINISH_SETUP_REGISTRATION_SCOPE,
);

export const MS_AUTO_INVEST = createSuccessType('MS_AUTO_INVEST');
