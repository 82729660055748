import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';
import startCase from 'lodash.startcase';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import config from '../../config';
import { productLocationTypes } from '../../util/enum/api/productTypes';
import { formatMoneyNumeral } from '../../util/helpers/numeralHelpers';
import { parseEnumType } from '../../util/helpers/enumMappers';
import { selectSettings } from '../../store/selectors/settingsSelectors';
import themeColors from '../../assets/styles/themeColors';
import { uFlexCenter, uFlexColumn } from '../../assets/styles/utility';
import { variables } from '../../assets/styles/variables';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';

export const InvestmentContainer = styled.div`
  ${uFlexColumn};
  background-color: ${themeColors.colorWhite};
  box-shadow: ${variables.shadow.boxShadow};
  border-bottom-left-radius: ${variables.borderRadius.borderRadius};
  border-bottom-right-radius: ${variables.borderRadius.borderRadius};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    max-width: ${pxToRemMd(400)};
    width: 100%;
  }
`;

export const InvestmentHeader = styled.div`
  padding: ${pxToRem(8)} ${pxToRem(16)};
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  box-shadow: ${variables.shadow.boxShadow};
  background-color: ${themeColors.colorGrey};
  border-top-left-radius: ${variables.borderRadius.borderRadius};
  border-top-right-radius: ${variables.borderRadius.borderRadius};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(8)} ${pxToRemMd(16)};
  }
`;

export const InvestmentType = styled.p`
  font-size: ${pxToRem(14)};
  line-height: 1.72;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(14)};
  }
`;

export const InvestmentPercentage = styled.p`
  font-size: ${pxToRem(14)};
  line-height: 1.72;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(14)};
  }
`;

export const InvestmentBody = styled.div`
  padding: ${pxToRem(16)} ${pxToRem(8)};
  display: grid;
  grid-template-columns: ${pxToRem(72)} 1fr;
  grid-column-gap: ${pxToRem(8)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(24)} ${pxToRemMd(8)};
    grid-template-columns: ${pxToRemMd(72)} 1fr;
    grid-column-gap: ${pxToRemMd(16)};
  }
`;

export const InvestmentImageWrap = styled.div`
  ${uFlexCenter};
  width: ${pxToRem(72)};
  height: ${pxToRem(72)};
  object-fit: contain;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    width: ${pxToRemMd(72)};
    height: ${pxToRemMd(72)};
  }
`;

export const InvestmentImage = styled.img`
  ${uFlexCenter};
  width: ${pxToRem(72)};
  height: ${pxToRem(72)};
  object-fit: contain;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    width: ${pxToRemMd(72)};
    height: ${pxToRemMd(72)};
  }
`;

export const InvestmentContent = styled.div`
  ${uFlexColumn};
`;

export const InvestmentName = styled.p`
  margin-bottom: auto;
  font-size: ${pxToRem(14)};
  line-height: 1.45;
  font-weight: 600;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(14)};
  }
`;

export const InvestmentPrice = styled.p`
  line-height: 1.45;
  font-size: ${pxToRem(14)};
  margin-top: ${pxToRem(8)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(14)};
    margin-top: ${pxToRemMd(8)};
  }
`;

const Investment = ({ percentage, product, amount, type, symbol }) => {
  const { t } = useTranslation();
  const formatPercentage = (num) => `${(num.toFixed(2) * 100).toFixed(0)}%`;
  const { RootUrl } = useSelector(selectSettings);

  const imageSource = useMemo(
    () =>
      !isEmpty(product) && product.ProductCode
        ? config.algoliaSearch.imageCode
            .replace('{product_code}', product.ProductCode)
            .replace('{base_url}', RootUrl)
        : null,
    [RootUrl, product],
  );

  const renderPercentage = useCallback(
    () => (
      <InvestmentPercentage>
        {formatPercentage(percentage)} (
        {`${formatMoneyNumeral(percentage * amount)}`})
      </InvestmentPercentage>
    ),
    [amount, percentage],
  );

  const locationDetails = useMemo(
    () =>
      !isEmpty(product) &&
      product.Locations.find((location) => location.SymbolCode === symbol),
    [product, symbol],
  );

  if (isEmpty(product)) {
    return null;
  }

  return (
    <InvestmentContainer>
      <InvestmentHeader>
        <InvestmentType>{type}</InvestmentType>
        {renderPercentage()}
      </InvestmentHeader>
      <InvestmentBody>
        {!!imageSource && (
          <InvestmentImageWrap>
            <InvestmentImage src={imageSource} alt="Product" />
          </InvestmentImageWrap>
        )}
        <InvestmentContent>
          <InvestmentName>
            {`${product.ProductCaption} - ${startCase(
              parseEnumType(productLocationTypes, locationDetails.LocationType),
            )}`}
          </InvestmentName>
          <InvestmentPrice>
            {t('investment.price', {
              pricePerOz: formatMoneyNumeral(locationDetails.PricePerOz),
            })}
          </InvestmentPrice>
        </InvestmentContent>
      </InvestmentBody>
    </InvestmentContainer>
  );
};

Investment.propTypes = {
  percentage: PropTypes.number,
  product: PropTypes.shape({
    ProductCode: PropTypes.string,
    ProductCaption: PropTypes.string,
    ProductPrice: PropTypes.number,
    Locations: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  amount: PropTypes.number,
  type: PropTypes.string,
  symbol: PropTypes.string,
};

export default Investment;
