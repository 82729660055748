import styled from 'styled-components';
import { pxToRem } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${themeColors.colorBorderPrimary};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${pxToRem(marginBottom)}` : 0};
  margin-top: ${({ marginTop }) => (marginTop ? `${pxToRem(marginTop)}` : 0)};
`;

export default HorizontalLine;
