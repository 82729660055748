import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import NagDashboardTableRow from './NagDashboardTableRow';
import StandardDepositWizard from '../Modals/Deposit/StandardDepositWizard/StandardDepositWizard';
import { useSessionStorageState } from '../../util/hooks/useSessionStorageState';
import { IS_FUND_MODAL_OPEN } from '../../constants/sessionStorage';
import useCheckPermissions from '../../util/hooks/useCheckPermissions';
import { parseEnumType } from '../../util/helpers/enumMappers';
import {
  accountStatusTypesArray,
  BASIC_ACCOUNT_STEP,
  DEPOSIT_STATUS_TYPES_LABELS,
  nagDashboardStepsArray,
  VERIFIED_NON_POST_PAID,
  VERIFIED_POST_PAID,
  VERIFY_ACCOUNT_STEP,
} from '../../constants/nagDashboardContants';
import IraDepositWizard from '../Modals/DepositWizard/IraDepositWizard';
import { IRA_ENTRUST_TYPE, IRA_EQUITY_TYPE } from '../../util/constants';
import MilleniumDepositWizard from '../Modals/DepositWizard/MilleniumDepositWizard';
import { selectNagDashboard } from '../../store/selectors/accountSelectors';

const basicAccountFundStep = {
  title: 'Fund your account',
  description:
    'Configure your initial funding instructions and we will process them as soon as your account is verified.',
  buttonText: 'Make a deposit',
  status: 'Disabled',
};

const nonVerifiedAccountFundStep = {
  title: 'Fund your account',
  description:
    'Configure your initial funding instructions and we will process them as soon as your account is verified.',
  buttonText: 'Make a deposit',
};

const verifiedAccountFundStep = {
  title: 'Fund your account',
  buttonText: 'Make a deposit',
};

const completedAccountFundStep = {
  title: 'Fund your account',
  description: 'Your account is funded and ready for trading.',
  buttonText: 'Make a deposit',
};

const NagDashboardFundAccountStep = ({ activeStep }) => {
  const {
    displayStandardOptionsPermission,
    displayIRAEquityOptionsPermission,
    displayIRAEntrustOptionsPermission,
    displayIRAMilleniumOptionsPermission,
  } = useCheckPermissions();
  const nagDashboard = useSelector(selectNagDashboard);
  const [isModalOpen, setIsModalOpen] = useSessionStorageState(
    IS_FUND_MODAL_OPEN,
    false,
  );
  const accountStatus = nagDashboard?.AccountStatusType;

  const openFundModal = () => {
    setIsModalOpen(true);
  };

  const getDescriptionBasedOnStatus = () => {
    switch (DEPOSIT_STATUS_TYPES_LABELS[nagDashboard.DepositStatusType]) {
      case 'Pending':
        return 'Your initial funding instructions are being processed. Deposits usually complete within 5-8 business days.';
      case 'WaitingForVerification':
        return 'Your initial funding instructions will be processed once your account is verified.';
      default:
        return 'Configure your initial funding instructions and we will process them as soon as your account is verified.';
    }
  };

  const descriptionBasedOnStatus = getDescriptionBasedOnStatus();

  const getDashboardFundAccount = () => {
    switch (parseEnumType(nagDashboardStepsArray, activeStep)) {
      case BASIC_ACCOUNT_STEP:
        return basicAccountFundStep;
      case VERIFY_ACCOUNT_STEP:
        return {
          ...nonVerifiedAccountFundStep,
          status:
            ((parseEnumType(accountStatusTypesArray, accountStatus) ===
              'RequiresAttention' ||
              parseEnumType(accountStatusTypesArray, accountStatus) ===
                'Pending' ||
              parseEnumType(accountStatusTypesArray, accountStatus) ===
                'Err') &&
              !(
                DEPOSIT_STATUS_TYPES_LABELS[nagDashboard.DepositStatusType] ===
                  'Pending' ||
                DEPOSIT_STATUS_TYPES_LABELS[nagDashboard.DepositStatusType] ===
                  'WaitingForVerification'
              )) ||
            (DEPOSIT_STATUS_TYPES_LABELS[nagDashboard.DepositStatusType] ===
              'WaitingForVerification' &&
              accountStatus === 7)
              ? 'Disabled'
              : DEPOSIT_STATUS_TYPES_LABELS[nagDashboard.DepositStatusType],
          description: descriptionBasedOnStatus,
        };

      case VERIFIED_NON_POST_PAID:
        return {
          ...verifiedAccountFundStep,
          status: DEPOSIT_STATUS_TYPES_LABELS[nagDashboard.DepositStatusType],
          description: descriptionBasedOnStatus,
          action: () => {
            openFundModal();
          },
        };
      case VERIFIED_POST_PAID:
        return {
          ...completedAccountFundStep,
          status: DEPOSIT_STATUS_TYPES_LABELS[nagDashboard.DepositStatusType],
        };
      default:
        return basicAccountFundStep;
    }
  };

  const dashboardData = getDashboardFundAccount();

  return (
    <>
      <NagDashboardTableRow
        title={dashboardData.title}
        description={dashboardData.description}
        buttonText={dashboardData.buttonText}
        status={dashboardData.status}
        action={dashboardData.action}
        activeStep={activeStep}
      />
      {displayStandardOptionsPermission && isModalOpen && (
        <StandardDepositWizard
          isModalOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
        />
      )}
      {displayIRAEquityOptionsPermission && isModalOpen && (
        <IraDepositWizard
          isModalOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          type={IRA_EQUITY_TYPE}
        />
      )}
      {displayIRAEntrustOptionsPermission && isModalOpen && (
        <IraDepositWizard
          isModalOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          type={IRA_ENTRUST_TYPE}
        />
      )}
      {displayIRAMilleniumOptionsPermission && isModalOpen && (
        <MilleniumDepositWizard
          isModalOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};

NagDashboardFundAccountStep.propTypes = {
  activeStep: PropTypes.number,
};

export default NagDashboardFundAccountStep;
