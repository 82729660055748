import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ModalBody from '../Modal/ModalBody';
import Support from '../Support/Support';
import Paragraph from '../Paragraph/Paragraph';
import ModalButtons from '../Modal/ModalButtons';
import { pxToRem } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';

const ValidateAddressesList = styled.ul`
margin-top;${pxToRem(16)};
  list-style-type: circle;
  padding-left: ${pxToRem(50)};
`;
const ValidateAddressesItem = styled.li`
  margin-bottom: ${pxToRem(16)};
  color: ${themeColors.colorTextPrimary};
`;

const NoAddress = ({ openSupport, isSupportOpen, onBack }) => {
  const { t } = useTranslation();

  if (isSupportOpen) {
    return (
      <ModalBody>
        <Support />
      </ModalBody>
    );
  }

  return (
    <ModalBody>
      <Paragraph>
        {t('buyWizard.buyForDelivery.chooseDeliveryAddress.validateAddress')}
      </Paragraph>
      <Paragraph marginTop={16}>
        {t('buyWizard.buyForDelivery.chooseDeliveryAddress.validateAddress1')}
      </Paragraph>
      <ValidateAddressesList>
        <ValidateAddressesItem>
          {t('buyWizard.buyForDelivery.chooseDeliveryAddress.validateAddress2')}
        </ValidateAddressesItem>
        <ValidateAddressesItem>
          {t('buyWizard.buyForDelivery.chooseDeliveryAddress.validateAddress3')}
        </ValidateAddressesItem>
      </ValidateAddressesList>
      <ModalButtons
        isVertical
        marginTop
        secondaryButtonProps={{ onClick: onBack }}
        primaryButtonProps={{
          label: t('buyWizard.common.contactCustomerSupport'),
          onClick: openSupport,
        }}
      />
    </ModalBody>
  );
};

NoAddress.propTypes = {
  onBack: PropTypes.func,
  openSupport: PropTypes.func,
  isSupportOpen: PropTypes.bool,
};

export default NoAddress;
