export const postPaidApiErrors = [
  'BraintreeAuthorizationError',
  'BraintreeCaptureError',
  'SignifydInsuranceError',
  'CreditCardProcessingTimeout',
  'SignifydACHInsuranceError',
  'PlaidAccessTokenUnavailable',
  'SignifydCCInsuranceError',
  'PlaidAccountIdUnavailable',
  'PlaidError',
  'AvailableBalanceTooLow',
];
