import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  SIGNIFYD_SESSION_ID,
  WIZARD_DATA,
} from '../../../constants/sessionStorage';
import Modal from '../../Modal/Modal';
import {
  removeFromSessionStorage,
  retrieveFromSessionStorage,
  storeInSessionStorage,
} from '../../../util/helpers/sessionStorageHelper';
import BuyForStorage from '../../BuyForStorage/BuyForStorage';
import { closeWizardContent } from '../../../util/helpers/wizardHelpers';
import { isActionRestrictedHelper } from '../../../util/helpers/restrictionHelper';
import useRestrictionHook from '../../../util/hooks/useRestrictionHook';
import { restrictionTypes } from '../../../util/enum/api/restrictionTypes';
import Restriction from '../RestrictionModal/Restriction';
import { selectProductTiers } from '../../../store/selectors/productsSelector';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import {
  accountTypeKeys,
  accountTypes,
} from '../../../util/enum/api/accountTypes';
import { getCurrentAccount } from '../../../store/selectors/accountSelectors';
import { selectSettings } from '../../../store/selectors/settingsSelectors';

const BuyForStorageWizard = ({
  isModalOpen,
  handleCloseModal,
  isSegregated,
}) => {
  const [wizardTitle, setWizardTitle] = useState('Buy Wizard');
  const [wizardData, setWizardData] = useState(
    () => retrieveFromSessionStorage(WIZARD_DATA) || {},
  );
  const settings = useSelector(selectSettings);
  const account = useSelector(getCurrentAccount);
  const restrictions = useRestrictionHook();
  const productTiers = useSelector(selectProductTiers);
  const isPostPaidEnabled = settings?.IsPostPaidActive;
  const isIRAAccount =
    parseEnumType(accountTypeKeys, account.AccountType) === accountTypes.IRA;
  const isPostPaidVisible = !isIRAAccount && isPostPaidEnabled;

  const setDataForWizard = (value) => {
    setWizardData({ ...value, isSegregated });
    storeInSessionStorage(WIZARD_DATA, value);
  };

  const closeModal = () => {
    handleCloseModal();
    closeWizardContent();
    removeFromSessionStorage(WIZARD_DATA);
    removeFromSessionStorage(SIGNIFYD_SESSION_ID);
  };

  if (isModalOpen && !isEmpty(wizardData)) {
    return (
      <Modal
        title={wizardTitle}
        size="sm"
        isOpen={isModalOpen}
        close={closeModal}
        dataCy="container-buy-for-storage"
      >
        {isActionRestrictedHelper(restrictions, [
          restrictionTypes.LogonIsPrevented,
          restrictionTypes.RestrictBuy,
        ]) ? (
          <Restriction restrictions={restrictions} />
        ) : (
          <BuyForStorage
            handleBack={closeModal}
            setWizardTitle={setWizardTitle}
            wizardData={wizardData}
            setWizardData={setDataForWizard}
            isProductPage
            closeModal={closeModal}
            isSegregated={isSegregated}
            data-cy="container-buy-for-storage"
            tiers={productTiers?.Tiers}
            isPostPaidVisible={isPostPaidVisible}
          />
        )}
      </Modal>
    );
  }

  return null;
};

BuyForStorageWizard.propTypes = {
  isModalOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  isSegregated: PropTypes.bool,
};

export default BuyForStorageWizard;
