import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Modal from '../../Modal/Modal';

import StepByStep from '../../StepByStep';
import EnterCardDetails from './EnterCardDetails';
import EnterBillingAddress from './EnterBillingAddress';
import Status from '../Status';
import {
  ADD_CREDIT_CARD_WIZARD,
  WIZARD,
  WIZARD_DATA,
} from '../../../constants/sessionStorage';
import {
  removeFromSessionStorage,
  retrieveFromSessionStorage,
  storeInSessionStorage,
} from '../../../util/helpers/sessionStorageHelper';
import ModalBody from '../../Modal/ModalBody';
import { selectAddCreditCardError } from '../../../store/selectors/creditCardSelectors';

const steps = [EnterCardDetails, EnterBillingAddress, Status];

const AddCreditCardWizard = ({ isOpen, onClose }) => {
  const [wizardData, setWizardData] = useState(
    () => retrieveFromSessionStorage(WIZARD_DATA) || {},
  );
  const addCreditCardError = useSelector(selectAddCreditCardError);

  const { t } = useTranslation();

  if (!isOpen) {
    return null;
  }

  const handleClose = () => {
    onClose();
    removeFromSessionStorage(WIZARD);
    removeFromSessionStorage(WIZARD_DATA);
  };

  const setDataForWizard = (value) => {
    if (value) {
      setWizardData(value);
      storeInSessionStorage(WIZARD_DATA, value);
    }
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        close={handleClose}
        title={t('creditCard.addACreditOrDebitCard')}
        size="sm"
        avoidClearingStorage
      >
        <ModalBody>
          <StepByStep sessionScopeStep={ADD_CREDIT_CARD_WIZARD}>
            {({ goStepForward, goStepBack }) =>
              steps.map((step) =>
                React.createElement(step, {
                  handleBack: goStepBack,
                  handleNext: goStepForward,
                  handleClose,
                  wizardData,
                  setWizardData: setDataForWizard,
                  hasError: !!addCreditCardError,
                  text: !addCreditCardError
                    ? t('creditCard.successfulMessage')
                    : t('creditCard.unsuccessfulMessage'),
                  onButtonClick: handleClose,
                  steps: [
                    { label: 'EnterCardDetails', percentage: 50 },
                    { label: 'EnterBillingAddress', percentage: 100 },
                    { label: 'Status', percentage: 100 },
                  ],
                }),
              )
            }
          </StepByStep>
        </ModalBody>
      </Modal>
    </>
  );
};

AddCreditCardWizard.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AddCreditCardWizard;
