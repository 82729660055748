import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, startCase } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { productLocationTypes } from '../../util/enum/api/productTypes';
import OrderTotal from './OrderTotal';
import {
  selectBuyCashBufferPercentage,
  selectSettings,
} from '../../store/selectors/settingsSelectors';
import {
  emptyQuotesOrderStorageBuy,
  executeQuotesForOrderStorageBuy,
  getQuotesForOrderStorageBuy,
} from '../../store/actions/orders/orderActions';
import { getCurrentAccount } from '../../store/selectors/accountSelectors';
import ReviewBar from '../ReviewBar/ReviewBar';
import ModalBody from '../Modal/ModalBody';
import Label from '../Notes/Label';
import useGtmHook from '../../util/hooks/useGtmHook';
import {
  onConfirmOrderGtmHelper,
  verifyOrderEcommerceGtmHelper,
} from '../../util/helpers/gtmHelper';
import { parseEnumType } from '../../util/helpers/enumMappers';
import {
  accountTransactionsFetch,
  fetchPortfolioProductBySymbol,
} from '../../store/actions/portfolio/portfolioActions';
import SectionLoader from '../Loader/SectionLoader';
import { PRODUCT_TRANSACTIONS } from '../../constants/accountTransactionConstants';
import ModalButtons from '../Modal/ModalButtons';
import DelayedSettlementWarning from '../DelayedSettlementWarning/DelayedSettlementWarning';
import CountdownTimer from '../Common/Timers/CountdownTimer';
import Paragraph from '../Paragraph/Paragraph';
import AnchorOnClick from '../Anchor/AnchorOnClick';
import LockedPriceVariableWeightDisclaimerModal from '../Modals/BuyWizard/LockedPriceVariableWeightDisclaimerModal';
import useStartTimer from '../../util/hooks/useStartTimer';
import {
  counterValueToTimestamp,
  handleExpiration,
} from '../../util/helpers/timerHelpers';
import { IdleTimeContext } from '../../providers/IdleTimeContextProvider';
import {
  COUNTDOWN_TIMER_SEC,
  IDLE_TIMEOUT_THRESHOLD,
} from '../../constants/timerConstants';
import ModalTitle from '../Modal/ModalTitle';
import ErrorModal from '../Common/ErrorModal';
import { SIGNIFYD_SESSION_ID } from '../../constants/sessionStorage';
import { retrieveFromSessionStorage } from '../../util/helpers/sessionStorageHelper';
import PaymentFailureStatus from './PaymentFailureStatus';
import { postPaidApiErrors } from '../../util/enum/api/postPaidApiErrors';
import paymentTypes from '../../util/enum/api/paymentTypes';

const ReviewOrderForStorage = ({
  wizardData,
  setWizardData,
  handleBack,
  handleNext,
  setWizardTitle,
  isProductPage,
  isPostPaidVisible,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [finalOrder, setFinalOrder] = useState({});
  const [isDisclaimerModalOpen, setIsDisclaimerModalOpen] = useState(false);
  const buyCashBuffer = useSelector(selectBuyCashBufferPercentage);
  const account = useSelector(getCurrentAccount);

  const settings = useSelector(selectSettings);

  const [expirationMs, setExpirationMs] = useState(0);
  const [counter, setCounter] = useState(COUNTDOWN_TIMER_SEC);
  const [unity, setUnity] = useState(false);
  const [isLoadingGetQuotes, setIsLoadingGetQuotes] = useState(false);
  const [isLoadingExecuteQuotes, setIsLoadingExecuteQuotes] = useState(false);
  const isSegregated = wizardData?.isSegregated;
  const segregatedCaption = settings?.SegregatedCaption;
  const getRemainingTime = useContext(IdleTimeContext);
  const [getQuotesError, setGetQuotesError] = useState('');
  const [executeQuotesError, setExecuteQuotesError] = useState('');
  const [getPostPaidError, setGetPostPaidError] = useState('');

  const hasDelayedSettlement =
    wizardData?.selectedVault?.HasDelayedSettlement ||
    wizardData?.productPrices?.Locations[0]?.HasDelayedSettlement;
  const {
    storageProduct,
    orderValues,
    selectedVault,
    orderType,
    quoteID,
    paymentMethod,
    postPaidCalculation,
    calculation,
    expiryTime,
    productPrices,
    isDefaultPaymentMethod,
  } = wizardData;

  const sessionId = retrieveFromSessionStorage(SIGNIFYD_SESSION_ID);

  const [paymentMethodData, setPaymentMethodData] = useState(
    postPaidCalculation?.paymentMethodStepData || {},
  );

  const paymentMethodInfo =
    paymentMethod?.PaymentMethodType === 3
      ? paymentMethodData?.DataWithCCFee
      : paymentMethodData?.DataWithoutCCFee;

  const newPricePerUnit = isPostPaidVisible
    ? paymentMethodInfo?.ClientPricePerUnit
    : calculation?.newPricePerUnit;

  const quantityExecuted = isPostPaidVisible
    ? paymentMethodInfo?.QuantityExecuted
    : calculation?.quantity;

  const executionPrice = isPostPaidVisible
    ? paymentMethodInfo?.TotalClientExecutionPrice
    : calculation?.estimatedOrderTotal;

  const volumeDiscount = isPostPaidVisible
    ? paymentMethodInfo?.VolumeDiscount
    : calculation?.volumeDiscount;

  const paymentDiscountValue = isPostPaidVisible
    ? postPaidCalculation?.PaymentTypeDiscount
    : calculation?.paymentTypeDiscount;

  const [pricePerUnit, setPricePerUnit] = useState(newPricePerUnit || 0);
  const [quoteUid, setQuoteUid] = useState(quoteID || '');
  const [quantity, setQuantity] = useState(quantityExecuted || 0);
  const [clientExecutionPrice, setClientExecutionPrice] = useState(
    executionPrice || 0,
  );
  const [volDiscount, setVolumeDiscount] = useState(volumeDiscount || 0);
  const [paymentDiscount, setPaymentDiscount] = useState(
    paymentDiscountValue || 0,
  );
  const [expiryTimeStored, setExpiryTimeStored] = useState(false);

  const { SymbolCode } = selectedVault;

  const { ecommerceGtmEvent, gtmEvent, gtmCustomEvent } = useGtmHook();

  useEffect(() => {
    if (!expirationMs) {
      if (expiryTime) {
        handleExpiration(expiryTime, setExpirationMs);
      }
    }
  }, [expirationMs, expiryTime]);

  const onHandleGetQuotesSuccess = (getQuoteResult) => {
    const {
      QuoteUid,
      ExpiresOnUtc,
      QuoteStatusItem: {
        QuantityExecuted,
        ClientPricePerUnit,
        TotalClientExecutionPrice,
        VolumeDiscount,
        PaymentTypeDiscount,
        PaymentMethodStepData,
      },
    } = getQuoteResult;

    const calculation = {
      estimatedOrderTotal: TotalClientExecutionPrice,
      newPricePerUnit: ClientPricePerUnit,
      volumeDiscount: VolumeDiscount,
      quantity: QuantityExecuted,
      paymentTypeDiscount: PaymentTypeDiscount,
      paymentMethodStepData: PaymentMethodStepData,
    };
    setQuoteUid(QuoteUid);
    setPricePerUnit(ClientPricePerUnit);
    setQuantity(QuantityExecuted);
    setClientExecutionPrice(TotalClientExecutionPrice);
    setVolumeDiscount(VolumeDiscount);
    handleExpiration(ExpiresOnUtc, setExpirationMs);
    setPaymentDiscount(PaymentTypeDiscount);
    setPaymentMethodData(PaymentMethodStepData);

    setWizardData({
      ...wizardData,
      calculation,
      quoteID: QuoteUid,
      expiryTime: ExpiresOnUtc,
    });
  };

  const onHandleExecuteQuotesSuccess = () => {
    onConfirmOrder();
  };

  const onHandleGetQuotesError = (errorMessage) => {
    setGetQuotesError(errorMessage);
    setExecuteQuotesError('');
    setGetPostPaidError('');
  };

  const onHandleExecuteQuotesError = (error) => {
    const errorCode = error?.response?.data?.Errors[0]?.Code;
    if (postPaidApiErrors.includes(errorCode)) {
      setGetPostPaidError(errorCode);
    } else {
      setExecuteQuotesError(errorCode);
    }
    setGetQuotesError('');
  };

  const counterUpdater = useCallback((epoch) => {
    if (epoch?.data) {
      const [counter, unity] = epoch.data;
      setCounter((prevCounter) => prevCounter - prevCounter + counter);
      setUnity(unity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchQuotes = () => {
    const paymentTypeUid =
      paymentMethod?.PaymentMethodType !== 1
        ? paymentMethod?.PaymentMethodUid
        : '00000000-0000-0000-0000-000000000000';

    const payload = {
      getQuotesPayload: {
        accountUid: account.AccountUid,
        symbolCode: SymbolCode,
        quantityOrAmount:
          wizardData?.orderValues?.CashAmount ||
          wizardData?.orderValues?.QuantityAmount,
        sideType: 'Buy',
        unitType: wizardData?.orderType,
        paymentType:
          paymentTypes[paymentMethod?.PaymentMethodType] ??
          paymentTypes[isDefaultPaymentMethod?.PaymentMethodType],
      },
      isLoading: setIsLoadingGetQuotes,
      onSuccess: onHandleGetQuotesSuccess,
      onError: onHandleGetQuotesError,
      isSegregated,
      paymentTypeUid,
      addPaymentMethodData: true,
    };
    setIsLoadingGetQuotes(true);
    dispatch(
      getQuotesForOrderStorageBuy({
        ...payload,
      }),
    );
  };

  useEffect(() => {
    if (counter === 2 && SymbolCode) {
      fetchQuotes();
      setCounter(COUNTDOWN_TIMER_SEC);
    }

    return () => dispatch(emptyQuotesOrderStorageBuy());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, account.AccountUid, dispatch, orderType, SymbolCode]);

  useEffect(() => {
    const remainingIdleTime = getRemainingTime();
    if (remainingIdleTime < IDLE_TIMEOUT_THRESHOLD && !expiryTimeStored) {
      const expirationTimeStamp = counterValueToTimestamp(counter);
      setExpiryTimeStored(true);
      setWizardData({
        ...wizardData,
        expiryTime: expirationTimeStamp,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, getRemainingTime]);

  useEffect(() => {
    if (!selectedVault || isEmpty(orderValues)) {
      return;
    }

    if (orderType === 'Cash') {
      setFinalOrder({
        pricePerUnit,
        estimatedPriceForUnit: pricePerUnit,
        quantity,
        estimatedTotal: clientExecutionPrice,
        isSegregated,
        volumeDiscount: volDiscount,
        paymentTypeDiscount: paymentDiscount,
        paymentMethodData,
      });
    }

    if (orderType === 'Quantity') {
      setFinalOrder({
        pricePerUnit,
        quantity,
        estimatedTotal: clientExecutionPrice,
        cashBuffer:
          buyCashBuffer * (orderValues.QuantityAmount * newPricePerUnit),
        isSegregated,
        requiredBalanceWithBuffer:
          orderValues.QuantityAmount * newPricePerUnit +
          orderValues.QuantityAmount * newPricePerUnit * buyCashBuffer,
        volumeDiscount: volDiscount,
        paymentTypeDiscount: paymentDiscount,
        paymentMethodData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    buyCashBuffer,
    pricePerUnit,
    volDiscount,
    quantity,
    selectedVault,
    orderValues,
    orderType,
    clientExecutionPrice,
    newPricePerUnit,
    paymentDiscount,
  ]);

  useEffect(() => {
    if (getPostPaidError) {
      setWizardTitle(t('buyWizard.postPaid.postPaidStorageErrorModalTitle'));
      return;
    }

    setWizardTitle(t('buyWizard.verifyOrder.buyForStorage'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPostPaidError]);

  const shouldHideVariableWeightDisclaimer = () => {
    if (isProductPage) {
      return productPrices.IsFixedWeight;
    }

    return storageProduct.IsFixedWeight;
  };

  const onConfirmOrder = () => {
    setWizardTitle(t('buyWizard.buyForStorage.title'));
    handleNext();
  };

  const onHandleSuccess = (orderCode) => {
    onConfirmOrderGtmHelper({
      gtmEvent,
      gtmCustomEvent,
      wizardData,
      finalOrder,
      account,
      isForDelivery: false,
      orderCode,
    });

    verifyOrderEcommerceGtmHelper({
      ecommerceGtmEvent,
      wizardData,
      finalOrder,
      account,
      orderSideType: 'buy',
      quantity,
      isForDelivery: false,
      orderCode,
    });

    if (isProductPage) {
      dispatch(
        fetchPortfolioProductBySymbol({
          accountUid: account.AccountUid,
          symbol: selectedVault.SymbolCode,
          isSegregated,
        }),
      );
      dispatch(
        accountTransactionsFetch({
          accountUid: account.AccountUid,
          productSymbol: selectedVault.SymbolCode,
          pageNumber: 0,
          pageSize: 20,
          content: PRODUCT_TRANSACTIONS,
        }),
      );
    }
  };

  const handleConfirmOrder = () => {
    const payload = {
      executeQuotePayload: {
        quoteUid: quoteUid || quoteID,
        signifydSessionId:
          isPostPaidVisible && paymentMethod?.PaymentMethodType !== 1
            ? sessionId
            : 'null',
      },
      isLoading: setIsLoadingExecuteQuotes,
      onSuccess: onHandleExecuteQuotesSuccess,
      onConfirm: onHandleSuccess,
      onError: onHandleExecuteQuotesError,
    };
    setIsLoadingExecuteQuotes(true);
    dispatch(
      executeQuotesForOrderStorageBuy({
        ...payload,
      }),
    );
  };

  const closeErrorModal = () => {
    if (getQuotesError) {
      setGetQuotesError('');
      handleBack();
    }
    setExecuteQuotesError('');
    fetchQuotes();
  };

  const closeDisclaimerModal = () => {
    setIsDisclaimerModalOpen(false);
  };

  useStartTimer(
    expirationMs,
    counterUpdater,
    isLoadingGetQuotes || isLoadingExecuteQuotes,
  );

  if (isDisclaimerModalOpen) {
    return (
      <LockedPriceVariableWeightDisclaimerModal
        isOpen={isDisclaimerModalOpen}
        close={closeDisclaimerModal}
        goBack={closeDisclaimerModal}
      />
    );
  }

  if (getQuotesError || executeQuotesError) {
    return (
      <ErrorModal
        title={t('buyWizard.buyForStorage.title')}
        subtitle={t('apiErrors.IssueWithYourOrder')}
        message={
          getQuotesError
            ? t(`apiErrors.${getQuotesError}`)
            : t(`apiErrors.${executeQuotesError}`)
        }
        btnTitle={
          getQuotesError
            ? t('product.chooseAnAmount')
            : t('product.reviewYourOrder')
        }
        isOpen={getQuotesError || executeQuotesError}
        close={closeErrorModal}
        goBack={closeErrorModal}
      />
    );
  }

  if (getPostPaidError) {
    return (
      <PaymentFailureStatus
        handleBack={handleBack}
        paymentMethod={paymentMethod}
      />
    );
  }

  return (
    <SectionLoader isLoading={isLoadingGetQuotes || isLoadingExecuteQuotes}>
      <ReviewBar title={t('buyWizard.verifyOrder.review')} />
      <ModalBody>
        <ModalTitle marginBottom={4}>
          {isSegregated
            ? `${storageProduct?.ProductCaption} ${startCase(
                parseEnumType(
                  productLocationTypes,
                  selectedVault?.LocationType,
                ),
              )} (${segregatedCaption})`
            : `${storageProduct?.ProductCaption} ${startCase(
                parseEnumType(
                  productLocationTypes,
                  selectedVault?.LocationType,
                ),
              )}`}
        </ModalTitle>
        <Label text={t('buyWizard.verifyOrder.subtitle')} />

        {!isEmpty(finalOrder) && (
          <OrderTotal
            orderValues={orderValues}
            finalOrder={finalOrder}
            orderType={orderType}
            buyCashBuffer={buyCashBuffer}
            paymentMethod={paymentMethod}
            isPostPaidVisible={isPostPaidVisible}
          />
        )}
        {hasDelayedSettlement && <DelayedSettlementWarning marginTop={20} />}
        {settings?.IsLockedPricingActive &&
          !shouldHideVariableWeightDisclaimer() && (
            <Paragraph fontSize={14} marginTop={12}>
              {t('lockedPrice.disclaimerInfo')}
              <AnchorOnClick onClick={() => setIsDisclaimerModalOpen(true)}>
                {t('lockedPrice.disclaimerInfoLink')}
              </AnchorOnClick>
            </Paragraph>
          )}
        {getQuotesError ||
          (executeQuotesError && (
            <Paragraph isError fontSize={14} marginTop={12}>
              {getQuotesError
                ? `apiErrors.${getQuotesError}`
                : `apiErrors.${executeQuotesError}`}
            </Paragraph>
          ))}
        <CountdownTimer
          counter={counter}
          unity={unity}
          loading={isLoadingExecuteQuotes || isLoadingGetQuotes}
        />
        <ModalButtons
          marginTop
          isHorizontal
          primaryButtonProps={{
            onClick: handleConfirmOrder,
            label: t('common.confirm'),
            'data-cy': 'button-confirm',
          }}
          secondaryButtonProps={{
            onClick: () => {
              handleBack();
              fetchQuotes();
            },
            'data-cy': 'button-back',
          }}
        />
      </ModalBody>
    </SectionLoader>
  );
};

ReviewOrderForStorage.propTypes = {
  wizardData: PropTypes.shape({
    paymentMethod: PropTypes.shape({
      PaymentMethodType: PropTypes.number,
      PaymentMethodUid: PropTypes.number,
    }),
    isDefaultPaymentMethod: PropTypes.shape({
      PaymentMethodType: PropTypes.number,
      PaymentMethodUid: PropTypes.number,
    }),
    selectedVault: PropTypes.shape({
      LocationType: PropTypes.number,
      PricePerUnit: PropTypes.number,
      SymbolCode: PropTypes.string,
      HasDelayedSettlement: PropTypes.bool,
      BuyMinimumAmount: PropTypes.number,
    }),
    newCalculation: PropTypes.shape({
      estimatedOrderTotal: PropTypes.number,
      newPricePerUnit: PropTypes.number,
      volumeDiscount: PropTypes.number,
      quantity: PropTypes.number,
      paymentTypeDiscount: PropTypes.number,
    }),
    postPaidCalculation: PropTypes.shape({
      PaymentTypeDiscount: PropTypes.number,
      paymentMethodStepData: PropTypes.shape({
        DataWithCCFee: PropTypes.number,
        DataWithoutCCFee: PropTypes.number,
      }),
    }),
    isSegregated: PropTypes.bool,
    orderValues: PropTypes.shape({
      QuantityAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      CashAmount: PropTypes.number,
    }),
    storageProduct: PropTypes.shape({
      ProductCaption: PropTypes.string,
      IsFixedWeight: PropTypes.bool,
    }),
    productPrices: PropTypes.shape({
      Locations: PropTypes.shape({
        HasDelayedSettlement: PropTypes.bool,
      }),
      IsFixedWeight: PropTypes.bool,
    }),
    orderType: PropTypes.string,
    ExpiresOnUtc: PropTypes.string,
    calculation: PropTypes.shape({
      estimatedOrderTotal: PropTypes.number,
      newPricePerUnit: PropTypes.number,
      volumeDiscount: PropTypes.number,
      quantity: PropTypes.number,
      paymentTypeDiscount: PropTypes.number,
    }),
    quoteID: PropTypes.string,
    expiryTime: PropTypes.string,
  }),
  setWizardData: PropTypes.func,
  setWizardTitle: PropTypes.func,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  isProductPage: PropTypes.bool,
  isSegregated: PropTypes.bool,
  isPostPaidVisible: PropTypes.bool,
};

export default ReviewOrderForStorage;
