import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import themeColors from '../../assets/styles/themeColors';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import Checkbox from '../InputFields/Checkbox';
import { ReactComponent as Info } from '../../assets/images/svg/info-icon.svg';
import { buttonizeDiv } from '../../util/buttonizeDiv';
import LearnMoreModal from './LearnMoreModal';
import { selectSettings } from '../../store/selectors/settingsSelectors';

export const SegregatedStorageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 7px 0 7px 0;
  gap: 6px;
  background: ${themeColors.colorTableEvenRowHoverBackground};

  p {
    text-align: left;
  }

  svg {
    width: ${pxToRem(16)};
    margin: 0 ${pxToRem(8)};
  }

  button {
    cursor: pointer;
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    font-size: ${pxToRemMd(14)};

    svg {
      width: ${pxToRemMd(16)};
      margin: 0 ${pxToRemMd(8)};
    }
  }
`;

const InfoWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: ${pxToRem(16)};
`;
const IconWrap = styled.div`
margin-right: ${pxToRem(18)};

  &,
  svg {
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    &,
    svg {
      width: ${pxToRemMd(16)};
      height: ${pxToRemMd(16)};
    }

`;
const InfoText = styled.div`
  text-decoration: underline;
  font-size: ${pxToRem(16)};
  color: #5d90ad;
  cursor: pointer;
`;
const UseSegregatedStorage = ({
  isSegregatedStorageEnabled,
  isSegregatedStorageSelect,
  selectIsSegregatedStorage,
}) => {
  const { t } = useTranslation();
  const settings = useSelector(selectSettings);
  const segregatedCaption = settings?.SegregatedCaption;
  const [isLearnMoreModalOpen, setIsLearnMoreModalOpen] = useState(false);

  return (
    <>
      <SegregatedStorageContainer>
        {isSegregatedStorageSelect && (
          <Checkbox
            onChange={() =>
              selectIsSegregatedStorage(!isSegregatedStorageSelect)
            }
            checked={isSegregatedStorageSelect}
            disabled={!isSegregatedStorageEnabled}
          >
            {t('buyWizard.chooseVault.segregatedStorage', {
              segregatedCaption,
            })}
          </Checkbox>
        )}
        <InfoWrap
          {...buttonizeDiv((event) => {
            event.preventDefault();
            setIsLearnMoreModalOpen(true);
          })}
        >
          <IconWrap>
            <Info />
          </IconWrap>
          <InfoText>
            {isSegregatedStorageSelect
              ? t('buyWizard.chooseVault.learnMore')
              : t('buyWizard.chooseVault.requireSegregatedStorage', {
                  segregatedCaption,
                })}
          </InfoText>
        </InfoWrap>
      </SegregatedStorageContainer>
      {isLearnMoreModalOpen && (
        <LearnMoreModal
          isOpen={isLearnMoreModalOpen}
          close={() => setIsLearnMoreModalOpen(false)}
          selectIsSegregatedStorage={selectIsSegregatedStorage}
          isSegregatedStorageSelect={isSegregatedStorageSelect}
          isSegregatedStorageEnabled={isSegregatedStorageEnabled}
        />
      )}
    </>
  );
};

UseSegregatedStorage.propTypes = {
  isSegregatedStorageSelect: PropTypes.bool,
  isSegregatedStorageEnabled: PropTypes.bool,
  vault: PropTypes.shape({
    LocationType: PropTypes.number,
  }),
  selectIsSegregatedStorage: PropTypes.func,
};

export default UseSegregatedStorage;
