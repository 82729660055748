import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { updateAccountUser } from '../../../store/actions/account/accountActions';
import { getCurrentAccount } from '../../../store/selectors/accountSelectors';
import {
  ACCOUNT_CHANGE_USER_ROLE,
  userPermissionTypes,
  USER_ROLE_TYPES,
  USER_ROLE_TYPES_LABELS,
} from '../../../util/enum/api/accountTypes';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import ModalBody from '../../Modal/ModalBody';
import ModalButtons from '../../Modal/ModalButtons';
import TwoColumnList from '../../TwoColumnList/TwoColumnList';
import Label from '../../Notes/Label';
import Paragraph from '../../Paragraph/Paragraph';
import TypeList from '../../TypeList/TypeList';
import RoundedRadio from '../../InputFields/RoundedRadio';
import SingleColumnList from '../../TwoColumnList/SingleColumnList';
import SectionLoader from '../../Loader/SectionLoader';

const EditUser = ({ selectedUser, handleClose, handleNext, setError }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const account = useSelector(getCurrentAccount);
  const [isLoading, setIsLoading] = useState(false);

  const roleTypes = Object.values(USER_ROLE_TYPES)?.map((roleType) =>
    roleType.toString(),
  );

  const filterOutNotificationOnly = roleTypes.filter(
    (item) => item !== roleTypes[0],
  );

  if (!selectedUser) {
    return null;
  }

  const { Email, UserUid, RoleType } = selectedUser;

  const onFormSubmit = ({ permissionOption }) => {
    dispatch(
      updateAccountUser({
        accountUid: account.AccountUid,
        userUid: UserUid,
        actionType: ACCOUNT_CHANGE_USER_ROLE,
        data: [{ op: 'replace', path: '/RoleType', value: permissionOption }],
        handleNext,
        setError,
        setIsLoading,
      }),
    );
  };

  return (
    <SectionLoader isLoading={isLoading}>
      <ModalBody>
        <Formik
          initialValues={{
            permissionOption: parseEnumType(userPermissionTypes, RoleType),
          }}
          onSubmit={onFormSubmit}
        >
          {({ values }) => (
            <Form>
              <TwoColumnList rightFullWidth marginBottom={32}>
                <Label text={t('user.email.label')} />
                <Paragraph>{Email}</Paragraph>
              </TwoColumnList>
              <Label text={t('user.permissions.label')} marginBottom={4} />
              <TypeList>
                {filterOutNotificationOnly.map((type) => (
                  <Field
                    key={type}
                    id={type}
                    type="radio"
                    name="permissionOption"
                    component={RoundedRadio}
                    value={type}
                    selected={values.permissionOption === type}
                  >
                    <SingleColumnList>
                      <Label
                        text={t(
                          `user.permissions.${USER_ROLE_TYPES_LABELS[type]}.label`,
                        )}
                        marginBottom={8}
                      />
                      <Paragraph>
                        {t(
                          `user.permissions.${USER_ROLE_TYPES_LABELS[type]}.description`,
                        )}
                      </Paragraph>
                    </SingleColumnList>
                  </Field>
                ))}
              </TypeList>
              <ModalButtons
                marginTop
                isHorizontal
                secondaryButtonProps={{
                  label: t('common.cancel'),
                  onClick: handleClose,
                }}
                primaryButtonProps={{
                  label: t('common.save'),
                  disabled: values.permissionOption === userPermissionTypes[0],
                  type: 'submit',
                }}
              />
            </Form>
          )}
        </Formik>
      </ModalBody>
    </SectionLoader>
  );
};

EditUser.propTypes = {
  handleClose: PropTypes.func,
  selectedUser: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  handleNext: PropTypes.func,
  setError: PropTypes.func,
};

export default EditUser;
