import React from 'react';
import { Redirect, Route } from 'react-router';
import PropTypes from 'prop-types';
import { authScopeStringGetHelper } from '../../util/helpers/authScopeHelpers';
import { JWT_TOKEN } from '../../constants/localStorage';
import { PORTFOLIO_PAGE } from '../../constants/pages';

const RegistrationRoute = ({ ...props }) => {
  const jwtToken = authScopeStringGetHelper(JWT_TOKEN);

  return jwtToken ? <Redirect to={PORTFOLIO_PAGE} /> : <Route {...props} />;
};

RegistrationRoute.propTypes = {
  path: PropTypes.string,
};

export default RegistrationRoute;
