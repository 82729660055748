import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AddAddress from './AddAddress';
import Status from '../Status';
import Modal from '../../Modal/Modal';
import StepByStep from '../../StepByStep';
import { ADD_ADDRESS_WIZARD } from '../../../constants/sessionStorage';

const steps = [AddAddress, Status];

const AddAddressModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');

  return (
    <Modal title="Add Address" size="sm" isOpen={isOpen} close={onClose}>
      <StepByStep saveStepDisabled sessionScopeStep={ADD_ADDRESS_WIZARD}>
        {({ goStepForward, goStepBack }) =>
          steps.map((step) =>
            React.createElement(step, {
              handleBack: goStepBack,
              handleNext: goStepForward,
              handleClose: onClose,
              onButtonClick: onClose,
              hasError: !!error,
              text: error || t('account.addresses.addAddressSuccessfulMessage'),
              backButtonText: t('common.ok'),
              setError,
            }),
          )
        }
      </StepByStep>
    </Modal>
  );
};

AddAddressModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AddAddressModal;
