import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import TypeCard from '../../../TypeCard/TypeCard';
import { DEPOSIT_WIZARD_TYPES } from '../../../../constants/standardDepositWizardConstants';
import useGtmHook from '../../../../util/hooks/useGtmHook';
import {
  selectAchDepositLimit,
  selectCheckDepositLimit,
  selectSettlementDaysInfo,
} from '../../../../store/selectors/settingsSelectors';
import { formatMoneyNumeral } from '../../../../util/helpers/numeralHelpers';
import { FUND_DEPOSIT_START } from '../../../../constants/gtmEvents';
import {
  ACH_DEPOSIT,
  CHECK_DEPOSIT,
  WIRE_DEPOSIT,
} from '../../../../util/constants';
import { useIsClosedAccount } from '../../../../util/hooks/useIsClosedAccount';
import ModalBody from '../../../Modal/ModalBody';
import TypeList from '../../../TypeList/TypeList';

const StandardDepositTypeSelect = ({
  handleNext,
  setWizardData,
  setModalSize,
  setWizardTitle,
}) => {
  const { t } = useTranslation();

  const { gtmDialogScreenView, fundGtmEvent } = useGtmHook();
  const allowedAchDepositLimit = useSelector(selectAchDepositLimit);
  const allowedCheckDepositLimit = useSelector(selectCheckDepositLimit);
  const settlementDaysInfo = useSelector(selectSettlementDaysInfo);
  const isClosedAccount = useIsClosedAccount();

  const setSelectedDepositType = (type, gtmType) => {
    setWizardData({ depositType: type });
    // gtmEvent(DEPOSIT_INITIAITED, {
    //   DepositType: type,
    // });
    fundGtmEvent(FUND_DEPOSIT_START, {
      fund: {
        method: type,
      },
    });
    gtmDialogScreenView({
      title: `Deposit Funds - ${gtmType}`,
    });
    handleNext();
  };

  useEffect(() => {
    setModalSize('sm');
    setWizardTitle(t('depositWizard.depositFunds'));
  }, [setModalSize, setWizardTitle, t]);

  const typeValues = [
    {
      title: t('standardDepositWizard.achDeposit.achDepositTitle'),
      highlight: t('standardDepositWizard.achDeposit.achDepositHighlight'),
      description: t('standardDepositWizard.achDeposit.achDepositDescription'),
      note: `${t(
        'standardDepositWizard.achDeposit.achDepositVoidedCheckNote',
      )} \n ${t('standardDepositWizard.achDeposit.achDepositNote', {
        amount: formatMoneyNumeral(allowedAchDepositLimit),
        days: settlementDaysInfo,
      })}`,
      onClick: () =>
        setSelectedDepositType(DEPOSIT_WIZARD_TYPES.ACH, ACH_DEPOSIT),
      disabled: isClosedAccount,
    },
    {
      title: t('standardDepositWizard.wireDeposit.wireDepositTitle'),
      highlight: t('standardDepositWizard.wireDeposit.wireDepositHighlight'),
      description: t(
        'standardDepositWizard.wireDeposit.wireDepositDescription',
      ),
      note: t('standardDepositWizard.wireDeposit.wireDepositNote'),
      onClick: () =>
        setSelectedDepositType(DEPOSIT_WIZARD_TYPES.WIRE, WIRE_DEPOSIT),
    },
    {
      title: t('standardDepositWizard.checkDeposit.checkDepositTitle'),
      description: t(
        'standardDepositWizard.checkDeposit.checkDepositDescription',
      ),
      note: t('standardDepositWizard.checkDeposit.checkDepositNote', {
        amount: formatMoneyNumeral(allowedCheckDepositLimit),
      }),
      onClick: () =>
        setSelectedDepositType(DEPOSIT_WIZARD_TYPES.CHECK, CHECK_DEPOSIT),
    },
  ];

  return (
    <ModalBody>
      <TypeList>
        {typeValues.map((type) => (
          <TypeCard {...type} />
        ))}
      </TypeList>
    </ModalBody>
  );
};

StandardDepositTypeSelect.propTypes = {
  handleNext: PropTypes.func,
  setWizardData: PropTypes.func,
  setModalSize: PropTypes.func,
  setWizardTitle: PropTypes.func,
};

export default StandardDepositTypeSelect;
