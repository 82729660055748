import { css } from 'styled-components';
import { variables } from './variables';

// Pixels to rems/Rems to pixels
export const pxToRem = (px) =>
  `${(px / variables.sizes.baseFontSize).toFixed(3)}rem`;

export const pxToRemMd = (px) =>
  `${(px / variables.sizes.baseFontSizeMd).toFixed(3)}rem`;

// Breakpoints
export const mediaUp = (media) =>
  `@media only screen and (min-width: ${media}px)`;

export const mediaBelow = (media) =>
  `@media only screen and (max-width: ${media - 0.02}px)`;

export const mediaBetween = (mediaMin, mediaMax) =>
  `@media only screen and (min-width: ${mediaMin}px) and (max-width: ${
    mediaMax - '0.02'
  }px)`;

// Styles props
const stylesObject = {
  marginTop: (size) =>
    css`
      margin-top: ${pxToRem(size)};

      ${mediaBelow(variables.breakpoints.bpMd)} {
        margin-top: ${pxToRemMd(size)};
      }
    `,
  marginBottom: (size) =>
    css`
      margin-bottom: ${pxToRem(size)};

      ${mediaBelow(variables.breakpoints.bpMd)} {
        margin-bottom: ${pxToRemMd(size)};
      }
    `,
  marginLeft: (size) =>
    css`
      margin-left: ${pxToRem(size)};

      ${mediaBelow(variables.breakpoints.bpMd)} {
        margin-left: ${pxToRemMd(size)};
      }
    `,
  marginRight: (size) =>
    css`
      margin-right: ${pxToRem(size)};

      ${mediaBelow(variables.breakpoints.bpMd)} {
        margin-right: ${pxToRemMd(size)};
      }
    `,

  marginVertical: (size) =>
    css`
      margin-top: ${pxToRem(size)};
      margin-bottom: ${pxToRem(size)};

      ${mediaBelow(variables.breakpoints.bpMd)} {
        margin-top: ${pxToRemMd(size)};
        margin-bottom: ${pxToRemMd(size)};
      }
    `,

  fontSize: (size) => css`
    font-size: ${pxToRem(size)};

    ${mediaBelow(variables.breakpoints.bpMd)} {
      font-size: ${pxToRemMd(size)};
    }
  `,

  maxWidth: (size) =>
    css`
      max-width: ${size}px;
      width: 100%;

      ${mediaBelow(variables.breakpoints.bpMd)} {
        max-width: ${size}px;
        width: 100%;
      }
    `,
  textDecoration: (value) =>
    css`
      text-decoration: ${value};
    `,
};

export const getStylesByProps = (props) => {
  let styles = [];

  Object.keys(props).forEach((key) => {
    if (stylesObject[key] && props[key]) {
      styles = [...styles, stylesObject[key](props[key])];
    }
  });

  return styles;
};
