import * as Yup from 'yup';
import i18next from '../i18n';

export const bankAccountAddressValidationSchema = Yup.object().shape({
  bankCity: Yup.string().required(
    i18next.t('validationSchema.bankAccount.bankCityRequired'),
  ),
  bankPostalCode: Yup.string()
    .required(i18next.t('validationSchema.bankAccount.bankPostalCodeRequired'))
    .matches(
      /^\S*$/,
      i18next.t('validationSchema.bankAccount.bankPostalCodeRequirement'),
    )
    .max(
      10,
      i18next.t('validationSchema.bankAccount.bankPostalCodeRequirement'),
    ),
});
