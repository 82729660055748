import styled from 'styled-components';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';

const ProfileSettingsItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${pxToRem(marginBottom)}` : 0};
  margin-top: ${({ marginTop }) => (marginTop ? `${pxToRem(marginTop)}` : 0)};

  > :first-child {
    margin-right: auto;
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    flex-direction: column;

    button {
      margin-top: ${pxToRemMd(16)};
      width: 100%;
      font-size: ${pxToRemMd(18)} !important;
      padding: ${pxToRemMd(8)} ${pxToRemMd(15)} !important;
    }
  }
`;

export default ProfileSettingsItem;
