/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BannerSubText, BannerText } from '../RestrictionBanner/Banner';
import Button from '../Button/Button';
import Note from '../Notes/Note';
import { storeInSessionStorage } from '../../util/helpers/sessionStorageHelper';
import {
  IS_DEPOSIT_MODAL_OPEN,
  PREVENT_SUPPORT_MODAL,
} from '../../constants/sessionStorage';
import { setWizardContent } from '../../util/helpers/wizardHelpers';
import { mediaBelow, pxToRem } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import themeColors from '../../assets/styles/themeColors';

export const BannerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ sectionAlign }) =>
    sectionAlign
      ? `${pxToRem(18.288)} ${pxToRem(52)}`
      : `${pxToRem(24)} ${pxToRem(18.288)}`};
  background-color: ${themeColors.colorPendingBannerBackground};

  button {
    margin-left: 30px;
    height: max-content;
    width: max-content;
  }


  ${mediaBelow(variables.breakpoints.bpXl)} {
    ${({ sectionAlign }) =>
      sectionAlign && `padding: ${pxToRem(24)} ${pxToRem(18.288)};`}

  ${mediaBelow(variables.breakpoints.bpMd)} {
    flex-direction: column;
    justify-content: center;
    padding: ${pxToRem(24)} ${pxToRem(18.288)};

    button {
      height: max-content;
      width: max-content;
      margin-left: 0;
      margin-top: 15px;
      align-self: flex-start;
    }
  }
`;

export const BannerTextContainer = styled.div``;

const StorageFeeBanner = ({
  amount,
  setIsDepositModalOpen,
  gtmDialogScreenView,
  isDepositAvailable,
  setIsOpenPayStorageFeeByCard,
  setIsDepositRestrictionsModalOpen,
  isBasicAccount,
}) => {
  const { t } = useTranslation();
  return (
    <BannerContainer sectionAlign>
      <BannerTextContainer>
        <BannerText>{t('storageFee.storageFeeDueBannerTitle')}</BannerText>
        <BannerSubText>
          {t('storageFee.storageFeeDueBannerSubtext1', {
            amount: `$${amount}`,
          })}
          <Note
            primaryColor
            bold
            text={t('storageFee.storageFeeDueBannerSubtext2')}
            onClick={(event) => {
              event.preventDefault();
              setIsOpenPayStorageFeeByCard(true);
            }}
          />
          {t('storageFee.storageFeeDueBannerSubtext3')}
          <Note
            primaryColor
            bold
            text={t('storageFee.storageFeeDueBannerSubtext4')}
            onClick={(event) => {
              if (isBasicAccount || !isDepositAvailable) {
                setIsDepositRestrictionsModalOpen(true);
                return;
              }
              event.preventDefault();
              storeInSessionStorage(PREVENT_SUPPORT_MODAL, true);
              setIsDepositModalOpen(true);
              setWizardContent(IS_DEPOSIT_MODAL_OPEN, true);
              gtmDialogScreenView({
                title: 'Deposit Funds',
              });
            }}
          />
          {t('storageFee.storageFeeDueBannerSubtext5')}
        </BannerSubText>
      </BannerTextContainer>
      <Button
        onClick={() => setIsOpenPayStorageFeeByCard(true)}
        type="button"
        variant="primary"
        textTransform="capitalize"
      >
        {t('storageFee.storageFeeDueBannerButtonText')}
      </Button>
    </BannerContainer>
  );
};

StorageFeeBanner.propTypes = {
  amount: PropTypes.number,
  setIsDepositModalOpen: PropTypes.func,
  gtmDialogScreenView: PropTypes.func,
  isDepositAvailable: PropTypes.bool,
  setIsOpenPayStorageFeeByCard: PropTypes.func,
  setIsDepositRestrictionsModalOpen: PropTypes.func,
  isBasicAccount: PropTypes.bool,
};
export default StorageFeeBanner;
