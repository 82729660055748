import Bowser from 'bowser';
import take from 'lodash.take';

export const getBrowserInfo = () => {
  const { name: BrowserName, version } = Bowser.getParser(
    window.navigator.userAgent,
  ).getBrowser();

  if (!version) {
    return null;
  }

  const BrowserVersion = take(version.split('.'), 2).join('.');

  if (!BrowserVersion || !BrowserName) {
    return null;
  }

  return { BrowserName, BrowserVersion };
};

export const getBrowserPayload = (payload) => {
  const browserInfo = getBrowserInfo();

  if (browserInfo) {
    const { BrowserName, BrowserVersion } = browserInfo;

    return {
      ...payload,
      BrowserName,
      BrowserVersion,
    };
  }

  return {
    ...payload,
  };
};
