import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';

const InformationListElement = styled.ul`
  display: grid;
  grid-row-gap: ${pxToRem(8)};
  max-width: ${pxToRem(640)};
  ${({ withContainer }) =>
    withContainer && `padding: ${pxToRem(8)} ${pxToRem(16)};`}
  ${({ ignoreBackground }) => ignoreBackground && `margin: 0 ${pxToRem(-16)};`}
  ${({ whiteBackground }) =>
    whiteBackground &&
    `background-color: ${themeColors.colorWhite};
      max-width: 200%;`}
  margin-top: ${({ marginTop }) => (marginTop ? `${pxToRem(marginTop)}` : 0)};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${pxToRem(marginBottom)}` : 0};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    max-width: 100%;
    grid-row-gap: ${pxToRemMd(8)};

    ${({ ignoreBackground }) =>
      ignoreBackground && `margin: 0 ${pxToRemMd(-16)};`}
  }
`;

const InformationListContainer = styled.div`
  background-color: ${themeColors.colorBackgroundSecondary};
  border-radius: ${variables.borderRadius.borderRadius};
  max-width: ${pxToRem(730)};
  padding: ${pxToRem(16)};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${pxToRem(marginBottom)}` : `${pxToRem(16)}`};
  ${({ marginTop }) => marginTop && `margin-top: ${pxToRem(marginTop)};`}
  ${({ isSmall }) => isSmall && `max-width: ${pxToRem(428)};`}
  ${({ noMargin }) => noMargin && `margin: 0;`}


  
  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(16)};
    margin-bottom: ${pxToRemMd(16)};

    ${({ isSmall }) => isSmall && `max-width: ${pxToRemMd(428)};`}
  }
`;

const InformationList = ({
  children,
  withBackground,
  isSmall,
  noMargin,
  withContainer,
  ignoreBackground,
  whiteBackground,
  marginBottom,
  marginTop,
}) => {
  if (withBackground) {
    return (
      <InformationListContainer
        isSmall={isSmall}
        noMargin={noMargin}
        marginTop={marginTop}
        marginBottom={marginBottom}
      >
        <InformationListElement>{children}</InformationListElement>
      </InformationListContainer>
    );
  }

  return (
    <InformationListElement
      withContainer={withContainer}
      ignoreBackground={ignoreBackground}
      whiteBackground={whiteBackground}
      marginBottom={marginBottom}
      marginTop={marginTop}
    >
      {children}
    </InformationListElement>
  );
};

InformationList.propTypes = {
  children: PropTypes.node,
  withBackground: PropTypes.bool,
  isSmall: PropTypes.bool,
  noMargin: PropTypes.bool,
  withContainer: PropTypes.bool,
  ignoreBackground: PropTypes.bool,
  whiteBackground: PropTypes.bool,
  marginBottom: PropTypes.number,
  marginTop: PropTypes.number,
};

export default InformationList;
