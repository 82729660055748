import {
  createErrorType,
  createSubmitType,
  createFetchType,
  createSuccessType,
  createLoadingType,
  createDeleteType,
} from '../actionHelpers';

// GET /2fa/availabletypes - FETCH 2FA Types
const TWO_FA_TYPES_SCOPE = 'TWO_FA_TYPES_SCOPE';
export const TWO_FA_TYPES = createFetchType(TWO_FA_TYPES_SCOPE);
export const TWO_FA_TYPES_SUCCESS = createSuccessType(TWO_FA_TYPES_SCOPE);
export const TWO_FA_TYPES_ERROR = createErrorType(TWO_FA_TYPES_SCOPE);
export const TWO_FA_TYPES_LOADING = createLoadingType(TWO_FA_TYPES_SCOPE);

// GET /users/{userUid}/2fa/keys - FETCH 2FA Application keys (Authenticator APP)
const TWO_FA_APPLICATION_KEYS_SCOPE = 'TWO_FA_APPLICATION_KEYS_SCOPE';
export const TWO_FA_APPLICATION_KEYS = createFetchType(
  TWO_FA_APPLICATION_KEYS_SCOPE,
);
export const TWO_FA_APPLICATION_KEYS_SUCCESS = createSuccessType(
  TWO_FA_APPLICATION_KEYS_SCOPE,
);
export const TWO_FA_APPLICATION_KEYS_ERROR = createErrorType(
  TWO_FA_APPLICATION_KEYS_SCOPE,
);
export const TWO_FA_APPLICATION_KEYS_LOADING = createLoadingType(
  TWO_FA_APPLICATION_KEYS_SCOPE,
);

// POST /users/{userUid}/2fa/code - POST 2FA Code - sends code to client via phone or email (Phone Email)
const TWO_FA_CODE_SCOPE = 'TWO_FA_CODE_SCOPE';
export const TWO_FA_CODE = createSubmitType(TWO_FA_CODE_SCOPE);
export const TWO_FA_CODE_SUCCESS = createSuccessType(TWO_FA_CODE_SCOPE);
export const TWO_FA_CODE_ERROR = createErrorType(TWO_FA_CODE_SCOPE);
export const TWO_FA_CODE_LOADING = createLoadingType(TWO_FA_CODE_SCOPE);

// GET /users/{username}/2fa/phonenumbers/{phoneUid}/code - Send 2FA Code to Phone
const TWO_FA_CODE_VIA_PHONE_SCOPE = 'TWO_FA_CODE_VIA_PHONE_SCOPE';
export const TWO_FA_CODE_VIA_PHONE = createFetchType(
  TWO_FA_CODE_VIA_PHONE_SCOPE,
);
export const TWO_FA_CODE_VIA_PHONE_SUCCESS = createSuccessType(
  TWO_FA_CODE_VIA_PHONE_SCOPE,
);
export const TWO_FA_CODE_VIA_PHONE_ERROR = createErrorType(
  TWO_FA_CODE_VIA_PHONE_SCOPE,
);
export const TWO_FA_CODE_VIA_PHONE_LOADING = createLoadingType(
  TWO_FA_CODE_VIA_PHONE_SCOPE,
);

// GET /users/{username}/2fa/phonenumbers/{phoneUid}/code - Send 2FA Code to Phone
const TWO_FA_CODE_VIA_EMAIL_SCOPE = 'TWO_FA_CODE_VIA_EMAIL_SCOPE';
export const TWO_FA_CODE_VIA_EMAIL = createFetchType(
  TWO_FA_CODE_VIA_EMAIL_SCOPE,
);
export const TWO_FA_CODE_VIA_EMAIL_SUCCESS = createSuccessType(
  TWO_FA_CODE_VIA_EMAIL_SCOPE,
);
export const TWO_FA_CODE_VIA_EMAIL_ERROR = createErrorType(
  TWO_FA_CODE_VIA_EMAIL_SCOPE,
);
export const TWO_FA_CODE_VIA_EMAIL_LOADING = createLoadingType(
  TWO_FA_CODE_VIA_EMAIL_SCOPE,
);

// POST /users/{username}/2fa - Save 2FA application
const SAVE_TWO_FA_SCOPE = 'SAVE_TWO_FA_SCOPE';
export const SAVE_TWO_FA = createSubmitType(SAVE_TWO_FA_SCOPE);
export const SAVE_TWO_FA_SUCCESS = createSuccessType(SAVE_TWO_FA_SCOPE);
export const SAVE_TWO_FA_ERROR = createErrorType(SAVE_TWO_FA_SCOPE);
export const SAVE_TWO_FA_LOADING = createLoadingType(SAVE_TWO_FA_SCOPE);

// DELETE /users/{userUid}/2fa - Delete 2FA Application
const DELETE_TWO_FA_SCOPE = 'DELETE_TWO_FA_SCOPE';
export const DELETE_TWO_FA = createDeleteType(DELETE_TWO_FA_SCOPE);
export const DELETE_TWO_FA_SUCCESS = createSuccessType(DELETE_TWO_FA_SCOPE);
export const DELETE_TWO_FA_ERROR = createErrorType(DELETE_TWO_FA_SCOPE);
export const DELETE_TWO_FA_LOADING = createLoadingType(DELETE_TWO_FA_SCOPE);

// GET /users/{userUid}/2fa - Get Setuped 2FA
const GET_TWO_FA_SCOPE = 'GET_TWO_FA_SCOPE';
export const GET_TWO_FA = createFetchType(GET_TWO_FA_SCOPE);
export const GET_TWO_FA_SUCCESS = createSuccessType(GET_TWO_FA_SCOPE);
export const GET_TWO_FA_ERROR = createErrorType(GET_TWO_FA_SCOPE);
export const GET_TWO_FA_LOADING = createLoadingType(GET_TWO_FA_SCOPE);

// GET /2fa/isOpen - Get Is 2fa Customer Support Open 2FA
const GET_TWO_FA_CUSTOMER_SUPPORT_IS_OPEN_SCOPE =
  'GET_TWO_FA_CUSTOMER_SUPPORT_IS_OPEN_SCOPE';
export const GET_TWO_FA_CUSTOMER_SUPPORT_IS_OPEN = createFetchType(
  GET_TWO_FA_CUSTOMER_SUPPORT_IS_OPEN_SCOPE,
);
export const GET_TWO_FA_CUSTOMER_SUPPORT_IS_OPEN_SUCCESS = createSuccessType(
  GET_TWO_FA_CUSTOMER_SUPPORT_IS_OPEN_SCOPE,
);
export const GET_TWO_FA_CUSTOMER_SUPPORT_IS_OPEN_ERROR = createErrorType(
  GET_TWO_FA_CUSTOMER_SUPPORT_IS_OPEN_SCOPE,
);
export const GET_TWO_FA_CUSTOMER_SUPPORT_IS_OPEN_LOADING = createLoadingType(
  GET_TWO_FA_CUSTOMER_SUPPORT_IS_OPEN_SCOPE,
);
