import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as jwt from 'jsonwebtoken';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useHistory } from 'react-router';
import get from 'lodash-es/get';
import Auth from '../../components/Auth/Auth';
import TextField from '../../components/InputFields/TextField';
import Checkbox from '../../components/InputFields/Checkbox';

import {
  selectLoginError,
  selectTokens,
} from '../../store/selectors/loginSelectors';
import {
  resetLoginState,
  updateSecurityQuestionAnswer,
} from '../../store/actions/login/loginActions';
import {
  EMAIL_LOGIN_PAGE,
  INVITE_USER_PAGE,
  INVITE_USER_WELCOME_PAGE,
  USER_LOGIN_PAGE,
  VERIFY_CHOOSE_METHOD_PAGE,
} from '../../constants/pages';
import useGtmHook from '../../util/hooks/useGtmHook';
import { selectSettings } from '../../store/selectors/settingsSelectors';
import { accountSecurityQuestionTypesObject } from '../../util/enum/api/accountTypes';
import { inviteUser } from '../../store/actions/user/userActions';
import { setRegisterContent } from '../../util/helpers/registerHelpers';
import {
  ERROR,
  INVITE_USER_DATA,
  USER_INVITE_STATUS_MODAL,
} from '../../constants/sessionStorage';
import { useSessionStorageState } from '../../util/hooks/useSessionStorageState';
import StatusModal from '../../components/Modals/StatusModal';
import { closeWizardContent } from '../../util/helpers/wizardHelpers';
import { getUsernames } from '../../util/helpers/userHelpers';
import { selectIsLoadingByActionType } from '../../store/selectors/loadingSelectors';
import { INVITE_USER_LOADING } from '../../store/actions/user/userActionConstants';
import { getBrowserPayload } from '../../util/helpers/browserHelper';
import { securityQuestionValidationSchema } from '../../validation/securityQuestionValidationSchema';
import AuthCard from '../../components/Auth/AuthCard';
import Paragraph from '../../components/Paragraph/Paragraph';
import FormContainer from '../../components/FormContainer/FormContainer';
import AuthButtons from '../../components/Auth/AuthButtons';
import { landingPageHelper } from '../../util/helpers/landingPageHelper';

const SecurityQuestionPage = ({
  isInviteUserFlow,
  formValues,
  userInviteId,
  handleNext,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const brokerSettings = useSelector(selectSettings);
  const tokens = useSelector(selectTokens);
  const error = useSelector(selectLoginError);
  const [isLoading, setIsLoading] = useState(false);
  const isLoadingSelector = useSelector(
    selectIsLoadingByActionType(INVITE_USER_LOADING),
  );
  const [securityQuestionData, setSecurityQuestionData] = useState({
    RefreshToken: '',
    SecurityQuestionType: '',
  });
  const { IsBroker2FAActive } = brokerSettings;
  const usernames = getUsernames();
  const LinkWithExistingLogin = usernames.length > 0;
  const [errorInviteUser, setError] = useSessionStorageState(ERROR, '');
  const [statusModal, setStatusModal] = useSessionStorageState(
    USER_INVITE_STATUS_MODAL,
    false,
  );

  const { gtmScreenView } = useGtmHook();

  useEffect(() => {
    if (!isInviteUserFlow) {
      gtmScreenView({
        path: window.location.pathname,
        title: 'Login',
      });
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    const { RefreshToken, JwtToken } = tokens;

    if (isInviteUserFlow) {
      if (!RefreshToken) {
        return history.push(INVITE_USER_PAGE);
      }
    }

    if (JwtToken) {
      if (IsBroker2FAActive) {
        return history.push({
          pathname: VERIFY_CHOOSE_METHOD_PAGE,
          state: {
            from: history.location.pathname,
          },
        });
      }

      return history.push({
        pathname: landingPageHelper(),
        state: {
          from: history.location.pathname,
        },
      });
    }

    if (!RefreshToken) {
      return history.push(USER_LOGIN_PAGE);
    }

    const { SecurityQuestionType } = jwt.decode(RefreshToken);
    setSecurityQuestionData({ RefreshToken, SecurityQuestionType });
  }, [tokens]); // eslint-disable-line

  const existingLoginHandleNextClick = () => {
    const { values } = formValues;
    const { RefreshToken } = tokens;
    const { Username, Password } = values;
    const { SecurityQuestionType } = jwt.decode(RefreshToken);
    const inviteUserData = {
      Username: Username.value,
      Password,
      LinkWithExistingLogin,
      InvitedUserInfo: {
        EncryptedPersonEntity: userInviteId,
        SecurityQuestionType: get(
          accountSecurityQuestionTypesObject,
          SecurityQuestionType,
          null,
        ),
      },
    };

    dispatch(
      inviteUser({
        data: inviteUserData,
        meta: {
          handleNext: () => handleNext(INVITE_USER_WELCOME_PAGE),
          createSessionUser: () =>
            setRegisterContent(INVITE_USER_DATA, inviteUserData),
          setError,
          setIsLoading,
          setStatusModal,
        },
      }),
    );
  };

  const handleSubmit = (values) => {
    const payload = getBrowserPayload({
      ...securityQuestionData,
      SecurityAnswer: values.answer,
    });

    dispatch(
      updateSecurityQuestionAnswer({
        ...payload,
        setIsLoading,
        isInviteUserFlow,
        existingLoginHandleNextClick,
        isTrustedLocation: values.remember,
      }),
    );
  };

  const handleCancel = () => {
    dispatch(resetLoginState());
    if (isInviteUserFlow) {
      history.push(INVITE_USER_PAGE);
    } else {
      history.push(EMAIL_LOGIN_PAGE);
    }
  };

  return (
    <Auth>
      <AuthCard
        isLoading={isLoading || isLoadingSelector}
        title={t('profileSettings.securityQuestionTitle')}
        subtitle={t('login.securityQuestion.pleaseAnswer')}
      >
        <Paragraph bold marginBottom={24}>
          {securityQuestionData?.SecurityQuestionType
            ? t(
                `login.securityQuestionTypes.${securityQuestionData.SecurityQuestionType}`,
              )
            : ''}
        </Paragraph>

        <Formik
          initialValues={{ answer: '', remember: false }}
          validationSchema={securityQuestionValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form>
              <FormContainer>
                <Field
                  label={t('profileSettings.answer')}
                  name="answer"
                  component={TextField}
                  errorMessage={error}
                  autoFocus
                />
                <Field
                  name="remember"
                  component={Checkbox}
                  checked={values.remember}
                >
                  {t('login.securityQuestion.rememberMe')}
                </Field>
              </FormContainer>
              <AuthButtons
                isVertical
                secondaryButtonProps={{
                  onClick: handleCancel,
                  label: t('common.cancel'),
                }}
                primaryButtonProps={{
                  type: 'submit',
                  label: t('login.securityQuestion.gainAccess'),
                }}
              />
            </Form>
          )}
        </Formik>

        {errorInviteUser && statusModal && (
          <StatusModal
            isOpen={statusModal}
            hasError={errorInviteUser}
            close={() => {
              setStatusModal(false);
              setError('');
              closeWizardContent(ERROR);
              closeWizardContent(USER_INVITE_STATUS_MODAL);
            }}
            text={errorInviteUser}
            modalTitle={i18next.t('inviteUser.review.statusModal')}
            backButtonText={i18next.t('common.ok')}
            onButtonClick={() => {
              setStatusModal(false);
              setError('');
              closeWizardContent(ERROR);
              closeWizardContent(USER_INVITE_STATUS_MODAL);
            }}
          />
        )}
      </AuthCard>
    </Auth>
  );
};

SecurityQuestionPage.propTypes = {
  isInviteUserFlow: PropTypes.bool,
  handleNext: PropTypes.func,
  userInviteId: PropTypes.string,
  formValues: PropTypes.shape({
    values: PropTypes.shape({
      ExistingUsername: PropTypes.string,
      LegalFirstName: PropTypes.string,
      LegalMiddleName: PropTypes.string,
      LegalLastName: PropTypes.string,
      Suffix: PropTypes.string,
      Username: PropTypes.string,
      Password: PropTypes.string,
      SecurityQuestion: PropTypes.oneOfType([
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
        PropTypes.string,
      ]),
      Answer: PropTypes.string,
      answer: PropTypes.string,
      remember: PropTypes.bool,
    }),
    phoneNumbers: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default SecurityQuestionPage;
