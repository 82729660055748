import isEmpty from 'lodash.isempty';
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import {
  IRA_ENTRUST_TYPE,
  IRA_EQUITY_TRUST_TYPE,
  IRA_EQUITY_TYPE,
} from '../../../../util/constants';
import TabSelect from '../../../TabSelect/TabSelect';
import EntrustCheckTab from './EntrustCheckTab';
import EntrustWireTab from './EntrustWireTab';
import EquityCheckTab from './EquityCheckTab';
import EquityWireTab from './EquityWireTab';
import EquityAchTab from './EquityAchTab';
import InformationList from '../../../InformationList/InformationList';
import InformationListItem from '../../../InformationList/InformationListItem';
import Anchor from '../../../Anchor/Anchor';
import ModalBody from '../../../Modal/ModalBody';
import ModalImage from '../../../Modal/ModalImage';
import ModalTitle from '../../../Modal/ModalTitle';
import Paragraph from '../../../Paragraph/Paragraph';
import ModalButtons from '../../../Modal/ModalButtons';
import config from '../../../../config';

const IraContribution = ({ type, onOkClick, iraSettings }) => {
  const TAB_TYPE_WIRE_ACH =
    type === IRA_EQUITY_TYPE ? 'Wire Transfer' : 'Wire or ACH Transfer';
  const TAB_TYPE_CHECK = 'Contribution via Check';
  const TAB_TYPE_ACH = 'ACH Transfer';
  const [tab, setTab] = useState(TAB_TYPE_WIRE_ACH);
  const { t } = useTranslation();
  const { brokerTheme } = config;
  const isLPM = brokerTheme === 'LegacyPM';
  const isAch = tab === TAB_TYPE_ACH;

  const typeOfIra = () => {
    if (type === IRA_EQUITY_TYPE) {
      return IRA_EQUITY_TRUST_TYPE;
    }
    return IRA_ENTRUST_TYPE;
  };

  const renderType = () => {
    if (type === IRA_EQUITY_TYPE) {
      switch (tab) {
        case TAB_TYPE_WIRE_ACH:
          return (
            <EquityWireTab
              wireDetails={
                isEmpty(iraSettings) ? {} : iraSettings.WireDepositInstructions
              }
              trusteeDetails={
                isEmpty(iraSettings) ? {} : iraSettings.IRATrusteeDetails
              }
              showAltNotice
            />
          );
        case TAB_TYPE_ACH:
          return (
            <EquityAchTab
              achDetails={
                isEmpty(iraSettings) ? {} : iraSettings.AchDepositInstructions
              }
              trusteeDetails={
                isEmpty(iraSettings) ? {} : iraSettings.IRATrusteeDetails
              }
              showAltNotice
            />
          );

        case TAB_TYPE_CHECK:
          return (
            <EquityCheckTab
              checkDetails={
                isEmpty(iraSettings) ? {} : iraSettings.CheckDepositInstructions
              }
            />
          );
        default:
          break;
      }
    } else {
      switch (tab) {
        case TAB_TYPE_WIRE_ACH:
          return (
            <EntrustWireTab
              wireDetails={
                isEmpty(iraSettings) ? {} : iraSettings.WireDepositInstructions
              }
            />
          );

        case TAB_TYPE_CHECK:
          return (
            <EntrustCheckTab
              checkDetails={
                isEmpty(iraSettings) ? {} : iraSettings.CheckDepositInstructions
              }
            />
          );

        default:
          break;
      }
    }
  };

  const iraDetails = useMemo(() => {
    if (isEmpty(iraSettings)) {
      return {};
    }
    if (type === IRA_EQUITY_TYPE || type === IRA_ENTRUST_TYPE) {
      return isEmpty(iraSettings.IRATrusteeDetails)
        ? {}
        : iraSettings.IRATrusteeDetails;
    }
  }, [iraSettings, type]);

  return (
    <ModalBody>
      <ModalTitle>{t('depositWizard.iraContribution.title')}</ModalTitle>
      <Paragraph marginBottom={24}>
        {t('depositWizard.iraContribution.fastestWayToFund', {
          type: typeOfIra(),
        })}
      </Paragraph>
      <Paragraph marginBottom={24}>
        {t('depositWizard.iraContribution.haveAnyQuestions', {
          type: typeOfIra(),
        })}
      </Paragraph>

      <TabSelect
        initialValue={TAB_TYPE_WIRE_ACH}
        types={
          type === IRA_EQUITY_TYPE
            ? [TAB_TYPE_WIRE_ACH, TAB_TYPE_ACH, TAB_TYPE_CHECK]
            : [TAB_TYPE_WIRE_ACH, TAB_TYPE_CHECK]
        }
        onChange={(type) => setTab(type)}
      >
        {type && renderType(type)}
      </TabSelect>

      {iraDetails?.LogoImageUrl && (
        <ModalImage src={iraDetails.LogoImageUrl} alt="IRA Trustee" />
      )}
      <Paragraph bold marginBottom={4}>
        {t('depositWizard.iraContribution.questions')}
      </Paragraph>
      {!isLPM ||
        (!isAch && (
          <Paragraph marginBottom={24}>
            {t('depositWizard.iraContribution.contactCenter', {
              type: typeOfIra(),
            })}
          </Paragraph>
        ))}
      {iraDetails && (
        <InformationList withBackground noMargin>
          <InformationListItem
            label={t('depositWizard.iraContribution.phone')}
            value={<Anchor type="telephone" value={iraDetails.Phone} />}
          />
          <InformationListItem
            label={t('depositWizard.iraContribution.fax')}
            value={<Anchor type="telephone" value={iraDetails.Fax} />}
          />
          <InformationListItem
            label={t('depositWizard.iraContribution.email')}
            value={<Anchor type="email" value={iraDetails.Email} />}
            whiteSpace
          />
          {type !== 'equity' && iraDetails.Contact && (
            <InformationListItem
              label={t('depositWizard.iraContribution.contact')}
              value={<Anchor type="website" value={iraDetails.Contact} />}
              whiteSpace
            />
          )}
          {type !== 'equity' && iraDetails.Url && (
            <InformationListItem
              label={t('depositWizard.iraContribution.contact')}
              value={<Anchor type="website" value={iraDetails.Url} />}
            />
          )}
        </InformationList>
      )}

      <ModalButtons
        showOnlyPrimary
        marginTop
        primaryButtonProps={{ onClick: onOkClick, label: t('common.ok') }}
        smallButtons
        buttonOnRight
      />
    </ModalBody>
  );
};

IraContribution.propTypes = {
  type: PropTypes.string,
  onOkClick: PropTypes.func,
  iraSettings: PropTypes.shape({
    WireDepositInstructions: PropTypes.shape({
      DocumentUrl: PropTypes.string,
    }),
    CheckDepositInstructions: PropTypes.shape({}),
    AchDepositInstructions: PropTypes.shape({}),
    IRATrusteeDetails: PropTypes.shape({}),
    IraTrusteeDetails: PropTypes.shape({}),
  }),
};

export default IraContribution;
