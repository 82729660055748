import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Modal from '../../Modal/Modal';

import StepByStep from '../../StepByStep';
import PayStorageFeeByCard from './PayStorageFeeByCard';
import Status from '../Status';
import Paragraph from '../../Paragraph/Paragraph';
import { selectCreditCards } from '../../../store/selectors/creditCardSelectors';

const steps = [PayStorageFeeByCard, Status];

const PayStorageFeeByCardModal = ({
  isOpen,
  handleClose,
  storageFeeAmount,
  setStorageFeeAmount,
  isAutoPaySettingsModalOpen,
  setIsAutoPaySettingsModalOpen,
  setIsOpenPayStorageFeeByCard,
  isOpenPayStorageFeeByCard,
  handleRefreshTransactions,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [selectedCreditCard, setSelectedCreditCard] = useState();
  const creditCards = useSelector(selectCreditCards);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isAutoPayOn, setIsAutoPayOn] = useState(false);

  useEffect(() => {
    setIsAutoPayOn(creditCards?.some((card) => card.IsDefaultForStorage));
    setSelectedCreditCard(
      creditCards?.find((card) => card.IsDefaultForStorage) || creditCards[0],
    );
  }, [creditCards]);

  useEffect(() => {
    setButtonDisabled(!creditCards?.some((card) => card.IsDefaultForStorage));
  }, [creditCards]);

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        close={handleClose}
        title={t('storageFee.payStorageFeeByCardModal.label')}
        size="sm"
      >
        <StepByStep saveStepDisabled>
          {({ goStepForward, goStepBack }) =>
            steps.map((step) =>
              React.createElement(step, {
                handleBack: goStepBack,
                handleNext: goStepForward,
                handleClose,
                setError,
                hasError: !!error,
                text: (
                  <>
                    <Paragraph bold>
                      {error
                        ? t(
                            'storageFee.payStorageFeeByCardModal.paymentFailureTitle',
                          )
                        : t(
                            'storageFee.payStorageFeeByCardModal.paymentSuccessfulTitle',
                          )}
                    </Paragraph>
                    {error
                      ? t(
                          'storageFee.payStorageFeeByCardModal.paymentFailureMessage',
                        )
                      : t(
                          'storageFee.payStorageFeeByCardModal.paymentSuccessfulMessage',
                        )}
                  </>
                ),
                backButtonText: t('common.ok'),
                onButtonClick: handleClose,
                storageFeeAmount,
                setStorageFeeAmount,
                setSelectedCreditCard,
                selectedCreditCard,
                showAutoPayStatus: true,
                isAutoPayOn,
                setIsAutoPayOn,
                isAutoPaySettingsModalOpen,
                setIsAutoPaySettingsModalOpen,
                setIsOpenPayStorageFeeByCard,
                isOpenPayStorageFeeByCard,
                buttonDisabled,
                handleRefreshTransactions,
              }),
            )
          }
        </StepByStep>
      </Modal>
    </>
  );
};

PayStorageFeeByCardModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  storageFeeAmount: PropTypes.number,
  setStorageFeeAmount: PropTypes.func,
  isAutoPaySettingsModalOpen: PropTypes.bool,
  setIsAutoPaySettingsModalOpen: PropTypes.func,
  setIsOpenPayStorageFeeByCard: PropTypes.func,
  isOpenPayStorageFeeByCard: PropTypes.bool,
  handleRefreshTransactions: PropTypes.func,
};

export default PayStorageFeeByCardModal;
