import * as Yup from 'yup';
import { INVITE_EMAIL, USERNAME } from '../constants/sessionStorage';
import i18next from '../i18n';
import { retrieveFromSessionStorage } from '../util/helpers/sessionStorageHelper';

const username = retrieveFromSessionStorage(USERNAME);
const inviteUserEmail = retrieveFromSessionStorage(INVITE_EMAIL);

const invalidValue = inviteUserEmail || username;

export const passwordValidationRule = Yup.string()
  .notOneOf([invalidValue])
  .required(i18next.t(`validationSchema.general.passwordRequired`))
  .matches(
    /(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/,
    i18next.t(`validationSchema.general.passwordStrength`),
  );
