import React, { useRef } from 'react';
import PropType from 'prop-types';
import styled from 'styled-components';
import {
  uButtonClear,
  uFlexCenter,
  uOutlineNone,
  uTransition,
} from '../../assets/styles/utility';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import themeColors from '../../assets/styles/themeColors';
import { ReactComponent as Lock } from '../../assets/images/svg/lock.svg';

const getButtonSize = (size) => {
  if (size === 'sm') {
    return `
      font-size: ${pxToRem(16)};
      line-height: 1.5;
      padding: ${pxToRem(4)} ${pxToRem(12)};

      ${mediaBelow(variables.breakpoints.bpMd)} {
        padding: ${pxToRemMd(4)} ${pxToRemMd(15)};
      }`;
  }

  if (size === 'lg') {
    return `
    ${mediaBelow(variables.breakpoints.bpMd)} {
      padding: ${pxToRemMd(7.5)} ${pxToRemMd(15)};
      font-size: ${pxToRemMd(18)};
      line-height: 1.5;
    }`;
  }
};

const getButtonVariant = (variant) => {
  if (variant === 'primary') {
    return `
      background: ${themeColors.colorButtonPrimary};
      color: ${themeColors.colorButtonPrimaryText};
      border: 1px solid ${themeColors.colorButtonPrimary};
      
      ${({ lockStyle }) =>
        lockStyle &&
        `&:hover {
      background: ${themeColors.colorButtonPrimary};
      box-shadow: none;
    }
 `};
      &:disabled {
        &:hover {
          background: ${themeColors.colorButtonPrimary};
          box-shadow: none;
        }
      }

      &:hover {
        background: ${themeColors.colorButtonPrimaryHover};
        box-shadow: ${variables.shadow.buttonShadowHover};
        color: ${themeColors.colorButtonPrimaryHoverText};
      }

      &:focus,
      &:active {
        background: ${themeColors.colorButtonPrimaryFocused};
        box-shadow: ${variables.shadow.buttonShadowPressed};
        color: ${themeColors.colorButtonPrimaryHoverText};
      }
    `;
  }

  if (variant === 'primaryOutlined') {
    return `
      background: ${themeColors.colorButtonOutlined};
      color: ${themeColors.colorButtonOutlinedText};
      border: 1px solid ${themeColors.colorButtonOutlinedBorder};
      ${({ lockStyle }) =>
        lockStyle &&
        `&:hover {
          color: ${themeColors.colorButtonOutlinedText};
          border: 1px solid ${themeColors.colorButtonOutlinedBorder};
        }
 `};
      &:disabled {
        &:hover {
          color: ${themeColors.colorButtonOutlinedText};
          border: 1px solid ${themeColors.colorButtonOutlinedBorder};
        }
      }

 
      &:hover,
      &:focus,
      &:active {
        color: ${themeColors.colorButtonOutlinedText};
        border: 1px solid ${themeColors.colorButtonOutlinedBorder};
      }
    `;
  }

  if (variant === 'primaryClear') {
    return `
      background: transparent;
      color: ${themeColors.colorButtonPrimary};
      border: none;
      box-shadow: none;
      padding: 0;`;
  }

  if (variant === 'default') {
    return `
      background: ${themeColors.colorButtonDefault};
      color: ${themeColors.colorButtonDefaultText};
      border: 1px solid ${themeColors.colorButtonDefaultBorder};

      > svg {
        color: ${themeColors.colorButtonDefaultText};
      }

      &:hover,
      &:focus,
      &:active {
        color: ${themeColors.colorButtonDefaultHover};
        border: 1px solid ${themeColors.colorButtonDefaultHover};

        > svg {
          color: ${themeColors.colorButtonDefaultHover};
        }
      }
    `;
  }
};

const getButtonTextStyles = (type) => {
  if (type === 'capitalize') {
    return `text-transform: capitalize`;
  }

  if (type === 'uppercase') {
    return `text-transform: uppercase`;
  }
};

const getIconVariant = (variant) => {
  if (variant === 'primary') {
    return `
      color: ${themeColors.colorButtonPrimaryText};
       svg {
     color: ${themeColors.colorButtonPrimaryText};
   }
    `;
  }

  if (variant === 'primaryOutlined') {
    return `
      color: ${themeColors.colorButtonOutlinedText};
      svg {
        color: ${themeColors.colorButtonOutlinedText};
      }
    `;
  }

  if (variant === 'primaryClear') {
    return `
      color: ${themeColors.colorButtonPrimary};
      svg {
        color: ${themeColors.colorButtonPrimary};
      }
`;
  }

  if (variant === 'default') {
    return `
      color: ${themeColors.colorButtonDefaultText};
      svg {
        color: ${themeColors.colorButtonDefaultText};
      }
    `;
  }
};

export const ButtonElement = styled.button.attrs((props) => ({
  'data-cy': props['data-cy'] || 'default-button',
}))`
  ${uOutlineNone};
  ${uButtonClear};
  ${uFlexCenter};
  ${uTransition};
  font-size: ${({ fontSize }) => fontSize || `${pxToRem(18)}`};
  line-height: 1.35;
  padding: ${pxToRem(8)} ${pxToRem(8)};
  margin-top: ${({ marginTop }) => pxToRem(marginTop)};
  border-radius: ${variables.borderRadius.borderRadius};
  box-shadow: ${variables.shadow.buttonShadowPressed};
  color: inherit;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  text-transform: ${({ textTransform }) => textTransform || 'uppercase'};
  user-select: none;
  white-space: nowrap;
  flex-shrink: 0;
  cursor: pointer;
  min-width: ${({ minWidth }) => minWidth || `${pxToRem(120)}`};
  ${({ hidden }) =>
    hidden &&
    `visibility: hidden;
     height: 0;
    `}
  ${({ size }) => getButtonSize(size)};
  ${({ variant }) => getButtonVariant(variant)};
  ${({ textTransform }) => getButtonTextStyles(textTransform)};
  ${({ lockStyle }) =>
    lockStyle &&
    `opacity: 0.5;
 `};

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    user-interaction: none;
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(4)} ${pxToRemMd(25)};
    font-size: ${pxToRemMd(16)};
    line-height: 1.5;
    min-width: ${pxToRemMd(80)};
  }

  ${mediaBelow(variables.breakpoints.bpXs)} {
    white-space: unset;
  }
`;

const ButtonLockStatusWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
const ButtonStatus = styled.div`
  ${({ variant }) => getIconVariant(variant)};
  display: flex;
`;

export const LockIconWrap = styled.div`
  ${({ variant }) => getIconVariant(variant)};

  &,
  svg {
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    &,
    svg {
      width: ${pxToRemMd(16)};
      height: ${pxToRemMd(16)};
    }
  }
`;
const Button = ({
  children,
  authButton,
  type,
  onClick,
  disabled,
  hidden,
  minWidth,
  fontSize,
  marginBottom,
  marginTop,
  label,
  lockStyle,
  variant,
  ...restProps
}) => {
  const buttonRef = useRef(null);

  function handleClick() {
    buttonRef.current.blur();
    if (typeof onClick === 'function') {
      onClick();
    }
  }

  return (
    <ButtonElement
      marginBottom={marginBottom}
      marginTop={marginTop}
      minWidth={minWidth}
      fontSize={fontSize}
      hidden={hidden}
      ref={buttonRef}
      onClick={handleClick}
      type={type}
      disabled={disabled}
      variant={variant}
      lockStyle={lockStyle}
      {...restProps}
    >
      {lockStyle ? (
        <ButtonLockStatusWrap>
          <LockIconWrap variant={variant}>
            <Lock />
          </LockIconWrap>
          <ButtonStatus variant={variant}>{label || children}</ButtonStatus>
        </ButtonLockStatusWrap>
      ) : (
        label || children
      )}
    </ButtonElement>
  );
};

Button.propTypes = {
  children: PropType.node,
  size: PropType.oneOf(['sm', 'md', 'lg', 'xl']),
  authButton: PropType.bool,
  variant: PropType.string,
  type: PropType.oneOf(['button', 'submit', 'reset']),
  onClick: PropType.func,
  disabled: PropType.bool,
  minWidth: PropType.oneOf(['auto']),
  fontSize: PropType.string,
  hidden: PropType.bool,
  marginBottom: PropType.number,
  marginTop: PropType.number,
  label: PropType.string,
  lockStyle: PropType.bool,
};

Button.defaultProps = {
  type: 'button',
};

export default Button;
