import styled from 'styled-components';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';

const ModalImage = styled.img`
  height: ${pxToRem(50)};
  align-self: flex-start;
  object-fit: contain;
  margin-bottom: ${pxToRem(16)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-bottom: ${pxToRemMd(16)};
    height: ${pxToRemMd(50)};
  }
`;

export default ModalImage;
