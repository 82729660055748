import { getRequest, replaceInUrl, downloadRequest } from './index';
import apiEndpoints from './apiEndpoints';

export const getDocuments = (accountUid, year) =>
  getRequest(
    replaceInUrl(apiEndpoints.documents.getDocuments, { accountUid, year }),
  );

export const getDocument = (documentUrl) => downloadRequest(documentUrl);

export const getDocumentByType = (accountUid, documentType) =>
  downloadRequest(
    replaceInUrl(apiEndpoints.documents.getDocument, {
      accountUid,
      documentType,
    }),
  );
