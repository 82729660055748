import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import i18next from '../../i18n';

import {
  ACH_ACCOUNT_NAME,
  WIRE_ACCOUNT_NAME,
} from '../../constants/bankAccounts';
import {
  clearBaByRoutingNum,
  fetchBankAccounts,
  submitNewAchAccount,
} from '../../store/actions/bankAccount/bankAccountActions';
import {
  selectBankAccountAchData,
  selectBankAccountWireData,
} from '../../store/selectors/bankAccountSelectors';
import { METHOD_VIA_CHECK } from '../../util/constants';
import {
  getCurrentAccount,
  getCurrentAccountUid,
} from '../../store/selectors/accountSelectors';
import { selectNumberOfAllowedAchDepositAccounts } from '../../store/selectors/settingsSelectors';
import BankAccountsCard from '../../components/BankAccountsCard';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import Section from '../../components/Section/Section';
import AddWireWithdrawalModal from '../../components/Modals/BankAccount/AddWireWithdrawalModal';
import VerifyBankAccountModal from '../../components/Modals/BankAccount/VerifyBankAccount/VerifyBankAccountModal';
import ChooseAchAddMethod from '../../components/Modals/BankAccount/ChooseAchAddMethod';
import AddNewAchModal from '../../components/Modals/BankAccount/AddNewAchModal';
import {
  deleteWizardKey,
  closeWizardContent,
  setWizardContent,
} from '../../util/helpers/wizardHelpers';
import useGtmHook from '../../util/hooks/useGtmHook';
import {
  ACC_PURPOSE_ACH,
  ACC_PURPOSE_WIRE,
} from '../../util/enum/api/bankAccountTypes';
import {
  CHOSEN_ACH_METHOD,
  IS_ADD_ACCOUNT_MODAL_OPEN,
  IS_NEW_WIRE_OPEN,
  PREVENT_SUPPORT_MODAL,
  WIRE_DEPOSIT_WIZARD,
} from '../../constants/sessionStorage';
import useCheckPermissions from '../../util/hooks/useCheckPermissions';
import VerifyErrorModal from '../../components/Modals/BankAccount/VerifyBankAccount/VerifyErrorModal';
import WireDeposit from '../../components/Modals/Deposit/StandardDepositWizard/Wire/WireDeposit';
import { useSessionStorageState } from '../../util/hooks/useSessionStorageState';
import { storeInSessionStorage } from '../../util/helpers/sessionStorageHelper';
import { useBrokerName } from '../../util/hooks/userBrokerName';
import RemoveBankAccountModal from '../../components/Modals/BankAccount/RemoveBankAccountModal';
import Paragraph from '../../components/Paragraph/Paragraph';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import { PORTFOLIO_PAGE } from '../../constants/pages';
import SupportModal from '../../components/Modals/SupportModal/SupportModal';
import NagDashboardBanner from '../../components/NagDashboard/NagDashboardBanner';
import {
  ACCOUNT_DATA_LOADED,
  BANK_ACCOUNT_ADDED,
} from '../../constants/gtmEvents';

export const BankAccountsPageContainer = styled.div`
  margin: ${pxToRem(24)} 0;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: 0 ${pxToRemMd(16)};
    margin: ${pxToRemMd(24)} 0;
  }
`;

export const BankAccountsPageGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${pxToRem(80)};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    grid-template-columns: 1fr;
    grid-row-gap: ${pxToRemMd(58)};
  }
`;

const BankAccountsPage = () => {
  const dispatch = useDispatch();
  const brokerName = useBrokerName();
  const history = useHistory();
  const [verifyAccountModalOpen, setVerifyAccountModalOpen] = useState(false);
  const [verifyAccountId, setVerifyAccountId] = useState(null);
  const [removeAccountModalOpen, setRemoveAccountModalOpen] = useState(false);
  const [isVerifyErrorModalOpen, setIsVerifyErrorModalOpen] = useState(false);
  const [removeAccountId, setRemoveAccountId] = useState(null);
  const [removeAccountType, setRemoveAccountType] = useState(null);
  const [removeType, setRemoveType] = useState(null);
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);
  const [isNewWireOpen, setIsNewWireOpen] = useSessionStorageState(
    IS_NEW_WIRE_OPEN,
    false,
  );
  const [chooseAchMethodOpen, setChooseAchMethodOpen] = useSessionStorageState(
    IS_ADD_ACCOUNT_MODAL_OPEN,
    false,
  );
  const [chosenAchMethod, setChosenAchMethod] = useSessionStorageState(
    CHOSEN_ACH_METHOD,
    null,
  );
  const [
    wireDepositInstructionsModal,
    setWireDepositInstructionsModal,
  ] = useSessionStorageState(WIRE_DEPOSIT_WIZARD, false);

  const bankAccAchData = useSelector(selectBankAccountAchData);
  const bankAccWireData = useSelector(selectBankAccountWireData);
  const accountUid = useSelector(getCurrentAccountUid);
  const account = useSelector(getCurrentAccount);
  const numberOfAllowedAchDepositAccounts = useSelector(
    selectNumberOfAllowedAchDepositAccounts,
  );

  const isErrAccount = account?.AccountStatus === 7;

  const {
    allowAccountChangesPermission,
    displayIRAOptionsPermission,
    displayBasicAccountOptionsPermission,
  } = useCheckPermissions();

  const { gtmScreenView, appGtmEvent, bankAccountGtmEvent } = useGtmHook();

  const isPendingAccount =
    account?.AccountStatus === 4 ||
    account?.AccountStatus === 1 ||
    account?.AccountStatus === 7;

  const disableManualAch =
    isPendingAccount || displayBasicAccountOptionsPermission;

  const handleGtmEvent = (data) => {
    bankAccountGtmEvent(
      BANK_ACCOUNT_ADDED,
      'AchDeposit',
      data?.BankAccountType,
      'USA',
    );
  };

  const handleVerifyAccount = (bankAccountUid) => {
    setVerifyAccountModalOpen(true);
    setVerifyAccountId(bankAccountUid);
  };

  const handleRemoveAccount = (bankAccountUid, bankAccountType, bankType) => {
    setRemoveAccountModalOpen(true);
    setRemoveAccountId(bankAccountUid);
    setRemoveAccountType(bankAccountType);
    setRemoveType(bankType);
  };

  const handleNewAchSubmit = (data, meta) => {
    if (chosenAchMethod === METHOD_VIA_CHECK) {
      dispatch(
        submitNewAchAccount({
          data,
          accountUid,
          handleApiResponse: meta.handleApiResponse,
          onSuccess: handleGtmEvent,
        }),
      );
    }
  };

  const handleVerifyModalClose = () => {
    setVerifyAccountModalOpen(false);
    setVerifyAccountId(null);
  };

  const handleRemoveModalClose = () => {
    setRemoveAccountModalOpen(false);
    setRemoveAccountId(null);
  };

  useEffect(() => {
    if (displayIRAOptionsPermission) {
      history.push(PORTFOLIO_PAGE);
    }
  }, [displayIRAOptionsPermission, history]); // eslint-disable-line

  useEffect(() => {
    gtmScreenView({
      path: window.location.pathname,
      title: 'Bank Accounts',
    });
    appGtmEvent(ACCOUNT_DATA_LOADED);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (accountUid) {
      dispatch(fetchBankAccounts({ accountUid }));
    }
  }, [accountUid, dispatch]);

  const openWireDepositWizard = (event) => {
    event.preventDefault();
    setWireDepositInstructionsModal(true);
    setWizardContent(WIRE_DEPOSIT_WIZARD, true);
  };

  const handleCloseModal = () => {
    setWireDepositInstructionsModal(false);
    setWizardContent(WIRE_DEPOSIT_WIZARD, false);
    closeWizardContent();
  };

  return (
    <div data-cy="container-bank-accounts-page">
      <NagDashboardBanner />
      <NavigationBar
        mainPage={i18next.t('header.navDropdownAccount')}
        subPage={i18next.t('header.linkBankAccounts')}
      />
      <Section>
        <BankAccountsPageContainer>
          <Paragraph>
            {i18next.t('bankAccounts.pageDescription', { brokerName })}
          </Paragraph>
          <Paragraph marginBottom={32}>
            {i18next.t('bankAccounts.moreInfo')}
          </Paragraph>
          <BankAccountsPageGrid>
            <BankAccountsCard
              handleVerifyAccount={handleVerifyAccount}
              handleRemoveAccount={handleRemoveAccount}
              accountType={ACH_ACCOUNT_NAME}
              bankAccounts={bankAccAchData}
              bankAccountType={ACC_PURPOSE_ACH}
              onAddNewClick={() => {
                storeInSessionStorage(PREVENT_SUPPORT_MODAL, false);
                setWizardContent(IS_ADD_ACCOUNT_MODAL_OPEN, true);
                setChooseAchMethodOpen(true);
              }}
              allowAccountChangesPermission={allowAccountChangesPermission}
              numberOfAccountsAllowed={numberOfAllowedAchDepositAccounts}
              handleErrorAccount={setIsVerifyErrorModalOpen}
              contactSupport={() => setIsSupportModalOpen(true)}
              isErrAccount={isErrAccount}
            />
            <BankAccountsCard
              handleVerifyAccount={handleVerifyAccount}
              handleRemoveAccount={handleRemoveAccount}
              accountType={WIRE_ACCOUNT_NAME}
              bankAccounts={bankAccWireData}
              bankAccountType={ACC_PURPOSE_WIRE}
              onAddNewClick={() => {
                storeInSessionStorage(PREVENT_SUPPORT_MODAL, false);
                setWizardContent(IS_NEW_WIRE_OPEN, true);
                setIsNewWireOpen(true);
              }}
              displayBasicAccountOptionsPermission={
                displayBasicAccountOptionsPermission
              }
              allowAccountChangesPermission={allowAccountChangesPermission}
              openWireDepositWizard={openWireDepositWizard}
              contactSupport={() => setIsSupportModalOpen(true)}
              isErrAccount={isErrAccount}
            />
          </BankAccountsPageGrid>
        </BankAccountsPageContainer>
      </Section>
      <AddWireWithdrawalModal
        accountUid={accountUid}
        isModalOpen={isNewWireOpen}
        setIsModalOpen={setIsNewWireOpen}
        onClose={() => dispatch(clearBaByRoutingNum())}
      />
      {!chosenAchMethod && (
        <ChooseAchAddMethod
          isOpen={chooseAchMethodOpen}
          setChooseAchMethodOpen={setChooseAchMethodOpen}
          onChoose={(choice) => {
            setChosenAchMethod(choice);
          }}
          handleNewAchSubmit={handleNewAchSubmit}
          setChosenAchMethod={setChosenAchMethod}
          handleClose={() => {
            setChosenAchMethod(null);
          }}
          baseModal
          disableManualAch={disableManualAch}
        />
      )}
      <AddNewAchModal
        chosenAddMethod={chosenAchMethod}
        onClose={() => {
          setChooseAchMethodOpen(false);
          setChosenAchMethod(null);
          dispatch(clearBaByRoutingNum());
        }}
        handleNewAchSubmit={handleNewAchSubmit}
        setChooseAchMethodOpen={setChooseAchMethodOpen}
        setChosenAchMethod={setChosenAchMethod}
        goInitialModal={() => {
          deleteWizardKey(CHOSEN_ACH_METHOD);
          setChosenAchMethod(null);
        }}
        disableManualAch={disableManualAch}
      />
      <VerifyBankAccountModal
        isOpen={verifyAccountModalOpen}
        onClose={handleVerifyModalClose}
        bankAccountUid={verifyAccountId}
      />
      <RemoveBankAccountModal
        isOpen={removeAccountModalOpen}
        onClose={handleRemoveModalClose}
        bankAccountUid={removeAccountId}
        removeAccountType={removeAccountType}
        removeType={removeType}
      />
      <VerifyErrorModal
        isOpen={isVerifyErrorModalOpen}
        onClose={() => setIsVerifyErrorModalOpen(false)}
      />
      {wireDepositInstructionsModal && (
        <WireDeposit
          isModalOpen={wireDepositInstructionsModal}
          handleClose={handleCloseModal}
        />
      )}
      <SupportModal
        isOpen={isSupportModalOpen}
        close={() => setIsSupportModalOpen(false)}
      />
    </div>
  );
};

export default BankAccountsPage;
