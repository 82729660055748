import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import BalanceInfoCard from '../../components/Balance/BalanceInfoCard';
import Section from '../../components/Section/Section';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import Button from '../../components/Button/Button';

import {
  fetchPortfolioBalances,
  accountTransactionsFetch,
  portfolioFinancialPendingTransactions,
} from '../../store/actions/portfolio/portfolioActions';
import {
  getCurrentAccount,
  getCurrentAccountUid,
} from '../../store/selectors/accountSelectors';
import {
  selectPortfolioPendingTransactions,
  selectPortfolioTransactions,
  selectPortfolioTransactionsTotalCount,
} from '../../store/selectors/portfolioTransactionsSelector';
import { selectBalancesAvailable } from '../../store/selectors/portfolioBalancesSelectors';
import { selectIsLoadingByActionType } from '../../store/selectors/loadingSelectors';
import {
  PORTFOLIO_BALANCES_LOADING,
  PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_LOADING,
  ACCOUNT_TRANSACTIONS_FETCH_LOADING,
} from '../../store/actions/portfolio/portfolioActionConstants';
import {
  IRA_ENTRUST_TYPE,
  IRA_EQUITY_TYPE,
  IRA_MILLENIUM_TYPE,
} from '../../util/constants';

import StandardWithdrawalWizard from '../../components/Modals/WithdrawalWizard/StandardWithdrawalWizard';
import IraWithdrawalWizard from '../../components/Modals/WithdrawalWizard/IraWithdrawalWizard';
import EquityWithdrawalWizard from '../../components/Modals/WithdrawalWizard/EquityWithdrawalWizard';
import PrefundedDepositWizard from '../../components/Modals/DepositWizard/PrefundedDepositWizard';

import MilleniumDepositWizard from '../../components/Modals/DepositWizard/MilleniumDepositWizard';
import BlockSectionLoader from '../../components/Loader/BlockSectionLoader';
import {
  closeWizardContent,
  setWizardContent,
} from '../../util/helpers/wizardHelpers';
import useGtmHook from '../../util/hooks/useGtmHook';
import { USER_PERMISSIONS_LOADING } from '../../store/actions/account/accountActionConstants';
import {
  IS_DEPOSIT_MODAL_OPEN,
  PREFUNDED_WITHDRAW_MODAL_OPEN,
  PREVENT_SUPPORT_MODAL,
  SUPPORT_MODAL_OPEN,
  WITHDRAW_MODAL_OPEN,
} from '../../constants/sessionStorage';
import useCheckPermissions from '../../util/hooks/useCheckPermissions';
import PendingTransactionsTable from '../../components/Portfolio/PendingTransactionsTable/PendingTransactionsTable';
import PrefundedFeeWithdrawalWizard from '../../components/Modals/WithdrawalWizard/PrefundedWithdrawalWizard/PrefundedFeeWithdrawalWizard';
import FinancialBalanceTransactions from '../../components/FinancialBalanceTransactions/FinancialBalanceTransactions';
import { useSessionStorageState } from '../../util/hooks/useSessionStorageState';
import { useTransactionPagination } from '../../util/hooks/useTransactionPagination';
import { FINANCIAL_TRANSACTIONS } from '../../constants/accountTransactionConstants';
import { storeInSessionStorage } from '../../util/helpers/sessionStorageHelper';
import {
  clearObiMessages,
  fetchBankAccounts,
} from '../../store/actions/bankAccount/bankAccountActions';
import IraDepositWizard from '../../components/Modals/DepositWizard/IraDepositWizard';
import CancelPendingTransactionModal from '../../components/Modals/CancelPendingTransaction/CancelPendingTransactionModal';
import {
  mediaBelow,
  mediaBetween,
  pxToRem,
  pxToRemMd,
} from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import { uFlexColumn } from '../../assets/styles/utility';
import StandardDepositWizard from '../../components/Modals/Deposit/StandardDepositWizard/StandardDepositWizard';
import {
  actionRequiredStatus,
  expiredVerificationStatus,
  failedVerificationStatus,
  getBankAccountStatus,
  pendingReviewStatus,
} from '../../util/helpers/bankAccountHelper';
import { selectBankAccountAchData } from '../../store/selectors/bankAccountSelectors';
import { BANK_ACCOUNT_LOADING } from '../../store/actions/bankAccount/bankAccountActionConstants';
import NagDashboardBanner from '../../components/NagDashboard/NagDashboardBanner';
import { LEGACY_ORDER_HISTORY_PAGE } from '../../constants/pages';
import SupportModal from '../../components/Modals/SupportModal/SupportModal';
import Paragraph from '../../components/Paragraph/Paragraph';
import Note from '../../components/Notes/Note';
import { useGetIsImpersonating } from '../../util/hooks/useGetIsImpersonating';
import BasicAccountRestristionsModal from '../../components/Modals/BasicAccountRestrictionsModal/BasicAccountRestrictionsModal';
import {
  accountTypeKeys,
  accountTypes,
} from '../../util/enum/api/accountTypes';
import { parseEnumType } from '../../util/helpers/enumMappers';
import StorageFeeBanner from '../../components/StorageFee/StorageFeeBanner';
import { fetchStorageFeeAmount } from '../../store/actions/storageFee/storageFeeActions';
import PayStorageFeeByCardModal from '../../components/Modals/PayStorageFeeByCard/PayStorageFeeByCardModal';
import RestrictedPermissionModal from '../../components/Modals/RestrictedPermissionModal';
import { selectSettings } from '../../store/selectors/settingsSelectors';
import AutoPayStatus from '../../components/StorageFee/AutoPayStatus';
import { fetchCreditCards } from '../../store/actions/creditCard/creditCardActions';
import { selectCreditCards } from '../../store/selectors/creditCardSelectors';
import ChangeAutoPaySettingsModal from '../../components/Modals/ChangeAutoPaySettings/ChangeAutoPaySettingsModal';
import {
  ACCOUNT_DATA_LOADED,
  FUND_DEPOSIT_START,
  FUND_WITHDRAW_START,
} from '../../constants/gtmEvents';

const FinancialBalancesContainer = styled.div`
  margin: ${pxToRem(32)} 0;
  display: flex;

  ${mediaBelow(1400)} {
    flex-direction: column;
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    margin: ${pxToRem(16)} 0;
  }
`;

const FinancialBalancesSidebar = styled.div`
  max-width: ${pxToRem(400)};
  width: 100%;
  margin-right: ${pxToRem(60)};

  > :not(:first-child) {
    margin-top: ${pxToRem(56)};
  }

  ${mediaBelow(1400)} {
    margin-bottom: ${pxToRem(56)};
    max-width: 100%;

    > :not(:first-child) {
      margin-top: ${pxToRem(56)};
    }
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    margin-bottom: ${pxToRemMd(56)};
    padding: 0 ${pxToRemMd(16)};

    > :not(:first-child) {
      margin-top: ${pxToRemMd(56)};
    }
  }
`;

const FinancialBalancesContentWrap = styled.div`
  ${uFlexColumn};
  width: 100%;
  max-width: 100%;

  > :not(:last-child) {
    margin-bottom: ${pxToRem(32)};
  }
`;

const FinancialBalancesCardsWrap = styled.div`
  > :not(:last-child) {
    margin-bottom: ${pxToRem(16)};
  }

  ${mediaBelow(1400)} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${pxToRem(24)};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    grid-column-gap: ${pxToRemMd(24)};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    grid-template-columns: 1fr;
  }
`;

const FinancialBalancesButtonsWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${pxToRem(24)};
  margin-top: ${pxToRem(24)};
  ${(props) => props.css}

  > ${Button} {
    ${(props) => props.variant === 'primary' && `grid-column: 1 / 2;`};
    ${(props) => props.variant === 'primaryOutlined' && `grid-column: 2 / 3;`};
  }
`;
const FinancialBalancesButtonsWrapReverse = styled(
  FinancialBalancesButtonsWrap,
)`
  margin-bottom: ${pxToRem(24)};
  margin-top: 0;
`;
const FinancialBalancesLegacyOrderButtonWrap = styled.div`
  display: grid;
  grid-template-columns: 3fr 0fr;
  grid-column-gap: 0px;
  margin-top: ${pxToRem(24)};
  ${(props) => props.css}

  > ${Button} {
    ${(props) => props.variant === 'primary' && `grid-column: 2 / 2;`};
  }
  ${mediaBetween(variables.breakpoints.bpSm, variables.breakpoints.bpXxl)} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${pxToRem(24)};
  }
  ${mediaBelow(variables.breakpoints.bpSm)} {
    grid-template-columns: 3fr 0fr;
  }
`;
const FinancialBalancesSidebarTitle = styled.h2`
  margin-bottom: ${pxToRem(24)};
  font-size: ${pxToRem(24)};
  line-height: 1.35;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-bottom: ${pxToRemMd(24)};
    font-size: ${pxToRemMd(24)};
  }
`;
const DisabledWireWithdrawal = styled.div`
  grid-template-columns: 0fr 4fr;
  grid-column-gap: 0;
  ${mediaBetween(variables.breakpoints.bpMd, variables.breakpoints.bpXl)} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${pxToRem(24)};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    grid-template-columns: 0fr 4fr;
    grid-column-gap: 0;
  }
`;
const ParagraphWrapper = styled.div`
  ${mediaBelow(variables.breakpoints.bpMd)} {
    width: 100%;
  }
`;
const DisabledWireWithdrawalDivider = styled.div`
  ${mediaBelow(variables.breakpoints.bpMd)} {
    width: 0px;
  }
`;

const FinancialBalancesPage = () => {
  const path = window.location.pathname;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    gtmScreenView,
    gtmDialogScreenView,
    appGtmEvent,
    fundGtmEvent,
  } = useGtmHook();
  const history = useHistory();
  const settings = useSelector(selectSettings);
  const account = useSelector(getCurrentAccount);
  const hasLegacyOrderHistory = account.ShowLegacyHistory;
  const transactions = useSelector(selectPortfolioTransactions);
  const transactionsTotalCount = useSelector(
    selectPortfolioTransactionsTotalCount,
  );
  const accountUid = useSelector(getCurrentAccountUid);
  const balances = useSelector(selectBalancesAvailable);
  const pendingTransactions = useSelector(selectPortfolioPendingTransactions);
  const balanceLoading = useSelector(
    selectIsLoadingByActionType(PORTFOLIO_BALANCES_LOADING),
  );

  const permissionsLoading = useSelector(
    selectIsLoadingByActionType(USER_PERMISSIONS_LOADING),
  );

  const transactionsLoading = useSelector(
    selectIsLoadingByActionType(ACCOUNT_TRANSACTIONS_FETCH_LOADING),
  );

  const pendingTransactionLoading = useSelector(
    selectIsLoadingByActionType([
      PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_LOADING,
    ]),
  );

  const creditCards = useSelector(selectCreditCards);
  const isAutoPayOn = creditCards?.some((card) => card.IsDefaultForStorage);

  const [isDepositModalOpen, setIsDepositModalOpen] = useSessionStorageState(
    IS_DEPOSIT_MODAL_OPEN,
    false,
  );

  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useSessionStorageState(
    WITHDRAW_MODAL_OPEN,
    false,
  );

  const [isSupportModalOpen, setIsSupportModalOpen] = useSessionStorageState(
    SUPPORT_MODAL_OPEN,
    false,
  );

  const [
    isPrefundedDepositModalOpen,
    setIsPrefundedDepositModalOpen,
  ] = useState(false);

  const [
    isPrefundedWithdrawModalOpen,
    setIsPrefundedWithdrawModalOpen,
  ] = useSessionStorageState(PREFUNDED_WITHDRAW_MODAL_OPEN, false);

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [cancelTransactionData, setCancelTransactionData] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(20); // eslint-disable-line
  const [storageFeeAmount, setStorageFeeAmount] = useState();
  const [isOpenPayStorageFeeByCard, setIsOpenPayStorageFeeByCard] = useState(
    false,
  );
  const [isAutoPaySettingsModalOpen, setIsAutoPaySettingsModalOpen] = useState(
    false,
  );
  const [transactionList] = useTransactionPagination(transactions, pageNumber);
  const { isImpersonating } = useGetIsImpersonating();
  const [
    isDepositRestrictionsModalOpen,
    setIsDepositRestrictionsModalOpen,
  ] = useSessionStorageState(IS_DEPOSIT_MODAL_OPEN, false);
  const [
    isWithdrawRestrictionsModalOpen,
    setIsWithdrawRestrictionsModalOpen,
  ] = useSessionStorageState(WITHDRAW_MODAL_OPEN, false);

  const handleCancelModalOpen = (accountUid, paymentUid) => {
    setIsCancelModalOpen(true);
    setCancelTransactionData({
      accountUid,
      paymentUid,
    });
  };

  const handleCancelModalClose = () => {
    setIsCancelModalOpen(false);
    setCancelTransactionData({});
  };

  const handleLoadMore = () => {
    setPageNumber((old) => old + 1);
  };

  const handleFetchTransactions = (page) => {
    dispatch(
      accountTransactionsFetch({
        accountUid: account.AccountUid,
        pageNumber: page,
        pageSize,
        content: FINANCIAL_TRANSACTIONS,
      }),
    );
  };

  const handleRefreshTransactions = () => {
    setPageNumber(1);
    handleFetchTransactions(1);
  };

  const {
    allowAccountChangesPermission,
    displayIRAOptionsPermission,
    displayIRAEntrustOptionsPermission,
    displayIRAMilleniumOptionsPermission,
    displayIRAEquityOptionsPermission,
    displayStandardOptionsPermission,
    displayClosedAccountOptionsPermission,
    displayBasicAccountOptionsPermission,
  } = useCheckPermissions();

  const achBankAccounts = useSelector(selectBankAccountAchData);
  const unverifiedBankAccounts = achBankAccounts?.filter((bankAccount) => {
    const verificationStatus = getBankAccountStatus(bankAccount).status;
    return [
      actionRequiredStatus,
      failedVerificationStatus,
      expiredVerificationStatus,
      pendingReviewStatus,
    ].includes(verificationStatus);
  });

  const isBasicAccount =
    parseEnumType(accountTypeKeys, account.AccountType) === accountTypes.Basic;

  const isErrAccount = account?.AccountStatus === 7;
  const isPendingAccount =
    account?.AccountStatus === 4 || account?.AccountStatus === 1;

  const isLoadingBankAccounts = useSelector(
    selectIsLoadingByActionType([BANK_ACCOUNT_LOADING]),
  );

  const [creditCardForAutoPay, setCreditCardForAutoPay] = useState();

  useEffect(() => {
    setCreditCardForAutoPay(
      creditCards?.find((card) => card.IsDefaultForStorage) ?? null,
    );
  }, [creditCards]);

  useEffect(() => {
    if (accountUid) {
      dispatch(fetchBankAccounts({ accountUid }));
    }
  }, [accountUid, dispatch]);

  useEffect(() => {
    if (accountUid && settings?.IsCreditCardActive) {
      dispatch(
        fetchStorageFeeAmount({
          accountUid,
          setStorageFeeAmount,
        }),
      );
    }
  }, [dispatch, accountUid, settings.IsCreditCardActive]);

  useEffect(() => {
    if (accountUid && settings?.IsCreditCardActive) {
      dispatch(fetchCreditCards({ accountUid }));
    }
  }, [dispatch, accountUid, settings]);

  useEffect(() => {
    gtmScreenView({
      path,
      title: 'Financial Balance',
    });
    appGtmEvent(ACCOUNT_DATA_LOADED);
  }, []); // eslint-disable-line

  useEffect(() => {
    dispatch(fetchPortfolioBalances({ accountUid: account.AccountUid }));
  }, [dispatch, account.AccountUid]);

  useEffect(() => {
    if (
      displayStandardOptionsPermission ||
      (displayBasicAccountOptionsPermission &&
        (isErrAccount || isPendingAccount))
    ) {
      dispatch(
        portfolioFinancialPendingTransactions({
          accountUid: account.AccountUid,
        }),
      );
    }
  }, [
    account.AccountUid,
    dispatch,
    displayStandardOptionsPermission,
    displayBasicAccountOptionsPermission,
    isErrAccount,
    isPendingAccount,
  ]);

  useEffect(() => {
    handleFetchTransactions(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.AccountUid]);

  useEffect(() => {
    if (pageNumber > 1) {
      handleFetchTransactions(pageNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const closeWireWithdrawal = () => {
    setIsWithdrawModalOpen(false);
    dispatch(clearObiMessages());
  };

  const showLegacyHistoryItem = () =>
    hasLegacyOrderHistory && (
      <FinancialBalancesLegacyOrderButtonWrap>
        <Button
          variant="primary"
          size="lg"
          disabled={displayBasicAccountOptionsPermission}
          onClick={() => {
            history.push(LEGACY_ORDER_HISTORY_PAGE);
          }}
        >
          {t('account.legacyOrderHistory.button')}
        </Button>
      </FinancialBalancesLegacyOrderButtonWrap>
    );

  const sidebarItem = () => (
    <>
      {balanceLoading || permissionsLoading || isLoadingBankAccounts ? (
        <BlockSectionLoader isLoading />
      ) : (
        <div>
          {(allowAccountChangesPermission ||
            displayClosedAccountOptionsPermission ||
            !displayBasicAccountOptionsPermission) && (
            <>
              <FinancialBalancesButtonsWrapReverse>
                <Button
                  variant="primary"
                  size="lg"
                  disabled={
                    (!allowAccountChangesPermission &&
                      !displayBasicAccountOptionsPermission) ||
                    (displayBasicAccountOptionsPermission && !isBasicAccount) ||
                    !allowAccountChangesPermission
                  }
                  lockStyle={isBasicAccount}
                  onClick={() => {
                    if (isBasicAccount) {
                      // closeWizardContent();
                      setIsDepositRestrictionsModalOpen(true);
                    }
                    storeInSessionStorage(PREVENT_SUPPORT_MODAL, true);
                    setIsDepositModalOpen(true);
                    setWizardContent(IS_DEPOSIT_MODAL_OPEN, true);
                    gtmDialogScreenView({
                      title: 'Deposit Funds',
                    });
                  }}
                  data-cy="button-deposit"
                >
                  {t('common.deposit')}
                </Button>
                <Button
                  disabled={
                    (!(balances.Effective > 0) &&
                      !allowAccountChangesPermission &&
                      !displayBasicAccountOptionsPermission) ||
                    (displayBasicAccountOptionsPermission && !isBasicAccount) ||
                    !allowAccountChangesPermission
                  }
                  variant="primaryOutlined"
                  size="lg"
                  lockStyle={isBasicAccount}
                  onClick={() => {
                    if (isBasicAccount) {
                      // closeWizardContent();
                      setIsWithdrawRestrictionsModalOpen(true);
                    }
                    storeInSessionStorage(PREVENT_SUPPORT_MODAL, true);
                    setWizardContent(WITHDRAW_MODAL_OPEN, true);
                    setIsWithdrawModalOpen(true);
                    gtmDialogScreenView({
                      title: 'Withdraw Funds',
                    });
                  }}
                  data-cy="button-withdraw"
                >
                  {t('common.withdraw')}
                </Button>
              </FinancialBalancesButtonsWrapReverse>
              {displayBasicAccountOptionsPermission && (
                <DisabledWireWithdrawal>
                  <DisabledWireWithdrawalDivider />
                  <ParagraphWrapper>
                    <Paragraph marginBottom={16}>
                      {t('balances.disabledBasicAccountOne')}
                      <Note
                        primaryColor
                        bold
                        text={t('balances.disableBasicAccountTwo')}
                        onClick={(event) => {
                          event.preventDefault();
                          setIsSupportModalOpen(true);
                        }}
                        fontSize={pxToRem(24)}
                      />
                      {t('balances.disableBasicAccountThree')}
                    </Paragraph>
                  </ParagraphWrapper>
                </DisabledWireWithdrawal>
              )}
            </>
          )}
          <>
            <FinancialBalancesCardsWrap>
              <BalanceInfoCard
                title={t('balances.effectiveBalanceTitle')}
                description={t('balances.effectiveBalanceDescription')}
                balance={balances.Effective}
                isActive
              />
              <BalanceInfoCard
                title={t('balances.availableForTradingTitle')}
                description={t('balances.availableForTradingDescription')}
                balance={balances.AvailableForTrading}
              />
              <BalanceInfoCard
                title={t('balances.availableForWithdrawalTitle')}
                description={t('balances.availableForWithdrawalDescription')}
                balance={balances.AvailableForWithdrawal}
              />
              <SupportModal
                isOpen={isSupportModalOpen}
                close={setIsSupportModalOpen}
              />
            </FinancialBalancesCardsWrap>
            {!displayIRAOptionsPermission && settings?.IsCreditCardActive && (
              <>
                <Paragraph bold marginTop={24}>
                  {t('storageFee.payStorageFeeByCardModal.storageFeePayments')}
                </Paragraph>

                <AutoPayStatus
                  creditCard={
                    creditCards?.find((card) => card.IsDefaultForStorage) ??
                    null
                  }
                  isAutoPayOn={isAutoPayOn}
                  setIsAutoPaySettingsModalOpen={setIsAutoPaySettingsModalOpen}
                  isOpenPayStorageFeeByCard={isOpenPayStorageFeeByCard}
                  showChangeSettingsButton
                  isOnBalancesPage
                />
              </>
            )}
          </>
        </div>
      )}
    </>
  );

  const preFundedSidebarItem = () => (
    <>
      {balanceLoading || permissionsLoading ? (
        <BlockSectionLoader isLoading={balanceLoading || permissionsLoading} />
      ) : (
        <div>
          <FinancialBalancesSidebarTitle>
            {t('balances.prefunded')}
          </FinancialBalancesSidebarTitle>
          {settings?.IsCreditCardActive && (
            <AutoPayStatus
              creditCard={creditCardForAutoPay}
              isAutoPayOn={isAutoPayOn}
              setIsAutoPaySettingsModalOpen={setIsAutoPaySettingsModalOpen}
              isOpenPayStorageFeeByCard={isOpenPayStorageFeeByCard}
              showChangeSettingsButton
              isOnBalancesPage
            />
          )}

          <FinancialBalancesCardsWrap>
            <BalanceInfoCard
              title={t('balances.availableForWithdrawalTitle')}
              description={t('balances.availableForWithdrawalDescription')}
              balance={balances.AvailablePrefundedStorageFees}
            />
          </FinancialBalancesCardsWrap>
          {(allowAccountChangesPermission ||
            displayClosedAccountOptionsPermission ||
            !displayBasicAccountOptionsPermission) && (
            <>
              <FinancialBalancesButtonsWrap>
                <Button
                  variant="primary"
                  size="lg"
                  type="button"
                  disabled={displayBasicAccountOptionsPermission}
                  lockStyle={isBasicAccount}
                  onClick={() => {
                    if (isBasicAccount) {
                      closeWizardContent();
                      setIsDepositRestrictionsModalOpen(true);
                    }
                    setIsPrefundedDepositModalOpen(true);
                    gtmDialogScreenView({
                      title: 'Prefunded Deposit Funds',
                    });
                    fundGtmEvent(FUND_DEPOSIT_START, {
                      fund: {
                        method: 'Prefunded Deposit Funds',
                      },
                    });
                  }}
                  data-cy="button-deposit"
                >
                  {t('common.deposit')}
                </Button>

                <Button
                  disabled={
                    !balances.AvailablePrefundedStorageFees ||
                    displayBasicAccountOptionsPermission
                  }
                  variant="primaryOutlined"
                  size="lg"
                  type="button"
                  lockStyle={isBasicAccount}
                  onClick={() => {
                    if (isBasicAccount) {
                      // closeWizardContent();
                      setIsWithdrawRestrictionsModalOpen(true);
                    }
                    closeWizardContent();
                    setWizardContent(PREFUNDED_WITHDRAW_MODAL_OPEN, true);
                    setIsPrefundedWithdrawModalOpen(true);
                    gtmDialogScreenView({
                      title: 'Prefunded Withdraw Funds',
                    });
                    fundGtmEvent(FUND_WITHDRAW_START, {
                      fund: {
                        method: 'Check',
                      },
                    });
                  }}
                  data-cy="button-withdraw"
                >
                  {t('common.withdraw')}
                </Button>
              </FinancialBalancesButtonsWrap>
              {displayBasicAccountOptionsPermission && (
                <DisabledWireWithdrawal>
                  <DisabledWireWithdrawalDivider />
                  <ParagraphWrapper>
                    <Paragraph marginBottom={16}>
                      {t('balances.disabledBasicAccountOne')}
                      <Note
                        primaryColor
                        bold
                        text={t('balances.disableBasicAccountTwo')}
                        onClick={(event) => {
                          event.preventDefault();
                          setIsSupportModalOpen(true);
                        }}
                      />
                      {t('balances.disableBasicAccountThree')}
                    </Paragraph>
                  </ParagraphWrapper>
                </DisabledWireWithdrawal>
              )}
            </>
          )}
        </div>
      )}
    </>
  );

  const renderPendingTransactions = () => {
    if (
      displayStandardOptionsPermission ||
      displayBasicAccountOptionsPermission
    ) {
      if (pendingTransactionLoading) {
        return <BlockSectionLoader isLoading={pendingTransactionLoading} />;
      }

      return (
        <PendingTransactionsTable
          data={pendingTransactions}
          accountUid={account.AccountUid}
          shouldShowTransactions={
            !isEmpty(pendingTransactions) && !!pendingTransactions
          }
          handleModalOpen={handleCancelModalOpen}
        />
      );
    }
  };
  return (
    <>
      <NagDashboardBanner />
      <NavigationBar
        hasBack
        mainPage={t('header.portfolioLink')}
        subPage={t('header.financialBalances')}
      />
      {storageFeeAmount > 0 && (
        <StorageFeeBanner
          amount={storageFeeAmount}
          setIsDepositModalOpen={setIsDepositModalOpen}
          gtmDialogScreenView={gtmDialogScreenView}
          isDepositAvailable={
            !(displayBasicAccountOptionsPermission && !isBasicAccount)
          }
          setIsOpenPayStorageFeeByCard={setIsOpenPayStorageFeeByCard}
          setIsDepositRestrictionsModalOpen={setIsDepositRestrictionsModalOpen}
          isBasicAccount={isBasicAccount}
          setIsAutoPaySettingsModalOpen={setIsAutoPaySettingsModalOpen}
        />
      )}
      <Section data-cy="container-financial-balances-page">
        <FinancialBalancesContainer>
          <FinancialBalancesSidebar>
            {sidebarItem()}
            {displayIRAOptionsPermission && preFundedSidebarItem()}
            {showLegacyHistoryItem()}
          </FinancialBalancesSidebar>
          <FinancialBalancesContentWrap data-cy="container-transactions">
            {renderPendingTransactions()}

            {transactionsLoading && pageNumber === 1 ? (
              <BlockSectionLoader isLoading={transactionsLoading} />
            ) : (
              <FinancialBalanceTransactions
                transactionsLoading={transactionsLoading}
                account={account}
                handleLoadMore={handleLoadMore}
                transactionsTotalCount={transactionsTotalCount}
                transactionList={transactionList}
                pageNumber={pageNumber}
                pageSize={pageSize}
              />
            )}
          </FinancialBalancesContentWrap>
        </FinancialBalancesContainer>

        {displayStandardOptionsPermission && (
          <>
            {isDepositModalOpen && (
              <StandardDepositWizard
                isModalOpen={isDepositModalOpen}
                handleClose={() => {
                  setIsDepositModalOpen(false);
                }}
                handleRefreshTransactions={handleRefreshTransactions}
              />
            )}

            {isWithdrawModalOpen && (
              <StandardWithdrawalWizard
                balances={balances}
                isModalOpen={isWithdrawModalOpen}
                handleRefreshTransactions={handleRefreshTransactions}
                handleClose={() => closeWireWithdrawal()}
                unverifiedBankAccounts={unverifiedBankAccounts}
              />
            )}
          </>
        )}

        {displayIRAEquityOptionsPermission && (
          <>
            {isDepositModalOpen && (
              <IraDepositWizard
                isModalOpen={isDepositModalOpen}
                handleClose={() => setIsDepositModalOpen(false)}
                type={IRA_EQUITY_TYPE}
              />
            )}

            {isWithdrawModalOpen && (
              <EquityWithdrawalWizard
                isModalOpen={isWithdrawModalOpen}
                handleClose={() => setIsWithdrawModalOpen(false)}
              />
            )}

            {isPrefundedDepositModalOpen && (
              <PrefundedDepositWizard
                isModalOpen={isPrefundedDepositModalOpen}
                handleClose={() => setIsPrefundedDepositModalOpen(false)}
                type={IRA_EQUITY_TYPE}
              />
            )}
            {isPrefundedWithdrawModalOpen && (
              <PrefundedFeeWithdrawalWizard
                isModalOpen={isPrefundedWithdrawModalOpen}
                handleClose={() => setIsPrefundedWithdrawModalOpen(false)}
                handleRefreshTransactions={handleRefreshTransactions}
                accountUid={account.AccountUid}
                type={IRA_EQUITY_TYPE}
              />
            )}
          </>
        )}

        {displayIRAEntrustOptionsPermission && (
          <>
            {isDepositModalOpen && (
              <IraDepositWizard
                isModalOpen={isDepositModalOpen}
                handleClose={() => setIsDepositModalOpen(false)}
                type={IRA_ENTRUST_TYPE}
              />
            )}

            {isWithdrawModalOpen && (
              <IraWithdrawalWizard
                isModalOpen={isWithdrawModalOpen}
                handleClose={() => setIsWithdrawModalOpen(false)}
                handleRefreshTransactions={handleRefreshTransactions}
                balance={balances}
                accountUid={account.AccountUid}
                type={IRA_ENTRUST_TYPE}
                isImpersonating={isImpersonating}
              />
            )}

            {isPrefundedDepositModalOpen && (
              <PrefundedDepositWizard
                isModalOpen={isPrefundedDepositModalOpen}
                handleClose={() => setIsPrefundedDepositModalOpen(false)}
                type={IRA_ENTRUST_TYPE}
              />
            )}
            {isPrefundedWithdrawModalOpen && (
              <PrefundedFeeWithdrawalWizard
                isModalOpen={isPrefundedWithdrawModalOpen}
                handleClose={() => setIsPrefundedWithdrawModalOpen(false)}
                handleRefreshTransactions={handleRefreshTransactions}
                accountUid={account.AccountUid}
                type={IRA_ENTRUST_TYPE}
              />
            )}
          </>
        )}

        {displayIRAMilleniumOptionsPermission && (
          <>
            {isDepositModalOpen && (
              <MilleniumDepositWizard
                isModalOpen={isDepositModalOpen}
                handleClose={() => setIsDepositModalOpen(false)}
              />
            )}
            {isWithdrawModalOpen && (
              <IraWithdrawalWizard
                isModalOpen={isWithdrawModalOpen}
                handleClose={() => setIsWithdrawModalOpen(false)}
                handleRefreshTransactions={handleRefreshTransactions}
                balance={balances}
                accountUid={account.AccountUid}
                type={IRA_MILLENIUM_TYPE}
                isImpersonating={isImpersonating}
              />
            )}
            {isPrefundedDepositModalOpen && (
              <PrefundedDepositWizard
                isModalOpen={isPrefundedDepositModalOpen}
                handleClose={() => setIsPrefundedDepositModalOpen(false)}
                type={IRA_MILLENIUM_TYPE}
              />
            )}
            {isPrefundedWithdrawModalOpen && (
              <PrefundedFeeWithdrawalWizard
                isModalOpen={isPrefundedWithdrawModalOpen}
                handleClose={() => setIsPrefundedWithdrawModalOpen(false)}
                handleRefreshTransactions={handleRefreshTransactions}
                accountUid={account.AccountUid}
                type={IRA_MILLENIUM_TYPE}
              />
            )}
          </>
        )}

        <CancelPendingTransactionModal
          handleClose={handleCancelModalClose}
          isOpen={isCancelModalOpen}
          cancelTransactionData={cancelTransactionData}
        />
      </Section>
      {isBasicAccount && (
        <BasicAccountRestristionsModal
          isOpen={isDepositRestrictionsModalOpen}
          close={() => setIsDepositRestrictionsModalOpen(false)}
          title={t('basicAccountRestrictions.deposit.title')}
          description={t('basicAccountRestrictions.deposit.description')}
        />
      )}
      {isBasicAccount && (
        <BasicAccountRestristionsModal
          isOpen={isWithdrawRestrictionsModalOpen}
          close={() => setIsWithdrawRestrictionsModalOpen(false)}
          title={t('basicAccountRestrictions.withdraw.title')}
          description={t('basicAccountRestrictions.withdraw.description')}
        />
      )}
      {storageFeeAmount > 0 && !isBasicAccount && (
        <RestrictedPermissionModal
          isOpen={isDepositRestrictionsModalOpen}
          handleClose={() => setIsDepositRestrictionsModalOpen(false)}
          title={t('storageFee.payStorageFeeByCardModal.label')}
          setIsSupportModalOpen={setIsSupportModalOpen}
        />
      )}
      <ChangeAutoPaySettingsModal
        isOpen={isAutoPaySettingsModalOpen}
        handleClose={() => setIsAutoPaySettingsModalOpen(false)}
        storageFeeAmount={storageFeeAmount}
        setIsOpenPayStorageFeeByCard={setIsOpenPayStorageFeeByCard}
        isOpenPayStorageFeeByCard={isOpenPayStorageFeeByCard}
      />
      <PayStorageFeeByCardModal
        isOpen={isOpenPayStorageFeeByCard}
        handleClose={() => setIsOpenPayStorageFeeByCard(false)}
        storageFeeAmount={storageFeeAmount}
        setStorageFeeAmount={setStorageFeeAmount}
        isAutoPaySettingsModalOpen={isAutoPaySettingsModalOpen}
        setIsAutoPaySettingsModalOpen={setIsAutoPaySettingsModalOpen}
        setIsOpenPayStorageFeeByCard={setIsOpenPayStorageFeeByCard}
        isOpenPayStorageFeeByCard={isOpenPayStorageFeeByCard}
        handleRefreshTransactions={handleRefreshTransactions}
      />
    </>
  );
};

export default FinancialBalancesPage;
