import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useBrokerCode } from '../../../../../util/hooks/useBrokerCode';
import { selectSettlementDaysInfo } from '../../../../../store/selectors/settingsSelectors';
import { getCurrentAccount } from '../../../../../store/selectors/accountSelectors';
import useGtmHook from '../../../../../util/hooks/useGtmHook';
import SectionLoader from '../../../../Loader/SectionLoader';
import ReviewBar from '../../../../ReviewBar/ReviewBar';
import ModalBody from '../../../../Modal/ModalBody';
import FormContainer from '../../../../FormContainer/FormContainer';
import ListWithIcon from '../../../../ListWithIcon/ListWithIcon';
import AgreementCheckbox from '../../../../InputFields/AgreementCheckbox';
import ModalButtons from '../../../../Modal/ModalButtons';
import AchDepositTerms from './AchDepositTerms';
import { formatMoneyNumeral } from '../../../../../util/helpers/numeralHelpers';
import { UPDATE_ACH_DEPOSIT_LOADING } from '../../../../../store/actions/orders/orderActionConstants';
import { selectIsLoadingByActionType } from '../../../../../store/selectors/loadingSelectors';

const AchDepositReview = ({
  wizardData,
  setWizardData,
  setModalSize,
  handleBack,
  handleSubmitDeposit,
  handleNext,
}) => {
  const { t } = useTranslation();

  const settlementDaysInfo = useSelector(selectSettlementDaysInfo);
  const brokerCode = useBrokerCode();
  const { AccountUid } = useSelector(getCurrentAccount);
  const [termsChecked, setTermsChecked] = useState(false);
  const isLoading = useSelector(
    selectIsLoadingByActionType(UPDATE_ACH_DEPOSIT_LOADING),
  );

  const { gtmDialogScreenView } = useGtmHook();

  useEffect(() => {
    if (wizardData?.isTermsModalOpen) {
      setModalSize('lg');
    } else {
      setModalSize('sm');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setModalSize, wizardData?.isTermsModalOpen]);

  const openTermsModal = (event) => {
    event.preventDefault();
    setWizardData({ isTermsModalOpen: true });
  };

  const closeTermsModal = () => {
    setWizardData({ isTermsModalOpen: false });
  };

  const handleCheckboxClick = () => {
    setTermsChecked(!termsChecked);
  };

  const handleNextTermsModal = () => {
    closeTermsModal();
    setTermsChecked(true);
  };

  const isConfirmDisabled =
    termsChecked && wizardData?.bankAccount.BankAccountUid;

  useEffect(() => {
    gtmDialogScreenView({
      title: `Confirm Deposit ACH Deposit`,
    });
  }, []); // eslint-disable-line

  const reviewData = [
    {
      icon: 'card',
      labels: [
        t('standardDepositWizard.achDeposit.depositFromBank', {
          bankName: wizardData?.bankAccount.BankName,
        }),
        formatMoneyNumeral(wizardData?.amount),
      ],
    },
    {
      icon: 'check',
      labels: [
        t('standardDepositWizard.achDeposit.fundsAvailable', {
          days: settlementDaysInfo,
        }),
      ],
    },
  ];

  if (wizardData?.isTermsModalOpen) {
    return (
      <ModalBody>
        <AchDepositTerms
          bankAccount={wizardData?.bankAccount}
          depositAmount={wizardData?.amount}
          accountUid={AccountUid}
          handleAgree={handleNextTermsModal}
          handleBack={closeTermsModal}
        />
      </ModalBody>
    );
  }

  return (
    <SectionLoader isLoading={isLoading}>
      <ReviewBar
        title={t(
          'standardDepositWizard.achDeposit.pleaseReviewAndConfirmTheWithdrawal',
        )}
      />
      <ModalBody>
        <FormContainer>
          <ListWithIcon items={reviewData} />
          <AgreementCheckbox
            checked={termsChecked}
            onChange={handleCheckboxClick}
            name="terms"
            text={t('standardDepositWizard.achDeposit.checkboxText', {
              brokerCode,
            })}
            link={t('standardDepositWizard.achDeposit.checkboxLink')}
            onLinkClick={(event) => {
              openTermsModal(event);
            }}
          />
        </FormContainer>
        <ModalButtons
          isHorizontal
          marginTop
          secondaryButtonProps={{ onClick: handleBack }}
          primaryButtonProps={{
            disabled: !isConfirmDisabled,
            onClick: () => handleSubmitDeposit(handleNext),
            label: t('common.confirm'),
          }}
        />
      </ModalBody>
    </SectionLoader>
  );
};

AchDepositReview.propTypes = {
  handleBack: PropTypes.func,
  handleSubmitDeposit: PropTypes.func,
  wizardData: PropTypes.shape({
    amount: PropTypes.number,
    isTermsModalOpen: PropTypes.bool,
    bankAccount: PropTypes.shape({
      BankAccountUid: PropTypes.string,
      BankName: PropTypes.string,
    }),
  }),
  setWizardData: PropTypes.func,
  setModalSize: PropTypes.func,
  handleNext: PropTypes.func,
};

export default AchDepositReview;
