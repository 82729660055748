import React from 'react';
import { Configure } from 'react-instantsearch-dom';
import startCase from 'lodash.startcase';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Refinement from '../Refinement/Refinement';
import Checkbox from '../InputFields/Checkbox';
import { CustomClearRefinements } from './ClearFilters';
import { selectMyHoldingsCodes } from '../../store/selectors/portfolioHoldingsSelectors';
import Button from '../Button/Button';
import AlgoliaLoader from '../Loader/AlgoliaLoader';
import {
  FilterContainer,
  FilterCta,
  FilterHead,
  FilterHeaderWrap,
  FilterList,
} from './Components/FilterComponent';

export default function Filter({
  isForSell,
  isMyHoldingsChecked,
  myHoldingsChangeHandler,
  products,
  close,
}) {
  const { t } = useTranslation();
  const myHoldingsCodes = useSelector(selectMyHoldingsCodes);

  return (
    <FilterContainer>
      <AlgoliaLoader>
        <FilterHeaderWrap>
          <FilterHead>
            <p>Filters</p>
          </FilterHead>
          {isForSell && (
            <Checkbox
              onChange={myHoldingsChangeHandler}
              checked={isMyHoldingsChecked}
              variant="MyHoldingsCheckbox"
            >
              {t('portfolio.holdings.myHoldingsOnlyLabel')}
            </Checkbox>
          )}
        </FilterHeaderWrap>

        <FilterList>
          <CustomClearRefinements />
          <Refinement
            products={products}
            title="Type"
            attribute="MetalType"
            showMore
            dataCy="checkbox-type-filter"
          />
          <Refinement
            products={products}
            title="Unit"
            attribute="ProductType"
            showMore
            dataCy="checkbox-unit-filter"
          />
          <Refinement
            title="Vault"
            attribute="Vaults.Location"
            formatLabel={startCase}
            isMyHoldingsChecked={isMyHoldingsChecked}
            myHoldingsCodes={myHoldingsCodes}
            products={products}
            dataCy="checkbox-vault-filter"
          />
          <Refinement
            title="Weight"
            attribute="WeightCaption"
            showMore
            products={products}
            dataCy="checkbox-weight-filter"
          />
          <FilterCta>
            <CustomClearRefinements />
            <Button
              onClick={close}
              variant="primary"
              size="sm"
              data-cy="button-apply-filters"
            >
              Apply Filters
            </Button>
          </FilterCta>
          <Configure />
        </FilterList>
      </AlgoliaLoader>
    </FilterContainer>
  );
}

Filter.propTypes = {
  isForSell: PropTypes.bool,
  isMyHoldingsChecked: PropTypes.bool,
  myHoldingsChangeHandler: PropTypes.func,
  products: PropTypes.arrayOf(PropTypes.shape({})),
  close: PropTypes.func,
};
