import {
  AUTHENTICATE_USER,
  CLEAR_LOGIN_USER_ERROR,
  LOGIN_USER_ERROR,
  LOGIN_USER_FETCH,
  LOGIN_USER_SUCCESS,
  LOGIN_USERNAMES_ERROR,
  LOGIN_USERNAMES_FETCH,
  LOGIN_USERNAMES_SUCCESS,
  LOGOUT_USER,
  RESET_LOGIN_STATE,
  SECURITY_QUESTION_ANSWER_ERROR,
  SECURITY_QUESTION_ANSWER_UPDATE,
  UPDATE_USER_JWT_TOKEN,
  REFRESH_TOKEN,
  LOGIN_USERNAMES_CLEAR,
  REGISTER_AUTHENTICATE,
  REGISTER_AUTHENTICATE_SUCCESS,
  REGISTER_AUTHENTICATE_ERROR,
  GENERATE_TOKEN,
  GENERATE_TOKEN_SUCCESS,
  GENERATE_TOKEN_ERROR,
  REGISTER_CONFIRM_AUTHENTICATION,
  REGISTER_CONFIRM_AUTHENTICATION_SUCCESS,
  REGISTER_CONFIRM_AUTHENTICATION_ERROR,
  CLEAR_LOGIN_STATE,
  REGISTER_USER_FETCH,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
} from './loginActionConstants';

export const fetchUsernames = ({
  email,
  token,
  setFieldError,
  setIsLoading,
  onSuccess,
  formUserInvite,
}) => ({
  type: LOGIN_USERNAMES_FETCH,
  email,
  token,
  setFieldError,
  setIsLoading,
  onSuccess,
  formUserInvite,
});

export const fetchUsernamesSuccess = (payload) => ({
  type: LOGIN_USERNAMES_SUCCESS,
  payload,
});

export const fetchUsernamesError = (payload) => ({
  type: LOGIN_USERNAMES_ERROR,
  payload,
});

export const clearUsernamesState = () => ({
  type: LOGIN_USERNAMES_CLEAR,
});

export const fetchUser = (payload) => ({
  type: LOGIN_USER_FETCH,
  payload,
});

export const fetchUserSuccess = (payload) => ({
  type: LOGIN_USER_SUCCESS,
  payload,
});

export const fetchUserError = (payload) => ({
  type: LOGIN_USER_ERROR,
  payload,
});

export const updateSecurityQuestionAnswer = (payload) => ({
  type: SECURITY_QUESTION_ANSWER_UPDATE,
  payload,
});

export const updateUserToken = (payload) => ({
  type: UPDATE_USER_JWT_TOKEN,
  payload,
});

export const updateSecurityQuestionAnswerError = (payload) => ({
  type: SECURITY_QUESTION_ANSWER_ERROR,
  payload,
});

export const resetLoginState = () => ({
  type: RESET_LOGIN_STATE,
});

export const clearLoginErrors = () => ({
  type: CLEAR_LOGIN_USER_ERROR,
});

export const authenticateUser = () => ({
  type: AUTHENTICATE_USER,
});

export const logoutUser = (payload) => ({
  type: LOGOUT_USER,
  payload,
});

export const refreshUserToken = (payload) => ({
  type: REFRESH_TOKEN,
  payload,
});

export const registerAuthenticate = (payload) => ({
  type: REGISTER_AUTHENTICATE,
  payload,
});

export const registerAuthenticateSuccess = (payload) => ({
  type: REGISTER_AUTHENTICATE_SUCCESS,
  payload,
});

export const registerAuthenticateError = (payload) => ({
  type: REGISTER_AUTHENTICATE_ERROR,
  payload,
});

export const registerConfirmAuthentication = (payload) => ({
  type: REGISTER_CONFIRM_AUTHENTICATION,
  payload,
});

export const registerConfirmAuthenticationSuccess = (payload) => ({
  type: REGISTER_CONFIRM_AUTHENTICATION_SUCCESS,
  payload,
});

export const registerConfirmAuthenticationError = (payload) => ({
  type: REGISTER_CONFIRM_AUTHENTICATION_ERROR,
  payload,
});

export const generateToken = (payload) => ({
  type: GENERATE_TOKEN,
  payload,
});

export const generateTokenSuccess = (payload) => ({
  type: GENERATE_TOKEN_SUCCESS,
  payload,
});

export const generateTokenError = (payload) => ({
  type: GENERATE_TOKEN_ERROR,
  payload,
});

export function clearLoginState() {
  return {
    type: CLEAR_LOGIN_STATE,
  };
}

export const registerUser = (payload) => ({
  type: REGISTER_USER_FETCH,
  payload,
});

export const registerUserSuccess = (payload) => ({
  type: REGISTER_USER_SUCCESS,
  payload,
});

export const registerUserError = (payload) => ({
  type: REGISTER_USER_ERROR,
  payload,
});
