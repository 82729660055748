import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import {
  getProductBySymbol,
  selectProductPrices,
} from '../../store/selectors/productsSelector';

const useProductPrice = (storageProduct, vault, isProductPage) => {
  const prices = useSelector(selectProductPrices);
  const productBySymbol = useSelector(getProductBySymbol);
  const [unitPrice, setUnitPrice] = useState(0);

  useEffect(() => {
    if (isProductPage) {
      setUnitPrice(productBySymbol?.ProductPrice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productBySymbol]);

  const selectedProduct = prices.find(
    (item) => item?.ProductCode === storageProduct?.ProductCode,
  );

  useEffect(() => {
    if (!isProductPage) {
      const productVault = selectedProduct?.Locations?.find(
        (location) => location?.SymbolCode === vault?.SymbolCode,
      );

      setUnitPrice(productVault?.PricePerUnit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct?.ProductPrice]);

  return unitPrice;
};

export default useProductPrice;
