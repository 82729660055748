import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash';
import BankAccountList from '../Deposit/StandardDepositWizard/Components/BankAccountList';
import { selectIsLoadingByActionType } from '../../../store/selectors/loadingSelectors';
import { formatMoneyNumeral } from '../../../util/helpers/numeralHelpers';
import { fetchBankAccountsByType } from '../../../store/actions/bankAccount/bankAccountActions';

import useGtmHook from '../../../util/hooks/useGtmHook';
import Label from '../../Notes/Label';
import ModalBody from '../../Modal/ModalBody';
import ModalButtons from '../../Modal/ModalButtons';
import ModalTitle from '../../Modal/ModalTitle';
import Paragraph from '../../Paragraph/Paragraph';
import AgreementCheckbox from '../../InputFields/AgreementCheckbox';
import RoundedNote from '../../RoundedNote/RoundedNote';
import { ACC_PURPOSE_ACH } from '../../../util/enum/api/bankAccountTypes';
import { getCurrentAccount } from '../../../store/selectors/accountSelectors';
import { BANK_ACCOUNTS_BY_TYPE_FETCH_LOADING } from '../../../store/actions/bankAccount/bankAccountActionConstants';
import { selectBankAccountsByType } from '../../../store/selectors/bankAccountSelectors';
import SectionLoader from '../../Loader/SectionLoader';
import FixBankAccount from '../BankAccount/FixBankAccount/FixBankAccount';
import AddAchBankAccountWizard from '../BankAccount/AddAchBankAccount/AddAchBankAccountWizard';
import useCheckPermissions from '../../../util/hooks/useCheckPermissions';
import { deleteWizardKey } from '../../../util/helpers/wizardHelpers';
import config from '../../../config';

const MetalStreamFunding = ({
  handleBack,
  handleNext,
  setWizardData,
  wizardData,
  setModalSize,
  setWizardBack,
  setWizardTitle,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { AccountUid } = useSelector(getCurrentAccount);
  const isLoading = useSelector(
    selectIsLoadingByActionType(BANK_ACCOUNTS_BY_TYPE_FETCH_LOADING),
  );
  const bankAccounts = useSelector(selectBankAccountsByType);
  const { metalStreamCaption } = config;
  const { gtmDialogScreenView } = useGtmHook();
  const {
    displayStandardOptionsPermission,
    displayBasicAccountOptionsPermission,
  } = useCheckPermissions();
  const account = useSelector(getCurrentAccount);
  const isPendingAccount =
    account?.AccountStatus === 4 ||
    account?.AccountStatus === 1 ||
    account?.AccountStatus === 7;

  const disableManualAch =
    isPendingAccount || displayBasicAccountOptionsPermission;

  useEffect(() => {
    dispatch(
      fetchBankAccountsByType({
        accountUid: AccountUid,
        type: ACC_PURPOSE_ACH,
      }),
    );
  }, [AccountUid, dispatch]);

  useEffect(() => {
    gtmDialogScreenView({
      title: `MetalStream Funding`,
    });
  }, []); //eslint-disable-line

  const handleSelectBankAccount = (bankAccount) => {
    setWizardData({ BankAccount: bankAccount });
  };

  const handleFixBankAccount = (event, bankAccount) => {
    event.stopPropagation();
    setWizardData({
      bankAccountForFix: bankAccount,
      isFixBankAccountWizardOpen: true,
    });
  };

  const closeHandleFixBankAccount = () => {
    deleteWizardKey('AddNewAchWizard');
    setWizardData({
      bankAccountForFix: null,
      isFixBankAccountWizardOpen: false,
    });
  };

  const handleAddBankAccount = () => {
    setWizardData({
      isAddAchWizardOpen: true,
    });
  };

  const closeAddBankAccount = () => {
    deleteWizardKey('AddNewAchWizard');
    setWizardData({
      isAddAchWizardOpen: false,
    });
  };

  useEffect(() => {
    if (wizardData?.isFixBankAccountWizardOpen) {
      setModalSize('lg');
    } else {
      setModalSize('sm');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setModalSize, wizardData?.isFixBankAccountWizardOpen]);

  useEffect(() => {
    if (wizardData?.isAddAchWizardOpen) {
      setWizardBack(() => () => closeAddBankAccount());
      setWizardTitle(t('achDeposit.chooseAchAddMethod.modalTitle'));

      return;
    }

    if (wizardData?.isFixBankAccountWizardOpen) {
      setWizardBack(() => () => closeHandleFixBankAccount());
      setWizardTitle(
        t('standardDepositWizard.achDeposit.fixBankAccount.title'),
      );
      return;
    }

    setWizardBack(null);
    setWizardTitle(
      t('metalStream.modalTitle', {
        metalStream: metalStreamCaption,
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardData?.isAddAchWizardOpen, wizardData?.isFixBankAccountWizardOpen]);

  if (wizardData?.isFixBankAccountWizardOpen) {
    return (
      <ModalBody>
        <FixBankAccount
          wizardData={wizardData}
          handleClose={closeHandleFixBankAccount}
          setWizardData={setWizardData}
        />
      </ModalBody>
    );
  }

  if (wizardData?.isAddAchWizardOpen) {
    return (
      <AddAchBankAccountWizard
        wizardData={wizardData}
        setWizardData={setWizardData}
        onClose={closeAddBankAccount}
        disableManualAch={disableManualAch}
      />
    );
  }

  return (
    <SectionLoader isLoading={isLoading}>
      <ModalBody>
        <ModalTitle>{t('funding.funding')}</ModalTitle>
        <Paragraph marginBottom={32}>{t('funding.pleaseSelect')}</Paragraph>
        <Label marginBottom={4} isBold text={t('funding.monthlyInvestment')} />
        <Paragraph fontSize={18} marginBottom={32}>
          {formatMoneyNumeral(wizardData?.Amount)}
        </Paragraph>
        {!displayStandardOptionsPermission && (
          <RoundedNote marginBottom={32} text={t('funding.restricted')} />
        )}
        {displayStandardOptionsPermission && !wizardData?.UseAccountBalance && (
          <BankAccountList
            label={t('funding.fundFromBankAccount')}
            empty={!bankAccounts.length}
            handleSelectBankAccount={handleSelectBankAccount}
            selectedBankAccount={wizardData?.BankAccount}
            handleFixBankAccount={handleFixBankAccount}
            bankAccounts={bankAccounts}
            handleAddBankAccount={handleAddBankAccount}
            marginBottom={32}
          />
        )}

        <Label marginBottom={8} text={t('funding.fundFromAccountBalance')} />
        <AgreementCheckbox
          checked={wizardData?.UseAccountBalance}
          onChange={(e) => {
            setWizardData({ UseAccountBalance: e.target.checked });
          }}
          text={t('funding.myBalance', {
            metalStream: metalStreamCaption,
          })}
        />
        {wizardData?.UseAccountBalance && (
          <Paragraph marginTop={16}>
            {t('funding.checkingOption', {
              metalStream: metalStreamCaption,
            })}
          </Paragraph>
        )}
        <ModalButtons
          isHorizontal
          marginTop
          secondaryButtonProps={{ onClick: handleBack }}
          primaryButtonProps={{
            onClick: handleNext,
            disabled:
              !wizardData?.UseAccountBalance &&
              isEmpty(wizardData?.BankAccount),
          }}
        />
      </ModalBody>
    </SectionLoader>
  );
};

MetalStreamFunding.propTypes = {
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  selectedBankAccount: PropTypes.shape({}),
  setWizardData: PropTypes.func,
  wizardData: PropTypes.shape({
    isFixBankAccountWizardOpen: PropTypes.bool,
    isAddAchWizardOpen: PropTypes.bool,
    Amount: PropTypes.number,
    BankAccount: PropTypes.shape({}),
    UseAccountBalance: PropTypes.bool,
  }),
  setModalSize: PropTypes.func,
  setWizardBack: PropTypes.func,
  setWizardTitle: PropTypes.func,
};

export default MetalStreamFunding;
