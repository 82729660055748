import i18next from 'i18next';

export const REGISTRATION = {
  APPLICATION_TYPE_PATH: '/ApplicationType',
  ACCOUNT_TYPE_PATH: '/AccountType',
  COMPLETE_PATH: 'ApplicationStep',
};

export const APPLICATION_TYPE = {
  INDIVIDUAL_APPLICATION_TYPE: 'Individual',
  JOINT_APPLICATION_TYPE: 'Joint',
  CORPORATE_APPLICATION_TYPE: 'Corporate',
  IRA_APPLICATION_TYPE: 'IRA',
  URMA_APPLICATION_TYPE: 'UTMA',
};

export const LOGIN_SCREEN = 'LOGIN';
export const EXISTING_ACCOUNT = 'EXISTING_ACCOUNT';

export const ACCOUNT_TYPE = {
  JOINT_TENANTS_COMMON_ACCOUNT_TYPE: 'JointTenantsCommon',
  JOINT_TENANTS_SURVIVORSHIP_ACCOUNT_TYPE:
    'JointTenantsWithRightOfSurvivorship',

  CORPORATE_ACCOUNT_TYPE: 'Corporation',
  CORPORATE_SOLE_PROPRIETORSHIP_ACCOUNT_TYPE: 'SoleProprietorship',
  CORPORATE_PARTNERSHIP_ACCOUNT_TYPE: 'Partnership',
  CORPORATE_LIMITED_LIABILITY_CORPORATION_ACCOUNT_TYPE:
    'LimitedLiabilityCorporation',
  CORPORATE_TRUST_ACCOUNT_TYPE: 'Trust',

  IRA_TRADITIONAL_ACCOUNT_TYPE: 'Traditional',
  IRA_ROTH_ACCOUNT_TYPE: 'Roth',
  IRA_SIMPLE_ACCOUNT_TYPE: 'SIMPLE',
  IRA_SEP_ACCOUNT_TYPE: 'SEP',

  UTMA_ACCOUNT_TYPE: 'UTMA',
  INDIVIDUAL_ACCOUNT_TYPE: 'Individual',
};

export const ACCOUNT_TYPES = [
  'Individual',
  'JointTenantsWithRightOfSurvivorship',
  'JointTenantsInCommon',
  'Corporate',
  'Partnership',
  'SoleProprietorship',
  'LimitedLiabilityCorporation',
  'Trust',
  'IRA',
  'UTMA',
  'Basic',
];

export const SUFFIX_TYPES_ARRAY = [
  { value: '', label: i18next.t('common.none') },
  { value: 1, label: 'I' },
  { value: 2, label: 'II' },
  { value: 3, label: 'III' },
  { value: 4, label: 'IV' },
  { value: 5, label: 'IX' },
  { value: 6, label: 'JR' },
  { value: 7, label: 'SR' },
  { value: 8, label: 'V' },
  { value: 9, label: 'VI' },
  { value: 10, label: 'VII' },
  { value: 11, label: 'VIII' },
  { value: 12, label: 'ESQ' },
  { value: 13, label: 'MD' },
];

export const MARITAL_STATUS_TYPE_ARRAY = [
  { value: 1, label: 'Single' },
  { value: 2, label: 'Married' },
  { value: 3, label: 'Divorced' },
  { value: 4, label: 'Widowed' },
];

export const MARITAL_STATUS_TYPE_LABEL_ARRAY = [
  'Single',
  'Married',
  'Divorced',
  'Widowed',
];

export const FLAG_URL =
  'https://purecatamphetamine.github.io/country-flag-icons/3x2/{iso2}.svg';
