import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as Checked } from '../../assets/images/svg/checked.svg';
import { ReactComponent as Unchecked } from '../../assets/images/svg/unchecked.svg';
import { pxToRem } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';

const getCheckboxWrapStyles = (variant) => {
  if (variant === 'AgreementCheckbox') {
    return `
      box-shadow: ${variables.shadow.boxShadow};
      padding: ${pxToRem(4)} ${pxToRem(16)};
      border-radius: ${variables.borderRadius.borderRadius};
    `;
  }

  if (variant === 'MyHoldingsCheckbox') {
    return `
      border: 1px solid ${themeColors.colorPrimary};
      padding: ${pxToRem(4)} ${pxToRem(16)};
      border-radius: ${variables.borderRadius.borderRadius};

      ${CheckboxContent} {
        color: ${themeColors.colorPrimary};
        font-weight: 400;
        font-size: ${pxToRem(16)};
      }
    `;
  }
};

export const CheckboxWrap = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${pxToRem(marginBottom)}` : 0};
  margin-top: ${({ marginTop }) => (marginTop ? `${pxToRem(marginTop)}` : 0)};
  ${({ variant }) => getCheckboxWrapStyles(variant)}
`;

export const CheckboxField = styled.input`
  display: none;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    user-interaction: none;
  }
`;

export const CheckboxIndicator = styled.div`
  margin-right: ${pxToRem(16)};
  width: ${pxToRem(16)};
  height: ${pxToRem(16)};
  ${({ disabled }) =>
    disabled &&
    ` opacity: 0.5;
      cursor: not-allowed;
      user-interaction: none;
`};

  svg {
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
  }
`;

export const CheckboxContent = styled.div`
  font-size: ${pxToRem(14)};
  line-height: 1.15;
  color: ${themeColors.colorLabel};
  user-select: none;

  &,
  p,
  a,
  span {
    font-weight: 600;
  }
  ${({ disabled }) =>
    disabled &&
    ` opacity: 0.5;
  cursor: not-allowed;
  user-interaction: none;
`};
`;

const Checkbox = ({
  children,
  name,
  onChange,
  checked,
  field,
  disabled,
  variant = 'Checkbox',
  ...props
}) => (
  <CheckboxWrap htmlFor={name} variant={variant} {...props}>
    <CheckboxField
      name={name}
      id={name}
      type="checkbox"
      checked={checked}
      {...field}
      onChange={onChange || field.onChange}
      disabled={disabled}
    />

    <CheckboxIndicator disabled={disabled}>
      {checked ? <Checked /> : <Unchecked />}
    </CheckboxIndicator>
    <CheckboxContent disabled={disabled}>{children}</CheckboxContent>
  </CheckboxWrap>
);

Checkbox.propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  field: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  variant: PropTypes.oneOf([
    'AgreementCheckbox',
    'Checkbox',
    'MyHoldingsCheckbox',
  ]),
};

export default Checkbox;
