import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import jwt from 'jsonwebtoken';
import isEmpty from 'lodash.isempty';
import { fetchSettings } from '../../store/actions/settings/settingsActions';
import {
  fetchUserAccounts,
  setUser,
} from '../../store/actions/user/userActions';
import { selectAuthUser } from '../../store/selectors/userSelectors';
import {
  getCurrentAccount,
  getCurrentAccountUid,
  getCurrentUserPermissions,
} from '../../store/selectors/accountSelectors';
import { setAppReady } from '../../store/actions/app/appActions';
import {
  fetchAccountSettings,
  fetchSingleAccount,
  setCurrentAccount,
  userPermissionsFetch,
} from '../../store/actions/account/accountActions';
import { addHeaderToken } from '../../request';
import { setLocale } from '../helpers/numeralHelpers';
import {
  ACCOUNT_UID,
  IGNORE_PERMISSIONS,
  IMPERSONATE_USER_UID,
  REGISTRATION_USER_UID,
} from '../../constants/sessionStorage';
import { JWT_TOKEN } from '../../constants/localStorage';
import { authScopeStringGetHelper } from '../helpers/authScopeHelpers';
import {
  retrieveFromSessionStorage,
  storeInSessionStorage,
} from '../helpers/sessionStorageHelper';
import { MAINTENANCE_PAGE } from '../../constants/pages';

export default function useInitHook(setNoPermissionsModal, maintenance) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  if (params.clear) {
    // logout user
    localStorage.clear();
    sessionStorage.clear();
  }

  const dispatch = useDispatch();
  const user = useSelector(selectAuthUser);
  const accountUid = useSelector(getCurrentAccountUid);
  const permissions = useSelector(getCurrentUserPermissions);
  const currentAccount = useSelector(getCurrentAccount);
  const impersonateAccountUid = retrieveFromSessionStorage(
    IMPERSONATE_USER_UID,
  );
  const registrationAccountUid = retrieveFromSessionStorage(
    REGISTRATION_USER_UID,
  );
  const ignorePermissions = retrieveFromSessionStorage(IGNORE_PERMISSIONS);
  const [impersonateAccount, setImpersonateAccount] = useState({});
  const isMaintenanceMode =
    maintenance || window.location.pathname === MAINTENANCE_PAGE;

  useEffect(() => {
    if (isMaintenanceMode) {
      return;
    }

    dispatch(fetchSettings());
  }, [dispatch, isMaintenanceMode]);

  useEffect(() => {
    if (isMaintenanceMode) {
      return;
    }

    setLocale(currentAccount ? currentAccount.CurrencyIso3Code : 'en');
  }, [currentAccount, currentAccount.CurrencyIso3Code, isMaintenanceMode]);

  useEffect(() => {
    if (isMaintenanceMode) {
      return;
    }

    const token = authScopeStringGetHelper(JWT_TOKEN);
    if (token) {
      const decodedTokenInfo = jwt.decode(token);
      addHeaderToken(token);
      dispatch(setUser(decodedTokenInfo));
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    if (isMaintenanceMode) {
      return;
    }

    if (user && user.UserUid) {
      const jwtToken = authScopeStringGetHelper(JWT_TOKEN);
      if (jwtToken) {
        dispatch(
          fetchUserAccounts({
            userUid: user.UserUid,
            setDefaultAccount: true,
          }),
        );

        if (impersonateAccountUid || registrationAccountUid) {
          storeInSessionStorage(
            ACCOUNT_UID,
            impersonateAccountUid || registrationAccountUid,
          );

          dispatch(
            fetchSingleAccount({
              AccountUid: impersonateAccountUid || registrationAccountUid,
              setImpersonateAccount,
            }),
          );

          if (!isEmpty(impersonateAccount)) {
            dispatch(setCurrentAccount(impersonateAccount));
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (isMaintenanceMode) {
      return;
    }

    if (user && user.UserUid) {
      if (currentAccount.AccountUid) {
        dispatch(
          userPermissionsFetch({
            accountUid: currentAccount.AccountUid,
            userUid: user.UserUid,
          }),
        );
      }
    }
  }, [currentAccount.AccountUid]); // eslint-disable-line

  useEffect(() => {
    if (isMaintenanceMode) {
      return;
    }

    if (accountUid) {
      dispatch(fetchAccountSettings({ accountUid }));
    }
  }, [accountUid, dispatch, isMaintenanceMode]);

  useEffect(() => {
    if (isMaintenanceMode) {
      return;
    }

    if (!isEmpty(permissions) && accountUid) {
      if (isEmpty(permissions?.Permissions)) {
        if (!ignorePermissions) {
          setNoPermissionsModal(true);
          return;
        }
      }

      setNoPermissionsModal(false);
      dispatch(setAppReady());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, isMaintenanceMode]);
}
