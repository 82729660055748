import { getRequest, postRequest, replaceInUrl, deleteRequest } from './index';
import apiEndpoints from './apiEndpoints';

export const getClientToken = (accountUid) =>
  getRequest(
    replaceInUrl(apiEndpoints.creditCards.getClientToken, { accountUid }),
  );

export const submitCreditCardRequest = (AccountUid, data) =>
  postRequest(
    replaceInUrl(apiEndpoints.creditCards.addOrGetCreditCards, {
      accountUid: AccountUid,
    }),
    data,
  );

export const getCreditCardsRequest = (accountUid) =>
  getRequest(
    replaceInUrl(apiEndpoints.creditCards.getCreditCards, { accountUid }),
  );

export const deleteCreditCardRequest = (accountUid, creditCardUid) =>
  deleteRequest(
    replaceInUrl(apiEndpoints.creditCards.deleteCreditCard, {
      accountUid,
      creditCardUid,
    }),
  );
