import themeColors from '../../assets/styles/themeColors';
import i18n from '../../i18n';
import {
  bankAccountApprovalStatus,
  bankAccountPurposeTypes,
  bankAccountSetupTypes,
  voidedCheckCompleteStatusType,
  voidedCheckDeclinedStatusType,
  voidedCheckIncompleteStatusType,
  voidedCheckNotReadyStatusType,
  voidedCheckReUploadStatusType,
  voidedCheckReviewStatusType,
  voidedCheckStatusTypes,
  bankAccountApprovalUnknownStatusType,
  bankAccountApprovalDisabledStatusType,
  bankAccountApprovalPendingActivationStatusType,
  bankAccountApprovalActivatedStatusType,
  bankAccountApprovalFailedVerificationStatusType,
  bankAccountApprovalExpiredVerificationStatusType,
  VOIDED_CHECK_ENUM_STATUS_TYPES,
} from '../enum/api/bankAccountTypes';
import { parseEnumType } from './enumMappers';

export const completeStatus = i18n.t(
  'bankAccounts.verificationStatus.complete',
);
const pendingStatus = i18n.t('bankAccounts.verificationStatus.pending');
export const actionRequiredStatus = i18n.t(
  'bankAccounts.verificationStatus.actionRequired',
);
const incompleteStatus = i18n.t('bankAccounts.verificationStatus.incomplete');
const preparingStatus = i18n.t('bankAccounts.verificationStatus.preparing');
export const expiredVerificationStatus = i18n.t(
  'bankAccounts.verificationStatus.expiredVerification',
);
const verifiedStatus = i18n.t('bankAccounts.verificationStatus.verified');
const processingStatus = i18n.t('bankAccounts.verificationStatus.processing');
export const failedVerificationStatus = i18n.t(
  'bankAccounts.verificationStatus.failedVerification',
);
export const pendingReviewStatus = i18n.t(
  'bankAccounts.verificationStatus.pendingReview',
);

export const getVoidedCheckStatus = (bankAccount) => {
  if (!bankAccount) {
    return null;
  }

  const { VoidedCheckStatusType } = bankAccount;
  const voidedCheckStatus = parseEnumType(
    voidedCheckStatusTypes,
    VoidedCheckStatusType,
  );

  switch (voidedCheckStatus) {
    case voidedCheckCompleteStatusType:
    case voidedCheckReviewStatusType:
      return {
        color: themeColors.colorSuccess,
        status: completeStatus,
      };

    case voidedCheckNotReadyStatusType:
      return {
        color: themeColors.colorIncomplete,
        status: preparingStatus,
      };

    case voidedCheckDeclinedStatusType:
      return {
        color: themeColors.colorIncomplete,
        status: failedVerificationStatus,
      };

    default:
      return {
        color: themeColors.colorIncomplete,
        status: incompleteStatus,
      };
  }
};

export const getMicroDepositsStatus = (bankAccount) => {
  if (!bankAccount) {
    return null;
  }

  const { StateType } = bankAccount;
  const stateType = parseEnumType(bankAccountApprovalStatus, StateType);

  switch (stateType) {
    case bankAccountApprovalActivatedStatusType:
    case bankAccountApprovalPendingActivationStatusType:
      return {
        color: themeColors.colorSuccess,
        status: completeStatus,
      };

    case bankAccountApprovalUnknownStatusType:
    case bankAccountApprovalFailedVerificationStatusType:
    case bankAccountApprovalDisabledStatusType:
      return {
        color: themeColors.colorIncomplete,
        status: failedVerificationStatus,
      };

    case bankAccountApprovalExpiredVerificationStatusType:
      return {
        color: themeColors.colorIncomplete,
        status: expiredVerificationStatus,
      };

    default:
      return {
        color: themeColors.colorIncomplete,
        status: incompleteStatus,
      };
  }
};

export const getBankAccountStatus = (bankAccount) => {
  if (!bankAccount) {
    return null;
  }

  const { SetupType } = bankAccount;

  const isPlaidBankAccount =
    parseEnumType(bankAccountSetupTypes, SetupType) ===
    parseEnumType(bankAccountSetupTypes, 3);

  const isWireBankAccount =
    parseEnumType(bankAccountPurposeTypes, bankAccount?.PurposeType) ===
    parseEnumType(bankAccountPurposeTypes, 2);

  if (isWireBankAccount) {
    const isCompleteState =
      parseEnumType(bankAccountApprovalStatus, bankAccount?.StateType) ===
      parseEnumType(bankAccountApprovalStatus, 5);

    const isPendingState =
      parseEnumType(bankAccountApprovalStatus, bankAccount?.StateType) ===
      parseEnumType(bankAccountApprovalStatus, 4);

    if (isCompleteState) {
      return {
        color: themeColors.colorSuccess,
        status: completeStatus,
      };
    }

    if (isPendingState) {
      return {
        color: themeColors.colorWarning,
        status: pendingStatus,
      };
    }

    return {
      color: themeColors.colorIncomplete,
      status: failedVerificationStatus,
    };
  }

  const { VoidedCheckStatusType, StateType } = bankAccount;
  const voidedCheckStatus = parseEnumType(
    voidedCheckStatusTypes,
    VoidedCheckStatusType,
  );

  const stateType = parseEnumType(bankAccountApprovalStatus, StateType);

  if (isPlaidBankAccount) {
    if (voidedCheckStatus) {
      switch (voidedCheckStatus) {
        case voidedCheckIncompleteStatusType:
        case voidedCheckReUploadStatusType:
          return {
            color: themeColors.colorError,
            status: actionRequiredStatus,
          };

        case voidedCheckDeclinedStatusType:
          return {
            color: themeColors.colorIncomplete,
            status: failedVerificationStatus,
          };

        case voidedCheckReviewStatusType:
          return {
            color: themeColors.colorWarning,
            status: pendingReviewStatus,
          };

        case voidedCheckNotReadyStatusType:
          return {
            color: themeColors.colorWarning,
            status: processingStatus,
          };

        case voidedCheckCompleteStatusType:
          return {
            color: themeColors.colorSuccess,
            status: verifiedStatus,
          };

        default:
          return {
            color: themeColors.colorIncomplete,
            status: incompleteStatus,
          };
      }
    }
  }

  if (
    stateType === bankAccountApprovalFailedVerificationStatusType ||
    stateType === bankAccountApprovalUnknownStatusType ||
    stateType === bankAccountApprovalDisabledStatusType ||
    voidedCheckStatus === voidedCheckDeclinedStatusType
  ) {
    return {
      color: themeColors.colorIncomplete,
      status: failedVerificationStatus,
    };
  }

  if (stateType === bankAccountApprovalExpiredVerificationStatusType) {
    return {
      color: themeColors.colorIncomplete,
      status: expiredVerificationStatus,
    };
  }

  if (
    (stateType === bankAccountApprovalActivatedStatusType &&
      voidedCheckStatus === voidedCheckReviewStatusType) ||
    (stateType === bankAccountApprovalPendingActivationStatusType &&
      voidedCheckStatus === voidedCheckCompleteStatusType)
  ) {
    return {
      color: themeColors.colorWarning,
      status: pendingReviewStatus,
    };
  }

  if (
    stateType === bankAccountApprovalActivatedStatusType &&
    voidedCheckStatus === voidedCheckCompleteStatusType
  ) {
    return {
      color: themeColors.colorSuccess,
      status: verifiedStatus,
    };
  }

  return {
    color: themeColors.colorError,
    status: actionRequiredStatus,
  };
};

export const shouldRenderRequirements = (bankAccount) => {
  if (!bankAccount) {
    return null;
  }

  const { PurposeType } = bankAccount;
  const bankAccountPurposeType = parseEnumType(
    bankAccountPurposeTypes,
    PurposeType,
  );

  if (bankAccountPurposeType === parseEnumType(bankAccountPurposeTypes, 2)) {
    return false;
  }

  const bankAccountStatus = getBankAccountStatus(bankAccount);

  if (
    bankAccountStatus?.status === verifiedStatus ||
    bankAccountStatus?.status === failedVerificationStatus ||
    bankAccountStatus?.status === expiredVerificationStatus
  ) {
    return false;
  }

  return true;
};

export const shouldRenderBankAccountBadge = (bankAccount) => {
  if (!bankAccount) {
    return null;
  }

  const { PurposeType } = bankAccount;
  const bankAccountPurposeType = parseEnumType(
    bankAccountPurposeTypes,
    PurposeType,
  );

  if (bankAccountPurposeType === parseEnumType(bankAccountPurposeTypes, 2)) {
    return false;
  }

  return true;
};

export const isBankAccountVerified = (bankAccount) => {
  if (!bankAccount) {
    return null;
  }

  const isWireBankAccount =
    parseEnumType(bankAccountPurposeTypes, bankAccount?.PurposeType) ===
    parseEnumType(bankAccountPurposeTypes, 2);

  if (isWireBankAccount) {
    const isCompleteState =
      parseEnumType(bankAccountApprovalStatus, bankAccount?.StateType) ===
      parseEnumType(bankAccountApprovalStatus, 5);

    if (isCompleteState) {
      return true;
    }

    return false;
  }

  const { status } = getVoidedCheckStatus(bankAccount);

  if (status === failedVerificationStatus) {
    return false;
  }

  return bankAccount.IsVerified;
};

export const sortBankAccountsUploadedVoidedCheckIncompleteFirst = (
  bankAccounts,
) =>
  bankAccounts?.sort((bankAccountA, bankAccountB) => {
    const uploadNeededVerificationStatuses = [
      VOIDED_CHECK_ENUM_STATUS_TYPES.RE_UPLOAD_NEEDED,
      VOIDED_CHECK_ENUM_STATUS_TYPES.INCOMPLETE,
    ];

    if (
      uploadNeededVerificationStatuses.includes(
        bankAccountA.VoidedCheckStatusType,
      ) &&
      !uploadNeededVerificationStatuses.includes(
        bankAccountB.VoidedCheckStatusType,
      )
    ) {
      return -1;
    }
    if (
      !uploadNeededVerificationStatuses.includes(
        bankAccountA.VoidedCheckStatusType,
      ) &&
      uploadNeededVerificationStatuses.includes(
        bankAccountB.VoidedCheckStatusType,
      )
    ) {
      return 1;
    }
    return 0;
  });
