import React from 'react';
import PropTypes from 'prop-types';

import BaseInputField from './BaseInputField';
import {
  BACKSPACE_KEYCODE,
  TAB_KEYCODE,
  RIGHT_ARROW_KEYCODE,
  LEFT_ARROW_KEYCODE,
  WHITESPACE_KEYCODE,
} from '../../constants/keyCodeConstants';

const TextField = ({
  field,
  form,
  label,
  placeholder,
  disabled,
  centerText,
  autoFocus,
  preventAllExceptNumbers,
  ...props
}) => {
  const onKeydownHandler = (event) => {
    const { value } = field;
    const { keyCode } = event;

    if (!value && keyCode === WHITESPACE_KEYCODE) {
      event.preventDefault();
    }

    if (preventAllExceptNumbers) {
      if (
        keyCode === BACKSPACE_KEYCODE ||
        keyCode === TAB_KEYCODE ||
        keyCode === RIGHT_ARROW_KEYCODE ||
        keyCode === LEFT_ARROW_KEYCODE
      ) {
        return;
      }

      if ((keyCode < 58 && keyCode > 47) || (keyCode < 106 && keyCode > 95)) {
        return;
      }

      event.preventDefault();
    }
  };

  return (
    <BaseInputField
      autoFocus={autoFocus}
      type="text"
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      form={form}
      field={field}
      centerText={centerText}
      {...props}
      onKeyDown={(event) => onKeydownHandler(event)}
    />
  );
};

TextField.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  form: PropTypes.shape({}),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  centerText: PropTypes.bool,
  autoFocus: PropTypes.bool,
  preventAllExceptNumbers: PropTypes.bool,
};

export default TextField;
