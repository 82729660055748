import React from 'react';
import isEmpty from 'lodash.isempty';

import ProductCardTableRow from '../components/ProductCard/ProductCardTableRow';
import {
  LOCATION_TYPE_DELIVERY,
  productLocationTypes,
  PRODUCT_TYPE_FRACTIONAL,
} from './enum/api/productTypes';
import { parseEnumType } from './helpers/enumMappers';
import { separateByUppercase } from './helpers/stringHelpers';
import i18next from '../i18n';
import { formatMoneyNumeral } from './helpers/numeralHelpers';

export function handleLocationText(location, segregatedCaption) {
  if (location.IsTestProduct) {
    return `${separateByUppercase(
      parseEnumType(productLocationTypes, location.LocationType),
    )} (test)`;
  }
  if (location.IsSegregated) {
    return `${separateByUppercase(
      parseEnumType(productLocationTypes, location.LocationType),
    )} (${segregatedCaption})`;
  }
  return separateByUppercase(
    parseEnumType(productLocationTypes, location.LocationType),
  );
}

export function formatLocationsTableRowData(
  productLocations,
  priceTiersClickHandler,
  product,
  selectProduct,
  side,
  myHoldingsCodes,
  isMyHoldingsChecked,
  allowAccountChangesPermission,
  isTestAccount,
  isTestProduct,
  isTradingEnabled,
  filterOutDelivery,
  displayBasicAccountOptionsPermission,
  setIsModalOpen,
  isBasicAccount,
  isErrAccount,
  isIRAAccount,
  segregatedCaption,
) {
  let locations = productLocations;

  if (filterOutDelivery) {
    locations = productLocations.filter(
      (item) => item.LocationType !== LOCATION_TYPE_DELIVERY,
    );
  }
  const shouldCheckSymbolCodes = side === 'sell' && !isEmpty(myHoldingsCodes);
  if (shouldCheckSymbolCodes) {
    const segregatedHoldings = myHoldingsCodes?.filter(
      (holdingCode) => holdingCode.IsSegregated,
    );
    const nonSegregatedHoldings = myHoldingsCodes?.filter(
      (holdingCode) => !holdingCode.IsSegregated,
    );
    if (isMyHoldingsChecked) {
      const filterLocations = locations.filter((location) =>
        myHoldingsCodes.some(
          (holdingCode) => holdingCode.SymbolCode === location.SymbolCode,
        ),
      );
      const newLocationsArrey = filterLocations.reduce((acc, cur) => {
        if (
          segregatedHoldings?.some(
            (holding) => holding.SymbolCode === cur.SymbolCode,
          ) &&
          nonSegregatedHoldings?.some(
            (holding) => holding.SymbolCode === cur.SymbolCode,
          )
        )
          return acc.concat([cur, { ...cur, IsSegregated: true }]);
        if (
          segregatedHoldings?.some(
            (holding) => holding.SymbolCode === cur.SymbolCode,
          ) &&
          !nonSegregatedHoldings?.some(
            (holding) => holding.SymbolCode === cur.SymbolCode,
          )
        )
          return acc.concat([{ ...cur, IsSegregated: true }]);
        return acc.concat([cur]);
      }, []);
      locations = newLocationsArrey;
    } else {
      const newLocationsArrey = locations.reduce((acc, cur) => {
        if (
          segregatedHoldings?.some(
            (holding) => holding.SymbolCode === cur.SymbolCode,
          )
        )
          return acc.concat([
            {
              ...cur,
              hideSellButton: !nonSegregatedHoldings?.some(
                (holding) => holding.SymbolCode === cur.SymbolCode,
              ),
            },
            { ...cur, IsSegregated: true },
          ]);
        return acc.concat([cur]);
      }, []);
      locations = newLocationsArrey;
    }
  }
  return locations.map((location, i) => {
    const hideSellButton =
      (shouldCheckSymbolCodes &&
        !myHoldingsCodes.some(
          (holdingCode) => holdingCode.SymbolCode === location.SymbolCode,
        )) ||
      (!isMyHoldingsChecked && isEmpty(myHoldingsCodes) && side === 'sell') ||
      (isTestAccount && location.LocationType === 12) ||
      (isTestAccount && !location.IsTestProduct) ||
      location.hideSellButton ||
      !isTradingEnabled;

    const actions = [
      {
        id: `${location.id}-price-tiers-action`,
        label: i18next.t('product.priceTiersBtn'),
        action: () => priceTiersClickHandler(product, location),
        variant: 'primaryOutlined',
        size: 'sm',
        'data-cy': 'button-product-vault-price-tiers',
      },
      {
        id: `${location.id}-buy-action`,
        label:
          side === 'buy'
            ? i18next.t('product.buyBtn')
            : i18next.t('product.sellBtn'),
        action: () =>
          location.LocationType !== 12 && isBasicAccount
            ? setIsModalOpen(true)
            : selectProduct(product, location),
        variant: 'primary',
        size: 'sm',
        disabled:
          (!allowAccountChangesPermission &&
            !displayBasicAccountOptionsPermission) ||
          (location.LocationType !== 12 &&
            displayBasicAccountOptionsPermission &&
            !isBasicAccount) ||
          (side === 'sell' && displayBasicAccountOptionsPermission) ||
          isErrAccount ||
          (side === 'sell' && product.IsSegregated && isIRAAccount),
        hidden: hideSellButton,
        lockStyle: location.LocationType !== 12 && isBasicAccount,
        'data-cy':
          side === 'buy' ? 'button-product-vault-buy' : 'button-sell-product',
      },
    ].filter(Boolean);

    return {
      id: `${location.ProductCode}-${i}`,
      ...location,
      content: (
        <ProductCardTableRow
          vault={handleLocationText(location, segregatedCaption)}
          removePricePerUnit={
            product.ProductType === PRODUCT_TYPE_FRACTIONAL &&
            location.LocationType !== 13
          }
          removePricePerOz={!location?.ShowPerOz}
          perUnit={formatMoneyNumeral(location.PricePerUnit)}
          perOz={formatMoneyNumeral(location.PricePerOz)}
        />
      ),
      actions,
    };
  });
}

export function formatLocationsHeaderData(showHeaderPerOz, showHeaderPerUnit) {
  return [
    { id: 1, content: i18next.t('product.table.vaultLabel') },
    {
      id: 2,
      content: showHeaderPerUnit
        ? i18next.t('product.table.pricePerUnitLabel')
        : '',
    },
    {
      id: 3,
      content: showHeaderPerOz
        ? i18next.t('product.table.pricePerOzLabel')
        : '',
    },
    { id: 4, content: '' },
  ];
}
