import * as Yup from 'yup';
import i18next from '../i18n';
import { formatMoneyNumeral } from '../util/helpers/numeralHelpers';

export const checkWithdrawalValidationSchema = (maxAmount) =>
  Yup.object().shape({
    address: Yup.object().shape({
      value: Yup.string().required(
        i18next.t('validationSchema.general.addressRequired'),
      ),
    }),
    amount: Yup.number()
      .required(i18next.t('validationSchema.general.amountRequired'))
      .min(
        0.01,
        i18next.t('validationSchema.withdrawal.withdrawalMinAmount', {
          amount: formatMoneyNumeral(0.01),
        }),
      )
      .max(maxAmount, i18next.t('validationSchema.general.insufficientFunds')),
  });
