import React, { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import isEmpty from 'lodash.isempty';
import { uuid } from 'uuidv4';
import {
  productLocationTypes,
  PRODUCT_TYPE_FRACTIONAL,
  productTypesArray,
} from '../../util/enum/api/productTypes';
import { parseEnumType } from '../../util/helpers/enumMappers';
import { separateByUppercase } from '../../util/helpers/stringHelpers';
import { formatMoneyNumeral } from '../../util/helpers/numeralHelpers';
import { setWizardContent } from '../../util/helpers/wizardHelpers';
import { useSessionStorageState } from '../../util/hooks/useSessionStorageState';
import DetailRow from './DetailRow';
import { uFlexColumn } from '../../assets/styles/utility';
import { variables } from '../../assets/styles/variables';
import themeColors from '../../assets/styles/themeColors';
import { selectSettings } from '../../store/selectors/settingsSelectors';

const DetailTableElement = styled.div`
  ${uFlexColumn};
  box-shadow: ${variables.shadow.boxShadow};

  > :nth-child(even) {
    background-color: ${themeColors.colorTableEvenRowBackground});
  }
`;

const DetailTable = ({ product, isUtmaAccount }) => {
  const { t } = useTranslation();
  const [valuation, setValiuation] = useSessionStorageState('Valuation', 0);
  const settings = useSelector(selectSettings);
  const [tableData, setTableData] = useState([]);
  const isProductFractional = useMemo(
    () =>
      parseEnumType(productTypesArray, product.ProductType) ===
      PRODUCT_TYPE_FRACTIONAL,
    [product],
  );

  useEffect(() => {
    const valuationCalculated = product.Quantity * product.SellPrice;

    if (product.SellPrice > 0) {
      setValiuation(valuationCalculated);
      setWizardContent('Valuation', valuationCalculated);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.Quantity, product.SellPrice]);

  useEffect(() => {
    if (!settings || isEmpty(settings) || !settings?.BrokerCode) {
      return;
    }

    const dataForTable = [
      {
        label: t('product.detailsTable.location'),
        value: separateByUppercase(
          parseEnumType(productLocationTypes, LocationType),
        ),
      },
      {
        label: t('product.detailsTable.quantity'),
        value: Quantity,
      },
      {
        label: t('product.detailsTable.unitsAvailableSale'),
        value: UnitsAvailableForSell,
      },
      {
        ...getDeliveryOrFractionalValues(),
      },
      {
        label: settings.IsLockedPricingActive
          ? t('product.detailsTable.pricePerUnit')
          : t('product.detailsTable.estimatedPrice'),
        value: formatMoneyNumeral(EstimatedPrice),
      },
      {
        label: t('product.detailsTable.valuation'),
        value: valuation ? formatMoneyNumeral(valuation) : '-',
      },
    ];
    setTableData(dataForTable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  if (isEmpty(product)) {
    return null;
  }

  const {
    LocationType,
    Quantity,
    UnitsAvailableForSell,
    UnitsAvailableForConversion,
    UnitsAvailableForDelivery,
    EstimatedPrice,
  } = product;

  const getDeliveryOrFractionalValues = () => {
    if (isUtmaAccount) {
      return {};
    }

    if (isProductFractional) {
      return {
        label: t('product.detailsTable.unitsAvailableConversion'),
        value: UnitsAvailableForConversion || 0,
      };
    }

    return {
      label: t('product.detailsTable.unitsAvailableDelivery'),
      value: UnitsAvailableForDelivery,
    };
  };

  return (
    <DetailTableElement>
      {tableData.map(({ label, value }) => {
        if (label || value) {
          return <DetailRow key={uuid()} label={label} value={value} />;
        }

        return null;
      })}
    </DetailTableElement>
  );
};

DetailTable.propTypes = {
  product: PropTypes.shape({
    LocationType: PropTypes.number,
    Quantity: PropTypes.number,
    UnitsAvailableForSell: PropTypes.number,
    UnitsAvailableForConversion: PropTypes.number,
    UnitsAvailableForDelivery: PropTypes.number,
    EstimatedPrice: PropTypes.number,
    Valuation: PropTypes.number,
    ProductType: PropTypes.number,
    SellPrice: PropTypes.number,
  }),
  isUtmaAccount: PropTypes.bool,
};

export default DetailTable;
