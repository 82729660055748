import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash.isempty';
import groupBy from 'lodash.groupby';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import IconButton from '../../components/IconButton/IconButton';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import { ReactComponent as ChevronRight } from '../../assets/images/svg/chevron-right.svg';
import { ReactComponent as ChevronLeft } from '../../assets/images/svg/chevron-left.svg';
import StatementsTable from '../../components/StatementsTable/StatementsTable';
import Section from '../../components/Section/Section';
import { getDocumentsData } from '../../store/selectors/documentsSelectors';
import { getCurrentAccount } from '../../store/selectors/accountSelectors';
import { fetchDocumentsByYear } from '../../store/actions/documents/documentActions';
import i18next from '../../i18n';
import useGtmHook from '../../util/hooks/useGtmHook';
import { selectAuthUser } from '../../store/selectors/userSelectors';
import SectionLoader from '../../components/Loader/SectionLoader';
import { usePopUpInstructions } from '../../util/hooks/usePopUpInstructions';
import NagDashboardBanner from '../../components/NagDashboard/NagDashboardBanner';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import StorageFeeBanner from '../../components/StorageFee/StorageFeeBanner';
import { fetchStorageFeeAmount } from '../../store/actions/storageFee/storageFeeActions';
import {
  IS_DEPOSIT_MODAL_OPEN,
  SUPPORT_MODAL_OPEN,
} from '../../constants/sessionStorage';
import { useSessionStorageState } from '../../util/hooks/useSessionStorageState';
import { IRA_ENTRUST_TYPE, IRA_EQUITY_TYPE } from '../../util/constants';
import IraDepositWizard from '../../components/Modals/DepositWizard/IraDepositWizard';
import StandardDepositWizard from '../../components/Modals/Deposit/StandardDepositWizard/StandardDepositWizard';
import MilleniumDepositWizard from '../../components/Modals/DepositWizard/MilleniumDepositWizard';
import useCheckPermissions from '../../util/hooks/useCheckPermissions';
import { parseEnumType } from '../../util/helpers/enumMappers';
import {
  accountTypeKeys,
  accountTypes,
} from '../../util/enum/api/accountTypes';
import PayStorageFeeByCardModal from '../../components/Modals/PayStorageFeeByCard/PayStorageFeeByCardModal';
import BasicAccountRestristionsModal from '../../components/Modals/BasicAccountRestrictionsModal/BasicAccountRestrictionsModal';
import RestrictedPermissionModal from '../../components/Modals/RestrictedPermissionModal';
import SupportModal from '../../components/Modals/SupportModal/SupportModal';
import { selectSettings } from '../../store/selectors/settingsSelectors';
import ChangeAutoPaySettingsModal from '../../components/Modals/ChangeAutoPaySettings/ChangeAutoPaySettingsModal';
import { ACCOUNT_DATA_LOADED } from '../../constants/gtmEvents';

const STATEMENTS = {
  STATEMENT: 1,
  INVOICE: 2,
  TAX: 3,
};

export const StatementsContainer = styled.div`
  padding: ${pxToRem(24)} 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: ${pxToRem(54)};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: ${pxToRemMd(26)} ${pxToRemMd(16)};
    grid-template-columns: 1fr;
    grid-row-gap: ${pxToRemMd(40)};

    > :nth-child(3) {
      order: 1;
    }

    > :nth-child(1) {
      order: 2;
    }

    > :nth-child(2) {
      order: 3;
    }
  }
`;

export const StatementsTimeline = styled.div`
  display: flex;
  align-items: center;
`;

export const StatementsTimelineTimestamp = styled.p`
  margin: 0 ${pxToRem(20)};
  font-size: ${pxToRem(24)};
  line-height: 1.35;
  font-weight: 600;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    margin: 0 ${pxToRemMd(20)};
    font-size: ${pxToRemMd(24)};
  }
`;

export const StatementsIcon = styled(IconButton)`
  svg {
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    svg {
      width: ${pxToRemMd(16)};
      height: ${pxToRemMd(16)};
    }
  }
`;

export const StatementsNote = styled.div`
  p {
    margin-bottom: ${pxToRem(16)};
  }

  ul > li {
    margin-bottom: ${pxToRem(8)};
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: 0 ${pxToRemMd(16)};
  }
`;

const StatementsPage = () => {
  const { gtmScreenView, gtmDialogScreenView, appGtmEvent } = useGtmHook();
  const dispatch = useDispatch();
  const data = useSelector(getDocumentsData);
  const account = useSelector(getCurrentAccount);
  const user = useSelector(selectAuthUser);
  const settings = useSelector(selectSettings);
  const {
    displayStandardOptionsPermission,
    displayIRAEquityOptionsPermission,
    displayIRAEntrustOptionsPermission,
    displayIRAMilleniumOptionsPermission,
    displayBasicAccountOptionsPermission,
  } = useCheckPermissions();

  const isBasicAccount =
    parseEnumType(accountTypeKeys, account.AccountType) === accountTypes.Basic;

  const [
    isDepositRestrictionsModalOpen,
    setIsDepositRestrictionsModalOpen,
  ] = useSessionStorageState(IS_DEPOSIT_MODAL_OPEN, false);

  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();
  const [year, setYear] = useState(currentYear);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [storageFeeAmount, setStorageFeeAmount] = useState();
  const [isOpenPayStorageFeeByCard, setIsOpenPayStorageFeeByCard] = useState(
    false,
  );
  const [isAutoPaySettingsModalOpen, setIsAutoPaySettingsModalOpen] = useState(
    false,
  );

  const [isDepositModalOpen, setIsDepositModalOpen] = useSessionStorageState(
    IS_DEPOSIT_MODAL_OPEN,
    false,
  );
  const [isSupportModalOpen, setIsSupportModalOpen] = useSessionStorageState(
    SUPPORT_MODAL_OPEN,
    false,
  );

  const taxYear = year - 1;

  useEffect(() => {
    if (account.AccountUid && settings?.IsCreditCardActive) {
      dispatch(
        fetchStorageFeeAmount({
          accountUid: account.AccountUid,
          setStorageFeeAmount,
        }),
      );
    }
  }, [dispatch, account, settings.IsCreditCardActive]);

  useEffect(() => {
    if (!isEmpty(account) && account.AccountUid) {
      dispatch(
        fetchDocumentsByYear({
          accountUid: account.AccountUid,
          year,
        }),
      );
    }
  }, [dispatch, account, year, user]);

  useEffect(() => {
    gtmScreenView({
      path: window.location.pathname,
      title: 'Statements',
    });
    appGtmEvent(ACCOUNT_DATA_LOADED);
  }, []); // eslint-disable-line

  const group = groupBy(data, 'DocumentType');
  const statements = group[STATEMENTS.STATEMENT];
  const Invoices = group[STATEMENTS.INVOICE];
  const taxes = group[STATEMENTS.TAX];

  const TimelinePicker = () => (
    <StatementsTimeline>
      <StatementsIcon
        onClick={() => {
          setYear(year - 1);
        }}
      >
        <ChevronLeft />
      </StatementsIcon>
      <StatementsTimelineTimestamp>{year}</StatementsTimelineTimestamp>
      <StatementsIcon
        onClick={() => {
          if (year < currentYear) {
            setYear(year + 1);
          }
        }}
      >
        <ChevronRight />
      </StatementsIcon>
    </StatementsTimeline>
  );

  return (
    <div data-cy="container-statements-page">
      <NagDashboardBanner />
      <NavigationBar
        mainPage={i18next.t('header.navDropdownAccount')}
        subPage={i18next.t('header.statements')}
        cta={<TimelinePicker />}
      />
      {storageFeeAmount > 0 && (
        <StorageFeeBanner
          amount={storageFeeAmount}
          setIsDepositModalOpen={setIsDepositModalOpen}
          gtmDialogScreenView={gtmDialogScreenView}
          isDepositAvailable={
            !(displayBasicAccountOptionsPermission && !isBasicAccount)
          }
          setIsOpenPayStorageFeeByCard={setIsOpenPayStorageFeeByCard}
          setIsDepositRestrictionsModalOpen={setIsDepositRestrictionsModalOpen}
          isBasicAccount={isBasicAccount}
        />
      )}
      <Section>
        <SectionLoader isLoading={isDownloadLoading}>
          <StatementsContainer>
            <StatementsTable
              data={statements}
              title={i18next.t('statements.statements')}
              year={year}
              accountUid={account.AccountUid}
              type="statements"
              setIsDownloadLoading={setIsDownloadLoading}
            />
            <StatementsTable
              data={Invoices}
              title={i18next.t('statements.Invoices')}
              year={year}
              accountUid={account.AccountUid}
              type="Invoices"
              setIsDownloadLoading={setIsDownloadLoading}
            />
            <StatementsTable
              data={taxes}
              title={i18next.t('statements.taxes')}
              year={taxYear}
              accountUid={account.AccountUid}
              type="tax"
              setIsDownloadLoading={setIsDownloadLoading}
            />
          </StatementsContainer>
        </SectionLoader>
        <StatementsNote>
          <p>{i18next.t('statements.note')}</p>
          {usePopUpInstructions()}
        </StatementsNote>
      </Section>

      {displayStandardOptionsPermission && isDepositModalOpen && (
        <StandardDepositWizard
          isModalOpen={isDepositModalOpen}
          handleClose={() => {
            setIsDepositModalOpen(false);
          }}
        />
      )}

      {displayIRAEquityOptionsPermission && isDepositModalOpen && (
        <IraDepositWizard
          isModalOpen={isDepositModalOpen}
          handleClose={() => setIsDepositModalOpen(false)}
          type={IRA_EQUITY_TYPE}
        />
      )}

      {displayIRAEntrustOptionsPermission && isDepositModalOpen && (
        <IraDepositWizard
          isModalOpen={isDepositModalOpen}
          handleClose={() => setIsDepositModalOpen(false)}
          type={IRA_ENTRUST_TYPE}
        />
      )}

      {displayIRAMilleniumOptionsPermission && isDepositModalOpen && (
        <MilleniumDepositWizard
          isModalOpen={isDepositModalOpen}
          handleClose={() => setIsDepositModalOpen(false)}
        />
      )}
      {storageFeeAmount > 0 && isBasicAccount && (
        <BasicAccountRestristionsModal
          isOpen={isDepositRestrictionsModalOpen}
          close={() => setIsDepositRestrictionsModalOpen(false)}
          title={t('storageFee.payStorageFeeByCardModal.label')}
          description={t('basicAccountRestrictions.deposit.description')}
        />
      )}
      {storageFeeAmount > 0 && !isBasicAccount && (
        <RestrictedPermissionModal
          isOpen={isDepositRestrictionsModalOpen}
          handleClose={() => setIsDepositRestrictionsModalOpen(false)}
          title={t('storageFee.payStorageFeeByCardModal.label')}
          setIsSupportModalOpen={setIsSupportModalOpen}
        />
      )}
      <SupportModal isOpen={isSupportModalOpen} close={setIsSupportModalOpen} />
      <ChangeAutoPaySettingsModal
        isOpen={isAutoPaySettingsModalOpen}
        handleClose={() => setIsAutoPaySettingsModalOpen(false)}
        storageFeeAmount={storageFeeAmount}
        setIsOpenPayStorageFeeByCard={setIsOpenPayStorageFeeByCard}
        isOpenPayStorageFeeByCard={isOpenPayStorageFeeByCard}
      />
      <PayStorageFeeByCardModal
        isOpen={isOpenPayStorageFeeByCard}
        handleClose={() => setIsOpenPayStorageFeeByCard(false)}
        storageFeeAmount={storageFeeAmount}
        setStorageFeeAmount={setStorageFeeAmount}
        isAutoPaySettingsModalOpen={isAutoPaySettingsModalOpen}
        setIsAutoPaySettingsModalOpen={setIsAutoPaySettingsModalOpen}
        setIsOpenPayStorageFeeByCard={setIsOpenPayStorageFeeByCard}
        isOpenPayStorageFeeByCard={isOpenPayStorageFeeByCard}
      />
    </div>
  );
};

export default StatementsPage;
