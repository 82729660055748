import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import NagDashboardTableRow from './NagDashboardTableRow';
import {
  accountStatusTypesArray,
  BASIC_ACCOUNT_STEP,
  nagDashboardStepsArray,
  UPGRADE_STATUS_TYPES,
  VERIFIED_NON_POST_PAID,
  VERIFIED_POST_PAID,
  VERIFY_ACCOUNT_STEP,
} from '../../constants/nagDashboardContants';
import { parseEnumType } from '../../util/helpers/enumMappers';
import {
  getCurrentAccountCode,
  getCurrentAccountUid,
  selectNagDashboard,
} from '../../store/selectors/accountSelectors';
import { selectSettings } from '../../store/selectors/settingsSelectors';
import { JWT_TOKEN } from '../../constants/localStorage';
import { authScopeStringGetHelper } from '../../util/helpers/authScopeHelpers';
import { BUY_PAGE, FINANCIAL_BALANCES_PAGE } from '../../constants/pages';

const nonVerifiedAccountVerifiedStep = {
  title: 'Verify your account',
  description:
    'Please check your email for details. One or more supporting documents have been requested to help us approve your application.',
  status: 'ActionRequired',
};

const nonVerifiedAccountPendingStep = {
  title: 'Verify your account',
  description:
    'We are reviewing your account for verification. Any additional information we may need for this process will be requested via email.',
  status: 'Pending',
};

const NagDashboardVerifyAccountStep = ({ isShortFlow, activeStep }) => {
  const { RegistrationUrlV2 } = useSelector(selectSettings);
  const token = authScopeStringGetHelper(JWT_TOKEN);
  const accountUid = useSelector(getCurrentAccountUid);
  const accountCode = useSelector(getCurrentAccountCode);
  const nagDashboard = useSelector(selectNagDashboard);
  const history = useHistory();
  const registrationApi = RegistrationUrlV2;
  const goToRegistrationPage = () => {
    window.location.replace(
      `${registrationApi}/choose-application?accountCode=${accountCode}&accountUid=${accountUid}&token=${token}`,
    );
  };

  const isVerifiedAccount =
    parseEnumType(nagDashboardStepsArray, activeStep) === VERIFIED_POST_PAID ||
    parseEnumType(nagDashboardStepsArray, activeStep) ===
      VERIFIED_NON_POST_PAID;

  const isPendingAccount =
    parseEnumType(accountStatusTypesArray, nagDashboard?.AccountStatusType) ===
    'Pending';

  const isUpgradeProgress =
    nagDashboard.UpgradeStatusType === UPGRADE_STATUS_TYPES.InProgress;

  const isUpgraded =
    nagDashboard.UpgradeStatusType === UPGRADE_STATUS_TYPES.Upgraded;

  const isPending = isPendingAccount || (isPendingAccount && !isUpgraded);

  const getBasicAccountBannerText = () => {
    switch (history.location.pathname) {
      case FINANCIAL_BALANCES_PAGE:
        return {
          title: 'Verify account to manage balance',
          description:
            'Your account details are not confirmed; for security you cannot deposit or withdraw from your cash balance.  Please verify your information to unlock all features.',
          status: 'GetStarted',
        };
      case BUY_PAGE:
        return {
          title: 'Verify account to use storage',
          description:
            'You have a basic account, which cannot take advantage of our secure, independent storage.  Please verify your information to unlock all features.',
          status: 'GetStarted',
        };
      default:
        return {
          title: 'Verify your account',
          description:
            'You have a basic account and can only order for delivery. Verify your account to unlock all features like vault storage.',
          status: 'GetStarted',
        };
    }
  };

  const basicAccountVerifiedStepInfo = getBasicAccountBannerText();

  const getDashboardVerifiedAccount = () => {
    switch (parseEnumType(nagDashboardStepsArray, activeStep)) {
      case BASIC_ACCOUNT_STEP:
        return {
          ...basicAccountVerifiedStepInfo,
          buttonText: isUpgradeProgress ? 'Continue' : 'Get Started',
          action: () => {
            goToRegistrationPage();
          },
        };
      case VERIFY_ACCOUNT_STEP:
        return isPending
          ? nonVerifiedAccountPendingStep
          : nonVerifiedAccountVerifiedStep;

      default:
    }
  };

  const dashboardData = getDashboardVerifiedAccount();

  if (!isVerifiedAccount) {
    return (
      <NagDashboardTableRow
        title={dashboardData.title}
        description={dashboardData.description}
        action={dashboardData.action}
        buttonText={dashboardData.buttonText}
        status={dashboardData.status}
        isActive={
          parseEnumType(nagDashboardStepsArray, activeStep) ===
            VERIFY_ACCOUNT_STEP && !isPending
        }
        isShortFlow={isShortFlow}
        isPendingVerificationStatus={
          parseEnumType(nagDashboardStepsArray, activeStep) ===
            VERIFY_ACCOUNT_STEP && !isPending
        }
        activeStep={activeStep}
      />
    );
  }
  return <></>;
};

NagDashboardVerifyAccountStep.propTypes = {
  isShortFlow: PropTypes.string,
  activeStep: PropTypes.number,
};

export default NagDashboardVerifyAccountStep;
