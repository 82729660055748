import styled from 'styled-components';

import {
  TableDropdownStyle,
  TableStyle,
} from '../Table/Components/TableComponent';
import { mediaBelow, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import {
  ProductCardDropdown,
  ProductCardContent,
} from '../ProductCard/ProductCard';
import TradingContainer from './TradingContainer';

const SellTradingContainer = styled(TradingContainer)`
  ${ProductCardDropdown} {
    max-height: unset;
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    ${TableStyle} {
      ${(props) =>
        props.isLocationTableMobile &&
        `
        margin-bottom: 0;
      `};
    }

    ${ProductCardContent} {
      padding-bottom: 0;
    }

    ${TableDropdownStyle} {
      padding: 0 0 ${pxToRemMd(16)};
    }
  }
`;

export default SellTradingContainer;
