import i18next from 'i18next';
import * as Yup from 'yup';

export const twoFaPhoneValidationSchema = Yup.object().shape({
  PhoneNumber: Yup.string().required(
    i18next.t('validationSchema.general.phoneNumberRequired'),
  ),
  Type: Yup.string().required(
    i18next.t('validationSchema.general.messageTypeRequired'),
  ),
});

export const twoFaPhoneNumberValidationSchema = Yup.object().shape({
  PhoneNumber: Yup.string().required(
    i18next.t('validationSchema.general.phoneNumberRequired'),
  ),
});
