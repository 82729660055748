import React from 'react';
import styled from 'styled-components';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import ModalButtons from '../Modal/ModalButtons';

export const AuthButtonsElement = styled(ModalButtons)`
  width: 100%;
  margin-top: ${pxToRem(68)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-top: ${pxToRemMd(40)};
  }
`;

const AuthButtons = (props) => <AuthButtonsElement {...props} />;

export default AuthButtons;
