import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from './Checkbox';
import { buttonizeDiv } from '../../util/buttonizeDiv';
import Paragraph from '../Paragraph/Paragraph';
import Anchor from '../Anchor/Anchor';

const AgreementCheckbox = ({ text, onLinkClick, link, ...props }) => (
  <Checkbox {...props} variant="AgreementCheckbox" {...props}>
    <Paragraph fontSize={14}>
      {text && <span>{text}</span>}
      {link && (
        <>
          {' '}
          <Anchor
            text={link}
            {...buttonizeDiv((event) => {
              event.preventDefault();
              onLinkClick(event);
            })}
          />
        </>
      )}
    </Paragraph>
  </Checkbox>
);

AgreementCheckbox.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  onLinkClick: PropTypes.func,
};

export default AgreementCheckbox;
