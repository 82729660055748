import { useSelector } from 'react-redux';
import { selectSettings } from '../../store/selectors/settingsSelectors';

export function useBrokerName() {
  const settings = useSelector(selectSettings);

  const { BrokerName } = settings;

  return BrokerName;
}
