import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { formatMoneyNumeral } from '../../../../../util/helpers/numeralHelpers';
import {
  ADDRESSES_PAGE,
  FINANCIAL_BALANCES_PAGE,
} from '../../../../../constants/pages';
import CurrencyField from '../../../../InputFields/CurrencyField';
import SelectField from '../../../../InputFields/SelectField';
import { deleteWizardKey } from '../../../../../util/helpers/wizardHelpers';
import {
  PREFUNDED_CHECK_WITHDRAWAL_WIZARD,
  WITHDRAWAL_TYPE,
} from '../../../../../constants/sessionStorage';
import { FETCH_ACCOUNT_ADDRESSES_LOADING } from '../../../../../store/actions/account/accountActionConstants';
import { selectIsLoadingByActionType } from '../../../../../store/selectors/loadingSelectors';
import SectionLoader from '../../../../Loader/SectionLoader';
import { checkWithdrawalValidationSchema } from '../../../../../validation/checkWithdrawalValidationSchema';
import Paragraph from '../../../../Paragraph/Paragraph';
import Label from '../../../../Notes/Label';
import ModalBody from '../../../../Modal/ModalBody';
import NavigationLink from '../../../../Link/Link';
import ModalButtons from '../../../../Modal/ModalButtons';
import FormContainer from '../../../../FormContainer/FormContainer';

const CheckInformationForm = ({
  availableMoney,
  addressOptions,
  handleClose,
  setValue,
  handleNext,
  goBack,
  value,
  setWithdrawalType,
}) => {
  const { t } = useTranslation();
  const isLoading = useSelector(
    selectIsLoadingByActionType(FETCH_ACCOUNT_ADDRESSES_LOADING),
  );

  const onFormSubmit = (values) => {
    setValue(values);
    handleNext();
  };

  const onGoBack = () => {
    goBack();
    setWithdrawalType(null);
    deleteWizardKey(WITHDRAWAL_TYPE);
    deleteWizardKey(PREFUNDED_CHECK_WITHDRAWAL_WIZARD);
  };

  return (
    <SectionLoader isLoading={isLoading}>
      <ModalBody>
        <Label text={t('IRAWithdraw.amountForm.amountAvailable')} />
        <Paragraph fontSize={18} marginBottom={8}>
          {formatMoneyNumeral(availableMoney)}
        </Paragraph>
        <NavigationLink to={FINANCIAL_BALANCES_PAGE} marginBottom={24}>
          {t('IRAWithdraw.amountForm.dontSeeFunds')}
        </NavigationLink>
        <Formik
          initialValues={value}
          onSubmit={onFormSubmit}
          validationSchema={checkWithdrawalValidationSchema(availableMoney)}
          initialTouched={{ address: true, amount: true }}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form>
              <FormContainer>
                <SelectField
                  name="address"
                  label={t('product.requestDelivery.deliverCheckTo')}
                  options={addressOptions}
                  link={
                    <NavigationLink to={ADDRESSES_PAGE}>
                      {t('product.requestDelivery.dontSeeAddress')}
                    </NavigationLink>
                  }
                />
                <CurrencyField
                  defaultValue={values.amount}
                  label={t('IRAWithdraw.amountForm.amountLabel')}
                  name="amount"
                  placeholder={formatMoneyNumeral(0)}
                  onChange={(value) => setFieldValue('amount', value)}
                />
              </FormContainer>
              <ModalButtons
                isHorizontal
                marginTop
                primaryButtonProps={{
                  size: 'lg',
                  type: 'submit',
                  label: t('common.next'),
                }}
                secondaryButtonProps={{
                  size: 'lg',
                  onClick: goBack ? onGoBack : handleClose,
                  label: goBack ? t('common.back') : t('common.cancel'),
                }}
              />
            </Form>
          )}
        </Formik>
      </ModalBody>
    </SectionLoader>
  );
};

CheckInformationForm.propTypes = {
  handleClose: PropTypes.func,
  addressOptions: PropTypes.arrayOf(PropTypes.shape({})),
  availableMoney: PropTypes.number,
  setValue: PropTypes.func,
  value: PropTypes.shape({}),
  handleNext: PropTypes.func,
  goBack: PropTypes.func,
  setWithdrawalType: PropTypes.func,
};

export default CheckInformationForm;
