import React from 'react';
// import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
// import Paragraph from '../Paragraph/Paragraph';
import RoundedRadio from '../InputFields/RoundedRadio';
import TypeList from '../TypeList/TypeList';
import SinglePaymentMethod from './SinglePaymentMethod';
import { PAYMENT_METHOD_TYPES } from '../../util/enum/api/paymentTypes';

const ChoosePaymentMethod = ({
  paymentMethods,
  values,
  errorMessage,
  setErrorMessage,
  setSelectedPaymentMethod,
  clientExecutionPrice,
  isAccountBalanceDisabled,
  totalAmountWithCreditCard,
  totalAmountWithoutCreditCard,
  // setFieldValue,
}) => {
  // const { t } = useTranslation();

  const handleChangeValue = (paymentMethodUid) =>
    setSelectedPaymentMethod(
      paymentMethods.find(
        (method) => method.PaymentMethodUid === paymentMethodUid,
      ),
    );

  return (
    <>
      {!isEmpty(paymentMethods) && (
        <>
          <TypeList gridRowGap={9}>
            {paymentMethods.map((method) => (
              <Field
                key={method.PaymentMethodUid}
                id={method.PaymentMethodUid}
                type="radio"
                name="paymentMethodUid"
                component={RoundedRadio}
                value={method.PaymentMethodUid}
                selected={values.paymentMethodUid === method.PaymentMethodUid}
                style={{ padding: '6px 16px' }}
                // disabled: BankAccountSetupType = 2; enabled: BankAccountSetupType = 3;
                disabled={
                  (method?.PaymentMethodType === PAYMENT_METHOD_TYPES.ACH &&
                    method?.BankAccountSetupType === 2) ||
                  (method?.PaymentMethodType ===
                    PAYMENT_METHOD_TYPES.CREDIT_CARD &&
                    method?.CreditCardIsExpired) ||
                  (method?.PaymentMethodType === PAYMENT_METHOD_TYPES.ACH &&
                    totalAmountWithoutCreditCard > method?.MaximumLimit) ||
                  (method?.PaymentMethodType ===
                    PAYMENT_METHOD_TYPES.CREDIT_CARD &&
                    totalAmountWithCreditCard > method?.MaximumLimit) ||
                  (method?.PaymentMethodType ===
                    PAYMENT_METHOD_TYPES.ACCOUNT_BALANCE &&
                    isAccountBalanceDisabled)
                }
                onChange={(e) => {
                  handleChangeValue(e.target.value);
                  if (errorMessage) {
                    setErrorMessage(!errorMessage);
                  }
                }}
              >
                <SinglePaymentMethod
                  method={method}
                  disabled={
                    method?.PaymentMethodType === PAYMENT_METHOD_TYPES.ACH &&
                    method?.BankAccountSetupType === 2
                  }
                  clientExecutionPrice={clientExecutionPrice}
                  isAccountBalanceDisabled={isAccountBalanceDisabled}
                  totalAmountWithoutCreditCard={totalAmountWithoutCreditCard}
                  totalAmountWithCreditCard={totalAmountWithCreditCard}
                />
              </Field>
            ))}
          </TypeList>
        </>
      )}
    </>
  );
};

ChoosePaymentMethod.propTypes = {
  paymentMethods: PropTypes.arrayOf({}),
  values: PropTypes.shape({
    paymentMethodUid: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({}),
    ]),
  }),
  setErrorMessage: PropTypes.func,
  errorMessage: PropTypes.bool,
  setSelectedPaymentMethod: PropTypes.func,
  clientExecutionPrice: PropTypes.number,
  isAccountBalanceDisabled: PropTypes.bool,
  totalAmountWithCreditCard: PropTypes.number,
  totalAmountWithoutCreditCard: PropTypes.number,
};

export default ChoosePaymentMethod;
