import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

import Modal from '../../Modal/Modal';
import StepByStep from '../../StepByStep';
import RemoveCreditCard from './RemoveCreditCard';
import Status from '../Status';
import { selectIsSuccessfulDelete } from '../../../store/selectors/creditCardSelectors';
import { selectIsLoadingByActionType } from '../../../store/selectors/loadingSelectors';
import { CREDIT_CARD_DELETE_LOADING } from '../../../store/actions/creditCard/creditCardActionConstants';
import { IS_CARD_USED_FOR_AUTO_PAY } from '../../../constants/sessionStorage';
import { storeInSessionStorage } from '../../../util/helpers/sessionStorageHelper';

const steps = [RemoveCreditCard, Status];

const RemoveCreditCardModal = ({
  isOpen,
  onClose,
  isDefaultForStorage,
  setIsAutoPaySettingsModalOpen,
  removeCreditCardModalOpen,
  setRemoveCreditCardModalOpen,
  creditCard,
}) => {
  const isDeleteSuccessful = useSelector(selectIsSuccessfulDelete);
  const [errorMessage, setErrorMessage] = useState('');
  const isLoading = useSelector(
    selectIsLoadingByActionType(CREDIT_CARD_DELETE_LOADING),
  );

  useEffect(() => {
    if (isDefaultForStorage) {
      storeInSessionStorage(IS_CARD_USED_FOR_AUTO_PAY, true);
    }
  }, [isDefaultForStorage]);

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      title={i18next.t('creditCards.removeActionModalTitle')}
      close={onClose}
      isOpen={isOpen}
      size="sm"
      isLoading={isLoading}
    >
      <StepByStep saveStepDisabled>
        {({ goStepForward, goStepBack }) =>
          steps.map((step) =>
            React.createElement(step, {
              handleBack: goStepBack,
              handleNext: goStepForward,
              handleClose,
              hasError: !isDeleteSuccessful,
              text: isDeleteSuccessful
                ? i18next.t('creditCards.confirmationScreenRemoveMessage')
                : errorMessage,

              onButtonClick: handleClose,
              backButtonText: i18next.t('common.cancel'),
              setErrorMessage,
              isDefaultForStorage,
              isAutoPayOn: false,
              setIsAutoPaySettingsModalOpen,
              removeCreditCardModalOpen,
              setRemoveCreditCardModalOpen,
              creditCard,
            }),
          )
        }
      </StepByStep>
    </Modal>
  );
};

RemoveCreditCardModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  isDefaultForStorage: PropTypes.bool,
  setIsAutoPaySettingsModalOpen: PropTypes.func,
  removeCreditCardModalOpen: PropTypes.bool,
  setRemoveCreditCardModalOpen: PropTypes.func,
  creditCard: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

export default RemoveCreditCardModal;
