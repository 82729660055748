import { variables } from '../variables';
import MontserratRegular from '../../fonts/LegacyPM/Montserrat-Regular.woff2';
import MontserratItalic from '../../fonts/LegacyPM/Montserrat-Italic.woff2';
import Merriweather from '../../fonts/LegacyPM/Merriweather.woff2';

export const legacyPMColors = {
  colorPrimary: '#870012',
  colorPrimaryLight: '#DDD288',
  colorPrimaryDark: '#A47E25',
  colorWhite: '#ffffff',
  colorBlack: '#000000',
  colorRed: '#f2545b',
  colorGrey: '#f5f5f5',

  // Backgrounds
  colorBackgroundPrimary: '#ffffff',
  colorBackgroundSecondary: '#fafafa',
  colorWarningnBackground: '#FFECB5',

  // Typography Colors
  // Text
  colorTextPrimary: '#231F20',
  colorTextSecondary: '#231F20',
  colorTextContrast: '#231F20',
  colorTextHeader: '#231F20',
  colorTextLink: '#ffffff',

  colorSuccess: '#00876A',
  colorError: '#f2545b',
  colorWarning: '#A47E25',
  colorIncomplete: '#212528',

  // Links
  colorLinkPrimary: '#A47E25',
  colorLinkRegistration: '#003246', // semiDark'

  // Button Colors
  colorButtonPrimary: '#7B0A18',
  colorButtonPrimaryText: '#ffffff',
  colorButtonPrimaryHover: '#54050F',
  colorButtonPrimaryFocused: '#7B0A18',
  colorButtonPrimaryHoverText: '#ffffff',
  colorButtonOutlined: '#ffffff',
  colorButtonOutlinedBorder: '#7B0A18',
  colorButtonOutlinedText: '#7B0A18',
  colorButtonDisabled: 'rgba(123, 10, 24, 0.5)',

  colorButtonDefault: '#ffffff',
  colorButtonDefaultText: '#231F20',
  colorButtonDefaultBorder: '#231F20',
  colorButtonDefaultHover: '#8b8b8b',
  colorButtonAddNewBank: '#ffffff',

  // Input
  colorInputBorder: '#CFCFCF',
  colorInputBackground: '#ffffff',
  colorInputBackgroundDisabled: '#EBF2F2',
  colorInputBorderFocused: '#DCCC79',
  colorInputBorderActive: '#DCCC79',
  colorInputText: '#000000',
  colorInputPlaceholder: '#000000',
  colorInputSelectItemFocused: '#f4f4f4',

  // Label
  colorLabel: '#231F20',

  // Modal
  colorModalBackground: 'rgba(0, 0, 0, 0.3)',

  // Tabs
  colorTabBackground: '#ffffff',
  colorTabText: '#A47E25',
  colorTabSelectedBackground: '#A47E25',
  colorTabSelectedText: '#ffffff',

  // Pills
  colorPillsBackground: 'rgba(164, 126, 37, 0.14)',
  colorPillsText: '#231F20',
  colorPillsBackgroundActive: '#7B0A18',
  colorPillsTextActive: '#ffffff',

  // Filters
  colorFilterItemBackground: '#231F20',
  colorFilterItemText: '#ffffff',
  colorFilterIcon: '#ffffff',

  // Icons
  colorIconPrimary: '#231F20',
  colorIconSecondary: '#ffffff',
  colorLoaderIcon: '#A47E25',

  // Radio Button
  colorRadioButton: '#231F20',
  colorRadioButtonWrapperBorderSelected: '#A47E25',

  // Tooltip
  colorTooltipBackground: '#231F20',
  colorTooltipText: '#ffffff',

  // Password Strength
  colorPasswordStrengthDefault: '#EBF2F2',
  colorPasswordStrengthWeak: '#f2545b',
  colorPasswordStrengthAverage: '#A47E25',
  colorPasswordStrengthGood: '#152D5C',
  colorPasswordStrengthStrong: '#00876A',

  // Progress Bar Colors, Linear Gradient
  colorLinearGradientStart: '#DCCC79',
  colorLinearGradientEnd: '#97752D',
  colorProgressBarDefault: '#F5F5F5',
  colorProgressBarFilled: '#A47E25',

  // Header Colors
  colorMainHeaderBackground: '#ffffff',
  colorSecondaryHeaderBackground: '#ffffff',
  colorHeaderLogoLight: '#ffffff',
  colorHeaderLogoDark: '#231F20',

  // Navigation Colors
  colorNavigationItemHover: '#fafafa',

  // Navigation Dropdown Color
  colorNavigationDropdownBackground: '#ffffff',
  colorNavigationDropdownItemHoverBackground: 'rgba(164, 126, 37, 0.14)',

  // Account Colors
  colorAccountBackground: 'rgba(164, 126, 37, 0.14)',
  colorAccountBorder: '#dde5e7',

  // Footer Colors
  colorFooterBackground: '#1F2123',
  colorFooterText: '#ffffff',

  // Horizontal Break and Borders, Separators
  colorBorderPrimary: '#c9d6db',
  colorBorderSecondary: '#A47E25',

  // Table Colors
  colorTableHeaderBackground: '#f54f5f5',
  colorTableEvenRowBackground: '#fafafa',
  colorTableEvenRowHoverBackground: '#f5f5f5',
  colorTableSelectedBackground: 'rgba(164, 126, 37, 0.14)',
  colorHeadingRowItem: '#F0F5F6',

  // Shippment Status Colors
  colorShippmentSuccess: '#09846b',
  colorShippmentPending: '#A47E25',
  colorShippmentShipped: '#152D5C',
  colorShippmentFailed: '#f2545b',

  // Address Status Colors
  colorVerifiedSuccess: '#09846b',
  colorNotVerified: '#8E8E8E',

  // Card Colors
  colorCardBackground: '#ffffff',
  colorCardSelected: 'rgba(164, 126, 37, 0.14)',

  // Scrollbar Colors
  colorScrollbarBackground: '#c2c5c6',

  // MakeOver Banner
  colorMakeOverBannerBackground: '#f5f5f5',
  colorMakeOverBanner: '#231F20',

  // Pending Banner
  colorPendingBannerBackground: '#f1f5f6',

  // Onboarding
  colorOnboardingBoxBorder: '#f3f3f3',
  colorOnboardingBoxBackground: 'rgba(196, 196, 196, 0.2)',
  colorOnboardingTextPrimary: '#ffffff',

  // Empty Box
  colorEmptyBoxTextPrimary: '#C2C5C6',

  // Graphic
  colorGraphicGridLines: '#ebeff2',

  // Info Banner
  colorInfoBannerBackground: '#231F20',
  colorInfoBannerText: '#ffffff',

  // Auth card
  colorAuthCardSubtitle: '#231F20',

  // Registration
  colorRegistration: '#2B5769',

  // Pie chart
  colorPieOne: '#231F20',
  colorPieTwo: '#870012',
  colorPieThree: '#DDD288',
  colorPieFour: '#808285',
};

export const legacyPMFonts = `
@font-face {
  font-family: ${variables.family.legacyPM};
  src: url(${MontserratRegular}) format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: ${variables.family.legacyPM};
  src: url(${MontserratRegular}) format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: ${variables.family.legacyPM};
  src: url(${MontserratItalic}) format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: ${variables.family.legacyPMHeaders};
  src: url(${Merriweather}) format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6,
h1 *,
h2 *,
h3 *,
h4 *,
h5 *,
h6 * {
  font-family: ${variables.family.legacyPMHeaders};
}

*,
*::after,
*::before {
  font-family: ${variables.family.legacyPM};
}


`;

export const legacyPMDefaultFonts = `
  @font-face {
    font-family: "Segoe UI";
    src: local("Segoe UI Bold");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Segoe UI";
    src: local("Segoe UI Semibold");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family:"Segoe UI";
    src: local("Segoe UI");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Segoe UI";
    src: local("Segoe UI");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  *,
  *::after,
  *::before {
    font-family: "Segoe UI";
  }
`;
