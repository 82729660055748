import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import InformationList from '../../../InformationList/InformationList';
import InformationListItem from '../../../InformationList/InformationListItem';
import Paragraph from '../../../Paragraph/Paragraph';
import { formatMailingAddress } from '../../../../util/helpers/addressHelper';

const EntrustCheckTab = ({ checkDetails }) => {
  const { t } = useTranslation();

  if (isEmpty(checkDetails)) {
    return null;
  }

  return (
    <>
      <Paragraph marginBottom={24} fontSize={14}>
        {t('depositWizard.equity.wire.textOneAlt')}
      </Paragraph>
      <InformationList withContainer ignoreBackground>
        <InformationListItem
          label={t('depositWizard.equityCheck.payableTo')}
          value={checkDetails.PayableTo}
        />
        <InformationListItem
          label={t('depositWizard.equityCheck.memo')}
          value={checkDetails.Memo}
        />
      </InformationList>
      <InformationList ignoreBackground whiteBackground withContainer>
        <InformationListItem
          label={t('depositWizard.equityCheck.sendCheckTo')}
          noValue
        />
      </InformationList>
      <InformationList withContainer ignoreBackground>
        <InformationListItem
          label={t('depositWizard.equityCheck.mailing')}
          value={
            <span>
              {formatMailingAddress(checkDetails.Addresses.checkAddress)}
            </span>
          }
        />
      </InformationList>
    </>
  );
};

EntrustCheckTab.propTypes = {
  checkDetails: PropTypes.shape({
    PayableTo: PropTypes.string,
    Memo: PropTypes.string,
    Addresses: PropTypes.shape({
      checkAddress: PropTypes.shape({
        Receipient: PropTypes.string,
        Attn: PropTypes.string,
        AddressLine: PropTypes.string,
        AddressLine2: PropTypes.string,
        City: PropTypes.string,
        PostalCode: PropTypes.string,
        StateRegion: PropTypes.string,
      }),
    }),
  }),
};

export default EntrustCheckTab;
