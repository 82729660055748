import React, { useState } from 'react';
import PropTypes from 'prop-types';

import startcase from 'lodash.startcase';

import styled from 'styled-components';
import {
  uButtonClear,
  uFlexColumn,
  uOutlineNone,
} from '../../assets/styles/utility';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import themeColors from '../../assets/styles/themeColors';
import config from '../../config';

const { brokerTheme } = config;
const isWhiteText = brokerTheme === 'Contoso' || brokerTheme === 'LegacyPM';

const getTabButtonActiveStyles = (isActive) => {
  if (isActive) {
    return isWhiteText
      ? `
        background-color: ${themeColors.colorPrimary};
      color: ${themeColors.colorWhite};
  `
      : `
  background-color: ${themeColors.colorPrimary};
color: ${themeColors.colorTextContrast};
`;
  }

  return null;
};
export const TabContainer = styled.div`
  ${uFlexColumn};
  margin-bottom: ${pxToRem(24)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-bottom: ${pxToRemMd(24)};
  }
`;

export const TabHeader = styled.div`
  display: flex;
  border: 1px solid ${themeColors.colorPrimary};
  border-top-right-radius: ${variables.borderRadius.borderRadius};
  border-top-left-radius: ${variables.borderRadius.borderRadius};
  overflow: hidden;
`;

export const TabButton = styled.button`
  ${uButtonClear};
  ${uOutlineNone};
  padding: ${pxToRem(4)} ${pxToRem(16)};
  background-color: ${themeColors.colorWhite};
  font-size: ${pxToRem(16)};
  line-height: 1.5;
  font-weight: 600;
  letter-spacing: 0;
  color: ${themeColors.colorPrimary};
  cursor: pointer;
  flex: 1 1 0;

  ${({ isActive }) => getTabButtonActiveStyles(isActive)};

  &:not(:last-child) {
    border-right: 1px solid ${themeColors.colorPrimary};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(4.5)} ${pxToRemMd(16)};
    font-size: ${pxToRemMd(16)};
  }
`;

export const TabBody = styled.div`
  background-color: ${themeColors.colorBackgroundSecondary};
  padding: ${pxToRem(16)};
  border-bottom-right-radius: ${variables.borderRadius.borderRadius};
  border-bottom-left-radius: ${variables.borderRadius.borderRadius};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(16)};
  }
`;

const TabSelect = ({
  initialValue = '',
  types,
  onChange,
  format = startcase,
  children,
  ...props
}) => {
  const [selectedType, setSelectedType] = useState(initialValue);
  const handleTypeChange = (type) => {
    setSelectedType(type);
    onChange(type);
  };

  return (
    <TabContainer {...props}>
      <TabHeader>
        {types.map((type) => (
          <TabButton
            key={type}
            type="button"
            isActive={type === selectedType}
            onClick={() => handleTypeChange(type)}
          >
            {format(type)}
          </TabButton>
        ))}
      </TabHeader>
      <TabBody>{children}</TabBody>
    </TabContainer>
  );
};

TabSelect.propTypes = {
  initialValue: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  format: PropTypes.func,
  children: PropTypes.node,
};

export default TabSelect;
