import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { pxToRem } from '../../assets/styles/helper';

export const BadgeWrapper = styled.div`
  width: fit-content;
  border-radius: 50px;
  background-color: ${({ color = 'red' }) => color};
  padding: 0 ${pxToRem(8)};
`;

export const BadgeText = styled.p`
  color: white;
  font-size: small;
  font-weight: bold;
`;

const Badge = ({ color, status }) => (
  <BadgeWrapper color={color}>
    <BadgeText>{status}</BadgeText>
  </BadgeWrapper>
);

export default Badge;

Badge.propTypes = {
  color: PropTypes.string,
  status: PropTypes.string,
};
