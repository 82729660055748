import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Paragraph from '../Paragraph/Paragraph';
import { uFlexColumn } from '../../assets/styles/utility';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { variables } from '../../assets/styles/variables';

const RoundedNoteStyles = styled.div`
  ${uFlexColumn}
  padding: ${pxToRem(8)};
  color: ${themeColors.colorPrimary};
  line-height: 1.72;
  border: 1px solid ${themeColors.colorPrimary};
  border-radius: ${variables.borderRadius.borderRadius};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${pxToRem(marginBottom)}` : 0};
  margin-top: ${({ marginTop }) => (marginTop ? `${pxToRem(marginTop)}` : 0)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(8)};
  }
`;

const RoundedNote = ({ text, marginBottom, marginTop }) => (
  <RoundedNoteStyles marginBottom={marginBottom} marginTop={marginTop}>
    <Paragraph isPrimaryColor fontSize="14">
      {text}
    </Paragraph>
  </RoundedNoteStyles>
);

RoundedNote.propTypes = {
  text: PropTypes.string,
  marginBottom: PropTypes.number,
  marginTop: PropTypes.number,
};

export default RoundedNote;
