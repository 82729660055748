import React from 'react';
import PropTypes from 'prop-types';
import StepByStep from '../../StepByStep';
import TwoFAVerify from '../TwoFAVerify';

const twoFAEmailWizardSteps = [TwoFAVerify];

const TwoFAEmailWizard = ({
  handleNext,
  handleBack,
  setTwoFaValues,
  twoFaValue,
  getTwoFaCode,
  setIsSuccessful,
  setErrorMessage,
}) => {
  const handleBackButton = () => {
    setTwoFaValues(null);
    handleBack();
  };

  return (
    <StepByStep sessionScopeStep="2fa-email-wizard">
      {({ goStepForward }) =>
        twoFAEmailWizardSteps.map((modal) =>
          React.createElement(modal, {
            goStepBack: handleBackButton,
            goStepForward,
            setTwoFaValues,
            twoFaValue,
            handleNext,
            getTwoFaCode,
            setIsSuccessful,
            setErrorMessage,
          }),
        )
      }
    </StepByStep>
  );
};

TwoFAEmailWizard.propTypes = {
  handleBack: PropTypes.func,
  setTwoFaValues: PropTypes.func,
  handleNext: PropTypes.func,
  twoFaValue: PropTypes.shape({}),
  getTwoFaCode: PropTypes.func,
  setIsSuccessful: PropTypes.func,
  setErrorMessage: PropTypes.func,
};

export default TwoFAEmailWizard;
