import createReducer from '../../utils/createReducer';
import {
  TWO_FA_TYPES_SUCCESS,
  TWO_FA_TYPES_ERROR,
  TWO_FA_APPLICATION_KEYS_SUCCESS,
  TWO_FA_APPLICATION_KEYS_ERROR,
  TWO_FA_CODE_SUCCESS,
  TWO_FA_CODE_ERROR,
  TWO_FA_CODE_VIA_PHONE_SUCCESS,
  TWO_FA_CODE_VIA_PHONE_ERROR,
  TWO_FA_CODE_VIA_EMAIL_SUCCESS,
  TWO_FA_CODE_VIA_EMAIL_ERROR,
  SAVE_TWO_FA_SUCCESS,
  SAVE_TWO_FA_ERROR,
  DELETE_TWO_FA_SUCCESS,
  DELETE_TWO_FA_ERROR,
  GET_TWO_FA_SUCCESS,
  GET_TWO_FA_ERROR,
  GET_TWO_FA_CUSTOMER_SUPPORT_IS_OPEN_SUCCESS,
  GET_TWO_FA_CUSTOMER_SUPPORT_IS_OPEN_ERROR,
} from '../../actions/twoFA/twoFAActionConstants';

const initialState = {
  TwoFaTypes: [],
  TwoFaApplicationKeys: {},
  TwoFaCode: {},
  TwoFaApplication: {},
  IsCustomerSupportOpen: false,
  ErrorMessage: '',
};

export default createReducer(
  {
    [TWO_FA_TYPES_SUCCESS]: fetchTwoFaTypesSuccess,
    [TWO_FA_TYPES_ERROR]: fetchTwoFaTypesError,
    [TWO_FA_APPLICATION_KEYS_SUCCESS]: fetchTwoFaApplicationKeysSuccess,
    [TWO_FA_APPLICATION_KEYS_ERROR]: fetchTwoFaApplicationKeysError,
    [TWO_FA_CODE_SUCCESS]: postTwoFaCodeSuccess,
    [TWO_FA_CODE_ERROR]: postTwoFaCodeError,
    [TWO_FA_CODE_VIA_PHONE_SUCCESS]: getTwoFaCodeViaPhoneSuccess,
    [TWO_FA_CODE_VIA_PHONE_ERROR]: getTwoFaCodeViaPhoneError,
    [TWO_FA_CODE_VIA_EMAIL_SUCCESS]: getTwoFaCodeViaEmailSuccess,
    [TWO_FA_CODE_VIA_EMAIL_ERROR]: getTwoFaCodeViaEmailError,
    [SAVE_TWO_FA_SUCCESS]: postTwoFaSuccess,
    [SAVE_TWO_FA_ERROR]: postTwoFaError,
    [DELETE_TWO_FA_SUCCESS]: deleteTwoFaSuccess,
    [DELETE_TWO_FA_ERROR]: deleteTwoFaError,
    [GET_TWO_FA_SUCCESS]: getTwoFaSuccess,
    [GET_TWO_FA_ERROR]: getTwoFaError,
    [GET_TWO_FA_CUSTOMER_SUPPORT_IS_OPEN_SUCCESS]: getTwoFaCustomerSupportIsOpenSuccess,
    [GET_TWO_FA_CUSTOMER_SUPPORT_IS_OPEN_ERROR]: getTwoFaCustomerSupportIsOpenError,
  },
  initialState,
);

function fetchTwoFaTypesSuccess(state, action) {
  return {
    ...state,
    TwoFaTypes: action.payload,
  };
}

function fetchTwoFaTypesError(state, action) {
  return {
    ...state,
    ErrorMessage: action.payload,
  };
}

function fetchTwoFaApplicationKeysSuccess(state, action) {
  return {
    ...state,
    TwoFaApplicationKeys: action.payload,
  };
}

function fetchTwoFaApplicationKeysError(state, action) {
  return {
    ...state,
    ErrorMessage: action.payload,
  };
}

function postTwoFaCodeSuccess(state, action) {
  return {
    ...state,
    TwoFaCode: action.payload,
  };
}

function postTwoFaCodeError(state, action) {
  return {
    ...state,
    ErrorMessage: action.payload,
  };
}

function getTwoFaCodeViaPhoneSuccess(state, action) {
  return {
    ...state,
    TwoFaCode: action.payload,
  };
}

function getTwoFaCodeViaPhoneError(state, action) {
  return {
    ...state,
    ErrorMessage: action.payload,
  };
}

function getTwoFaCodeViaEmailSuccess(state, action) {
  return {
    ...state,
    TwoFaCode: action.payload,
  };
}

function getTwoFaCodeViaEmailError(state, action) {
  return {
    ...state,
    ErrorMessage: action.payload,
  };
}

function postTwoFaSuccess(state, action) {
  return {
    ...state,
    TwoFaApplication: action.payload,
  };
}

function postTwoFaError(state, action) {
  return {
    ...state,
    ErrorMessage: action.payload,
  };
}

function deleteTwoFaSuccess(state) {
  return state;
}

function deleteTwoFaError(state, action) {
  return {
    ...state,
    ErrorMessage: action.payload,
  };
}

function getTwoFaSuccess(state, action) {
  return {
    ...state,
    TwoFaApplication: action.payload,
  };
}

function getTwoFaError(state, action) {
  return {
    ...state,
    ErrorMessage: action.payload,
  };
}

function getTwoFaCustomerSupportIsOpenSuccess(state, action) {
  return {
    ...state,
    IsCustomerSupportOpen: action.payload,
  };
}

function getTwoFaCustomerSupportIsOpenError(state, action) {
  return {
    ...state,
    ErrorMessage: action.payload,
  };
}
