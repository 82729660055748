import { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { createWorker } from '../../workers';
import { apiDefaultUrl, replaceInUrl } from '../../request';
import apiEndpoints from '../../request/apiEndpoints';
import { PRODUCT_SYMBOL_PRICES_FETCH_SUCCESS } from '../../store/actions/products/productActionConstants';
import { JWT_TOKEN } from '../../constants/localStorage';
import { authScopeStringGetHelper } from '../helpers/authScopeHelpers';

export default function useGetPeriodicProductPrices(
  workerName,
  accountUid,
  symbol,
  side,
  shouldUpdate,
  interval = 5000,
  secondCallback,
) {
  useEffect(() => {
    const token = authScopeStringGetHelper(JWT_TOKEN);

    if (accountUid && shouldUpdate) {
      const worker = createWorker(
        `${workerName}-${accountUid}-${symbol}-${token}-${uuid()}`,
        {
          request: {
            url:
              apiDefaultUrl +
              replaceInUrl(apiEndpoints.products.symbolPrices, {
                accountUid,
                symbol,
                side,
              }),
            method: 'get',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
          actionSuccessType: PRODUCT_SYMBOL_PRICES_FETCH_SUCCESS,
          workerInterval: interval,
          periodicCallback: secondCallback,
        },
      );
      const stop = worker.start();
      return () => stop();
    }
  }, [
    accountUid,
    interval,
    side,
    symbol,
    workerName,
    shouldUpdate,
    secondCallback,
  ]);
}
