import styled from 'styled-components';
import { variables } from '../../assets/styles/variables';
import { mediaBelow } from '../../assets/styles/helper';

const Section = styled.section`
  padding: ${({ withoutPadding }) => (withoutPadding ? 0 : '0 3.25rem')};
  ${(props) => props.css};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: 0;
  }
`;

export default Section;
