import {
  DELETE,
  ERROR,
  FETCH,
  SUCCESS,
  UPDATE,
  SUBMIT,
} from '../actions/actionHelpers';

const promiseTypes = [FETCH, UPDATE, DELETE, SUBMIT];
export default ({ dispatch }) => (next) => (action) => {
  const promiseType = promiseTypes.find((promiseType) =>
    action.type.includes(promiseType),
  );
  if (promiseType) {
    dispatch({
      type: 'UPDATE_LOADER',
      payload: {
        actionType: action.type.replace(promiseType, '[LOADING]'),
        isLoading: true,
      },
    });
    return next(action);
  }

  if (action.type.includes(SUCCESS) || action.type.includes(ERROR)) {
    const actionType = action.type.includes(SUCCESS)
      ? action.type.replace(SUCCESS, '[LOADING]')
      : action.type.replace(ERROR, '[LOADING]');

    dispatch({
      type: 'UPDATE_LOADER',
      payload: {
        actionType,
        isLoading: false,
      },
    });
    return next(action);
  }
  next(action);
};
