import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Section from '../../components/Section/Section';
import { ReactComponent as MaintenanceIcon } from '../../assets/images/svg/maintenance.svg';
import { uFlexCenter, uFlexColumn } from '../../assets/styles/utility';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';

const MaintenancePageWrap = styled.div`
  ${uFlexColumn}
  ${uFlexCenter}
    flex: 1 0 auto;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(32)} ${pxToRemMd(16)};
  }
`;

const MaintenancePageSection = styled(Section)`
  ${uFlexColumn}
  ${uFlexCenter}
    flex: 1 0 auto;
`;

const MaintenancePageIcon = styled(MaintenanceIcon)`
  max-width: ${pxToRem(360)};
  width: 100%;
  margin-bottom: ${pxToRem(80)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    max-width: ${pxToRemMd(300)};
  }
`;

const MaintenancePageTitle = styled.h1`
  font-size: ${pxToRem(36)};
  line-height: 1.22;
  font-weight: bold;
  margin-bottom: ${pxToRem(40)};
  letter-spacing: 0;
  text-align: center;
`;

const MaintenancePageParagraph = styled.p`
  font-size: ${pxToRem(20)};
  letter-spacing: 0;
  line-height: 1.4;
  text-align: center;
`;

const MaintenancePage = () => {
  const { t } = useTranslation();

  return (
    <MaintenancePageWrap>
      <MaintenancePageSection>
        <MaintenancePageIcon />
        <MaintenancePageTitle>{t('maintenance.title')}</MaintenancePageTitle>
        <MaintenancePageParagraph>
          {t('maintenance.text')}
        </MaintenancePageParagraph>
      </MaintenancePageSection>
    </MaintenancePageWrap>
  );
};

MaintenancePage.propTypes = {};

export default MaintenancePage;
