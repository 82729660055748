import axios from 'axios';
import queryString from 'qs';

const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  paramsSerializer: (params) =>
    queryString.stringify(params, { arrayFormat: 'comma' }),
});

export const getRequest = (url, params = null, options = null) =>
  request.get(url, { params, ...options });

export const postRequest = (url, data, params = null, options = null) =>
  request.post(url, data, { params, ...options });

export const putRequest = (url, data, params = null, options = null) =>
  request.put(url, data, { params, ...options });

export const patchRequest = (url, data, params = null, options = null) =>
  request.patch(url, data, { params, ...options });

export const deleteRequest = (url, params = null, options = null) =>
  request.delete(url, { params, ...options });

export const downloadRequest = (url, params = null, options = null) =>
  request.get(url, { params, ...options, responseType: 'blob' });

export const replaceInUrl = (url, pathVariables = {}) => {
  const keys = Object.keys(pathVariables);
  if (!keys.length) {
    return url;
  }

  return keys.reduce(
    (acc, key) => acc.replace(`{${key}}`, pathVariables[`${key}`]),
    url,
  );
};

export const addHeaderToken = (token) => {
  request.defaults.headers.Authorization = `Bearer ${token}`;
};

export const addHeaderCookie = (key, value) => {
  request.defaults.headers[`${key}`] = value;
};

export const removeHeaderToken = () => {
  delete request.defaults.headers.Authorization;
};

export const attachPostRequestListener = (postRequestListener) => {
  request.interceptors.response.use(
    (response) => response,
    (response) => postRequestListener(response),
  );
};

export const apiDefaultUrl = request.defaults.baseURL;
