import { css } from 'styled-components';
import { mediaBelow, pxToRem, pxToRemMd } from '../../../assets/styles/helper';
import themeColors from '../../../assets/styles/themeColors';
import { variables } from '../../../assets/styles/variables';
import { TableDataElement } from '../TableData';

export const priceTierModal = css``;

export const priceTierModalTableHead = css`
  background-color: ${themeColors.colorTableHeaderBackground};
  padding: ${pxToRem(3)} ${pxToRem(16)};
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: ${pxToRem(16)};

  > :last-child {
    text-align: right;
    justify-content: flex-end;
  }

  ${TableDataElement} {
    font-size: ${pxToRem(14)};
    line-height: 1.6;
    letter-spacing: 0;
    font-weight: 400;
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(3)} ${pxToRemMd(16)};

    ${TableDataElement} {
      font-size: ${pxToRemMd(14)};
    }
  }
`;

export const priceTierModalTableRow = css`
  padding: ${pxToRem(7)} ${pxToRem(16)};

  ${TableDataElement} {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: ${pxToRem(16)};

    > :last-child {
      text-align: right;
      justify-content: flex-end;
    }
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(7)} ${pxToRemMd(16)};
  }
`;
