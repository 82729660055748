import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { isEmpty, startCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getCurrentAccount } from '../../store/selectors/accountSelectors';
import {
  selectBalancesAvailable,
  selectBalancesAvailableForTrading,
} from '../../store/selectors/portfolioBalancesSelectors';
import { selectSettings } from '../../store/selectors/settingsSelectors';
import { fetchPortfolioBalances } from '../../store/actions/portfolio/portfolioActions';
import {
  productLocationStateAbbr,
  productLocationTypes,
  productLocationUSType,
} from '../../util/enum/api/productTypes';
import { formatMoneyNumeral } from '../../util/helpers/numeralHelpers';

import Note from '../Notes/Note';
import Label from '../Notes/Label';
import ModalBody from '../Modal/ModalBody';
import useGtmHook from '../../util/hooks/useGtmHook';
import {
  ecommerceGtmHelper,
  onConfirmOrderEcommerceGtmHelper,
} from '../../util/helpers/gtmHelper';
import SectionLoader from '../Loader/SectionLoader';
import { parseEnumType } from '../../util/helpers/enumMappers';
import useProductPrice from '../../util/hooks/useProductPrice';
import BuyFields from '../InputFields/BuyFields';
import { buyForDeliveryValidationSchema } from '../../validation/buyForDeliveryValidationSchema';
import ModalTitle from '../Modal/ModalTitle';
import TwoColumnList from '../TwoColumnList/TwoColumnList';
import Paragraph from '../Paragraph/Paragraph';
import ModalButtons from '../Modal/ModalButtons';
import SingleColumnList from '../TwoColumnList/SingleColumnList';
import SupportModal from '../Modals/SupportModal/SupportModal';
import config from '../../config';
import themeColors from '../../assets/styles/themeColors';
import { pxToRem } from '../../assets/styles/helper';
import { getQuotesForBuyDelivery } from '../../store/actions/orders/orderActions';
import paymentTypes from '../../util/enum/api/paymentTypes';

const Error = styled.div`
  dispay: flex;
  color: ${themeColors.colorRed};
  font-size: ${pxToRem(16)};
  line-height: 1.35;
  font-weight: 500;
`;

const DeliveryLimit = styled.p`
  display: flex;
  flex-direction: column;
  color: ${themeColors.colorError};
  font-size: ${pxToRem(14)};
  font-weight: 500;
  line-height: 1.35;
  margin: ${pxToRem(4)} 0;
`;

const OrderForDelivery = ({
  handleNext,
  setWizardData,
  wizardData,
  handleBack,
  setWizardTitle,
  isPostPaidVisible,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const { gtmDialogScreenView, ecommerceGtmEvent } = useGtmHook();
  const balance = useSelector(selectBalancesAvailable);
  const [activeType, setActiveType] = useState(wizardData.activeType || 'Cash');
  const [orderValues, setOrderValues] = useState({});
  const [calculation, setCalculation] = useState({});
  const [deliveryFees, setDeliveryFees] = useState(0);
  const [salesTax, setSalesTax] = useState(0);
  const [quoteID, setQuoteID] = useState('');
  const [expiryTime, setExpiryTime] = useState('');
  const [nextClicked, setNextClicked] = useState(false);
  const [isLoadingGetQuotes, setIsLoadingGetQuotes] = useState(false);
  const [limitation, setLimitation] = useState(0);
  const limitValue = 225000;
  const [showLimitationMsg, setShowLimitationMsg] = useState(
    limitation > limitValue,
  );
  const balancePaymentMethodUid = '00000000-0000-0000-0000-000000000000';
  const [isSupportModalOpen, setSupportModalOpen] = useState(false);
  const [
    balanceAvailableForTradingIsValid,
    setBalanceAvailableForTradingIsValid,
  ] = useState(true);
  const [
    orderAvailableForTradingIsValid,
    setOrderIsAvailableForTradingIsValid,
  ] = useState(true);

  const dispatch = useDispatch();
  const account = useSelector(getCurrentAccount);
  const balanceAvailableForTrading = useSelector(
    selectBalancesAvailableForTrading,
  );
  const settings = useSelector(selectSettings);

  const unitPrice = useProductPrice(
    wizardData?.storageProduct,
    wizardData?.selectedVault,
  );
  const [pricePerUnit, setPricePerUnit] = useState(unitPrice);
  const [getQuotesError, setGetQuotesError] = useState('');
  const [isDefaultPaymentMethod, setIsDefaultPaymentMethod] = useState('');

  const {
    storageProduct,
    selectedVault,
    deliveryAddress,
    isSegregated,
  } = wizardData;

  const isLegacyPM = () => {
    const { brokerTheme } = config;
    if (brokerTheme === 'LegacyPM') {
      return true;
    }
    return false;
  };

  const getMinimumOrderAmount = () => {
    const buyForDeliveryLocationId = settings.BuyForDeliveryLocation;

    const vaultWithDeliveryMinimum = settings.BuyForDeliveryMinimums.find(
      (item) =>
        startCase(parseEnumType(productLocationStateAbbr, item.Location)) ===
        deliveryAddress?.StateRegion,
    );

    const wizardDeliveryMinimum =
      wizardData?.selectedVault?.BuyForDeliveryMinimumAmount;

    const legacyPMVaultWithDeliveryMinimum = settings.BuyForDeliveryMinimums.find(
      (item) => item.Location === buyForDeliveryLocationId,
    );

    if (!vaultWithDeliveryMinimum && !wizardDeliveryMinimum && !isLegacyPM()) {
      return 0;
    }

    if (!isLegacyPM()) {
      if (vaultWithDeliveryMinimum) {
        const { Amount } = vaultWithDeliveryMinimum;
        if (Amount > wizardDeliveryMinimum) {
          return Amount;
        }
      }
      if (wizardDeliveryMinimum) {
        return wizardDeliveryMinimum;
      }
      return 0;
    }

    if (isLegacyPM() && legacyPMVaultWithDeliveryMinimum) {
      const { Amount } = legacyPMVaultWithDeliveryMinimum;
      return Amount;
    }
  };

  const addressValidation = () => {
    const locationStateRegion = storageProduct.Locations.some((location) =>
      productLocationUSType.includes(
        parseEnumType(productLocationTypes, location.LocationType),
      ),
    );

    return locationStateRegion && deliveryAddress?.CountryIso3Code === 'USA';
  };

  useEffect(() => {
    if (account.AccountUid) {
      dispatch(fetchPortfolioBalances({ accountUid: account.AccountUid }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.AccountUid]);

  useEffect(() => {
    setWizardTitle(t('buyWizard.buyForDelivery.orderEntry.modalTitle'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (wizardData?.storageProduct) {
      return;
    }

    gtmDialogScreenView({
      title: `Buy for Delivery - Order Entry ${wizardData?.storageProduct.ProductCaption}`,
    });
  }, []); //eslint-disable-line

  useEffect(() => {
    if (unitPrice) {
      setPricePerUnit(unitPrice);
      ecommerceGtmHelper({
        ecommerceGtmEvent,
        wizardData,
        orderSideType: 'buy',
        account,
        unitPrice,
        isForDelivery: true,
      });
    }
  }, [unitPrice]); //eslint-disable-line

  useEffect(() => {
    setWizardData({
      ...wizardData,
      balanceAvailable: balanceAvailableForTrading,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balanceAvailableForTrading]);

  useEffect(() => {
    setShowLimitationMsg(limitation > limitValue);
  }, [limitation]);

  const displayError = () => {
    const isSelectedAddressEnabled = addressValidation();
    if (!isSelectedAddressEnabled) {
      return <Error>{t('buyWizard.buyForDelivery.locationValidation')}</Error>;
    }
    if (getQuotesError) {
      return (
        <Paragraph isError fontSize={14} bold>
          {getQuotesError}
        </Paragraph>
      );
    }
  };

  const onActiveTypeChange = (value) => {
    const { CashAmount, QuantityAmount } = ref.current.values;
    if (value === 'Cash') {
      ref.current.values.QuantityAmount = 0;
      setLimitation(CashAmount);
    } else {
      ref.current.values.CashAmount = 0;
      setLimitation(pricePerUnit * QuantityAmount);
    }
    setGetQuotesError('');
    setActiveType(value);
  };

  const onHandleGetQuotesSuccess = (getQuoteResult) => {
    const {
      QuoteUid,
      QuoteStatusItem: {
        QuantityExecuted,
        ClientPricePerUnit,
        TotalClientExecutionPrice,
        DeliveryFee,
        SalesTax,
        VolumeDiscount,
        PaymentMethodStepData,
      },
      ExpiresOnUtc,
    } = getQuoteResult;

    const calculation = {
      estimatedOrderTotal: TotalClientExecutionPrice,
      newPricePerUnit: ClientPricePerUnit,
      volumeDiscount: VolumeDiscount,
      quantity: QuantityExecuted,
      deliveryFees: DeliveryFee,
      salesTax: SalesTax,
      paymentMethodStepData: PaymentMethodStepData,
    };

    setQuoteID(QuoteUid);
    setExpiryTime(ExpiresOnUtc);
    setCalculation(calculation);
    setDeliveryFees(DeliveryFee);
    setSalesTax(SalesTax);
    setNextClicked(true);
  };

  useEffect(() => {
    if (!isEmpty(calculation) && !isEmpty(orderValues)) {
      setWizardData({
        ...wizardData,
        calculation,
        quoteID,
        expiryTime,
        orderValues,
        orderType: activeType,
        deliveryFees,
        salesTax,
        isDefaultPaymentMethod,
      });
    }
  }, [
    orderValues,
    calculation,
    setWizardData,
    wizardData,
    quoteID,
    expiryTime,
    activeType,
    deliveryFees,
    salesTax,
    isDefaultPaymentMethod,
  ]);

  useEffect(() => {
    if (!isEmpty(wizardData?.orderValues) && nextClicked) {
      setNextClicked(false);
      handleNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardData.orderValues, nextClicked]);

  const onHandleGetQuotesError = (error) => {
    const errorCode = error?.response?.data?.Errors[0]?.Code;

    if (errorCode === 'BuyForDeliveryAmountMinimumIsNotReached') {
      setGetQuotesError(
        t('apiErrors.BuyForDeliveryAmountMinimumIsNotReached', {
          orderMinimum:
            deliveryAddress?.StateRegion === 'NY'
              ? `$${settings?.BuyForDeliveryMinimums[0].Amount}`
              : `$${selectedVault?.BuyForDeliveryMinimumAmount}`,
        }),
      );
    } else {
      setGetQuotesError(t(`apiErrors.${errorCode}`));
    }

    setShowLimitationMsg(false);
  };

  const submitForm = (values) => {
    const calculateMaxQuantity = () =>
      Math.floor(balanceAvailableForTrading / pricePerUnit);

    const isValueReachedMaximumLimit =
      values?.CashAmount > balanceAvailableForTrading ||
      values?.QuantityAmount > calculateMaxQuantity();

    const defaultPaymentMethod = balance?.PaymentMethods?.find(
      (paymentMethod) => paymentMethod.IsDefault,
    );

    setIsDefaultPaymentMethod(balance?.PaymentMethods?.[0]);

    if (isValueReachedMaximumLimit) {
      setIsDefaultPaymentMethod(defaultPaymentMethod);
    }

    if (showLimitationMsg) {
      return;
    }

    setOrderValues({
      CashAmount: activeType === 'Cash' ? values.CashAmount : 0,
      QuantityAmount: activeType === 'Quantity' ? values.QuantityAmount : 0,
    });
    setGetQuotesError('');

    onConfirmOrderEcommerceGtmHelper({
      ecommerceGtmEvent,
      wizardData,
      orderSideType: 'buy',
      account,
      pricePerUnit,
      activeType,
      values,
      isForDelivery: true,
    });

    const paymentTypeUid =
      isValueReachedMaximumLimit &&
      defaultPaymentMethod?.PaymentMethodType !== 1
        ? defaultPaymentMethod?.PaymentMethodUid
        : balancePaymentMethodUid;

    const payload = {
      getQuotesPayload: {
        accountUid: account.AccountUid,
        symbolCode: selectedVault?.SymbolCode,
        quantityOrAmount:
          activeType === 'Cash' ? values?.CashAmount : values?.QuantityAmount,
        sideType: 'Buy',
        unitType: activeType,
        paymentType: !isValueReachedMaximumLimit
          ? paymentTypes[balance?.PaymentMethods?.[0]?.PaymentMethodType]
          : paymentTypes[defaultPaymentMethod?.PaymentMethodType],
        addressUid: deliveryAddress?.AddressUid,
        isSegregated,
      },
      isLoading: setIsLoadingGetQuotes,
      onSuccess: onHandleGetQuotesSuccess,
      onError: onHandleGetQuotesError,
      paymentTypeUid,
      addPaymentMethodData: true,
    };
    setIsLoadingGetQuotes(true);
    dispatch(
      getQuotesForBuyDelivery({
        ...payload,
      }),
    );
  };

  useEffect(() => {
    if (!wizardData || isEmpty(wizardData) || !wizardData?.calculation) {
      return;
    }

    const {
      calculation: { estimatedOrderTotalWithCashBuffer, estimatedOrderTotal },
    } = wizardData;

    const isValid =
      activeType === 'Quantity'
        ? balanceAvailableForTrading >= estimatedOrderTotalWithCashBuffer
        : balanceAvailableForTrading >= estimatedOrderTotal;

    setBalanceAvailableForTradingIsValid(isValid);
  }, [activeType, balanceAvailableForTrading, wizardData]);

  if (isEmpty(wizardData)) {
    return null;
  }

  return (
    <>
      <SectionLoader isLoading={isLoadingGetQuotes}>
        <ModalBody>
          <ModalTitle marginBottom={4}>
            {storageProduct?.ProductCaption}
          </ModalTitle>
          <Label marginBottom={32} text={t('buyWizard.common.orderEntry')} />
          <TwoColumnList marginBottom={24}>
            <SingleColumnList>
              <Label
                marginBottom={4}
                text={t('buyWizard.common.pricePerUnitLockedPrice')}
              />
              <Paragraph fontSize={18}>
                {formatMoneyNumeral(pricePerUnit)}
              </Paragraph>
            </SingleColumnList>
            <SingleColumnList>
              <Label
                marginBottom={4}
                text={t('buyWizard.common.availableForTrading')}
              />
              <Paragraph fontSize={18}>
                {formatMoneyNumeral(balanceAvailableForTrading)}
              </Paragraph>
            </SingleColumnList>
          </TwoColumnList>
          <Formik
            innerRef={ref}
            initialValues={{
              CashAmount: wizardData?.orderValues?.CashAmount || '',
              QuantityAmount: wizardData?.orderValues?.QuantityAmount || '',
              applyMax: !isPostPaidVisible,
            }}
            initialTouched={{
              CashAmount: true,
              QuantityAmount: true,
            }}
            validationSchema={buyForDeliveryValidationSchema({
              activeType,
              unitPrice,
              balanceAvailableForTrading,
              getMinimumOrderAmount,
              deliveryAddress,
              buyForDeliveryMinimums: settings?.BuyForDeliveryMinimums,
            })}
            onSubmit={submitForm}
            validateOnBlur={false}
          >
            {({ values, errors, setFieldValue, setFieldTouched }) => {
              const isNextButtonEnabled = addressValidation();
              return (
                <Form>
                  <BuyFields
                    activeType={activeType}
                    setActiveType={onActiveTypeChange}
                    values={values}
                    setFieldValue={setFieldValue}
                    selectedVault={selectedVault}
                    setFieldTouched={setFieldTouched}
                    setDeliveryFees={setDeliveryFees}
                    deliveryFees={deliveryFees}
                    unitPrice={unitPrice}
                    setLimitation={setLimitation}
                    displayError={displayError}
                    setOrderIsAvailableForTradingIsValid={
                      setOrderIsAvailableForTradingIsValid
                    }
                    orderAvailableForTradingIsValid={
                      orderAvailableForTradingIsValid
                    }
                    setGetQuotesError={setGetQuotesError}
                  />

                  {showLimitationMsg && isEmpty(errors) && (
                    <DeliveryLimit>
                      {t('validationSchema.orders.limitationExceeds', {
                        amount: formatMoneyNumeral(limitValue),
                      })}
                    </DeliveryLimit>
                  )}

                  <Paragraph marginTop={24} isColumn>
                    {activeType === 'Cash' && (
                      <Note
                        marginBottom={8}
                        text={t('buyWizard.buyForDelivery.orderEntry.cashNote')}
                      />
                    )}
                  </Paragraph>
                  {showLimitationMsg && (
                    <Paragraph isError marginTop={8}>
                      {t('buyWizard.buyForDelivery.limitationCopy')}
                    </Paragraph>
                  )}
                  {!showLimitationMsg && (
                    <ModalButtons
                      marginTop
                      isHorizontal
                      primaryButtonProps={{
                        type: 'submit',
                        label: t('common.next'),
                        disabled:
                          (!isEmpty(deliveryFees) &&
                            (!balanceAvailableForTradingIsValid ||
                              // !balanceAvailableForWithdrawalIsValid ||
                              (selectedVault?.IsCashOrderOnly &&
                                activeType === 'Quantity'))) ||
                          errors?.CashAmount ||
                          errors?.QuantityAmount ||
                          !isNextButtonEnabled,
                      }}
                      secondaryButtonProps={{ onClick: handleBack }}
                    />
                  )}

                  {showLimitationMsg && (
                    <ModalButtons
                      marginTop
                      isHorizontal
                      primaryButtonProps={{
                        label: t('common.contact'),
                        disabled: !isNextButtonEnabled,
                        onClick: () => setSupportModalOpen(true),
                      }}
                      secondaryButtonProps={{ onClick: handleBack }}
                    />
                  )}
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
      </SectionLoader>

      <SupportModal
        handleOutsideClick
        isOpen={isSupportModalOpen}
        close={setSupportModalOpen}
      />
    </>
  );
};

OrderForDelivery.propTypes = {
  handleNext: PropTypes.func,
  setWizardData: PropTypes.func,
  wizardData: PropTypes.shape({
    storageProduct: PropTypes.shape({
      ProductCaption: PropTypes.string,
      Locations: PropTypes.arrayOf({
        LocationType: PropTypes.string,
      }),
    }),
    selectedVault: PropTypes.shape({
      LocationType: PropTypes.number,
      SymbolCode: PropTypes.string,
      IsCashOrderOnly: PropTypes.bool,
      BuyForDeliveryMinimumAmount: PropTypes.number,
    }),
    cashBuffer: PropTypes.number,
    deliveryAddress: PropTypes.shape({
      AddressUid: PropTypes.string,
      StateRegion: PropTypes.string,
      CountryIso3Code: PropTypes.string,
    }),
    orderValues: PropTypes.shape({
      CashAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      QuantityAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    activeType: PropTypes.string,
    calculation: PropTypes.shape({
      estimatedOrderTotal: PropTypes.number,
      deliveryCosts: PropTypes.number,
      salesTax: PropTypes.number,
      newPricePerUnit: PropTypes.number,
      volumeDiscount: PropTypes.number,
      quantity: PropTypes.number,
      estimatedOrderTotalWithCashBuffer: PropTypes.number,
    }),
    isSegregated: PropTypes.bool,
  }),
  handleBack: PropTypes.func,
  setWizardTitle: PropTypes.func,
  isPostPaidVisible: PropTypes.bool,
};

export default OrderForDelivery;
