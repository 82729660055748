import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ReviewBar from '../../../ReviewBar/ReviewBar';
import SectionLoader from '../../../Loader/SectionLoader';
import ModalBody from '../../../Modal/ModalBody';
import ModalButtons from '../../../Modal/ModalButtons';
import Paragraph from '../../../Paragraph/Paragraph';
import ModalTitle from '../../../Modal/ModalTitle';
import InformationList from '../../../InformationList/InformationList';
import InformationListItem from '../../../InformationList/InformationListItem';

const ConfirmConversion = ({
  product,
  quantity,
  goStepBack,
  onConfirm,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <SectionLoader isLoading={isLoading}>
      <ReviewBar title={t('product.fractionalConversion.reviewConversion')} />
      <ModalBody>
        <ModalTitle>{product.ProductCaption}</ModalTitle>
        <InformationList marginBottom={24}>
          <InformationListItem
            fullWidth
            label={t(
              'product.fractionalConversion.unitsAvailableForConversion',
            )}
            value={product.UnitsAvailableForConversion}
          />
          <InformationListItem
            fullWidth
            label={t('product.fractionalConversion.unitsToConvert')}
            value={product.UnitsAvailableForConversion}
          />
          <InformationListItem
            value={quantity}
            label={t('product.fractionalConversion.unitsToConvert')}
            fullWidth
          />
        </InformationList>
        <Paragraph>
          {t('product.fractionalConversion.conversionInfo')}
        </Paragraph>
        <ModalButtons
          isHorizontal
          marginTop
          secondaryButtonProps={{ onClick: goStepBack }}
          primaryButtonProps={{
            onClick: onConfirm,
            label: t('common.confirm'),
          }}
        />
      </ModalBody>
    </SectionLoader>
  );
};

ConfirmConversion.propTypes = {
  product: PropTypes.shape({
    ProductCaption: PropTypes.string,
    UnitsAvailableForConversion: PropTypes.number,
  }),
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  goStepBack: PropTypes.func,
  onConfirm: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default ConfirmConversion;
