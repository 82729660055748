import React from 'react';
import PropTypes from 'prop-types';
import StepByStep from '../../../StepByStep';
import EntrustChooseType from './Entrust/EntrustChooseType';
import EntrustChosenWizard from './Entrust/EntrustChosenWizard';
import {
  ENTRUST_PREFUNDED_WITHDRAWAL_WIZARD,
  WITHDRAWAL_TYPE,
} from '../../../../constants/sessionStorage';
import { setWizardContent } from '../../../../util/helpers/wizardHelpers';
import { useSessionStorageState } from '../../../../util/hooks/useSessionStorageState';

const steps = [EntrustChooseType, EntrustChosenWizard];

const EntrustPrefundedWithdrawalWizard = ({
  closeModal,
  handleRefreshTransactions,
  accountUid,
  type,
  setTitleType,
}) => {
  const [withdrawalType, setWithdrawalType] = useSessionStorageState(
    WITHDRAWAL_TYPE,
    null,
  );

  const setType = (value) => {
    setWithdrawalType(value);
    setWizardContent(WITHDRAWAL_TYPE, value);
  };

  return (
    <StepByStep sessionScopeStep={ENTRUST_PREFUNDED_WITHDRAWAL_WIZARD}>
      {({ goStepForward, goStepBack }) =>
        steps.map((buyStorageStep) =>
          React.createElement(buyStorageStep, {
            handleBack: goStepBack,
            handleNext: goStepForward,
            handleClose: closeModal,
            accountUid,
            withdrawalType,
            setWithdrawalType: setType,
            handleRefreshTransactions,
            type,
            setTitleType,
          }),
        )
      }
    </StepByStep>
  );
};

EntrustPrefundedWithdrawalWizard.propTypes = {
  closeModal: PropTypes.func,
  handleRefreshTransactions: PropTypes.func,
  accountUid: PropTypes.string,
  type: PropTypes.string,
  setTitleType: PropTypes.func,
};

export default EntrustPrefundedWithdrawalWizard;
