import i18next from 'i18next';

export const headerData = [
  {
    id: 1,
    content: i18next.t('portfolio.transactions.tableHeadings.yourTransactions'),
  },
  {
    id: 2,
    content: i18next.t('portfolio.transactions.tableHeadings.amount'),
  },
  {
    id: 3,
    content: i18next.t('portfolio.transactions.tableHeadings.effectiveBalance'),
  },
  {
    id: 4,
    content: i18next.t('portfolio.transactions.tableHeadings.status'),
  },
  { id: 5, content: '' },
];

export const equityHeaderData = [
  {
    id: 1,
    content: i18next.t('portfolio.transactions.tableHeadings.yourTransactions'),
  },
  {
    id: 2,
    content: i18next.t('portfolio.transactions.tableHeadings.amount'),
  },
  {
    id: 3,
    content: i18next.t('portfolio.transactions.tableHeadings.status'),
  },
  { id: 4, content: '' },
];
