import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import TwoFASetupChooseMethodPage from './TwoFASetupChooseMethodPage';
import TwoFAQRCodePage from './TwoFAQRCodePage';
import TwoFAPhonePage from './TwoFAPhonePage';
import TwoFAVerificationCodePage from './TwoFAVerificationCodePage';
import TwoFASuccessfulSetup from './TwoFASuccessfulSetup';
import {
  closeTwoFaLoginContent,
  getTwoFaLoginContent,
  setTwoFaLoginContent,
} from '../../../util/helpers/twoFALoginSetupHelper';
import { TWO_FA_LOGIN_SETUP } from '../../../constants/sessionStorage';
import RouterStepByStep from '../../../components/RouterStepByStep/RouterStepByStep';
import { postTwoFaCode } from '../../../store/actions/twoFA/twoFAActions';
import { EMAIL_LOGIN_PAGE } from '../../../constants/pages';
import { selectAuthUser } from '../../../store/selectors/userSelectors';

const TwoFASetupPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectAuthUser);

  const [twoFaValue, setValues] = useState(() => getTwoFaLoginContent() || {});
  const currentPath = history.location.pathname;

  const setTwoFaValues = (data) => {
    if (data === null) {
      setValues({});
      closeTwoFaLoginContent();
      return;
    }

    setValues({ ...twoFaValue, ...data });
    setTwoFaLoginContent(TWO_FA_LOGIN_SETUP, { ...twoFaValue, ...data });
  };

  const saveFormValuesOnSuccess = (values) => {
    setTwoFaValues(values);
  };

  const getTwoFaCode = (value) => {
    const { requestData, userUid, onSuccess, onError, onReset } = value;

    dispatch(
      postTwoFaCode({
        requestData,
        userUid,
        saveFormValuesOnSuccess,
        onSuccess,
        onError,
        onReset,
      }),
    );
  };

  useEffect(() => {
    if (!user) {
      history.push(EMAIL_LOGIN_PAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const twoFASetupSteps = useCallback(
    (goStepBack, goStepForward) => {
      const compProps = {
        goStepBack,
        goStepForward,
        setTwoFaValues,
        twoFaValue,
        getTwoFaCode,
        user,
      };

      return {
        '/verify-choose-method': <TwoFASetupChooseMethodPage {...compProps} />,
        '/authentication-code': <TwoFAQRCodePage {...compProps} />,
        '/verify-phone-method': <TwoFAPhonePage {...compProps} />,
        '/verify-code': <TwoFAVerificationCodePage {...compProps} />,
        '/successful-setup': <TwoFASuccessfulSetup {...compProps} />,
      };
    },
    [twoFaValue, user], // eslint-disable-line
  );

  return (
    <RouterStepByStep currentPath={currentPath}>
      {({ goStepBack, goStepForward, currentFormStep, goToFirstStep }) =>
        twoFASetupSteps(
          goStepBack,
          goStepForward,
          currentFormStep,
          goToFirstStep,
        )
      }
    </RouterStepByStep>
  );
};

TwoFASetupPage.propTypes = {};

export default TwoFASetupPage;
