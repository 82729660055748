import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { formatMoneyNumeral } from '../../../../../util/helpers/numeralHelpers';
import { FINANCIAL_BALANCES_PAGE } from '../../../../../constants/pages';
import CurrencyField from '../../../../InputFields/CurrencyField';
import { deleteWizardKey } from '../../../../../util/helpers/wizardHelpers';
import {
  PREFUNDED_WIRE_WITHDRAWAL_WIZARD,
  WITHDRAWAL_TYPE,
} from '../../../../../constants/sessionStorage';
import { selectIsLoadingByActionType } from '../../../../../store/selectors/loadingSelectors';
import { FETCH_ACCOUNT_ADDRESSES_LOADING } from '../../../../../store/actions/account/accountActionConstants';
import SectionLoader from '../../../../Loader/SectionLoader';
import Note from '../../../../Notes/Note';
import { amountValidationSchema } from '../../../../../validation/amountValidationSchema';
import ModalBody from '../../../../Modal/ModalBody';
import Label from '../../../../Notes/Label';
import Paragraph from '../../../../Paragraph/Paragraph';
import ModalButtons from '../../../../Modal/ModalButtons';

const WireInformationForm = ({
  availableMoney,
  handleClose,
  setValue,
  handleNext,
  goBack,
  value,
  setWithdrawalType,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isLoading = useSelector(
    selectIsLoadingByActionType(FETCH_ACCOUNT_ADDRESSES_LOADING),
  );

  const onFormSubmit = (values) => {
    setValue(values);
    handleNext();
  };

  return (
    <SectionLoader isLoading={isLoading}>
      <ModalBody>
        <Label
          marginBottom={4}
          isBold
          text={t('IRAWithdraw.amountForm.amountAvailable')}
        />

        <Paragraph fontSize={18} marginBottom={24}>
          {formatMoneyNumeral(availableMoney)}
        </Paragraph>
        <Note
          onClick={() => history.push(FINANCIAL_BALANCES_PAGE)}
          text={t('IRAWithdraw.amountForm.dontSeeFunds')}
          marginBottom={32}
        />

        <Formik
          initialValues={value}
          validationSchema={amountValidationSchema(availableMoney)}
          onSubmit={onFormSubmit}
          initialTouched={{
            amount: true,
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <CurrencyField
                defaultValue={values.amount}
                label={t('IRAWithdraw.amountForm.amountLabel')}
                name="amount"
                placeholder={formatMoneyNumeral(0)}
                onChange={(value) => setFieldValue('amount', value)}
              />
              <ModalButtons
                isHorizontal
                marginTop
                secondaryButtonProps={{
                  label: goBack ? t('common.back') : t('common.cancel'),
                  onClick: goBack
                    ? () => {
                        goBack();
                        setWithdrawalType(null);
                        deleteWizardKey(WITHDRAWAL_TYPE);
                        deleteWizardKey(PREFUNDED_WIRE_WITHDRAWAL_WIZARD);
                      }
                    : handleClose,
                }}
                primaryButtonProps={{
                  type: 'submit',
                  disabled: !values?.amount,
                }}
              />
            </Form>
          )}
        </Formik>
      </ModalBody>
    </SectionLoader>
  );
};

WireInformationForm.propTypes = {
  handleClose: PropTypes.func,
  availableMoney: PropTypes.number,
  setValue: PropTypes.func,
  value: PropTypes.shape({}),
  handleNext: PropTypes.func,
  goBack: PropTypes.func,
  setWithdrawalType: PropTypes.func,
};

export default WireInformationForm;
