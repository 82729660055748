import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import i18next from 'i18next';
import { useHistory } from 'react-router';
import getCountryISO3 from 'country-iso-2-to-3';
import { useDispatch, useSelector } from 'react-redux';
import {
  formatPhoneNumberIntl,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input';
import { decode } from 'jsonwebtoken';
import CreateUserFormSection from './Forms/CreateUserFormSection';
import CredentialsFormSection from './Forms/CredentialsFormSection';
import {
  INVITE_USER_IDENTITY,
  INVITE_USER_PAGE,
  INVITE_USER_TWO_FA_VERIFY_CHOOSE_METHOD,
  INVITE_USER_TWO_FA_VERIFY_IDENTITY,
  INVITE_USER_TWO_FA_VERIFY_IDENTITY_PHONE,
  INVITE_USER_WELCOME_PAGE,
} from '../../constants/pages';
import {
  getRegisterContent,
  setRegisterContent,
} from '../../util/helpers/registerHelpers';
import {
  ERROR,
  FORM_VALUES,
  INVITE_USER_DATA,
  USER_INVITE_STATUS_MODAL,
  INVITE_EMAIL,
} from '../../constants/sessionStorage';
import { SUFFIX_TYPES_ARRAY } from '../../constants/registrationConstants';
import {
  userInviteExistingFormValidationSchema,
  userInviteNewFormValidationSchema,
} from '../../validation/userInviteFormSchema';
import {
  clearLoginErrors,
  fetchUser,
} from '../../store/actions/login/loginActions';
import {
  selectTokens,
  selectTwoFactorAuthenticationResponse,
} from '../../store/selectors/loginSelectors';

import SectionLoader from '../../components/Loader/SectionLoader';
import { selectIsLoadingByActionTypes } from '../../store/selectors/loadingSelectors';
import { LOGIN_USER_LOADING } from '../../store/actions/login/loginActionConstants';
import RegisterStep from '../../components/RegisterStep/RegisterStep';
import {
  selectPortalGatewayISOCodesListToIgnoreAreaCode,
  selectSettings,
} from '../../store/selectors/settingsSelectors';
import {
  removeFromSessionStorage,
  retrieveFromSessionStorage,
} from '../../util/helpers/sessionStorageHelper';
import { JWT_REFRESH_TOKEN, JWT_TOKEN } from '../../constants/localStorage';
import { accountSecurityQuestionTypesObject } from '../../util/enum/api/accountTypes';
import { inviteUser } from '../../store/actions/user/userActions';
import { useSessionStorageState } from '../../util/hooks/useSessionStorageState';
import StatusModal from '../../components/Modals/StatusModal';
import { closeWizardContent } from '../../util/helpers/wizardHelpers';
import { INVITE_USER_LOADING } from '../../store/actions/user/userActionConstants';
import { getUsernames } from '../../util/helpers/userHelpers';
import { getBrowserPayload } from '../../util/helpers/browserHelper';
import { shouldIgnoreAreaCodeHelperViaIso3 } from '../../util/helpers/phoneNumberHelpers';
import ModalButtons from '../../components/Modal/ModalButtons';
import FormContainer from '../../components/FormContainer/FormContainer';

const YES = 'Yes';
const NO = 'No';

const Credentials = ({
  setCurrentStep,
  handleNext,
  setFormValues,
  phoneNumbers,
  userInviteId,
  setPhoneNumbers,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const tokens = useSelector(selectTokens);
  const brokerSettings = useSelector(selectSettings);
  const selectedISOCodesListToIgnoreAreaCode = useSelector(
    selectPortalGatewayISOCodesListToIgnoreAreaCode,
  );
  const usernames = getUsernames();
  const email = retrieveFromSessionStorage(INVITE_EMAIL);
  const isExisting = usernames.length > 0 ? YES : NO;
  const isLoading = useSelector(
    selectIsLoadingByActionTypes([LOGIN_USER_LOADING, INVITE_USER_LOADING]),
  );
  const twoFaAuthenticationResponse = useSelector(
    selectTwoFactorAuthenticationResponse,
  );

  const { IsBroker2FAActive } = brokerSettings;

  const [error, setError] = useSessionStorageState(ERROR, '');
  const [statusModal, setStatusModal] = useSessionStorageState(
    USER_INVITE_STATUS_MODAL,
    false,
  );
  useEffect(() => {
    function redirectClient() {
      if (!tokens.RefreshToken && !tokens.JwtToken) {
        return;
      }

      if (IsBroker2FAActive) {
        const authenticationMethodType = decode(tokens.JwtToken)
          ?.AuthenticationMethodType;

        if (tokens.JwtToken) {
          if (authenticationMethodType) {
            return history.push({
              pathname: INVITE_USER_WELCOME_PAGE,
              state: {
                from: history.location.pathname,
              },
            });
          }
          return history.push({
            pathname: INVITE_USER_TWO_FA_VERIFY_CHOOSE_METHOD,
            state: {
              from: history.location.pathname,
              isInviteUser: true,
            },
          });
        }
      }
    }

    redirectClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens]);

  const setReviewValues = (values) => {
    setFormValues(values);
    setRegisterContent(FORM_VALUES, values);
  };

  const handleNoneExistentUserSubmit = (values, setFieldError) => {
    const {
      LegalFirstName,
      LegalMiddleName,
      LegalLastName,
      Suffix,
      Username,
      Password,
      PhoneNumber,
      SecurityQuestion,
      Answer,
    } = values;

    if (PhoneNumber) {
      if (!isPossiblePhoneNumber(PhoneNumber)) {
        setFieldError(
          'PhoneNumber',
          i18next.t('validationSchema.general.phoneNumberNotValidLength'),
        );
        return;
      }

      if (!isValidPhoneNumber(PhoneNumber)) {
        setFieldError(
          'PhoneNumber',
          i18next.t('validationSchema.general.phoneNumberNotValidFormat'),
        );
        return;
      }
    }

    const parsedNumber = parsePhoneNumber(PhoneNumber);
    const formatPhoneNumber = formatPhoneNumberIntl(PhoneNumber);
    const splitPhoneNumber = formatPhoneNumber.split(' ');

    const iso3CountryCode =
      parsedNumber && getCountryISO3(parsedNumber.country);

    const parsedNumberObject = {
      Iso3CountryCode: iso3CountryCode,
      AreaCode: splitPhoneNumber[1],
      LocalNumber: `${splitPhoneNumber[2] || ''}${splitPhoneNumber[3] || ''}`,
    };

    const { number } = parsedNumber;

    const phoneNumberObject = {
      ...parsedNumberObject,
      number,
      isPrimary: false,
    };

    const primaryPhoneNumber = phoneNumberObject;

    const inviteUserData = {
      Username,
      Password,
      InvitedUserInfo: {
        FirstName: LegalFirstName,
        MiddleName: LegalMiddleName,
        LastName: LegalLastName,
        SuffixType: Suffix,
        PrimaryPhone: {
          Iso3CountryCode: primaryPhoneNumber?.Iso3CountryCode || '',
          AreaCode: shouldIgnoreAreaCodeHelperViaIso3(
            primaryPhoneNumber?.Iso3CountryCode,
            selectedISOCodesListToIgnoreAreaCode,
          )
            ? ''
            : primaryPhoneNumber?.AreaCode,

          LocalNumber: primaryPhoneNumber?.LocalNumber || '',
        },
        SecurityQuestionType:
          accountSecurityQuestionTypesObject[SecurityQuestion.value],
        SecurityAnswer: Answer,
        EncryptedPersonEntity: userInviteId,
      },
    };
    dispatch(
      inviteUser({
        data: inviteUserData,
        meta: {
          handleNext: () => handleNext(INVITE_USER_WELCOME_PAGE),
          createSessionUser: () =>
            setRegisterContent(INVITE_USER_DATA, inviteUserData),
          setError,
          setStatusModal,
        },
      }),
    );
    const reviewValue = { values };
    setReviewValues(reviewValue);
  };

  const handleExistentUserSubmit = (values) => {
    const { Username, Password } = values;

    const payload = getBrowserPayload({ Username: Username.value, Password });

    dispatch(clearLoginErrors());

    dispatch(
      fetchUser({
        ...payload,
      }),
    );

    const reviewValue = { values };
    setReviewValues(reviewValue);
  };

  const handleSubmit = (values, { setFieldError }) => {
    if (isExisting === NO) {
      handleNoneExistentUserSubmit(values, setFieldError);
    } else {
      handleExistentUserSubmit(values);
    }
  };

  useEffect(() => {
    const jwtRefreshToken = retrieveFromSessionStorage(JWT_REFRESH_TOKEN);
    const jwtToken = retrieveFromSessionStorage(JWT_TOKEN);

    if (jwtRefreshToken && jwtToken) {
      removeFromSessionStorage(JWT_TOKEN);
      removeFromSessionStorage(JWT_REFRESH_TOKEN);
    }
  }, []);

  useEffect(() => {
    setCurrentStep(0);
  }, [setCurrentStep]);

  useEffect(() => {
    function redirectClient() {
      if (!tokens.RefreshToken && !tokens.JwtToken) {
        return;
      }

      if (tokens.RefreshToken) {
        if (IsBroker2FAActive && twoFaAuthenticationResponse) {
          if (twoFaAuthenticationResponse.AuthenticationMethodType === 2) {
            return history.push(INVITE_USER_TWO_FA_VERIFY_IDENTITY_PHONE);
          }
          return history.push(INVITE_USER_TWO_FA_VERIFY_IDENTITY);
        }

        return history.push(INVITE_USER_IDENTITY);
      }
    }

    redirectClient();
  }, [IsBroker2FAActive, history, tokens, twoFaAuthenticationResponse]);

  const initialValues = getRegisterContent(FORM_VALUES)
    ? getRegisterContent(FORM_VALUES).values
    : {
        SuffixType: '',
        LegalFirstName: '',
        LegalMiddleName: '',
        LegalLastName: '',
        PhoneNumber: '',
        Username: isExisting === YES ? usernames[0] : email,
        Password: '',
        ConfirmPassword: '',
        SecurityQuestion: '',
        Answer: '',
      };
  return (
    <RegisterStep
      title={
        isExisting === YES
          ? i18next.t('inviteUser.credentials.titleExisting')
          : i18next.t('inviteUser.credentials.titleCreate')
      }
    >
      <Formik
        initialValues={initialValues}
        validationSchema={
          isExisting === YES
            ? userInviteExistingFormValidationSchema
            : userInviteNewFormValidationSchema
        }
        onSubmit={handleSubmit}
        initialTouched={{ PhoneNumber: true }}
      >
        {({ values, errors, setFieldValue, setFieldError }) => (
          <SectionLoader isLoading={isLoading}>
            <Form>
              <FormContainer>
                {isExisting === NO ? (
                  <CreateUserFormSection
                    setPhoneNumbers={setPhoneNumbers}
                    setFieldValue={setFieldValue}
                    phoneNumbers={phoneNumbers}
                    values={values}
                    setFieldError={setFieldError}
                    suffixOptions={SUFFIX_TYPES_ARRAY}
                    errors={errors}
                  />
                ) : (
                  <CredentialsFormSection values={values} />
                )}
                <ModalButtons
                  isHorizontal
                  mediumButtons
                  secondaryButtonProps={{
                    onClick: () => history.push(INVITE_USER_PAGE),
                  }}
                  primaryButtonProps={{
                    type: 'submit',
                    label: i18next.t('common.continue'),
                  }}
                />
              </FormContainer>
            </Form>
            {error && statusModal && (
              <StatusModal
                isOpen={statusModal}
                hasError={error}
                close={() => {
                  setStatusModal(false);
                  setError('');
                  closeWizardContent(ERROR);
                  closeWizardContent(USER_INVITE_STATUS_MODAL);
                }}
                text={error}
                modalTitle={i18next.t('inviteUser.review.statusModal')}
                backButtonText={i18next.t('common.ok')}
                onButtonClick={() => {
                  setStatusModal(false);
                  setError('');
                  closeWizardContent(ERROR);
                  closeWizardContent(USER_INVITE_STATUS_MODAL);
                }}
              />
            )}
          </SectionLoader>
        )}
      </Formik>
    </RegisterStep>
  );
};

Credentials.propTypes = {
  setCurrentStep: PropTypes.func,
  handleNext: PropTypes.func,
  formValues: PropTypes.shape({}),
  setFormValues: PropTypes.func,
  phoneNumbers: PropTypes.arrayOf(PropTypes.shape({})),
  setPhoneNumbers: PropTypes.func,
  userInviteId: PropTypes.string,
};

export default Credentials;
