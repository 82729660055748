import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatMoneyNumeral } from '../../util/helpers/numeralHelpers';
import { uFlexColumn } from '../../assets/styles/utility';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';
import themeColors from '../../assets/styles/themeColors';

export const MonthlyInvestmentContainer = styled.div`
  ${uFlexColumn};
  margin-bottom: ${pxToRem(40)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-bottom: ${pxToRemMd(40)};
  }
`;

export const MonthlyInvestmentTitle = styled.h3`
  font-weight: bold;
  font-size: ${pxToRem(18)};
  line-height: 1.33;
  margin-bottom: ${pxToRem(16)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(18)};
    line-height: 1.33;
    margin-bottom: ${pxToRemMd(16)};
  }
`;

export const MonthlyInvestmentValue = styled.p`
  font-size: ${pxToRem(18)};
  line-height: 1.33;
  color: v ${themeColors.colorSuccess};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(18)};
  }
`;

const MonthlyInvestment = ({ value }) => {
  const { t } = useTranslation();

  return (
    <MonthlyInvestmentContainer>
      <MonthlyInvestmentTitle>
        {t('metalStream.monthlyAmountInvestmentLabel')}
      </MonthlyInvestmentTitle>
      <MonthlyInvestmentValue>
        {formatMoneyNumeral(value)}
      </MonthlyInvestmentValue>
    </MonthlyInvestmentContainer>
  );
};

MonthlyInvestment.propTypes = {
  value: PropTypes.number,
};

export default MonthlyInvestment;
