import { call, takeLatest, all, put } from '@redux-saga/core/effects';
import { FETCH_ACCOUNT_IRA_SETTINGS } from '../actions/iraSettings/iraSettingsActionConstants';
import {
  fetchAccountIraSettingsSuccess,
  fetchAccountIraSettingsError,
} from '../actions/iraSettings/iraSettingsActions';
import { getIraSettings } from '../../request/iraSettingsRequests';
import { rejectErrorCodeHelper } from '../../util/helpers/rejectErrorCodeHelper';

function* fetchIraSettings({ payload }) {
  try {
    const { data } = yield call(getIraSettings, payload.accountUid);
    yield put(fetchAccountIraSettingsSuccess(data));
  } catch (error) {
    const errorMessage = yield call(rejectErrorCodeHelper, error);
    yield put(fetchAccountIraSettingsError(errorMessage));
  }
}

export default function* iraSettingsSaga() {
  yield all([takeLatest(FETCH_ACCOUNT_IRA_SETTINGS, fetchIraSettings)]);
}
