import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { getCurrentAccount } from '../../../../store/selectors/accountSelectors';
import { selectIsLoadingByActionTypes } from '../../../../store/selectors/loadingSelectors';
import { PLAID_LINK_TOKEN_LOADING } from '../../../../store/actions/plaid/PlaidActionConstants';
import { selectLinkToken } from '../../../../store/selectors/plaidSelectors';
import { fetchLinkToken } from '../../../../store/actions/plaid/PlaidActions';
import {
  fetchBankAccountsByType,
  submitNewAchAccount,
} from '../../../../store/actions/bankAccount/bankAccountActions';
import TypeList from '../../../TypeList/TypeList';
import ModalBody from '../../../Modal/ModalBody';
import SectionLoader from '../../../Loader/SectionLoader';
import TypeCard from '../../../TypeCard/TypeCard';
import PlaidCard from '../../../PlaidCard/PlaidCard';
import {
  BANK_ACCOUNTS_BY_TYPE_FETCH,
  SUBMIT_NEW_ACH_LOADING,
} from '../../../../store/actions/bankAccount/bankAccountActionConstants';
import useGtmHook from '../../../../util/hooks/useGtmHook';
import { BANK_ACCOUNT_ADDED } from '../../../../constants/gtmEvents';

const AddAchBankAccountSelectType = ({
  setWizardData,
  goTwoStepsForward,
  handleNext,
  disableManualAch,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { AccountUid } = useSelector(getCurrentAccount);
  const isLoading = useSelector(
    selectIsLoadingByActionTypes([
      SUBMIT_NEW_ACH_LOADING,
      BANK_ACCOUNTS_BY_TYPE_FETCH,
      PLAID_LINK_TOKEN_LOADING,
    ]),
  );

  const linkToken = useSelector(selectLinkToken);

  const { bankAccountGtmEvent } = useGtmHook();

  useEffect(() => {
    if (AccountUid) {
      dispatch(fetchLinkToken());
    }
  }, [AccountUid]); // eslint-disable-line

  const onSuccess = useCallback(
    (token, metadata) => {
      const handleGtmEvent = () => {
        bankAccountGtmEvent(
          BANK_ACCOUNT_ADDED,
          'AchDeposit',
          metadata.account.subtype,
          'USA',
        );
      };
      const handleApiResponse = (errorMessage) => {
        dispatch(
          fetchBankAccountsByType({
            accountUid: AccountUid,
            type: 'achdeposit',
          }),
        );

        setWizardData({ errorMessage });
        goTwoStepsForward();
      };

      const bankAccountIdArray = metadata.accounts.map((account) => account.id);
      const data = {
        PlaidDetails: {
          PublicToken: token,
          BankName: metadata.institution.name,
          BankAccounts: bankAccountIdArray,
        },
        BankAccountType: 'Savings',
        BankAccountUid: uuid(),
        PurposeType: 'AchDeposit',
      };

      dispatch(
        submitNewAchAccount({
          data,
          accountUid: AccountUid,
          handleApiResponse,
          onSuccess: handleGtmEvent,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, AccountUid],
  );

  const onExit = useCallback((err) => {
    if (err != null && err.error_code === 'INVALID_LINK_TOKEN') {
      dispatch(fetchLinkToken());
    }
  }, []); // eslint-disable-line

  return (
    <>
      <SectionLoader isLoading={isLoading}>
        <ModalBody>
          <TypeList>
            {linkToken && (
              <PlaidCard
                linkToken={linkToken}
                onSuccess={onSuccess}
                onExit={onExit}
              />
            )}
            <TypeCard
              title={t('achDeposit.chooseAchAddMethod.customCardTitle')}
              description={`${t(
                'standardDepositWizard.achDeposit.achDepositVoidedCheckNote',
              )} \n ${t(
                'achDeposit.chooseAchAddMethod.customCardDescription',
              )}`}
              note={t('achDeposit.chooseAchAddMethod.customCardNote')}
              onClick={() => {
                handleNext();
              }}
              disabled={disableManualAch}
              approvedAccountRequired={disableManualAch}
            />
          </TypeList>
        </ModalBody>
      </SectionLoader>
    </>
  );
};

AddAchBankAccountSelectType.propTypes = {
  setWizardData: PropTypes.func,
  goTwoStepsForward: PropTypes.func,
  handleNext: PropTypes.func,
  disableManualAch: PropTypes.func,
};

export default AddAchBankAccountSelectType;
