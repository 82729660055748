import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import CurrencyField from '../../../InputFields/CurrencyField';
import { TYPE_DISTRIBUTION } from '../../../../util/constants';
import { fetchDocumentByType } from '../../../../store/actions/documents/documentActions';
import { accountDocumentTypes } from '../../../../util/enum/api/accountDocumentTypes';
import AllFundsModal from '../../AllFundsModal';
import { getCurrentAccountUid } from '../../../../store/selectors/accountSelectors';
import { formatMoneyNumeral } from '../../../../util/helpers/numeralHelpers';
import { setWizardContent } from '../../../../util/helpers/wizardHelpers';
import { WITHDRAWAL_AMOUNT } from '../../../../constants/sessionStorage';
import useCheckPermissions from '../../../../util/hooks/useCheckPermissions';
import Note from '../../../Notes/Note';
import SectionLoader from '../../../Loader/SectionLoader';
import { amountValidationSchema } from '../../../../validation/amountValidationSchema';
import ModalBody from '../../../Modal/ModalBody';
import Paragraph from '../../../Paragraph/Paragraph';
import ModalButtons from '../../../Modal/ModalButtons';
import Label from '../../../Notes/Label';

const IraWithdrawAmount = ({
  balance,
  type,
  goStepForward,
  goInitialScreen,
  setAmountToWithdraw,
  closeModal,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isAllFundsModalOpen, setIsAllFundsModalOpen] = useState(false);
  const accountUid = useSelector(getCurrentAccountUid);
  const { displayIRAMilleniumOptionsPermission } = useCheckPermissions();
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const documentUrl =
    'https://www.mtrustcompany.com/sites/default/files/forms/static/IRA_Distribution_Request.pdf';

  const onFormSubmit = ({ amount }) => {
    setAmountToWithdraw(amount);
    setWizardContent(WITHDRAWAL_AMOUNT, amount);
    goStepForward();
  };

  const handleDownloadBtn = () => {
    if (displayIRAMilleniumOptionsPermission) {
      window.open(documentUrl, '_blank');
    } else {
      dispatch(
        fetchDocumentByType({
          accountUid,
          documentType: accountDocumentTypes.IRADistributionForm,
          setIsDownloadLoading,
        }),
      );
    }
  };

  return (
    <SectionLoader isLoading={isDownloadLoading}>
      <ModalBody>
        <Label marginBottom={4} isBold text={t('entrust.amount.available')} />

        <Paragraph fontSize={18} marginBottom={8}>
          {formatMoneyNumeral(balance)}
        </Paragraph>
        <Note
          text={t('entrust.amount.dontSee')}
          onClick={() => setIsAllFundsModalOpen(true)}
          marginBottom={32}
        />

        {type === TYPE_DISTRIBUTION && (
          <>
            <ModalButtons
              showOnlySecondary
              marginBottom
              secondaryButtonProps={{
                onClick: handleDownloadBtn,
                label: t('entrust.amount.download'),
              }}
            />
          </>
        )}

        <Formik
          initialValues={{ amount: 0 }}
          onSubmit={onFormSubmit}
          validationSchema={amountValidationSchema(balance)}
          initialTouched={{
            amount: true,
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <CurrencyField
                label={t('entrust.amount.label')}
                name="amount"
                defaultValue={values.amount}
                placeholder={formatMoneyNumeral(0)}
                onChange={(value) => setFieldValue('amount', value)}
              />
              <ModalButtons
                isHorizontal
                marginTop
                secondaryButtonProps={{ onClick: goInitialScreen }}
                primaryButtonProps={{
                  type: 'submit',
                  disabled:
                    Number(values.amount) <= 0 ||
                    Number(values.amount) > balance,
                }}
              />
            </Form>
          )}
        </Formik>
        {isAllFundsModalOpen && (
          <AllFundsModal
            isOpen={isAllFundsModalOpen}
            setIsOpen={setIsAllFundsModalOpen}
            closeModal={closeModal}
          />
        )}
      </ModalBody>
    </SectionLoader>
  );
};

IraWithdrawAmount.propTypes = {
  balance: PropTypes.number,
  type: PropTypes.string,
  goStepForward: PropTypes.func,
  goInitialScreen: PropTypes.func,
  setAmountToWithdraw: PropTypes.func,
  closeModal: PropTypes.func,
};

export default IraWithdrawAmount;
