import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import RouterStepByStep from '../../../components/RouterStepByStep/RouterStepByStep';
import {
  fetchTwoFa,
  fetchTwoFaCodeViaEmail,
  fetchTwoFaCodeViaPhone,
} from '../../../store/actions/twoFA/twoFAActions';
import { twoFaTypesEnum } from '../../../util/enum/api/twoFATypes';
import { parseEnumType } from '../../../util/helpers/enumMappers';
import { TWO_FA_TYPES } from '../../../constants/twoFaConstants';
import { selectTwoFaApplication } from '../../../store/selectors/twoFASelector';
import TwoFaForgotPasswordIdentityPage from './TwoFaForgotPasswordIdentityPage';
import TwoFaForgotPasswordIdentityPhonePage from './TwoFaForgotPasswordIdentityPhonePage';
import TwoFaForgotPasswordHavingTroublePage from './TwoFaForgotPasswordHavingTroublePage';
import {
  retrieveFromSessionStorage,
  storeInSessionStorage,
} from '../../../util/helpers/sessionStorageHelper';
import { TWO_FA } from '../../../constants/localStorage';

const TwoFaForgotPasswordPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const username =
    params.get('username') ||
    retrieveFromSessionStorage('ForgotPasswordUsername');
  const currentPath = history.location.pathname;
  const [twoFaValue, setValues] = useState(
    () => retrieveFromSessionStorage(TWO_FA) || {},
  );
  const twoFaApplication = useSelector(selectTwoFaApplication);

  const getTwoFaCode = (value) => {
    const { AuthenticationMethodType } = twoFaApplication;

    const parsedAuthenticationMethodType = parseEnumType(
      twoFaTypesEnum,
      AuthenticationMethodType,
    );

    if (parsedAuthenticationMethodType === TWO_FA_TYPES.Phone) {
      dispatch(
        fetchTwoFaCodeViaPhone({
          ...value,
        }),
      );
    }

    if (parsedAuthenticationMethodType === TWO_FA_TYPES.Email) {
      dispatch(
        fetchTwoFaCodeViaEmail({
          ...value,
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(fetchTwoFa({ username }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  useEffect(() => {
    storeInSessionStorage('ForgotPasswordUsername', username);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  const twoFaAuthenticateSteps = useCallback(
    (goStepBack, goStepForward) => {
      const compProps = {
        goStepBack,
        goStepForward,
        username,
        getTwoFaCode,
        twoFaApplication,
        twoFaValue,
        setValues,
      };

      return {
        '/forgot-password/verify-identity': (
          <TwoFaForgotPasswordIdentityPage {...compProps} />
        ),
        '/forgot-password/verify-identity-phone': (
          <TwoFaForgotPasswordIdentityPhonePage {...compProps} />
        ),
        '/forgot-password/having-trouble': (
          <TwoFaForgotPasswordHavingTroublePage {...compProps} />
        ),
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [twoFaApplication, twoFaValue, username],
  );

  return (
    <RouterStepByStep currentPath={currentPath}>
      {({ goStepBack, goStepForward, currentFormStep, goToFirstStep }) =>
        twoFaAuthenticateSteps(
          goStepBack,
          goStepForward,
          currentFormStep,
          goToFirstStep,
          twoFaValue,
          setValues,
        )
      }
    </RouterStepByStep>
  );
};

TwoFaForgotPasswordPage.propTypes = {};

export default TwoFaForgotPasswordPage;
