import { createSelector } from 'reselect';

const settingsSelector = (state) => state.settings;

export const selectSettings = createSelector(
  settingsSelector,
  (state) => state,
);

export const selectRegistrationUrl = createSelector(
  settingsSelector,
  (state) => state.RegistrationUrl,
);

export const selectBuyCashBufferPercentage = createSelector(
  settingsSelector,
  (state) => state.BuyCashBufferPercentage,
);

export const selectMetalStreamMinimumDepositAmount = createSelector(
  settingsSelector,
  (state) => state.MetaltreamMinimumDepositAmount,
);

export const selectMetalStreamMaximumDepositAmount = createSelector(
  settingsSelector,
  (state) => state.MetalStreamMaximumDepositAmount,
);

export const selectNumberOfAllowedAchDepositAccounts = createSelector(
  settingsSelector,
  (state) => state.NumberOfAllowedAchDepositAccounts,
);

export const selectAchDepositLimit = createSelector(
  settingsSelector,
  (state) => state.AchDepositLimit,
);

export const selectCheckDepositLimit = createSelector(
  settingsSelector,
  (state) => state.CheckDepositLimit,
);

export const selectSettlementDaysInfo = createSelector(
  settingsSelector,
  (state) => state.AchSettlementDaysInfo,
);

export const selectWireWithdrawalFee = createSelector(
  settingsSelector,
  (state) => state.WireWithdrawalFee,
);

export const selectIsReCaptchaEnabled = createSelector(
  settingsSelector,
  (state) => state.EnableGoogleReCaptcha,
);

export const selectIsTwoFAEnabled = createSelector(
  settingsSelector,
  (state) => state.IsBroker2FAActive,
);

export const selectPortalGatewayISOCodesListToIgnoreAreaCode = createSelector(
  settingsSelector,
  (state) => state.PortalGatewayISOCodesListToIgnoreAreaCode,
);

export const selectSupportEmail = createSelector(
  settingsSelector,
  (state) => state.SupportEmail,
);

export const selectAddUpdateEmailMailingAddressUrl = createSelector(
  settingsSelector,
  (state) => state.AddUpdateEmailMailingAddressUrl,
);

export const selectSegregatedStorageEnabled = createSelector(
  settingsSelector,
  (state) => state?.SegregatedStorageEnabled,
);

export const selectIsAddAddressesEnabled = createSelector(
  settingsSelector,
  (state) => state?.SelfServeAddressAllowed,
);

export const selectMailInEnabled = createSelector(
  settingsSelector,
  (state) => state?.MailInStorageEnabled,
);

export const selectMailInLearnMoreLink = createSelector(
  settingsSelector,
  (state) => state?.MailInLearnMoreUrl,
);
