import {
  FETCH_CLIENT_TOKEN,
  FETCH_CLIENT_TOKEN_ERROR,
  FETCH_CLIENT_TOKEN_SUCCESS,
  SUBMIT_CREDIT_CARD,
  SUBMIT_CREDIT_CARD_ERROR,
  CREDIT_CARD_FETCH,
  CREDIT_CARD_ERROR,
  CREDIT_CARD_SUCCESS,
  CREDIT_CARD_DELETE,
  CREDIT_CARD_DELETE_SUCCESS,
  CREDIT_CARD_DELETE_ERROR,
  SUBMIT_CREDIT_CARD_SUCCESS,
} from './creditCardActionConstants';

// fetch client token success callback
export const fetchClientTokenSuccess = (payload) => ({
  type: FETCH_CLIENT_TOKEN_SUCCESS,
  payload,
});

// fetch client token
export const fetchClientToken = (payload) => ({
  type: FETCH_CLIENT_TOKEN,
  payload,
});

// fetch client token error callback
export const fetchClientTokenError = (payload) => ({
  type: FETCH_CLIENT_TOKEN_ERROR,
  payload,
});

// add credit card
export const submitCreditCard = (payload) => ({
  type: SUBMIT_CREDIT_CARD,
  payload,
});

// add credit card success
export const submitCreditCardSuccess = (payload) => ({
  type: SUBMIT_CREDIT_CARD_SUCCESS,
  payload,
});

// add credit card error callback
export const submitCreditCardError = (payload) => ({
  type: SUBMIT_CREDIT_CARD_ERROR,
  payload,
});

// get credit cards
export const fetchCreditCards = (payload) => ({
  type: CREDIT_CARD_FETCH,
  payload,
});

export const fetchCreditCardsSuccess = (payload) => ({
  type: CREDIT_CARD_SUCCESS,
  payload,
});

export const fetchCreditCardsError = (payload) => ({
  type: CREDIT_CARD_ERROR,
  payload,
});

// delete credit cards
export const deleteCreditCard = (payload) => ({
  type: CREDIT_CARD_DELETE,
  payload,
});

export const deleteCreditCardSuccess = (payload) => ({
  type: CREDIT_CARD_DELETE_SUCCESS,
  payload,
});

export const deleteCreditCardError = (payload) => ({
  type: CREDIT_CARD_DELETE_ERROR,
  payload,
});
