import React, { useCallback, useEffect, useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';

import { useTranslation } from 'react-i18next';
import InfiniteHitsWithScrollToTop from '../components/InfiniteHitsWithScrollToTop/InfiniteHitsWithScrollToTop';
import SortBy from '../components/SortBy/SortBy';
import NavigationBar from '../components/NavigationBar/NavigationBar';

import config from '../config';
import Section from '../components/Section/Section';
import Filter from '../components/Filter/Filter';
import { ReactComponent as FilterIcon } from '../assets/images/svg/filter.svg';
import useWindowSize from '../util/hooks/useIsMobileHook';
import { fetchMyHoldingsCodes } from '../store/actions/portfolio/portfolioActions';
import { getCurrentAccount } from '../store/selectors/accountSelectors';
import useGetPeriodicProductCodes from '../util/hooks/useGetPeriodicProductCodes';
import PriceTierModal from '../components/Modals/Prices/PriceTierModal';
import Checkbox from '../components/InputFields/Checkbox';
import FilterModal from '../components/Modal/FilterModal';
import BlockSectionLoader from '../components/Loader/BlockSectionLoader';
import { selectIsLoadingByActionType } from '../store/selectors/loadingSelectors';
import { PRODUCT_PRICES_LOADING } from '../store/actions/products/productActionConstants';
import useGtmHook from '../util/hooks/useGtmHook';
import { fetchProductsWithPrices } from '../store/actions/products/productActions';
import { PORTFOLIO_PAGE } from '../constants/pages';
import { selectSettings } from '../store/selectors/settingsSelectors';
import { ProductSearch } from '../components/InputFields/ProductSearch';
import TradingFilter from '../components/Trading/TradingFilter';
import TradingContent from '../components/Trading/TradingContent';
import TradingHeader from '../components/Trading/TradingHeader';
import { TradingFilterButton } from '../components/Trading/TradingFilterButton';
import SellTradingContainer from '../components/Trading/SellTradingContainer';
import VirtualFilter from '../components/Filter/VirtualFilters';
import NagDashboardBanner from '../components/NagDashboard/NagDashboardBanner';
import { ACCOUNT_DATA_LOADED } from '../constants/gtmEvents';
import { COUNTDOWN_TIMER_INTERVAL_MS } from '../constants/timerConstants';
import { setWizardContent } from '../util/helpers/wizardHelpers';
import {
  IS_PRODUCT_FRACTIONAL,
  IS_INSTA_VAULT_PRODUCT,
} from '../constants/sessionStorage';
import { PRODUCT_TYPE_FRACTIONAL } from '../util/enum/api/productTypes';
import { useSessionStorageState } from '../util/hooks/useSessionStorageState';

const { apiKey, apiSecret, apiSellIndex } = config.algoliaSearch;
const searchClient = algoliasearch(apiKey, apiSecret);
const { brokerTheme } = config;

const SellPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isGS = brokerTheme === 'GoldSilver';
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMyHoldingsChecked, setIsMyHoldingsChecked] = useState(true);
  const [products, setProducts] = useState(null);
  const [isPriceTiersOpen, setIsPriceTiersOpen] = useState(false);
  const [priceTiersData, setPriceTiersData] = useState({});
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 1200;
  const account = useSelector(getCurrentAccount);
  const settings = useSelector(selectSettings);
  const isLoadingProducts = useSelector(
    selectIsLoadingByActionType(PRODUCT_PRICES_LOADING),
  );
  const [isFractionalProduct, setIsFractionalProduct] = useSessionStorageState(
    IS_PRODUCT_FRACTIONAL,
    false,
  );
  const [isInstaVaultProduct, setIsInstaVaultProduct] = useSessionStorageState(
    IS_INSTA_VAULT_PRODUCT,
    false,
  );
  const [searchState, setSearchState] = useState({});
  const [isLockedPrice, setIsLockedPrice] = useState(true);

  const myHoldingsChangeHandler = ({ target: { checked } }) =>
    setIsMyHoldingsChecked(checked);

  const priceTiersClickHandler = (product, location) => {
    setWizardContent(
      IS_PRODUCT_FRACTIONAL,
      product.ProductType === PRODUCT_TYPE_FRACTIONAL,
    );
    setIsFractionalProduct(product.ProductType === PRODUCT_TYPE_FRACTIONAL);
    const isInstavault =
      isGS &&
      (product.ProductCode === settings.InstavaultProductCodes[0] ||
        product.ProductCode === settings.InstavaultProductCodes[1]);
    setWizardContent(IS_INSTA_VAULT_PRODUCT, isInstavault);
    setIsInstaVaultProduct(isInstavault);
    setIsPriceTiersOpen(true);
    setPriceTiersData(location);
  };

  const [counterValue, setCounterValue] = useState(
    Math.floor(
      settings.PricingRefreshFrequencyMilliseconds /
        COUNTDOWN_TIMER_INTERVAL_MS,
    ),
  );
  const [unityValue, setUnityValue] = useState(false);

  const periodicCallback = useCallback((epoch) => {
    if (epoch?.data) {
      const [counter, unity] = epoch.data;
      setCounterValue((prevCounter) => prevCounter - prevCounter + counter);
      setUnityValue(unity);
    }
  }, []);

  useGetPeriodicProductCodes(
    'sell',
    account,
    'Sell',
    !!products || (!isEmpty(products) && products.length),
    settings?.PricingRefreshFrequencyMilliseconds,
    !isLockedPrice ? periodicCallback : null,
  );

  const { gtmScreenView, appGtmEvent } = useGtmHook();

  const getPrices = () => {
    dispatch(
      fetchProductsWithPrices({
        accountUid: account.AccountUid,
        side: 'Sell',
      }),
    );
  };

  useEffect(() => {
    gtmScreenView({
      path: window.location.pathname,
      title: 'Sell',
    });
    appGtmEvent(ACCOUNT_DATA_LOADED);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!isEmpty(account) && account.AccountUid) {
      dispatch(
        fetchMyHoldingsCodes({
          accountUid: account.AccountUid,
        }),
      );
    }
  }, [isMyHoldingsChecked, account, account.AccountUid, dispatch]);

  useEffect(() => {
    if (!isEmpty(account) && account.AccountUid) {
      getPrices();
    }
  }, [account.AccountUid, dispatch]); // eslint-disable-line

  useEffect(() => {
    if (!settings || isEmpty(settings)) {
      return;
    }

    setIsLockedPrice(settings?.IsLockedPricingActive);
  }, [settings]);

  return (
    <>
      <NagDashboardBanner />
      <NavigationBar
        hasBack
        pageRoute={PORTFOLIO_PAGE}
        mainPage={t('header.navDropdownTrading')}
        subPage={t('header.linkSell')}
      />
      <Section>
        <SellTradingContainer>
          <InstantSearch
            indexName={apiSellIndex}
            searchClient={searchClient}
            searchState={searchState}
            onSearchStateChange={setSearchState}
          >
            {isMobile && <VirtualFilter />}
            {!isMobile && (
              <TradingFilter data-cy="container-filters">
                {!products ? (
                  <BlockSectionLoader isLoading />
                ) : (
                  <Filter
                    products={products}
                    isForSell
                    myHoldingsChangeHandler={myHoldingsChangeHandler}
                    isMyHoldingsChecked={isMyHoldingsChecked}
                  />
                )}
              </TradingFilter>
            )}
            <TradingContent data-cy="container-product-list">
              <TradingHeader>
                <div>
                  <SortBy
                    items={[
                      {
                        value: apiSellIndex,
                        label: t('sortBy.popularity'),
                        dataCy: 'menu-item-product-sort-popularity',
                      },
                      {
                        value: `${apiSellIndex}_prices_asc`,
                        label: t('sortBy.lowToHigh'),
                        dataCy: 'menu-item-product-sort-low-to-high',
                      },
                      {
                        value: `${apiSellIndex}_prices_desc`,
                        label: t('sortBy.highToLow'),
                        dataCy: 'menu-item-product-sort-high-to-low',
                      },
                    ]}
                    defaultRefinement={apiSellIndex}
                    getPrices={getPrices}
                  />
                  <ProductSearch />
                  <TradingFilterButton
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                  >
                    <FilterIcon />
                  </TradingFilterButton>
                </div>

                {isMobile && (
                  <Checkbox
                    variant="MyHoldingsCheckbox"
                    onChange={myHoldingsChangeHandler}
                    checked={isMyHoldingsChecked}
                  >
                    {t('portfolio.holdings.myHoldingsOnlyLabel')}
                  </Checkbox>
                )}
              </TradingHeader>
              <InfiniteHitsWithScrollToTop
                isMyHoldingsChecked={isMyHoldingsChecked}
                priceTiersClickHandler={priceTiersClickHandler}
                page="sell"
                side="sell"
                setProducts={setProducts}
                products={products}
                isLoading={isLoadingProducts}
                isLockedPrice={isLockedPrice}
                counter={counterValue}
                unity={unityValue}
              />
              <PriceTierModal
                isOpen={isPriceTiersOpen}
                data={priceTiersData}
                account={account}
                tradingSide="Sell"
                handleClose={() => setIsPriceTiersOpen(false)}
                handleBack={() => setIsPriceTiersOpen(false)}
                isInstaVaultProduct={isInstaVaultProduct}
                isFractionalProduct={isFractionalProduct}
              />
            </TradingContent>
            {isMobile && (
              <FilterModal
                title="Filters"
                isOpen={isModalOpen && isMobile}
                close={setIsModalOpen}
                size="sm"
              >
                <SortBy
                  items={[
                    {
                      value: apiSellIndex,
                      label: t('sortBy.popularity'),
                      dataCy: 'menu-item-product-sort-popularity-mobile',
                    },
                    {
                      value: `${apiSellIndex}_prices_asc`,
                      label: t('sortBy.lowToHigh'),
                      dataCy: 'menu-item-product-sort-low-to-high-mobile',
                    },
                    {
                      value: `${apiSellIndex}_prices_desc`,
                      label: t('sortBy.highToLow'),
                      dataCy: 'menu-item-product-sort-high-to-low-mobile',
                    },
                  ]}
                  defaultRefinement={apiSellIndex}
                  getPrices={getPrices}
                />
                {isEmpty(products) ? (
                  <BlockSectionLoader isLoading />
                ) : (
                  <>
                    <InstantSearch
                      indexName={apiSellIndex}
                      searchClient={searchClient}
                      searchState={searchState}
                      onSearchStateChange={setSearchState}
                    >
                      <Filter
                        products={products}
                        myHoldingsChangeHandler={myHoldingsChangeHandler}
                        isMyHoldingsChecked={isMyHoldingsChecked}
                        close={setIsModalOpen}
                      />
                    </InstantSearch>
                  </>
                )}
              </FilterModal>
            )}
          </InstantSearch>
        </SellTradingContainer>
      </Section>
    </>
  );
};

export default SellPage;
