import createReducer from '../../utils/createReducer';
import {
  SET_USER,
  SET_USER_ERROR,
  USER_SECURITY_QUESTION_FETCH_SUCCESS,
  USER_SECURITY_QUESTION_FETCH_ERROR,
  PASSWORD_FORGOTTEN_REQUEST_SUCCESS,
  PASSWORD_FORGOTTEN_REQUEST_ERROR,
  RESET_PASSWORD_REQUEST_SUCCESS,
  RESET_PASSWORD_REQUEST_ERROR,
  UPDATE_USER_STATE_ERROR,
  FETCH_USERSNAMES_SUCCESS,
  FETCH_USERSNAMES_ERROR,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  USER_REGISTRATION_ACCOUNTS_FETCH_SUCCESS,
  USER_REGISTRATION_ACCOUNTS_FETCH_ERROR,
  INVITE_USER_SUCCESS,
  INVITE_USER_ERROR,
  UPDATE_USER_REGISTRATION_SUCCESS,
  UPDATE_USER_REGISTRATION_ERROR,
  SECURITY_QUESTION_BY_USERNAME_SUCCESS,
  SECURITY_QUESTION_BY_USERNAME_ERROR,
  FORGOT_PASSWORD_REQUEST_SUCCESS,
  FORGOT_PASSWORD_REQUEST_ERROR,
  REGISTRATION_RESET_PASSWORD_REQUEST_SUCCESS,
  REGISTRATION_RESET_PASSWORD_REQUEST_ERROR,
  USER_PHONE_NUMBERS_SUCCESS,
  USER_PHONE_NUMBERS_ERROR,
  USER_PHONE_NUMBER_SUCCESS,
  USER_PHONE_NUMBER_ERROR,
  DELETE_USER_PHONE_NUMBER_ERROR,
  DELETE_USER_PHONE_NUMBER_SUCCESS,
  INVITE_USER_EMAIL_SUCCESS,
  INVITE_USER_EMAIL_ERROR,
  SUBMIT_PHONE_ERROR,
  SUBMIT_PHONE_SUCCESS,
} from '../../actions/user/userActionConstants';

const initialState = {
  user: {},
  inviteUser: {},
  inviteUserEmail: '',
  permissions: [],
  phoneNumbers: [],
  twoFAPhoneNumber: '',
  securityQuestion: {
    data: [],
    errorMessage: '',
  },
  registration: {
    users: [],
    accounts: [],
    createdUser: {},
    securityQuestion: '',
    error: '',
  },
  errorMessage: '',
  isSuccessfulSubmit: null,
};

export default createReducer(
  {
    [SET_USER]: setUser,
    [SET_USER_ERROR]: setUserError,
    [USER_SECURITY_QUESTION_FETCH_SUCCESS]: fetchUserSecurityQuestion,
    [USER_SECURITY_QUESTION_FETCH_ERROR]: fetchUserSecurityQuestionError,
    [PASSWORD_FORGOTTEN_REQUEST_SUCCESS]: passwordForgottenRequest,
    [PASSWORD_FORGOTTEN_REQUEST_ERROR]: passwordForgottenRequestError,
    [FORGOT_PASSWORD_REQUEST_SUCCESS]: forgotPasswordRequest,
    [FORGOT_PASSWORD_REQUEST_ERROR]: forgotPasswordRequestError,
    [RESET_PASSWORD_REQUEST_SUCCESS]: resetPasswordRequest,
    [RESET_PASSWORD_REQUEST_ERROR]: resetPasswordRequestError,
    [UPDATE_USER_STATE_ERROR]: updateUserStateError,
    [FETCH_USERSNAMES_SUCCESS]: getUsernames,
    [FETCH_USERSNAMES_ERROR]: getUsernamesError,
    [CREATE_USER_SUCCESS]: createUser,
    [CREATE_USER_ERROR]: createUserError,
    [UPDATE_USER_REGISTRATION_SUCCESS]: updateUserRegistration,
    [UPDATE_USER_REGISTRATION_ERROR]: updateUserRegistrationError,
    [USER_REGISTRATION_ACCOUNTS_FETCH_SUCCESS]: fetchRegistrationAccounts,
    [USER_REGISTRATION_ACCOUNTS_FETCH_ERROR]: fetchRegistrationAccountsError,
    [INVITE_USER_SUCCESS]: inviteUser,
    [INVITE_USER_ERROR]: inviteUserError,
    [INVITE_USER_EMAIL_SUCCESS]: inviteUserEmail,
    [INVITE_USER_EMAIL_ERROR]: inviteUserEmailError,
    [SECURITY_QUESTION_BY_USERNAME_SUCCESS]: getSecurityQuestionByUsername,
    [SECURITY_QUESTION_BY_USERNAME_ERROR]: getSecurityQuestionByUsernameError,
    [REGISTRATION_RESET_PASSWORD_REQUEST_SUCCESS]: registerResetPasswordRequest,
    [REGISTRATION_RESET_PASSWORD_REQUEST_ERROR]: registerResetPasswordRequestError,
    [USER_PHONE_NUMBERS_SUCCESS]: fetchUserPhoneNumbers,
    [USER_PHONE_NUMBERS_ERROR]: fetchUserPhoneNumbersError,
    [USER_PHONE_NUMBER_SUCCESS]: fetchUserPhoneNumber,
    [USER_PHONE_NUMBER_ERROR]: fetchUserPhoneNumberError,
    [DELETE_USER_PHONE_NUMBER_SUCCESS]: deleteUserPhoneNumber,
    [DELETE_USER_PHONE_NUMBER_ERROR]: deleteUserPhoneNumberError,
    [SUBMIT_PHONE_SUCCESS]: postPhoneNumberSuccess,
    [SUBMIT_PHONE_ERROR]: postPhoneNumberError,
  },
  initialState,
);

function createUser(state, { payload }) {
  return {
    ...state,
    registration: {
      ...state.registration,
      createdUser: payload,
    },
  };
}

function createUserError(state, { payload }) {
  return {
    ...state,
    registration: {
      ...state.registration,
      error: payload,
    },
  };
}

function updateUserRegistration(state, { payload }) {
  return {
    ...state,
    registration: {
      ...state.registration,
      createdUser: payload,
    },
  };
}

function updateUserRegistrationError(state, { payload }) {
  return {
    ...state,
    registration: {
      ...state.registration,
      error: payload,
    },
  };
}

function inviteUser(state, action) {
  return {
    ...state,
    inviteUser: action.payload,
    errorMessage: '',
  };
}

function inviteUserError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}

function inviteUserEmail(state, action) {
  return {
    ...state,
    inviteUserEmail: action.payload,
    errorMessage: '',
  };
}

function inviteUserEmailError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}

function getUsernames(state, action) {
  return {
    ...state,
    registration: {
      ...state.registration,
      users: action.payload,
    },
  };
}

function getUsernamesError(state, action) {
  return {
    ...state,
    registration: {
      ...state.registration,
      error: action.payload,
    },
  };
}

function setUser(state, action) {
  return {
    ...state,
    user: action.payload,
  };
}

function setUserError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}

function fetchUserSecurityQuestion(state, action) {
  return {
    ...state,
    securityQuestion: {
      ...state.securityQuestion,
      data: action.payload,
    },
  };
}

function fetchUserSecurityQuestionError(state, { payload }) {
  return {
    ...state,
    securityQuestion: {
      ...state.securityQuestion,
      errorMessage: payload,
    },
  };
}

function passwordForgottenRequest(state, action) {
  return {
    ...state,
    user: action.payload,
    errorMessage: '',
  };
}

function passwordForgottenRequestError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}

function forgotPasswordRequest(state, action) {
  return {
    ...state,
    registration: {
      ...state.registration,
      createdUser: action.payload,
      error: '',
    },
  };
}

function forgotPasswordRequestError(state, action) {
  return {
    ...state,
    registration: {
      ...state.registration,
      error: action.payload,
    },
  };
}

function resetPasswordRequest(state, action) {
  return {
    ...state,
    user: action.payload,
    errorMessage: '',
  };
}

function resetPasswordRequestError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}

function registerResetPasswordRequest(state, action) {
  return {
    ...state,
    registration: {
      ...state.registration,
      createdUser: action.payload,
      error: '',
    },
  };
}

function registerResetPasswordRequestError(state, action) {
  return {
    ...state,
    registration: {
      ...state.registration,
      error: action.payload,
    },
  };
}

function updateUserStateError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}

function fetchRegistrationAccounts(state, action) {
  return {
    ...state,
    registration: {
      ...state.registration,
      accounts: action.payload,
    },
    errorMessage: '',
  };
}

function fetchRegistrationAccountsError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}

function getSecurityQuestionByUsername(state, action) {
  return {
    ...state,
    registration: {
      ...state.registration,
      securityQuestion: action.payload,
    },
  };
}

function getSecurityQuestionByUsernameError(state, action) {
  return {
    ...state,
    registration: {
      ...state.registration,
      error: action.payload,
    },
  };
}

function fetchUserPhoneNumbers(state, action) {
  return {
    ...state,
    phoneNumbers: action.payload,
    errorMessage: '',
  };
}

function fetchUserPhoneNumbersError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}

function fetchUserPhoneNumber(state, action) {
  return {
    ...state,
    twoFAPhoneNumber: action.payload,
    errorMessage: '',
  };
}

function fetchUserPhoneNumberError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}
function deleteUserPhoneNumber(state) {
  return {
    ...state,
    errorMessage: '',
  };
}

function deleteUserPhoneNumberError(state, action) {
  return {
    ...state,
    errorMessage: action.payload,
  };
}

function postPhoneNumberSuccess(state) {
  return {
    ...state,
    isSuccessfulSubmit: true,
  };
}

function postPhoneNumberError(state, { payload }) {
  return {
    ...state,
    newPhone: {
      ...state.newPhone,
      error: payload,
    },
  };
}
