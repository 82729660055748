import styled from 'styled-components';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';

const BulletList = styled.ul`
  margin-bottom: ${pxToRem(24)};
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${pxToRem(16)};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${pxToRem(marginBottom)}` : 0};
  margin-top: ${({ marginTop }) => (marginTop ? `${pxToRem(marginTop)}` : 0)};

  ${({ smallGap }) =>
    smallGap &&
    `
      grid-gap: ${pxToRem(8)};
  `};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-bottom: ${pxToRemMd(24)};
    grid-gap: ${pxToRemMd(16)};

    ${({ smallGap }) =>
      smallGap &&
      `
        grid-gap: ${pxToRemMd(8)};
    `};
  }
`;

export default BulletList;
