import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import ModalButtons from '../../../../Modal/ModalButtons';
import { formatMoneyNumeral } from '../../../../../util/helpers/numeralHelpers';
import CurrencyField from '../../../../InputFields/CurrencyField';
import BankAccountList from '../Components/BankAccountList';
import { ACC_PURPOSE_ACH } from '../../../../../util/enum/api/bankAccountTypes';
import FormContainer from '../../../../FormContainer/FormContainer';
import { achDepositValidationSchema } from '../../../../../validation/achDepositValidationSchema';
import { selectAchDepositLimit } from '../../../../../store/selectors/settingsSelectors';
import ModalBody from '../../../../Modal/ModalBody';
import FixBankAccount from '../../../BankAccount/FixBankAccount/FixBankAccount';
import { selectBankAccountsByType } from '../../../../../store/selectors/bankAccountSelectors';
import { fetchBankAccountsByType } from '../../../../../store/actions/bankAccount/bankAccountActions';
import { getCurrentAccount } from '../../../../../store/selectors/accountSelectors';
import { selectIsLoadingByActionType } from '../../../../../store/selectors/loadingSelectors';
import { BANK_ACCOUNTS_BY_TYPE_FETCH_LOADING } from '../../../../../store/actions/bankAccount/bankAccountActionConstants';
import SectionLoader from '../../../../Loader/SectionLoader';
import AddAchBankAccountWizard from '../../../BankAccount/AddAchBankAccount/AddAchBankAccountWizard';
import loadSignifydDeviceFingerprintScript from '../../../../../signifydDeviceFingerprint';
import { storeInSessionStorage } from '../../../../../util/helpers/sessionStorageHelper';
import { SIGNIFYD_SESSION_ID } from '../../../../../constants/sessionStorage';

const AchDeposit = ({
  handleNext,
  goToInitialModal,
  wizardData,
  setWizardData,
  setModalSize,
  closeEverything,
  setWizardBack,
  setWizardTitle,
  disableManualAch,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allowedAchDepositLimit = useSelector(selectAchDepositLimit);
  const { AccountUid } = useSelector(getCurrentAccount);
  const isLoading = useSelector(
    selectIsLoadingByActionType(BANK_ACCOUNTS_BY_TYPE_FETCH_LOADING),
  );
  const bankAccounts = useSelector(selectBankAccountsByType);

  useEffect(() => {
    loadSignifydDeviceFingerprintScript();
    const script = document.getElementById('sig-api');
    storeInSessionStorage(SIGNIFYD_SESSION_ID, script.dataset.orderSessionId);
  }, []);

  useEffect(() => {
    dispatch(
      fetchBankAccountsByType({
        accountUid: AccountUid,
        type: ACC_PURPOSE_ACH,
      }),
    );
  }, [AccountUid, dispatch]);

  const handleSubmit = (value) => {
    const { amount } = value;
    setWizardData({ amount });
    handleNext();
  };

  const handleSelectBankAccount = (bankAccount) => {
    setWizardData({ bankAccount });
  };

  const handleFixBankAccount = (event, bankAccount) => {
    event.stopPropagation();
    setWizardData({
      bankAccountForFix: bankAccount,
      isFixBankAccountWizardOpen: true,
    });
  };

  const closeHandleFixBankAccount = () => {
    setWizardData({
      bankAccountForFix: null,
      isFixBankAccountWizardOpen: false,
    });
  };

  const handleAddBankAccount = () => {
    setWizardData({
      isAddAchWizardOpen: true,
    });
  };

  const closeAddBankAccount = () => {
    setWizardData({
      isAddAchWizardOpen: false,
    });
  };

  useEffect(() => {
    if (wizardData?.isFixBankAccountWizardOpen) {
      setModalSize('md');
    } else {
      setModalSize('sm');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setModalSize, wizardData?.isFixBankAccountWizardOpen]);

  useEffect(() => {
    if (wizardData?.isAddAchWizardOpen) {
      setWizardBack(() => () => closeAddBankAccount());
      setWizardTitle(t('achDeposit.chooseAchAddMethod.modalTitle'));

      return;
    }

    if (wizardData?.isFixBankAccountWizardOpen) {
      setWizardBack(() => () => closeHandleFixBankAccount());
      setWizardTitle(
        t('standardDepositWizard.achDeposit.fixBankAccount.title'),
      );
      return;
    }

    setWizardBack(null);
    setWizardTitle(t('standardDepositWizard.achDeposit.achDepositTitle'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardData?.isAddAchWizardOpen, wizardData?.isFixBankAccountWizardOpen]);

  if (wizardData?.isFixBankAccountWizardOpen) {
    return (
      <ModalBody>
        <FixBankAccount
          wizardData={wizardData}
          handleClose={closeHandleFixBankAccount}
          closeEverything={closeEverything}
          setWizardData={setWizardData}
        />
      </ModalBody>
    );
  }

  if (wizardData?.isAddAchWizardOpen) {
    return (
      <AddAchBankAccountWizard
        wizardData={wizardData}
        setWizardData={setWizardData}
        onClose={closeAddBankAccount}
        disableManualAch={disableManualAch}
      />
    );
  }

  return (
    <SectionLoader isLoading={isLoading}>
      <ModalBody>
        <Formik
          initialValues={{ amount: wizardData?.amount || 0 }}
          onSubmit={handleSubmit}
          validationSchema={achDepositValidationSchema(allowedAchDepositLimit)}
          initialTouched={{ amount: true }}
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <Form>
              <FormContainer>
                <BankAccountList
                  label={t('funding.fundFrom')}
                  empty={!bankAccounts.length}
                  handleSelectBankAccount={handleSelectBankAccount}
                  selectedBankAccount={wizardData?.bankAccount}
                  handleFixBankAccount={handleFixBankAccount}
                  bankAccounts={bankAccounts}
                  handleAddBankAccount={handleAddBankAccount}
                />

                <CurrencyField
                  defaultValue={values.amount}
                  label={t('standardDepositWizard.achDeposit.achAmount')}
                  name="amount"
                  placeholder={formatMoneyNumeral(0)}
                  onChange={(value) => setFieldValue('amount', value)}
                />
              </FormContainer>
              <ModalButtons
                marginTop
                isHorizontal
                secondaryButtonProps={{ onClick: goToInitialModal }}
                primaryButtonProps={{
                  onClick: handleSubmit,
                  disabled: !(wizardData?.bankAccount && values?.amount),
                }}
              />
            </Form>
          )}
        </Formik>
      </ModalBody>
    </SectionLoader>
  );
};

AchDeposit.propTypes = {
  handleNext: PropTypes.func,
  goToInitialModal: PropTypes.func,
  wizardData: PropTypes.shape({
    bankAccount: PropTypes.shape({}),
    isFixBankAccountWizardOpen: PropTypes.bool,
    amount: PropTypes.number,
    isAddAchWizardOpen: PropTypes.bool,
  }),
  setWizardData: PropTypes.func,
  setModalSize: PropTypes.func,
  closeEverything: PropTypes.func,
  isAddAchWizardOpen: PropTypes.func,
  setWizardBack: PropTypes.func,
  setWizardTitle: PropTypes.func,
  disableManualAch: PropTypes.bool,
};

export default AchDeposit;
