import React from 'react';
import { ReactComponent as VisaIcon } from '../../../assets/images/svg/visa.svg';
import { ReactComponent as JcbIcon } from '../../../assets/images/svg/jcb.svg';
import { ReactComponent as MasterCardIcon } from '../../../assets/images/svg/mastercard.svg';
import { ReactComponent as AmericanExpressIcon } from '../../../assets/images/svg/american-express.svg';
import { ReactComponent as DiscoverIcon } from '../../../assets/images/svg/discover.svg';
import { ReactComponent as DinersClubIcon } from '../../../assets/images/svg/diners.svg';

export const CREDIT_CARDS_TYPES = {
  VISA: 1,
  MASTERCARD: 2,
  AMERICAN_EXPRESS: 3,
  DISCOVER: 4,
  JCB: 5,
  DINERS_CLUB: 6,
};

export default {
  [CREDIT_CARDS_TYPES.VISA]: 'visa',
  [CREDIT_CARDS_TYPES.MASTERCARD]: 'masterCard',
  [CREDIT_CARDS_TYPES.AMERICAN_EXPRESS]: 'americanExpress',
  [CREDIT_CARDS_TYPES.DISCOVER]: 'discover',
  [CREDIT_CARDS_TYPES.JCB]: 'jcb',
  [CREDIT_CARDS_TYPES.DINERS_CLUB]: 'dinersClub',
};

export const CREDIT_CARDS_TYPES_ICONS = {
  [CREDIT_CARDS_TYPES.VISA]: <VisaIcon />,
  [CREDIT_CARDS_TYPES.MASTERCARD]: <MasterCardIcon />,
  [CREDIT_CARDS_TYPES.AMERICAN_EXPRESS]: <AmericanExpressIcon />,
  [CREDIT_CARDS_TYPES.DISCOVER]: <DiscoverIcon />,
  [CREDIT_CARDS_TYPES.JCB]: <JcbIcon />,
  [CREDIT_CARDS_TYPES.DINERS_CLUB]: <DinersClubIcon />,
};
