import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import WireDepositList from './WireDepositList';
import Modal from '../../../../Modal/Modal';
import TabSelect from '../../../../TabSelect/TabSelect';
import { selectWireDepositInstructions } from '../../../../../store/selectors/accountSelectors';
import {
  deleteWizardKey,
  setWizardContent,
} from '../../../../../util/helpers/wizardHelpers';
import {
  SUPPORT_MODAL_OPEN,
  WIRE_DEPOSIT_TYPE,
} from '../../../../../constants/sessionStorage';
import { useSessionStorageState } from '../../../../../util/hooks/useSessionStorageState';
import SupportModal from '../../../SupportModal/SupportModal';
import ModalBody from '../../../../Modal/ModalBody';
import ModalTitle from '../../../../Modal/ModalTitle';
import Paragraph from '../../../../Paragraph/Paragraph';
import Note from '../../../../Notes/Note';
import AnchorOnClick from '../../../../Anchor/AnchorOnClick';
import ModalButtons from '../../../../Modal/ModalButtons';
import { useBrokerCode } from '../../../../../util/hooks/useBrokerCode';

const depositTypes = {
  Domestic: 'Domestic USD',
  International: 'International USD',
};

const WireDeposit = ({ handleBack, isLoading, isModalOpen, handleClose }) => {
  const { t } = useTranslation();
  const [isSupportModalOpen, setIsSupportModalOpen] = useSessionStorageState(
    SUPPORT_MODAL_OPEN,
    false,
  );
  const wireDepositInstructions = useSelector(selectWireDepositInstructions);
  const brokerCode = useBrokerCode();

  const [type, setType] = useSessionStorageState(
    WIRE_DEPOSIT_TYPE,
    depositTypes.Domestic,
  );

  const setNewType = (type) => {
    setType(type);
    setWizardContent(WIRE_DEPOSIT_TYPE, type);
  };

  const filterObject = (object, keys) => {
    const filteredObject = Object.keys(object)
      .filter((key) => keys.includes(key))
      .reduce((obj, key) => {
        obj[key] = object[key]; // eslint-disable-line
        return obj;
      }, {});

    return filteredObject;
  };

  const listFields = (entries) => {
    const fields = entries.map((entry) => ({
      label: t(`standardDepositWizard.wireDeposit.wireDepositList.${entry[0]}`),
      value: entry[1],
    }));

    return fields;
  };

  const bankNameEdit = (instructions) => {
    const { BankAddress } = instructions;
    const BankAddressLabel = `${BankAddress.AddressLine} ${BankAddress.AddressLine2} ${BankAddress.City}, ${BankAddress.CountryIso3Code} ${BankAddress.PostalCode}`;

    const wireDepositInstructionObject = {
      ...instructions,
      BankAddress: BankAddressLabel,
    };

    return wireDepositInstructionObject;
  };

  const domesticWireDepositEntries = () => {
    const wireDepositInstruction = bankNameEdit(
      wireDepositInstructions.Domestic,
    );

    const listKeys = [
      'BankName',
      'BankAddress',
      'RoutingAbaNumber',
      'AccountNumber',
      'PayableTo',
      'Memo',
    ];

    const entries = Object.entries(
      filterObject(wireDepositInstruction, listKeys),
    );

    return entries;
  };

  const internationalWireDepositEntries = () => {
    const wireDepositInstruction = bankNameEdit(
      wireDepositInstructions.International,
    );

    const listKeys = [
      'BankName',
      'BankAddress',
      'SwiftCode',
      'AccountNumber',
      'AccountName',
      'Memo',
    ];

    const entries = Object.entries(
      filterObject(wireDepositInstruction, listKeys),
    );

    return entries;
  };

  const renderType = (type) => {
    if (type === 'Domestic USD') {
      if (wireDepositInstructions.Domestic) {
        const domesticEntries = domesticWireDepositEntries();

        return <WireDepositList fields={listFields(domesticEntries)} />;
      }
    }

    if (type === 'International USD') {
      if (wireDepositInstructions.International) {
        const internationalEntries = internationalWireDepositEntries();

        return <WireDepositList fields={listFields(internationalEntries)} />;
      }
    }
  };

  const openSupportModal = () => {
    setIsSupportModalOpen(true);
    setWizardContent(SUPPORT_MODAL_OPEN, true);
  };

  const closeSupportModal = () => {
    setIsSupportModalOpen(false);
    deleteWizardKey(SUPPORT_MODAL_OPEN);
  };

  return (
    <>
      {!isSupportModalOpen ? (
        <Modal
          goBack={handleBack}
          size="lg"
          isOpen={isModalOpen}
          close={handleClose}
          title={t('standardDepositWizard.wireDeposit.wireDepositTitle')}
          isLoading={isLoading}
        >
          <ModalBody>
            <ModalTitle>
              {t('standardDepositWizard.wireDeposit.bankWireDepositTitle')}
            </ModalTitle>
            <Paragraph marginBottom={24}>
              {t('standardDepositWizard.wireDeposit.checkOnlineWire')}
            </Paragraph>
            <Paragraph marginBottom={24}>
              <strong>
                {t('standardDepositWizard.wireDeposit.useGuideBelow')}
              </strong>
            </Paragraph>
            <Paragraph marginBottom={24}>
              <Trans i18nKey="standardDepositWizard.checkDeposit.haveQuestions">
                If you have any questions about funding your account,
                <AnchorOnClick
                  onClick={(event) => {
                    event.preventDefault();
                    openSupportModal();
                  }}
                >
                  please contact customer service.
                </AnchorOnClick>
              </Trans>
            </Paragraph>

            <Paragraph marginBottom={24}>
              {t('standardDepositWizard.wireDeposit.pleaseProvideInformation', {
                brokerCode,
              })}
            </Paragraph>
            <TabSelect
              initialValue={type}
              types={Object.values(depositTypes)}
              onChange={(type) => setNewType(type)}
            >
              {type && renderType(type)}
            </TabSelect>

            <Note
              text={t(
                'standardDepositWizard.wireDeposit.brokerDoesNotChargeFee',
                {
                  brokerCode,
                },
              )}
            />

            <ModalButtons
              showOnlyPrimary
              marginTop
              primaryButtonProps={{
                onClick: handleClose,
                label: t('common.ok'),
              }}
              smallButtons
              buttonOnRight
            />
          </ModalBody>
        </Modal>
      ) : (
        <SupportModal
          avoidClearingStorage
          isOpen={isSupportModalOpen}
          close={closeSupportModal}
          goBack={closeSupportModal}
        />
      )}
    </>
  );
};

WireDeposit.propTypes = {
  isLoading: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleBack: PropTypes.func,
};

export default WireDeposit;
