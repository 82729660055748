import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';

import styled from 'styled-components';
import { fetchSingleBankAccount } from '../../store/actions/bankAccount/bankAccountActions';
import {
  selectBankAccountsError,
  selectSingleBankAccount,
} from '../../store/selectors/bankAccountSelectors';
import BlockSectionLoader from '../Loader/BlockSectionLoader';
import { selectIsLoadingByActionType } from '../../store/selectors/loadingSelectors';
import { FETCH_SINGLE_BA_LOADING } from '../../store/actions/bankAccount/bankAccountActionConstants';
import { ACC_PURPOSE_ACH } from '../../util/enum/api/bankAccountTypes';
import { uFlexColumn } from '../../assets/styles/utility';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';

export const FundingSourceContainer = styled.div`
  ${uFlexColumn};
  margin-bottom: ${pxToRem(32)};

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-bottom: ${pxToRemMd(32)};
  }
`;

export const FundingSourceTitle = styled.h3`
  font-size: ${pxToRem(18)};
  margin-bottom: ${pxToRem(12)};
  font-weight: bold;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(18)};
    margin-bottom: ${pxToRemMd(12)};
  }
`;

export const FundingSourceItemContainer = styled.div`
  ${uFlexColumn};
`;

export const FundingSourceItemBankName = styled.p`
  font-size: ${pxToRem(14)};
  line-height: 1.75;
  font-weight: 600;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(14)};
  }
`;

export const FundingSourceItemAccountMask = styled.p`
  font-size: ${pxToRem(14)};
  line-height: 1.75;
  font-weight: 600;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(14)};
  }
`;

export const FundingSourcePaused = styled.p`
  font-size: ${pxToRem(14)};
  line-height: 1.75;
  font-weight: 600;

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(14)};
  }
`;

const FundingSource = ({
  accountUid,
  bankAccountUid,
  isFromAccountBalance,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const singleBa = useSelector(selectSingleBankAccount);
  const isSingleBaLoading = useSelector(
    selectIsLoadingByActionType(FETCH_SINGLE_BA_LOADING),
  );
  const bankAccError = useSelector(selectBankAccountsError);

  useEffect(() => {
    if (!isFromAccountBalance && accountUid && bankAccountUid) {
      dispatch(
        fetchSingleBankAccount({
          accountUid,
          bankAccountUid,
          type: ACC_PURPOSE_ACH,
        }),
      );
    }
  }, [dispatch, isFromAccountBalance, accountUid, bankAccountUid]);

  const renderFundingSource = () => {
    if (isFromAccountBalance) {
      return (
        <FundingSourcePaused>{t('fundingSource.paused')}</FundingSourcePaused>
      );
    }

    if (isSingleBaLoading) {
      return <BlockSectionLoader isLoading={isSingleBaLoading} />;
    }

    return (
      <>
        {!!bankAccError && <span>{bankAccError}</span>}
        {isEmpty(singleBa) ? null : (
          <FundingSourceItemContainer>
            <FundingSourceItemBankName>
              {singleBa.BankName}
            </FundingSourceItemBankName>
            <FundingSourceItemAccountMask>
              {singleBa.AccountMask}
            </FundingSourceItemAccountMask>
          </FundingSourceItemContainer>
        )}
      </>
    );
  };

  return (
    <FundingSourceContainer>
      <FundingSourceTitle>{t('fundingSource.title')}</FundingSourceTitle>
      {renderFundingSource()}
    </FundingSourceContainer>
  );
};

FundingSource.propTypes = {
  accountUid: PropTypes.string,
  bankAccountUid: PropTypes.string,
  isFromAccountBalance: PropTypes.bool,
};

export default FundingSource;
