import i18next from 'i18next';
import * as Yup from 'yup';
import { formatMoneyNumeral } from '../util/helpers/numeralHelpers';

export const amountValidationSchema = (max) =>
  Yup.object().shape({
    amount: Yup.number()
      .required(i18next.t('validationSchema.general.amountRequired'))
      .min(
        0.01,
        i18next.t('validationSchema.withdrawal.withdrawalMinAmount', {
          amount: formatMoneyNumeral(0.01),
        }),
      )
      .max(max, i18next.t('validationSchema.general.insufficientFunds')),
  });

export const minmaxAmountValidationSchema = (minimumAmount, maximumAmount) =>
  Yup.object().shape({
    amount: Yup.number()
      .required(i18next.t('validationSchema.general.amountRequired'))
      .min(
        minimumAmount,
        i18next.t('validationSchema.deposit.minimumAmount', {
          amount: formatMoneyNumeral(minimumAmount),
        }),
      )
      .max(
        maximumAmount,
        i18next.t('validationSchema.deposit.maximumAmount', {
          amount: formatMoneyNumeral(maximumAmount),
        }),
      ),
  });
