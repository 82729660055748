import i18next from 'i18next';

export const VIEW_TYPE_OPTIONS = [
  {
    value: 'Vault',
    label: i18next.t('portfolioSummary.viewByVault'),
    dataCy: 'menu-item-holdings-view-vault',
  },
  {
    value: 'MetalType',
    label: i18next.t('portfolioSummary.viewByType'),
    dataCy: 'menu-item-holdings-view-type',
  },
  {
    value: 'Product',
    label: i18next.t('portfolioSummary.viewByProduct'),
    dataCy: 'menu-item-holdings-view-product',
  },
];

export const VIEW_VALUE_OPTIONS = [
  {
    value: 'Value',
    label: i18next.t('portfolioSummary.viewByValue'),
  },
  {
    value: 'Percentage',
    label: i18next.t('portfolioSummary.viewByPercentage'),
  },
];
