import {
  ACCOUNT_TRANSACTIONS_SUCCESS,
  ACCOUNT_TRANSACTIONS_ERROR,
  PORTFOLIO_TRANSACTIONS_SINGLE_ERROR,
  PORTFOLIO_TRANSACTIONS_SINGLE_SUCCESS,
  PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_ERROR,
  PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_SUCCESS,
  PATCH_PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_ERROR,
} from '../../actions/portfolio/portfolioActionConstants';
import createReducer from '../../utils/createReducer';

const initialState = {
  data: [],
  error: '',
  singleTransaction: {
    data: {},
    error: '',
  },
  transactions: {
    data: {},
    error: '',
  },
  pendingTransactions: {
    data: {},
    error: '',
  },
};

export default createReducer(
  {
    [PORTFOLIO_TRANSACTIONS_SINGLE_SUCCESS]: setPortfolioSingleTransaction,
    [PORTFOLIO_TRANSACTIONS_SINGLE_ERROR]: setPortfolioSingleTransactionError,
    [ACCOUNT_TRANSACTIONS_SUCCESS]: setAccountTransactions,
    [ACCOUNT_TRANSACTIONS_ERROR]: setAccountTransactionsError,
    [PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_ERROR]: setFinancialPendingTransactionsError,
    [PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_SUCCESS]: setFinancialPendingTransactions,
    [PATCH_PORTFOLIO_FINANCIAL_PENDING_TRANSACTIONS_ERROR]: patchFinancialPendingTransactionsError,
  },
  initialState,
);

function setPortfolioSingleTransaction(state, action) {
  return {
    ...state,
    singleTransaction: {
      ...state.singleTransaction,
      data: action.payload,
    },
  };
}

function setPortfolioSingleTransactionError(state, action) {
  return {
    ...state,
    singleTransaction: {
      ...state.singleTransaction,
      error: action.payload,
    },
  };
}

function setAccountTransactions(state, action) {
  return {
    ...state,
    transactions: {
      data: action.payload,
    },
  };
}

function setAccountTransactionsError(state, action) {
  return {
    ...state,
    error: action.payload,
  };
}

function setFinancialPendingTransactions(state, action) {
  return {
    ...state,
    pendingTransactions: {
      data: action.payload,
    },
  };
}

function setFinancialPendingTransactionsError(state, action) {
  return {
    ...state,
    error: action.payload,
  };
}

function patchFinancialPendingTransactionsError(state, action) {
  return {
    ...state,
    error: action.payload,
  };
}
