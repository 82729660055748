import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Table from '../Table/Table';
import TableBody from '../Table/TableBody';
import TableHead from '../Table/TableHead';
import TableRow from '../Table/TableRow';
import TableData from '../Table/TableData';
import BrandRow from './BrandRow';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import { variables } from '../../assets/styles/variables';

export const BrandTableHead = styled(TableHead)`
  padding: ${pxToRem(4)} ${pxToRem(16)};
  display: grid;
  grid-template-columns: 1.5fr 2fr 1.5fr;
  grid-column-gap: ${pxToRem(16)};

  ${TableData} {
    font-size: ${pxToRem(14)};
  }

  > :nth-child(2) {
    justify-content: center;
  }

  > :last-child {
    justify-content: flex-end;
    text-align: right;
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(4)} ${pxToRemMd(16)};

    > ${TableData} {
      font-size: ${pxToRemMd(14)};
    }
  }
`;

export const BrandTableRow = styled(TableRow)`
  padding: ${pxToRem(9)} ${pxToRem(16)};

  p {
    font-size: ${pxToRem(14)};
  }

  ${TableData} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: ${pxToRem(16)};

    > :nth-child(2) {
      text-align: center;
    }

    > :last-child {
      text-align: right;
    }
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: ${pxToRemMd(9)} ${pxToRemMd(16)};

    p {
      font-size: ${pxToRemMd(16)};
    }
  }
`;

const BrandTable = ({ data, marginTop, marginBottom }) => {
  const { t } = useTranslation();

  const brandRowData = data.map((brand) => ({
    id: `${brand.BrandCode}_${brand.SerialNumber}_${brand.FineWeight}`,
    ...brand,
    content: (
      <BrandRow
        code={brand.BrandCode}
        serial={brand.SerialNumber}
        weight={brand.FineWeight}
      />
    ),
  }));

  const brandHeaderData = [
    { id: 1, content: t('product.brandsTable.brandCode') },
    { id: 2, content: t('product.brandsTable.serialNumber') },
    { id: 3, content: t('product.brandsTable.fineWeight') },
  ];

  return (
    <>
      <Table marginTop={marginTop} marginBottom={marginBottom}>
        <BrandTableHead data={brandHeaderData} />
        <TableBody>
          <TableRow onClick={() => {}} rowData={brandRowData} />
        </TableBody>
      </Table>
    </>
  );
};

BrandTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
};

export default BrandTable;
