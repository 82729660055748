import * as Yup from 'yup';
import i18next from '../i18n';

export const verifyBankAccountValidationSchema = Yup.object().shape({
  AmountOne: Yup.number()
    .max(
      0.99,
      i18next.t(
        'validationSchema.verifyBankAccountValidationSchema.amountDecimal',
      ),
    )
    .required(i18next.t('validationSchema.general.amountRequired')),
  AmountTwo: Yup.number()
    .max(
      0.99,
      i18next.t(
        'validationSchema.verifyBankAccountValidationSchema.amountDecimal',
      ),
    )
    .required(i18next.t('validationSchema.general.amountRequired')),
});
