import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../../components/InputFields/Checkbox';

const RememberDevice = ({ values }) => {
  const { t } = useTranslation();

  return (
    <Field name="Remember" component={Checkbox} checked={values.Remember}>
      {t('twoFa.loginVerify.rememberMe')}
    </Field>
  );
};

RememberDevice.propTypes = {
  values: PropTypes.shape({
    Remember: PropTypes.bool,
  }),
};

export default RememberDevice;
