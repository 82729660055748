import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useHistory } from 'react-router';
import Auth from '../components/Auth/Auth';

import {
  EMAIL_LOGIN_PAGE,
  INVITE_USER_PAGE,
  INVITE_USER_SECURITY_QUESTION,
  SECURITY_QUESTION_PAGE,
} from '../constants/pages';
import { resetLoginState } from '../store/actions/login/loginActions';
import useGtmHook from '../util/hooks/useGtmHook';
import BulletItem from '../components/BulletItem/BulletItem';
import AuthCard from '../components/Auth/AuthCard';
import Paragraph from '../components/Paragraph/Paragraph';
import BulletList from '../components/BulletList/BulletList';
import AuthButtons from '../components/Auth/AuthButtons';

const IdentityPage = ({ isInviteUserFlow }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { gtmScreenView } = useGtmHook();

  useEffect(() => {
    if (!isInviteUserFlow) {
      gtmScreenView({
        path: window.location.pathname,
        title: 'Login',
      });
    }
  }, []); // eslint-disable-line

  const handleCancel = () => {
    dispatch(resetLoginState());

    if (isInviteUserFlow) {
      history.push(INVITE_USER_PAGE);
    } else {
      history.push(EMAIL_LOGIN_PAGE);
    }
  };

  const handleNext = () => {
    if (isInviteUserFlow) {
      history.push(INVITE_USER_SECURITY_QUESTION);
    } else {
      history.push(SECURITY_QUESTION_PAGE);
    }
  };

  return (
    <Auth>
      <AuthCard title={t('login.identityPage.title')}>
        <Paragraph marginBottom={32}>
          {t('login.identityPage.description')}
        </Paragraph>
        <Paragraph marginBottom={16} bold isPrimaryColor>
          {t('login.identityPage.dontRecognizeComputer')}
        </Paragraph>

        <BulletList marginBottom={32}>
          <BulletItem text={t('login.identityPage.newDevice')} />
          <BulletItem text={t('login.identityPage.providerChangedSettings')} />
        </BulletList>
        <Paragraph marginBottom={16} bold isPrimaryColor>
          {t('login.identityPage.whatToDo')}
        </Paragraph>
        <Paragraph marginBottom={16} bold>
          {t('login.identityPage.needToAnswerSecurityQuestion')}
        </Paragraph>
        <Paragraph>{t('login.identityPage.willBeNotified')}</Paragraph>
        <AuthButtons
          isVertical
          secondaryButtonProps={{
            onClick: handleCancel,
            label: t('common.cancel'),
          }}
          primaryButtonProps={{
            onClick: handleNext,
            label: t('login.identityPage.answerSecurityQuestion'),
          }}
        />
      </AuthCard>
    </Auth>
  );
};

IdentityPage.propTypes = {
  isInviteUserFlow: PropTypes.bool,
};

export default IdentityPage;
