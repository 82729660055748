import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import { updateAccountAddresses } from '../../store/actions/account/accountActions';
import useGtmHook from '../../util/hooks/useGtmHook';
import { ADDRESS_SET_TO_DEFAULT } from '../../constants/gtmEvents';
import { formatAddress } from '../../util/helpers/addressHelper';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { uFlexColumn, uFullWidth } from '../../assets/styles/utility';
import { variables } from '../../assets/styles/variables';
import AddressStatus from './AddressStatus';
import { selectIsLoadingByActionType } from '../../store/selectors/loadingSelectors';
import { ACCOUNT_ADDRESS_UPDATE_LOADING } from '../../store/actions/account/accountActionConstants';

export const AddressCardButtons = styled.div`
  display: flex;
  > button {
    min-width: ${pxToRem(120)};
  }
  > :first-child {
    margin-right: ${pxToRem(18)};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    ${uFullWidth};
    margin-top: ${pxToRemMd(16)};
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${pxToRemMd(20)};

    button {
      ${uFullWidth};
      min-width: 0;
    }

    > :first-child {
      margin-right: 0;
    }
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    button {
      min-width: ${pxToRemMd(120)};
      font-size: ${pxToRemMd(16)};
    }

    > :first-child {
      margin-right: ${pxToRemMd(18)};
    }
  }
`;

export const AddressCardDetails = styled.div`
  ${uFlexColumn};
  padding-right: ${pxToRem(16)};
  margin-right: auto;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding-right: ${pxToRemMd(16)};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-right: 0;
  }
`;

export const AddressCardDefault = styled.p`
  margin-right: ${pxToRem(8)};
  line-height: 1.72;
  color: ${themeColors.colorPrimary};
  font-weight: 600;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    margin-right: ${pxToRemMd(8)};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-right: 0;
    margin-top: ${pxToRemMd(16)};
    margin-bottom: 0;
  }
`;

export const AddressCardName = styled.p`
  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-weight: 500;
  }
`;

export const AddressCardAddress = styled.p`
  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-weight: 500;
  }
`;

export const AddressesCard = styled.div`
  padding: ${pxToRem(8)} ${pxToRem(20)} ${pxToRem(8)} ${pxToRem(16)};
  display: flex;
  box-shadow: ${variables.shadow.boxShadow};
  border-radius: ${variables.borderRadius.borderRadius};
  background-color: ${themeColors.colorBackgroundPrimary};
  align-items: center;
  border: 1px solid ${themeColors.colorBackgroundPrimary};
  ${(props) =>
    props.isAddressCardDefault &&
    `border: 1px solid ${themeColors.colorPrimary}`};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: ${pxToRemMd(7)} ${pxToRemMd(20)} ${pxToRemMd(7)} ${pxToRemMd(16)};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    flex-direction: column;
    align-items: flex-start;
    padding: ${pxToRemMd(7)} ${pxToRemMd(16)} ${pxToRemMd(16)} ${pxToRemMd(16)};
  }
`;

const AddressCard = ({
  address,
  hasChangePermission,
  accountUid,
  setAddressUid,
  setIsDeleteModalOpen,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { gtmEvent } = useGtmHook();
  const isLoading = useSelector(
    selectIsLoadingByActionType([ACCOUNT_ADDRESS_UPDATE_LOADING]),
  );

  if (!address) {
    return null;
  }

  const { AddressUid, RecipientCaption, IsDefaultMailingAddress } = address;

  const makeDefault = () => {
    const handleGtm = () => {
      gtmEvent(ADDRESS_SET_TO_DEFAULT);
    };

    dispatch(
      updateAccountAddresses({
        accountUid,
        addressUid: AddressUid,
        data: [
          {
            op: 'replace',
            path: '/IsDefaultMailingAddress',
            value: true,
          },
        ],
        handleGtm,
      }),
    );
  };

  return (
    <AddressesCard isAddressCardDefault={IsDefaultMailingAddress}>
      <AddressCardDetails>
        <AddressCardName>{RecipientCaption}</AddressCardName>
        <AddressCardAddress>{formatAddress(address)}</AddressCardAddress>
        <AddressStatus isVerified={address.IsVerified} />
      </AddressCardDetails>

      {IsDefaultMailingAddress ? (
        <AddressCardDefault>{t('common.defaultAddress')}</AddressCardDefault>
      ) : (
        <AddressCardButtons>
          <Button
            disabled={!hasChangePermission || isLoading}
            textTransform="capitalize"
            variant="primary"
            size="sm"
            onClick={makeDefault}
          >
            {t('common.makeDefault')}
          </Button>
          <Button
            textTransform="capitalize"
            variant="primaryOutlined"
            size="sm"
            disabled={!hasChangePermission}
            onClick={() => {
              setAddressUid(AddressUid);
              setIsDeleteModalOpen(true);
            }}
          >
            {t('common.delete')}
          </Button>
        </AddressCardButtons>
      )}
    </AddressesCard>
  );
};

AddressCard.propTypes = {
  address: PropTypes.shape({
    AddressUid: PropTypes.string,
    RecipientCaption: PropTypes.string,
    IsDefaultMailingAddress: PropTypes.bool,
    IsVerified: PropTypes.bool,
    BrokerAccountHolderType: PropTypes.number,
  }),
  hasChangePermission: PropTypes.bool,
  accountUid: PropTypes.string,
  setAddressUid: PropTypes.func,
  setIsDeleteModalOpen: PropTypes.func,
};

export default AddressCard;
