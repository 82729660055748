import React from 'react';
import PropTypes from 'prop-types';

import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  CartesianGrid,
} from 'recharts';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CustomizedDot from './CustomizedDot';
import CustomizedTooltip from './CustomizedTooltip';
import { PERIOD } from '../../constants/period';
import { buttonizeDiv } from '../../util/buttonizeDiv';
import useWindowSize from '../../util/hooks/useIsMobileHook';
import { formatMoneyNumeral } from '../../util/helpers/numeralHelpers';
import { variables } from '../../assets/styles/variables';
import { mediaBelow, pxToRem, pxToRemMd } from '../../assets/styles/helper';
import themeColors from '../../assets/styles/themeColors';
import { uFlexCenter, uFlexColumn } from '../../assets/styles/utility';

export const ChartContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;

  ${mediaBelow(variables.breakpoints.bpXl)} {
    padding: 0 ${pxToRem(24)};
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    padding: 0 ${pxToRemMd(24)};
  }

  .recharts-surface {
    overflow: visible;
  }

  .recharts-cartesian-axis-tick-value {
    color: ${themeColors.colorTextSecondary};
    font-size: ${pxToRem(10)};
    letter-spacing: 0;
    line-height: ${pxToRem(20)};
  }

  .recharts-tooltip-wrapper:empty {
    display: 'none';
  }

  .recharts-text {
    &.recharts-pie-label-text {
      font-size: ${pxToRem(14)};

      ${mediaBelow(variables.breakpoints.bpXl)} {
        font-size: ${pxToRem(12)};
      }
    }
  }
`;

export const ChartPeriodsContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 38px;
  margin-right: 10px;

  > :not(:last-child) {
    margin-left: 8px;
  }

  ${mediaBelow(variables.breakpoints.bpXl)} {
    position: relative;
    bottom: unset;
    right: unset;
    justify-content: flex-end;
    margin-bottom: ${pxToRem(16)};
    margin-right: 0;
    font-size: ${pxToRem(16)};
    line-height: 1.375;
    max-width: ${pxToRem(390)};
    width: 100%;

    > :not(:last-child) {
      margin-left: auto;
    }
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    margin-bottom: ${pxToRemMd(16)};
    font-size: ${pxToRemMd(16)};
    max-width: ${pxToRemMd(390)};
  }
`;

export const ChartPeriods = styled.div`
  color: ${themeColors.colorLabel};
  opacity: 0.5;
  cursor: pointer;
  ${({ isSelected }) =>
    isSelected &&
    `opacity: 1;
     text-decoration: underline;`};

  ${mediaBelow(variables.breakpoints.bpXl)} {
    color: ${themeColors.colorTextPrimary});
    font-size: ${pxToRem(16)};
    line-height: 1.375;
  }

  ${mediaBelow(variables.breakpoints.bpMd)} {
    font-size: ${pxToRemMd(16)};
  }
`;

export const ChartEmptyContainer = styled.div`
  ${uFlexCenter};
  ${uFlexColumn};
  min-height: ${pxToRem(232)};
  position: relative;
`;

export const ChartEmptyText = styled.p`
  color: ${themeColors.colorTextSecondary};
  font-weight: 600;
`;

const LineChart = ({
  data,
  selectedPeriod,
  setSelectedPeriod,
  period,
  domain,
  chartError,
}) => {
  const { t } = useTranslation();
  const intialDataValues = [{ Value: 0, Date: new Date().toISOString() }];
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 1200;
  const minValue = domain.min;
  const maxValue = domain.max;

  const returnTickFormat = (item) => {
    if (item) {
      const date = parseISO(item);
      switch (selectedPeriod) {
        case PERIOD.All:
        case PERIOD.OneYear:
        case PERIOD.SixMonths:
          return format(date, 'MMM/yyyy');
        case PERIOD.ThreeMonths:
        case PERIOD.OneMonth:
        case PERIOD.OneWeek:
          return format(date, 'dd/MMM');
        case PERIOD.OneDay:
          return `${format(date, 'HH')}:00 h`;
        case PERIOD.OneHour:
          return `${format(date, 'HH:mm ')} h`;
        default:
          break;
      }
    }
  };

  const roundCeilNumber = (value, divider) =>
    Math.ceil(value / divider) * divider;

  const roundFloorNumber = (value, divider) =>
    Math.floor(value / divider) * divider;

  const valueDifferenceDecade =
    10 ** (Math.round(maxValue - minValue).toString().length - 1);

  const roundValueDifference =
    roundCeilNumber(maxValue, valueDifferenceDecade) -
    roundFloorNumber(minValue, valueDifferenceDecade);

  const calculateTicksList = () => {
    const ticks = [];

    const interval = roundValueDifference / 4;

    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= 4; i++) {
      ticks.push(
        (
          roundFloorNumber(minValue, valueDifferenceDecade) +
          interval * i
        ).toFixed(2),
      );
    }

    return ticks;
  };

  const ticks = [
    roundFloorNumber(minValue, valueDifferenceDecade),
    ...calculateTicksList(),
  ];

  const TickTimeFrame = () => (
    <ChartPeriodsContainer>
      {period.map((p) => (
        <ChartPeriods
          key={p}
          {...buttonizeDiv(() => setSelectedPeriod(p))}
          isSelected={`${[p === selectedPeriod].filter(Boolean)}`}
        >
          {p}
        </ChartPeriods>
      ))}
    </ChartPeriodsContainer>
  );

  return (
    <>
      {!chartError ? (
        <ChartContainer>
          <ResponsiveContainer width="100%" height={400}>
            <AreaChart data={data.length > 0 ? data : intialDataValues}>
              <Tooltip
                isAnimationActive={false}
                content={<CustomizedTooltip selectedPeriod={selectedPeriod} />}
                cursor={{ strokeDasharray: 8 }}
              />
              <CartesianGrid stroke={themeColors.colorGraphicGridLines} />
              <Area
                stroke={themeColors.colorPrimary}
                strokeWidth={2}
                fill="transparent"
                dataKey="Value"
                activeDot={<CustomizedDot />}
                isAnimationActive={false}
              />
              <XAxis
                tickFormatter={(item) => returnTickFormat(item)}
                dataKey="Date"
                tickLine={false}
                axisLine={false}
                minTickGap={isMobile ? 50 : 200}
                interval="preserveStart"
                dy={6}
              />
              <YAxis
                tickFormatter={(item) => formatMoneyNumeral(item)}
                tickLine={false}
                axisLine={false}
                dataKey="Value"
                domain={[
                  roundFloorNumber(minValue, valueDifferenceDecade),
                  roundCeilNumber(maxValue, valueDifferenceDecade),
                ]}
                ticks={ticks}
                dx={-7}
              />
            </AreaChart>
          </ResponsiveContainer>
          <TickTimeFrame />
        </ChartContainer>
      ) : (
        <ChartEmptyContainer>
          <ChartEmptyText>{t('portfolio.couldNotLoad')}</ChartEmptyText>
          <TickTimeFrame />
        </ChartEmptyContainer>
      )}
    </>
  );
};

LineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  selectedPeriod: PropTypes.string,
  setSelectedPeriod: PropTypes.func,
  period: PropTypes.arrayOf(PropTypes.string),
  domain: PropTypes.shape({ min: PropTypes.number, max: PropTypes.number }),
  chartError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      ticks: PropTypes.arrayOf(null),
    }),
  ]),
};

export default LineChart;
