import i18next from 'i18next';
import * as Yup from 'yup';

export const registrationValidationSchema = Yup.object().shape({
  Email: Yup.string()
    .email(i18next.t(`fields.errors.validation.emailFormat`))
    .required(i18next.t(`fields.errors.validation.emailRequired`)),
  Password: Yup.string()
    .required(i18next.t(`fields.errors.validation.password`))
    .matches(
      /(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/,
      i18next.t(`fields.errors.validation.passwordStrength`),
    ),
  FirstName: Yup.string().required(
    i18next.t('validationSchema.general.firstNameRequired'),
  ),
  LastName: Yup.string().required(
    i18next.t('validationSchema.general.lastNameRequired'),
  ),
});
